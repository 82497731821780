import React, { useLayoutEffect, useMemo, useState } from "react";
import { useAggregatedFiltersContext } from "../AggregatedFilters/AggregatedFilters.context";
import { Button } from "../../general/Button/Button";
import { Separator } from "../Separator/Separator";
import styles from "./ActiveFilters.module.scss";

export interface ActiveFiltersProps<TFilter extends string = string> {
    activeFilters: TFilter[];
    clearFilters(): void;
    renderVisibleFilters(visibleFilters: TFilter[]): React.ReactNode;
    mergeMultipleFiltersIntoOneButton?: boolean;
    hideClearButton?: boolean;
}

export const ActiveFilters = <TFilter extends string>({
    activeFilters,
    clearFilters,
    renderVisibleFilters,
    mergeMultipleFiltersIntoOneButton = false,
    hideClearButton = false,
}: ActiveFiltersProps<TFilter>) => {
    const [maxActiveFilters, setMaxActiveFilters] = useState(2);
    const { open: openFilters } = useAggregatedFiltersContext();
    const containerRef = React.useRef<HTMLElement>(null);

    const hasActiveFilters = activeFilters.length > 0;
    const hasMoreActiveFilters = activeFilters.length > maxActiveFilters;

    const visibleActiveFilters = useMemo(
        () =>
            activeFilters.slice(
                0,
                hasMoreActiveFilters ? maxActiveFilters : undefined,
            ),
        [activeFilters, hasMoreActiveFilters, maxActiveFilters],
    );

    useLayoutEffect(() => {
        if (!containerRef.current) {
            return;
        }

        const width = containerRef.current.clientWidth;

        if (width > 700) {
            setMaxActiveFilters(3);
        } else if (width > 500) {
            setMaxActiveFilters(2);
        } else {
            setMaxActiveFilters(1);
        }
    }, [containerRef.current?.clientWidth]);

    const mergeFiltersIntoOneButton =
        mergeMultipleFiltersIntoOneButton && activeFilters.length > 1;

    return (
        <section className={styles.activeFilters} ref={containerRef}>
            {mergeFiltersIntoOneButton && hasActiveFilters && (
                <>
                    <Separator orientation="vertical" block />
                    <div className={styles.list}>
                        <Button
                            variant="tertiary"
                            size="sm"
                            onClick={() => openFilters()}
                        >
                            {activeFilters.length} Filters
                        </Button>
                    </div>
                </>
            )}
            {!mergeFiltersIntoOneButton && hasActiveFilters && (
                <div className={styles.list}>
                    {renderVisibleFilters(visibleActiveFilters)}

                    {hasMoreActiveFilters && (
                        <Button
                            variant="tertiary"
                            size="sm"
                            onClick={() => openFilters()}
                        >
                            +
                            {activeFilters.length - visibleActiveFilters.length}{" "}
                            more
                        </Button>
                    )}
                </div>
            )}
            {hasActiveFilters && !hideClearButton && (
                <>
                    <span className={styles.separator}>•</span>
                    <Button
                        variant="tertiary"
                        size="sm"
                        onClick={clearFilters}
                        className={styles.clearButton}
                        data-testid="clear-filters-button"
                    >
                        Clear filters
                    </Button>
                </>
            )}
        </section>
    );
};
