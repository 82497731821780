import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverEvent,
    DragOverlay,
    DragStartEvent,
} from "@dnd-kit/core";
import { sortBy } from "lodash";
import { arrayMove } from "@dnd-kit/sortable";
import { EntityAccount } from "../EntityAccount";
import { Entity } from "../../../common/types/entity";
import { useDebouncedCallback } from "../../../hooks/useDebouncedCallback";
import { FinancialAccount } from "../../../common/types/financialAccount";
import { useEntitiesContexts } from "../../../hooks/useEntitiesContexts";
import { ChildrenProps } from "../../../types";
import { getEntityId } from "./accountsDnd";
import { AccountsDndContext, EntityWithAccounts } from "./AccountsDnd.context";

interface Props extends ChildrenProps {
    onReorder: (
        entity: Entity,
        newOrder: number[],
        movedAccount: FinancialAccount,
    ) => Promise<boolean>;
}

interface ReorderAccountsParams {
    entity: Entity;
    accounts: FinancialAccount[];
    draggedFromEntity: EntityWithAccounts;
    draggedToEntity: EntityWithAccounts;
    dragOverEntityHeader: boolean;
}

export const AccountsDndContextProvider: React.FC<Props> = ({
    onReorder,
    children,
}) => {
    const [draggedItemId, setDraggedItemId] = useState<number>();

    const entitiesContext = useEntitiesContexts();
    const [entitiesWithAccounts, setEntitiesWithAccounts] = useState<
        EntityWithAccounts[]
    >([]);

    const rebuildEntitiesWithAccounts = useCallback(() => {
        setEntitiesWithAccounts(
            entitiesContext?.map((ec) => ({
                entity: ec.entity,
                accounts: sortBy(ec.financialAccounts, (a) => a.order),
            })) ?? [],
        );
    }, [entitiesContext]);

    useEffect(() => {
        rebuildEntitiesWithAccounts();
    }, [rebuildEntitiesWithAccounts]);

    const getContainerByAccountId = useCallback(
        (accountId: number) =>
            entitiesWithAccounts.find((entityWithAccounts) =>
                entityWithAccounts.accounts.find((a) => a.id === accountId),
            ),
        [entitiesWithAccounts],
    );

    const getContainerByEntityId = useCallback(
        (entityId: number) =>
            entitiesWithAccounts.find(
                (entityWithAccounts) =>
                    entityWithAccounts.entity.id === entityId,
            ),
        [entitiesWithAccounts],
    );

    const draggedAccount = useMemo(() => {
        if (draggedItemId) {
            return getContainerByAccountId(draggedItemId)?.accounts.find(
                (a) => a.id === draggedItemId,
            );
        }
    }, [draggedItemId, getContainerByAccountId]);

    const handleDragStart = useCallback((event: DragStartEvent) => {
        const accountId = event.active.id as number;
        setDraggedItemId(accountId);
    }, []);

    const reorderAccounts = useCallback(
        ({
            entity,
            accounts,
            draggedFromEntity,
            draggedToEntity,
            dragOverEntityHeader,
        }: ReorderAccountsParams) => {
            if (entity.id === draggedFromEntity.entity.id) {
                return accounts.filter(
                    (account) => account.id !== draggedAccount!.id,
                );
            } else if (entity.id === draggedToEntity.entity.id) {
                return dragOverEntityHeader
                    ? [draggedAccount!, ...accounts]
                    : [...accounts, draggedAccount!];
            } else {
                return accounts;
            }
        },
        [draggedAccount],
    );

    const handleDragOver = useDebouncedCallback(
        (event: DragOverEvent) => {
            const { active, over } = event;

            if (!over || active.id === over.id) {
                return;
            }

            const draggedFromEntity = getContainerByAccountId(
                active.id as number,
            );

            const overEntityId = getEntityId(over.id as string);
            const draggedToEntity = overEntityId
                ? getContainerByEntityId(overEntityId)
                : getContainerByAccountId(over.id as number);

            if (
                draggedAccount &&
                draggedFromEntity &&
                draggedToEntity &&
                draggedFromEntity.entity.id !== draggedToEntity.entity.id
            ) {
                // temporarily move account between entities when dragged over
                setEntitiesWithAccounts((prev) =>
                    prev.map(({ entity, accounts }) => ({
                        entity,
                        accounts: reorderAccounts({
                            entity,
                            accounts,
                            draggedFromEntity,
                            draggedToEntity,
                            dragOverEntityHeader: Boolean(overEntityId),
                        }),
                    })),
                );
            }
        },
        [
            getContainerByAccountId,
            getContainerByEntityId,
            reorderAccounts,
            draggedAccount,
        ],
        30,
    );

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event;

            setDraggedItemId(undefined);
            if (!over) {
                return;
            }

            const entityId = getEntityId(over.id as string);
            const container = entityId
                ? getContainerByEntityId(entityId)
                : getContainerByAccountId(over.id as number);

            if (
                !container ||
                (active.id === over.id &&
                    draggedAccount!.entity?.id === container.entity.id)
            ) {
                return;
            }

            const currentIds = container.accounts.map((a) => a.id);
            const oldIndex = currentIds.indexOf(active.id as number);
            const newIndex = currentIds.indexOf(over.id as number);

            const newIds = arrayMove(currentIds, oldIndex, newIndex);

            onReorder(container.entity, newIds, draggedAccount!).then(
                (approveDrop) => {
                    if (!approveDrop) {
                        rebuildEntitiesWithAccounts();
                    }
                },
            );
        },
        [
            onReorder,
            getContainerByAccountId,
            getContainerByEntityId,
            draggedAccount,
            rebuildEntitiesWithAccounts,
        ],
    );

    const dndContextValue = useMemo(
        () => ({
            entitiesWithAccounts,
            draggedAccount,
        }),
        [draggedAccount, entitiesWithAccounts],
    );

    return (
        <DndContext
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
        >
            <AccountsDndContext.Provider value={dndContextValue}>
                {children}
            </AccountsDndContext.Provider>
            <DragOverlay>
                {draggedAccount ? (
                    <EntityAccount account={draggedAccount} />
                ) : null}
            </DragOverlay>
        </DndContext>
    );
};
