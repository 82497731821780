import { AccountClass } from "../common/types/domains/accounting/accounts";
import { PREMIUM_FEATURES } from "../common/flatRateBilling";
import { useAccounts } from "./useAccounts";
import { useAllowFeature } from "./useAllowFeature";

export function useManualJournalAccounts(
    entityId?: number,
    isManualJournal?: boolean,
) {
    const allowFeature = useAllowFeature(PREMIUM_FEATURES.GENERAL_LEDGER);

    // We provide only pnl accounts in manual journals for low tier users
    const classes =
        isManualJournal && !allowFeature
            ? [AccountClass.expenses, AccountClass.income]
            : undefined;

    return useAccounts({ entityId, classes });
}
