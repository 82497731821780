import React from "react";
import { TESTIMONIAL_AVATAR_URL } from "../../constants";
import styles from "./Testimonial.module.scss";

export const Testimonial: React.FC = () => (
    <div className={styles.testimonial}>
        <q>Kick cleaned up my financial mess. Saved me $30k in 30 minutes</q>

        <footer>
            <img src={TESTIMONIAL_AVATAR_URL} alt="Shaan Puri" /> Shaan Puri
        </footer>
    </div>
);
