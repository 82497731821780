import React from "react";
import { Counterparty } from "../../../common/types/counterparty";
import {
    RuleConditionType,
    TransactionRule,
    TransactionRuleCondition,
} from "../../../common/types/transactionRule";
import { RuleCategoryCondition } from "./RuleCategoryCondition";
import { RuleAmountCondition } from "./RuleAmountCondition";
import { RuleCounterpartyCondition } from "./RuleCounterpartyCondition";
import { RuleDescriptionCondition } from "./RuleDescriptionCondition";
import { RuleFinancialAccountsCondition } from "./RuleFinancialAccountsCondition";
import { RuleEntityCondition } from "./RuleEntityCondition";
import { RuleSuggestedCategoryCondition } from "./RuleSuggestedCategoryCondition";

interface Props {
    condition: TransactionRuleCondition;
    rule: TransactionRule;
    counterparties: Counterparty[];
}

export const RuleCondition: React.FC<Props> = ({
    condition,
    rule,
    counterparties,
}) => {
    switch (condition.conditionType) {
        case RuleConditionType.CATEGORY:
            return <RuleCategoryCondition condition={condition} />;
        case RuleConditionType.SUGGESTED_CATEGORY:
            return <RuleSuggestedCategoryCondition condition={condition} />;
        case RuleConditionType.AMOUNT:
            return <RuleAmountCondition condition={condition} />;
        case RuleConditionType.COUNTERPARTY:
            return (
                <RuleCounterpartyCondition
                    condition={condition}
                    rule={rule}
                    counterparties={counterparties}
                />
            );
        case RuleConditionType.DESCRIPTION_INCLUDES:
        case RuleConditionType.DESCRIPTION_IS:
            return <RuleDescriptionCondition condition={condition} />;
        case RuleConditionType.FINANCIAL_ACCOUNTS:
            return <RuleFinancialAccountsCondition condition={condition} />;
        case RuleConditionType.ENTITY:
            return <RuleEntityCondition condition={condition} />;
    }
};
