import React from "react";
import Logo from "@static/images/logo.svg?react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ChildrenProps } from "../../../types";
import { useLogout } from "../../../hooks/useLogout";
import { Button } from "../Button/Button";
import styles from "./MobileBlock.module.scss";

interface MobileBlockProps extends ChildrenProps {
    icon: React.ReactNode;
    heading: string;
    fullscreen?: boolean;
    showHomeButton?: boolean;
    showLogoutButton?: boolean;
}

export const MobileBlock: React.FC<MobileBlockProps> = ({
    icon,
    heading,
    children,
    fullscreen,
    showHomeButton = false,
    showLogoutButton = false,
}) => {
    const logout = useLogout();

    return (
        <div
            className={classNames(styles.blocker, {
                [styles.fullscreen]: fullscreen,
            })}
        >
            <Logo className={styles.logo} />

            <div className={styles.icon}>{icon}</div>

            <h1 className={styles.heading}>{heading}</h1>

            {children}

            <div className="d-flex flex-row gap-2">
                {showHomeButton && (
                    <Button asChild variant="secondary" size="sm">
                        <Link to="/">Go home</Link>
                    </Button>
                )}

                {showLogoutButton && (
                    <Button variant="secondary" size="sm" onClick={logout}>
                        Log out
                    </Button>
                )}
            </div>
        </div>
    );
};
