import React, { useCallback } from "react";
import { Counterparty } from "../../../common/types/counterparty";
import {
    CounterpartyForm,
    FormSchema,
} from "../../counterparty/CounterpartyForm";
import {
    useCopyGlobalCounterpartyToLocalMutation,
    useUpdateCounterpartyMutation,
} from "../../../api/counterparty.api";

interface Props {
    counterparty: Counterparty;
    onEdited: (updatedCounterparty: Counterparty) => void;
    onCancelled: () => void;
}

export const EditCounterparty: React.FC<Props> = ({
    counterparty,
    onEdited,
    onCancelled,
}) => {
    const updateCounterparty = useUpdateCounterpartyMutation(
        counterparty,
        onEdited,
    );
    const copyGlobalToLocal =
        useCopyGlobalCounterpartyToLocalMutation(onEdited);
    const onSubmit = useCallback(
        async (data: FormSchema) => {
            if (counterparty.workspaceId) {
                await updateCounterparty.mutateAsync(data);
            } else {
                await copyGlobalToLocal.mutateAsync({
                    globalCounterpartyId: counterparty.id,
                    update: data,
                });
            }
        },
        [
            updateCounterparty,
            copyGlobalToLocal,
            counterparty.id,
            counterparty.workspaceId,
        ],
    );

    return (
        <CounterpartyForm
            counterparty={counterparty}
            onSubmit={onSubmit}
            onCancelled={onCancelled}
            submitText="Save"
        />
    );
};
