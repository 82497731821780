import { Entity } from "../common/types/entity";
import { useEntities } from "./useEntities";

export function useSingleBusinessEntity(
    requireAdminAccess = false,
): Entity | undefined {
    const businessEntities = useEntities({
        onlyBusiness: true,
        excludeAllMockEntities: true,
        requireAdminAccess,
    });

    return businessEntities.length === 1 ? businessEntities[0] : undefined;
}
