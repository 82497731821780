import { useEffect, useMemo } from "react";
import { ReportFilters } from "../../common/types/filters/reports";
import { DATE_RANGE_PRESETS_BY_ID } from "../../helpers/date";
import { ReportingTabCycle } from "../../common/constants";
import { useEntitiesContexts } from "../../hooks/useEntitiesContexts";
import { useToaster } from "../general/ToastMessages/useToaster";
import { useReportParams } from "./useReportParams";

const defaultFiltersLastYear: Omit<ReportFilters, "entityIds"> = {
    start: DATE_RANGE_PRESETS_BY_ID.last_year.getStart(new Date()),
    end: DATE_RANGE_PRESETS_BY_ID.last_year.getEnd(new Date()),
    cycle: ReportingTabCycle.MONTH,
};

const defaultFiltersThisYear: Omit<ReportFilters, "entityIds"> = {
    start: DATE_RANGE_PRESETS_BY_ID.this_year.getStart(new Date()),
    end: DATE_RANGE_PRESETS_BY_ID.this_year.getEnd(new Date()),
    cycle: ReportingTabCycle.MONTH,
};

export function useDefaultReportsFilters(): ReportFilters {
    const { entityId, cycle, start, end } = useReportParams();
    const currentMonth = new Date().getMonth();

    const entitiesContexts = useEntitiesContexts({
        onlyBusiness: true,
    });
    const { error } = useToaster();
    const firstEntity = entitiesContexts?.[0];
    const selectedEntity = entityId
        ? entitiesContexts?.find((ctx) => ctx.entity.id === entityId)
        : firstEntity;

    useEffect(() => {
        if (!selectedEntity) {
            error("Entity not found");
        }
    }, [error, selectedEntity]);

    const entityToUse = selectedEntity ?? firstEntity;

    return useMemo(() => {
        const defaultFilters =
            currentMonth < 4 ? defaultFiltersLastYear : defaultFiltersThisYear;
        const withoutParams = {
            ...defaultFilters,
            entityIds: entityToUse ? [entityToUse.entity.id] : [],
        };

        return {
            ...withoutParams,
            cycle: cycle ?? withoutParams.cycle,
            start: start ?? withoutParams.start,
            end: end ?? withoutParams.end,
        };
    }, [cycle, end, entityToUse, start, currentMonth]);
}
