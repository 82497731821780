import { currencyFormatter } from "../../common/helpers/currency";
import { useKeyboardCommands } from "../../hooks/keyboard/useKeyboardCommands";
import { KeyboardReturnIcon } from "../../icons";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";
import { KeyboardShortcut } from "../general/KeyboardShortcut/KeyboardShortcut";
import { ExtendedButtonProps } from "../general/Button/Button";
import actionCardStyles from "./ActionCard.module.scss";

interface Props extends ExtendedButtonProps {
    onClick: () => void;
    loading?: boolean;
    showKeyboardShortcut?: boolean;
    savings?: number;
}

export const ActionConfirmButton: React.FC<Props> = ({
    onClick,
    loading,
    showKeyboardShortcut,
    savings,
    children = "Confirm",
    tooltip,
    ...rest
}) => {
    useKeyboardCommands({
        enabled: !loading && !rest.disabled,
        commands: [
            {
                key: "Enter",
                callback: onClick,
                requiresCtrlOrMeta: false,
                repeat: false,
            },
        ],
    });

    const button = (
        <ButtonWithLoader
            className={actionCardStyles.confirmButton}
            loading={!!loading}
            onClick={onClick}
            tooltip={tooltip}
            {...rest}
        >
            {children}
            {showKeyboardShortcut && (
                <KeyboardShortcut square>
                    <KeyboardReturnIcon />
                </KeyboardShortcut>
            )}
            {!!savings && (
                <>
                    {" "}
                    <span className={actionCardStyles.savings}>
                        + {currencyFormatter.format(savings)}
                    </span>
                </>
            )}
        </ButtonWithLoader>
    );

    return button;
};
