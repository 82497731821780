import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import { PageCoverLoader } from "../general/PageCoverLoader/PageCoverLoader";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import { useUser } from "../../hooks/useUser";
import { useBillingStatus } from "../../hooks/useBillingStatus";
import { useSubscribeMutation } from "../../mutations/flatRateBilling";
import { FLAT_RATE_PLANS } from "../../common/flatRateBilling";

interface CheckoutRouteParams {
    plan: FLAT_RATE_PLANS;
}

export const Subscribe: React.FC = () => {
    const navigate = useNavigate();
    const { plan } = useParams() as Readonly<CheckoutRouteParams>;
    const [{ after: afterCheckoutUrl, code, intent: intentId }] =
        useQueryParams({
            after: StringParam,
            code: withDefault(StringParam, undefined),
            intent: StringParam,
        });

    const { onboardingComplete } = useUser();
    const { query } = useBillingStatus();
    const afterCheckout = useCallback(async () => {
        await query.refetch();
        if (onboardingComplete) {
            navigate(afterCheckoutUrl ?? "/settings/billing");
        } else {
            navigate("/onboarding");
        }
    }, [afterCheckoutUrl, navigate, onboardingComplete, query]);
    const subscribeMutation = useSubscribeMutation(
        {
            plan,
            code,
            intentId: intentId!,
        },
        afterCheckout,
    );

    useEffectOnce(() => {
        subscribeMutation.mutate();
    });

    return (
        <PageCoverLoader>
            {onboardingComplete
                ? "Starting autopilot"
                : "Let's set up your account"}
        </PageCoverLoader>
    );
};
