import React, { useCallback, useMemo, useState, memo } from "react";
import { useQuery } from "@tanstack/react-query";
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";
import { FLAT_RATE_PLANS } from "../../../common/flatRateBilling";
import { StandardModal } from "../../general/Modal/Modal";
import { useLoadStripe } from "../../settings/Billing/useLoadStripe";
import { prepareCheckout } from "../../../lib/flatRateBilling";
import { useCanManageBilling } from "../../../hooks/useCanManageBilling";
import { trackEvent } from "../../../lib/analytics";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import { ChildrenProps } from "../../../types";
import { ConvertTrialModal } from "./ConvertTrialModal";
import { DowngradeModalContent } from "./DowngradeModal/DowngradeModalContent";
import styles from "./PlanManagement.module.scss";
import {
    planManagementContext,
    PlanManagementContextValue,
} from "./PlanManagementContext";
import { UpgradeSubscriptionModal } from "./UpgradeSubscriptionModal/UpgradeSubscriptionModal";

interface UpgradeModalOptions {
    plan: FLAT_RATE_PLANS;
    isTrial: boolean;
}

interface PlanManagementProviderProps extends ChildrenProps {
    onBeforeUpgrade?(plan: FLAT_RATE_PLANS, trial: boolean): void;
    onBeforeDowngrade?(plan: FLAT_RATE_PLANS): void;
}

export const PlanManagementProvider = memo(
    ({
        children,
        onBeforeDowngrade,
        onBeforeUpgrade,
    }: PlanManagementProviderProps) => {
        const stripePromise = useLoadStripe();
        const canManageBilling = useCanManageBilling();
        const { isSubscribed } = useBillingStatus();
        const location = useLocation();

        const { data: checkoutData, isFetched } = useQuery({
            queryKey: ["prepareCheckout"],
            queryFn: prepareCheckout,
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            enabled: canManageBilling,
        });

        const beforeUpgrade: PlanManagementProviderProps["onBeforeUpgrade"] =
            useMemo(
                () =>
                    onBeforeUpgrade ??
                    ((plan) => {
                        void trackEvent(
                            isSubscribed
                                ? "plan_upgrade_initiated"
                                : "trial_start_initiated",
                            {
                                product: plan,
                                path: location.pathname,
                            },
                        );
                    }),
                [isSubscribed, location.pathname, onBeforeUpgrade],
            );

        const beforeDowngrade: PlanManagementProviderProps["onBeforeDowngrade"] =
            useMemo(
                () =>
                    onBeforeDowngrade ??
                    ((plan) => {
                        void trackEvent("plan_downgrade_initiated", {
                            product: plan,
                            path: location.pathname,
                        });
                    }),
                [location.pathname, onBeforeDowngrade],
            );

        const [downgradeModal, setDowngradeModal] =
            useState<FLAT_RATE_PLANS | null>(null);

        const [upgradeModal, setUpgradeModal] =
            useState<UpgradeModalOptions | null>(null);

        const [convertModal, setConvertModal] = useState<boolean>(false);

        const openUpgradeModal = useCallback(
            (plan: FLAT_RATE_PLANS, isTrial: boolean) => {
                beforeUpgrade(plan, isTrial);
                setUpgradeModal({ plan, isTrial });
            },
            [beforeUpgrade],
        );

        const openDowngradeModal = useCallback(
            (plan: FLAT_RATE_PLANS) => {
                beforeDowngrade(plan);
                setDowngradeModal(plan);
            },
            [beforeDowngrade],
        );

        const openConvertModal = useCallback(() => {
            setConvertModal(true);
        }, []);

        const value: PlanManagementContextValue = useMemo(
            () => ({
                downgrade: openDowngradeModal,
                upgrade: openUpgradeModal,
                convert: openConvertModal,
            }),
            [openConvertModal, openDowngradeModal, openUpgradeModal],
        );

        const stripeOptions: StripeElementsOptions = useMemo(
            () => ({
                clientSecret: checkoutData?.clientSecret,
                appearance: {
                    variables: {
                        spacingUnit: "4px",
                        spacingGridRow: "24px",
                        spacingGridColumn: "32px",
                        fontFamily: `"Inter", sans-serif`,
                        colorText: "#515D71", // gray-700,
                        colorTextSecondary: "#515D71", // gray-700,
                        colorDangerText: "#ec535a", // red-500,
                    },
                    rules: {
                        ".Input": {
                            color: "#0F1826", // gray-900,
                        },
                    },
                },
                loader: "always",
            }),
            [checkoutData?.clientSecret],
        );

        const handleUpgradeModalHide = useCallback(() => {
            if (upgradeModal) {
                void trackEvent("onboarding_payment_canceled", {
                    plan: upgradeModal.plan,
                });
            }
            setUpgradeModal(null);
        }, [upgradeModal]);

        return (
            <planManagementContext.Provider value={value}>
                {children}

                <StandardModal
                    size="xl"
                    show={!!upgradeModal}
                    onHide={handleUpgradeModalHide}
                    className={styles.planManagementModal}
                >
                    {upgradeModal && isFetched && (
                        <Elements
                            stripe={stripePromise}
                            options={stripeOptions}
                        >
                            <UpgradeSubscriptionModal
                                {...upgradeModal}
                                intentId={checkoutData?.intentId}
                            />
                        </Elements>
                    )}
                </StandardModal>

                <StandardModal
                    size="xl"
                    show={convertModal}
                    onHide={() => setConvertModal(false)}
                    className={styles.planManagementModal}
                >
                    {convertModal && isFetched && (
                        <Elements
                            stripe={stripePromise}
                            options={stripeOptions}
                        >
                            <ConvertTrialModal
                                intentId={checkoutData?.intentId}
                            />
                        </Elements>
                    )}
                </StandardModal>

                <StandardModal
                    size="lg"
                    show={!!downgradeModal}
                    onHide={() => setDowngradeModal(null)}
                    className={styles.planChangeModal}
                >
                    {downgradeModal && (
                        <DowngradeModalContent
                            downgradingToPlan={downgradeModal}
                            onHide={() => setDowngradeModal(null)}
                        />
                    )}
                </StandardModal>
            </planManagementContext.Provider>
        );
    },
);
