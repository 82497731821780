import React from "react";
import { useField } from "formik";
import { TransactionRuleConditionFormSchema } from "../constants";
import { TransactionDirectionType } from "../../../../../common/categories";
import { CategorySelectSimple } from "../../../../transactions/CategorySelect/CategorySelectSimple";
import { Category } from "../../../../../common/types/category";

interface Props {
    condition: TransactionRuleConditionFormSchema;
    index: number;
    showPersonal: boolean;
}

export const CategoryConditionForm: React.FC<Props> = ({
    condition,
    index,
    showPersonal,
}) => {
    const [field, , helpers] = useField<
        TransactionRuleConditionFormSchema["categoryValue"]
    >(`conditions.${index}.categoryValue`);

    return (
        <CategorySelectSimple
            size="xs"
            onSelected={(value) => helpers.setValue(value as Category)}
            value={field.value}
            dropdownKey={`ruleCategory${condition.id}`}
            transactionDirection={TransactionDirectionType.mixed}
            hideCustomCategories
            showPersonal={showPersonal}
        />
    );
};
