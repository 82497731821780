import React from "react";
import { CampaignActivityProps } from "../../ActionActivityItem";
import { ActionActivity } from "../../ActionActivity";
import { ActivityIcon } from "../../../../icons";
import { pluralize } from "../../../../common/helpers/string";

export const CategorizedTransactionsActivity: React.FC<
    CampaignActivityProps
> = ({ animated, count }) => (
    <ActionActivity icon={<ActivityIcon />} animated={animated}>
        Categorized {count} {pluralize("transaction", count)}
    </ActionActivity>
);
