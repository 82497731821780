import React, { useState } from "react";
import classNames from "classnames";
import { Amount } from "../general/Amount/Amount";
import { PopoverContainer } from "../general/PopoverContainer";
import { TriangleIcon } from "../../icons";
import { Separator } from "../general/Separator/Separator";
import styles from "./TransactionsTotals.module.scss";

export interface TransactionSavingsProps {
    count: number;
    cashIn: number;
    cashOut: number;
    total: number;
    average: number;
}

export const TransactionsTotals: React.FC<TransactionSavingsProps> = ({
    count,
    cashIn,
    cashOut,
    total,
    average,
}) => {
    const [isShown, setIsShown] = useState(false);

    return (
        <PopoverContainer
            id="transactions-totals-popover"
            popoverClass={classNames("no-padding", styles.popoverContainer)}
            onShow={() => setIsShown(true)}
            onHide={() => setIsShown(false)}
            trigger={
                <div
                    className={classNames(styles.triggerButton, {
                        [styles.active]: isShown,
                    })}
                >
                    <span className="d-block medium text-gray-700">
                        Total:{" "}
                        <Amount
                            amount={total}
                            showNegative
                            className={classNames(total > 0 && styles.blueText)}
                        />
                    </span>
                    <TriangleIcon />
                </div>
            }
            placement="top"
            offset={4}
        >
            <div className={classNames(styles.line, styles.smallPadding)}>
                <span>Cash in</span>
                <Amount
                    amount={cashIn}
                    className={classNames(cashIn > 0 && styles.blueText)}
                />
            </div>
            <div className={styles.line}>
                <span>Cash out</span>
                <Amount amount={cashOut} showNegative />
            </div>
            <Separator />
            <div className={styles.line}>
                <span>Total</span>
                <Amount
                    amount={total}
                    showNegative
                    className={classNames(total > 0 && styles.blueText)}
                />
            </div>
            <Separator />
            <div className={classNames(styles.line, styles.smallPadding)}>
                <span>Transactions</span>
                <span>{count}</span>
            </div>
            <div className={styles.line}>
                <span>Average</span>
                <Amount amount={average} />
            </div>
        </PopoverContainer>
    );
};
