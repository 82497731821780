import {
    EntityProfileIndustry,
    EntityProfileType,
    EntityType,
    FirmSize,
    ProfessionalType,
} from "../../common/types/entity";

export const entityTypeLabels: Record<EntityType, string> = {
    [EntityType.NOT_REGISTERED]: "Not registered (Sole proprietorship)",
    [EntityType.LLC]: "LLC",
    [EntityType.LLC_WITH_S_CORP]: "LLC with S Corp Election",
    [EntityType.C_CORP_WITH_S_CORP]: "C Corporation with S Corp Election",
    [EntityType.C_CORP]: "C Corporation",
} as const;

export const entityTypeV2Labels = {
    [EntityProfileType.C_CORP]: "C Corporation",
    [EntityProfileType.LLC]: "LLC",
    [EntityProfileType.SMLLC]: "LLC (sole owner)",
    [EntityProfileType.MMLLC]: "LLC (multiple owners)",
    [EntityProfileType.NO_LEGAL_ENTITY]: "I don't have a US legal entity",
    [EntityProfileType.PARTNERSHIP]: "Partnership (including LLPs)",
    [EntityProfileType.S_CORP]: "S Corporation",
    [EntityProfileType.SOLE_PROPRIETORSHIP]: "Sole Proprietorship",
} as const satisfies Record<EntityProfileType, string>;

export const industryLabels = {
    [EntityProfileIndustry.ACCOUNTANT]: "Accountant",
    [EntityProfileIndustry.AGENCY]: "Agency",
    [EntityProfileIndustry.BOOKKEEPER]: "Bookkeeper",
    [EntityProfileIndustry.CONSTRUCTION]: "Construction",
    [EntityProfileIndustry.CONSULTING]: "Consulting/Services",
    [EntityProfileIndustry.CONTENT]: "Content",
    [EntityProfileIndustry.CRYPTO]: "Crypto",
    [EntityProfileIndustry.TRUCKING]: "Driver/Trucking",
    [EntityProfileIndustry.FINANCIAL_ADVISOR]: "Financial Advisor",
    [EntityProfileIndustry.INVESTOR]: "Investor",
    [EntityProfileIndustry.LEGAL]: "Legal",
    [EntityProfileIndustry.MANUFACTURING]: "Manufacturing",
    [EntityProfileIndustry.MEDICAL]: "Medical",
    [EntityProfileIndustry.NON_PROFIT]: "Non-Profit",
    [EntityProfileIndustry.PHYSICAL_GOODS]: "Physical Goods",
    [EntityProfileIndustry.PROPERTY_MANAGEMENT]: "Property Management",
    [EntityProfileIndustry.PSYCHOLOGIST]: "Psychologist/Therapist",
    [EntityProfileIndustry.REAL_ESTATE_AGENT]: "Real Estate Agent",
    [EntityProfileIndustry.REAL_ESTATE_INVESTOR]: "Real Estate Investor",
    [EntityProfileIndustry.RETAIL]: "Retail",
    [EntityProfileIndustry.SAAS]: "SaaS",
    [EntityProfileIndustry.TRADEPERSON]:
        "Tradesperson (HVAC, Electrician, Plumber)",
    [EntityProfileIndustry.ENTERTAINMENT]: "TV/Film/Entertainment",
    [EntityProfileIndustry.RESTAURANT]: "Restaurant",
    [EntityProfileIndustry.OTHER]: "Other",
} as const satisfies Record<EntityProfileIndustry, string>;

export const professionalTypeLabels = {
    [ProfessionalType.BOOKKEEPER]: "Bookkeeper",
    [ProfessionalType.CORPORATE_ACCOUNTANT]: "Corporate Accountant",
    [ProfessionalType.FIRM_ACCOUNTANT]: "Firm Accountant",
    [ProfessionalType.TAX_ADVISOR]: "Tax Advisor",
} as const satisfies Record<ProfessionalType, string>;

export const firmSizeLabels = {
    [FirmSize["0_5"]]: "0 - 5",
    [FirmSize["6_9"]]: "6 - 9",
    [FirmSize["10_20"]]: "10 - 20",
    [FirmSize["21_49"]]: "21 - 49",
    [FirmSize["50_PLUS"]]: "50+",
} as const satisfies Record<FirmSize, string>;
