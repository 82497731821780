import React, { useCallback, useMemo, useState } from "react";
import { keyBy, omit, omitBy } from "lodash";
import { FinancialDocumentFilters } from "../FinancialDocumentsPageFilters/lib";
import { FinancialDocument } from "../../../common/types/financialDocument";
import { ChildrenProps } from "../../../types";
import {
    financialDocumentBulkSelectionContext,
    FinancialDocumentBulkSelectionContextValue as ValueType,
} from "./financialDocumentBulkSelectionContext";

interface Props extends ChildrenProps {
    totalCount: number;
    currentFilters: FinancialDocumentFilters;
}

export const FinancialDocumentBulkSelectionContextProvider: React.FC<Props> = ({
    totalCount,
    currentFilters,
    children,
}) => {
    const [selected, setSelected] = useState<
        Record<FinancialDocument["id"], FinancialDocument>
    >({});

    const [filtersSelection, setFiltersSelection] =
        useState<FinancialDocumentFilters | null>(null);

    const selectedArray = useMemo(
        () => (filtersSelection ? [] : Object.values(selected)),
        [filtersSelection, selected],
    );

    const isSelected = useCallback(
        (financialDocument: FinancialDocument) =>
            financialDocument.id in selected,
        [selected],
    );

    const select: ValueType["select"] = useCallback(
        (financialDocumentOrDocuments) => {
            const selectionUpdate: Record<number, FinancialDocument> =
                Array.isArray(financialDocumentOrDocuments)
                    ? keyBy(financialDocumentOrDocuments, "id")
                    : {
                          [financialDocumentOrDocuments.id]:
                              financialDocumentOrDocuments,
                      };

            setSelected((prev) => ({ ...prev, ...selectionUpdate }));
        },
        [],
    );

    const deselect: ValueType["deselect"] = useCallback(
        (financialDocumentOrDocuments) => {
            if (Array.isArray(financialDocumentOrDocuments)) {
                const dict = keyBy(financialDocumentOrDocuments, "id");
                setSelected((prev) =>
                    omitBy(prev, (value) => value.id in dict),
                );
            } else {
                setSelected((prev) =>
                    omit(prev, financialDocumentOrDocuments.id),
                );
            }
        },
        [],
    );

    const clearSelection = useCallback(() => {
        setSelected({});
        setFiltersSelection(null);
    }, []);

    const selectByCurrentFilters = useCallback(() => {
        clearSelection();
        setFiltersSelection(currentFilters);
    }, [clearSelection, currentFilters]);

    const value: ValueType = useMemo(
        () => ({
            totalCount,
            selected: selectedArray,
            selectedCount: filtersSelection ? totalCount : selectedArray.length,
            filtersSelection,
            hasSelectedByCurrentFilters: !!filtersSelection,
            select,
            deselect,
            clearSelection,
            selectAll: selectByCurrentFilters,
            isSelected,
        }),
        [
            clearSelection,
            deselect,
            filtersSelection,
            isSelected,
            select,
            selectByCurrentFilters,
            selectedArray,
            totalCount,
        ],
    );

    return (
        <financialDocumentBulkSelectionContext.Provider value={value}>
            {children}
        </financialDocumentBulkSelectionContext.Provider>
    );
};
