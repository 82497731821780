import React, { useCallback } from "react";
import { useField } from "formik";
import { FormCheckbox } from "../../../forms/FormCheckbox/FormCheckbox";

export const ApplyRuleToExistingTransactions: React.FC = () => {
    const [field, , helpers] = useField<boolean>("applyToExistingTransactions");
    const [, , { setValue: setExcludedTransactions }] = useField<number[]>(
        "excludedTransactionsIds",
    );

    const toggle = useCallback(() => {
        helpers.setValue(!field.value);
        setExcludedTransactions([]);
    }, [field.value, helpers, setExcludedTransactions]);

    return (
        <FormCheckbox
            value="applyToExistingTransactions"
            isChecked={!field.value}
            handleChange={toggle}
            label="Only apply going forward"
            small
        />
    );
};
