import React, { useEffect } from "react";
import { useTimer } from "../../../../hooks/useTimer";
import { ActionCloseButton } from "../../../actions/ActionCloseButton";
import animations from "../../layout/OnboardingLayout.module.scss";
import commonStyles from "../common.module.scss";
import styles from "./Interlude.module.scss";
import { DemoActionProps } from "./lib";

const DURATION = 5_000;

export const Interlude: React.FC<DemoActionProps> = ({
    opened,
    onCardClosed,
}) => {
    const { start, clear } = useTimer(() => onCardClosed?.(), DURATION);

    useEffect(() => {
        if (opened) {
            start();
        } else {
            clear();
        }

        return clear;
    }, [clear, opened, start]);

    if (opened) {
        return (
            <div className={styles.interlude}>
                <h1 className={`${commonStyles.title} m-0`}>
                    Ready to automate your books?
                </h1>

                <p className={`${commonStyles.subtitle} m-0`}>
                    Let’s get started by connecting your accounts...
                </p>

                <ActionCloseButton
                    onClick={() => {
                        clear();
                        onCardClosed?.();
                    }}
                    className={animations.fadeIn2}
                    duration={DURATION}
                >
                    Next
                </ActionCloseButton>
            </div>
        );
    }

    return null;
};
