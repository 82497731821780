import React from "react";
import { TransactionRule } from "../../../common/types/transactionRule";
import { CategoryIcon } from "../../transactions/CategoryIcon";
import styles from "./RuleCategoryAction.module.scss";

interface Props {
    rule: TransactionRule;
}

export const RuleCategoryAction: React.FC<Props> = ({ rule }) => {
    const categoryLabel = rule.category?.label;
    const categoryId = rule.categoryId;

    if (!categoryLabel || !categoryId) {
        return null;
    }

    return (
        <>
            Update Category to{" "}
            <span className={styles.category}>
                <CategoryIcon categoryId={categoryId} />{" "}
                <strong>{categoryLabel}</strong>
            </span>
            .
        </>
    );
};
