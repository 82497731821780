import React from "react";
import colors from "@styles/colors.module.scss";
import { pluralize } from "../../../common/helpers/string";
import { ObjectIcon } from "../../general/ObjectIcon/ObjectIcon";
import { Class } from "../../../common/types/class";
import { ClassObjectIcon } from "../ClassObjectIcon";
import { IconWithTooltip } from "./IconWithTooltip";

import styles from "./ClassIconList.module.scss";

export interface ClassIconListProps {
    classes: Class[];
    maxClassesToDisplay?: number;
    label?: string;
    showLabel?: boolean;
    onClassClick?: (classId: string) => void;
}

export const ClassIconList: React.FC<ClassIconListProps> = ({
    classes,
    maxClassesToDisplay = 4,
    label,
    showLabel,
    onClassClick,
}) => {
    const shouldAggregate = classes.length > maxClassesToDisplay;

    const displayedLabel = (
        <span className="ml-2">
            {label ?? (
                <>
                    {classes.length} {pluralize("Class", classes.length)}
                </>
            )}
        </span>
    );

    const sortedClassesByOrder = [...classes].sort((a, b) => a.order - b.order);

    if (shouldAggregate) {
        const classesToShow = sortedClassesByOrder.slice(
            0,
            maxClassesToDisplay - 1,
        );
        const classesToHide = classes.slice(maxClassesToDisplay - 1);

        return (
            <span className={styles.list}>
                {classesToShow.map((c) => (
                    <button key={c.id} onClick={() => onClassClick?.(c.id)}>
                        <IconWithTooltip
                            tooltipId={`filter-class-${c.id}`}
                            tooltip={c.label}
                        >
                            <ClassObjectIcon class={c} />
                        </IconWithTooltip>
                    </button>
                ))}

                <IconWithTooltip
                    tooltipId={`filter-class-more`}
                    tooltip={classesToHide.map((c) => (
                        <React.Fragment key={c.id}>
                            {c.label} <br />
                        </React.Fragment>
                    ))}
                >
                    <ObjectIcon
                        defaultColor="white"
                        border={colors.miscAlpha200}
                        size="xs"
                        rhombus
                    >
                        +{classesToHide.length}
                    </ObjectIcon>
                </IconWithTooltip>

                {showLabel && displayedLabel}
            </span>
        );
    } else {
        return (
            <span className={styles.list}>
                {classes.map((c) => (
                    <button
                        key={c.id}
                        className={styles.stylelessButton}
                        onClick={() => onClassClick?.(c.id)}
                    >
                        <IconWithTooltip
                            tooltipId={`filter-class-${c.id}`}
                            tooltip={c.label}
                        >
                            <ClassObjectIcon class={c} />
                        </IconWithTooltip>
                    </button>
                ))}
                {showLabel && displayedLabel}
            </span>
        );
    }
};
