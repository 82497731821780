import React, { useRef, useState } from "react";
import { useField } from "formik";
import classNames from "classnames";
import {
    TransactionRuleConditionFormSchema,
    DescriptionConditionType,
} from "../constants";
import { CustomSelect } from "../../../../forms/CustomSelect/CustomSelect";
import { Tag } from "../../../../general/Tag/Tag";
import styles from "../RuleForm.module.scss";
import { CloseIcon } from "../../../../../icons";
import { Button } from "../../../../general/Button/Button";
import { useInteraction } from "../../../../../hooks/useInteraction";

interface Props {
    condition: TransactionRuleConditionFormSchema;
    index: number;
}

const conditionTypesOptions = Object.values(DescriptionConditionType).map(
    (v) => ({ value: v }),
);

export const BankDescriptionConditionForm: React.FC<Props> = ({
    condition,
    index,
}) => {
    const [conditionTypeField, , conditionTypeHelpers] = useField<
        TransactionRuleConditionFormSchema["descriptionConditionType"]
    >(`conditions.${index}.descriptionConditionType`);
    const [valueField, , valueHelpers] = useField<
        TransactionRuleConditionFormSchema["descriptionValue"]
    >(`conditions.${index}.descriptionValue`);
    const [newValue, setNewValue] = useState("");
    const newValueInput = useRef<HTMLInputElement>(null);

    const addNewValue = () => {
        const finalValue = newValue.trim();
        const currentValue = valueField.value ?? [];

        if (finalValue && !currentValue.includes(finalValue)) {
            valueHelpers.setValue([...currentValue, finalValue]);
        }
        setNewValue("");
    };

    const removeValue = (value: string) => {
        valueHelpers.setValue(valueField.value?.filter((v) => v !== value));
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            addNewValue();
            e.preventDefault();
        }
    };

    const focusInputInteraction = useInteraction(() =>
        newValueInput.current?.focus(),
    );

    return (
        <>
            <CustomSelect
                value={conditionTypeField.value}
                onSelected={(v) => conditionTypeHelpers.setValue(v)}
                dropdownKey={`descriptionConditionType${condition.id}`}
                placeholder="Select type"
                size="xs"
                options={conditionTypesOptions}
            />
            <div
                className={classNames(
                    "form-control",
                    "form-control-xs",
                    styles.descriptionsList,
                )}
                {...focusInputInteraction}
            >
                {valueField.value?.map((item) => (
                    <Tag key={item}>
                        {item}
                        <Button
                            variant="tertiary"
                            icon
                            onClick={() => removeValue(item)}
                            size="sm"
                        >
                            <CloseIcon />
                        </Button>
                    </Tag>
                ))}
                <input
                    value={newValue}
                    onChange={(e) => setNewValue(e.target.value)}
                    onBlur={() => addNewValue()}
                    onKeyDown={onKeyDown}
                    ref={newValueInput}
                />
            </div>
        </>
    );
};
