import {
    AccountClass as AccountClassV2,
    AccountSubtype as AccountSubtypeV2,
    AccountType as AccountTypeV2,
} from "../../../constants/domains/accounting/accountsv2";
import {
    AccountClass as AccountClassV1,
    AccountSubtype as AccountSubtypeV1,
    AccountType as AccountTypeV1,
} from "../../../constants/domains/accounting/accountsv1";

export interface Account {
    id: string;
    code: number;
    name: string;
    class: AccountClassV2 | AccountClassV1;
    type: AccountTypeV2 | AccountTypeV1;
    subtype: AccountSubtypeV2 | AccountSubtypeV1;
    isDisabled: boolean;
    isManuallyCreated: boolean;
    financialAccountId: number | null;
}

export enum AccountStatus {
    ENABLED = "enabled",
    DISABLED = "disabled",
}
