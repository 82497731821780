import { Taxonomy } from "../categories";
import {
    CounterpartyDirection,
    CounterpartyDirectionLabels,
    getCounterpartyDirectionForTaxonomy,
} from "./counterparties";

export const DEFAULT_LABEL_FOR_COUNTERPARTY_CONDITION =
    CounterpartyDirectionLabels[CounterpartyDirection.VENDOR];

export function getCounterpartyRuleConditionLabel(
    usedTaxonomy: Taxonomy | undefined,
) {
    if (usedTaxonomy) {
        return CounterpartyDirectionLabels[
            getCounterpartyDirectionForTaxonomy(usedTaxonomy)
        ];
    }

    return DEFAULT_LABEL_FOR_COUNTERPARTY_CONDITION;
}
