import React, { useState } from "react";
import { useWindowMessage } from "../../hooks/useWindowMessage";
import {
    OAuthAuthorizationResponse,
    triggerAuthorization,
} from "../../lib/oauth";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";
import { OAuthServiceType } from "../../common/constants";
import { useFixMercuryConnectionsMutation } from "../../mutations/mercuryConnection";
import { useInteraction } from "../../hooks/useInteraction";
import { FinancialConnection } from "../../common/types/financialConnection";
import { ExtendedButtonProps } from "../general/Button/Button";

interface Props extends Omit<ExtendedButtonProps, "onClick"> {
    connection: FinancialConnection;
    onFixed?: () => void;
    children?: React.ReactNode;
}
export const FixMercuryConnection: React.FC<Props> = ({
    connection,
    children = <>Connect</>,
    onFixed,
    ...btnProps
}) => {
    const mutation = useFixMercuryConnectionsMutation(onFixed);
    const [fixing, setFixing] = useState(false);
    const handler = ({ code }: OAuthAuthorizationResponse) => {
        if (fixing) {
            mutation.mutate({ code, connection });
        }
        setFixing(false);
    };

    useWindowMessage<OAuthAuthorizationResponse>({
        scope: OAuthServiceType.MERCURY,
        handler,
    });

    const authorize = useInteraction(() => {
        triggerAuthorization(OAuthServiceType.MERCURY);
        setFixing(true);
    });

    return (
        <span {...authorize}>
            <ButtonWithLoader loading={mutation.isPending} {...btnProps}>
                {children}
            </ButtonWithLoader>
        </span>
    );
};
