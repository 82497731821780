import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { isEqual, omitBy } from "lodash";
import { FinancialDocument } from "../../../common/types/financialDocument";
import {
    FiltersChangeFn,
    TransactionsFilters,
} from "../../transactions/filters/lib";
import {
    FinancialDocumentDetailsView,
    useFinancialDocumentDetailsView,
} from "./useFinancialDocumentDetailsView";
import { FinancialDocumentDetailsHeader } from "./FinancialDocumentDetailsHeader";
import { MatchingViewHeader } from "./MatchingView/MatchingViewHeader";
import styles from "./FinancialDocumentDetails.module.scss";
import { FinancialDocumentPreview } from "./FinancialDocumentPreview";
import { MatchingView } from "./MatchingView/MatchingView";
import { FinancialDocumentMatches } from "./FinancialDocumentMatches/FinancialDocumentMatches";

interface FinancialDocumentDetailsProps {
    financialDocument: FinancialDocument;
}

export const FinancialDocumentDetails: React.FC<
    FinancialDocumentDetailsProps
> = ({ financialDocument }) => {
    const [transactionFilters, setTransactionFilters] =
        useState<TransactionsFilters>({});

    const handleFiltersChange: FiltersChangeFn = useCallback(
        (value: Partial<TransactionsFilters>) => {
            const newFilters = omitBy(
                { ...transactionFilters, ...value },
                (v) => v === null || v === undefined,
            );

            if (!isEqual(newFilters, transactionFilters)) {
                setTransactionFilters(newFilters);
            }
        },
        [transactionFilters],
    );

    const { currentView, go } = useFinancialDocumentDetailsView();

    let content = null;
    let header = null;

    if (currentView === FinancialDocumentDetailsView.DEFAULT) {
        header = (
            <FinancialDocumentDetailsHeader
                financialDocument={financialDocument}
            />
        );
        content = (
            <FinancialDocumentMatches financialDocument={financialDocument} />
        );
    } else if (currentView === FinancialDocumentDetailsView.MATCHING) {
        header = (
            <MatchingViewHeader
                onBack={() => go(FinancialDocumentDetailsView.DEFAULT)}
            />
        );
        content = (
            <MatchingView
                financialDocument={financialDocument}
                filters={transactionFilters}
                onFiltersChange={handleFiltersChange}
                clearFilters={() => setTransactionFilters({})}
            />
        );
    }

    return (
        <div
            className={classNames(styles.layout, {
                [styles.defaultView]:
                    currentView === FinancialDocumentDetailsView.DEFAULT,
                [styles.matchingView]:
                    currentView === FinancialDocumentDetailsView.MATCHING,
            })}
        >
            <div className={styles.previewArea}>
                <FinancialDocumentPreview
                    financialDocument={financialDocument}
                />
            </div>
            <div className={styles.headerArea}>{header}</div>
            <div className={styles.contentArea}>{content}</div>
        </div>
    );
};
