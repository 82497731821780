import { useMediaQuery } from "react-responsive";

export function useLargeDesktopView() {
    return useMediaQuery({ minWidth: 1720 });
}

export function useDesktopView() {
    return useMediaQuery({ minWidth: 1200 });
}

export function useMobileView() {
    return useMediaQuery({ maxWidth: 1200 });
}

export function useMobileBlockScreen() {
    return useMediaQuery({ maxWidth: 800 });
}
