import { useCallback, useState } from "react";
import { useClasses, useUpdateClassMutation } from "../../../../api/class.api";
import { checkClassLabelConflict } from "../../../../helpers/class";
import { Class } from "../../../../common/types/class";
import { FormRowRaw } from "../../../forms/FormRowRaw";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import { Button } from "../../../general/Button/Button";

import { StandardModalHeader } from "../../../general/Modal/Modal";

export const RenameClassModal = ({
    classInstance,
    onHide,
}: {
    classInstance: Class;
    onHide: () => void;
}) => {
    const [renameClassName, setRenameClassName] = useState(classInstance.label);
    const updateClass = useUpdateClassMutation();
    const { classes } = useClasses();
    const [error, setError] = useState<string | null>(null);

    const onChange = (value: string) => {
        setRenameClassName(value);

        const { conflict, conflictingClass } = checkClassLabelConflict({
            classes,
            parentClassId: classInstance.parentClassId,
            label: value,
            targetClassId: classInstance.id,
        });
        if (conflict) {
            if (conflictingClass?.id === classInstance.parentClassId) {
                setError("Subclass can't have the parent's name");
            } else if (conflictingClass?.parentClassId) {
                setError(
                    "Subclass with this name already exists in this class",
                );
            } else {
                setError("Class with this name already exists");
            }
        } else {
            setError(null);
        }
    };

    const onRenameClick = useCallback(async () => {
        await updateClass.mutateAsync({
            params: {
                id: classInstance.id,
            },
            body: {
                label: renameClassName,
            },
        });
        onHide();
    }, [classInstance.id, onHide, renameClassName, updateClass]);

    return (
        <>
            <StandardModalHeader>Rename class</StandardModalHeader>

            <FormRowRaw
                autoFocus
                label="Class name"
                value={renameClassName}
                size="sm"
                showErrorMessage={true}
                onChange={onChange}
                error={error}
            />

            <footer className="d-flex justify-content-end gap-2">
                <Button
                    variant="secondary"
                    onClick={onHide}
                    size="lg"
                    data-testid="confirmation-modal-no-button"
                >
                    Cancel
                </Button>

                <ButtonWithLoader
                    variant="default"
                    loading={updateClass.isPending}
                    onClick={onRenameClick}
                    size="lg"
                    data-testid="confirmation-modal-yes-button"
                    disabled={
                        !!error || classInstance.label === renameClassName
                    }
                >
                    Rename
                </ButtonWithLoader>
            </footer>
        </>
    );
};
