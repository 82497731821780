import { useCallback } from "react";
import { clearAccessToken, getWebAuth } from "../lib/auth0";
import { useWorkspaceContext } from "../state/workspaceContext";
import { intercom } from "../lib/intercom";
import { getAnalytics } from "../lib/analytics";
import { clearUser } from "../helpers/auth";
import { useClearWorkspaceUserCache } from "./useClearWorkspaceUserCache";

export function useLogout() {
    const { changeActiveWorkspace } = useWorkspaceContext();
    const clearAllWorkspaceCache = useClearWorkspaceUserCache();

    return useCallback(() => {
        intercom("shutdown");
        clearAccessToken();
        getWebAuth().logout({ returnTo: window.location.origin });
        getAnalytics()?.reset();
        clearAllWorkspaceCache();
        void changeActiveWorkspace(null);
        clearUser();
    }, [changeActiveWorkspace, clearAllWorkspaceCache]);
}
