import { createContext } from "react";
import { noop } from "../../helpers/general";
import { CommandsDirectory, CommandsOptions } from "./lib";

export interface CommandsContext {
    isOpen: boolean;
    open(directory?: CommandsDirectory, options?: CommandsOptions): void;
    close(): void;
}

export const commandsContext = createContext<CommandsContext>({
    isOpen: false,
    open: noop,
    close: noop,
});
