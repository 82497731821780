import React, { useState } from "react";
import { Formik, FormikConfig } from "formik";
import { object, string } from "yup";
import { useUpdateUserMutation } from "../../../mutations/user";
import { submitHelper } from "../../../helpers/form";
import { InviteProfile } from "../steps/InviteProfile";
import { ProductPreview } from "../components/ProductPreview/ProductPreview";
import { OnboardingLayout } from "../layout/OnboardingLayout";
import { Entity } from "../../../common/types/entity";

interface FormSchema {
    firstName: string;
    lastName: string;
}

interface InviteFlowProps {
    entity?: Entity;
}

export const InviteFlow: React.FC<InviteFlowProps> = ({ entity }) => {
    const [loading, setLoading] = useState(false);
    const updateUserMutation = useUpdateUserMutation();

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            firstName: "",
            lastName: "",
        },
        validationSchema: object().shape({
            firstName: string().required("First name is required"),
            lastName: string().required("Last name is required"),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async ({ firstName, lastName }) => {
                await updateUserMutation.mutateAsync({
                    preferredName: `${firstName} ${lastName}`,
                });
            },
        }),
    };

    return (
        <Formik {...form}>
            <OnboardingLayout
                currentStep="inviteProfile"
                main={<InviteProfile loading={loading} />}
                sidebar={<ProductPreview entity={entity} />}
            />
        </Formik>
    );
};
