import { useQuery } from "@tanstack/react-query";
import { ActionCategory } from "../common/types/actions";
import { getSkippedActions } from "../lib/actions";
import { queryClient } from "../queryClient";
import { useWorkspaceContext } from "../state/workspaceContext";

export const SKIPPED_ACTIONS_QUERY_KEY = "skippedActions";

export function useSkippedActions(category: ActionCategory) {
    const { activeWorkspace } = useWorkspaceContext();

    return useQuery({
        queryKey: [SKIPPED_ACTIONS_QUERY_KEY, activeWorkspace?.id, category],
        queryFn: () =>
            activeWorkspace
                ? getSkippedActions(activeWorkspace.id, category)
                : { actions: [] },

        refetchOnWindowFocus: false,
    });
}

export function invalidateSkippedActions(
    workspaceId: string,
    category: ActionCategory,
) {
    queryClient.invalidateQueries({
        queryKey: [SKIPPED_ACTIONS_QUERY_KEY, workspaceId, category],
    });
}
