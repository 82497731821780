import { useEffect } from "react";
import { isEventFromForm } from "./helpers";
import { KeyboardCommandCommonOptions, KeyboardCommandRunner } from "./types";

export interface CommandParams
    extends KeyboardCommandRunner,
        KeyboardCommandCommonOptions {
    key: string;
    requiresCtrlOrMeta?: boolean;
    requiresShift?: boolean;
    repeat?: boolean;
}

interface UseKeyboardCommandsProps {
    enabled?: boolean;
    rootEl?: EventTarget;
    commands: CommandParams[];
}

const matchCommand = (command: CommandParams, e: KeyboardEvent): boolean => {
    const {
        key,
        requiresCtrlOrMeta = true,
        requiresShift = false,
        ignoreForms = true,
        repeat = true,
    } = command;

    const matchingKey = requiresShift ? e.key.toLowerCase() : e.key;
    const targetKey = requiresShift ? key.toLowerCase() : key;

    if (targetKey !== matchingKey) {
        return false;
    }
    if (requiresCtrlOrMeta !== (e.ctrlKey || e.metaKey)) {
        return false;
    }
    if (requiresShift && !e.shiftKey) {
        return false;
    }
    if (!repeat && e.repeat) {
        return false;
    }
    if (ignoreForms && isEventFromForm(e)) {
        return false;
    }

    return true;
};

const executeCommand = (command: CommandParams, e: KeyboardEvent): void => {
    const { stopPropagation = true, preventDefault = true, callback } = command;

    const callbackResult = callback(e) ?? {};

    if (preventDefault || callbackResult.preventDefault) {
        e.preventDefault();
    }
    if (stopPropagation || callbackResult.stopPropagation) {
        e.stopPropagation();
    }
};

export const useKeyboardCommands = ({
    enabled = true,
    rootEl = document,
    commands,
}: UseKeyboardCommandsProps) => {
    useEffect(() => {
        if (!enabled) {
            return;
        }

        const onKeyDown = getKeyboardCommandsListenerFunction({
            commands,
        });

        rootEl.addEventListener("keydown", onKeyDown as EventListener);
        return () => {
            rootEl.removeEventListener("keydown", onKeyDown as EventListener);
        };
    }, [commands, enabled, rootEl]);
};

export const getKeyboardCommandsListenerFunction = ({
    commands,
}: UseKeyboardCommandsProps) => {
    const onKeyDown = (e: KeyboardEvent) => {
        for (const command of commands) {
            if (matchCommand(command, e)) {
                executeCommand(command, e);
                break;
            }
        }
    };

    return onKeyDown;
};
