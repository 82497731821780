import React from "react";

export interface ReceiptFilterLabelProps {
    filters: {
        hasReceipt?: boolean;
        noReceipt?: boolean;
        requiresReceipt?: boolean;
    };
}

export const ReceiptsFilterLabel: React.FC<ReceiptFilterLabelProps> = ({
    filters: { hasReceipt, noReceipt, requiresReceipt },
}) => {
    if (
        [hasReceipt, noReceipt, requiresReceipt].filter((value) => !!value)
            .length > 1
    ) {
        return <>Receipt</>;
    }

    if (hasReceipt) {
        return <>Has receipt</>;
    }

    if (noReceipt) {
        return <>No receipt</>;
    }

    if (requiresReceipt) {
        return <>Requires receipt</>;
    }
};
