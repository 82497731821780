import React, { useCallback, useEffect, useState } from "react";
import { format, isValid, parse } from "date-fns";
import { FieldProps, FormRowRaw } from "../../FormRowRaw";
import { fullDateShortFormat } from "../../../../common/helpers/date";

interface Props extends FieldProps {
    value?: Date;
    onChange: (date?: Date) => void;
    dateFormat?: string;
}

export const DatePickerInputManual: React.FC<Props> = ({
    value,
    onChange,
    dateFormat = fullDateShortFormat,
    ...fieldProps
}) => {
    const [displayValue, setDisplayValue] = useState("");
    const [error, setError] = useState<string>();

    useEffect(() => {
        if (value) {
            setDisplayValue(format(value, dateFormat));
        } else {
            setDisplayValue("");
        }
    }, [dateFormat, value]);

    useEffect(() => {
        setError(undefined);
    }, [displayValue]);

    const handleBlur = useCallback(() => {
        if (displayValue) {
            const parsedDate = parse(displayValue, dateFormat, new Date());

            if (isValid(parsedDate)) {
                onChange(parsedDate);
            } else {
                setError("Invalid date");
            }
        } else {
            onChange();
        }
    }, [displayValue, dateFormat, onChange]);

    const onKeyPress = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
                (e.target as HTMLInputElement)?.blur?.();
            }
        },
        [],
    );

    return (
        <FormRowRaw
            {...fieldProps}
            value={displayValue}
            onChange={setDisplayValue}
            onBlur={handleBlur}
            error={error}
            showErrorMessage={true}
            onKeyPress={onKeyPress}
            placeholder={fieldProps.placeholder ?? "MM/DD/YY"}
            dataTestId="date-picker-input-manual"
        />
    );
};
