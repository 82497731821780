import classNames from "classnames";
import { isString } from "lodash";
import React, {
    Children,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { OverlayChildren, Placement } from "react-bootstrap/Overlay";
import { OverlayTrigger } from "react-bootstrap";
import {
    FilterSearch,
    TransactionFilterSearchProps,
} from "../../general/FilterSearch/FilterSearch";
import {
    PopoverContainer,
    PopoverContainerHandle,
} from "../../general/PopoverContainer";
import {
    SelectDropdown,
    SelectDropdownRef,
} from "../../general/SelectDropdown/SelectDropdown";
import { SelectDropdownDivider } from "../../general/SelectDropdown/SelectDropdownDivider";
import "./CustomSelect.scss";
import { useDebouncedEffect } from "../../../hooks/useDebouncedEffect";
import { useKeyboardCommands } from "../../../hooks/keyboard/useKeyboardCommands";
import { CaptureInteractions } from "../../general/CaptureInteractions";
import { Loader } from "../../general/Loader";
import { TriangleIcon } from "../../../icons";
import { SelectDropdownOption } from "../../general/SelectDropdown/SelectDropdownOption";
import { Button } from "../../general/Button/Button";
import {
    CustomSelectDefaultTrigger,
    CustomSelectDefaultTriggerProps,
} from "./CustomSelectDefaultTrigger";

function getSearchableOption<TValue extends string | number = string>(
    option: CustomSelectOption<TValue>,
): string {
    if (option.searchableLabel) {
        return option.searchableLabel;
    } else if (isString(option.label)) {
        return option.label;
    } else {
        return String(option.value);
    }
}

export interface CustomSelectOption<TValue extends string | number = string> {
    value: TValue;
    label?: React.ReactNode;
    description?: OverlayChildren;
    searchableLabel?: string;
    className?: string;
    disabled?: boolean;
    suboptions?: Array<CustomSelectOption<TValue>>;
    suboptionsSelectProps?: Partial<CustomSelectProps<TValue>>;
    onSelected?(value: TValue): void;
    renderAddon?: (option: CustomSelectOption<TValue>) => React.ReactNode;
}

export interface CustomSelectProps<TValue extends string | number = string> {
    onSelected(value: TValue): void;
    onSearch?(value: string): void;
    dropdownKey: string | number;
    options: Array<CustomSelectOption<TValue>>;
    loadingOptions?: boolean;
    customOptionsTitle?: React.ReactNode;
    customOptions?: Array<CustomSelectOption<TValue>>;
    shouldShowCustomOptionsWhileSearching?: boolean;
    customOptionsPlacement?: "top" | "bottom";
    value?: TValue;
    searchable?: boolean;
    externalSearch?: boolean;
    placement?: Placement;
    label?: string;
    insideLabel?: boolean;
    empty?: React.ReactNode;
    className?: string;
    popoverClassName?: string;
    dropdownClassName?: string;
    children?: React.ReactNode | ((open: boolean) => React.ReactNode);
    size?: CustomSelectDefaultTriggerProps["size"];
    placeholder?: string;
    disabled?: boolean;
    fullWidthDropdown?: boolean;
    appendTo?: HTMLElement;
    showPopover?: boolean;
    onShowPopover?: () => void;
    onHidePopover?: () => void;
    canShowPopover?: boolean;
    cta?: React.ReactNode | ((search: string) => React.ReactNode);
}

export const CustomSelect = <T extends string | number>({
    onSelected,
    onSearch = () => {},
    dropdownKey,
    options,
    loadingOptions,
    customOptions,
    customOptionsTitle,
    shouldShowCustomOptionsWhileSearching = false,
    customOptionsPlacement = "top",
    value,
    searchable,
    externalSearch,
    placement,
    label,
    insideLabel,
    empty = "No matching items",
    className,
    popoverClassName,
    dropdownClassName,
    size,
    placeholder,
    disabled,
    fullWidthDropdown,
    children,
    appendTo,
    showPopover,
    onShowPopover: parentOnShow = () => {},
    onHidePopover: parentOnHide = () => {},
    canShowPopover = true,
    cta,
}: CustomSelectProps<T>) => {
    const [search, setSearch] = useState("");
    const [opened, setOpened] = useState(false);
    const [scrolling, setScrolling] = useState(false);
    const searchRef = useRef<HTMLInputElement>();
    const scrollRef = useRef<SelectDropdownRef>();
    const popoverRef = useRef<PopoverContainerHandle>(null);
    const containerRef = useRef<HTMLDivElement>();
    const dropdownRef = useRef<HTMLDivElement>();
    const [activeOption, setActiveOption] = useState<
        CustomSelectOption<T> | undefined
    >(undefined);

    const onShow = useCallback(() => {
        setOpened(true);
        parentOnShow();
    }, [parentOnShow]);
    const onHide = useCallback(() => {
        setOpened(false);
        parentOnHide();
    }, [parentOnHide]);

    useEffect(() => {
        if (showPopover === undefined || showPopover === opened) {
            return;
        }

        if (showPopover) {
            onShow();
            popoverRef.current?.open();
        } else {
            onHide();
            popoverRef.current?.close();
        }
    }, [showPopover, onShow, onHide, opened]);

    const displayedOptions = useMemo(() => {
        if (externalSearch) {
            return options;
        }

        return options.filter((option) =>
            getSearchableOption(option)
                .toLowerCase()
                .includes(search.toLowerCase()),
        );
    }, [search, externalSearch, options]);

    const closePopover = useCallback(() => {
        popoverRef.current?.close();
    }, []);

    useEffect(() => {
        if (canShowPopover === false) {
            closePopover();
        }
    }, [closePopover, canShowPopover]);

    const handleScroll = useCallback(() => {
        setScrolling(true);
    }, []);

    const resetScrolling = useCallback(
        () => setScrolling(false),
        [setScrolling],
    );
    useDebouncedEffect(resetScrolling, [scrolling, resetScrolling], 500);

    const handleSelected: CustomSelectDropdownOptionProps<T>["handleSelected"] =
        useCallback(
            (newValue) => {
                closePopover();
                setSearch("");
                setActiveOption(undefined);
                onSelected(newValue);
            },
            [closePopover, onSelected],
        );

    const handleSearch: TransactionFilterSearchProps["onChange"] = useCallback(
        (newValue) => {
            setSearch(newValue);
            onSearch(newValue);
        },
        [onSearch],
    );

    const selectedOption = useMemo(
        () => options.find((option) => option.value === value),
        [value, options],
    );
    const providedCustomTrigger =
        children instanceof Function || Children.count(children) > 0;

    const navigatableOptions = useMemo(() => {
        if (search === "" && customOptions) {
            return [...customOptions, ...displayedOptions];
        } else {
            return displayedOptions;
        }
    }, [displayedOptions, customOptions, search]);

    const scrollToActiveOption = useCallback(() => {
        if (!scrollRef.current) {
            return;
        }

        const activeOptionElement = scrollRef.current?.container.querySelector(
            `.select-dropdown-option--active`,
        ) as HTMLElement;

        const min = scrollRef.current.getScrollTop();
        const max = min + scrollRef.current.getClientHeight();

        if (!activeOptionElement) {
            return;
        }

        const isOptionAbove = activeOptionElement.offsetTop < min;
        const isOptionBelow =
            activeOptionElement.offsetTop + activeOptionElement.clientHeight >
            max;

        if (isOptionAbove) {
            scrollRef.current.scrollTop(activeOptionElement.offsetTop);
        } else if (isOptionBelow) {
            scrollRef.current.scrollTop(
                activeOptionElement.offsetTop -
                    scrollRef.current.getClientHeight() +
                    activeOptionElement.clientHeight,
            );
        }
    }, []);

    const navigateDown = useCallback(() => {
        if (!opened) {
            popoverRef.current?.open();
            return;
        }
        if (!activeOption) {
            setActiveOption(navigatableOptions[0]);
            return;
        }

        const currentIndex = navigatableOptions.indexOf(activeOption);
        const nextIndex = Math.min(
            currentIndex + 1,
            navigatableOptions.length - 1,
        );

        setActiveOption(navigatableOptions[nextIndex]);
        scrollToActiveOption();
    }, [activeOption, navigatableOptions, scrollToActiveOption, opened]);

    const navigateUp = useCallback(() => {
        if (!opened) {
            return;
        }
        if (!activeOption) {
            setActiveOption(navigatableOptions[0]);
            return;
        }

        const currentIndex = navigatableOptions.indexOf(activeOption);
        const nextIndex = Math.max(currentIndex - 1, 0);

        setActiveOption(navigatableOptions[nextIndex]);
        scrollToActiveOption();
    }, [activeOption, navigatableOptions, scrollToActiveOption, opened]);

    const selectActiveOption = useCallback(() => {
        if (!opened) {
            popoverRef.current?.open();
            return;
        }

        if (activeOption) {
            handleSelected(activeOption.value);
        }

        return {
            preventDefault: true,
            stopPropagation: true,
        };
    }, [activeOption, handleSelected, opened]);

    const commands = useMemo(
        () => [
            {
                key: "ArrowDown",
                callback: navigateDown,
                requiresCtrlOrMeta: false,
                ignoreForms: false,
            },
            {
                key: "ArrowUp",
                callback: navigateUp,
                requiresCtrlOrMeta: false,
                ignoreForms: false,
            },
            {
                key: "Enter",
                callback: selectActiveOption,
                requiresCtrlOrMeta: false,
                preventDefault: false,
                stopPropagation: false,
                ignoreForms: false,
            },
            {
                key: "Escape",
                callback: () => popoverRef.current?.close(),
                requiresCtrlOrMeta: false,
                preventDefault: false,
                stopPropagation: false,
                ignoreForms: false,
            },
        ],
        [navigateDown, navigateUp, selectActiveOption],
    );

    useKeyboardCommands({
        enabled: !!containerRef.current,
        commands,
        rootEl: containerRef.current,
    });

    useKeyboardCommands({
        enabled: !!dropdownRef.current,
        commands,
        rootEl: dropdownRef.current,
    });

    let trigger: React.ReactNode;

    if (!providedCustomTrigger) {
        trigger = (
            <CustomSelectDefaultTrigger
                value={
                    selectedOption?.label ??
                    selectedOption?.value ??
                    placeholder
                }
                label={label}
                insideLabel={insideLabel}
                size={size}
                disabled={disabled}
                className={classNames({
                    "custom-select-dropdown__placeholder": !selectedOption,
                })}
            />
        );
    } else if (children instanceof Function) {
        trigger = children(opened);
    } else {
        trigger = children;
    }

    const hasResults = displayedOptions.length > 0;
    const hasCustomOptions = !!customOptions && customOptions.length > 0;
    const isSearching = !!search;
    const shouldShowCustomOptions =
        hasCustomOptions &&
        (!isSearching || shouldShowCustomOptionsWhileSearching);

    const ctaContent = useMemo(() => {
        if (cta instanceof Function) {
            return cta(search);
        }

        return cta;
    }, [cta, search]);

    const content = useMemo(() => {
        if (loadingOptions) {
            return <Loader />;
        }

        if (!hasResults && !shouldShowCustomOptions) {
            return <div className="custom-dropdown__empty">{empty}</div>;
        }

        const results = displayedOptions.map((option) => (
            <CustomSelectDropdownOption
                key={option.value}
                option={option}
                handleSelected={handleSelected}
                showOverlay={!scrolling}
                activeOption={activeOption}
                setActiveOption={setActiveOption}
            />
        ));

        if (!shouldShowCustomOptions) {
            return results;
        }

        const customSelectOptions = (
            <>
                {customOptionsTitle}
                {customOptions.map((option) => (
                    <CustomSelectDropdownOption
                        key={option.value}
                        option={option}
                        handleSelected={handleSelected}
                        showOverlay={!scrolling}
                        activeOption={activeOption}
                        setActiveOption={setActiveOption}
                    />
                ))}
            </>
        );

        if (customOptionsPlacement === "bottom") {
            return (
                <>
                    {results}
                    <SelectDropdownDivider />
                    {customSelectOptions}
                </>
            );
        }

        return (
            <>
                {customSelectOptions}
                <SelectDropdownDivider />
                {results}
            </>
        );
    }, [
        customOptions,
        customOptionsTitle,
        customOptionsPlacement,
        displayedOptions,
        empty,
        handleSelected,
        hasResults,
        shouldShowCustomOptions,
        scrolling,
        activeOption,
        loadingOptions,
    ]);

    return (
        <div
            ref={containerRef as any}
            className={classNames("custom-dropdown", className, {
                "custom-dropdown--opened": opened,
            })}
        >
            <PopoverContainer
                ref={popoverRef as any}
                container={appendTo}
                trigger={trigger}
                id={`custom_select_${dropdownKey}`}
                offset={providedCustomTrigger ? 4 : 0}
                placement={placement}
                onShow={onShow}
                onHide={onHide}
                disabled={disabled}
                popoverClass={popoverClassName}
                maxDropdownWidth={
                    fullWidthDropdown && containerRef.current
                        ? containerRef?.current?.clientWidth
                        : undefined
                }
            >
                <SelectDropdown
                    dropdownRef={dropdownRef as any}
                    ref={scrollRef}
                    onScroll={handleScroll}
                    prepend={
                        searchable ? (
                            <CaptureInteractions>
                                <FilterSearch
                                    value={search}
                                    onChange={handleSearch}
                                    inputRef={searchRef}
                                    focus
                                />
                            </CaptureInteractions>
                        ) : undefined
                    }
                    append={
                        ctaContent && (
                            <div className="custom-dropdown__cta">
                                {ctaContent}
                            </div>
                        )
                    }
                    className={dropdownClassName}
                >
                    {content}
                </SelectDropdown>
            </PopoverContainer>
        </div>
    );
};

function CustomSelectDropdownOption<TValue extends string | number = string>({
    option,
    handleSelected,
    showOverlay,
    activeOption,
    setActiveOption,
}: CustomSelectDropdownOptionProps<TValue>) {
    const {
        value,
        label,
        className,
        disabled,
        suboptions = [],
        suboptionsSelectProps,
        renderAddon,
    } = option;

    const isActive = activeOption === option;

    const handleMouseEnter = useCallback(() => {
        setActiveOption(option);
    }, [option, setActiveOption]);

    const onClick = useCallback(() => {
        if (suboptions.length) {
            return;
        }
        handleSelected(value);
    }, [handleSelected, suboptions.length, value]);

    const optionContent = (
        <div className="select-dropdown-content">
            <span>{label ?? value}</span>
            {suboptions.length > 0 && (
                <Button
                    variant="tertiary"
                    onClick={onClick}
                    icon
                    className="ml-1"
                >
                    <TriangleIcon />
                </Button>
            )}
            {renderAddon?.({ value, onSelected: handleSelected })}
        </div>
    );

    const renderedOption = (
        <SelectDropdownOption
            onMouseEnter={handleMouseEnter}
            key={value}
            onClick={onClick}
            className={classNames(
                "select-dropdown-option",
                className,
                isActive && "select-dropdown-option--active",
            )}
            disabled={disabled}
        >
            {option.description ? (
                <OverlayTrigger
                    show={isActive && showOverlay}
                    placement="left"
                    overlay={option.description}
                >
                    {optionContent}
                </OverlayTrigger>
            ) : (
                optionContent
            )}
        </SelectDropdownOption>
    );

    if (suboptions.length > 0 || renderAddon) {
        return (
            <CustomSelect
                placement="right-start"
                dropdownKey={value}
                {...suboptionsSelectProps}
                onSelected={handleSelected}
                options={suboptions}
            >
                {renderedOption}
            </CustomSelect>
        );
    }

    return renderedOption;
}

interface CustomSelectDropdownOptionProps<
    TValue extends string | number = string,
> {
    readonly handleSelected: (newValue: TValue) => void;
    readonly option: CustomSelectOption<TValue>;
    readonly showOverlay: boolean;
    readonly setActiveOption: (option: CustomSelectOption<TValue>) => void;
    readonly activeOption?: CustomSelectOption<TValue>;
}
