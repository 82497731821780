import React from "react";
import { useQuery } from "@tanstack/react-query";
import isNumber from "lodash/isNumber";
import { DocumentName } from "../documents/DocumentName";
import { TrashIcon } from "../../icons";
import { Loader } from "../general/Loader";
import { fetchFinancialDocument } from "../../lib/financialDocument";
import {
    financialDocumentQueryKeys,
    UploadingDocumentPlaceholder,
} from "../documents/lib";
import { Button } from "../general/Button/Button";
import styles from "./ImportedDocuments.module.scss";

interface Props {
    onRemove(): void;
    item: UploadingDocumentPlaceholder;
    showTransactionsCount?: boolean;
}

export const ImportedDocumentsRow: React.FC<Props> = ({
    item,
    onRemove,
    showTransactionsCount,
}) => {
    const { data: refreshedFinancialDocument } = useQuery({
        queryKey: [financialDocumentQueryKeys.details(), item.fileName],
        queryFn: () => fetchFinancialDocument(item.financialDocument!.id),
        enabled: showTransactionsCount && !!item.financialDocument,
        refetchInterval(query) {
            return !isNumber(query.state.data?.numTransactions) ? 1_000 : false;
        },
    });

    const financialDocument =
        refreshedFinancialDocument ?? item.financialDocument;

    const fetchedNumberOfTransactions = isNumber(
        refreshedFinancialDocument?.numTransactions,
    );

    return (
        <div className={styles.row}>
            <span>
                <DocumentName fileName={item.fileName} />
            </span>
            {showTransactionsCount ? (
                <span className="text-right text-blue-600">
                    {fetchedNumberOfTransactions ? (
                        refreshedFinancialDocument.numTransactions
                    ) : (
                        <Loader inline />
                    )}
                </span>
            ) : (
                <span />
            )}
            <span>
                {financialDocument ? (
                    <Button
                        onClick={onRemove}
                        variant="action"
                        icon
                        tooltip="Delete document"
                    >
                        <TrashIcon />
                    </Button>
                ) : (
                    <Loader inline />
                )}
            </span>
        </div>
    );
};
