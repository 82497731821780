import { useCallback, useMemo, useRef, useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars-2";

import classNames from "classnames";
import {
    COLUMN_INDEX_TO_NAME,
    JournalEntryLineProxyObject,
    NUMBER_OF_CELLS_PER_LINE,
} from "../journalEntryModal/JournalEntryModal";
import {
    Box,
    boxesIntersect,
    useSelectionContainer,
} from "../../../lib/dragToSelect";
import { useKeyboardCommands } from "../../../hooks/keyboard/useKeyboardCommands";
import {
    convertClipboardTextIntoCells,
    copyTextToClipboard,
    readTextFromClipboard,
} from "../../../lib/clipboardUtils";
import styles from "./EditableTable.module.scss";
import { EditableCellSearchOption } from "./EditableTableCellSearch";
import { EditableTableCellSelectionState } from "./EditableTableCell";
import {
    CellGrid,
    EditableCellItem,
    calculateSelectedArea,
    convertLinesToCells,
    doesSelectedAreaContainOnlyOneCell,
    getCellByPosition,
    getItemBoundingClientRect,
    isCellInSelectedArea,
    isDraggingOrderCell,
    keepPreviousCellSelectionReferenceIfEqual,
    keepPreviousLineSelectionReferenceIfEqual,
    moveArrayChunk,
    traverseCellsToEdit,
} from "./EditableTableUtils";
import { useEditableTableContext } from "./EditableTableContext";
import { EditableTableRow } from "./EditableTableRow";

interface Props {
    tableRef: React.RefObject<HTMLDivElement>;
    scrollbarsRef: React.RefObject<Scrollbars>;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
    ) => void;
    isEditable?: boolean;
    // NOTE: for now we support only one type of search options
    // (assuming all searchable columns have the same type)
    searchOptions: EditableCellSearchOption[];
}

export type JournalEntryTableLineStateValue = string | number | null;

export const DRAGGING_THRESHOLD = 2;

export const EditableTableBody = ({
    tableRef,
    scrollbarsRef,
    setFieldValue,
    isEditable = true,
    searchOptions,
}: Props) => {
    const selectableItemsRef = useRef<CellGrid<EditableCellItem>>([]);
    const {
        selectedArea,
        setSelectedArea,
        combineWithExistingSelectedArea,
        targetLines,
        bodyRef,
        isInBuffer,
        setIsInBuffer,
        updateAndReturnNewLine,
    } = useEditableTableContext<JournalEntryLineProxyObject>();
    const linesRef = useRef(targetLines);
    const dragState = useRef<"dragging" | "selecting">("selecting");
    const [draggingAreaBox, setDraggingAreaBox] = useState<Box | null>(null);
    const [lineToInsert, setLineToInsert] = useState<number | null>(null);
    const draggingAreaOffset = useRef<number | null>(null);

    const calculateDraggingArea = useCallback(
        (box: Box, event: MouseEvent) => {
            let boxTop =
                box.top +
                box.height -
                getItemBoundingClientRect(tableRef.current!).top;
            if (box.top === event.y) {
                boxTop =
                    box.top - getItemBoundingClientRect(tableRef.current!).top;
            }
            const draggingState = {
                top: boxTop - (draggingAreaOffset.current ?? 0),
                left: event.x === box.left ? box.left : box.left + box.width,
                height: selectedArea
                    ? (selectedArea?.bottomRightCellPosition.rowIndex -
                          selectedArea?.topLeftCellPosition.rowIndex +
                          1) *
                      64
                    : 0,
                width: 0,
            };
            return draggingState;
        },
        [selectedArea, tableRef],
    );

    const recalculateSelectableItems = useCallback(() => {
        if (tableRef.current) {
            selectableItemsRef.current = [];
            Array.from(tableRef.current.children).forEach((line, rowIndex) => {
                const cells = Array.from(line.children).filter((child) =>
                    child.classList.contains(styles.editableTableCell),
                );
                cells.forEach((cell, columnIndex) => {
                    const itemToStore: EditableCellItem = {
                        topToParent: (cell as HTMLElement).offsetTop,
                        item: cell as HTMLElement,
                        cell: { rowIndex, columnIndex },
                    };
                    if (selectableItemsRef.current[rowIndex] === undefined) {
                        selectableItemsRef.current[rowIndex] = [];
                    }
                    selectableItemsRef.current[rowIndex]![columnIndex] =
                        itemToStore;
                });
            });
        }
    }, [tableRef]);

    useEffect(() => {
        linesRef.current = targetLines;
        recalculateSelectableItems();
    }, [targetLines, recalculateSelectableItems]);

    const handleDraggingState = (box: Box, event: MouseEvent) => {
        const draggingState = calculateDraggingArea(box, event);
        setDraggingAreaBox(draggingState);

        let lineToInsertNew: number | null = null;
        for (const row of selectableItemsRef.current) {
            for (const cell of row!) {
                if (
                    boxesIntersect(
                        {
                            ...draggingState,
                            top:
                                event.y === box.top
                                    ? box.top
                                    : box.top + box.height,
                            height: 0,
                            width: 0,
                        },
                        getItemBoundingClientRect(cell.item),
                    )
                ) {
                    lineToInsertNew = cell.cell.rowIndex;
                }
            }
        }
        if (
            selectedArea &&
            lineToInsertNew !== null &&
            lineToInsertNew > selectedArea.topLeftCellPosition.rowIndex &&
            lineToInsertNew <= selectedArea.bottomRightCellPosition.rowIndex
        ) {
            lineToInsertNew = selectedArea.topLeftCellPosition.rowIndex;
        }
        if (lineToInsert !== lineToInsertNew) {
            setLineToInsert(lineToInsertNew);
        }
    };

    const handleSelectionState = (box: Box, event: MouseEvent) => {
        dragState.current = "selecting";
        if (draggingAreaBox !== null) {
            setDraggingAreaBox(null);
        }
        const { topLeftCellPosition, bottomRightCellPosition } =
            calculateSelectedArea(box, selectableItemsRef.current);

        if (event.shiftKey) {
            combineWithExistingSelectedArea(
                topLeftCellPosition,
                bottomRightCellPosition,
            );
        } else {
            setSelectedArea(topLeftCellPosition, bottomRightCellPosition);
        }
    };

    const handleTransitToDragging = (box: Box, event: MouseEvent) => {
        const conditionToSwitchState =
            isEditable &&
            selectedArea !== null &&
            selectedArea.bottomRightCellPosition.columnIndex !==
                selectedArea.topLeftCellPosition.columnIndex &&
            !(
                selectedArea?.topLeftCellPosition.rowIndex === 0 &&
                selectedArea?.bottomRightCellPosition.rowIndex ===
                    linesRef.current.length - 1
            ) &&
            box.width < DRAGGING_THRESHOLD &&
            box.height < DRAGGING_THRESHOLD;

        if (!conditionToSwitchState) {
            return false;
        }
        if (
            !isDraggingOrderCell(box, selectedArea, selectableItemsRef.current)
        ) {
            return false;
        }
        dragState.current = "dragging";
        document.body.classList.add(styles.bodyDragging);
        const lineToInsertNew = selectedArea.topLeftCellPosition.rowIndex;
        if (lineToInsert !== lineToInsertNew) {
            setLineToInsert(lineToInsertNew);
        }

        if (draggingAreaOffset.current === null) {
            const topCell = getCellByPosition(
                selectedArea.topLeftCellPosition,
                selectableItemsRef.current,
            );

            draggingAreaOffset.current =
                box.top - getItemBoundingClientRect(topCell!.item).top;
        }
        const draggingState = calculateDraggingArea(box, event);
        setDraggingAreaBox(draggingState);

        return true;
    };

    const onDragEnd = () => {
        if (dragState.current !== "dragging") {
            return;
        }

        dragState.current = "selecting";
        if (selectedArea === null) {
            setDraggingAreaBox(null);
            setLineToInsert(null);
            return;
        }

        const topLeftCell = getCellByPosition(
            selectedArea.topLeftCellPosition,
            selectableItemsRef.current,
        );
        const bottomRightCell = getCellByPosition(
            selectedArea.bottomRightCellPosition,
            selectableItemsRef.current,
        );

        if (!topLeftCell || !bottomRightCell) {
            return;
        }
        if (lineToInsert === null) {
            return;
        }
        const lineFrom = topLeftCell.cell.rowIndex;
        const lineTo = bottomRightCell.cell.rowIndex;

        const newLines = moveArrayChunk({
            array: linesRef.current,
            startIndex: lineFrom,
            chunkSize: lineTo - lineFrom + 1,
            newIndex: lineToInsert,
        });

        setFieldValue("journalEntry.lines", newLines);
        setSelectedArea(null, null);
        setDraggingAreaBox(null);
        setLineToInsert(null);
        draggingAreaOffset.current = null;
        document.body.classList.remove(styles.bodyDragging);
    };

    const { DragSelection } = useSelectionContainer({
        eventsElement: scrollbarsRef.current?.container,
        onSelectionCancel: onDragEnd,
        onSelectionEnd: onDragEnd,
        onSelectionChange: (box, event) => {
            if (dragState.current === "dragging") {
                handleDraggingState(box, event);
                return;
            }

            const isTransitToDragging = handleTransitToDragging(box, event);
            if (isTransitToDragging) {
                return;
            }

            handleSelectionState(box, event);
        },
        selectionProps: {
            style: {
                opacity: 0,
            },
        },
    });

    const previousSelectedItemsWithSelectionBorders = useRef<
        CellGrid<EditableTableCellSelectionState>
    >([]);

    const selectedItemsWithSelectionBorders: CellGrid<EditableTableCellSelectionState> =
        useMemo(() => {
            if (selectedArea === null) {
                return [];
            }

            const minRow = selectedArea?.topLeftCellPosition.rowIndex;
            const maxRow = selectedArea?.bottomRightCellPosition.rowIndex;
            const minCol = selectedArea?.topLeftCellPosition.columnIndex;
            const maxCol = selectedArea?.bottomRightCellPosition.columnIndex;

            const items = selectableItemsRef.current.reduce(
                (acc, line, lineIndex) => {
                    line!.forEach((cell, columnIndex) => {
                        if (!acc[lineIndex]) {
                            acc[lineIndex] = [];
                        }
                        if (isCellInSelectedArea(cell.cell, selectedArea)) {
                            const newState = {
                                top: minRow === cell.cell.rowIndex,
                                bottom: maxRow === cell.cell.rowIndex,
                                left:
                                    minCol === columnIndex && columnIndex !== 0,
                                right:
                                    maxCol === columnIndex &&
                                    columnIndex !== NUMBER_OF_CELLS_PER_LINE,
                                isInBuffer,
                            };
                            // NOTE: for render optimization
                            const prevState =
                                previousSelectedItemsWithSelectionBorders
                                    .current[lineIndex]?.[columnIndex];
                            acc[lineIndex]![columnIndex] =
                                keepPreviousCellSelectionReferenceIfEqual({
                                    prevState,
                                    newState,
                                });
                        }
                    });
                    // NOTE: for render optimization
                    acc[lineIndex] = keepPreviousLineSelectionReferenceIfEqual({
                        prevValue:
                            previousSelectedItemsWithSelectionBorders.current[
                                lineIndex
                            ],
                        newValue: acc[lineIndex]!,
                    });
                    return acc;
                },
                [] as CellGrid<EditableTableCellSelectionState>,
            );
            previousSelectedItemsWithSelectionBorders.current = items;
            return items;
        }, [selectedArea, isInBuffer]);

    const editLineValue = useCallback(
        (
            lineIndex: number,
            field: keyof JournalEntryLineProxyObject,
            value: string | number | null,
        ) => {
            if (!isEditable) {
                return;
            }
            const newLine = updateAndReturnNewLine({
                line: linesRef.current[lineIndex],
                changes: [{ field, value }],
                fillEmptyFieldsAutomatically: true,
            });

            setFieldValue(`journalEntry.lines.${lineIndex}`, newLine);
        },
        [isEditable, setFieldValue, updateAndReturnNewLine],
    );

    const clearLine = useCallback(
        (lineIndex: number) => {
            if (!isEditable) {
                return;
            }
            const newLine = updateAndReturnNewLine({
                line: linesRef.current[lineIndex],
                changes: [
                    { field: "description", value: "" },
                    { field: "accountCode", value: "" },
                    { field: "debitAmount", value: null },
                    { field: "creditAmount", value: null },
                ],
                fillEmptyFieldsAutomatically: true,
            });

            setFieldValue(`journalEntry.lines.${lineIndex}`, newLine);
        },
        [isEditable, setFieldValue, updateAndReturnNewLine],
    );

    const memoizedOnChangeCallback: CellGrid<
        (value: JournalEntryTableLineStateValue) => void
    > = useMemo(() => {
        const mapCellToCallback = (lineIndex: number, columnIndex: number) => {
            const cellName = COLUMN_INDEX_TO_NAME[columnIndex];
            return (value: JournalEntryTableLineStateValue) => {
                if (cellName === undefined) {
                    return;
                }
                editLineValue(lineIndex, cellName, value);
            };
        };

        const arrayOfCallbacks = new Array(targetLines.length)
            .fill(null)
            .map((_, lineIndex) =>
                new Array(NUMBER_OF_CELLS_PER_LINE)
                    .fill(null)
                    .map((__, columnIndex) =>
                        mapCellToCallback(lineIndex, columnIndex),
                    ),
            );
        return arrayOfCallbacks;
    }, [editLineValue, targetLines.length]);

    const memoizedOnClickCallback = useMemo(() => {
        const mapCellToCallback =
            (lineIndex: number, columnIndex: number) => () => {
                setSelectedArea(
                    {
                        rowIndex: lineIndex,
                        columnIndex,
                    },
                    {
                        rowIndex: lineIndex,
                        columnIndex,
                    },
                );
            };

        const arrayOfCallbacks = new Array(targetLines.length)
            .fill(null)
            .map((_, lineIndex) =>
                new Array(NUMBER_OF_CELLS_PER_LINE)
                    .fill(null)
                    .map((__, columnIndex) =>
                        mapCellToCallback(lineIndex, columnIndex),
                    ),
            );

        return arrayOfCallbacks;
    }, [targetLines.length, setSelectedArea]);

    const onBackspace = useCallback(
        (e: any) => {
            if (selectedArea === null) {
                return;
            }
            if (
                doesSelectedAreaContainOnlyOneCell(selectedArea) &&
                document.activeElement instanceof HTMLInputElement
            ) {
                return;
            }
            e.preventDefault();
            const newLines = [...linesRef.current];

            selectableItemsRef.current.forEach((line) => {
                line!.forEach((cell) => {
                    if (!isCellInSelectedArea(cell.cell, selectedArea)) {
                        return;
                    }
                    const columnName =
                        COLUMN_INDEX_TO_NAME[cell.cell.columnIndex];

                    const lineIndex = cell.cell.rowIndex;
                    const newLine: JournalEntryLineProxyObject = {
                        ...newLines[lineIndex],
                    };
                    newLines[lineIndex] = newLine;
                    (newLine as any)[columnName] = "";
                });
            });
            setFieldValue(`journalEntry.lines`, newLines);
        },
        [selectedArea, setFieldValue, selectableItemsRef],
    );

    const onCopy = useCallback(
        (e: any) => {
            if (!selectedArea) {
                return;
            }
            e.preventDefault();

            const effectiveToColumn =
                selectedArea.bottomRightCellPosition.columnIndex !== 0
                    ? selectedArea.bottomRightCellPosition.columnIndex
                    : 1;
            const cells = convertLinesToCells({
                lines: linesRef.current,
                cellsIndexesToColumnsMapping: COLUMN_INDEX_TO_NAME,
                fromLine: selectedArea.topLeftCellPosition.rowIndex,
                toLine: selectedArea.bottomRightCellPosition.rowIndex,
                // NOTE: we don't want to copy order cells
                fromColumn:
                    selectedArea.topLeftCellPosition.columnIndex !== 0
                        ? selectedArea.topLeftCellPosition.columnIndex
                        : 1,
                toColumn: effectiveToColumn,
            });
            copyTextToClipboard(
                cells.map((row) => row.join("	")).join("\n"),
            ).then((result) => {
                if (result) {
                    setIsInBuffer(true);
                }
            });
        },
        [selectedArea, setIsInBuffer, linesRef],
    );

    const onCut = useCallback(
        (e: any) => {
            if (!selectedArea) {
                return;
            }
            e.preventDefault();

            // NOTE: we don't want to copy or change non-editable cells
            const fromColumn =
                selectedArea.topLeftCellPosition.columnIndex !== 0
                    ? selectedArea.topLeftCellPosition.columnIndex
                    : 1;
            const effectiveToColumn =
                selectedArea.bottomRightCellPosition.columnIndex !== 0
                    ? selectedArea.bottomRightCellPosition.columnIndex
                    : 1;
            const cells = convertLinesToCells({
                lines: linesRef.current,
                cellsIndexesToColumnsMapping: COLUMN_INDEX_TO_NAME,
                fromLine: selectedArea.topLeftCellPosition.rowIndex,
                toLine: selectedArea.bottomRightCellPosition.rowIndex,
                fromColumn,
                toColumn: effectiveToColumn,
            });

            copyTextToClipboard(
                cells.map((row) => row.join("	")).join("\n"),
            ).then((result) => {
                if (!result) {
                    return;
                }
                const newLines = [...linesRef.current];

                traverseCellsToEdit({
                    firstActiveCell: [
                        selectedArea.topLeftCellPosition.rowIndex,
                        selectedArea.topLeftCellPosition.columnIndex,
                    ],
                    cellsToInsert: cells,
                    lastColumnIndex: effectiveToColumn,
                    callback: (lineIndex, columnIndex) => {
                        const columnToChangeName =
                            COLUMN_INDEX_TO_NAME[columnIndex];
                        if (!columnToChangeName) {
                            return;
                        }
                        (newLines[lineIndex] as any)[columnToChangeName] = [
                            "debitAmount",
                            "creditAmount",
                        ].includes(columnToChangeName)
                            ? null
                            : "";
                    },
                });
                setFieldValue(`journalEntry.lines`, newLines);
                setIsInBuffer(false);
            });
        },
        [selectedArea, setIsInBuffer, setFieldValue, linesRef],
    );

    const onPaste = useCallback(
        (e: any) => {
            if (!selectedArea) {
                return;
            }
            e.preventDefault();

            readTextFromClipboard().then((text) => {
                if (text === null) {
                    return;
                }
                const cellsToInsert = convertClipboardTextIntoCells(text || "");

                const newLines = [...linesRef.current];

                const firstCell: [number, number] = [
                    selectedArea.topLeftCellPosition.rowIndex,
                    selectedArea.topLeftCellPosition.columnIndex === 0
                        ? 1
                        : selectedArea.topLeftCellPosition.columnIndex,
                ];

                traverseCellsToEdit({
                    firstActiveCell: firstCell,
                    cellsToInsert,
                    lastColumnIndex: NUMBER_OF_CELLS_PER_LINE - 1,
                    callback: (lineIndex, columnIndex, value) => {
                        const columnToChangeName =
                            COLUMN_INDEX_TO_NAME[columnIndex];
                        if (!columnToChangeName || value === undefined) {
                            return;
                        }
                        let valueToInsert: JournalEntryTableLineStateValue =
                            value;
                        if (
                            ["debitAmount", "creditAmount"].includes(
                                columnToChangeName,
                            )
                        ) {
                            valueToInsert = value === "" ? null : Number(value);
                        }
                        newLines[lineIndex] = updateAndReturnNewLine({
                            line: newLines[lineIndex],
                            changes: [
                                {
                                    field: columnToChangeName,
                                    value: valueToInsert,
                                },
                            ],
                        });
                    },
                });
                setFieldValue(`journalEntry.lines`, newLines);
                setIsInBuffer(false);
            });
        },
        [
            selectedArea,
            setFieldValue,
            setIsInBuffer,
            linesRef,
            updateAndReturnNewLine,
        ],
    );

    const editableCommands = [
        {
            key: "Backspace",
            requiresCtrlOrMeta: false,
            callback: onBackspace,
            preventDefault: false,
        },
        {
            key: "x",
            callback: onCut,
            preventDefault: false,
        },
        {
            key: "v",
            callback: onPaste,
            preventDefault: false,
        },
    ];

    useKeyboardCommands({
        commands: [
            {
                key: "c",
                callback: onCopy,
                preventDefault: false,
            },
            ...(isEditable ? editableCommands : []),
        ],
    });

    const hasSelectedAllLines =
        selectedArea?.topLeftCellPosition.rowIndex === 0 &&
        selectedArea?.bottomRightCellPosition.rowIndex ===
            targetLines.length - 1;

    return (
        <div
            ref={bodyRef}
            className={classNames(
                styles.container,
                draggingAreaBox ? styles.bodyDragging : "",
            )}
        >
            <DragSelection />
            <div ref={tableRef} className={styles.editableTableBody}>
                {targetLines.map(({ id }, lineIndex) => (
                    <EditableTableRow
                        key={id}
                        id={id}
                        lineIndex={lineIndex}
                        hasSelectedAllLines={hasSelectedAllLines}
                        onChangeCallback={memoizedOnChangeCallback[lineIndex]}
                        onClickCallback={memoizedOnClickCallback[lineIndex]}
                        searchOptions={searchOptions}
                        selectionState={
                            selectedItemsWithSelectionBorders[lineIndex]
                        }
                        insertToThisLine={lineToInsert === lineIndex}
                        isEditable={isEditable}
                        clearLine={clearLine}
                    />
                ))}
            </div>
        </div>
    );
};
