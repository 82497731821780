import classNames from "classnames";
import React, { MouseEvent, useCallback, useMemo } from "react";
import { Button } from "../../../general/Button/Button";
import { Entity } from "../../../../common/types/entity";
import { useBreakableText } from "../../../../hooks/useBreakableText";
import { TriangleIcon } from "../../../../icons";
import { EntityIcon } from "../../../entity/EntityIcon";
import { TreeHelpers, TreeNode } from "../../../general/Tree/Tree.types";
import css from "../FormCheckItem.module.scss";

interface EntityItemLabelProps
    extends Partial<
        Pick<TreeHelpers, "isExpanded" | "collapseNode" | "expandNode">
    > {
    entity: TreeNode<Entity>;
    hideTriangle?: boolean;
}
export const EntityItemLabel: React.FC<EntityItemLabelProps> = ({
    entity,
    collapseNode,
    isExpanded,
    expandNode,
    hideTriangle,
}) => {
    const handleToggleExpanded = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation();
            if (isExpanded?.(entity.nodeId)) {
                collapseNode?.(entity.nodeId);
            } else {
                expandNode?.(entity.nodeId);
            }
        },
        [collapseNode, expandNode, isExpanded, entity.nodeId],
    );

    const breakableDescription = useBreakableText(entity.current.name ?? "");

    const showTriangle = useMemo(
        () => !hideTriangle && entity.children.length,
        [hideTriangle, entity.children],
    );

    return (
        <div className={css.itemLabel}>
            <EntityIcon entity={entity.current} size="sm" />
            <span className={classNames(css.ellipses, "ml-2")}>
                {breakableDescription}
            </span>
            {showTriangle && (
                <Button
                    variant="tertiary"
                    onClick={handleToggleExpanded}
                    className="ml-1"
                    icon
                    data-testid="entity-tree-item-toggle"
                >
                    <TriangleIcon />
                </Button>
            )}
        </div>
    );
};
