import React from "react";
import classNames from "classnames";
import { MergeFinancialAccountsResponse } from "../../../../common/dto/financialAccount/mergeFinancialAccounts/merge-financial-accounts-response.dto";
import { pluralize } from "../../../../common/helpers/string";
import gridTableStyles from "../../../../styles/grid-table.module.scss";
import { Collapsible } from "../../../general/Collapsible/Collapsible";
import styles from "./DeduplicatedTransactionsPreview.module.scss";
import { TransactionToMerge } from "./TransactionToMerge";

interface Props {
    duplicates: MergeFinancialAccountsResponse["deduplicatedTransactions"];
}

export const DeduplicatedTransactionsPreview: React.FC<Props> = ({
    duplicates,
}) => {
    const header = (
        <div className={styles.header}>
            <main>
                <p>
                    Following {pluralize("transaction", duplicates.length)} were
                    found in both accounts and will be deduplicated:
                </p>
            </main>
        </div>
    );

    return (
        <Collapsible header={header}>
            <div
                className={classNames(
                    gridTableStyles.gridTable,
                    styles.table,
                    styles.deduplicatedTransactionsPreview,
                )}
            >
                {duplicates.map((transaction) => (
                    <TransactionToMerge
                        transaction={transaction}
                        key={transaction.id}
                    />
                ))}
            </div>
        </Collapsible>
    );
};
