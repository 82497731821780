import React from "react";
import { isBoolean } from "lodash";
import { CheckIcon, MinusIcon } from "../../../icons";
import { DetailedFeatures } from "./lib";
import { PlansRow } from "./PlansRow";

interface Props {
    features: DetailedFeatures[];
    featuresForPlan: Record<DetailedFeatures, string | boolean>;
    last?: boolean;
}

export const DetailedFeatureSection: React.FC<Props> = ({
    features,
    featuresForPlan,
    last,
}) => (
    <>
        <PlansRow /> {/* This is a spacer for title cell */}
        {features.map((feature, index) => {
            const value = featuresForPlan[feature];
            const isLast = last && index === features.length - 1;

            if (isBoolean(value)) {
                return (
                    <PlansRow key={feature} last={isLast}>
                        {value ? (
                            <CheckIcon className="icon-color-blue-600" />
                        ) : (
                            <MinusIcon className="icon-color-misc-alpha-300" />
                        )}
                    </PlansRow>
                );
            } else {
                return (
                    <PlansRow key={feature} last={isLast}>
                        {value}
                    </PlansRow>
                );
            }
        })}
    </>
);
