import { useMemo } from "react";
import { InvoiceFilters } from "../../../../../common/contracts/invoice.contract";
import { InvoiceStatus } from "../../../../../common/types/invoice";
import { ActiveFilter } from "./lib";

type FiltersChangeFn = (filters: Partial<InvoiceFilters>) => void;

type PredicateFn = (filters: InvoiceFilters) => boolean;

export const defaultStatuses: InvoiceStatus[] = [
    InvoiceStatus.DRAFT,
    InvoiceStatus.OPEN,
    InvoiceStatus.PAID,
];

const isDefaultStatuses = (statuses: InvoiceStatus[]) =>
    statuses.length === defaultStatuses.length &&
    statuses.every((status) => defaultStatuses.includes(status));

const isActive: Record<ActiveFilter, PredicateFn> = {
    date: ({ start, end }) => start !== undefined || end !== undefined,
    dueDate: ({ dueStart, dueEnd }) =>
        dueStart !== undefined || dueEnd !== undefined,
    amount: ({ minAmount, maxAmount }) =>
        minAmount !== undefined || maxAmount !== undefined,
    statuses: ({ statuses }) =>
        !!statuses && statuses.length > 0 && !isDefaultStatuses(statuses),
    accounts: ({ accountIds }) => !!accountIds && accountIds.length > 0,
} as const;

const activeFiltersOrder: ActiveFilter[] = [
    "date",
    "dueDate",
    "amount",
    "statuses",
    "accounts",
];

export function useActiveFilters(filters: InvoiceFilters): ActiveFilter[] {
    return useMemo(
        () =>
            activeFiltersOrder.filter((filterId) =>
                isActive[filterId](filters),
            ),
        [filters],
    );
}

const resetPayload: Record<ActiveFilter, Partial<InvoiceFilters>> = {
    date: {
        start: undefined,
        end: undefined,
    },
    dueDate: {
        dueStart: undefined,
        dueEnd: undefined,
    },
    amount: {
        minAmount: undefined,
        maxAmount: undefined,
    },
    statuses: { statuses: undefined },
    accounts: { accountIds: undefined },
};

export function useResetFilter(
    onChange: FiltersChangeFn,
    filterIds: ActiveFilter | ActiveFilter[],
) {
    return useResetFilters(onChange, filterIds);
}

function useResetFilters(
    onChange: FiltersChangeFn,
    filterIds: ActiveFilter | ActiveFilter[],
) {
    return useMemo(() => {
        const ids = Array.isArray(filterIds) ? filterIds : [filterIds];
        return () => {
            const resetValues = ids.reduce(
                (acc, id) => ({ ...acc, ...resetPayload[id] }),
                {},
            );
            onChange(resetValues);
        };
    }, [onChange, filterIds]);
}
