import React, { useRef, useState } from "react";
import classNames from "classnames";
import { Field, Form, Formik, FormikConfig } from "formik";
import { object, string } from "yup";
import { FormikProps } from "formik/dist/types";
import { Entity } from "../../../common/types/entity";
import { submitHelper } from "../../../helpers/form";
import { FormRow } from "../../forms/FormRow";
import { KeyboardReturnIcon } from "../../../icons";
import { useKeyboardCommands } from "../../../hooks/keyboard/useKeyboardCommands";
import animation from "../layout/OnboardingLayout.module.scss";
import { Button } from "../../general/Button/Button";
import { KeyboardShortcut } from "../../general/KeyboardShortcut/KeyboardShortcut";
import styles from "./WaitlistIntro.module.scss";
import commonStyles from "./common.module.scss";

interface Props {
    entity: Entity;
    onBack: () => void;
    onSubmit(comment: string): Promise<void>;
}

interface FormSchema {
    comment: string;
}

export const AccountantWaitlistIntro: React.FC<Props> = ({
    entity,
    onSubmit,
}) => {
    const [loading, setLoading] = useState(false);
    const formRef = useRef<FormikProps<FormSchema>>();

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            comment: entity?.profile?.waitlistComment ?? "",
        },
        validationSchema: object().shape({
            comment: string().required("Please provide a comment"),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async (values) => {
                await onSubmit(values.comment);
            },
        }),
    };

    useKeyboardCommands({
        commands: [
            {
                key: "Enter",
                requiresCtrlOrMeta: false,
                callback: (e) => {
                    if (e.target) {
                        formRef.current?.submitForm();
                    }
                },
            },
        ],
    });

    return (
        <div className={classNames(commonStyles.content420, styles.content)}>
            <header className={classNames(animation.fadeIn1, styles.header)}>
                <h1 className={commonStyles.title}>
                    Kick Partner Program will be available soon
                </h1>

                <p>
                    To join the beta, tell us what's the number one thing about
                    your firm that keeps you up at night?
                </p>
            </header>

            <main className={classNames(styles.main, animation.fadeIn2)}>
                <Formik {...form} innerRef={formRef as any}>
                    {({ isValid }) => (
                        <Form>
                            <FormRow fieldName="comment">
                                <Field
                                    name="comment"
                                    as="textarea"
                                    className={classNames(
                                        "form-control",
                                        styles.textarea,
                                    )}
                                />
                            </FormRow>

                            <Button
                                type="submit"
                                variant="secondary"
                                block
                                disabled={!isValid}
                            >
                                Submit
                                <KeyboardShortcut square className="ml-2">
                                    <KeyboardReturnIcon />
                                </KeyboardShortcut>
                            </Button>
                        </Form>
                    )}
                </Formik>
            </main>
        </div>
    );
};
