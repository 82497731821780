import { CollapseIcon } from "../../../../icons";
import { Category } from "../../../../common/types/category";
import { Button } from "../../../general/Button/Button";
import { TablePageHeader } from "../../../pageHeader/TablePageHeader";

interface Props {
    collapsedCategories: Set<string>;
    setCollapsedCategories: (categories: Set<string>) => void;
    categories: Category[];
    search: string;
    setSearch: (search: string) => void;
}

export function CategoriesHeader({
    collapsedCategories,
    setCollapsedCategories,
    categories,
    search,
    setSearch,
}: Readonly<Props>) {
    const onCollapseButtonClick = () => {
        if (collapsedCategories.size === 0) {
            setCollapsedCategories(
                new Set(
                    categories
                        .filter((c) => c.parentCategoryId === null)
                        .map((c) => c.id),
                ),
            );
        } else {
            setCollapsedCategories(new Set());
        }
    };

    return (
        <TablePageHeader
            search={search}
            onSearchChange={(value) => {
                setSearch(value ?? "");
            }}
        >
            <Button icon variant="secondary" onClick={onCollapseButtonClick}>
                <CollapseIcon />
            </Button>
        </TablePageHeader>
    );
}
