import React from "react";
import { Form, Formik, FormikConfig } from "formik";
import Scrollbars from "react-custom-scrollbars-2";
import { submitHelper } from "../../../../helpers/form";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import { useEntities } from "../../../../hooks/useEntities";
import { Counterparty } from "../../../../common/types/counterparty";
import { NewTransactionRuleDefinition } from "../../../transactionRules/TransactionRuleCreation/TransactionRuleCreation.context";
import {
    TransactionRule,
    TransactionRuleCondition,
} from "../../../../common/types/transactionRule";
import { useCategoriesById } from "../../../../hooks/useCategoriesById";
import { RuleConditionsForm } from "./RuleConditionsForm/RuleConditionsForm";
import { RuleActionsForm } from "./RuleActionsForm/RuleActionsForm";
import { TransactionRuleFormSchema, FormRuleConditionType } from "./constants";
import {
    conditionModelToFormRow,
    getDefaultFormRow,
    modelToFormActions,
} from "./modelToForm.helpers";
import {
    conditionFormRowToModel,
    formActionsToModel,
} from "./formToModel.helpers";
import styles from "./RuleForm.module.scss";
import { ApplyRuleToExistingTransactions } from "./ApplyRuleToExistingTransactions";
import { RuleTransactionsPreview } from "./RuleTransactionsPreview/RuleTransactionsPreview";

export interface TransactionRuleSubmitData {
    rule: {
        categoryId?: string;
        conditions: Array<Omit<TransactionRuleCondition, "id">>;
        entityId: number | null;
    };
    applyToExistingTransactions: boolean;
    excludedTransactionsIds: number[];
}

interface Props {
    rule?: TransactionRule;
    onSubmit: (data: TransactionRuleSubmitData) => Promise<void>;
    isSubmitting: boolean;
    submitText: string;
    counterparties?: Counterparty[];
    newRuleDefinition?: NewTransactionRuleDefinition;
}

export const RuleForm: React.FC<Props> = ({
    rule,
    onSubmit,
    isSubmitting,
    submitText,
    counterparties,
    newRuleDefinition,
}) => {
    const businessEntities = useEntities({
        onlyBusiness: true,
        excludeAllMockEntities: true,
    });
    const categoriesById = useCategoriesById();

    const initialData = rule ?? newRuleDefinition;

    const form: FormikConfig<TransactionRuleFormSchema> = {
        initialValues: {
            conditions: initialData?.conditions
                ?.map((c, idx) =>
                    conditionModelToFormRow({
                        conditionModel: { id: idx.toString(), ...c },
                        counterparties,
                        categoriesById,
                    }),
                )
                .filter((v) => v !== undefined) ?? [
                {
                    id: Date.now().toString(),
                    ...getDefaultFormRow(),
                    conditionType: FormRuleConditionType.ENTITY,
                    entityValue: businessEntities.map((e) => e.id),
                },
                {
                    id: (Date.now() + 1).toString(),
                    ...getDefaultFormRow(),
                },
            ],
            actions: initialData ? modelToFormActions(initialData) : [{}],
            applyToExistingTransactions: true,
            excludedTransactionsIds: [],
        },
        validate: ({ actions }) => {
            const output = formActionsToModel(actions);

            if (!output.entityId && !output.categoryId) {
                return { actions: "At least one valid action required" };
            }
        },
        onSubmit: submitHelper({
            handler: async ({
                actions,
                conditions,
                applyToExistingTransactions,
                excludedTransactionsIds,
            }) => {
                await onSubmit({
                    rule: {
                        ...formActionsToModel(actions),
                        conditions: conditions
                            .map(conditionFormRowToModel)
                            .filter((v) => v !== undefined),
                    },
                    applyToExistingTransactions,
                    excludedTransactionsIds,
                });
            },
            loading: isSubmitting,
        }),
    };

    return (
        <Formik {...form}>
            <Form>
                <div className={styles.container}>
                    <Scrollbars>
                        <div className={styles.formContainer}>
                            <RuleConditionsForm rule={rule} />
                            <RuleActionsForm />
                        </div>
                    </Scrollbars>
                    <div className={styles.transactionsContainer}>
                        <RuleTransactionsPreview rule={rule} />
                    </div>
                </div>

                <footer className={styles.footer}>
                    <ApplyRuleToExistingTransactions />
                    <ButtonWithLoader type="submit" loading={isSubmitting}>
                        {submitText}
                    </ButtonWithLoader>
                </footer>
            </Form>
        </Formik>
    );
};
