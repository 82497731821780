import React, { useCallback } from "react";
import { Placement } from "react-bootstrap/Overlay";
import { OverlayTrigger } from "react-bootstrap";
import { Switch } from "../general/Switch/Switch";
import { usePlaidConnectionFixingMutation } from "../../mutations/plaidConnection";
import { FinancialAccount } from "../../common/types/financialAccount";
import {
    useAccountDisablingMutation,
    useAccountEnablingMutation,
} from "../../mutations/financialAccount";
import { FinancialConnection } from "../../common/types/financialConnection";
import { PlaidConnection } from "../../common/types/plaidConnection";
import { FinancialConnectionProviderLabel } from "../../common/constants";

export interface FinancialAccountStateManagerProps {
    account: FinancialAccount;
    connection: FinancialConnection;
    tooltip?: React.ReactElement;
    tooltipPlacement?: Placement;
}

export const FinancialAccountStateManager: React.FC<
    FinancialAccountStateManagerProps
> = ({ account, connection, tooltip, tooltipPlacement = "left" }) => {
    const enableAccount = useAccountEnablingMutation(account);
    const disableAccount = useAccountDisablingMutation(account);

    const handleDisabledStateChange = useCallback(
        async (active: boolean) => {
            if (active) {
                await enableAccount.mutateAsync();
            } else {
                await disableAccount.mutateAsync();
            }
        },
        [enableAccount, disableAccount],
    );

    const handleConnectionFixed = useCallback(async () => {
        if (account.disabled) {
            await handleDisabledStateChange(true);
        }
    }, [account.disabled, handleDisabledStateChange]);

    const fixAccount = usePlaidConnectionFixingMutation(
        connection as PlaidConnection,
        handleConnectionFixed,
    );

    const label = `Connected to ${
        FinancialConnectionProviderLabel[connection.connectionProvider]
    }`;

    let controlPartial: React.ReactElement | null;

    if (connection.error) {
        controlPartial = (
            <Switch
                label={label}
                active={!connection.error}
                onChanged={() => fixAccount.mutate()}
                loading={fixAccount.isPending}
                size="sm"
                danger
                direction="rtl"
            />
        );
    } else {
        controlPartial = (
            <Switch
                label={label}
                active={!account.disabled}
                onChanged={handleDisabledStateChange}
                loading={disableAccount.isPending || enableAccount.isPending}
                size="sm"
                direction="rtl"
            />
        );
    }

    if (tooltip) {
        controlPartial = (
            <OverlayTrigger
                placement={tooltipPlacement}
                trigger={!account.disabled ? ["hover"] : []}
                overlay={tooltip}
            >
                <span className="w-100">{controlPartial}</span>
            </OverlayTrigger>
        );
    }

    return <>{controlPartial}</>;
};
