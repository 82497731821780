import React from "react";
import { format } from "date-fns";
import { fullDateFormat } from "../../../../common/helpers/date";
import { Amount } from "../../../general/Amount/Amount";
import { useShowTransactionDetails } from "../../../../hooks/useShowTransactionDetails";
import { TransactionMatch } from "../../../../common/types/transactionMatch";
import { ArrowCornerIcon, DetachIcon } from "../../../../icons";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import { useDetachFinancialDocumentMutation } from "../../../../mutations/financialDocument";
import { EagerLoaded } from "../../../../common/types/base/orm";
import { FinancialDocument } from "../../../../common/types/financialDocument";
import { Button } from "../../../general/Button/Button";
import styles from "./FinancialDocumentMatches.module.scss";

interface Props {
    transactionMatch: EagerLoaded<TransactionMatch, "financialTransaction">;
    financialDocument: FinancialDocument;
}

export const FinancialTransactionMatch: React.FC<Props> = ({
    financialDocument,
    transactionMatch,
}) => {
    const financialTransaction = transactionMatch.financialTransaction;
    const showTransactionDetails = useShowTransactionDetails();

    const detachMutation = useDetachFinancialDocumentMutation(
        financialDocument,
        transactionMatch,
    );

    return (
        <article
            className={styles.item}
            data-testid="financial-document-transaction-match-item"
        >
            <main>
                <div
                    className="text-gray-700"
                    data-testid="financial-document-transaction-match-item-date"
                >
                    {format(financialTransaction.date, fullDateFormat)}
                </div>

                <div
                    className={styles.description}
                    data-testid="financial-document-transaction-match-item-description"
                >
                    {financialTransaction.bankDescription}
                </div>
            </main>

            <Amount amount={financialTransaction!.amount} />
            <aside>
                <ButtonWithLoader
                    variant="tertiary"
                    size="sm"
                    icon
                    mutation={detachMutation}
                    tooltip="Detach from this transaction"
                    data-testid="financial-document-transaction-match-detach-button"
                >
                    <DetachIcon />
                </ButtonWithLoader>

                <Button
                    variant="tertiary"
                    size="sm"
                    icon
                    onClick={() => showTransactionDetails(financialTransaction)}
                    tooltip="View transaction"
                    data-testid="financial-document-transaction-match-view-transaction-button"
                >
                    <ArrowCornerIcon />
                </Button>
            </aside>
        </article>
    );
};
