import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { PlaidConnectionIcon } from "../PlaidConnectionIcon";
import { AlertIcon, ClockIcon } from "../../../icons";
import { timeAgo } from "../../../helpers/date";
import { PlaidConnectionWithAccounts } from "../../../common/types/plaidConnection";
import { BrokenPlaidConnectionLink } from "../BrokenPlaidConnectionLink";
import { useLatestTransactionsSyncTime } from "../../../hooks/useLatestSyncTime";
import { useManualDataUpload } from "./useManualDataUpload";

interface Props {
    connection: PlaidConnectionWithAccounts;
}
export const DisplayPlaidConnectionHeader: React.FC<Props> = ({
    connection,
}) => {
    const { isMissingData } = useManualDataUpload(connection);
    const hasAccountsWithMissingInfo = connection.accounts.some(
        (acc) => !acc.financialAccount.entityId,
    );
    const lastSynced = useLatestTransactionsSyncTime(connection.accounts ?? []);

    return (
        <div className="d-flex align-items-center">
            <div className="plaid-connection__icon">
                <PlaidConnectionIcon connection={connection} />
            </div>

            <div className="plaid-connection__description">
                <h5>
                    {connection.bankName}{" "}
                    {hasAccountsWithMissingInfo && (
                        <OverlayTrigger
                            overlay={
                                <Tooltip id={`missing-info-${connection.id}`}>
                                    One or more accounts have missing
                                    information.
                                </Tooltip>
                            }
                        >
                            <AlertIcon className="ml-2 icon-color-red-600" />
                        </OverlayTrigger>
                    )}
                </h5>
                <span className="d-flex align-items-center">
                    {!connection.error && lastSynced && (
                        <>
                            <ClockIcon
                                className="icon-color-gray-900 mr-1"
                                height={12}
                            />
                            {timeAgo(lastSynced)} by Plaid
                        </>
                    )}
                    {connection.error && (
                        <BrokenPlaidConnectionLink connection={connection} />
                    )}

                    {isMissingData && (
                        <span className="plaid-connection__missing-data">
                            <AlertIcon className="mr-2 icon-color-current" />
                            Missing Data
                        </span>
                    )}
                </span>
            </div>
        </div>
    );
};
