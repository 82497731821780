import React, { useMemo } from "react";
import { isEqual } from "lodash";
import { useResetFilters } from "../../general/ActiveFilters/useResetFilters";

import { AggregatedFiltersProvider } from "../../general/AggregatedFilters/AggregatedFiltersProvider";
import { AggregatedFilters } from "../../general/AggregatedFilters/AggregatedFilters";
import { ActiveFilters } from "../../general/ActiveFilters/ActiveFilters";
import { FiltersTypes } from "../../../common/types/filters/general";
import {
    ActiveFilter,
    activeFiltersOrder,
    ActiveFilterToFilterTabsMap,
    FiltersChangeFn,
    FilterTabs,
    isActive,
    resetPayload,
} from "./lib";
import { ActiveFilterButtonLabel } from "./ActiveFilterButtonLabel";
import { FiltersOverlay } from "./FiltersOverlay";

export interface FiltersProps {
    filters: FiltersTypes;
    onChange: FiltersChangeFn;

    availableFilters: FilterTabs[];
    defaultFilters?: FiltersTypes;

    defaultTab?: FilterTabs;

    directionSwitchDisabled?: boolean;
    hardcodedEntityId?: number;
}

function useActiveFilters(
    filters: FiltersTypes,
    availableFilters: FilterTabs[],
): ActiveFilter[] {
    return useMemo(
        () =>
            activeFiltersOrder.filter(
                (filterId) =>
                    availableFilters.includes(
                        ActiveFilterToFilterTabsMap[filterId],
                    ) && isActive[filterId](filters),
            ),
        [filters, availableFilters],
    );
}

export function useFiltersActive(
    currentFilters: FiltersTypes,
    defaultFilters?: FiltersTypes,
): boolean {
    return useMemo(
        () => !defaultFilters || !isEqual(currentFilters, defaultFilters),
        [currentFilters, defaultFilters],
    );
}

export const Filters: React.FC<FiltersProps> = ({
    filters,
    onChange,
    availableFilters,
    defaultTab = FilterTabs.DATE,
    directionSwitchDisabled,
    defaultFilters,
    hardcodedEntityId,
}) => {
    // this checks if there are any default filters applied
    const filtersActive = useFiltersActive(filters, defaultFilters);
    const activeFilters = useActiveFilters(filters, availableFilters);
    const resetActiveFilters = useResetFilters(
        resetPayload,
        onChange,
        activeFilters,
    );

    return (
        <AggregatedFiltersProvider defaultTab={defaultTab}>
            <AggregatedFilters>
                <FiltersOverlay
                    filters={filters}
                    onChange={onChange}
                    directionSwitchDisabled={directionSwitchDisabled}
                    availableFilters={availableFilters}
                    hardcodedEntityId={hardcodedEntityId}
                />
            </AggregatedFilters>

            {filtersActive && (
                <ActiveFilters
                    activeFilters={activeFilters}
                    renderVisibleFilters={(visibleFilters) =>
                        visibleFilters.map((filterId) => (
                            <ActiveFilterButtonLabel
                                key={filterId}
                                filterId={filterId}
                                filters={filters}
                                onChange={onChange}
                            />
                        ))
                    }
                    clearFilters={resetActiveFilters}
                />
            )}
        </AggregatedFiltersProvider>
    );
};
