import { Transaction } from "../../../types/transaction";
import { TransactionDto } from "../../transactions/transaction.dto";
import {
    BothModifiedConflict,
    BothModifiedConflictDto,
} from "./both-modified-conflict.dto";
import {
    MultipleMatchingConflict,
    MultipleMatchingConflictDto,
} from "./multiple-matching-conflict.dto";

export interface MergeFinancialAccountsResponse {
    wasDryRun: boolean;
    conflictsWhenMerging: boolean;
    deduplicatedTransactions: Transaction[];
    movedTransactionsNumber: number;
    multipleMatchingConflicts: MultipleMatchingConflict[];
    bothModifiedConflicts: BothModifiedConflict[];
}

export class MergeFinancialAccountsResponseDto {
    wasDryRun: boolean;
    conflictsWhenMerging: boolean;
    deduplicatedTransactions: TransactionDto[];
    movedTransactionsNumber: number;
    multipleMatchingConflicts: MultipleMatchingConflictDto[];
    bothModifiedConflicts: BothModifiedConflictDto[];

    constructor(response: MergeFinancialAccountsResponse) {
        this.wasDryRun = response.wasDryRun;
        this.conflictsWhenMerging = response.conflictsWhenMerging;
        this.deduplicatedTransactions = response.deduplicatedTransactions.map(
            (t) => new TransactionDto(t),
        );
        this.movedTransactionsNumber = response.movedTransactionsNumber;
        this.multipleMatchingConflicts = response.multipleMatchingConflicts.map(
            (c) => new MultipleMatchingConflictDto(c),
        );
        this.bothModifiedConflicts = response.bothModifiedConflicts.map(
            (c) => new BothModifiedConflictDto(c),
        );
    }

    static deserialize(
        dto: MergeFinancialAccountsResponseDto,
    ): MergeFinancialAccountsResponse {
        return {
            wasDryRun: dto.wasDryRun,
            conflictsWhenMerging: dto.conflictsWhenMerging,
            deduplicatedTransactions: dto.deduplicatedTransactions.map((t) =>
                TransactionDto.deserialize(t),
            ),
            movedTransactionsNumber: dto.movedTransactionsNumber,
            multipleMatchingConflicts: dto.multipleMatchingConflicts.map((c) =>
                MultipleMatchingConflictDto.deserialize(c),
            ),
            bothModifiedConflicts: dto.bothModifiedConflicts.map((c) =>
                BothModifiedConflictDto.deserialize(c),
            ),
        };
    }
}
