import React from "react";
import { useFormikContext } from "formik";
import { Entity } from "../../common/types/entity";
import { Workspace } from "../../common/types/workspace";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";
import { Button } from "../general/Button/Button";
import { ConfigurePermissionsForm } from "./ConfigurePermissionsForm";
import styles from "./MemberManagement.module.scss";
import { MemberPermissionsSchema, StepProps } from "./types";

interface Props extends StepProps {
    entities: Entity[];
    workspace: Workspace;
    busy: boolean;
}

export const ConfigurePermissionsStep: React.FC<Props> = ({
    onBack,
    onNext,
    entities,
    workspace,
    busy,
}) => {
    const formikContext = useFormikContext<MemberPermissionsSchema>();

    return (
        <section>
            <h3 className={styles.heading}>Add member details</h3>

            <ConfigurePermissionsForm
                workspace={workspace}
                entities={entities}
            />

            <footer className={styles.footer}>
                <Button variant="secondary" onClick={onBack}>
                    Back
                </Button>
                <ButtonWithLoader
                    loading={busy}
                    variant="default"
                    onClick={onNext}
                    disabled={!formikContext.isValid}
                >
                    Add member
                </ButtonWithLoader>
            </footer>
        </section>
    );
};
