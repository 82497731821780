import React, { useCallback, useMemo, useState } from "react";
import { without } from "lodash";
import classNames from "classnames";
import { Alert } from "react-bootstrap";
import GustoLogo from "../../../../icons/logos/Gusto.svg?react";
import ADPLogo from "../../../../icons/logos/ADP.svg?react";
import QuickbooksLogo from "../../../../icons/logos/QuickBooks.svg?react";
import Paychex from "../../../../icons/logos/Paychex.svg?react";
import commonStyles from "../common.module.scss";
import animations from "../../layout/OnboardingLayout.module.scss";
import { OptionGroupItem } from "../../../general/OptionGroup/OptionGroupItem";
import { OptionGroupCustomItem } from "../../../general/OptionGroup/OptionGroupCustomItem";
import { Footer } from "../../components/Footer";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import { KeyboardReturnIcon, LockIcon } from "../../../../icons";
import { useKeyboardCommands } from "../../../../hooks/keyboard/useKeyboardCommands";
import { KeyboardShortcut } from "../../../general/KeyboardShortcut/KeyboardShortcut";

interface Option {
    value: string;
    label: string;
    icon: React.ReactNode;
}

const payrollProviderOptions: Option[] = [
    {
        value: "gusto",
        label: "Gusto",
        icon: <GustoLogo />,
    },
    {
        value: "adp",
        label: "ADP",
        icon: <ADPLogo />,
    },
    {
        value: "quickbooks",
        label: "Quickbooks",
        icon: <QuickbooksLogo />,
    },
    {
        value: "paychex",
        label: "Paychex",
        icon: <Paychex />,
    },
];

const definedProviders = payrollProviderOptions.map((option) => option.value);

interface Props {
    payrollProviders: string[];
    onSubmit: (providers: string[]) => Promise<void>;
    back(): void;
}

export const Payroll: React.FC<Props> = ({
    onSubmit,
    back,
    payrollProviders,
}) => {
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(() =>
        payrollProviders.filter((provider) =>
            definedProviders.includes(provider),
        ),
    );
    const [other, setOther] = useState(() =>
        payrollProviders
            .filter((provider) => !definedProviders.includes(provider))
            .join(", "),
    );

    const handleToggleProvider = useCallback(
        (item: string) => {
            if (selected.includes(item)) {
                setSelected((prev) => without(prev, item));
            } else {
                setSelected((prev) => [...prev, item]);
            }
        },
        [selected],
    );

    const finalValue = useMemo(() => {
        const providers = [...selected];

        if (other.trim().length) {
            providers.push(other.trim());
        }

        return providers;
    }, [other, selected]);

    const handleNext = useCallback(
        () => onSubmit(finalValue),
        [onSubmit, finalValue],
    );

    useKeyboardCommands({
        commands: [
            {
                key: "Enter",
                requiresCtrlOrMeta: false,
                callback: () => {
                    if (loading || !finalValue.length) {
                        return;
                    }

                    void handleNext();
                },
            },
        ],
    });

    return (
        <div
            className={classNames(animations.fadeIn1, commonStyles.content780)}
        >
            <h1 className={classNames(commonStyles.title, animations.fadeIn2)}>
                Select payroll
            </h1>

            <Alert variant="cta" className="mb-5">
                <LockIcon className="icon-color-current mr-1" /> We’ll follow up
                with instructions on how to provide secure access.
            </Alert>

            {payrollProviderOptions.map((option) => (
                <OptionGroupItem
                    key={option.value}
                    icon={option.icon}
                    title={option.label}
                    selected={selected.includes(option.value)}
                    onToggle={() => handleToggleProvider(option.value)}
                />
            ))}
            <OptionGroupCustomItem
                value={other}
                empty="Other"
                onChange={setOther}
            />
            <Footer className="mt-4" onBack={back}>
                <ButtonWithLoader
                    loading={loading}
                    setLoading={setLoading}
                    variant="secondary"
                    onClick={handleNext}
                >
                    {finalValue.length > 0 ? (
                        <>
                            Next
                            <KeyboardShortcut square className="ml-2">
                                <KeyboardReturnIcon />
                            </KeyboardShortcut>
                        </>
                    ) : (
                        "No payroll"
                    )}
                </ButtonWithLoader>
            </Footer>
        </div>
    );
};
