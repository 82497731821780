import { BillingHistory, BillingStatus } from "../common/types/billing";
import { BillingStatusDto } from "../common/dto/billing/billing-status.dto";
import { BillingInfoDto } from "../common/dto/billing/billing-info.dto";
import { BillingHistoryDto } from "../common/dto/billing/billing-history.dto";
import { backendClient, unwrapResponse } from "./backendClient";

export async function getBillingStatus(
    workspaceId: string,
): Promise<BillingStatus> {
    return BillingStatusDto.deserialize(
        unwrapResponse(
            await backendClient.get(`/billing/billing-status/${workspaceId}`),
        ),
    );
}

export async function getBillingInfo(workspaceId: string) {
    return unwrapResponse(
        await backendClient.get(`/billing/billing-info/${workspaceId}`),
    ) as BillingInfoDto | null;
}

export async function getBillingHistory(
    workspaceId: string,
): Promise<BillingHistory> {
    return BillingHistoryDto.deserialize(
        unwrapResponse(
            await backendClient.get(`/billing/billing-history/${workspaceId}`),
        ),
    );
}
