import React from "react";
import { useBillingStatus } from "../../../../hooks/useBillingStatus";
import { CurrentPlan } from "./CurrentPlan";
import { PaymentDetails } from "./PaymentDetails";
import { BillingHistory } from "./BillingHistory";

export const FlatRateBilling: React.FC = () => {
    const { isSubscribed } = useBillingStatus();

    return (
        <>
            <CurrentPlan />
            {isSubscribed && <PaymentDetails />}
            <BillingHistory />
        </>
    );
};
