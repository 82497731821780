import { Counterparty } from "../../../../common/types/counterparty";
import { RuleConditionAmount } from "../../../../common/types/transactionRule";
import { Category } from "../../../../common/types/category";

export interface TransactionRuleFormSchema {
    conditions: TransactionRuleConditionFormSchema[];
    actions: TransactionRuleActionFormSchema[];
    applyToExistingTransactions: boolean;
    excludedTransactionsIds: number[];
}

export enum FormRuleConditionType {
    CATEGORY = "Category",
    SUGGESTED_CATEGORY = "Suggested category",
    AMOUNT = "Amount",
    BANK_DESCRIPTION = "Bank description",
    ACCOUNT = "Account",
    ENTITY = "Entity",
    COUNTERPARTY = "Vendor",
}

export enum AmountConditionType {
    GREATER_THAN = "Is above",
    LESS_THAN = "Is below",
    BETWEEN = "Is between",
}

export enum DescriptionConditionType {
    CONTAINS = "Contains",
    IS = "Is",
}

export interface TransactionRuleConditionFormSchema {
    id: string;
    conditionType?: FormRuleConditionType;
    amountIsCredit: boolean;
    amountConditionType: AmountConditionType;
    descriptionConditionType: DescriptionConditionType;
    amountSingleValue?: number;
    amountValueRange?: RuleConditionAmount;
    descriptionValue?: string[];
    categoryValue?: Category;
    accountValue?: number[];
    entityValue?: number[];
    counterparty?: Counterparty;
}

export enum FormRuleActionType {
    CATEGORY = "Change category",
    ENTITY = "Move to",
}

export interface TransactionRuleActionFormSchema {
    actionType?: FormRuleActionType;
    categoryValue?: Category;
    entityValue?: number;
}
