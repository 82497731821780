import React from "react";
import { Entity } from "../../common/types/entity";
import { BreakableText } from "../general/BreakableText/BreakableText";
import styles from "./EntityDisplay.module.scss";
import { EntityIcon } from "./EntityIcon";

interface Props {
    entity: Entity;
}

export const EntityDisplay: React.FC<Props> = ({ entity }) => (
    <div className={styles.container}>
        <EntityIcon entity={entity} size="xs" />
        <span className={styles.name}>
            <BreakableText text={entity.name} />
        </span>
    </div>
);
