import React, { useCallback, useEffect } from "react";
import "./AccountsConnectionWidget.scss";
import classNames from "classnames";
import { PlaidConnection } from "../../../common/types/plaidConnection";
import { IntegrationAccount } from "../../../common/types/integrationAccount";
import { ChildrenProps } from "../../../types";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { useCommands } from "../../commands/useCommands";
import { Entity } from "../../../common/types/entity";
import { useFinancialAccountEntitySelection } from "../../../hooks/useFinancialAccountEntitySelection";
import { CommandsDirectory } from "../../commands/lib";
import { isValidAccount } from "./helpers";
import { useAccountsConnectionContext } from "./AccountsConnectionContext";
import { AccountConnectionRow } from "./AccountConnectionRow";

export interface AccountsConnectionOnboardingWidgetProps extends ChildrenProps {
    heading: React.ReactNode;
    emptyConnectPrompt?: React.ReactNode;
    connectPrompt?: React.ReactNode;
    accounts: IntegrationAccount[];
    connection?: PlaidConnection;
    onChangeAccountType?(isBusiness: boolean): Promise<void>;
    showAddAccount?: boolean;
    onAddAccount?: () => void;
    onAddAccountComplete?: () => void;
    defaultEntity?: Entity;
    beforeConnect?: () => Promise<void>;
}

export const AccountsConnectionOnboardingWidget: React.FC<
    AccountsConnectionOnboardingWidgetProps
> = ({
    heading,
    accounts,
    connection,
    emptyConnectPrompt,
    connectPrompt,
    onChangeAccountType,
    showAddAccount,
    onAddAccount,
    onAddAccountComplete,
    defaultEntity,
    beforeConnect,
}) => {
    const { open: openCommands, isOpen } = useCommands();
    const { showBusinessEntitySelection, showPersonalEntitySelection } =
        useFinancialAccountEntitySelection();
    const entitySelection =
        (showBusinessEntitySelection && !defaultEntity?.isBusiness) ||
        showPersonalEntitySelection;
    const cols = entitySelection ? 3 : 2;
    const { showAccountTypeWarning } = useAccountsConnectionContext();
    const hasError =
        showAccountTypeWarning && accounts.some((a) => !isValidAccount(a));

    useEffect(() => {
        if (isOpen) {
            onAddAccount?.();
        } else {
            onAddAccountComplete?.();
        }
    }, [onAddAccountComplete, onAddAccount, isOpen]);

    useEffect(() => {
        if (showAddAccount) {
            openCommands(CommandsDirectory.ONBOARDING, { defaultEntity });
        }
    }, [showAddAccount, openCommands, defaultEntity]);

    const handleAddAccountClick = useCallback(async () => {
        await beforeConnect?.();
        openCommands(CommandsDirectory.ONBOARDING, { defaultEntity });
    }, [beforeConnect, openCommands, defaultEntity]);

    return (
        <table
            className={classNames("table accounts-connection-widget", {
                "accounts-connection-widget--error": hasError,
            })}
        >
            <thead>
                <tr>
                    <th colSpan={cols} className="p-0">
                        <div className="accounts-connection-widget__header">
                            <header>{heading}</header>
                        </div>
                    </th>
                </tr>
                {entitySelection && accounts.length > 0 && (
                    <tr className="small text-grey accounts-connection-widget__header-labels">
                        <th>Account</th>
                        <th className="accounts-connection-widget__classification">
                            Type
                        </th>
                        <th>Entity / Business</th>
                    </tr>
                )}
            </thead>

            <tbody>
                {accounts.length > 0 ? (
                    <>
                        {accounts.map((account) => (
                            <AccountConnectionRow
                                key={account.id}
                                account={account}
                                connection={connection}
                                onChangeAccountType={onChangeAccountType}
                                defaultEntity={defaultEntity}
                            />
                        ))}
                        <tr>
                            <td colSpan={cols}>
                                <ButtonWithLoader
                                    loading={false}
                                    onClick={handleAddAccountClick}
                                    variant="secondary"
                                    disabled={false}
                                >
                                    {connectPrompt}
                                </ButtonWithLoader>
                            </td>
                        </tr>
                    </>
                ) : (
                    <tr>
                        <td colSpan={cols} className="p-0">
                            <div className="accounts-connection-widget__empty">
                                <ButtonWithLoader
                                    loading={false}
                                    onClick={handleAddAccountClick}
                                    variant="secondary"
                                    disabled={false}
                                >
                                    {emptyConnectPrompt}
                                </ButtonWithLoader>
                            </div>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};
