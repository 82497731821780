import classNames from "classnames";
import { EditIcon } from "../../../icons";
import { ToggleableInput } from "../../general/ToggleableInput/ToggleableInput";
import { ActionCardDetail } from "../ActionCardDetail";
import style from "../ActionCardDetail.module.scss";
import { CaptureInteractions } from "../../general/CaptureInteractions";
import { Button } from "../../general/Button/Button";
import { TransactionDetailProps } from "./TransactionDetails";

export const MemoDetail: React.FC<TransactionDetailProps> = ({
    action,
    onUpdate,
}) => {
    const transaction = action.transaction!;
    const transactionUpdate = action.meta.transactionUpdate;
    const memo = transactionUpdate?.memo ?? transaction.memo;

    return (
        <ActionCardDetail label="Note">
            <ToggleableInput
                renderDefaultState={(toggleInput) => (
                    <div
                        {...toggleInput}
                        className={classNames(
                            "form-control form-control-xs cursor-pointer",
                            style.detailInput,
                        )}
                    >
                        {memo ? (
                            <div
                                className={style.detailInputText}
                                data-testid="transaction-note"
                            >
                                {memo}{" "}
                            </div>
                        ) : (
                            <span className={style.detailInputPlaceholder}>
                                Add note
                            </span>
                        )}
                        <aside className="ml-auto">
                            <Button variant="tertiary" icon size="sm">
                                <EditIcon />
                            </Button>
                        </aside>
                    </div>
                )}
                renderInputState={(toggleInput, inputRef) => (
                    <CaptureInteractions>
                        <input
                            className="form-control form-control-xs"
                            ref={inputRef}
                            defaultValue={memo ?? ""}
                            placeholder="Add note"
                            onBlur={(event) => {
                                onUpdate({
                                    memo: event.target.value,
                                });
                                toggleInput.onClick();
                            }}
                        />
                    </CaptureInteractions>
                )}
            />
        </ActionCardDetail>
    );
};
