import {
    InvoiceFilters,
    InvoiceFiltersSchema,
} from "../common/contracts/invoice.contract";
import {
    useWorkspaceUserCache,
    WORKSPACE_USER_CACHE_KEYS,
} from "./useWorkspaceUserCache";

export const FILTER_PARAMS_TO_CACHE: Record<keyof InvoiceFilters, boolean> = {
    search: false,
    start: true,
    end: true,
    dueStart: true,
    dueEnd: true,
    minAmount: true,
    maxAmount: true,
    accountIds: true,
    entityIds: true,
    statuses: true,
    counterpartiesIds: true,
};

const sanitizeFilters = (
    filters: InvoiceFilters | undefined,
): InvoiceFilters | undefined => {
    if (!filters) {
        return undefined;
    }

    const clearedFilters: Record<string, unknown> = {};

    Object.keys(filters).forEach((key) => {
        if (FILTER_PARAMS_TO_CACHE[key as keyof InvoiceFilters]) {
            clearedFilters[key] = filters[key as keyof InvoiceFilters];
        }
    });

    return clearedFilters as InvoiceFilters;
};

export function useInvoicesPageFiltersCache(): [
    InvoiceFilters | undefined,
    (value: InvoiceFilters | undefined) => void,
] {
    const [value, setter] = useWorkspaceUserCache<InvoiceFilters>(
        WORKSPACE_USER_CACHE_KEYS.INVOICES_PAGE_FILTERS_CACHE_KEY,
        InvoiceFiltersSchema,
    );

    return [
        value,
        (filters: InvoiceFilters | undefined) => {
            setter(sanitizeFilters(filters));
        },
    ];
}
