import { StepConfig } from "./types";

const productPreviewSidebarKey = "productPreview";

export const onboardingStepsConfigs = {
    start: {
        cover: "full",
    },
    verify: {
        cover: "full",
    },
    profile: {
        cover: "split",
        sidebar: productPreviewSidebarKey,
    },
    firmInfo: {
        cover: "split",
        sidebar: productPreviewSidebarKey,
    },
    introCall: {
        cover: "full",
    },
    waitlistIntro: {
        cover: "split",
        sidebar: productPreviewSidebarKey,
    },
    waitlistComment: {
        cover: "split",
        sidebar: productPreviewSidebarKey,
    },
    waitlistOutro: {
        cover: "split",
        sidebar: productPreviewSidebarKey,
    },
    accountantWaitlistIntro: {
        cover: "split",
        sidebar: productPreviewSidebarKey,
    },
    accountantWaitlistOutro: {
        cover: "split",
        sidebar: productPreviewSidebarKey,
    },
    outro: {
        cover: "full",
    },
    connectAccounts: {
        cover: "sidebar",
    },
    paymentProcessor: {
        cover: "sidebar",
    },
    payroll: {
        cover: "sidebar",
    },
    choosePlan: {
        cover: "full",
    },
    howItWorks: {
        cover: "none",
    },
    inviteProfile: {
        cover: "split",
    },
    mobileBlock: {
        cover: "full",
    },
} as const satisfies Record<string, StepConfig>;

export type OnboardingStepKey = keyof typeof onboardingStepsConfigs;
