import classNames from "classnames";
import { MutableRefObject, useCallback, useEffect, useRef } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useInteraction } from "../../../hooks/useInteraction";
import css from "./Tree.module.scss";
import { NodeRenderer, TreeNode, WithId } from "./Tree.types";
import { useTreeContext } from "./useTreeContext";

export interface TreeProps<T extends WithId> {
    nodes?: Array<TreeNode<T>>;
    depth?: number;
    renderNode: NodeRenderer<T>;
    scrollbarRef?: React.RefObject<Scrollbars>;
    scrollToNodeIdOnOpen?: string;
    wasScrolledRef?: MutableRefObject<boolean>;
}

export const Tree = <T extends WithId>({
    nodes,
    renderNode,
    depth = 0,
    scrollbarRef,
    scrollToNodeIdOnOpen,
    wasScrolledRef,
}: TreeProps<T>) => {
    const { nodes: rootNodes } = useTreeContext<T>();

    const locallyCreatedWasScrolledRef = useRef(false);

    return (
        <ul className={classNames(css.tree, { [css.root]: depth === 0 })}>
            {(nodes ?? rootNodes).map((node) => (
                <TreeItem
                    scrollbarRef={scrollbarRef}
                    key={node.nodeId}
                    scrollToNodeIdOnOpen={scrollToNodeIdOnOpen}
                    node={node}
                    depth={depth}
                    renderNode={renderNode}
                    wasScrolledRef={
                        // NOTE: if wasScrolledRef is not provided, we create a new ref on a top level
                        wasScrolledRef ?? locallyCreatedWasScrolledRef
                    }
                />
            ))}
        </ul>
    );
};

interface TreeItemProps<T extends WithId> {
    readonly node: TreeNode<T>;
    readonly depth: number;
    readonly renderNode: NodeRenderer<T>;
    readonly scrollToNodeIdOnOpen?: string;
    readonly scrollbarRef?: React.RefObject<Scrollbars>;
    readonly wasScrolledRef: MutableRefObject<boolean>;
}

function TreeItem<T extends WithId>({
    node,
    depth,
    renderNode,
    scrollbarRef,
    scrollToNodeIdOnOpen,
    wasScrolledRef,
}: TreeItemProps<T>) {
    const context = useTreeContext<T>();
    const { isExpanded, hoverNode, unhoverNode } = context;

    const onMouseEnter = useCallback(
        () => hoverNode(node.nodeId),
        [hoverNode, node.nodeId],
    );
    const onMouseLeave = useCallback(
        () => unhoverNode(node.nodeId),
        [unhoverNode, node.nodeId],
    );
    const handleMouseEnter = useInteraction(onMouseEnter, "onMouseEnter");
    const handleMouseLeave = useInteraction(onMouseLeave, "onMouseLeave");

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (
            node.current.id === scrollToNodeIdOnOpen &&
            wasScrolledRef.current === false
        ) {
            scrollbarRef?.current?.scrollTop(
                containerRef.current?.offsetTop ?? 0,
            );
            containerRef.current?.focus();
            wasScrolledRef.current = true;
        }
    }, [node, node.nodeId, scrollToNodeIdOnOpen, scrollbarRef, wasScrolledRef]);

    return (
        <li
            key={node.nodeId}
            style={
                {
                    "--depth": depth,
                } as any
            }
            className={classNames(css.node, node.className, {
                [css.childNode]: depth > 0,
            })}
        >
            <div
                ref={containerRef}
                className={css.body}
                {...handleMouseEnter}
                {...handleMouseLeave}
            >
                {renderNode(node, context)}
            </div>
            {node.children.length > 0 && isExpanded(node.nodeId) && (
                <Tree
                    scrollToNodeIdOnOpen={scrollToNodeIdOnOpen}
                    nodes={node.children}
                    renderNode={renderNode}
                    depth={depth + 1}
                    wasScrolledRef={wasScrolledRef}
                />
            )}
        </li>
    );
}
