import { useMemo } from "react";
import { partition } from "lodash";
import { useEntities } from "./useEntities";

export function useFinancialAccountEntitySelection() {
    const entitiesToAdminister = useEntities({
        requireAdminAccess: true,
        excludeEmptyMockEntities: false,
    });

    const [businessEntitiesToAdminister, personalEntitiesToAdminister] =
        useMemo(
            () => partition(entitiesToAdminister, (e) => e.isBusiness),
            [entitiesToAdminister],
        );

    return useMemo(
        () => ({
            businessEntitiesToAdminister,
            personalEntitiesToAdminister,
            showBusinessEntitySelection:
                businessEntitiesToAdminister.length !== 1,
            showPersonalEntitySelection:
                personalEntitiesToAdminister.length !== 1,
            nonMockBusinessEntities: businessEntitiesToAdminister.filter(
                (e) => !e.isMockEntity,
            ),
        }),
        [businessEntitiesToAdminister, personalEntitiesToAdminister],
    );
}
