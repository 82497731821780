import React, { useState } from "react";
import { PopupModal, useCalendlyEventListener } from "react-calendly";
import { useKeyboardCommands } from "../../../hooks/keyboard/useKeyboardCommands";
import { ChildrenProps } from "../../../types";
import { Button, ExtendedButtonProps } from "../Button/Button";
import { useWidgetOptions } from "./useWidgetOptions";

interface BookCallProps
    extends Pick<
            ExtendedButtonProps,
            "variant" | "size" | "disabled" | "className"
        >,
        ChildrenProps {
    url: string;
    keyboardShortcut?: string;
    onBooked?: () => void;
}

export const BookCallButton: React.FC<BookCallProps> = ({
    url,
    children,
    onBooked,
    keyboardShortcut,
    ...btnProps
}) => {
    const { prefill, pageSettings } = useWidgetOptions();
    const [open, setOpen] = useState(false);

    useCalendlyEventListener({
        onEventScheduled: onBooked,
    });

    useKeyboardCommands({
        enabled: !!keyboardShortcut,
        commands: [
            {
                key: keyboardShortcut ?? "",
                callback: () => setOpen(true),
                requiresCtrlOrMeta: false,
                repeat: false,
            },
        ],
    });

    return (
        <>
            <Button {...btnProps} onClick={() => setOpen(true)}>
                {children}
            </Button>

            <PopupModal
                onModalClose={() => setOpen(false)}
                open={open}
                rootElement={document.body}
                url={url}
                pageSettings={pageSettings}
                prefill={prefill}
            />
        </>
    );
};
