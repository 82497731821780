import { createContext } from "react";
import { UserWorkspaceRole } from "../../common/userWorkspaceRoles";
import { WorkspaceMember } from "../../common/types/workspace";
import { noop } from "../../helpers/general";

export interface AddMemberOptions {
    email: string;
    role: UserWorkspaceRole;
    onInvited?: () => void;
}

export interface MemberManagementContextValue {
    configurePermissions(member: WorkspaceMember): void;
    addMember(options?: AddMemberOptions): void;
}

export const memberManagementContext =
    createContext<MemberManagementContextValue>({
        configurePermissions: noop,
        addMember: noop,
    });
