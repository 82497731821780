import { Counterparty } from "../../types/counterparty";
import { Transaction } from "../../types/transaction";
import { TransactionDto } from "./transaction.dto";

export class TransactionWithCounterpartyDto extends TransactionDto {
    counterparty?: Counterparty;

    static deserialize(dto: TransactionWithCounterpartyDto): Transaction {
        return {
            ...TransactionDto.deserialize(dto),
            counterparty: dto.counterparty ?? null,
        };
    }

    constructor(transaction: Transaction) {
        super(transaction);

        this.counterparty = transaction.counterparty ?? undefined;
    }
}
