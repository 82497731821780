import { isObject } from "lodash";
import { Taxonomy } from "../categories";
import { Category } from "../types/category";

export function isValidTaxonomy(value: string): value is Taxonomy {
    return value in Taxonomy;
}

export function getMainCategory(
    category: string | Category,
    categoriesById: Record<string, Category>,
) {
    const categoryObject = isObject(category)
        ? category
        : categoriesById[category];

    if (categoryObject) {
        return categoryObject.parentCategoryId
            ? categoriesById[categoryObject.parentCategoryId]
            : categoryObject;
    }
}
