import React, { useMemo } from "react";
import { keyBy } from "lodash";
import { PLCell } from "../../../common/dto/reports/reports-common.dto";
import { DataTableCell } from "../../general/DataTable/DataTableCell";
import { DataTableRow } from "../../general/DataTable/DataTableRow";
import { ProfitLossValueCell } from "./ProfitLossValueCell";

interface Props {
    title: string;
    values: PLCell[];
    columns: string[];
    isExpandable?: boolean;
    isExpanded?: boolean;
    onExpand?: () => void;
    isTotal?: boolean;
    isSummary?: boolean;
    depth?: number;
    accountCodes?: number[];
}

export const ProfitLossTableRow: React.FC<Props> = ({
    title,
    isExpandable,
    isExpanded,
    onExpand,
    values,
    columns,
    isTotal,
    isSummary,
    depth = 0,
    accountCodes,
}) => {
    const valuesByColumn = useMemo(
        () => keyBy(values, (c) => c.label),
        [values],
    );
    const total = useMemo(
        () => values.reduce((acc, c) => acc + c.value, 0),
        [values],
    );
    const showValues = !isExpandable || !isExpanded;

    return (
        <DataTableRow depth={depth} isTotal={isTotal} isSummary={isSummary}>
            <DataTableCell
                isExpandable={isExpandable}
                isTitle
                onExpand={onExpand}
                isExpanded={isExpanded}
            >
                {title}
            </DataTableCell>
            {columns.map((column) =>
                showValues ? (
                    <ProfitLossValueCell
                        value={valuesByColumn[column].value}
                        accountCodes={accountCodes}
                        label={column}
                        key={column}
                    />
                ) : (
                    <DataTableCell key={column} />
                ),
            )}
            {showValues ? (
                <ProfitLossValueCell
                    value={total}
                    accountCodes={accountCodes}
                />
            ) : (
                <DataTableCell />
            )}
        </DataTableRow>
    );
};
