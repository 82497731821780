import { useMemo } from "react";
import { useEntities } from "../../../hooks/useEntities";
import { EntitySelect } from "../../entity/EntitySelect";
import { ActionCardDetail } from "../ActionCardDetail";
import { TransactionDetailProps } from "./TransactionDetails";

export const EntityDetail: React.FC<TransactionDetailProps> = ({
    action,
    onUpdate,
}) => {
    const entities = useEntities({
        onlyBusiness: true,
        excludeAllMockEntities: true,
    });

    const selectedEntity = useMemo(
        () =>
            entities.find(
                (entity) =>
                    entity.id === action.meta.transactionUpdate?.entityId,
            ),
        [action, entities],
    );
    return (
        <ActionCardDetail label="Entity">
            <EntitySelect
                size="xs"
                entities={entities}
                selectedEntity={selectedEntity}
                onChange={(entity) =>
                    onUpdate({
                        entityId: entity?.id,
                    })
                }
                dropdownKey={`action-detail-entity-${action.id}`}
            />
        </ActionCardDetail>
    );
};
