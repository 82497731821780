import { format } from "date-fns";
import { cloneDeep } from "lodash";
import React, { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { REPORTS_CONTROLLER_DATE_FORMAT } from "../../common/constants";
import { ReportFilters } from "../../common/types/filters/reports";
import { getCashFlowTabReport } from "../../lib/ownerReports";
import { ChildrenProps } from "../../types";
import { useEntities } from "../../hooks/useEntities";
import { CashFlowContext } from "./cash-flow.context";

interface Props extends ChildrenProps {
    filters: ReportFilters;
}

interface ReportsQueryOptions {
    filters: ReportFilters;
    refetchOnWindowFocus?: boolean;
}

function useCashFlowQuery({
    filters,
    refetchOnWindowFocus = false,
}: ReportsQueryOptions) {
    return useQuery({
        queryKey: ["cashFlowTabReport", filters],
        queryFn: async () => {
            const filtersUsed = cloneDeep(filters);

            return {
                report: await getCashFlowTabReport({
                    startDate: filters.start
                        ? format(filters.start, REPORTS_CONTROLLER_DATE_FORMAT)
                        : undefined,
                    endDate: filters.end
                        ? format(filters.end, REPORTS_CONTROLLER_DATE_FORMAT)
                        : undefined,
                    cycle: filters.cycle,
                    entityIds: filters.entityIds,
                    accountIds: filters.accountIds,
                    category: filters.category,
                    classes: filters.classes,
                    classFilterType: filters.classFilterType,
                }),
                filtersUsed,
                reportCreatedAt: new Date().getTime(),
            };
        },
        refetchOnWindowFocus,
    });
}

export const CashFlowContextProvider: React.FC<Props> = ({
    filters,
    children,
}) => {
    const entities = useEntities();

    const filtersToPass = {
        ...filters,
        entityIds:
            filters.entityIds && filters.entityIds.length > 0
                ? filters.entityIds
                : entities.map((entity) => entity.id),
    };

    const { data, isLoading: isCashFlowReportLoading } = useCashFlowQuery({
        filters: filtersToPass,
    });

    const value = useMemo(
        () => ({
            cashFlowReport: data?.report,
            cashFlowFiltersUsed: data?.filtersUsed,
            reportCreatedAt: data?.reportCreatedAt,
            isCashFlowReportLoading,
        }),
        [data, isCashFlowReportLoading],
    );

    return (
        <CashFlowContext.Provider value={value}>
            {children}
        </CashFlowContext.Provider>
    );
};
