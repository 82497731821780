import React from "react";

export interface SplittedFilterLabelProps {
    filters: {
        isSplitted?: boolean;
        isNotSplitted?: boolean;
    };
}

export const SplittedFilterLabel: React.FC<SplittedFilterLabelProps> = ({
    filters: { isSplitted, isNotSplitted },
}) => {
    if (isSplitted && !isNotSplitted) {
        return <>Split</>;
    }

    if (!isSplitted && isNotSplitted) {
        return <>Not split</>;
    }
};
