import React from "react";
import { KickIcon } from "../../../../../icons";
import { ActionCard, ActionCardProps } from "../../../../actions/ActionCard";
import { ActionCardSection } from "../../../../actions/ActionCardSection";
import { ActionConfirmButton } from "../../../../actions/ActionConfirmButton";
import { DemoActionProps } from "../lib";

export const StartPreviewActionCard: React.FC<DemoActionProps> = ({
    onConfirm,
    isCompleted,
    ...rest
}) => {
    const firstSection: ActionCardProps["firstSection"] = (
        inOpened,
        closeCard,
    ) => (
        <ActionCardSection
            icon={<KickIcon />}
            header="Start your Kick preview"
            isTitle
            inOpened={inOpened}
            ctas={
                <ActionConfirmButton
                    onClick={() => {
                        onConfirm?.();
                        closeCard();
                    }}
                >
                    Preview
                </ActionConfirmButton>
            }
        />
    );

    return (
        <ActionCard
            firstSection={firstSection}
            isCompleted={isCompleted}
            {...rest}
        />
    );
};
