import { useCallback, useState } from "react";
import { isEqual, omitBy } from "lodash";
import { FinancialDocumentFilters } from "./FinancialDocumentsPageFilters/lib";

const DEFAULT_FILTERS = {};

export function useFinancialDocumentFilters(onFiltersChange: () => void) {
    const [filters, setFilters] =
        useState<FinancialDocumentFilters>(DEFAULT_FILTERS);
    const handleFiltersChange = useCallback(
        (value: FinancialDocumentFilters) => {
            const newFilters = omitBy(
                { ...filters, ...value },
                (v) => v === null || v === undefined,
            );

            if (!isEqual(newFilters, filters)) {
                setFilters(newFilters);
                onFiltersChange();
            }
        },
        [filters, onFiltersChange],
    );

    const resetFilters = useCallback(() => {
        setFilters({});
        onFiltersChange();
    }, [onFiltersChange]);

    const hasActiveFilters = !isEqual(filters, {});

    return {
        filters,
        handleFiltersChange,
        resetFilters,
        hasActiveFilters,
    };
}
