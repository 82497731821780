import { Col, Row } from "react-bootstrap";
import React from "react";
import { useFormikContext } from "formik";
import { sortBy } from "lodash";
import { FormRow } from "../../forms/FormRow";
import {
    CustomSelect,
    CustomSelectOption,
} from "../../forms/CustomSelect/CustomSelect";

import { EntityProfileFormSchema } from "../types";
import { EntityProfileIndustry } from "../../../common/types/entity";
import { annualRevenueBrackets, entityTypes, industries } from "../constants";
import { entityTypeV2Labels, industryLabels } from "../../entity/constants";
import { COUNTRIES } from "../../../common/countries";

const entityTypeOptions: CustomSelectOption[] = entityTypes.map((value) => ({
    value,
    label: entityTypeV2Labels[value],
}));

const industryOptions: CustomSelectOption[] = industries.map((value) => ({
    value,
    label: industryLabels[value],
}));

const preferredCountries = ["US", "CA", "GB"];

const countryOptions: CustomSelectOption[] = sortBy(COUNTRIES, [
    (country) =>
        preferredCountries.includes(country.code)
            ? preferredCountries.indexOf(country.code)
            : 100,
    (country) => country.name,
]).map(({ name, code }) => ({
    value: code,
    label: name,
}));

const annualRevenueOptions: CustomSelectOption[] = annualRevenueBrackets.map(
    (value) => ({ value, label: value }),
);

export const EntityProfileFormFields = <
    T extends EntityProfileFormSchema,
>() => {
    const { values } = useFormikContext<T>();

    const isOtherIndustry = values.industry === EntityProfileIndustry.OTHER;

    return (
        <>
            <FormRow fieldName="name" label="Business name" size="sm" />

            <Row>
                <Col sm={6}>
                    <FormRow fieldName="type">
                        {(helpers, field) => (
                            <CustomSelect
                                label="Entity type"
                                value={field.value}
                                onSelected={(value) => helpers.setValue(value)}
                                dropdownKey={field.name}
                                options={entityTypeOptions}
                                fullWidthDropdown
                                size="sm"
                            />
                        )}
                    </FormRow>
                </Col>

                <Col sm={6}>
                    <FormRow fieldName="industry">
                        {(helpers, field) => (
                            <CustomSelect
                                label="Industry"
                                value={field.value}
                                onSelected={(value) => {
                                    if (value !== EntityProfileIndustry.OTHER) {
                                        values.industryOther = "";
                                    }
                                    helpers.setValue(value);
                                }}
                                dropdownKey={field.name}
                                options={industryOptions}
                                fullWidthDropdown
                                size="sm"
                            />
                        )}
                    </FormRow>
                </Col>
            </Row>

            {isOtherIndustry && (
                <FormRow
                    fieldName="industryOther"
                    label="What industry are you in? (optional)"
                    size="sm"
                />
            )}

            <FormRow fieldName="country">
                {(helpers, field) => (
                    <CustomSelect
                        label="Where does your business pay taxes?"
                        value={field.value}
                        onSelected={(value) => helpers.setValue(value)}
                        dropdownKey={field.name}
                        options={countryOptions}
                        fullWidthDropdown
                        searchable
                        size="sm"
                    />
                )}
            </FormRow>

            <FormRow fieldName="annualRevenue">
                {(helpers, field) => (
                    <CustomSelect
                        label="Annual revenue"
                        value={field.value}
                        onSelected={helpers.setValue}
                        dropdownKey={field.name}
                        options={annualRevenueOptions}
                        fullWidthDropdown
                        size="sm"
                    />
                )}
            </FormRow>
        </>
    );
};
