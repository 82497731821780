import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useDebouncedEffect } from "../../../hooks/useDebouncedEffect";
import { ResizableTextInput } from "../ResizableTextInput/ResizableTextInput";
import { SearchIcon } from "../../../icons";
import { SEARCH_DEBOUNCE_TIME } from "../../../constants";
import styles from "./SearchFilter.module.scss";

export interface SearchFilterProps {
    filter: string | undefined;
    onChange(value: string | undefined): void;
    minLength?: number;
    placeholder?: string;
    dataTestId?: string;
    containerClassName?: string;
    debounceTime?: number;
}

export const SearchFilter: React.FC<SearchFilterProps> = ({
    filter,
    onChange,
    placeholder = "Search...",
    dataTestId = "search-filter",
    minLength = 3,
    containerClassName,
    debounceTime = SEARCH_DEBOUNCE_TIME,
}) => {
    const [value, setValue] = useState<string>(filter ?? "");
    const prevValue = useRef(value);
    const debounceFn = useCallback(() => {
        if (prevValue.current === value) {
            return;
        }
        prevValue.current = value;
        if (value.length >= minLength) {
            onChange(value);
        } else {
            onChange(undefined);
        }
    }, [minLength, onChange, value]);

    useDebouncedEffect(debounceFn, [debounceFn], debounceTime);

    useEffect(() => {
        if (!filter) {
            setValue((currentValue) =>
                currentValue.length < minLength ? currentValue : "",
            );
        }
    }, [filter, minLength]);

    return (
        <ResizableTextInput
            value={value}
            onChange={setValue}
            icon={<SearchIcon />}
            size="xs"
            placeholder={placeholder}
            dataTestId={dataTestId}
            containerClassName={classNames(
                styles.container,
                containerClassName,
            )}
        />
    );
};
