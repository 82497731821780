import { CalendarIcon } from "../../../icons";
import { ActionActivity } from "../ActionActivity";
import { CampaignActivityProps } from "../ActionActivityItem";

export const BookOnboardingCallActivity: React.FC<CampaignActivityProps> = ({
    animated,
}) => (
    <ActionActivity icon={<CalendarIcon />} animated={animated}>
        Kick review call booked
    </ActionActivity>
);
