import React from "react";
import { Form, useFormikContext } from "formik";
import classNames from "classnames";
import { Col, Row } from "react-bootstrap";
import animation from "../layout/OnboardingLayout.module.scss";
import { FormRow } from "../../forms/FormRow";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { KeyboardReturnIcon } from "../../../icons";
import { KeyboardShortcut } from "../../general/KeyboardShortcut/KeyboardShortcut";
import styles from "./Profile.module.scss";
import commonStyles from "./common.module.scss";

interface Props {
    loading: boolean;
}

export const InviteProfile: React.FC<Props> = ({ loading }) => {
    const { isValid } = useFormikContext();

    return (
        <div className={commonStyles.content420}>
            <header className={classNames(animation.fadeIn2)}>
                <h1 className={classNames(commonStyles.title, styles.header)}>
                    Finish setting up your account
                </h1>
            </header>

            <Form className={classNames(animation.fadeIn1)}>
                <Row>
                    <Col>
                        <FormRow
                            fieldName="firstName"
                            label="First name"
                            size="sm"
                        />
                    </Col>

                    <Col>
                        <FormRow
                            fieldName="lastName"
                            label="Last name"
                            size="sm"
                        />
                    </Col>
                </Row>

                <footer>
                    <ButtonWithLoader
                        type="submit"
                        variant="secondary"
                        loading={loading}
                        block
                        disabled={!isValid}
                    >
                        Submit
                        <KeyboardShortcut square className="ml-2">
                            <KeyboardReturnIcon />
                        </KeyboardShortcut>
                    </ButtonWithLoader>
                </footer>
            </Form>
        </div>
    );
};
