import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import { TransactionsFilters } from "../filters/lib";
import { Transaction } from "../../../common/types/transaction";
import { Sort } from "../useSort";
import { transferMatchingQueryKeys } from "../../documents/lib";
import {
    getSuggestedMatchesForTransaction,
    getTransactions,
} from "../../../lib/transactions";
import { convertFiltersToDto } from "../useTransactionsQuery";
import { queryClient } from "../../../queryClient";
import { EagerLoaded } from "../../../common/types/base/orm";
import { WithRequiredProperties } from "../../../common/types/base/generics";

export enum MatchingFiltersPreferenceOption {
    Suggested = "suggested",
    All = "all",
}

interface UseTransactionsForTransferMatchingParams {
    preference: MatchingFiltersPreferenceOption;
    transaction: Transaction;
    queryFiltersToPass: WithRequiredProperties<
        TransactionsFilters,
        "entityIds"
    >;
    sortExpression?: Sort;
}

export function useTransactionsForTransferMatching({
    preference,
    transaction,
    queryFiltersToPass,
    sortExpression,
}: UseTransactionsForTransferMatchingParams) {
    return useInfiniteQuery({
        queryKey: [
            ...transferMatchingQueryKeys.list(queryFiltersToPass),
            preference,
            sortExpression,
        ],
        queryFn: async ({ pageParam: page = 0 }) => {
            const result =
                preference === MatchingFiltersPreferenceOption.Suggested
                    ? await getSuggestedMatchesForTransaction(transaction.id, {
                          filters: convertFiltersToDto(queryFiltersToPass),
                          page,
                          limit: 100,
                          sort: sortExpression,
                      })
                    : await getTransactions({
                          filters: convertFiltersToDto(queryFiltersToPass),
                          page,
                          limit: 20,
                          sort: sortExpression,
                      });
            return {
                ...result,
                page,
                nextPage: result.pageCount > page + 1 ? page + 1 : undefined,
            };
        },
        getNextPageParam: (lastPage) => {
            if (lastPage) {
                return lastPage.nextPage;
            }
            return 0;
        },
        initialPageParam: 0,
    });
}

export function updateTransactionInTransactionsForTransferMatchingQuery(
    transactionId: number,
    transactionUpdate: Partial<Transaction>,
) {
    queryClient.setQueriesData({ queryKey: transferMatchingQueryKeys.all() }, ((
        oldData: InfiniteData<{
            data: Array<
                EagerLoaded<Transaction, "transactionMatches" | "category">
            >;
        }>,
    ) => ({
        ...oldData,
        pages: oldData.pages.map((page) => ({
            ...page,
            data: page.data.map((fetchedTransaction) =>
                fetchedTransaction.id === transactionId
                    ? {
                          ...fetchedTransaction,
                          ...transactionUpdate,
                      }
                    : fetchedTransaction,
            ),
        })),
    })) as any);
}
