import React from "react";
import { AmountFilterLabel } from "../../general/ActiveFilters/AmountFilterLabel";
import { CounterpartiesFilterLabel } from "../../general/ActiveFilters/CounterpartiesFilterLabel";
import { DateFilterLabel } from "../../general/ActiveFilters/DateFilterLabel";
import { MatchedFilterLabel } from "../../general/ActiveFilters/MatchedFilterLabel";
import { NoteFilterLabel } from "../../general/ActiveFilters/NoteFilterLabel";
import { ReceiptsFilterLabel } from "../../general/ActiveFilters/ReceiptsFilterLabel";
import { SplittedFilterLabel } from "../../general/ActiveFilters/SplittedFilterLabel";
import { useAggregatedFiltersContext } from "../../general/AggregatedFilters/AggregatedFilters.context";
import { AccountsFilterLabel } from "../../general/ActiveFilters/AccountsFilterLabel";
import { ActiveFilterButton } from "../../general/ActiveFilters/ActiveFilterButton";
import { DirectionFilterLabel } from "../../general/ActiveFilters/DirectionFilterLabel";
import { RulesFilterLabel } from "../../general/ActiveFilters/RulesFilterLabel";
import { CategoryFilterLabel } from "../../general/ActiveFilters/CategoryFilterLabel";
import { ClassFilterLabel } from "../../general/ActiveFilters/ClassFilterLabel";
import { useResetFilters } from "../../general/ActiveFilters/useResetFilters";
import { StatusFilterLabel } from "../../general/ActiveFilters/StatusFilterLabel";
import { AccountCodeFilterLabel } from "../../accounting/AccountingPageFilters/AccountFilterLabel";
import { AccountStatusFilterLabel } from "../../accounting/AccountingPageFilters/AccountStatusFilterLabel";
import { FiltersTypes } from "../../../common/types/filters/general";
import {
    ActiveFilter,
    ActiveFilterToFilterTabsMap,
    FiltersChangeFn,
    resetPayload,
} from "./lib";

interface Props {
    filterId: ActiveFilter;
    filters: FiltersTypes;
    onChange: FiltersChangeFn;
}

export const ActiveFilterButtonLabel: React.FC<Props> = ({
    filterId,
    filters,
    onChange,
}) => {
    const { open: openFilter } = useAggregatedFiltersContext();
    const resetFilter = useResetFilters(resetPayload, onChange, filterId);

    let label;

    switch (filterId) {
        case "amount":
            label = <AmountFilterLabel filters={filters} />;
            break;

        case "category":
            label = <CategoryFilterLabel filters={filters} />;
            break;

        case "date":
            label = <DateFilterLabel filters={filters} />;
            break;

        case "classes":
            label = <ClassFilterLabel filters={filters} />;
            break;

        case "direction":
            label = <DirectionFilterLabel filters={filters} />;
            break;

        case "rules":
            label = <RulesFilterLabel filters={filters} />;
            break;

        case "counterparties":
            label = <CounterpartiesFilterLabel filters={filters} />;
            break;

        case "isMatched":
            label = <MatchedFilterLabel filters={filters} />;
            break;

        case "isSplitted":
            label = <SplittedFilterLabel filters={filters} />;
            break;

        case "note":
            label = <NoteFilterLabel filters={filters} />;
            break;

        case "receipts":
            label = <ReceiptsFilterLabel filters={filters} />;
            break;

        case "accounts":
            label = <AccountsFilterLabel filters={filters} />;
            break;

        case "documentStatus":
            label = <StatusFilterLabel filters={filters} />;
            break;

        case "accountCodes":
            label = (
                <AccountCodeFilterLabel
                    accountCodes={filters.accountCodes}
                    entityId={filters.entityId}
                />
            );
            break;

        case "accountStatus":
            label = <AccountStatusFilterLabel filters={filters} />;
            break;
    }

    return (
        <ActiveFilterButton
            openFilter={() => openFilter(ActiveFilterToFilterTabsMap[filterId])}
            resetFilter={resetFilter}
            label={label}
        />
    );
};
