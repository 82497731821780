import { animated, useSpring } from "@react-spring/web";
import colors from "@styles/colors.module.scss";
import React, { useCallback, useEffect } from "react";
import { AnimatedCard } from "../general/Card/AnimatedCard";
import styles from "./ActionCard.module.scss";
import { ActionCardSkipped } from "./ActionCardSkipped";

export interface ActionCardProps {
    opened: boolean;
    isCompleted?: boolean;
    isSkipped?: boolean;
    onClick?(): void;
    onCardClosing?(): void;
    onCardClosed?(): void;
    firstSection(inOpened: boolean, closeCard: () => void): React.ReactNode;
    otherSections?(inOpened?: boolean): React.ReactNode;
}

const commonCardStyles = {
    display: "grid",
    gridTemplateRows: "1fr",
    paddingBlock: styles.spacing,
    marginBottom: styles.spacing,
    marginTop: "0px",
    borderWidth: "1px",
    transform: "scale(1)",
    willChange: "transform, opacity",
};

const noBoxShadow = `0px 0px 0px 0px transparent`;
const closedCard = {
    boxShadow: noBoxShadow,
    borderColor: colors.miscAlpha300,
    ...commonCardStyles,
};

const openedCard = {
    opacity: 1,
    boxShadow: `0px 0px 30px 0px ${colors.blue600}1a`,
    borderColor: colors.blue300,
    ...commonCardStyles,
};

const removedCard = {
    ...openedCard,
    opacity: 0,
    // There's a 6 pixel difference between the card
    // existing in the DOM and not existing in the DOM
    // this is a hack to make the transition smoother
    marginTop: "-106px",
    transform: "scale(0.64)",
    willChange: "transform, opacity",
};

const sectionsCommonStyles = {
    display: "grid",
};
const closedSections = {
    gridTemplateRows: "0fr",
    opacity: 0,
    ...sectionsCommonStyles,
};
const openedSections = {
    opacity: 1,
    gridTemplateRows: "1fr",
    ...sectionsCommonStyles,
};

export const ActionCard: React.FC<ActionCardProps> = ({
    opened,
    isCompleted,
    isSkipped,
    onClick,
    onCardClosing,
    onCardClosed,
    firstSection,
    otherSections,
}) => {
    const [cardSpring, cardApi] = useSpring(() => ({
        from: closedCard,
    }));
    const [sectionsSpring, sectionsApi] = useSpring(() => ({
        from: closedSections,
    }));
    const [cardClosing, setCardClosing] = React.useState(false);
    const [cardClosed, setCardClosed] = React.useState(false);

    useEffect(() => {
        if (!isCompleted && !isSkipped) {
            cardApi.start(opened ? openedCard : closedCard);
            sectionsApi.start(opened ? openedSections : closedSections);
        }
    }, [opened, isSkipped, isCompleted, cardApi, sectionsApi]);

    const closeCard = useCallback(() => {
        if (cardClosing || cardClosed) {
            return;
        }

        onCardClosing?.();
        setCardClosing(true);
        cardApi.start(removedCard)[0].then(() => {
            onCardClosed?.();
            setCardClosed(true);
        });
    }, [cardClosing, cardClosed, onCardClosing, cardApi, onCardClosed]);

    useEffect(() => {
        if (isCompleted || isSkipped) {
            sectionsApi.start(closedSections);
        }
    }, [isCompleted, sectionsApi, closeCard, isSkipped]);

    return (
        <AnimatedCard
            className={styles.card}
            onClick={onClick}
            style={cardSpring}
        >
            {isSkipped ? (
                <ActionCardSkipped header="Skipped" onClose={closeCard} />
            ) : (
                firstSection(opened, closeCard)
            )}
            {otherSections && (
                <animated.div style={sectionsSpring}>
                    <div className={styles.otherSections}>
                        {otherSections(opened)}
                    </div>
                </animated.div>
            )}
        </AnimatedCard>
    );
};
