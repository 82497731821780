import React, { useContext } from "react";
import { Button } from "../../general/Button/Button";
import { useTransactionRules } from "../../../hooks/useTransactionRules";
import { TransactionRuleCreationContext } from "../../transactionRules/TransactionRuleCreation/TransactionRuleCreation.context";
import { RuleSource } from "../../../common/types/transactionRule";
import { RulesSettingsContent } from "./RulesSettingsContent";

export const RulesSettings: React.FC = () => {
    const rules = useTransactionRules();
    const { createRule } = useContext(TransactionRuleCreationContext);

    return (
        <main>
            <header className="settings__header">
                <h3>Rules</h3>

                <Button
                    variant="default"
                    onClick={() =>
                        createRule({
                            source: RuleSource.SETTINGS,
                        })
                    }
                >
                    Add rule
                </Button>
            </header>

            <RulesSettingsContent data={rules} />
        </main>
    );
};
