import React from "react";
import { Button } from "../../general/Button/Button";
import { RequestCodeFn } from "./types";
import { UseResend, useResend } from "./useResend";

interface Props {
    request: RequestCodeFn;
    children?: (params: UseResend) => React.ReactElement | null;
    btnSize?: "sm" | "lg";
}

export const ResendCode: React.FC<Props> = ({ request, children, btnSize }) => {
    const resend = useResend(request);

    if (children) {
        return children(resend);
    }

    return (
        <Button
            variant="tertiary"
            size={btnSize}
            onClick={resend.handleResend}
            disabled={resend.resendBlocked}
        >
            {!resend.resendBlocked ? <>Resend Code</> : <>Code re-sent</>}
        </Button>
    );
};
