import { useMutation } from "@tanstack/react-query";
import { CompleteActionDto } from "../common/dto/actions/complete-action.dto";
import { completeAction } from "../lib/actions";
import { Action } from "../common/types/actions";
import { useWorkspaceContext } from "../state/workspaceContext";

export function useCompleteAction() {
    const { activeWorkspace } = useWorkspaceContext();
    return useMutation({
        mutationFn: (action: Action) => {
            if (!activeWorkspace) {
                throw new Error("No active workspace");
            }
            const dto: CompleteActionDto = {
                campaign: action.campaign,
                status: action.status,
                meta: action.meta,
            };
            return completeAction(action.id, activeWorkspace.id, dto);
        },
    });
}
