export enum AccountType {
    cash = "Cash",
    accountsReceivable = "Accounts Receivable",
    prepaidExpenses = "Prepaid Expenses",
    inventory = "Inventory",
    otherCurrentAssets = "Other Current Assets",
    investments = "Investments",
    fixedAssets = "Fixed Assets",
    intangibleAssets = "Intangible Assets",
    otherAssets = "Other Assets",
    accountsPayable = "Accounts Payable",
    creditCards = "Credit Cards",
    payrollLiabilities = "Payroll Liabilities",
    shortTermLoans = "Short-Term Loans",
    otherCurrentLiabilities = "Other Current Liabilities",
    longTermLoans = "Long-Term Loans",
    otherLiabilities = "Other Liabilities",
    equity = "Equity",
    income = "Income",
    cogs = "COGS",
    payrollExpenses = "Payroll Expenses",
    payrollTaxes = "Payroll Taxes",
    employeeBenefits = "Employee Benefits",
    operatingExpenses = "Operating Expenses",
    otherIncome = "Other Income",
    otherExpenses = "Other Expenses",
    taxExpenses = "Tax Expenses",
}

export enum AccountSubtype {
    cashOnHand = "Cash on Hand",
    bankAccount = "Bank Account",
    paymentProcessor = "Payment Processor",
    transferClearing = "Transfer Clearing",
    accountsReceivable = "Accounts Receivable",
    allowanceForBadDebts = "Allowance for Bad Debts",
    prepaidExpenses = "Prepaid Expenses",
    inventory = "Inventory",
    otherCurrentAssets = "Other Current Assets",
    usGovernmentObligations = "U.S. Government Obligations",
    taxExemptSecurities = "Tax-Exempt Securities",
    investments = "Investments",
    land = "Land",
    buildings = "Buildings",
    leaseholds = "Leaseholds",
    vehicles = "Vehicles",
    equipment = "Equipment",
    furnitureAndFixtures = "Furniture And Fixtures",
    otherFixedAssets = "Other Fixed Assets",
    accumulatedDepreciation = "Accumulated Depreciation",
    startupCosts = "Startup Costs",
    trademarksAndPatents = "Trademarks and Patents",
    goodwill = "Goodwill",
    otherIntangibleAssets = "Other Intangible Assets",
    accumulatedAmortization = "Accumulated Amortization",
    intercompanyReceivables = "Intercompany Receivables",
    employeeLoans = "Loans to Employees",
    loansToOwner = "Loans to Owner",
    loansToShareholders = "Loans to Shareholders",
    loansToPartners = "Loans to Partners",
    mortgageAndRealEstateLoans = "Mortgage and Real Estate Loans",
    securityDeposits = "Security deposits",
    depletableAssets = "Depletable Assets",
    accumulatedDepletion = "Accumulated Depletion",
    otherLongTermAssets = "Other Long-Term Assets",
    accountsPayable = "Accounts Payable",
    creditCard = "Credit Card",
    creditCardClearing = "Credit Card Clearing",
    payrollPayable = "Payroll Payable",
    lineOfCredit = "Line of Credit",
    shortTermLoans = "Short-term Loans",
    currentMaturitiesOfLongTermDebt = "Current Maturities of Long-Term Debt",
    salesTaxPayable = "Sales Tax Payable",
    deferredRevenue = "Deferred Revenue",
    accruedExpenses = "Accrued Expenses",
    otherCurrentLiabilities = "Other Current Liabilities",
    longTermLoans = "Long-Term Loans",
    loansFromOwner = "Loans from Owner",
    loansFromShareholders = "Loans from Shareholders",
    loansFromPartners = "Loans from Partners",
    lessCurrentMaturitiesOfLongTermDebt = "Less Current Maturities of Long-Term Debt",
    intercompanyPayables = "Intercompany Payables",
    otherLiabilities = "Other Liabilities",
    contributions = "Contributions",
    distributions = "Distributions",
    capital = "Capital",
    commonStock = "Common Stock",
    additionalPaidInCapital = "Additional Paid-In Capital",
    dividends = "Dividends",
    retainedEarnings = "Retained Earnings",
    adjustmentsToShareholdersEquity = "Adjustments to Shareholders' Equity",
    costOfTreasuryStock = "Cost of Treasury Stock",
    revenue = "Revenue",
    refundsAndReturns = "Refunds and Returns",
    cogsGoodsAndMaterials = "COGS - Goods and Materials",
    cogsLabor = "COGS - Labor",
    cogsOther = "COGS - Other",
    salariesAndWages = "Salaries and Wages",
    guaranteedPaymentsToPartners = "Guaranteed payments to partners",
    officersCompensation = "Officer's compensation",
    contractLabor = "Contract Labor",
    salariesAndWagesClearing = "Salaries and Wages - Clearing",
    commisions = "Commisions",
    payrollTaxes = "Payroll Taxes",
    employeeBenefits = "Employee Benefits",
    retirementBenefits = "Retirement Benefits",
    advertisingAndMarketing = "Advertising and Marketing",
    computersAndElectronics = "Computers and Electronics",
    learningAndDevelopment = "Learning and Development",
    licensesAndPermits = "Licenses and Permits",
    propertyTaxes = "Property Taxes",
    entertainment = "Entertainment",
    insurance = "Insurance",
    internetAndPhone = "Internet and Phone",
    facilities = "Facilities",
    meals = "Meals",
    merchantAndBankFees = "Merchant and Bank Fees",
    suppliesAndEquipment = "Supplies and Equipment",
    softwareAndSubscriptions = "Software and Subscriptions",
    officeExpenses = "Office Expenses",
    officeUtilities = "Office Utilities",
    legalAndProfessionalFees = "Legal and Professional Fees",
    rentAndLease = "Rent and Lease",
    travel = "Travel",
    vehicle = "Vehicle",
    otherOperatingExpenses = "Other Operating Expenses",
    rentalIncome = "Rental Income",
    interestIncome = "Interest Income",
    dividendIncome = "Dividend Income",
    rebateIncome = "Rebate Income",
    royaltyIncome = "Royalty Income",
    gainLossAssetDisposals = "Gain (Loss) Asset Disposals",
    otherIncome = "Other Income",
    interestExpense = "Interest Expense",
    depreciation = "Depreciation",
    amortization = "Amortization",
    depletion = "Depletion",
    finesAndPenalties = "Fines and Penalties",
    badDebts = "Bad debts",
    donations = "Donations",
    otherExpense = "Other Expense",
    federalTaxes = "Federal Taxes",
    stateAndLocalTaxes = "State and Local Taxes",
    uncategorizedIncome = "Uncategorized Income",
    uncategorizedExpenses = "Uncategorized Expenses",
}

export enum AccountClass {
    assets = "Assets",
    liabilities = "Liabilities",
    equity = "Equity",
    income = "Income",
    expenses = "Expenses",
}

export enum BalanceSheetDerivativeLines {
    netIncome = "Net Income",
}

export enum DefaultCashBasisAccountCodes {
    cashOnHands = 10000,
    bankTransferClearing = 10900,
    paymentTransferClearing = 10901,
    accountsReceivable = 11000,
    allowanceForBadDebts = 11900,
    inventory = 12000,
    prepaidExpenses = 13000,
    otherCurrentAssets = 13900,
    usGovernmentObligations = 14000,
    taxExemptSecurities = 14100,
    investments = 14200,
    land = 15000,
    buildings = 15100,
    leaseholdImprovements = 15200,
    vehicles = 15300,
    equipment = 15400,
    furnitureAndFixturesAsset = 15500,
    otherFixedAssets = 15800,
    accumulatedDepreciation = 15900,
    startupCosts = 16000,
    trademarksAndPatents = 16100,
    goodwill = 16200,
    otherIntangibleAssets = 16800,
    accumulatedAmortization = 16900,

    // NOTE: intercompanyReceivables is within a dynamic type group
    // for now, we still map it based on the original account code as it's alawys first in its group
    // but in the future we should migrate to another form of unique key
    intercompanyReceivables = 19000,
    employeeLoans = 19100,
    loansToRelatedParties = 19200,
    mortgageAndRealEstateLoans = 19300,
    securityDeposits = 19400,
    depletableAssets = 19500,
    accumulatedDepletion = 19600,
    otherLongTermAssets = 19900,

    accountsPayable = 20000,
    creditCardClearing = 21900,
    payrollPayable = 22000,
    lineOfCredit = 23000,
    shortTermLoans = 23100,
    currentMaturitiesOfLongTermDebt = 23900,

    salesTaxPayable = 24000,
    deferredRevenue = 24100,
    accruedExpenses = 24200,
    otherCurrentLiabilities = 24900,

    longTermLoans = 25000,
    loansFromRelatedParties = 25100,
    lessCurrentMaturitiesOfLongTermDebt = 25900,

    intercompanyPayables = 29000,
    otherLongTermLiabilities = 29900,
    revenue = 40000,
    refundsAndReturns = 40100,
    uncategorizedIncome = 49999,
    cogsGoodsAndMaterials = 50000,
    cogsLabor = 50100,
    cogsOther = 50200,
    salariesAndWages = 60000,
    contractLabor = 60100,
    commissions = 60300,
    salariesAndWagesClearing = 60900,
    payrollTaxes = 61000,

    employeeBenefits = 62000,
    retirementBenefits = 62100,

    advertisingAndMarketing = 70000,
    learningAndDevelopment = 70100,
    licensesAndPermits = 70200,
    propertyTaxes = 70300,
    entertainment = 70400,
    insuranceBusiness = 70500,
    insuranceOwnersPartnersOfficers = 70501,
    internetAndPhone = 70600,
    facilitiesRepairsAndMaintenance = 70700,
    facilitiesCleaningFees = 70701,
    facilitiesManagementFees = 70702,
    furnitureAndFixturesExpense = 70800,
    meals = 70900,
    mealsEmployeeCatering = 70901,
    merchantAndBankFees = 71000,
    suppliesAndEquipment = 71100,
    softwareAndSubscriptions = 71200,
    officeExpenses = 71300,
    officeUtilities = 71400,
    legalAndProfessionalFees = 71500,
    rentAndLease = 71600,
    travel = 71700,
    vehicle = 71800,
    vehicleLeases = 71801,
    vehicleParkingAndTolls = 71802,
    otherOperatingExpenses = 79900,
    uncategorizedExpenses = 79999,

    rentalIncome = 80000,
    interestIncome = 80100,
    dividendIncome = 80200,
    rebateIncome = 80300,
    royaltyIncome = 80400,
    gainLossAssetDisposals = 84800,
    otherIncome = 84900,
    interestExpense = 85000,
    depreciation = 85100,
    amortization = 85200,
    depletion = 85300,
    finesAndPenalties = 85400,
    badDebts = 85500,
    donations = 85600,
    otherExpense = 89900,
    federalTaxes = 90000,
    stateAndLocalTaxes = 90100,
}
