import React, { useCallback, useState } from "react";
import { Placement } from "react-bootstrap/Overlay";
import { TransactionDirectionType } from "../../common/categories";
import { UpdateTransactionDto } from "../../common/dto/transactions/update-transaction.dto";
import { REMOVE_REAL_ESTATE_CATEGORY_ACTION } from "../../common/requiredActions";
import { Category } from "../../common/types/category";
import { Entity } from "../../common/types/entity";
import { TransactionListItemDto } from "../../common/types/transaction";
import { useSingleBusinessEntity } from "../../hooks/useSingleBusinessEntity";
import { SelectBusinessEntityModal } from "../entity/SelectBusinessEntityModal";
import { PlanManagementProvider } from "../billing/PlanManagement/PlanManagementProvider";
import { useUncategorizedCategory } from "../../hooks/useUncategorizedCategory";
import { StandardModal } from "../general/Modal/Modal";
import {
    CategorySelectCustom,
    CategorySelectCustomProps,
} from "./CategorySelect/CategorySelectCustom";
import { CategorySelectPrompt } from "./CategorySelect/CategorySelectPrompt";

interface Props {
    transaction: TransactionListItemDto;
    onUpdate: (payload: UpdateTransactionDto) => Promise<void>;
    enableWarning?: boolean;
    placement?: Placement;
}

export const TransactionCategoryChange: React.FC<Props> = ({
    transaction,
    onUpdate,
    enableWarning,
    placement = "bottom-end",
}) => {
    const singleBusinessEntity = useSingleBusinessEntity();
    const [switchingToBusinessCategory, setSwitchingToBusinessCategory] =
        useState<Category>();
    const hideBusinessSelection = useCallback(
        () => setSwitchingToBusinessCategory(undefined),
        [],
    );
    const uncategorized = useUncategorizedCategory();

    const onSelected = useCallback<CategorySelectCustomProps["onSelected"]>(
        (selected: Category) => {
            if (!selected.isBusiness) {
                return onUpdate({ categoryId: selected.id });
            }
            if (transaction.isBusiness) {
                return onUpdate({ categoryId: selected.id });
            } else if (singleBusinessEntity) {
                return onUpdate({
                    categoryId: selected.id,
                    entityId: singleBusinessEntity.id,
                });
            } else {
                setSwitchingToBusinessCategory(selected);
            }
        },
        [onUpdate, singleBusinessEntity, transaction.isBusiness],
    );

    const onBusinessSelected = useCallback(
        (entity: Entity) => {
            if (switchingToBusinessCategory) {
                setSwitchingToBusinessCategory(undefined);
                return onUpdate({
                    categoryId: switchingToBusinessCategory.id,
                    entityId: entity.id,
                });
            }
        },
        [switchingToBusinessCategory, onUpdate],
    );

    const uncategorizedWarning =
        transaction.categoryId === uncategorized.id &&
        transaction.isBusiness &&
        !transaction.inReview;
    const realEstateWarning =
        transaction.isBusiness &&
        transaction.requiredActions.includes(
            REMOVE_REAL_ESTATE_CATEGORY_ACTION,
        );

    return (
        <>
            <PlanManagementProvider>
                <CategorySelectCustom
                    suggestedCategories={transaction.suggestedCategories ?? []}
                    dropdownKey={transaction.id}
                    onSelected={onSelected}
                    placement={placement}
                    transactionDirection={
                        transaction.amount > 0
                            ? TransactionDirectionType.incoming
                            : TransactionDirectionType.outgoing
                    }
                >
                    {(open) => (
                        <CategorySelectPrompt
                            variant={
                                enableWarning &&
                                (uncategorizedWarning || realEstateWarning)
                                    ? "warning"
                                    : "default"
                            }
                            transaction={transaction}
                            active={open}
                        />
                    )}
                </CategorySelectCustom>
            </PlanManagementProvider>
            <StandardModal
                show={!!switchingToBusinessCategory}
                onHide={hideBusinessSelection}
            >
                <SelectBusinessEntityModal
                    prompt="To assign a business category please select a company you'd like to move this transaction to:"
                    onCancel={hideBusinessSelection}
                    onSelected={onBusinessSelected}
                    selectPlaceholder="Select company"
                />
            </StandardModal>
        </>
    );
};
