import { Formik } from "formik";
import { UpdateTransactionSplitV2Dto } from "../../../common/dto/transactions/update-transaction-split-v2.dto";
import { Transaction } from "../../../common/types/transaction";
import { useEntities } from "../../../hooks/useEntities";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { Button } from "../../general/Button/Button";
import { StandardModalFooter } from "../../general/Modal/Modal";
import { TransactionSplitForm } from "./TransactionSplitForm";
import styles from "./TransactionSplitModal.module.scss";

interface Props {
    transaction: Transaction;
    onSubmit: (values: {
        splits: UpdateTransactionSplitV2Dto[];
    }) => Promise<void>;
    onUnsplit: () => void;
    loading: boolean;
}

export const TransactionSplitContainer = ({
    transaction,
    onSubmit,
    onUnsplit,
    loading,
}: Props) => {
    const entities = useEntities({ excludeAllMockEntities: true });

    const initialSplits: UpdateTransactionSplitV2Dto[] =
        transaction.splits!.length > 0
            ? transaction
                  .splits!.map(
                      (split) =>
                          ({
                              id: split.id,
                              amount: split.amount,
                              entityId: split.entity.id,
                              splitDescription: split.splitDescription ?? "",
                              categoryId: split.categoryId,
                          }) satisfies UpdateTransactionSplitV2Dto,
                  )
                  .sort((a, b) => a.id - b.id)
            : [
                  {
                      id: transaction.id,
                      amount: transaction.originalAmount,
                      entityId: transaction.entity.id,
                      splitDescription: transaction.displayDescription ?? "",
                      categoryId: transaction.categoryId,
                  },
              ];
    return (
        <Formik initialValues={{ splits: initialSplits }} onSubmit={onSubmit}>
            {({ handleSubmit }) => (
                <>
                    <TransactionSplitForm
                        transaction={transaction}
                        entities={entities}
                    />
                    <StandardModalFooter className={styles.footer}>
                        <Button
                            disabled={loading}
                            variant="secondary"
                            onClick={onUnsplit}
                        >
                            Unsplit
                        </Button>
                        <ButtonWithLoader
                            loading={loading}
                            onClick={handleSubmit}
                        >
                            Save Split
                        </ButtonWithLoader>
                    </StandardModalFooter>
                </>
            )}
        </Formik>
    );
};
