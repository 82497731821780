import { EagerLoaded } from "../../types/base/orm";
import { Category } from "../../types/category";
import { TaxQuestionAnswer } from "../../types/taxQuestionAnswer";
import { Transaction } from "../../types/transaction";
import { AuditLog } from "../../types/auditLog";
import { CounterpartyDto } from "../counterparty/counterparty.dto";
import { EntityDto } from "../entity/entity.dto";
import { FinancialAccountDto } from "../financialAccount/financial-account.dto";
import { TransactionMatchDto } from "../transactionMatch/transaction-match.dto";
import { TransactionBaseDto } from "./transaction-base.dto";

export class TransactionDto extends TransactionBaseDto {
    financialAccount: FinancialAccountDto;
    entity: EntityDto;
    auditLogs?: AuditLog[];
    taxQuestionAnswers: TaxQuestionAnswer[];
    transactionMatches?: TransactionMatchDto[];
    counterparty?: CounterpartyDto;
    category?: Category;

    static deserialize(dto: TransactionDto): Transaction {
        return {
            ...dto,
            ...TransactionBaseDto.deserialize(dto),
            financialAccount: FinancialAccountDto.deserialize(
                dto.financialAccount,
            ),
            entity: EntityDto.deserialize(dto.entity),
            transactionMatches: dto.transactionMatches?.map(
                TransactionMatchDto.deserialize,
            ),
            counterparty: dto.counterparty
                ? CounterpartyDto.deserialize(dto.counterparty)
                : undefined,
        };
    }

    constructor(
        transaction: EagerLoaded<Transaction, "financialAccount" | "entity">,
    ) {
        super(transaction);

        this.financialAccount = new FinancialAccountDto(
            transaction.financialAccount,
        );
        this.entity = new EntityDto(transaction.entity);
        this.auditLogs = transaction.auditLogs;
        this.taxQuestionAnswers = transaction.taxQuestionAnswers;
        this.transactionMatches = transaction.transactionMatches?.map(
            (tm) => new TransactionMatchDto(tm),
        );
        this.counterparty = transaction.counterparty
            ? new CounterpartyDto(transaction.counterparty)
            : undefined;
        this.category = transaction.category;
    }
}
