import React, {
    RefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from "react";
import { useQuery } from "@tanstack/react-query";
import { uniqBy, without } from "lodash";
import { FinancialAccount } from "../../../../common/types/financialAccount";
import {
    addFinancialDocumentsToFinancialAccount,
    getDocumentsForFinancialAccount,
} from "../../../../lib/financialAccount";
import {
    financialDocumentsForFinancialAccountKeys,
    UploadingDocumentPlaceholder,
} from "../../../documents/lib";
import { Loader } from "../../../general/Loader";
import { FinancialDocumentUploadContextValue } from "../../../documents/FinancialDocumentUpload/financialDocumentUploadContext";
import { FinancialDocumentUploadProvider } from "../../../documents/FinancialDocumentUpload/FinancialDocumentUploadProvider";
import {
    ALLOWED_FILE_TYPES_FOR_UNIVERSAL_IMPORT,
    ALLOWED_FILE_TYPES_FOR_FINANCIAL_ACCOUNTS,
} from "../../../../common/constants";
import { useRemoveFinancialDocumentMutation } from "../../../../mutations/financialDocument";
import { ImportedDocuments } from "../../../manualImport/ImportedDocuments";
import { FinancialDocument } from "../../../../common/types/financialDocument";
import { useWorkspaceContext } from "../../../../state/workspaceContext";
import { queryClient } from "../../../../queryClient";
import { useTypedFlags } from "../../../../hooks/useTypedFlags";
import { useToaster } from "../../../general/ToastMessages/useToaster";
import styles from "./AccountFinancialDocuments.module.scss";

interface Props {
    account: FinancialAccount;
}

export const AccountFinancialDocuments: React.FC<Props> = ({ account }) => {
    const { universalImporterUi } = useTypedFlags();
    const { activeWorkspaceKey } = useWorkspaceContext();
    const uploaderRef = useRef<FinancialDocumentUploadContextValue>();

    const acceptTypes = universalImporterUi
        ? ALLOWED_FILE_TYPES_FOR_UNIVERSAL_IMPORT
        : ALLOWED_FILE_TYPES_FOR_FINANCIAL_ACCOUNTS;

    const { data, isLoading } = useQuery({
        queryKey: financialDocumentsForFinancialAccountKeys.list(account.id),
        queryFn: () => getDocumentsForFinancialAccount(account.id),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (data) {
            uploaderRef.current?.clearUploads();
        }
    }, [data]);

    const removeFinancialDocumentMutation =
        useRemoveFinancialDocumentMutation();
    const { toast } = useToaster();

    const items: UploadingDocumentPlaceholder[] = useMemo(() => {
        const existingDocuments = data ?? [];

        return existingDocuments.map(
            (financialDocument) =>
                ({
                    financialDocument,
                    fileName: financialDocument.fileName,
                    isUploading: false,
                }) as UploadingDocumentPlaceholder,
        );
    }, [data]);

    const handleRemoveItem = useCallback(
        async (item: UploadingDocumentPlaceholder) => {
            if (!item.financialDocument) {
                return;
            }

            removeFinancialDocumentMutation.mutate(item.financialDocument);
            uploaderRef.current?.updateUploads((prev) => without(prev, item));
        },
        [removeFinancialDocumentMutation],
    );

    const handleComplete = useCallback(
        async (uploadedDocuments: FinancialDocument[]) => {
            const { needsVerification } =
                await addFinancialDocumentsToFinancialAccount(account.id, {
                    documentIds: uploadedDocuments.map((doc) => doc.id),
                    workspaceId: activeWorkspaceKey,
                });

            await queryClient.invalidateQueries({
                queryKey: financialDocumentsForFinancialAccountKeys.all(),
            });

            toast(
                needsVerification
                    ? "Your transactions are being imported. Check back in 24h."
                    : "Transactions imported successfully.",
            );
        },
        [account.id, activeWorkspaceKey, toast],
    );

    if (isLoading) {
        return <Loader />;
    }

    const uniqueItems = uniqBy(
        [...items, ...(uploaderRef.current?.uploadingDocuments ?? [])],
        (item) => item.financialDocument?.id ?? item.fileName,
    );

    return (
        <div className={styles.body}>
            <FinancialDocumentUploadProvider
                accept={acceptTypes}
                onCompleted={handleComplete}
                ref={
                    uploaderRef as RefObject<FinancialDocumentUploadContextValue>
                }
            >
                <ImportedDocuments
                    onRemoveItem={handleRemoveItem}
                    items={uniqueItems}
                    showTransactionsCount={false}
                />
            </FinancialDocumentUploadProvider>
        </div>
    );
};
