import { Counterparty } from "../../types/counterparty";
import { TransactionRule } from "../../types/transactionRule";
import { CounterpartyDto } from "../counterparty/counterparty.dto";
import { TransactionRuleDto } from "./transaction-rule.dto";

export interface GetTransactionRulesResponse {
    rules: TransactionRule[];
    counterparties: Counterparty[];
    transactionCounts: Record<string, number>;
}

export class GetTransactionRulesResponseDto {
    static deserialize(
        dto: GetTransactionRulesResponseDto,
    ): GetTransactionRulesResponse {
        return {
            rules: dto.rules.map((rule) =>
                TransactionRuleDto.deserialize(rule),
            ),
            counterparties: dto.counterparties.map((counterparty) =>
                CounterpartyDto.deserialize(counterparty),
            ),
            transactionCounts: dto.transactionCounts,
        };
    }

    rules: TransactionRuleDto[];
    counterparties: CounterpartyDto[];
    transactionCounts: Record<string, number>;

    constructor(
        rules: TransactionRule[],
        counterparties: Counterparty[],
        transactionCounts: Record<string, number>,
    ) {
        this.rules = rules.map((rule) => new TransactionRuleDto(rule));
        this.counterparties = counterparties.map(
            (counterparty) => new CounterpartyDto(counterparty),
        );
        this.transactionCounts = transactionCounts;
    }
}
