import {
    startOfMonth,
    subWeeks,
    format,
    isSameDay,
    isSameMonth,
    startOfDay,
    subDays,
} from "date-fns";
import { sortBy, groupBy } from "lodash";
import { useMemo, useRef } from "react";
import { currencyFormatterNoFractions } from "../../common/helpers/currency";
import {
    ACTION_CATEGORY_CAMPAIGN_MAP,
    ActionCampaign,
    ActionsActivity,
    FakeActionCampaign,
} from "../../common/types/actions";
import { useActionsActivity } from "../../hooks/useActionsActivity";
import { Loader } from "../general/Loader";
import { Widget } from "../general/Widget";
import { User } from "../../common/types/user";
import { ActionActivityItem } from "./ActionActivityItem";
import styles from "./ActionsActivityList.module.scss";

interface DailyActivity {
    campaign: ActionCampaign | FakeActionCampaign;
    count: number;
    amountSaved: number;
    description?: string;
    user?: User;
}

function aggregateDailyActivities(activity: ActionsActivity) {
    const today = startOfDay(new Date());
    const yesterday = subDays(today, 1);

    const grouped: Record<string, DailyActivity[]> = {
        Today: [],
        Yesterday: [],
        "This Month": [],
    };

    // Group activities by date first
    Object.entries(activity).forEach(([period, activities]) => {
        const activityDate = new Date(period);

        if (isSameDay(activityDate, today)) {
            grouped.Today.push(...activities);
        } else if (isSameDay(activityDate, yesterday)) {
            grouped.Yesterday.push(...activities);
        } else if (
            isSameMonth(activityDate, today) &&
            !isSameDay(activityDate, today) &&
            !isSameDay(activityDate, yesterday)
        ) {
            grouped["This Month"].push(...activities);
        } else {
            const monthKey = format(activityDate, "MMMM yyyy");
            if (!grouped[monthKey]) {
                grouped[monthKey] = [];
            }
            grouped[monthKey].push(...activities);
        }
    });

    // Aggregate activities within each period
    return Object.entries(grouped).reduce(
        (acc, [period, activities]) => {
            if (activities.length === 0) {
                return acc;
            }

            const aggregatedActivities = Object.values(
                groupBy(activities, "campaign"),
            ).map((campaignActivities) => ({
                campaign: campaignActivities[0].campaign,
                count: campaignActivities.reduce((sum, a) => sum + a.count, 0),
                amountSaved: campaignActivities.reduce(
                    (sum, a) => sum + a.amountSaved,
                    0,
                ),
                description: campaignActivities[0].description,
                user: campaignActivities[0].user,
            }));

            acc[period] = aggregatedActivities;
            return acc;
        },
        {} as Record<
            string,
            Array<{
                campaign: ActionCampaign | FakeActionCampaign;
                count: number;
                amountSaved: number;
            }>
        >,
    );
}

export const ActionsActivityList: React.FC = () => {
    const initialFetchTimeRef = useRef<number | null>(null);
    const actionsActivity = useActionsActivity();
    const now = startOfDay(new Date());

    const aggregatedActivity = useMemo(() => {
        if (!actionsActivity.data) {
            return {};
        }
        return aggregateDailyActivities(actionsActivity.data);
    }, [actionsActivity.data]);

    const activity = useMemo(
        () =>
            sortBy(Object.entries(aggregatedActivity), ([period]) => {
                if (period === "Today") {
                    return -now.getTime();
                }
                if (period === "Yesterday") {
                    return -subWeeks(now, 1).getTime();
                }
                if (period === "This Month") {
                    return -startOfMonth(now).getTime();
                }
                return -new Date(period).getTime();
            }),
        [aggregatedActivity, now],
    );

    if (actionsActivity.isSuccess && initialFetchTimeRef.current === null) {
        initialFetchTimeRef.current = actionsActivity.dataUpdatedAt;
    }

    const totalAmountSaved = useMemo(
        () =>
            Object.values(activity).reduce(
                (total, [_, entries]) =>
                    total +
                    entries
                        .map((e) =>
                            ACTION_CATEGORY_CAMPAIGN_MAP.savings.includes(
                                e.campaign as ActionCampaign,
                            )
                                ? e.amountSaved
                                : 0,
                        )
                        .reduce((a, b) => a + b, 0),
                0,
            ),
        [activity],
    );

    return (
        <Widget className={styles.actionsActivityContainer}>
            <div className={styles.actionsActivityHeader}>
                <h4>Activity</h4>
                {totalAmountSaved > 0 && (
                    <span className={styles.totalAmountSaved}>
                        {currencyFormatterNoFractions.format(totalAmountSaved)}{" "}
                        saved
                    </span>
                )}
            </div>
            {actionsActivity.isLoading && <Loader />}
            {activity.length === 0 && (
                <div className={styles.actionsActivityEmptyState}>
                    <p>No recent activity</p>
                </div>
            )}
            {activity.map(([period, entries]) => (
                <div key={period} className={styles.actionsActivityList}>
                    <div className={styles.actionsActivityHeader}>
                        <h5>{period}</h5>
                    </div>
                    {entries.map((entry) => (
                        <ActionActivityItem
                            key={`${period}+${entry.campaign}`}
                            {...entry}
                            animated={
                                actionsActivity.dataUpdatedAt >
                                initialFetchTimeRef.current!
                            }
                        />
                    ))}
                </div>
            ))}
        </Widget>
    );
};
