import React from "react";
import classNames from "classnames";
import { useBreakableText } from "../../../hooks/useBreakableText";
import styles from "./BreakableText.module.scss";

interface BreakableTextProps {
    text: string | null | undefined;
    className?: string;
}

export const BreakableText: React.FC<BreakableTextProps> = ({
    text,
    className,
}) => {
    const breakableText = useBreakableText(text ?? "");

    return (
        <span className={classNames(className, styles.breakableText)}>
            {breakableText}
        </span>
    );
};
