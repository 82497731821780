import React, { useCallback, useContext, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { isAxiosError } from "axios";
import { Counterparty } from "../../../common/types/counterparty";
import { ManagementDropdown } from "../../general/ManagementDropdown/ManagementDropdown";
import { useCounterpartyTransactionsOpener } from "../../../hooks/useCounterpartyTransactionsOpener";
import { Loader } from "../../general/Loader";
import { useCounterpartyRemovalMutation } from "../../../api/counterparty.api";
import { StandardModal } from "../../general/Modal/Modal";
import { DirectoryContext } from "./Directory.context";
import { MergeOrRemoveCounterpartyModal } from "./MergeOrRemoveCounterpartyModal";

interface Props {
    counterparty: Counterparty;
    className: string;
    hasTransactions: boolean;
}

export const CounterpartyManagementDropdown: React.FC<Props> = ({
    counterparty,
    className,
    hasTransactions,
}) => {
    const { setEditedItem, counterpartyDirection } =
        useContext(DirectoryContext);
    const showTransactions = useCounterpartyTransactionsOpener(counterparty, {
        includeDateFilter: true,
    });
    const counterpartyRemoval = useCounterpartyRemovalMutation(counterparty);
    const [showMergeOrRemoveModal, setShowMergeOrRemoveModal] = useState(false);

    const onRemove = useCallback(async () => {
        if (hasTransactions) {
            setShowMergeOrRemoveModal(true);
        } else {
            try {
                await counterpartyRemoval.mutateAsync();
            } catch (e) {
                if (isAxiosError(e) && e.response?.data?.transactionsFound) {
                    setShowMergeOrRemoveModal(true);
                } else {
                    throw e;
                }
            }
        }
    }, [hasTransactions, counterpartyRemoval]);

    return (
        <>
            <ManagementDropdown
                className={className}
                id={`counterparty-management-${counterparty.id}`}
            >
                <Dropdown.Item onClick={() => setEditedItem(counterparty)}>
                    Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={showTransactions}>
                    View Transactions
                </Dropdown.Item>
                <Dropdown.Item onClick={onRemove} className="text-danger">
                    {counterpartyRemoval.isPending ? (
                        <Loader inline />
                    ) : (
                        "Delete"
                    )}
                </Dropdown.Item>
            </ManagementDropdown>
            <StandardModal
                show={showMergeOrRemoveModal}
                onHide={() => setShowMergeOrRemoveModal(false)}
            >
                <MergeOrRemoveCounterpartyModal
                    counterparty={counterparty}
                    direction={counterpartyDirection}
                    close={() => setShowMergeOrRemoveModal(false)}
                />
            </StandardModal>
        </>
    );
};
