import React, { useCallback, useMemo } from "react";
import { SearchFilter } from "../../general/SearchFilter/SearchFilter";
import {
    CustomSelect,
    CustomSelectOption,
} from "../../forms/CustomSelect/CustomSelect";
import { DirectoryFilters } from "./lib";
import styles from "./DirectoryView.module.scss";

interface Props {
    filters: DirectoryFilters;
    setFilters: (filters: DirectoryFilters) => void;
    availableYears: number[];
}

export const DirectoryFiltersForm: React.FC<Props> = ({
    filters,
    setFilters,
    availableYears,
}) => {
    const setSearch = useCallback(
        (search: string | undefined) => {
            setFilters({ ...filters, search });
        },
        [filters, setFilters],
    );

    const setYear = useCallback(
        (year: number) => {
            setFilters({ ...filters, year: year !== 0 ? year : undefined });
        },
        [filters, setFilters],
    );

    const yearOptions = useMemo(() => {
        const options: Array<CustomSelectOption<number>> = availableYears.map(
            (year) => ({
                value: year,
            }),
        );

        if (availableYears.length > 1) {
            options.unshift({
                value: 0,
                label: "All",
            });
        }

        return options;
    }, [availableYears]);

    return (
        <>
            <SearchFilter
                filter={filters.search}
                onChange={setSearch}
                containerClassName={styles.search}
            />
            <CustomSelect
                onSelected={setYear}
                dropdownKey="directoryYear"
                options={yearOptions}
                value={filters.year ?? 0}
                size="xs"
            />
        </>
    );
};
