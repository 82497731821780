import React from "react";
import { Tag } from "../../../general/Tag/Tag";
import { CampaignCardProps } from "../../../actions/ActionItem";
import { PlaidConnection } from "../../../../common/types/plaidConnection";
import { FinancialAccount } from "../../../../common/types/financialAccount";
import { FinancialConnection } from "../../../../common/types/financialConnection";
import { Entity } from "../../../../common/types/entity";
import { KeyboardReturnIcon } from "../../../../icons";
import { KeyboardShortcut } from "../../../general/KeyboardShortcut/KeyboardShortcut";
import { Category } from "../../../../common/types/category";
import { DemoPersonalToBusiness } from "./cards/DemoPersonalToBusiness";
import { ShareWithAccountantCard } from "./cards/ShareWithAccountantCard";
import { Interlude } from "./Interlude";
import { CategorizationSummaryActionCard } from "./cards/CategorizationSummaryActionCard";
import { StartPreviewActionCard } from "./cards/StartPreviewActionCard";

export interface DemoActionProps
    extends Pick<
        CampaignCardProps,
        | "onCardClosing"
        | "onCardClosed"
        | "opened"
        | "isCompleted"
        | "isSkipped"
        | "onConfirm"
        | "onSkip"
    > {
    onConfirm?: () => void;
    onSkip?: () => void;
}

interface ActionConfig {
    heading: React.ReactNode;
    description: React.ReactNode;
    hint: React.ReactNode;
    component: React.FC<DemoActionProps>;
}

export const actionConfigs = {
    startPreview: {
        component: StartPreviewActionCard,
        heading: "Let’s start by seeing Kick in action.",
        hint: (
            <>
                Hit{" "}
                <Tag variant="cta" size="sm">
                    Preview
                </Tag>{" "}
                or
                <KeyboardShortcut square className="ml-2">
                    <KeyboardReturnIcon />
                </KeyboardShortcut>{" "}
                to continue
            </>
        ),
        description: null,
    },
    categorizationSummary: {
        component: CategorizationSummaryActionCard,
        heading: "Kick works in the background to categorize your transactions",
        description: "This frees up time so you can focus on what matters.",
        hint: (
            <>
                Hit{" "}
                <Tag variant="cta" size="sm">
                    Next
                </Tag>{" "}
                or
                <KeyboardShortcut square className="ml-2">
                    <KeyboardReturnIcon />
                </KeyboardShortcut>{" "}
                to continue
            </>
        ),
    },
    personalToBusiness: {
        component: DemoPersonalToBusiness,
        heading: "Have mixed expenses? Kick untangles those for you.",
        description: (
            <>
                Personal account deductions found by Kick save the average user{" "}
                <em>$4k</em>.
            </>
        ),
        hint: (
            <>
                Hit{" "}
                <Tag variant="cta" size="sm">
                    Confirm
                </Tag>{" "}
                or
                <KeyboardShortcut square className="ml-2">
                    <KeyboardReturnIcon />
                </KeyboardShortcut>{" "}
                to continue
            </>
        ),
    },
    shareWithAccountant: {
        component: ShareWithAccountantCard,
        heading: "Kick helps you stay on top of accounting",
        description:
            "Make life stress-free come tax time for both you and your Accountant.",
        hint: (
            <>
                Hit{" "}
                <Tag variant="cta" size="sm">
                    Share
                </Tag>{" "}
                or
                <KeyboardShortcut square className="ml-2">
                    <KeyboardReturnIcon />
                </KeyboardShortcut>{" "}
                to continue
            </>
        ),
    },
    interlude: {
        component: Interlude,
        heading: null,
        description: null,
        hint: (
            <span className="text-gray-900">
                Hit{" "}
                <Tag variant="secondary" outline>
                    Next
                </Tag>{" "}
                or
                <KeyboardShortcut square className="ml-2">
                    <KeyboardReturnIcon />
                </KeyboardShortcut>{" "}
                to continue
            </span>
        ),
    },
} as const satisfies Record<string, ActionConfig>;

export type ActionKey = keyof typeof actionConfigs;

export type DemoFinancialConnection = Pick<
    FinancialConnection,
    "createdAt" | "connectionProvider"
>;

export type DemoPlaidConnection = Pick<
    PlaidConnection,
    "bankName" | "bankLogoUrl" | "createdAt" | "connectionProvider"
>;

export interface DemoIntegrationAccount {
    connection: DemoPlaidConnection | DemoFinancialConnection;
}

export interface DemoFinancialAccount
    extends Pick<
        FinancialAccount,
        "id" | "accountNumberMask" | "financialType" | "name" | "sourceType"
    > {
    integrationAccounts: DemoIntegrationAccount[];
}

export interface DemoTransaction {
    id: number;
    date: Date;
    counterparty: {
        name: string;
    };
    bankDescription: string;
    displayDescription: string;
    category: Category;
    financialAccount: DemoFinancialAccount;
    amount: number;
    entity: Entity;
}
