import React from "react";
import { SelectDropdownOption } from "../../general/SelectDropdown/SelectDropdownOption";
import { FormCheckbox } from "../FormCheckbox/FormCheckbox";
import { MultiselectOptionsSection, ValueType } from "./types";
import styles from "./Multiselect.module.scss";

interface Props {
    section: MultiselectOptionsSection;
    isChecked: (value: ValueType) => boolean;
    handleChange: (value: ValueType) => void;
}

export const MultiselectSection: React.FC<Props> = ({
    section,
    isChecked,
    handleChange,
}) => (
    <section className={styles.section}>
        {section.name && (
            <label className={styles.sectionLabel}>{section.name}</label>
        )}
        {section.options.map((option) => (
            <SelectDropdownOption key={option.value} className="p-0">
                <FormCheckbox
                    value={option.value}
                    isChecked={isChecked(option.value)}
                    label={option.label}
                    handleChange={handleChange}
                />
            </SelectDropdownOption>
        ))}
    </section>
);
