import { useQuery } from "@tanstack/react-query";
import { useWorkspaceContext } from "../state/workspaceContext";
import { getBillingInfo } from "../lib/billing";
import { billingQueryKeys } from "./useBillingStatus";

export function useBillingInfo() {
    const { activeWorkspaceKey } = useWorkspaceContext();

    return useQuery({
        queryKey: billingQueryKeys.info(activeWorkspaceKey),
        queryFn: () => getBillingInfo(activeWorkspaceKey),
    });
}
