import { Dropdown } from "react-bootstrap";
import classNames from "classnames";
import { useState } from "react";
import { useUser } from "../../../hooks/useUser";
import { UserAvatar } from "../../user/UserAvatar";
import { useLogout } from "../../../hooks/useLogout";
import { ReferralModal } from "../../settings/Referrals/ReferralModal";
import { useSettings } from "../../settings/SettingsProvider";
import styles from "./UserDropdown.module.scss";
import { WorkspaceItems } from "./WorkspaceItems";

export const UserDropdown: React.FC = () => {
    const [open, setOpen] = useState(false);
    const user = useUser();
    const logout = useLogout();
    const { open: openSettings } = useSettings();
    const [showReferralModal, setShowReferralModal] = useState(false);

    return (
        <>
            <Dropdown show={open} onToggle={setOpen}>
                <Dropdown.Toggle
                    variant="tertiary"
                    className={classNames(
                        styles.userDropdownToggle,
                        "dropdown-toggle-plain",
                    )}
                >
                    <UserAvatar user={user} size="md" active={open} />
                </Dropdown.Toggle>
                <Dropdown.Menu align="right">
                    <div className={styles.userDropdownHeader}>
                        <div
                            className={styles.userDropdownUserName}
                            data-testid="dropdown-user-name"
                        >
                            {user.name}
                        </div>
                        <div
                            className={styles.userDropdownUserEmail}
                            data-testid="dropdown-user-email"
                        >
                            {user.email}
                        </div>
                    </div>
                    <Dropdown.Divider />
                    <Dropdown.Header>My account</Dropdown.Header>

                    <Dropdown.Item
                        onClick={() => openSettings(["general"])}
                        data-testid="settings-profile-button"
                    >
                        Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => openSettings(["receipts"])}
                        data-testid="settings-receipts-button"
                    >
                        Receipts
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => openSettings(["general"])}
                        data-testid="settings-notifications-button"
                    >
                        Notifications
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => openSettings(["security"])}
                        data-testid="settings-security-button"
                    >
                        Security
                    </Dropdown.Item>

                    <Dropdown.Item
                        onClick={() => openSettings(["integrations"])}
                        data-testid="settings-integrations-button"
                    >
                        Integrations
                    </Dropdown.Item>

                    <Dropdown.Item
                        onClick={() => setShowReferralModal(true)}
                        data-testid="refer-and-earn-button"
                    >
                        Refer &amp; earn
                    </Dropdown.Item>

                    <Dropdown.Divider />
                    <WorkspaceItems />

                    <Dropdown.Divider />
                    <Dropdown.Item onClick={logout} data-testid="logout-button">
                        Log out
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <ReferralModal
                show={showReferralModal}
                onHide={() => setShowReferralModal(false)}
            />
        </>
    );
};
