import { useQuery } from "@tanstack/react-query";
import { getUserStatus } from "../lib/user";

export const userStatusQueryKey = "status" as const;

export function useUserStatus() {
    return useQuery({
        queryKey: [userStatusQueryKey],
        queryFn: getUserStatus,
        staleTime: 60_000,
        refetchOnWindowFocus: false,
    });
}
