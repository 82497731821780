import { animated, useSpring } from "@react-spring/web";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActionStatus } from "../../../common/types/actions";
import { GraphIcon } from "../../../icons";
import { ActionCard } from "../ActionCard";
import { ActionCardCompleted } from "../ActionCardCompleted";
import { ActionCardSection } from "../ActionCardSection";
import { ActionCloseButton } from "../ActionCloseButton";
import { ActionConfirmButton } from "../ActionConfirmButton";
import { CampaignCardProps } from "../ActionItem";
import { Button } from "../../general/Button/Button";
import { LargestTransactions } from "./LargestTransactions";
import { RecentTransactions } from "./RecentTransactions";

enum Step {
    Recent,
    Largest,
}

const RECENT_MESSAGE_PENDING =
    "Confirm the category of your latest transactions";
const RECENT_MESSAGE_CONFIRMED = "Recent transactions confirmed";

export const ConfirmTransactionsCard: React.FC<CampaignCardProps> = ({
    action,
    onConfirm,
    onSkip,
    isLoading,
    ...rest
}) => {
    const { isCompleted } = rest;
    const [step, setStep] = useState<Step>(Step.Recent);
    const navigate = useNavigate();
    const [recentMessage, setRecentMessage] = useState(RECENT_MESSAGE_PENDING);
    const [recentLoaded, setRecentLoaded] = useState<number>(0);
    const [largestLoaded, setLargestLoaded] = useState<number>(0);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const [recentSpring, recentSpringApi] = useSpring(() => ({
        display: "grid",
        gridTemplateRows: "1fr",
        marginTop: "0px",
        opacity: 1,
    }));

    const [largestSpring, largestSpringApi] = useSpring(() => ({
        display: "grid",
        gridTemplateRows: "0fr",
        opacity: 0,
    }));

    const confirmAction = useCallback(async () => {
        if (step === Step.Recent) {
            setRecentMessage(RECENT_MESSAGE_CONFIRMED);
            setIsTransitioning(true);
            await recentSpringApi.start({
                gridTemplateRows: "0fr",
                marginTop: "-32px",
                opacity: 0,
            })[0];
            setStep(Step.Largest);
            await largestSpringApi.start({
                gridTemplateRows: "1fr",
                opacity: 1,
            })[0];
            setIsTransitioning(false);
        }

        if (step === Step.Largest) {
            onConfirm?.(
                {
                    ...action,
                },
                {
                    step,
                    recent_loaded: recentLoaded,
                    largest_loaded: largestLoaded,
                },
            );
        }
    }, [
        step,
        recentSpringApi,
        largestSpringApi,
        onConfirm,
        action,
        recentLoaded,
        largestLoaded,
    ]);

    const skipAction = useCallback(() => {
        onSkip?.(action, {
            step,
            recent_loaded: recentLoaded,
            largest_loaded: largestLoaded,
        });
    }, [action, largestLoaded, onSkip, recentLoaded, step]);

    const ctasEnabled = useMemo(() => {
        if (isTransitioning) {
            return false;
        }
        switch (step) {
            case Step.Recent:
                return recentLoaded;
            case Step.Largest:
                return largestLoaded;
        }
    }, [recentLoaded, largestLoaded, step, isTransitioning]);

    const firstSection = (inOpened: boolean, closeCard: () => void) =>
        isCompleted ? (
            <ActionCardCompleted header="Reporting unlocked">
                <div className="d-flex gap-2">
                    <ActionCloseButton onClick={closeCard} duration={5000} />
                    <Button
                        variant="secondary"
                        size="sm"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate("/cashflow");
                        }}
                    >
                        View Cashflow
                    </Button>
                    <Button
                        variant="secondary"
                        size="sm"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate("/accounting/profit-loss");
                        }}
                    >
                        View P&amp;L
                    </Button>
                </div>
            </ActionCardCompleted>
        ) : (
            <ActionCardSection
                inOpened={inOpened}
                header="Review your transactions to unlock reporting"
                icon={<GraphIcon />}
                isTitle
                ctas={
                    <>
                        {action.status !== ActionStatus.SKIPPED && (
                            <Button variant="secondary" onClick={skipAction}>
                                Skip
                            </Button>
                        )}
                        <ActionConfirmButton
                            loading={!!isLoading || isTransitioning}
                            disabled={!ctasEnabled}
                            onClick={confirmAction}
                        />
                    </>
                }
            ></ActionCardSection>
        );

    const otherSections = (inOpened: boolean) => (
        <>
            <ActionCardSection header={recentMessage} inOpened={inOpened}>
                {step === Step.Recent && (
                    <animated.div style={recentSpring}>
                        <div style={{ overflow: "hidden" }}>
                            <RecentTransactions
                                onLoadingComplete={(count) =>
                                    setRecentLoaded(count)
                                }
                            />
                        </div>
                    </animated.div>
                )}
            </ActionCardSection>

            <ActionCardSection
                header="Confirm the category for your 5 largest transactions"
                inOpened={inOpened}
            >
                {step === Step.Largest && (
                    <animated.div style={largestSpring}>
                        <div style={{ overflow: "hidden" }}>
                            <LargestTransactions
                                onLoadingComplete={(count) =>
                                    setLargestLoaded(count)
                                }
                            />
                        </div>
                    </animated.div>
                )}
            </ActionCardSection>
        </>
    );

    return (
        <ActionCard
            {...rest}
            firstSection={firstSection}
            otherSections={otherSections}
        />
    );
};
