import React, { useMemo } from "react";
import { last, orderBy } from "lodash";
import { Transaction } from "../../../../common/types/transaction";
import { TransactionDetailsSection } from "../TransactionDetailsSection";
import { useUncategorizedCategory } from "../../../../hooks/useUncategorizedCategory";
import {
    CategoryAuditLogValue,
    AuditLogProperty,
} from "../../../../common/types/auditLog";
import { TransactionDetailsHistoryLog } from "./TransactionDetailsHistoryLog";
import "./TransactionDetailsHistory.scss";

interface Props {
    transaction: Transaction;
}

export const TransactionDetailsHistory: React.FC<Props> = ({ transaction }) => {
    const uncategorized = useUncategorizedCategory();
    const displayedLogs = useMemo(() => {
        const sorted = orderBy(transaction.auditLogs, "date", "desc");
        const lastLog = last(sorted);

        if (
            lastLog?.property === AuditLogProperty.CATEGORY &&
            (lastLog?.newValue as CategoryAuditLogValue).categoryId ===
                uncategorized.id
        ) {
            sorted.pop();
        }

        return sorted;
    }, [transaction.auditLogs, uncategorized.id]);

    return displayedLogs.length ? (
        <TransactionDetailsSection title="History">
            <section
                className="transaction-details-history"
                data-testid="transaction-details-history"
            >
                {displayedLogs.map((log) => (
                    <TransactionDetailsHistoryLog log={log} key={log.id} />
                ))}
            </section>
        </TransactionDetailsSection>
    ) : null;
};
