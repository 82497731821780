import { useMemo } from "react";
import { range } from "lodash";
import { useEntities } from "../../../hooks/useEntities";

export function useAvailableYears() {
    const entities = useEntities();
    return useMemo(() => {
        const earliestBookkeepingStart = entities.reduce(
            (earliest, entity) =>
                entity.bookkeepingStart < earliest
                    ? entity.bookkeepingStart
                    : earliest,
            new Date(),
        );
        const earliestYear = earliestBookkeepingStart.getFullYear();
        const currentYear = new Date().getFullYear();

        return range(earliestYear, currentYear + 1).reverse();
    }, [entities]);
}
