import React from "react";
import classNames from "classnames";
import { TriangleIcon } from "../../icons";

interface NavigationSubsectionControllerProps {
    open: boolean;
    disabled?: boolean;
    isSubsection?: boolean;
    onToggle?: () => void;
}

export const NavigationSubsectionController = ({
    open,
    disabled,
    isSubsection,
    onToggle,
}: NavigationSubsectionControllerProps) => {
    const handleClick = (e: React.MouseEvent) => {
        if (disabled) {
            return;
        }
        e.stopPropagation();
        e.preventDefault();

        if (onToggle) {
            onToggle();
        }
    };

    return (
        <span
            className={classNames("navigation-subsection__controller", {
                "navigation-subsection__controller--active": open,
                "navigation-subsection__controller--disabled": disabled,
                "navigation-subsection__controller--subsection": isSubsection,
            })}
            onClick={handleClick}
        >
            <TriangleIcon className="navigation-subsection__controller__icon" />
        </span>
    );
};
