import React, { useCallback, useState } from "react";
import { Formik, FormikConfig } from "formik";
import { object, string } from "yup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useManageSteps } from "../useManageSteps";
import { Entity, EntityProfile } from "../../../common/types/entity";
import { OnboardingLayout } from "../layout/OnboardingLayout";
import { getOnboardingFlow } from "../getOnboardingFlow";
import { Profile } from "../steps/Profile";
import { useUser } from "../../../hooks/useUser";
import { trackEvent } from "../../../lib/analytics";
import { useUpdateUserMutation } from "../../../mutations/user";
import { useEditEntityMutation } from "../../../mutations/entity";
import { OnboardingStepKey } from "../steps";
import { Extends } from "../../../common/helpers/typescript";
import { ConnectAccounts } from "../steps/ConnectAccounts/ConnectAccounts";
import { ConnectAccountsSidebar } from "../steps/ConnectAccounts/ConnectAccountsSidebar";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import {
    useFinancialAccounts,
    usePlaidFinancialAccounts,
} from "../../../hooks/useFinancialAccounts";
import { PaymentProcessor } from "../steps/PaymentProcessor/PaymentProcessor";
import { PaymentProcessorSidebar } from "../steps/PaymentProcessor/PaymentProcessorSidebar";
import { Payroll } from "../steps/Payroll/Payroll";
import { PayrollSidebar } from "../steps/Payroll/PayrollSidebar";
import { ChoosePlan } from "../steps/ChoosePlan";
import { Outro } from "../steps/Outro/Outro";
import { PlanManagementProvider } from "../../billing/PlanManagement/PlanManagementProvider";
import { FLAT_RATE_PLANS } from "../../../common/flatRateBilling";
import { ProductPreview } from "../components/ProductPreview/ProductPreview";
import { submitHelper } from "../../../helpers/form";
import { OnboardingFlowType, ProfileFormSchema } from "../types";
import { WaitlistIntro } from "../steps/WaitlistIntro";
import { WaitlistComment } from "../steps/WaitlistComment";
import { WaitlistOutro } from "../steps/WaitlistOutro";
import { FirmInfo, FirmInfoProps } from "../steps/FirmInfo";
import { startEnterpriseTrial } from "../../../lib/flatRateBilling";
import { IntroCall } from "../steps/IntroCall";
import { HowItWorks } from "../steps/HowItWorks/HowItWorks";
import { AccountantWaitlistIntro } from "../steps/AccountantWaitlistIntro";
import { AccountantWaitlistOutro } from "../steps/AccountantWaitlistOutro";
import { usePromise } from "../../../hooks/usePromise";
import { StandardModal } from "../../general/Modal/Modal";
import { ConnectPrivateAccounts } from "../components/ConnectPrivateAccounts/ConnectPrivateAccounts";
import { MobileBlock } from "../../general/MobileBlock/MobileBlock";
import { useMobileBlockScreen } from "../../../hooks/useMobileView";
import { trackSignupComplete } from "../../../lib/onboarding";
import { CheckIcon, LogoutIcon } from "../../../icons";
import { useLogout } from "../../../hooks/useLogout";
import containerStyles from "../components/OnboardingContainer.module.scss";
import { useEmailVerifiedTracking } from "../useEmailVerifiedTracking";
import { useEntityProfileFormConfig } from "../useEntityProfileFormConfig";
import { Button } from "../../general/Button/Button";

type PrimaryFlowSteps = Extends<
    OnboardingStepKey,
    | "profile"
    | "waitlistIntro"
    | "waitlistComment"
    | "waitlistOutro"
    | "firmInfo"
    | "introCall"
    | "outro"
    | "connectAccounts"
    | "paymentProcessor"
    | "payroll"
    | "choosePlan"
    | "howItWorks"
    | "accountantWaitlistIntro"
    | "accountantWaitlistOutro"
    | "mobileBlock"
>;

export interface ProfilePayload {
    firstName: string;
    lastName: string;
    name: string;
    profile: EntityProfile;
}

interface Props {
    entity: Entity;
    onFinish(): Promise<void>;
}

export const PrimaryFlow: React.FC<Props> = ({ entity, onFinish }) => {
    const user = useUser();
    const logout = useLogout();
    const updateUserMutation = useUpdateUserMutation();
    const updateEntityMutation = useEditEntityMutation(entity);
    const plaidAccounts = usePlaidFinancialAccounts();
    const allFinancialAccounts = useFinancialAccounts();
    const [profileFormBusy, setProfileFormBusy] = useState(false);
    const isMobile = useMobileBlockScreen();

    useEmailVerifiedTracking();

    const flow = getOnboardingFlow(entity);

    const { isFetched: billingStatusFetched, isSubscribed } =
        useBillingStatus();

    const getInitialStep = useCallback((): PrimaryFlowSteps => {
        if (!user.preferredName || !entity.profile?.type) {
            return "profile";
        }

        if (isMobile) {
            return "mobileBlock";
        }

        if (flow === OnboardingFlowType.WAITLIST) {
            if (!entity.profile?.waitlistComment) {
                return "waitlistIntro";
            }

            return "waitlistOutro";
        }

        if (
            flow === OnboardingFlowType.ACCOUNTANT ||
            flow === OnboardingFlowType.BOOKKEEPER
        ) {
            if (!entity.profile?.firmSize) {
                return "firmInfo";
            }

            if (!entity.profile?.waitlistComment) {
                return "accountantWaitlistIntro";
            }

            return "accountantWaitlistOutro";
        }

        if (
            !plaidAccounts?.length ||
            plaidAccounts.some((acc) => acc.isBusiness === null)
        ) {
            return "howItWorks";
        }

        if (!isSubscribed) {
            return "choosePlan";
        }

        return "outro";
    }, [
        entity.profile?.firmSize,
        entity.profile?.type,
        entity.profile?.waitlistComment,
        flow,
        isMobile,
        isSubscribed,
        plaidAccounts,
        user.preferredName,
    ]);

    const { go, currentStep, nextStep, isExiting } =
        useManageSteps<PrimaryFlowSteps>(billingStatusFetched, getInitialStep);

    const handleEntityProfileUpdate = useCallback(
        async (payload: Partial<EntityProfile>) =>
            await updateEntityMutation.mutateAsync({
                profile: {
                    ...entity.profile,
                    ...payload,
                },
            }),
        [entity.profile, updateEntityMutation],
    );

    const [connectPersonalReminder, setConnectPersonalReminder] =
        useState(false);

    const [reminderPromise, { resolve: confirmReminder }] = usePromise<void>();

    const beforeFirstConnection = useCallback(() => {
        if (allFinancialAccounts.length > 0) {
            confirmReminder();
        } else {
            setConnectPersonalReminder(true);
            reminderPromise.then(() => setConnectPersonalReminder(false));
        }

        return reminderPromise;
    }, [confirmReminder, allFinancialAccounts.length, reminderPromise]);

    const handleReminderConfirmed = useCallback(async () => {
        setConnectPersonalReminder(false);
        // For smooth animation between closing confiramtion modal and opening accounts modal
        await new Promise((resolve) => setTimeout(resolve, 500));
        confirmReminder();
    }, [confirmReminder]);

    const handleProfileSetup = useCallback(
        async ({ profile, name, lastName, firstName }: ProfilePayload) => {
            await updateUserMutation.mutateAsync({
                preferredName: `${firstName} ${lastName}`,
            });

            const updatedEntity = await updateEntityMutation.mutateAsync({
                name,
                profile: {
                    ...entity.profile,
                    ...profile,
                },
            });

            const flowForUpdatedEntity = getOnboardingFlow(updatedEntity);

            void trackEvent("signup_application_submitted", {
                name,
                ...profile,
                application_status: flowForUpdatedEntity.toLowerCase(),
            });

            if (isMobile) {
                go("mobileBlock");
                return;
            }

            switch (flowForUpdatedEntity) {
                case OnboardingFlowType.WAITLIST:
                    go("waitlistIntro");
                    break;
                case OnboardingFlowType.ACCOUNTANT:
                case OnboardingFlowType.BOOKKEEPER:
                    go("firmInfo");
                    break;
                default:
                    go("howItWorks");
            }
        },
        [
            entity.profile,
            go,
            isMobile,
            updateEntityMutation,
            updateUserMutation,
        ],
    );

    const handlePaymentProcessors = useCallback(
        async (requestedProcessors: string) => {
            await handleEntityProfileUpdate({
                paymentProcessorRequest: requestedProcessors,
            });
            go("payroll");
        },
        [go, handleEntityProfileUpdate],
    );

    const handlePayrollProviders = useCallback(
        async (payrollProviders: string[]) => {
            await handleEntityProfileUpdate({
                payrollProviders,
            });

            await trackSignupComplete();

            go("choosePlan");
        },
        [go, handleEntityProfileUpdate],
    );

    const handleOnboardingPaymentInitiated = useCallback(
        (plan: FLAT_RATE_PLANS) => {
            void trackEvent("onboarding_payment_initiated", {
                plan,
            });
        },
        [],
    );

    const handleWaitlist = useCallback(
        async (comment: string) => {
            await handleEntityProfileUpdate({ waitlistComment: comment });
            go("waitlistOutro");
        },
        [go, handleEntityProfileUpdate],
    );

    const handleAccountantWaitlist = useCallback(
        async (comment: string) => {
            await handleEntityProfileUpdate({ waitlistComment: comment });
            go("accountantWaitlistOutro");
        },
        [go, handleEntityProfileUpdate],
    );

    const handleFirmInfo: FirmInfoProps["onSubmit"] = useCallback(
        async (payload) => {
            await handleEntityProfileUpdate(payload);

            go("accountantWaitlistIntro");
        },
        [go, handleEntityProfileUpdate],
    );

    const handleIntroCallBooked = useCallback(async () => {
        await startEnterpriseTrial();
        await updateUserMutation.mutateAsync({ bookedEnterpriseCall: true });

        go("connectAccounts");
    }, [go, updateUserMutation]);

    const { initialValues, validationSchema } =
        useEntityProfileFormConfig(entity);

    const form: FormikConfig<ProfileFormSchema> = {
        initialValues: {
            firstName: user.firstName ?? "",
            lastName: user.lastName ?? "",
            source: entity.profile?.source ?? "",
            ...initialValues,
        },
        validationSchema: validationSchema.concat(
            object().shape({
                firstName: string().required("First name is required"),
                lastName: string().required("Last name is required"),
            }),
        ),
        onSubmit: submitHelper({
            loading: profileFormBusy,
            setLoading: setProfileFormBusy,
            handler: async ({
                type,
                name,
                firstName,
                lastName,
                country,
                source,
                industry,
                industryOther,
                annualRevenue,
            }) => {
                await handleProfileSetup({
                    firstName,
                    lastName,
                    name,
                    profile: {
                        type,
                        industry,
                        industryOther,
                        annualRevenue,
                        country,
                        source,
                    },
                });
            },
        }),
    };

    let main: React.ReactNode;
    let sidebar: React.ReactNode;
    switch (currentStep) {
        case "profile":
            main = <Profile busy={profileFormBusy} />;
            sidebar = <ProductPreview />;

            break;

        case "connectAccounts":
            main = (
                <ConnectAccounts
                    onBack={() => go("profile")}
                    onFinished={() => go("paymentProcessor")}
                    beforeConnect={beforeFirstConnection}
                />
            );

            sidebar = <ConnectAccountsSidebar />;
            break;

        case "paymentProcessor":
            main = (
                <PaymentProcessor
                    initialValue={entity.profile?.paymentProcessorRequest ?? ""}
                    financialAccounts={allFinancialAccounts}
                    onSubmit={handlePaymentProcessors}
                    back={() => go("connectAccounts")}
                />
            );

            sidebar = <PaymentProcessorSidebar />;
            break;

        case "payroll":
            main = (
                <Payroll
                    payrollProviders={entity.profile?.payrollProviders ?? []}
                    onSubmit={handlePayrollProviders}
                    back={() => go("paymentProcessor")}
                />
            );

            sidebar = <PayrollSidebar />;
            break;

        case "choosePlan":
            main = (
                <PlanManagementProvider
                    onBeforeUpgrade={handleOnboardingPaymentInitiated}
                >
                    <ChoosePlan
                        onContinue={() => go("outro")}
                        back={() => go("payroll")}
                    />
                </PlanManagementProvider>
            );
            break;

        case "outro":
            main = <Outro onFinished={onFinish} />;
            break;

        case "waitlistIntro":
            main = (
                <WaitlistIntro
                    entity={entity}
                    onBack={() => go("profile")}
                    onNext={() => go("waitlistComment")}
                />
            );
            sidebar = <ProductPreview />;
            break;

        case "waitlistComment":
            main = (
                <WaitlistComment entity={entity} onSubmit={handleWaitlist} />
            );
            sidebar = <ProductPreview />;
            break;

        case "waitlistOutro":
            main = <WaitlistOutro />;
            sidebar = <ProductPreview />;
            break;

        case "firmInfo":
            main = (
                <FirmInfo
                    entity={entity}
                    onSubmit={handleFirmInfo}
                    back={() => go("profile")}
                />
            );
            sidebar = <ProductPreview />;
            break;

        case "introCall":
            main = (
                <IntroCall
                    onBack={() => go("firmInfo")}
                    onCallBooked={handleIntroCallBooked}
                />
            );
            break;

        case "howItWorks":
            main = (
                <HowItWorks
                    onBack={() => go("profile")}
                    onFinish={() => go("connectAccounts")}
                />
            );
            break;

        case "accountantWaitlistIntro":
            main = (
                <AccountantWaitlistIntro
                    entity={entity}
                    onBack={() => go("profile")}
                    onSubmit={handleAccountantWaitlist}
                />
            );
            sidebar = <ProductPreview />;
            break;

        case "accountantWaitlistOutro":
            main = (
                <AccountantWaitlistOutro
                    onBack={() => go("accountantWaitlistIntro")}
                />
            );
            sidebar = <ProductPreview />;
            break;

        case "mobileBlock":
            main = (
                <MobileBlock
                    icon={<CheckIcon />}
                    heading="Application accepted"
                >
                    <p>Kick isn't available on mobile just yet.</p>
                    <p>
                        To continue setting up your account, please log in on a
                        desktop browser.
                    </p>
                </MobileBlock>
            );
            break;
    }

    return (
        <>
            <Formik {...form}>
                <OnboardingLayout
                    currentStep={currentStep}
                    nextStep={nextStep}
                    isExiting={isExiting}
                    main={main}
                    sidebar={sidebar}
                />
            </Formik>

            <OverlayTrigger
                placement="left"
                overlay={
                    <Tooltip id="onboarding-log-out-tooltip">Log out</Tooltip>
                }
            >
                <Button
                    variant="tertiary"
                    className={`${containerStyles.close} btn-icon`}
                    onClick={logout}
                    icon
                >
                    <LogoutIcon />
                </Button>
            </OverlayTrigger>

            <StandardModal
                show={connectPersonalReminder}
                onHide={handleReminderConfirmed}
                size="lg"
            >
                <ConnectPrivateAccounts onContinue={handleReminderConfirmed} />
            </StandardModal>
        </>
    );
};
