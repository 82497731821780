import React from "react";
import { Entity } from "../../common/types/entity";
import { Button, ExtendedButtonProps } from "../general/Button/Button";
import { useCommands } from "../commands/useCommands";

import { CommandsDirectory } from "../commands/lib";

interface Props {
    btnText: string;
    btnVariant?: ExtendedButtonProps["variant"];
    btnSize?: "sm" | "lg";
    btnClassName?: string;
    defaultEntity?: Entity;
}

export const AddAccount: React.FC<Props> = ({
    btnClassName,
    btnSize,
    btnText,
    btnVariant,
    defaultEntity,
}) => {
    const { open } = useCommands();

    return (
        <Button
            onClick={() => open(CommandsDirectory.ACCOUNTS, { defaultEntity })}
            variant={btnVariant}
            className={btnClassName}
            size={btnSize}
            data-testid="add-account-btn"
        >
            {btnText}
        </Button>
    );
};
