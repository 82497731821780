import {
    FinancialDocument,
    FinancialDocumentBase,
    SpreadsheetImporterStatus,
} from "../../types/financialDocument";
import { unpackDateOrNull } from "../dto-helpers";

export class FinancialDocumentBaseDto {
    static deserialize(dto: FinancialDocumentBaseDto): FinancialDocumentBase {
        return {
            ...dto,
            createdAt: new Date(dto.createdAt),
            date: unpackDateOrNull(dto.date),
            amount: dto.amount,
            spreadsheetImporterStatus: dto.spreadsheetImporterStatus,
            spreadsheetParsingRuleId: dto.spreadsheetParsingRuleId,
            isSpreadsheet: dto.isSpreadsheet,
            numTransactions: dto.numTransactions,
            csvFormatStr: dto.csvFormatStr,
        };
    }

    id: number;
    source: string;
    createdAt: string;
    path: string;
    fileName: string;
    hasAttemptedMatching: boolean;
    amount: number | null;
    date: string | null;
    spreadsheetImporterStatus: SpreadsheetImporterStatus;
    spreadsheetParsingRuleId: number | null;
    isSpreadsheet: boolean | null;
    numTransactions: number | null;
    csvFormatStr: string | null;

    constructor(
        financialDocument: Omit<
            FinancialDocument,
            "transactionMatches" | "entity"
        >,
    ) {
        this.id = financialDocument.id;
        this.source = financialDocument.source;
        this.createdAt = financialDocument.createdAt.toISOString();
        this.path = financialDocument.path;
        this.fileName = financialDocument.fileName;
        this.hasAttemptedMatching = financialDocument.hasAttemptedMatching;
        this.amount = financialDocument.amount ?? null;
        this.date = financialDocument.date?.toISOString() ?? null;
        this.spreadsheetImporterStatus =
            financialDocument.spreadsheetImporterStatus ?? null;
        this.spreadsheetParsingRuleId =
            financialDocument.spreadsheetParsingRuleId ?? null;
        this.isSpreadsheet = financialDocument.isSpreadsheet ?? null;
        this.numTransactions = financialDocument.numTransactions ?? null;
        this.csvFormatStr = financialDocument.csvFormatStr ?? null;
    }
}
