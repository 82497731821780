import React, { useMemo } from "react";
import { PopoverButtonProps, PopoverContainer } from "../PopoverContainer";
import { Entity } from "../../../common/types/entity";
import {
    AccountsFilterUICommonProps,
    AccountsFilterValueDisplay,
    EntityFilterSharedProps,
} from "./lib";
import { AccountsFilterBody } from "./AccountsFilterBody";
import { AccountsFilterEntityValueDisplay } from "./AccountsFilterEntityValueDisplay";
import { AccountsFilterAccountValueDisplay } from "./AccountsFilterAccountValueDisplay";

export interface AccountsFilterProps
    extends PopoverButtonProps,
        EntityFilterSharedProps,
        AccountsFilterUICommonProps {
    entities: Entity[];
    valueDisplay: AccountsFilterValueDisplay;
    everythingSelected: boolean;
    readOnly?: boolean;
}

export const AccountsFilterDropdown: React.FC<AccountsFilterProps> = ({
    excludeNoBalanceAccounts,
    singleEntityName,
    entities,
    valueDisplay,
    isEntitySelected,
    isAccountSelected,
    toggleEntity,
    toggleAccount,
    everythingSelected,
    showEntitiesWithoutAccounts,
    showSelectedIcons = true,
    readOnly = false,
    ...popoverButtonProps
}) => {
    const selectedEntities = useMemo(
        () => entities.filter((e) => isEntitySelected(e.id)),
        [entities, isEntitySelected],
    );

    const label = useMemo(
        () => (
            <span className="accounts-filter__label">
                {valueDisplay === AccountsFilterValueDisplay.ENTITY ? (
                    <AccountsFilterEntityValueDisplay
                        everythingSelected={everythingSelected}
                        selectedEntities={selectedEntities}
                        singleEntityName={singleEntityName}
                        showSelectedIcons={showSelectedIcons}
                    />
                ) : (
                    <AccountsFilterAccountValueDisplay
                        isAccountSelected={isAccountSelected}
                        showSelectedIcons={showSelectedIcons}
                    />
                )}
            </span>
        ),
        [
            selectedEntities,
            singleEntityName,
            everythingSelected,
            showSelectedIcons,
            valueDisplay,
            isAccountSelected,
        ],
    );

    return (
        <PopoverContainer
            disabled={readOnly}
            id="accounts-filter"
            buttonText={label}
            offset={4}
            {...popoverButtonProps}
        >
            <AccountsFilterBody
                entities={entities}
                isEntitySelected={isEntitySelected}
                toggleEntity={toggleEntity}
                showEntitiesWithoutAccounts={showEntitiesWithoutAccounts}
                isAccountSelected={isAccountSelected}
                toggleAccount={toggleAccount}
                excludeNoBalanceAccounts={excludeNoBalanceAccounts}
            />
        </PopoverContainer>
    );
};
