import React from "react";
import { HideIcon } from "../../../icons";
import { TransactionActionsMessage } from "./TransactionActionsMessage";

export const TransactionIgnoredMessage: React.FC = () => (
    <TransactionActionsMessage
        header={
            <>
                <HideIcon className="icon-color-current" /> Hidden from reports
            </>
        }
    >
        <p>
            You've hidden this transaction from reports which means it won't be
            deductible.
        </p>
    </TransactionActionsMessage>
);
