import { User } from "../common/types/user";
import { backendClient } from "./backendClient";

export function getAnalytics(): SegmentAnalytics.AnalyticsJS | undefined {
    return (window as any).analytics;
}

export function getCio(): any {
    return (window as any)._cio;
}

interface CioIdentifyParams {
    id: number;
    client_id?: string;
}

export function identifyUser(user: User) {
    const [, gaCookie] = document.cookie.match(/_ga=(.+?);/) ?? [];
    const clientId = gaCookie?.split(".").slice(-2).join(".");

    const cioParams: CioIdentifyParams = {
        id: user.id,
    };

    if (clientId) {
        cioParams.client_id = clientId;
    }

    getAnalytics()?.identify(user.id);
    getCio()?.identify(cioParams);
}

export async function trackEvent(
    name: string,
    properties: Record<string, unknown> = {},
) {
    await backendClient.post("/t/e", { name, properties });
}
