import { QueryFunction, useQueries, useQuery } from "@tanstack/react-query";
import {
    invoiceContract,
    InvoiceQueryParams,
    InvoiceStatsParams,
} from "../common/contracts/invoice.contract";
import { getBackendAPIClient } from "../lib/backendAPIClient";
import { useWorkspaceContext } from "../state/workspaceContext";
import { queryClient } from "../queryClient";

export const invoiceContractClient = getBackendAPIClient(invoiceContract, {
    jsonQuery: true,
});

type InvoiceQueryKey = [
    "invoices",
    string | undefined,
    InvoiceQueryParams | undefined,
];
type InvoiceStatsQueryKey = [
    "invoiceStats",
    string | undefined,
    InvoiceStatsParams | undefined,
];

export const getInvoicesQueryKey = (
    workspaceId?: string,
    params?: InvoiceQueryParams,
): InvoiceQueryKey => ["invoices", workspaceId, params];

export const getInvoiceStatsQueryKey = (
    workspaceId?: string,
    params?: InvoiceStatsParams,
): InvoiceStatsQueryKey => ["invoiceStats", workspaceId, params];

export function useInvoicesWithStats(query: InvoiceQueryParams) {
    const { activeWorkspace } = useWorkspaceContext();

    return useQueries({
        queries: [
            {
                queryKey: getInvoicesQueryKey(activeWorkspace?.id, query),
                queryFn: fetchInvoices,
            },
            {
                queryKey: getInvoiceStatsQueryKey(activeWorkspace?.id, {
                    filters: query.filters,
                }),
                queryFn: fetchInvoiceStats,
            },
        ],
    });
}

export function useInvoices(query: InvoiceQueryParams) {
    const { activeWorkspace } = useWorkspaceContext();

    return useQuery({
        queryKey: getInvoicesQueryKey(activeWorkspace?.id, query),
        queryFn: fetchInvoices,
    });
}

export function useInvoiceStats(query: InvoiceStatsParams) {
    const { activeWorkspace } = useWorkspaceContext();

    return useQuery({
        queryKey: getInvoiceStatsQueryKey(activeWorkspace?.id, query),
        queryFn: fetchInvoiceStats,
    });
}

export function invalidateInvoiceQueries(workspaceId: string) {
    queryClient.invalidateQueries({
        queryKey: getInvoicesQueryKey(workspaceId),
    });
    queryClient.invalidateQueries({
        queryKey: getInvoiceStatsQueryKey(workspaceId),
    });
}

const fetchInvoices: QueryFunction<any, InvoiceQueryKey> = async ({
    queryKey,
}) => {
    const [, workspaceId, query] = queryKey;

    if (!workspaceId) {
        return;
    }
    const result = await invoiceContractClient.getInvoices({
        params: { workspaceId },
        query: {
            ...query,
            page: (query?.page ?? 1) - 1,
            limit: query?.limit ?? 10,
        },
    });
    return result.body;
};

const fetchInvoiceStats: QueryFunction<any, InvoiceStatsQueryKey> = async ({
    queryKey,
}) => {
    const [, workspaceId, query] = queryKey;

    if (!workspaceId) {
        return;
    }
    const result = await invoiceContractClient.getInvoiceStats({
        params: { workspaceId },
        query,
    });
    return result.body;
};
