import { FinancialAccount } from "../types/financialAccount";
import { ConnectionProviderType } from "../types/financialConnection";
import { getLeadingAccountConnection } from "./financialAccount";
import { isPlaidConnection } from "./financialConnection";

export enum Processors {
    VENMO = "VENMO",
    PAYPAL = "PAYPAL",
    STRIPE = "STRIPE",
    CASH_APP = "CASH_APP",
}

type ProcessorsConnectedViaPlaid = Exclude<Processors, Processors.STRIPE>;

const processorPlaidBankNames = {
    [Processors.VENMO]: "venmo - personal",
    [Processors.PAYPAL]: "paypal",
    [Processors.CASH_APP]: "cashapp",
} satisfies Record<ProcessorsConnectedViaPlaid, string>;

export function isPaymentProcessorAccount(
    financialAccount: FinancialAccount,
): boolean {
    const connection = getLeadingAccountConnection(financialAccount);

    if (!connection) {
        return false;
    }

    return (
        connection.connectionProvider === ConnectionProviderType.STRIPE ||
        (isPlaidConnection(connection) &&
            Object.values(processorPlaidBankNames)
                .map((b) => b.toLowerCase())
                .includes(connection.bankName.toLowerCase()))
    );
}

export function isSpecificPaymentProcessorAccount(
    financialAccount: FinancialAccount,
    processor: Processors,
): boolean {
    const connection = getLeadingAccountConnection(financialAccount);

    if (!connection) {
        return false;
    }

    if (processor === Processors.STRIPE) {
        return connection.connectionProvider === ConnectionProviderType.STRIPE;
    }

    if (!isPlaidConnection(connection)) {
        return false;
    }

    return (
        connection.bankName.toLowerCase() ===
        processorPlaidBankNames[processor].toLowerCase()
    );
}
