// reflects Stripes subscription status 1:1 for now
import { endOfMonth, endOfYear, startOfYear, subYears } from "date-fns";
import { BillingInterval } from "./types/billing";

export const ENTERPRISE_TRIAL_CALL_URL =
    "https://calendly.com/d/ckdh-jk5-sk2/kick-personalized-onboarding-call";

export const PARTNER_INTRO_CALL_URL =
    "https://calendly.com/echo_kick/kick-bookkeeper-dec24";

export const ONBOARDING_CALL_URL =
    "https://calendly.com/d/ckdh-jk5-sk2/kick-intro";

export enum SubscriptionStatus {
    ACTIVE = "ACTIVE",
    CANCELLED = "CANCELLED",
    INCOMPLETE = "INCOMPLETE",
    INCOMPLETE_EXPIRED = "INCOMPLETE_EXPIRED",
    PAST_DUE = "PAST_DUE",
    PAUSED = "PAUSED",
    TRIALING = "TRIALING",
    UNPAID = "UNPAID",
}

export const ACTIVE_SUBSCRIPTION_STATUSES = [
    SubscriptionStatus.ACTIVE,
    SubscriptionStatus.TRIALING,
    SubscriptionStatus.PAST_DUE,
];

export enum InvoiceStatus {
    DRAFT = "DRAFT",
    OPEN = "OPEN",
    PAID = "PAID",
    VOID = "VOID",
    UNCOLLECTIBLE = "UNCOLLECTIBLE",
}

export const DEFAULT_FREE_SAVINGS_CREDITS = 10_000;
export const DEFAULT_FREE_USAGE_LIMIT = 25_000;

export type DiscountType = "flat" | "percent";
export interface BillingDateFilters {
    start: Date;
    end: Date;
}

export function currentYearFilters() {
    return {
        start: startOfYear(new Date()),
        end: endOfMonth(new Date()),
    };
}

export function previousYearFilters() {
    return {
        start: subYears(startOfYear(new Date()), 1),
        end: subYears(endOfYear(new Date()), 1),
    };
}

export function getBillingIntervalAdjective(
    interval: BillingInterval,
    count: number,
): string {
    if (interval === "year") {
        return "annually";
    }

    if (interval === "month" && count === 1) {
        return "monthly";
    }

    if (interval === "month" && count === 3) {
        return "quarterly";
    }

    return `every ${count} months`;
}

export function getMonthlyAmount(
    unitAmount: number,
    interval: BillingInterval,
    intervalCount: number,
): number {
    if (interval === "year") {
        return unitAmount / 12;
    }

    return unitAmount / intervalCount;
}

export function getAnnualAmount(
    unitAmount: number,
    interval: BillingInterval,
    intervalCount: number,
) {
    if (interval === "year") {
        return unitAmount;
    }

    return (12 / intervalCount) * unitAmount;
}
