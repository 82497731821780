export const copyTextToClipboard = async (text: string) => {
    // Check if the Clipboard API is supported
    if (navigator?.clipboard?.writeText) {
        await navigator.clipboard.writeText(text);
        return true;
    } else {
        // Fallback for browsers that don't support the Clipboard API
        return false;
    }
};

export const readTextFromClipboard = async (): Promise<string | null> => {
    try {
        // Check if the Clipboard API is supported
        if (navigator.clipboard && navigator.clipboard.readText) {
            const text = await navigator.clipboard.readText();
            return text;
        }
        return null;
    } catch {
        return null;
    }
};

export const convertClipboardTextIntoCells = (text: string) => {
    const lines = text.split(/\r?\n/);
    return lines.map((line) => line.split("	"));
};
