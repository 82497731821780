import React, { useCallback } from "react";
import { UpdateProfileDto } from "../../../../common/dto/user/update-profile.dto";
import { updateProfile } from "../../../../lib/user";

import { PlainModal } from "../../../general/Modal/Modal";
import { useUpdateUser } from "../../../../hooks/useUpdateUser";
import { useToaster } from "../../../general/ToastMessages/useToaster";
import { UpdateProfileFlow } from "./UpdateProfileFlow";
import { EditableProfileFields } from "./types";

interface Props {
    update: EditableProfileFields | null;
    onClose(): void;
}

export const UpdateProfileModal: React.FC<Props> = ({ update, onClose }) => {
    const updateUser = useUpdateUser();
    const { toast, error } = useToaster();

    const handleSuccess = useCallback(
        async (payload: UpdateProfileDto) => {
            try {
                updateUser(await updateProfile(payload));

                toast("Profile updated");

                onClose();
            } catch {
                error(`Error when updating profile`);
            }
        },
        [toast, error, onClose, updateUser],
    );

    if (!update) {
        return null;
    }

    return (
        <PlainModal show onHide={onClose}>
            <UpdateProfileFlow
                update={update}
                onSuccess={handleSuccess}
                onCancel={onClose}
            />
        </PlainModal>
    );
};
