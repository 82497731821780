import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

import { JournalEntryLineProxyObject } from "../journalEntryModal/JournalEntryModal";
import { UpdateAndReturnNewLineParams } from "../journalEntryModal/JournalEntryModalHooks";
import {
    doesSelectedAreaContainOnlyOneCell,
    isSamePosition,
} from "./EditableTableUtils";

export interface CellPosition {
    columnIndex: number;
    rowIndex: number;
}
export interface SelectedArea {
    topLeftCellPosition: CellPosition;
    bottomRightCellPosition: CellPosition;
}

interface EditableTableContextState<T = any> {
    selectedArea: SelectedArea | null;
    setSelectedArea: (
        topLeftPassed: CellPosition | null,
        bottomRightPassed: CellPosition | null,
    ) => void;
    targetLines: T[];
    combineWithExistingSelectedArea: (
        topLeftPassed: CellPosition | null,
        bottomRightPassed: CellPosition | null,
    ) => void;
    bodyRef: React.Ref<HTMLDivElement> | null;
    headerRef: React.Ref<HTMLDivElement> | null;
    isInBuffer: boolean;
    setIsInBuffer: (isInBuffer: boolean) => void;
    updateAndReturnNewLine: (params: UpdateAndReturnNewLineParams<T>) => T;
}
export const EditableTableContext = createContext<EditableTableContextState>({
    selectedArea: null,
    setSelectedArea: () => {},
    targetLines: [],
    combineWithExistingSelectedArea: () => {},
    bodyRef: null,
    headerRef: null,
    isInBuffer: false,
    setIsInBuffer: () => {},
    updateAndReturnNewLine: (params: UpdateAndReturnNewLineParams<any>) =>
        params.line,
});
interface Props<T> {
    targetLines: T[];
    updateAndReturnNewLine: (params: UpdateAndReturnNewLineParams<T>) => T;
    children: React.ReactNode;
}

export const EditableTable = ({
    targetLines,
    children,
    updateAndReturnNewLine,
}: Props<JournalEntryLineProxyObject>) => {
    const [selectedArea, setSelectedAreaState] = useState<SelectedArea | null>(
        null,
    );
    const [isInBuffer, setIsInBuffer] = useState(false);
    const selectedAreaRef = useRef(selectedArea);
    const bodyRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        selectedAreaRef.current = selectedArea;
    }, [selectedArea]);

    const setSelectedArea = useCallback(
        (
            topLeftPassed: CellPosition | null,
            bottomRightPassed: CellPosition | null,
        ) => {
            if (topLeftPassed !== null && bottomRightPassed !== null) {
                if (selectedAreaRef.current !== null) {
                    if (
                        isSamePosition(
                            topLeftPassed,
                            selectedAreaRef.current.topLeftCellPosition,
                        ) &&
                        isSamePosition(
                            bottomRightPassed,
                            selectedAreaRef.current.bottomRightCellPosition,
                        )
                    ) {
                        return;
                    }
                }
                const newArea = {
                    topLeftCellPosition: topLeftPassed,
                    bottomRightCellPosition: bottomRightPassed,
                };
                setIsInBuffer(false);
                setSelectedAreaState(newArea);
                if (!doesSelectedAreaContainOnlyOneCell(newArea)) {
                    if (document.activeElement instanceof HTMLElement) {
                        document.activeElement.blur();
                    }
                }
                return;
            }
            setSelectedAreaState(null);
            setIsInBuffer(false);
        },
        [selectedAreaRef, setSelectedAreaState, setIsInBuffer],
    );
    const combineWithExistingSelectedArea = useCallback(
        (
            topLeftPassed: CellPosition | null,
            bottomRightPassed: CellPosition | null,
        ) => {
            if (!topLeftPassed || !bottomRightPassed) {
                return;
            }

            let topLeftCellPosition: CellPosition | null = {
                rowIndex: Math.min(
                    topLeftPassed?.rowIndex ?? Infinity,
                    selectedAreaRef.current?.topLeftCellPosition.rowIndex ??
                        Infinity,
                ),
                columnIndex: Math.min(
                    topLeftPassed?.columnIndex ?? Infinity,
                    selectedAreaRef.current?.topLeftCellPosition.columnIndex ??
                        Infinity,
                ),
            };
            let bottomRightCellPosition: CellPosition | null = {
                rowIndex: Math.max(
                    bottomRightPassed?.rowIndex ?? -Infinity,
                    selectedAreaRef.current?.bottomRightCellPosition.rowIndex ??
                        -Infinity,
                ),
                columnIndex: Math.max(
                    bottomRightPassed?.columnIndex ?? -Infinity,
                    selectedAreaRef.current?.bottomRightCellPosition
                        .columnIndex ?? -Infinity,
                ),
            };
            if (
                topLeftCellPosition.rowIndex === Infinity ||
                topLeftCellPosition.columnIndex === Infinity ||
                bottomRightCellPosition.rowIndex === -Infinity ||
                bottomRightCellPosition.columnIndex === -Infinity
            ) {
                topLeftCellPosition = null;
                bottomRightCellPosition = null;
            }
            setSelectedArea(topLeftCellPosition, bottomRightCellPosition);
        },
        [selectedAreaRef, setSelectedArea],
    );

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            // Check if click was outside the container
            if (
                bodyRef.current &&
                !bodyRef.current.contains(event.target) &&
                headerRef.current &&
                !headerRef.current.contains(event.target)
            ) {
                setSelectedArea(null, null);
                setIsInBuffer(false);
            }
        };

        // Bind the event listener to the window object
        window.addEventListener("click", handleClickOutside);

        // Cleanup by removing the event listener when component unmounts
        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, [bodyRef, headerRef, setSelectedArea]);

    const values = {
        selectedArea,
        setSelectedArea,
        targetLines,
        combineWithExistingSelectedArea,
        bodyRef,
        headerRef,
        isInBuffer,
        setIsInBuffer,
        updateAndReturnNewLine,
    };
    return (
        <EditableTableContext.Provider value={values}>
            {children}
        </EditableTableContext.Provider>
    );
};

export function useEditableTableContext<T>() {
    const context =
        useContext<EditableTableContextState<T>>(EditableTableContext);

    return context;
}
