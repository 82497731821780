import { CalculatePriceDto } from "../common/dto/billing/flatRate/calculate-price.dto";
import { PrepareCheckoutResponseDto } from "../common/dto/billing/flatRate/prepare-checkout-response.dto";
import { CalculatePriceResponseDto } from "../common/dto/billing/flatRate/calculate-price-response.dto";
import { SubscribeDto } from "../common/dto/billing/flatRate/subscribe.dto";
import { UpgradeDto } from "../common/dto/billing/flatRate/upgrade.dto";
import { ConvertDto } from "../common/dto/billing/flatRate/convert.dto";
import { DowngradeDto } from "../common/dto/billing/flatRate/downgrade.dto";
import { backendClient, unwrapResponse } from "./backendClient";

export async function prepareCheckout(): Promise<PrepareCheckoutResponseDto> {
    return unwrapResponse(
        await backendClient.post(`/flat-rate-billing/prepare-checkout`),
    );
}

export async function startEnterpriseTrial() {
    return unwrapResponse(
        await backendClient.post(`/flat-rate-billing/enterprise-trial`),
    );
}

export async function getPricing(payload: CalculatePriceDto) {
    const response = unwrapResponse(
        await backendClient.post(`/flat-rate-billing/calculate-price`, payload),
    );

    return CalculatePriceResponseDto.deserialize(response);
}

export async function subscribe(payload: SubscribeDto) {
    await backendClient.post(`/flat-rate-billing/subscribe`, payload);
}

export async function upgrade(payload: UpgradeDto) {
    await backendClient.post(`/flat-rate-billing/upgrade`, payload);
}

export async function convert(payload: ConvertDto) {
    await backendClient.post(`/flat-rate-billing/convert`, payload);
}

export async function downgrade(payload: DowngradeDto) {
    await backendClient.post(`/flat-rate-billing/downgrade`, payload);
}
