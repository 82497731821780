import React, { ReactNode } from "react";
import { BulkActionsToastData } from "../../../general/ToastMessages/lib/bulkActions";

import { pluralize } from "../../../../common/helpers/string";
import { useCategoryLabelGetter } from "../../../../hooks/useCategoryLabelGetter";
import { Button } from "../../../general/Button/Button";
import { usePersonalCategory } from "../../../../hooks/usePersonalCategory";

type Props = BulkActionsToastData;

export const ToastSuccess: React.FC<Props> = ({
    updateDetails,
    previousTransactions,
    undo,
}) => {
    const getCategoryLabel = useCategoryLabelGetter();
    const personalCategory = usePersonalCategory();

    let message: ReactNode | undefined;

    if (updateDetails.isUndo) {
        message = "Changes reverted";
    } else {
        switch (updateDetails.update) {
            case "categoryId":
                if (updateDetails.value === personalCategory.id) {
                    message = `${pluralize(
                        "Transaction",
                        updateDetails.transactionsCount,
                    )} marked as personal`;
                } else {
                    message = `Category changed to ${getCategoryLabel(updateDetails.value)}`;
                }
                break;

            case "taxQuestionAnswers":
                message = "Answer saved";
                break;

            case "memo":
                message = "Note updated";
                break;

            case "entity":
                message = `${pluralize(
                    "Transaction",
                    updateDetails.transactionsCount,
                )} moved to ${updateDetails.value.name}`;
                break;
        }
    }

    return (
        <>
            <div className="transactions-update-toast__content">
                <p className="transactions-update-toast__label">
                    {updateDetails.transactionsCount}{" "}
                    {pluralize("transaction", updateDetails.transactionsCount)}{" "}
                    updated. {message}
                </p>
            </div>

            {previousTransactions && undo && (
                <div className="transactions-update-toast__actions">
                    <Button
                        variant="secondary"
                        onClick={() =>
                            undo(previousTransactions, updateDetails)
                        }
                    >
                        Undo
                    </Button>
                </div>
            )}
        </>
    );
};
