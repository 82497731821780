import { Sortable } from "./base/sortable";
import { Entity } from "./entity";
import { IntegrationAccount } from "./integrationAccount";

export enum FinancialAccountType {
    CHECKING = "checking",
    SAVINGS = "savings",
    CREDIT_CARD = "credit_card",
    LOAN = "loan",
    INVESTMENT = "investment",
    PAYMENT_PROCESSOR = "payment_processor",
    OTHER = "other",
}

export interface FinancialAccount extends Sortable {
    id: number;
    name: string | null;
    isBusiness: boolean | null;
    balance: number | null;
    balanceSyncedAt: Date | null;
    transactionsSyncedAt: Date | null;
    createdAt: Date | null;
    entityId: number | null;
    entity: Entity | null;
    sourceType: FinancialAccountSourceType;
    routingNumber: string | null;
    wireRoutingNumber: string | null;
    accountNumberMask: string | null;
    disabled: boolean;
    financialType: FinancialAccountType;
    integrationAccounts?: IntegrationAccount[];
}

export enum FinancialAccountSourceType {
    STRIPE = "stripe",
    GUSTO = "gusto",
    FILE_IMPORT = "file_import",
    PLAID = "plaid",
    MERCURY = "mercury",
    RAMP = "ramp",
    PAYPAL = "paypal",
}
