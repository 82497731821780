import React from "react";
import classNames from "classnames";
import { Transaction } from "../../../../../common/types/transaction";
import { FormCheckbox } from "../../../../forms/FormCheckbox/FormCheckbox";
import { TransactionDateColumnContent } from "../../../../transactions/ListItem/TransactionDateColumnContent";
import { TransactionDescriptionColumnContent } from "../../../../transactions/ListItem/TransactionDescriptionColumnContent";
import { TransactionAmount } from "../../../../transactions/TransactionAmount";
import { EntityIcon } from "../../../../entity/EntityIcon";
import styles from "./RuleTransactionsPreview.module.scss";

interface Props {
    transaction: Transaction;
    isChecked: boolean;
    isDisabled: boolean;
    toggle: (transaction: Transaction) => void;
}

export const MatchingTransaction: React.FC<Props> = ({
    transaction,
    isDisabled,
    isChecked,
    toggle,
}) => (
    <>
        <div>
            <FormCheckbox
                value={`transaction${transaction.id}`}
                isChecked={isChecked}
                handleChange={() => toggle(transaction)}
                disabled={isDisabled}
                small
            />
        </div>
        <div>
            <TransactionDateColumnContent transaction={transaction} />
        </div>
        <div>
            <TransactionDescriptionColumnContent transaction={transaction} />
        </div>
        <div className={classNames(styles.amountColumn, styles.amountValue)}>
            <TransactionAmount
                transaction={transaction}
                addSign={true}
                color={true}
            />
        </div>
        <div>
            <EntityIcon entity={transaction.entity} size="xs" />
        </div>
    </>
);
