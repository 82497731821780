import { Taxonomy } from "../categories";
import { Category } from "./category";
import { Entity } from "./entity";

export enum RuleConditionType {
    COUNTERPARTY = "counterparty",
    CATEGORY = "category",
    SUGGESTED_CATEGORY = "suggested_category",
    DESCRIPTION_IS = "description_is",
    DESCRIPTION_INCLUDES = "description_includes",
    AMOUNT = "amount",
    FINANCIAL_ACCOUNTS = "financial_accounts",
    ENTITY = "entity",
}

export enum RuleSource {
    SETTINGS = "settings",
    TRANSACTIONS_FEED = "transactions_feed",
    ACTIONS = "actions",
    ENTITY_MIGRATION = "entity_migration",
}

export interface TransactionRule {
    id: string;
    workspaceId: string;
    categoryId: string | null;
    category: Category | null;
    conditions: TransactionRuleCondition[];
    entityId: number | null;
    entity: Entity | null;
    source: RuleSource | null;
}

export interface RuleConditionAmount {
    min?: number;
    max?: number;
}

export interface TransactionRuleConditionDefinition {
    conditionType: RuleConditionType;
    value:
        | string
        | string[]
        | number
        | number[]
        | Taxonomy
        | RuleConditionAmount;
}

export interface TransactionRuleCondition
    extends TransactionRuleConditionDefinition {
    id: string;
}
