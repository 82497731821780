import React from "react";
import { endOfDay, startOfDay } from "date-fns";
import { useBillingManagement } from "../../hooks/useBillingManagement";
import { useBillingStatus } from "../../hooks/useBillingStatus";
import { SubscriptionType } from "../../common/types/billing";
import { currencyFormatterShort } from "../../common/helpers/currency";
import {
    FLAT_RATE_PLANS,
    flatRatePlanNames,
    TRIAL_LENGTH_DAYS,
} from "../../common/flatRateBilling";
import { TransactionsFilters } from "./filters/lib";
import {
    TransactionsTableCta,
    TransactionsTableCtaProps,
} from "./TransactionsTableCta/TransactionsTableCta";
import { TransactionSortValue } from "./useSort";

interface UseActivityFeedCtaParams {
    filters: TransactionsFilters;
    currentSort: TransactionSortValue;
    currentPage: number;
    totalPages: number;
    transactionSelected: boolean;
    showSplitButton?: boolean;
}

export function useActivityFeedCta(
    {
        filters,
        currentSort,
        currentPage,
        totalPages,
        transactionSelected,
        showSplitButton,
    }: UseActivityFeedCtaParams,
    transactionsCtaProps: Omit<
        TransactionsTableCtaProps,
        "rows" | "reversed"
    > = {},
): [React.ReactNode, React.ReactNode] {
    const {
        currentUsageLimit,
        canManageBilling,
        showPastTransactionsCta,
        showUsageLimitReachedCta,

        bookkeepingStartDate,
        bookkeepingEndDate,
    } = useBillingManagement();

    const { currentPlan, isTrialing, isFetched, subscriptionType } =
        useBillingStatus();

    const sortedByDate = isSortedByDate(currentSort);

    const isDescending = currentSort === TransactionSortValue.DATE_DESC;

    if (subscriptionType !== SubscriptionType.FLAT_RATE) {
        return [null, null];
    }

    if (!sortedByDate || !isFetched || !canManageBilling) {
        return [null, null];
    }

    let prependContent: React.ReactNode, appendContent: React.ReactNode;

    const showPrependContent =
        showUsageLimitReachedCta &&
        transactionsMaybeAfterLimitReached(filters.end, bookkeepingEndDate);

    const showAppendContent =
        showPastTransactionsCta &&
        transactionsMaybeBeforeBookkeepingStart(
            filters.start,
            bookkeepingStartDate,
        );

    prependContent = showPrependContent ? (
        <TransactionsTableCta
            rows={isDescending ? 6 : 4}
            header={getNewDataContentHeader({
                isTrialing,
                usageLimit: currentUsageLimit,
                currentPlan,
            })}
            transactionSelected={transactionSelected}
            showAccountNames
            bulkActionsEnabled
            reversed={!isDescending}
            showSplitButton={showSplitButton}
            {...transactionsCtaProps}
        />
    ) : null;

    appendContent = showAppendContent ? (
        <TransactionsTableCta
            rows={isDescending ? 4 : 6}
            header={getHistoricalDataContentHeader({
                isTrialing,
                usageLimit: currentUsageLimit,
                currentPlan,
            })}
            transactionSelected={transactionSelected}
            showAccountNames
            bulkActionsEnabled
            reversed={isDescending}
            showSplitButton={showSplitButton}
            {...transactionsCtaProps}
        />
    ) : null;

    if (!isDescending) {
        [prependContent, appendContent] = [appendContent, prependContent];
    }

    return [
        currentPage === 1 ? prependContent : null,
        currentPage === totalPages ? appendContent : null,
    ];
}

function isSortedByDate(currentSort: TransactionSortValue): boolean {
    return (
        currentSort === TransactionSortValue.DATE_ASC ||
        currentSort === TransactionSortValue.DATE_DESC
    );
}

function transactionsMaybeAfterLimitReached(
    filtersEndDate?: Date,
    limitReachedDate?: Date | null,
) {
    return Boolean(
        !filtersEndDate ||
            (limitReachedDate &&
                endOfDay(limitReachedDate) < endOfDay(filtersEndDate)),
    );
}

function transactionsMaybeBeforeBookkeepingStart(
    filtersStartDate?: Date,
    bookkeepingStartDate?: Date | null,
) {
    return Boolean(
        !filtersStartDate ||
            (bookkeepingStartDate &&
                startOfDay(bookkeepingStartDate) >
                    startOfDay(filtersStartDate)),
    );
}

interface GetCTAHeaderParams {
    isTrialing: boolean;
    usageLimit: number;
    currentPlan: FLAT_RATE_PLANS;
}

function getHistoricalDataContentHeader({
    isTrialing,
    usageLimit,
    currentPlan,
}: GetCTAHeaderParams) {
    if (isTrialing) {
        return (
            <>
                <h3>
                    Your <em>{flatRatePlanNames[currentPlan]}</em> Trial
                    includes up to 4 months of data
                </h3>

                <p>
                    Start your subscription now to get full access to your
                    historicals
                </p>
            </>
        );
    }

    return (
        <>
            <h3>
                You’ve reached the {currencyFormatterShort.format(usageLimit)}{" "}
                expense limit
            </h3>
            <p>Upgrade now for full access to historical data</p>
        </>
    );
}

function getNewDataContentHeader({ usageLimit }: GetCTAHeaderParams) {
    return (
        <>
            <h3>
                You’ve reached the {currencyFormatterShort.format(usageLimit)}{" "}
                expense limit
            </h3>

            <p>
                Start your {TRIAL_LENGTH_DAYS}-day trial now to access recent
                data
            </p>
        </>
    );
}
