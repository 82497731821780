import { useQuery } from "@tanstack/react-query";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import Scrollbars from "react-custom-scrollbars-2";
import {
    CounterpartiesReturnFormat,
    CounterpartySort,
} from "../../../../common/constants/counterparty";
import { RegisteredCounterpartyIncludeScope } from "../../../../common/helpers/counterparties";
import {
    counterpartiesQueryKeys,
    getCounterparties,
} from "../../../../api/counterparty.api";
import { useWorkspaceContext } from "../../../../state/workspaceContext";
import { FormCheckbox } from "../../../forms/FormCheckbox/FormCheckbox";
import { FilterSearch } from "../../../general/FilterSearch/FilterSearch";
import { Loader } from "../../../general/Loader";
import { FilterActionsFooter } from "../../Filter/FilterActionsFooter/FilterActionsFooter";
import styles from "./CounterpartyFilter.module.scss";

interface CounterpartiesFilters {
    counterpartiesIds?: string[];
}

interface Props {
    filters: CounterpartiesFilters;
    onChange: (value: CounterpartiesFilters) => void;
}

export const CounterpartyFilter: React.FC<Props> = ({ filters, onChange }) => {
    const { activeWorkspace } = useWorkspaceContext();
    const counterparties = useQuery({
        queryKey: counterpartiesQueryKeys.query(activeWorkspace?.id ?? ""),
        queryFn: () => {
            if (!activeWorkspace) {
                return { data: [] };
            }

            return getCounterparties(
                {
                    workspaceId: activeWorkspace.id,
                    sort: CounterpartySort.NAME_ASC,
                    includeRegistered: RegisteredCounterpartyIncludeScope.USED,
                },
                CounterpartiesReturnFormat.JSON,
            );
        },
    });

    const [search, setSearch] = useState("");
    const searchRef = useRef<HTMLInputElement>();

    useEffect(() => {
        searchRef.current?.focus();

        return () => {
            setSearch("");
        };
    }, []);

    const handleChange = useCallback(
        (counterpartyId: string) => {
            if (filters.counterpartiesIds?.includes(counterpartyId)) {
                onChange({
                    counterpartiesIds: filters.counterpartiesIds.filter(
                        (id) => id !== counterpartyId,
                    ),
                });
            } else {
                onChange({
                    counterpartiesIds: [
                        ...(filters.counterpartiesIds ?? []),
                        counterpartyId,
                    ],
                });
            }
        },
        [filters.counterpartiesIds, onChange],
    );

    const displayedCounterparties = useMemo(() => {
        if (!counterparties.data) {
            return undefined;
        }

        if (!search) {
            return counterparties.data.data;
        }

        return counterparties.data.data.filter((item) =>
            item.counterparty.name.toLowerCase().includes(search.toLowerCase()),
        );
    }, [counterparties.data, search]);

    if (!counterparties.data?.data.length) {
        return (
            <p className="text-center mt-5 text-prominent">
                No Vendors/Customers created yet
            </p>
        );
    }

    return (
        <div className={styles.filter}>
            <FilterSearch
                value={search}
                onChange={setSearch}
                inputRef={searchRef}
            />
            <div className={styles.content}>
                {counterparties.isLoading && <Loader />}
                {displayedCounterparties && (
                    <Scrollbars style={{ width: "100%", height: "100%" }}>
                        {displayedCounterparties.length > 0 ? (
                            <>
                                {displayedCounterparties.map((item) => (
                                    <FormCheckbox
                                        value={item.counterparty.id}
                                        key={item.counterparty.id}
                                        isChecked={
                                            filters.counterpartiesIds?.includes(
                                                item.counterparty.id,
                                            ) ?? false
                                        }
                                        handleChange={handleChange}
                                        className={styles.checkbox}
                                        small
                                        label={item.counterparty.name}
                                    />
                                ))}
                            </>
                        ) : (
                            <p className={styles.empty}>
                                No matching Vendor/Customer
                            </p>
                        )}
                    </Scrollbars>
                )}
            </div>

            {!!filters.counterpartiesIds?.length && (
                <FilterActionsFooter
                    deselectFunction={() =>
                        onChange({ counterpartiesIds: undefined })
                    }
                    selectedCount={filters.counterpartiesIds.length}
                />
            )}
        </div>
    );
};
