import { useQuery } from "@tanstack/react-query";
import { useWorkspaceContext } from "../state/workspaceContext";
import { getRulesForWorkspace } from "../lib/transactionRules";
import { TRANSACTION_RULES_QUERY_KEY } from "../api/transactionRule.api";

interface UseTransactionRulesOptions {
    refetchOnMount?: boolean;
}

export function useTransactionRules(options?: UseTransactionRulesOptions) {
    const { activeWorkspace } = useWorkspaceContext();
    const rules = useQuery({
        queryKey: [TRANSACTION_RULES_QUERY_KEY, activeWorkspace?.id],
        queryFn: () =>
            activeWorkspace
                ? getRulesForWorkspace(activeWorkspace.id)
                : { rules: [], counterparties: [], transactionCounts: {} },
        ...options,
    });

    return rules.data;
}
