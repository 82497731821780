import React, { FC, useCallback } from "react";
import {
    IntegrationsIcon,
    NotificationsIcon,
    ProfileIcon,
    ReceiptIcon,
    SecurityIcon,
} from "../../icons";
import { Command } from "../../vendor/cmdk/cmdk";
import { useSettings } from "../settings/SettingsProvider";
import { CommandItem } from "./CommandItem";
import { useCommands } from "./useCommands";

export const AccountSettingsCommandList: FC = () => {
    const { open: openSettings } = useSettings();
    const { close: closeCommands } = useCommands();

    const handleSelected = useCallback(
        (path: string[]) => {
            closeCommands();
            setTimeout(() => {
                openSettings(path);
            }, 100);
        },
        [openSettings, closeCommands],
    );

    return (
        <Command.Group heading="Account settings">
            <CommandItem onSelect={() => handleSelected(["general"])}>
                <ProfileIcon /> Go to Profile
            </CommandItem>
            <CommandItem onSelect={() => handleSelected(["receipts"])}>
                <ReceiptIcon /> Go to Receipts
            </CommandItem>
            <CommandItem onSelect={() => handleSelected(["integrations"])}>
                <IntegrationsIcon />
                Go to Integrations
            </CommandItem>
            <CommandItem onSelect={() => handleSelected(["general"])}>
                <NotificationsIcon />
                Go to Notifications
            </CommandItem>
            <CommandItem onSelect={() => handleSelected(["security"])}>
                <SecurityIcon />
                Go to Security
            </CommandItem>
        </Command.Group>
    );
};
