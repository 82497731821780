import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { BaseFilterProps } from "../../lib";
import { useAccounts } from "../../../../../hooks/useAccounts";
import { FilterActionsFooter } from "../../../../general/Filter/FilterActionsFooter/FilterActionsFooter";
import { FilterSearch } from "../../../../general/FilterSearch/FilterSearch";
import { FormCheckbox } from "../../../../forms/FormCheckbox/FormCheckbox";
import styles from "./AccountCodesFilter.module.scss";

interface Props extends BaseFilterProps {
    hardcodedEntityId?: number;
}

export const AccountCodesFilter: React.FC<Props> = ({
    filters,
    onChange,
    hardcodedEntityId,
}) => {
    const [search, setSearch] = useState("");
    const searchRef = useRef<HTMLInputElement>();

    const { accounts } = useAccounts({ entityId: hardcodedEntityId });

    useEffect(() => {
        searchRef.current?.focus();

        return () => {
            setSearch("");
        };
    }, []);

    const handleChange = useCallback(
        (accountCode: number) => {
            if (filters.accountCodes?.includes(accountCode)) {
                onChange({
                    accountCodes: filters.accountCodes.filter(
                        (id) => id !== accountCode,
                    ),
                });
            } else {
                onChange({
                    accountCodes: [
                        ...(filters.accountCodes ?? []),
                        accountCode,
                    ],
                });
            }
        },
        [filters.accountCodes, onChange],
    );

    const displayedAcountCodes = useMemo(() => {
        if (!accounts) {
            return [];
        }

        if (!search) {
            return accounts;
        }

        return accounts.filter(
            (item) =>
                item.name.toLowerCase().includes(search.toLowerCase()) ||
                item.code.toString().startsWith(search.toLowerCase()),
        );
    }, [accounts, search]);

    const reverseSelection = useCallback(() => {
        if (filters.accountCodes) {
            onChange({
                accountCodes: accounts
                    .filter(
                        (item) => !filters.accountCodes?.includes(item.code),
                    )
                    .map(({ code }) => code),
            });
        }
    }, [accounts, onChange, filters.accountCodes]);

    return (
        <div className={styles.filter}>
            <FilterSearch
                value={search}
                onChange={setSearch}
                inputRef={searchRef}
            />
            <div className={styles.content}>
                <Scrollbars style={{ width: "100%", height: "100%" }}>
                    {displayedAcountCodes.length > 0 ? (
                        <>
                            {displayedAcountCodes.map((item) => (
                                <FormCheckbox
                                    value={item.code}
                                    key={item.code}
                                    isChecked={
                                        filters.accountCodes?.includes(
                                            item.code,
                                        ) ?? false
                                    }
                                    handleChange={handleChange}
                                    className={styles.checkbox}
                                    small
                                    label={item.name}
                                />
                            ))}
                        </>
                    ) : (
                        <p className={styles.empty}>No account codes found</p>
                    )}
                </Scrollbars>
            </div>

            {!!filters.accountCodes?.length && (
                <FilterActionsFooter
                    deselectFunction={() =>
                        onChange({ accountCodes: undefined })
                    }
                    selectedCount={filters.accountCodes.length}
                    reverseFunction={reverseSelection}
                />
            )}
        </div>
    );
};
