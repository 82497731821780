import React, { useState } from "react";
import { ListGroup } from "react-bootstrap";
import { Button } from "../../general/Button/Button";
import { BinIcon } from "../../../icons";
import { AdditionalEmail } from "../../../common/types/additionalEmail";
import { ConfirmationModal } from "../../general/ConfirmationModal";

export interface ReceiptsEmailsRowProps {
    value: AdditionalEmail;
    onRemove(email: string): Promise<void>;
}

export const ReceiptsEmailsRow: React.FC<ReceiptsEmailsRowProps> = ({
    value,
    onRemove,
}) => {
    const [showRemovalConfirmation, setShowRemovalConfirmation] =
        useState<boolean>(false);

    return (
        <>
            <ListGroup.Item className="d-flex align-items-center py-4">
                <div>{value.email}</div>
                <div className="ml-auto">
                    <Button
                        variant="tertiary"
                        size="sm"
                        icon
                        className="ml-2"
                        onClick={() => setShowRemovalConfirmation(true)}
                    >
                        <BinIcon />
                    </Button>
                </div>
            </ListGroup.Item>

            <ConfirmationModal
                show={showRemovalConfirmation}
                onHide={() => setShowRemovalConfirmation(false)}
                question={
                    <>
                        Receipts from <strong>{value.email}</strong> will no
                        longer be accepted. Are you sure?
                    </>
                }
                onConfirm={() => onRemove(value.email)}
                onReject={() => setShowRemovalConfirmation(false)}
            />
        </>
    );
};
