import { User } from "../common/types/user";
import { FinancialConnection } from "../common/types/financialConnection";
import { useUser } from "./useUser";

export function isConnectionOwner(connection: FinancialConnection, user: User) {
    return connection.user?.id === user.id;
}

export function useConnectionOwnership(connection: FinancialConnection) {
    const user = useUser();

    return isConnectionOwner(connection, user);
}
