import { TransactionSortValue } from "../components/transactions/useSort";
import {
    useWorkspaceUserCache,
    WORKSPACE_USER_CACHE_KEYS,
} from "./useWorkspaceUserCache";

export function useTransactionsPageSortingCache(): [
    TransactionSortValue | undefined,
    (value: TransactionSortValue | undefined) => void,
] {
    const [value, setter] = useWorkspaceUserCache<TransactionSortValue>(
        WORKSPACE_USER_CACHE_KEYS.TRANSACTIONS_PAGE_SORTING_CACHE_KEY,
    );

    return [
        value,
        (sorting: TransactionSortValue | undefined) => {
            setter(sorting);
        },
    ];
}
