import classNames from "classnames";
import { Form, Formik, FormikConfig } from "formik";
import { FormikProps } from "formik/dist/types";
import React, { useCallback, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { object, string } from "yup";
import { ActionStatus } from "../../../common/types/actions";
import { submitHelper } from "../../../helpers/form";
import { CheckIcon, PhoneIcon } from "../../../icons";
import { addPhoneNumber } from "../../../lib/user";
import { FormRow } from "../../forms/FormRow";
import { ActionCard } from "../ActionCard";
import { ActionCardCompleted } from "../ActionCardCompleted";
import { ActionCardSection } from "../ActionCardSection";
import sectionStyles from "../ActionCardSection.module.scss";
import { ActionCloseButton } from "../ActionCloseButton";
import { ActionConfirmButton } from "../ActionConfirmButton";
import { CampaignCardProps } from "../ActionItem";
import { Button } from "../../general/Button/Button";
import styles from "./SetupReceiptMatchingCard.module.scss";

interface FormSchema {
    phoneNumber: string;
}

export const SetupReceiptMatchingCard: React.FC<CampaignCardProps> = ({
    action,
    onConfirm,
    onSkip,
    isLoading,
    ...rest
}) => {
    const { isCompleted } = rest;
    const [loading, setLoading] = useState(false);
    const formRef = useRef<FormikProps<FormSchema>>(null);

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            phoneNumber: "",
        },
        validationSchema: object().shape({
            phoneNumber: string()
                .required("Phone number is required")
                .matches(/\d{10}/, "Provide valid US phone number"),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async ({ phoneNumber }) => {
                await addPhoneNumber({ phoneNumber });
                onConfirm?.(action);
            },
        }),
    };

    const skipAction = useCallback(() => {
        onSkip?.(action);
    }, [action, onSkip]);

    const firstSection = (inOpened: boolean, closeCard: () => void) =>
        isCompleted ? (
            <ActionCardCompleted header="Receipt matching activated">
                <ActionCloseButton onClick={closeCard} />
            </ActionCardCompleted>
        ) : (
            <ActionCardSection
                inOpened={inOpened}
                header="Automated receipt matching"
                icon={<PhoneIcon />}
                isTitle
                ctas={
                    <>
                        {action.status !== ActionStatus.SKIPPED && (
                            <Button variant="secondary" onClick={skipAction}>
                                Skip
                            </Button>
                        )}
                        <ActionConfirmButton
                            loading={!!isLoading || loading}
                            onClick={() => formRef.current?.submitForm()}
                        >
                            Turn on
                        </ActionConfirmButton>
                    </>
                }
            ></ActionCardSection>
        );

    const otherSections = (inOpened: boolean) => (
        <ActionCardSection
            header="Add your phone number to turn on receipt matching"
            inOpened={inOpened}
        >
            <Formik {...form} innerRef={formRef}>
                <Form className={styles.form}>
                    <FormRow fieldName="phoneNumber" label="Phone number">
                        {({ setValue }, { value }) => (
                            <NumberFormat
                                value={value}
                                onValueChange={(v) => setValue(v.value)}
                                format="(###) ###-####"
                                placeholder="(000) 000-0000"
                                className="form-control form-control-xs"
                            />
                        )}
                    </FormRow>
                </Form>
            </Formik>

            <img
                src="/images/receipts/preview.png"
                alt="Receipt matching preview"
                className={classNames(styles.preview, "mb-4")}
            />

            <header className={classNames(sectionStyles.header, "mb-3")}>
                Adding your phone also grants you:
            </header>

            <ul className="list-unstyled">
                <li className="mb-2">
                    <CheckIcon className="icon-color-blue-600 icon-size-sm mr-2" />
                    Reduces back-and-forth email
                </li>
                <li className="mb-2">
                    <CheckIcon className="icon-color-blue-600 icon-size-sm mr-2" />
                    Speeds up tax reporting preparation
                </li>
            </ul>
        </ActionCardSection>
    );

    return (
        <ActionCard
            {...rest}
            firstSection={firstSection}
            otherSections={otherSections}
        />
    );
};
