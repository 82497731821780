import React from "react";
import { SidebarInfo } from "../../components/SidebarInfo";
import animation from "../../layout/OnboardingLayout.module.scss";

export const PayrollSidebar: React.FC = () => (
    <aside>
        <SidebarInfo className={animation.fadeIn2}>
            <header>Don’t have a payroll provider?</header>

            <p>
                Solutions like Gusto can get you set up to pay your employees.
            </p>

            <p>Reach out to support@kick.co for more information.</p>
        </SidebarInfo>

        <SidebarInfo className={animation.fadeIn1}>
            <header>
                Why do you want to know what payroll provider I use?
            </header>

            <p>
                Sharing your payroll provider helps Kick maintain accurate
                financials by categorizing payroll-related expenses (payroll,
                taxes, fees).
            </p>
        </SidebarInfo>
    </aside>
);
