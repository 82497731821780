import classNames from "classnames";
import { UploadIcon } from "../../../icons";
import { useReceiptUpload } from "../../transactions/ReceiptUpload/useReceiptUpload";
import { ActionCardDetail } from "../ActionCardDetail";
import style from "../ActionCardDetail.module.scss";
import { Loader } from "../../general/Loader";
import { ClickableContainer } from "../../general/ClickableContainer";
import { Button } from "../../general/Button/Button";

interface Props {
    fileName?: string;
    uploading?: boolean;
    required?: boolean;
}

export const ReceiptDetail: React.FC<Props> = ({
    fileName,
    uploading,
    required,
}) => {
    const { open, inputProps } = useReceiptUpload();

    return (
        <ActionCardDetail label="Receipt" required={required && !fileName}>
            <ClickableContainer
                onClick={open}
                className={classNames(
                    "form-control form-control-xs cursor-pointer",
                    style.detailInput,
                )}
            >
                {uploading ? (
                    <Loader inline />
                ) : (
                    <>
                        {fileName ? (
                            <span className={style.detailInputText}>
                                {fileName}
                            </span>
                        ) : (
                            <div className={style.detailInputPlaceholder}>
                                Add Receipt {required && "(required)"}
                            </div>
                        )}
                        <Button variant="tertiary" size="sm" icon>
                            <UploadIcon />
                        </Button>
                        <input {...inputProps} />
                    </>
                )}
            </ClickableContainer>
        </ActionCardDetail>
    );
};
