import React from "react";
import { FinancialDocument } from "../../../../common/types/financialDocument";
import styles from "./FinancialDocumentMatches.module.scss";
import { FinancialTransactionMatch } from "./FinancialTransactionMatch";

interface FinancialDocumentMatchesListProps {
    financialDocument: FinancialDocument;
}

export const FinancialDocumentMatchesList: React.FC<
    FinancialDocumentMatchesListProps
> = ({ financialDocument }) => (
    <section
        className={styles.list}
        data-testid="financial-document-matches-list"
    >
        {financialDocument.transactionMatches.map((match) => (
            <FinancialTransactionMatch
                key={match.id}
                financialDocument={financialDocument}
                transactionMatch={match}
            />
        ))}
    </section>
);
