import React, { FC, useMemo } from "react";
import { sortBy } from "lodash";
import { Loader } from "../../general/Loader";
import {
    GridTable,
    GridTableHeader,
} from "../../general/Tables/GridTable/GridTable";
import { UserWorkspaceRole } from "../../../common/userWorkspaceRoles";
import {
    useCurrentWorkspaceMembers,
    usePendingWorkspaceInvites,
} from "../../../api/workspace-member.api";
import { WorkspaceMember } from "../../../common/types/workspace";
import { MemberRow } from "./MemberRow";
import styles from "./WorkspaceMembers.module.scss";
import { InviteRow } from "./InviteRow";

const roleOrder: UserWorkspaceRole[] = [
    UserWorkspaceRole.PRIMARY_ADMIN,
    UserWorkspaceRole.ADMIN,
    UserWorkspaceRole.ACCOUNTANT,
    UserWorkspaceRole.MEMBER,
];

interface Props {
    onConfigureMemberPermissions(member: WorkspaceMember): void;
}

export const WorkspaceMemberList: FC<Props> = ({
    onConfigureMemberPermissions,
}) => {
    const { data: members, isFetched: membersFetched } =
        useCurrentWorkspaceMembers();

    const { data: pendingInvites, isFetched: pendingInvitesFetched } =
        usePendingWorkspaceInvites();

    const sortedMembers = useMemo(() => {
        if (!members?.length) {
            return [];
        }

        return sortBy(
            members,
            (member) => roleOrder.indexOf(member.role),
            (member) => member.user.id,
        );
    }, [members]);

    if (!membersFetched || !pendingInvitesFetched) {
        return <Loader />;
    }
    return (
        <GridTable className={styles.table}>
            <GridTableHeader>
                <div>User</div>
                <div>Entities</div>
                <div>Role</div>
                <div>Last active</div>
                <div />
            </GridTableHeader>

            {sortedMembers.map((member) => (
                <MemberRow
                    key={member.user.id}
                    member={member}
                    onConfigurePermissions={() =>
                        onConfigureMemberPermissions(member)
                    }
                />
            ))}

            {pendingInvites?.map((invite) => (
                <InviteRow key={invite.email} invite={invite} />
            ))}
        </GridTable>
    );
};
