import React from "react";
import { Transaction } from "../../../../common/types/transaction";
import { EagerLoaded } from "../../../../common/types/base/orm";
import { TransactionMatch } from "../../../../common/types/transactionMatch";
import styles from "./TransactionMatches.module.scss";
import { TransactionMatchFinancialDocument } from "./TransactionMatchFinancialDocument";
import { TransactionMatchesAddReceipt } from "./TransactionMatchesAddReceipt";
import { TransactionMatchInvoice } from "./TransactionMatchInvoice";

interface Props {
    financialTransaction: EagerLoaded<Transaction, "transactionMatches">;
}

export const TransactionMatches: React.FC<Props> = ({
    financialTransaction,
}) => {
    const documentMatches = financialTransaction.transactionMatches.filter(
        (match) => match.financialDocument,
    ) as Array<EagerLoaded<TransactionMatch, "financialDocument">>;
    const invoiceMatches = financialTransaction.transactionMatches.filter(
        (match) => match.invoice,
    ) as Array<EagerLoaded<TransactionMatch, "invoice">>;
    return (
        <div className={styles.matchesContainer}>
            <div className={styles.matches}>
                <TransactionMatchesAddReceipt />

                {documentMatches.length > 0 && (
                    <div className={styles.list}>
                        {documentMatches.map((match) => (
                            <TransactionMatchFinancialDocument
                                key={match.id}
                                financialTransaction={financialTransaction}
                                transactionMatch={match}
                            />
                        ))}
                    </div>
                )}
            </div>

            {invoiceMatches.length > 0 && (
                <div className={styles.matches}>
                    {invoiceMatches.map((match) => (
                        <TransactionMatchInvoice
                            key={match.id}
                            financialTransaction={financialTransaction}
                            transactionMatch={match}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
