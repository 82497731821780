import React, { useEffect, useMemo } from "react";
import { useField } from "formik";
import { isArray, keyBy } from "lodash";
import { TransactionRuleConditionFormSchema } from "../constants";
import { AccountsFilter } from "../../../../general/AccountsFilter/AccountsFilter";
import { AccountsFilterValueDisplay } from "../../../../general/AccountsFilter/lib";
import { useFinancialAccounts } from "../../../../../hooks/useFinancialAccounts";
import { useEntitiesFromFormCondition } from "../useEntitiesFromFormCondition";

interface Props {
    index: number;
}

export const AccountConditionForm: React.FC<Props> = ({ index }) => {
    const [field, , helpers] = useField<
        TransactionRuleConditionFormSchema["accountValue"]
    >(`conditions.${index}.accountValue`);

    const displayedEntities = useEntitiesFromFormCondition();

    const accounts = useFinancialAccounts();
    const accountsById = useMemo(
        () => keyBy(accounts, (a) => a.id),
        [accounts],
    );
    useEffect(() => {
        // Update accounts selection when entity filter changes
        if (!isArray(field.value)) {
            return;
        }
        const displayedEntitiesIds = displayedEntities.map((e) => e.id);
        const newValue = field.value.filter(
            (v) =>
                accountsById[v]?.entityId &&
                displayedEntitiesIds.includes(accountsById[v].entityId),
        );

        if (newValue.length !== field.value.length) {
            helpers.setValue(newValue);
        }
    }, [displayedEntities, field.value, accountsById, helpers]);

    return (
        <AccountsFilter
            value={field.value ?? []}
            valueDisplay={AccountsFilterValueDisplay.ACCOUNT}
            onChange={(v) => helpers.setValue(v)}
            entities={displayedEntities}
        />
    );
};
