import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Switch } from "../../general/Switch/Switch";
import { noop } from "../../../helpers/general";
import { useInteraction } from "../../../hooks/useInteraction";
import { FilterProps } from "./lib";
import styles from "./TransactionActionsToggleFilter.module.scss";

export const TransactionActionsToggleFilter: React.FC<FilterProps> = ({
    filters,
    onChange,
}) => {
    const toggle = useInteraction(() => {
        onChange({
            requiredAction: !filters.requiredAction,
        });
    });

    const isActive = !!filters.requiredAction;

    let tooltipText: React.ReactNode;

    if (isActive) {
        tooltipText = <>Turn Off to view all your transactions.</>;
    } else {
        tooltipText = <>Turn on to only view transactions with actions.</>;
    }

    return (
        <OverlayTrigger
            placement="bottom-end"
            overlay={
                <Tooltip id="required-actions" className="tooltip--large">
                    {tooltipText}
                </Tooltip>
            }
        >
            <div className={styles.filter} {...toggle}>
                <span className={styles.toggleButton}>
                    <Switch
                        active={isActive}
                        onChanged={noop}
                        label="Actions"
                        size="sm"
                    />
                </span>
            </div>
        </OverlayTrigger>
    );
};
