import React, { useState } from "react";
import { useEntities } from "../../hooks/useEntities";
import { Entity } from "../../common/types/entity";
import { Button } from "../general/Button/Button";
import { StandardModalBody } from "../general/Modal/Modal";
import { EntitySelect } from "./EntitySelect";

interface Props {
    prompt?: string;
    onCancel: () => void;
    onSelected: (entity: Entity) => void;
    selectPlaceholder?: string;
}

export const SelectBusinessEntityModal: React.FC<Props> = ({
    prompt = "Select business entity",
    onCancel,
    onSelected,
    selectPlaceholder,
}) => {
    const businessEntities = useEntities({ onlyBusiness: true });
    const [selected, setSelected] = useState<Entity>();

    return (
        <StandardModalBody>
            <p className="text-prominent">{prompt}</p>
            <EntitySelect
                entities={businessEntities}
                selectedEntity={selected}
                onChange={setSelected}
                dropdownKey="selectBusinessEntityModal"
                placeholder={selectPlaceholder}
            />

            <footer className="mt-5 d-flex justify-content-between">
                <Button variant="secondary" onClick={onCancel}>
                    Cancel
                </Button>
                <Button
                    variant="default"
                    disabled={!selected}
                    onClick={() => selected && onSelected(selected)}
                >
                    Confirm
                </Button>
            </footer>
        </StandardModalBody>
    );
};
