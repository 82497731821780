import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { FilterSearch } from "../../../../general/FilterSearch/FilterSearch";
import { Loader } from "../../../../general/Loader";
import { useTransactionRules } from "../../../../../hooks/useTransactionRules";
import { BaseFilterProps } from "../../lib";
import { FormCheckbox } from "../../../../forms/FormCheckbox/FormCheckbox";
import { TransactionRuleConditions } from "../../../../transactionRules/TransactionRuleConditions";
import { TransactionRuleActions } from "../../../../transactionRules/TransactionRuleActions";
import { useCategoryLabelGetter } from "../../../../../hooks/useCategoryLabelGetter";
import { useFinancialAccounts } from "../../../../../hooks/useFinancialAccounts";
import { useEntities } from "../../../../../hooks/useEntities";
import { FilterActionsFooter } from "../../../../general/Filter/FilterActionsFooter/FilterActionsFooter";
import styles from "./TransactionRulesFilter.module.scss";
import { getSearchableLabel } from "./helpers";

export const TransactionRulesFilter: React.FC<BaseFilterProps> = ({
    filters,
    onChange,
}) => {
    const rulesData = useTransactionRules({
        refetchOnMount: false,
    });
    const [search, setSearch] = useState("");
    const searchRef = useRef<HTMLInputElement>();
    const labelGetter = useCategoryLabelGetter();
    const financialAccounts = useFinancialAccounts();
    const entities = useEntities();

    useEffect(() => {
        searchRef.current?.focus();

        return () => {
            setSearch("");
        };
    }, []);

    const handleChange = useCallback(
        (ruleId: string) => {
            if (filters.transactionRulesIds?.includes(ruleId)) {
                onChange({
                    transactionRulesIds: filters.transactionRulesIds.filter(
                        (id) => id !== ruleId,
                    ),
                });
            } else {
                onChange({
                    transactionRulesIds: [
                        ...(filters.transactionRulesIds ?? []),
                        ruleId,
                    ],
                });
            }
        },
        [filters.transactionRulesIds, onChange],
    );

    const rulesWithSearchableLabels = useMemo(
        () =>
            rulesData?.rules.map((rule) => ({
                rule,
                label: getSearchableLabel(rule, {
                    labelGetter,
                    financialAccounts,
                    entities,
                    counterparties: rulesData.counterparties,
                }).toLowerCase(),
            })),
        [
            rulesData?.rules,
            labelGetter,
            financialAccounts,
            entities,
            rulesData?.counterparties,
        ],
    );

    const displayedRules = useMemo(() => {
        if (!search) {
            return rulesWithSearchableLabels?.map(({ rule }) => rule);
        }

        return rulesWithSearchableLabels
            ?.filter(({ label }) => label.includes(search.toLowerCase()))
            .map(({ rule }) => rule);
    }, [search, rulesWithSearchableLabels]);

    if (!rulesData) {
        return <Loader />;
    }

    if (!rulesData.rules.length) {
        return (
            <p className="text-center mt-5 text-prominent">
                No rules created yet
            </p>
        );
    }

    return (
        <div className={styles.filter}>
            <FilterSearch
                value={search}
                onChange={setSearch}
                inputRef={searchRef}
            />
            <div className={styles.rules}>
                <Scrollbars style={{ width: "100%", height: "100%" }}>
                    {displayedRules?.map((rule) => (
                        <FormCheckbox
                            value={rule.id}
                            key={rule.id}
                            isChecked={
                                filters.transactionRulesIds?.includes(
                                    rule.id,
                                ) ?? false
                            }
                            handleChange={handleChange}
                            className={styles.checkbox}
                            label={
                                <div className={styles.rule}>
                                    <div>
                                        <TransactionRuleConditions
                                            rule={rule}
                                            counterparties={
                                                rulesData.counterparties
                                            }
                                        />
                                    </div>
                                    <div>
                                        <TransactionRuleActions rule={rule} />
                                    </div>
                                </div>
                            }
                        />
                    ))}
                    {displayedRules?.length === 0 && (
                        <p className={styles.empty}>No matching rules</p>
                    )}
                </Scrollbars>
            </div>

            {!!filters.transactionRulesIds?.length && (
                <FilterActionsFooter
                    deselectFunction={() =>
                        onChange({ transactionRulesIds: undefined })
                    }
                    selectedCount={filters.transactionRulesIds.length}
                />
            )}
        </div>
    );
};
