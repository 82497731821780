import * as Sentry from "@sentry/react";
import { queryClient } from "../queryClient";
import { ACTIVE_USER_ID_KEY } from "../constants";
import { userStatusQueryKey } from "../hooks/useUserStatus";

export function clearUser() {
    localStorage.removeItem(ACTIVE_USER_ID_KEY);
    queryClient.setQueryData([userStatusQueryKey], null);
    Sentry.getCurrentScope().setUser(null);
}
