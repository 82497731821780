import React from "react";
import "./NavigationMenu.scss";
import classNames from "classnames";

interface Props {
    className?: string;
    small?: boolean;
    children: React.ReactNode;
}

export const NavigationMenu: React.FC<Props> = ({
    className,
    small,
    children,
}) => (
    <nav
        className={classNames(
            "navigation-menu",
            {
                "navigation-menu--small": small,
            },
            className,
        )}
    >
        {children}
    </nav>
);
