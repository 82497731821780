import React from "react";
import classNames from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./FormRow.scss";
import { InfoIcon } from "../../icons";
import { getInputClasses, InputSize } from "../../helpers/form";

const alwaysFilledTypes = ["date", "file"];

export interface FieldProps {
    label?: string;
    placeholder?: string;
    dataTestId?: string;
    fieldType?: string;
    isValidating?: boolean;
    description?: string;
    icon?: React.ReactNode;
    iconPosition?: "left" | "right";
    showErrorMessage?: boolean;
    disabled?: boolean;
    size?: InputSize;
    readOnly?: boolean;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    isInline?: boolean;
    autoResize?: boolean;
    autoResizeShrinkOnBlur?: boolean;
}

export interface FormRowRawProps extends FieldProps {
    value: any;
    field?: React.ReactNode;
    onChange?: (value: any) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    error?: React.ReactNode | string;
    descriptionId?: string;
    className?: string;
    required?: boolean;
    insideLabel?: boolean;
    inputId?: string;
    autoFocus?: boolean;
    inputRef?: React.RefObject<HTMLInputElement>;
}

export const FormRowRaw: React.FC<FormRowRawProps> = ({
    value,
    label,
    field,
    fieldType = "text",
    onChange,
    isValidating,
    error,
    description,
    descriptionId,
    icon,
    iconPosition = "left",
    placeholder,
    dataTestId = "form-row",
    showErrorMessage,
    className,
    disabled,
    required,
    insideLabel,
    size,
    onBlur,
    onFocus,
    readOnly,
    onKeyPress,
    isInline,
    inputId,
    autoFocus,
    inputRef,
}) => {
    const inputClasses = getInputClasses(size);

    return (
        <div
            data-testid={dataTestId + "-div"}
            className={classNames("form-group", className, {
                "form-group--filled":
                    Boolean(value) ||
                    (fieldType && alwaysFilledTypes.includes(fieldType)) ||
                    value === 0,
                "form-group--error": Boolean(error),
                "form-group--validating": isValidating,
                "form-group--with-label": Boolean(label),
                "form-group--inside-label": Boolean(label && insideLabel),
                "form-group--icon": Boolean(icon),
                "form-group--icon--left": icon && iconPosition === "left",
                "form-group--icon--right": icon && iconPosition === "right",
                "form-group--readonly": readOnly,
                "form-group--inline": isInline,
            })}
        >
            {label && !insideLabel ? (
                <label
                    className="form-group__label"
                    data-testid={dataTestId + "-label"}
                >
                    {label}{" "}
                    {required && (
                        <span className="form-group__label__required">*</span>
                    )}
                    {description && descriptionId ? (
                        <div className="form-group__description">
                            <OverlayTrigger
                                placement="auto"
                                overlay={
                                    <Tooltip id={descriptionId}>
                                        {description}
                                    </Tooltip>
                                }
                            >
                                <InfoIcon className="icon-color-gray-700" />
                            </OverlayTrigger>
                        </div>
                    ) : null}
                </label>
            ) : null}
            {field ?? (
                <input
                    size={0}
                    type={fieldType}
                    className={inputClasses}
                    placeholder={placeholder}
                    data-testid={dataTestId + "-input"}
                    value={value}
                    onChange={(e) => onChange?.(e.target.value)}
                    disabled={disabled}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    readOnly={readOnly}
                    onKeyPress={onKeyPress}
                    id={inputId}
                    autoFocus={autoFocus}
                    ref={inputRef}
                />
            )}

            {label && insideLabel && (
                <label className="form-group__label">{label}</label>
            )}

            {showErrorMessage ? (
                <div className="error-message" data-testid="error-message">
                    {error}
                </div>
            ) : null}

            {icon ? <span className="form-group__icon">{icon}</span> : null}
        </div>
    );
};
