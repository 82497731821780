import React from "react";
import { format } from "date-fns";
import styles from "./AccountingReportFooter.module.scss";

interface Props {
    generationDate: Date;
}

export const AccountingReportFooter: React.FC<Props> = ({ generationDate }) => (
    <div className={styles.footer}>
        Kick.co | Modified Cash Basis |{" "}
        {format(generationDate, "MMM d, yyyy h:mm a")}
    </div>
);
