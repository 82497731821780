import React from "react";
import { useFormikContext } from "formik";
import classNames from "classnames";
import animation from "../../layout/OnboardingLayout.module.scss";
import { ProfileFormSchema } from "../../types";
import { ObjectIcon } from "../../../general/ObjectIcon/ObjectIcon";
import { ENTITY_ICON_COLORS } from "../../../../common/constants";
import { useUser } from "../../../../hooks/useUser";
import { Entity } from "../../../../common/types/entity";
import styles from "./ProductPreview.module.scss";

interface ProductPreviewProps {
    entity?: Entity;
}

export const ProductPreview: React.FC<ProductPreviewProps> = ({ entity }) => {
    const user = useUser();
    const { values } = useFormikContext<ProfileFormSchema>();

    const displayEntityName =
        (values.name?.trim() ?? entity?.name) || "Internet Money";
    const displayUserName =
        `${values.firstName ?? user.firstName} ${values.lastName ?? user.lastName}`.trim() ||
        "Matt Peterson";

    return (
        <div className={classNames(styles.productPreview, animation.fadeIn1)}>
            <section className={classNames(styles.entities, animation.fadeIn2)}>
                <div className={styles.entity}>
                    <ObjectIcon
                        defaultColor={ENTITY_ICON_COLORS[2]}
                        size="xs"
                        name={displayEntityName}
                        square
                    />{" "}
                    {displayEntityName}
                </div>
                <div className={styles.entity}>
                    <ObjectIcon
                        defaultColor="transparent"
                        size="xs"
                        name={displayUserName}
                        border={styles.border}
                        square
                    />
                    {displayUserName}
                </div>
            </section>
        </div>
    );
};
