import {
    GetActionsResponse,
    GetActionsResponseDto,
} from "../common/dto/actions/get-actions-response.dto";
import { CompleteActionDto } from "../common/dto/actions/complete-action.dto";
import {
    ActionCategory,
    ActionsActivity,
    ActionsSummary,
} from "../common/types/actions";
import { backendClient, unwrapResponse } from "./backendClient";

export async function getActionsSummary(
    workspaceId: string,
): Promise<ActionsSummary> {
    return unwrapResponse(
        await backendClient.get(`/actions/${workspaceId}/summary`),
    );
}

export async function getPendingActions(
    workspaceId: string,
    category: ActionCategory,
): Promise<GetActionsResponse> {
    return GetActionsResponseDto.deserialize(
        unwrapResponse(
            await backendClient.get(
                `/actions/${workspaceId}/pending/${category}`,
            ),
        ),
    );
}

export async function getConfirmedActions(
    workspaceId: string,
): Promise<GetActionsResponse> {
    return GetActionsResponseDto.deserialize(
        unwrapResponse(
            await backendClient.get(`/actions/${workspaceId}/confirmed`),
        ),
    );
}

export async function getSkippedActions(
    workspaceId: string,
    category: ActionCategory,
): Promise<GetActionsResponse> {
    return GetActionsResponseDto.deserialize(
        unwrapResponse(
            await backendClient.get(
                `/actions/${workspaceId}/skipped/${category}`,
            ),
        ),
    );
}

export async function getActionsActivity(
    workspaceId: string,
): Promise<ActionsActivity> {
    return unwrapResponse(
        await backendClient.get(`/actions/${workspaceId}/activity`),
    );
}

export async function completeAction(
    id: string,
    workspaceId: string,
    data: CompleteActionDto,
): Promise<void> {
    return unwrapResponse(
        await backendClient.post(`/actions/${workspaceId}/${id}`, data),
    );
}
