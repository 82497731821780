import { isPlaidConnection } from "../../helpers/financialConnection";
import { FinancialAccountSourceType } from "../../types/financialAccount";
import {
    ConnectionProviderType,
    FinancialConnection,
} from "../../types/financialConnection";
import { IntegrationAccount } from "../../types/integrationAccount";
import { isoStringOrNull, unpackDateOrNull, valueOrNull } from "../dto-helpers";
import { PlaidConnectionDto } from "../plaidConnection/plaid-connection.dto";
import { FinancialDocumentBaseDto } from "../financialDocument/financial-document-base.dto";
import { IntegrationConnectionDto } from "../integrationConnection/integration-connection.dto";
import { FinancialAccountDto } from "./financial-account.dto";
import { FinancialConnectionDto } from "./financial-connection.dto";

interface IntegrationAccountDtoOptions {
    nestedInFinancialAccount?: boolean;
}

export class IntegrationAccountDto {
    static deserialize(dto: IntegrationAccountDto): IntegrationAccount {
        const connectionDeserialization =
            dto.connection?.connectionProvider === ConnectionProviderType.PLAID
                ? PlaidConnectionDto.deserialize
                : FinancialConnectionDto.deserialize;

        return {
            ...dto,
            balanceSyncedAt: unpackDateOrNull(dto.balanceSyncedAt),
            transactionsSyncedAt: unpackDateOrNull(dto.transactionsSyncedAt),
            invoicesSyncedAt: unpackDateOrNull(dto.invoicesSyncedAt),
            createdAt: unpackDateOrNull(dto.createdAt),
            financialAccount: FinancialAccountDto.deserialize(
                dto.financialAccount,
            ),
            financialDocument: dto.financialDocument
                ? FinancialDocumentBaseDto.deserialize(dto.financialDocument)
                : undefined,
            connection: dto.connection
                ? valueOrNull(
                      dto.connection,
                      connectionDeserialization as (
                          dto: FinancialConnectionDto,
                      ) => FinancialConnection,
                  )
                : null,
            integrationConnection: dto.integrationConnection
                ? IntegrationConnectionDto.deserialize(
                      dto.integrationConnection,
                  )
                : undefined,
        };
    }
    id: string;
    balanceSyncedAt: string | null;
    transactionsSyncedAt: string | null;
    invoicesSyncedAt: string | null;
    createdAt: string | null;
    sourceType: FinancialAccountSourceType;
    sourceAccountId: string | null;
    connectionId: number | null;
    connection?: FinancialConnectionDto;
    integrationConnectionId: string | null;
    integrationConnection?: IntegrationConnectionDto;
    financialAccountId: number;
    financialAccount: FinancialAccountDto;
    financialDocument?: FinancialDocumentBaseDto;
    metadata: any;

    constructor(
        integrationAccount: IntegrationAccount,
        { nestedInFinancialAccount }: IntegrationAccountDtoOptions = {},
    ) {
        this.id = integrationAccount.id;
        this.balanceSyncedAt = isoStringOrNull(
            integrationAccount.balanceSyncedAt,
        );
        this.transactionsSyncedAt = isoStringOrNull(
            integrationAccount.transactionsSyncedAt,
        );
        this.invoicesSyncedAt = isoStringOrNull(
            integrationAccount.invoicesSyncedAt,
        );
        this.createdAt = isoStringOrNull(integrationAccount.createdAt);
        this.sourceType = integrationAccount.sourceType;
        this.sourceAccountId = integrationAccount.sourceAccountId;
        this.connectionId = integrationAccount.connectionId;
        if (integrationAccount.connection) {
            this.connection = this.serializeConnection(
                integrationAccount.connection,
            );
        }
        this.integrationConnection = integrationAccount.integrationConnection
            ? new IntegrationConnectionDto(
                  integrationAccount.integrationConnection,
              )
            : undefined;
        this.integrationConnectionId =
            integrationAccount.integrationConnectionId;
        this.financialAccountId = integrationAccount.financialAccountId;
        this.financialAccount = new FinancialAccountDto(
            integrationAccount.financialAccount,
            {
                includeIntegrationAccounts: !nestedInFinancialAccount,
            },
        );
        this.financialDocument = integrationAccount.financialDocument
            ? new FinancialDocumentBaseDto(integrationAccount.financialDocument)
            : undefined;

        this.metadata = integrationAccount.metadata;
    }

    serializeConnection(
        connection: FinancialConnection,
    ): FinancialConnectionDto {
        if (isPlaidConnection(connection)) {
            return new PlaidConnectionDto(connection);
        }

        return new FinancialConnectionDto(connection);
    }
}
