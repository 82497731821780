import React, { useCallback, useContext, useState } from "react";
import fileDownload from "js-file-download";
import { DownloadIcon } from "../../../icons";
import { useWorkspaceContext } from "../../../state/workspaceContext";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import {
    CounterpartiesReturnFormat,
    CounterpartySort,
} from "../../../common/constants/counterparty";
import { getCounterparties } from "../../../api/counterparty.api";
import { DirectoryContext } from "./Directory.context";

export const DirectoryDownload: React.FC = () => {
    const { activeWorkspace } = useWorkspaceContext();
    const [downloading, setDownloading] = useState(false);
    const { filters } = useContext(DirectoryContext);

    const download = useCallback(async () => {
        if (!activeWorkspace) {
            return;
        }

        const { data, headers } = await getCounterparties(
            {
                workspaceId: activeWorkspace.id,
                sort: CounterpartySort.NAME_ASC,
                includeUsageStats: true,
                startDate: filters.year ? `${filters.year}-01-01` : undefined,
                endDate: filters.year ? `${filters.year}-12-31` : undefined,
            },
            CounterpartiesReturnFormat.CSV,
        );

        fileDownload(
            data,
            headers["content-disposition"].split("filename=")[1],
        );
    }, [activeWorkspace, filters.year]);

    return (
        <ButtonWithLoader
            variant="secondary"
            icon
            loading={downloading}
            setLoading={setDownloading}
            onClick={download}
        >
            <DownloadIcon />
        </ButtonWithLoader>
    );
};
