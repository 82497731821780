import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTypedFlags } from "../../../hooks/useTypedFlags";
import {
    useSettings,
    WorkspaceSettingsPages,
} from "../../settings/SettingsProvider";
import { useCanManageBilling } from "../../../hooks/useCanManageBilling";

export const WorkspaceItems: React.FC = () => {
    const { open: openSettings } = useSettings();
    const canManageBilling = useCanManageBilling();

    const { directory: canUseDirectory, classes: canUseClasses } =
        useTypedFlags();

    return (
        <>
            <Dropdown.Header>Workspace</Dropdown.Header>
            <Dropdown.Item
                onClick={() => openSettings([WorkspaceSettingsPages.MEMBERS])}
            >
                Members
            </Dropdown.Item>

            <Dropdown.Item
                onClick={() =>
                    openSettings([WorkspaceSettingsPages.CATEGORIES])
                }
            >
                Categories
            </Dropdown.Item>

            <Dropdown.Item
                onClick={() => openSettings([WorkspaceSettingsPages.RULES])}
            >
                Rules
            </Dropdown.Item>

            {canUseDirectory && (
                <Dropdown.Item
                    onClick={() =>
                        openSettings([WorkspaceSettingsPages.DIRECTORY])
                    }
                >
                    Directory
                </Dropdown.Item>
            )}

            {canUseClasses && (
                <Dropdown.Item
                    onClick={() =>
                        openSettings([WorkspaceSettingsPages.CLASSES])
                    }
                >
                    Classes
                </Dropdown.Item>
            )}

            {canManageBilling && (
                <>
                    <Dropdown.Item onClick={() => openSettings(["plans"])}>
                        Plans
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => openSettings(["billing"])}>
                        Billing
                    </Dropdown.Item>
                </>
            )}
        </>
    );
};
