import React from "react";
import { SettingsSection } from "../SettingsSection";
import { CheckIcon } from "../../../icons";
import "./WhatsIncluded.scss";

const items: string[] = [
    "Profit & Loss (Cash Basis)",
    "Real-time Categorization",
    "Receipt Matching",
    "Expense Monitoring",
    "Deduction Optimization",
    "Unlimited Accounts",
    "Unlimited Members",
    "Expert Support",
];

export const WhatsIncluded: React.FC = () => (
    <SettingsSection
        heading="What's Included"
        className="billing__whats-included"
    >
        <ul className="list-unstyled billing__whats-included__list">
            {items.map((item) => (
                <li className="billing__whats-included__item" key={item}>
                    <CheckIcon /> {item}
                </li>
            ))}
        </ul>
    </SettingsSection>
);
