export const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export const currencyFormatterNoFractions = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const currencyFormatterFractionsIfNeeded = new Intl.NumberFormat(
    "en-US",
    {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    },
);

export const currencyFormatterShort = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    notation: "compact",
    compactDisplay: "short",
});

export interface CurrencyFormatterFinancialStatementsOptions {
    addCurrency?: boolean;
    includeFractions?: boolean;
    displaySign?: boolean;
    explicitPlusSign?: boolean;
    fractionDigits?: number;
}
export const currencyFormatterFinancialStatements = {
    format: (
        value: number | string,
        {
            addCurrency = false,
            includeFractions = false,
            displaySign = false,
            explicitPlusSign = false,
            fractionDigits: fractionDigitsPassed = 2,
        }: CurrencyFormatterFinancialStatementsOptions = {},
    ) => {
        const fractionDigits = includeFractions ? fractionDigitsPassed : 0;
        if (typeof value === "string") {
            value = parseFloat(value);
        }
        value = value === 0 ? 0 : value; // -0 fix
        value = displaySign ? value : Math.abs(value);
        const options = {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        } as Intl.NumberFormatOptions;
        if (addCurrency) {
            options.style = "currency";
            options.currency = "USD";
        }
        return [
            value > 0 && explicitPlusSign ? "+" : "",
            value.toLocaleString("en-US", options),
        ].join("");
    },
};
