import React, { useEffect, useState } from "react";
import { Field, Form, Formik, FormikConfig } from "formik";
import { object, string } from "yup";
import classNames from "classnames";
import { FormRow } from "../../../forms/FormRow";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import { submitHelper } from "../../../../helpers/form";
import { KeyboardReturnIcon } from "../../../../icons";
import { KeyboardShortcut } from "../../../general/KeyboardShortcut/KeyboardShortcut";
import styles from "./Register.module.scss";

interface Props {
    onSubmit(email: string): void | Promise<void>;
    initialEmail?: string;
}

interface FormSchema {
    email: string;
}

export const RegisterWithEmail: React.FC<Props> = ({
    onSubmit,
    initialEmail,
}) => {
    const [loading, setLoading] = useState(false);
    const [touched, setTouched] = useState(!!initialEmail);
    const emailInputRef = React.useRef<HTMLInputElement>(null);

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            email: initialEmail ?? "",
        },
        validationSchema: object().shape({
            email: string()
                .required("Email is required")
                .email("Provide a valid email"),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async ({ email }) => await onSubmit(email),
        }),
    };

    useEffect(() => {
        if (initialEmail && emailInputRef.current) {
            emailInputRef.current.focus();
        }
    }, [initialEmail]);

    return (
        <Formik {...form}>
            {({ isValid }) => (
                <Form
                    className={classNames(styles.form, {
                        [styles.touched]: touched,
                    })}
                >
                    <FormRow fieldName="email" className={styles.emailInput}>
                        <Field
                            name="email"
                            className="form-control form-control-sm"
                            placeholder="Enter your email"
                            onFocus={() => setTouched(true)}
                            innerRef={emailInputRef}
                        />
                    </FormRow>

                    <ButtonWithLoader
                        loading={loading}
                        type="submit"
                        variant="secondary"
                        size="lg"
                        disabled={!isValid}
                        className={styles.submitButton}
                    >
                        Continue
                        <KeyboardShortcut square className="ml-2">
                            <KeyboardReturnIcon />
                        </KeyboardShortcut>
                    </ButtonWithLoader>
                </Form>
            )}
        </Formik>
    );
};
