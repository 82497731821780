import React, { useMemo, useState } from "react";
import { ChildrenProps } from "../../../types";
import { AccountsChartEntityContext } from "./AccountsChartEntity.context";

export const AccountsChartEntityContextProvider: React.FC<ChildrenProps> = ({
    children,
}) => {
    const [hoveredAccountId, setHoveredAccountId] = useState<number>();

    const value = useMemo(
        () => ({ hoveredAccountId, setHoveredAccountId }),
        [hoveredAccountId],
    );

    return (
        <AccountsChartEntityContext.Provider value={value}>
            {children}
        </AccountsChartEntityContext.Provider>
    );
};
