import { useEffect, useMemo, useState } from "react";
import { formatISO } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { Entity } from "../../common/types/entity";
import { TransactionClassFilterType } from "../../common/helpers/transactions";
import { AccountingReportFilters } from "./types";

export interface BasicAccountingReportQuery {
    startDate?: string;
    endDate?: string;
    entityId: number;
    class?: string[];
    classFilterType?: TransactionClassFilterType;
}

export interface UseAccountingReportParams<
    T,
    Q extends BasicAccountingReportQuery,
> {
    filters: AccountingReportFilters;
    entity?: Entity;
    queryKey: string;
    reportGetter: (query: Q) => Promise<T>;
    prepareQuery?: (query: BasicAccountingReportQuery) => Q;
}

export function useAccountingReport<T, Q extends BasicAccountingReportQuery>({
    filters,
    entity,
    queryKey,
    reportGetter,
    prepareQuery = (query) => query as Q,
}: UseAccountingReportParams<T, Q>) {
    const [generationDate, setGenerationDate] = useState(new Date());

    const query = useMemo(
        () =>
            entity
                ? prepareQuery({
                      startDate: filters.startDate
                          ? formatISO(filters.startDate, {
                                representation: "date",
                            })
                          : undefined,
                      endDate: filters.endDate
                          ? formatISO(filters.endDate, {
                                representation: "date",
                            })
                          : undefined,
                      entityId: entity.id,
                      class: filters.classes,
                      classFilterType: filters.classFilterType,
                  })
                : null,
        [
            entity,
            filters.startDate,
            filters.endDate,
            filters.classes,
            filters.classFilterType,
            prepareQuery,
        ],
    );

    const report = useQuery({
        queryKey: [queryKey, query],
        queryFn: () => (query ? reportGetter(query) : null),
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
    });

    useEffect(() => {
        if (report.data) {
            setGenerationDate(new Date());
        }
    }, [report.data, setGenerationDate]);

    return {
        report,
        generationDate,
    };
}
