import React from "react";
import { InvalidPlaidAccount } from "../../../common/dto/plaid/plaid-common";
import { getAnonymousAccountNumber } from "../../../common/helpers/bankAccount";

interface Props {
    invalidAccounts: InvalidPlaidAccount[];
}

export const PlaidInvalidAccountsList: React.FC<Props> = ({
    invalidAccounts,
}) => (
    <ul>
        {invalidAccounts.map((account) => (
            <li key={account.name + account.accountNumberLast4}>
                {account.name}{" "}
                {account.accountNumberLast4
                    ? getAnonymousAccountNumber(account.accountNumberLast4)
                    : ""}
            </li>
        ))}
    </ul>
);
