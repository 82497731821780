import { createContext } from "react";
import { Counterparty } from "../../../common/types/counterparty";
import { noop } from "../../../helpers/general";
import { CounterpartyDirection } from "../../../common/helpers/counterparties";
import { DirectoryFilters } from "./lib";

interface Context {
    filters: DirectoryFilters;
    setFilters: (filters: DirectoryFilters) => void;
    editedItem?: Counterparty;
    setEditedItem: (item?: Counterparty) => void;
    addingNew: boolean;
    setAddingNew: (addingNew: boolean) => void;
    counterpartyDirection?: CounterpartyDirection;
    setCounterpartyDirection: (direction?: CounterpartyDirection) => void;
}

export const DirectoryContext = createContext<Context>({
    filters: {},
    setFilters: noop,
    setEditedItem: noop,
    addingNew: false,
    setAddingNew: noop,
    setCounterpartyDirection: noop,
});
