import { useMutation } from "@tanstack/react-query";
import { userRest } from "../lib/restClient";
import { User } from "../common/types/user";
import { UpdateUserDto } from "../common/dto/user/update-user.dto";
import { useUser } from "../hooks/useUser";
import { useUpdateUser } from "../hooks/useUpdateUser";

export function useUpdateUserMutation() {
    const user = useUser();
    const updateUser = useUpdateUser();

    return useMutation<User, unknown, UpdateUserDto, User>({
        mutationFn: (payload: UpdateUserDto) =>
            userRest.update(user.id, payload),

        onMutate: (payload) => {
            updateUser({
                ...user,
                ...payload,
            });

            return user;
        },
        onError: (_1, _2, previous) => {
            if (previous) {
                updateUser(previous);
            }
        },
        onSuccess: (updatedUser) => {
            updateUser(updatedUser);
        },
    });
}
