import { pluralize } from "../../../common/helpers/string";
import { ReceiptIcon } from "../../../icons";
import { ActionActivity } from "../ActionActivity";
import { CampaignActivityProps } from "../ActionActivityItem";

export const IRSRequirementsActivity: React.FC<CampaignActivityProps> = ({
    animated,
    count,
}) => (
    <ActionActivity icon={<ReceiptIcon />} animated={animated}>
        Confirmed {count} tax {pluralize("requirement", count)}
    </ActionActivity>
);
