import React from "react";
import { CampaignActivityProps } from "../../ActionActivityItem";
import { ActionActivity } from "../../ActionActivity";
import { DocumentIcon } from "../../../../icons";
import { pluralize } from "../../../../common/helpers/string";

export const MatchedReceiptsActivity: React.FC<CampaignActivityProps> = ({
    animated,
    count,
}) => (
    <ActionActivity icon={<DocumentIcon />} animated={animated}>
        Matched {count} {pluralize("receipt", count)}
    </ActionActivity>
);
