import React, { useCallback, useMemo, useState } from "react";
import { FinancialAccount } from "../../../../common/types/financialAccount";
import { MergeFinancialAccountsResponse } from "../../../../common/dto/financialAccount/mergeFinancialAccounts/merge-financial-accounts-response.dto";
import { getAccountNameWithLast4 } from "../../../../common/helpers/financialAccount";
import { pluralize } from "../../../../common/helpers/string";
import { Button } from "../../../general/Button/Button";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import { Transaction } from "../../../../common/types/transaction";
import { BothModifiedConflict } from "../../../../common/dto/financialAccount/mergeFinancialAccounts/both-modified-conflict.dto";
import { MultipleMatchingConflicts } from "./MultipleMatchingConflicts";
import { BothModifiedConflicts } from "./BothModifiedConflicts";
import { DeduplicatedTransactionsPreview } from "./DeduplicatedTransactionsPreview";

export interface ExecuteMergeOptions {
    bothModifiedConflictsResolutions: number[];
    multipleMatchingConflictsResolutions: Array<{
        fromDestination: number;
        toKeep: number;
    }>;
}

interface Props {
    destinationAccount: FinancialAccount;
    accountToMerge: FinancialAccount;
    mergePreview: MergeFinancialAccountsResponse;
    executeMerge: (options: ExecuteMergeOptions) => Promise<void>;
    onReset: () => void;
}

export const MergePreview: React.FC<Props> = ({
    destinationAccount,
    accountToMerge,
    mergePreview,
    onReset,
    executeMerge,
}) => {
    const destinationAccountName = getAccountNameWithLast4(
        destinationAccount,
        2,
    );
    const mergedAccountName = getAccountNameWithLast4(accountToMerge, 2);
    const [merging, setMerging] = useState(false);
    const [
        bothModifiedConflictsResolutions,
        setBothModifiedConflictsResolutions,
    ] = useState<number[]>([]);

    const [
        multipleMatchingConflictsResolutions,
        setMultipleMatchingConflictsResolutions,
    ] = useState<
        Array<{
            fromDestination: number;
            toKeep: number;
        }>
    >([]);

    const bothModifiedConflictsResolved = useMemo(
        () =>
            mergePreview.bothModifiedConflicts.every(
                (conflict) =>
                    bothModifiedConflictsResolutions.includes(
                        conflict.transactionFromDestination.id,
                    ) ||
                    bothModifiedConflictsResolutions.includes(
                        conflict.transactionFromMerged.id,
                    ),
            ),
        [mergePreview.bothModifiedConflicts, bothModifiedConflictsResolutions],
    );

    const mergeDisabled =
        mergePreview.multipleMatchingConflicts.length >
            multipleMatchingConflictsResolutions.length ||
        (mergePreview.bothModifiedConflicts.length > 0 &&
            !bothModifiedConflictsResolved);
    let mergeMessage;

    if (mergeDisabled) {
        mergeMessage = "Please resolve conflicts before merging.";
    }

    const resolveBothModifiedConflict = useCallback(
        (conflict: BothModifiedConflict, resolution: Transaction) => {
            setBothModifiedConflictsResolutions((prev) => {
                const newResolutions = [...prev].filter(
                    (id) =>
                        id !== conflict.transactionFromDestination.id &&
                        id !== conflict.transactionFromMerged.id,
                );
                newResolutions.push(resolution.id);

                return newResolutions;
            });
        },
        [],
    );

    return (
        <>
            {mergePreview.movedTransactionsNumber > 0 && (
                <p>
                    {mergePreview.movedTransactionsNumber}{" "}
                    {pluralize(
                        "transaction",
                        mergePreview.movedTransactionsNumber,
                    )}{" "}
                    will be moved to <strong>{destinationAccountName}</strong>
                </p>
            )}

            {mergePreview.deduplicatedTransactions.length > 0 && (
                <div className="mb-4">
                    <DeduplicatedTransactionsPreview
                        duplicates={mergePreview.deduplicatedTransactions}
                    />
                </div>
            )}

            {mergePreview.multipleMatchingConflicts.length > 0 && (
                <div className="mb-4">
                    <MultipleMatchingConflicts
                        conflicts={mergePreview.multipleMatchingConflicts}
                        multipleMatchingConflictsResolutions={
                            multipleMatchingConflictsResolutions
                        }
                        onResolve={setMultipleMatchingConflictsResolutions}
                    />
                </div>
            )}

            {mergePreview.bothModifiedConflicts.length > 0 && (
                <div className="mb-4">
                    <BothModifiedConflicts
                        conflicts={mergePreview.bothModifiedConflicts}
                        resolutions={bothModifiedConflictsResolutions}
                        onResolve={resolveBothModifiedConflict}
                    />
                </div>
            )}

            <p>
                <strong>{mergedAccountName}</strong> account will be removed.
            </p>

            <footer className="d-flex justify-content-between mt-4 align-items-start">
                <Button variant="secondary" onClick={onReset}>
                    Cancel
                </Button>
                <div className="text-right">
                    <ButtonWithLoader
                        variant="default"
                        onClick={() =>
                            executeMerge({
                                bothModifiedConflictsResolutions,
                                multipleMatchingConflictsResolutions,
                            })
                        }
                        loading={merging}
                        setLoading={setMerging}
                        disabled={mergeDisabled}
                    >
                        Merge
                    </ButtonWithLoader>
                    {mergeMessage && (
                        <p className="small mb-0 mt-2">{mergeMessage}</p>
                    )}
                </div>
            </footer>
        </>
    );
};
