import { initContract } from "@ts-rest/core";
import { z } from "zod";
import { Class } from "../types/class";
import {
    AccountType,
    AccountSubtype,
} from "../constants/domains/accounting/accountsv2";
import { Account } from "../types/domains/accounting/account";

const c = initContract();

export interface CreateAccountParams {
    account: {
        name: string;
        type: AccountType;
        subtype: AccountSubtype;
    };
}

export const accountContract = c.router(
    {
        getAccounts: {
            method: "GET",
            path: "/:entityId",
            responses: {
                200: c.type<{ classes: Class[] }>(),
            },
        },
        createAccount: {
            method: "POST",
            path: "/:entityId",
            responses: {
                200: c.type<{ account: Account }>(),
            },
            body: z.object({
                account: z.object({
                    name: z.string(),
                    type: z.nativeEnum(AccountType),
                    subtype: z.nativeEnum(AccountSubtype),
                }),
            }),
        },
        disableAccount: {
            method: "POST",
            path: "/:entityId/:id/disable",
            responses: {
                200: c.type<{ deleted: boolean; account: Account | null }>(),
            },
            body: z.object({}),
        },
        enableAccount: {
            method: "POST",
            path: "/:entityId/:id/enable",
            responses: {
                200: c.type<{ account: Account }>(),
            },
            body: z.object({}),
        },
        updateAccount: {
            method: "PATCH",
            path: "/:entityId/:id",
            responses: {
                200: c.type<{ account: Account }>(),
            },
            body: z.object({
                name: z.string(),
            }),
        },
        changeAccountPosition: {
            method: "POST",
            path: "/:entityId/:id/position",
            responses: {
                200: c.type<{ account: Account }>(),
            },
            body: z.object({
                targetOrder: z.number().int(),
                targetType: z.nativeEnum(AccountType),
                targetSubtype: z.nativeEnum(AccountSubtype),
            }),
        },
    },
    {
        pathPrefix: "/accounting/accounts",
    },
);
