import React from "react";
import { SettingsSection } from "../SettingsSection";
import { UserInfo } from "./UserInfo";
import { Notifications } from "./Notifications";

export const GeneralSettings: React.FC = () => (
    <div className="settings__general">
        <header className="settings__header">
            <h3>General</h3>
        </header>

        <SettingsSection heading="Your Info">
            <UserInfo />
        </SettingsSection>

        <SettingsSection heading="Email Notifications">
            <Notifications />
        </SettingsSection>
    </div>
);
