import type { FlatRatePlan } from "../../flatRateBilling";
import { BillingStatus, SubscriptionDetails } from "../../types/billing";
import { valueOrNull } from "../dto-helpers";
import { SubscriptionDetailsDto } from "./subscription-details.dto";

export class BillingStatusDto {
    static deserialize(data: BillingStatusDto): BillingStatus {
        return {
            ...data,
            subscriptionDetails: valueOrNull(
                data.subscriptionDetails,
                SubscriptionDetailsDto.deserialize,
            ),
        };
    }

    public subscriptionDetails: SubscriptionDetailsDto | null;
    constructor(
        public totalUsage: number,
        public plans: FlatRatePlan[],
        public trialAvailable: boolean,
        subscriptionDetails?: SubscriptionDetails,
    ) {
        this.subscriptionDetails = subscriptionDetails
            ? new SubscriptionDetailsDto(subscriptionDetails)
            : null;
    }
}
