import { useQuery } from "@tanstack/react-query";
import { useWorkspaceContext } from "../state/workspaceContext";
import { getBillingHistory } from "../lib/billing";
import { billingQueryKeys } from "./useBillingStatus";

export function useBillingHistory() {
    const { activeWorkspaceKey } = useWorkspaceContext();

    return useQuery({
        queryKey: billingQueryKeys.history(activeWorkspaceKey),
        queryFn: () => getBillingHistory(activeWorkspaceKey),
    });
}
