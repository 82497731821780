import { Transaction } from "../../../types/transaction";
import { TransactionDto } from "../../transactions/transaction.dto";

export interface BothModifiedConflict {
    transactionFromDestination: Transaction;
    transactionFromMerged: Transaction;
}

export class BothModifiedConflictDto {
    transactionFromDestination: TransactionDto;
    transactionFromMerged: TransactionDto;

    constructor(conflict: BothModifiedConflict) {
        this.transactionFromDestination = new TransactionDto(
            conflict.transactionFromDestination,
        );
        this.transactionFromMerged = new TransactionDto(
            conflict.transactionFromMerged,
        );
    }

    static deserialize(dto: BothModifiedConflictDto): BothModifiedConflict {
        return {
            transactionFromDestination: TransactionDto.deserialize(
                dto.transactionFromDestination,
            ),
            transactionFromMerged: TransactionDto.deserialize(
                dto.transactionFromMerged,
            ),
        };
    }
}
