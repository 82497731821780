import React, { useEffect, useRef } from "react";
import { object } from "yup";
import { Form, Formik, FormikConfig } from "formik";
import { FormikProps } from "formik/dist/types";
import { omit } from "lodash";
import { AddressForm, getUSAddressSchema } from "../../../forms/AddressForm";
import { submitHelper } from "../../../../helpers/form";
import { USAddress } from "../../../../common/types/embeddable/usAddress";
import { ChildrenProps } from "../../../../types";

export interface ClientAddressProps extends ChildrenProps {
    address?: Partial<USAddress>;
    onSubmit?(values: USAddress): Promise<any>;
    business: boolean;
    readOnly?: boolean;
    wide?: boolean;
}

function getRelevantAddressFields(address: Partial<USAddress>) {
    return {
        street: address.street ?? "",
        city: address.city ?? "",
        state: address.state ?? "",
        postalCode: address.postalCode ?? "",
    };
}

export const ClientAddress: React.FC<ClientAddressProps> = ({
    address = {},
    business,
    readOnly,
    onSubmit,
    children,
    wide,
}) => {
    const formRef = useRef<FormikProps<USAddress>>();

    useEffect(() => {
        formRef.current?.resetForm({
            values: getRelevantAddressFields(address),
        });
    }, [address]);

    const form: FormikConfig<USAddress> = {
        initialValues: getRelevantAddressFields(address),
        validationSchema: object().shape(getUSAddressSchema(!business)),
        onSubmit: submitHelper({
            async handler(values) {
                await onSubmit?.(omit(values, "country"));
            },
        }),
    };

    return (
        <Formik {...form} innerRef={formRef as any}>
            <Form>
                <fieldset disabled={readOnly}>
                    <AddressForm
                        prefix=""
                        wide={wide}
                        fieldSize="sm"
                        readOnly={readOnly}
                    />
                </fieldset>

                {children}
            </Form>
        </Formik>
    );
};
