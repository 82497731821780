import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { values } from "lodash";
import React, { useEffect } from "react";
import {
    ProfitLossChartDataSeriesCalculatedIdentifier,
    ProfitLossChartReportDto,
    ProfitLossReportDto,
} from "../../common/dto/reports/reporting-tab-profit-loss-response.dto";
import { printLabel } from "../../common/helpers/reports";
import { ReportFilters } from "../../common/types/filters/reports";
import {
    CategoryLabelGetter,
    useCategoryLabelGetter,
} from "../../hooks/useCategoryLabelGetter";
import { useStandardCategories } from "../../hooks/useStandardCategories";
import { Card } from "../general/Card/Card";
import { isUUID } from "../../common/helpers/uuid";
import { useCategoriesById } from "../../hooks/useCategoriesById";
import {
    ChartDataSeriesItem,
    CreateFilteredChartParams,
    GenerateDataSeriesParams,
} from "./types";
import { generateSingleCategoryTooltip } from "./helpers/tooltip";
import { applySeriesColor } from "./helpers/colors";

am4core.useTheme(am4themes_animated);

const chartLabelsMap: Record<
    ProfitLossChartDataSeriesCalculatedIdentifier,
    string
> = {
    other: "Other",
    income_rest: "Other",
    dummy: "Dummy",
};

function getSeriesLabel(
    field: ChartDataSeriesItem,
    getCategoryLabel: CategoryLabelGetter,
): string {
    if (
        values(ProfitLossChartDataSeriesCalculatedIdentifier).includes(
            field.label as ProfitLossChartDataSeriesCalculatedIdentifier,
        )
    ) {
        return chartLabelsMap[
            field.label as ProfitLossChartDataSeriesCalculatedIdentifier
        ];
    }

    if (isUUID(field.label)) {
        return getCategoryLabel(field.label);
    }

    return field.label;
}

function generateDataSeries({
    chart,
    item,
    getCategoryLabel,
    categoriesById,
}: GenerateDataSeriesParams) {
    // Set up series
    const series = chart.series.push(new am4charts.ColumnSeries());
    series.name = getSeriesLabel(item, getCategoryLabel);

    series.dataFields.valueY = item.label;
    series.dataFields.categoryX = "label";

    // Make it stacked
    series.stacked = true;
    series.strokeWidth = 0.5;

    applySeriesColor({ item, series, categoriesById });
    series.strokeOpacity = 0.6;
    series.fillOpacity = 0.6;

    // Configure columns
    series.columns.template.width = am4core.percent(45);

    series.columns.template.tooltipHTML = ``;
    series.columns.template.tooltipY = am4core.percent(50);

    return series;
}

interface CreateCashFlowChartParams
    extends CreateFilteredChartParams<ProfitLossChartReportDto> {
    reportCreatedAt: number;
    setCategoryFilter?: (categories: string[]) => void;
}

function createChart({
    id,
    report,
    getCategoryLabel,
    filters,
    categoriesById,
    reportCreatedAt,
    setCategoryFilter,
}: CreateCashFlowChartParams) {
    const chart = am4core.create(id, am4charts.XYChart);

    chart.data = report.data;
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "label";
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.cursorTooltipEnabled = false;
    categoryAxis.renderer.labels.template.fontSize = 12;
    categoryAxis.renderer.labels.template.fill = am4core.color("#515d71");
    categoryAxis.renderer.labels.template.adapter.add("textOutput", (label) =>
        printLabel(label, filters),
    );
    categoryAxis.renderer.minLabelPosition = 0.02;
    categoryAxis.renderer.maxLabelPosition = 0.98;
    if (chart.data.length <= 12) {
        categoryAxis.renderer.minGridDistance = 1;
    }

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.stroke = am4core.color("#53647e");
    valueAxis.renderer.grid.template.opacity = 0.6;
    valueAxis.renderer.baseGrid.stroke = am4core.color("#53647e");
    valueAxis.renderer.baseGrid.opacity = 0.7;
    valueAxis.renderer.baseGrid.strokeWidth = 1.5;
    valueAxis.renderer.labels.template.fill = am4core.color("#515d71"); // gray-700
    valueAxis.renderer.labels.template.fontSize = 12;
    valueAxis.cursorTooltipEnabled = false;

    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "$#,###sa| -$#,###sa";

    const series = [];
    const reportSeries = report.series.filter(
        (s) => s.label !== ProfitLossChartDataSeriesCalculatedIdentifier.DUMMY,
    );

    for (const rs of reportSeries) {
        series.push(
            generateDataSeries({
                chart,
                item: rs,
                getCategoryLabel,
                categoriesById,
            }),
        );
    }
    for (const seriesItem of series) {
        generateSingleCategoryTooltip(
            {
                chart,
                seriesToHookInto: seriesItem,
                filters,
                categoriesById,
            },
            reportCreatedAt,
        );
    }

    const columnSeries = series;
    for (const seriesItem of series) {
        if (setCategoryFilter) {
            seriesItem.columns.template.cursorOverStyle =
                am4core.MouseCursorStyle.pointer;
            seriesItem.columns.template.events.on("hit", function () {
                const category = seriesItem.dataFields.valueY!;

                if (category !== "other") {
                    if (isUUID(category)) {
                        setCategoryFilter([category]);
                        return;
                    }

                    return;
                }

                const categories: string[] =
                    filters.category ??
                    Object.values(categoriesById).map((cat) => cat.id);
                const shownCategories = columnSeries
                    .filter((item) => item !== seriesItem)
                    .map((item) => categoriesById[item.dataFields.valueY!]);
                const selectedTaxonomies = categories.filter((cat) =>
                    shownCategories.every((cat2) => cat2.id !== cat),
                );
                setCategoryFilter(selectedTaxonomies);
            });
        }
    }

    /* Style */
    chart.padding(30, 24, 12, 12);

    return chart;
}

export interface CashFlowReportChartProps {
    report: ProfitLossReportDto;
    filters: ReportFilters;
    reportCreatedAt: number;
    setCategoryFilter?: (categories: string[]) => void;
}

export const CashFlowReportChart: React.FC<CashFlowReportChartProps> = ({
    report,
    filters,
    reportCreatedAt,
    setCategoryFilter,
}) => {
    const getCategoryLabel = useCategoryLabelGetter();
    const categoryMap = useStandardCategories();
    const categoriesById = useCategoriesById();

    useEffect(() => {
        const chart = createChart({
            id: "cash-flow-chart",
            report: report.chart,
            getCategoryLabel,
            filters,
            reportCreatedAt,
            setCategoryFilter,
            categoriesById,
        });
        return () => chart.dispose();
    }, [
        report.chart,
        getCategoryLabel,
        filters,
        categoryMap,
        reportCreatedAt,
        setCategoryFilter,
        categoriesById,
    ]);
    return (
        <Card className="cash-flow-chart">
            <div id="cash-flow-chart" className="cash-flow-chart__chart"></div>
        </Card>
    );
};
