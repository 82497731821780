import { useCallback, useMemo } from "react";
import { DragOverlay, UniqueIdentifier } from "@dnd-kit/core";
import {
    SortableContext,
    SortingStrategy,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import {
    AbstractSortableListItem,
    RenderItemFunctionType,
    AbstractSortableListItemType,
} from "./AbstractSortableListItem";

export interface AbstractSortableListProps<
    T extends AbstractSortableListItemType = AbstractSortableListItemType,
> {
    items: T[];
    getItemId: (item: T) => UniqueIdentifier;
    renderItem: RenderItemFunctionType<T>;
    listId: string;
    activeId?: UniqueIdentifier | null;
    projected?: ProjectionForDnDTree | null;
    renderDragOverlay?: (item: T) => React.ReactNode;
}
export interface ProjectionForDnDTree {
    depth: number;
    overItemIndex: number;
}

export const AbstractSortableList = <T extends AbstractSortableListItemType>({
    items,
    getItemId,
    renderItem,
    listId,
    activeId,
    projected,
    renderDragOverlay,
}: AbstractSortableListProps<T>) => {
    const ids = useMemo(() => items.map((item) => item.id), [items]);

    const activeItemIndex = useMemo(
        () => items.findIndex((item) => item.id === activeId),
        [items, activeId],
    );
    const activeItem = items[activeItemIndex];

    const strategy = useCallback(
        (params: Parameters<SortingStrategy>[0]) =>
            verticalListSortingStrategy({
                ...params,
                overIndex: projected
                    ? projected.overItemIndex
                    : params.overIndex,
            }),
        [projected],
    );

    const itemsWithMemoizedState = useMemo(
        () =>
            items.map((item) => ({
                item,
                state: {
                    isDragged: item.id === activeId,
                    depth:
                        item.id === activeId && projected?.depth !== undefined
                            ? projected.depth
                            : item.depth,
                    parentIsDragged:
                        activeId !== null && item.parentId === activeId,
                },
            })),
        [activeId, items, projected?.depth],
    );

    return (
        <SortableContext id={listId} items={ids} strategy={strategy}>
            {itemsWithMemoizedState.map((item) => (
                <AbstractSortableListItem
                    state={item.state}
                    id={getItemId(item.item)}
                    key={getItemId(item.item)}
                    renderItem={renderItem}
                    item={item.item}
                />
            ))}
            {renderDragOverlay && (
                <DragOverlay>
                    {activeId && activeItem
                        ? renderDragOverlay(activeItem)
                        : null}
                </DragOverlay>
            )}
        </SortableContext>
    );
};
