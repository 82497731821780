import { useMutation } from "@tanstack/react-query";
import { FinancialAccount } from "../common/types/financialAccount";
import { Entity } from "../common/types/entity";
import {
    plaidConnectionsQueryKey,
    queryClient,
    financialConnectionsQueryKey,
} from "../queryClient";

import { financialAccountsRest } from "../lib/restClient";
import { PlaidConnection } from "../common/types/plaidConnection";
import {
    disableFinancialAccount,
    enableFinancialAccount,
    mergeFinancialAccounts,
} from "../lib/financialAccount";
import {
    entitiesAccountsQueryKey,
    removeFinancialAccountFromQueryData,
    updateFinancialAccountInQueryData,
} from "../queries/entitiesAccounts";
import {
    addUnassignedAccountInQueryData,
    removeUnassignedAccountFromQueryData,
} from "../queries/unassignedAccounts";
import { MergeFinancialAccountsDto } from "../common/dto/financialAccount/mergeFinancialAccounts/merge-financial-accounts.dto";
import { useFinancialAccounts } from "../hooks/useFinancialAccounts";
import { MergeFinancialAccountsResponse } from "../common/dto/financialAccount/mergeFinancialAccounts/merge-financial-accounts-response.dto";
import { useConnectAccount } from "../components/connectAccount/ConnectAccount.context";
import { useToaster } from "../components/general/ToastMessages/useToaster";

function doFinancialAccountUpdate(
    account: FinancialAccount,
    update: (account: FinancialAccount) => void,
) {
    updateFinancialAccountInQueryData(queryClient, account);
    queryClient.setQueryData<PlaidConnection[] | undefined>(
        plaidConnectionsQueryKey,
        (connections) =>
            connections?.map((connection) => ({
                ...connection,
                accounts: connection.accounts?.map((a) => ({
                    ...a,
                    financialAccount:
                        a.financialAccount.id === account.id
                            ? account
                            : a.financialAccount,
                })),
            })) ?? [],
    );
    if (account.entityId) {
        removeUnassignedAccountFromQueryData(queryClient, account);
    } else {
        addUnassignedAccountInQueryData(queryClient, account);
    }
    update(account);
}

function doFinancialAccountRemoval(account: FinancialAccount) {
    removeFinancialAccountFromQueryData(queryClient, account);
    removeUnassignedAccountFromQueryData(queryClient, account);
    queryClient.invalidateQueries({ queryKey: [financialConnectionsQueryKey] });
}

export function useChangeFinancialAccountEntityMutation(
    account: FinancialAccount,
    onSuccess?: () => void,
) {
    const { updateFinancialAccount } = useConnectAccount();
    const { error } = useToaster();

    return useMutation<FinancialAccount, unknown, Entity, Entity | null>({
        mutationFn: (entity) =>
            financialAccountsRest.update(account.id, {
                entityId: entity.id,
            }),

        onMutate: async (entity) => {
            const previousValue = account.entity;
            doFinancialAccountUpdate(
                {
                    ...account,
                    entity,
                    entityId: entity.id,
                    isBusiness: entity.isBusiness,
                },
                updateFinancialAccount,
            );

            return previousValue;
        },
        onSuccess: async (updatedAccount) => {
            doFinancialAccountUpdate(updatedAccount, updateFinancialAccount);
            onSuccess?.();
            await queryClient.invalidateQueries({
                queryKey: [financialConnectionsQueryKey],
            });
        },
        onError: (_1, _2, oldEntity) => {
            if (oldEntity !== undefined) {
                doFinancialAccountUpdate(
                    {
                        ...account,
                        entity: oldEntity,
                        entityId: oldEntity?.id ?? null,
                        isBusiness: oldEntity?.isBusiness ?? null,
                    },
                    updateFinancialAccount,
                );
            }

            error("Failed to change account company");
        },
    });
}

export function useFinancialAccountRemovalMutation(
    account: FinancialAccount,
    onRemoved?: () => void,
) {
    return useMutation({
        mutationFn: () => financialAccountsRest.delete(account.id),
        onSuccess: () => {
            doFinancialAccountRemoval(account);
            onRemoved?.();
        },
    });
}

export function useAccountEnablingMutation(account: FinancialAccount) {
    const { updateFinancialAccount } = useConnectAccount();

    return useMutation({
        mutationFn: () => enableFinancialAccount(account.id),
        onSuccess: (updatedAccount) => {
            doFinancialAccountUpdate(
                {
                    ...updatedAccount,
                    integrationAccounts: account.integrationAccounts,
                },
                updateFinancialAccount,
            );
            queryClient.invalidateQueries({
                queryKey: [financialConnectionsQueryKey],
            });
        },
    });
}

export function useAccountDisablingMutation(
    account: FinancialAccount,
    onDisabled?: () => void,
) {
    const { updateFinancialAccount } = useConnectAccount();

    return useMutation({
        mutationFn: () => disableFinancialAccount(account.id),
        onSuccess: (updatedAccount) => {
            doFinancialAccountUpdate(
                {
                    ...updatedAccount,
                    integrationAccounts: account.integrationAccounts,
                },
                updateFinancialAccount,
            );
            queryClient.invalidateQueries({
                queryKey: [financialConnectionsQueryKey],
            });
            onDisabled?.();
        },
    });
}

export function useAccountMergingMutation(
    onSuccess?: (response: MergeFinancialAccountsResponse) => void,
) {
    const accounts = useFinancialAccounts();

    return useMutation({
        mutationFn: (data: MergeFinancialAccountsDto) =>
            mergeFinancialAccounts(data),

        onSuccess: (response, data) => {
            if (!data.dryRun) {
                const account = accounts.find(
                    (a) => a.id === data.accountToMergeId,
                );
                if (account) {
                    doFinancialAccountRemoval(account);
                }
                queryClient.invalidateQueries({
                    queryKey: [entitiesAccountsQueryKey],
                });
            }
            onSuccess?.(response);
        },
    });
}
