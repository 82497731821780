import React from "react";
import {
    CategoryAuditLogValue,
    EntityAuditLogValue,
    SplitAuditLogValue,
    AuditLog,
    AuditLogProperty,
} from "../../../../common/types/auditLog";
import { AuditLogCategoryContent } from "./AuditLogCategoryContent";
import { AuditLogEntityContent } from "./AuditLogEntityContent";
import { AuditLogSplitContent } from "./AuditLogSplitContent";

interface Props {
    log: AuditLog;
}

export const TransactionDetailsHistoryLogContent: React.FC<Props> = ({
    log,
}) => {
    if (log.property === AuditLogProperty.CATEGORY) {
        return (
            <AuditLogCategoryContent
                log={log}
                newValue={log.newValue as CategoryAuditLogValue}
            />
        );
    } else if (log.property === AuditLogProperty.SPLIT) {
        return (
            <AuditLogSplitContent
                log={log}
                newValue={log.newValue as SplitAuditLogValue}
            />
        );
    } else {
        return (
            <AuditLogEntityContent
                log={log}
                newValue={log.newValue as EntityAuditLogValue}
            />
        );
    }
};
