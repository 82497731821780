import React from "react";
import { PopupButton } from "react-calendly";
import { ONBOARDING_CALL_URL } from "../../../common/billing";

interface Props {
    className?: string;
    text: string;
}

export const CustomPricingCallButton: React.FC<Props> = ({
    className,
    text,
}) => (
    <PopupButton
        className={className}
        url={ONBOARDING_CALL_URL}
        rootElement={document.body}
        text={text}
    />
);
