import React from "react";
import styles from "./CustomPricingBanner.module.scss";
import { CustomPricingCallButton } from "./CustomPricingCallButton";

export const CustomPricingBanner: React.FC = () => (
    <section className={styles.banner}>
        <main>
            <h6 className="mb-2 font-weight-medium">
                Congrats! You qualify for custom pricing.
            </h6>
            <p>
                Book a personalized onboarding call with a product expert to get
                your books handled properly as you grow.
            </p>

            <CustomPricingCallButton
                className="btn btn-sm btn-primary"
                text="Book a call"
            />
        </main>
        <aside>
            <img src="/images/conrad.png" alt="Conrad" />
            <img src="/images/echo.png" alt="Echo" />
            <img src="/images/thea.png" alt="Thea" />
        </aside>
    </section>
);
