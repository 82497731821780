import React, { RefObject } from "react";
import isNumber from "lodash/isNumber";
import { currencyFormatter } from "../../../common/helpers/currency";
import { GridTableRow } from "../../general/Tables/GridTable/GridTable";
import { GetCounterpartiesResponseRowDto } from "../../../common/dto/counterparty/get-counterparties-response.dto";
import { EllipsisWithTooltip } from "../../general/EllipsisWithTooltip/EllipsisWithTooltip";
import { Category } from "../../../common/types/category";
import { useCounterpartyTransactionsLink } from "../../../hooks/useCounterpartyTransactionsOpener";
import { CounterpartyManagementDropdown } from "./CounterpartyManagementDropdown";
import { CounterpartyCategories } from "./CounterpartyCategories";
import styles from "./CounterpartiesList.module.scss";

interface Props {
    row: GetCounterpartiesResponseRowDto;
    showRevenue: boolean;
    showSpend: boolean;
    categoriesForDrilldown?: Category[];
}

export const CounterpartiesListItem: React.FC<Props> = ({
    row,
    showSpend,
    showRevenue,
    categoriesForDrilldown,
}) => {
    const transactionsLink = useCounterpartyTransactionsLink(row.counterparty, {
        includeDateFilter: true,
        categories: categoriesForDrilldown,
    });

    return (
        <GridTableRow>
            <EllipsisWithTooltip
                fullText={row.counterparty.name}
                tooltipId={`counterparty-name-${row.counterparty.id}`}
            >
                {(ref) => (
                    <div>
                        <div
                            className={styles.name}
                            ref={ref as RefObject<HTMLDivElement>}
                        >
                            {row.counterparty.name}
                        </div>
                    </div>
                )}
            </EllipsisWithTooltip>
            <div>
                {row.usageStats && (
                    <CounterpartyCategories
                        categories={row.usageStats?.categories}
                        tooltipPrefix={`categories_${row.counterparty.id}`}
                    />
                )}
            </div>
            <div className={styles.transactionsCount}>
                {row.usageStats?.transactionsCount ? (
                    <a
                        href={transactionsLink}
                        target="_blank"
                        className={styles.transactionsLink}
                    >
                        {row.usageStats.transactionsCount}
                    </a>
                ) : (
                    row.usageStats?.transactionsCount
                )}
            </div>
            {showRevenue && (
                <div className={styles.revenue}>
                    {isNumber(row.usageStats?.revenue)
                        ? currencyFormatter.format(row.usageStats.revenue)
                        : null}
                    {!showSpend && (
                        <CounterpartyManagementDropdown
                            counterparty={row.counterparty}
                            className={styles.managementDropdown}
                            hasTransactions={
                                !!row.usageStats?.transactionsCount
                            }
                        />
                    )}
                </div>
            )}
            {showSpend && (
                <div className={styles.spend}>
                    {isNumber(row.usageStats?.spend)
                        ? currencyFormatter.format(row.usageStats.spend)
                        : null}
                    <CounterpartyManagementDropdown
                        counterparty={row.counterparty}
                        className={styles.managementDropdown}
                        hasTransactions={!!row.usageStats?.transactionsCount}
                    />
                </div>
            )}
        </GridTableRow>
    );
};
