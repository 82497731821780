import { WORKSPACE_ADMIN_ROLES } from "../../common/constants";
import { useActiveWorkspaceRole } from "./useActiveWorkspaceRole";

export function useCanManageWorkspaceMembers() {
    const role = useActiveWorkspaceRole();

    if (!role) {
        return false;
    }

    return WORKSPACE_ADMIN_ROLES.includes(role);
}
