/* eslint-disable no-var */
import "./polyfil";
import "reflect-metadata";

import React from "react";
import { createRoot } from "react-dom/client";
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en";
import * as Sentry from "@sentry/react";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import * as am4core from "@amcharts/amcharts4/core";
import { browserTracingIntegration } from "@sentry/browser";
import TagManager from "react-gtm-module";
import { App } from "./App";
import { getEnv, initEnvironment } from "./lib/environment";
import { queryClient } from "./queryClient";
import { WorkspaceProvider } from "./state/workspaceContext";
import { intercom } from "./lib/intercom";
import { setupIntercom } from "./lib/setupIntercom";

initEnvironment();

if (getEnv("VITE_SENTRY_ENABLED") === "true") {
    Sentry.init({
        dsn: getEnv("VITE_SENTRY_DSN"),
        environment: getEnv("NODE_ENV"),
        integrations: [browserTracingIntegration()],
        tracesSampleRate: 0.2,
        normalizeDepth: 6,
        beforeSend(event: Sentry.ErrorEvent, hint: Sentry.EventHint) {
            if ((hint.originalException as any)?.code === "ERR_CANCELED") {
                return null;
            }

            return event;
        },
    });
}

if (getEnv("VITE_SEGMENT_ENABLED") === "true") {
    window.analytics = window.analytics || [];
    // @ts-ignore
    // prettier-ignore
    // eslint-disable-next-line @typescript-eslint/no-shadow,no-console, @typescript-eslint/no-unused-expressions, prefer-rest-params
    /* nosonar */ if(!analytics.initialize){if(analytics.invoked){window.console&&console.error&&console.error("Segment snippet included twice.");}else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";}}
    // @ts-ignore
    window.analytics.load(getEnv("VITE_SEGMENT_KEY"));
}

if (getEnv("VITE_INTERCOM_APP_ID")) {
    setupIntercom();

    intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: getEnv("VITE_INTERCOM_APP_ID"),
        hide_default_launcher: true,
        custom_launcher_selector: "#intercom-launcher",
        alignment: "left",
        vertical_padding: 56,
        horizontal_padding: 12,
        action_color: "#515D71", // gray-700
        background_color: "white",
    });
}

if (getEnv("VITE_AMCHARTS_LICENSE")) {
    am4core.addLicense(getEnv("VITE_AMCHARTS_LICENSE"));
}

const hotjarId = getEnv("VITE_HOTJAR_ID");
if (hotjarId && /^\d+$/.exec(hotjarId)) {
    // @ts-ignore
    // prettier-ignore
    // eslint-disable-next-line
    /*nosonar*/ (function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:hotjarId,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
}

const gtmId = getEnv("VITE_GTM_ID");

if (gtmId?.startsWith("GTM-")) {
    TagManager.initialize({ gtmId });
}

const cioSiteId = getEnv("VITE_CIO_SITE_ID");

if (cioSiteId) {
    // prettier-ignore
    (function() {
        // @ts-ignore
        // prettier-ignore
        // eslint-disable-next-line
        /*nosonar*/ window._cio=window._cio||[];!function(){for(a=0,t=function(t){return function(){window._cio.push([t].concat(Array.prototype.slice.call(arguments,0)))}},e=["load","identify","sidentify","track","page","on","off"];a<e.length;a++)window._cio[e[a]]=t(e[a]);var t,e,a,r=document.createElement("script"),s=document.getElementsByTagName("script")[0];r.async=!0,r.id="cio-tracker",r.setAttribute("data-site-id",cioSiteId),r.setAttribute("data-use-array-params","true"),r.setAttribute("data-use-in-app","true"),r.src="https://assets.customer.io/assets/track.js",s.parentNode.insertBefore(r,s)}();
    }())
}

const rootContainer = document.getElementById("root")!;
const root = createRoot(rootContainer);

root.render(
    <QueryClientProvider client={queryClient}>
        <LDProvider clientSideID={getEnv("VITE_LAUNCHDARKLY_CLIENT_SIDE_ID")}>
            <WorkspaceProvider>
                <App />
            </WorkspaceProvider>
        </LDProvider>
        <ReactQueryDevtools
            buttonPosition="bottom-right"
            initialIsOpen={false}
        />
    </QueryClientProvider>,
);
