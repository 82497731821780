import React, { useContext, useMemo, MouseEvent, useState } from "react";
import classNames from "classnames";
import {
    useFinancialAccounts,
    useTotalBalance,
} from "../../hooks/useFinancialAccounts";
import { EntityIcon } from "../entity/EntityIcon";
import { currencyFormatter } from "../../common/helpers/currency";
import { Collapsible } from "../general/Collapsible/Collapsible";
import { useEntity } from "../entity/useEntity";
import { SortableList } from "../general/SortableList/SortableList";
import { useLatestBalanceSyncTime } from "../../hooks/useLatestSyncTime";
import { AlertIcon, ClockIcon, TriangleIcon } from "../../icons";
import { timeAgo } from "../../helpers/date";
import { EntityManagementDropdown } from "../entity/EntityManagementDropdown";
import { useInteraction } from "../../hooks/useInteraction";
import { WorkspaceMember } from "../../common/types/workspace";
import { EntityRow } from "./EntityRow";
import { EntityAccount } from "./EntityAccount";
import "./Entity.scss";
import { EntityNoAccounts } from "./EntityNoAccounts";
import { AccountsDndContext } from "./AccountsDnd/AccountsDnd.context";
import { getEntityKey } from "./AccountsDnd/accountsDnd";
import { EntityDropArea } from "./EntityDropArea";
import { EntityTeam } from "./EntityTeam/EntityTeam";

interface Props {
    last?: boolean;
    members?: WorkspaceMember[];
}

export const Entity: React.FC<Props> = ({ last, members }) => {
    const entity = useEntity();
    const { entitiesWithAccounts, draggedAccount } =
        useContext(AccountsDndContext);
    const extendedAccounts = useMemo(
        () =>
            entitiesWithAccounts.find((ea) => ea.entity.id === entity.id)
                ?.accounts ?? [],
        [entitiesWithAccounts, entity],
    );

    const totalBalance = useTotalBalance(entity);
    const lastSyncedBalanceAt = useLatestBalanceSyncTime(extendedAccounts);
    const accounts = useFinancialAccounts({ entityId: entity.id });
    const hasAnyError = accounts.some(
        (a) =>
            !a.disabled &&
            a.integrationAccounts?.every((ia) => ia.connection?.error),
    );

    const hasAnyAccounts = Boolean(extendedAccounts.length);
    const listId = getEntityKey(entity.id);

    const actionsInteraction = useInteraction((e: MouseEvent) =>
        e.stopPropagation(),
    );
    const [menuOpened, setMenuOpened] = useState(false);
    const [accountsOpened, setAccountsOpened] = useState(false);

    return (
        <EntityDropArea id={listId}>
            {(isOver) => (
                <Collapsible
                    className={classNames("entity", {
                        "entity--drop-active": isOver,
                        "entity--menu-opened": menuOpened,
                        "entity--accounts-opened": accountsOpened,
                    })}
                    data-testid="entity-section"
                    stacked
                    middle={!last}
                    last={last}
                    onToggle={setAccountsOpened}
                    header={
                        <EntityRow
                            className="entity__header"
                            entityOrAccount={
                                <>
                                    <EntityIcon size="sm" entity={entity} />
                                    <span
                                        className="entity__name"
                                        data-testid="entity-name"
                                    >
                                        {entity.name}
                                    </span>
                                    <TriangleIcon className="entity__toggle" />
                                </>
                            }
                            team={
                                <EntityTeam
                                    entity={entity}
                                    members={members ?? []}
                                />
                            }
                            lastSync={
                                lastSyncedBalanceAt ? (
                                    <>
                                        {hasAnyError ? (
                                            <AlertIcon
                                                className="icon-color-red-500 mr-2"
                                                height={12}
                                            />
                                        ) : (
                                            <ClockIcon
                                                className="icon-color-current mr-2"
                                                height={12}
                                            />
                                        )}
                                        {timeAgo(lastSyncedBalanceAt)}
                                    </>
                                ) : null
                            }
                            balanceAndActions={
                                <div className="d-flex justify-content-between">
                                    <div className="entity__balance">
                                        {currencyFormatter.format(totalBalance)}
                                    </div>
                                    <div
                                        className="entity__actions"
                                        {...actionsInteraction}
                                    >
                                        <EntityManagementDropdown
                                            entity={entity}
                                            onToggle={setMenuOpened}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    }
                >
                    <div className="entity__accounts">
                        {!hasAnyAccounts && !draggedAccount && (
                            <div className="entity__no-accounts">
                                <EntityNoAccounts />
                            </div>
                        )}

                        <SortableList
                            items={extendedAccounts}
                            getItemId={(a) => a.id}
                            listId={listId}
                            renderItem={(item) => (
                                <EntityAccount
                                    account={item}
                                    sortableId={item.id}
                                />
                            )}
                        />
                    </div>
                </Collapsible>
            )}
        </EntityDropArea>
    );
};
