import classNames from "classnames";
import fileDownload from "js-file-download";
import { noop } from "lodash";
import React, { Fragment, useCallback, useContext, useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { CashFlowReportRowPath } from "../../common/dto/reports/cash-flow-tab-report-response.dto";
import { ArrowRightIcon, DownloadIcon } from "../../icons";
import { getTransactionsCsv } from "../../lib/transactions";
import { AccountsFilterDropdown } from "../general/AccountsFilter/AccountsFilterDropdown";
import { useEntityAccountsFilter } from "../general/AccountsFilter/useEntityAccountsFilter";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";
import { Card } from "../general/Card/Card";
import { DateRangeFilter } from "../general/DateRangeFilter/DateRangeFilter";
import { PaginatedTransactionsTableContext } from "../transactions/PaginatedTransactionsTableContextProvider";
import { convertFiltersToDto } from "../transactions/useTransactionsQuery";
import { AccountsFilterValueDisplay } from "../general/AccountsFilter/lib";
import { DateRange } from "../../common/helpers/date";
import { Button } from "../general/Button/Button";
import css from "./CashFlowDrillDownModalHeader.module.scss";

export interface CashFlowDrillDownModalHeaderProps {
    path?: CashFlowReportRowPath[];
}

export const CashFlowDrillDownModalHeader: React.FC<
    CashFlowDrillDownModalHeaderProps
> = ({ path = [] }) => {
    const [isExporting, setIsExporting] = useState(false);
    const { filters, setCurrentFilters } = useContext(
        PaginatedTransactionsTableContext,
    );

    const {
        entities,
        isAccountSelected,
        toggleAccount,
        toggleEntity,
        isEntitySelected,
        everythingSelected,
    } = useEntityAccountsFilter({
        value: filters.entityIds,
        accountIds: filters.accountIds,
        onChange: noop,
        excludeAllMockEntities: true,
        onlyBusiness: true,
    });

    const exportTransactions = useCallback(async () => {
        if (isExporting) {
            return;
        }

        setIsExporting(true);

        try {
            const response = await getTransactionsCsv(
                convertFiltersToDto(filters, entities),
            );

            fileDownload(response, `transactions.csv`, "text/csv");
        } finally {
            setIsExporting(false);
        }
    }, [isExporting, filters, entities]);

    const handleDateFilterChange = useCallback<
        (value: Partial<DateRange>) => void
    >(
        (value) =>
            setCurrentFilters((currentFilters) => ({
                ...currentFilters,
                ...value,
            })),
        [setCurrentFilters],
    );

    return (
        <Card className={classNames(css.filters, "mb-4")}>
            <ButtonGroup className="gap-2">
                <AccountsFilterDropdown
                    entities={entities}
                    everythingSelected={everythingSelected}
                    isAccountSelected={isAccountSelected}
                    isEntitySelected={isEntitySelected}
                    readOnly
                    showEntitiesWithoutAccounts
                    showSelectedIcons
                    singleEntityName
                    toggleAccount={toggleAccount}
                    toggleEntity={toggleEntity}
                    valueDisplay={AccountsFilterValueDisplay.ENTITY}
                />
                <Button
                    className={classNames(css.breadcrumbButton)}
                    variant="secondary"
                >
                    {path.map(({ label }, index) => (
                        <Fragment key={label}>
                            {index > 0 && (
                                <ArrowRightIcon className="icon-color-gray-500 icon-size-sm ml-1 mr-1" />
                            )}
                            <span>{label}</span>
                        </Fragment>
                    ))}
                </Button>
            </ButtonGroup>
            <section className={classNames(css.filters, "ml-auto")}>
                <DateRangeFilter
                    dateRange={{
                        start: filters.start,
                        end: filters.end,
                    }}
                    onChange={handleDateFilterChange}
                    monthOnly
                />
                <ButtonWithLoader
                    icon
                    onClick={exportTransactions}
                    loading={isExporting}
                    variant="secondary"
                >
                    <DownloadIcon />
                </ButtonWithLoader>
            </section>
        </Card>
    );
};
