import React from "react";
import { isArray } from "lodash";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useFinancialAccounts } from "../../../hooks/useFinancialAccounts";
import { getAccountNameWithLast4 } from "../../../common/helpers/financialAccount";
import { Tag } from "../../general/Tag/Tag";
import { TransactionRuleCondition } from "../../../common/types/transactionRule";

interface Props {
    condition: TransactionRuleCondition;
}

export const RuleFinancialAccountsCondition: React.FC<Props> = ({
    condition,
}) => {
    const ids = (
        isArray(condition.value) ? condition.value : [condition.value]
    ) as number[];
    const accounts = useFinancialAccounts();
    const relevantAccounts = accounts.filter((account) =>
        ids.includes(account.id),
    );

    if (relevantAccounts.length === 1) {
        return (
            <>
                From account{" "}
                <strong>
                    {getAccountNameWithLast4(relevantAccounts[0], 2)}
                </strong>
            </>
        );
    }

    if (relevantAccounts.length > 1) {
        const [firstAccount, ...otherAccounts] = relevantAccounts;

        return (
            <>
                From accounts{" "}
                <strong>{getAccountNameWithLast4(firstAccount, 2)},</strong>{" "}
                <OverlayTrigger
                    overlay={
                        <Tooltip id={`other-accounts-${condition.id}`}>
                            {otherAccounts
                                .map((a) => getAccountNameWithLast4(a, 2))
                                .join(", ")}
                        </Tooltip>
                    }
                >
                    <span>
                        <Tag variant="secondary" outline>
                            + {otherAccounts.length}
                        </Tag>
                    </span>
                </OverlayTrigger>
            </>
        );
    }

    return null;
};
