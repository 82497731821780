import React, { useCallback, useState } from "react";
import { ConfirmPaymentData, StripeError } from "@stripe/stripe-js";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import { getPricing } from "../../../lib/flatRateBilling";
import { SelectedPaymentMethod } from "./lib";
import { ModalWithPaymentSetupContent } from "./ModalWithPaymentSetupContent";
import { CurrentPlanSummary } from "./UpgradeSubscriptionModal/CurrentPlanSummary";
import { UpgradeModalFooter } from "./UpgradeSubscriptionModal/UpgradeModalFooter";

export interface Props {
    intentId?: string;
}

export const ConvertTrialModal: React.FC<Props> = ({ intentId }) => {
    const [paymentBusy, setPaymentBusy] = useState(false);
    const [error, setError] = useState<StripeError | null>(null);
    const [appliedDiscountCode, setAppliedDiscountCode] = useState<
        string | null
    >(null);
    const stripe = useStripe();
    const elements = useElements();

    const { plans, currentPlan } = useBillingStatus();

    const { data: pricing, isLoading } = useQuery({
        queryKey: ["pricing", currentPlan, appliedDiscountCode],
        queryFn: () =>
            getPricing({
                plan: currentPlan,
                discountCode: appliedDiscountCode ?? undefined,
            }),
        placeholderData: keepPreviousData,
    });

    const selectedPlan = plans.find((p) => p.plan === currentPlan)!;

    const handleConfirm = useCallback(
        async (selectedMethod: SelectedPaymentMethod) => {
            if (!stripe || !elements || !intentId) {
                return;
            }

            setPaymentBusy(true);
            setError(null);

            const afterCheckout = `${window.location.pathname}${window.location.search}`;

            const returnUrl = new URL(`${window.location.origin}/convert`);

            returnUrl.searchParams.set("after", afterCheckout);

            if (intentId && selectedMethod === SelectedPaymentMethod.NEW) {
                returnUrl.searchParams.set("intent", intentId);
            }

            if (appliedDiscountCode) {
                returnUrl.searchParams.set("code", appliedDiscountCode);
            }

            if (selectedMethod === SelectedPaymentMethod.EXISTING) {
                window.location.replace(returnUrl); // if using existing method, just checkout
                return;
            }

            const options: ConfirmPaymentData = {
                return_url: returnUrl.toString(),
            };

            const response = await stripe.confirmSetup({
                elements,
                confirmParams: options,
            });

            if (response.error) {
                setError(response.error);
            }

            setPaymentBusy(false);
        },
        [appliedDiscountCode, elements, intentId, stripe],
    );

    return (
        <ModalWithPaymentSetupContent
            isLoading={isLoading}
            busy={paymentBusy}
            onConfirm={handleConfirm}
            stripeError={error}
            header="Start subscription"
            submitText="Start subscription"
        >
            {pricing ? (
                <>
                    <div className="mb-4">
                        <CurrentPlanSummary
                            plan={selectedPlan}
                            isTrial={true}
                            billingDate={pricing.nextBillingDate}
                        />
                    </div>

                    <UpgradeModalFooter
                        billingDate={new Date()}
                        plan={selectedPlan}
                        pricing={pricing}
                        onDiscountCodeChange={setAppliedDiscountCode}
                        discountCode={appliedDiscountCode}
                        codeInvalid={pricing.invalidDiscountCode}
                    />
                </>
            ) : null}
        </ModalWithPaymentSetupContent>
    );
};
