import React, { useMemo } from "react";
import { LedgerBasedProfitLossResponseDto } from "../../../common/dto/reports/ledger-based-profit-loss-response.dto";
import { AccountingReportFilters } from "../types";
import { AccountingReportHeader } from "../AccountingReportHeader";
import { Card } from "../../general/Card/Card";
import { CardSection } from "../../general/Card/CardSection";
import { AccountingReportFooter } from "../AccountingReportFooter";
import { ExcelTableWrapper } from "../../excelExport/ExcelTableWrapper";
import { Entity } from "../../../common/types/entity";
import { AccountingReportContext } from "../AccountingReport.context";
import { ProfitLossTable } from "./ProfitLossTable";
import styles from "./ProfitLossPreviewContent.module.scss";
import { LedgerBasedProfitLossDownloadTable } from "./profitLossDownload/LedgerBasedProfitLossDownloadTable";

interface Props {
    profitLoss: LedgerBasedProfitLossResponseDto;
    filters: AccountingReportFilters;
    generationDate: Date;
    entity: Entity;
}

export const ProfitLossPreviewContent: React.FC<Props> = ({
    profitLoss,
    filters,
    generationDate,
    entity,
}) => {
    const { startDate, endDate } = filters;
    const accountingReportContextValue = useMemo(
        () => ({ filters }),
        [filters],
    );

    if (!entity) {
        return null;
    }

    return (
        <>
            <Card stacked className={styles.content}>
                <CardSection>
                    <AccountingReportHeader
                        entityName={entity.name ?? ""}
                        reportType="Profit & Loss"
                        startDate={startDate}
                        endDate={endDate}
                    />
                </CardSection>
                <AccountingReportContext.Provider
                    value={accountingReportContextValue}
                >
                    <ProfitLossTable report={profitLoss} />
                </AccountingReportContext.Provider>
                <AccountingReportFooter generationDate={generationDate} />
            </Card>
            <ExcelTableWrapper>
                <LedgerBasedProfitLossDownloadTable
                    report={profitLoss}
                    filters={filters}
                    generationDate={generationDate}
                    entity={entity}
                />
            </ExcelTableWrapper>
        </>
    );
};
