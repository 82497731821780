import { TransactionDto } from "../transactions/transaction.dto";
import { Action, ActionCampaign, ActionStatus } from "../../types/actions";
import { EntityDto } from "../entity/entity.dto";
import { TransactionWithCounterpartyDto } from "../transactions/transaction-with-counterparty.dto";

export class ActionDto {
    id!: string;
    campaign!: ActionCampaign;
    status!: ActionStatus;
    entity!: EntityDto;
    createdAt!: Date;
    updatedAt!: Date;
    transaction?: TransactionDto;
    meta: object;
    deduplicationKey: string;

    static deserialize({
        entity,
        transaction,
        createdAt,
        updatedAt,
        ...rest
    }: ActionDto): Action {
        return {
            createdAt: new Date(createdAt),
            updatedAt: new Date(updatedAt),
            entity: EntityDto.deserialize(entity),
            transaction: transaction
                ? TransactionWithCounterpartyDto.deserialize(transaction)
                : undefined,
            ...rest,
        };
    }

    constructor(action: Action) {
        this.id = action.id;
        this.campaign = action.campaign;
        this.status = action.status;
        this.entity = new EntityDto(action.entity);
        this.createdAt = action.createdAt;
        this.updatedAt = action.updatedAt;
        this.transaction = action.transaction
            ? new TransactionWithCounterpartyDto(action.transaction)
            : undefined;
        this.meta = action.meta;
        this.deduplicationKey = action.deduplicationKey;
    }
}
