import React, { memo, useState } from "react";
import classNames from "classnames";
import { Account } from "../../../common/types/domains/accounting/account";
import {
    AccountTransactions,
    AccountTransactionsLine,
} from "../../../common/types/domains/accounting/accountTransactions";
import { accountingNumberFormatter } from "../../../common/helpers/number";
import { GeneralLedgerTableTransaction } from "./GeneralLedgerTableTransaction";

interface Props {
    account: Account;
    isAccountIncreasingByDebit: boolean;
    transactions: AccountTransactions;
    onSourceClick: (transaction: AccountTransactionsLine) => void;
    onReferenceClick: (transaction: AccountTransactionsLine) => void;
}

export const GeneralLedgerTableSection: React.FC<Props> = memo(
    ({
        account,
        isAccountIncreasingByDebit,
        transactions,
        onSourceClick,
        onReferenceClick,
    }) => {
        const [expanded, setExpanded] = useState(true);

        return (
            <>
                <tr className="data-table__row--depth-0">
                    <td
                        onClick={() => setExpanded(!expanded)}
                        className={classNames(
                            "data-table__cell data-table__cell--expandable data-table__cell--string-col data-table__cell--bold",
                            {
                                "data-table__cell--expanded": expanded,
                            },
                        )}
                        colSpan={6}
                    >
                        {account.code} - {account.name}
                    </td>
                    <td
                        onClick={() => setExpanded(!expanded)}
                        className="data-table__cell"
                    />
                    <td
                        onClick={() => setExpanded(!expanded)}
                        className="data-table__cell data-table__cell--bold"
                    >
                        {!expanded &&
                            accountingNumberFormatter(
                                transactions.closingBalance,
                            )}
                    </td>
                </tr>
                {expanded && (
                    <>
                        <tr className="data-table__row--depth-1">
                            <td className="data-table__cell data-table__cell--string-col data-table__cell--bold">
                                Beginning Balance
                            </td>
                            <td className="data-table__cell" colSpan={5} />
                            <td className="data-table__cell" />
                            <td className="data-table__cell data-table__cell--bold">
                                {accountingNumberFormatter(
                                    transactions.openingBalance,
                                )}
                            </td>
                        </tr>
                        {transactions.transactions.map((entry) => (
                            // for now using composite key as we don't pass JE's id
                            <GeneralLedgerTableTransaction
                                key={`${entry.referenceNumber}-${entry.accountCode}-${entry.balance}`}
                                transaction={entry}
                                isAccountIncreasingByDebit={
                                    isAccountIncreasingByDebit
                                }
                                onSourceClick={onSourceClick}
                                onReferenceClick={onReferenceClick}
                            />
                        ))}
                        <tr className="data-table__row--summary-section data-table__row--depth-1">
                            <td className="data-table__cell data-table__cell--string-col">
                                Ending Balance
                            </td>
                            <td className="data-table__cell" colSpan={5} />
                            <td className="data-table__cell" />
                            <td className="data-table__cell">
                                {accountingNumberFormatter(
                                    transactions.closingBalance,
                                )}
                            </td>
                        </tr>
                    </>
                )}
            </>
        );
    },
);
