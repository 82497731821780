import { useMutation } from "@tanstack/react-query";
import { removeIntegrationAccount } from "../lib/integrationAccount";
import { IntegrationAccount } from "../common/types/integrationAccount";

export function useIntegrationAccountRemovalMutation(
    account: IntegrationAccount,
    onRemove?: () => void,
) {
    return useMutation({
        mutationFn: () => removeIntegrationAccount(account),
        onSuccess: () => {
            onRemove?.();
        },
    });
}
