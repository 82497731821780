import React, { useCallback, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { ManagementDropdown } from "../../general/ManagementDropdown/ManagementDropdown";
import { Loader } from "../../general/Loader";
import { Counterparty } from "../../../common/types/counterparty";
import { TransactionRuleConditions } from "../../transactionRules/TransactionRuleConditions";
import { TransactionRuleActions } from "../../transactionRules/TransactionRuleActions";
import { TransactionRule } from "../../../common/types/transactionRule";
import { Button } from "../../general/Button/Button";
import { TransactionRuleFormModal } from "./TransactionRuleFormModal";
import styles from "./TransactionRule.module.scss";
import { EditRuleModal } from "./EditRuleModal";
import { RuleRemoval } from "./RuleRemoval";

interface Props {
    rule: TransactionRule;
    counterparties: Counterparty[];
    transactionsCount: number;
}

export const RuleSettings: React.FC<Props> = ({
    rule,
    transactionsCount,
    ...rest
}) => {
    const [showEdit, setShowEdit] = useState(false);

    const showTransactions = useCallback(() => {
        window.open(
            `/transactions?filters={"transactionRulesIds":["${rule.id}"]}`,
        );
    }, [rule.id]);

    return (
        <>
            <div className="d-flex align-items-center">
                <div>
                    <TransactionRuleConditions rule={rule} {...rest} />
                </div>
            </div>
            <div className="d-flex align-items-center">
                <span>
                    <TransactionRuleActions rule={rule} />
                </span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
                <Button
                    variant="tertiary"
                    onClick={showTransactions}
                    className={styles.numberOfTransactions}
                >
                    {transactionsCount}
                </Button>
                <ManagementDropdown
                    id={`rule-${rule.id}`}
                    className={styles.managementDropdown}
                >
                    <Dropdown.Item onClick={() => setShowEdit(true)}>
                        Edit
                    </Dropdown.Item>
                    <RuleRemoval
                        rule={rule}
                        showTransactions={showTransactions}
                        transactionsCount={transactionsCount}
                    >
                        {({ onRemove, isRemoving }) => (
                            <Dropdown.Item
                                onClick={onRemove}
                                className="text-danger"
                            >
                                {isRemoving ? <Loader inline /> : "Delete"}
                            </Dropdown.Item>
                        )}
                    </RuleRemoval>
                </ManagementDropdown>
                <TransactionRuleFormModal
                    show={showEdit}
                    onHide={() => setShowEdit(false)}
                >
                    <EditRuleModal
                        rule={rule}
                        close={() => setShowEdit(false)}
                        {...rest}
                    />
                </TransactionRuleFormModal>
            </div>
        </>
    );
};
