import React, { useContext } from "react";
import { Col } from "react-bootstrap";
import classNames from "classnames";
import { useMutation } from "@tanstack/react-query";
import { PlusIcon } from "../../../icons";
import {
    getCounterpartyDirectionLabelForTransaction,
    isVerifiedCounterparty,
} from "../../../common/helpers/counterparties";
import { CounterpartySelect } from "../../counterparty/CounterpartySelect";
import { Transaction } from "../../../common/types/transaction";
import { Counterparty } from "../../../common/types/counterparty";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { useTypedFlags } from "../../../hooks/useTypedFlags";
import styles from "./TransactionDetailsCounterparty.module.scss";
import { TransactionDetailsContext } from "./transactionDetails.context";
import { TransactionDetailsField } from "./TransactionDetailsField";

interface Props {
    transaction: Transaction;
}

export const TransactionDetailsCounterparty: React.FC<Props> = ({
    transaction,
}) => {
    const { onUpdate } = useContext(TransactionDetailsContext);
    const counterpartyLabel =
        getCounterpartyDirectionLabelForTransaction(transaction);
    const counterpartyToShow =
        transaction.counterparty &&
        isVerifiedCounterparty(transaction.counterparty)
            ? transaction.counterparty
            : null;

    const updateCounterparty = useMutation({
        mutationFn: async (counterparty?: Counterparty) => {
            if (counterparty) {
                await onUpdate({ counterpartyId: counterparty.id });
            }
        },
    });

    const { directory: isDirectoryEnabled } = useTypedFlags();

    if (!counterpartyToShow && !isDirectoryEnabled) {
        return null;
    }

    return (
        <Col md={12}>
            <TransactionDetailsField
                className={styles.container}
                label={counterpartyLabel}
                value={
                    <span className={styles.valueContainer}>
                        {counterpartyToShow?.name}
                        {isDirectoryEnabled && (
                            <CounterpartySelect
                                onChange={updateCounterparty.mutate}
                                dropdownKey={`transaction_counterparty_${transaction.id}`}
                                allowToCreateNew
                                forTransactionEditing
                            >
                                <ButtonWithLoader
                                    variant="secondary"
                                    size="sm"
                                    loading={updateCounterparty.isPending}
                                    className={classNames({
                                        [styles.editButton]: counterpartyToShow,
                                    })}
                                >
                                    {counterpartyToShow ? (
                                        "Edit"
                                    ) : (
                                        <>
                                            <PlusIcon /> Add
                                        </>
                                    )}
                                </ButtonWithLoader>
                            </CounterpartySelect>
                        )}
                    </span>
                }
            />
        </Col>
    );
};
