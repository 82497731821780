import React from "react";

export interface MatchedFilterLabelProps {
    filters: {
        isMatched?: boolean;
        isNotMatched?: boolean;
    };
}

export const MatchedFilterLabel: React.FC<MatchedFilterLabelProps> = ({
    filters: { isMatched, isNotMatched },
}) => {
    if (isMatched && !isNotMatched) {
        return <>Matched</>;
    }

    if (!isMatched && isNotMatched) {
        return <>Not matched</>;
    }
};
