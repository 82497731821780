import { UseMutationResult } from "@tanstack/react-query";
import { useState } from "react";
import classNames from "classnames";
import { FinancialAccount } from "../../../../common/types/financialAccount";
import { AccountCard } from "../../../general/AccountCard/AccountCard";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import { FormRowRaw } from "../../../forms/FormRowRaw";
import { Button } from "../../../general/Button/Button";
import { FormCheckbox } from "../../../forms/FormCheckbox/FormCheckbox";
import { checkIfEnteredAccountNameIsValidForConfirmation } from "../../../../common/helpers/financialAccount";
import {
    StandardModalBody,
    StandardModalHeader,
} from "../../../general/Modal/Modal";
import styles from "./RemoveFinancialAccount.module.scss";

interface RemoveAccountAttentionContentProps {
    account: FinancialAccount;
    back?: () => void;
    removeAccountMutation: UseMutationResult<void, unknown, void, unknown>;
}

export function RemoveAccountConfirmationContent({
    account,
    back,
    removeAccountMutation,
}: RemoveAccountAttentionContentProps) {
    const [accountName, setAccountName] = useState("");
    const [understood, setUnderstood] = useState(false);

    return (
        <>
            <StandardModalHeader>
                Please confirm your decision
            </StandardModalHeader>
            <StandardModalBody>
                <p className={styles.modalText}>
                    To remove your account, enter the full name of the account
                    and check the box below.
                </p>
                <AccountCard
                    className={styles.accountName}
                    accountNameConfirmationMode
                    account={account}
                />
                <FormRowRaw
                    value={accountName}
                    onChange={(value) => setAccountName(value)}
                />
                <FormCheckbox
                    small
                    label="I understand this will remove the account and all of its historical data. "
                    value={accountName}
                    isChecked={understood}
                    handleChange={() => setUnderstood(!understood)}
                    className={styles.removeCheckbox}
                />
                <p className={styles.warning}>
                    Warning: This action cannot be undone.
                </p>
                <footer
                    className={classNames(
                        "d-flex",
                        back
                            ? "justify-content-between"
                            : "justify-content-end",
                    )}
                >
                    {back && (
                        <Button
                            variant="secondary"
                            className="mr-2"
                            size="lg"
                            data-testid="confirmation-modal-no-button"
                            onClick={back}
                        >
                            Back
                        </Button>
                    )}
                    <ButtonWithLoader
                        disabled={
                            !understood ||
                            !checkIfEnteredAccountNameIsValidForConfirmation(
                                accountName,
                                account,
                            )
                        }
                        mutation={removeAccountMutation}
                        size="lg"
                        data-testid="confirmation-modal-yes-button"
                        variant="danger"
                    >
                        Remove account
                    </ButtonWithLoader>
                </footer>
            </StandardModalBody>
        </>
    );
}
