import React, { useEffect, useRef, useState } from "react";
import { Tab } from "react-bootstrap";
import "./Settings.scss";
import Scrollbars from "react-custom-scrollbars-2";
import classNames from "classnames";
import {
    CogwheelIcon,
    IntegrationsIcon,
    LockIcon,
    ReceiptIcon,
} from "../../icons";
import { useEntities } from "../../hooks/useEntities";
import { NavigationItem } from "../navigation/NavigationItem";
import { NavigationMenu } from "../navigation/NavigationMenu";
import { EntitiesNavigation } from "../navigation/EntitiesNavigation";
import { useShowWorkspaceSettings } from "../../hooks/useShowWorkspaceSettings";
import { CommandsProvider } from "../commands/CommandsProvider";
import { IntegrationsSettings } from "./Integrations/IntegrationsSettings";
import { GeneralSettings } from "./General/GeneralSettings";
import { SecuritySettings } from "./Security/SecuritySettings";
import {
    EntitySettingsPages,
    UserSettingsPages,
    useSettings,
} from "./SettingsProvider";
import { ReceiptsSettings } from "./Receipts/ReceiptsSettings";
import { EntityPanes } from "./EntityPanes";
import { SettingsNavLink } from "./SettingsNavLink";
import { EntitySettingsNavigation } from "./EntitySettingsNavigation";
import { getEntityPaneKey } from "./lib";
import "./SettingsWidget.scss";
import { WorkspaceSettingsNavigation } from "./WorkspaceSettingsNavigation";
import { WorkspacePanes } from "./WorkspacePanes";

export const Settings: React.FC = () => {
    const { open, path, entityId } = useSettings();
    const entities = useEntities();
    const [openedEntity, setOpenedEntity] = useState<number>();

    useEffect(() => {
        if (entityId) {
            setOpenedEntity(entityId);
        }
    }, [entityId]);

    const scrollbarsRef = useRef<Scrollbars>(null);

    const showWorkspaceSettings = useShowWorkspaceSettings();

    return (
        <Tab.Container
            mountOnEnter
            unmountOnExit
            activeKey={path[0]}
            onSelect={(key) => open([key!])}
        >
            <CommandsProvider>
                <div className={classNames("settings", `settings--${path[0]}`)}>
                    <aside className="settings__navigation">
                        <Scrollbars>
                            <div className="settings__navigation__content">
                                <header className="settings__navigation__header">
                                    My Account
                                </header>

                                <NavigationMenu className="flex-column">
                                    <SettingsNavLink
                                        eventKey={UserSettingsPages.GENERAL}
                                    >
                                        <NavigationItem icon={<CogwheelIcon />}>
                                            Profile
                                        </NavigationItem>
                                    </SettingsNavLink>
                                    <SettingsNavLink
                                        eventKey={UserSettingsPages.RECEIPTS}
                                    >
                                        <NavigationItem icon={<ReceiptIcon />}>
                                            Receipts
                                        </NavigationItem>
                                    </SettingsNavLink>
                                    <SettingsNavLink
                                        eventKey={
                                            UserSettingsPages.INTEGRATIONS
                                        }
                                    >
                                        <NavigationItem
                                            icon={<IntegrationsIcon />}
                                        >
                                            Integrations
                                        </NavigationItem>
                                    </SettingsNavLink>
                                    <SettingsNavLink
                                        eventKey={UserSettingsPages.SECURITY}
                                    >
                                        <NavigationItem icon={<LockIcon />}>
                                            Security
                                        </NavigationItem>
                                    </SettingsNavLink>
                                </NavigationMenu>

                                {showWorkspaceSettings && (
                                    <WorkspaceSettingsNavigation />
                                )}

                                <EntitiesNavigation
                                    isEntityActive={(entity) =>
                                        entityId === entity.id
                                    }
                                    onSelectEntity={(entity) =>
                                        open([
                                            getEntityPaneKey(
                                                EntitySettingsPages.ACCOUNTS,
                                                entity,
                                            ),
                                        ])
                                    }
                                    renderNavigation={(entity) => (
                                        <EntitySettingsNavigation
                                            entity={entity}
                                        />
                                    )}
                                    openedEntity={openedEntity}
                                    setOpenedEntity={setOpenedEntity}
                                />
                            </div>
                        </Scrollbars>
                    </aside>

                    <div className="settings__content__panel">
                        <Scrollbars ref={scrollbarsRef}>
                            <main className="settings__content">
                                <Tab.Content>
                                    <Tab.Pane
                                        eventKey={UserSettingsPages.GENERAL}
                                        title="General"
                                    >
                                        <GeneralSettings />
                                    </Tab.Pane>

                                    <Tab.Pane
                                        eventKey={UserSettingsPages.RECEIPTS}
                                        title="Receipts"
                                    >
                                        <ReceiptsSettings />
                                    </Tab.Pane>

                                    <Tab.Pane
                                        eventKey={
                                            UserSettingsPages.INTEGRATIONS
                                        }
                                        title="Integrations"
                                    >
                                        <IntegrationsSettings
                                            scrollbars={scrollbarsRef}
                                        />
                                    </Tab.Pane>

                                    <Tab.Pane
                                        eventKey={UserSettingsPages.SECURITY}
                                        title="Security"
                                    >
                                        <SecuritySettings />
                                    </Tab.Pane>

                                    {showWorkspaceSettings && (
                                        <WorkspacePanes />
                                    )}

                                    {entities.map((entity) => (
                                        <EntityPanes
                                            entity={entity}
                                            scrollbars={scrollbarsRef}
                                            key={entity.id}
                                        />
                                    ))}
                                </Tab.Content>
                            </main>
                        </Scrollbars>
                    </div>
                </div>
            </CommandsProvider>
        </Tab.Container>
    );
};
