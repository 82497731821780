import React from "react";
import classNames from "classnames";
import { OnboardingStepKey, onboardingStepsConfigs } from "../steps";
import { StepConfig } from "../types";
import styles from "./OnboardingLayout.module.scss";

interface Props {
    currentStep: OnboardingStepKey;
    nextStep?: OnboardingStepKey;
    main: React.ReactNode;
    sidebar?: React.ReactNode;
    isExiting?: boolean;
}

const coverClassMap = {
    sidebar: styles.coverSidebar,
    split: styles.coverSplit,
    full: styles.coverFull,
    none: styles.coverNone,
} as const satisfies Record<StepConfig["cover"], string>;

export const OnboardingLayout: React.FC<Props> = ({
    currentStep,
    nextStep,
    main,
    sidebar,
    isExiting,
}) => {
    const currentStepConfig: StepConfig = onboardingStepsConfigs[currentStep];
    const nextStepConfig: StepConfig | null = nextStep
        ? onboardingStepsConfigs[nextStep]
        : null;

    const preserveSidebar =
        currentStepConfig.sidebar &&
        nextStepConfig?.sidebar === currentStepConfig.sidebar;

    return (
        <div
            className={classNames(
                styles.layout,
                coverClassMap[currentStepConfig.cover],
                {
                    [styles.isExiting]: isExiting,
                    [styles.preserveSidebar]: preserveSidebar,
                },
            )}
        >
            <main className={styles.main}>{main}</main>
            <aside className={styles.sidebar}>{sidebar}</aside>
        </div>
    );
};
