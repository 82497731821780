import React from "react";
import { BaseFilterProps } from "../../lib";
import { FormCheckbox } from "../../../../forms/FormCheckbox/FormCheckbox";
import styles from "./NoteFilter.module.scss";

export const NoteFilter: React.FC<BaseFilterProps> = ({
    filters,
    onChange,
}) => (
    <section className={styles.direction}>
        <header className={styles.header}>Note</header>

        <FormCheckbox
            value="all"
            label="All"
            isChecked={[
                filters.hasNote,
                filters.noNote,
                filters.requiresNote,
            ].every((value) => !value)}
            handleChange={() =>
                onChange({
                    hasNote: undefined,
                    noNote: undefined,
                    requiresNote: undefined,
                })
            }
            small
        />
        <FormCheckbox
            value="some"
            label="Has note"
            isChecked={!!filters.hasNote}
            handleChange={() =>
                onChange({ hasNote: filters.hasNote ? undefined : true })
            }
            small
        />
        <FormCheckbox
            value="some"
            label="No note"
            isChecked={!!filters.noNote}
            handleChange={() =>
                onChange({ noNote: filters.noNote ? undefined : true })
            }
            small
        />
        <FormCheckbox
            value="some"
            label="Requires note"
            isChecked={!!filters.requiresNote}
            handleChange={() =>
                onChange({
                    requiresNote: filters.requiresNote ? undefined : true,
                })
            }
            small
        />
    </section>
);
