import React, { useCallback } from "react";
import { useUser } from "../../hooks/useUser";
import { useEntities } from "../../hooks/useEntities";
import { Entity } from "../../common/types/entity";
import { completeOnboarding } from "../../lib/onboarding";
import { useFetchAsset } from "../../hooks/useFetchAsset";
import { usePartnerStackCookies } from "./usePartnerStackCookies";
import { OnboardingContainer } from "./components/OnboardingContainer";
import { RegistrationFlow } from "./flows/RegistrationFlow";
import { PrimaryFlow } from "./flows/PrimaryFlow";
import { OnboardingLayout } from "./layout/OnboardingLayout";
import { Outro } from "./steps/Outro/Outro";
import { PRODUCT_PREVIEW_URL } from "./constants";
import { InviteFlow } from "./flows/InviteFlow";

export const Onboarding: React.FC = () => {
    usePartnerStackCookies();
    const user = useUser();
    const businessEntity = useEntities({
        onlyBusiness: true,
        excludeEmptyMockEntities: false,
    })[0] as Entity | undefined;

    const handleFinishOnboarding = useCallback(async () => {
        await completeOnboarding();
        window.location.replace("/");
    }, []);

    useFetchAsset(PRODUCT_PREVIEW_URL);

    if (!user) {
        return (
            <OnboardingContainer>
                <RegistrationFlow />
            </OnboardingContainer>
        );
    }

    if (businessEntity && !businessEntity.onboardingComplete) {
        return (
            <OnboardingContainer>
                <PrimaryFlow
                    entity={businessEntity}
                    onFinish={handleFinishOnboarding}
                />
            </OnboardingContainer>
        );
    }

    if (businessEntity?.onboardingComplete && !user.preferredName) {
        return (
            <OnboardingContainer>
                <InviteFlow entity={businessEntity} />
            </OnboardingContainer>
        );
    }

    return (
        <OnboardingContainer>
            <OnboardingLayout
                currentStep="outro"
                main={<Outro onFinished={handleFinishOnboarding} />}
            />
        </OnboardingContainer>
    );
};
