import React from "react";
import { getDateRangeLabel } from "../../../helpers/date";

export interface DateFilterLabelProps {
    filters: {
        start?: Date;
        end?: Date;
    };
}
export const DateFilterLabel: React.FC<DateFilterLabelProps> = ({
    filters: { start, end },
}) => <>{getDateRangeLabel(start, end)}</>;
