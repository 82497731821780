import React from "react";
import classNames from "classnames";
import { isFunction } from "lodash";
import gridTable from "../../../../styles/grid-table.module.scss";
import { ChildrenProps } from "../../../../types";
import { Loader } from "../../Loader";
import { EmptyState, EmptyStateProps } from "../../EmptyState/EmptyState";
import styles from "./GridTable.module.scss";

interface GridTableProps {
    children: React.ReactNode;
    className?: string;
}

export const GridTable = ({ children, className }: GridTableProps) => (
    <section
        className={classNames(styles.container, gridTable.gridTable, className)}
    >
        {children}
    </section>
);

interface GridTableHeaderProps extends ChildrenProps {
    columns?: Array<{
        key: string;
        label: string | React.ReactNode;
    }>;
    columnBorders?: boolean;
    className?: string;
}

export const GridTableHeader: React.FC<GridTableHeaderProps> = ({
    columns,
    columnBorders,
    className,
    children,
}) => (
    <div
        className={classNames(styles.row, gridTable.headerRow, className, {
            [gridTable.columnBorders]: columnBorders,
        })}
    >
        {columns?.map((column) => <div key={column.key}>{column.label}</div>)}
        {children}
    </div>
);

export interface GridTableRowProps extends ChildrenProps {
    cells?: Array<{
        key: string;
        value: string | React.ReactNode;
        className?: string;
        onClick?: () => void;
    }>;
    onClick?: () => void;
    columnBorders?: boolean;
    className?: string;
    setNodeRef?: (node: HTMLElement | null) => void;
    style?: React.CSSProperties;
}

export const GridTableRow: React.FC<GridTableRowProps> = ({
    cells,
    onClick,
    columnBorders,
    className,
    children,
    setNodeRef,
    style,
}) => (
    <div
        ref={setNodeRef}
        className={classNames(
            styles.row,
            gridTable.row,
            onClick && styles.clickable,
            className,
            {
                [gridTable.columnBorders]: columnBorders,
            },
        )}
        style={style}
        onClick={onClick}
    >
        {cells?.map((cell) => (
            <div
                onClick={cell.onClick}
                key={cell.key}
                className={cell.className}
            >
                {isFunction(cell.value) ? cell.value() : cell.value}
            </div>
        ))}
        {children}
    </div>
);

export interface GridTableFallbackProps extends EmptyStateProps {
    dataCount?: number;
    loading?: boolean;
    showLoading?: boolean;
}

export const GridTableFallbacks: React.FC<GridTableFallbackProps> = ({
    dataCount = 0,
    loading = false,
    showLoading = false,
    ...rest
}) => (
    <>
        {dataCount === 0 && !loading && (
            <GridTableRow className={styles.emptyState}>
                <EmptyState {...rest} />
            </GridTableRow>
        )}
        {showLoading && loading && (
            <GridTableRow className={styles.emptyState}>
                <Loader />
            </GridTableRow>
        )}
    </>
);
