import React, { useCallback, useMemo, useRef, useState } from "react";
import { partition } from "lodash";
import Scrollbars from "react-custom-scrollbars-2";
import { useEntities } from "../../../hooks/useEntities";
import { FilterSearch } from "../FilterSearch/FilterSearch";
import { useCallbackWithFocus } from "../../../hooks/useCallbackWithFocus";
import { FormCheckbox } from "../../forms/FormCheckbox/FormCheckbox";
import { FilterActionsFooter } from "../Filter/FilterActionsFooter/FilterActionsFooter";
import styles from "./EntityFilter.module.scss";
import { EntityFilterItem } from "./EntityFilterItem";
import { EntityFilterProps } from "./types";

export const EntityFilterOverlay: React.FC<EntityFilterProps> = ({
    filters,
    onChange,
    onlyBusiness,
}) => {
    const [search, setSearch] = useState("");
    const searchRef = useRef<HTMLInputElement>();
    const entities = useEntities();

    const [personalEntities, businessEntities] = useMemo(() => {
        let filteredEntities = entities;
        if (search) {
            filteredEntities = entities.filter((entity) =>
                entity.name?.toLowerCase().includes(search.toLowerCase()),
            );
        }

        return partition(filteredEntities, (entity) => entity.isPersonal);
    }, [entities, search]);

    const isEntitySelected = useCallback(
        (entityId: number) => !!filters.entityIds?.includes(entityId),
        [filters.entityIds],
    );
    const toggleEntity = useCallback(
        (entityId: number) => {
            if (isEntitySelected(entityId)) {
                onChange({
                    entityIds: filters.entityIds?.filter(
                        (id) => id !== entityId,
                    ),
                });
            } else {
                onChange({
                    entityIds: [...(filters.entityIds || []), entityId],
                });
            }
        },
        [filters.entityIds, isEntitySelected, onChange],
    );

    const isPersonalEntitiesChecked = useCallback(
        () => personalEntities.every((entity) => isEntitySelected(entity.id)),
        [personalEntities, isEntitySelected],
    );

    const togglePersonalEntities = useCallback(() => {
        const currentSelectedBusinessEntities =
            filters.entityIds?.filter((entityId) =>
                businessEntities.some(({ id }) => id === entityId),
            ) || [];

        let newEntityIds: number[] | undefined;
        if (isPersonalEntitiesChecked()) {
            newEntityIds =
                currentSelectedBusinessEntities.length > 0
                    ? currentSelectedBusinessEntities
                    : undefined;
        } else {
            newEntityIds = [
                ...currentSelectedBusinessEntities,
                ...personalEntities.map(({ id }) => id),
            ];
        }

        onChange({ entityIds: newEntityIds });
    }, [
        filters.entityIds,
        isPersonalEntitiesChecked,
        onChange,
        businessEntities,
        personalEntities,
    ]);

    const isBusinessEntitiesChecked = useCallback(
        () => businessEntities.every((entity) => isEntitySelected(entity.id)),
        [businessEntities, isEntitySelected],
    );

    const toggleBusinessEntities = useCallback(() => {
        const currentSelectedPersonalEntities =
            filters.entityIds?.filter((entityId) =>
                personalEntities.some(({ id }) => id === entityId),
            ) || [];

        let newEntityIds: number[] | undefined;
        if (isBusinessEntitiesChecked()) {
            newEntityIds =
                currentSelectedPersonalEntities.length > 0
                    ? currentSelectedPersonalEntities
                    : undefined;
        } else {
            newEntityIds = [
                ...currentSelectedPersonalEntities,
                ...businessEntities.map(({ id }) => id),
            ];
        }

        onChange({ entityIds: newEntityIds });
    }, [
        businessEntities,
        personalEntities,
        filters.entityIds,
        onChange,
        isBusinessEntitiesChecked,
    ]);

    const resetFilter = useCallback(() => {
        onChange({ entityIds: undefined });
    }, [onChange]);

    const displayAnyEntities =
        personalEntities.length > 0 || businessEntities.length > 0;
    const isFilterActive = filters.entityIds && filters.entityIds?.length > 0;
    const isEverySelected = entities.length === filters.entityIds?.length;

    const handleToggleEntity = useCallbackWithFocus(toggleEntity, searchRef);
    const handleReset = useCallbackWithFocus(resetFilter, searchRef);

    return (
        <div>
            <FilterSearch
                value={search}
                onChange={setSearch}
                inputRef={searchRef}
                focus
            />

            <Scrollbars
                style={{ width: "100%" }}
                autoHeight
                autoHeightMax={400}
                autoHeightMin={80}
            >
                {displayAnyEntities ? (
                    <>
                        {businessEntities.length > 0 && (
                            <section className={styles.section}>
                                <FormCheckbox
                                    small
                                    value="business-entities"
                                    isChecked={isBusinessEntitiesChecked()}
                                    handleChange={() => {
                                        toggleBusinessEntities();
                                    }}
                                    className={styles.itemHeader}
                                    label={<header>Business</header>}
                                />

                                <div className={styles.list}>
                                    {businessEntities.map((entity) => (
                                        <EntityFilterItem
                                            key={entity.id}
                                            entity={entity}
                                            isSelected={isEntitySelected(
                                                entity.id,
                                            )}
                                            onToggle={handleToggleEntity}
                                        />
                                    ))}
                                </div>
                            </section>
                        )}

                        {onlyBusiness !== true &&
                            personalEntities.length > 0 && (
                                <section className={styles.section}>
                                    <FormCheckbox
                                        small
                                        value="personal-entities"
                                        isChecked={isPersonalEntitiesChecked()}
                                        handleChange={() => {
                                            togglePersonalEntities();
                                        }}
                                        className={styles.itemHeader}
                                        label={<header>Personal</header>}
                                    />

                                    <div className={styles.list}>
                                        {personalEntities.map((entity) => (
                                            <EntityFilterItem
                                                key={entity.id}
                                                entity={entity}
                                                isSelected={isEntitySelected(
                                                    entity.id,
                                                )}
                                                onToggle={handleToggleEntity}
                                            />
                                        ))}
                                    </div>
                                </section>
                            )}
                    </>
                ) : (
                    <p className={styles.empty}>No matching entities</p>
                )}
            </Scrollbars>

            {isFilterActive && !isEverySelected ? (
                <FilterActionsFooter
                    deselectFunction={handleReset}
                    selectedCount={filters.entityIds?.length || 0}
                />
            ) : null}
        </div>
    );
};
