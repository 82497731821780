import { getCookie, setCookie } from "../helpers/cookie";
import { LANDING_PAGE } from "../common/registrationCookies";

export function useLandingPageCookie() {
    if (getCookie(LANDING_PAGE)) {
        return;
    }

    setCookie(LANDING_PAGE, `${window.location.href}`);
}
