import React from "react";
import { SettingsSection } from "../SettingsSection";
import { ThunderboltIcon } from "../../../icons";
import { CustomPricingBanner } from "./CustomPricingBanner";

export const CustomPricingCall: React.FC = () => (
    <SettingsSection
        heading={
            <h4>
                Subscribe <ThunderboltIcon />
            </h4>
        }
    >
        <CustomPricingBanner />
    </SettingsSection>
);
