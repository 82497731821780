import React from "react";
import { Logo } from "../general/Logo";
import { LoginFlow } from "./LoginFlow/LoginFlow";
import "./Login.scss";

export const Login: React.FC = () => (
    <section className="login">
        <header>
            <Logo variant="full" />
        </header>
        <div className="login__content">
            <LoginFlow />
        </div>
    </section>
);
