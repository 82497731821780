import React from "react";
import { Form, useFormikContext } from "formik";
import classNames from "classnames";
import animation from "../layout/OnboardingLayout.module.scss";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { EntityProfileFormFields } from "../components/EntityProfileFormFields";
import commonStyles from "./common.module.scss";
import styles from "./Profile.module.scss";

interface Props {
    busy: boolean;
}

export const CreateEntityProfile: React.FC<Props> = ({ busy }) => {
    const { isValid } = useFormikContext();

    return (
        <div className={commonStyles.content420}>
            <header className={classNames(animation.fadeIn2)}>
                <h1 className={classNames(commonStyles.title, styles.header)}>
                    Create business profile
                </h1>
            </header>

            <Form className={classNames(animation.fadeIn1)}>
                <EntityProfileFormFields />

                <footer>
                    <ButtonWithLoader
                        type="submit"
                        variant="secondary"
                        loading={busy}
                        block
                        disabled={!isValid}
                    >
                        Create entity
                    </ButtonWithLoader>
                </footer>
            </Form>
        </div>
    );
};
