import React, { useCallback, useMemo, useRef } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { Form, useFormikContext } from "formik";
import classNames from "classnames";

import { EditableTableBody } from "../editableTable/EditableTableBody";
import { EditableTable } from "../editableTable/EditableTableContext";
import { useManualJournalAccounts } from "../../../hooks/useManualJournalAccounts";
import {
    StandardModalBody,
    StandardModalHeader,
} from "../../general/Modal/Modal";
import { useEntitiesWithAccountingAvailable } from "../useEntitiesWithAccountingAvailable";
import { Entity } from "../../../common/types/entity";
import { ChartOfAccountsAvailableVersions } from "../../../common/types/domains/accounting/accounts";
import { DefaultCashBasisAccountCodes as DefaultCashBasisAccountCodesV1 } from "../../../common/constants/domains/accounting/accountsv1";
import { DefaultCashBasisAccountCodes as DefaultCashBasisAccountCodesV2 } from "../../../common/constants/domains/accounting/accountsv2";
import { FormRowRaw } from "../../forms/FormRowRaw";
import { DatePickerInput } from "../../forms/dates/DatePickerInput/DatePickerInput";
import { EntitySelect } from "../../entity/EntitySelect";
import { EditableTableHeader } from "../editableTable/EditableTableHeader";
import { getShortJournalEntryReferenceNumber } from "../../../common/helpers/accounting";
import styles from "./JournalEntryModal.module.scss";
import { JournalEntryModalFormikConfig } from "./JournalEntryModal";
import { JournalEntryModalFooter } from "./JournalEntryModalFooter";
import {
    useAccountsSearchOptions,
    useIsManualJournalEntry,
    useUpdateAndReturnNewLineCallback,
} from "./JournalEntryModalHooks";

interface Props {
    setFormEntityId: (entityId: number) => void;
    formEntityId: number;
    loading: boolean;
    onDelete: () => void;
}

export const JournalEntryModalContent: React.FC<Props> = ({
    setFormEntityId,
    formEntityId,
    loading,
    onDelete,
}) => {
    const scrollbarsRef = useRef<Scrollbars>(null);
    const tableRef = useRef<HTMLDivElement>(null);

    const formikContext = useFormikContext<JournalEntryModalFormikConfig>();
    const { values, setFieldValue } = formikContext;

    const lines = values.journalEntry.lines;
    const memo = values.journalEntry.memo;

    const linesRef = React.useRef(lines);

    React.useEffect(() => {
        linesRef.current = lines;
    }, [lines]);

    const addLines = useCallback(() => {
        const currentLines = linesRef.current;
        const newLines = [
            ...currentLines,
            ...new Array(20).fill(null).map(() => ({
                id: Math.random().toString(),
                accountCode: "",
                debitAmount: null,
                creditAmount: null,
                description: "",
            })),
        ];
        setFieldValue(`journalEntry.lines`, newLines);
    }, [linesRef, setFieldValue]);

    const isManualJournal = useIsManualJournalEntry(values);

    const { accountTypesWithAccounts, chartOfAccountsVersion } =
        useManualJournalAccounts(formEntityId, isManualJournal);

    const accountsSearchOptions = useAccountsSearchOptions(
        accountTypesWithAccounts,
    );

    const updateAndReturnNewLine = useUpdateAndReturnNewLineCallback({
        isEditable: isManualJournal,
        accountsSearchOptions,
        memo,
        linesRef,
    });

    const availableEntities = useEntitiesWithAccountingAvailable();

    const selectedEntity = useMemo(
        () => availableEntities.find((e) => e.id === values.entityId)!,
        [availableEntities, values.entityId],
    );

    const onEntityChange = useCallback(
        (entity: Entity | undefined) => {
            if (!entity || !chartOfAccountsVersion) {
                return;
            }

            setFieldValue("entityId", entity.id);
            setFormEntityId(entity.id);

            const defaultCoA = new Set(
                Object.keys(
                    chartOfAccountsVersion ===
                        ChartOfAccountsAvailableVersions.V1
                        ? DefaultCashBasisAccountCodesV1
                        : DefaultCashBasisAccountCodesV2,
                ),
            );
            for (const [index, line] of values.journalEntry.lines.entries()) {
                if (line.accountCode.length === 0) {
                    return;
                }
                const isAccountInDefaultCoA = defaultCoA.has(line.accountCode);
                if (isAccountInDefaultCoA) {
                    continue;
                }
                setFieldValue(`journalEntry.lines.${index}.accountCode`, "");
            }
        },
        [
            values.journalEntry.lines,
            setFormEntityId,
            setFieldValue,
            chartOfAccountsVersion,
        ],
    );

    const isJournalEntryEditable = useIsManualJournalEntry(values);

    return (
        <EditableTable
            updateAndReturnNewLine={updateAndReturnNewLine}
            targetLines={lines}
        >
            <>
                <StandardModalHeader>
                    {values.journalEntryId
                        ? "Journal entry"
                        : "New journal entry"}
                </StandardModalHeader>
                <StandardModalBody className={styles.modalBody}>
                    <Form className={styles.form}>
                        <div className={classNames("p-4", styles.card)}>
                            {values.journalEntryId && (
                                <FormRowRaw
                                    value={getShortJournalEntryReferenceNumber(
                                        values.journalEntryId,
                                    )}
                                    size="sm"
                                    showErrorMessage={false}
                                    className={classNames(
                                        styles.headerFormRow,
                                        styles.headerJournalEntryId,
                                    )}
                                    disabled
                                />
                            )}
                            <DatePickerInput
                                value={values.journalEntry.date}
                                name="date"
                                onChange={(value) => {
                                    if (value) {
                                        setFieldValue(
                                            "journalEntry.date",
                                            value,
                                        );
                                    }
                                }}
                                inputSize="sm"
                                disabled={!isJournalEntryEditable}
                            />
                            <EntitySelect
                                entities={availableEntities}
                                selectedEntity={selectedEntity}
                                onChange={onEntityChange}
                                dropdownKey="accounting-report-entity-select"
                                className={styles.entitySelect}
                                size="sm"
                                disabled={
                                    !isJournalEntryEditable ||
                                    values.journalEntryId !== undefined
                                }
                            />
                            <FormRowRaw
                                placeholder={
                                    isJournalEntryEditable ? "Add Memo..." : ""
                                }
                                onChange={(value) =>
                                    setFieldValue("journalEntry.memo", value)
                                }
                                value={values.journalEntry.memo}
                                size="sm"
                                label="Memo"
                                isInline
                                className={classNames(
                                    styles.headerFormRow,
                                    styles.headerMemo,
                                )}
                                autoResize
                                autoResizeShrinkOnBlur
                                showErrorMessage={false}
                                disabled={!isJournalEntryEditable}
                            />
                        </div>
                        <EditableTableHeader />

                        <Scrollbars ref={scrollbarsRef}>
                            <EditableTableBody
                                setFieldValue={setFieldValue}
                                tableRef={tableRef}
                                scrollbarsRef={scrollbarsRef}
                                isEditable={isManualJournal}
                                searchOptions={accountsSearchOptions}
                            />
                        </Scrollbars>
                    </Form>
                </StandardModalBody>

                <JournalEntryModalFooter
                    loading={loading}
                    onDelete={onDelete}
                    addLines={addLines}
                />
            </>
        </EditableTable>
    );
};
