import React from "react";
import NumberFormat from "react-number-format";
import { FormRow } from "../forms/FormRow";
import { getInputClasses, InputSize } from "../../helpers/form";

interface Props {
    label?: string;
    fieldName?: string;
    size?: InputSize;
}

export const PhoneNumberInput: React.FC<Props> = ({
    label = "Phone number",
    fieldName = "phoneNumber",
    size,
}) => (
    <FormRow fieldName={fieldName} label={label}>
        {({ setValue }, { value }) => (
            <NumberFormat
                value={value}
                onValueChange={(v) => setValue(v.value)}
                format="(###) ###-####"
                mask="_"
                className={getInputClasses(size)}
            />
        )}
    </FormRow>
);
