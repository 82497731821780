import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Transaction } from "../common/types/transaction";

export function useShowTransactionDetails<
    T extends Pick<Transaction, "id" | "type">,
>() {
    const navigate = useNavigate();

    return useCallback(
        (transaction: T) => {
            navigate(
                `/transactions?transactionId=${transaction.id}&transactionType=${transaction.type}`,
            );
        },
        [navigate],
    );
}
