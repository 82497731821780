import { getBackendAPIClient } from "../lib/backendAPIClient";
import { authContract } from "../common/contracts/auth.contract";

export const authApiClient = getBackendAPIClient(authContract);

export async function login(emailOrPhoneNumber: string) {
    await authApiClient.login({
        body: {
            emailOrPhoneNumber,
        },
    });
}
