import React from "react";
import { CheckIcon } from "../../icons";
import { ChildrenProps } from "../../types";
import { ActionCardSection } from "./ActionCardSection";

interface Props extends ChildrenProps {
    header: string;
}

export const ActionCardCompleted: React.FC<Props> = ({ header, children }) => (
    <ActionCardSection
        icon={<CheckIcon width={16} height={16} />}
        header={header}
        isCompleted
    >
        {children}
    </ActionCardSection>
);
