import { useCallback, useEffect, useRef, useState } from "react";
import { EXIT_ANIMATION_DURATION } from "./constants";

export function useManageSteps<T>(isLoaded: boolean, getInitialStep: () => T) {
    const [forcedStep, setForcedStep] = useState<T | undefined>();
    const [nextStep, setNextStep] = useState<T | undefined>();

    const [isExiting, setIsExiting] = useState(false);
    const timerRef = useRef<number>();

    const currentStep = forcedStep || getInitialStep();

    const go = useCallback(
        (stepKey: T) => {
            if (stepKey === currentStep) {
                return;
            }

            setNextStep(stepKey);
            setIsExiting(true);

            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }

            timerRef.current = window.setTimeout(() => {
                window.scroll({ top: 0, behavior: "instant" });
                setForcedStep(stepKey);
                setNextStep(undefined);
                setIsExiting(false);
            }, EXIT_ANIMATION_DURATION);
        },
        [currentStep],
    );

    useEffect(() => {
        if (isLoaded) {
            setForcedStep(currentStep);
        }
    }, [currentStep, isLoaded]);

    return {
        isExiting,
        currentStep,
        nextStep,
        go,
    };
}
