import React from "react";
import { ConnectPlaid } from "../plaid/ConnectPlaid/ConnectPlaid";
import { useEntity } from "../entity/useEntity";
import { AddAccount } from "./AddAccount";

export const EntityNoAccounts: React.FC = () => {
    const entity = useEntity();

    return (
        <div
            className="text-center no-accounts d-flex flex-column align-items-center"
            data-testid="entity-no-accounts"
        >
            {!entity.isMockEntity ? (
                <p>
                    Add your {entity.isPersonal ? "personal" : "business"}{" "}
                    accounts to max out your tax savings.
                </p>
            ) : (
                <p>
                    Well done! All business accounts associated with entities in
                    Kick.
                </p>
            )}

            {!entity.isMockEntity && entity.isPersonal && (
                <ConnectPlaid
                    btnVariant="secondary"
                    btnText="Add account"
                    defaultEntity={entity}
                />
            )}
            {!entity.isMockEntity && !entity.isPersonal && (
                <AddAccount
                    btnText="Add account"
                    btnVariant="secondary"
                    defaultEntity={entity}
                />
            )}
        </div>
    );
};
