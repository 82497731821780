import React from "react";
import { Transaction } from "../../../common/types/transaction";
import { TransactionAmount } from "../TransactionAmount";
import { TransactionStatus } from "../TransactionStatus";

export interface TransactionDetailsHeaderProps {
    transaction: Transaction;
}

export const TransactionDetailsHeader: React.FC<
    TransactionDetailsHeaderProps
> = ({ transaction }) => (
    <div>
        <div className="d-flex justify-content-between align-items-start">
            <div className="transaction-details__description-container">
                <TransactionStatus transaction={transaction} className="mr-2" />
                <div
                    className="transaction-details__description"
                    data-testid="transaction-details-description"
                >
                    {transaction.displayDescription ??
                        transaction.bankDescription}
                </div>
            </div>
        </div>

        <TransactionAmount transaction={transaction} color addSign />
    </div>
);
