import React from "react";
import classNames from "classnames";
import { Transaction } from "../../common/types/transaction";
import { formatDateForTransaction } from "../../helpers/date";
import { TransactionAccountColumnContent } from "../transactions/ListItem/TransactionAccountColumnContent";
import { TransactionAmount } from "../transactions/TransactionAmount";
import styles from "./ActionCardTransaction.module.scss";
import { TransactionCategoryContent } from "./transactionContent/TransactionCategoryContent";
import { TransactionDescriptionContent } from "./transactionContent/TransactionDescriptionContent";
import { TransactionEntityContent } from "./transactionContent/TransactionEntityContent";

export enum AmountDisplayFormat {
    SIGN = "sign",
    COLOR = "color",
}

interface Props {
    transaction: Transaction;
    showEntity?: boolean;
    showCategory?: boolean;
    className?: string;
    amountDisplayFormat?: AmountDisplayFormat;
}

export const ActionCardTransaction: React.FC<Props> = ({
    transaction,
    showEntity,
    showCategory,
    className,
    amountDisplayFormat = AmountDisplayFormat.COLOR,
}) => (
    <div className={classNames(styles.transaction, className)}>
        <div>{formatDateForTransaction(transaction.date)}</div>
        <TransactionDescriptionContent transaction={transaction} />
        <TransactionAccountColumnContent transaction={transaction} size="xs" />
        {showCategory && (
            <TransactionCategoryContent transaction={transaction} />
        )}
        <TransactionAmount
            className={styles.transactionAmount}
            transaction={transaction}
            color={amountDisplayFormat === AmountDisplayFormat.COLOR}
            addSign={amountDisplayFormat === AmountDisplayFormat.SIGN}
        />
        {showEntity && <TransactionEntityContent transaction={transaction} />}
    </div>
);
