import { useMutation } from "@tanstack/react-query";
import { queryClient, mercuryConnectionsQueryKey } from "../queryClient";
import { createMercuryConnection, fixMercuryConnection } from "../lib/mercury";
import { MercuryConnection } from "../common/types/mercuryConnection";
import {
    FinancialConnection,
    FinancialConnectionWithAccounts,
} from "../common/types/financialConnection";
import { Entity } from "../common/types/entity";
import {
    addFinancialConnectionInQueryData,
    clearConnectionErrorInQueryData,
} from "../queries/entitiesAccounts";
import { useConnectAccount } from "../components/connectAccount/ConnectAccount.context";
import { useToaster } from "../components/general/ToastMessages/useToaster";

interface MercuryConnectionCreationPayload {
    code: string;
    defaultEntity?: Entity;
}
export function useMercuryConnectionCreationMutation(
    onSuccess?: (connection: FinancialConnectionWithAccounts) => void,
) {
    const { updateConnectAccountState } = useConnectAccount();
    const { toast } = useToaster();
    return useMutation({
        mutationFn: ({
            code,
            defaultEntity,
        }: MercuryConnectionCreationPayload) =>
            createMercuryConnection({
                code,
                defaultEntityId: defaultEntity?.id,
            }),
        onSuccess: (connection: FinancialConnectionWithAccounts) => {
            queryClient.setQueryData(mercuryConnectionsQueryKey, [
                ...(queryClient.getQueryData<MercuryConnection[]>(
                    mercuryConnectionsQueryKey,
                ) ?? []),
                connection,
            ]);
            addFinancialConnectionInQueryData(queryClient, connection);
            if (!connection.accounts[0].financialAccount.entity) {
                updateConnectAccountState({
                    isConnecting: false,
                    connection,
                    connectionSaved: true,
                    foreign: [],
                });
            } else {
                toast(`Mercury account connected`);
            }
            onSuccess?.(connection);
        },
    });
}
interface FixMercuryConnectionPayload {
    connection: FinancialConnection;
    code: string;
}
export function useFixMercuryConnectionsMutation(onSuccess?: () => unknown) {
    return useMutation({
        mutationFn: (payload: FixMercuryConnectionPayload) =>
            fixMercuryConnection(payload.connection.id, { code: payload.code }),
        onSuccess: (connected: FinancialConnectionWithAccounts) => {
            if (!connected) {
                return;
            }
            clearConnectionErrorInQueryData(queryClient, connected.id);
            onSuccess?.();
            queryClient.setQueryData<FinancialConnection[] | undefined>(
                mercuryConnectionsQueryKey,
                (connections) =>
                    connections?.map((c) =>
                        c.id === connected.id
                            ? {
                                  ...c,
                                  accounts: connected.accounts,
                                  error: null,
                              }
                            : c,
                    ) ?? [],
            );
        },
    });
}
