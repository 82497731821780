import React, { useMemo } from "react";
import { useField } from "formik";
import NumberFormat from "react-number-format";
import classNames from "classnames";
import { CustomSelect } from "../../../../forms/CustomSelect/CustomSelect";
import {
    AmountConditionType,
    TransactionRuleConditionFormSchema,
} from "../constants";
import styles from "../RuleForm.module.scss";
import { RuleConditionAmount } from "../../../../../common/types/transactionRule";

interface Props {
    condition: TransactionRuleConditionFormSchema;
    index: number;
}

const conditionTypesOptions = Object.values(AmountConditionType).map((v) => ({
    value: v,
}));
const isCreditOptions = [
    { value: "credit", label: "Cash in" },
    { value: "debit", label: "Cash out" },
];

export const AmountConditionForm: React.FC<Props> = ({ condition, index }) => {
    const [conditionTypeField, , conditionTypeHelpers] = useField<
        TransactionRuleConditionFormSchema["amountConditionType"]
    >(`conditions.${index}.amountConditionType`);
    const [isCreditField, , isCreditHelpers] = useField<
        TransactionRuleConditionFormSchema["amountIsCredit"]
    >(`conditions.${index}.amountIsCredit`);
    const [singleValueField, , singleValueHelpers] = useField<
        TransactionRuleConditionFormSchema["amountSingleValue"]
    >(`conditions.${index}.amountSingleValue`);
    const [valueRangeField, , valueRangeHelpers] = useField<
        TransactionRuleConditionFormSchema["amountValueRange"]
    >(`conditions.${index}.amountValueRange`);

    const isCreditDropdownValue = useMemo(() => {
        if (isCreditField.value !== undefined) {
            return isCreditField.value ? "credit" : "debit";
        }
    }, [isCreditField.value]);

    const setRangeValue = (
        value: number | undefined,
        key: keyof RuleConditionAmount,
    ) => {
        valueRangeHelpers.setValue({
            ...(valueRangeField.value ?? {}),
            [key]: value,
        });
    };

    return (
        <>
            <CustomSelect
                value={isCreditDropdownValue}
                onSelected={(v) => isCreditHelpers.setValue(v === "credit")}
                dropdownKey={`amountCreditOrDebit${condition.id}`}
                placeholder="Select cash in or out"
                size="xs"
                options={isCreditOptions}
            />
            <CustomSelect
                value={conditionTypeField.value}
                onSelected={(v) => conditionTypeHelpers.setValue(v)}
                dropdownKey={`amountConditionType${condition.id}`}
                placeholder="Select type"
                size="xs"
                options={conditionTypesOptions}
            />
            {(conditionTypeField.value === AmountConditionType.GREATER_THAN ||
                conditionTypeField.value === AmountConditionType.LESS_THAN) && (
                <NumberFormat
                    value={singleValueField.value}
                    onValueChange={(v) =>
                        singleValueHelpers.setValue(v.floatValue)
                    }
                    prefix="$"
                    decimalScale={2}
                    allowEmptyFormatting
                    thousandSeparator
                    allowNegative={false}
                    className={classNames(
                        "form-control",
                        "form-control-xs",
                        styles.amountInput,
                    )}
                />
            )}
            {conditionTypeField.value === AmountConditionType.BETWEEN && (
                <>
                    <NumberFormat
                        value={valueRangeField.value?.min}
                        onValueChange={(v) =>
                            setRangeValue(v.floatValue, "min")
                        }
                        prefix="$"
                        decimalScale={2}
                        allowEmptyFormatting
                        thousandSeparator
                        allowNegative={false}
                        className={classNames(
                            "form-control",
                            "form-control-xs",
                            styles.amountInput,
                        )}
                    />
                    and
                    <NumberFormat
                        value={valueRangeField.value?.max}
                        onValueChange={(v) =>
                            setRangeValue(v.floatValue, "max")
                        }
                        prefix="$"
                        decimalScale={2}
                        allowEmptyFormatting
                        thousandSeparator
                        allowNegative={false}
                        className={classNames(
                            "form-control",
                            "form-control-xs",
                            styles.amountInput,
                        )}
                    />
                </>
            )}
        </>
    );
};
