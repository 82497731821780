import React, { useCallback, useState } from "react";
import { PopoverContainer } from "../../general/PopoverContainer";
import {
    ClassFilterOverlay,
    ClassFilterOverlayProps,
} from "./ClassFilterOverlay";
import styles from "./styles.module.scss";
import { ClassFilterTrigger } from "./ClassFilterTrigger";
import "./ClassFilter.scss";

interface ClassFilterProps extends ClassFilterOverlayProps {
    onShow?: () => void;
    onHide?: () => void;
}

export const ClassFilter: React.FC<ClassFilterProps> = ({
    selectedClasses,
    onShow,
    onHide,
    ...rest
}) => {
    const [clickedClass, setClickedClass] = useState<string | null>(null);

    const renderButton = useCallback(
        (isShown: boolean) => (
            <ClassFilterTrigger
                onClassClick={isShown ? undefined : setClickedClass}
                value={selectedClasses}
            />
        ),
        [selectedClasses, setClickedClass],
    );

    return (
        <PopoverContainer
            id="accounts-filter"
            buttonClass="class-filter-button"
            popoverClass={styles.popover}
            renderButton={renderButton}
            offset={4}
            showArrow={false}
            onShow={onShow}
            onHide={onHide}
        >
            <ClassFilterOverlay
                selectedClasses={selectedClasses}
                scrollToClassOnOpen={clickedClass ?? undefined}
                useAutoHeight
                {...rest}
            />
        </PopoverContainer>
    );
};
