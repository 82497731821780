import React, { MutableRefObject, useMemo, useRef, useState } from "react";
import Scrollbars, { ScrollbarProps } from "react-custom-scrollbars-2";
import { Button } from "react-bootstrap";
import { FilterSearch } from "../FilterSearch/FilterSearch";
import { Entity } from "../../../common/types/entity";
import { AccountsFilterEntity } from "./AccountsFilterEntity";
import { AccountsFilterProps } from "./AccountsFilterDropdown";
import { AccountsFilterUICommonProps, EntityFilterSharedProps } from "./lib";

interface Props extends EntityFilterSharedProps, AccountsFilterUICommonProps {
    entities: Entity[];
    scrollbarProps?: ScrollbarProps;
}

export const AccountsFilterBody: React.FC<Props> = ({
    entities,
    isAccountSelected,
    toggleAccount,
    toggleEntity,
    showEntitiesWithoutAccounts,
    excludeNoBalanceAccounts,
    isEntitySelected,
    onReset,
    scrollbarProps,
}) => {
    const [search, setSearch] = useState("");
    const searchRef = useRef<HTMLInputElement>();

    const filteredEntities = useMemo(() => {
        if (!search) {
            return entities;
        }

        return entities.filter((entity) =>
            entity.name?.toLowerCase().includes(search.toLowerCase()),
        );
    }, [entities, search]);

    const handleToggleEntity: AccountsFilterProps["toggleEntity"] = useMemo(
        () => getCallbackWithFocus(toggleEntity, searchRef),
        [searchRef, toggleEntity],
    );

    const handleToggleAccount: AccountsFilterProps["toggleAccount"] = useMemo(
        () => getCallbackWithFocus(toggleAccount, searchRef),
        [searchRef, toggleAccount],
    );

    const handleReset: AccountsFilterProps["onReset"] = useMemo(
        () => getCallbackWithFocus(onReset ?? (() => {}), searchRef),
        [onReset, searchRef],
    );

    return (
        <div className="accounts-filter">
            <FilterSearch
                value={search}
                onChange={setSearch}
                inputRef={searchRef}
                focus
            />
            <Scrollbars
                style={{ width: "100%" }}
                autoHeight
                autoHeightMax={400}
                autoHeightMin={80}
                {...scrollbarProps}
            >
                {filteredEntities.length > 0 ? (
                    filteredEntities.map((entity) => (
                        <AccountsFilterEntity
                            key={entity.id}
                            entity={entity}
                            excludeNoBalanceAccounts={excludeNoBalanceAccounts}
                            isEntitySelected={isEntitySelected}
                            isAccountSelected={isAccountSelected}
                            toggleEntity={handleToggleEntity}
                            toggleAccount={handleToggleAccount}
                            showEntitiesWithoutAccounts={
                                showEntitiesWithoutAccounts
                            }
                        />
                    ))
                ) : (
                    <p className="transactions-filter-form__empty">
                        No matching entities
                    </p>
                )}
            </Scrollbars>

            {onReset ? (
                <Button variant="tertiary" onClick={handleReset}>
                    Reset
                </Button>
            ) : null}
        </div>
    );
};

function getCallbackWithFocus<T extends (...args: any[]) => void>(
    callback: T,
    inputRef: MutableRefObject<HTMLInputElement | undefined>,
): T {
    return ((...args: Parameters<T>) => {
        const result = callback(...args);
        inputRef.current?.focus();
        return result;
    }) as T;
}
