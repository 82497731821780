import React from "react";
import { Entity } from "../../common/types/entity";
import { usePayrollFinancialAccounts } from "../../hooks/useFinancialAccounts";
import { useIsAdmin } from "../../hooks/useRoles";
import { SettingsNavLink } from "./SettingsNavLink";
import { EntitySettingsPages } from "./SettingsProvider";
import { getEntityPaneKey } from "./lib";

interface Props {
    entity: Entity;
}

export const EntitySettingsNavigation: React.FC<Props> = ({ entity }) => {
    const isAdmin = useIsAdmin(entity);
    const hasPayroll =
        usePayrollFinancialAccounts({ entityId: entity.id }).length > 0;

    return (
        <>
            <SettingsNavLink
                eventKey={getEntityPaneKey(
                    EntitySettingsPages.ACCOUNTS,
                    entity,
                )}
            >
                Accounts
            </SettingsNavLink>

            {!entity.isPersonal && !entity.isMockEntity && hasPayroll && (
                <SettingsNavLink
                    eventKey={getEntityPaneKey(
                        EntitySettingsPages.PAYROLL,
                        entity,
                    )}
                >
                    Payroll
                </SettingsNavLink>
            )}
            {isAdmin && !entity.isMockEntity && (
                <SettingsNavLink
                    eventKey={getEntityPaneKey(
                        EntitySettingsPages.SETTINGS,
                        entity,
                    )}
                >
                    Settings
                </SettingsNavLink>
            )}
        </>
    );
};
