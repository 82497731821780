import React, { useCallback } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./AccountDetails.scss";
import { format } from "date-fns";
import { UpdateFinancialAccountEntity } from "../../../financialAccount/UpdateFinancialAccountEntity";
import { EMPTY_STATE } from "../../../../common/constants";
import { isConnectionOwner } from "../../../../hooks/useConnectionOwnership";
import { useUser } from "../../../../hooks/useUser";
import { useEntity } from "../../../entity/useEntity";
import { FinancialAccountRoutingNumbers } from "../../../financialAccount/FinancialAccountRoutingNumbers";
import { getAnonymousAccountNumber } from "../../../../common/helpers/bankAccount";
import { AlertIcon } from "../../../../icons";
import { fullDateFullYearFormat } from "../../../../common/helpers/date";
import { useIsAdmin } from "../../../../hooks/useRoles";
import { RemoveFinancialAccount } from "../../../financialAccount/RemoveFinancialAccount/RemoveFinancialAccount";
import { ExtendedFinancialAccount } from "../../../../lib/financialAccount";
import { FinancialAccountSourceType } from "../../../../common/types/financialAccount";
import { getConnectionLabel } from "../../../../common/helpers/financialConnection";
import {
    ACCOUNTS_POSSIBLY_PERSONAL,
    getLeadingAccountConnection,
    isCardAccount,
} from "../../../../common/helpers/financialAccount";
import { Switch, SwitchProps } from "../../../general/Switch/Switch";
import {
    useAccountDisablingMutation,
    useAccountEnablingMutation,
} from "../../../../mutations/financialAccount";
import { SyncGustoConnection } from "../../Payroll/SyncGustoConnection";
import { useTypedFlags } from "../../../../hooks/useTypedFlags";
import { MergeAccounts } from "../MergeAccounts/MergeAccounts";
import { useToaster } from "../../../general/ToastMessages/useToaster";
import { AccountTypeChange } from "./AccountTypeChange";
import { MergedAccountsDetails } from "./MergedAccountsDetails";
import { AccountFinancialDocuments } from "./AccountFinancialDocuments";
import { AccountName } from "./AccountName";
import { FinancialAccountInfo } from "./FinancialAccountInfo";
import { GustoAccountDetails } from "./GustoAccountDetails";
import { AccountDetailsField } from "./AccountDetailsField";
import { AccountConnectionStatus } from "./AccountConnectionStatus";

export interface AccountDetailsProps {
    account: ExtendedFinancialAccount;
}

function getAccountNumber(account: ExtendedFinancialAccount) {
    if (account.accountNumberMask) {
        return getAnonymousAccountNumber(account.accountNumberMask);
    } else {
        return EMPTY_STATE;
    }
}

export const AccountDetails: React.FC<AccountDetailsProps> = ({ account }) => {
    const { toast } = useToaster();
    const entity = useEntity();
    const isAdmin = useIsAdmin();
    const connection = getLeadingAccountConnection(account);
    const user = useUser();
    const { mergingAccounts } = useTypedFlags();
    const canEditEntity =
        isAdmin || (connection && isConnectionOwner(connection, user));
    const canMergeAccounts = mergingAccounts && isAdmin;

    const enableAccount = useAccountEnablingMutation(account);
    const disableAccount = useAccountDisablingMutation(account);
    const canBePersonal = ACCOUNTS_POSSIBLY_PERSONAL[account.sourceType];

    const handleChangeAccountEnabled: SwitchProps["onChanged"] = useCallback(
        (enabled) => {
            if (enabled) {
                enableAccount.mutate();
            } else {
                disableAccount.mutate();
            }
        },
        [disableAccount, enableAccount],
    );

    const missingClassification = account.isBusiness === null;
    const missingEntity = account.entity === null;

    const onEntityUpdated = useCallback(() => {
        toast("Account successfully moved to a different entity");
    }, [toast]);

    const accountNumber = getAccountNumber(account);

    return (
        <div className="account-details">
            <section className="account-details__section">
                <header className="account-details__section__header">
                    <h4>Details</h4>
                </header>

                <main className="account-details__section__content">
                    <AccountDetailsField title="Account name">
                        <AccountName account={account} entity={entity} />
                    </AccountDetailsField>

                    <AccountDetailsField
                        title={
                            <>
                                Account type
                                {missingClassification && (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip
                                                id={`missing-classification-${account.id}`}
                                            >
                                                Required info missing
                                            </Tooltip>
                                        }
                                    >
                                        <AlertIcon className="icon-color-red-600 ml-2" />
                                    </OverlayTrigger>
                                )}
                            </>
                        }
                        className="account_details__type"
                    >
                        {canBePersonal ? (
                            <AccountTypeChange
                                account={account}
                                connection={connection ?? undefined}
                                onChanged={onEntityUpdated}
                            />
                        ) : (
                            "Business"
                        )}
                    </AccountDetailsField>

                    <AccountDetailsField
                        title={
                            <>
                                Entity
                                {missingEntity && (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip
                                                id={`missing-entity-${account.id}`}
                                            >
                                                Required info missing
                                            </Tooltip>
                                        }
                                    >
                                        <AlertIcon className="icon-color-red-600 ml-2" />
                                    </OverlayTrigger>
                                )}
                            </>
                        }
                        className="account_details__entity"
                    >
                        <UpdateFinancialAccountEntity
                            account={account}
                            isBusiness={account.entity?.isBusiness ?? undefined}
                            selectedEntity={account.entity ?? undefined}
                            dropdownKey={`accountEntity_${account.id}`}
                            disabled={!canEditEntity}
                            size="sm"
                            onUpdated={onEntityUpdated}
                        />
                    </AccountDetailsField>
                </main>
            </section>

            {account.sourceType === FinancialAccountSourceType.FILE_IMPORT && (
                <section className="account-details__section">
                    <header className="account-details__section__header">
                        <h4>Imported files</h4>
                    </header>

                    <main className="account-details__section__content">
                        <AccountFinancialDocuments account={account} />
                    </main>
                </section>
            )}

            {account.sourceType !== FinancialAccountSourceType.FILE_IMPORT && (
                <section className="account-details__section">
                    <header className="account-details__section__header">
                        <h4>Info</h4>
                    </header>

                    <main className="account-details__section__content">
                        {connection && (
                            <AccountDetailsField title="Account">
                                {getConnectionLabel(connection)}
                            </AccountDetailsField>
                        )}

                        {account.routingNumber && (
                            <AccountDetailsField title="Routing number">
                                <FinancialAccountRoutingNumbers
                                    account={account}
                                />
                            </AccountDetailsField>
                        )}

                        {account.sourceType ===
                        FinancialAccountSourceType.PLAID ? (
                            <AccountDetailsField
                                title={
                                    isCardAccount(account)
                                        ? "Card number"
                                        : "Account number"
                                }
                            >
                                {accountNumber}
                            </AccountDetailsField>
                        ) : (
                            <FinancialAccountInfo account={account} />
                        )}

                        {account.sourceType ===
                        FinancialAccountSourceType.GUSTO ? (
                            <GustoAccountDetails account={account} />
                        ) : null}
                    </main>
                </section>
            )}

            {connection && (
                <section className="account-details__section">
                    <header className="account-details__section__header">
                        <h4>Connection</h4>
                    </header>

                    <main className="account-details__section__content">
                        <AccountDetailsField
                            title={`Connected to ${connection.connectionProvider}`}
                        >
                            <div className="account-details__connection__content">
                                <AccountConnectionStatus
                                    connection={connection}
                                    account={account}
                                />

                                <Switch
                                    active={
                                        !account.disabled && !connection?.error
                                    }
                                    loading={
                                        enableAccount.isPending ||
                                        disableAccount.isPending
                                    }
                                    onChanged={handleChangeAccountEnabled}
                                    size="sm"
                                />
                            </div>
                        </AccountDetailsField>

                        {connection?.user && (
                            <AccountDetailsField title="Integration created by">
                                {isConnectionOwner(connection, user)
                                    ? "You"
                                    : connection.user.name}
                                {connection.createdAt && (
                                    <>
                                        {" "}
                                        on{" "}
                                        {format(
                                            connection.createdAt,
                                            fullDateFullYearFormat,
                                        )}
                                    </>
                                )}
                            </AccountDetailsField>
                        )}
                    </main>
                </section>
            )}
            {canMergeAccounts && (
                <MergedAccountsDetails financialAccount={account} />
            )}
            {isAdmin && (
                <AccountDetailsField>
                    <div className="w-100 d-flex justify-content-between">
                        <div className="gap-buttons">
                            {account.sourceType ===
                            FinancialAccountSourceType.GUSTO ? (
                                <SyncGustoConnection
                                    entity={entity}
                                    variant="secondary"
                                    className="account-details__resync-account"
                                >
                                    Sync account
                                </SyncGustoConnection>
                            ) : null}
                            {canMergeAccounts && (
                                <MergeAccounts
                                    financialAccount={account}
                                    entity={entity}
                                    buttonProps={{ variant: "secondary" }}
                                    buttonClass="account-details__merge-account"
                                />
                            )}
                        </div>

                        <RemoveFinancialAccount
                            account={account}
                            variant="danger"
                            className="account-details__remove-account danger"
                        >
                            Remove account
                        </RemoveFinancialAccount>
                    </div>
                </AccountDetailsField>
            )}
        </div>
    );
};
