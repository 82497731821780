import React from "react";
import {
    FinancialAccount,
    FinancialAccountSourceType,
} from "../../common/types/financialAccount";
import { accountHasError } from "../../helpers/account";
import { getLeadingAccountConnection } from "../../common/helpers/financialAccount";
import { BrokenConnectionLink } from "../financialConnection/BrokenConnectionLink";
import { useFinancialAccountSyncedAt } from "../../hooks/useFinancialAccountSyncedAt";
import { SpreadsheetImporterStatus } from "../../common/types/financialDocument";
import { ClockIcon, Offline } from "../../icons";
import { timeAgo } from "../../helpers/date";

interface Props {
    financialAccount: FinancialAccount;
}

const syncIconProps = {
    height: 12,
    width: 12,
    className: "icon-color-current mr-2",
} as const;

export const EntityAccountLastSync: React.FC<Props> = ({
    financialAccount,
}) => {
    const isErrored = financialAccount.integrationAccounts?.every((ia) =>
        accountHasError(ia, ia.connection ?? undefined),
    );

    const connection = getLeadingAccountConnection(financialAccount);
    const integrationAccountOfLeadingConnection =
        financialAccount.integrationAccounts?.find(
            (ia) => ia.connectionId && ia.connectionId === connection?.id,
        );

    const syncedAt = useFinancialAccountSyncedAt(financialAccount);

    if (
        isErrored &&
        !financialAccount.disabled &&
        connection &&
        integrationAccountOfLeadingConnection
    ) {
        return (
            <BrokenConnectionLink
                connection={connection}
                integrationAccount={integrationAccountOfLeadingConnection}
                balanceSyncedAt={syncedAt}
            />
        );
    }

    if (
        financialAccount.sourceType === FinancialAccountSourceType.FILE_IMPORT
    ) {
        const financialDocuments =
            financialAccount.integrationAccounts
                ?.map((ia) => ia.financialDocument)
                .filter((fd) => !!fd) ?? [];

        const allSuccessfullyImported = financialDocuments.every(
            (fd) =>
                fd.spreadsheetImporterStatus ===
                SpreadsheetImporterStatus.IMPORT_SUCCESS,
        );

        return allSuccessfullyImported && syncedAt ? (
            <>
                <Offline {...syncIconProps} />
                {timeAgo(syncedAt)}
            </>
        ) : (
            <>Importing...</>
        );
    }

    if (!syncedAt) {
        return null;
    }

    return (
        <>
            <ClockIcon {...syncIconProps} />
            {timeAgo(syncedAt)}
        </>
    );
};
