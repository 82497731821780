import React from "react";
import classNames from "classnames";
import { ChildrenProps } from "../../../../types";

export interface AccountDetailsFieldProps extends ChildrenProps {
    title?: React.ReactNode;
    className?: string;
}

export const AccountDetailsField: React.FC<AccountDetailsFieldProps> = ({
    title,
    children,
    className,
}) => (
    <div className={classNames("account-details__field", className)}>
        {title && (
            <header className="account-details__field__heading">{title}</header>
        )}
        <div className="account-details__field__content">{children}</div>
    </div>
);
