import { useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useDocumentParams } from "../useDocumentParams";

export enum FinancialDocumentDetailsView {
    DEFAULT = "default",
    MATCHING = "matching",
}

export function useFinancialDocumentDetailsView() {
    const navigate = useNavigate();
    const { view, documentId } = useDocumentParams();

    let currentView = FinancialDocumentDetailsView.DEFAULT;

    if (view?.toLowerCase() === "matching") {
        currentView = FinancialDocumentDetailsView.MATCHING;
    }

    useEffect(() => {
        if (currentView === FinancialDocumentDetailsView.DEFAULT) {
            navigate(`/documents/${documentId}`, { replace: true });
        }
    }, [currentView, documentId, navigate]);

    const go = useCallback(
        (newView?: FinancialDocumentDetailsView) => {
            navigate(`/documents/${documentId}/${newView}`);
        },
        [documentId, navigate],
    );

    return {
        currentView,
        go,
    };
}
