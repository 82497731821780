import type { Plaid } from "plaid-link";
import { CreateBankAccountConnectionDto } from "../common/dto/onboarding/create-bank-account-connection.dto";
import { GetPlaidLinkTokenResponseDto } from "../common/dto/plaid/get-plaid-link-token-response.dto";
import {
    CreatePlaidConnectionResponse,
    CreatePlaidConnectionResponseDto,
} from "../common/dto/plaid/create-plaid-connection-response.dto";
import { PlaidConnection } from "../common/types/plaidConnection";
import { AddNewPlaidAccountsResponseDto } from "../common/dto/plaid/add-new-plaid-accounts-response.dto";
import { PlaidConnectionDto } from "../common/dto/plaidConnection/plaid-connection.dto";
import { PLAID_INSTITUTIONS_NAMES } from "../common/constants";
import { backendClient, unwrapResponse } from "./backendClient";
import { getAnalytics } from "./analytics";

export interface PlaidSuccessfulResponse {
    connected: true;
    publicToken: string;
    metadata: Plaid.OnSuccessMetaData;
}

export interface PlaidFailedResponse {
    connected: false;
    publicToken?: never;
    metadata?: never;
}

interface ConnectWithPlaidOptions {
    connectionId?: number;
    addAccounts?: boolean;
    institutionName?: PLAID_INSTITUTIONS_NAMES;
}
export type PlaidResponse = PlaidSuccessfulResponse | PlaidFailedResponse;

export async function connectWithPlaid(
    options?: ConnectWithPlaidOptions,
): Promise<PlaidResponse> {
    let sessionIdTracked = false;
    const { token } = await getPlaidLinkToken(
        options?.connectionId,
        options?.addAccounts,
        options?.institutionName,
    );

    return await new Promise<PlaidResponse>((resolve, reject) => {
        window.Plaid?.create({
            token,
            onSuccess: (publicToken, metadata) =>
                resolve({
                    connected: true,
                    publicToken,
                    metadata,
                }),
            onExit: (error) => {
                if (error) {
                    reject(
                        new Error(
                            `Plaid error ${error.error_code}: ${error.error_message}`,
                        ),
                    );
                } else {
                    resolve({ connected: false });
                }
            },
            onEvent: (_, metadata) => {
                if (!sessionIdTracked) {
                    getAnalytics()?.track("Plaid Link Session", {
                        linkSessionId: metadata.link_session_id,
                    });
                    sessionIdTracked = true;
                }
            },
        }).open();
    });
}

async function getPlaidLinkToken(
    connectionId?: number,
    addAccounts?: boolean,
    institutionName?: PLAID_INSTITUTIONS_NAMES,
): Promise<GetPlaidLinkTokenResponseDto> {
    return unwrapResponse(
        await backendClient.get(
            `/plaid-connection/link-token${
                connectionId ? `/${connectionId}` : ""
            }`,
            { params: { addAccounts, institutionName } },
        ),
    );
}

export async function connectPlaidConnection(
    params: CreateBankAccountConnectionDto,
): Promise<CreatePlaidConnectionResponse> {
    return CreatePlaidConnectionResponseDto.deserialize(
        unwrapResponse(await backendClient.post("/plaid-connection", params)),
    );
}

export async function getPlaidConnections(institutionId?: string) {
    const params = institutionId ? { institutionId } : undefined;

    const response = unwrapResponse(
        await backendClient.get<PlaidConnectionDto[]>("/plaid-connection", {
            params,
        }),
    );

    return response.map((dto: PlaidConnectionDto) =>
        PlaidConnectionDto.deserialize(dto),
    );
}

export async function connectNewPlaidAccounts(
    connection: PlaidConnection,
): Promise<AddNewPlaidAccountsResponseDto> {
    return unwrapResponse(
        await backendClient.post(
            `/plaid-connection/${connection.id}/new-accounts`,
        ),
    );
}

export async function clearPlaidConnectionErrors(
    connectionId: number,
): Promise<void> {
    return unwrapResponse(
        await backendClient.post(
            `/plaid-connection/clear-errors/${connectionId}`,
        ),
    );
}

export async function deletePlaidConnection(
    connectionId: number,
): Promise<void> {
    return await backendClient.delete(`/plaid-connection/${connectionId}`);
}
