export function getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        const uriComponent = parts.pop()?.split(";").shift();

        return uriComponent ? decodeURIComponent(uriComponent) : undefined;
    }
}

const MAX_AGE = 90 * 24 * 60 * 60 * 1000;
export function setCookie(name: string, value: string) {
    document.cookie = `${name}=${value}; samesite=strict; path=/; max-age=${MAX_AGE}`;
}
