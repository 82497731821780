import React from "react";
import { ModalComponentProps } from "../../helpers/modal";
import { Button } from "../general/Button/Button";
import { StandardModalBody, StandardModalHeader } from "../general/Modal/Modal";

export const AccountTypeChangeForbiddenModal: React.FC<ModalComponentProps> = ({
    close,
}) => (
    <>
        <StandardModalHeader>Account type change forbidden</StandardModalHeader>
        <StandardModalBody>
            <p className="text-prominent mb-5">
                You can't change the account type of this account to personal.
            </p>
            <footer className="d-flex justify-content-between">
                <Button variant="secondary" size="lg" onClick={() => close()}>
                    Close
                </Button>
            </footer>
        </StandardModalBody>
    </>
);
