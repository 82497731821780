import { useQuery } from "@tanstack/react-query";

export function useFetchAsset(url: string) {
    return useQuery({
        queryKey: ["fetchAsset", url],
        queryFn: async () =>
            fetch(url)
                .then((res) => res.blob())
                .then((res) => URL.createObjectURL(res)),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
}
