import { useCallback, useMemo, useState } from "react";
import { UpdateTransactionDto } from "../../../common/dto/transactions/update-transaction.dto";
import { isReceiptRequired } from "../../../common/taxSavingRules";
import { Action } from "../../../common/types/actions";
import { toBase64 } from "../../../helpers/file";
import { uploadTransactionReceipt } from "../../../lib/transactions";
import { ReceiptUploadDropzone } from "../../transactions/ReceiptUpload/ReceiptUploadDropzone";
import { useCategoriesById } from "../../../hooks/useCategoriesById";
import { TransactionUpdateMetaDto } from "../../../common/dto/actions/transaction-update-meta.dto";
import { CategoryDetail } from "./CategoryDetail";
import { EntityDetail } from "./EntityDetail";
import { MemoDetail } from "./MemoDetail";
import { ReceiptDetail } from "./ReceiptDetail";
import { TaxQuestionDetails } from "./TaxQuestionDetails";

export interface TransactionDetailProps {
    action: Action<TransactionUpdateMetaDto>;
    onUpdate: (update: Partial<UpdateTransactionDto>) => void;
}

interface Props {
    action: Action<TransactionUpdateMetaDto>;
    onUpdate: (update: UpdateTransactionDto) => void;
    showMemo?: boolean;
    showReceipt?: boolean;
    mustAddReceiptIfRequired?: boolean;
    onReceiptAdded?: (fileName: string) => void;
}

export const TransactionDetails: React.FC<Props> = ({
    action,
    onUpdate,
    showMemo,
    showReceipt,
    mustAddReceiptIfRequired,
    onReceiptAdded,
}) => {
    const transaction = action.transaction!;
    const updateTransaction = useCallback(
        (update: Partial<UpdateTransactionDto>) => {
            onUpdate(update);
        },
        [onUpdate],
    );
    const categoriesById = useCategoriesById();
    const categoryId =
        action.meta.transactionUpdate?.categoryId ?? transaction.categoryId;
    const category = categoriesById[categoryId];

    const [uploading, setUploading] = useState(false);
    const [receiptFilename, setReceiptFilename] = useState<string>();

    const showEntitySelect = action.meta.transactionUpdate?.entityId;
    const showCategorySelect = action.meta.transactionUpdate?.categoryId;

    const receiptRequired = useMemo(
        () =>
            Boolean(
                isReceiptRequired({
                    ...transaction,
                    category,
                }) && mustAddReceiptIfRequired,
            ),
        [transaction, category, mustAddReceiptIfRequired],
    );

    const addReceipt = useCallback(
        async (file: File) => {
            setUploading(true);

            try {
                const receipt = await toBase64(file);

                await uploadTransactionReceipt(transaction.id, {
                    receipt,
                    contentType: file.type,
                    name: file.name,
                });
                setReceiptFilename(file.name);
                onReceiptAdded?.(file.name);
            } finally {
                setUploading(false);
            }
        },
        [transaction.id, onReceiptAdded],
    );

    const children = useMemo(() => {
        const params = {
            action,
            onUpdate: updateTransaction,
        };
        return (
            <>
                {showEntitySelect && <EntityDetail {...params} />}

                <TaxQuestionDetails {...params} />

                {showCategorySelect && <CategoryDetail {...params} />}

                {showMemo && <MemoDetail {...params} />}

                {showReceipt && (
                    <ReceiptDetail
                        fileName={receiptFilename}
                        uploading={uploading}
                        required={receiptRequired}
                    />
                )}
            </>
        );
    }, [
        action,
        updateTransaction,
        showEntitySelect,
        showCategorySelect,
        showMemo,
        showReceipt,
        receiptFilename,
        uploading,
        receiptRequired,
    ]);

    if (showReceipt) {
        return (
            <ReceiptUploadDropzone onAddReceipt={addReceipt}>
                {children}
            </ReceiptUploadDropzone>
        );
    }

    return <>{children}</>;
};
