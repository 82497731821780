import React, { useState } from "react";
import { FinancialAccount } from "../../../../common/types/financialAccount";
import { Entity } from "../../../../common/types/entity";
import { StandardModal } from "../../../general/Modal/Modal";
import { Button, ExtendedButtonProps } from "../../../general/Button/Button";
import { MergeAccountsModal } from "./MergeAccountsModal";

interface Props {
    financialAccount: FinancialAccount;
    entity: Entity;
    buttonProps?: ExtendedButtonProps;
    buttonClass?: string;
}

export const MergeAccounts: React.FC<Props> = ({
    financialAccount,
    entity,
    buttonProps = { variant: "default" },
    buttonClass,
}) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Button
                className={buttonClass}
                {...buttonProps}
                onClick={() => setShowModal((shown) => !shown)}
            >
                Merge account
            </Button>

            <StandardModal
                show={showModal}
                onHide={() => setShowModal(false)}
                size="xl"
            >
                <MergeAccountsModal
                    financialAccount={financialAccount}
                    entity={entity}
                    close={() => setShowModal(false)}
                />
            </StandardModal>
        </>
    );
};
