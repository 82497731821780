import React, { useState } from "react";
import { FinancialAccount } from "../../../../common/types/financialAccount";
import { GridTable } from "../../../general/Tables/GridTable/GridTable";
import { MergedAccountDetails } from "./MergedAccountDetails";

interface Props {
    financialAccount: FinancialAccount;
}

export const MergedAccountsDetails: React.FC<Props> = ({
    financialAccount,
}) => {
    const [extractingAccount, setExtractingAccount] = useState(false);

    if (
        !financialAccount.integrationAccounts ||
        financialAccount.integrationAccounts.length === 1
    ) {
        return null;
    }

    return (
        <section className="account-details__section">
            <header className="account-details__section__header">
                <h4>Merged accounts</h4>
            </header>
            <GridTable>
                {financialAccount.integrationAccounts.map(
                    (integrationAccount) => (
                        <MergedAccountDetails
                            integrationAccount={integrationAccount}
                            financialAccount={financialAccount}
                            onExtractStarted={() => setExtractingAccount(true)}
                            onExtractFinished={() =>
                                setExtractingAccount(false)
                            }
                            extractingOtherAccount={extractingAccount}
                            key={integrationAccount.id}
                        />
                    ),
                )}
            </GridTable>
        </section>
    );
};
