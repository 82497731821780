import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { keyBy } from "lodash";
import { getAccounts } from "../lib/accounting";
import {
    AccountClass,
    AccountType,
} from "../common/types/domains/accounting/accounts";
import { Account } from "../common/types/domains/accounting/account";

export interface AccountTypeWithAccounts {
    type: AccountType;
    accounts: Account[];
}

export function getAccountsQueryKey(entityId?: number) {
    return ["accounting_accounts", entityId];
}

export function useAccounts({
    entityId,
    classes,
    includeDisabled = false,
}: {
    entityId?: number;
    classes?: AccountClass[];
    includeDisabled?: boolean;
} = {}) {
    const accountsRequest = useQuery({
        queryKey: getAccountsQueryKey(entityId),
        queryFn: () => (entityId ? getAccounts({ entityId }) : null),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
    });
    const accounts = useMemo(
        () =>
            (accountsRequest.data?.accounts ?? []).filter((account) => {
                if (!includeDisabled && account.isDisabled) {
                    return false;
                }

                if (classes) {
                    return classes.includes(account.class);
                }

                return true;
            }),
        [accountsRequest.data?.accounts, classes, includeDisabled],
    );

    const accountsByCode = useMemo(
        () => (accounts ? keyBy(accounts, (a) => a.code) : {}),
        [accounts],
    );
    const accountTypesWithAccounts = useMemo(() => {
        const accountsPerType = accounts
            ? accounts
                  .sort((a, b) => (a.code < b.code ? -1 : 1))
                  .reduce((acc, curValue) => {
                      if (!acc.has(curValue.type)) {
                          acc.set(curValue.type, []);
                      }
                      acc.get(curValue.type)?.push(curValue);
                      return acc;
                  }, new Map<AccountType, Account[]>())
            : new Map<AccountType, Account[]>();

        const arrayToReturn: AccountTypeWithAccounts[] = [];
        for (const accountType of accountsPerType.keys()) {
            arrayToReturn.push({
                type: accountType,
                accounts: accountsPerType.get(accountType)!.map((a) => a),
            });
        }
        return arrayToReturn;
    }, [accounts]);

    return {
        isLoading: accountsRequest.isLoading,
        isFetching: accountsRequest.isFetching,
        accounts,
        accountsByCode,
        accountTypesWithAccounts,
        chartOfAccountsVersion: accountsRequest.data?.chartOfAccountsVersion,
    };
}
