import React, { createContext, FC, useMemo, useState } from "react";
import { noop } from "../../helpers/general";
import { NewVersionModal } from "../newVersion/NewVersionModal";
import { ChildrenProps } from "../../types";

interface NewVersionAvailableContextValue {
    notifyNewVersionAvailable(): void;
}

const newNewVersionAvailableContext =
    createContext<NewVersionAvailableContextValue>({
        notifyNewVersionAvailable: noop,
    });

export const newVersionAvailableProxy: NewVersionAvailableContextValue = {
    notifyNewVersionAvailable: noop,
};

export const NewVersionAvailableProvider: FC<ChildrenProps> = ({
    children,
}) => {
    const [show, setShow] = useState<boolean>(false);

    const value = useMemo(() => {
        const notifyNewVersionAvailable = () => {
            setShow(true);
        };

        newVersionAvailableProxy.notifyNewVersionAvailable =
            notifyNewVersionAvailable;

        return { notifyNewVersionAvailable };
    }, []);

    return (
        <newNewVersionAvailableContext.Provider value={value}>
            {children}

            <NewVersionModal show={show} />
        </newNewVersionAvailableContext.Provider>
    );
};
