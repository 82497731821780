import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Markdown from "markdown-to-jsx";
import { Transaction } from "../../../common/types/transaction";
import { CategorySelectPrompt } from "../../transactions/CategorySelect/CategorySelectPrompt";
import { useCategoriesById } from "../../../hooks/useCategoriesById";

interface Props {
    transaction: Transaction;
}

export const TransactionCategoryContent: React.FC<Props> = ({
    transaction,
}) => {
    const categoriesById = useCategoriesById();
    const category = categoriesById[transaction.categoryId];

    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip
                    id={`category:${transaction.id}`}
                    className="transaction-list-item__description__tooltip"
                >
                    {category && (
                        <Markdown options={{ disableParsingRawHTML: true }}>
                            {category.description ?? ""}
                        </Markdown>
                    )}
                </Tooltip>
            }
        >
            <div>
                <CategorySelectPrompt
                    readonly={true}
                    transaction={transaction}
                />
            </div>
        </OverlayTrigger>
    );
};
