import { createContext } from "react";
import { noop } from "lodash";
import { Counterparty } from "../../../common/types/counterparty";
import {
    RuleSource,
    TransactionRuleConditionDefinition,
} from "../../../common/types/transactionRule";
import { Category } from "../../../common/types/category";

export interface NewTransactionRuleDefinition {
    categoryId?: string;
    category?: Category;
    conditions: TransactionRuleConditionDefinition[];
    entityId?: number;
}

export interface CreateRuleOptions {
    definition?: NewTransactionRuleDefinition;
    counterparties?: Counterparty[];
    source: RuleSource;
}

interface Context {
    createRule(options: CreateRuleOptions): void;
}

export const TransactionRuleCreationContext = createContext<Context>({
    createRule: noop,
});
