import React from "react";
import { useNavigate } from "react-router-dom";
import { HEADER_HEIGHT } from "../../constants";
import { ArrowRightIcon, MenuIcon, PlusIcon } from "../../icons";
import { useNavigationContext } from "../navigation/NavigationContext";
import { useCommands } from "../commands/useCommands";
import { Button } from "../general/Button/Button";
import { Shortcut } from "../commands/Shortcut";
import { UserDropdown } from "./UserDropdown/UserDropdown";
import styles from "./MainInterface.module.scss";

export interface Breadcrumb {
    label: string;
    to?: string;
}

interface Props {
    breadcrumbs?: Breadcrumb[];
}

export const MainInterfaceHeader: React.FC<Props> = ({ breadcrumbs = [] }) => {
    const { open: openUserDropdown } = useNavigationContext();
    const { open: openCommands } = useCommands();
    const routerNavigate = useNavigate();

    const navigate = (breadcrumb: Breadcrumb) => {
        if (breadcrumb.to) {
            routerNavigate(breadcrumb.to);
        }
    };

    return (
        <header className={styles.header}>
            <div
                className={`${styles.container} ${styles.headerContainer}`}
                style={{ "--height": `${HEADER_HEIGHT}px` } as any}
            >
                <aside className={styles.headerContent}>
                    <div className={styles.headerBreadcrumbs}>
                        {breadcrumbs.map((breadcrumb, index) => (
                            <React.Fragment key={breadcrumb.label}>
                                {breadcrumb.to && (
                                    <Button
                                        size="sm"
                                        variant="tertiary"
                                        onClick={() => navigate(breadcrumb)}
                                    >
                                        {breadcrumb.label}
                                    </Button>
                                )}
                                {!breadcrumb.to && (
                                    <span className={styles.headerBreadcrumb}>
                                        {breadcrumb.label}
                                    </span>
                                )}
                                {index < breadcrumbs.length - 1 && (
                                    <ArrowRightIcon
                                        className={
                                            styles.headerBreadcrumbSeparator
                                        }
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </div>

                    <div className={styles.headerActions}>
                        <Button
                            variant="secondary"
                            size="sm"
                            icon
                            onClick={() => openCommands()}
                            tooltip={
                                <div className="d-flex gap-2">
                                    Open actions modal
                                    <Shortcut keys={["⌘", "K"]} hideText />
                                </div>
                            }
                            tooltipPlacement="left"
                        >
                            <PlusIcon />
                        </Button>

                        <UserDropdown />

                        <Button
                            size="sm"
                            variant="tertiary"
                            icon
                            onClick={openUserDropdown}
                            className="d-md-none"
                        >
                            <MenuIcon />
                        </Button>
                    </div>
                </aside>
            </div>
        </header>
    );
};
