const env = {
    VITE_PLAID_KEY: import.meta.env.VITE_PLAID_KEY,
    VITE_BACKEND_URL: import.meta.env.VITE_BACKEND_URL,
    VITE_AUTH0_CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID,
    VITE_AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN,
    VITE_AUTH0_REDIRECT_URI: import.meta.env.VITE_AUTH0_REDIRECT_URI,
    VITE_AUTH0_AUDIENCE: import.meta.env.VITE_AUTH0_AUDIENCE,
    VITE_SENTRY_ENABLED: import.meta.env.VITE_SENTRY_ENABLED,
    VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
    VITE_SEGMENT_KEY: import.meta.env.VITE_SEGMENT_KEY,
    VITE_SEGMENT_ENABLED: import.meta.env.VITE_SEGMENT_ENABLED,
    VITE_VGS_VAULT_ID: import.meta.env.VITE_VGS_VAULT_ID,
    NODE_ENV: import.meta.env.NODE_ENV,
    KICK_ENV: import.meta.env.KICK_ENV,
    VITE_LAUNCHDARKLY_CLIENT_SIDE_ID: import.meta.env
        .VITE_LAUNCHDARKLY_CLIENT_SIDE_ID,
    VITE_INTERCOM_APP_ID: import.meta.env.VITE_INTERCOM_APP_ID,
    VITE_AMCHARTS_LICENSE: import.meta.env.VITE_AMCHARTS_LICENSE,
    VITE_STRIPE_PUBLISHABLE_KEY: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
    VITE_STRIPE_PORTAL_URL: import.meta.env.VITE_STRIPE_PORTAL_URL,
    VITE_HOTJAR_ID: import.meta.env.VITE_HOTJAR_ID,
    VITE_GTM_ID: import.meta.env.VITE_GTM_ID,
    VITE_CIO_SITE_ID: import.meta.env.VITE_CIO_SITE_ID,
    VITE_FAKE_AUTH: import.meta.env.VITE_FAKE_AUTH,
} as const;

export function initEnvironment() {
    if (!(window as any)._env) {
        // Init environment in CRA development build
        (window as any)._env = env;
    }
}

export function getEnv(name: keyof typeof env): string {
    return (window as any)._env[name] || "";
}
