import React, { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { isArray } from "lodash";
import { useEntities } from "../../../hooks/useEntities";
import { Tag } from "../../general/Tag/Tag";
import { TransactionRuleCondition } from "../../../common/types/transactionRule";

interface Props {
    condition: TransactionRuleCondition;
}

export const RuleEntityCondition: React.FC<Props> = ({ condition }) => {
    const entities = useEntities();
    const ids = (
        isArray(condition.value) ? condition.value : [condition.value]
    ) as number[];
    const relevantEntities = entities.filter((e) => ids.includes(e.id));

    const allBusinessEntitiesSelected = useMemo(() => {
        const allBusinessIds = entities
            .filter((e) => e.isBusiness)
            .map((e) => e.id);

        return (
            allBusinessIds.every((id) =>
                relevantEntities.some((e) => e.id === id),
            ) && relevantEntities.length === allBusinessIds.length
        );
    }, [entities, relevantEntities]);
    const allPersonalEntitiesSelected = useMemo(() => {
        const allPersonalIds = entities
            .filter((e) => e.isPersonal)
            .map((e) => e.id);

        return (
            allPersonalIds.every((id) =>
                relevantEntities.some((e) => e.id === id),
            ) && relevantEntities.length === allPersonalIds.length
        );
    }, [entities, relevantEntities]);

    if (relevantEntities.length === 1) {
        return (
            <>
                From entity <strong>{relevantEntities[0].name}</strong>
            </>
        );
    }

    if (allBusinessEntitiesSelected) {
        return (
            <>
                From any <strong>business entity</strong>
            </>
        );
    }

    if (allPersonalEntitiesSelected) {
        return (
            <>
                From any <strong>personal entity</strong>
            </>
        );
    }

    if (relevantEntities.length > 1) {
        const [firstEntity, ...otherEntities] = relevantEntities;

        return (
            <>
                From entities <strong>{firstEntity.name},</strong>{" "}
                <OverlayTrigger
                    overlay={
                        <Tooltip id={`other-accounts-${condition.id}`}>
                            {otherEntities.map((e) => e.name).join(", ")}
                        </Tooltip>
                    }
                >
                    <span>
                        <Tag variant="secondary" outline>
                            + {otherEntities.length}
                        </Tag>
                    </span>
                </OverlayTrigger>
            </>
        );
    }

    return null;
};
