import { useQuery } from "@tanstack/react-query";
import { getActionsSummary } from "../lib/actions";
import { queryClient } from "../queryClient";
import { useWorkspaceContext } from "../state/workspaceContext";
import { DEFAULT_ACTIONS_SUMMARY } from "../common/types/actions";

export const ACTIONS_SUMMARY_QUERY_KEY = "actionsSummary";

export function useActionsSummary() {
    const { activeWorkspace } = useWorkspaceContext();

    return useQuery({
        queryKey: [ACTIONS_SUMMARY_QUERY_KEY, activeWorkspace?.id],
        queryFn: () =>
            activeWorkspace
                ? getActionsSummary(activeWorkspace.id)
                : DEFAULT_ACTIONS_SUMMARY,
        staleTime: 1000 * 60 * 30, // 30 minutes
        refetchInterval: 1000 * 60 * 5, // 5 minutes
    });
}

export function invalidateActionsSummary(workspaceId: string) {
    queryClient.invalidateQueries({
        queryKey: [ACTIONS_SUMMARY_QUERY_KEY, workspaceId],
    });
}
