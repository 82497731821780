import { PhoneIcon } from "../../../icons";
import { ActionActivity } from "../ActionActivity";
import { CampaignActivityProps } from "../ActionActivityItem";

export const SetupReceiptMatchingActivity: React.FC<CampaignActivityProps> = ({
    animated,
}) => (
    <ActionActivity icon={<PhoneIcon />} animated={animated}>
        Receipt matching activated
    </ActionActivity>
);
