import React, { useMemo } from "react";
import { useField } from "formik";
import { Dropdown } from "react-bootstrap";
import {
    TransactionRuleConditionFormSchema,
    FormRuleConditionType,
} from "../constants";
import { CustomSelect } from "../../../../forms/CustomSelect/CustomSelect";
import { ManagementDropdown } from "../../../../general/ManagementDropdown/ManagementDropdown";
import styles from "../RuleForm.module.scss";
import { getCounterpartyRuleConditionLabel } from "../../../../../common/helpers/rules";
import { CategoryConditionForm } from "./CategoryConditionForm";
import { BankDescriptionConditionForm } from "./BankDescriptionConditionForm";
import { AmountConditionForm } from "./AmountConditionForm";
import { AccountConditionForm } from "./AccountConditionForm";
import { EntityConditionForm } from "./EntityConditionForm";
import { CounterpartyConditionForm } from "./CounterpartyConditionForm";
import { useTaxonomyFromRuleConditionsForm } from "./useTaxonomyFromRuleConditionsForm";

interface Props {
    condition: TransactionRuleConditionFormSchema;
    index: number;
    onRemove: (id: string) => void;
    onDuplicate: (condition: TransactionRuleConditionFormSchema) => void;
}

export const RuleConditionForm: React.FC<Props> = ({
    condition,
    index,
    onRemove,
    onDuplicate,
}) => {
    const [conditionTypeField, , conditionTypeHelpers] = useField<
        TransactionRuleConditionFormSchema["conditionType"]
    >(`conditions.${index}.conditionType`);

    const canDuplicate =
        condition.conditionType !== undefined &&
        condition.conditionType !== FormRuleConditionType.ENTITY;
    const canRemove = condition.conditionType !== FormRuleConditionType.ENTITY;
    const conditionType = conditionTypeField.value;
    const usedTaxonomy = useTaxonomyFromRuleConditionsForm();

    const conditionTypesOptions = useMemo(
        () =>
            Object.values(FormRuleConditionType)
                .filter((v) => v !== FormRuleConditionType.ENTITY)
                .map((value) => {
                    if (value === FormRuleConditionType.COUNTERPARTY) {
                        return {
                            value,
                            label: getCounterpartyRuleConditionLabel(
                                usedTaxonomy,
                            ),
                        };
                    }

                    return {
                        value,
                        label: value,
                    };
                }),
        [usedTaxonomy],
    );

    return (
        <>
            <div className={styles.sectionRowContent}>
                {conditionType !== FormRuleConditionType.ENTITY && (
                    <CustomSelect
                        value={conditionType}
                        onSelected={(v) => conditionTypeHelpers.setValue(v)}
                        dropdownKey={`conditionType${condition.id}`}
                        placeholder="Select"
                        size="xs"
                        options={conditionTypesOptions}
                    />
                )}

                {conditionType === FormRuleConditionType.CATEGORY && (
                    <CategoryConditionForm
                        condition={condition}
                        index={index}
                        showPersonal={true}
                    />
                )}
                {conditionType === FormRuleConditionType.SUGGESTED_CATEGORY && (
                    <CategoryConditionForm
                        condition={condition}
                        index={index}
                        showPersonal={false}
                    />
                )}
                {conditionType === FormRuleConditionType.BANK_DESCRIPTION && (
                    <BankDescriptionConditionForm
                        condition={condition}
                        index={index}
                    />
                )}
                {conditionType === FormRuleConditionType.AMOUNT && (
                    <AmountConditionForm condition={condition} index={index} />
                )}
                {conditionType === FormRuleConditionType.ACCOUNT && (
                    <AccountConditionForm index={index} />
                )}
                {conditionType === FormRuleConditionType.ENTITY && (
                    <EntityConditionForm index={index} />
                )}
                {conditionType === FormRuleConditionType.COUNTERPARTY && (
                    <CounterpartyConditionForm index={index} />
                )}
            </div>

            {(canDuplicate || canRemove) && (
                <ManagementDropdown
                    id={`conditionManagement${condition.id}`}
                    className={styles.conditionManagementDropdown}
                >
                    {canDuplicate && (
                        <Dropdown.Item onClick={() => onDuplicate(condition)}>
                            Duplicate
                        </Dropdown.Item>
                    )}
                    {canRemove && (
                        <Dropdown.Item
                            onClick={() => onRemove(condition.id)}
                            className="text-danger"
                        >
                            Remove
                        </Dropdown.Item>
                    )}
                </ManagementDropdown>
            )}
        </>
    );
};
