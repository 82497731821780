import React from "react";
import { Navigate, useLocation } from "react-router-dom";

export const DocsToDocumentsRedirect: React.FC = () => {
    const location = useLocation();
    return (
        <Navigate
            to={{
                ...location,
                pathname: location.pathname.replace("/docs", "/documents"),
            }}
        />
    );
};
