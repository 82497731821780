import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryParams, StringParam, withDefault } from "use-query-params";
import { PageCoverLoader } from "../general/PageCoverLoader/PageCoverLoader";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import { useBillingStatus } from "../../hooks/useBillingStatus";
import { useUpgradeMutation } from "../../mutations/flatRateBilling";
import { FLAT_RATE_PLANS } from "../../common/flatRateBilling";

interface CheckoutRouteParams {
    plan: FLAT_RATE_PLANS;
}

export const Upgrade: React.FC = () => {
    const navigate = useNavigate();
    const { plan } = useParams() as Readonly<CheckoutRouteParams>;
    const [{ after: afterCheckoutUrl, code, intent: intentId }] =
        useQueryParams({
            after: StringParam,
            code: withDefault(StringParam, undefined),
            intent: withDefault(StringParam, undefined),
        });

    const { query } = useBillingStatus();
    const afterCheckout = useCallback(async () => {
        await query.refetch();
        navigate(afterCheckoutUrl ?? "/settings/billing");
    }, [afterCheckoutUrl, navigate, query]);

    const subscribeMutation = useUpgradeMutation(
        {
            plan,
            code,
            intentId,
        },
        afterCheckout,
    );

    useEffectOnce(() => {
        subscribeMutation.mutate();
    });

    return <PageCoverLoader>Starting autopilot</PageCoverLoader>;
};
