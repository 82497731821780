import classnames from "classnames";
import React from "react";
import { ChildrenProps } from "../../types";
import { useNavigationContext } from "./NavigationContext";
import "./NavigationContainer.scss";

export const NavigationContainer: React.FC<ChildrenProps> = ({ children }) => {
    const { isOpen, close } = useNavigationContext();

    return (
        <div
            className={classnames("navigation-container", {
                "navigation-container--open": isOpen,
            })}
        >
            <div className="navigation-container__content">{children}</div>
            <aside className="navigation-container__backdrop" onClick={close} />
        </div>
    );
};
