import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createRule, updateRule } from "../lib/transactionRules";
import { CreateTransactionRuleDto } from "../common/dto/transactionRule/create-transaction-rule.dto";
import { GetTransactionRulesResponse } from "../common/dto/transactionRule/get-transaction-rules-response.dto";
import { TransactionRule } from "../common/types/transactionRule";
import { UpdateTransactionRuleDto } from "../common/dto/transactionRule/update-transaction-rule.dto";
import { TRANSACTION_RULES_QUERY_KEY } from "../api/transactionRule.api";

export function useRuleCreationMutation(onSuccess?: () => void) {
    const client = useQueryClient();

    return useMutation({
        mutationFn: (data: CreateTransactionRuleDto) => createRule(data),
        onSuccess: (rule) => {
            client.setQueriesData<GetTransactionRulesResponse | undefined>(
                { queryKey: [TRANSACTION_RULES_QUERY_KEY] },
                (data) => {
                    if (!data) {
                        return;
                    }

                    return {
                        ...data,
                        rules: [...data.rules, rule],
                    };
                },
            );
            client.invalidateQueries({
                queryKey: [TRANSACTION_RULES_QUERY_KEY],
            });
            onSuccess?.();
        },
    });
}

export function useRuleUpdateMutation(
    rule: TransactionRule,
    onSuccess?: () => void,
) {
    const client = useQueryClient();

    return useMutation({
        mutationFn: (data: UpdateTransactionRuleDto) => updateRule(rule, data),

        onSuccess: (updatedRule) => {
            client.setQueriesData<GetTransactionRulesResponse | undefined>(
                { queryKey: [TRANSACTION_RULES_QUERY_KEY] },
                (data) => {
                    if (!data) {
                        return;
                    }

                    return {
                        ...data,
                        rules: data.rules.map((r) =>
                            r.id === updatedRule.id ? updatedRule : r,
                        ),
                    };
                },
            );
            client.invalidateQueries({
                queryKey: [TRANSACTION_RULES_QUERY_KEY],
            });
            onSuccess?.();
        },
    });
}
