import React from "react";
import classNames from "classnames";
import { numberFormatter } from "../../../common/helpers/number";

interface Props {
    showNegative?: boolean;
    showPositive?: boolean;
    separateCurrencySign?: boolean;
    amount: number;
    className?: string;
}

export const Amount: React.FC<Props> = ({
    amount,
    showNegative,
    showPositive,
    separateCurrencySign,
    className,
}) => {
    const sign = (
        <span className="amount__sign">
            {showNegative && amount < 0 ? "- " : ""}
            {showPositive && amount > 0 ? "+ " : ""}
        </span>
    );

    return (
        <span className={classNames("amount", className)} data-testid="amount">
            {separateCurrencySign && (
                <>
                    {sign}
                    <span className="amount__currency">$</span>
                </>
            )}
            <span className="amount__dollars">
                {!separateCurrencySign && <>{sign} $</>}
                {numberFormatter.format(Math.floor(Math.abs(amount)))}
            </span>
            <span className="amount__cents">
                .
                {(Math.round(Math.abs(amount) * 100) % 100)
                    .toString()
                    .padStart(2, "0")}
            </span>
        </span>
    );
};
