import React from "react";
import classNames from "classnames";
import { ChildrenProps } from "../../../types";

interface Props extends ChildrenProps {
    depth?: number;
    isTotal?: boolean;
    isSummary?: boolean;
}

export const DataTableRow: React.FC<Props> = ({
    depth = 0,
    isTotal,
    isSummary,
    children,
}) => (
    <tr
        className={classNames(
            `data-table__row data-table__row--depth-${depth}`,
            {
                "data-table__row--total": isTotal,
                "data-table__row--summary-section": isSummary,
            },
        )}
    >
        {children}
    </tr>
);
