import classNames from "classnames";
import { useState } from "react";
import { useTransactionRules } from "../../../hooks/useTransactionRules";
import { useCategoryLabelGetter } from "../../../hooks/useCategoryLabelGetter";
import { useEntities } from "../../../hooks/useEntities";
import { useFinancialAccounts } from "../../../hooks/useFinancialAccounts";
import { RulesPageHeader } from "./RulesPageHeader";
import { RulesSettingsContent } from "./RulesSettingsContent";
import styles from "./RulesPage.module.scss";
import { buildRulesItems } from "./utils";

export const RulesPage: React.FC = () => {
    const categoryLabelGetter = useCategoryLabelGetter();
    const entities = useEntities();
    const accounts = useFinancialAccounts();

    const rules = useTransactionRules();

    const [searchQuery, setSearchQuery] = useState("");

    const filteredRules = buildRulesItems({
        search: searchQuery,
        categoryLabelGetter,
        transactionResponse: rules,
        entities,
        accounts,
    });

    return (
        <div className={classNames(styles.rules, "settings__content")}>
            <RulesPageHeader setSearch={setSearchQuery} search={searchQuery} />
            <RulesSettingsContent data={filteredRules} />
        </div>
    );
};
