import { FinancialAccount } from "../../common/types/financialAccount";
import { FinancialConnectionWithAccounts } from "../../common/types/financialConnection";
import { IntegrationConnection } from "../../common/types/integrationConnection";

export function isValidAccountEntity(account: FinancialAccount) {
    return !!account.entity;
}
export function isValidAccount(account: FinancialAccount) {
    return isValidAccountEntity(account);
}

export function isValidConnection(connection: FinancialConnectionWithAccounts) {
    return connection.accounts.every((integrationAccount) =>
        isValidAccount(integrationAccount.financialAccount),
    );
}

export function isValidIntegrationConnection(
    connection: IntegrationConnection,
) {
    return (
        connection.integrationAccounts?.every((integrationAccount) =>
            isValidAccount(integrationAccount.financialAccount),
        ) ?? false
    );
}
