import {
    TransactionsFilters,
    transactionsFiltersSchema,
} from "../components/transactions/filters/lib";
import {
    useWorkspaceUserCache,
    WORKSPACE_USER_CACHE_KEYS,
} from "./useWorkspaceUserCache";

export const FILTER_PARAMS_TO_CACHE: Record<
    keyof TransactionsFilters,
    boolean
> = {
    requiredAction: true,
    entityIds: true,
    accountIds: true,
    category: true,
    start: true,
    end: true,
    hasNote: true,
    noNote: true,
    hasReceipt: true,
    noReceipt: true,
    requiresReceipt: true,
    isMatched: true,
    isNotMatched: true,
    requiresNote: true,
    isSplitted: true,
    isNotSplitted: true,
    transactionRulesIds: true,
    counterpartiesIds: true,
    maxAmount: true,
    minAmount: true,
    direction: true,
    hasTransferMatch: false,
    classification: false,
    search: false,
    statuses: false,
    classes: true,
    classFilterType: true,
    processedByML: false,
};

const sanitizeFilters = (
    filters: TransactionsFilters | undefined,
): TransactionsFilters | undefined => {
    if (!filters) {
        return undefined;
    }

    const clearedFilters: Record<string, unknown> = {};

    Object.keys(filters).forEach((key) => {
        if (FILTER_PARAMS_TO_CACHE[key as keyof TransactionsFilters]) {
            clearedFilters[key] = filters[key as keyof TransactionsFilters];
        }
    });

    return clearedFilters as TransactionsFilters;
};

export function useTransactionsPageFiltersCache(): [
    TransactionsFilters | undefined,
    (value: TransactionsFilters | undefined) => void,
] {
    const [value, setter] = useWorkspaceUserCache<TransactionsFilters>(
        WORKSPACE_USER_CACHE_KEYS.TRANSACTIONS_PAGE_FILTERS_CACHE_KEY,
        transactionsFiltersSchema,
    );

    return [
        value,
        (filters: TransactionsFilters | undefined) => {
            setter(sanitizeFilters(filters));
        },
    ];
}
