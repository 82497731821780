import React from "react";
import { Button } from "../../../general/Button/Button";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";

interface Props {
    valid: boolean;
    loading?: boolean;
    onCancel(): void;
}

export const UpdateProfileFormFooter: React.FC<Props> = ({
    loading,
    valid,
    onCancel,
}) => (
    <footer className="d-flex justify-content-end gap-1">
        <Button
            className="mr-2"
            variant="secondary"
            size="lg"
            onClick={onCancel}
        >
            Cancel
        </Button>

        <ButtonWithLoader
            disabled={!valid}
            loading={!!loading}
            type="submit"
            size="lg"
        >
            Next
        </ButtonWithLoader>
    </footer>
);
