import {
    endOfISOWeek,
    endOfMonth,
    parse,
    startOfISOWeek,
    startOfMonth,
} from "date-fns";
import { uniq } from "lodash";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { MoneyDirection, ReportingTabCycle } from "../../common/constants";
import { CashFlowSectionId } from "../../common/constants/reports/cashflow.constant";
import {
    CashFlowTableData,
    CashFlowTableRow,
} from "../../common/dto/reports/cash-flow-tab-report-response.dto";
import { NonUndefined } from "../../common/helpers/typescript";
import { ReportFilters } from "../../common/types/filters/reports";
import { TransactionStatus } from "../../common/types/transaction";
import {
    StandardModal,
    StandardModalBody,
    StandardModalHeader,
} from "../general/Modal/Modal";
import { TransactionsFilters } from "../transactions/filters/lib";
import { NoMatchingTransactions } from "../transactions/NoMatchingTransactions";
import { NoTransactions } from "../transactions/NoTransactions";
import { PaginatedTransactionsTable } from "../transactions/PaginatedTransactionsTable";
import {
    PaginatedTransactionsTableContext,
    PaginatedTransactionsTableContextProvider,
} from "../transactions/PaginatedTransactionsTableContextProvider";
import { TransactionsTableProps } from "../transactions/TransactionsTable";
import { useFiltersActive } from "../transactions/useFiltersActive";
import { useCashFlowDrillDownContext } from "./CashFlowDrillDown.context";
import "./CashFlowDrillDownModal.scss";
import { CashFlowDrillDownModalHeader } from "./CashFlowDrillDownModalHeader";

export interface CashFlowDrillDownModalProps {
    tableData: CashFlowTableData;
    filters: ReportFilters;
}

function findRowByPath(
    rows: CashFlowTableRow[],
    pathIds: string[],
): CashFlowTableRow | undefined {
    if (pathIds.length === 0) {
        return undefined;
    }

    const [currentId, ...restPathIds] = pathIds;

    for (const row of rows) {
        if (row.id === currentId) {
            if (restPathIds.length === 0) {
                return row;
            } else if (row.children) {
                return findRowByPath(row.children, restPathIds);
            } else {
                return undefined;
            }
        }
    }

    return undefined;
}

export function getCategoriesFromCashFlowRowsByPath(
    rows: CashFlowTableRow[],
    pathIds: string[],
): {
    categoriesInRow: string[];
} {
    if (pathIds[0] === "cash-generated") {
        const cashIn = getCategoriesFromCashFlowRowsByPath(rows, ["cash-in"]);
        const cashOut = getCategoriesFromCashFlowRowsByPath(rows, ["cash-out"]);

        return {
            categoriesInRow: [
                ...cashIn.categoriesInRow,
                ...cashOut.categoriesInRow,
            ],
        };
    }

    let row = findRowByPath(rows, pathIds);

    if (row?.isTotal) {
        row = findRowByPath(rows, pathIds.slice(0, -1));
    }

    const categoriesInRow = row ? getCategoriesFromRows([row]) : [];

    return {
        categoriesInRow,
    };
}

function getCategoriesFromRows(rows: CashFlowTableRow[]): string[] {
    return rows.flatMap((row) => {
        const categories = row.filters?.category ?? [];
        const childCategories = row.children
            ? getCategoriesFromRows(row.children)
            : [];
        return [...categories, ...childCategories];
    });
}

export const CashFlowDrillDownModal: React.FC<CashFlowDrillDownModalProps> = ({
    tableData,
    filters: { cycle, ...filtersWithoutCycle },
}) => {
    const [page, setPage] = useState(1);
    const { params, close } = useCashFlowDrillDownContext();
    const shouldShowModal = params != null;
    const { category, path, columnId } = params ?? {};

    const parentFilters = useMemo<TransactionsFilters>(() => {
        const pathIds = path?.map((p) => p.id) ?? [];
        const { categoriesInRow } = getCategoriesFromCashFlowRowsByPath(
            tableData.rows,
            pathIds,
        );

        const categoryToUse = uniq(category ?? categoriesInRow);

        let newDates = {};
        if (columnId && !["percent", "title", "total"].includes(columnId)) {
            const endDate =
                cycle === ReportingTabCycle.MONTH
                    ? endOfMonth(parse(columnId, "yyyy-MM", new Date()))
                    : endOfISOWeek(parse(columnId, "yyyy-MM-dd", new Date()));
            const startDate =
                cycle === ReportingTabCycle.MONTH
                    ? startOfMonth(endDate)
                    : startOfISOWeek(endDate);

            newDates = {
                start: startDate,
                end: endDate,
            };
        }

        let direction: MoneyDirection | undefined;

        if (pathIds.includes(CashFlowSectionId.cashIn)) {
            direction = MoneyDirection.MONEY_IN;
        } else if (pathIds.includes(CashFlowSectionId.cashOut)) {
            direction = MoneyDirection.MONEY_OUT;
        }

        return {
            ...filtersWithoutCycle,
            ...newDates,
            category: categoryToUse,
            statuses: [TransactionStatus.PENDING, TransactionStatus.COMPLETED],
            counterpartiesIds: params?.counterpartyId,
            direction,
        };
    }, [
        category,
        filtersWithoutCycle,
        columnId,
        cycle,
        tableData,
        path,
        params?.counterpartyId,
    ]);

    const renderEmptyState = useCallback<
        NonUndefined<TransactionsTableProps["renderEmptyState"]>
    >(
        () => (
            <CashFlowDrillDownModalEmptyState parentFilters={parentFilters} />
        ),
        [parentFilters],
    );

    return (
        <StandardModal
            enforceFocus={false}
            show={shouldShowModal}
            onHide={close}
            className="cash-flow-modal"
        >
            <StandardModalHeader>Transactions</StandardModalHeader>
            <StandardModalBody>
                <PaginatedTransactionsTableContextProvider
                    parentFilters={parentFilters}
                    page={page}
                    setPage={setPage}
                    useCache={false}
                >
                    <CashFlowDrillDownModalHeader path={path} />
                    <PaginatedTransactionsTable
                        renderEmptyState={renderEmptyState}
                    />
                </PaginatedTransactionsTableContextProvider>
            </StandardModalBody>
        </StandardModal>
    );
};

interface CashFlowDrillDownModalEmptyStateProps {
    parentFilters: TransactionsFilters;
}

const CashFlowDrillDownModalEmptyState: React.FC<
    CashFlowDrillDownModalEmptyStateProps
> = ({ parentFilters }) => {
    const { filters: currentFilters, setCurrentFilters } = useContext(
        PaginatedTransactionsTableContext,
    );
    const filtersActive = useFiltersActive(currentFilters);

    if (filtersActive) {
        return (
            <NoMatchingTransactions
                onResetFilters={() => setCurrentFilters(parentFilters)}
            />
        );
    }

    return <NoTransactions />;
};
