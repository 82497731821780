import { useCallback } from "react";
import { difference, uniq } from "lodash";
import { useEntities } from "../../../hooks/useEntities";
import { useFinancialAccounts } from "../../../hooks/useFinancialAccounts";
import { Entity } from "../../../common/types/entity";
import { useAccountIdsByEntity } from "./useAccountIdsByEntity";

interface UseAccountFilterOptions {
    value: number[];
    onChange(value: number[]): void;
    onlyBusiness?: boolean;
    excludeAllMockEntities?: boolean;
    excludeEmptyMockEntities?: boolean;
    excludeNoBalanceAccounts?: boolean;
    allSelectedByDefault?: boolean;
    entities?: Entity[];
}
export function useAccountFilter({
    value,
    onChange,
    excludeAllMockEntities,
    excludeEmptyMockEntities,
    onlyBusiness,
    excludeNoBalanceAccounts,
    allSelectedByDefault,
    entities,
}: UseAccountFilterOptions) {
    const entitiesFromFilters = useEntities({
        onlyBusiness,
        excludeAllMockEntities,
        excludeEmptyMockEntities,
    });
    const entitiesToShow = entities ?? entitiesFromFilters;
    const availableAccounts = useFinancialAccounts({
        excludeNoBalanceAccounts,
        onlyBusiness,
    });

    const accountKeysByEntity = useAccountIdsByEntity();

    const isEntitySelected = useCallback(
        (entityId: number) =>
            (!value.length && allSelectedByDefault) ||
            accountKeysByEntity[entityId].some((key) => value.includes(key)),
        [accountKeysByEntity, value, allSelectedByDefault],
    );

    const isAccountSelected = useCallback(
        (accountId: number) =>
            (!value.length && allSelectedByDefault) ||
            value.includes(accountId),
        [value, allSelectedByDefault],
    );

    const prepareValue = useCallback(
        () =>
            value.length || !allSelectedByDefault
                ? value
                : availableAccounts.map((account) => account.id),
        [value, availableAccounts, allSelectedByDefault],
    );

    const handleAddedAccounts = useCallback(
        (newValue: number[]) => {
            if (newValue.length === availableAccounts.length) {
                onChange([]);
            } else {
                onChange(newValue);
            }
        },
        [onChange, availableAccounts],
    );

    const onToggleAccount = useCallback(
        (accountId: number) => {
            const processedValue = prepareValue();

            if (processedValue.includes(accountId)) {
                onChange(processedValue.filter((key) => key !== accountId));
            } else {
                handleAddedAccounts(uniq([...processedValue, accountId]));
            }
        },
        [onChange, prepareValue, handleAddedAccounts],
    );

    const onSelectAccounts = useCallback(
        (accountsIds: number[]) => {
            handleAddedAccounts(uniq([...value, ...accountsIds]));
        },
        [handleAddedAccounts, value],
    );

    const onDeselectAccounts = useCallback(
        (accountsIds: number[]) => {
            const processedValue = prepareValue();
            onChange(difference(processedValue, accountsIds));
        },
        [onChange, prepareValue],
    );

    const toggleEntity = useCallback(
        (entityId: number) => {
            if (isEntitySelected(entityId)) {
                onDeselectAccounts(accountKeysByEntity[entityId]);
            } else {
                onSelectAccounts(accountKeysByEntity[entityId]);
            }
        },
        [
            isEntitySelected,
            onDeselectAccounts,
            onSelectAccounts,
            accountKeysByEntity,
        ],
    );

    return {
        entities: entitiesToShow,
        isEntitySelected,
        isAccountSelected,
        onToggleAccount,
        toggleEntity,
    };
}
