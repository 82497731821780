import React from "react";
import { PlaidConnectionWithAccounts } from "../../../common/types/plaidConnection";
import { AccountsConnectionWidget } from "../../financialAccount/AccountConnectionWidget/AccountsConnectionWidget";
import { PlaidConnectionIcon } from "../PlaidConnectionIcon";
import { InvalidPlaidAccount } from "../../../common/dto/plaid/plaid-common";
import { PlaidInvalidAccountsList } from "./PlaidInvalidAccountsList";

interface Props {
    connection: PlaidConnectionWithAccounts;
    connectionSaved: boolean;
    foreign: InvalidPlaidAccount[] | null;
}

export const PlaidAccountsConnectedModalBody: React.FC<Props> = ({
    connection,
    connectionSaved,
    foreign,
}) => {
    const accounts = connection.accounts;

    return (
        <>
            {connectionSaved && (
                <AccountsConnectionWidget
                    heading={
                        <div className="d-flex align-items-center">
                            <PlaidConnectionIcon connection={connection} />

                            <h6 className="ml-3">{connection.bankName}</h6>
                        </div>
                    }
                    accounts={accounts}
                    connection={connection}
                />
            )}

            {foreign && foreign.length > 0 && (
                <>
                    <p className="mt-3">
                        The following <strong>{connection.bankName}</strong>{" "}
                        accounts hold currency different than USD and are not
                        currently supported by Kick:
                    </p>
                    <PlaidInvalidAccountsList invalidAccounts={foreign} />
                </>
            )}
        </>
    );
};
