import { FinancialConnectionWithAccounts } from "../common/types/financialConnection";
import { FinancialConnectionDto } from "../common/dto/financialAccount/financial-connection.dto";
import { CreateGustoOauthConnectionDto } from "../common/dto/gusto/create-gusto-oauth-connection.dto";
import { Entity } from "../common/types/entity";
import { GustoConnection } from "../common/types/gustoConnection";
import { backendClient, unwrapResponse } from "./backendClient";

export async function createGustoConnection(
    data: CreateGustoOauthConnectionDto,
): Promise<FinancialConnectionWithAccounts> {
    return FinancialConnectionDto.deserialize(
        unwrapResponse(await backendClient.post("/gusto-connection", data)),
    );
}

export async function getGustoConnectionForEntity(
    entity: Entity,
): Promise<FinancialConnectionWithAccounts | null> {
    const response = unwrapResponse(
        await backendClient.get(`/gusto-connection/entity/${entity.id}`),
    );
    return response ? FinancialConnectionDto.deserialize(response) : null;
}

export async function syncGustoConnection(connection: GustoConnection) {
    return FinancialConnectionDto.deserialize(
        unwrapResponse(
            await backendClient.post(`/gusto-connection/sync/${connection.id}`),
        ),
    );
}

export async function fixGustoConnection(
    connection: GustoConnection,
    code: string,
) {
    return FinancialConnectionDto.deserialize(
        unwrapResponse(
            await backendClient.post(`/gusto-connection/fix/${connection.id}`, {
                code,
            }),
        ),
    );
}

export async function getGustoConnections(): Promise<
    FinancialConnectionWithAccounts[]
> {
    const response = unwrapResponse(
        await backendClient.get("/gusto-connection"),
    );
    return response.map((dto: FinancialConnectionDto) =>
        FinancialConnectionDto.deserialize(dto),
    );
}
