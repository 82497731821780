import classNames from "classnames";
import { useCallback, useMemo, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";

import { FilterSearch } from "../../general/FilterSearch/FilterSearch";
import { TreeProvider } from "../../general/Tree/TreeProvider";
import { TreeHelpers, TreeNode } from "../../general/Tree/Tree.types";
import { Class } from "../../../common/types/class";
import { useClasses } from "../../../api/class.api";
import { Tree } from "../../general/Tree/Tree";
import { ClassWithChildren } from "../../../helpers/class";
import { FormCheckbox } from "../../forms/FormCheckbox/FormCheckbox";
import { TransactionClassFilterType } from "../../../common/helpers/transactions";
import { SelectDropdownDivider } from "../../general/SelectDropdown/SelectDropdownDivider";
import { getKeyboardCommandsListenerFunction } from "../../../hooks/keyboard/useKeyboardCommands";
import { FilterActionsFooter } from "../../general/Filter/FilterActionsFooter/FilterActionsFooter";
import styles from "./styles.module.scss";
import { ClassTreeItem } from "./ClassTreeItem";

type NodeType = TreeNode<Class>;

const classFilterSettings = [
    {
        label: "Any selected class",
        option: TransactionClassFilterType.ANY_SELECTED,
    },
    {
        label: "All selected classes",
        option: TransactionClassFilterType.ALL_SELECTED,
    },
    {
        label: "Exclude selected classes",
        option: TransactionClassFilterType.EXCLUDE_SELECTED,
    },
    {
        label: "No classes",
        option: TransactionClassFilterType.NO_CLASSES,
    },
];

export interface ClassFilterOverlayProps {
    selectedClasses: string[];
    onChange: (classes: string[]) => void;
    behaviour: {
        selectedParentClassIncludesAllChildren: boolean;
        canSelectOnlyOneClassInTheSameRoot: boolean;
        bottomButtonIsAlwaysVisible?: boolean;
    };
    containerClassName?: string;
    applyButtonOnClick?: () => void;
    resetButtonOnClick?: () => void;
    classFilterType?: TransactionClassFilterType;
    onSettingsChange?: (option: TransactionClassFilterType) => void;
    showFilterTypeSettings?: boolean;
    scrollToClassOnOpen?: string;
    useAutoHeight?: boolean;
}

export function ClassFilterOverlay({
    selectedClasses,
    onChange,
    behaviour: {
        selectedParentClassIncludesAllChildren,
        canSelectOnlyOneClassInTheSameRoot,
        bottomButtonIsAlwaysVisible = false,
    },
    applyButtonOnClick,
    resetButtonOnClick,
    classFilterType: settingsOption,
    onSettingsChange,
    showFilterTypeSettings,
    containerClassName,
    scrollToClassOnOpen,
    useAutoHeight,
}: ClassFilterOverlayProps) {
    const [search, setSearch] = useState("");
    const searchRef = useRef<HTMLInputElement>();
    const scrollbarRef = useRef<Scrollbars>(null);

    const { classes, classesMap, classesWithChildren } = useClasses();

    const renderNode = useCallback(
        (node: NodeType, helpers: TreeHelpers) => (
            <ClassTreeItem
                hasSelectedChildren={(id) =>
                    classesMap
                        .get(id)
                        ?.children.some((child) =>
                            selectedClasses.includes(child.id),
                        ) ?? false
                }
                {...helpers}
                disabled={
                    settingsOption === TransactionClassFilterType.NO_CLASSES
                }
                node={node}
                toggleSelected={(classId) => {
                    const newSelectedClasses = new Set(selectedClasses);
                    const shouldAdd = !newSelectedClasses.has(classId);
                    if (shouldAdd) {
                        newSelectedClasses.add(classId);
                    } else {
                        newSelectedClasses.delete(classId);
                    }
                    const classInstance = classesMap.get(classId);
                    if (selectedParentClassIncludesAllChildren) {
                        classInstance?.children.forEach((child) =>
                            newSelectedClasses[shouldAdd ? "add" : "delete"](
                                child.id,
                            ),
                        );
                    }
                    if (canSelectOnlyOneClassInTheSameRoot) {
                        classes.forEach((c) => {
                            if (
                                c.rootClassId === classInstance?.rootClassId &&
                                c.id !== classInstance.id
                            ) {
                                newSelectedClasses.delete(c.id);
                            }
                        });
                    }

                    onChange(Array.from(newSelectedClasses));
                }}
                isSelected={(item) => selectedClasses.includes(item)}
            />
        ),
        [
            selectedClasses,
            onChange,
            classesMap,
            selectedParentClassIncludesAllChildren,
            canSelectOnlyOneClassInTheSameRoot,
            classes,
            settingsOption,
        ],
    );

    const filteredClassesWithChildren = useMemo(
        () =>
            classesWithChildren
                .map((c) => {
                    if (c.label.toLowerCase().includes(search.toLowerCase())) {
                        return c;
                    }
                    const children = c.children.filter((child) =>
                        child.label
                            .toLowerCase()
                            .includes(search.toLowerCase()),
                    );
                    if (children.length) {
                        return { ...c, children };
                    }
                    return null;
                })
                .filter(Boolean) as ClassWithChildren[],
        [classesWithChildren, search],
    );

    const initiallyExpanded = useMemo(
        () =>
            filteredClassesWithChildren
                .filter(
                    (c) =>
                        selectedClasses.includes(c.id) ||
                        c.children.some((child) =>
                            selectedClasses.includes(child.id),
                        ),
                )
                .map((c) => c.id),
        [filteredClassesWithChildren, selectedClasses],
    );

    const containerRef = useRef<HTMLDivElement | null>(null);

    const getInteractiveElements = useCallback(() => {
        const activeElement = document.activeElement;
        const elements = containerRef.current?.querySelectorAll(
            `${showFilterTypeSettings ? `.${styles.filterTypeSettings} > label, ` : ""}li > div, button.${styles.bottomButton}`,
        );
        const elementsArray = elements
            ? ([searchRef.current, ...Array.from(elements)] as HTMLElement[])
            : null;

        return {
            elements: elementsArray,
            activeElementIndex:
                elementsArray?.indexOf(activeElement as HTMLElement) ?? -1,
        };
    }, [showFilterTypeSettings]);

    const navigateDown = useCallback(() => {
        const { elements, activeElementIndex } = getInteractiveElements();

        if (elements) {
            const nextElement = elements[activeElementIndex + 1];
            if (nextElement) {
                nextElement.focus();
            }
        }
    }, [getInteractiveElements]);

    const navigateUp = useCallback(() => {
        const { elements, activeElementIndex } = getInteractiveElements();
        if (elements) {
            const previousElement = elements[activeElementIndex - 1];
            if (previousElement) {
                previousElement.focus();
            }
        }
    }, [getInteractiveElements]);

    const selectActiveOption = useCallback(() => {
        if (document.activeElement instanceof HTMLElement) {
            (document.activeElement as HTMLElement)
                .querySelector("input")
                ?.click();
        }
    }, []);

    const navigateRightOrLeft = useCallback(() => {
        const activeElement = document.activeElement;
        activeElement?.querySelector("button")?.click();
    }, []);

    const commands = useMemo(
        () => [
            {
                key: "ArrowDown",
                callback: navigateDown,
                requiresCtrlOrMeta: false,
                ignoreForms: false,
            },
            {
                key: "ArrowUp",
                callback: navigateUp,
                requiresCtrlOrMeta: false,
                ignoreForms: false,
            },
            {
                key: "Enter",
                callback: selectActiveOption,
                requiresCtrlOrMeta: false,
                preventDefault: false,
                stopPropagation: false,
                ignoreForms: false,
            },
            {
                key: "ArrowRight",
                callback: navigateRightOrLeft,
                requiresCtrlOrMeta: false,
                ignoreForms: false,
            },
            {
                key: "ArrowLeft",
                callback: navigateRightOrLeft,
                requiresCtrlOrMeta: false,
                ignoreForms: false,
            },
        ],
        [navigateDown, navigateUp, selectActiveOption, navigateRightOrLeft],
    );

    const activateListeners = useCallback(
        (element: any) => {
            containerRef.current = element;

            if (!element) {
                return;
            }
            const listener = getKeyboardCommandsListenerFunction({
                commands,
            });
            element.addEventListener("keydown", listener);
        },
        [commands],
    );

    const onResetClick = useCallback(() => {
        if (resetButtonOnClick) {
            resetButtonOnClick();
        } else {
            onChange([]);
        }
        const { elements } = getInteractiveElements();
        if (elements) {
            elements[0].focus();
        }
    }, [onChange, resetButtonOnClick, getInteractiveElements]);

    return (
        <div
            className={classNames(styles.filter, containerClassName)}
            ref={activateListeners}
        >
            <FilterSearch
                value={search}
                onChange={setSearch}
                inputRef={searchRef}
                focus={scrollToClassOnOpen === undefined}
            />

            <Scrollbars
                style={{ height: "100%", width: "100%" }}
                ref={scrollbarRef}
                {...(useAutoHeight
                    ? {
                          autoHeight: true,
                          autoHeightMin: 80,
                          autoHeightMax: 400,
                      }
                    : {})}
            >
                {showFilterTypeSettings && (
                    <>
                        <div className={styles.filterTypeSettings}>
                            {classFilterSettings.map(({ label, option }) => (
                                <FormCheckbox
                                    key={option}
                                    value={option ?? ""}
                                    type="radio"
                                    name="direction"
                                    label={label}
                                    isChecked={option === settingsOption}
                                    handleChange={onSettingsChange}
                                    small
                                />
                            ))}
                        </div>

                        <SelectDropdownDivider />
                    </>
                )}

                {/* NOTE: do not remove tree from the render, 
                otherwise it will be automatically scrolled second time after next mounting */}
                <section
                    className={classNames(styles.section, {
                        [styles.emptyTree]:
                            filteredClassesWithChildren.length === 0,
                    })}
                >
                    <TreeProvider
                        items={filteredClassesWithChildren}
                        expandAll={!!search}
                        initiallyExpanded={initiallyExpanded}
                    >
                        <Tree
                            renderNode={renderNode}
                            scrollbarRef={scrollbarRef}
                            scrollToNodeIdOnOpen={scrollToClassOnOpen}
                        />
                    </TreeProvider>
                </section>
                {filteredClassesWithChildren.length === 0 && (
                    <p className={styles.empty}>No matching classes</p>
                )}
            </Scrollbars>

            {bottomButtonIsAlwaysVisible || selectedClasses.length ? (
                <FilterActionsFooter
                    deselectFunction={onResetClick}
                    selectedCount={selectedClasses.length}
                    applyFunction={applyButtonOnClick}
                />
            ) : null}
        </div>
    );
}
