import React, { useEffect } from "react";
import { Field, Form, useFormikContext } from "formik";
import { UploadingDocumentPlaceholder } from "../documents/lib";
import { useFinancialDocumentUpload } from "../documents/FinancialDocumentUpload/useFinancialDocumentUpload";
import { FormRow } from "../forms/FormRow";
import {
    ALLOWED_FINANCIAL_ACCOUNT_TYPES_FOR_FILE_IMPORT,
    FINANCIAL_ACCOUNT_TYPE_LABEL,
} from "../../common/constants";
import { EntitySelect } from "../entity/EntitySelect";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";
import { useEntities } from "../../hooks/useEntities";
import { ImportedDocuments } from "./ImportedDocuments";
import styles from "./ManualImportAccountModal.module.scss";
import { UniversalImportDropzone } from "./UniversalImportDropzone";
import { isBankWithoutAccountNumber } from "./lib";

interface Props {
    onRemoveItem: (item: UploadingDocumentPlaceholder) => void;
    showDetails: boolean;
    creatingAccount: boolean;
}

interface FormValues {
    bankName: string;
    accountNumber: string;
    accountType: string;
    entityId: string;
}

export const UniversalImport: React.FC<Props> = ({
    onRemoveItem,
    showDetails,
    creatingAccount,
}) => {
    const allowedEntities = useEntities({ excludeAllMockEntities: true });
    const { uploadingDocuments } = useFinancialDocumentUpload();
    const { isValid, values, setFieldValue } = useFormikContext<FormValues>();

    const isAccountNumberDisabled = isBankWithoutAccountNumber(values.bankName);

    useEffect(() => {
        if (isAccountNumberDisabled) {
            setFieldValue("accountNumber", "");
        }
    }, [setFieldValue, isAccountNumberDisabled]);

    return (
        <Form>
            <FormRow
                fieldName="name"
                size="sm"
                label="Account nickname (optional)"
            />

            <section className="mb-4">
                <UniversalImportDropzone>
                    <ImportedDocuments
                        items={uploadingDocuments}
                        onRemoveItem={onRemoveItem}
                        showTransactionsCount={false}
                    />
                </UniversalImportDropzone>
            </section>

            {showDetails && (
                <section className={styles.accountDetails}>
                    <FormRow fieldName="bankName" size="sm" label="Bank name" />

                    <FormRow
                        fieldName="accountNumber"
                        size="sm"
                        label="Account number"
                        disabled={isAccountNumberDisabled}
                        placeholder={isAccountNumberDisabled ? "" : "XXXX"}
                    />

                    <FormRow fieldName="accountType" label="Account type">
                        <Field
                            name="accountType"
                            as="select"
                            className="form-control form-control-sm"
                        >
                            <option value="">Select</option>
                            {ALLOWED_FINANCIAL_ACCOUNT_TYPES_FOR_FILE_IMPORT.map(
                                (type) => (
                                    <option key={type} value={type}>
                                        {FINANCIAL_ACCOUNT_TYPE_LABEL[type]}
                                    </option>
                                ),
                            )}
                        </Field>
                    </FormRow>

                    <FormRow fieldName="entityId">
                        {({ setValue }, { value }) => (
                            <EntitySelect
                                label="Entity"
                                entities={allowedEntities}
                                selectedEntity={allowedEntities.find(
                                    (e) => e.id === value,
                                )}
                                onChange={(e) => setValue(e?.id)}
                                dropdownKey="importer-entity-select"
                                size="sm"
                            />
                        )}
                    </FormRow>
                </section>
            )}

            {showDetails && (
                <footer className="text-right">
                    <ButtonWithLoader
                        loading={creatingAccount}
                        type="submit"
                        disabled={!isValid}
                    >
                        Create Account
                    </ButtonWithLoader>
                </footer>
            )}
        </Form>
    );
};
