import React from "react";

export interface CaptureInteractionsProps {
    className?: string;
    children: React.ReactNode;
}
export const CaptureInteractions: React.FC<CaptureInteractionsProps> = ({
    className,
    children,
}) => (
    <span
        className={className}
        onClick={(ev) => ev.stopPropagation()}
        onKeyPress={(ev) => ev.stopPropagation()}
        onKeyDown={(ev) => ev.stopPropagation()}
    >
        {children}
    </span>
);
