import React, { useLayoutEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { TransactionListItemDto } from "../../../common/types/transaction";
import { TransactionStatus } from "../TransactionStatus";
import { useBreakableText } from "../../../hooks/useBreakableText";
import { Amount } from "../../general/Amount/Amount";
import {
    getCounterpartyDirectionLabelForTransaction,
    isVerifiedCounterparty,
} from "../../../common/helpers/counterparties";
import styles from "./TransactionDescriptionColumnContent.module.scss";

interface Props {
    transaction: TransactionListItemDto;
}

export const TransactionDescriptionColumnContent: React.FC<Props> = ({
    transaction,
}) => {
    const isSplit = transaction.splitParentId !== null;

    const breakableDescription = useBreakableText(
        transaction.displayDescription ?? transaction.bankDescription,
    );
    const counterpartyLabel =
        getCounterpartyDirectionLabelForTransaction(transaction);
    const counterpartyToShow =
        transaction.counterparty &&
        isVerifiedCounterparty(transaction.counterparty)
            ? transaction.counterparty
            : null;

    const breakableTextContainer = useRef<HTMLSpanElement>(null);
    const [descriptionWrapped, setDescriptionWrapped] = useState(false);

    useLayoutEffect(() => {
        if (breakableTextContainer.current) {
            setDescriptionWrapped(
                breakableTextContainer.current.clientHeight <
                    breakableTextContainer.current.scrollHeight,
            );
        }
    }, [breakableDescription]);

    const showTooltip = isSplit || !!counterpartyToShow || descriptionWrapped;

    return (
        <div className={styles.content}>
            <OverlayTrigger
                placement="top"
                show={showTooltip ? undefined : false}
                overlay={
                    <Tooltip
                        id={`bankDescription:${transaction.id}`}
                        className="transaction-list-item__description__tooltip"
                    >
                        {isSplit ? (
                            <>
                                <span className="label">Bank Description</span>
                                <p>{transaction.bankDescription} </p>
                                <span className="label">Original Amount</span>
                                <p>
                                    <Amount
                                        amount={transaction.originalAmount}
                                        showNegative
                                    />
                                </p>
                            </>
                        ) : (
                            <>
                                {counterpartyToShow && (
                                    <>
                                        <span className="label">
                                            {counterpartyLabel}
                                        </span>
                                        <p>{counterpartyToShow.name}</p>
                                    </>
                                )}
                                <span className="label">Bank description</span>
                                <p>{transaction.bankDescription}</p>
                            </>
                        )}
                    </Tooltip>
                }
            >
                <span
                    className="transaction-list-item__description__value"
                    data-testid="transaction-list-item-description"
                    ref={breakableTextContainer}
                >
                    {breakableDescription}
                </span>
            </OverlayTrigger>
            <TransactionStatus transaction={transaction} />
        </div>
    );
};
