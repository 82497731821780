import React, { useContext } from "react";
import { CreateCounterparty } from "../../counterparty/CreateCounterparty";
import { DirectoryContext } from "./Directory.context";
import { DirectoryView } from "./DirectoryView";
import { EditCounterparty } from "./EditCounterparty";

export const DirectoryContent: React.FC = () => {
    const { addingNew, setAddingNew, editedItem, setEditedItem } =
        useContext(DirectoryContext);

    if (addingNew) {
        return (
            <CreateCounterparty
                onCreated={() => setAddingNew(false)}
                onCancelled={() => setAddingNew(false)}
            />
        );
    }

    if (editedItem) {
        return (
            <EditCounterparty
                counterparty={editedItem}
                onEdited={() => setEditedItem()}
                onCancelled={() => setEditedItem()}
            />
        );
    }

    return <DirectoryView />;
};
