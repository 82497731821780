import { useMemo } from "react";
import { useUser } from "../../../hooks/useUser";

export function useWidgetOptions() {
    const { email, firstName, lastName, preferredName, legalName } = useUser();

    return useMemo(
        () => ({
            prefill: {
                name: preferredName ?? legalName ?? `${firstName} ${lastName}`,
                email: email ?? "",
                firstName,
                lastName,
            },
            pageSettings: {
                primaryColor: "#1A78C0",
            },
        }),
        [email, firstName, lastName, legalName, preferredName],
    );
}
