import React from "react";
import { TransactionListItemDto } from "../../common/types/transaction";
import { Entity } from "../../common/types/entity";
import { EntityMenuItem } from "../general/EntityMenuItem/EntityMenuItem";

interface Props {
    transaction?: TransactionListItemDto;
    entity: Entity;
    onSelect: () => void;
}

export const TransactionEntityMenuItem: React.FC<Props> = ({
    transaction,
    entity,
    onSelect,
}) => (
    <EntityMenuItem
        onSelect={onSelect}
        entity={entity}
        isSelected={transaction?.entity.id === entity.id}
    />
);
