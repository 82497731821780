import React from "react";
import { useField } from "formik";
import { TransactionRuleConditionFormSchema } from "../constants";
import { CounterpartySelect } from "../../../../counterparty/CounterpartySelect";
import { getCounterpartyDirectionForTaxonomy } from "../../../../../common/helpers/counterparties";
import { useTaxonomyFromRuleConditionsForm } from "./useTaxonomyFromRuleConditionsForm";

interface Props {
    index: number;
}

export const CounterpartyConditionForm: React.FC<Props> = ({ index }) => {
    const [field, , helpers] = useField<
        TransactionRuleConditionFormSchema["counterparty"]
    >(`conditions.${index}.counterparty`);
    const usedTaxonomy = useTaxonomyFromRuleConditionsForm();

    return (
        <CounterpartySelect
            selectedCounterparty={field.value}
            onChange={helpers.setValue}
            size="xs"
            dropdownKey={`counterpartyCondition${index}`}
            counterpartyDirection={getCounterpartyDirectionForTaxonomy(
                usedTaxonomy,
            )}
        />
    );
};
