import React from "react";
import { getAccountingProfitAndLoss } from "../../../lib/accountingReports";
import { AccountingReportFiltersForm } from "../AccountingReportFiltersForm";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { useAccountingTabContext } from "../useAccountingTab";
import { useAccountingReport } from "../useAccountingReport";
import { ReportingTabCycle } from "../../../common/constants";
import { LoaderOnInitialLoading } from "../../general/LoaderOnInitialLoading";
import { useProfitLossDownload } from "./profitLossDownload/useProfitLossDownload";
import { ProfitLossPreviewContent } from "./ProfitLossPreviewContent";

export const ProfitLossPreviewPage: React.FC = () => {
    const { filters, setFilters, selectedEntity } = useAccountingTabContext();
    const { report: profitLoss, generationDate } = useAccountingReport({
        filters,
        entity: selectedEntity,
        queryKey: "accountingProfitLoss",
        reportGetter: getAccountingProfitAndLoss,
        prepareQuery: (query) => {
            if (!query.startDate || !query.endDate) {
                throw new Error(
                    "Start date and end date are required for Profit & Loss report",
                );
            }

            return {
                ...query,
                startDate: query.startDate,
                endDate: query.endDate,
                cycle: ReportingTabCycle.MONTH,
            };
        },
    });

    const { downloading, download } = useProfitLossDownload(
        profitLoss.data ?? undefined,
    );

    return (
        <>
            <AccountingReportFiltersForm
                filters={filters}
                setFilters={setFilters}
                additionalButtons={
                    <>
                        <ButtonWithLoader
                            onClick={download}
                            loading={downloading}
                            disabled={
                                profitLoss.isLoading || !profitLoss.isSuccess
                            }
                        >
                            Download (.xlsx)
                        </ButtonWithLoader>
                    </>
                }
                selectExactDays
                requireDateSelection
            />
            <LoaderOnInitialLoading queryResult={profitLoss}>
                {profitLoss.data && selectedEntity && (
                    <ProfitLossPreviewContent
                        profitLoss={profitLoss.data}
                        filters={filters}
                        generationDate={generationDate}
                        entity={selectedEntity}
                    />
                )}
            </LoaderOnInitialLoading>
        </>
    );
};
