import React, { forwardRef, useImperativeHandle, useState } from "react";
import { isFunction } from "lodash";
import { Button } from "../general/Button/Button";
import {
    AddAccountProps,
    CreateAccountHandle,
} from "../accounts/AddAccounts/lib";
import { RightArrowIcon } from "../../icons";
import { UniversalImportAccountContainer } from "./UniversalImportAccountContainer";

interface UniversalImportTriggerProps extends AddAccountProps {
    children?:
        | React.ReactNode
        | ((props: {
              connecting: boolean;
              initializeConnection: () => void;
          }) => React.ReactNode);
}

export const UniversalImportTrigger = forwardRef<
    CreateAccountHandle,
    UniversalImportTriggerProps
>(({ children, ...otherProps }, ref) => {
    const [connecting, setConnecting] = useState(false);

    useImperativeHandle(
        ref,
        () => ({
            initializeConnection: () => setConnecting(true),
        }),
        [],
    );

    return (
        <>
            {isFunction(children) ? (
                children({
                    connecting,
                    initializeConnection: () => setConnecting(true),
                })
            ) : (
                <Button
                    variant="tertiary"
                    size="sm"
                    icon
                    onClick={() => setConnecting(true)}
                >
                    <RightArrowIcon />
                </Button>
            )}

            <UniversalImportAccountContainer
                {...otherProps}
                show={connecting}
                onHide={() => setConnecting(false)}
            />
        </>
    );
});
