import classNames from "classnames";
import React, { forwardRef, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { useInteraction } from "../../hooks/useInteraction";
import { useLogout } from "../../hooks/useLogout";
import { useUser } from "../../hooks/useUser";
import { useWorkspaces } from "../../hooks/useWorkspaces";
import { ArrowDownIcon } from "../../icons";
import { useWorkspaceContext } from "../../state/workspaceContext";
import { Logo } from "../general/Logo";
import { useSettings } from "../settings/SettingsProvider";
import { useCommands } from "../commands/useCommands";
import { useMultikeyKeyboardCommand } from "../../hooks/keyboard/useMultikeyKeyboardCommands";
import { Shortcut } from "../commands/Shortcut";
import { CommandsDirectory } from "../commands/lib";
import { ExtendedButtonProps } from "../general/Button/Button";
import styles from "./NavigationDropdown.module.scss";

const MAX_WORKSPACE_SHORT_NAME_LENGTH = 13;

const CustomToggle: React.FC<ExtendedButtonProps> = forwardRef(
    ({ onClick }, ref) => {
        const toggleClick = useInteraction(onClick);

        const { activeWorkspace } = useWorkspaceContext();

        const shortName = useMemo(() => {
            const workspaceName = activeWorkspace?.name ?? "";
            return workspaceName.length > MAX_WORKSPACE_SHORT_NAME_LENGTH
                ? `${workspaceName.slice(0, MAX_WORKSPACE_SHORT_NAME_LENGTH)}...`
                : workspaceName;
        }, [activeWorkspace?.name]);

        return (
            <button
                {...toggleClick}
                className={styles.topLeftBar}
                ref={ref as any}
                data-testid="workspace-button"
            >
                <div className={styles.logoContainer}>
                    <Logo className={styles.logo} variant="icon" />
                </div>

                <div
                    className={styles.activeWorkspaceName}
                    data-testid="active-workspace-name"
                >
                    {shortName}
                    <ArrowDownIcon className={styles.arrowDownIcon} />
                </div>
            </button>
        );
    },
);

export const NavigationDropdown = () => {
    const user = useUser();

    const { activeWorkspace } = useWorkspaceContext();

    const { open: openSettings } = useSettings();
    const { open: openCommands } = useCommands();
    const logout = useLogout();

    const userWorkspaces = useWorkspaces();

    const hasMultipleWorkspaces = (userWorkspaces.data?.length ?? 0) > 1;

    const userRoleInWorkspace = useMemo(() => {
        if (!activeWorkspace) {
            return "Member";
        }
        return activeWorkspace.primaryAdminUserId === user.id
            ? "Owner"
            : "Member";
    }, [activeWorkspace, user]);

    useMultikeyKeyboardCommand({
        command: {
            keys: "ow",
            callback: () => openCommands(CommandsDirectory.WORKSPACES),
        },
    });

    return (
        <Dropdown className={styles.dropdown}>
            <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
            <Dropdown.Menu className={styles.dropdownMenu} align="left">
                <div
                    className={classNames(
                        styles.navigationDropdownItemTextOnly,
                        styles.navigationDropdownWorkspaceName,
                    )}
                    data-testid="dropdown-workspace-name"
                >
                    {activeWorkspace?.name ?? ""}
                </div>
                <div
                    className={classNames(
                        styles.navigationDropdownItemTextOnly,
                        styles.navigationDropdownUserEmail,
                    )}
                    data-testid="user-email"
                >
                    {user.email}
                </div>
                <div
                    className={classNames(
                        styles.navigationDropdownItemTextOnly,
                        styles.navigationDropdownUserRoleItem,
                    )}
                >
                    <span
                        className={styles.navigationDropdownUserRole}
                        data-testid="user-role"
                    >
                        {userRoleInWorkspace}
                    </span>
                </div>
                <Dropdown.Divider />

                <Dropdown.Item
                    onClick={() => openSettings()}
                    data-testid="settings-button"
                >
                    Settings
                </Dropdown.Item>
                <Dropdown.Divider />
                {hasMultipleWorkspaces && (
                    <Dropdown.Item
                        onClick={() =>
                            openCommands(CommandsDirectory.WORKSPACES)
                        }
                        disabled={userWorkspaces.isLoading}
                        className={styles.dropdownMenuSwitchWorkspaceItem}
                        data-testid="switch-workspace-button"
                    >
                        Switch workspace{" "}
                        <div
                            className={
                                styles.dropdownMenuSwitchWorkspaceIconContainer
                            }
                        >
                            <Shortcut keys={["O", "W"]} />
                        </div>
                    </Dropdown.Item>
                )}

                <Dropdown.Item onClick={logout} data-testid="logout-button">
                    Log out
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};
