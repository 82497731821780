import { initContract } from "@ts-rest/core";
import { z } from "zod";

const c = initContract();

export const transactionRuleContract = c.router(
    {
        deleteRule: {
            method: "DELETE",
            path: "/:workspaceId/:ruleId",
            query: z.object({
                revertTransactionChanges: z.enum(["true", "false"]).optional(),
            }),
            responses: {
                200: c.noBody(),
                400: z.object({
                    reversalErrorsNumber: z.number().optional(),
                    processedTransactionsNumber: z.number().optional(),
                }),
            },
        },
    },
    {
        pathPrefix: "/rules",
    },
);
