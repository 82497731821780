import React from "react";
import { format } from "date-fns";
import { NavLink } from "react-router-dom";
import { PlaidConnection } from "../../../common/types/plaidConnection";
import { fullDateFullYearFormat } from "../../../common/helpers/date";
import "./ManualDataUploadPrompt.scss";
import { CollapsibleInfoBanner } from "../../general/Collapsible/CollapsibleInfoBanner";
import { useSettings } from "../../settings/SettingsProvider";
import { useManualDataUpload } from "./useManualDataUpload";

interface Props {
    connection: PlaidConnection;
}
export const ManualDataUploadPrompt: React.FC<Props> = ({ connection }) => {
    const { missingDataStart, missingDataEnd } =
        useManualDataUpload(connection);
    const { close } = useSettings();

    return (
        <CollapsibleInfoBanner className="manual-data-upload-prompt">
            {connection.bankName} does not provide transaction data prior to{" "}
            <strong>{format(missingDataEnd, fullDateFullYearFormat)}</strong>.
            To complete your bookkeeping, please import a CSV of your{" "}
            {connection.bankName} transactions from{" "}
            <strong>{format(missingDataStart, fullDateFullYearFormat)}</strong>{" "}
            - <strong>{format(missingDataEnd, fullDateFullYearFormat)}</strong>{" "}
            navigating to{" "}
            <NavLink to="/accounts" onClick={() => close()}>
                Accounts tab
            </NavLink>
            , clicking "Add account", and selecting "CSV Import". Once complete,
            email <a href="mailto:support@kick.co">support@kick.co</a> and we
            will merge the accounts.
        </CollapsibleInfoBanner>
    );
};
