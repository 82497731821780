import classNames from "classnames";
import React, { MouseEvent, useCallback } from "react";
import { TriangleIcon } from "../../../../../icons";
import { FormCheckbox } from "../../../../forms/FormCheckbox/FormCheckbox";
import { TreeNode, WithId } from "../../../../general/Tree/Tree.types";
import { useTreeContext } from "../../../../general/Tree/useTreeContext";
import css from "../../../../general/AggregatedFilters/FormCheckItem.module.scss";
import { Button } from "../../../../general/Button/Button";
import { Category } from "../../../../../common/types/category";
import { CategoryIcon } from "../../../../transactions/CategoryIcon";

interface Props<T extends WithId> {
    node: TreeNode<T>;
    isChecked(category: T): boolean;
    handleChange(category: T): void;
}
export const CategoryTreeItem: React.FC<Props<Category>> = ({
    node,
    isChecked,
    handleChange,
}) => {
    const category = node.current;
    const onChange = useCallback(
        () => handleChange(category),
        [category, handleChange],
    );

    const { isExpanded, expandNode, collapseNode } = useTreeContext();
    const handleToggle = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation();
            if (isExpanded(node.nodeId)) {
                collapseNode(node.nodeId);
            } else {
                expandNode(node.nodeId);
            }
        },
        [collapseNode, expandNode, isExpanded, node.nodeId],
    );

    return (
        <FormCheckbox
            small
            key={category.id}
            value={category.id}
            isChecked={isChecked(category)}
            handleChange={onChange}
            className={classNames(css.item, {
                [css.rootItem]: node.depth === 0,
                [css.expandedItem]: isExpanded(node.nodeId),
            })}
            label={
                <div className={css.itemLabel}>
                    {node.depth === 0 && (
                        <CategoryIcon categoryId={category.id} />
                    )}
                    <span
                        className={classNames(node.depth === 0 && "ml-2")}
                        data-testid="category-item"
                    >
                        {category.label}
                    </span>
                    {node.children.length > 0 && (
                        <Button
                            variant="tertiary"
                            icon
                            size="sm"
                            onClick={handleToggle}
                            className="ml-1"
                        >
                            <TriangleIcon />
                        </Button>
                    )}
                </div>
            }
        />
    );
};
