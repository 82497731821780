import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { ClientInferResponseBody, ServerInferRequest } from "@ts-rest/core";
import { getBackendAPIClient } from "../lib/backendAPIClient";
import { transactionRuleContract } from "../common/contracts/transactionRule.contract";
import { TransactionRule } from "../common/types/transactionRule";
import { GetTransactionRulesResponse } from "../common/dto/transactionRule/get-transaction-rules-response.dto";

export const TRANSACTION_RULES_QUERY_KEY = "transactionRules";
export type RuleRemovalError = ClientInferResponseBody<
    typeof transactionRuleContract.deleteRule,
    400
>;

export const transactionRuleContractClient = getBackendAPIClient(
    transactionRuleContract,
);

interface RuleRemovalOptions {
    onSuccess?: () => void;
    onError?: (error: RuleRemovalError) => void;
}

export function useRuleRemovalMutation(
    rule: TransactionRule,
    { onSuccess, onError }: RuleRemovalOptions = {},
) {
    const client = useQueryClient();

    return useMutation({
        mutationFn: (
            query?: ServerInferRequest<
                typeof transactionRuleContract.deleteRule
            >["query"],
        ) =>
            transactionRuleContractClient.deleteRule({
                params: {
                    workspaceId: rule.workspaceId,
                    ruleId: rule.id,
                },
                query,
            }),

        onSuccess: () => {
            client.setQueriesData<GetTransactionRulesResponse | undefined>(
                { queryKey: [TRANSACTION_RULES_QUERY_KEY] },
                (data) => {
                    if (!data) {
                        return;
                    }

                    return {
                        ...data,
                        rules: data.rules.filter((r) => r.id !== rule.id),
                    };
                },
            );
            onSuccess?.();
        },
        onError: (error) => {
            if (
                axios.isAxiosError(error) &&
                error.response?.status === 400 &&
                typeof error.response.data === "object" &&
                !("message" in error.response.data)
            ) {
                onError?.(error.response.data as RuleRemovalError);
            }
        },
    });
}
