import React from "react";
import { useOpeningBalancesLink } from "../../../hooks/useOpeningBalancesLink";
import { Button } from "../../general/Button/Button";

export const SubmitOpeningBalancesAction: React.FC = () => {
    const url = useOpeningBalancesLink();

    return (
        <Button asChild variant="secondary">
            <a href={url} target="_blank">
                Submit opening balances
            </a>
        </Button>
    );
};
