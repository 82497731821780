import { PlaidConnectionWithAccounts } from "../../types/plaidConnection";
import { PlaidConnectionDto } from "../plaidConnection/plaid-connection.dto";
import { InvalidPlaidAccount } from "./plaid-common";

export interface FullAccountNumber {
    id: number;
    accountNumber: string | null;
}

export interface CreatePlaidConnectionResponse {
    connection: PlaidConnectionWithAccounts;
    connectionSaved: boolean;
    numbers: FullAccountNumber[];
    foreign: InvalidPlaidAccount[];
}
export class CreatePlaidConnectionResponseDto {
    static deserialize(
        data: CreatePlaidConnectionResponseDto,
    ): CreatePlaidConnectionResponse {
        return {
            ...data,
            connection: PlaidConnectionDto.deserialize(data.connection),
        };
    }

    connection: PlaidConnectionDto;
    connectionSaved: boolean;
    numbers: FullAccountNumber[];
    foreign: InvalidPlaidAccount[];

    constructor(data: CreatePlaidConnectionResponse) {
        this.connection = new PlaidConnectionDto(data.connection);
        this.connectionSaved = data.connectionSaved;
        this.numbers = data.numbers;
        this.foreign = data.foreign;
    }
}
