import React from "react";
import "./Widget.scss";
import classNames from "classnames";
import { ChildrenProps } from "../../types";
import { Card } from "./Card/Card";

interface Props extends ChildrenProps {
    title?: React.ReactNode;
    className?: string;
}

export const Widget: React.FC<Props> = ({
    children,
    title,
    className = "",
}) => (
    <Card className={classNames("widget", className)}>
        {title ? <h4 className="widget__title">{title}</h4> : null}
        {children}
    </Card>
);
