import React, { FC } from "react";
import { useRoles } from "../../hooks/useRoles";
import { ADMIN_ROLES } from "../../common/constants";
import { useWorkspaces } from "../../hooks/useWorkspaces";
import { AddCommandsList } from "./AddCommandsList";
import { AccountsCommandsList } from "./AccountsCommandsList";
import { NavigationCommands } from "./NavigationCommands";
import { WorkspaceSettingsCommandsList } from "./WorkspaceSettingsCommandsList";
import { WorkspacesCommandsList } from "./WorkspacesCommandsList";
import { AccountSettingsCommandList } from "./AccountSettingsCommandList";
import { CommandsListProps } from "./lib";

export const AllCommandsList: FC<CommandsListProps> = (props) => {
    const isAdmin = useRoles(ADMIN_ROLES);
    const userWorkspaces = useWorkspaces();
    const hasMultipleWorkspaces = (userWorkspaces.data?.length ?? 0) > 1;

    return (
        <>
            <AddCommandsList />
            <AccountsCommandsList includeStaticAccounts {...props} />
            <NavigationCommands />
            <AccountSettingsCommandList />

            {isAdmin ? <WorkspaceSettingsCommandsList /> : null}
            {hasMultipleWorkspaces ? <WorkspacesCommandsList /> : null}
        </>
    );
};
