import {
    cssTransition,
    ToastContainer as ToastsContainerLib,
} from "react-toastify";

const customSlide = cssTransition({
    enter: "custom-slide-in",
    exit: "custom-slide-out",
    collapseDuration: 300,
    collapse: false,
});

export const ToastContainer: React.FC = () => (
    <ToastsContainerLib
        aria-label="toasts"
        position="bottom-left"
        hideProgressBar
        limit={3}
        transition={customSlide}
    />
);
