import React from "react";
import { GENERAL_LEDGER_PREVIEW_URL } from "../../../constants";
import { ReportAccess } from "../ReportAccess";
import { FLAT_RATE_PLANS } from "../../../common/flatRateBilling";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import { GeneralLedgerPage } from "./GeneralLedgerPage";

export const GeneralLedgerAccessPage: React.FC = () => {
    const { currentPlan } = useBillingStatus();

    const alwaysAccessible = [
        FLAT_RATE_PLANS.FREE,
        FLAT_RATE_PLANS.BASIC,
    ].includes(currentPlan);

    return (
        <ReportAccess
            alwaysAccessible={alwaysAccessible}
            report="General Ledger"
            previewUrl={GENERAL_LEDGER_PREVIEW_URL}
            renderReportPage={() => <GeneralLedgerPage />}
        />
    );
};
