import React from "react";
import { useField } from "formik";
import { FormRow } from "../forms/FormRow";
import { Button } from "../general/Button/Button";
import { AddMemberFormSchema, StepProps } from "./types";
import styles from "./MemberManagement.module.scss";

export const AddMemberDetailsStep: React.FC<StepProps> = ({
    onBack,
    onNext,
}) => {
    const [, fieldMeta] = useField<AddMemberFormSchema>("email");

    return (
        <section>
            <h3 className={styles.heading}>Add member details</h3>

            <main className={styles.memberDetailsContent}>
                <FormRow fieldName="email" size="xs" label="Email address" />
            </main>

            <footer className={styles.footer}>
                <Button type="button" variant="secondary" onClick={onBack}>
                    Cancel
                </Button>
                <Button
                    type="button"
                    variant="default"
                    onClick={onNext}
                    disabled={!!fieldMeta.error || !fieldMeta.value}
                >
                    Next
                </Button>
            </footer>
        </section>
    );
};
