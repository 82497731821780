import React, { useContext, useMemo } from "react";
import { difference } from "lodash";
import {
    ChatIcon,
    IgnoreRequirementsIcon,
    LinkIcon,
    RequirementsIcon,
    SplitSIcon,
} from "../../../icons";
import {
    Transaction,
    TransactionStatus,
} from "../../../common/types/transaction";
import { CATEGORY_RELATED_ACTIONS } from "../../../common/requiredActions";
import { Button } from "../../general/Button/Button";
import { intercom } from "../../../lib/intercom";
import { TransactionDetailsContext } from "./transactionDetails.context";
import { TransactionDetailsSection } from "./TransactionDetailsSection";

interface Props {
    transaction: Transaction;
}

export const TransactionDetailsFooter: React.FC<Props> = ({ transaction }) => {
    const { onSplit, onUpdate, onMatch } = useContext(
        TransactionDetailsContext,
    );
    const canSplitTransaction =
        transaction.status === TransactionStatus.COMPLETED;

    const splitTransactionMessage = canSplitTransaction
        ? "Split transaction"
        : `Cannot split ${transaction.status} transactions`;

    const showRequirementsSnoozing = useMemo(
        () =>
            transaction.requirementsSnoozed ||
            difference(transaction.requiredActions, CATEGORY_RELATED_ACTIONS)
                .length > 0,
        [transaction.requirementsSnoozed, transaction.requiredActions],
    );

    return (
        <TransactionDetailsSection forceOpened>
            <div className="d-flex gap-2">
                <Button
                    variant="tertiary"
                    icon
                    onClick={() => onSplit(transaction)}
                    disabled={!canSplitTransaction}
                    data-testid="transaction-details-split"
                    tooltip={splitTransactionMessage}
                    size="sm"
                >
                    <SplitSIcon />
                </Button>
                {showRequirementsSnoozing && (
                    <Button
                        variant="tertiary"
                        icon
                        size="sm"
                        onClick={() =>
                            onUpdate({
                                requirementsSnoozed:
                                    !transaction.requirementsSnoozed,
                            })
                        }
                        data-testid="transaction-details-snooze-requirements"
                        tooltip={
                            transaction.requirementsSnoozed
                                ? "Enable Requirements"
                                : "Snooze Requirements"
                        }
                    >
                        {transaction.requirementsSnoozed ? (
                            <RequirementsIcon />
                        ) : (
                            <IgnoreRequirementsIcon />
                        )}
                    </Button>
                )}
                <Button
                    variant="tertiary"
                    icon
                    onClick={() => onMatch(transaction)}
                    data-testid="transaction-details-match"
                    tooltip="Match transaction"
                    size="sm"
                >
                    <LinkIcon />
                </Button>

                <Button
                    variant="tertiary"
                    onClick={() => intercom("show")}
                    data-testid="transaction-details-help"
                    icon
                    tooltip="Open chat"
                    size="sm"
                >
                    <ChatIcon />
                </Button>
            </div>
        </TransactionDetailsSection>
    );
};
