import { useMemo } from "react";
import { useSingleBusinessEntity } from "../../../../../hooks/useSingleBusinessEntity";
import {
    Action,
    ActionCampaign,
    ActionStatus,
} from "../../../../../common/types/actions";
import { Taxonomy } from "../../../../../common/categories";
import { TaxQuestionKey } from "../../../../../common/taxSavingRules";
import { Transaction } from "../../../../../common/types/transaction";
import { useStandardCategories } from "../../../../../hooks/useStandardCategories";
import { TransactionUpdateMetaDto } from "../../../../../common/dto/actions/transaction-update-meta.dto";
import { personalChaseFinancialAccount } from "./mockData";

export function usePersonalToBusinessAction() {
    const entity = useSingleBusinessEntity()!;
    const categories = useStandardCategories();

    return useMemo(
        (): Action<TransactionUpdateMetaDto> => ({
            id: "personal-to-business",
            campaign: ActionCampaign.PERSONAL_TO_BUSINESS,
            createdAt: new Date(),
            updatedAt: new Date(),
            status: ActionStatus.PENDING,
            entity,
            transaction: {
                entity,
                financialAccount: personalChaseFinancialAccount,
                amount: -15,
                absoluteAmount: 15,
                date: new Date("2024-09-15"),
                bankDescription: "Starbucks",
                displayDescription: "Starbucks",
                category: Taxonomy.personal,
            } as unknown as Transaction,
            deduplicationKey: `entity-${entity.id}`,
            meta: {
                transactionUpdate: {
                    entityId: entity.id,
                    categoryId: categories[Taxonomy.meals].id,
                    taxQuestionAnswers: [
                        {
                            key: TaxQuestionKey.MEALS_MET_WITH,
                            answer: "Client",
                        },
                        {
                            key: TaxQuestionKey.MEALS_MET_FOR,
                            answer: "To discuss ongoing business",
                        },
                    ],
                },
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [entity],
    );
}
