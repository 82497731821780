import { initContract } from "@ts-rest/core";
import { z } from "zod";
import { Invoice, InvoiceStats, InvoiceStatus } from "../types/invoice";

const c = initContract();

export const InvoiceFiltersSchema = z.object({
    search: z.string().optional(),
    entityIds: z.array(z.number()).optional(),
    statuses: z.array(z.nativeEnum(InvoiceStatus)).optional(),
    start: z.coerce.date().optional(),
    end: z.coerce.date().optional(),
    dueStart: z.coerce.date().optional(),
    dueEnd: z.coerce.date().optional(),
    minAmount: z.coerce.number().optional(),
    maxAmount: z.coerce.number().optional(),
    accountIds: z.array(z.number()).optional(),
    counterpartiesIds: z.array(z.string()).optional(),
});

export type InvoiceFilters = z.infer<typeof InvoiceFiltersSchema>;

const InvoiceQueryParamsSchema = z.object({
    filters: InvoiceFiltersSchema.optional(),
    sort: z
        .object({
            field: z.string(),
            order: z.enum(["ASC", "DESC"]),
        })
        .optional(),
    page: z.number(),
    limit: z.number(),
});

export type InvoiceQueryParams = z.infer<typeof InvoiceQueryParamsSchema>;

export const InvoiceStatsParamsSchema = z.object({
    filters: InvoiceFiltersSchema.optional(),
});

export type InvoiceStatsParams = z.infer<typeof InvoiceStatsParamsSchema>;

export const invoiceContract = c.router(
    {
        getInvoices: {
            method: "GET",
            path: "/:workspaceId",
            responses: {
                200: c.type<{
                    total: number;
                    pageCount: number;
                    data: Invoice[];
                }>(),
            },
            query: InvoiceQueryParamsSchema,
        },
        getInvoiceStats: {
            method: "GET",
            path: "/:workspaceId/stats",
            responses: {
                200: c.type<{
                    data: InvoiceStats;
                }>(),
            },
            query: InvoiceStatsParamsSchema,
        },
    },
    {
        pathPrefix: "/invoices",
    },
);
