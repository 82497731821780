import { isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import classNames from "classnames";
import { MoneyDirection } from "../../../../common/constants";
import { NonUndefined } from "../../../../common/helpers/typescript";
import { EagerLoaded } from "../../../../common/types/base/orm";
import {
    Transaction,
    TransactionListItemDto,
} from "../../../../common/types/transaction";
import { EmptyState } from "../../../general/EmptyState/EmptyState";
import {
    DEFAULT_ACTIVITY_FEED_FILTERS,
    TransactionsFilters,
} from "../../filters/lib";
import {
    TransactionsTable,
    TransactionsTableProps,
} from "../../TransactionsTable";
import { TransactionSortValue, useSort } from "../../useSort";
import { MatchButton, MatchButtonProps } from "../MatchButton/MatchButton";
import {
    MatchingFiltersPreferenceOption,
    useTransactionsForTransferMatching,
} from "../useTransactionsForTransferMatching";
import { useEntities } from "../../../../hooks/useEntities";
import { Button } from "../../../general/Button/Button";
import { TablePageHeader } from "../../../pageHeader/TablePageHeader";
import { FilterTabs } from "../../../pageHeader/filters/lib";
import { useTypedFlags } from "../../../../hooks/useTypedFlags";
import { useClasses } from "../../../../api/class.api";
import { EntityFilter } from "../../../general/EntityFilter/EntityFilter";
import { CustomSelect } from "../../../forms/CustomSelect/CustomSelect";
import { ArrowDownIcon, ArrowUpIcon } from "../../../../icons";
import styles from "./MatchingView.module.scss";

const preferenceOptions = [
    {
        label: "Suggested",
        value: MatchingFiltersPreferenceOption.Suggested,
    },
    {
        label: "All transactions",
        value: MatchingFiltersPreferenceOption.All,
    },
];

const preferenceLabels: Record<MatchingFiltersPreferenceOption, string> = {
    [MatchingFiltersPreferenceOption.Suggested]: "Suggested",
    [MatchingFiltersPreferenceOption.All]: "All transactions",
};

interface Props {
    transaction: EagerLoaded<Transaction, "pairedTransfer">;
    pairedTransaction: TransactionListItemDto | null;
    setPairedTransaction: (
        transaction: TransactionListItemDto | undefined,
    ) => void;
}

export const MatchingView: React.FC<Props> = ({
    transaction,
    pairedTransaction,
    setPairedTransaction,
}) => {
    const entities = useEntities();
    const { directory: canUseDirectory } = useTypedFlags();
    const { isActivated: classesEnabled } = useClasses();
    const initialFilters = useMemo(
        () => ({
            ...DEFAULT_ACTIVITY_FEED_FILTERS,
            direction:
                transaction.amount > 0
                    ? MoneyDirection.MONEY_OUT
                    : MoneyDirection.MONEY_IN,
        }),
        [transaction.amount],
    );
    const [filters, setFilters] = useState<TransactionsFilters>(initialFilters);
    const {
        currentSort,
        handleSortChange: setSort,
        sortExpression,
    } = useSort(TransactionSortValue.NONE);

    const hasActiveFilters = useMemo(() => {
        const filtersWithoutDirection = {
            ...filters,
        };
        delete filtersWithoutDirection.direction;
        return !isEqual(filtersWithoutDirection, DEFAULT_ACTIVITY_FEED_FILTERS);
    }, [filters]);

    const queryFiltersToPass = {
        ...filters,
        entityIds:
            filters.entityIds && filters.entityIds.length > 0
                ? filters.entityIds
                : entities.map((entity) => entity.id),
        accountIds: filters.accountIds,
    };

    const [preference, setPreference] =
        useState<MatchingFiltersPreferenceOption>(
            MatchingFiltersPreferenceOption.Suggested,
        );

    const resetFilters = useCallback(
        () => setFilters(initialFilters),
        [initialFilters],
    );

    const setPreferenceCallback = useCallback(
        (preferenceNew: MatchingFiltersPreferenceOption) => {
            setPreference(preferenceNew);
            if (preferenceNew === MatchingFiltersPreferenceOption.Suggested) {
                setSort(TransactionSortValue.NONE);
            } else if (currentSort === TransactionSortValue.NONE) {
                setSort(TransactionSortValue.DATE_DESC);
            }
        },
        [currentSort, setSort],
    );

    const renderEmptyState = useCallback<
        NonUndefined<TransactionsTableProps["renderEmptyState"]>
    >(
        () => (
            <MatchingViewEmptyState
                resetFilters={resetFilters}
                hasActiveFilters={hasActiveFilters}
                preference={preference}
                setPreference={setPreferenceCallback}
            />
        ),
        [hasActiveFilters, preference, resetFilters, setPreferenceCallback],
    );

    const {
        data: transactionsResponse,
        isLoading,
        hasNextPage,
        fetchNextPage,
    } = useTransactionsForTransferMatching({
        preference,
        transaction,
        queryFiltersToPass,
        sortExpression,
    });

    const { inView, ref } = useInView({
        skip: isLoading,
    });

    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, inView]);

    const transactions = useMemo(
        () =>
            transactionsResponse?.pages
                .flatMap((_) => _.data)
                .filter((t) => t.id !== pairedTransaction?.id),
        [transactionsResponse?.pages, pairedTransaction],
    );

    const onUnmatch = useCallback(() => {
        setPairedTransaction(undefined);
    }, [setPairedTransaction]);

    const onMatch: MatchButtonProps["onMatch"] = useCallback(
        (_, targetTransactionUpdated: TransactionListItemDto) => {
            setPairedTransaction(targetTransactionUpdated);
        },
        [setPairedTransaction],
    );

    return (
        <main className={styles.container}>
            <TablePageHeader
                search={filters.search}
                onSearchChange={(value) => {
                    setFilters({ search: value ?? "" });
                }}
                filters={filters}
                onFiltersChange={setFilters}
                availableFilters={[
                    FilterTabs.DATE,
                    FilterTabs.CATEGORY,
                    FilterTabs.ACCOUNT,
                    ...(classesEnabled ? [FilterTabs.CLASS] : []),
                    FilterTabs.AMOUNT,
                    FilterTabs.RULES,
                    ...(canUseDirectory ? [FilterTabs.COUNTERPARTY] : []),
                    FilterTabs.NOTE,
                    FilterTabs.RECEIPTS,
                    FilterTabs.SPLIT,
                    FilterTabs.MATCHED,
                ]}
                prependChildren={
                    <CustomSelect
                        onSelected={(
                            newValue: MatchingFiltersPreferenceOption,
                        ) => setPreferenceCallback(newValue)}
                        value={preference}
                        dropdownKey="transfer-matching-preference-select"
                        options={preferenceOptions}
                        className="reports-filter-form__cycle-select"
                        popoverClassName={styles.filtersPreferenceSelectPopover}
                        empty="NO"
                    >
                        {(opened) => (
                            <Button
                                variant="secondary"
                                className={classNames({
                                    active: opened,
                                })}
                            >
                                {preferenceLabels[preference]}
                                {opened ? (
                                    <ArrowUpIcon className="ml-2" />
                                ) : (
                                    <ArrowDownIcon className="ml-2" />
                                )}
                            </Button>
                        )}
                    </CustomSelect>
                }
            >
                <EntityFilter filters={filters} onChange={setFilters} />
            </TablePageHeader>

            <section className={styles.transactionsContainer}>
                <TransactionsTable
                    renderEmptyState={renderEmptyState}
                    disableBulkActions
                    sort={currentSort}
                    transactions={transactions}
                    onSortChange={setSort}
                    actionButtonComponent={(targetTransaction) => (
                        <MatchButton
                            matchedTransaction={pairedTransaction}
                            originTransaction={transaction}
                            targetTransaction={targetTransaction}
                            onUnmatch={onUnmatch}
                            onMatch={onMatch}
                        />
                    )}
                    lastRowContent={
                        preference ===
                            MatchingFiltersPreferenceOption.Suggested && (
                            <div className={styles.dontSeeTransactionQuestion}>
                                Don't see the transaction you're looking for?{" "}
                                <Button
                                    variant="tertiary"
                                    onClick={() =>
                                        setPreference(
                                            MatchingFiltersPreferenceOption.All,
                                        )
                                    }
                                >
                                    View all transactions
                                </Button>
                            </div>
                        )
                    }
                    disableTransactionChange
                />
                <footer className={styles.observerFooter} ref={ref}></footer>
            </section>
        </main>
    );
};

interface MatchingViewEmptyStateProps {
    resetFilters: () => void;
    hasActiveFilters: boolean;
    preference: MatchingFiltersPreferenceOption;
    setPreference: (value: MatchingFiltersPreferenceOption) => void;
}

const MatchingViewEmptyState: React.FC<MatchingViewEmptyStateProps> = ({
    resetFilters,
    hasActiveFilters,
    preference,
    setPreference,
}) => {
    if (hasActiveFilters) {
        return (
            <EmptyState
                header="No matching transactions"
                body="The filters don’t match any of the transactions."
                ctaText="Clear filters"
                action={resetFilters}
            />
        );
    }

    return (
        <EmptyState
            header={
                preference === MatchingFiltersPreferenceOption.Suggested
                    ? "No suggested transactions"
                    : "No transactions"
            }
            body={
                preference === MatchingFiltersPreferenceOption.Suggested
                    ? "We couldn't find a potential match for this transaction"
                    : "It may take a few hours till first transactions are visible."
            }
            ctaText={
                preference === MatchingFiltersPreferenceOption.Suggested
                    ? "View all transactions"
                    : undefined
            }
            action={() =>
                preference === MatchingFiltersPreferenceOption.Suggested &&
                setPreference(MatchingFiltersPreferenceOption.All)
            }
        />
    );
};
