import { Invoice } from "../../types/invoice";
import { TransactionMatch } from "../../types/transactionMatch";
import { valueOrUndefined } from "../dto-helpers";
import { FinancialDocumentBaseDto } from "../financialDocument/financial-document-base.dto";
import { TransactionMatchFinancialTransactionDto } from "./transaction-match-financial-transaction.dto";

export class TransactionMatchDto {
    static deserialize(dto: TransactionMatchDto): TransactionMatch {
        return {
            ...dto,
            financialDocument: valueOrUndefined(
                dto.financialDocument,
                FinancialDocumentBaseDto.deserialize,
            ),
            financialTransaction: valueOrUndefined(
                dto.financialTransaction,
                TransactionMatchFinancialTransactionDto.deserialize,
            ),
            invoice: valueOrUndefined(dto.invoice, (v) => v),
        };
    }

    id: number;
    financialTransaction?: TransactionMatchFinancialTransactionDto;
    financialDocument?: FinancialDocumentBaseDto;
    invoice?: Invoice;

    constructor(transactionMatch: TransactionMatch) {
        this.id = transactionMatch.id;
        this.financialTransaction = valueOrUndefined(
            transactionMatch.financialTransaction,
            (v) => new TransactionMatchFinancialTransactionDto(v),
        );
        this.financialDocument = valueOrUndefined(
            transactionMatch.financialDocument,
            (v) => new FinancialDocumentBaseDto(v),
        );
        this.invoice = transactionMatch.invoice;
    }
}
