import React, { CSSProperties, useCallback, useContext } from "react";
import classNames from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Entity } from "../../../common/types/entity";
import { FinancialAccount } from "../../../common/types/financialAccount";
import { AccountsChartEntityContext } from "./AccountsChartEntity.context";
import { AccountChartTooltip } from "./AccountChartTooltip";

interface Props {
    entity: Entity;
    account: FinancialAccount;
    entityBalance: number;
    proxy?: boolean;
}

const DISPLAY_THRESHOLD = 3;

export const AccountsChartEntityAccount: React.FC<Props> = ({
    entity,
    account,
    entityBalance,
    proxy,
}) => {
    const { hoveredAccountId, setHoveredAccountId } = useContext(
        AccountsChartEntityContext,
    );

    const onTooltipToggle = useCallback(
        (show: boolean) => {
            if (proxy) {
                if (show) {
                    setHoveredAccountId(account.id);
                } else {
                    setHoveredAccountId((prev) =>
                        prev === account.id ? undefined : prev,
                    );
                }
            }
        },
        [setHoveredAccountId, account.id, proxy],
    );

    if (account.balance === null) {
        return null;
    }

    const percentage = (account.balance / entityBalance) * 100;

    return percentage > DISPLAY_THRESHOLD ? (
        <OverlayTrigger
            overlay={
                <Tooltip
                    id={`account-balance-${account.id}`}
                    className="accounts-chart__tooltip"
                >
                    <AccountChartTooltip entity={entity} account={account} />
                </Tooltip>
            }
            onToggle={onTooltipToggle}
        >
            <div
                className={classNames("accounts-chart__entity__account", {
                    "accounts-chart__entity__account--proxy": proxy,
                    "accounts-chart__entity__account--hover":
                        hoveredAccountId === account.id,
                })}
                style={
                    { "--account-height": percentage + "%" } as CSSProperties
                }
            />
        </OverlayTrigger>
    ) : null;
};
