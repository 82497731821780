import isNumber from "lodash/isNumber";
import { currencyFormatter } from "../common/helpers/currency";
import { FinancialAccount } from "../common/types/financialAccount";
import { FinancialConnection } from "../common/types/financialConnection";
import { isPlaidConnection } from "../common/helpers/financialConnection";
import { PlaidAccountMetadata } from "../common/helpers/plaid";
import { IntegrationAccount } from "../common/types/integrationAccount";

export function displayAccountBalance(account: FinancialAccount) {
    return isNumber(account.balance)
        ? currencyFormatter.format(account.balance)
        : "—";
}

export function accountHasError(
    integrationAccount: IntegrationAccount,
    connection?: FinancialConnection,
): boolean {
    if (!connection) {
        return false;
    }

    if (connection.error) {
        return true;
    }

    if (isPlaidConnection(connection)) {
        return Boolean(
            integrationAccount.metadata?.[PlaidAccountMetadata.ACCOUNT_CHURN],
        );
    }

    return false;
}
