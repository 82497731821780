import { useCallback, useEffect, useState } from "react";
import {
    StandardModal,
    StandardModalBody,
    StandardModalHeader,
} from "../../general/Modal/Modal";
import {
    isReducedMotionEnabled,
    PARTNERSTACK_PARTNERSHIPS_URL_BASE,
} from "../../../constants";
import { useUser } from "../../../hooks/useUser";
import { FormRowRaw } from "../../forms/FormRowRaw";
import { CopyIcon } from "../../../icons";
import { Loader } from "../../general/Loader";
import { useToaster } from "../../general/ToastMessages/useToaster";
import { Button } from "../../general/Button/Button";
import { useTimer } from "../../../hooks/useTimer";

interface Props {
    show: boolean;
    onHide: () => void;
}

export const ReferralModal: React.FC<Props> = ({ show, onHide }) => {
    const user = useUser();
    const { toast } = useToaster();
    const [referralLink, setReferralLink] = useState<string>();
    const [warning, setWarning] = useState<boolean>(false);
    const { start, clear } = useTimer(() => setWarning(true), 5000);

    const generateReferralLink = useCallback(async () => {
        const url = `${PARTNERSTACK_PARTNERSHIPS_URL_BASE}/${user.email}`;
        const result = await fetch(url, {
            method: "POST",
        });
        const body = await result.json();
        const data = JSON.parse(body.rdata);
        clear();
        setReferralLink(data.link);
    }, [setReferralLink, user.email, clear]);

    const copyReferralLink = useCallback(() => {
        if (!referralLink) {
            return;
        }

        navigator.clipboard.writeText(referralLink);
        toast("Referral link copied to clipboard");
    }, [referralLink, toast]);

    useEffect(() => {
        if (show && !referralLink) {
            Promise.race([generateReferralLink(), start()]);
        }
    }, [show, referralLink, generateReferralLink, setWarning, start]);

    const handleHide = useCallback(() => {
        setWarning(false);
        onHide();
        clear();
    }, [onHide, clear]);

    const message = warning
        ? "Waiting too long? Make sure your AdBlocker is turned off and reload the page."
        : "Share Kick with friends and earn a 20% fee for every signup.";

    return (
        <StandardModal
            className="settings-referral-widget__modal"
            show={show}
            onHide={handleHide}
            animation={!isReducedMotionEnabled}
        >
            <StandardModalHeader>
                <h4>Refer &amp; earn</h4>
                <span>{message}</span>
            </StandardModalHeader>
            <StandardModalBody>
                {referralLink ? (
                    <FormRowRaw
                        label="Referral link"
                        value={referralLink}
                        iconPosition="right"
                        readOnly
                        icon={
                            <Button
                                variant="tertiary"
                                size="sm"
                                icon
                                onClick={copyReferralLink}
                            >
                                <CopyIcon />
                            </Button>
                        }
                    />
                ) : (
                    <Loader />
                )}
            </StandardModalBody>
        </StandardModal>
    );
};
