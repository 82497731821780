import { useMemo } from "react";
import { FinancialAccount } from "../../common/types/financialAccount";
import {
    isSpecificPaymentProcessorAccount,
    Processors,
} from "../../common/helpers/paymentProcessorHelpers";

function isProcessorConnected(
    financialAccounts: FinancialAccount[],
    processor: Processors,
) {
    return financialAccounts.some((account) =>
        isSpecificPaymentProcessorAccount(account, processor),
    );
}

export function useProcessorConnectionStatus(
    financialAccounts: FinancialAccount[],
) {
    return useMemo(() => {
        const statuses = {
            stripeConnected: isProcessorConnected(
                financialAccounts,
                Processors.STRIPE,
            ),
            paypalConnected: isProcessorConnected(
                financialAccounts,
                Processors.PAYPAL,
            ),
            venmoConnected: isProcessorConnected(
                financialAccounts,
                Processors.VENMO,
            ),
            cashAppConnected: isProcessorConnected(
                financialAccounts,
                Processors.CASH_APP,
            ),
        } as const;

        return {
            ...statuses,
            hasAnyProcessorConnected: Object.values(statuses).some(Boolean),
        };
    }, [financialAccounts]);
}
