import React from "react";
import { dateTimeFormatter } from "../../common/helpers/date";
import { SimulatedMessage } from "../../common/types/simulatedMessage";

export interface RecentMessagesProps {
    messages: SimulatedMessage[];
}

export const RecentMessages: React.FC<RecentMessagesProps> = ({ messages }) => (
    <section>
        <h3>Recent Messages</h3>

        {messages.length > 0 ? (
            <ul>
                {messages.map((message) => (
                    <li key={message.body} className="mb-3">
                        <header>
                            Sent to <strong>{message.to}</strong> on{" "}
                            {dateTimeFormatter.format(message.timestamp)}
                        </header>
                        <div>{message.body}</div>
                        {message.attachments.length > 0 && (
                            <div>
                                Attachments: <br />
                                {message.attachments.map((url) => (
                                    <>
                                        {typeof url === "string" ? (
                                            <a
                                                href={url}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {url}
                                            </a>
                                        ) : (
                                            JSON.stringify(url)
                                        )}

                                        <br />
                                    </>
                                ))}
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        ) : (
            <p>No messages yet</p>
        )}
    </section>
);
