import React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import {
    CounterpartyDirection,
    CounterpartyDirectionLabels,
} from "../../common/helpers/counterparties";
import { ModalComponentWithResultProps } from "../../helpers/modal";
import { CounterpartyCreationResponse } from "../../common/contracts/counterparty.contract";
import { StandardModalBody, StandardModalHeader } from "../general/Modal/Modal";
import { CreateCounterparty } from "./CreateCounterparty";

interface Props
    extends ModalComponentWithResultProps<CounterpartyCreationResponse> {
    direction?: CounterpartyDirection;
    forTransactionEditing?: boolean;
}

export const CreateCounterpartyModal: React.FC<Props> = ({
    direction,
    close,
    forTransactionEditing,
}) => {
    const directionLabel = direction && CounterpartyDirectionLabels[direction];

    return (
        <>
            <StandardModalHeader>
                Create new {directionLabel?.toLowerCase()}
            </StandardModalHeader>
            <Scrollbars
                style={{ maxHeight: "calc(100vh - 100px)", height: "920px" }}
            >
                <StandardModalBody className="pb-0">
                    <CreateCounterparty
                        onCreated={close}
                        onCancelled={close}
                        fullWidth
                        forTransactionEditing={forTransactionEditing}
                    />
                </StandardModalBody>
            </Scrollbars>
        </>
    );
};
