import {
    BillingInterval,
    SubscriptionDetails,
    SubscriptionDiscount,
    SubscriptionType,
} from "../../types/billing";
import { isoStringOrNull, unpackDateOrNull } from "../dto-helpers";

import { FLAT_RATE_PLANS } from "../../flatRateBilling";
import { SubscriptionStatus } from "../../billing";

export class SubscriptionDetailsDto {
    static deserialize(dto: SubscriptionDetailsDto): SubscriptionDetails {
        return {
            ...dto,
            renewsOn: unpackDateOrNull(dto.renewsOn),
            cancelsAt: unpackDateOrNull(dto.cancelsAt),
            periodEndsAt: unpackDateOrNull(dto.periodEndsAt),
        };
    }

    type: SubscriptionType;
    status: SubscriptionStatus;
    price: number;
    monthlyPriceCents: number;
    renewsOn: string | null;
    plan: FLAT_RATE_PLANS;
    cancelsAt: string | null;
    cancelOnTrialEnd: boolean;
    periodEndsAt: string | null;
    nextPeriodPlan: FLAT_RATE_PLANS;
    interval: BillingInterval;
    intervalCount: number;
    intervalAmountCents: number;
    discountedIntervalAmountCents: number;
    discountedMonthlyAmountCents: number;
    discount: SubscriptionDiscount | null;

    constructor(details: SubscriptionDetails) {
        this.type = details.type;
        this.status = details.status;
        this.price = details.price;
        this.monthlyPriceCents = details.monthlyPriceCents;
        this.renewsOn = isoStringOrNull(details.renewsOn);
        this.plan = details.plan;
        this.cancelsAt = isoStringOrNull(details.cancelsAt);
        this.cancelOnTrialEnd = details.cancelOnTrialEnd;
        this.periodEndsAt = isoStringOrNull(details.periodEndsAt);
        this.nextPeriodPlan = details.nextPeriodPlan;
        this.interval = details.interval;
        this.intervalCount = details.intervalCount;
        this.intervalAmountCents = details.intervalAmountCents;
        this.discountedIntervalAmountCents =
            details.discountedIntervalAmountCents;
        this.discountedMonthlyAmountCents =
            details.discountedMonthlyAmountCents;
        this.discount = details.discount;
    }
}
