import { useMutation } from "@tanstack/react-query";
import { Entity } from "../common/types/entity";
import { createEntity, saveTaxLocations, updateAddress } from "../lib/entity";
import { USAddressDto } from "../common/dto/common/usAddress.dto";
import { getAddressQueryKey, queryClient } from "../queryClient";
import { CreateEntityDto } from "../common/dto/entity/create-entity.dto";
import { UpdateEntityDto } from "../common/dto/entity/update-entity.dto";
import { entityRest } from "../lib/restClient";
import { SaveTaxLocationsDto } from "../common/dto/entity/save-tax-locations.dto";
import {
    entitiesAccountsQueryKey,
    updateEntityInQueryData,
} from "../queries/entitiesAccounts";
import { Workspace } from "../common/types/workspace";

export function useEntityAddressMutation(entity: Entity) {
    return useMutation({
        mutationFn: (address: USAddressDto) =>
            updateAddress({ entityId: entity.id, address }),
        onSuccess: (data) => {
            queryClient.setQueryData(getAddressQueryKey(entity.id), data);
        },
    });
}

export function useEntityCreationMutation(workspace: Workspace) {
    return useMutation({
        mutationFn: (data: Omit<CreateEntityDto, "workspaceId">) =>
            createEntity({
                ...data,
                workspaceId: workspace.id,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [entitiesAccountsQueryKey],
            });
        },
    });
}

export function useEntityTaxLocationsMutation() {
    return useMutation({
        mutationFn: (data: SaveTaxLocationsDto) => saveTaxLocations(data),
        onSuccess: (entity) => {
            updateEntityInQueryData(queryClient, entity);
        },
    });
}

export function useEditEntityMutation(entity: Entity) {
    return useMutation<Entity, unknown, UpdateEntityDto, Entity>({
        mutationFn: (payload: UpdateEntityDto) =>
            entityRest.update(entity.id, payload),
        onMutate: (payload) => {
            updateEntityInQueryData(queryClient, {
                ...entity,
                ...payload,
            });

            return entity;
        },
        onError: (_1, _2, previousEntity) => {
            if (previousEntity) {
                updateEntityInQueryData(queryClient, previousEntity);
            }
        },
        onSuccess: (updatedEntity) => {
            updateEntityInQueryData(queryClient, updatedEntity);
        },
    });
}
