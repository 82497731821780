import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { Entity } from "../../../../common/types/entity";
import { FinancialAccount } from "../../../../common/types/financialAccount";
import { useEntities } from "../../../../hooks/useEntities";
import {
    useFinancialAccounts,
    UseFinancialAccountsOptions,
} from "../../../../hooks/useFinancialAccounts";
import { FilterSearch } from "../../../general/FilterSearch/FilterSearch";
import { Tree } from "../../../general/Tree/Tree";
import { TreeHelpers, TreeNode } from "../../../general/Tree/Tree.types";
import { TreeProvider } from "../../../general/Tree/TreeProvider";
import { FilterActionsFooter } from "../../Filter/FilterActionsFooter/FilterActionsFooter";
import { AccountTreeItem } from "./AccountTreeItem";
import css from "./AggregatedAccountsFilter.module.scss";
import { EntityItemLabel } from "./EntityItemLabel";

type ItemType = Entity | FinancialAccount;
type NodeType = TreeNode<Entity> | TreeNode<FinancialAccount>;

interface Filters {
    accountIds?: number[];
}

interface AggregatedAccountsSearchProps extends UseFinancialAccountsOptions {
    filters: Filters;
    onChange(value: Filters): void;
    businessOnly?: boolean;
}

interface EntityWithAccounts extends Entity {
    children: FinancialAccount[];
}

export const AggregatedAccountsFilter: React.FC<
    AggregatedAccountsSearchProps
> = ({ filters, onChange, businessOnly, ...options }) => {
    const [search, setSearch] = useState("");
    const searchRef = useRef<HTMLInputElement>();
    const entities = useEntities();
    const bankAccounts = useFinancialAccounts(options);

    const entitiesWithAccounts: EntityWithAccounts[] = useMemo(
        () =>
            entities
                .map((entity) => ({
                    ...entity,
                    children: bankAccounts.filter(
                        (account) => account.entity?.id === entity.id,
                    ),
                }))
                .filter(
                    ({ children, isBusiness }) =>
                        children.length > 0 && (!businessOnly || isBusiness),
                ),
        [bankAccounts, entities, businessOnly],
    );

    const isChecked = useCallback(
        (accountId: number): boolean =>
            (filters.accountIds ?? []).some(
                (selectedAccountId) => selectedAccountId === accountId,
            ),
        [filters.accountIds],
    );

    const reverseSelection = () => {
        const newAccountIds = bankAccounts
            .filter((account) => !filters.accountIds?.includes(account.id))
            .map(({ id }) => id);

        onChange({ accountIds: newAccountIds });
    };

    const handleChange = useCallback(
        (accountId: number): void => {
            let accountIdsToSet: number[] | undefined = undefined;
            if (isChecked(accountId)) {
                accountIdsToSet = (filters.accountIds ?? []).filter(
                    (selectedAccountIds) => selectedAccountIds !== accountId,
                );
            } else {
                accountIdsToSet = [...(filters.accountIds ?? []), accountId];
            }

            onChange({ accountIds: accountIdsToSet });

            searchRef.current?.focus();
        },
        [isChecked, filters.accountIds, onChange],
    );

    const accountMatches = useCallback(
        (financialAccount: FinancialAccount) =>
            (financialAccount.name ?? "")
                .toLowerCase()
                .includes(search.toLowerCase()) ||
            (financialAccount.accountNumberMask ?? "")
                .toLowerCase()
                .includes(search.toLowerCase()),
        [search],
    );

    const entityFinancialAcountMatchesSearch = useCallback(
        (entity: EntityWithAccounts) =>
            entity.children.some((financialAccount) =>
                accountMatches(financialAccount),
            ),
        [accountMatches],
    );

    const entitiesToDisplay = useMemo(
        () =>
            entitiesWithAccounts
                .filter((entity) => entityFinancialAcountMatchesSearch(entity))
                .map((entity) => ({
                    ...entity,
                    children: entity.children.filter((financialAccount) =>
                        accountMatches(financialAccount),
                    ),
                })),
        [
            accountMatches,
            entitiesWithAccounts,
            entityFinancialAcountMatchesSearch,
        ],
    );

    useEffect(() => {
        searchRef.current?.focus();

        return () => {
            setSearch("");
        };
    }, []);

    const renderNode = useCallback(
        (node: NodeType, helpers: TreeHelpers) => {
            if (isEntityNode(node)) {
                return (
                    <div className={css.entityLabelContainer}>
                        <EntityItemLabel entity={node} hideTriangle={true} />
                    </div>
                );
            } else {
                return (
                    <AccountTreeItem
                        {...helpers}
                        node={node}
                        toggleSelected={(accountId) => handleChange(accountId)}
                        isSelected={(accountId) => isChecked(accountId)}
                    />
                );
            }
        },
        [handleChange, isChecked],
    );

    return (
        <div className={css.filter}>
            <FilterSearch
                value={search}
                onChange={setSearch}
                inputRef={searchRef}
            />
            <div className={css.categories}>
                <Scrollbars style={{ width: "100%", height: "100%" }}>
                    {entitiesToDisplay.length > 0 ? (
                        <TreeProvider
                            items={entitiesToDisplay as ItemType[]}
                            expandAll={true}
                        >
                            <Tree renderNode={renderNode} />
                        </TreeProvider>
                    ) : (
                        <p className={css.empty}>No matching accounts</p>
                    )}
                </Scrollbars>
            </div>

            {!!filters.accountIds?.length && (
                <FilterActionsFooter
                    reverseFunction={reverseSelection}
                    deselectFunction={() => onChange({ accountIds: undefined })}
                    selectedCount={filters.accountIds.length}
                />
            )}
        </div>
    );
};

function isEntityNode(node: NodeType): node is TreeNode<Entity> {
    return "profile" in node.current;
}
