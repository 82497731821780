import { Transaction } from "../../../types/transaction";
import { TransactionDto } from "../../transactions/transaction.dto";

export interface MultipleMatchingConflict {
    transactionFromDestination: Transaction;
    transactionsToMerge: Transaction[];
}

export class MultipleMatchingConflictDto {
    transactionFromDestination: TransactionDto;
    transactionsToMerge: TransactionDto[];

    constructor(conflict: MultipleMatchingConflict) {
        this.transactionFromDestination = new TransactionDto(
            conflict.transactionFromDestination,
        );
        this.transactionsToMerge = conflict.transactionsToMerge.map(
            (t) => new TransactionDto(t),
        );
    }

    static deserialize(
        dto: MultipleMatchingConflictDto,
    ): MultipleMatchingConflict {
        return {
            transactionFromDestination: TransactionDto.deserialize(
                dto.transactionFromDestination,
            ),
            transactionsToMerge: dto.transactionsToMerge.map((t) =>
                TransactionDto.deserialize(t),
            ),
        };
    }
}
