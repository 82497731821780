import React from "react";
import { EmptyState } from "../general/EmptyState/EmptyState";

export interface NoMatchingTransactionsProps {
    onResetFilters: () => void;
}

export const NoMatchingTransactions: React.FC<NoMatchingTransactionsProps> = ({
    onResetFilters,
}) => (
    <EmptyState
        header="No matching transactions"
        body="The filters don’t match any of the transactions."
        ctaText="Clear filters"
        action={onResetFilters}
    />
);
