import { initContract } from "@ts-rest/core";
import { z } from "zod";
import { Class } from "../types/class";

const c = initContract();

export const classContract = c.router(
    {
        getClasses: {
            method: "GET",
            path: "/:workspaceId",
            responses: {
                200: c.type<{ classes: Class[] }>(),
            },
        },
        getClassTransactionsCount: {
            method: "GET",
            path: "/:workspaceId/transactions-count",
            responses: {
                200: c.type<Record<string, number>>(),
            },
        },
        createClass: {
            method: "POST",
            path: "/:workspaceId",
            responses: {
                201: c.type<{ class: Class }>(),
            },
            body: z.object({
                label: z.string(),
                parentClassId: z.string().nullable(),
            }),
        },
        updateClass: {
            method: "PATCH",
            path: "/:workspaceId/:id",
            responses: {
                200: c.type<{ class: Class }>(),
            },
            body: z.object({
                label: z.string().optional(),
            }),
        },
        changeClassPosition: {
            method: "POST",
            path: "/:workspaceId/:id/position",
            responses: {
                200: c.type<{ class: Class }>(),
            },
            body: z.object({
                parentClassId: z.string().nullable(),
                order: z.number().nonnegative().optional(),
                mergingStrategy: z.enum(["child", "parent"]),
            }),
        },
        deleteClass: {
            method: "DELETE",
            path: "/:workspaceId/:id",
            responses: {
                200: z.void(),
            },
        },
    },
    {
        pathPrefix: "/class",
    },
);
