import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    Navigate,
    Outlet,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";
import { PREMIUM_FEATURES } from "../common/flatRateBilling";
import { useAppConfig } from "../hooks/useAppConfig";
import { useMobileBlockScreen } from "../hooks/useMobileView";
import { useOnboardingStatus } from "../hooks/useOnboardingStatus";
import { usePageTracking } from "../hooks/usePageTracking";
import { LaptopIcon } from "../icons";
import { clearRedirectPath } from "../lib/redirectAfterLogin";
import { AccountingPage } from "./accounting/AccountingPage";
import { BalanceSheetAccessPage } from "./accounting/balanceSheet/BalanceSheetAccessPage";
import { ChartOfAccountsPage } from "./accounting/chartOfAccounts/ChartOfAccounts";
import { GeneralLedgerAccessPage } from "./accounting/generalLedger/GeneralLedgerAccessPage";
import { ProfitLossPreviewPage } from "./accounting/profitLoss/ProfitLossPreviewPage";
import { AccountingTabProvider } from "./accounting/useAccountingTab";
import { AccountsPage } from "./accounts/AccountsPage";
import { AddAccountsRoute } from "./accounts/AddAccounts/AddAccountsRoute";
import { ActionsPage } from "./actions/ActionsPage";
import { ActionsTabs } from "./actions/ActionsTabs";
import { Convert } from "./billing/Convert";
import { Subscribe } from "./billing/Subscribe";
import { Upgrade } from "./billing/Upgrade";
import { DocsToDocumentsRedirect } from "./documents/DocsToDocumentsRedirect";
import { FinancialDocumentsPage } from "./documents/FinancialDocumentsPage";
import { FinancialDocumentUploadProvider } from "./documents/FinancialDocumentUpload/FinancialDocumentUploadProvider";
import { FeatureAccess } from "./general/FeatureAccess/FeatureAccess";
import { MobileBlock } from "./general/MobileBlock/MobileBlock";
import { InvoicesPage } from "./invoices/InvoicesPage";
import { PaginatedInvoicesTableContextProvider } from "./invoices/PaginatedInvoicesTableContextProvider";
import {
    MainInterface,
    MainInterfaceProps,
} from "./mainInterface/MainInterface";
import { MemberManagementProvider } from "./memberManagement/MemberManagementProvider";
import { NavigationProvider } from "./navigation/NavigationContext";
import { OAuthAuthorize } from "./oauth/authorization/OAuthAuthorize";
import { OAuthCallback } from "./oauth/authorization/OAuthCallback";
import { NewEntity } from "./onboarding/flows/NewEntity";
import { Onboarding } from "./onboarding/Onboarding";
import { NewConnectionHandler } from "./plaid/NewConnection/NewConnectionHandler";
import { CashFlowPage } from "./reports/CashFlowPage";
import { CategoriesPage } from "./settings/Categories/CategoriesPage";
import { ClassesPage } from "./settings/Classes/ClassesPage";
import { RulesPage } from "./settings/Rules/RulesPage";
import { SettingsProvider } from "./settings/SettingsProvider";
import { SettingsRoute } from "./settings/SettingsRoute";
import { SimulationsDashboard } from "./simulations/SimulationsDashboard";
import { TransactionsPageContainer } from "./transactions/TransactionsPageContainer";
import { usePrefetchTransactions } from "./transactions/usePrefetchTransactions";

const ALLOWED_ON_TABLET = ["/onboarding"];

export const PrivateInterface: React.FC = () => {
    const [redirectChecked, setRedirectChecked] = useState(false);
    const showMobileBlockScreen = useMobileBlockScreen();
    const isOnboarded = useOnboardingStatus();
    // The useAppConfig call is to ensure that the app is observing the query, and the data does not get cleared after the default gc time elapses 5min
    useAppConfig();
    const navigate = useNavigate();
    const location = useLocation();
    usePageTracking();
    usePrefetchTransactions();

    const pathCanBeRedirected = useCallback((checkedPath: string) => {
        const pathsWithoutRedirection = [
            "/onboarding",
            "/oauth",
            "/checkout",
            "/subscribe",
            "/simulations",
        ];

        return !pathsWithoutRedirection.some((path) =>
            checkedPath.startsWith(path),
        );
    }, []);

    const showMobileBlock = useMemo(
        () =>
            showMobileBlockScreen &&
            !ALLOWED_ON_TABLET.some((p) => location.pathname.startsWith(p)),
        [showMobileBlockScreen, location.pathname],
    );

    const accountingBreadcrumbs = useMemo(() => {
        const breadcrumbs: MainInterfaceProps["breadcrumbs"] = [
            {
                label: "Accounting",
                to: "/accounting",
            },
        ];

        switch (location.pathname) {
            case "/accounting/profit-loss":
                breadcrumbs.push({
                    label: "Profit & Loss",
                });
                break;
            case "/accounting/balance-sheet":
                breadcrumbs.push({
                    label: "Balance Sheet",
                });
                break;
            case "/accounting/general-ledger":
                breadcrumbs.push({
                    label: "General Ledger",
                });
                break;
            case "/accounting/chart-of-accounts":
                breadcrumbs.push({
                    label: "Chart of Accounts",
                });
                break;
            default:
                delete breadcrumbs[0].to; // not interactable when there are no breadcrumbs
                break;
        }

        return breadcrumbs;
    }, [location.pathname]);

    useEffect(() => {
        setRedirectChecked(true);

        if (pathCanBeRedirected(location.pathname) && !isOnboarded) {
            navigate("/onboarding");
            return;
        }

        if (isOnboarded && location.pathname.startsWith("/onboarding")) {
            navigate("/");
        }
    }, [location.pathname, isOnboarded, pathCanBeRedirected, navigate]);

    useEffect(clearRedirectPath, []);

    if (!redirectChecked) {
        return null;
    }

    return (
        <NavigationProvider>
            <MemberManagementProvider>
                <SettingsProvider>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <MainInterface
                                    breadcrumbs={[{ label: "Actions" }]}
                                    subNavigation={<ActionsTabs />}
                                >
                                    <ActionsPage />
                                </MainInterface>
                            }
                        />
                        <Route
                            path="/invoices"
                            element={
                                <MainInterface
                                    breadcrumbs={[{ label: "Invoicing" }]}
                                >
                                    <PaginatedInvoicesTableContextProvider>
                                        <InvoicesPage />
                                    </PaginatedInvoicesTableContextProvider>
                                </MainInterface>
                            }
                        />

                        <Route
                            path="/accounts"
                            element={
                                <MainInterface
                                    breadcrumbs={[{ label: "Accounts" }]}
                                >
                                    <AccountsPage />
                                </MainInterface>
                            }
                        />

                        <Route
                            path="/transactions/:entityId?"
                            element={
                                <MainInterface
                                    breadcrumbs={[{ label: "Transactions" }]}
                                >
                                    <TransactionsPageContainer />
                                </MainInterface>
                            }
                        />

                        <Route
                            path="/rules"
                            element={
                                <MainInterface
                                    breadcrumbs={[{ label: "Rules" }]}
                                >
                                    <RulesPage />
                                </MainInterface>
                            }
                        />

                        <Route
                            path="/categories"
                            element={
                                <MainInterface
                                    breadcrumbs={[{ label: "Categories" }]}
                                >
                                    <CategoriesPage />
                                </MainInterface>
                            }
                        />

                        <Route
                            path="/classes"
                            element={
                                <MainInterface
                                    breadcrumbs={[{ label: "Classes" }]}
                                >
                                    <ClassesPage />
                                </MainInterface>
                            }
                        />

                        <Route
                            path="/actions/:category?"
                            element={
                                <MainInterface
                                    breadcrumbs={[{ label: "Actions" }]}
                                    subNavigation={<ActionsTabs />}
                                >
                                    <ActionsPage />
                                </MainInterface>
                            }
                        />

                        <Route
                            path="/cashflow"
                            element={
                                <MainInterface
                                    breadcrumbs={[{ label: "Cash Flow" }]}
                                >
                                    <CashFlowPage />
                                </MainInterface>
                            }
                        />

                        <Route
                            path="/documents/:documentId?/:view?"
                            element={
                                <MainInterface
                                    breadcrumbs={[{ label: "Documents" }]}
                                >
                                    <FinancialDocumentUploadProvider>
                                        <FinancialDocumentsPage />
                                    </FinancialDocumentUploadProvider>
                                </MainInterface>
                            }
                        />

                        <Route
                            path="/docs/*"
                            element={<DocsToDocumentsRedirect />}
                        />

                        <Route
                            path="/accounting"
                            element={
                                <MainInterface
                                    breadcrumbs={accountingBreadcrumbs}
                                >
                                    <AccountingTabProvider>
                                        <Outlet />
                                    </AccountingTabProvider>
                                </MainInterface>
                            }
                        >
                            <Route index element={<AccountingPage />} />
                            <Route
                                path="profit-loss"
                                element={<ProfitLossPreviewPage />}
                            />
                            <Route
                                path="balance-sheet"
                                element={<BalanceSheetAccessPage />}
                            />
                            <Route
                                path="general-ledger"
                                element={<GeneralLedgerAccessPage />}
                            />
                            <Route
                                path="chart-of-accounts"
                                element={<ChartOfAccountsPage />}
                            />
                        </Route>

                        <Route path="/onboarding" element={<Onboarding />} />

                        <Route
                            path="/new-entity"
                            element={
                                <FeatureAccess
                                    feature={
                                        PREMIUM_FEATURES.UNLIMITED_ENTITIES
                                    }
                                    renderAllowed={() => <NewEntity />}
                                    renderBlocked={() => (
                                        <Navigate
                                            to="/settings/plans"
                                            replace
                                        />
                                    )}
                                />
                            }
                        />

                        <Route path="/settings/*" element={<SettingsRoute />} />
                        <Route
                            path={`/add-account`}
                            element={<AddAccountsRoute />}
                        />
                        <Route
                            path="/simulations"
                            element={<SimulationsDashboard />}
                        />

                        <Route path="/oauth">
                            <Route
                                path="authorize/:service"
                                element={<OAuthAuthorize />}
                            />
                            <Route
                                path="callback/:service"
                                element={<OAuthCallback />}
                            />
                        </Route>

                        <Route
                            path="/subscribe/:plan"
                            element={<Subscribe />}
                        />

                        <Route path="/upgrade/:plan" element={<Upgrade />} />

                        <Route path="/convert" element={<Convert />} />

                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>

                    {showMobileBlock && (
                        <MobileBlock
                            fullscreen
                            icon={<LaptopIcon />}
                            heading="Kick isn't available on mobile just yet"
                            showLogoutButton
                        >
                            <p>Please log in on a desktop browser.</p>
                        </MobileBlock>
                    )}
                </SettingsProvider>
            </MemberManagementProvider>

            <NewConnectionHandler />
        </NavigationProvider>
    );
};
