import React from "react";
import { useBillingType } from "../../../hooks/useBillingType";
import { SubscriptionType } from "../../../common/types/billing";
import { useEffectOnce } from "../../../hooks/useEffectOnce";
import { trackEvent } from "../../../lib/analytics";
import { FlatRateBilling } from "./FlatRate/FlatRateBilling";
import { BillingPageCustomPricing } from "./BillingPageCustomPricing";

export const BillingPage: React.FC = () => {
    const billingType = useBillingType();

    useEffectOnce(() => {
        void trackEvent("view_billing_tab");
    });

    let content;

    if (billingType === SubscriptionType.FLAT_RATE) {
        content = <FlatRateBilling />;
    } else {
        content = <BillingPageCustomPricing />;
    }

    return (
        <div className="settings__billing billing">
            <header className="settings__header">
                <h3>Billing</h3>
            </header>

            {content}
        </div>
    );
};
