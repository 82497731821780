import { useMemo } from "react";
import { useCategoriesTree } from "../../../hooks/useCategoriesTree";
import { useAllowFeature } from "../../../hooks/useAllowFeature";
import { PREMIUM_FEATURES } from "../../../common/flatRateBilling";
import { buildFlattenedItems } from "./utils";

export function useCustomCategoryFlattenedItems(search: string) {
    const categories = useCategoriesTree();
    const allowCreateNewCustomCategory = useAllowFeature(
        PREMIUM_FEATURES.CUSTOM_CATEGORIES,
    );

    const { items } = useMemo(
        () =>
            buildFlattenedItems(
                categories,
                allowCreateNewCustomCategory,
                search,
            ),
        [categories, search, allowCreateNewCustomCategory],
    );

    return items;
}
