import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { finishPasswordlessLogin } from "../../../lib/auth0";
import { Loader } from "../../general/Loader";
import { useManageSteps } from "../useManageSteps";
import { Register } from "../steps/Register/Register";
import { isInviteCodeValid, register } from "../../../lib/onboarding";
import { RegisterCodeVerification } from "../steps/RegisterCodeVerification";
import { CodeVerificationProps } from "../../general/CodeVerification/CodeVerification";
import { OnboardingLayout } from "../layout/OnboardingLayout";
import { login } from "../../../api/auth.api";

type RegistrationSteps = "start" | "verify";

interface Props {
    onFinished?: () => Promise<void>;
}

interface RouteParams {
    inviteCode?: string;
}

export const RegistrationFlow: React.FC<Props> = () => {
    const { inviteCode } = useParams() as Readonly<RouteParams>;
    const [providedEmail, setProvidedEmail] = useState<string>();

    const { data: inviteCodeResponse, isFetching } = useQuery({
        queryKey: ["inviteCode", inviteCode],
        queryFn: () => isInviteCodeValid(inviteCode!),
        refetchOnWindowFocus: false,
        enabled: !!inviteCode,
    });

    const { go, currentStep, nextStep, isExiting } =
        useManageSteps<RegistrationSteps>(true, () => "start");

    const requestVerificationCode = useCallback(async (email: string) => {
        try {
            await login(email);
        } catch {
            return;
        }
    }, []);

    const handleRegistrationStarted = useCallback(
        async (email: string) => {
            setProvidedEmail(email);
            await register(email, inviteCode);
            await requestVerificationCode(email);

            go("verify");
        },
        [go, requestVerificationCode, inviteCode],
    );

    const verify: Required<CodeVerificationProps>["verify"] = useCallback(
        async (verificationCode) =>
            await finishPasswordlessLogin(providedEmail!, verificationCode),
        [providedEmail],
    );

    let main: React.ReactNode;

    switch (currentStep) {
        case "start":
            main = (
                <Register
                    onSubmit={handleRegistrationStarted}
                    initialEmail={providedEmail ?? inviteCodeResponse?.email}
                />
            );
            break;
        case "verify":
            main = (
                <RegisterCodeVerification
                    email={providedEmail!}
                    verifyCode={verify}
                    requestVerification={() =>
                        requestVerificationCode(providedEmail!)
                    }
                />
            );
            break;
    }

    if (isFetching) {
        return <Loader />;
    } else if (inviteCodeResponse && !inviteCodeResponse.valid) {
        main = <p>This invite link has already been used.</p>;
    }

    return (
        <OnboardingLayout
            isExiting={isExiting}
            currentStep={currentStep}
            nextStep={nextStep}
            main={main}
        />
    );
};
