import React, { useState } from "react";
import { FinancialAccount } from "../../../common/types/financialAccount";
import { Button, ExtendedButtonProps } from "../../general/Button/Button";
import { RemoveFinancialAccountModal } from "./RemoveFinancialAccountModal/RemoveFinancialAccountModal";

interface Props extends Omit<ExtendedButtonProps, "onClick"> {
    account: FinancialAccount;
}
export const RemoveFinancialAccount: React.FC<Props> = ({
    account,
    children,
    ...btnProps
}) => {
    const [showModal, setShowModal] = useState(false);
    const closeModal = () => setShowModal(false);

    return (
        <>
            <Button onClick={() => setShowModal(true)} {...btnProps}>
                {children}
            </Button>
            <RemoveFinancialAccountModal
                showModal={showModal}
                setShowModal={setShowModal}
                account={account}
                closeModal={closeModal}
            />
        </>
    );
};
