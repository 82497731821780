import React from "react";
import { BaseFilterProps } from "../../lib";
import { FormCheckbox } from "../../../../forms/FormCheckbox/FormCheckbox";
import styles from "./SplitFilter.module.scss";

export const SplitFilter: React.FC<BaseFilterProps> = ({
    filters,
    onChange,
}) => (
    <section className={styles.direction}>
        <header className={styles.header}>Split</header>

        <FormCheckbox
            value="all"
            label="All"
            isChecked={[filters.isSplitted, filters.isNotSplitted].every(
                (value) => !value,
            )}
            handleChange={() =>
                onChange({
                    isSplitted: undefined,
                    isNotSplitted: undefined,
                })
            }
            small
        />
        <FormCheckbox
            value="splitted"
            label="Split"
            isChecked={!!filters.isSplitted}
            handleChange={() => onChange({ isSplitted: !filters.isSplitted })}
            small
        />
        <FormCheckbox
            value="not-splitted"
            label="Not split"
            isChecked={!!filters.isNotSplitted}
            handleChange={() =>
                onChange({ isNotSplitted: !filters.isNotSplitted })
            }
            small
        />
    </section>
);
