import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { TreeContext } from "./Tree.context";
import { TreeItem, TreeNode, WithId } from "./Tree.types";

export interface TreeProviderProps<T extends WithId> {
    items: Array<TreeItem<T>>;
    children: ReactNode;
    expandAll?: boolean;
    forceExpanded?: string[];
    initiallyExpanded?: string[];
}

export const TreeProvider = <T extends WithId>({
    items,
    children,
    expandAll,
    forceExpanded = [],
    initiallyExpanded = [],
}: TreeProviderProps<T>) => {
    const nodes = useMemo(() => convertToNodes({ items }), [items]);

    const [expandedNodes, setExpandedNodes] =
        useState<string[]>(initiallyExpanded);

    const isExpanded = useCallback(
        (nodeId: string) =>
            !!expandAll ||
            expandedNodes.includes(nodeId) ||
            forceExpanded.includes(nodeId),
        [expandAll, expandedNodes, forceExpanded],
    );

    const expandNode = useCallback((nodeId: string) => {
        setExpandedNodes((prev) => [...prev, nodeId]);
    }, []);

    const collapseNode = useCallback((nodeId: string) => {
        setExpandedNodes((prev) => prev.filter((n) => n !== nodeId));
    }, []);

    const [hoveredNodeId, setHoveredNodeId] = useState<string | null>(null);
    const isHovered = useCallback(
        (nodeId: string) => hoveredNodeId === nodeId,
        [hoveredNodeId],
    );
    const hoverNode = useCallback(
        (nodeId: string) => setHoveredNodeId(nodeId),
        [],
    );
    const unhoverNode = useCallback(() => setHoveredNodeId(null), []);

    const value = useMemo(
        () => ({
            collapseNode,
            expandNode,
            hoverNode,
            isExpanded,
            isHovered,
            nodes,
            unhoverNode,
        }),
        [
            collapseNode,
            expandNode,
            hoverNode,
            isExpanded,
            isHovered,
            nodes,
            unhoverNode,
        ],
    );

    return (
        <TreeContext.Provider value={value}>{children}</TreeContext.Provider>
    );
};

interface ConvertToNodesParams<T extends WithId> {
    items: Array<TreeItem<T>>;
    parent?: T;
    depth?: number;
    parentId?: string;
}

export function convertToNodes<T extends WithId>({
    items,
    parent,
    depth = 0,
    parentId,
}: ConvertToNodesParams<T>): Array<TreeNode<T>> {
    return items.map((item) => {
        const nodeId = parentId ? `${parentId}.${item.id}` : `${item.id}`;
        return {
            nodeId,
            current: item,
            parent,
            depth,
            children: item.children
                ? convertToNodes({
                      items: item.children,
                      parent: item,
                      depth: depth + 1,
                      parentId: nodeId,
                  })
                : [],
            className: item.className,
        };
    });
}
