import React, { useMemo } from "react";
import classNames from "classnames";
import { ClassIconList } from "../ClassIconList/ClassIconList";
import { useClasses } from "../../../api/class.api";
import { Class } from "../../../common/types/class";
import { PlusIcon } from "../../../icons";
import { TransactionsFilters } from "../../transactions/filters/lib";
import styles from "./styles.module.scss";

interface ClassFilterTriggerProps {
    value: TransactionsFilters["classes"];
    onClassClick?: (classId: string) => void;
}

const MAX_CLASSES_TO_DISPLAY = 4;

export const ClassFilterTrigger: React.FC<ClassFilterTriggerProps> = ({
    value,
    onClassClick,
}) => {
    const { classes, sortedClassesIndexMap } = useClasses();

    const selectedClasses = useMemo(() => {
        if (!value?.length) {
            return [];
        }

        const extractedClasses = value
            .map((v) => classes.find((c) => v.includes(c.id)))
            .filter(Boolean) as Class[];

        return extractedClasses.sort((a, b) => {
            const aIndex = sortedClassesIndexMap.get(a.id) ?? Infinity;
            const bIndex = sortedClassesIndexMap.get(b.id) ?? Infinity;
            return aIndex - bIndex;
        });
    }, [classes, value, sortedClassesIndexMap]);

    return selectedClasses.length > 0 ? (
        <ClassIconList
            classes={selectedClasses}
            maxClassesToDisplay={MAX_CLASSES_TO_DISPLAY}
            onClassClick={onClassClick}
        />
    ) : (
        <div
            className={classNames("d-flex align-items-center", styles.addClass)}
        >
            <PlusIcon /> <span className="ml-1">Add</span>
        </div>
    );
};
