import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "../../icons";
import { Tag } from "../general/Tag/Tag";
import styles from "./AccountingReportLink.module.scss";

interface Props {
    title: React.ReactNode;
    description: string;
    active?: boolean;
    link?: string;
    comingSoon?: boolean;
}

export const AccountingReportLink: React.FC<Props> = ({
    title,
    description,
    active,
    link,
    comingSoon,
}) => (
    <Link
        to={link ?? "#"}
        className={classNames(styles.section, {
            [styles.active]: active,
            [styles.sectionNoClickable]: !link,
        })}
    >
        <div className={styles.title}>
            {title}

            {comingSoon && (
                <Tag className={styles.comingSoon} variant="secondary">
                    Coming Soon
                </Tag>
            )}
        </div>
        <div className={styles.description}>{description}</div>
        <ArrowRightIcon />
    </Link>
);
