import { useWorkspaceContext } from "../state/workspaceContext";
import { SubscriptionType } from "../common/types/billing";
import { useUser } from "./useUser";
import { useBillingStatus } from "./useBillingStatus";
import { useIsDemoUser } from "./useIsDemoUser";

export interface UseBillingManagement {
    canManageBilling: boolean;
    bookkeepingStartDate: Date | null;
    bookkeepingEndDate: Date | null;

    showUsageLimitReachedCta: boolean; // no future transactions visible, because of expense limit reached
    showPastTransactionsCta: boolean; // no past transactions visible, because of previous year bookkeeping not included
    currentUsageLimit: number;
}

export function useBillingManagement(): UseBillingManagement {
    const user = useUser();
    const isDemoUser = useIsDemoUser();
    const { isTrialing, plans, isFetched, subscriptionType } =
        useBillingStatus();

    const { activeWorkspace } = useWorkspaceContext();

    if (!isFetched) {
        return {
            canManageBilling: false,
            bookkeepingStartDate: user.bookkeepingStartDate,
            bookkeepingEndDate: user.bookkeepingEndDate,
            showUsageLimitReachedCta: false,
            showPastTransactionsCta: false,
            currentUsageLimit: user.freeUsageLimit,
        };
    }

    const canManageBilling =
        activeWorkspace?.primaryAdminUserId === user.id && !isDemoUser;

    const [freePlan] = plans;
    const hasLimitedAccess = !!user.bookkeepingEndDate;

    const showUsageLimitReachedCta = hasLimitedAccess;

    const showPastTransactionsCta =
        subscriptionType === SubscriptionType.FLAT_RATE &&
        (isTrialing || hasLimitedAccess);

    return {
        canManageBilling,
        showUsageLimitReachedCta,
        showPastTransactionsCta,

        currentUsageLimit: freePlan.usageLimit,
        bookkeepingEndDate: user.bookkeepingEndDate,
        bookkeepingStartDate: user.bookkeepingStartDate,
    };
}
