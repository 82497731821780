import React, { useCallback } from "react";
import { PlaidConnection } from "../../common/types/plaidConnection";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";
import {
    useAddPlaidAccountsMutation,
    usePlaidConnectionFixingMutation,
} from "../../mutations/plaidConnection";
import { ExtendedButtonProps } from "../general/Button/Button";

interface Props extends Omit<ExtendedButtonProps, "onClick"> {
    connection: PlaidConnection;
    accountChurned?: boolean;
    onFixed?: () => void;
}

export const FixPlaidConnection: React.FC<Props> = ({
    connection,
    onFixed,
    accountChurned,
    children,
    ...btnProps
}) => {
    const fixAccount = usePlaidConnectionFixingMutation(connection, onFixed);
    const addAccounts = useAddPlaidAccountsMutation(onFixed);

    const fix = useCallback(() => {
        if (accountChurned) {
            addAccounts.mutate(connection);
        } else {
            fixAccount.mutate();
        }
    }, [accountChurned, addAccounts, connection, fixAccount]);

    return (
        <ButtonWithLoader
            onClick={fix}
            loading={fixAccount.isPending || addAccounts.isPending}
            {...btnProps}
        >
            {children}
        </ButtonWithLoader>
    );
};
