import React from "react";
import classNames from "classnames";
import { Footer } from "../components/Footer";
import animations from "../layout/OnboardingLayout.module.scss";
import { PARTNER_INTRO_CALL_URL } from "../../../common/billing";
import { BookCallWidget } from "../../general/BookCall/BookCallWidget";
import commonStyles from "./common.module.scss";
import styles from "./IntroCall.module.scss";

interface Props {
    onBack(): void;
    onCallBooked: () => Promise<void>;
}

export const IntroCall: React.FC<Props> = ({ onCallBooked, onBack }) => (
    <div className={commonStyles.content1100}>
        <header className={animations.fadeIn2}>
            <h1 className={commonStyles.title}>
                You’re a great fit for Kick’s Partner Program
            </h1>

            <p className={commonStyles.subtitle}>
                Schedule a 30-min walkthrough with a Kick CPA and set up a test
                account.
            </p>
        </header>

        <main
            className={classNames(animations.fadeIn1, styles.introCallWidget)}
        >
            <BookCallWidget
                url={PARTNER_INTRO_CALL_URL}
                onBooked={onCallBooked}
            />
        </main>

        <Footer onBack={onBack} />
    </div>
);
