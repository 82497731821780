import React from "react";
import { Alert } from "react-bootstrap";
import { LockIcon } from "../../../icons";

export const PersonalDataAlert: React.FC = () => (
    <Alert variant="cta" className="mb-5">
        <LockIcon className="icon-color-current mr-1" />
        Kick does not store any account password information
    </Alert>
);
