import { useCallback, useMemo } from "react";
import { useEntities } from "../../../hooks/useEntities";
import { TransactionsFilters } from "../../transactions/filters/lib";

export interface UseEntityAccountsFilterOptions {
    value?: number[];
    accountIds?: number[];
    onChange(value: Partial<TransactionsFilters>): void;
    onlyBusiness?: boolean;
    excludeAllMockEntities?: boolean;
    excludeEmptyMockEntities?: boolean;
    includeCreditCards?: boolean;
    defaultSelected?: boolean;
}

export function useEntityAccountsFilter({
    value: entityIds,
    accountIds,
    excludeAllMockEntities,
    excludeEmptyMockEntities,
    onChange,
    onlyBusiness,
    defaultSelected = true,
}: UseEntityAccountsFilterOptions) {
    const entities = useEntities({
        onlyBusiness,
        excludeAllMockEntities,
        excludeEmptyMockEntities,
    });

    const isEntitySelected = useCallback(
        (entityId: number) => {
            if (!entityIds) {
                return defaultSelected;
            }

            return entityIds.some((v) => v === entityId);
        },
        [defaultSelected, entityIds],
    );

    const isAccountSelected = useCallback(
        (accountId: number) => (accountIds || []).some((v) => v === accountId),
        [accountIds],
    );

    const toggleEntity = useCallback(
        (entityId: number) => {
            if (isEntitySelected(entityId)) {
                onChange({
                    entityIds: (entityIds || [])?.filter(
                        (eId) => eId !== entityId,
                    ),
                });
            } else {
                onChange({ entityIds: [...(entityIds || []), entityId] });
            }
        },
        [entityIds, isEntitySelected, onChange],
    );

    const toggleAccount = useCallback(
        (accountId: number) => {
            if (isAccountSelected(accountId)) {
                onChange({
                    accountIds: (accountIds || [])?.filter(
                        (eId) => eId !== accountId,
                    ),
                });
            } else {
                onChange({ accountIds: [...(accountIds || []), accountId] });
            }
        },
        [accountIds, isAccountSelected, onChange],
    );

    const everythingSelected = useMemo(
        () => !entityIds || entityIds.length === entities.length,
        [entityIds, entities],
    );

    return {
        entities,
        everythingSelected,
        isEntitySelected,
        isAccountSelected,
        toggleEntity,
        toggleAccount,
    };
}
