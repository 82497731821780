import React, { useCallback, useMemo, useState } from "react";
import {
    DndContext,
    DragOverEvent,
    DragStartEvent,
    PointerSensor,
    UniqueIdentifier,
    useSensor,
    useSensors,
} from "@dnd-kit/core";

import { useAccountingTabContext } from "../useAccountingTab";
import { AccountingReportFiltersForm } from "../AccountingReportFiltersForm";
import {
    GridTable,
    GridTableFallbacks,
    GridTableHeader,
} from "../../general/Tables/GridTable/GridTable";
import { SortableTreeList } from "../../general/SortableTreeList/SortableTreeList";
import { useChartOfAccountsQuery } from "../../../api/accountingReports.api";
import {
    AccountSubtype,
    AccountType,
} from "../../../common/constants/domains/accounting/accountsv2";
import {
    optimisticallyChangeAccountPosition,
    useChangeAccountPositionMutation,
    useDeactivateAccountMutation,
} from "../../../api/account.api";
import { useAccounts } from "../../../hooks/useAccounts";
import { ConfirmationModal } from "../../general/ConfirmationModal";
import { getSubtypeBordersInCoA } from "../../../common/constants/domains/accounting/chartOfAccountsV2";
import { MergedEntityType } from "../../../common/types/entity";
import { Button } from "../../general/Button/Button";
import { ChartOfAccountsItemClassification } from "./ChartOfAccountsItemClassification";
import {
    buildFlattenedItemsForChartOfAccounts,
    ExtendedFlattenedItemForAccount,
    ExtendedFlattenedItemForAccountMetaAccount,
    ExtendedFlattenedItemForAccountMetaClassification,
    getProjectionForChartOfAccountsList,
    isAccountWillBeDeletedOnDisabling,
} from "./utils";
import { CreateAccountModal } from "./CreateCoAAccountModal";
import styles from "./styles.module.scss";
import { ChartOfAccountsItem } from "./ChartOfAccountsItem";

const chartOfAccountsTableColumns = [
    {
        key: "name",
        label: "Account",
    },
    {
        key: "type",
        label: "Type",
    },
    {
        key: "subtype",
        label: "Subtype",
    },
    {
        key: "debit",
        label: "Debit",
    },
    {
        key: "credit",
        label: "Credit",
    },
    {
        key: "balance",
        label: "Balance",
    },
    {
        key: "actions",
        label: "",
    },
];

export const ChartOfAccountsPage: React.FC = () => {
    const { filters, setFilters, selectedEntity } = useAccountingTabContext();

    const { data } = useChartOfAccountsQuery(selectedEntity?.id ?? null);
    const { accounts } = useAccounts({
        entityId: selectedEntity?.id,
    });

    const { items: flattenedItems, itemIdToIndexMap } = useMemo(
        () =>
            buildFlattenedItemsForChartOfAccounts({
                accounts: data?.accounts,
                filters,
            }),
        [data?.accounts, filters],
    );

    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
    const [overId, setOverId] = useState<UniqueIdentifier | null>(null);

    const projected = useMemo(
        () =>
            activeId && overId
                ? getProjectionForChartOfAccountsList({
                      items: flattenedItems,
                      itemIdToIndexMap,
                      activeId,
                      overId,
                      indentationWidth: 50,
                      maxPossibleDepth: 1,
                  })
                : null,
        [activeId, overId, itemIdToIndexMap, flattenedItems],
    );

    const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);

    const accountNameState = useState("");
    const accountTypeState = useState<AccountType>(AccountType.cash);
    const accountSubtypeState = useState<AccountSubtype>(
        AccountSubtype.cashOnHand,
    );
    const [accountIdToEdit, setAccountIdToEdit] = useState<string | null>(null);

    const [_, setAccountName] = accountNameState;

    const [__, setAccountType] = accountTypeState;

    const [___, setAccountSubtype] = accountSubtypeState;

    const openAddAccountModal = useCallback(
        ({
            accountType,
            subType,
            newAccountIdToEdit,
        }: {
            accountType: AccountType;
            subType: AccountSubtype;
            newAccountIdToEdit?: string;
        }) => {
            const account = newAccountIdToEdit
                ? accounts.find((a) => a.id === newAccountIdToEdit)
                : null;

            setAccountName(account?.name ?? "");
            setAccountType(accountType);
            setAccountSubtype(subType);
            setShowCreateAccountModal(true);
            setAccountIdToEdit(newAccountIdToEdit ?? null);
        },
        [accounts, setAccountName, setAccountType, setAccountSubtype],
    );

    const resetState = useCallback(() => {
        setOverId(null);
        setActiveId(null);
    }, []);

    const handleDragCancel = useCallback(() => {
        resetState();
    }, [resetState]);

    const handleDragStart = useCallback(
        ({ active: { id: newActiveId } }: DragStartEvent) => {
            setActiveId(newActiveId);
            setOverId(newActiveId);
        },
        [setActiveId, setOverId],
    );

    const { mutateAsync: changeAccountPosition } =
        useChangeAccountPositionMutation();

    const handleDragEnd = useCallback(async () => {
        setActiveId(null);
        setOverId(null);

        if (
            !(
                selectedEntity?.profile?.type ??
                selectedEntity?.profile?.entityType
            ) ||
            !projected ||
            !activeId
        ) {
            return;
        }

        const subtypeBorders = getSubtypeBordersInCoA(
            (selectedEntity.profile?.type ??
                selectedEntity.profile?.entityType) as MergedEntityType,
            projected.targetType as AccountType,
            projected.targetSubtype as AccountSubtype,
        );

        const targetAccountCode =
            subtypeBorders.startFrom + projected.targetOrderInSubtype;
        if (
            targetAccountCode ===
            (
                flattenedItems[projected.activeItemIndex]
                    .meta as ExtendedFlattenedItemForAccountMetaAccount
            ).account.code
        ) {
            return;
        }

        optimisticallyChangeAccountPosition({
            entityId: selectedEntity.id.toString(),
            accountId: activeId as string,
            targetOrder: projected.targetOrderInSubtype,
            targetType: projected.targetType as AccountType,
            targetSubtype: projected.targetSubtype as AccountSubtype,
            entityType: (selectedEntity.profile?.type ??
                selectedEntity.profile?.entityType) as MergedEntityType,
        });
        await changeAccountPosition({
            params: {
                entityId: selectedEntity.id.toString(),
                id: activeId as string,
            },
            body: {
                targetOrder: projected.targetOrderInSubtype,
                targetType: projected.targetType as AccountType,
                targetSubtype: projected.targetSubtype as AccountSubtype,
            },
        });
    }, [
        selectedEntity?.profile,
        selectedEntity?.id,
        projected,
        activeId,
        flattenedItems,
        changeAccountPosition,
    ]);

    const handleDragOver = useCallback(({ over }: DragOverEvent) => {
        setOverId(over?.id ?? null);
    }, []);

    const sensors = useSensors(useSensor(PointerSensor));

    const [accountIdToDisable, setAccountIdToDisable] = useState<string | null>(
        null,
    );

    const numberOfAccountsPerSubtype = useMemo(
        () =>
            flattenedItems.reduce(
                (acc, item) => {
                    if (!("account" in item.meta)) {
                        return acc;
                    }
                    const key = `${item.meta.account.type}-${item.meta.account.subtype}`;
                    acc[key] = (acc[key] || 0) + 1;
                    return acc;
                },
                {} as Record<string, number>,
            ),
        [flattenedItems],
    );

    const renderItemCallback = useCallback(
        (item: ExtendedFlattenedItemForAccount) => {
            const activeItemIndex =
                activeId && itemIdToIndexMap.get(activeId as string);
            const activeItem =
                activeItemIndex && flattenedItems[activeItemIndex];

            if (activeItem && !("account" in activeItem.meta)) {
                return null;
            }

            const activeItemMeta = activeItem
                ? (activeItem.meta as ExtendedFlattenedItemForAccountMetaAccount)
                : undefined;

            if ("classification" in item.meta) {
                return (
                    <ChartOfAccountsItemClassification
                        item={
                            item as ExtendedFlattenedItemForAccount<ExtendedFlattenedItemForAccountMetaClassification>
                        }
                        disabledDuringDragging={
                            activeItemMeta &&
                            activeItemMeta.account.class !==
                                item.meta.classification &&
                            activeItemMeta.account.type !==
                                item.meta.classification
                        }
                    />
                );
            }

            const isDisabledDuringDragging: boolean = activeItemMeta
                ? activeItemMeta.account.subtype !==
                      item.meta.account.subtype ||
                  activeItemMeta.account.type !== item.meta.account.type
                : false;

            return (
                <ChartOfAccountsItem
                    key={item.id}
                    item={
                        item as ExtendedFlattenedItemForAccount<ExtendedFlattenedItemForAccountMetaAccount>
                    }
                    entityId={selectedEntity?.id ?? 0}
                    openAddAccountModal={openAddAccountModal}
                    isDisabledDuringDragging={isDisabledDuringDragging}
                    onDisableAccount={setAccountIdToDisable}
                    canReorder={
                        numberOfAccountsPerSubtype[
                            `${item.meta.account.type}-${item.meta.account.subtype}`
                        ] > 1
                    }
                />
            );
        },
        [
            selectedEntity?.id,
            openAddAccountModal,
            activeId,
            itemIdToIndexMap,
            flattenedItems,
            numberOfAccountsPerSubtype,
        ],
    );

    const nextCodeAfterDragEnd = useMemo(() => {
        if (
            !projected ||
            !(
                selectedEntity?.profile?.type ??
                selectedEntity?.profile?.entityType
            )
        ) {
            return null;
        }

        return (
            getSubtypeBordersInCoA(
                (selectedEntity.profile?.type ??
                    selectedEntity.profile?.entityType) as MergedEntityType,
                projected.targetType,
                projected.targetSubtype,
            ).startFrom + projected.targetOrderInSubtype
        );
    }, [projected, selectedEntity?.profile]);

    const renderDragOverlay = useCallback(
        (item: ExtendedFlattenedItemForAccount) => {
            if (!("account" in item.meta)) {
                return null;
            }
            return (
                <ChartOfAccountsItem
                    item={
                        item as ExtendedFlattenedItemForAccount<ExtendedFlattenedItemForAccountMetaAccount>
                    }
                    entityId={selectedEntity?.id ?? 0}
                    openAddAccountModal={openAddAccountModal}
                    onDisableAccount={setAccountIdToDisable}
                    isDragged
                    newAccountCodeOnDrag={nextCodeAfterDragEnd ?? undefined}
                />
            );
        },
        [
            selectedEntity?.id,
            openAddAccountModal,
            setAccountIdToDisable,
            nextCodeAfterDragEnd,
        ],
    );

    const accountToDisable = useMemo(
        () => data?.accounts.find((a) => a.id === accountIdToDisable),
        [accountIdToDisable, data?.accounts],
    );

    const { mutateAsync: deactivateAccount } = useDeactivateAccountMutation();

    const getItemId = useCallback(
        (item: ExtendedFlattenedItemForAccount) => item.id,
        [],
    );

    return (
        <DndContext
            sensors={sensors}
            onDragOver={handleDragOver}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            {accountIdToDisable}
            <AccountingReportFiltersForm
                filters={filters}
                setFilters={setFilters}
                requireDateSelection
                hideCreateJournalEntryButton
                hideDateRangeFilter
                hideClassFilter
                showAccountEnabledFilter
                additionalButtons={
                    <Button
                        onClick={() => setShowCreateAccountModal(true)}
                        variant="secondary"
                    >
                        Add account
                    </Button>
                }
            />
            <GridTable className={styles.report}>
                <GridTableHeader
                    columnBorders={false}
                    columns={chartOfAccountsTableColumns}
                    className={styles.headerRow}
                />

                {selectedEntity && (
                    <SortableTreeList
                        items={flattenedItems}
                        getItemId={getItemId}
                        listId="classes"
                        activeId={activeId}
                        projected={projected}
                        renderDragOverlay={renderDragOverlay}
                        renderItem={renderItemCallback}
                    />
                )}
                <GridTableFallbacks
                    header="No accounts for the selected filters"
                    dataCount={flattenedItems.length}
                />
            </GridTable>
            {selectedEntity && (
                <CreateAccountModal
                    show={showCreateAccountModal}
                    onHide={() => setShowCreateAccountModal(false)}
                    entity={selectedEntity}
                    accountNameState={accountNameState}
                    accountTypeState={accountTypeState}
                    accountSubtypeState={accountSubtypeState}
                    accountIdToEdit={accountIdToEdit}
                />
            )}

            <ConfirmationModal
                show={!!accountIdToDisable && !!accountToDisable}
                onHide={() => setAccountIdToDisable(null)}
                onReject={() => setAccountIdToDisable(null)}
                onConfirm={async () => {
                    if (selectedEntity?.id && accountToDisable?.id) {
                        await deactivateAccount({
                            params: {
                                entityId: String(selectedEntity?.id),
                                id: accountToDisable?.id,
                            },
                            body: {},
                        });
                        setAccountIdToDisable(null);
                    }
                }}
                title={
                    isAccountWillBeDeletedOnDisabling(accountToDisable)
                        ? "Are you sure you want to delete this account?"
                        : "Are you sure you want to disable this account?"
                }
                question={
                    isAccountWillBeDeletedOnDisabling(accountToDisable)
                        ? "Because account doesn't contain any journal entries, it will be permanently deleted. This action cannot be undone."
                        : "After disabling, the account will be hidden from all of the reports. You can re-enable it later."
                }
                yes={
                    isAccountWillBeDeletedOnDisabling(accountToDisable)
                        ? "Delete"
                        : "Disable"
                }
                yesBtnVariant={
                    isAccountWillBeDeletedOnDisabling(accountToDisable)
                        ? "danger"
                        : "default"
                }
            />
        </DndContext>
    );
};
