import {
    AccountType as AccountTypeV1,
    AccountSubtype as AccountSubtypeV1,
    DefaultCashBasisAccountCodes as DefaultCashBasisAccountCodesV1,
} from "../../../constants/domains/accounting/accountsv1";
import {
    AccountType as AccountTypeV2,
    AccountSubtype as AccountSubtypeV2,
    AccountClass,
    BalanceSheetDerivativeLines,
    DefaultCashBasisAccountCodes as DefaultCashBasisAccountCodesV2,
} from "../../../constants/domains/accounting/accountsv2";
import { Account } from "./account";

export type AccountType = AccountTypeV1 | AccountTypeV2;

export type AccountSubtype = AccountSubtypeV1 | AccountSubtypeV2;

// NOTE: re-export from yjr last version as they weren't changed
export { AccountClass, BalanceSheetDerivativeLines };

export type DefaultCashBasisAccountCodes =
    | DefaultCashBasisAccountCodesV1
    | DefaultCashBasisAccountCodesV2;

export enum ChartOfAccountsAvailableVersions {
    V1 = 1,
    V2 = 2,
}

export interface GetAccountsResponse {
    accounts: Account[];
    chartOfAccountsVersion: ChartOfAccountsAvailableVersions;
}
