import { useCallback, useContext, useMemo } from "react";
import { endOfDay } from "date-fns";
import { TransactionsFilters } from "../components/transactions/filters/lib";
import { Counterparty } from "../common/types/counterparty";
import { DirectoryContext } from "../components/settings/Directory/Directory.context";
import { Category } from "../common/types/category";

interface Options {
    includeDateFilter: boolean;
    categories?: Category[];
}

export function useCounterpartyTransactionsLink(
    counterparty: Counterparty,
    { includeDateFilter, categories }: Options,
) {
    const { filters } = useContext(DirectoryContext);

    return useMemo(() => {
        const transactionFilters: TransactionsFilters = {
            counterpartiesIds: [counterparty.id],
            category: categories?.map((c) => c.id),
        };

        if (includeDateFilter && filters.year) {
            transactionFilters.start = new Date(`${filters.year}-01-01`);
            transactionFilters.end = endOfDay(
                new Date(`${filters.year}-12-31`),
            );
        }

        return `/transactions?filters=${JSON.stringify(transactionFilters)}`;
    }, [counterparty.id, filters.year, includeDateFilter, categories]);
}

export function useCounterpartyTransactionsOpener(
    counterparty: Counterparty,
    options: Options,
) {
    const link = useCounterpartyTransactionsLink(counterparty, options);

    return useCallback(() => {
        window.open(link);
    }, [link]);
}
