import React, { useCallback, useMemo, useRef, useState } from "react";

import classNames from "classnames";
import { FormRowRaw } from "../../forms/FormRowRaw";
import { CustomSelect } from "../../forms/CustomSelect/CustomSelect";
import {
    AccountSubtype,
    AccountType,
} from "../../../common/constants/domains/accounting/accountsv2";
import {
    StandardModal,
    StandardModalBody,
    StandardModalHeader,
} from "../../general/Modal/Modal";
import {
    useCreateAccountMutation,
    useUpdateAccountMutation,
} from "../../../api/account.api";
import { useAccounts } from "../../../hooks/useAccounts";
import {
    getSubtypeBordersInCoA,
    getSubtypesInsideType,
} from "../../../common/constants/domains/accounting/chartOfAccountsV2";
import { Entity, MergedEntityType } from "../../../common/types/entity";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { Button } from "../../general/Button/Button";
import styles from "./styles.module.scss";
interface Props {
    show: boolean;
    onHide: () => void;
    entity: Entity;
    accountNameState: [string, React.Dispatch<React.SetStateAction<string>>];
    accountTypeState: [
        AccountType,
        React.Dispatch<React.SetStateAction<AccountType>>,
    ];
    accountSubtypeState: [
        AccountSubtype,
        React.Dispatch<React.SetStateAction<AccountSubtype>>,
    ];
    accountIdToEdit: string | null;
}

export const CreateAccountModal: React.FC<Props> = ({
    entity,
    show,
    onHide,
    accountNameState: [accountName, setAccountName],
    accountTypeState: [type, setType],
    accountSubtypeState: [subtype, setSubtype],
    accountIdToEdit,
}) => {
    const createAccountMutation = useCreateAccountMutation();
    const { accounts } = useAccounts({
        entityId: entity.id,
        includeDisabled: true,
    });

    const accountCode = useMemo(() => {
        if (!(entity.profile?.type ?? entity.profile?.entityType)) {
            return null;
        }

        if (accountIdToEdit !== null) {
            const account = accounts.find((a) => a.id === accountIdToEdit);
            return account?.code ?? null;
        }

        const accountsWithSameTypeAndSubtype = accounts.filter(
            (a) => a.type === type && a.subtype === subtype,
        );

        const subtypeBorders = getSubtypeBordersInCoA(
            (entity.profile?.type ??
                entity.profile?.entityType) as MergedEntityType,
            type,
            subtype,
        );

        const lastAccountCode =
            accountsWithSameTypeAndSubtype[
                accountsWithSameTypeAndSubtype.length - 1
            ]?.code;

        if (!lastAccountCode) {
            return subtypeBorders.startFrom;
        }

        const nextAccountCode = lastAccountCode + 1;

        if (subtypeBorders.upTo < nextAccountCode) {
            return null;
        }

        return nextAccountCode;
    }, [entity.profile, accountIdToEdit, accounts, type, subtype]);

    const changeAccountType = useCallback(
        (newType: AccountType) => {
            const subtypesInsideType = getSubtypesInsideType(
                (entity.profile?.type ??
                    entity.profile?.entityType) as MergedEntityType,
                newType,
            );
            setType(newType);
            setSubtype(subtypesInsideType.subtypesStartFromBeginning[0].key);
        },
        [entity.profile, setSubtype, setType],
    );

    const changeAccountSubtype = useCallback(
        (newSubtype: AccountSubtype) => {
            setSubtype(newSubtype);
            nameInputRef.current?.focus();
        },
        [setSubtype],
    );

    const availableSubtypes = useMemo(() => {
        if (!(entity.profile?.type ?? entity.profile?.entityType)) {
            return [];
        }

        const subtypesInsideType = getSubtypesInsideType(
            (entity.profile?.type ??
                entity.profile?.entityType) as MergedEntityType,
            type,
        );

        return [
            ...subtypesInsideType.subtypesStartFromBeginning,
            ...subtypesInsideType.subtypesStartFromEnd,
        ];
    }, [entity.profile, type]);

    const errorMessage = useMemo(() => {
        if (accountCode === null) {
            return `You've reached the maximum number of accounts in ${subtype}.`;
        }

        return null;
    }, [accountCode, subtype]);

    const nameInputRef = useRef<HTMLInputElement>(null);

    const [isLoading, setIsLoading] = useState(false);

    const updateAccountMutation = useUpdateAccountMutation();

    const onComplete = useCallback(async () => {
        setIsLoading(true);
        try {
            if (accountIdToEdit !== null) {
                await updateAccountMutation.mutateAsync({
                    params: {
                        entityId: entity.id.toString(),
                        id: accountIdToEdit,
                    },
                    body: {
                        name: accountName,
                    },
                });
            } else {
                await createAccountMutation.mutateAsync({
                    body: {
                        account: {
                            name: accountName,
                            type,
                            subtype,
                        },
                    },
                    params: {
                        entityId: entity.id.toString(),
                    },
                });
            }

            onHide();
        } finally {
            setIsLoading(false);
        }
    }, [
        accountIdToEdit,
        onHide,
        updateAccountMutation,
        entity.id,
        accountName,
        createAccountMutation,
        type,
        subtype,
    ]);

    return (
        <StandardModal show={show} onHide={onHide}>
            <StandardModalHeader>Add Account</StandardModalHeader>
            <StandardModalBody>
                <CustomSelect
                    disabled={Boolean(accountIdToEdit)}
                    className={styles.select}
                    label="Type"
                    value={type ?? undefined}
                    onSelected={(v) => changeAccountType(v)}
                    dropdownKey={`type`}
                    placeholder="Select"
                    size="sm"
                    options={[
                        ...Object.values(AccountType).map((v) => ({
                            value: v,
                            label: v,
                        })),
                    ]}
                />
                <CustomSelect
                    disabled={Boolean(accountIdToEdit)}
                    className={styles.select}
                    label="Subtype"
                    value={subtype ?? undefined}
                    onSelected={(v) => changeAccountSubtype(v)}
                    dropdownKey={`subtype`}
                    placeholder="Select"
                    size="sm"
                    options={availableSubtypes.map((v) => ({
                        value: v.key,
                        label: v.key,
                    }))}
                />

                <label className="form-group__label">Name</label>
                <div className={styles.accountCodeAndNameInputsContainer}>
                    <FormRowRaw
                        fieldType="text"
                        size="sm"
                        value={accountCode === null ? "-" : accountCode}
                        className={styles.accountCodeInput}
                        disabled
                    />
                    <FormRowRaw
                        autoFocus
                        inputRef={nameInputRef}
                        fieldType="text"
                        placeholder="Enter account name"
                        size="sm"
                        value={accountName}
                        className={styles.accountNameInput}
                        onChange={(value) => setAccountName(value)}
                        disabled={errorMessage !== null}
                    />
                </div>
                <div
                    className={classNames({
                        [styles.errorMessage]: true,
                    })}
                    data-testid="error-message"
                >
                    {accountCode === null ? errorMessage : undefined}
                </div>

                <footer
                    className={classNames("d-flex", {
                        [styles.footer]: true,
                        "justify-content-between": true,
                    })}
                >
                    <Button
                        variant="secondary"
                        onClick={() => onHide()}
                        className="mr-2"
                        size="lg"
                        data-testid="confirmation-modal-no-button"
                    >
                        Cancel
                    </Button>

                    <ButtonWithLoader
                        loading={isLoading}
                        variant="default"
                        type="submit"
                        size="lg"
                        data-testid="confirmation-modal-yes-button"
                        onClick={onComplete}
                        disabled={
                            !accountCode ||
                            accountName.length === 0 ||
                            errorMessage !== null
                        }
                    >
                        {accountIdToEdit !== null ? "Rename" : "Create"}
                    </ButtonWithLoader>
                </footer>
            </StandardModalBody>
        </StandardModal>
    );
};
