import React from "react";
import classNames from "classnames";
import { Entity } from "../../../common/types/entity";
import { FinancialAccount } from "../../../common/types/financialAccount";
import { AccountsChartEntityAccount } from "./AccountsChartEntityAccount";

interface Props {
    entity: Entity;
    totalBalance: number;
    accounts: FinancialAccount[];
    proxy?: boolean;
}

export const AccountsChartEntityAccounts: React.FC<Props> = ({
    entity,
    totalBalance,
    accounts,
    proxy,
}) => (
    <div
        className={classNames("accounts-chart__entity__accounts", {
            "accounts-chart__entity__accounts--proxy": proxy,
        })}
    >
        {accounts.map((account) => (
            <AccountsChartEntityAccount
                entity={entity}
                account={account}
                entityBalance={totalBalance}
                key={account.id}
                proxy={proxy}
            />
        ))}
    </div>
);
