import React from "react";
import { FormCheckbox } from "../../../../forms/FormCheckbox/FormCheckbox";
import { BaseFilterProps } from "../../lib";
import styles from "./ReceiptsFilter.module.scss";

export const ReceiptsFilter: React.FC<BaseFilterProps> = ({
    filters,
    onChange,
}) => (
    <section className={styles.direction}>
        <header className={styles.header}>Receipts</header>

        <FormCheckbox
            value="all"
            label="All"
            isChecked={[
                filters.hasReceipt,
                filters.noReceipt,
                filters.requiresReceipt,
            ].every((value) => !value)}
            handleChange={() =>
                onChange({
                    hasReceipt: undefined,
                    noReceipt: undefined,
                    requiresReceipt: undefined,
                })
            }
            small
        />
        <FormCheckbox
            value="some"
            label="Has receipt"
            isChecked={!!filters.hasReceipt}
            handleChange={() => onChange({ hasReceipt: !filters.hasReceipt })}
            small
        />
        <FormCheckbox
            value="some"
            label="No receipt"
            isChecked={!!filters.noReceipt}
            handleChange={() => onChange({ noReceipt: !filters.noReceipt })}
            small
        />
        <FormCheckbox
            value="some"
            label="Requires receipt"
            isChecked={!!filters.requiresReceipt}
            handleChange={() =>
                onChange({ requiresReceipt: !filters.requiresReceipt })
            }
            small
        />
    </section>
);
