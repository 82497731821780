import React, { useCallback } from "react";
import { useRuleUpdateMutation } from "../../../mutations/transactionRules";
import { ModalComponentProps } from "../../../helpers/modal";
import { Counterparty } from "../../../common/types/counterparty";
import { TransactionRule } from "../../../common/types/transactionRule";
import {
    StandardModalBody,
    StandardModalHeader,
} from "../../general/Modal/Modal";
import {
    RuleForm,
    TransactionRuleSubmitData,
} from "./TransactionRuleForm/RuleForm";
import styles from "./TransactionRuleFormModal.module.scss";

interface Props extends ModalComponentProps {
    rule: TransactionRule;
    counterparties: Counterparty[];
}

export const EditRuleModal: React.FC<Props> = ({
    rule,
    close,
    counterparties,
}) => {
    const update = useRuleUpdateMutation(rule, close);

    const onEdit = useCallback(
        async (data: TransactionRuleSubmitData) => {
            await update.mutateAsync({
                ...data,
                rule: {
                    ...data.rule,
                    categoryId: data.rule.categoryId ?? null,
                },
            });
        },
        [update],
    );

    return (
        <>
            <StandardModalHeader>Update Rule</StandardModalHeader>
            <StandardModalBody className={styles.body}>
                <RuleForm
                    rule={rule}
                    onSubmit={onEdit}
                    isSubmitting={update.isPending}
                    submitText="Update rule"
                    counterparties={counterparties}
                />
            </StandardModalBody>
        </>
    );
};
