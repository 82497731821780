import { useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { TransactionListItemDto } from "../../common/types/transaction";
import { Taxonomy } from "../../common/categories";
import { useStandardCategories } from "../../hooks/useStandardCategories";
import { PaginatedTransactionsTableContext } from "./PaginatedTransactionsTableContextProvider";

export function useChangeTransactionToPersonal(
    transaction: TransactionListItemDto,
) {
    const { saveTransaction } = useContext(PaginatedTransactionsTableContext);
    const categories = useStandardCategories();

    return useMutation({
        mutationFn: async () => {
            const previousEntity = transaction.entity;

            if (!previousEntity.isPersonal) {
                return await saveTransaction(transaction, {
                    categoryId: categories[Taxonomy.personal].id,
                });
            }
        },
    });
}
