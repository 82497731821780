import { RequestVerificationDto } from "../common/dto/auth/request-verification.dto";
import { backendClient } from "./backendClient";

export async function requestIdentityVerification(
    data: RequestVerificationDto,
): Promise<void> {
    await backendClient.post(
        "/user-verification/request-identity-verification",
        data,
    );
}
