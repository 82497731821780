import React from "react";
import { ChildrenProps } from "../../../../types";
import styles from "./DowngradeModal.module.scss";
import { DowngradeFeature } from "./lib";

interface Props extends ChildrenProps {
    feature: DowngradeFeature;
}

export const PlanFeature: React.FC<Props> = ({ feature: { icon, label } }) => (
    <article className={styles.planFeature}>
        <aside className={styles.icon}>{icon}</aside>
        {label}
    </article>
);
