import React, { useMemo } from "react";
import { range } from "lodash";
import { Dropdown } from "react-bootstrap";
import "./YearSelect.scss";
import { CustomToggle } from "../../../general/CustomToggle/CustomToggle";
import { ChildrenProps } from "../../../../types";

export interface YearSelectProps extends ChildrenProps {
    value?: number;
    onChange(value: number): void;
    count?: number;
}

export const YearSelect: React.FC<YearSelectProps> = ({
    count = 100,
    value = new Date().getFullYear(),
    onChange,
    children,
}) => {
    const currentYear = new Date().getFullYear();
    const years = useMemo(
        () => range(currentYear, currentYear - count, -1),
        [count, currentYear],
    );

    return (
        <Dropdown className="year-select">
            <Dropdown.Toggle as={CustomToggle}>
                {children ?? value}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {years.map((year) => (
                    <Dropdown.Item
                        active={value === year}
                        key={year}
                        onClick={() => onChange(year)}
                    >
                        {year}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
