import classNames from "classnames";
import NumberFormat from "react-number-format";
import { useEffect, useRef } from "react";
import { CloseIcon } from "../../../icons";
import { Entity } from "../../../common/types/entity";
import { EntitySelect } from "../../entity/EntitySelect";
import { CategorySelectSimple } from "../CategorySelect/CategorySelectSimple";
import { FormRow } from "../../forms/FormRow";
import gridTable from "../../../styles/grid-table.module.scss";
import { Transaction } from "../../../common/types/transaction";
import { TransactionDirectionType } from "../../../common/categories";
import { UpdateTransactionSplitV2Dto } from "../../../common/dto/transactions/update-transaction-split-v2.dto";
import { useCategoriesById } from "../../../hooks/useCategoriesById";
import { Category } from "../../../common/types/category";
import { Button } from "../../general/Button/Button";
import styles from "./TransactionSplitRow.module.scss";

interface TransactionSplitRowProps {
    split: UpdateTransactionSplitV2Dto;
    index: number;
    entities: Entity[];
    transaction: Transaction;
    onRemove: () => void;
    setFieldValue: (field: string, value: any) => void;
    isParent?: boolean;
    onEntityChange: (entityId: number) => void;
}

export const TransactionSplitRow: React.FC<TransactionSplitRowProps> = ({
    split,
    index,
    entities,
    transaction,
    onRemove,
    setFieldValue,
    isParent = false,
    onEntityChange,
}) => {
    const descriptionRef = useRef<HTMLInputElement>(null);
    const selectedEntity = entities.find((e) => e.id === split.entityId);
    const categories = useCategoriesById();

    const category = split.categoryId
        ? categories[split.categoryId]
        : undefined;

    const onCategoryChange = (newCategory: Category) => {
        setFieldValue(`splits.${index}.categoryId`, newCategory.id);
    };

    useEffect(() => {
        if (descriptionRef.current && split.id === undefined) {
            descriptionRef.current.focus();
        }
    }, [split.id]);

    return (
        <div className={classNames(gridTable.row, styles.row)}>
            <div className={styles.index}></div>
            <FormRow
                fieldName={`splits.${index}.splitDescription`}
                className={styles.formGroup}
                showErrorMessage={false}
            >
                {(_, field) => (
                    <input
                        {...field}
                        ref={descriptionRef}
                        className="form-control"
                        placeholder="Add description..."
                    />
                )}
            </FormRow>
            <FormRow
                fieldName={`splits.${index}.amount`}
                className={classNames(styles.formGroup, styles.amountColumn)}
                showErrorMessage={false}
            >
                {({ setValue, setTouched }, { value }) => (
                    <NumberFormat
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={value}
                        onValueChange={(v) => setValue(v.floatValue)}
                        onBlur={() => setTouched(true)}
                        allowNegative={true}
                        className={classNames(
                            "form-control",
                            styles.formControl,
                        )}
                        prefix="$"
                        disabled={isParent}
                    />
                )}
            </FormRow>
            <div className={styles.categorySelect}>
                <CategorySelectSimple
                    dropdownKey={`split-${index}`}
                    onSelected={onCategoryChange}
                    value={category}
                    suggestedCategories={
                        transaction.suggestedCategories ?? undefined
                    }
                    transactionDirection={
                        transaction.amount > 0
                            ? TransactionDirectionType.incoming
                            : TransactionDirectionType.outgoing
                    }
                />
            </div>
            <div className={styles.entitySelect}>
                <EntitySelect
                    entities={entities}
                    selectedEntity={selectedEntity}
                    onChange={(entity) => entity && onEntityChange(entity.id)}
                    dropdownKey={`split-entity-${index}`}
                />
            </div>
            <div className="d-flex justify-content-center align-items-center">
                {!isParent && (
                    <Button
                        variant="tertiary"
                        size="sm"
                        icon
                        type="button"
                        onClick={onRemove}
                    >
                        <CloseIcon />
                    </Button>
                )}
            </div>
        </div>
    );
};
