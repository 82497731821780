import { isString, mapValues, sortBy } from "lodash";
import { Taxonomy } from "./categories";
import { COUNTRIES } from "./countries";
import { Transaction } from "./types/transaction";
import { US_TRAVEL_DESTINATIONS } from "./usTravelDestinations";

export const OTHER_ANSWER = "Other";

export interface TaxQuestion {
    key: TaxQuestionKey;
    question: string;
    short: string;
    hint: string;
    options: Array<string | TaxQuestionAnswer>;
}

export interface TaxQuestionAnswer {
    value: string;
    default?: boolean;
    subquestions?: TaxQuestion[];
}

export interface TaxRule {
    questions?: TaxQuestion[];
    deductionRate?: number | ((transaction: Transaction) => number);
    receiptRequired?: boolean | ((transaction: Transaction) => boolean);
}

export interface TaxQuestionResponse {
    key: TaxQuestionKey;
    answer: string;
}

export enum TaxQuestionKey {
    TRAVEL_COUNTRY = "travel_country",
    TRAVEL_DESTINATION = "travel_destination",
    MEALS_MET_WITH = "meals_met_with",
    MEALS_MET_FOR = "meals_met_for",
}

export type TaxSavingRules = Record<Taxonomy, TaxRule | null>;

const FULLY_DEDUCTIBLE: TaxRule = { deductionRate: 1 };
const NON_DEDUCTIBLE = null;
const INSURANCE_RULE: TaxRule = FULLY_DEDUCTIBLE;
const TAXES_RULE: TaxRule = FULLY_DEDUCTIBLE;
const FEES_RULE: TaxRule = FULLY_DEDUCTIBLE;
const SUPPLIES_RULE: TaxRule = {
    deductionRate: 1,
    receiptRequired: (transaction) => transaction.amount <= -2500,
};
const AUTO_RULE: TaxRule = { deductionRate: 1 };
const PAYROLL_RULE: TaxRule = FULLY_DEDUCTIBLE;
const REAL_ESTATE_RULE: TaxRule = FULLY_DEDUCTIBLE;

const PREFERRED_COUNTRIES = ["US", "CA", "GB"];
const SORTED_COUNTRIES = sortBy(COUNTRIES, [
    (country) =>
        PREFERRED_COUNTRIES.includes(country.code)
            ? PREFERRED_COUNTRIES.indexOf(country.code)
            : 100,
    (country) => country.name,
]);
const TRAVEL_RULE: TaxRule = {
    questions: [
        {
            key: TaxQuestionKey.TRAVEL_COUNTRY,
            question: "Where did you travel?",
            short: "Country",
            hint: "Select country",
            options: SORTED_COUNTRIES.map((country) => ({
                value: country.name,
                default: country.code === "US",
                subquestions:
                    country.code === "US"
                        ? [
                              {
                                  key: TaxQuestionKey.TRAVEL_DESTINATION,
                                  question: "Where in the US did you travel?",
                                  short: "Destination",
                                  hint: "Select state",
                                  options: US_TRAVEL_DESTINATIONS,
                              },
                          ]
                        : undefined,
            })),
        },
    ],
    deductionRate: 1,
};
const MEALS_QUESTIONS: TaxQuestion[] = [
    {
        key: TaxQuestionKey.MEALS_MET_WITH,
        question: "Who did you meet?",
        short: "Relationship",
        hint: "Add business relationship",
        options: ["Client", "Prospect", "Industry Contact", "Vendor"],
    },
    {
        key: TaxQuestionKey.MEALS_MET_FOR,
        question: "What was the purpose?",
        short: "Purpose",
        hint: "Add business purpose",
        options: [
            "To discuss ongoing business",
            "To discuss potential business",
            "To discuss partnership",
            "To discuss ongoing relationship",
            "To get advice",
            "To discuss a contract",
            "Relationship building",
        ],
    },
];
const MEALS_23_RULE: TaxRule = {
    questions: MEALS_QUESTIONS,
    deductionRate: 0.5,
    receiptRequired: (transaction) => transaction.amount <= -75,
};
const MEALS_22_RULE: TaxRule = {
    questions: MEALS_QUESTIONS,
    deductionRate: (transaction) => (transaction.amount < -75 ? 0.5 : 1),
    receiptRequired: (transaction) => transaction.amount <= -75,
};

const COMMON_RULES_FOR_YEARS: Omit<
    Record<Taxonomy, TaxRule | null>,
    "meals"
> = {
    [Taxonomy.software_subscriptions]: FULLY_DEDUCTIBLE,
    [Taxonomy.advertising_marketing]: FULLY_DEDUCTIBLE,
    [Taxonomy.supplies_equipment]: SUPPLIES_RULE,
    [Taxonomy.office_supplies]: SUPPLIES_RULE,
    [Taxonomy.furniture_fixtures]: SUPPLIES_RULE,
    [Taxonomy.props_production]: SUPPLIES_RULE,
    [Taxonomy.legal_professional]: FULLY_DEDUCTIBLE,
    [Taxonomy.travel]: TRAVEL_RULE,
    [Taxonomy.learning_development]: FULLY_DEDUCTIBLE,
    [Taxonomy.rentals_leases]: {
        deductionRate: 0.1,
    },
    [Taxonomy.taxes]: TAXES_RULE,
    [Taxonomy.local_taxes]: TAXES_RULE,
    [Taxonomy.property_taxes]: TAXES_RULE,
    [Taxonomy.federal_taxes]: NON_DEDUCTIBLE,
    [Taxonomy.sales_taxes]: NON_DEDUCTIBLE,
    [Taxonomy.fees]: FEES_RULE,
    [Taxonomy.merchant_bank_fees]: FEES_RULE,
    [Taxonomy.licenses_permits]: FEES_RULE,
    [Taxonomy.penalties_fines]: NON_DEDUCTIBLE,
    [Taxonomy.credit_card_loans]: NON_DEDUCTIBLE,
    [Taxonomy.credit_card_payment]: NON_DEDUCTIBLE,
    [Taxonomy.interest_paid]: FULLY_DEDUCTIBLE,
    [Taxonomy.loan_payment]: NON_DEDUCTIBLE,
    [Taxonomy.transfer]: NON_DEDUCTIBLE,
    [Taxonomy.contributions_distributions]: NON_DEDUCTIBLE,
    [Taxonomy.communications]: {
        deductionRate: 0.5,
    },
    [Taxonomy.auto_vehicle]: AUTO_RULE,
    [Taxonomy.parking_tolls]: AUTO_RULE,
    [Taxonomy.auto_insurance]: AUTO_RULE,
    [Taxonomy.auto_payment]: AUTO_RULE,
    [Taxonomy.utilities]: { deductionRate: 0.1 },
    [Taxonomy.health_dental]: NON_DEDUCTIBLE,
    [Taxonomy.payroll]: PAYROLL_RULE,
    [Taxonomy.contractors]: PAYROLL_RULE,
    [Taxonomy.salaries_wages]: PAYROLL_RULE,
    [Taxonomy.payroll_taxes]: PAYROLL_RULE,
    [Taxonomy.donations]: NON_DEDUCTIBLE,
    [Taxonomy.uncategorized]: NON_DEDUCTIBLE,
    [Taxonomy.real_estate]: REAL_ESTATE_RULE,
    [Taxonomy.management_fees]: REAL_ESTATE_RULE,
    [Taxonomy.improvements]: REAL_ESTATE_RULE,
    [Taxonomy.insurance]: INSURANCE_RULE,
    [Taxonomy.health_insurance]: INSURANCE_RULE,
    [Taxonomy.life_insurance]: INSURANCE_RULE,
    [Taxonomy.property_insurance]: INSURANCE_RULE,
    [Taxonomy.repairs_maintenance]: FULLY_DEDUCTIBLE,
    [Taxonomy.income]: NON_DEDUCTIBLE,
    [Taxonomy.interest_income]: NON_DEDUCTIBLE,
    [Taxonomy.other_income]: NON_DEDUCTIBLE,
    [Taxonomy.retirement]: NON_DEDUCTIBLE,
    [Taxonomy.entertainment]: NON_DEDUCTIBLE,
    [Taxonomy.personal]: NON_DEDUCTIBLE,
    [Taxonomy.security_deposit]: NON_DEDUCTIBLE,
    [Taxonomy.principal_paid]: NON_DEDUCTIBLE,
    [Taxonomy.home_office]: NON_DEDUCTIBLE,
    [Taxonomy.cogs]: NON_DEDUCTIBLE,
    [Taxonomy.direct_labor]: NON_DEDUCTIBLE,
    [Taxonomy.goods_materials]: NON_DEDUCTIBLE,
    [Taxonomy.other_cogs]: NON_DEDUCTIBLE,
    [Taxonomy.business_office]: FULLY_DEDUCTIBLE,
    [Taxonomy.mortgage_payment]: NON_DEDUCTIBLE,
    [Taxonomy.equipment_rentals]: FULLY_DEDUCTIBLE,
    [Taxonomy.investment]: NON_DEDUCTIBLE,
};

export const TAX_SAVING_RULES: Record<number, TaxSavingRules> = {
    2025: {
        ...COMMON_RULES_FOR_YEARS,
        [Taxonomy.meals]: MEALS_23_RULE,
    },
    2024: {
        ...COMMON_RULES_FOR_YEARS,
        [Taxonomy.meals]: MEALS_23_RULE,
    },
    2023: {
        ...COMMON_RULES_FOR_YEARS,
        [Taxonomy.meals]: MEALS_23_RULE,
    },
    2022: {
        ...COMMON_RULES_FOR_YEARS,
        [Taxonomy.meals]: MEALS_22_RULE,
    },
};

const CURRENT_YEAR = new Date().getFullYear();
export const CURRENT_YEAR_TAX_RULES =
    TAX_SAVING_RULES[CURRENT_YEAR] ?? TAX_SAVING_RULES[CURRENT_YEAR - 1];
if (!CURRENT_YEAR_TAX_RULES) {
    throw new Error("Missing tax rules for current or previous year");
}

function flattenQuestions(questions: TaxQuestion[]): TaxQuestion[] {
    const result: TaxQuestion[] = [];

    questions.forEach((question) => {
        result.push(question);

        question.options.forEach((option) => {
            if (!isString(option) && option.subquestions) {
                result.push(...flattenQuestions(option.subquestions));
            }
        });
    });

    return result;
}

export interface TaxQuestionsConfig {
    [year: number]: Record<Taxonomy, TaxQuestion[]>;
}
export const taxQuestions: TaxQuestionsConfig = mapValues(
    TAX_SAVING_RULES,
    (rulesForYear) =>
        mapValues(rulesForYear, (rulesForCategory) => {
            if (rulesForCategory?.questions) {
                return flattenQuestions(rulesForCategory.questions);
            } else {
                return [];
            }
        }),
);

export function getRulesForDate(date: Date): TaxSavingRules {
    return TAX_SAVING_RULES[date.getFullYear()] ?? TAX_SAVING_RULES[2022];
}

export function getAnswerText(option: string | TaxQuestionAnswer) {
    return typeof option === "string" ? option : option.value;
}

export function getApplicableQuestions(
    questions: TaxQuestion[],
    answersByKey: Record<string, string>,
    response: TaxQuestion[] = [],
): TaxQuestion[] {
    for (const question of questions) {
        response.push(question);

        const selectedOption =
            answersByKey[question.key] &&
            question.options.find(
                (option) =>
                    getAnswerText(option) === answersByKey[question.key],
            );

        if (!isString(selectedOption) && selectedOption?.subquestions) {
            getApplicableQuestions(
                selectedOption.subquestions,
                answersByKey,
                response,
            );
        }
    }

    return response;
}

export const capitalizationRuleCategories = [
    Taxonomy.supplies_equipment,
    Taxonomy.furniture_fixtures,
    Taxonomy.props_production,
    Taxonomy.auto_vehicle,
    Taxonomy.repairs_maintenance,
    Taxonomy.management_fees,
] as const;

export const capitalizationRuleThreshold = 2500;

export function calculatePotentialSavings(transaction: Transaction) {
    const category = transaction.category;
    if (!category) {
        return 0;
    }
    const rules = getRulesForDate(transaction.date);
    const rule = rules[category.taxonomy];
    if (!rule) {
        return 0;
    }

    const deductionRate =
        typeof rule.deductionRate === "function"
            ? rule.deductionRate(transaction)
            : rule.deductionRate;

    return Math.abs(
        transaction.amount *
            (deductionRate ?? 0) *
            transaction.entity.federalTaxRate,
    );
}

export function isReceiptRequired(transaction: Transaction) {
    const category = transaction.category;
    if (!category) {
        return false;
    }
    const rules = getRulesForDate(transaction.date)[category.taxonomy];

    if (rules) {
        return typeof rules.receiptRequired === "function"
            ? rules.receiptRequired(transaction)
            : rules.receiptRequired ?? false;
    } else {
        return false;
    }
}
