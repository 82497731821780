import { useMutation } from "@tanstack/react-query";
import { PlaidConnection } from "../common/types/plaidConnection";
import {
    clearPlaidConnectionErrors,
    connectNewPlaidAccounts,
    connectPlaidConnection,
    connectWithPlaid,
    deletePlaidConnection,
} from "../lib/plaidConnection";
import {
    financialConnectionsQueryKey,
    plaidConnectionsQueryKey,
    queryClient,
} from "../queryClient";
import { CreateBankAccountConnectionDto } from "../common/dto/onboarding/create-bank-account-connection.dto";
import { usePlaidFinancialAccounts } from "../hooks/useFinancialAccounts";
import {
    addFinancialConnectionInQueryData,
    clearConnectionErrorInQueryData,
    entitiesAccountsQueryKey,
} from "../queries/entitiesAccounts";
import { IntegrationAccount } from "../common/types/integrationAccount";
import { UserIntegrationsResponse } from "../common/dto/user/user-integrations-response.dto";
import { ConnectionProviderType } from "../common/types/financialConnection";
import { useConnectAccount } from "../components/connectAccount/ConnectAccount.context";
import { useToaster } from "../components/general/ToastMessages/useToaster";

export function useBankConnectionCreationMutation() {
    const { updateConnectAccountState } = useConnectAccount();

    return useMutation({
        mutationFn: (data: CreateBankAccountConnectionDto) =>
            connectPlaidConnection(data),
        onSuccess: ({ connection, connectionSaved, foreign }) => {
            if (connectionSaved) {
                queryClient.setQueryData<PlaidConnection[]>(
                    plaidConnectionsQueryKey,
                    (connections) => [...(connections ?? []), connection],
                );
                addFinancialConnectionInQueryData(queryClient, connection);
            }

            const hasAccountsNotReadyForSync = connection.accounts.some(
                (a) => !a.financialAccount.entityId,
            );
            if (hasAccountsNotReadyForSync || foreign.length > 0) {
                updateConnectAccountState({
                    isConnecting: false,
                    connectionSaved,
                    connection,
                    foreign,
                });
            } else {
                updateConnectAccountState({
                    isConnecting: false,
                });
            }
        },
    });
}

export function useBankConnectionRemovalMutation(connection: PlaidConnection) {
    const { error } = useToaster();
    return useMutation({
        mutationFn: () => deletePlaidConnection(connection.id),
        onSuccess: () => {
            queryClient.setQueryData<PlaidConnection[] | undefined>(
                plaidConnectionsQueryKey,
                (connections) =>
                    connections?.filter((c) => c.id !== connection.id) ?? [],
            );
            queryClient.setQueryData<UserIntegrationsResponse | undefined>(
                [financialConnectionsQueryKey],
                (response) =>
                    response
                        ? {
                              financialConnections:
                                  response.financialConnections.filter(
                                      (fc) =>
                                          fc.connection.id !== connection.id ||
                                          fc.connection.connectionProvider !==
                                              ConnectionProviderType.PLAID,
                                  ),
                          }
                        : undefined,
            );
            queryClient.invalidateQueries({
                queryKey: [entitiesAccountsQueryKey],
            });
        },
        onError: () => {
            error("Failed to remove connection");
        },
    });
}

export function usePlaidConnectionFixingMutation(
    connection: PlaidConnection,
    onSuccess?: () => unknown,
) {
    return useMutation({
        mutationFn: () => connectWithPlaid({ connectionId: connection.id }),
        onSuccess: async ({ connected }) => {
            if (connected) {
                clearConnectionErrorInQueryData(queryClient, connection.id);

                await clearPlaidConnectionErrors(connection.id);
                onSuccess?.();

                queryClient.setQueryData<PlaidConnection[] | undefined>(
                    plaidConnectionsQueryKey,
                    (connections) =>
                        connections?.map((c) =>
                            c.id === connection.id
                                ? {
                                      ...c,
                                      error: null,
                                  }
                                : c,
                        ) ?? [],
                );
            }
        },
    });
}
export function useAddPlaidAccountsMutation(onSuccess?: () => void) {
    const { toast } = useToaster();
    const allAccounts = usePlaidFinancialAccounts();
    const { updateConnectAccountState } = useConnectAccount();

    return useMutation({
        mutationFn: async (connection: PlaidConnection) => {
            const { connected } = await connectWithPlaid({
                connectionId: connection.id,
                addAccounts: true,
            });

            if (connected) {
                updateConnectAccountState({
                    isConnecting: true,
                });

                return await connectNewPlaidAccounts(connection);
            }
        },

        onSuccess: (response, connection) => {
            const connectionAccounts = allAccounts
                .flatMap((a) => a.integrationAccounts ?? [])
                .filter((ia) => ia.connectionId === connection.id);
            const currentAccountsIds = connectionAccounts.map((a) => a.id);

            if (response) {
                const { connection: updatedConnection, foreign } = response;
                const newAccounts: IntegrationAccount[] = [];

                updatedConnection.accounts.forEach((a) => {
                    const account = { ...a, connection };
                    if (!currentAccountsIds.includes(a.id)) {
                        newAccounts.push(account);
                    }
                });

                queryClient.invalidateQueries({
                    queryKey: [entitiesAccountsQueryKey],
                });

                if (
                    newAccounts.some((a) => !a.financialAccount.entityId) ||
                    foreign.length > 0
                ) {
                    updateConnectAccountState({
                        isConnecting: false,
                        connection: updatedConnection,
                        connectionSaved: true,
                        foreign,
                    });
                } else {
                    updateConnectAccountState({
                        isConnecting: false,
                    });
                    toast("Accounts reconnected");
                }

                queryClient.setQueryData<PlaidConnection[] | undefined>(
                    plaidConnectionsQueryKey,
                    (connections) =>
                        connections?.map((c) =>
                            c.id === updatedConnection.id
                                ? updatedConnection
                                : c,
                        ) ?? [],
                );
            } else {
                updateConnectAccountState({
                    isConnecting: false,
                });
            }

            onSuccess?.();
        },
        onError: () => {
            updateConnectAccountState({
                isConnecting: false,
            });
        },
    });
}
