import React, { useCallback } from "react";
import { Dropdown } from "react-bootstrap";
import { GridTableRow } from "../../general/Tables/GridTable/GridTable";
import { Tag } from "../../general/Tag/Tag";
import { EntityIconList } from "../../general/EntityIconList/EntityIconList";
import { ObjectIcon } from "../../general/ObjectIcon/ObjectIcon";
import { WORKSPACE_ROLES_LABELS } from "../../../common/constants";
import { InviteCode } from "../../../common/types/inviteCode";
import { useCanManageWorkspaceMembers } from "../../../hooks/permissions/useCanManageWorkspaceMembers";
import { ManagementDropdown } from "../../general/ManagementDropdown/ManagementDropdown";
import {
    useCancelInviteMutation,
    useResendInviteMutation,
} from "../../../api/workspace-member.api";
import { useWorkspaceContext } from "../../../state/workspaceContext";
import { useToaster } from "../../general/ToastMessages/useToaster";
import styles from "./WorkspaceMembers.module.scss";

interface Props {
    invite: InviteCode;
}

export const InviteRow: React.FC<Props> = ({ invite }) => {
    const { activeWorkspace } = useWorkspaceContext();
    const { toast } = useToaster();

    const canManageWorkspaceMembers = useCanManageWorkspaceMembers();
    const resendInvite = useResendInviteMutation(activeWorkspace!);
    const cancelInvite = useCancelInviteMutation(activeWorkspace!);

    const handleCancel = useCallback(() => {
        cancelInvite.mutate(invite);
        toast("Invite cancelled");
    }, [cancelInvite, invite, toast]);

    const handleResend = useCallback(() => {
        resendInvite.mutate(invite);
        toast("Invite resent");
    }, [invite, resendInvite, toast]);

    return (
        <GridTableRow className={styles.row}>
            <div>
                <span className={styles.userCard}>
                    <ObjectIcon
                        name={invite.email!}
                        defaultColor={invite.iconColor}
                        size="lg"
                    />

                    {invite.email}
                </span>
            </div>
            <div>
                <span className={styles.list}>
                    <EntityIconList
                        showLabel={false}
                        entities={invite.entityAssignments.map(
                            (item) => item.entity,
                        )}
                    />
                </span>
            </div>
            <div>{WORKSPACE_ROLES_LABELS[invite.role!]}</div>
            <div>
                <Tag size="sm" variant="secondary" outline>
                    Invite pending
                </Tag>{" "}
            </div>
            <div>
                {canManageWorkspaceMembers ? (
                    <ManagementDropdown id={`manage-${invite.id}`}>
                        <Dropdown.Item
                            as="button"
                            onClick={() => handleResend()}
                        >
                            Resend invite
                        </Dropdown.Item>

                        <Dropdown.Item
                            as="button"
                            className="text-danger"
                            onClick={() => handleCancel()}
                        >
                            Cancel invite
                        </Dropdown.Item>
                    </ManagementDropdown>
                ) : null}
            </div>
        </GridTableRow>
    );
};
