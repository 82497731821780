import React from "react";
import { StandardModal } from "../../general/Modal/Modal";
import { ChildrenProps } from "../../../types";
import styles from "./TransactionRuleFormModal.module.scss";

interface Props extends ChildrenProps {
    show: boolean;
    onHide: () => void;
}

export const TransactionRuleFormModal: React.FC<Props> = ({
    children,
    ...props
}) => (
    <StandardModal
        {...props}
        size="lg"
        enforceFocus={false}
        className={styles.modal}
    >
        {children}
    </StandardModal>
);
