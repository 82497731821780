import { backendClient, unwrapResponse } from "../lib/backendClient";

export async function get1099Report(workspaceId: string): Promise<ArrayBuffer> {
    return unwrapResponse(
        await backendClient.get("/tax-reports/1099", {
            params: { workspaceId },
            responseType: "arraybuffer",
        }),
    );
}
