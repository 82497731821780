import { ReactNode, useMemo } from "react";
import { Id, toast } from "react-toastify";
import { ErrorToast } from "./ErrorToast";

const TOAST_DURATION_MS = 5_000;

export function useToaster() {
    return useMemo(
        () => ({
            toast: (
                content: ReactNode,
                key?: Id,
                options?: { preventAutoClose: boolean },
            ): Id => {
                if (key && toast.isActive(key)) {
                    toast.update(key, {
                        render: content,
                        autoClose: options?.preventAutoClose
                            ? undefined
                            : TOAST_DURATION_MS,
                    });
                    return key;
                }

                return toast(() => content, {
                    autoClose: TOAST_DURATION_MS,
                    toastId: key,
                });
            },
            error: (content: ReactNode) =>
                toast(() => <ErrorToast>{content}</ErrorToast>, {
                    autoClose: TOAST_DURATION_MS,
                }),
        }),
        [],
    );
}
