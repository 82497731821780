import { useCallback, useRef } from "react";

export type UnsubscribeFn = () => void;
export type NotificationHandler<TValue> = (value: TValue) => void;
export interface UseObserver<TNotificationValue> {
    subscribe(fn: NotificationHandler<TNotificationValue>): UnsubscribeFn;
    notify(value: TNotificationValue): void;
}
export function useObserver<T>(): UseObserver<T> {
    const subscribers = useRef<Array<NotificationHandler<T>>>([]);

    const subscribe = useCallback((fn: NotificationHandler<T>) => {
        subscribers.current.push(fn);

        return () => {
            subscribers.current = subscribers.current.filter(
                (sub) => sub !== fn,
            );
        };
    }, []);

    const notify = useCallback((value: T) => {
        subscribers.current.forEach((fn) => fn(value));
    }, []);

    return {
        subscribe,
        notify,
    };
}
