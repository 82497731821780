import { useMemo } from "react";
import { useEntities } from "../../hooks/useEntities";

export function useEntitiesWithAccountingAvailable(
    requireReportsReady?: boolean,
) {
    const entities = useEntities({
        onlyBusiness: true,
        excludeAllMockEntities: true,
        reportsReady: requireReportsReady,
    });

    return useMemo(
        () =>
            entities.filter(
                (e) =>
                    Boolean(e.profile?.entityType ?? e.profile?.type) &&
                    e.isBusiness,
            ),
        [entities],
    );
}
