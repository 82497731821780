import React from "react";
import { PayrollTotals } from "../../../common/types/payroll";
import { currencyFormatter } from "../../../common/helpers/currency";
import css from "./PayrollDetails.module.scss";

interface Props {
    totals?: PayrollTotals | null;
    total: keyof PayrollTotals;
}
const payrollTotalsLabelsMap: Record<keyof PayrollTotals, string> = {
    companyDebit: "Company Debit",
    netPayDebit: "Net Pay Debit",
    taxDebit: "Tax Debit",
    reimbursementDebit: "Reimbursement Debit",
    childSupportDebit: "Child Support Debit",
    reimbursements: "Reimbursements",
    grossPay: "Gross Pay",
    netPay: "Net Pay",
    employeeBonuses: "Employee Bonuses",
    employeeCommissions: "Employee Commissions",
    employeeCashTips: "Employee Cash Tips",
    employeePaycheckTips: "Employee Paycheck Tips",
    additionalEarnings: "Additional Earnings",
    ownersDraw: "Owners Draw",
    employerTaxes: "Employer Taxes",
    benefits: "Benefits",
    employeeTaxes: "Employee Taxes",
    employeeBenefitsDeductions: "Employee Benefits Deductions",
    checkAmount: "Check Amount",
    deferredPayrollTaxes: "Deferred Payroll Taxes",
};
export const PayrollDetailsTotalItem: React.FC<Props> = ({ totals, total }) => {
    if (!totals || !total) {
        return null;
    }

    return (
        <>
            <td>{payrollTotalsLabelsMap[total]}</td>
            <td className={css.total}>
                {currencyFormatter.format(totals[total])}
            </td>
        </>
    );
};
