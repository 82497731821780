import React from "react";
import { AccountingReportFilters } from "../types";

export const AccountStatusFilterLabel: React.FC<{
    filters: AccountingReportFilters;
}> = ({ filters }) => (
    <>
        {filters.accountStatus === "enabled"
            ? "Active Accounts"
            : "Disabled Accounts"}
    </>
);
