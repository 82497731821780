import classNames from "classnames";
import { capitalize } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { Badge, Nav, Tab } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ActionCategory } from "../../common/types/actions";
import { useActionsSummary } from "../../hooks/useActionsSummary";
import mainInterfaceStyles from "../mainInterface/MainInterface.module.scss";
import styles from "./ActionsTabs.module.scss";

interface Params {
    category: ActionCategory;
}

export const ActionsTabs: React.FC = () => {
    const { category = ActionCategory.PERSONALIZE } =
        useParams() as Readonly<Params>;
    const navigate = useNavigate();
    const location = useLocation();
    const tabsRef = useRef<Map<string, HTMLElement>>(new Map());
    const [indicatorStyle, setIndicatorStyle] = useState({});

    const summary = useActionsSummary();
    const categories = Object.values(ActionCategory);

    const showPersonalizeTab = useMemo(() => {
        if (!summary.data) {
            return false;
        }

        const counts = summary.data.categories[ActionCategory.PERSONALIZE];
        return counts.pending > 0 || counts.skipped > 0;
    }, [summary.data]);

    useEffect(() => {
        if (!summary.data) {
            return;
        }
        if (location.pathname !== "/actions") {
            return;
        }

        const firstActionableCategory = categories.find(
            (cat) => summary.data.categories[cat].pending > 0,
        );

        if (firstActionableCategory) {
            navigate(`/actions/${firstActionableCategory}`, {
                replace: true,
            });
        } else {
            navigate(`/actions/${ActionCategory.SAVINGS}`, { replace: true });
        }
    }, [summary.data, categories, location.pathname, navigate]);

    useEffect(() => {
        const activeElement = tabsRef.current.get(category);
        if (activeElement) {
            setIndicatorStyle({
                left: `${activeElement.offsetLeft}px`,
                width: `${activeElement.offsetWidth}px`,
            });
        }
    }, [category, showPersonalizeTab]);

    const getBadgeValue = (cat: ActionCategory) => {
        const count = summary.data?.categories[cat].pending;
        if (count === 0 || !count) {
            return "-";
        }
        return count;
    };

    const filteredCategories = categories.filter(
        (cat) => cat !== ActionCategory.PERSONALIZE || showPersonalizeTab,
    );

    return (
        <div className={classNames(styles.container)}>
            <Tab.Container activeKey={category}>
                <Nav
                    variant="tabs"
                    className={classNames(
                        mainInterfaceStyles.container,
                        styles.tabs,
                    )}
                >
                    {filteredCategories.map((cat) => (
                        <Nav.Item key={cat}>
                            <Nav.Link
                                as={Link}
                                to={`/actions/${cat}`}
                                active={category === cat}
                                className={classNames(styles.tab, {
                                    [styles.noActions]:
                                        getBadgeValue(cat) === "-",
                                })}
                                ref={(el: HTMLAnchorElement) => {
                                    if (el) {
                                        tabsRef.current.set(cat, el);
                                    }
                                }}
                            >
                                <Badge className={classNames(styles.badge)}>
                                    {getBadgeValue(cat)}
                                </Badge>
                                {capitalize(cat)}
                            </Nav.Link>
                        </Nav.Item>
                    ))}
                    <div
                        className={styles.tabIndicator}
                        style={indicatorStyle}
                    />
                </Nav>
            </Tab.Container>
        </div>
    );
};
