import { CreateOauthConnectionDto } from "../common/dto/oauth/create-oauth-connection.dto";
import { FinancialConnectionDto } from "../common/dto/financialAccount/financial-connection.dto";
import { FinancialConnectionWithAccounts } from "../common/types/financialConnection";
import { CreateStripeOauthConnectionDto } from "../common/dto/stripe/create-stripe-oauth-connection.dto";
import { backendClient, unwrapResponse } from "./backendClient";

export async function createStripeConnection(
    data: CreateStripeOauthConnectionDto,
): Promise<FinancialConnectionWithAccounts> {
    return FinancialConnectionDto.deserialize(
        unwrapResponse(await backendClient.post("/stripe-connection", data)),
    );
}

export async function fixStripeConnection(
    id: number,
    data: CreateOauthConnectionDto,
): Promise<FinancialConnectionWithAccounts> {
    return FinancialConnectionDto.deserialize(
        unwrapResponse(
            await backendClient.post(`/stripe-connection/fix/${id}`, data),
        ),
    );
}

export async function getStripeConnections(): Promise<
    FinancialConnectionWithAccounts[]
> {
    const response = unwrapResponse(
        await backendClient.get("/stripe-connection"),
    );
    return response.map((dto: FinancialConnectionDto) =>
        FinancialConnectionDto.deserialize(dto),
    );
}
