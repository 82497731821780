import React from "react";
import classNames from "classnames";
import { Footer } from "../components/Footer";
import animation from "../layout/OnboardingLayout.module.scss";
import { Entity } from "../../../common/types/entity";
import { industryLabels } from "../../entity/constants";
import { TagIcon } from "../../../icons";
import { BetaDiscountCode } from "../components/DiscountCode/BetaDiscountCode";
import { COUNTRIES_BY_CODE } from "../../../common/countries";
import { Button } from "../../general/Button/Button";
import styles from "./WaitlistIntro.module.scss";
import commonStyles from "./common.module.scss";

interface Props {
    entity: Entity;
    onBack: () => void;
    onNext: () => void;
}

export const WaitlistIntro: React.FC<Props> = ({ entity, onBack, onNext }) => {
    let title: React.ReactNode;
    let discountTitle: React.ReactNode;
    let betaProgram: React.ReactNode;
    let code: string;

    if (!entity.profile?.industry || !entity.profile?.country) {
        return null;
    } else if (entity.profile.country !== "US") {
        const countryName = COUNTRIES_BY_CODE[entity.profile.country];

        betaProgram = <>Want to join the {countryName} Beta Program?</>;
        title = `Kick isn't available yet in ${countryName}`;
        discountTitle = (
            <>
                Here's a <em>50% discount code</em> for when Kick is available
                in {countryName}
            </>
        );
        code = "global";
    } else {
        const industryName = industryLabels[entity.profile.industry];

        betaProgram = <>Want to join the {industryName} Beta Program?</>;
        code = industryName;
        title = `Kick isn't available yet for ${industryName}`;
        discountTitle = (
            <>
                Here's a <em>50% discount code</em> for when {industryName} is
                ready
            </>
        );
    }

    return (
        <div
            className={classNames(
                commonStyles.content420,
                styles.content,
                "h-100",
            )}
        >
            <header
                className={classNames(
                    animation.fadeIn1,
                    styles.header,
                    "mt-auto",
                )}
            >
                <span className={commonStyles.icon}>
                    <TagIcon />
                </span>

                <h3 className={styles.heading}>{title}</h3>
                <h1 className={commonStyles.title}>{discountTitle}</h1>
            </header>

            <main className={classNames(styles.main, animation.fadeIn2)}>
                <BetaDiscountCode className="mb-4" name={code} value={50} />

                <p className={styles.hint}>
                    Don't worry, we've also emailed you the code
                </p>
            </main>

            <footer className={styles.footerContainer}>
                <p>{betaProgram}</p>

                <Footer onBack={onBack} className={styles.footer}>
                    <Button variant="secondary" onClick={onNext}>
                        Submit interest
                    </Button>
                </Footer>
            </footer>
        </div>
    );
};
