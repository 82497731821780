import React, { PropsWithChildren, useCallback } from "react";
import classNames from "classnames";
import { useInteraction } from "../../../hooks/useInteraction";
import styles from "./SortableHeader.module.scss";

export interface SortableHeaderProps<T extends string> {
    sort?: T;
    onChange?: (sort: T) => void;
    asc: T;
    desc: T;
    defaultOrder: "asc" | "desc";
}

export const SortableHeader = <T extends string = string>({
    sort,
    asc,
    desc,
    onChange,
    defaultOrder,
    children,
}: PropsWithChildren<SortableHeaderProps<T>>) => {
    const handleClick = useCallback(() => {
        if (defaultOrder === "asc") {
            onChange?.(sort === asc ? desc : asc);
        }
        if (defaultOrder === "desc") {
            onChange?.(sort === desc ? asc : desc);
        }
    }, [asc, desc, onChange, sort, defaultOrder]);

    const onClick = useInteraction(handleClick);

    if (!onChange) {
        return <>{children}</>;
    }

    return (
        <div
            className={classNames(styles.sortableHeader, {
                [styles.asc]: sort === asc,
                [styles.desc]: sort === desc,
            })}
            {...onClick}
            data-testid={`${sort === asc ? "sortable-header--asc" : ""}${sort === desc ? "sortable-header--desc" : ""}`}
        >
            {children}
        </div>
    );
};
