import React from "react";
import classNames from "classnames";

interface Props {
    children: React.ReactNode;
    activeIndicator?: boolean;
    disabled?: boolean;
}

export const NavigationSubsectionItem: React.FC<Props> = ({
    children,
    disabled,
    activeIndicator,
}) => (
    <span
        className={classNames("navigation-subsection-item", {
            "navigation-subsection-item--disabled": disabled,
            "navigation-subsection-item--active": activeIndicator,
        })}
    >
        {children}
    </span>
);
