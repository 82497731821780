import React, { useCallback, useState } from "react";
import { Formik, FormikConfig } from "formik";
import { NavLink, useNavigate } from "react-router-dom";
import { Extends } from "../../../common/helpers/typescript";
import { OnboardingStepKey } from "../steps";
import { useManageSteps } from "../useManageSteps";
import { Entity, EntityProfile } from "../../../common/types/entity";
import {
    useEditEntityMutation,
    useEntityCreationMutation,
} from "../../../mutations/entity";
import { EntityProfileFormSchema } from "../types";
import { submitHelper } from "../../../helpers/form";
import { OnboardingContainer } from "../components/OnboardingContainer";
import { OnboardingLayout } from "../layout/OnboardingLayout";
import { ProductPreview } from "../components/ProductPreview/ProductPreview";
import { ConnectAccounts } from "../steps/ConnectAccounts/ConnectAccounts";
import { CreateEntityProfile } from "../steps/CreateEntityProfile";
import { ConnectAccountsSidebar } from "../steps/ConnectAccounts/ConnectAccountsSidebar";
import { CloseIcon } from "../../../icons";
import containerStyles from "../components/OnboardingContainer.module.scss";
import { useEntityProfileFormConfig } from "../useEntityProfileFormConfig";
import { useWorkspaceContext } from "../../../state/workspaceContext";

type NewEntityFlowSteps = Extends<
    OnboardingStepKey,
    "profile" | "connectAccounts"
>;

export const NewEntity: React.FC = () => {
    const [profileFormBusy, setProfileFormBusy] = useState(false);
    const navigate = useNavigate();
    const { go, currentStep, nextStep, isExiting } =
        useManageSteps<NewEntityFlowSteps>(true, () => "profile");

    const [entityToCreate, setEntityToCreate] = useState<Partial<Entity>>({});
    const { activeWorkspace } = useWorkspaceContext();

    const createEntity = useEntityCreationMutation(activeWorkspace!);
    const editEntity = useEditEntityMutation(entityToCreate as Entity);

    const onFinished = useCallback(() => {
        navigate("/accounts");
    }, [navigate]);

    const handleNewEntityProfile = useCallback(
        async (name: string, profile: EntityProfile) => {
            if (entityToCreate.id) {
                setEntityToCreate(
                    await editEntity.mutateAsync({
                        name,
                        profile: {
                            ...entityToCreate.profile,
                            ...profile,
                        },
                    }),
                );
            } else {
                setEntityToCreate(
                    await createEntity.mutateAsync({
                        name,
                        profile,
                    }),
                );
            }
            go("connectAccounts");
        },
        [
            createEntity,
            editEntity,
            entityToCreate.id,
            entityToCreate.profile,
            go,
        ],
    );

    const { initialValues, validationSchema } = useEntityProfileFormConfig(
        entityToCreate as Entity,
    );

    const form: FormikConfig<EntityProfileFormSchema> = {
        initialValues,
        validationSchema,
        onSubmit: submitHelper({
            loading: profileFormBusy,
            setLoading: setProfileFormBusy,
            handler: async ({
                type,
                name,
                country,
                industry,
                annualRevenue,
            }) => {
                await handleNewEntityProfile(name, {
                    type,
                    industry,
                    annualRevenue,
                    country,
                });
            },
        }),
    };

    let main: React.ReactNode = null;
    let sidebar: React.ReactNode = null;

    if (currentStep === "profile") {
        main = <CreateEntityProfile busy={profileFormBusy} />;
        sidebar = <ProductPreview />;
    } else {
        main = (
            <ConnectAccounts
                onBack={() => go("profile")}
                onFinished={onFinished}
                defaultEntity={entityToCreate as Entity}
                allowNoAccounts
            />
        );

        sidebar = <ConnectAccountsSidebar />;
    }

    return (
        <OnboardingContainer>
            <Formik {...form}>
                <OnboardingLayout
                    currentStep={currentStep}
                    main={main}
                    nextStep={nextStep}
                    isExiting={isExiting}
                    sidebar={sidebar}
                />
            </Formik>

            <NavLink
                to="/accounts"
                className={`${containerStyles.close} btn btn-secondary btn-icon`}
            >
                <CloseIcon />
            </NavLink>
        </OnboardingContainer>
    );
};
