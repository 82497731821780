import { UseMutationResult } from "@tanstack/react-query";
import { Form } from "react-bootstrap";
import { FinancialAccount } from "../../../../common/types/financialAccount";
import { AccountCard } from "../../../general/AccountCard/AccountCard";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import { Button } from "../../../general/Button/Button";

import {
    StandardModalBody,
    StandardModalHeader,
} from "../../../general/Modal/Modal";
import styles from "./RemoveFinancialAccount.module.scss";

interface RemoveAccountAttentionContentProps {
    account: FinancialAccount;
    closeModal: () => void;
    selectedOption: string;
    handleOptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disableAccount: UseMutationResult<FinancialAccount, unknown, void, unknown>;
    onRemoveAccount: () => void;
    removeAccountLoadingState: boolean;
}

export function RemoveAccountAttentionContent({
    account,
    closeModal,
    selectedOption,
    handleOptionChange,
    disableAccount,
    onRemoveAccount,
    removeAccountLoadingState,
}: RemoveAccountAttentionContentProps) {
    return (
        <>
            <StandardModalHeader>
                Would you like to keep the historical data for this account?
            </StandardModalHeader>
            <StandardModalBody>
                <AccountCard account={account} />
                <p className={styles.modalText}>
                    The historical data may contain important information about
                    your transactions, documents, and it may be needed for
                    accounting purposes.
                </p>
                <Form className={styles.radioGroup}>
                    <Form.Check
                        type="radio"
                        label="Keep historical data and just disable the account instead"
                        id="keep-historical-data"
                        name="account-action"
                        checked={selectedOption === "keep-historical-data"}
                        onChange={handleOptionChange}
                        className={styles.radio}
                    />
                    <Form.Check
                        type="radio"
                        label="Remove all historical data and account"
                        id="remove-account"
                        name="account-action"
                        checked={selectedOption === "remove-account"}
                        onChange={handleOptionChange}
                        className={styles.radio}
                    />
                </Form>
                <footer className="d-flex justify-content-between">
                    <Button
                        variant="secondary"
                        className="mr-2"
                        size="lg"
                        data-testid="confirmation-modal-no-button"
                        onClick={closeModal}
                    >
                        Back
                    </Button>
                    <ButtonWithLoader
                        onClick={
                            selectedOption === "keep-historical-data"
                                ? disableAccount.mutate
                                : onRemoveAccount
                        }
                        loading={
                            selectedOption === "keep-historical-data"
                                ? disableAccount.isPending
                                : removeAccountLoadingState
                        }
                        size="lg"
                        data-testid="confirmation-modal-yes-button"
                    >
                        {selectedOption === "keep-historical-data"
                            ? "Disable account"
                            : "Remove account"}
                    </ButtonWithLoader>
                </footer>
            </StandardModalBody>
        </>
    );
}
