import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { useUser } from "../../hooks/useUser";
import { useFinancialAccountEntitySelection } from "../../hooks/useFinancialAccountEntitySelection";
import { Button } from "../general/Button/Button";
import { IntegrationConnection } from "../../common/types/integrationConnection";
import {
    StandardModal,
    StandardModalBody,
    StandardModalHeader,
} from "../general/Modal/Modal";
import { FinancialAccountsConnectionProvider } from "./FinancialAccountsConnectionContext";
import { isValidIntegrationConnection } from "./helpers";
import { FinancialAccountsConnectionWidgetV2 } from "./FinancialAccountConnectionWidgetV2";

export interface FinancialAccountsConnectedModalProps {
    show: boolean;
    integrationConnection: IntegrationConnection;
    onHide(): void;
}

export const FinancialAccountsConnectedModalV2: React.FC<
    FinancialAccountsConnectedModalProps
> = ({ show, integrationConnection, onHide }) => {
    const { showBusinessEntitySelection } =
        useFinancialAccountEntitySelection();
    const [submitted, setSubmitted] = useState<boolean>(false);
    const user = useUser();
    const handleSubmit = useCallback(() => {
        if (
            isValidIntegrationConnection(integrationConnection) ||
            submitted ||
            !user.onboardingComplete
        ) {
            setSubmitted(false);
            onHide();
        } else {
            setSubmitted(true);
        }
    }, [integrationConnection, onHide, submitted, user.onboardingComplete]);

    return (
        <FinancialAccountsConnectionProvider
            showAccountTypeWarning={submitted && user.onboardingComplete}
        >
            <StandardModal
                show={show}
                onHide={onHide}
                size={
                    integrationConnection && showBusinessEntitySelection
                        ? "xl"
                        : "lg"
                }
                backdrop={integrationConnection ? true : "static"}
                keyboard={!!integrationConnection}
                className={classNames("accounts-connected-modal", {
                    "accounts-connected-modal--with-entities":
                        showBusinessEntitySelection,
                })}
            >
                <>
                    <StandardModalHeader closeButton>
                        Select entity
                    </StandardModalHeader>
                    <StandardModalBody>
                        <p>For which entity are you adding these accounts?</p>

                        <FinancialAccountsConnectionWidgetV2
                            accounts={
                                integrationConnection.integrationAccounts ?? []
                            }
                            integrationConnection={integrationConnection}
                        />

                        <footer className="d-flex align-items-center justify-content-end">
                            <Button onClick={handleSubmit} size="lg">
                                Continue
                            </Button>
                        </footer>
                    </StandardModalBody>
                </>
            </StandardModal>
        </FinancialAccountsConnectionProvider>
    );
};
