import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteJournalEntry } from "../lib/accounting";
import { useToaster } from "../components/general/ToastMessages/useToaster";

interface UseDeleteJournalEntryParams {
    journalEntryId: string | undefined;
    entityId: number;
    onSuccess: () => void;
}

export function useDeleteJournalEntry({
    journalEntryId,
    entityId,
    onSuccess,
}: UseDeleteJournalEntryParams) {
    const queryClient = useQueryClient();
    const { toast } = useToaster();

    return useMutation({
        mutationFn: async () => {
            if (!journalEntryId) {
                return;
            }

            await deleteJournalEntry(entityId, journalEntryId);

            queryClient.invalidateQueries({ queryKey: ["generalLedger"] });

            toast(`Journal entry successfully deleted`);

            onSuccess();
        },
    });
}
