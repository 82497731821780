import { Taxonomy } from "../categories";
import { CURRENT_YEAR_TAX_RULES } from "../taxSavingRules";
import { TransactionListItemDto } from "../types/transaction";
import { getAccountNameWithLast4 } from "./financialAccount";

export const allCategories = Object.values(Taxonomy);

export const taxonomiesExcludedFromAmountSavedCalculation: Taxonomy[] =
    Object.values(Taxonomy).filter(
        (category) => !CURRENT_YEAR_TAX_RULES[category],
    );

export function getTransactionAccountName(
    transaction: TransactionListItemDto,
): string {
    return transaction.financialAccount.name ?? "";
}

export function getTransactionAccountNameWithLast4(
    transaction: TransactionListItemDto,
    accountNumberMaskLength = 4,
): string {
    const name = getTransactionAccountName(transaction);
    const account = transaction.financialAccount;

    return account
        ? getAccountNameWithLast4(account, accountNumberMaskLength)
        : name;
}

export enum TransactionClassFilterType {
    ANY_SELECTED = "any_selected",
    ALL_SELECTED = "all_selected",
    EXCLUDE_SELECTED = "exclude_selected",
    NO_CLASSES = "no_classes",
}
