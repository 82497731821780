import React, { useMemo } from "react";
import { isString } from "lodash";
import { Counterparty } from "../../../common/types/counterparty";
import {
    RuleConditionType,
    TransactionRule,
    TransactionRuleCondition,
} from "../../../common/types/transactionRule";
import { getCounterpartyRuleConditionLabel } from "../../../common/helpers/rules";
import { useCategoriesById } from "../../../hooks/useCategoriesById";

interface Props {
    condition: TransactionRuleCondition;
    rule: TransactionRule;
    counterparties: Counterparty[];
}

export const RuleCounterpartyCondition: React.FC<Props> = ({
    condition,
    rule,
    counterparties,
}) => {
    const categoriesById = useCategoriesById();
    const usedTaxonomy = useMemo(() => {
        const categoryCondition = rule.conditions.find(
            (c) =>
                c.conditionType === RuleConditionType.CATEGORY ||
                c.conditionType === RuleConditionType.SUGGESTED_CATEGORY,
        );

        if (!categoryCondition) {
            return;
        }

        if (isString(categoryCondition.value)) {
            return categoriesById[categoryCondition.value]?.taxonomy;
        }
    }, [rule.conditions, categoriesById]);

    const counterparty = counterparties.find((c) => c.id === condition.value);

    if (!counterparty) {
        return null;
    }

    const conditionLabel = getCounterpartyRuleConditionLabel(usedTaxonomy);

    return (
        <>
            {conditionLabel} is <strong>{counterparty.name}</strong>
        </>
    );
};
