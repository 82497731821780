import React, { ForwardedRef, forwardRef } from "react";
import classNames from "classnames";
import { accountingNumberFormatter } from "../../../common/helpers/number";
import editableTableStyles from "./EditableTable.module.scss";

const titleClass = "title small " + editableTableStyles.editableTableTitle;

interface Props {
    totals: TableFooterTotals;
    className?: string;
}

export interface TableFooterTotals {
    debitAmount: number;
    creditAmount: number;
}

export const EditableTableFooter = forwardRef(
    ({ totals, className }: Props, ref: ForwardedRef<any>) => (
        <div
            className={classNames(
                editableTableStyles.editableTableTotal,
                className,
            )}
            ref={ref}
        >
            <div
                className={classNames(
                    editableTableStyles.editableTableHeaderCell,
                    editableTableStyles.editableTableHeaderNoLeftBorder,
                )}
            />
            <div
                className={classNames(
                    editableTableStyles.editableTableHeaderCell,
                    editableTableStyles.editableTableHeaderNoLeftBorder,
                    editableTableStyles.editableTableCellAlignRight,
                )}
            >
                <p
                    className={classNames(
                        titleClass,
                        editableTableStyles.totalTitle,
                    )}
                >
                    Total
                </p>
            </div>
            <div
                className={classNames(
                    editableTableStyles.editableTableHeaderCell,
                    editableTableStyles.editableTableCellAlignRight,
                )}
            >
                <p
                    className={classNames(
                        titleClass,
                        editableTableStyles.totalTitle,
                    )}
                >
                    {totals.debitAmount === 0
                        ? 0
                        : accountingNumberFormatter(totals.debitAmount)}
                </p>
            </div>
            <div
                className={classNames(
                    editableTableStyles.editableTableHeaderCell,
                    editableTableStyles.editableTableCellAlignRight,
                )}
            >
                <p
                    className={classNames(
                        titleClass,
                        editableTableStyles.totalTitle,
                    )}
                >
                    {totals.creditAmount === 0
                        ? 0
                        : accountingNumberFormatter(totals.creditAmount)}
                </p>
            </div>
            <div className={editableTableStyles.editableTableHeaderCell}></div>
            <div className={editableTableStyles.editableTableHeaderCell}></div>
        </div>
    ),
);
