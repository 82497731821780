import React, { useCallback, useMemo } from "react";
import { Collapsible } from "../general/Collapsible/Collapsible";
import stripeLogo from "../../static/images/icons/stripe.svg";
import { ClockIcon } from "../../icons";
import { timeAgo } from "../../helpers/date";
import { DisplayIntegrationAccount } from "../integrationAccount/DisplayIntegrationAccount";
import { FinancialConnectionWithAccounts } from "../../common/types/financialConnection";
import { useToaster } from "../general/ToastMessages/useToaster";
import css from "./StripeConnectionsStatus.module.scss";
import { CreateStripeConnection } from "./CreateStripeConnection";

interface Props {
    stripeConnections: FinancialConnectionWithAccounts[];
}

export const StripeConnectionsStatus: React.FC<Props> = ({
    stripeConnections,
}) => {
    const { toast } = useToaster();

    const handleConnected = useCallback(() => {
        toast(`Integration successfully connected`);
    }, [toast]);

    const mostRecentBalanceSyncedAt = useMemo(
        () =>
            stripeConnections?.length &&
            stripeConnections?.length > 0 &&
            stripeConnections?.reduce(
                (prev, current) =>
                    prev &&
                    current.accounts[0].balanceSyncedAt &&
                    prev.getTime() >
                        current.accounts[0].balanceSyncedAt.getTime()
                        ? prev
                        : current.accounts[0].balanceSyncedAt,
                stripeConnections[0].accounts[0].balanceSyncedAt,
            ),
        [stripeConnections],
    );

    const header = (
        <div className={css.header}>
            <img src={stripeLogo} alt="Stripe" />
            <main>
                <h5>Stripe</h5>
                {mostRecentBalanceSyncedAt && (
                    <small>
                        <ClockIcon
                            height={12}
                            width={12}
                            className="icon-color-current"
                        />
                        {timeAgo(mostRecentBalanceSyncedAt)}
                    </small>
                )}
            </main>
        </div>
    );

    return (
        <Collapsible
            className={css.stripeConnections}
            header={header}
            footer={
                <div className={css.footerSection}>
                    <CreateStripeConnection
                        btnVariant="secondary"
                        onCreated={handleConnected}
                    >
                        Connect another account
                    </CreateStripeConnection>
                </div>
            }
        >
            <>
                {stripeConnections?.map((connection) => (
                    <DisplayIntegrationAccount
                        key={connection.id}
                        className={css.displayAccount}
                        connection={connection}
                        integrationAccount={connection.accounts[0]}
                    />
                ))}
            </>
        </Collapsible>
    );
};
