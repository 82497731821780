import { capitalize, noop } from "lodash";
import { InvoiceStatus } from "../../../../common/types/invoice";
import { Multiselect } from "../../../forms/Multiselect/Multiselect";
import { MultiselectOption } from "../../../forms/Multiselect/types";
import { Tag } from "../../../general/Tag/Tag";
import { getInvoiceStatusVariant } from "../../../../helpers/invoice";
import styles from "./InvoiceStatusFilter.module.scss";
import { defaultStatuses } from "./ActiveFilters/ActivityFilter.hooks";

interface Filter {
    statuses?: InvoiceStatus[];
}

interface Props {
    filters: Filter;
    onChange(value: Filter): void;
}

export const InvoiceStatusFilter: React.FC<Props> = ({ filters, onChange }) => {
    const options: MultiselectOption[] = Object.values(InvoiceStatus).map(
        (status) => ({
            value: status,
            label: (
                <Tag
                    size="sm"
                    outline
                    variant={getInvoiceStatusVariant(status as InvoiceStatus)}
                >
                    {capitalize(status)}
                </Tag>
            ),
            searchableLabel: status,
        }),
    );

    const statuses =
        filters.statuses && filters.statuses.length > 0
            ? filters.statuses
            : defaultStatuses;

    return (
        <div className={styles.multiselect}>
            <Multiselect
                inDropdown={false}
                options={options}
                value={statuses}
                onChange={(val) => {
                    onChange({ statuses: val as InvoiceStatus[] });
                }}
                buttonText="Status"
                name="statuses"
                onBlur={noop}
            />
        </div>
    );
};
