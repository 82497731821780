import { useMemo } from "react";
import { Category } from "../../../../../common/types/category";

/**
 * Returns list of taxonomies that need to be expanded so that
 * all selectedCategories can be visible
 */
export function useExpandParentCategory(
    categories: Category[] = [],
    selectedCategoryIds: string[] = [],
) {
    return useMemo(
        () =>
            categories
                .filter(({ subcategories }) =>
                    subcategories?.some(({ id }) =>
                        selectedCategoryIds.includes(id),
                    ),
                )
                .map(({ id }) => id),
        [selectedCategoryIds, categories],
    );
}
