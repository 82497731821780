import { differenceInMonths, subMonths } from "date-fns";
import { PlaidConnection } from "../../../common/types/plaidConnection";
import { institutionsWithManuallyProvidedData } from "../../../common/constants";
import { previousYear } from "../../settings/Billing/constants";

const DATA_FETCH_RANGE_MONTHS = 6;
const EARLIEST_BOOKKEEPING_START = new Date(previousYear, 0, 1);

export function useManualDataUpload(connection: PlaidConnection) {
    const bookkeepingStartDatesFromEntities = (connection.accounts ?? [])
        .map((acc) => acc.financialAccount.entity?.bookkeepingStart)
        .filter((value) => !!value);

    const earliestBookkeepingStart = bookkeepingStartDatesFromEntities.length
        ? new Date(
              Math.min(
                  ...bookkeepingStartDatesFromEntities.map((date) =>
                      date.getTime(),
                  ),
              ),
          )
        : EARLIEST_BOOKKEEPING_START;

    const isMissingData =
        connection.institutionId &&
        !connection.historicalDataProvidedManually &&
        institutionsWithManuallyProvidedData.includes(
            connection.institutionId,
        ) &&
        differenceInMonths(connection.createdAt, earliestBookkeepingStart) >
            DATA_FETCH_RANGE_MONTHS;

    return {
        isMissingData: Boolean(isMissingData),
        missingDataStart: earliestBookkeepingStart,
        missingDataEnd: subMonths(
            connection.createdAt,
            DATA_FETCH_RANGE_MONTHS,
        ),
    };
}
