import React, { useCallback } from "react";
import { useCreateCounterpartyMutation } from "../../api/counterparty.api";
import { CounterpartyCreationResponse } from "../../common/contracts/counterparty.contract";
import { useToaster } from "../general/ToastMessages/useToaster";
import { CounterpartyForm, FormSchema } from "./CounterpartyForm";
import { CounterpartyVerifiedToast } from "./CounterpartyVerifiedToast/CounterpartyVerifiedToast";

interface Props {
    onCreated: (response: CounterpartyCreationResponse) => void;
    onCancelled: () => void;
    fullWidth?: boolean;
    forTransactionEditing?: boolean;
}

export const CreateCounterparty: React.FC<Props> = ({
    onCreated,
    onCancelled,
    fullWidth,
    forTransactionEditing,
}) => {
    const { toast } = useToaster();
    const createCounterparty = useCreateCounterpartyMutation();
    const onSubmit = useCallback(
        async (data: FormSchema) => {
            const response = await createCounterparty.mutateAsync(data);
            onCreated(response);

            if (
                !forTransactionEditing &&
                response.revealedOnTransactionsNumber
            ) {
                toast(
                    <CounterpartyVerifiedToast
                        counterparty={response.counterparty}
                        transactionsNumber={
                            response.revealedOnTransactionsNumber
                        }
                    />,
                    `counterparty-verified-${response.counterparty.id}}`,
                );
            }
        },
        [createCounterparty, onCreated, forTransactionEditing, toast],
    );

    return (
        <CounterpartyForm
            onSubmit={onSubmit}
            onCancelled={onCancelled}
            fullWidth={fullWidth}
            submitText="Add"
        />
    );
};
