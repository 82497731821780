import { useEffect, useMemo, useState } from "react";
import { UpdateTransactionDto } from "../../common/dto/transactions/update-transaction.dto";
import {
    CategoryBackupKeys,
    Transaction,
} from "../../common/types/transaction";
import { useWorkspaceContext } from "../../state/workspaceContext";
import { Switch } from "../general/Switch/Switch";
import { TransactionRuleActions } from "../transactionRules/TransactionRuleActions";
import { TransactionRuleConditions } from "../transactionRules/TransactionRuleConditions";
import { useTransactionRules } from "../../hooks/useTransactionRules";
import { CreateTransactionRuleDto } from "../../common/dto/transactionRule/create-transaction-rule.dto";
import {
    RuleConditionType,
    RuleSource,
    TransactionRule,
} from "../../common/types/transactionRule";
import { useCategoriesById } from "../../hooks/useCategoriesById";
import styles from "./ActionTransactionRule.module.scss";
import { ActionCardDetail } from "./ActionCardDetail";

interface Props {
    transaction: Transaction;
    update: UpdateTransactionDto;
    onUpdate: (rule: CreateTransactionRuleDto | undefined) => void;
}

export const ActionTransactionRule: React.FC<Props> = ({
    transaction,
    update,
    onUpdate,
}) => {
    const rules = useTransactionRules();
    const { activeWorkspace } = useWorkspaceContext();
    const [createRule, setCreateRule] = useState(true);
    const categoriesById = useCategoriesById();
    const category =
        categoriesById[update.categoryId || transaction.categoryId];
    const suggestedCategory =
        transaction.categoryBackup?.[CategoryBackupKeys.CATEGORIZATION_MODEL];

    const createRuleDto: CreateTransactionRuleDto | undefined = useMemo(() => {
        if (!activeWorkspace) {
            return;
        }

        return {
            workspaceId: activeWorkspace.id,
            applyToExistingTransactions: true,
            source: RuleSource.ACTIONS,
            rule: {
                categoryId: category.id,
                entityId: update.entityId,
                conditions: [
                    {
                        conditionType: RuleConditionType.COUNTERPARTY,
                        value: transaction.counterpartyId,
                    },
                    {
                        conditionType: RuleConditionType.ENTITY,
                        value: [transaction.entity.id],
                    },
                    {
                        conditionType: RuleConditionType.SUGGESTED_CATEGORY,
                        value: suggestedCategory,
                    },
                ],
            },
        };
    }, [
        activeWorkspace,
        category,
        suggestedCategory,
        transaction.counterpartyId,
        transaction.entity.id,
        update.entityId,
    ]);

    const rule: TransactionRule | undefined = useMemo(() => {
        if (!createRuleDto) {
            return;
        }

        return {
            id: "rule",
            workspaceId: createRuleDto.workspaceId,
            categoryId: createRuleDto.rule.categoryId ?? null,
            entityId: createRuleDto.rule.entityId ?? null,
            entity: transaction.entity,
            source: RuleSource.ACTIONS,
            category: null,
            conditions: createRuleDto.rule.conditions!.map((condition, i) => ({
                id: `condition-${i}`,
                ...condition,
            })),
        };
    }, [createRuleDto, transaction.entity]);

    const ruleAlreadyExists = useMemo(() => {
        if (!rules || !rule) {
            return false;
        }

        return rules.rules.some(
            (r) =>
                r.entityId === rule.entityId &&
                r.categoryId === rule.categoryId &&
                JSON.stringify(r.conditions.map(({ id, ...rest }) => rest)) ===
                    JSON.stringify(
                        rule.conditions.map(({ id, ...rest }) => rest),
                    ),
        );
    }, [rules, rule]);

    useEffect(() => {
        if (createRule && !ruleAlreadyExists) {
            onUpdate(createRuleDto);
        } else {
            onUpdate(undefined);
        }
    }, [createRule, createRuleDto, onUpdate, ruleAlreadyExists]);

    if (!transaction.counterparty) {
        return null;
    }

    return (
        <div className={styles.container}>
            <Switch
                size="sm"
                label={createRule ? "Yes" : "No"}
                active={createRule}
                onChanged={() => setCreateRule(!createRule)}
            />

            {createRule && rule && (
                <div>
                    <ActionCardDetail label="Condition">
                        <div className={styles.ruleContent}>
                            <TransactionRuleConditions
                                rule={rule}
                                counterparties={[transaction.counterparty]}
                            />
                        </div>
                    </ActionCardDetail>
                    <ActionCardDetail label="Action">
                        <div className={styles.ruleContent}>
                            <TransactionRuleActions rule={rule} />
                        </div>
                    </ActionCardDetail>
                </div>
            )}
        </div>
    );
};
