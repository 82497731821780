import React from "react";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";
import { IntegrationAccount } from "../../common/types/integrationAccount";
import { useIntegrationAccountRemovalMutation } from "../../mutations/integrationAccount";
import { useToaster } from "../general/ToastMessages/useToaster";
import { ExtendedButtonProps } from "../general/Button/Button";

interface Props extends Omit<ExtendedButtonProps, "onClick"> {
    account: IntegrationAccount;
}
export const RemoveIntegrationAccount: React.FC<Props> = ({
    account,
    children,
    ...btnProps
}) => {
    const { toast } = useToaster();
    const removal = useIntegrationAccountRemovalMutation(account, () => {
        toast("Account removed");
    });

    return (
        <ButtonWithLoader mutation={removal} {...btnProps}>
            {children}
        </ButtonWithLoader>
    );
};
