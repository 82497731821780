import React from "react";
import classNames from "classnames";
import {
    TransactionListItemDto,
    TransactionStatus as TransactionStatusValues,
} from "../../common/types/transaction";
import { TRANSACTION_STATUS_LABELS } from "../../common/constants";
import { Tag } from "../general/Tag/Tag";

interface Props {
    transaction: TransactionListItemDto;
    className?: string;
}

export const TransactionStatus: React.FC<Props> = ({
    transaction,
    className,
}) => {
    const variant =
        transaction.status === TransactionStatusValues.PENDING
            ? "secondary"
            : "danger";

    return transaction.status === TransactionStatusValues.COMPLETED ? null : (
        <Tag
            variant={variant}
            className={classNames("transaction-status", className)}
            data-testid="transaction-status"
        >
            {TRANSACTION_STATUS_LABELS[transaction.status]}
        </Tag>
    );
};
