import React from "react";
import { AccountingReportFilters } from "../types";
import { CardSection } from "../../general/Card/CardSection";
import { AccountingReportHeader } from "../AccountingReportHeader";
import { Card } from "../../general/Card/Card";
import { AccountingReportFooter } from "../AccountingReportFooter";
import { AccountWithTransactions } from "../../../common/dto/reports/get-account-transactions-report-response.dto";
import { Entity } from "../../../common/types/entity";
import { GeneralLedgerTableContainer } from "./GeneralLedgerTableContainer";

interface Props {
    data: AccountWithTransactions[];
    filters: AccountingReportFilters;
    generationDate: Date;
    entity: Entity;
}

export const GeneralLedgerPageContent: React.FC<Props> = ({
    data,
    filters,
    generationDate,
    entity,
}) => (
    <Card stacked>
        <CardSection>
            <AccountingReportHeader
                entityName={entity.name ?? ""}
                reportType="General Ledger"
                startDate={filters.startDate}
                endDate={filters.endDate}
            />
        </CardSection>
        <GeneralLedgerTableContainer data={data} />
        <AccountingReportFooter generationDate={generationDate} />
    </Card>
);
