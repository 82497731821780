import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DetachIcon, SearchIcon } from "../../../../icons";
import { topStartPopperConfig } from "../../../../helpers/overlays";
import { RECEIPT_UPLOAD_HIGHLIGHTED_ELEMENT } from "../../ReceiptUpload/lib";
import { formatDateForTransaction } from "../../../../helpers/date";

import { Transaction } from "../../../../common/types/transaction";
import { EagerLoaded } from "../../../../common/types/base/orm";
import { useUnmatchTransfer } from "../../../../mutations/transaction";
import { Amount } from "../../../general/Amount/Amount";
import { EntityIcon } from "../../../entity/EntityIcon";
import { TransactionDetailsContext } from "../transactionDetails.context";
import { ClickableContainer } from "../../../general/ClickableContainer";
import styles from "./TransactionActionMatchTransfer.module.scss";

interface Props {
    required: boolean;
    transaction: EagerLoaded<
        Transaction,
        "transactionMatches" | "pairedTransfer"
    >;
}

const TRUNCATED_DESCRIPTION_LENGTH = 20;

export const TransactionActionsMatchTransfer: React.FC<Props> = ({
    transaction,
    required,
}) => {
    const { onMatch } = useContext(TransactionDetailsContext);
    const [pairedTransaction, setPairedTransaction] = useState(
        transaction?.pairedTransfer,
    );

    useEffect(() => {
        setPairedTransaction(transaction?.pairedTransfer);
    }, [transaction?.pairedTransfer]);

    const counterparty = pairedTransaction?.counterparty?.name;
    const bankDescription = pairedTransaction?.bankDescription ?? "";
    const description = counterparty ?? bankDescription;

    const truncatedDescription =
        description.length > TRUNCATED_DESCRIPTION_LENGTH
            ? description.slice(0, TRUNCATED_DESCRIPTION_LENGTH - 3) + "..."
            : description;

    const unlinkMatch = useUnmatchTransfer({
        financialTransaction: transaction,
        onSuccess: () => {
            setPairedTransaction(null);
        },
    });

    return (
        <div
            className={classNames(
                "form-group form-group--with-label form-group--inside-label form-group--icon form-group--icon--right",
                RECEIPT_UPLOAD_HIGHLIGHTED_ELEMENT,
                styles.addReceipt,
                styles.transactionActionMatch,
                required && !pairedTransaction && styles.required,
            )}
        >
            {pairedTransaction ? (
                <div>
                    <ClickableContainer
                        onClick={() => onMatch(transaction)}
                        className={classNames(
                            "d-flex justify-content-between align-items-center form-control mb-0 cursor-pointer",
                            styles.bottomBorderNoRadius,
                        )}
                    >
                        <span className="form-group__label">
                            Matched transaction
                        </span>

                        <div
                            className={styles.matchedTransactionIconsContainer}
                        >
                            <OverlayTrigger
                                overlay={
                                    <Tooltip
                                        id={`transaction-unmatch-${transaction.id}`}
                                    >
                                        Unmatch transaction
                                    </Tooltip>
                                }
                                placement="top"
                                popperConfig={topStartPopperConfig}
                            >
                                <button className="form-group__icon">
                                    <DetachIcon
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            unlinkMatch.mutateAsync();
                                        }}
                                    />
                                </button>
                            </OverlayTrigger>

                            <OverlayTrigger
                                overlay={
                                    <Tooltip
                                        id={`transaction-match-replace-${transaction.id}`}
                                    >
                                        Replace transaction match
                                    </Tooltip>
                                }
                                placement="top"
                                popperConfig={topStartPopperConfig}
                            >
                                <div className="form-group__icon">
                                    <SearchIcon />
                                </div>
                            </OverlayTrigger>
                        </div>
                    </ClickableContainer>
                    <OverlayTrigger
                        overlay={
                            <Tooltip
                                id={`transaction-actions-match-description-${transaction.id}`}
                            >
                                <div className="d-flex flex-column gap-1">
                                    <div className="d-flex flex-row gap-1 align-items-center">
                                        <EntityIcon
                                            size="xs"
                                            entity={pairedTransaction.entity}
                                        />
                                        <span className="text-gray-700 font-weight-bold">
                                            {pairedTransaction.entity.name}
                                        </span>
                                    </div>
                                    {bankDescription}
                                </div>
                            </Tooltip>
                        }
                        placement="bottom"
                        popperConfig={topStartPopperConfig}
                    >
                        <div className={styles.cardFooter}>
                            <div className={styles.cardFooterText}>
                                <span>
                                    {formatDateForTransaction(
                                        new Date(pairedTransaction.date),
                                    )}
                                </span>
                                <span className={styles.truncatedText}>
                                    {truncatedDescription}
                                </span>
                            </div>
                            <div className={classNames(styles.cardFooterText)}>
                                <span
                                    className={classNames(
                                        styles.cardFooterTextAmount,
                                        pairedTransaction.amount > 0 &&
                                            styles.cardFooterTextAmountPositive,
                                    )}
                                >
                                    <Amount
                                        amount={pairedTransaction.amount}
                                        separateCurrencySign
                                        showNegative={true}
                                    />
                                </span>
                            </div>
                        </div>
                    </OverlayTrigger>
                </div>
            ) : (
                <OverlayTrigger
                    overlay={
                        <Tooltip id={`transaction-match-${transaction.id}`}>
                            Link a transaction match for this transfer
                        </Tooltip>
                    }
                    placement="top"
                    popperConfig={topStartPopperConfig}
                >
                    <ClickableContainer
                        onClick={() => onMatch(transaction)}
                        className="d-flex justify-content-between align-items-center form-control mb-0 cursor-pointer"
                    >
                        <span className="form-group__label">
                            Find a transaction match
                        </span>

                        <div
                            className={styles.matchedTransactionIconsContainer}
                        >
                            <button
                                className="form-group__icon"
                                onClick={() => onMatch(transaction)}
                            >
                                <SearchIcon />
                            </button>
                        </div>
                    </ClickableContainer>
                </OverlayTrigger>
            )}
        </div>
    );
};
