import React, { Fragment } from "react";
import classNames from "classnames";
import { Cell } from "@tanstack/react-table";
import { range } from "lodash";
import {
    PLColumns,
    sectionLabels,
} from "../../../reports/ProfitLossReportTable/useProfitLossReportTable";
import { PLDataRow } from "../../../reports/ProfitLossReportTable/types";
import { ProfitLossReportSummarySections } from "../../../../common/dto/reports/reports-common.dto";
import { SHEET_ACCOUNTING_FORMAT_NO_CURRENCY } from "../../../../common/helpers/sheet";

interface RenderCellProps {
    cell: Cell<PLDataRow, any>;
}

const INDENT_UNIT = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;

function renderIndent(depth: number) {
    return range(depth).map((i) => <Fragment key={i}>{INDENT_UNIT}</Fragment>);
}

const Title: React.FC<RenderCellProps> = ({ cell }) => {
    const { original, depth } = cell.getContext().row;

    const labelDepth = original.isTotal ? 0 : depth;
    const prefix = original.isTotal ? "Total " : "";

    switch (labelDepth) {
        case 0:
            return (
                <>
                    {renderIndent(labelDepth)}
                    {prefix} {sectionLabels[original.section]}
                </>
            );

        case 1: {
            return (
                <>
                    {renderIndent(labelDepth)}
                    {original.category}
                </>
            );
        }

        default:
            return null;
    }
};
export const LedgerBasedProfitLossDownloadCell: React.FC<RenderCellProps> = ({
    cell,
}) => {
    const row = cell.row;
    const isTitle = cell.column.id === PLColumns.TITLE;
    const isTotal = cell.row.original.isTotal;
    const parentRow = cell.row.getParentRow();
    const value = isTotal
        ? parentRow?.getValue(cell.column.id)
        : cell.getValue();
    const isSummarySection = ProfitLossReportSummarySections.includes(
        row.original.section,
    );

    const classes = classNames("download-table__table__cell", {
        "download-table__cell--bold": isSummarySection,
        "download-table__cell--right": !isTitle,
        "download-table__cell--left": isTitle,
        "download-table__cell--border-top":
            (isTotal || isSummarySection) && !isTitle,
    });

    if (isTitle) {
        return (
            <td className={classes}>
                <Title cell={cell} />
            </td>
        );
    }

    const showValue = !cell.getIsAggregated() || !row.getIsExpanded();

    return (
        <td className={classes} data-z={SHEET_ACCOUNTING_FORMAT_NO_CURRENCY}>
            {showValue ? value : null}
        </td>
    );
};
