import React, { FC } from "react";
import { Entity } from "../../common/types/entity";
import { EntityIcon } from "../entity/EntityIcon";
import { CommandItem } from "./CommandItem";

interface EntitySettingsCommandItemProps {
    index: number;
    entity: Entity;
    onSelected(): void;
}

export const EntitySettingsCommandItem: FC<EntitySettingsCommandItemProps> = ({
    index,
    entity,
    onSelected,
}) => (
    <CommandItem
        keywords={["entity", entity.name!]}
        onSelect={onSelected}
        shortcut={["G", index.toString()]}
    >
        <EntityIcon entity={entity} size="xs" /> {entity.name}
    </CommandItem>
);
