import React from "react";
import classNames from "classnames";
import { AddMemberStep } from "./types";
import styles from "./MemberManagement.module.scss";

const addMemberStepLabel = {
    [AddMemberStep.DETAILS]: "Member details",
    [AddMemberStep.PERMISSIONS]: "Configure permissions",
} as const satisfies Record<AddMemberStep, string>;

const stepsOrder = [AddMemberStep.DETAILS, AddMemberStep.PERMISSIONS] as const;

interface Props {
    currentStep: AddMemberStep;
}

export const AddMemberSteps: React.FC<Props> = ({ currentStep }) => (
    <ul className={classNames("list-unstyled", styles.steps)}>
        {stepsOrder.map((step) => (
            <li
                key={step}
                className={classNames(styles.step, {
                    [styles.activeStep]: step === currentStep,
                })}
            >
                {addMemberStepLabel[step]}
            </li>
        ))}
    </ul>
);
