import {
    QueryClient,
    useMutation,
    useQueryClient,
} from "@tanstack/react-query";
import { ServerInferRequest } from "@ts-rest/core";
import { getBackendAPIClient } from "../lib/backendAPIClient";
import { counterpartyContract } from "../common/contracts/counterparty.contract";
import { useWorkspaceContext } from "../state/workspaceContext";
import { Counterparty } from "../common/types/counterparty";
import { GetCounterpartiesDto } from "../common/dto/counterparty/get-counterparties.dto";
import { CounterpartiesReturnFormat } from "../common/constants/counterparty";
import { GetCounterpartiesResponseDto } from "../common/dto/counterparty/get-counterparties-response.dto";
import { FileResponse } from "../types";
import { backendClient, unwrapResponse } from "../lib/backendClient";

export const counterpartyContractClient =
    getBackendAPIClient(counterpartyContract);

export const counterpartiesQueryKeys = {
    all: () => ["counterparties"] as const,
    query: (workspaceId: string) =>
        [...counterpartiesQueryKeys.all(), workspaceId] as const,
};

export function useCreateCounterpartyMutation() {
    const { activeWorkspace } = useWorkspaceContext();

    return useMutation({
        mutationFn: async (
            data: Omit<
                ServerInferRequest<
                    typeof counterpartyContract.createCounterparty
                >["body"],
                "workspaceId"
            >,
        ) => {
            if (!activeWorkspace) {
                throw new Error("No active workspace");
            }

            const response =
                await counterpartyContractClient.createCounterparty({
                    body: {
                        ...data,
                        workspaceId: activeWorkspace.id,
                    },
                });

            return response.body;
        },
    });
}

export function useUpdateCounterpartyMutation(
    counterparty: Counterparty,
    onSuccess?: (counterparty: Counterparty) => void,
) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (
            data: ServerInferRequest<
                typeof counterpartyContract.updateCounterparty
            >["body"],
        ) => {
            if (!counterparty.workspaceId) {
                throw new Error("Can't edit global counterparty");
            }

            const response =
                await counterpartyContractClient.updateCounterparty({
                    body: data,
                    params: {
                        workspaceId: counterparty.workspaceId,
                        counterpartyId: counterparty.id,
                    },
                });

            return response.body.counterparty;
        },
        onSuccess: (updatedCounterparty) => {
            handleCounterpartyUpdate(
                queryClient,
                counterparty.id,
                updatedCounterparty,
            );
            onSuccess?.(updatedCounterparty);
        },
    });
}

export function useCopyGlobalCounterpartyToLocalMutation(
    onSuccess?: (localCounterparty: Counterparty) => void,
) {
    const queryClient = useQueryClient();
    const { activeWorkspace } = useWorkspaceContext();

    return useMutation({
        mutationFn: async (
            data: Omit<
                ServerInferRequest<
                    typeof counterpartyContract.copyGlobalCounterparty
                >["body"],
                "workspaceId"
            >,
        ) => {
            if (!activeWorkspace) {
                throw new Error("No active workspace");
            }

            const response =
                await counterpartyContractClient.copyGlobalCounterparty({
                    body: {
                        ...data,
                        workspaceId: activeWorkspace.id,
                    },
                });

            return response.body.localCounterparty;
        },
        onSuccess: (localCounterparty, data) => {
            handleCounterpartyUpdate(
                queryClient,
                data.globalCounterpartyId,
                localCounterparty,
            );
            onSuccess?.(localCounterparty);
        },
    });
}

export function useCounterpartyRemovalMutation(counterparty: Counterparty) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (force: true | void) => {
            if (!counterparty.workspaceId) {
                throw new Error("Can't delete global counterparty");
            }

            return await counterpartyContractClient.deleteCounterparty({
                params: {
                    workspaceId: counterparty.workspaceId,
                    counterpartyId: counterparty.id,
                },
                query: { force: force ? "true" : undefined },
            });
        },
        onSuccess: () => {
            handleCounterpartyRemoval(
                queryClient,
                counterparty.id,
                counterparty.workspaceId ?? "",
            );
        },
    });
}

export function useCounterpartyMergingMutation() {
    const queryClient = useQueryClient();
    const { activeWorkspace } = useWorkspaceContext();

    return useMutation({
        mutationFn: async (
            data: ServerInferRequest<
                typeof counterpartyContract.mergeCounterparties
            >["body"],
        ) => {
            if (!activeWorkspace) {
                throw new Error("No active workspace");
            }

            return await counterpartyContractClient.mergeCounterparties({
                params: {
                    workspaceId: activeWorkspace.id,
                },
                body: data,
            });
        },
        onSuccess: (_, body) => {
            handleCounterpartyRemoval(
                queryClient,
                body.counterpartyToMergeId,
                activeWorkspace?.id ?? "",
            );
        },
    });
}

function handleCounterpartyUpdate(
    queryClient: QueryClient,
    counterpartyId: string,
    updatedCounterparty: Counterparty,
) {
    const queryKey = counterpartiesQueryKeys.query(
        updatedCounterparty.workspaceId ?? "",
    );
    queryClient.setQueriesData(
        { queryKey },
        (queryData: GetCounterpartiesResponseDto | undefined) => {
            if (!queryData) {
                return { data: [] };
            }

            return {
                ...queryData,
                data: queryData.data.map((row) => ({
                    ...row,
                    counterparty:
                        row.counterparty.id === counterpartyId
                            ? updatedCounterparty
                            : row.counterparty,
                })),
            };
        },
    );
    queryClient.invalidateQueries({ queryKey });
}

function handleCounterpartyRemoval(
    queryClient: QueryClient,
    removedCounterpartyId: string,
    workspaceId: string,
) {
    const queryKey = counterpartiesQueryKeys.query(workspaceId);
    queryClient.setQueriesData(
        { queryKey },
        (queryData: GetCounterpartiesResponseDto | undefined) => {
            if (!queryData) {
                return { data: [] };
            }

            return {
                ...queryData,
                data: queryData.data.filter(
                    (row) => row.counterparty.id !== removedCounterpartyId,
                ),
            };
        },
    );
    queryClient.invalidateQueries({ queryKey });
}

type QueryWithoutFormat = Omit<GetCounterpartiesDto, "format">;

export function getCounterparties(
    query: QueryWithoutFormat,
    format: CounterpartiesReturnFormat.JSON,
): Promise<GetCounterpartiesResponseDto>;
export function getCounterparties(
    query: QueryWithoutFormat,
    format: CounterpartiesReturnFormat.CSV,
): Promise<FileResponse>;
export async function getCounterparties(
    query: QueryWithoutFormat,
    format: CounterpartiesReturnFormat,
): Promise<GetCounterpartiesResponseDto | FileResponse> {
    const response = await backendClient.get("/counterparty", {
        params: { ...query, format },
        responseType:
            format === CounterpartiesReturnFormat.JSON ? "json" : "arraybuffer",
    });

    return format === CounterpartiesReturnFormat.CSV
        ? response
        : unwrapResponse(response);
}
