import React, { useMemo } from "react";
import { Form, Formik, FormikConfig } from "formik";
import { boolean, object } from "yup";
import { useUser } from "../../../hooks/useUser";
import { submitHelper } from "../../../helpers/form";
import { useUpdateUserMutation } from "../../../mutations/user";
import { FormRow } from "../../forms/FormRow";
import { Switch } from "../../general/Switch/Switch";
import styles from "./Notifications.module.scss";

interface Schema {
    weeklyInsightsEnabled: boolean;
    monthlyInsightsEnabled: boolean;
}
export const Notifications: React.FC = () => {
    const { settings } = useUser();
    const updateUser = useUpdateUserMutation();

    const form: FormikConfig<Schema> = useMemo(
        () => ({
            initialValues: {
                weeklyInsightsEnabled: Boolean(
                    settings.weeklyInsightsEnabledAt,
                ),
                monthlyInsightsEnabled: Boolean(
                    settings.monthlyInsightsEnabledAt,
                ),
            },
            validationSchema: object().shape({
                weeklyInsightsEnabled: boolean(),
                monthlyInsightsEnabled: boolean(),
            }),
            onSubmit: submitHelper({
                handler: async (value) => {
                    await updateUser.mutateAsync({
                        settings: {
                            ...settings,
                            weeklyInsightsEnabledAt: value.weeklyInsightsEnabled
                                ? new Date().toISOString()
                                : null,
                            monthlyInsightsEnabledAt:
                                value.monthlyInsightsEnabled
                                    ? new Date().toISOString()
                                    : null,
                        },
                    });
                },
            }),
        }),
        [settings, updateUser],
    );

    return (
        <Formik {...form}>
            {({ submitForm }) => (
                <section className={styles.settings}>
                    <Form>
                        <FormRow fieldName="weeklyInsightsEnabled">
                            {({ setValue }, { value }) => (
                                <article className={styles.settingElement}>
                                    <div>
                                        <header>Weekly update</header>
                                        <p>
                                            A summary of your weekly revenue and
                                            estimated savings with Kick.
                                        </p>
                                    </div>

                                    <Switch
                                        active={value}
                                        onChanged={() => {
                                            setValue(!value);
                                            submitForm();
                                        }}
                                        size="md"
                                    />
                                </article>
                            )}
                        </FormRow>

                        <FormRow fieldName="monthlyInsightsEnabled">
                            {({ setValue }, { value }) => (
                                <article className={styles.settingElement}>
                                    <div>
                                        <header>Monthly update</header>
                                        <p>
                                            A summary of your monthly Cash
                                            Activity and estimated savings with
                                            Kick.
                                        </p>
                                    </div>

                                    <Switch
                                        active={value}
                                        onChanged={() => {
                                            setValue(!value);
                                            submitForm();
                                        }}
                                        size="md"
                                    />
                                </article>
                            )}
                        </FormRow>
                    </Form>
                </section>
            )}
        </Formik>
    );
};
