import { FinancialConnectionWithAccounts } from "../common/types/financialConnection";
import { FinancialConnectionDto } from "../common/dto/financialAccount/financial-connection.dto";
import { CreateRampOauthConnectionDto } from "../common/dto/ramp/create-ramp-oauth-connection.dto";
import { CreateOauthConnectionDto } from "../common/dto/oauth/create-oauth-connection.dto";
import { backendClient, unwrapResponse } from "./backendClient";

export async function createRampConnection(
    data: CreateRampOauthConnectionDto,
): Promise<FinancialConnectionWithAccounts> {
    return FinancialConnectionDto.deserialize(
        unwrapResponse(await backendClient.post("/ramp-connection", data)),
    );
}

export async function fixRampConnection(
    id: number,
    data: CreateOauthConnectionDto,
): Promise<FinancialConnectionWithAccounts> {
    return FinancialConnectionDto.deserialize(
        unwrapResponse(
            await backendClient.post(`/ramp-connection/fix/${id}`, data),
        ),
    );
}
