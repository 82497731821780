import { Taxonomy } from "../../categories";

// NOTE: Map only Cashflow In And CapitalChange categories.
// All other categories are mapped to Cashflow Out

export const CashFlowCashInCategories = [
    Taxonomy.income,
    Taxonomy.other_income,
    Taxonomy.interest_income,
];

export const CashFlowCapitalChangeCategories = [
    Taxonomy.contributions_distributions,
    Taxonomy.transfer,
    Taxonomy.security_deposit,
    Taxonomy.investment,
    Taxonomy.credit_card_loans,
    Taxonomy.credit_card_payment,
    Taxonomy.loan_payment,
    Taxonomy.taxes,
    Taxonomy.federal_taxes,
];

export enum CashFlowSectionId {
    cashIn = "cash-in",
    cashOut = "cash-out",
    cashInRefunds = "cash-in-refunds",
    cashOutRefunds = "cash-out-refunds",
    netProfitLoss = "net-profit-loss",
    capital = "capital",
    netCashFlow = "net-cash-flow",
}
