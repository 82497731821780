import React from "react";
import { Nav, Tab } from "react-bootstrap";
import classNames from "classnames";
import { NavigationMenu } from "../../navigation/NavigationMenu";
import { useAggregatedFiltersContext } from "./AggregatedFilters.context";
import css from "./AggregatedFiltersOverlay.module.scss";

interface Props {
    navigation: React.ReactNode;
    content: React.ReactNode;
    filterClassname?: string;
}
export const AggregatedFiltersOverlay: React.FC<Props> = ({
    navigation,
    content,
    filterClassname,
}) => {
    const { activeTab } = useAggregatedFiltersContext();

    return (
        <Tab.Container defaultActiveKey={activeTab} mountOnEnter unmountOnExit>
            <section className={css.layout}>
                <Nav className={css.tabs}>
                    <NavigationMenu small>{navigation}</NavigationMenu>
                </Nav>
                <main className={classNames(css.filter, filterClassname)}>
                    <Tab.Content>{content}</Tab.Content>
                </main>
            </section>
        </Tab.Container>
    );
};
