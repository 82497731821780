import React from "react";
import { CloseIcon } from "../../icons";
import { ActionCardSection } from "./ActionCardSection";
import { ActionCloseButton } from "./ActionCloseButton";

interface Props {
    header: string;
    onClose: () => void;
}

export const ActionCardSkipped: React.FC<Props> = ({ header, onClose }) => (
    <ActionCardSection
        icon={<CloseIcon width={16} height={16} />}
        header={header}
        isCompleted
        isSkipped
    >
        <ActionCloseButton onClick={onClose} />
    </ActionCardSection>
);
