import React from "react";
import { noop } from "../../../helpers/general";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import {
    CodeVerification,
    CodeVerificationProps,
} from "../../general/CodeVerification/CodeVerification";
import { ResendCode } from "../../general/CodeVerification/ResendCode";
import { isEmail } from "../../../common/helpers/accounts";
import { Button } from "../../general/Button/Button";
import { PhoneNumberDisplay } from "../../phoneNumber/PhoneNumberDisplay";
import { LeftArrowIcon } from "../../../icons";

interface LoginFlowPhoneAuthProps {
    providedPhoneOrEmail: string;
    onBack(): void;
    onRequestCode: CodeVerificationProps["request"];
    onVerify: CodeVerificationProps["verify"];
}

export const LoginFlowCodeAuth: React.FC<LoginFlowPhoneAuthProps> = ({
    providedPhoneOrEmail,
    onBack,
    onVerify,
    onRequestCode,
}) => {
    const codeSentTo = isEmail(providedPhoneOrEmail) ? (
        providedPhoneOrEmail
    ) : (
        <PhoneNumberDisplay phoneNumber={providedPhoneOrEmail} />
    );

    return (
        <>
            <h2 className="login__heading">
                Check your {isEmail(providedPhoneOrEmail) ? "email" : "phone"}
                <div>
                    We just sent a 6-digit code to{" "}
                    <strong className="font-weight-medium">{codeSentTo}</strong>
                    <br />
                    Proceed by typing your code below.
                </div>
            </h2>

            <CodeVerification
                phoneOrEmail={providedPhoneOrEmail}
                verify={onVerify}
                request={onRequestCode}
                requestOnMount={false}
                onSuccess={noop}
                lead={null}
                renderSubmit={(valid, verifying) => (
                    <>
                        <section className="mb-5 text-center d-flex justify-content-center">
                            <ResendCode request={onRequestCode} btnSize="lg" />
                        </section>

                        <footer className="d-flex justify-content-center mt-5">
                            <Button
                                variant="secondary"
                                onClick={onBack}
                                className="mr-2"
                                size="lg"
                                icon
                            >
                                <LeftArrowIcon />
                            </Button>

                            <ButtonWithLoader
                                disabled={!valid}
                                loading={verifying}
                                type="submit"
                                size="lg"
                            >
                                Log in
                            </ButtonWithLoader>
                        </footer>
                    </>
                )}
            />
        </>
    );
};
