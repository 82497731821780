import React from "react";
import classNames from "classnames";
import { TransactionListItemDto } from "../../common/types/transaction";
import { Amount } from "../general/Amount/Amount";
import "./TransactionAmount.scss";

interface Props {
    transaction: TransactionListItemDto;
    addSign?: boolean;
    color?: boolean;
    className?: string;
}

export const TransactionAmount: React.FC<Props> = ({
    transaction,
    addSign = false,
    color = false,
    className,
}) => (
    <div
        className={classNames(
            "transaction-amount",
            `transaction-amount--${transaction.status}`,
            {
                "transaction-amount--color": color,
                "transaction-amount--credit": transaction.amount > 0,
                "transaction-amount--debit": transaction.amount < 0,
            },
            className,
        )}
        data-testid="transaction-amount"
    >
        <Amount
            amount={transaction.amount}
            separateCurrencySign
            showNegative={addSign}
        />
    </div>
);
