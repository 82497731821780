import React from "react";
import { Loader } from "../../general/Loader";
import { GetTransactionRulesResponse } from "../../../common/dto/transactionRule/get-transaction-rules-response.dto";
import { TransactionRules } from "./TransactionRules";

interface Props {
    data?: GetTransactionRulesResponse;
}

export const RulesSettingsContent: React.FC<Props> = ({ data }) => {
    if (!data) {
        return <Loader />;
    }

    if (data.rules.length === 0) {
        return (
            <p className="text-center mt-5 text-prominent">
                No rules created yet
            </p>
        );
    }

    return <TransactionRules {...data} />;
};
