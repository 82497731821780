import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import { PageCoverLoader } from "../general/PageCoverLoader/PageCoverLoader";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import { useBillingStatus } from "../../hooks/useBillingStatus";
import { useConvertTrialMutation } from "../../mutations/flatRateBilling";

export const Convert: React.FC = () => {
    const navigate = useNavigate();
    const [{ after: afterCheckoutUrl, code, intent: intentId }] =
        useQueryParams({
            after: StringParam,
            code: withDefault(StringParam, undefined),
            intent: withDefault(StringParam, undefined),
        });

    const { query } = useBillingStatus();
    const afterCheckout = useCallback(async () => {
        await query.refetch();
        navigate(afterCheckoutUrl ?? "/settings/billing");
    }, [afterCheckoutUrl, navigate, query]);

    const convertMutation = useConvertTrialMutation(
        {
            code,
            intentId,
        },
        afterCheckout,
    );

    useEffectOnce(() => {
        convertMutation.mutate();
    });

    return <PageCoverLoader>Starting autopilot</PageCoverLoader>;
};
