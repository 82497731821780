import { FormikConfig } from "formik";
import { object, string } from "yup";
import { Entity } from "../../common/types/entity";
import { EntityProfileFormSchema } from "./types";
import { annualRevenueBrackets, entityTypes, industries } from "./constants";

export function useEntityProfileFormConfig(
    entity: Entity,
): Pick<
    FormikConfig<EntityProfileFormSchema>,
    "initialValues" | "validationSchema"
> {
    return {
        initialValues: {
            name: entity.name ?? "",
            type: entity.profile?.type,
            industry: entity.profile?.industry,
            annualRevenue: entity.profile?.annualRevenue,
            country: entity.profile?.country ?? "US",
        },
        validationSchema: object().shape({
            name: string().required("Business name is required"),
            annualRevenue: string()
                .oneOf(annualRevenueBrackets)
                .required("Select an annual revenue bracket"),
            type: string().oneOf(entityTypes).required("Select an entity type"),
            industry: string().oneOf(industries).required("Select an industry"),
            country: string().required("Select a country"),
        }),
    };
}
