import React, { useRef, useState } from "react";
import { object, string } from "yup";
import { Form, Formik, FormikConfig } from "formik";
import classNames from "classnames";
import { FormikProps } from "formik/dist/types";
import {
    Entity,
    EntityProfile,
    FirmSize,
    ProfessionalType,
} from "../../../common/types/entity";
import {
    CustomSelect,
    CustomSelectOption,
} from "../../forms/CustomSelect/CustomSelect";
import { firmSizeLabels, professionalTypeLabels } from "../../entity/constants";
import { submitHelper } from "../../../helpers/form";
import animations from "../layout/OnboardingLayout.module.scss";
import { FormRow } from "../../forms/FormRow";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { Footer } from "../components/Footer";
import { KeyboardReturnIcon } from "../../../icons";
import { useKeyboardCommands } from "../../../hooks/keyboard/useKeyboardCommands";
import { KeyboardShortcut } from "../../general/KeyboardShortcut/KeyboardShortcut";
import commonStyles from "./common.module.scss";

export interface FirmInfoProps {
    entity: Entity;
    onSubmit(
        payload: Pick<
            EntityProfile,
            "professionalType" | "firmSize" | "howDidYouLearn"
        >,
    ): Promise<void>;
    back(): void;
}

const sizes = Object.values(FirmSize);
const professionalTypes = Object.values(ProfessionalType);
const sizeOptions: CustomSelectOption[] = sizes.map((value) => ({
    value,
    label: firmSizeLabels[value],
}));

const professionalTypeOptions: CustomSelectOption[] = professionalTypes.map(
    (value) => ({
        value,
        label: professionalTypeLabels[value],
    }),
);

export interface FormSchema {
    firmSize?: FirmSize;
    professionalType?: ProfessionalType;
}

export const FirmInfo: React.FC<FirmInfoProps> = ({
    entity,
    onSubmit,
    back,
}) => {
    const formRef = useRef<FormikProps<FormSchema>>(null);

    const [loading, setLoading] = useState(false);

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            firmSize: entity.profile?.firmSize,
            professionalType: entity.profile?.professionalType,
        },
        validationSchema: object().shape({
            firmSize: string().oneOf(sizes).required("Select a size"),
            professionalType: string()
                .oneOf(professionalTypes)
                .required("Select a type"),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async ({ firmSize, professionalType }) => {
                await onSubmit({
                    firmSize,
                    professionalType,
                });
            },
        }),
    };

    useKeyboardCommands({
        commands: [
            {
                key: "Enter",
                requiresCtrlOrMeta: false,
                callback: () => {
                    formRef?.current?.submitForm();
                },
            },
        ],
    });

    return (
        <div
            className={classNames(commonStyles.content420, animations.fadeIn1)}
        >
            <header className={animations.fadeIn2}>
                <h1 className={commonStyles.title}>Tell us about your firm</h1>
            </header>

            <Formik {...form} innerRef={formRef}>
                <Form>
                    <FormRow fieldName="firmSize">
                        {(helpers, field) => (
                            <CustomSelect
                                label="Number of clients served"
                                value={field.value}
                                onSelected={(value) => helpers.setValue(value)}
                                dropdownKey={field.name}
                                options={sizeOptions}
                                fullWidthDropdown
                                size="sm"
                            />
                        )}
                    </FormRow>

                    <FormRow fieldName="professionalType">
                        {(helpers, field) => (
                            <CustomSelect
                                label="Type of professional"
                                value={field.value}
                                onSelected={(value) => helpers.setValue(value)}
                                dropdownKey={field.name}
                                options={professionalTypeOptions}
                                fullWidthDropdown
                                size="sm"
                            />
                        )}
                    </FormRow>

                    <Footer onBack={back}>
                        <ButtonWithLoader
                            type="submit"
                            role="submit"
                            variant="secondary"
                            loading={loading}
                        >
                            Next
                            <KeyboardShortcut square className="ml-2">
                                <KeyboardReturnIcon />
                            </KeyboardShortcut>
                        </ButtonWithLoader>
                    </Footer>
                </Form>
            </Formik>
        </div>
    );
};
