const today = new Date();
const currentYear = today.getFullYear();

export function useDefaultYear() {
    if (
        today.getMonth() > 3 ||
        (today.getMonth() === 3 && today.getDate() > 15)
    ) {
        return currentYear;
    }

    return currentYear - 1;
}
