import React from "react";
import { SidebarInfo } from "../../components/SidebarInfo";
import animation from "../../layout/OnboardingLayout.module.scss";

export const PaymentProcessorSidebar: React.FC = () => (
    <aside>
        <SidebarInfo className={animation.fadeIn2}>
            <header>Why do you need access to my payment processor?</header>

            <p>
                Connecting your payment processor helps Kick maintain accurate
                financials by categorizing revenue and fees in real-time.
            </p>
        </SidebarInfo>

        <SidebarInfo className={animation.fadeIn1}>
            <header>Don’t have a payment processor?</header>

            <p>
                We recommend Stripe as it has best in class technology and
                industry low fees.
            </p>

            <p>Reach out to support@kick.co for more information.</p>
        </SidebarInfo>
    </aside>
);
