import { initContract } from "@ts-rest/core";
import { z } from "zod";
import { FinancialDocumentDto } from "../dto/financialDocument/financial-document.dto";

const c = initContract();

export const financialDocumentContract = c.router(
    {
        createUploadUrl: {
            method: "POST",
            path: "/upload-url",
            body: z.object({
                path: z.string(),
            }),
            responses: {
                200: z.object({
                    url: z.string(),
                }),
            },
        },
        uploadDocumentS3: {
            method: "POST",
            path: "/s3",
            body: z.object({
                workspaceId: z.string(),
                path: z.string(),
            }),
            responses: {
                200: c.type<FinancialDocumentDto[]>(),
            },
        },
    },
    {
        pathPrefix: "/financial-document",
    },
);
