import React from "react";
import { NavigationContainer } from "../navigation/NavigationContainer";
import { Navigation } from "../navigation/Navigation";
import { ChildrenProps } from "../../types";
import { CommandsProvider } from "../commands/CommandsProvider";
import { Breadcrumb, MainInterfaceHeader } from "./MainInterfaceHeader";
import styles from "./MainInterface.module.scss";

export interface MainInterfaceProps extends ChildrenProps {
    navigation?: React.ReactNode;
    subNavigation?: React.ReactNode;
    breadcrumbs?: Breadcrumb[];
}

export const MainInterface: React.FC<MainInterfaceProps> = ({
    children,
    navigation = <Navigation />,
    subNavigation,
    breadcrumbs,
}) => (
    <CommandsProvider>
        <NavigationContainer>{navigation}</NavigationContainer>
        <div className={styles.mainInterface}>
            <MainInterfaceHeader breadcrumbs={breadcrumbs} />
            {subNavigation && (
                <div className={styles.subNavigation}>{subNavigation}</div>
            )}

            <main className={`${styles.container} ${styles.mainContainer}`}>
                {children}
            </main>
        </div>
    </CommandsProvider>
);
