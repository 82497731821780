import React, { useMemo } from "react";
import { ChildrenProps } from "../../types";
import {
    CashFlowDrillDownContext,
    CashFlowDrillDownContextValue,
} from "./CashFlowDrillDown.context";

export const CashFlowDrillDownContextProvider: React.FC<ChildrenProps> = ({
    children,
}) => {
    const [params, setParams] =
        React.useState<CashFlowDrillDownContextValue["params"]>();

    const value = useMemo<CashFlowDrillDownContextValue>(
        () => ({
            params,
            open: setParams,
            close: () => setParams(undefined),
        }),
        [params],
    );

    return (
        <CashFlowDrillDownContext.Provider value={value}>
            {children}
        </CashFlowDrillDownContext.Provider>
    );
};
