import React from "react";
import classNames from "classnames";
import { MergeFinancialAccountsResponse } from "../../../../common/dto/financialAccount/mergeFinancialAccounts/merge-financial-accounts-response.dto";
import gridTableStyles from "../../../../styles/grid-table.module.scss";
import { Collapsible } from "../../../general/Collapsible/Collapsible";
import { FormCheckbox } from "../../../forms/FormCheckbox/FormCheckbox";
import { TransactionToMerge } from "./TransactionToMerge";
import styles from "./MultipleMatchingConflicts.module.scss";

interface Props {
    conflicts: MergeFinancialAccountsResponse["multipleMatchingConflicts"];
    multipleMatchingConflictsResolutions: Array<{
        fromDestination: number;
        toKeep: number;
    }>;
    onResolve: (
        resolutions: Array<{
            fromDestination: number;
            toKeep: number;
        }>,
    ) => void;
}

export const MultipleMatchingConflicts: React.FC<Props> = ({
    conflicts,
    multipleMatchingConflictsResolutions,
    onResolve,
}) => {
    const header = (
        <div className={styles.header}>
            <main>
                <p>
                    Following transactions from destination account have more
                    than one matching transaction in account to be merged. Can't
                    determine which transactions should be moved.
                </p>
            </main>
        </div>
    );

    const handleSelectTransaction = (
        fromDestination: number,
        toKeep: number,
    ) => {
        const isAlreadySelected = multipleMatchingConflictsResolutions.find(
            (resolution) => resolution.fromDestination === fromDestination,
        );

        if (isAlreadySelected && isAlreadySelected.toKeep !== toKeep) {
            return onResolve([
                ...multipleMatchingConflictsResolutions.filter(
                    (resolution) =>
                        resolution.fromDestination !== fromDestination,
                ),
                { fromDestination, toKeep },
            ]);
        }

        onResolve([
            ...multipleMatchingConflictsResolutions,
            { fromDestination, toKeep },
        ]);
    };

    return (
        <Collapsible header={header}>
            <div className={styles.multipleMatchingConflicts}>
                {conflicts.map((conflict) => (
                    <div
                        className={classNames(
                            gridTableStyles.gridTable,
                            styles.table,
                        )}
                        key={conflict.transactionFromDestination.id}
                    >
                        <div className={gridTableStyles.headerRow}>
                            <div className={styles.headerCell}>
                                In destination
                            </div>
                        </div>
                        <TransactionToMerge
                            transaction={conflict.transactionFromDestination}
                            prepend={
                                <div>
                                    <FormCheckbox
                                        type="radio"
                                        isChecked
                                        value={
                                            conflict.transactionFromDestination
                                                .id
                                        }
                                        disabled
                                    />
                                </div>
                            }
                        />
                        <div className={gridTableStyles.headerRow}>
                            <div className={styles.headerCell}>To merge</div>
                        </div>
                        {conflict.transactionsToMerge.map((transaction) => (
                            <TransactionToMerge
                                transaction={transaction}
                                key={transaction.id}
                                prepend={
                                    <div>
                                        <FormCheckbox
                                            type="radio"
                                            isChecked={multipleMatchingConflictsResolutions.some(
                                                (resolution) =>
                                                    resolution.toKeep ===
                                                        transaction.id &&
                                                    resolution.fromDestination ===
                                                        conflict
                                                            .transactionFromDestination
                                                            .id,
                                            )}
                                            value={transaction.id}
                                            handleChange={() => {
                                                handleSelectTransaction(
                                                    conflict
                                                        .transactionFromDestination
                                                        .id,
                                                    transaction.id,
                                                );
                                            }}
                                            disabled={multipleMatchingConflictsResolutions.some(
                                                (resolution) =>
                                                    resolution.toKeep ===
                                                    transaction.id,
                                            )}
                                        />
                                    </div>
                                }
                            />
                        ))}
                    </div>
                ))}
            </div>
        </Collapsible>
    );
};
