import React, { useCallback, useMemo, useState } from "react";
import { partition, sortBy } from "lodash";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { useFinancialAccounts } from "../../../../hooks/useFinancialAccounts";
import { AccountsConnectionOnboardingWidget } from "../../../financialAccount/AccountConnectionWidget/AccountsConnectionOnboardingWidget";
import { AccountsConnectionProvider } from "../../../financialAccount/AccountConnectionWidget/AccountsConnectionContext";
import { ConnectPlaidAccountProps } from "../../../plaid/ConnectPlaid/ConnectPlaid";
import {
    FinancialAccount,
    FinancialAccountType,
} from "../../../../common/types/financialAccount";
import { useUser } from "../../../../hooks/useUser";
import { getUnassignedFinancialAccounts } from "../../../../lib/financialAccount";
import { unassignedAccountsQueryKey } from "../../../../queries/unassignedAccounts";
import { IntegrationAccount } from "../../../../common/types/integrationAccount";
import { useEffectOnce } from "../../../../hooks/useEffectOnce";
import { trackEvent } from "../../../../lib/analytics";
import { KeyboardReturnIcon, PlusIcon } from "../../../../icons";
import { Footer } from "../../components/Footer";
import { PersonalDataAlert } from "../../components/PersonalDataAlert";
import commonStyles from "../common.module.scss";
import animations from "../../layout/OnboardingLayout.module.scss";
import { useKeyboardCommands } from "../../../../hooks/keyboard/useKeyboardCommands";
import { useConnectAccount } from "../../../connectAccount/ConnectAccount.context";
import { CommandsProvider } from "../../../commands/CommandsProvider";
import { Button } from "../../../general/Button/Button";
import { KeyboardShortcut } from "../../../general/KeyboardShortcut/KeyboardShortcut";

export interface ConnectAccountsProps
    extends Pick<
        ConnectPlaidAccountProps,
        "onConnected" | "defaultEntity" | "beforeConnect"
    > {
    onBack?(): void;
    onFinished(): void;
    allowNoAccounts?: boolean;
}

type IntegrationAccountWithFinancialAccount = IntegrationAccount & {
    financialAccount: FinancialAccount;
};

function splitAccountsByType(
    allAccounts: IntegrationAccountWithFinancialAccount[],
) {
    const [checkingAccounts, creditCards] = partition(
        allAccounts,
        (account) =>
            account.financialAccount.financialType !==
            FinancialAccountType.CREDIT_CARD,
    );

    return { checkingAccounts, creditCards };
}

export const ConnectAccounts: React.FC<ConnectAccountsProps> = ({
    onBack,
    onFinished,
    allowNoAccounts,
    defaultEntity,
    beforeConnect,
}) => {
    const {
        state: { isConnecting, connection: newConnection },
    } = useConnectAccount();

    const user = useUser();
    // when onboarding additional entity, show only accounts from connections created during the flow
    // (entity is automatically assigned to new accounts created during additional entity onboarding flow)
    const allFinancialAccounts = useFinancialAccounts({
        entityId: defaultEntity?.id,
    });
    // when performing initial onboarding, entity is not assigned by default to account, so need to fetch all unassigned accounts
    const unassignedAccounts = useQuery({
        queryKey: [unassignedAccountsQueryKey],
        queryFn: () =>
            user.onboardingComplete ? [] : getUnassignedFinancialAccounts(),
    });

    const [showAddAccount, setShowAddAccount] = useState<boolean>(false);
    const [busy, setBusy] = useState<boolean>(false);

    const allFinancialAccountsOrdered = useMemo(
        () =>
            sortBy(
                [...allFinancialAccounts, ...(unassignedAccounts.data ?? [])],
                "id",
            ).flatMap((a) =>
                (a.integrationAccounts ?? []).map((ia) => ({
                    ...ia,
                    financialAccount: a,
                })),
            ),
        [allFinancialAccounts, unassignedAccounts],
    );

    const hasAccountWithNoType = allFinancialAccounts.some(
        (acc) => acc.isBusiness === null,
    );

    const { checkingAccounts, creditCards } = useMemo(
        () => splitAccountsByType(allFinancialAccountsOrdered),
        [allFinancialAccountsOrdered],
    );

    useEffectOnce(() => {
        void trackEvent("onboarding_step_connect_accounts", {
            bankAccountsConnected: checkingAccounts.length,
            creditCardsConnected: creditCards.length,
        });
    });

    const handleAccountConnecting = useCallback(() => {
        setBusy(true);
    }, []);

    const handleAccountConnected = useCallback(() => {
        setBusy(false);
        setShowAddAccount(false);
    }, []);

    const handleCommand = useCallback(async () => {
        if (busy) {
            return;
        }
        await beforeConnect?.();
        setShowAddAccount(true);
    }, [beforeConnect, busy]);

    const finish = useCallback(() => {
        onFinished();
    }, [onFinished]);

    const preventContinue =
        busy ||
        isConnecting ||
        !!newConnection ||
        (!allFinancialAccounts.length && !allowNoAccounts) ||
        hasAccountWithNoType;

    useKeyboardCommands({
        commands: [
            {
                key: "Enter",
                requiresCtrlOrMeta: false,
                callback: () => {
                    if (preventContinue) {
                        return;
                    }

                    finish();
                },
            },
        ],
    });

    return (
        <AccountsConnectionProvider
            showAccountTypeWarning
            defaultEntity={defaultEntity}
        >
            <CommandsProvider
                commands={[
                    {
                        key: "a",
                        callback: () => {
                            handleCommand();
                        },
                        requiresCtrlOrMeta: false,
                        preventDefault: !busy,
                    },
                ]}
            >
                <div
                    className={classNames(
                        animations.fadeIn1,
                        commonStyles.content780,
                    )}
                >
                    <h1
                        className={classNames(
                            commonStyles.title,
                            animations.fadeIn2,
                        )}
                    >
                        Connect your bank accounts and credit cards
                    </h1>

                    <PersonalDataAlert />
                    <section>
                        <AccountsConnectionOnboardingWidget
                            heading={<h5>Bank accounts</h5>}
                            emptyConnectPrompt={
                                <div className="d-flex gap-2 align-items-center">
                                    <PlusIcon /> Add bank accounts
                                    <KeyboardShortcut key="A" square>
                                        A
                                    </KeyboardShortcut>
                                </div>
                            }
                            connectPrompt={
                                <div className="d-flex gap-2 align-items-center">
                                    Add another account
                                    <KeyboardShortcut key="A" square>
                                        A
                                    </KeyboardShortcut>
                                </div>
                            }
                            accounts={checkingAccounts}
                            showAddAccount={showAddAccount}
                            beforeConnect={beforeConnect}
                            onAddAccount={handleAccountConnecting}
                            onAddAccountComplete={handleAccountConnected}
                            defaultEntity={defaultEntity}
                        />
                    </section>

                    <section className="mt-5">
                        <AccountsConnectionOnboardingWidget
                            heading={<h5>Credit cards</h5>}
                            emptyConnectPrompt={
                                <div className="d-flex gap-2 align-items-center">
                                    <PlusIcon /> Add credit cards
                                    <KeyboardShortcut square>
                                        A
                                    </KeyboardShortcut>
                                </div>
                            }
                            connectPrompt={
                                <div className="d-flex gap-2 align-items-center">
                                    Add another card
                                    <KeyboardShortcut square>
                                        A
                                    </KeyboardShortcut>
                                </div>
                            }
                            accounts={creditCards}
                            showAddAccount={showAddAccount}
                            beforeConnect={beforeConnect}
                            onAddAccount={handleAccountConnecting}
                            onAddAccountComplete={handleAccountConnected}
                            defaultEntity={defaultEntity}
                        />
                    </section>

                    <Footer onBack={onBack} onBackDisabled={busy}>
                        <Button
                            variant="secondary"
                            disabled={preventContinue}
                            onClick={finish}
                        >
                            Next
                            <KeyboardShortcut square>
                                <KeyboardReturnIcon />
                            </KeyboardShortcut>
                        </Button>
                    </Footer>
                </div>
            </CommandsProvider>
        </AccountsConnectionProvider>
    );
};
