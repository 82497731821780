import {
    CondOperator,
    CreateQueryParams,
    QueryJoin,
    RequestQueryBuilder,
} from "@dataui/crud-request";
import { QueryFilter } from "@dataui/crud-request/lib/types";
import { CancelTokenSource } from "axios";
import { LogStreamEvent } from "../common/types/logStreamEvent";
import { User } from "../common/types/user";
import { UpdateUserDto } from "../common/dto/user/update-user.dto";
import { Entity } from "../common/types/entity";
import { UpdateEntityDto } from "../common/dto/entity/update-entity.dto";
import { UpdateFinancialAccountDto } from "../common/dto/financialAccount/update-financial-account.dto";
import { FinancialAccountDto } from "../common/dto/financialAccount/financial-account.dto";
import { UserDto } from "../common/dto/user/user.dto";
import { FinancialAccount } from "../common/types/financialAccount";
import { backendClient, unwrapResponse } from "./backendClient";

export interface RestResponseWithPaging<T> {
    data: T[];
    count: number;
    total: number;
    page: number;
    pageCount: number;
}

export interface ParamsWithPaging extends CreateQueryParams {
    limit: number;
    page: number;
}

interface ResponseDtoWithDeserialization<T> {
    deserialize: (dto: any) => T;
}

interface QueryAsOwnerParams {
    owningId: number | string;
    alias: string;
    params?: CreateQueryParams | ParamsWithPaging;
    cancelToken?: CancelTokenSource;
}

export class RestClient<T, DTO = object> {
    constructor(
        private collection: string,
        private responseDto?: ResponseDtoWithDeserialization<T>,
    ) {}

    async fetch(
        id: string | number,
        cancelToken?: CancelTokenSource,
    ): Promise<T> {
        return unwrapResponse(
            await backendClient.get(`/rest/${this.collection}/${id}`, {
                cancelToken: cancelToken?.token,
            }),
        );
    }

    query(
        params: ParamsWithPaging,
        cancelToken?: CancelTokenSource,
    ): Promise<RestResponseWithPaging<T>>;
    query(
        params: CreateQueryParams,
        cancelToken?: CancelTokenSource,
    ): Promise<T[]>;
    async query(
        params: CreateQueryParams | ParamsWithPaging = {},
        cancelToken?: CancelTokenSource,
    ): Promise<T[] | RestResponseWithPaging<T>> {
        const query = RequestQueryBuilder.create(params).query();

        return unwrapResponse(
            await backendClient.get(`/rest/${this.collection}?${query}`, {
                cancelToken: cancelToken?.token,
            }),
        );
    }

    private async queryAsOwner({
        owningId,
        alias,
        params = {},
        cancelToken,
    }: QueryAsOwnerParams): Promise<T[] | RestResponseWithPaging<T>> {
        if (params.search) {
            throw new Error(
                "Can't use 'queryAsOwner' with 'search' param. Please add proper filters yourself",
            );
        }

        return await this.query(
            {
                ...params,
                join: [
                    ...((params.join ?? []) as QueryJoin[]),
                    { field: alias },
                ],
                filter: [
                    ...((params.filter ?? []) as QueryFilter[]),
                    {
                        field: `${alias}.id`,
                        operator: CondOperator.EQUALS,
                        value: owningId,
                    },
                ],
            },
            cancelToken,
        );
    }

    queryAsEntityOwner(
        entityId: number,
        params: ParamsWithPaging,
        cancelToken?: CancelTokenSource,
    ): Promise<RestResponseWithPaging<T>>;
    queryAsEntityOwner(
        entityId: number,
        params?: CreateQueryParams,
        cancelToken?: CancelTokenSource,
    ): Promise<T[]>;
    async queryAsEntityOwner(
        entityId: number,
        params: CreateQueryParams | ParamsWithPaging = {},
        cancelToken?: CancelTokenSource,
    ): Promise<T[] | RestResponseWithPaging<T>> {
        return await this.queryAsOwner({
            owningId: entityId,
            alias: "entity",
            params,
            cancelToken,
        });
    }

    queryAsWorkspaceAdmin(
        workspaceId: string,
        params: ParamsWithPaging,
        cancelToken?: CancelTokenSource,
    ): Promise<RestResponseWithPaging<T>>;
    queryAsWorkspaceAdmin(
        workspaceId: string,
        params?: CreateQueryParams,
        cancelToken?: CancelTokenSource,
    ): Promise<T[]>;
    async queryAsWorkspaceAdmin(
        workspaceId: string,
        params: CreateQueryParams | ParamsWithPaging = {},
        cancelToken?: CancelTokenSource,
    ): Promise<T[] | RestResponseWithPaging<T>> {
        return await this.queryAsOwner({
            owningId: workspaceId,
            alias: "workspace",
            params,
            cancelToken,
        });
    }

    queryAsOwningUser(
        userId: number,
        params: ParamsWithPaging,
        cancelToken?: CancelTokenSource,
    ): Promise<RestResponseWithPaging<T>>;
    queryAsOwningUser(
        userId: number,
        params?: CreateQueryParams,
        cancelToken?: CancelTokenSource,
    ): Promise<T[]>;
    async queryAsOwningUser(
        userId: number,
        params: CreateQueryParams | ParamsWithPaging = {},
        cancelToken?: CancelTokenSource,
    ): Promise<T[] | RestResponseWithPaging<T>> {
        return await this.queryAsOwner({
            owningId: userId,
            alias: "user",
            params,
            cancelToken,
        });
    }

    queryAsAdminUser(
        primaryAdminId: number,
        params: ParamsWithPaging,
        cancelToken?: CancelTokenSource,
    ): Promise<RestResponseWithPaging<T>>;
    queryAsAdminUser(
        primaryAdminId: number,
        params?: CreateQueryParams,
        cancelToken?: CancelTokenSource,
    ): Promise<T[]>;
    async queryAsAdminUser(
        primaryAdminId: number,
        params: CreateQueryParams | ParamsWithPaging = {},
        cancelToken?: CancelTokenSource,
    ): Promise<T[] | RestResponseWithPaging<T>> {
        return await this.queryAsOwner({
            owningId: primaryAdminId,
            alias: "primaryAdminUser",
            params,
            cancelToken,
        });
    }

    async delete(id: string | number): Promise<void> {
        return unwrapResponse(
            await backendClient.delete(`/rest/${this.collection}/${id}`),
        );
    }

    async create(data: DTO): Promise<T> {
        return unwrapResponse(
            await backendClient.post(`/rest/${this.collection}`, data),
        );
    }

    async update(id: string | number, data: DTO): Promise<T> {
        const response = unwrapResponse(
            await backendClient.patch(`/rest/${this.collection}/${id}`, data),
        );

        return this.responseDto
            ? this.responseDto.deserialize(response)
            : response;
    }
}

export const financialAccountsRest = new RestClient<
    FinancialAccount,
    UpdateFinancialAccountDto
>("financial-account", FinancialAccountDto);
export const logStreamRest = new RestClient<LogStreamEvent, object>(
    "log-stream-event",
);

export const userRest = new RestClient<User, UpdateUserDto>("user", UserDto);
export const entityRest = new RestClient<Entity, UpdateEntityDto>("entity");
