import React from "react";
import classNames from "classnames";
import { ChildrenProps } from "../../types";

export interface SettingsSectionProps extends ChildrenProps {
    heading?: string | React.ReactNode;
    subheading?: string | React.ReactNode;
    className?: string;
}

export const SettingsSection: React.FC<SettingsSectionProps> = ({
    heading,
    subheading,
    className,
    children,
}) => (
    <section className={classNames("settings__section", className)}>
        {heading && (
            <header className="settings__section__header">
                {typeof heading === "string" ? <h4>{heading}</h4> : heading}
            </header>
        )}
        {subheading && (
            <header className="settings__section__subheader">
                {subheading}
            </header>
        )}
        <main>{children}</main>
    </section>
);
