import classNames from "classnames";
import { capitalize } from "lodash";
import { Col, Row } from "react-bootstrap";
import { EagerLoaded } from "../../common/types/base/orm";
import { Invoice, InvoiceSource } from "../../common/types/invoice";
import { formatDateForTransaction } from "../../helpers/date";
import {
    getInvoiceStatusVariant,
    getStripeDashboardUrlForInvoice,
} from "../../helpers/invoice";
import { ArrowCornerIcon, RemoveIcon } from "../../icons";
import { Amount } from "../general/Amount/Amount";
import { Card } from "../general/Card/Card";
import { Tag } from "../general/Tag/Tag";
import { Button } from "../general/Button/Button";
import styles from "./InvoiceDetails.module.scss";

interface InvoiceLineItemProps {
    label: string | null;
    amount: number;
    showNegative?: boolean;
}

const InvoiceLineItem: React.FC<InvoiceLineItemProps> = ({
    label,
    amount,
    showNegative,
}) => (
    <Row>
        <Col className={styles.dataLabel}>{label}</Col>
        <Col className={styles.dataValue}>
            <Amount
                amount={amount}
                separateCurrencySign
                showNegative={showNegative}
            />
        </Col>
    </Row>
);

interface InvoiceDetailsProps {
    invoice: EagerLoaded<Invoice, "lines">;
    setInvoice: (invoice?: Invoice) => void;
}

export const InvoiceDetails: React.FC<InvoiceDetailsProps> = ({
    invoice,
    setInvoice,
}) => {
    const showSubtotal = invoice.subtotal !== invoice.total;
    const showTax = invoice.tax > 0;
    const showDiscount = invoice.discount > 0;
    const showExtras = showTax || showDiscount || showSubtotal;

    return (
        <Card className={classNames(styles.card, "p-0")}>
            <section className={styles.section}>
                <div className={styles.header}>
                    <Tag
                        size="sm"
                        outline
                        variant={getInvoiceStatusVariant(invoice.status)}
                    >
                        {capitalize(invoice.status)}
                    </Tag>
                    <Button
                        variant="tertiary"
                        onClick={() => setInvoice(undefined)}
                        data-testid="invoice-details-close"
                        icon
                    >
                        <RemoveIcon />
                    </Button>
                </div>

                <div className="d-flex flex-column">
                    {invoice.counterparty && (
                        <div className={styles.counterparty}>
                            <span className={styles.counterpartyName}>
                                {invoice.counterparty.name}
                            </span>
                            <span className={styles.counterpartyEmail}>
                                {invoice.counterparty.email}
                            </span>
                        </div>
                    )}

                    <Amount
                        className={styles.amount}
                        amount={invoice.total}
                        separateCurrencySign
                        showNegative={false}
                    />

                    {invoice.paidAt && (
                        <div className={styles.paidOn}>
                            Paid on{" "}
                            {formatDateForTransaction(new Date(invoice.paidAt))}
                        </div>
                    )}
                </div>

                {invoice.source === InvoiceSource.STRIPE && (
                    <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => {
                            window.open(
                                getStripeDashboardUrlForInvoice(invoice),
                                "_blank",
                            );
                        }}
                    >
                        View in Stripe
                        <ArrowCornerIcon />
                    </Button>
                )}
            </section>

            <section className={classNames(styles.section, styles.dataSection)}>
                <Row>
                    <Col className={styles.dataLabel}>Due Date</Col>
                    <Col className={styles.dataValue}>
                        {invoice.due
                            ? formatDateForTransaction(new Date(invoice.due))
                            : "-"}
                    </Col>
                </Row>
                <Row>
                    <Col className={styles.dataLabel}>Date</Col>
                    <Col className={styles.dataValue}>
                        {formatDateForTransaction(new Date(invoice.createdAt))}
                    </Col>
                </Row>
            </section>

            <section
                className={classNames(styles.section, styles.lineItemsSection)}
            >
                <span className={styles.lineItemsTitle}>Invoice Details</span>
                {invoice.lines.map((line) => (
                    <InvoiceLineItem
                        key={line.id}
                        label={line.description}
                        amount={line.amount}
                        showNegative={showSubtotal}
                    />
                ))}
                {showExtras && (
                    <div className={styles.lineItemsSeparator}></div>
                )}
                {showSubtotal && (
                    <InvoiceLineItem
                        label="Subtotal"
                        amount={invoice.subtotal}
                        showNegative={false}
                    />
                )}
                {invoice.discount > 0 && (
                    <InvoiceLineItem
                        label="Discount"
                        amount={-invoice.discount}
                        showNegative={true}
                    />
                )}
                {invoice.tax > 0 && (
                    <InvoiceLineItem
                        label="Tax"
                        amount={invoice.tax}
                        showNegative={false}
                    />
                )}
                <div className={styles.lineItemsSeparator}></div>
                <Row className={styles.lineItemsTotal}>
                    <Col className={styles.dataLabel}>Total</Col>
                    <Col className={styles.dataValue}>
                        <Amount
                            amount={invoice.total}
                            separateCurrencySign
                            showNegative={false}
                        />
                    </Col>
                </Row>
            </section>
        </Card>
    );
};
