import { Counterparty } from "../types/counterparty";
import { TransactionListItemDto } from "../types/transaction";
import { Taxonomy } from "../categories";

export enum CounterpartyDirection {
    CUSTOMER = "customer",
    VENDOR = "vendor",
    NEUTRAL = "neutral",
}

export enum RegisteredCounterpartyIncludeScope {
    ALL = "all",
    NONE = "none",
    USED = "used",
}

export enum LocalCounterpartyIncludeScope {
    ALL = "all",
    NONE = "none",
    FROM_WORKSPACE = "from_workspace",
}

export const CounterpartyDirectionLabels: Record<
    CounterpartyDirection,
    string
> = {
    [CounterpartyDirection.CUSTOMER]: "Customer",
    [CounterpartyDirection.VENDOR]: "Vendor",
    [CounterpartyDirection.NEUTRAL]: "Description",
};

export const GENERAL_COUNTERPARTY_LABEL = "Vendor/Customer";

export const TAXONOMY_TO_COUNTERPARTY_DIRECTION: Record<
    Taxonomy,
    CounterpartyDirection
> = {
    [Taxonomy.income]: CounterpartyDirection.CUSTOMER,
    [Taxonomy.interest_income]: CounterpartyDirection.NEUTRAL,
    [Taxonomy.other_income]: CounterpartyDirection.CUSTOMER,
    [Taxonomy.advertising_marketing]: CounterpartyDirection.VENDOR,
    [Taxonomy.auto_vehicle]: CounterpartyDirection.VENDOR,
    [Taxonomy.auto_insurance]: CounterpartyDirection.VENDOR,
    [Taxonomy.auto_payment]: CounterpartyDirection.VENDOR,
    [Taxonomy.parking_tolls]: CounterpartyDirection.VENDOR,
    [Taxonomy.communications]: CounterpartyDirection.VENDOR,
    [Taxonomy.learning_development]: CounterpartyDirection.VENDOR,
    [Taxonomy.entertainment]: CounterpartyDirection.VENDOR,
    [Taxonomy.fees]: CounterpartyDirection.VENDOR,
    [Taxonomy.merchant_bank_fees]: CounterpartyDirection.VENDOR,
    [Taxonomy.licenses_permits]: CounterpartyDirection.VENDOR,
    [Taxonomy.penalties_fines]: CounterpartyDirection.VENDOR,
    [Taxonomy.insurance]: CounterpartyDirection.VENDOR,
    [Taxonomy.health_insurance]: CounterpartyDirection.VENDOR,
    [Taxonomy.life_insurance]: CounterpartyDirection.VENDOR,
    [Taxonomy.legal_professional]: CounterpartyDirection.VENDOR,
    [Taxonomy.meals]: CounterpartyDirection.VENDOR,
    [Taxonomy.payroll]: CounterpartyDirection.VENDOR,
    [Taxonomy.contractors]: CounterpartyDirection.VENDOR,
    [Taxonomy.salaries_wages]: CounterpartyDirection.VENDOR,
    [Taxonomy.payroll_taxes]: CounterpartyDirection.VENDOR,
    [Taxonomy.rentals_leases]: CounterpartyDirection.VENDOR,
    [Taxonomy.repairs_maintenance]: CounterpartyDirection.VENDOR,
    [Taxonomy.software_subscriptions]: CounterpartyDirection.VENDOR,
    [Taxonomy.taxes]: CounterpartyDirection.VENDOR,
    [Taxonomy.federal_taxes]: CounterpartyDirection.VENDOR,
    [Taxonomy.local_taxes]: CounterpartyDirection.VENDOR,
    [Taxonomy.property_taxes]: CounterpartyDirection.VENDOR,
    [Taxonomy.sales_taxes]: CounterpartyDirection.VENDOR,
    [Taxonomy.travel]: CounterpartyDirection.VENDOR,
    [Taxonomy.utilities]: CounterpartyDirection.VENDOR,
    [Taxonomy.supplies_equipment]: CounterpartyDirection.VENDOR,
    [Taxonomy.office_supplies]: CounterpartyDirection.VENDOR,
    [Taxonomy.furniture_fixtures]: CounterpartyDirection.VENDOR,
    [Taxonomy.props_production]: CounterpartyDirection.VENDOR,
    [Taxonomy.credit_card_loans]: CounterpartyDirection.VENDOR,
    [Taxonomy.credit_card_payment]: CounterpartyDirection.NEUTRAL,
    [Taxonomy.interest_paid]: CounterpartyDirection.VENDOR,
    [Taxonomy.loan_payment]: CounterpartyDirection.VENDOR,
    [Taxonomy.transfer]: CounterpartyDirection.NEUTRAL,
    [Taxonomy.contributions_distributions]: CounterpartyDirection.NEUTRAL,
    [Taxonomy.real_estate]: CounterpartyDirection.VENDOR,
    [Taxonomy.improvements]: CounterpartyDirection.VENDOR,
    [Taxonomy.property_insurance]: CounterpartyDirection.VENDOR,
    [Taxonomy.management_fees]: CounterpartyDirection.VENDOR,
    [Taxonomy.health_dental]: CounterpartyDirection.VENDOR,
    [Taxonomy.donations]: CounterpartyDirection.VENDOR,
    [Taxonomy.uncategorized]: CounterpartyDirection.NEUTRAL,
    [Taxonomy.retirement]: CounterpartyDirection.VENDOR,
    [Taxonomy.personal]: CounterpartyDirection.NEUTRAL,
    [Taxonomy.home_office]: CounterpartyDirection.VENDOR,
    [Taxonomy.security_deposit]: CounterpartyDirection.VENDOR,
    [Taxonomy.principal_paid]: CounterpartyDirection.VENDOR,
    [Taxonomy.cogs]: CounterpartyDirection.VENDOR,
    [Taxonomy.direct_labor]: CounterpartyDirection.VENDOR,
    [Taxonomy.goods_materials]: CounterpartyDirection.VENDOR,
    [Taxonomy.other_cogs]: CounterpartyDirection.VENDOR,
    [Taxonomy.business_office]: CounterpartyDirection.VENDOR,
    [Taxonomy.mortgage_payment]: CounterpartyDirection.VENDOR,
    [Taxonomy.equipment_rentals]: CounterpartyDirection.VENDOR,
    [Taxonomy.investment]: CounterpartyDirection.VENDOR,
};

const allTaxonomies = Object.values(Taxonomy);
const vendorTaxonomies = allTaxonomies.filter(
    (category) =>
        TAXONOMY_TO_COUNTERPARTY_DIRECTION[category] ===
        CounterpartyDirection.VENDOR,
);
const customerTaxonomies = allTaxonomies.filter(
    (category) =>
        TAXONOMY_TO_COUNTERPARTY_DIRECTION[category] ===
        CounterpartyDirection.CUSTOMER,
);
const neutralTaxonomies = allTaxonomies.filter(
    (category) =>
        TAXONOMY_TO_COUNTERPARTY_DIRECTION[category] ===
        CounterpartyDirection.NEUTRAL,
);

export const COUNTERPARTY_TAXONOMY_BY_DIRECTION: Record<
    CounterpartyDirection,
    Taxonomy[]
> = {
    vendor: vendorTaxonomies,
    customer: customerTaxonomies,
    neutral: neutralTaxonomies,
};

export function getCounterpartyDirectionForTaxonomy(taxonomy?: Taxonomy) {
    return TAXONOMY_TO_COUNTERPARTY_DIRECTION[
        taxonomy ?? Taxonomy.uncategorized
    ];
}

export function getCounterpartyDirectionLabelForTransaction(
    transaction: TransactionListItemDto,
): string {
    return CounterpartyDirectionLabels[
        getCounterpartyDirectionForTaxonomy(transaction.category?.taxonomy)
    ];
}

export function isRegisteredCounterparty(counterparty: Counterparty) {
    return !counterparty.workspaceId;
}

export function isVerifiedCounterparty(counterparty: Counterparty) {
    return Boolean(
        isRegisteredCounterparty(counterparty) || counterparty.updatedByUserId,
    );
}
