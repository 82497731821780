import { useState } from "react";
import classNames from "classnames";
import { Class } from "../../../../common/types/class";
import { CollapseIcon } from "../../../../icons";
import styles from "../ClassesSettings.module.scss";
import { PlainModal, StandardModalHeader } from "../../../general/Modal/Modal";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import { useCreateClassMutation } from "../../../../api/class.api";
import { FormRowRaw } from "../../../forms/FormRowRaw";
import { getErrorForAddClass } from "../utils";
import { Button } from "../../../general/Button/Button";
import { TablePageHeader } from "../../../pageHeader/TablePageHeader";

interface Props {
    collapsedClasses: Set<string>;
    setCollapsedClasses: (classes: Set<string>) => void;
    classes: Class[];
    search: string;
    setSearch: (search: string) => void;
    showHeaderTitle: boolean;
}

export function ClassesSettingsHeader({
    collapsedClasses,
    setCollapsedClasses,
    classes,
    search,
    setSearch,
    showHeaderTitle,
}: Props) {
    const onCollapseButtonClick = () => {
        if (collapsedClasses.size === 0) {
            setCollapsedClasses(
                new Set(
                    classes
                        .filter((c) => c.parentClassId === null)
                        .map((c) => c.id),
                ),
            );
        } else {
            setCollapsedClasses(new Set());
        }
    };

    const addClass = useCreateClassMutation();
    const [showAddClassModal, setShowAddClassModal] = useState(false);
    const [createClassName, setCreateClassName] = useState("");
    const [error, setError] = useState<string | null>(null);

    const onChange = (value: string) => {
        setCreateClassName(value);
        setError(getErrorForAddClass(classes, null, value));
    };

    return (
        <>
            {showHeaderTitle && (
                <header className="settings__header">
                    <h3>Classes</h3>
                </header>
            )}

            <TablePageHeader
                search={search}
                onSearchChange={(value) => {
                    setSearch(value ?? "");
                }}
            >
                <div className={styles.flexBox}>
                    <Button
                        variant="secondary"
                        icon
                        onClick={onCollapseButtonClick}
                    >
                        <CollapseIcon />
                    </Button>
                    <Button
                        className={styles.addClassButton}
                        variant="secondary"
                        onClick={() => setShowAddClassModal(true)}
                    >
                        Add class
                    </Button>
                </div>
            </TablePageHeader>

            <PlainModal
                show={showAddClassModal}
                onHide={() => setShowAddClassModal(false)}
                title="Add class"
            >
                <StandardModalHeader>Add class</StandardModalHeader>
                <FormRowRaw
                    autoFocus
                    label="Class name"
                    value={createClassName}
                    size="sm"
                    showErrorMessage={true}
                    onChange={onChange}
                    error={error}
                />

                <footer
                    className={classNames("d-flex justify-content-end", {
                        [styles.buttonFooter]: true,
                    })}
                >
                    <Button
                        variant="secondary"
                        onClick={() => setShowAddClassModal(false)}
                        className="mr-2"
                        size="lg"
                        data-testid="confirmation-modal-no-button"
                    >
                        Cancel
                    </Button>

                    <ButtonWithLoader
                        variant="default"
                        loading={addClass.isPending}
                        onClick={async () => {
                            await addClass.mutateAsync({
                                label: createClassName,
                                parentClassId: null,
                            });
                            setShowAddClassModal(false);
                        }}
                        size="lg"
                        data-testid="confirmation-modal-yes-button"
                        disabled={!!error}
                    >
                        Create
                    </ButtonWithLoader>
                </footer>
            </PlainModal>
        </>
    );
}
