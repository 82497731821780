import React from "react";
import { ModalComponentWithResultProps } from "../../helpers/modal";
import { Entity } from "../../common/types/entity";
import { getAccountNameWithLast4 } from "../../common/helpers/financialAccount";
import { FinancialAccount } from "../../common/types/financialAccount";
import { Button } from "../general/Button/Button";
import { StandardModalBody, StandardModalHeader } from "../general/Modal/Modal";

interface Props extends ModalComponentWithResultProps<boolean> {
    account: FinancialAccount;
    entity: Entity;
}

export const ConfirmAccountTypeChangeModal: React.FC<Props> = ({
    account,
    entity,
    close,
}) => (
    <>
        <StandardModalHeader>
            Please change your account type
        </StandardModalHeader>
        <StandardModalBody>
            <p className="text-prominent mb-5">
                <strong>{getAccountNameWithLast4(account)}</strong> is a{" "}
                {account.isBusiness ? "business" : "personal"} account. To move
                it to your{" "}
                {entity.isPersonal ? (
                    "personal accounts"
                ) : (
                    <>
                        business <strong>{entity.name}</strong>
                    </>
                )}
                , you'd also need to change the account type to{" "}
                {entity.isPersonal ? "Personal" : "Business"}.
            </p>

            <footer className="d-flex justify-content-between">
                <Button
                    variant="secondary"
                    size="lg"
                    onClick={() => close(false)}
                >
                    Cancel
                </Button>
                <Button variant="default" size="lg" onClick={() => close(true)}>
                    Change to {entity.isPersonal ? "Personal" : "Business"} and
                    move
                </Button>
            </footer>
        </StandardModalBody>
    </>
);
