import React, { useCallback, useState } from "react";
import { Form } from "react-bootstrap";
import { Counterparty } from "../../../common/types/counterparty";
import { ArrowCornerIcon } from "../../../icons";
import { useCounterpartyTransactionsLink } from "../../../hooks/useCounterpartyTransactionsOpener";
import {
    CounterpartyDirection,
    CounterpartyDirectionLabels,
    GENERAL_COUNTERPARTY_LABEL,
} from "../../../common/helpers/counterparties";
import { FormCheckbox } from "../../forms/FormCheckbox/FormCheckbox";
import { ModalComponentProps } from "../../../helpers/modal";
import { CounterpartySelect } from "../../counterparty/CounterpartySelect";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import {
    useCounterpartyMergingMutation,
    useCounterpartyRemovalMutation,
} from "../../../api/counterparty.api";
import { Button } from "../../general/Button/Button";
import {
    StandardModalBody,
    StandardModalHeader,
} from "../../general/Modal/Modal";
import styles from "./MergeOrRemoveCounterpartyModal.module.scss";

interface Props extends ModalComponentProps {
    counterparty: Counterparty;
    direction?: CounterpartyDirection;
}

export const MergeOrRemoveCounterpartyModal: React.FC<Props> = ({
    counterparty,
    direction,
    close,
}) => {
    const showTransactionsLink = useCounterpartyTransactionsLink(counterparty, {
        includeDateFilter: false,
    });
    const directionLabel = direction
        ? CounterpartyDirectionLabels[direction]
        : GENERAL_COUNTERPARTY_LABEL;
    const [action, setAction] = useState<"merge" | "remove">();
    const [counterpartyToMergeWith, setCounterpartyToMergeWith] =
        useState<Counterparty>();
    const submitAllowed = Boolean(
        (counterpartyToMergeWith &&
            counterpartyToMergeWith.id !== counterparty.id) ||
            action === "remove",
    );

    const removal = useCounterpartyRemovalMutation(counterparty);
    const merging = useCounterpartyMergingMutation();

    const onSubmit = useCallback(async () => {
        if (action === "remove") {
            await removal.mutateAsync(true);
            close();
        } else if (action === "merge" && counterpartyToMergeWith) {
            await merging.mutateAsync({
                counterpartyToMergeId: counterparty.id,
                counterpartyToKeepId: counterpartyToMergeWith.id,
            });
            close();
        }
    }, [
        removal,
        merging,
        counterpartyToMergeWith,
        counterparty.id,
        close,
        action,
    ]);

    return (
        <>
            <StandardModalHeader className="p-0" />
            <StandardModalBody>
                <p>
                    <strong className="font-weight-medium">
                        {counterparty.name}
                    </strong>{" "}
                    is assigned to{" "}
                    <a href={showTransactionsLink}>
                        transactions
                        <ArrowCornerIcon className="ml-1" />
                    </a>
                    . Would you like to re-assign these transactions to a
                    different {directionLabel.toLowerCase()}?
                </p>

                <Form>
                    <FormCheckbox
                        value="remove"
                        type="radio"
                        name="action"
                        label={`No, leave transactions without ${directionLabel.toLowerCase()}`}
                        isChecked={action === "remove"}
                        handleChange={() => setAction("remove")}
                        small
                        className={styles.radio}
                    />
                    <FormCheckbox
                        value="merge"
                        type="radio"
                        name="action"
                        label={`Yes, select different ${directionLabel.toLowerCase()}`}
                        isChecked={action === "merge"}
                        handleChange={() => setAction("merge")}
                        small
                        className={styles.radio}
                    />

                    {action === "merge" && (
                        <CounterpartySelect
                            selectedCounterparty={counterpartyToMergeWith}
                            onChange={setCounterpartyToMergeWith}
                            dropdownKey="counterpartyToMergeWith"
                            size="sm"
                            counterpartyDirection={direction}
                        />
                    )}

                    {counterpartyToMergeWith?.id === counterparty.id && (
                        <p className="text-danger">
                            Please select a different{" "}
                            {directionLabel.toLowerCase()}.
                        </p>
                    )}

                    <footer className="d-flex justify-content-between mt-5">
                        <Button variant="secondary" onClick={close}>
                            Cancel
                        </Button>
                        <ButtonWithLoader
                            loading={removal.isPending || merging.isPending}
                            variant="default"
                            disabled={!submitAllowed}
                            onClick={onSubmit}
                        >
                            Delete
                        </ButtonWithLoader>
                    </footer>
                </Form>
            </StandardModalBody>
        </>
    );
};
