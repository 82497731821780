import React, {
    createContext,
    FC,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";
import {
    PlaidConnection,
    PlaidConnectionWithAccounts,
} from "../../common/types/plaidConnection";
import {
    FinancialConnection,
    FinancialConnectionWithAccounts,
} from "../../common/types/financialConnection";
import { InvalidPlaidAccount } from "../../common/dto/plaid/plaid-common";
import { noop } from "../../helpers/general";
import { FinancialAccount } from "../../common/types/financialAccount";
import { ChildrenProps } from "../../types";
import { IntegrationConnection } from "../../common/types/integrationConnection";

interface TConnectAccountState {
    connection?: PlaidConnection | FinancialConnection;
    integrationConnection?: IntegrationConnection;
    connectionSaved?: boolean;
    isConnecting: boolean;
    foreign?: InvalidPlaidAccount[];
}

interface NotConnectingAccount
    extends Omit<TConnectAccountState, "connectionSaved" | "foreign"> {
    isConnecting: false;
}

interface SavingConnection
    extends Omit<TConnectAccountState, "connectionSaved" | "foreign"> {
    isConnecting: true;
}

interface ConnectionSaved extends TConnectAccountState {
    connection: PlaidConnectionWithAccounts | FinancialConnectionWithAccounts;
    connectionSaved: boolean;
    isConnecting: false;
    foreign?: InvalidPlaidAccount[];
}

interface IntegrationConnectionSaved extends TConnectAccountState {
    integrationConnection: IntegrationConnection;
    connectionSaved: boolean;
    isConnecting: false;
    foreign?: InvalidPlaidAccount[];
}

export type ConnectAccountState =
    | NotConnectingAccount
    | SavingConnection
    | ConnectionSaved
    | IntegrationConnectionSaved;

interface ConnectAccountContextValue {
    state: ConnectAccountState;
    updateConnectAccountState(newState: ConnectAccountState): void;
    updateFinancialAccount(account: FinancialAccount): void;
}

export const ConnectAccountContext = createContext<ConnectAccountContextValue>({
    state: {
        isConnecting: false,
    },
    updateConnectAccountState: noop,
    updateFinancialAccount: noop,
});

export const ConnectAccountStateProvider: FC<ChildrenProps> = ({
    children,
}) => {
    const [currentState, setCurrentState] = useState<ConnectAccountState>({
        isConnecting: false,
    });

    const updateFinancialAccount = useCallback(
        (account: FinancialAccount) =>
            setCurrentState((state) => {
                if (state.connection?.accounts) {
                    return {
                        ...state,
                        connection: {
                            ...state.connection,
                            accounts: state.connection.accounts.map((ia) => ({
                                ...ia,
                                financialAccount:
                                    ia.financialAccount.id === account.id
                                        ? account
                                        : ia.financialAccount,
                            })),
                        },
                    };
                }

                if (state.integrationConnection?.integrationAccounts) {
                    return {
                        ...state,
                        integrationConnection: {
                            ...state.integrationConnection,
                            integrationAccounts:
                                state.integrationConnection.integrationAccounts.map(
                                    (ia) => ({
                                        ...ia,
                                        financialAccount:
                                            ia.financialAccount.id ===
                                            account.id
                                                ? account
                                                : ia.financialAccount,
                                    }),
                                ),
                        },
                    };
                }

                return state;
            }),
        [],
    );

    const value = useMemo(
        () => ({
            state: currentState,
            updateConnectAccountState: (newState: ConnectAccountState) =>
                setCurrentState(newState),
            updateFinancialAccount,
        }),
        [currentState, updateFinancialAccount],
    );

    return (
        <ConnectAccountContext.Provider value={value}>
            {children}
        </ConnectAccountContext.Provider>
    );
};

export function useConnectAccount() {
    const context = useContext(ConnectAccountContext);

    if (!context) {
        throw new Error(
            "useConnectAccount must be used within a ConnectAccountProvider",
        );
    }

    return context;
}
