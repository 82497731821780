import { FC } from "react";
import { Command } from "../../vendor/cmdk/cmdk";
import plaidLogo from "../../static/images/icons/plaid.svg";
import { ConnectPlaid } from "../plaid/ConnectPlaid/ConnectPlaid";
import { UniversalImportTrigger } from "../manualImport/UniversalImportTrigger";
import { UploadIcon } from "../../icons";
import { useCommands } from "./useCommands";
import styles from "./Commands.module.scss";
import { CommandItem } from "./CommandItem";
import { CommandsListProps } from "./lib";

export const StaticAccountsCommandsList: FC<CommandsListProps> = ({
    options,
}) => {
    const { close: closeCommands } = useCommands();

    return (
        <Command.Group heading="" cmdk-static-group="">
            <ConnectPlaid
                onConnected={closeCommands}
                defaultEntity={options?.defaultEntity}
            >
                {({ connecting, initializeConnection }) => (
                    <CommandItem
                        onSelect={initializeConnection}
                        disabled={connecting}
                        keywords={["static_score"]}
                    >
                        <img
                            className={styles.icon}
                            src={plaidLogo}
                            alt="Plaid"
                        />{" "}
                        Add other bank institutions with Plaid
                    </CommandItem>
                )}
            </ConnectPlaid>

            <UniversalImportTrigger
                onCreated={closeCommands}
                defaultEntity={options?.defaultEntity}
            >
                {({ connecting, initializeConnection }) => (
                    <CommandItem
                        onSelect={initializeConnection}
                        disabled={connecting}
                        keywords={["static_score"]}
                    >
                        <span className={styles.customIcon}>
                            <UploadIcon />
                        </span>
                        <span>
                            Import Transactions{" "}
                            <span className={styles.info}>
                                (.csv, .pdf, .xlsx)
                            </span>
                        </span>
                    </CommandItem>
                )}
            </UniversalImportTrigger>
        </Command.Group>
    );
};
