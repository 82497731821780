import { useField } from "formik";
import { useMemo } from "react";
import { isArray } from "lodash";
import { useEntities } from "../../../../hooks/useEntities";
import {
    TransactionRuleConditionFormSchema,
    FormRuleConditionType,
} from "./constants";

export function useEntitiesFromFormCondition() {
    const entities = useEntities();
    const [entityField] =
        useField<TransactionRuleConditionFormSchema>("conditions.0");
    const entityCondition = entityField.value;

    return useMemo(() => {
        const value = entityCondition.entityValue;
        if (
            entityCondition.conditionType !== FormRuleConditionType.ENTITY ||
            !isArray(value)
        ) {
            return entities;
        }

        return entities.filter((e) => value.includes(e.id));
    }, [entityCondition, entities]);
}
