import React, { useMemo } from "react";
import { Entity } from "../../common/types/entity";
import { useEntitiesContexts } from "../../hooks/useEntitiesContexts";
import { ChildrenProps } from "../../types";
import { EntityContext } from "./entity.context";

interface Props extends ChildrenProps {
    entity: Entity;
}

export const EntityProvider: React.FC<Props> = ({ entity, children }) => {
    const entities = useEntitiesContexts();
    const context = useMemo(
        () =>
            entities
                ? {
                      entity,
                      role: entities.find((e) => e.entity.id === entity.id)
                          ?.role,
                  }
                : undefined,
        [entity, entities],
    );

    return context ? (
        <EntityContext.Provider value={context}>
            {children}
        </EntityContext.Provider>
    ) : null;
};
