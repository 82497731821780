import { SubscriptionType } from "../common/types/billing";
import { PREMIUM_FEATURES } from "../common/flatRateBilling";
import { UserType } from "../common/types/user";
import { useUser } from "./useUser";
import { useBillingStatus } from "./useBillingStatus";

export function useAllowFeature(
    feature: PREMIUM_FEATURES,
    disallowTrials?: boolean,
) {
    const user = useUser();
    const { subscriptionType, isTrialing, currentPlan, plans } =
        useBillingStatus();

    if (subscriptionType !== SubscriptionType.FLAT_RATE) {
        return true; // by default, features are available to subscribed users on old plans
    }

    if (user.type !== UserType.REGULAR) {
        return true; // features are available for support and demo users
    }

    if (disallowTrials && isTrialing) {
        return false;
    }

    const activePlan = plans.find((plan) => plan.plan === currentPlan);

    return Boolean(activePlan?.features.includes(feature));
}
