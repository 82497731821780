import React from "react";
import { GraphIcon } from "../../../../../icons";
import { ActionCard, ActionCardProps } from "../../../../actions/ActionCard";
import { ActionCardCompleted } from "../../../../actions/ActionCardCompleted";
import { ActionCardSection } from "../../../../actions/ActionCardSection";
import { ActionCloseButton } from "../../../../actions/ActionCloseButton";
import { ActionConfirmButton } from "../../../../actions/ActionConfirmButton";
import { DemoActionProps } from "../lib";
import { ActionTransactionsTable } from "./ActionTransactionsTable";

export const CategorizationSummaryActionCard: React.FC<DemoActionProps> = ({
    isCompleted,
    isSkipped,
    onConfirm,
    ...rest
}) => {
    const firstSection: ActionCardProps["firstSection"] = (
        inOpened,
        closeCard,
    ) => {
        if (isCompleted) {
            return (
                <ActionCardCompleted header="8 minutes of life saved from categorizing">
                    <ActionCloseButton onClick={closeCard}>
                        Next
                    </ActionCloseButton>
                </ActionCardCompleted>
            );
        }

        return (
            <ActionCardSection
                inOpened={inOpened}
                header="24 transactions categorized today"
                icon={<GraphIcon />}
                isTitle
                ctas={
                    <ActionConfirmButton onClick={() => onConfirm?.()}>
                        Next
                    </ActionConfirmButton>
                }
            />
        );
    };

    const otherSections: ActionCardProps["otherSections"] = (inOpened) => (
        <ActionCardSection inOpened={inOpened}>
            <ActionTransactionsTable />
        </ActionCardSection>
    );

    return (
        <ActionCard
            firstSection={firstSection}
            otherSections={otherSections}
            isCompleted={isCompleted}
            isSkipped={isSkipped}
            {...rest}
        />
    );
};
