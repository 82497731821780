import React, { useEffect, useRef } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { ProfitLossReport } from "../../reports/types";
import { StickyDataTable } from "../../general/DataTable/StickyDataTable";
import { DataTableHeaderCell } from "../../general/DataTable/DataTableHeaderCell";
import { ProfitLossTableHeaderCell } from "./ProfitLossTableHeaderCell";
import { ProfitLossSectionsOrder } from "./constants";
import { ProfitLossTableSection } from "./ProfitLossTableSection";

interface Props {
    report: ProfitLossReport;
}

export const ProfitLossTable: React.FC<Props> = ({ report }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const scrollbarsRef = useRef<Scrollbars>(null);

    useEffect(() => {
        window.setTimeout(() => {
            scrollbarsRef.current?.scrollToRight();
        }, 0);
    }, [report]);

    return (
        <div className="data-table" ref={containerRef}>
            <StickyDataTable
                containerRef={containerRef}
                ref={scrollbarsRef}
                stickyLeft
                stickyRight
            >
                <table className="data-table__table data-table__table--fixed-layout">
                    <thead>
                        <tr>
                            <DataTableHeaderCell isTitle />
                            {report.columnLabels.map((label) => (
                                <ProfitLossTableHeaderCell
                                    label={label}
                                    key={label}
                                />
                            ))}
                            <DataTableHeaderCell>Total</DataTableHeaderCell>
                        </tr>
                    </thead>
                    <tbody>
                        {ProfitLossSectionsOrder.map((section) => (
                            <ProfitLossTableSection
                                report={report}
                                sectionId={section}
                                sectionData={report.sections[section]}
                                key={section}
                                columns={report.columnLabels}
                            />
                        ))}
                    </tbody>
                </table>
            </StickyDataTable>
        </div>
    );
};
