import React from "react";
import { FinancialDocument } from "../../../../common/types/financialDocument";
import { Button } from "../../../general/Button/Button";
import {
    FinancialDocumentDetailsView,
    useFinancialDocumentDetailsView,
} from "../useFinancialDocumentDetailsView";
import { FinancialDocumentNoMatches } from "./FinancialDocumentNoMatches";
import styles from "./FinancialDocumentMatches.module.scss";
import { FinancialDocumentMatchesList } from "./FinancialDocumentMatchesList";

interface FinancialDocumentMatchesProps {
    financialDocument: FinancialDocument;
}

export const FinancialDocumentMatches: React.FC<
    FinancialDocumentMatchesProps
> = ({ financialDocument }) => {
    const { go } = useFinancialDocumentDetailsView();

    return (
        <section className={styles.container}>
            {financialDocument.transactionMatches.length > 0 ? (
                <>
                    <FinancialDocumentMatchesList
                        financialDocument={financialDocument}
                    />
                    <footer className={styles.section}>
                        <Button
                            variant="tertiary"
                            size="sm"
                            className="p-0"
                            onClick={() =>
                                go(FinancialDocumentDetailsView.MATCHING)
                            }
                            data-testid="financial-document-matches-add-transaction"
                        >
                            Add another transaction
                        </Button>
                    </footer>
                </>
            ) : (
                <FinancialDocumentNoMatches />
            )}
        </section>
    );
};
