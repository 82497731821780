import React from "react";

export interface NoteFilterLabelProps {
    filters: {
        hasNote?: boolean;
        noNote?: boolean;
        requiresNote?: boolean;
    };
}

export const NoteFilterLabel: React.FC<NoteFilterLabelProps> = ({
    filters: { hasNote, noNote, requiresNote },
}) => {
    if ([hasNote, noNote, requiresNote].filter((value) => !!value).length > 1) {
        return <>Note</>;
    }

    if (hasNote) {
        return <>Has note</>;
    }

    if (noNote) {
        return <>No note</>;
    }

    if (requiresNote) {
        return <>Requires note</>;
    }
};
