import { STATE_CODES } from "./states";
import { FinancialAccountType } from "./types/financialAccount";
import { ConnectionProviderType } from "./types/financialConnection";
import { InvitedUserRole } from "./types/inviteCode";
import { TransactionClassification } from "./types/transaction";
import { UserRole } from "./types/userEntityAssignment";
import { UserWorkspaceRole } from "./userWorkspaceRoles";

export const TRANSACTION_STATUS_LABELS = {
    pending: "Pending",
    completed: "Success",
    failed: "Failed",
};

export const KICK_ADMIN_OPS_DESCRIPTION = "Admin <admin@kick.co>";

export const KICK_TRANSACTION_DESCRIPTION = "Kick";

export const PHONE_NUMBER_PREFIX = "+1";

export const FINANCIAL_ACCOUNT_TYPE_LABEL: Record<
    FinancialAccountType,
    string
> = {
    [FinancialAccountType.CHECKING]: "Checking",
    [FinancialAccountType.CREDIT_CARD]: "Credit card",
    [FinancialAccountType.SAVINGS]: "Savings",
    [FinancialAccountType.LOAN]: "Loan",
    [FinancialAccountType.INVESTMENT]: "Investment",
    [FinancialAccountType.PAYMENT_PROCESSOR]: "Payment processor",
    [FinancialAccountType.OTHER]: "Other",
};

export const ALLOWED_FINANCIAL_ACCOUNT_TYPES_FOR_FILE_IMPORT = [
    FinancialAccountType.CHECKING,
    FinancialAccountType.SAVINGS,
    FinancialAccountType.CREDIT_CARD,
    FinancialAccountType.PAYMENT_PROCESSOR,
];

export const ALLOWED_FILE_TYPES_FOR_FINANCIAL_ACCOUNTS = ["text/csv"];

export const ALLOWED_FILE_TYPES_FOR_UNIVERSAL_IMPORT = [
    "text/csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "image/png",
    "image/jpeg",
    "image/jpg",
    "application/pdf",
];

export enum OAuthServiceType {
    QUICKBOOKS = "quickbooks",
    STRIPE = "stripe",
    GUSTO = "gusto",
    MERCURY = "mercury",
    RAMP = "ramp",
}

export const SENTRY_SERVICE_TAG = "service";
export const TWILIO_CLIENT = Symbol("TWILIO_CLIENT");

export const MAX_RECEIPT_SIZE_BYTES = 20 * 1024 * 1024;

export const TRANSACTION_CLASSIFICATION_LABELS: Record<
    TransactionClassification,
    string
> = {
    [TransactionClassification.BUSINESS]: "Business",
    [TransactionClassification.PERSONAL]: "Personal",
};

export enum CustomLocationOption {
    INTERNATIONAL = "international",
    UNKNOWN = "unknown",
}

export const TAX_LOCATIONS = [
    ...Object.values(CustomLocationOption),
    ...STATE_CODES,
];

export enum FinancialAccountClassification {
    BUSINESS = "business",
    MIXED = "mixed",
}

export enum PlaidConnectionErrors {
    DISCONNECTED = "disconnected",
    MFA_REQUIRED = "mfa_required",
}

export const ROLES_LABELS: Record<UserRole, string> = {
    [UserRole.ADMIN]: "Admin",
    [UserRole.MEMBER]: "Member",
    [UserRole.ACCOUNTANT]: "Accountant",
};

export const WORKSPACE_ROLES_LABELS: Record<UserWorkspaceRole, string> = {
    [UserWorkspaceRole.PRIMARY_ADMIN]: "Primary Admin",
    [UserWorkspaceRole.ADMIN]: "Admin",
    [UserWorkspaceRole.MEMBER]: "Member",
    [UserWorkspaceRole.ACCOUNTANT]: "Accountant",
};

export const WORKSPACE_ADMIN_ROLES = [
    UserWorkspaceRole.PRIMARY_ADMIN,
    UserWorkspaceRole.ADMIN,
];

export type WorkspaceAdminRole =
    | UserWorkspaceRole.PRIMARY_ADMIN
    | UserWorkspaceRole.ADMIN;

export const ADMIN_ROLES = [UserRole.ADMIN, UserRole.ACCOUNTANT];

export const INVITE_ROLE_TO_USER_ROLE: Record<InvitedUserRole, UserRole> = {
    [InvitedUserRole.ADMIN]: UserRole.ADMIN,
    [InvitedUserRole.MEMBER]: UserRole.MEMBER,
    [InvitedUserRole.ACCOUNTANT]: UserRole.ACCOUNTANT,
};

export const VERSION_HEADER = "x-app-version" as const;

export const ENTITY_ICON_COLORS = [
    "#FADEC4",
    "#BDF0D5",
    "#D9CCFF",
    "#FED0D4",
    "#E8EAED",

    "#E6E5CA",
    "#C7E4E4",
    "#E6CEF1",
    "#F7D9DD",
    "#D1EBD0",

    "#D0D8F2",
    "#F2CFE3",
    "#F0E2E5",
];

export const USER_ICON_COLORS = [
    "#BDF0D5",
    "#D9CCFF",
    "#FED0D4",
    "#E8EAED",
    "#E6E5CA",

    "#C7E4E4",
    "#E6CEF1",
    "#F7D9DD",
    "#D1EBD0",
    "#D0D8F2",

    "#F2CFE3",
    "#F0E2E5",
    "#FADEC4",
];

// icon color for personal entity doesn't matter as user icon is used as personal entity icon
export const PERSONAL_ENTITY_ICON_COLOR = ENTITY_ICON_COLORS[1];
export const OTHER_ENTITY_COLOR = ENTITY_ICON_COLORS[2];

export const EMPTY_STATE = "—";

export const UNKNOWN_AUDIT_LOG_AUTHOR = "Unknown";

export enum TRANSACTION_UPDATE_SOURCE {
    // Data sources
    PLAID_IMPORT = "plaid_import",
    FILE_IMPORT = "file_import",
    STRIPE = "stripe",
    MERCURY = "mercury",
    RAMP = "ramp",
    PAYPAL = "paypal",

    // Kick app functionality
    CATEGORIZATION_MODEL_TIER_1 = "categorizationModelTier1",
    CATEGORIZATION_MODEL_TIER_2 = "categorizationModelTier2",
    COUNTERPARTY_ENRICHMENT = "counterpartyEnrichment",
    CATEGORIZATION_RULE_CREATED = "categorizationRuleCreated",
    CATEGORIZATION_RULE_TRIGGERED = "categorizationRuleTriggered",
    CATEGORIZATION_RULE_REVERSED = "categorizationRuleReversed",
    MANUAL_TRANSFER_MATCHING = "manualTransferMatching",
    AUTO_TRANSFER_MATCHING = "autoTransferMatching",
    AUTO_INVOICE_MATCHING = "autoInvoiceMatching",
    TRANSACTION_FEED = "transactionFeed",
    ACTIONS = "actions",
    CUSTOM_CATEGORY_REMOVAL = "customCategoryRemoval",
    DIRECTORY = "directory",
    // NOTE: waiting for complete removal of this functionality before dropping
    SIMILARITY = "similarity",

    // NOTE: any update from admin app
    ADMIN = "admin",

    // NOTE: everything else ie. report generating lambda
    SYSTEM = "system",
}

// Integration sources that set category of a transaction
// Including an integration here means that the category won't be overridden
// by ML, but can still be overridden by users or rules
export const INTEGRATION_TRANSACTION_UPDATE_SOURCES: TRANSACTION_UPDATE_SOURCE[] =
    [
        TRANSACTION_UPDATE_SOURCE.STRIPE,
        TRANSACTION_UPDATE_SOURCE.MERCURY,
        TRANSACTION_UPDATE_SOURCE.RAMP,
        TRANSACTION_UPDATE_SOURCE.PAYPAL,
    ];

// NOTE: other sources are supposed to be automatically triggered and
// therefore userId either doesn't make sense (e.g. for CATEGORIZATION_MODEL_TIER_1)
// OR can be extracted from another entity based on metadata (e.g. ruleId => rule.userId)
export type TRANSACTION_UPDATE_SOURCES_REQUIRING_USER_OR_OPS =
    | TRANSACTION_UPDATE_SOURCE.ADMIN
    | TRANSACTION_UPDATE_SOURCE.MANUAL_TRANSFER_MATCHING
    // | TRANSACTION_UPDATE_SOURCE.FILE_IMPORT
    | TRANSACTION_UPDATE_SOURCE.ACTIONS
    | TRANSACTION_UPDATE_SOURCE.TRANSACTION_FEED;
export const TRANSACTION_UPDATE_SOURCES_VALUES_REQUIRING_USER_OR_OPS: TRANSACTION_UPDATE_SOURCES_REQUIRING_USER_OR_OPS[] =
    [
        TRANSACTION_UPDATE_SOURCE.ADMIN,
        TRANSACTION_UPDATE_SOURCE.MANUAL_TRANSFER_MATCHING,
        // TRANSACTION_UPDATE_SOURCE.FILE_IMPORT,
        TRANSACTION_UPDATE_SOURCE.ACTIONS,
        TRANSACTION_UPDATE_SOURCE.TRANSACTION_FEED,
    ];

export enum MoneyDirection {
    MONEY_IN = "MONEY_IN",
    MONEY_OUT = "MONEY_OUT",
}

export enum SortOrder {
    ASC = "ASC",
    DESC = "DESC",
}

export const DatesComparisonOperands = {
    since: ">=",
    until: "<",
};

export const institutionsWithManuallyProvidedData = [
    "ins_128026", // Capital One
];
export const REPORTS_CONTROLLER_DATE_FORMAT = "yyyy-MM-dd";

export enum ReportingTabCycle {
    WEEK = "week",
    MONTH = "month",
}

export const DEFAULT_NUMBER_OF_MONTHS_AVAILABLE = 3;

export const TRANSACTION_MATERIALITY_THRESHOLD = 0.02;

export enum UpdateEmailsFrequency {
    WEEK = "week",
    MONTH = "month",
}

export enum SERVICE_PREFIX {
    MAIN_APP = "api",
    ADMIN = "admin2",
}

export const FinancialConnectionProviderLabel: Record<
    ConnectionProviderType,
    string
> = {
    [ConnectionProviderType.PLAID]: "Plaid",
    [ConnectionProviderType.STRIPE]: "Stripe",
    [ConnectionProviderType.GUSTO]: "Gusto",
    [ConnectionProviderType.MERCURY]: "Mercury",
    [ConnectionProviderType.RAMP]: "Ramp",
};

export enum FinancialDocumentStatus {
    MATCHED = "MATCHED",
    NOT_MATCHED = "NOT_MATCHED",
}

export enum FinancialDocumentBulkActionTypes {
    REMOVE = "REMOVE",
    ASSIGN_ENTITY = "ASSIGN_ENTITY",
}

export const GOOGLE_AUTH_REDIRECT_PATH = "/google";

export const MERCURY_API_URL = "https://api.mercury.com/api/v1";

export const MERCURY_OAUTH_URL = "https://oauth2.mercury.com";
export const AWS_REGION = "us-west-2";

export enum PLAID_INSTITUTIONS_NAMES {
    CHASE = "chase",
    BANK_OF_AMERICA = "bankofamerica",
    WELLS_FARGO = "wellsfargo",
    CITIBANK = "citibank",
    CAPITAL_ONE = "capitalone",
    AMERICAN_EXPRESS = "americanexpress",
}

// Same value as ai/src/domain/embedding.py:CURRENT_ENGINE_VERSION
export const CURRENT_COUNTERPARTY_ENGINE_VERSION = 2;
export const DEFAULT_COUNTERPARTY_ENGINE_VERSION = 1;
