import { useQuery } from "@tanstack/react-query";
import { getPendingActions } from "../lib/actions";
import { queryClient } from "../queryClient";
import { ActionCategory } from "../common/types/actions";
import { useWorkspaceContext } from "../state/workspaceContext";

export const ACTIONS_QUERY_KEY = "actions";

export function useActions(category: ActionCategory) {
    const { activeWorkspace } = useWorkspaceContext();

    return useQuery({
        queryKey: [ACTIONS_QUERY_KEY, activeWorkspace?.id, category],
        queryFn: () =>
            activeWorkspace
                ? getPendingActions(activeWorkspace.id, category)
                : { actions: [] },
        refetchOnWindowFocus: false,
    });
}

export function invalidateActions(
    workspaceId: string,
    category: ActionCategory,
) {
    queryClient.invalidateQueries({
        queryKey: [ACTIONS_QUERY_KEY, workspaceId, category],
    });
}
