import classNames from "classnames";
import { noop } from "lodash";
import { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { InvoiceSortValue } from "../../common/types/invoice";
import { generateMockInvoices, mockInvoiceStats } from "../../helpers/invoice";
import { ConnectOverlay } from "./ConnectOverlay";
import { InvoiceStats } from "./InvoiceStats";
import { InvoicesTable } from "./InvoicesTable";

export const InvoiceConnectOverlayPageContent: React.FC = () => {
    const invoices = useMemo(() => generateMockInvoices(10), []);

    return (
        <>
            <Col className={classNames("d-flex flex-column gap-4 p-0")}>
                <InvoiceStats invoiceStats={mockInvoiceStats} />
                <Row>
                    <Col sm={12} md={12}>
                        <InvoicesTable
                            invoices={invoices}
                            invoiceCount={invoices.length}
                            onInvoiceClick={noop}
                            sort={InvoiceSortValue.DATE_DESC}
                            onSortChange={noop}
                        />
                    </Col>
                </Row>
            </Col>
            <ConnectOverlay />
        </>
    );
};
