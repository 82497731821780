import { TaxQuestionKey } from "./taxSavingRules";

export const CATEGORIZE_ACTION = "CATEGORIZE";
export const TRANSFER_MATCH_ACTION = "TRANSFER_MATCH";
export const REMOVE_REAL_ESTATE_CATEGORY_ACTION = "REMOVE_REAL_ESTATE_CATEGORY";
export const UPLOAD_RECEIPT_ACTION = "UPLOAD_RECEIPT";

export const ADD_MEMO_ACTION = "ADD_MEMO";

export const ANSWER_QUESTION_ACTION_PREFIX = "ANSWER_QUESTION";
export const ACTION_SEPARATOR = ":";

export const CATEGORY_RELATED_ACTIONS = [
    CATEGORIZE_ACTION,
    REMOVE_REAL_ESTATE_CATEGORY_ACTION,
];

export function getActionForQuestionKey(key: TaxQuestionKey): string {
    return `${ANSWER_QUESTION_ACTION_PREFIX}${ACTION_SEPARATOR}${key}`;
}
