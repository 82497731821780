import classNames from "classnames";
import React, { memo, useCallback, useMemo, useState } from "react";
import { Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/Overlay";
import Markdown from "markdown-to-jsx";
import {
    mapCategoryName,
    Taxonomy,
    TransactionDirectionType,
} from "../../../common/categories";
import { ADMIN_ROLES } from "../../../common/constants";
import {
    FLAT_RATE_PLANS,
    PREMIUM_FEATURES,
} from "../../../common/flatRateBilling";
import { NonUndefined } from "../../../common/helpers/typescript";
import { Category } from "../../../common/types/category";
import { useAllowFeature } from "../../../hooks/useAllowFeature";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import { useRoles } from "../../../hooks/useRoles";
import { DotsHorizontalIcon, ThunderboltIcon } from "../../../icons";
import { usePlanManagement } from "../../billing/PlanManagement/PlanManagementContext";
import {
    CustomSelect,
    CustomSelectOption,
} from "../../forms/CustomSelect/CustomSelect";
import { CaptureClicks } from "../../general/CaptureClicks";
import {
    useSettings,
    WorkspaceSettingsPages,
} from "../../settings/SettingsProvider";
import { CategoryIcon } from "../CategoryIcon";
import "./CategorySelectCustom.scss";
import { useStandardCategories } from "../../../hooks/useStandardCategories";
import { isValidTaxonomy } from "../../../common/helpers/taxonomy";
import { Button } from "../../general/Button/Button";
import { useCategoriesById } from "../../../hooks/useCategoriesById";
import { CategoryStructure, useCategories } from "../../../hooks/useCategories";
import { useCreateCustomCategoryMutation } from "../../../api/category.api";

const PLAN_UPGRADE_VALUE = "#plan" as Taxonomy;

export interface CategorySelectCustomProps {
    children: React.ReactNode | ((open: boolean) => React.ReactNode);
    onSelected(category: Category): void;
    suggestedCategories?: string[];
    dropdownKey: string | number;
    placement?: Placement;
    showPersonal?: boolean;
    hideCustomCategories?: boolean;
    appendTo?: HTMLElement;
    transactionDirection: TransactionDirectionType;
}

export const CategorySelectCustom = memo(
    ({
        children,
        onSelected,
        suggestedCategories = [],
        dropdownKey,
        placement = "bottom-end",
        showPersonal = true,
        hideCustomCategories = false,
        appendTo,
        transactionDirection,
    }: CategorySelectCustomProps) => {
        const categories = useCategories({
            onlyStandardCategories: hideCustomCategories,
            structure: CategoryStructure.TREE,
        });
        const categoryMap = useStandardCategories();
        const categoriesById = useCategoriesById();
        const isAdmin = useRoles(ADMIN_ROLES);

        const [search, setSearch] = useState("");

        const { open: openSettings } = useSettings();
        const { upgrade } = usePlanManagement();
        const { trialAvailable, isTrialing } = useBillingStatus();

        const createCustomCategoryMutation = useCreateCustomCategoryMutation();

        const handleSelected = useCallback(
            async (value: string) => {
                if (value.startsWith("/")) {
                    openSettings(value.split("/").slice(1));
                    return;
                }

                if (value === PLAN_UPGRADE_VALUE) {
                    upgrade(FLAT_RATE_PLANS.PLUS, trialAvailable || isTrialing);
                    return;
                }

                if (!value.startsWith("@new/")) {
                    onSelected(categoriesById[value]);
                    return;
                }

                const parentCategoryId = (value as string).slice(5);
                const label = search;
                const customCategory =
                    await createCustomCategoryMutation.mutateAsync({
                        parentCategoryId,
                        label,
                    });
                onSelected(customCategory);
            },
            [
                createCustomCategoryMutation,
                isTrialing,
                onSelected,
                openSettings,
                search,
                trialAvailable,
                upgrade,
                categoriesById,
            ],
        );

        const isFeatureAllowed = useAllowFeature(
            PREMIUM_FEATURES.CUSTOM_CATEGORIES,
        );

        const getSettingsOptions = useCallback(
            (value: string): Array<CustomSelectOption<string>> => {
                if (!isAdmin || hideCustomCategories) {
                    return [];
                }
                return [
                    isFeatureAllowed
                        ? {
                              value: `/${WorkspaceSettingsPages.CATEGORIES}/${value}`,
                              label: "Manage categories",
                          }
                        : {
                              value: PLAN_UPGRADE_VALUE,
                              label: "Manage categories",
                              renderAddon: () => (
                                  <ThunderboltIcon className="ml-auto icon-color-blue-600 icon-size-text" />
                              ),
                          },
                ];
            },
            [isAdmin, isFeatureAllowed, hideCustomCategories],
        );

        const [categoryWithOptionsOpened, setCategoryWithOptionsOpened] =
            useState<string | null>(null);

        const renderAddon = useCallback<
            NonUndefined<CustomSelectOption<string>["renderAddon"]>
        >(
            ({ value, onSelected: optionOnSelected }) => {
                if (!isAdmin || hideCustomCategories) {
                    return null;
                }

                return (
                    <CaptureClicks>
                        <CustomSelect
                            className="select-dropdown-settings"
                            popoverClassName="select-dropdown-settings__popover"
                            placement="right"
                            dropdownKey={`select-dropdown-settings-${value}-value`}
                            onSelected={optionOnSelected!}
                            options={getSettingsOptions(value)}
                            onShowPopover={() =>
                                setCategoryWithOptionsOpened(value)
                            }
                            onHidePopover={() =>
                                setCategoryWithOptionsOpened((currentValue) =>
                                    currentValue === value
                                        ? null
                                        : currentValue,
                                )
                            }
                            canShowPopover={categoryWithOptionsOpened === value}
                        >
                            <Button variant="tertiary" icon className="ml-1">
                                <DotsHorizontalIcon />
                            </Button>
                        </CustomSelect>
                    </CaptureClicks>
                );
            },
            [
                categoryWithOptionsOpened,
                getSettingsOptions,
                isAdmin,
                hideCustomCategories,
            ],
        );

        const categoryDescriptionTooltip = (category: Category) => {
            const isSubCategory = category.parentCategoryId != null;

            if (!category.description) {
                return;
            }

            return (
                <Tooltip
                    id={`category-select-description-${category.id}`}
                    className={classNames("category-select__description", {
                        "category-select__description--subcategory":
                            isSubCategory,
                    })}
                >
                    <Markdown options={{ disableParsingRawHTML: true }}>
                        {category.description ?? ""}
                    </Markdown>
                </Tooltip>
            );
        };

        const sortedCategories = useMemo(
            () =>
                categories
                    .filter((category) => showPersonal || category.isBusiness)
                    .sort((a, b) =>
                        !b.isBusiness ? -1 : a.label.localeCompare(b.label),
                    ),
            [categories, showPersonal],
        );

        // include built-in subcategories and custom subcategories
        const mappedCategories = useMemo(
            () =>
                sortedCategories.flatMap<CustomSelectOption<string>>(
                    (category) => [
                        {
                            value: category.id,
                            label: (
                                <>
                                    <CategoryIcon
                                        categoryId={category.id}
                                        transactionDirection={
                                            transactionDirection
                                        }
                                    />
                                    <span
                                        className="ml-2"
                                        data-testid="category-name"
                                    >
                                        {mapCategoryName({
                                            category,
                                            transactionDirection,
                                        })}
                                    </span>
                                </>
                            ),
                            description: categoryDescriptionTooltip(category),
                            searchableLabel: [
                                category.label,
                                ...category.subcategories.map((s) => s.label),
                            ].join("_"),
                            className: classNames("category-select__category", {
                                "category-select__locked_option":
                                    categoryWithOptionsOpened === category.id,
                            }),
                            renderAddon,
                        },
                        ...category.subcategories.map<
                            CustomSelectOption<string>
                        >((subcategory, index) => ({
                            value: subcategory.id,
                            label: subcategory.label,
                            description:
                                categoryDescriptionTooltip(subcategory),
                            searchableLabel: `${category.label}_${subcategory.label}`,
                            className: classNames(
                                "category-select__subcategory",
                                {
                                    "category-select__first_subcategory":
                                        index === 0,
                                    "category-select__locked_option":
                                        categoryWithOptionsOpened ===
                                        subcategory.id,
                                },
                            ),
                            renderAddon,
                        })),
                    ],
                ),
            [
                sortedCategories,
                transactionDirection,
                categoryWithOptionsOpened,
                renderAddon,
            ],
        );

        const mappedSuggestedCategories = useMemo(
            () =>
                suggestedCategories
                    .map((c) => (isValidTaxonomy(c) ? categoryMap[c] : null))
                    .filter((c) => c !== null)
                    .map((category) => ({
                        value: category.id,
                        label: (
                            <>
                                <CategoryIcon
                                    categoryId={category.id}
                                    transactionDirection={transactionDirection}
                                />
                                <span
                                    className="ml-2"
                                    data-testid="suggested-category-name"
                                >
                                    {mapCategoryName({
                                        category,
                                        transactionDirection,
                                    })}
                                </span>
                            </>
                        ),
                        description: categoryDescriptionTooltip(category),
                    })),
            [suggestedCategories, categoryMap, transactionDirection],
        );

        return (
            <CustomSelect
                onSelected={handleSelected}
                onSearch={setSearch}
                dropdownKey={`category_${dropdownKey}`}
                customOptions={mappedSuggestedCategories}
                customOptionsTitle={
                    <div className="category-select__custom-options-title">
                        Suggested
                    </div>
                }
                options={mappedCategories}
                shouldShowCustomOptionsWhileSearching={false}
                placement={placement}
                empty="No matching categories"
                className="category-select"
                dropdownClassName={classNames("category-select__popover", {
                    "category-select__popover-locked":
                        categoryWithOptionsOpened != null,
                })}
                searchable
                appendTo={appendTo}
            >
                {(open) =>
                    children instanceof Function ? children(open) : children
                }
            </CustomSelect>
        );
    },
);
