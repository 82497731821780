import React, { useCallback, useState } from "react";
import classNames from "classnames";
import "./Collapsible.scss";
import { isFunction } from "lodash";
import { ArrowDownIcon, ArrowUpIcon } from "../../../icons";
import { extractDataAttributes } from "../extractDataAttributes";
import { useInteraction } from "../../../hooks/useInteraction";
import { Button } from "../../general/Button/Button";

export interface CollapsibleProps {
    header: React.ReactNode;
    actions?: React.ReactNode;
    children?: React.ReactNode | ((opened: boolean) => React.ReactNode);
    footer?: React.ReactNode;
    defaultOpen?: boolean;
    className?: string;
    stacked?: boolean;
    open?: boolean;
    onToggle?: (opened: boolean) => void;
    first?: boolean;
    last?: boolean;
    middle?: boolean;
}

export const Collapsible: React.FC<CollapsibleProps> = extractDataAttributes(
    ({
        header,
        footer,
        className,
        defaultOpen,
        actions,
        children,
        stacked,
        open: forceOpen,
        onToggle,
        first,
        last,
        middle,
        dataAttributes,
    }) => {
        const [open, setOpen] = useState(() => defaultOpen ?? false);
        const isOpened = forceOpen ?? open;

        const toggle = useCallback(() => {
            setOpen(!isOpened);
            onToggle?.(!isOpened);
        }, [isOpened, onToggle]);

        const click = useInteraction(toggle);

        return (
            <section
                className={classNames("collapsible", className, {
                    "collapsible--open": isOpened,
                    "collapsible--stacked": stacked,
                    "collapsible--first": first,
                    "collapsible--last": last,
                    "collapsible--middle": middle,
                })}
                {...dataAttributes}
            >
                <header className="collapsible__header" {...click}>
                    <div className="collapsible__header__content">{header}</div>

                    <aside className="collapsible__header__actions">
                        {actions}
                        {children && (
                            <Button variant="tertiary" icon>
                                {isOpened ? <ArrowUpIcon /> : <ArrowDownIcon />}
                            </Button>
                        )}
                    </aside>
                </header>

                <div className="collapsible__collapse">
                    <main className="collapsible__content">
                        {isFunction(children) ? children(isOpened) : children}
                        {footer ? (
                            <footer className="collapsible__footer">
                                {footer}
                            </footer>
                        ) : null}
                    </main>
                </div>
            </section>
        );
    },
);
