import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import classNames from "classnames";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { emulateTab } from "emulate-tab";

import {
    SelectDropdown,
    SelectDropdownRef,
} from "../../general/SelectDropdown/SelectDropdown";
import { SelectDropdownOption } from "../../general/SelectDropdown/SelectDropdownOption";
import { SelectDropdownDivider } from "../../general/SelectDropdown/SelectDropdownDivider";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import {
    EditableTableCell,
    EditableTableCellProps,
    calculateCellBorders,
} from "./EditableTableCell";
import styles from "./EditableTable.module.scss";

interface EditableCellSearchSelectableOption {
    value: string | number | null;
    label: string;
    isGroupLabel?: false;
}
interface EditableCellSearchOptionGroupLabel {
    isGroupLabel: true;
    label: string;
    value: string;
}
export type EditableCellSearchOption =
    | EditableCellSearchSelectableOption
    | EditableCellSearchOptionGroupLabel;

interface EditableTableCellSearchProps
    extends Omit<EditableTableCellProps, "children"> {
    options: EditableCellSearchOption[];
    onChange: undefined | ((value: string | number | null) => void);
    value: string;
    isDisabled: boolean;
}

const popperConfig = {
    modifiers: [
        {
            name: "offset",
            options: {
                offset: [0, 0],
            },
        },
    ],
};

export const EditableTableCellSearch = React.memo(
    ({
        id,
        isFirstRow,
        options,
        onChange,
        value,
        error,
        selectionState,
        onClick,
        isDisabled,
    }: EditableTableCellSearchProps) => {
        const [isDropdownOpened, setIsDropdownOpened] = useState(false);
        const [selectedIndex, setSelectedIndex] = useState(-1);
        const [search, setSearch] = useState(() => {
            const optionToFind = options.find(
                (option) =>
                    !option.isGroupLabel && String(option.value) === value,
            );
            return optionToFind?.label ?? value;
        });
        const [localValue, setLocalValue] = useState(value);
        const searchRef = useRef<HTMLInputElement | null>(null);
        const popoverRef = useRef<HTMLDivElement | null>(null);

        const { width, height } = useWindowDimensions();

        const popoverStyle = useMemo(
            () => ({
                maxWidth: searchRef.current?.getBoundingClientRect().width,
            }),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [searchRef.current, width, height],
        );

        useEffect(() => {
            if (value !== localValue) {
                const optionToFind = options.find(
                    (option) =>
                        !option.isGroupLabel && String(option.value) === value,
                );
                if (optionToFind) {
                    setSearch(optionToFind.label);
                } else {
                    setSearch(value);
                }
                setLocalValue(value);
            }
        }, [value, localValue, setLocalValue, setSearch, options, id]);

        const cellBorders = calculateCellBorders(selectionState);

        const filteredOptions = useMemo(() => {
            if (search === "") {
                return options;
            }
            const newOptions: EditableCellSearchOption[] = [];
            const addedGroups: Set<string> = new Set();
            let activeGroup: EditableCellSearchOptionGroupLabel | undefined;
            for (const option of options) {
                if (option.isGroupLabel) {
                    activeGroup = option;
                }
                if (option.label.toLowerCase().includes(search.toLowerCase())) {
                    if (activeGroup && !addedGroups.has(activeGroup.value)) {
                        addedGroups.add(activeGroup.value);
                        newOptions.push(activeGroup);
                    }
                    if (option.isGroupLabel) {
                        continue;
                    }
                    newOptions.push(option);
                }
            }
            // In case the user types in a text that matches exactly on coa (we have two options - one for the group and one for the coa)
            if (newOptions.length === 2) {
                setSelectedIndex(1);
            } else {
                setSelectedIndex(-1);
            }
            return newOptions;
        }, [options, search]);

        const dropdownRef = useRef<SelectDropdownRef>(null);

        const getNextIndex = useCallback(
            (currentIndex: number, direction: number): number => {
                if (!filteredOptions.length) {
                    return currentIndex;
                }

                const nextIndex =
                    (currentIndex + direction) % filteredOptions.length;

                if (filteredOptions.at(nextIndex)?.isGroupLabel) {
                    return getNextIndex(nextIndex, direction);
                }

                return nextIndex;
            },
            [filteredOptions],
        );

        const selectOption = useCallback(
            (option: EditableCellSearchOption) => {
                if (option.isGroupLabel) {
                    return;
                }
                setSearch(String(option.label));
                onChange?.(String(option.value));
                setLocalValue(String(option.value));
                setIsDropdownOpened(false);
                emulateTab();
                setSelectedIndex(-1);
            },
            [onChange],
        );

        const handleKeyDown = useCallback(
            (e: KeyboardEvent) => {
                if (!isDropdownOpened) {
                    return;
                }

                if (e.key === "ArrowDown") {
                    e.preventDefault();
                    const nextIndex = getNextIndex(selectedIndex, 1);
                    setSelectedIndex(nextIndex);
                    dropdownRef.current?.scrollTop(nextIndex * 33);
                } else if (e.key === "ArrowUp") {
                    e.preventDefault();
                    const nextIndex = getNextIndex(selectedIndex, -1);
                    setSelectedIndex(nextIndex);
                    dropdownRef.current?.scrollTop(nextIndex * 33);
                } else if (e.key === "Enter" && selectedIndex !== -1) {
                    selectOption(filteredOptions[selectedIndex]);
                }
            },
            [
                filteredOptions,
                getNextIndex,
                isDropdownOpened,
                selectOption,
                selectedIndex,
            ],
        );

        useEffect(() => {
            if (isDropdownOpened) {
                document.addEventListener("keydown", handleKeyDown);
            } else {
                document.removeEventListener("keydown", handleKeyDown);
            }

            return () => document.removeEventListener("keydown", handleKeyDown);
        }, [handleKeyDown, isDropdownOpened, selectedIndex]);

        return (
            <OverlayTrigger
                show={isDropdownOpened}
                placement="bottom-start"
                flip={true}
                popperConfig={popperConfig}
                overlay={
                    <Popover
                        id={`${id}-popover`}
                        className={classNames(
                            "popover-container",
                            styles.popoverBody,
                        )}
                        style={popoverStyle}
                    >
                        <Popover.Content ref={popoverRef}>
                            <SelectDropdown
                                className={classNames(
                                    styles.accountDropdown,
                                    "dropdown-menu",
                                )}
                                ref={dropdownRef}
                            >
                                {filteredOptions.map((option, index) => (
                                    <>
                                        {option.isGroupLabel && index !== 0 && (
                                            <SelectDropdownDivider
                                                key={`${option.label}-divider-${index}`}
                                            />
                                        )}
                                        <SelectDropdownOption
                                            className={classNames(
                                                styles.dropdownOption,
                                                option.isGroupLabel &&
                                                    styles.dropdownOptionGroupLabel,
                                                index === selectedIndex &&
                                                    !option.isGroupLabel &&
                                                    styles.selectedDropDownOption,
                                            )}
                                            key={
                                                option.isGroupLabel
                                                    ? option.label
                                                    : option.value
                                            }
                                            onClick={() => {
                                                selectOption(option);
                                            }}
                                        >
                                            {option.label}
                                        </SelectDropdownOption>
                                    </>
                                ))}
                                {filteredOptions.length === 0 && (
                                    <div
                                        className={classNames(
                                            styles.dropdownEmptyState,
                                            "d-flex justify-content-center align-items-center",
                                        )}
                                    >
                                        No accounts found
                                    </div>
                                )}
                            </SelectDropdown>
                        </Popover.Content>
                    </Popover>
                }
            >
                {({ ref }) => (
                    <EditableTableCell
                        id={id}
                        isFirstRow={isFirstRow}
                        ref={ref}
                        error={error}
                        selectionState={selectionState}
                    >
                        <input
                            value={search}
                            style={cellBorders}
                            className={classNames(
                                "form-control form-control-sm",
                                styles.editableTableInput,
                            )}
                            ref={searchRef as any}
                            onFocus={() => {
                                if (isDisabled) {
                                    return;
                                }
                                setIsDropdownOpened(true);
                                setSelectedIndex(-1);
                                onClick?.();
                            }}
                            onBlur={(e) => {
                                if (
                                    !!e.relatedTarget &&
                                    !popoverRef.current?.contains(
                                        e.relatedTarget,
                                    )
                                ) {
                                    // When the user leaves the input, and there was a value set previously
                                    // we reset it to the search, indicating to the user that it is not deleted
                                    setSearch(
                                        options.find(
                                            (option) =>
                                                !option.isGroupLabel &&
                                                String(option.value) === value,
                                        )?.label ?? "",
                                    );
                                }

                                if (
                                    !popoverRef.current?.contains(
                                        e.relatedTarget,
                                    )
                                ) {
                                    setIsDropdownOpened(false);
                                } else {
                                    searchRef.current?.focus();
                                }
                            }}
                            onChange={(e) => {
                                if (isDisabled) {
                                    return;
                                }
                                setSearch(e.target.value);
                            }}
                        />
                    </EditableTableCell>
                )}
            </OverlayTrigger>
        );
    },
);
