import React from "react";
import { MoneyDirection } from "../../../common/constants";
import { FiltersTypes } from "../../../common/types/filters/general";

interface DirectionFilterLabelProps {
    filters: Pick<FiltersTypes, "direction">;
}

export const DirectionFilterLabel: React.FC<DirectionFilterLabelProps> = ({
    filters: { direction },
}) => <>{direction === MoneyDirection.MONEY_IN ? "Money in" : "Money out"}</>;
