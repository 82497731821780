import { useMutation } from "@tanstack/react-query";
import { queryClient, stripeConnectionsQueryKey } from "../queryClient";
import { createStripeConnection, fixStripeConnection } from "../lib/stripe";
import { StripeConnection } from "../common/types/stripeConnection";
import { FinancialConnection } from "../common/types/financialConnection";
import { Entity } from "../common/types/entity";
import {
    addFinancialConnectionInQueryData,
    clearConnectionErrorInQueryData,
} from "../queries/entitiesAccounts";
import { useConnectAccount } from "../components/connectAccount/ConnectAccount.context";

import { useToaster } from "../components/general/ToastMessages/useToaster";

interface StripeConnectionCreationPayload {
    code: string;
    defaultEntity?: Entity;
}
export function useStripeConnectionCreationMutation(onSuccess?: () => void) {
    const { toast } = useToaster();
    const { updateConnectAccountState } = useConnectAccount();

    return useMutation({
        mutationFn: ({
            code,
            defaultEntity,
        }: StripeConnectionCreationPayload) =>
            createStripeConnection({
                code,
                defaultEntityId: defaultEntity?.id,
            }),

        onSuccess: (connection) => {
            queryClient.setQueryData(stripeConnectionsQueryKey, [
                ...(queryClient.getQueryData<StripeConnection[]>(
                    stripeConnectionsQueryKey,
                ) ?? []),
                connection,
            ]);
            addFinancialConnectionInQueryData(queryClient, connection);
            if (!connection.accounts[0].financialAccount.entity) {
                updateConnectAccountState({
                    isConnecting: false,
                    connection,
                    connectionSaved: true,
                    foreign: [],
                });
            } else {
                toast(`Stripe account connected`);
            }
            onSuccess?.();
        },
    });
}
interface FixStripeConnectionPayload {
    connection: FinancialConnection;
    code: string;
}
export function useFixStripeConnectionsMutation(onSuccess?: () => unknown) {
    return useMutation({
        mutationFn: (payload: FixStripeConnectionPayload) =>
            fixStripeConnection(payload.connection.id, { code: payload.code }),
        onSuccess: (connected) => {
            if (!connected) {
                return;
            }
            clearConnectionErrorInQueryData(queryClient, connected.id);
            onSuccess?.();
            queryClient.setQueryData<FinancialConnection[] | undefined>(
                stripeConnectionsQueryKey,
                (connections) =>
                    connections?.map((c) =>
                        c.id === connected.id
                            ? {
                                  ...c,
                                  accounts: connected.accounts,
                                  error: null,
                              }
                            : c,
                    ) ?? [],
            );
        },
    });
}
