import React, {
    forwardRef,
    PropsWithChildren,
    RefObject,
    useCallback,
    useEffect,
} from "react";
import Scrollbars, { positionValues } from "react-custom-scrollbars-2";
import { HEADER_HEIGHT } from "../../../constants";

type Direction = "top" | "left" | "right" | "right-2";

function updateStickyState(
    el: HTMLDivElement,
    direction: Direction,
    isSticky: boolean,
) {
    el.classList.toggle(`data-table--sticky-${direction}`, isSticky);
}

interface Props {
    containerRef: RefObject<HTMLDivElement>;
    stickyLeft?: boolean;
    stickyRight?: true | 1 | 2;
    setIsStickingRight?: (value: boolean) => void;
    alwaysHorizontalSticky?: boolean;
}

export const StickyDataTable = forwardRef<Scrollbars, PropsWithChildren<Props>>(
    (
        {
            containerRef,
            stickyLeft,
            stickyRight,
            setIsStickingRight,
            children,
            alwaysHorizontalSticky,
        },
        ref,
    ) => {
        const handleHorizontalScroll = useCallback(
            (values: positionValues) => {
                if (containerRef.current) {
                    if (stickyLeft) {
                        updateStickyState(
                            containerRef.current,
                            "left",
                            values.left > 0,
                        );
                    }
                    if (stickyRight) {
                        const isStickingRight = values.left < 1;
                        updateStickyState(
                            containerRef.current,
                            "right",
                            values.left < 1,
                        );
                        setIsStickingRight?.(isStickingRight);
                    }
                    if (stickyRight === 2) {
                        updateStickyState(
                            containerRef.current,
                            "right-2",
                            values.left < 1,
                        );
                    }
                }
            },
            [containerRef, setIsStickingRight, stickyLeft, stickyRight],
        );

        useEffect(() => {
            if (alwaysHorizontalSticky && containerRef.current) {
                if (stickyLeft) {
                    updateStickyState(containerRef.current, "left", true);
                }
                if (stickyRight) {
                    updateStickyState(containerRef.current, "right", true);
                }
                if (stickyRight === 2) {
                    updateStickyState(containerRef.current, "right-2", true);
                }
            }
        }, [containerRef, alwaysHorizontalSticky, stickyLeft, stickyRight]);

        useEffect(() => {
            const handleVerticalScroll = () => {
                if (containerRef.current) {
                    const distanceToTop =
                        containerRef.current.getBoundingClientRect().top -
                        HEADER_HEIGHT;
                    const val = Math.max(Math.floor(-distanceToTop) - 1, 0);

                    containerRef.current.style.setProperty(
                        `--offset-top`,
                        `${val}px`,
                    );

                    updateStickyState(containerRef.current, "top", val > 0);
                }
            };

            window.addEventListener("scroll", handleVerticalScroll);

            return () =>
                window.removeEventListener("scroll", handleVerticalScroll);
        }, [containerRef]);

        return (
            <Scrollbars
                ref={ref}
                onScrollFrame={
                    alwaysHorizontalSticky ? undefined : handleHorizontalScroll
                }
                autoHeight
                autoHeightMax={Number.MAX_VALUE}
            >
                {children}
            </Scrollbars>
        );
    },
);
