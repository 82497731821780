import React, { MouseEvent, useCallback } from "react";
import classNames from "classnames";
import { TreeHelpers, TreeNode } from "../../general/Tree/Tree.types";
import { TriangleIcon } from "../../../icons";
import { Class } from "../../../common/types/class";
import { ClassObjectIcon } from "../ClassObjectIcon";
import { FormCheckbox } from "../../forms/FormCheckbox/FormCheckbox";

import css from "../../general/AggregatedFilters/FormCheckItem.module.scss";
import { Button } from "../../general/Button/Button";

interface ClassTreeItemProps extends TreeHelpers {
    node: TreeNode<Class>;
    toggleSelected: (id: string) => void;
    isSelected: (id: string) => boolean;
    hasSelectedChildren: (id: string) => boolean;
    disabled?: boolean;
}
export const ClassTreeItem: React.FC<ClassTreeItemProps> = ({
    node,
    collapseNode,
    isExpanded,
    toggleSelected,
    isSelected,
    expandNode,
    disabled,
    hasSelectedChildren,
}) => {
    const handleToggleExpanded = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation();
            if (isExpanded(node.nodeId)) {
                collapseNode(node.nodeId);
            } else {
                expandNode(node.nodeId);
            }
        },
        [collapseNode, expandNode, isExpanded, node.nodeId],
    );

    return (
        <FormCheckbox
            disabled={disabled}
            small
            key={node.current.id}
            value={node.current.id}
            isChecked={isSelected(node.current.id)}
            isPartiallyChecked={hasSelectedChildren(node.current.id)}
            handleChange={() => toggleSelected(node.current.id)}
            className={classNames(css.item, {
                [css.rootItem]: node.depth === 0,
                [css.expandedItem]: isExpanded(node.nodeId),
            })}
            label={
                <div className={css.itemLabel}>
                    {hasSelectedChildren(node.current.id)}
                    <ClassObjectIcon class={node.current} />
                    <span className="ml-2">{node.current.label}</span>
                    {node.children.length > 0 && (
                        <Button
                            variant="tertiary"
                            onClick={handleToggleExpanded}
                            className="ml-1"
                            icon
                            size="sm"
                            data-testid="entity-tree-item-toggle"
                        >
                            <TriangleIcon />
                        </Button>
                    )}
                </div>
            }
        />
    );
};
