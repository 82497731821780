import React, { useCallback, useLayoutEffect, useMemo } from "react";
import { format } from "date-fns";
import { useDateRangePicker } from "../../../forms/dates/DateRangePicker/useDateRangePicker";
import { DATE_RANGE_PRESETS_BY_ID } from "../../../../helpers/date";
import { FormRowRaw } from "../../../forms/FormRowRaw";
import {
    DateRange,
    fullDateLongFormat,
    monthlyDateFormat,
} from "../../../../common/helpers/date";
import { DatePickerInputManual } from "../../../forms/dates/DatePickerInputManual/DatePickerInputManual";
import {
    CustomSelect,
    CustomSelectOption,
} from "../../../forms/CustomSelect/CustomSelect";
import { CustomSelectDefaultTrigger } from "../../../forms/CustomSelect/CustomSelectDefaultTrigger";
import { DateRangeCalendar } from "../../../forms/dates/DateRangePicker/DateRangeCalendar";
import styles from "./AggregatedDateFilter.module.scss";

const VISIBLE_PRESETS = [
    "last_month",
    "this_month",
    "last_4_months",
    "last_quarter",
    "this_quarter",
    "this_year",
    "last_year",
];

const ALL_TIME_PRESET_ID = "all_time";

export interface DateFilter {
    start?: Date;
    end?: Date;
}

interface Props {
    filters: DateFilter;
    onChange(value: DateFilter): void;
    monthOnly?: boolean;
    requireSelection?: boolean;
    maxDate?: Date;
}

export const AggregatedDateFilter: React.FC<Props> = ({
    filters,
    onChange,
    requireSelection = false,
    monthOnly,
    maxDate: maxDateProp,
}) => {
    const stableFilters = React.useRef(filters);
    const stableOnChange = React.useRef(onChange);

    useLayoutEffect(() => {
        stableOnChange.current = onChange;
        stableFilters.current = filters;
    }, [filters, onChange]);

    const onDateRangeChanged = useCallback((newValue: Partial<DateRange>) => {
        const { start, end } = stableFilters.current;

        if (
            newValue.start?.getTime() !== start?.getTime() ||
            newValue.end?.getTime() !== end?.getTime()
        ) {
            stableOnChange.current(newValue);
        }
    }, []);

    const {
        start,
        end,
        maxDate,
        calendarValue,
        changeDateRange,
        activeStart,
        onActiveStartDateChange,
        selectFromInput,
        setStart,
        setEnd,
        selectPreset,
        activePreset,
        presets,
    } = useDateRangePicker({
        onChange: onDateRangeChanged,
        initialValue: filters,
        visiblePresets: VISIBLE_PRESETS,
        requireSelection,
        maxDate: maxDateProp,
    });

    const presetOptions: CustomSelectOption[] = useMemo(() => {
        const options = presets.map((preset) => ({
            value: preset.id,
            label: preset.labelFn ? preset.labelFn(new Date()) : preset.label,
        })) as CustomSelectOption[];

        if (!requireSelection) {
            options.push({
                value: ALL_TIME_PRESET_ID,
                label: "All time",
            });
        }

        return options;
    }, [presets, requireSelection]);

    let displayedPreset: string | undefined;
    if (activePreset) {
        displayedPreset = activePreset;
    } else if (!start && !end) {
        displayedPreset = ALL_TIME_PRESET_ID;
    }

    const buttonText = displayedPreset
        ? presetOptions.find((preset) => preset.value === displayedPreset)
              ?.label
        : "Custom dates";

    return (
        <div className={styles.filter}>
            <section className={styles.presets}>
                <CustomSelect
                    value={displayedPreset}
                    onSelected={(value) =>
                        selectPreset(DATE_RANGE_PRESETS_BY_ID[value])
                    }
                    dropdownKey="aggregated-date-filter-preset"
                    options={presetOptions}
                    fullWidthDropdown
                    size="sm"
                >
                    <CustomSelectDefaultTrigger
                        label="Period"
                        value={buttonText}
                        size="sm"
                    />
                </CustomSelect>
            </section>
            <section className={styles.inputs}>
                {monthOnly ? (
                    <>
                        <FormRowRaw
                            value={
                                start ? format(start, monthlyDateFormat) : ""
                            }
                            disabled
                            label="From"
                            size="sm"
                        />
                        <FormRowRaw
                            value={end ? format(end, monthlyDateFormat) : ""}
                            disabled
                            label="To"
                            size="sm"
                        />
                    </>
                ) : (
                    <>
                        <DatePickerInputManual
                            value={start}
                            onChange={(value) =>
                                selectFromInput(value, setStart)
                            }
                            label="From"
                            size="sm"
                            dateFormat={fullDateLongFormat}
                            placeholder="MM/DD/YYYY"
                        />
                        <DatePickerInputManual
                            value={end}
                            onChange={(value) =>
                                selectFromInput(value, setEnd, true)
                            }
                            label="To"
                            size="sm"
                            dateFormat={fullDateLongFormat}
                            placeholder="MM/DD/YYYY"
                        />
                    </>
                )}
            </section>
            <section className={styles.calendar}>
                <DateRangeCalendar
                    maxDate={maxDate}
                    calendarValue={calendarValue ?? null}
                    changeDateRange={changeDateRange}
                    activeStart={activeStart}
                    onActiveStartDateChange={onActiveStartDateChange}
                />
            </section>
        </div>
    );
};
