import { Class } from "../common/types/class";

export interface ClassWithChildren extends Class {
    children: Class[];
}

export function getNormalizedClasses(classes: Class[]) {
    const classesMap = new Map<string, ClassWithChildren>();
    const parentClasses = [];
    const parentToChildrenMap = new Map<string, Class[]>();
    for (const c of classes) {
        classesMap.set(c.id, {
            ...c,
            children: [],
        });
        if (c.parentClassId) {
            parentToChildrenMap.set(c.parentClassId, [
                ...(parentToChildrenMap.get(c.parentClassId) ?? []),
                c,
            ]);
        } else {
            parentClasses.push(c);
        }
    }
    const classesToReturn: Class[] = [];
    for (const c of parentClasses.toSorted((a, b) => a.order - b.order)) {
        classesToReturn.push(c);
        const children = parentToChildrenMap.get(c.id);
        if (children) {
            classesToReturn.push(
                ...children.toSorted((a, b) => a.order - b.order),
            );
            classesMap.set(c.id, {
                ...classesMap.get(c.id)!,
                children,
            });
        }
    }

    const sortedClassesIndexMap = new Map<string, number>();
    classesToReturn.forEach((c, index) => {
        sortedClassesIndexMap.set(c.id, index);
    });

    return {
        sortedClasses: classesToReturn,
        sortedClassesIndexMap,
        classesWithChildren: [...parentClasses]
            .toSorted((a, b) => a.order - b.order)
            .map((c) => ({
                ...c,
                children: [...(parentToChildrenMap.get(c.id) ?? [])].toSorted(
                    (a, b) => a.order - b.order,
                ),
            })),
        classesMap,
    };
}

interface CheckClassLabelConflictParams {
    classes: Class[];
    parentClassId: string | null;
    label: string;
    targetClassId?: string;
}

export function checkClassLabelConflict({
    classes,
    parentClassId,
    label,
    targetClassId,
}: CheckClassLabelConflictParams) {
    const parent = classes.find((c) => c.id === parentClassId);
    if (parentClassId !== null && !parent) {
        // NOTE: inconsistent state
        return {
            conflict: true,
            conflictingClass: null,
        };
    }
    const classesToCheckAgainst = parent
        ? classes.filter((c) => c.rootClassId === parent?.rootClassId)
        : classes.filter((c) => c.parentClassId === null);

    const conflictingClass = classesToCheckAgainst.find(
        (c) =>
            c.label.toLowerCase() === label.toLowerCase() &&
            c.id !== targetClassId,
    );
    return {
        conflict: !!conflictingClass,
        conflictingClass,
    };
}
