import React from "react";
import { Form, useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import classNames from "classnames";
import { FormRow } from "../../forms/FormRow";
import animation from "../layout/OnboardingLayout.module.scss";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { KeyboardReturnIcon } from "../../../icons";
import { ProfileFormSchema } from "../types";
import { EntityProfileFormFields } from "../components/EntityProfileFormFields";
import { KeyboardShortcut } from "../../general/KeyboardShortcut/KeyboardShortcut";
import styles from "./Profile.module.scss";
import commonStyles from "./common.module.scss";

interface Props {
    busy: boolean;
}

export const Profile: React.FC<Props> = ({ busy }) => {
    const { isValid } = useFormikContext<ProfileFormSchema>();

    return (
        <div className={commonStyles.content420}>
            <header className={classNames(animation.fadeIn2)}>
                <h1 className={classNames(commonStyles.title, styles.header)}>
                    Apply to automate
                    <br />
                    your accounting
                </h1>
            </header>

            <Form className={classNames(animation.fadeIn1)}>
                <Row>
                    <Col>
                        <FormRow
                            fieldName="firstName"
                            label="First name"
                            size="sm"
                        />
                    </Col>

                    <Col>
                        <FormRow
                            fieldName="lastName"
                            label="Last name"
                            size="sm"
                        />
                    </Col>
                </Row>

                <EntityProfileFormFields />

                <FormRow
                    fieldName="source"
                    label="How did you hear about Kick?"
                    size="sm"
                />

                <footer>
                    <ButtonWithLoader
                        type="submit"
                        variant="secondary"
                        loading={busy}
                        block
                        disabled={!isValid}
                    >
                        Submit application
                        <KeyboardShortcut square className="ml-2">
                            <KeyboardReturnIcon />
                        </KeyboardShortcut>
                    </ButtonWithLoader>
                </footer>
            </Form>
        </div>
    );
};
