import { noop } from "lodash";
import { createContext, useContext } from "react";
import { CashFlowReportRowPath } from "../../common/dto/reports/cash-flow-tab-report-response.dto";

export interface CashFlowDrillDownModalParams {
    category?: string[];
    counterpartyId?: string[];
    path: CashFlowReportRowPath[];
    columnId: string;
}

export interface CashFlowDrillDownContextValue {
    params?: CashFlowDrillDownModalParams;
    open: (params: CashFlowDrillDownModalParams) => void;
    close: () => void;
}

export const CashFlowDrillDownContext =
    createContext<CashFlowDrillDownContextValue>({
        open: noop,
        close: noop,
    });

export function useCashFlowDrillDownContext(): CashFlowDrillDownContextValue {
    const ctx = useContext(CashFlowDrillDownContext);
    if (!ctx) {
        throw new Error(
            "Can only be used inside a TransactionsDrillDownContextProvider",
        );
    }

    return ctx;
}
