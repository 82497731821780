import React from "react";
import { useQuery } from "@tanstack/react-query";
import { GustoConnection } from "../../../common/types/gustoConnection";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { getGustoConnectionForEntity } from "../../../lib/gusto";
import { useSyncGustoConnectionMutation } from "../../../mutations/gustoConnection";
import { Entity } from "../../../common/types/entity";
import { ExtendedButtonProps } from "../../general/Button/Button";

interface Props extends Omit<ExtendedButtonProps, "onClick"> {
    entity: Entity;
}
export const SyncGustoConnection: React.FC<Props> = ({
    entity,
    children,
    ...btnProps
}) => {
    const { data: connection } = useQuery({
        queryKey: ["entityConnection", entity.id],
        queryFn: () => getGustoConnectionForEntity(entity),
    });

    const syncPayrolls = useSyncGustoConnectionMutation(entity);

    if (!connection) {
        return null;
    }
    return (
        <ButtonWithLoader
            onClick={() => syncPayrolls.mutate(connection as GustoConnection)}
            loading={syncPayrolls.isPending}
            {...btnProps}
        >
            {children}
        </ButtonWithLoader>
    );
};
