import React, { useCallback, useMemo } from "react";
import { Collapsible } from "../general/Collapsible/Collapsible";
import gustoLogo from "../../static/images/icons/gusto.svg";
import { ClockIcon } from "../../icons";
import { timeAgo } from "../../helpers/date";
import { DisplayIntegrationAccount } from "../integrationAccount/DisplayIntegrationAccount";
import { useLatestBalanceSyncTime } from "../../hooks/useLatestSyncTime";
import { FinancialConnectionWithAccounts } from "../../common/types/financialConnection";
import { useToaster } from "../general/ToastMessages/useToaster";
import css from "./GustoConnectionsStatus.module.scss";
import { CreateGustoConnection } from "./CreateGustoConnection";

interface Props {
    gustoConnections: FinancialConnectionWithAccounts[];
}

export const GustoConnectionsStatus: React.FC<Props> = ({
    gustoConnections,
}) => {
    const { toast } = useToaster();

    const handleConnected = useCallback(() => {
        toast(`Gusto successfully connected`);
    }, [toast]);

    const accounts = useMemo(
        () => gustoConnections.map((c) => c.accounts[0]),
        [gustoConnections],
    );
    const mostRecentBalanceSyncedAt = useLatestBalanceSyncTime(accounts);

    const header = (
        <div className={css.header}>
            <img src={gustoLogo} alt="Gusto" />
            <main>
                <h5>Gusto</h5>
                {mostRecentBalanceSyncedAt && (
                    <small>
                        <ClockIcon
                            height={12}
                            width={12}
                            className="icon-color-current"
                        />
                        {timeAgo(mostRecentBalanceSyncedAt)}
                    </small>
                )}
            </main>
        </div>
    );

    return (
        <Collapsible
            className={css.gustoConnections}
            header={header}
            footer={
                <div className={css.footerSection}>
                    <CreateGustoConnection
                        btnVariant="secondary"
                        onCreated={handleConnected}
                    >
                        Connect another account
                    </CreateGustoConnection>
                </div>
            }
        >
            <>
                {gustoConnections?.map((connection) => (
                    <DisplayIntegrationAccount
                        key={connection.id}
                        className={css.displayAccount}
                        connection={connection}
                        integrationAccount={connection.accounts[0]}
                    />
                ))}
            </>
        </Collapsible>
    );
};
