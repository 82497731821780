import React from "react";
import Logo from "@static/images/logo.svg?react";
import { EXIT_ANIMATION_DURATION, PRODUCT_PREVIEW_URL } from "../constants";
import { ChildrenProps } from "../../../types";
import styles from "./OnboardingContainer.module.scss";

const style = {
    "--exit-animation-duration": `${EXIT_ANIMATION_DURATION}ms`,
    "--preview-url": `url(${PRODUCT_PREVIEW_URL})`,
} as any;

export const OnboardingContainer: React.FC<ChildrenProps> = ({ children }) => (
    <div className={styles.container} style={style}>
        <Logo className={styles.logo} />
        {children}
    </div>
);
