import { useSortable } from "@dnd-kit/sortable";
import classNames from "classnames";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDeleteClassMutation } from "../../../../api/class.api";
import { DragHandle6DotIcon } from "../../../../icons";
import styles from "../ClassesSettings.module.scss";
import { ConfirmationModal } from "../../../general/ConfirmationModal";
import { Class } from "../../../../common/types/class";
import { PlainModal } from "../../../general/Modal/Modal";
import { ManagementDropdown } from "../../../general/ManagementDropdown/ManagementDropdown";
import { Button } from "../../../general/Button/Button";
import { RenameClassModal } from "./RenameClassModal";

export const ClassActions: React.FC<{
    classInstance: Class;
    numberOfSubclassesInsteadOfDelete?: number;
    onDelete?: () => void;
}> = ({ classInstance, numberOfSubclassesInsteadOfDelete }) => {
    const deleteClass = useDeleteClassMutation();

    const { attributes, listeners } = useSortable({ id: classInstance.id });

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const isSubclass = classInstance.parentClassId !== null;

    const [showRenameModal, setShowRenameModal] = useState(false);

    return (
        <div
            className={styles.classRowActionsContainer}
            onPointerDown={(e) => e.stopPropagation()}
        >
            <Button
                {...attributes}
                {...listeners}
                size="sm"
                icon
                variant="tertiary"
            >
                <DragHandle6DotIcon />
            </Button>

            <ManagementDropdown
                id={`class-actions-dropdown__${classInstance.id}`}
                className={classNames(
                    styles.actionIconContainer,
                    styles.dropdownButton,
                )}
                popperConfigStrategy="fixed"
            >
                <Dropdown.Item
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowRenameModal(true);
                    }}
                >
                    Rename
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowDeleteModal(true);
                    }}
                    className={styles.deleteItem}
                >
                    Delete
                </Dropdown.Item>
            </ManagementDropdown>
            {numberOfSubclassesInsteadOfDelete !== undefined && (
                <button
                    className={classNames(
                        styles.closeIconContainer,
                        styles.numberOfSubclasses,
                        numberOfSubclassesInsteadOfDelete === 0 &&
                            styles.invisible,
                    )}
                >
                    <span>{numberOfSubclassesInsteadOfDelete}</span>
                </button>
            )}

            <PlainModal
                show={showRenameModal}
                onHide={() => setShowRenameModal(false)}
                title="Rename class"
            >
                <RenameClassModal
                    classInstance={classInstance}
                    onHide={() => setShowRenameModal(false)}
                />
            </PlainModal>

            <ConfirmationModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                title={`Are you sure that you want to delete ${isSubclass ? "subclass" : "class"} ${classInstance.label}?`}
                no="Cancel"
                question=""
                onReject={(e) => {
                    e.stopPropagation();
                    setShowDeleteModal(false);
                }}
                onConfirm={() => {
                    deleteClass.mutate(classInstance.id);
                    setShowDeleteModal(false);
                }}
                yes="Delete"
            />
        </div>
    );
};
