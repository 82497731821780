import { Form, Formik, FormikConfig } from "formik";
import React, { useState } from "react";
import { object, string } from "yup";
import { Link } from "react-router-dom";
import { submitHelper } from "../../../helpers/form";
import { FormRow } from "../../forms/FormRow";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { LoginWithGoogleButton } from "../GoogleAuth/LoginWithGoogleButton";
import { HorizontalLine } from "../../general/HorizontalLine/HorizontalLine";
import { TermsAndPrivacyConsent } from "../../general/TermsAndPrivacyConsent/TermsAndPrivacyConsent";

interface FormSchema {
    phoneNumberOrEmail: string;
}

export interface LoginFlowStartProps {
    onRequestCode(phoneNumberOrEmail: string): Promise<any>;
    onSuccess(phoneNumberOrEmail: string): void;
}

export const LoginFlowStart: React.FC<LoginFlowStartProps> = ({
    onRequestCode,
    onSuccess,
}) => {
    const [loading, setLoading] = useState(false);

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            phoneNumberOrEmail: "",
        },
        validationSchema: object().shape({
            phoneNumberOrEmail: string()
                .required()
                .test({
                    test: (value) =>
                        string().trim().email().isValidSync(value) ||
                        string()
                            .trim()
                            .matches(/^\d{10}$/)
                            .isValidSync(normalizeValue(value)),
                }),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async ({ phoneNumberOrEmail }) => {
                const normalized = normalizeValue(phoneNumberOrEmail);

                const requestSuccessful = await onRequestCode(normalized);
                if (requestSuccessful) {
                    onSuccess(normalized);
                }
            },
        }),
    };

    return (
        <>
            <h2 className="login__heading">Log in to Kick</h2>
            <section className="login__form">
                <LoginWithGoogleButton size="lg" variant="secondary" block>
                    Continue with Google
                </LoginWithGoogleButton>

                <HorizontalLine className="login__hr">or</HorizontalLine>

                <Formik {...form}>
                    {({ isValid, dirty }) => (
                        <Form>
                            <FormRow
                                fieldName="phoneNumberOrEmail"
                                placeholder="Phone number or email"
                                className="mb-3"
                                size="sm"
                                showErrorMessage={false}
                            />
                            <footer className="d-flex justify-content-center">
                                <ButtonWithLoader
                                    loading={loading}
                                    type="submit"
                                    size="lg"
                                    disabled={!isValid || !dirty}
                                    variant="secondary"
                                >
                                    Log in
                                </ButtonWithLoader>
                            </footer>
                        </Form>
                    )}
                </Formik>
            </section>

            <TermsAndPrivacyConsent className="login__consent" />

            <footer className="mt-5 text-center">
                Don't have an account? <Link to="/register">Sign up</Link>
            </footer>
        </>
    );
};

function normalizeValue(value: string) {
    return value.includes("@")
        ? value.trim()
        : value.trim().replace(/\D/g, "").slice(-10);
}
