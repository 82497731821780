import { GetTransactionRulesResponse } from "../../../common/dto/transactionRule/get-transaction-rules-response.dto";
import { Counterparty } from "../../../common/types/counterparty";
import { Entity } from "../../../common/types/entity";
import { FinancialAccount } from "../../../common/types/financialAccount";
import {
    RuleConditionAmount,
    RuleConditionType,
    TransactionRule,
    TransactionRuleCondition,
} from "../../../common/types/transactionRule";

export function buildRulesItems({
    search,
    categoryLabelGetter,
    transactionResponse,
    entities,
    accounts,
}: {
    search: string | undefined;
    categoryLabelGetter: (id: string) => string;
    transactionResponse?: GetTransactionRulesResponse;
    entities: Entity[];
    accounts: FinancialAccount[];
}) {
    if (!transactionResponse || !search) {
        return transactionResponse;
    }

    const filteredRules = transactionResponse.rules.filter((rule) => {
        const conditionMatch = searchConditions({
            conditions: rule.conditions,
            search,
            entities,
            accounts,
            counterparties: transactionResponse.counterparties,
            categoryLabelGetter,
        });

        const actionMatch = searchActions({
            rule,
            search,
        });

        return conditionMatch || actionMatch;
    });

    return {
        ...transactionResponse,
        rules: filteredRules,
    };
}

function searchActions({
    rule,
    search,
}: {
    rule: TransactionRule;
    search: string;
}) {
    return (
        containsTextCaseInsensitive(rule.entity?.name, search) ||
        containsTextCaseInsensitive(rule.category?.label, search)
    );
}

function searchConditions({
    conditions,
    search,
    entities,
    accounts,
    counterparties,
    categoryLabelGetter,
}: {
    conditions: TransactionRuleCondition[];
    search: string;
    entities: Entity[];
    accounts: FinancialAccount[];
    counterparties: Counterparty[];
    categoryLabelGetter: (id: string) => string;
}) {
    return conditions.some((condition) => {
        switch (condition.conditionType) {
            case RuleConditionType.COUNTERPARTY:
                return searchCounterpartyCondition({
                    search,
                    counterparties,
                    counterpartyId: condition.value as string,
                });
            case RuleConditionType.CATEGORY:
            case RuleConditionType.SUGGESTED_CATEGORY:
                return searchCategoryCondition({
                    search,
                    categoryId: condition.value as string,
                    categoryLabelGetter,
                });
            case RuleConditionType.DESCRIPTION_IS:
            case RuleConditionType.DESCRIPTION_INCLUDES:
                return searchDescriptionCondition(
                    search,
                    condition.value as string[],
                );
            case RuleConditionType.AMOUNT:
                return searchAmountCondition(
                    Number(search),
                    condition.value as RuleConditionAmount,
                );
            case RuleConditionType.FINANCIAL_ACCOUNTS:
                return searchFinancialAccountsCondition({
                    search,
                    financialAccountIds: condition.value as number[],
                    accounts,
                });
            case RuleConditionType.ENTITY:
                return searchEntityCondition({
                    search,
                    entityIds: condition.value as number[],
                    entities,
                });
            default:
                return false;
        }
    });
}

function searchCounterpartyCondition({
    search,
    counterparties,
    counterpartyId,
}: {
    search: string;
    counterparties: Counterparty[];
    counterpartyId: string;
}) {
    return containsTextCaseInsensitive(
        findEntityById(counterparties, counterpartyId)?.name,
        search,
    );
}

function searchCategoryCondition({
    search,
    categoryId,
    categoryLabelGetter,
}: {
    search: string;
    categoryId: string;
    categoryLabelGetter: (id: string) => string;
}) {
    if (!categoryId) {
        return false;
    }
    return containsTextCaseInsensitive(categoryLabelGetter(categoryId), search);
}

function searchDescriptionCondition(search: string, descriptions?: string[]) {
    if (!descriptions) {
        return false;
    }
    return descriptions.some((description) =>
        containsTextCaseInsensitive(description, search),
    );
}

function searchAmountCondition(search: number, amount: RuleConditionAmount) {
    if (!amount || isNaN(search)) {
        return false;
    }
    return amount.min === search || amount.max === search;
}

function searchFinancialAccountsCondition({
    search,
    financialAccountIds,
    accounts,
}: {
    search: string;
    financialAccountIds: number[];
    accounts: FinancialAccount[];
}) {
    if (!financialAccountIds) {
        return false;
    }

    return financialAccountIds.some((id) =>
        containsTextCaseInsensitive(findEntityById(accounts, id)?.name, search),
    );
}

function searchEntityCondition({
    search,
    entityIds,
    entities,
}: {
    search: string;
    entityIds: number[];
    entities: Entity[];
}) {
    return entityIds.some((id) =>
        containsTextCaseInsensitive(findEntityById(entities, id)?.name, search),
    );
}

function containsTextCaseInsensitive(
    text: string | undefined | null,
    search: string,
): boolean {
    if (!text) {
        return false;
    }
    return text.toLowerCase().includes(search.toLowerCase());
}

export function findEntityById<T extends { id: string | number | null }>(
    entities: T[],
    id: string | number | null,
): T | undefined {
    if (!id) {
        return undefined;
    }
    return entities.find((entity) => entity.id === id);
}
