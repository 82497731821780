import { UserRole } from "../../common/types/userEntityAssignment";
import { UserWorkspaceRole } from "../../common/userWorkspaceRoles";

export enum AddMemberStep {
    DETAILS = "DETAILS",
    PERMISSIONS = "PERMISSIONS",
}

export interface StepProps {
    onBack: () => void;
    onNext: () => void;
}

interface EntityAssignmentFormSchema {
    included: boolean;
    entityId: number;
    role: UserRole;
}

export interface MemberPermissionsSchema {
    workspaceRole: UserWorkspaceRole;
    entityAssignments: EntityAssignmentFormSchema[];
}

export interface AddMemberFormSchema extends MemberPermissionsSchema {
    email: string;
}
