import { capitalize } from "lodash";
import {
    EntityProfileType,
    EntityType,
    MergedEntityType,
} from "../../../types/entity";
import {
    FinancialAccount,
    FinancialAccountType,
} from "../../../types/financialAccount";

import {
    AccountType as MergedAccountType,
    AccountSubtype as MergedAccountSubtype,
} from "../../../types/domains/accounting/accounts";
import { FinancialAccountsGroup } from "../../../types/domains/accounting/chart-of-accounts";
import {
    isCorporationLedger,
    isPartnershipLedger,
    isSolePropLedger,
} from "./helpersv2";
import {
    AccountClass,
    AccountSubtype,
    AccountType,
    DefaultCashBasisAccountCodes,
} from "./accountsv2";

export const enum SolePropEquityAccountCodes {
    "OwnerInvestments" = 30000,
    "OwnerDraws" = 31000,
    "OwnerCapital" = 32000,
}

export const enum SCorpAccountCodes {
    "LoansToShareholders" = 19200,

    "LoansFromShareholders" = 25100,

    "CommonStock" = 30000,
    "AdditionalPaidInCapital" = 31000,
    "Contributions" = 32000,
    "Distributions" = 33000,
    "Dividends" = 34000,
    "RetainedEarnings" = 35000,
    "AdjustmentsToShareholderEquity" = 36000,
    "CostOfTreasuryStock" = 37000,

    "OfficersCompensation" = 60200,
}

export const enum PartnershipAccountCodes {
    "LoansToPartners" = 19200,

    "LoansFromPartners" = 25100,

    "Contributions" = 30000,
    "Distributions" = 31000,
    "Capital" = 32000,

    "GuaranteedPaymentsToPartners" = 60200,
}

export type AccountCodes =
    | DefaultCashBasisAccountCodes
    | SolePropEquityAccountCodes
    | SCorpAccountCodes
    | PartnershipAccountCodes;

export const defaultSolePropEquityChartOfAccounts: ChartOfAccounts<SolePropEquityAccountCodes> =
    {
        30000: {
            name: "Owner Investments",
            type: AccountType.equity,
            subtype: AccountSubtype.contributions,
        },
        31000: {
            name: "Owner Draws",
            type: AccountType.equity,
            subtype: AccountSubtype.distributions,
        },
        32000: {
            name: "Owner Capital",
            type: AccountType.equity,
            subtype: AccountSubtype.capital,
        },
    };

export const defaultSCorpEquityChartOfAccounts: Partial<
    ChartOfAccounts<SCorpAccountCodes>
> = {
    30000: {
        name: "Common Stock",
        type: AccountType.equity,
        subtype: AccountSubtype.commonStock,
    },
    31000: {
        name: "Additional Paid-In Capital",
        type: AccountType.equity,
        subtype: AccountSubtype.additionalPaidInCapital,
    },
    32000: {
        name: "Contributions",
        type: AccountType.equity,
        subtype: AccountSubtype.contributions,
    },
    33000: {
        name: "Distributions",
        type: AccountType.equity,
        subtype: AccountSubtype.distributions,
    },
    34000: {
        name: "Dividends",
        type: AccountType.equity,
        subtype: AccountSubtype.dividends,
    },
    35000: {
        name: "Retained Earnings",
        type: AccountType.equity,
        subtype: AccountSubtype.retainedEarnings,
    },
    36000: {
        name: "Adjustments to Shareholders' Equity",
        type: AccountType.equity,
        subtype: AccountSubtype.adjustmentsToShareholdersEquity,
    },
    37000: {
        name: "Cost of Treasury Stock",
        type: AccountType.equity,
        subtype: AccountSubtype.costOfTreasuryStock,
    },
};

export const defaultPartnershipEquityChartOfAccounts: ChartOfAccounts<SolePropEquityAccountCodes> =
    {
        30000: {
            name: "Contributions",
            type: AccountType.equity,
            subtype: AccountSubtype.contributions,
        },
        31000: {
            name: "Distributions",
            type: AccountType.equity,
            subtype: AccountSubtype.distributions,
        },
        32000: {
            name: "Capital",
            type: AccountType.equity,
            subtype: AccountSubtype.capital,
        },
    };

// NOTE: legacy structure, to replace with defaultCashChartOfAccounts
export const defaultCashChartOfAccountsOld: Partial<
    ChartOfAccounts<DefaultCashBasisAccountCodes>
> = {
    10000: {
        name: "Cash on Hand",
        type: AccountType.cash,
        subtype: AccountSubtype.cashOnHand,
    },
    10900: {
        name: "Bank Transfer Clearing",
        type: AccountType.cash,
        subtype: AccountSubtype.transferClearing,
    },
    10901: {
        name: "Payment Transfer Clearing",
        type: AccountType.cash,
        subtype: AccountSubtype.transferClearing,
    },
    11000: {
        name: "Accounts Receivable",
        type: AccountType.accountsReceivable,
        subtype: AccountSubtype.accountsReceivable,
    },
    11900: {
        name: "Allowance for Bad Debts",
        type: AccountType.accountsReceivable,
        subtype: AccountSubtype.allowanceForBadDebts,
    },
    12000: {
        name: "Inventory",
        type: AccountType.inventory,
        subtype: AccountSubtype.inventory,
    },
    13000: {
        name: "Prepaid Expenses",
        type: AccountType.otherCurrentAssets,
        subtype: AccountSubtype.prepaidExpenses,
    },
    13900: {
        name: "Other Current Assets",
        type: AccountType.otherCurrentAssets,
        subtype: AccountSubtype.otherCurrentAssets,
    },
    14000: {
        name: "U.S. Government Obligations",
        type: AccountType.investments,
        subtype: AccountSubtype.usGovernmentObligations,
    },
    14100: {
        name: "Tax-Exempt Securities",
        type: AccountType.investments,
        subtype: AccountSubtype.taxExemptSecurities,
    },
    14200: {
        name: "Investments",
        type: AccountType.investments,
        subtype: AccountSubtype.investments,
    },
    15000: {
        name: "Land",
        type: AccountType.fixedAssets,
        subtype: AccountSubtype.land,
    },
    15100: {
        name: "Buildings",
        type: AccountType.fixedAssets,
        subtype: AccountSubtype.buildings,
    },
    15200: {
        name: "Leasehold Improvements",
        type: AccountType.fixedAssets,
        subtype: AccountSubtype.leaseholds,
    },
    15300: {
        name: "Vehicles",
        type: AccountType.fixedAssets,
        subtype: AccountSubtype.vehicles,
    },
    15400: {
        name: "Equipment",
        type: AccountType.fixedAssets,
        subtype: AccountSubtype.equipment,
    },
    15500: {
        name: "Furniture and Fixtures",
        type: AccountType.fixedAssets,
        subtype: AccountSubtype.furnitureAndFixtures,
    },
    15800: {
        name: "Other Fixed Assets",
        type: AccountType.fixedAssets,
        subtype: AccountSubtype.otherFixedAssets,
    },
    15900: {
        name: "Accumulated Depreciation",
        type: AccountType.fixedAssets,
        subtype: AccountSubtype.accumulatedDepreciation,
    },
    16000: {
        name: "Startup Costs",
        type: AccountType.intangibleAssets,
        subtype: AccountSubtype.startupCosts,
    },
    16100: {
        name: "Trademarks and Patents",
        type: AccountType.intangibleAssets,
        subtype: AccountSubtype.trademarksAndPatents,
    },
    16200: {
        name: "Goodwill",
        type: AccountType.intangibleAssets,
        subtype: AccountSubtype.goodwill,
    },
    16800: {
        name: "Other Intangible Assets",
        type: AccountType.intangibleAssets,
        subtype: AccountSubtype.otherIntangibleAssets,
    },
    16900: {
        name: "Accumulated Amortization",
        type: AccountType.intangibleAssets,
        subtype: AccountSubtype.accumulatedAmortization,
    },
    20000: {
        name: "Accounts Payable",
        type: AccountType.accountsPayable,
        subtype: AccountSubtype.accountsPayable,
    },
    21900: {
        name: "Credit Card Clearing",
        type: AccountType.creditCards,
        subtype: AccountSubtype.creditCardClearing,
    },
    22000: {
        name: "Payroll Payable",
        type: AccountType.payrollLiabilities,
        subtype: AccountSubtype.payrollPayable,
    },
    23000: {
        name: "Line of Credit",
        type: AccountType.shortTermLoans,
        subtype: AccountSubtype.lineOfCredit,
    },
    23100: {
        name: "Short-Term Loans",
        type: AccountType.shortTermLoans,
        subtype: AccountSubtype.shortTermLoans,
    },
    23900: {
        name: "Current Maturities of Long-Term Debt",
        type: AccountType.shortTermLoans,
        subtype: AccountSubtype.currentMaturitiesOfLongTermDebt,
    },
    24000: {
        name: "Sales Tax Payable",
        type: AccountType.otherCurrentLiabilities,
        subtype: AccountSubtype.salesTaxPayable,
    },
    24100: {
        name: "Deferred Revenue",
        type: AccountType.otherCurrentLiabilities,
        subtype: AccountSubtype.deferredRevenue,
    },
    24200: {
        name: "Accrued Expenses",
        type: AccountType.otherCurrentLiabilities,
        subtype: AccountSubtype.accruedExpenses,
    },
    24900: {
        name: "Other Current Liabilities",
        type: AccountType.otherCurrentLiabilities,
        subtype: AccountSubtype.otherCurrentLiabilities,
    },
    29000: {
        name: "Intercompany Payables",
        type: AccountType.otherLiabilities,
        subtype: AccountSubtype.intercompanyPayables,
    },
    29900: {
        name: "Other Long-Term Liabilities",
        type: AccountType.otherLiabilities,
        subtype: AccountSubtype.otherLiabilities,
    },
    40000: {
        name: "Revenue",
        type: AccountType.income,
        subtype: AccountSubtype.revenue,
    },
    40100: {
        name: "Refunds and Returns",
        type: AccountType.income,
        subtype: AccountSubtype.refundsAndReturns,
    },
    49999: {
        name: "Uncategorized Income",
        type: AccountType.income,
        subtype: AccountSubtype.uncategorizedIncome,
    },
    50000: {
        name: "COGS - Goods and Materials",
        type: AccountType.cogs,
        subtype: AccountSubtype.cogsGoodsAndMaterials,
    },
    50100: {
        name: "COGS - Labor",
        type: AccountType.cogs,
        subtype: AccountSubtype.cogsLabor,
    },
    50200: {
        name: "COGS - Other",
        type: AccountType.cogs,
        subtype: AccountSubtype.cogsOther,
    },
    61000: {
        name: "Payroll Taxes",
        type: AccountType.payrollTaxes,
        subtype: AccountSubtype.payrollTaxes,
    },
    62000: {
        name: "Employee Benefits",
        type: AccountType.employeeBenefits,
        subtype: AccountSubtype.employeeBenefits,
    },
    62100: {
        name: "Retirement Benefits",
        type: AccountType.employeeBenefits,
        subtype: AccountSubtype.retirementBenefits,
    },
    70000: {
        name: "Advertising and Marketing",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.advertisingAndMarketing,
    },
    70100: {
        name: "Learning and Development",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.learningAndDevelopment,
    },
    70200: {
        name: "Licenses and Permits",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.licensesAndPermits,
    },
    70300: {
        name: "Property Taxes",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.propertyTaxes,
    },
    70400: {
        name: "Entertainment",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.entertainment,
    },
    70500: {
        name: "Insurance - Business",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.insurance,
    },
    70501: {
        name: (entityType: MergedEntityType) => {
            let name = "Insurance - ";
            if (isSolePropLedger(entityType)) {
                name += "Owner";
            }
            if (isCorporationLedger(entityType)) {
                name += "Officers";
            }
            if (isPartnershipLedger(entityType)) {
                name += "Partners";
            }
            return name;
        },
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.insurance,
    },
    70600: {
        name: "Internet and Phone",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.internetAndPhone,
    },
    70700: {
        name: "Facilities - Repairs and Maintenance",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.facilities,
    },
    70701: {
        name: "Facilities - Cleaning Fees",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.facilities,
    },
    70702: {
        name: "Facilities - Management Fees",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.facilities,
    },
    70800: {
        name: "Furniture and Fixtures",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.furnitureAndFixtures,
    },
    70900: {
        name: "Meals",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.meals,
    },
    70901: {
        name: "Meals - Employee Catering",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.meals,
    },
    71000: {
        name: "Merchant and Bank Fees",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.merchantAndBankFees,
    },
    71100: {
        name: "Supplies and Equipment",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.suppliesAndEquipment,
    },
    71200: {
        name: "Software and Subscriptions",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.softwareAndSubscriptions,
    },
    71300: {
        name: "Office Expenses",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.officeExpenses,
    },
    71400: {
        name: "Office Utilities",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.officeUtilities,
    },
    71500: {
        name: "Legal and Professional Fees",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.legalAndProfessionalFees,
    },
    71600: {
        name: "Rent and Lease",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.rentAndLease,
    },
    71700: {
        name: "Travel",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.travel,
    },
    71800: {
        name: "Vehicle",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.vehicle,
    },
    71801: {
        name: "Vehicle - Leases",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.vehicle,
    },
    71802: {
        name: "Vehicle - Parking and Tolls",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.vehicle,
    },
    79900: {
        name: "Other Operating Expenses",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.otherOperatingExpenses,
    },
    79999: {
        name: "Uncategorized Expenses",
        type: AccountType.operatingExpenses,
        subtype: AccountSubtype.uncategorizedExpenses,
    },
    80000: {
        name: "Rental Income",
        type: AccountType.otherIncome,
        subtype: AccountSubtype.rentalIncome,
    },
    80100: {
        name: "Interest Income",
        type: AccountType.otherIncome,
        subtype: AccountSubtype.interestIncome,
    },
    80200: {
        name: "Dividend Income",
        type: AccountType.otherIncome,
        subtype: AccountSubtype.dividendIncome,
    },
    80300: {
        name: "Rebate Income",
        type: AccountType.otherIncome,
        subtype: AccountSubtype.rebateIncome,
    },
    80400: {
        name: "Royalty Income",
        type: AccountType.otherIncome,
        subtype: AccountSubtype.royaltyIncome,
    },
    84800: {
        name: "Gain (Loss) Asset Disposals",
        type: AccountType.otherIncome,
        subtype: AccountSubtype.gainLossAssetDisposals,
    },
    84900: {
        name: "Other Income",
        type: AccountType.otherIncome,
        subtype: AccountSubtype.otherIncome,
    },
    85000: {
        name: "Interest Expense",
        type: AccountType.otherExpenses,
        subtype: AccountSubtype.interestExpense,
    },
    85100: {
        name: "Depreciation",
        type: AccountType.otherExpenses,
        subtype: AccountSubtype.depreciation,
    },
    85200: {
        name: "Amortization",
        type: AccountType.otherExpenses,
        subtype: AccountSubtype.amortization,
    },
    85300: {
        name: "Depletion",
        type: AccountType.otherExpenses,
        subtype: AccountSubtype.depletion,
    },
    85400: {
        name: "Fines and Penalties",
        type: AccountType.otherExpenses,
        subtype: AccountSubtype.finesAndPenalties,
    },
    85500: {
        name: "Bad Debts",
        type: AccountType.otherExpenses,
        subtype: AccountSubtype.badDebts,
    },
    85600: {
        name: "Donations",
        type: AccountType.otherExpenses,
        subtype: AccountSubtype.donations,
    },
    89900: {
        name: "Other Expense",
        type: AccountType.otherExpenses,
        subtype: AccountSubtype.otherExpense,
    },
    90000: {
        name: "Federal Taxes",
        type: AccountType.taxExpenses,
        subtype: AccountSubtype.federalTaxes,
    },
    90100: {
        name: "State and Local Taxes",
        type: AccountType.taxExpenses,
        subtype: AccountSubtype.stateAndLocalTaxes,
    },
};

// DEPRECATED, use defaultChartOfAccountsNew
// NOTE: at the end of the day we need to migrate all account declarations
// to this format and also use account type keys instead of account codes
// to support taxonomy mapping for accounts from dynamic types
export const ChartOfAccountsTypesWithDynamicNumbering: ChartOfAccountsTypesWithDynamicNumberingType =
    [
        {
            typeKey: AccountType.otherAssets,
            startFrom: 19000,
            upTo: 19999,
            // for sections starting counting from the end
            endFrom: 19900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    name: "Intercompany Receivables",
                    type: AccountType.otherAssets,
                    subtype: AccountSubtype.intercompanyReceivables,
                },
                {
                    name: "Loans to Employees",
                    type: AccountType.otherAssets,
                    subtype: AccountSubtype.employeeLoans,
                },
                {
                    name: "Loans to Owner",
                    if: isSolePropLedger,
                    type: AccountType.otherAssets,
                    subtype: AccountSubtype.loansToOwner,
                },
                {
                    name: "Loans to Shareholders",
                    if: isCorporationLedger,
                    type: AccountType.otherAssets,
                    subtype: AccountSubtype.loansToShareholders,
                },
                {
                    name: "Loans to Partners",
                    if: isPartnershipLedger,
                    type: AccountType.otherAssets,
                    subtype: AccountSubtype.loansToPartners,
                },
                {
                    name: "Mortgage and Real Estate Loans",
                    type: AccountType.otherAssets,
                    subtype: AccountSubtype.mortgageAndRealEstateLoans,
                },
                {
                    name: "Security Deposits",
                    type: AccountType.otherAssets,
                    subtype: AccountSubtype.securityDeposits,
                },
                {
                    name: "Depletable Assets",
                    type: AccountType.otherAssets,
                    subtype: AccountSubtype.depletableAssets,
                },
                {
                    name: "Accumulated Depletion",
                    type: AccountType.otherAssets,
                    subtype: AccountSubtype.accumulatedDepletion,
                },
            ],
            subtypesStartFromEnd: [
                {
                    name: "Other Long-Term Assets",
                    type: AccountType.otherAssets,
                    subtype: AccountSubtype.otherLongTermAssets,
                },
            ],
        },
        {
            typeKey: AccountType.longTermLoans,
            startFrom: 25000,
            upTo: 25999,
            endFrom: 25900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    name: "Long-Term Loans",
                    type: AccountType.longTermLoans,
                    subtype: AccountSubtype.longTermLoans,
                },
                {
                    name: "Loans from Owner",
                    if: isSolePropLedger,
                    type: AccountType.otherAssets,
                    subtype: AccountSubtype.loansFromOwner,
                },
                {
                    name: "Loans from Shareholders",
                    if: isCorporationLedger,
                    type: AccountType.longTermLoans,
                    subtype: AccountSubtype.loansFromShareholders,
                },
                {
                    name: "Loans from Partners",
                    if: isPartnershipLedger,
                    type: AccountType.longTermLoans,
                    subtype: AccountSubtype.loansFromPartners,
                },
            ],
            subtypesStartFromEnd: [
                {
                    name: "Less Current Maturities of Long-Term Debt",
                    type: AccountType.longTermLoans,
                    subtype: AccountSubtype.lessCurrentMaturitiesOfLongTermDebt,
                },
            ],
        },
        {
            typeKey: AccountType.payrollExpenses,
            startFrom: 60000,
            upTo: 60999,
            endFrom: 60900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    name: "Salaries and Wages",
                    type: AccountType.payrollExpenses,
                    subtype: AccountSubtype.salariesAndWages,
                },
                {
                    name: "Contract Labor",
                    type: AccountType.payrollExpenses,
                    subtype: AccountSubtype.contractLabor,
                },
                {
                    name: "Guaranteed Payments to Partners",
                    type: AccountType.payrollExpenses,
                    subtype: AccountSubtype.guaranteedPaymentsToPartners,
                    if: isPartnershipLedger,
                },
                {
                    name: "Officer's Compensation",
                    type: AccountType.payrollExpenses,
                    subtype: AccountSubtype.officersCompensation,
                    if: isCorporationLedger,
                },
                {
                    name: "Commissions",
                    type: AccountType.payrollExpenses,
                    subtype: AccountSubtype.commisions,
                },
            ],
            subtypesStartFromEnd: [
                {
                    name: "Salaries and Wages - Clearing",
                    type: AccountType.payrollExpenses,
                    subtype: AccountSubtype.salariesAndWagesClearing,
                },
            ],
        },
    ];

export type AccountSubtypesForFinancialAccountGroups =
    | AccountSubtype.bankAccount
    | AccountSubtype.paymentProcessor
    | AccountSubtype.creditCard;

export const defaultCashChartOfAccountsFinancialAccountGroups: Record<
    AccountSubtypesForFinancialAccountGroups,
    FinancialAccountsGroup
> = {
    [AccountSubtype.bankAccount]: {
        startFrom: 10100,
        upTo: 10199,
        name: (account: FinancialAccount) =>
            `Bank account - ${account.name}${
                account.accountNumberMask
                    ? ` - ${account.accountNumberMask}`
                    : ""
            }`,
        type: AccountType.cash,
        subtype: AccountSubtype.bankAccount,
        class: AccountClass.assets,
        clearingAccountCode: DefaultCashBasisAccountCodes.bankTransferClearing,
    },
    [AccountSubtype.paymentProcessor]: {
        startFrom: 10200,
        upTo: 10299,
        name: (account: FinancialAccount) =>
            `Payment Processor - ${capitalize(account.sourceType)} - ${account.name}${
                account.accountNumberMask
                    ? ` - ${account.accountNumberMask}`
                    : ""
            }`,
        type: AccountType.cash,
        subtype: AccountSubtype.paymentProcessor,
        class: AccountClass.assets,
        clearingAccountCode:
            DefaultCashBasisAccountCodes.paymentTransferClearing,
    },
    [AccountSubtype.creditCard]: {
        startFrom: 21000,
        upTo: 21099,
        name: (account: FinancialAccount) =>
            `Credit card - ${account.name}${
                account.accountNumberMask
                    ? ` - ${account.accountNumberMask}`
                    : ""
            }`,
        type: AccountType.creditCards,
        subtype: AccountSubtype.creditCard,
        class: AccountClass.liabilities,
        clearingAccountCode: DefaultCashBasisAccountCodes.creditCardClearing,
    },
};

export const entityTypeToEquityAccounts: Record<
    MergedEntityType,
    ChartOfAccounts<any>
> = {
    [EntityType.C_CORP]: defaultSCorpEquityChartOfAccounts,
    [EntityType.C_CORP_WITH_S_CORP]: defaultSCorpEquityChartOfAccounts,
    [EntityType.LLC_WITH_S_CORP]: defaultSCorpEquityChartOfAccounts,
    [EntityType.LLC]: defaultSolePropEquityChartOfAccounts,
    [EntityType.NOT_REGISTERED]: defaultSolePropEquityChartOfAccounts,
    [EntityProfileType.S_CORP]: defaultSCorpEquityChartOfAccounts,
    [EntityProfileType.SOLE_PROPRIETORSHIP]:
        defaultSolePropEquityChartOfAccounts,
    [EntityProfileType.PARTNERSHIP]: defaultPartnershipEquityChartOfAccounts,
    [EntityProfileType.NO_LEGAL_ENTITY]: defaultSolePropEquityChartOfAccounts,
    [EntityProfileType.SMLLC]: defaultSolePropEquityChartOfAccounts,
    [EntityProfileType.MMLLC]: defaultPartnershipEquityChartOfAccounts,
};

export const mappingFinancialTypeToFinancialAccountGroup: Record<
    FinancialAccountType,
    FinancialAccountsGroup
> = {
    [FinancialAccountType.CHECKING]:
        defaultCashChartOfAccountsFinancialAccountGroups[
            AccountSubtype.bankAccount
        ],
    [FinancialAccountType.SAVINGS]:
        defaultCashChartOfAccountsFinancialAccountGroups[
            AccountSubtype.bankAccount
        ],
    [FinancialAccountType.OTHER]:
        defaultCashChartOfAccountsFinancialAccountGroups[
            AccountSubtype.bankAccount
        ],
    [FinancialAccountType.INVESTMENT]:
        defaultCashChartOfAccountsFinancialAccountGroups[
            AccountSubtype.bankAccount
        ],
    [FinancialAccountType.LOAN]:
        defaultCashChartOfAccountsFinancialAccountGroups[
            AccountSubtype.creditCard
        ],
    [FinancialAccountType.CREDIT_CARD]:
        defaultCashChartOfAccountsFinancialAccountGroups[
            AccountSubtype.creditCard
        ],
    [FinancialAccountType.PAYMENT_PROCESSOR]:
        defaultCashChartOfAccountsFinancialAccountGroups[
            AccountSubtype.paymentProcessor
        ],
};

export const defaultCashChartOfAccounts: ChartOfAccountsTypesWithDynamicNumberingTypeNew =
    [
        // 1) CASH (codes ~10000–10999)
        {
            typeKey: AccountType.cash,
            startFrom: 10000,
            upTo: 10999,
            endFrom: 10900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.cashOnHand,
                    accounts: [
                        {
                            name: "Cash on Hand",
                        },
                    ],
                },
                {
                    key: AccountSubtype.bankAccount,
                    accountsAreAutomaticallyGenerated: true,
                },
                {
                    key: AccountSubtype.paymentProcessor,
                    accountsAreAutomaticallyGenerated: true,
                },
            ],
            subtypesStartFromEnd: [
                {
                    key: AccountSubtype.transferClearing,
                    accounts: [
                        {
                            name: "Bank Transfer Clearing",
                        },
                        {
                            name: "Payment Transfer Clearing",
                        },
                    ],
                },
            ],
        },

        // 2) ACCOUNTS RECEIVABLE (11000–11999)
        {
            typeKey: AccountType.accountsReceivable,
            startFrom: 11000,
            upTo: 11999,
            endFrom: 11900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.accountsReceivable,
                    accounts: [
                        {
                            name: "Accounts Receivable",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [
                {
                    key: AccountSubtype.allowanceForBadDebts,
                    accounts: [
                        {
                            name: "Allowance for Bad Debts",
                        },
                    ],
                },
            ],
        },

        // 3) INVENTORY (12000–12999)
        {
            typeKey: AccountType.inventory,
            startFrom: 12000,
            upTo: 12999,
            endFrom: 12900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.inventory,
                    accounts: [
                        {
                            name: "Inventory",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [],
        },

        // 4) OTHER CURRENT ASSETS (13000–13999)
        {
            typeKey: AccountType.otherCurrentAssets,
            startFrom: 13000,
            upTo: 13999,
            endFrom: 13900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.prepaidExpenses,
                    accounts: [
                        {
                            name: "Prepaid Expenses",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [
                {
                    key: AccountSubtype.otherCurrentAssets,
                    accounts: [
                        {
                            name: "Other Current Assets",
                        },
                    ],
                },
            ],
        },

        // 5) INVESTMENTS (14000–14999)
        {
            typeKey: AccountType.investments,
            startFrom: 14000,
            upTo: 14999,
            endFrom: 14900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.usGovernmentObligations,
                    accounts: [
                        {
                            name: "U.S. Government Obligations",
                        },
                    ],
                },
                {
                    key: AccountSubtype.taxExemptSecurities,
                    accounts: [
                        {
                            name: "Tax-Exempt Securities",
                        },
                    ],
                },
                {
                    key: AccountSubtype.investments,
                    accounts: [
                        {
                            name: "Investments",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [],
        },

        // 6) FIXED ASSETS (15000–15999)
        {
            typeKey: AccountType.fixedAssets,
            startFrom: 15000,
            upTo: 15999,
            endFrom: 15900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.land,
                    accounts: [
                        {
                            name: "Land",
                        },
                    ],
                },
                {
                    key: AccountSubtype.buildings,
                    accounts: [
                        {
                            name: "Buildings",
                        },
                    ],
                },
                {
                    key: AccountSubtype.leaseholds,
                    accounts: [
                        {
                            name: "Leasehold Improvements",
                        },
                    ],
                },
                {
                    key: AccountSubtype.vehicles,
                    accounts: [
                        {
                            name: "Vehicles",
                        },
                    ],
                },
                {
                    key: AccountSubtype.equipment,
                    accounts: [
                        {
                            name: "Equipment",
                        },
                    ],
                },
                {
                    key: AccountSubtype.furnitureAndFixtures,
                    accounts: [
                        {
                            name: "Furniture and Fixtures",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [
                {
                    key: AccountSubtype.accumulatedDepreciation,
                    accounts: [
                        {
                            name: "Accumulated Depreciation",
                        },
                    ],
                },
                {
                    key: AccountSubtype.otherFixedAssets,
                    accounts: [
                        {
                            name: "Other Fixed Assets",
                        },
                    ],
                },
            ],
        },

        // 7) INTANGIBLE ASSETS (16000–16999)
        {
            typeKey: AccountType.intangibleAssets,
            startFrom: 16000,
            upTo: 16999,
            endFrom: 16900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.startupCosts,
                    accounts: [
                        {
                            name: "Startup Costs",
                        },
                    ],
                },
                {
                    key: AccountSubtype.trademarksAndPatents,
                    accounts: [
                        {
                            name: "Trademarks and Patents",
                        },
                    ],
                },
                {
                    key: AccountSubtype.goodwill,
                    accounts: [
                        {
                            name: "Goodwill",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [
                {
                    key: AccountSubtype.accumulatedAmortization,
                    accounts: [
                        {
                            name: "Accumulated Amortization",
                        },
                    ],
                },
                {
                    key: AccountSubtype.otherIntangibleAssets,
                    accounts: [
                        {
                            name: "Other Intangible Assets",
                        },
                    ],
                },
            ],
        },

        // OTHER ASSETS (19000–19999)
        {
            typeKey: AccountType.otherAssets,
            startFrom: 19000,
            upTo: 19999,
            endFrom: 19900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.intercompanyReceivables,
                    accounts: [
                        {
                            name: "Intercompany Receivables",
                        },
                    ],
                },
                {
                    key: AccountSubtype.employeeLoans,
                    accounts: [
                        {
                            name: "Loans to Employees",
                        },
                    ],
                },
                {
                    if: isSolePropLedger,
                    key: AccountSubtype.loansToOwner,
                    accounts: [
                        {
                            name: "Loans to Owner",
                        },
                    ],
                },
                {
                    if: isCorporationLedger,

                    key: AccountSubtype.loansToShareholders,
                    accounts: [
                        {
                            name: "Loans to Shareholders",
                        },
                    ],
                },
                {
                    if: isPartnershipLedger,
                    key: AccountSubtype.loansToPartners,
                    accounts: [
                        {
                            name: "Loans to Partners",
                        },
                    ],
                },
                {
                    key: AccountSubtype.mortgageAndRealEstateLoans,
                    accounts: [
                        {
                            name: "Mortgage and Real Estate Loans",
                        },
                    ],
                },
                {
                    key: AccountSubtype.securityDeposits,
                    accounts: [
                        {
                            name: "Security Deposits",
                        },
                    ],
                },
                {
                    key: AccountSubtype.depletableAssets,
                    accounts: [
                        {
                            name: "Depletable Assets",
                        },
                    ],
                },
                {
                    key: AccountSubtype.accumulatedDepletion,
                    accounts: [
                        {
                            name: "Accumulated Depletion",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [
                {
                    key: AccountSubtype.otherLongTermAssets,
                    accounts: [
                        {
                            name: "Other Long-Term Assets",
                        },
                    ],
                },
            ],
        },

        // 8) ACCOUNTS PAYABLE (20000–20999)
        {
            typeKey: AccountType.accountsPayable,
            startFrom: 20000,
            upTo: 20999,
            endFrom: 20900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.accountsPayable,
                    accounts: [
                        {
                            name: "Accounts Payable",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [],
        },

        // 9) CREDIT CARDS (21000–21999)
        {
            typeKey: AccountType.creditCards,
            startFrom: 21000,
            upTo: 21999,
            endFrom: 21900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.creditCard,
                    accountsAreAutomaticallyGenerated: true,
                },
            ],
            subtypesStartFromEnd: [
                {
                    key: AccountSubtype.creditCardClearing,
                    accounts: [
                        {
                            name: "Credit Card Clearing",
                        },
                    ],
                },
            ],
        },

        // 10) PAYROLL LIABILITIES (22000–22999)
        {
            typeKey: AccountType.payrollLiabilities,
            startFrom: 22000,
            upTo: 22999,
            endFrom: 22900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.payrollPayable,
                    accounts: [
                        {
                            name: "Payroll Payable",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [],
        },

        // 11) SHORT-TERM LOANS (23000–23999)
        {
            typeKey: AccountType.shortTermLoans,
            startFrom: 23000,
            upTo: 23999,
            endFrom: 23900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.lineOfCredit,
                    accounts: [
                        {
                            name: "Line of Credit",
                        },
                    ],
                },
                {
                    key: AccountSubtype.shortTermLoans,
                    accounts: [
                        {
                            name: "Short-Term Loans",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [
                {
                    key: AccountSubtype.currentMaturitiesOfLongTermDebt,
                    accounts: [
                        {
                            name: "Current Maturities of Long-Term Debt",
                        },
                    ],
                },
            ],
        },

        // 12) OTHER CURRENT LIABILITIES (24000–24999)
        {
            typeKey: AccountType.otherCurrentLiabilities,
            startFrom: 24000,
            upTo: 24999,
            endFrom: 24900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.salesTaxPayable,
                    accounts: [
                        {
                            name: "Sales Tax Payable",
                        },
                    ],
                },
                {
                    key: AccountSubtype.deferredRevenue,
                    accounts: [
                        {
                            name: "Deferred Revenue",
                        },
                    ],
                },
                {
                    key: AccountSubtype.accruedExpenses,
                    accounts: [
                        {
                            name: "Accrued Expenses",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [
                {
                    key: AccountSubtype.otherCurrentLiabilities,
                    accounts: [
                        {
                            name: "Other Current Liabilities",
                        },
                    ],
                },
            ],
        },

        // LONG-TERM LOANS (25000–25999)
        {
            typeKey: AccountType.longTermLoans,
            startFrom: 25000,
            upTo: 25999,
            endFrom: 25900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.longTermLoans,
                    accounts: [
                        {
                            name: "Long-Term Loans",
                        },
                    ],
                },
                {
                    if: isSolePropLedger,
                    key: AccountSubtype.loansFromOwner,
                    accounts: [
                        {
                            name: "Loans from Owner",
                        },
                    ],
                },
                {
                    if: isCorporationLedger,
                    key: AccountSubtype.loansFromShareholders,
                    accounts: [
                        {
                            name: "Loans from Shareholders",
                        },
                    ],
                },
                {
                    if: isPartnershipLedger,
                    key: AccountSubtype.loansFromPartners,
                    accounts: [
                        {
                            name: "Loans from Partners",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [
                {
                    key: AccountSubtype.lessCurrentMaturitiesOfLongTermDebt,
                    accounts: [
                        {
                            name: "Less Current Maturities of Long-Term Debt",
                        },
                    ],
                },
            ],
        },

        // 13) OTHER LIABILITIES (29000–29999)
        {
            typeKey: AccountType.otherLiabilities,
            startFrom: 29000,
            upTo: 29999,
            endFrom: 29900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.intercompanyPayables,
                    accounts: [
                        {
                            name: "Intercompany Payables",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [
                {
                    key: AccountSubtype.otherLiabilities,
                    accounts: [
                        {
                            name: "Other Long-Term Liabilities",
                        },
                    ],
                },
            ],
        },

        // EQUITY (30000–39999)
        {
            typeKey: AccountType.equity,
            startFrom: 30000,
            upTo: 39999,
            endFrom: 39900,
            offset: 1000,
            subtypesStartFromBeginning: [
                {
                    if: isSolePropLedger,
                    key: AccountSubtype.contributions,
                    accounts: [
                        {
                            name: "Owner Investments",
                        },
                    ],
                },
                {
                    if: isSolePropLedger,
                    key: AccountSubtype.distributions,
                    accounts: [
                        {
                            name: "Owner Draws",
                        },
                    ],
                },
                {
                    if: isSolePropLedger,
                    key: AccountSubtype.capital,
                    accounts: [
                        {
                            name: "Owner Capital",
                        },
                    ],
                },
                {
                    if: isPartnershipLedger,
                    key: AccountSubtype.contributions,
                    accounts: [
                        {
                            name: "Contributions",
                        },
                    ],
                },
                {
                    if: isPartnershipLedger,
                    key: AccountSubtype.distributions,
                    accounts: [
                        {
                            name: "Distributions",
                        },
                    ],
                },
                {
                    if: isPartnershipLedger,
                    key: AccountSubtype.capital,
                    accounts: [
                        {
                            name: "Capital",
                        },
                    ],
                },
                {
                    if: isCorporationLedger,
                    key: AccountSubtype.commonStock,
                    accounts: [
                        {
                            name: "Common Stock",
                        },
                    ],
                },
                {
                    if: isCorporationLedger,
                    key: AccountSubtype.additionalPaidInCapital,
                    accounts: [
                        {
                            name: "Additional Paid-In Capital",
                        },
                    ],
                },
                {
                    if: isCorporationLedger,
                    key: AccountSubtype.contributions,
                    accounts: [
                        {
                            name: "Contributions",
                        },
                    ],
                },
                {
                    if: isCorporationLedger,
                    key: AccountSubtype.distributions,
                    accounts: [
                        {
                            name: "Distributions",
                        },
                    ],
                },
                {
                    if: isCorporationLedger,
                    key: AccountSubtype.dividends,
                    accounts: [
                        {
                            name: "Dividends",
                        },
                    ],
                },
                {
                    if: isCorporationLedger,
                    key: AccountSubtype.retainedEarnings,
                    accounts: [
                        {
                            name: "Retained Earnings",
                        },
                    ],
                },
                {
                    if: isCorporationLedger,
                    key: AccountSubtype.adjustmentsToShareholdersEquity,
                    accounts: [
                        {
                            name: "Adjustments to Shareholders' Equity",
                        },
                    ],
                },
                {
                    if: isCorporationLedger,
                    key: AccountSubtype.costOfTreasuryStock,
                    accounts: [
                        {
                            name: "Cost of Treasury Stock",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [],
        },

        // 14) INCOME (40000–49999)
        {
            typeKey: AccountType.income,
            startFrom: 40000,
            upTo: 49999,
            endFrom: 49999,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.revenue,
                    accounts: [
                        {
                            name: "Revenue",
                        },
                    ],
                },
                {
                    key: AccountSubtype.refundsAndReturns,
                    accounts: [
                        {
                            name: "Refunds and Returns",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [
                {
                    key: AccountSubtype.uncategorizedIncome,
                    accounts: [
                        {
                            name: "Uncategorized Income",
                        },
                    ],
                },
            ],
        },

        // 15) COGS (50000–50999)
        {
            typeKey: AccountType.cogs,
            startFrom: 50000,
            upTo: 50999,
            endFrom: 50900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.cogsGoodsAndMaterials,
                    accounts: [
                        {
                            name: "COGS - Goods and Materials",
                        },
                    ],
                },
                {
                    key: AccountSubtype.cogsLabor,
                    accounts: [
                        {
                            name: "COGS - Labor",
                        },
                    ],
                },
                {
                    key: AccountSubtype.cogsOther,
                    accounts: [
                        {
                            name: "COGS - Other",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [],
        },

        // PAYROLL EXPENSES (60000–60999)
        {
            typeKey: AccountType.payrollExpenses,
            startFrom: 60000,
            upTo: 60999,
            endFrom: 60900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.salariesAndWages,
                    accounts: [
                        {
                            name: "Salaries and Wages",
                        },
                    ],
                },
                {
                    key: AccountSubtype.contractLabor,
                    accounts: [
                        {
                            name: "Contract Labor",
                        },
                    ],
                },
                {
                    if: isPartnershipLedger,
                    key: AccountSubtype.guaranteedPaymentsToPartners,
                    accounts: [
                        {
                            name: "Guaranteed Payments to Partners",
                        },
                    ],
                },
                {
                    if: isCorporationLedger,
                    key: AccountSubtype.officersCompensation,
                    accounts: [
                        {
                            name: "Officer's Compensation",
                        },
                    ],
                },
                {
                    key: AccountSubtype.commisions,
                    accounts: [
                        {
                            name: "Commissions",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [
                {
                    key: AccountSubtype.salariesAndWagesClearing,
                    accounts: [
                        {
                            name: "Salaries and Wages - Clearing",
                        },
                    ],
                },
            ],
        },

        // 16) PAYROLL TAXES (61000–61999)
        {
            typeKey: AccountType.payrollTaxes,
            startFrom: 61000,
            upTo: 61999,
            endFrom: 61900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.payrollTaxes,
                    accounts: [
                        {
                            name: "Payroll Taxes",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [],
        },

        // 17) EMPLOYEE BENEFITS (62000–62999)
        {
            typeKey: AccountType.employeeBenefits,
            startFrom: 62000,
            upTo: 62999,
            endFrom: 62900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.employeeBenefits,
                    accounts: [
                        {
                            name: "Employee Benefits",
                        },
                    ],
                },
                {
                    key: AccountSubtype.retirementBenefits,
                    accounts: [
                        {
                            name: "Retirement Benefits",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [],
        },

        // 18) OPERATING EXPENSES (70000–79999)
        {
            typeKey: AccountType.operatingExpenses,
            startFrom: 70000,
            upTo: 79999,
            endFrom: 79999,
            offset: 100,
            offsetEndFrom: (index) => (index === 0 ? 99 : 100),
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.advertisingAndMarketing,
                    accounts: [
                        {
                            name: "Advertising and Marketing",
                        },
                    ],
                },
                {
                    key: AccountSubtype.learningAndDevelopment,
                    accounts: [
                        {
                            name: "Learning and Development",
                        },
                    ],
                },
                {
                    key: AccountSubtype.licensesAndPermits,
                    accounts: [
                        {
                            name: "Licenses and Permits",
                        },
                    ],
                },
                {
                    key: AccountSubtype.propertyTaxes,
                    accounts: [
                        {
                            name: "Property Taxes",
                        },
                    ],
                },
                {
                    key: AccountSubtype.entertainment,
                    accounts: [
                        {
                            name: "Entertainment",
                        },
                    ],
                },
                {
                    key: AccountSubtype.insurance,
                    accounts: [
                        {
                            name: "Insurance - Business",
                        },
                        {
                            name: (entityType: MergedEntityType) => {
                                let name = "Insurance - ";
                                if (isSolePropLedger(entityType)) {
                                    name += "Owner";
                                }
                                if (isCorporationLedger(entityType)) {
                                    name += "Officers";
                                }
                                if (isPartnershipLedger(entityType)) {
                                    name += "Partners";
                                }
                                return name;
                            },
                        },
                    ],
                },
                {
                    key: AccountSubtype.internetAndPhone,
                    accounts: [
                        {
                            name: "Internet and Phone",
                        },
                    ],
                },
                {
                    key: AccountSubtype.facilities,
                    accounts: [
                        {
                            name: "Facilities - Repairs and Maintenance",
                        },
                        {
                            name: "Facilities - Cleaning Fees",
                        },
                        {
                            name: "Facilities - Management Fees",
                        },
                    ],
                },
                {
                    key: AccountSubtype.furnitureAndFixtures,
                    accounts: [
                        {
                            name: "Furniture and Fixtures",
                        },
                    ],
                },
                {
                    key: AccountSubtype.meals,
                    accounts: [
                        {
                            name: "Meals",
                        },
                        {
                            name: "Meals - Employee Catering",
                        },
                    ],
                },
                {
                    key: AccountSubtype.merchantAndBankFees,
                    accounts: [
                        {
                            name: "Merchant and Bank Fees",
                        },
                    ],
                },
                {
                    key: AccountSubtype.suppliesAndEquipment,
                    accounts: [
                        {
                            name: "Supplies and Equipment",
                        },
                    ],
                },
                {
                    key: AccountSubtype.softwareAndSubscriptions,
                    accounts: [
                        {
                            name: "Software and Subscriptions",
                        },
                    ],
                },
                {
                    key: AccountSubtype.officeExpenses,
                    accounts: [
                        {
                            name: "Office Expenses",
                        },
                    ],
                },
                {
                    key: AccountSubtype.officeUtilities,
                    accounts: [
                        {
                            name: "Office Utilities",
                        },
                    ],
                },
                {
                    key: AccountSubtype.legalAndProfessionalFees,
                    accounts: [
                        {
                            name: "Legal and Professional Fees",
                        },
                    ],
                },
                {
                    key: AccountSubtype.rentAndLease,
                    accounts: [
                        {
                            name: "Rent and Lease",
                        },
                    ],
                },
                {
                    key: AccountSubtype.travel,
                    accounts: [
                        {
                            name: "Travel",
                        },
                    ],
                },
                {
                    key: AccountSubtype.vehicle,
                    accounts: [
                        {
                            name: "Vehicle",
                        },
                        {
                            name: "Vehicle - Leases",
                        },
                        {
                            name: "Vehicle - Parking and Tolls",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [
                {
                    key: AccountSubtype.uncategorizedExpenses,
                    accounts: [
                        {
                            name: "Uncategorized Expenses",
                        },
                    ],
                },
                {
                    key: AccountSubtype.otherOperatingExpenses,
                    accounts: [
                        {
                            name: "Other Operating Expenses",
                        },
                    ],
                },
            ],
        },

        // 19) OTHER INCOME (80000–84999)
        {
            typeKey: AccountType.otherIncome,
            startFrom: 80000,
            upTo: 84999,
            endFrom: 84900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.rentalIncome,
                    accounts: [
                        {
                            name: "Rental Income",
                        },
                    ],
                },
                {
                    key: AccountSubtype.interestIncome,
                    accounts: [
                        {
                            name: "Interest Income",
                        },
                    ],
                },
                {
                    key: AccountSubtype.dividendIncome,
                    accounts: [
                        {
                            name: "Dividend Income",
                        },
                    ],
                },
                {
                    key: AccountSubtype.rebateIncome,
                    accounts: [
                        {
                            name: "Rebate Income",
                        },
                    ],
                },
                {
                    key: AccountSubtype.royaltyIncome,
                    accounts: [
                        {
                            name: "Royalty Income",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [
                {
                    key: AccountSubtype.otherIncome,
                    accounts: [
                        {
                            name: "Other Income",
                        },
                    ],
                },
                {
                    key: AccountSubtype.gainLossAssetDisposals,
                    accounts: [
                        {
                            name: "Gain (Loss) Asset Disposals",
                        },
                    ],
                },
            ],
        },

        // 20) OTHER EXPENSES (85000–89999)
        {
            typeKey: AccountType.otherExpenses,
            startFrom: 85000,
            upTo: 89999,
            endFrom: 89900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.interestExpense,
                    accounts: [
                        {
                            name: "Interest Expense",
                        },
                    ],
                },
                {
                    key: AccountSubtype.depreciation,
                    accounts: [
                        {
                            name: "Depreciation",
                        },
                    ],
                },
                {
                    key: AccountSubtype.amortization,
                    accounts: [
                        {
                            name: "Amortization",
                        },
                    ],
                },
                {
                    key: AccountSubtype.depletion,
                    accounts: [
                        {
                            name: "Depletion",
                        },
                    ],
                },
                {
                    key: AccountSubtype.finesAndPenalties,
                    accounts: [
                        {
                            name: "Fines and Penalties",
                        },
                    ],
                },
                {
                    key: AccountSubtype.badDebts,
                    accounts: [
                        {
                            name: "Bad Debts",
                        },
                    ],
                },
                {
                    key: AccountSubtype.donations,
                    accounts: [
                        {
                            name: "Donations",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [
                {
                    key: AccountSubtype.otherExpense,
                    accounts: [
                        {
                            name: "Other Expense",
                        },
                    ],
                },
            ],
        },

        // 21) TAX EXPENSES (90000–90999)
        {
            typeKey: AccountType.taxExpenses,
            startFrom: 90000,
            upTo: 90999,
            endFrom: 90900,
            offset: 100,
            subtypesStartFromBeginning: [
                {
                    key: AccountSubtype.federalTaxes,
                    accounts: [
                        {
                            name: "Federal Taxes",
                        },
                    ],
                },
                {
                    key: AccountSubtype.stateAndLocalTaxes,
                    accounts: [
                        {
                            name: "State and Local Taxes",
                        },
                    ],
                },
            ],
            subtypesStartFromEnd: [],
        },
    ];

interface SubtypeBorders {
    startFrom: number;
    upTo: number;
}

type SubtypeBordersForEntityType = Map<
    MergedAccountType,
    {
        startFrom: number;
        upTo: number;
        subtypeBorders: Map<MergedAccountSubtype, SubtypeBorders>;
    }
>;

function generateSubtypeBordersForEntityType(
    entityType: MergedEntityType,
    scheme: ChartOfAccountsTypesWithDynamicNumberingTypeNew,
) {
    return scheme.reduce((bordersMap, type) => {
        const accountSubtypeMap = new Map<
            MergedAccountSubtype,
            { startFrom: number; upTo: number }
        >();
        let currentStartFrom = type.startFrom;

        const { subtypesStartFromBeginning, subtypesStartFromEnd } =
            getSubtypesInsideType(entityType, type.typeKey);

        subtypesStartFromBeginning.forEach((subtype) => {
            accountSubtypeMap.set(subtype.key, {
                startFrom: currentStartFrom,
                upTo: currentStartFrom + type.offset - 1,
            });
            currentStartFrom += type.offset;
        });
        currentStartFrom = type.endFrom;
        let previousStartFrom = type.upTo;
        subtypesStartFromEnd.forEach((subtype, index) => {
            accountSubtypeMap.set(subtype.key, {
                startFrom: currentStartFrom,
                upTo: previousStartFrom,
            });

            previousStartFrom = currentStartFrom - 1;
            currentStartFrom =
                currentStartFrom -
                (type.offsetEndFrom ? type.offsetEndFrom(index) : type.offset);
        });
        bordersMap.set(type.typeKey, {
            subtypeBorders: accountSubtypeMap,
            startFrom: type.startFrom,
            upTo: type.upTo,
        });
        return bordersMap;
    }, new Map() as SubtypeBordersForEntityType);
}

const subtypeBordersForSCorp = generateSubtypeBordersForEntityType(
    EntityProfileType.S_CORP,
    defaultCashChartOfAccounts,
);

const subtypeBordersForSoleProp = generateSubtypeBordersForEntityType(
    EntityProfileType.SOLE_PROPRIETORSHIP,
    defaultCashChartOfAccounts,
);

const subtypeBordersForPartnership = generateSubtypeBordersForEntityType(
    EntityProfileType.PARTNERSHIP,
    defaultCashChartOfAccounts,
);

export function getSubtypeBordersInCoA(
    entityType: MergedEntityType,
    accountType: MergedAccountType,
    accountSubtype: MergedAccountSubtype,
) {
    let result: SubtypeBorders | undefined;
    if (isSolePropLedger(entityType)) {
        result = subtypeBordersForSoleProp
            .get(accountType)
            ?.subtypeBorders.get(accountSubtype);
    } else if (isCorporationLedger(entityType)) {
        result = subtypeBordersForSCorp
            .get(accountType)
            ?.subtypeBorders.get(accountSubtype);
    } else if (isPartnershipLedger(entityType)) {
        result = subtypeBordersForPartnership
            .get(accountType)
            ?.subtypeBorders.get(accountSubtype);
    }
    if (!result) {
        throw new Error(
            `Subtype borders not found for entity type ${entityType} and type ${accountType} and subtype ${accountSubtype}`,
        );
    }
    return result;
}

export function getSubtypesInsideType(
    entityType: MergedEntityType,
    accountType: MergedAccountType,
) {
    const foundTypeDeclaration = defaultCashChartOfAccounts.find(
        (t) => t.typeKey === accountType,
    );
    if (!foundTypeDeclaration) {
        throw new Error(`Type ${accountType} not found`);
    }
    return {
        subtypesStartFromBeginning:
            foundTypeDeclaration.subtypesStartFromBeginning.filter(
                (s) => !s.if || s.if(entityType),
            ),
        subtypesStartFromEnd: foundTypeDeclaration.subtypesStartFromEnd.filter(
            (s) => !s.if || s.if(entityType),
        ),
    };
}

export type ChartOfAccountsItemNameStringOrFunction =
    | string
    | ((entityType: MergedEntityType) => string);

export interface ChartOfAccountsItem {
    name: ChartOfAccountsItemNameStringOrFunction;
    type: AccountType;
    subtype: AccountSubtype;
}

export type ChartOfAccounts<T extends number> = Record<T, ChartOfAccountsItem>;

export interface ChartOfAccountsItemOptional extends ChartOfAccountsItem {
    if?: (entityType: MergedEntityType) => boolean;
}

export interface ChartOfAccountsTypeWithDynamicNumbering {
    typeKey: AccountType;
    startFrom: number;
    upTo: number;
    endFrom: number;
    offset: number;
    subtypesStartFromBeginning: ChartOfAccountsItemOptional[];
    subtypesStartFromEnd: ChartOfAccountsItemOptional[];
}

export type ChartOfAccountsTypesWithDynamicNumberingType =
    ChartOfAccountsTypeWithDynamicNumbering[];

export interface ChartOfAccountsItemNew {
    name: ChartOfAccountsItemNameStringOrFunction;
}

export interface ChartOfAccountsItemOptionalNew extends ChartOfAccountsItemNew {
    if?: (entityType: MergedEntityType) => boolean;
}

export type SubtypeItem = {
    key: AccountSubtype;
    if?: (entityType: MergedEntityType) => boolean;
} & (
    | {
          accounts: ChartOfAccountsItemOptionalNew[];
          accountsAreAutomaticallyGenerated?: false;
      }
    | {
          accountsAreAutomaticallyGenerated: true;
          accounts?: never;
      }
);

export interface ChartOfAccountsTypeWithDynamicNumberingNew {
    typeKey: AccountType;
    startFrom: number;
    upTo: number;
    endFrom: number;
    offset: number;
    offsetEndFrom?: (index: number) => number;
    subtypesStartFromBeginning: SubtypeItem[];
    subtypesStartFromEnd: SubtypeItem[];
}

export type ChartOfAccountsTypesWithDynamicNumberingTypeNew =
    ChartOfAccountsTypeWithDynamicNumberingNew[];
