import { initContract } from "@ts-rest/core";
import { Account } from "../types/domains/accounting/account";

const c = initContract();

export interface ExtendedAccount extends Account {
    debit: number;
    credit: number;
    balance: number;
}

export interface ChartOfAccountsResponse {
    accounts: ExtendedAccount[];
}

export const accountingReportsContract = c.router(
    {
        getChartOfAccounts: {
            method: "GET",
            path: "/chart-of-accounts/:entityId",
            responses: {
                200: c.type<ChartOfAccountsResponse>(),
            },
        },
    },
    {
        pathPrefix: "/accounting-reports",
    },
);
