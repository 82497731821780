import React from "react";
import { useAccountingState } from "../../hooks/useAccountingState";
import { AccessBlockedPage } from "../billing/AccessBlockedPage/AccessBlockedPage";
import { SubmitOpeningBalancesAction } from "../billing/AccessBlockedPage/SubmitOpeningBalancesAction";

interface ReportAccessProps {
    previewUrl: string;
    report: string;
    renderReportPage: () => React.ReactElement;
    alwaysAccessible?: boolean;
}

export const ReportAccess: React.FC<ReportAccessProps> = ({
    report,
    previewUrl,
    renderReportPage,
    alwaysAccessible,
}) => {
    const { openingBalancesSubmitted, reportsReady } = useAccountingState();

    if (alwaysAccessible) {
        return renderReportPage();
    }

    if (!openingBalancesSubmitted) {
        return (
            <AccessBlockedPage
                headline={
                    <>Upload opening balances to get access to your {report}</>
                }
                imageUrl={previewUrl}
                actions={<SubmitOpeningBalancesAction />}
            />
        );
    } else if (!reportsReady) {
        return (
            <AccessBlockedPage
                headline={<>We're working on your {report}</>}
                imageUrl={previewUrl}
            />
        );
    } else {
        return renderReportPage();
    }
};
