import React, { createContext, useContext, useMemo } from "react";
import { ChildrenProps } from "../../../types";
import { Entity } from "../../../common/types/entity";

export interface AccountsConnectionContextValue {
    showAccountTypeWarning: boolean;
    defaultEntity?: Entity;
}

export interface AccountsConnectionProviderProps extends ChildrenProps {
    showAccountTypeWarning: boolean;
    defaultEntity?: Entity;
}

const AccountsConnectionContext = createContext<AccountsConnectionContextValue>(
    {
        showAccountTypeWarning: false,
        defaultEntity: undefined,
    },
);

export function useAccountsConnectionContext() {
    const ctx = useContext(AccountsConnectionContext);

    if (!ctx) {
        throw new Error("Show only be used inside AccountsConnectionProvider ");
    }

    return ctx;
}

export const AccountsConnectionProvider: React.FC<
    AccountsConnectionProviderProps
> = ({ children, showAccountTypeWarning, defaultEntity }) => {
    const value = useMemo(
        () => ({
            showAccountTypeWarning,
            defaultEntity,
        }),
        [showAccountTypeWarning, defaultEntity],
    );

    return (
        <AccountsConnectionContext.Provider value={value}>
            {children}
        </AccountsConnectionContext.Provider>
    );
};
