import React, { useContext } from "react";
import { PaginatedTransactionsTableContext } from "./PaginatedTransactionsTableContextProvider";
import { TransactionsTable, TransactionsTableProps } from "./TransactionsTable";

export interface PaginatedTransactionsTableProps
    extends Omit<
        TransactionsTableProps,
        "sort" | "onSortChange" | "transactions" | "shownTransactionId"
    > {
    footerWidth?: number;
}

export const PaginatedTransactionsTable: React.FC<
    PaginatedTransactionsTableProps
> = (props) => {
    const { handleSortChange, currentSort, transactions, shownTransactionId } =
        useContext(PaginatedTransactionsTableContext);

    return (
        <TransactionsTable
            sort={currentSort}
            onSortChange={handleSortChange}
            transactions={transactions}
            shownTransactionId={shownTransactionId}
            {...props}
            showFooter
        />
    );
};
