import React, { useState } from "react";
import { Form, Formik, FormikConfig } from "formik";
import { object, string } from "yup";
import { submitHelper } from "../../../helpers/form";
import { FormRow } from "../../forms/FormRow";
import { Button } from "../../general/Button/Button";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";

export interface AddReceiptsEmailProps {
    onAddEmail(email: string): Promise<void>;
    onCancel(): void;
}

export const AddReceiptsEmail: React.FC<AddReceiptsEmailProps> = ({
    onAddEmail,
    onCancel,
}) => {
    const [loading, setLoading] = useState(false);

    const form: FormikConfig<{ email: string }> = {
        initialValues: {
            email: "",
        },
        validationSchema: object().shape({
            email: string()
                .required("Email is required")
                .email("Please provide a valid email address"),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: ({ email }) => onAddEmail(email),
        }),
    };

    return (
        <Formik {...form}>
            {({ isValid }) => (
                <Form>
                    <FormRow
                        fieldName="email"
                        fieldType="email"
                        label="Email Address"
                    />

                    <footer className="d-flex justify-content-end m-0 gap-2">
                        <Button
                            variant="secondary"
                            onClick={onCancel}
                            size="lg"
                        >
                            Cancel
                        </Button>

                        <ButtonWithLoader
                            disabled={!isValid}
                            loading={loading}
                            type="submit"
                            size="lg"
                        >
                            Add
                        </ButtonWithLoader>
                    </footer>
                </Form>
            )}
        </Formik>
    );
};
