import * as React from "react";
import * as SeparatorPrimitive from "@radix-ui/react-separator";
import classNames from "classnames";
import styles from "./Separator.module.scss";

interface Props
    extends React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root> {
    block?: boolean;
}

const Separator = React.forwardRef<
    React.ElementRef<typeof SeparatorPrimitive.Root>,
    Props
>(
    (
        {
            className,
            orientation = "horizontal",
            decorative = true,
            block,
            ...props
        },
        ref,
    ) => (
        <SeparatorPrimitive.Root
            ref={ref}
            decorative={decorative}
            orientation={orientation}
            className={classNames(
                styles.separator,
                orientation === "horizontal"
                    ? styles.horizontal
                    : styles.vertical,
                block && styles.block,
                className,
            )}
            {...props}
        />
    ),
);

Separator.displayName = SeparatorPrimitive.Root.displayName;

export { Separator };
