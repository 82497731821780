import { useContext, useState } from "react";
import {
    Transaction,
    TransactionListItemDto,
} from "../../../common/types/transaction";
import {
    ActionCardTransaction,
    AmountDisplayFormat,
} from "../../actions/ActionCardTransaction";
import {
    StandardModal,
    StandardModalBody,
    StandardModalHeader,
} from "../../general/Modal/Modal";
import {
    removeTransactionDetailsQueries,
    useTransactionDetailsQuery,
} from "../TransactionDetails/useTransactionDetailsQuery";
import { Loader } from "../../general/Loader";
import { useUpdateTransactionSplitsV2 } from "../../../hooks/useUpdateTransactionSplitsV2";
import { invalidateTransactionsQueries } from "../useTransactionsQuery";
import { UpdateTransactionSplitV2Dto } from "../../../common/dto/transactions/update-transaction-split-v2.dto";
import { UpdateTransactionSplitsV2Dto } from "../../../common/dto/transactions/update-transaction-splits-v2.dto";
import { ConfirmationModal } from "../../general/ConfirmationModal";
import { PaginatedTransactionsTableContext } from "../PaginatedTransactionsTableContextProvider";
import { TransactionSplitContainer } from "./TransactionSplitContainer";
import styles from "./TransactionSplitModal.module.scss";

interface Props {
    transaction?: TransactionListItemDto;
    setTransaction: (transaction: Transaction | undefined) => void;
}

export const TransactionSplitModal: React.FC<Props> = ({
    transaction: originalTransaction,
    setTransaction,
}: Props) => {
    const [showUnsplitConfirmation, setShowUnsplitConfirmation] =
        useState(false);

    const { transaction } = useTransactionDetailsQuery(
        originalTransaction?.splitParentId ?? originalTransaction?.id ?? null,
    );

    const { setShownTransactionId, shownTransactionId } = useContext(
        PaginatedTransactionsTableContext,
    );

    const updateSplitsMutation = useUpdateTransactionSplitsV2({
        onSuccess: (data) => {
            if (
                shownTransactionId &&
                data.splits
                    ?.map((split) => split.id)
                    .includes(shownTransactionId) === false
            ) {
                setShownTransactionId(undefined);
            }
            invalidateTransactionsQueries();
            removeTransactionDetailsQueries();
            setTransaction(undefined);
        },
    });

    const handleFormSubmit = async (values: {
        splits: UpdateTransactionSplitV2Dto[];
    }) => {
        if (!transaction) {
            return;
        }
        const dto: UpdateTransactionSplitsV2Dto = {
            splits: values.splits,
        };

        await updateSplitsMutation.mutateAsync({
            transactionId: transaction.id,
            dto,
        });
    };

    const handleUnsplit = async () => {
        if (!transaction) {
            return;
        }
        const splits: UpdateTransactionSplitV2Dto[] = [
            {
                id: transaction.id,
                amount: transaction.originalAmount,
                entityId: transaction.entity.id,
                splitDescription: transaction.displayDescription ?? "",
                categoryId: transaction.categoryId,
            },
        ];

        await updateSplitsMutation.mutateAsync({
            transactionId: transaction?.id,
            dto: { splits },
        });
        setShowUnsplitConfirmation(false);
    };

    return (
        <>
            <ConfirmationModal
                title="Are you sure that you want to unsplit these transactions?"
                question=""
                show={showUnsplitConfirmation}
                onConfirm={handleUnsplit}
                onReject={() => setShowUnsplitConfirmation(false)}
                onHide={() => setShowUnsplitConfirmation(false)}
                className={styles.unsplitModal}
            />

            <StandardModal
                enforceFocus={false}
                size="xl"
                show={Boolean(originalTransaction) && !showUnsplitConfirmation}
                onHide={() => setTransaction(undefined)}
                onExited={() => setShowUnsplitConfirmation(false)}
            >
                <StandardModalHeader>Split transaction</StandardModalHeader>

                <StandardModalBody className={styles.body}>
                    {transaction ? (
                        <>
                            <div className="pl-4 pb-4">
                                <ActionCardTransaction
                                    className={styles.transaction}
                                    transaction={{
                                        ...transaction,
                                        amount: transaction.originalAmount,
                                    }}
                                    amountDisplayFormat={
                                        AmountDisplayFormat.SIGN
                                    }
                                    showEntity
                                />
                            </div>
                            <TransactionSplitContainer
                                transaction={transaction}
                                onSubmit={handleFormSubmit}
                                onUnsplit={() =>
                                    setShowUnsplitConfirmation(true)
                                }
                                loading={updateSplitsMutation.isPending}
                            />
                        </>
                    ) : (
                        <Loader />
                    )}
                </StandardModalBody>
            </StandardModal>
        </>
    );
};
