import React from "react";
import {
    FLAT_RATE_PLANS,
    flatRatePlanNames,
} from "../../../common/flatRateBilling";
import { Button } from "../../general/Button/Button";
import { ThunderboltIcon } from "../../../icons";
import { useSettings } from "../../settings/SettingsProvider";
import { usePlanManagement } from "../PlanManagement/PlanManagementContext";
import { useBillingStatus } from "../../../hooks/useBillingStatus";

interface Props {
    targetPlan: FLAT_RATE_PLANS;
}

export const NotSubscribedUpgradeActions: React.FC<Props> = ({
    targetPlan,
}) => {
    const { open } = useSettings();

    const { upgrade } = usePlanManagement();
    const { trialAvailable, isTrialing } = useBillingStatus();

    return (
        <>
            <Button
                variant="action"
                onClick={() =>
                    upgrade(targetPlan, trialAvailable || isTrialing)
                }
            >
                <ThunderboltIcon />
                Upgrade to {flatRatePlanNames[targetPlan]}
            </Button>

            <Button variant="secondary" onClick={() => open(["plans"])}>
                View all plans
            </Button>
        </>
    );
};
