import classNames from "classnames";
import { EagerLoaded } from "../../../../common/types/base/orm";
import { Transaction } from "../../../../common/types/transaction";
import { TransactionMatch } from "../../../../common/types/transactionMatch";
import { formatDateForTransaction } from "../../../../helpers/date";
import { Amount } from "../../../general/Amount/Amount";
import styles from "./TransactionMatches.module.scss";

interface Props {
    transactionMatch: EagerLoaded<TransactionMatch, "invoice">;
    financialTransaction: Transaction;
}

export const TransactionMatchInvoice: React.FC<Props> = ({
    transactionMatch,
}) => {
    const { invoice } = transactionMatch;
    return (
        <div
            className={classNames(
                "form-group form-group--with-label form-group--inside-label form-group--icon form-group--icon--right",
            )}
        >
            <div
                className={classNames(
                    "form-control",
                    styles.invoiceMatchButton,
                )}
            >
                <span className="form-group__label">Matched invoice</span>
            </div>
            <div className={styles.cardFooter}>
                <div className={styles.cardFooterText}>
                    <span>
                        {formatDateForTransaction(new Date(invoice.createdAt))}
                    </span>
                    <span className={styles.truncatedText}>
                        {invoice.counterparty?.name}
                    </span>
                </div>
                <div className={classNames(styles.cardFooterText)}>
                    <span
                        className={classNames(
                            styles.cardFooterTextAmount,
                            styles.cardFooterTextAmountPositive,
                        )}
                    >
                        <Amount amount={invoice.total} separateCurrencySign />
                    </span>
                </div>
            </div>
        </div>
    );
};
