import { useMutation } from "@tanstack/react-query";
import { Entity } from "../common/types/entity";
import {
    getPayrollQueryKey,
    gustoConnectionsQueryKey,
    queryClient,
} from "../queryClient";
import { StripeConnection } from "../common/types/stripeConnection";

import {
    createGustoConnection,
    fixGustoConnection,
    syncGustoConnection,
} from "../lib/gusto";
import { GustoConnection } from "../common/types/gustoConnection";
import { FinancialConnection } from "../common/types/financialConnection";
import {
    addFinancialConnectionInQueryData,
    clearConnectionErrorInQueryData,
    entitiesAccountsQueryKey,
} from "../queries/entitiesAccounts";
import { useConnectAccount } from "../components/connectAccount/ConnectAccount.context";
import { useToaster } from "../components/general/ToastMessages/useToaster";

interface GustoConnectionCreationPayload {
    code: string;
    defaultEntity?: Entity;
}
export function useGustoConnectionCreationMutation(onSuccess?: () => void) {
    const { updateConnectAccountState } = useConnectAccount();
    return useMutation({
        mutationFn: ({ code, defaultEntity }: GustoConnectionCreationPayload) =>
            createGustoConnection({
                code,
                defaultEntityId: defaultEntity?.id,
            }),
        onSuccess: (connection) => {
            queryClient.setQueryData(gustoConnectionsQueryKey, [
                ...(queryClient.getQueryData<StripeConnection[]>(
                    gustoConnectionsQueryKey,
                ) ?? []),
                connection,
            ]);
            addFinancialConnectionInQueryData(queryClient, connection);
            if (!connection.accounts[0].financialAccount.entityId) {
                updateConnectAccountState({
                    isConnecting: false,
                    connection,
                    connectionSaved: true,
                    foreign: [],
                });
            }
            onSuccess?.();
        },
    });
}

export function useSyncGustoConnectionMutation(entity: Entity) {
    const { toast } = useToaster();
    return useMutation({
        mutationFn: (connection: GustoConnection) =>
            syncGustoConnection(connection),

        onSuccess: async (result) => {
            const key = getPayrollQueryKey(entity.id);
            await queryClient.invalidateQueries({ queryKey: key });
            queryClient.setQueryData(gustoConnectionsQueryKey, [
                ...(queryClient.getQueryData<StripeConnection[]>(
                    gustoConnectionsQueryKey,
                ) ?? []),
                result,
            ]);
            queryClient.invalidateQueries({
                queryKey: [entitiesAccountsQueryKey],
            });
            toast(`Connection synced successfully`);
        },
        onError: () => {
            queryClient.invalidateQueries({
                queryKey: [entitiesAccountsQueryKey],
            });
            queryClient.invalidateQueries({
                queryKey: [gustoConnectionsQueryKey],
            });
        },
    });
}

interface FixGustoConnectionPayload {
    connection: FinancialConnection;
    code: string;
}
export function useFixGustoConnectionsMutation(onSuccess?: () => unknown) {
    return useMutation({
        mutationFn: (payload: FixGustoConnectionPayload) =>
            fixGustoConnection(
                payload.connection as GustoConnection,
                payload.code,
            ),

        onSuccess: (connected) => {
            if (!connected) {
                return;
            }
            clearConnectionErrorInQueryData(queryClient, connected.id);
            onSuccess?.();
            queryClient.setQueryData<FinancialConnection[] | undefined>(
                gustoConnectionsQueryKey,
                (connections) =>
                    connections?.map((c) =>
                        c.id === connected.id
                            ? {
                                  ...c,
                                  accounts: connected.accounts,
                                  error: null,
                              }
                            : c,
                    ) ?? [],
            );
        },
    });
}
