import React from "react";
import { FinancialConnection } from "../../common/types/financialConnection";
import { FinancialAccount } from "../../common/types/financialAccount";
import { isPlaidConnection } from "../../common/helpers/financialConnection";
import { PlaidConnectionIcon } from "../plaid/PlaidConnectionIcon";
import { FinancialAccountIcon } from "../financialAccount/FinancialAccountIcon";
import { IntegrationConnection } from "../../common/types/integrationConnection";

interface Props {
    connection?: FinancialConnection | IntegrationConnection;
    account: FinancialAccount;
}

export const FinancialConnectionIcon: React.FC<Props> = ({
    connection,
    account,
}) => {
    if (
        connection &&
        "connectionProvider" in connection &&
        isPlaidConnection(connection)
    ) {
        return <PlaidConnectionIcon connection={connection} />;
    }

    return <FinancialAccountIcon account={account} />;
};
