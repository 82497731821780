import React, { useCallback, useContext } from "react";
import { difference } from "lodash";
import { Transaction } from "../../../common/types/transaction";
import {
    CATEGORIZE_ACTION,
    REMOVE_REAL_ESTATE_CATEGORY_ACTION,
    TRANSFER_MATCH_ACTION,
} from "../../../common/requiredActions";
import { TransactionActionsRequiredNumber } from "../TransactionActionsRequiredNumber";
import { TaxQuestion, taxQuestions } from "../../../common/taxSavingRules";
import { SelectedAnswer } from "../TaxQuestions/types";
import { EagerLoaded } from "../../../common/types/base/orm";
import { Taxonomy } from "../../../common/categories";
import { TransactionDetailsSection } from "./TransactionDetailsSection";
import { TransactionDetailsContext } from "./transactionDetails.context";
import { TransactionDetailsMemo } from "./TransactionDetailsMemo";
import { TransactionDetailsReceipt } from "./TransactionDetailsReceipt";
import { TransactionTaxQuestions } from "./TransactionTaxQuestions";
import { TransactionIgnoredMessage } from "./TransactionIgnoredMessage";
import { TransactionRequirementsIgnoredMessage } from "./TransactionRequirementsIgnoredMessage";
import { TransactionActionsMatchTransfer } from "./TransactionActions/TransactionActionMatchTransfer";

interface Props {
    transaction: EagerLoaded<
        Transaction,
        "transactionMatches" | "pairedTransfer" | "category"
    >;
}

export const TransactionActionsSection: React.FC<Props> = ({ transaction }) => {
    const { onUpdate, memoRequired, receiptRequired } = useContext(
        TransactionDetailsContext,
    );

    const transferMatchRequired = transaction.requiredActions.includes(
        TRANSFER_MATCH_ACTION,
    );

    const taxQuestionsForTransaction =
        taxQuestions[transaction.date.getFullYear()][
            transaction.category.taxonomy
        ];
    const applicableTaxQuestions = transaction.taxQuestions
        .map((key) => taxQuestionsForTransaction.find((q) => q.key === key))
        .filter(Boolean) as TaxQuestion[];

    const nonCategoryRequiredActions = difference(transaction.requiredActions, [
        REMOVE_REAL_ESTATE_CATEGORY_ACTION,
        CATEGORIZE_ACTION,
    ]).length;
    const answerTaxQuestionRequired = applicableTaxQuestions.length > 0;

    const hasRequiredFields = Boolean(
        memoRequired ||
            receiptRequired ||
            answerTaxQuestionRequired ||
            transferMatchRequired,
    );
    const hasOptionalFields = Boolean(!memoRequired || !receiptRequired);

    const onAnswer = useCallback(
        (answers: SelectedAnswer[]) => {
            void onUpdate({ taxQuestionAnswers: answers });
        },
        [onUpdate],
    );

    const shouldShowMatchTransfer = [
        Taxonomy.transfer,
        Taxonomy.contributions_distributions,
        Taxonomy.credit_card_payment,
        Taxonomy.personal,
    ].includes(transaction.category.taxonomy);

    return (
        <TransactionDetailsSection
            title={
                <>
                    {nonCategoryRequiredActions > 0 && (
                        <TransactionActionsRequiredNumber
                            value={nonCategoryRequiredActions}
                        />
                    )}
                    Actions
                </>
            }
            className="transaction-details__tax-savings"
            forceOpened={
                hasRequiredFields ||
                transaction.isIgnored ||
                transaction.requirementsSnoozed
            }
        >
            {transaction.isIgnored && <TransactionIgnoredMessage />}
            {transaction.requirementsSnoozed &&
                !transaction.isIgnored &&
                transaction.isBusiness && (
                    <TransactionRequirementsIgnoredMessage />
                )}
            {hasRequiredFields && <div className="label mb-2">Required</div>}
            <TransactionTaxQuestions
                transaction={transaction}
                onAnswer={onAnswer}
                questions={applicableTaxQuestions}
            />
            {shouldShowMatchTransfer && (
                <TransactionActionsMatchTransfer
                    transaction={transaction}
                    required={transferMatchRequired}
                />
            )}
            {memoRequired && (
                <TransactionDetailsMemo
                    transaction={transaction}
                    required={!transaction.requirementsSnoozed}
                />
            )}
            {receiptRequired && (
                <TransactionDetailsReceipt
                    transaction={transaction}
                    required={!transaction.requirementsSnoozed}
                />
            )}

            {hasOptionalFields && <div className="label mb-2">Optional</div>}
            {!memoRequired && (
                <TransactionDetailsMemo
                    transaction={transaction}
                    required={false}
                />
            )}
            {!receiptRequired && (
                <TransactionDetailsReceipt
                    transaction={transaction}
                    required={false}
                />
            )}
        </TransactionDetailsSection>
    );
};
