import React from "react";
import { currencyFormatterShort } from "../../../../../common/helpers/currency";

interface Props {
    filters: {
        minAmount?: number;
        maxAmount?: number;
    };
}

export const AmountFilterLabel: React.FC<Props> = ({
    filters: { minAmount, maxAmount },
}) => {
    if (minAmount !== undefined && maxAmount !== undefined) {
        return (
            <>
                {currencyFormatterShort.format(minAmount).toLowerCase()} -{" "}
                {currencyFormatterShort.format(maxAmount).toLowerCase()}
            </>
        );
    }

    if (minAmount !== undefined) {
        return <>≥ {currencyFormatterShort.format(minAmount).toLowerCase()}</>;
    }

    if (maxAmount !== undefined) {
        return <>≤ {currencyFormatterShort.format(maxAmount).toLowerCase()}</>;
    }

    return null;
};
