import React from "react";
import classNames from "classnames";
import styles from "./TimerSpinner.module.scss";

export interface TimerSpinnerProps {
    duration?: number;
    className?: string;
}

const pathLength = 100;

export const TimerSpinner: React.FC<TimerSpinnerProps> = ({
    duration = 2_000,
    className,
}) => (
    <span
        className={classNames(styles.spinner, className)}
        style={
            {
                "--duration": `${duration}ms`,
                "--_path-length": `${pathLength}px`,
            } as any
        }
    >
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle r="7" cx="8" cy="8" pathLength={pathLength}></circle>
        </svg>
    </span>
);
