import React from "react";
import { capitalize } from "lodash";
import { AccountsFilterLabel } from "../../../../general/ActiveFilters/AccountsFilterLabel";
import { ActiveFilterButton } from "../../../../general/ActiveFilters/ActiveFilterButton";
import { DateFilterLabel } from "../../../../general/ActiveFilters/DateFilterLabel";
import { useAggregatedFiltersContext } from "../../../../general/AggregatedFilters/AggregatedFilters.context";
import { AggregatedFiltersTabs, FilterProps } from "../../lib";
import { AmountFilterLabel } from "../../../../general/ActiveFilters/AmountFilterLabel";
import { FilterLabel } from "../../../../general/ActiveFilters/FilterLabel";
import { useResetFilter } from "./ActivityFilter.hooks";
import { ActiveFilter } from "./lib";

interface Props extends FilterProps {
    filterId: ActiveFilter;
}

const filterIdToTab: Record<ActiveFilter, AggregatedFiltersTabs> = {
    accounts: AggregatedFiltersTabs.ACCOUNT,
    amount: AggregatedFiltersTabs.AMOUNT,
    date: AggregatedFiltersTabs.DATE,
    dueDate: AggregatedFiltersTabs.DUE,
    statuses: AggregatedFiltersTabs.STATUS,
};

export const InvoicesActiveFilterButton: React.FC<Props> = ({
    filterId,
    filters,
    onChange,
}) => {
    const { open: openFilter } = useAggregatedFiltersContext();
    const resetFilter = useResetFilter(onChange, filterId);

    let label;

    switch (filterId) {
        case "date":
            label = <DateFilterLabel filters={filters} />;
            break;

        case "dueDate":
            label = (
                <DateFilterLabel
                    filters={{
                        start: filters.dueStart,
                        end: filters.dueEnd,
                    }}
                />
            );
            break;

        case "statuses":
            label = (
                <FilterLabel
                    label={filters.statuses
                        ?.map((s) => capitalize(s))
                        .join(", ")}
                />
            );
            break;

        case "accounts":
            label = <AccountsFilterLabel filters={filters} />;
            break;

        case "amount":
            label = <AmountFilterLabel filters={filters} />;
            break;
    }

    return (
        <ActiveFilterButton
            openFilter={() => openFilter(filterIdToTab[filterId])}
            resetFilter={resetFilter}
            label={label}
        />
    );
};
