import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { TransactionListItemDto } from "../../../common/types/transaction";
import { getConnectionLabel } from "../../../common/helpers/financialConnection";
import { getTransactionAccountNameWithLast4 } from "../../../common/helpers/transactions";
import {
    AccountCard,
    AccountCardProps,
} from "../../general/AccountCard/AccountCard";
import { getLeadingAccountConnection } from "../../../common/helpers/financialAccount";
import { useFinancialAccounts } from "../../../hooks/useFinancialAccounts";

interface Props {
    transaction: TransactionListItemDto;
    size?: AccountCardProps["iconSize"];
}

const MAX_NAME_LENGTH = 8;

export const TransactionAccountColumnContent: React.FC<Props> = ({
    transaction,
    size = "sm",
}) => {
    const financialAccounts = useFinancialAccounts();

    const account = financialAccounts.find(
        ({ id }) => id === transaction.financialAccount.id,
    )!;
    const transactionAccount = account ?? transaction.financialAccount;
    const connection = getLeadingAccountConnection(transactionAccount);
    const institutionName = connection && getConnectionLabel(connection);
    const accountName = getTransactionAccountNameWithLast4(transaction, 2);
    const tooltipNeeded =
        accountName.length > MAX_NAME_LENGTH ||
        transactionAccount.accountNumberMask;

    return (
        <OverlayTrigger
            placement="top"
            trigger={
                transactionAccount && tooltipNeeded ? ["hover", "focus"] : []
            }
            overlay={
                <Tooltip
                    id={`bankDescription:${transaction.id}`}
                    className="transaction-list-item__account__tooltip"
                >
                    {institutionName && institutionName !== accountName && (
                        <label>{institutionName}</label>
                    )}
                    <p>{accountName}</p>
                </Tooltip>
            }
        >
            <div>
                <AccountCard
                    account={transactionAccount}
                    onlyNumber={true}
                    showType={false}
                    iconSize={size}
                    maxNameLength={MAX_NAME_LENGTH}
                />
            </div>
        </OverlayTrigger>
    );
};
