import React from "react";
import { capitalize } from "lodash";
import classNames from "classnames";
import { Button } from "../../../general/Button/Button";
import { SettingsSection } from "../../SettingsSection";
import { Card } from "../../../general/Card/Card";
import { getAnonymousAccountNumber } from "../../../../common/helpers/bankAccount";
import { getEnv } from "../../../../lib/environment";
import { useBillingInfo } from "../../../../hooks/useBillingInfo";
import { Loader } from "../../../general/Loader";
import styles from "./FlatRateBilling.module.scss";

export const PaymentDetails: React.FC = () => {
    const { data: billingInfo, isFetched } = useBillingInfo();

    if (!isFetched) {
        return <Loader />;
    }

    if (!billingInfo) {
        return null;
    }

    let billedTo: React.ReactNode;

    if (billingInfo?.address || billingInfo?.name) {
        const addressLine = [
            billingInfo.name,
            billingInfo.address?.street,
            billingInfo.address?.country,
        ]
            .filter(Boolean)
            .join(", ");

        billedTo = (
            <section className={classNames(styles.section, styles.compact)}>
                <main>
                    <div>
                        <header className={styles.title}>Billed to</header>
                        {addressLine}
                    </div>
                    <aside>
                        <Button variant="secondary" asChild>
                            <a
                                href={getEnv("VITE_STRIPE_PORTAL_URL")}
                                target="_blank"
                                className="text-decoration-none"
                            >
                                Edit address
                            </a>
                        </Button>
                    </aside>
                </main>
            </section>
        );
    }

    return (
        <SettingsSection heading="Payment details">
            <Card className={styles.card}>
                {billingInfo?.paymentMethod && (
                    <section
                        className={classNames(styles.section, styles.compact)}
                    >
                        <main>
                            <div>
                                <header className={styles.title}>
                                    Payment method
                                </header>
                                {capitalize(
                                    billingInfo.paymentMethod.brand.toLowerCase(),
                                )}{" "}
                                {getAnonymousAccountNumber(
                                    billingInfo.paymentMethod.last4,
                                    2,
                                    "",
                                )}
                            </div>
                            <aside>
                                <Button variant="secondary" asChild>
                                    <a
                                        href={getEnv("VITE_STRIPE_PORTAL_URL")}
                                        target="_blank"
                                        className="text-decoration-none"
                                    >
                                        Change method
                                    </a>
                                </Button>
                            </aside>
                        </main>
                    </section>
                )}
                {billingInfo?.email && (
                    <section
                        className={classNames(styles.section, styles.compact)}
                    >
                        <main>
                            <div>
                                <header className={styles.title}>
                                    Billing email
                                </header>
                                {billingInfo.email}
                            </div>
                            <aside>
                                <Button variant="secondary" asChild>
                                    <a
                                        href={getEnv("VITE_STRIPE_PORTAL_URL")}
                                        target="_blank"
                                        className="text-decoration-none"
                                    >
                                        Update email
                                    </a>
                                </Button>
                            </aside>
                        </main>
                    </section>
                )}

                {billedTo}
            </Card>
        </SettingsSection>
    );
};
