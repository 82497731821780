import { noop } from "@tanstack/react-table";
import stripeLogo from "../../static/images/icons/stripe.svg";
import { CreateStripeConnection } from "../stripe/CreateStripeConnection";
import styles from "./ConnectOverlay.module.scss";

export const ConnectOverlay = () => (
    <div className={styles.connectOverlay}>
        <div className={styles.connectOverlayContent}>
            <img src={stripeLogo} alt="Stripe" />
            <span className={styles.connectOverlayText}>
                Connect your Stripe account to view Invoices.
            </span>
            <CreateStripeConnection onCreated={noop} btnVariant="default">
                Connect Stripe
            </CreateStripeConnection>
        </div>
    </div>
);
