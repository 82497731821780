import { QueryClient } from "@tanstack/react-query";
import { sortBy } from "lodash";
import { UserEntitiesResponse } from "../common/dto/onboarding/user-entities-response.dto";
import { FinancialAccount } from "../common/types/financialAccount";
import { Entity } from "../common/types/entity";
import { FinancialConnectionWithAccounts } from "../common/types/financialConnection";
import { IntegrationConnection } from "../common/types/integrationConnection";
import { addUnassignedAccountInQueryData } from "./unassignedAccounts";

export const entitiesAccountsQueryKey = "entities-accounts";

export function getEntitiesAccountsQueryKey(workspaceId: string) {
    return [entitiesAccountsQueryKey, workspaceId];
}

function clearErrorInFinancialAccount(
    account: FinancialAccount,
    connectionId: number,
): FinancialAccount {
    return {
        ...account,
        integrationAccounts: account.integrationAccounts?.map((ia) =>
            ia.connection?.id === connectionId
                ? {
                      ...ia,
                      connection: {
                          ...ia.connection,
                          error: null,
                      },
                  }
                : ia,
        ),
    };
}

export function clearConnectionErrorInQueryData(
    queryClient: QueryClient,
    connectionId: number,
) {
    queryClient.setQueriesData<UserEntitiesResponse | undefined>(
        { queryKey: [entitiesAccountsQueryKey] },
        (data: UserEntitiesResponse | undefined) => {
            if (!data) {
                return data;
            }

            return {
                ...data,
                entities: data.entities.map((entity) => ({
                    ...entity,
                    financialAccounts: entity.financialAccounts.map((a) =>
                        clearErrorInFinancialAccount(a, connectionId),
                    ),
                })),
            };
        },
    );
}

export function addFinancialConnectionInQueryData(
    queryClient: QueryClient,
    connection: FinancialConnectionWithAccounts,
) {
    connection.accounts.forEach((account) => {
        const financialAccount = {
            ...account.financialAccount,
            integrationAccounts: [{ ...account, connection }],
        };

        if (financialAccount.entity) {
            addFinancialAccountInQueryData(
                queryClient,
                financialAccount,
                financialAccount.entity,
            );
        } else {
            addUnassignedAccountInQueryData(queryClient, financialAccount);
        }
    });
}

export function addIntegrationConnectionInQueryData(
    queryClient: QueryClient,
    connection: IntegrationConnection,
) {
    connection.integrationAccounts?.forEach((account) => {
        const financialAccount = {
            ...account.financialAccount,
            integrationAccounts: [
                { ...account, integrationConnection: connection },
            ],
        };

        if (financialAccount.entity) {
            addFinancialAccountInQueryData(
                queryClient,
                financialAccount,
                financialAccount.entity,
            );
        } else {
            addUnassignedAccountInQueryData(queryClient, financialAccount);
        }
    });
}

export function addFinancialAccountInQueryData(
    queryClient: QueryClient,
    account: FinancialAccount,
    entity: Entity,
) {
    queryClient.setQueriesData<UserEntitiesResponse | undefined>(
        { queryKey: [entitiesAccountsQueryKey] },
        (data: UserEntitiesResponse | undefined) => {
            if (!data) {
                return data;
            }

            return {
                ...data,
                entities: data.entities.map((ec) =>
                    ec.entity.id === entity.id
                        ? {
                              ...ec,
                              financialAccounts: [
                                  ...ec.financialAccounts,
                                  account,
                              ],
                          }
                        : ec,
                ),
            };
        },
    );
}

export function updateFinancialAccountInQueryData(
    queryClient: QueryClient,
    account: FinancialAccount,
) {
    queryClient.setQueriesData<UserEntitiesResponse | undefined>(
        { queryKey: [entitiesAccountsQueryKey] },
        (data: UserEntitiesResponse | undefined) => {
            // don't update to invalid state if received account without integrationAccounts
            if (!data || !account.integrationAccounts) {
                return data;
            }

            const previousAccountEntityContext = data.entities.find((ec) =>
                ec.financialAccounts.some((acc) => acc.id === account.id),
            );
            if (previousAccountEntityContext?.entity.id !== account.entityId) {
                return {
                    ...data,
                    entities: data.entities.map((ec) => {
                        if (
                            ec.entity.id ===
                            previousAccountEntityContext?.entity.id
                        ) {
                            return {
                                ...ec,
                                financialAccounts: ec.financialAccounts.filter(
                                    (acc) => acc.id !== account.id,
                                ),
                            };
                        }
                        if (ec.entity.id === account.entityId) {
                            return {
                                ...ec,
                                financialAccounts: [
                                    ...ec.financialAccounts,
                                    account,
                                ],
                            };
                        }
                        return ec;
                    }),
                };
            }

            return {
                ...data,
                entities: data.entities.map((ec) => ({
                    ...ec,
                    financialAccounts: ec.financialAccounts.map((acc) =>
                        acc.id === account.id ? account : acc,
                    ),
                })),
            };
        },
    );
}

export function removeFinancialAccountFromQueryData(
    queryClient: QueryClient,
    account: FinancialAccount,
) {
    queryClient.setQueriesData<UserEntitiesResponse | undefined>(
        { queryKey: [entitiesAccountsQueryKey] },
        (data: UserEntitiesResponse | undefined) => {
            if (!data) {
                return data;
            }

            return {
                ...data,
                entities: data.entities.map((ec) => ({
                    ...ec,
                    financialAccounts: ec.financialAccounts.filter(
                        (acc) => acc.id !== account.id,
                    ),
                })),
            };
        },
    );
}

export function updateEntityInQueryData(
    queryClient: QueryClient,
    entity: Entity,
) {
    queryClient.setQueriesData<UserEntitiesResponse | undefined>(
        { queryKey: [entitiesAccountsQueryKey] },
        (data: UserEntitiesResponse | undefined) => {
            if (!data) {
                return data;
            }

            return {
                ...data,
                entities: data.entities.map((ec) =>
                    ec.entity.id === entity.id ? { ...ec, entity } : ec,
                ),
            };
        },
    );
}

export function reorderEntityAccountsInQueryData(
    queryClient: QueryClient,
    entity: Entity,
    order: number[],
) {
    queryClient.setQueriesData<UserEntitiesResponse | undefined>(
        { queryKey: [entitiesAccountsQueryKey] },
        (data: UserEntitiesResponse | undefined) => {
            if (!data) {
                return data;
            }

            return {
                ...data,
                entities: data.entities.map((ec) =>
                    ec.entity.id === entity.id
                        ? {
                              ...ec,
                              financialAccounts: sortBy(
                                  ec.financialAccounts?.map((account) => ({
                                      ...account,
                                      order: order.indexOf(account.id) + 1,
                                  })),
                                  (a) => a.order,
                              ),
                          }
                        : ec,
                ),
            };
        },
    );
}
