export interface AccountsFilterUICommonProps {
    excludeNoBalanceAccounts?: boolean;
    singleEntityName?: boolean;
    showSelectedIcons?: boolean;
    onReset?: () => void;
}

export interface AccountFilterSharedProps {
    isAccountSelected(accountId: number, entityId: number): boolean;
    toggleAccount(accountId: number, entityId: number): void;
    disabled?: boolean;
    showEntitiesWithoutAccounts: boolean;
}

export interface EntityFilterSharedProps extends AccountFilterSharedProps {
    isEntitySelected(entityId: number): boolean;
    toggleEntity(entityId: number): void;
}

export enum AccountsFilterValueDisplay {
    ENTITY = "entity",
    ACCOUNT = "account",
}
