import React from "react";
import { ContractorPaymentTotals } from "../../../common/types/payroll";
import { currencyFormatter } from "../../../common/helpers/currency";
import css from "./PayrollDetails.module.scss";

interface Props {
    totals?: ContractorPaymentTotals | null;
    total: keyof ContractorPaymentTotals;
}
const contractorPaymentTotalsLabelsMap: Record<
    keyof ContractorPaymentTotals,
    string
> = {
    wageAmount: "Wage Amount",
    debitAmount: "Debit Amount",
    reimbursementAmount: "Reimbursement Amount",
};
export const ContractorPaymentDetailsTotalItem: React.FC<Props> = ({
    totals,
    total,
}) => {
    if (!totals || !total) {
        return null;
    }
    return (
        <>
            <td>{contractorPaymentTotalsLabelsMap[total]}</td>
            <td className={css.total}>
                {currencyFormatter.format(totals[total])}
            </td>
        </>
    );
};
