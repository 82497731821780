import {
    AppRoute,
    AppRouter,
    AreAllPropertiesOptional,
    ClientArgs,
    ClientInferResponses,
    initClient,
    PartialClientInferRequest,
    Prettify,
} from "@ts-rest/core";
import { AxiosResponse } from "axios";
import { backendClient } from "./backendClient";

export function getBackendAPIClient<T extends AppRouter>(
    contract: T,
    clientArgs?: Omit<ClientArgs, "baseUrl">,
): FilteredRecursiveProxyObj<T> {
    return initClient(contract, {
        baseUrl: "",
        api: async ({ path, method, body, headers }) => {
            let response: AxiosResponse<unknown, unknown>;
            if (method === "GET") {
                response = await backendClient.get(`${path}`, {
                    params: body,
                    paramsSerializer: { indexes: true },
                });
            } else if (["POST", "PUT", "PATCH", "DELETE"].includes(method)) {
                response = await backendClient[
                    [method.toLowerCase()] as unknown as
                        | "post"
                        | "put"
                        | "patch"
                        | "delete"
                ](`${path}`, body as any, {
                    headers,
                });
            } else {
                throw new Error(`Unsupported method: ${method}`);
            }
            return {
                status: response.status,
                body: response?.data,
                headers: response.headers as unknown as Headers,
            };
        },
        ...clientArgs,
    }) as FilteredRecursiveProxyObj<T>;
}

// NOTE: This is needed because by default the client inferred responses contain all the status codes,
// while we only expect 200 or 201.
// Also note that backendClient throws an error if the status code is not in the 2xx range and)

// Utility type to extract the allowed status codes (200 and 201) from the route's responses
type AllowedStatusCodesForRoute<TRoute extends AppRoute> = Extract<
    keyof TRoute["responses"],
    200 | 201
>;

// Adjusted type that filters the client inferred responses for a route
type FilteredClientInferResponses<TRoute extends AppRoute> =
    ClientInferResponses<TRoute> extends infer R
        ? R extends { status: infer Status }
            ? Status extends AllowedStatusCodesForRoute<TRoute>
                ? R
                : never
            : never
        : never;

// Adjusted filtered AppRouteFunction that uses the filtered responses and allowed status codes
type FilteredAppRouteFunction<
    TRoute extends AppRoute,
    TClientArgs extends ClientArgs,
    TArgs = PartialClientInferRequest<TRoute, TClientArgs>,
> =
    AreAllPropertiesOptional<TArgs> extends true
        ? (
              args?: Prettify<TArgs>,
          ) => Promise<Prettify<FilteredClientInferResponses<TRoute>>>
        : (
              args: Prettify<TArgs>,
          ) => Promise<Prettify<FilteredClientInferResponses<TRoute>>>;

// Adjusted recursive proxy object type that uses the filtered AppRouteFunction
type FilteredRecursiveProxyObj<
    T extends AppRouter,
    TClientArgs extends ClientArgs = ClientArgs,
> = {
    [TKey in keyof T]: T[TKey] extends AppRoute
        ? FilteredAppRouteFunction<T[TKey], TClientArgs>
        : T[TKey] extends AppRouter
          ? FilteredRecursiveProxyObj<T[TKey], TClientArgs>
          : never;
};
