import classNames from "classnames";
import React from "react";
import "./PageCoverLoader.scss";
import { ChildrenProps } from "../../../types";

export interface PageCoverLoaderProps extends ChildrenProps {
    backdrop?: boolean;
    logo?: boolean;
    className?: string;
}

export const PageCoverLoader: React.FC<PageCoverLoaderProps> = ({
    backdrop,
    logo = true,
    className,
    children,
}) => (
    <div
        className={classNames(
            "page-cover-loader",
            {
                "page-cover-loader--backdrop": backdrop,
                "page-cover-loader--logo": logo,
            },
            className,
        )}
    >
        <span className="page-cover-loader__body">{children}</span>
    </div>
);
