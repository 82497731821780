import { useMemo } from "react";
import { Col } from "react-bootstrap";
import { FinancialAccountSourceType } from "../../common/types/financialAccount";
import { useEntitiesContexts } from "../../hooks/useEntitiesContexts";
import { useTypedFlags } from "../../hooks/useTypedFlags";
import { InvoiceConnectOverlayPageContent } from "./InvoiceDemoPageContent";
import { InvoicePageContent } from "./InvoicePageContent";
import { InvoicesTeaser } from "./InvoicesTeaser";

export const InvoicesPage: React.FC = () => {
    const { invoices: invoicesEnabled } = useTypedFlags();

    const entities = useEntitiesContexts();

    const invoicingAccounts = useMemo(() => {
        if (!entities) {
            return [];
        }

        return entities.flatMap((e) =>
            e.financialAccounts.filter(
                (f) => f.sourceType === FinancialAccountSourceType.STRIPE,
            ),
        );
    }, [entities]);

    const showConnectOverlay = useMemo(
        () => invoicingAccounts.length === 0,
        [invoicingAccounts],
    );

    if (!invoicesEnabled) {
        return (
            <Col className="d-flex flex-column gap-4 p-0">
                <InvoicesTeaser />
            </Col>
        );
    }

    if (showConnectOverlay) {
        return <InvoiceConnectOverlayPageContent />;
    } else {
        return <InvoicePageContent invoicingAccounts={invoicingAccounts} />;
    }
};
