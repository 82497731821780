import { GetAccountNumbersResponseDto } from "../common/dto/financialAccount/get-account-numbers-response.dto";
import { FinancialAccount } from "../common/types/financialAccount";
import { FinancialAccountDto } from "../common/dto/financialAccount/financial-account.dto";
import { CreateFinancialAccountFromDocumentsDto } from "../common/dto/financialAccount/create-financial-account-from-documents.dto";
import { CreateFinancialAccountFromDocumentsResponseDto } from "../common/dto/financialAccount/create-financial-account-from-documents-response.dto";
import { GetDocumentsForFinancialAccountResponseDto } from "../common/dto/financialAccount/get-documents-for-financial-account-response.dto";
import { FinancialDocumentBaseDto } from "../common/dto/financialDocument/financial-document-base.dto";
import { AddDocumentsToFinancialAccountDto } from "../common/dto/financialAccount/add-documents-to-financial-account.dto";
import { AddDocumentsToFinancialAccountResponseDto } from "../common/dto/financialAccount/add-documents-to-financial-account-response.dto";
import { MergeFinancialAccountsDto } from "../common/dto/financialAccount/mergeFinancialAccounts/merge-financial-accounts.dto";
import { MergeFinancialAccountsResponseDto } from "../common/dto/financialAccount/mergeFinancialAccounts/merge-financial-accounts-response.dto";
import { backendClient, unwrapResponse } from "./backendClient";

export interface ExtendedFinancialAccount extends FinancialAccount {
    accountNumber?: string;
}

export async function disableFinancialAccount(
    accountId: number,
): Promise<FinancialAccount> {
    return FinancialAccountDto.deserialize(
        unwrapResponse(
            await backendClient.post<FinancialAccountDto>(
                `/financial-account/${accountId}/disable`,
            ),
        ),
    );
}

export async function enableFinancialAccount(
    accountId: number,
): Promise<FinancialAccount> {
    return FinancialAccountDto.deserialize(
        unwrapResponse(
            await backendClient.post<FinancialAccountDto>(
                `/financial-account/${accountId}/enable`,
            ),
        ),
    );
}

export async function getFinancialAccountNumbers(): Promise<GetAccountNumbersResponseDto> {
    return unwrapResponse(
        await backendClient.get("/financial-account/account-numbers"),
    );
}

export async function getUnassignedFinancialAccounts(): Promise<
    FinancialAccount[]
> {
    return (
        await backendClient.get<FinancialAccountDto[]>(
            "/financial-account/unassigned",
        )
    ).data.map((account) => FinancialAccountDto.deserialize(account));
}

export async function createFinancialAccountFromDocuments(
    payload: CreateFinancialAccountFromDocumentsDto,
) {
    const response: CreateFinancialAccountFromDocumentsResponseDto =
        unwrapResponse(
            await backendClient.post(
                "/financial-account/from-financial-documents",
                payload,
            ),
        );

    return {
        account: FinancialAccountDto.deserialize(response.account),
        needsVerification: response.needsVerification,
    };
}

export async function addFinancialDocumentsToFinancialAccount(
    accountId: number,
    payload: AddDocumentsToFinancialAccountDto,
) {
    const response: AddDocumentsToFinancialAccountResponseDto = unwrapResponse(
        await backendClient.post(
            `/financial-account/${accountId}/add-financial-documents`,
            payload,
        ),
    );

    return {
        account: FinancialAccountDto.deserialize(response.account),
        needsVerification: response.needsVerification,
    };
}

export async function getDocumentsForFinancialAccount(accountId: number) {
    const response: GetDocumentsForFinancialAccountResponseDto = unwrapResponse(
        await backendClient.get(
            `/financial-account/${accountId}/financial-documents`,
        ),
    );

    return response.data.map(FinancialDocumentBaseDto.deserialize);
}

export async function mergeFinancialAccounts(data: MergeFinancialAccountsDto) {
    const response = unwrapResponse(
        await backendClient.post<MergeFinancialAccountsResponseDto>(
            "/financial-account/merge",
            data,
        ),
    );

    return MergeFinancialAccountsResponseDto.deserialize(response);
}
