import React from "react";
import { BaseFilterProps } from "../../lib";
import { FormCheckbox } from "../../../../forms/FormCheckbox/FormCheckbox";
import { FinancialDocumentStatus } from "../../../../../common/constants";
import styles from "./FinancialDocumentStatusFilter.module.scss";

interface StatusRadio {
    id: string;
    label: string;
    value?: FinancialDocumentStatus;
}

const statusRadios: StatusRadio[] = [
    {
        id: "financial-documents__status__any",
        label: "Any",
        value: undefined,
    },
    {
        id: "financial-documents__status__matched",
        label: "Matched",
        value: FinancialDocumentStatus.MATCHED,
    },
    {
        id: "financial-documents__status__not-matched",
        label: "Not matched",
        value: FinancialDocumentStatus.NOT_MATCHED,
    },
];

export const FinancialDocumentStatusFilter: React.FC<BaseFilterProps> = ({
    filters,
    onChange,
}) => (
    <div
        className={styles.statusFilter}
        data-testid="financial-document-status-container"
    >
        <header className={styles.heading}>Status</header>

        {statusRadios.map(({ id, label, value }) => (
            <FormCheckbox
                key={id}
                value={value ?? ""}
                type="radio"
                name="status"
                label={label}
                isChecked={filters.documentStatus === value}
                handleChange={() => onChange({ documentStatus: value })}
                small
            />
        ))}
    </div>
);
