import classNames from "classnames";
import React, { ReactNode } from "react";
import { pluralize } from "../../common/helpers/string";
import { Action } from "../../common/types/actions";
import { TriangleIcon } from "../../icons";
import { Button } from "../general/Button/Button";
import styles from "./SkippedActions.module.scss";

interface Props {
    actions: Action[];
    renderAction: (action: Action) => ReactNode;
}
export const SkippedActions: React.FC<Props> = ({ actions, renderAction }) => {
    const [opened, setOpened] = React.useState(false);

    return (
        <>
            <div className={styles.separator}>
                <Button
                    variant="tertiary"
                    size="sm"
                    onClick={() => setOpened(!opened)}
                >
                    {actions.length} skipped{" "}
                    {pluralize("action", actions.length)}
                    <TriangleIcon
                        className={classNames("ml-1", styles.carat, {
                            [styles.opened]: opened,
                        })}
                    />
                </Button>
            </div>
            <div
                className={classNames(styles.list, {
                    [styles.opened]: opened,
                })}
            >
                {opened &&
                    actions.map((action) => (
                        <React.Fragment key={action.id}>
                            {renderAction(action)}
                        </React.Fragment>
                    ))}
            </div>
        </>
    );
};
