import React from "react";
import classNames from "classnames";
import { TransactionMatch } from "../../../../common/types/transactionMatch";
import { Transaction } from "../../../../common/types/transaction";
import { useDownloadFinancialDocumentFile } from "../../../documents/FinancialDocumentDetails/useDownloadFinancialDocumentFile";
import { useDetachTransactionMatchMutation } from "../../../../mutations/transaction";
import { EagerLoaded } from "../../../../common/types/base/orm";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import { DetachIcon, TrashIcon } from "../../../../icons";
import { useRemoveFinancialDocumentMutation } from "../../../../mutations/financialDocument";
import { Button } from "../../../general/Button/Button";
import styles from "./TransactionMatches.module.scss";

interface Props {
    transactionMatch: EagerLoaded<TransactionMatch, "financialDocument">;
    financialTransaction: Transaction;
}

export const TransactionMatchFinancialDocument: React.FC<Props> = ({
    financialTransaction,
    transactionMatch,
}) => {
    const { download } = useDownloadFinancialDocumentFile(
        transactionMatch.financialDocument,
    );
    const detachMutation = useDetachTransactionMatchMutation(
        financialTransaction,
        transactionMatch,
    );

    const removeDocumentMutation = useRemoveFinancialDocumentMutation();

    return (
        <div className={styles.match} data-testid="transaction-document">
            <Button
                data-testid="transaction-document-name"
                variant="tertiary"
                size="sm"
                className={classNames("mr-auto", styles.documentName)}
                onClick={download}
            >
                <span>{transactionMatch.financialDocument.fileName}</span>
            </Button>

            <ButtonWithLoader
                data-testid="detach-document-button"
                variant="tertiary"
                icon
                size="sm"
                mutation={detachMutation}
                tooltip="Detach from this transaction"
            >
                <DetachIcon />
            </ButtonWithLoader>

            <ButtonWithLoader
                data-testid="remove-document-button"
                variant="tertiary"
                icon
                size="sm"
                tooltip="Delete receipt"
                loading={removeDocumentMutation.isPending}
                onClick={() =>
                    removeDocumentMutation.mutate(
                        transactionMatch.financialDocument,
                    )
                }
            >
                <TrashIcon />
            </ButtonWithLoader>
        </div>
    );
};
