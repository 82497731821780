import React from "react";
import { PopoverButtonProps } from "../PopoverContainer";
import { Entity } from "../../../common/types/entity";
import { AccountsFilterDropdown } from "./AccountsFilterDropdown";
import { AccountsFilterUICommonProps, AccountsFilterValueDisplay } from "./lib";
import { useAccountFilter } from "./useAccountFilter";

interface Props extends PopoverButtonProps, AccountsFilterUICommonProps {
    onlyBusiness?: boolean;
    value: number[];
    valueDisplay: AccountsFilterValueDisplay;
    allSelectedByDefault?: boolean;
    entities?: Entity[];
    onChange(value: number[]): void;
}

export const AccountsFilter: React.FC<Props> = ({
    excludeNoBalanceAccounts,
    onlyBusiness = false,
    singleEntityName = true,
    showSelectedIcons = true,
    value,
    valueDisplay,
    onChange,
    entities,
    ...popoverButtonProps
}) => {
    const {
        isAccountSelected,
        isEntitySelected,
        onToggleAccount,
        toggleEntity,
        entities: entitiesToShow,
    } = useAccountFilter({
        value,
        onChange,
        excludeAllMockEntities: true,
        onlyBusiness,
        excludeNoBalanceAccounts,
        entities,
    });

    return (
        <AccountsFilterDropdown
            entities={entitiesToShow}
            valueDisplay={valueDisplay}
            excludeNoBalanceAccounts={excludeNoBalanceAccounts}
            singleEntityName={singleEntityName}
            isEntitySelected={isEntitySelected}
            isAccountSelected={isAccountSelected}
            toggleEntity={toggleEntity}
            toggleAccount={onToggleAccount}
            everythingSelected={!value.length}
            showEntitiesWithoutAccounts={false}
            showSelectedIcons={showSelectedIcons}
            {...popoverButtonProps}
        />
    );
};
