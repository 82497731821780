import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { TransactionListItemDto } from "../../common/types/transaction";
import { useDesktopView, useLargeDesktopView } from "../../hooks/useMobileView";
import { FormCheckbox } from "../forms/FormCheckbox/FormCheckbox";
import { Loader } from "../general/Loader";
import { SortableHeader } from "../general/SortableHeader/SortableHeader";
import { TransactionListTableItem } from "./ListItem/TransactionListTableItem";
import { NoTransactions } from "./NoTransactions";
import { useBulkActions } from "./TransactionsBulkActions/useBulkActions";
import { TransactionSortValue } from "./useSort";
import { PaginatedTransactionsTableFooter } from "./PaginatedTransactionsTableFooter";

export interface TransactionsTableProps {
    transactions?: TransactionListItemDto[];
    sort?: TransactionSortValue;
    onSortChange?: (sort: TransactionSortValue) => void;
    shownTransactionId?: number;
    onTransactionSelected?(transaction: TransactionListItemDto): void;
    footer?: React.ReactNode;
    showFooter?: boolean;
    renderEmptyState?: () => React.ReactNode;
    renderLoadingState?: () => React.ReactNode;
    showAccountNames?: boolean;
    prependContent?: React.ReactNode;
    appendContent?: React.ReactNode;
    disableBulkActions?: boolean;
    actionButtonComponent?: (
        transaction: TransactionListItemDto,
    ) => React.ReactNode;
    onTransferMatchClick?: (transaction: TransactionListItemDto) => void;
    onSplitCategoryClick?: (transaction: TransactionListItemDto) => void;
    lastRowContent?: React.ReactNode;
    disableTransactionChange?: boolean;
    showClasses?: boolean;
    showSplitButton?: boolean;
}

export const TransactionsTable: React.FC<TransactionsTableProps> = ({
    transactions,
    onSortChange,
    sort,
    shownTransactionId,
    onTransactionSelected,
    footer,
    renderEmptyState = () => <NoTransactions />,
    renderLoadingState = () => <Loader />,
    showAccountNames = true,
    prependContent,
    appendContent,
    disableBulkActions = false,
    actionButtonComponent,
    onTransferMatchClick,
    onSplitCategoryClick,
    lastRowContent,
    disableTransactionChange = false,
    showClasses = false,
    showSplitButton = false,
    showFooter,
}) => {
    const bulkActions = useBulkActions();

    const allTransactionsSelected = useMemo(() => {
        if (bulkActions) {
            return (
                bulkActions.hasSelectedAll ||
                !!transactions?.every((t) => bulkActions.isSelected(t))
            );
        } else {
            return false;
        }
    }, [bulkActions, transactions]);

    const handleBulkSelect = useCallback(() => {
        if (bulkActions.hasSelectedAll) {
            bulkActions.clearSelection();
            return;
        }

        if (!transactions) {
            return;
        }

        if (allTransactionsSelected) {
            bulkActions.deselect(transactions);
        } else {
            bulkActions.select(transactions);
        }
    }, [allTransactionsSelected, bulkActions, transactions]);

    const isDesktop = useDesktopView();
    const isLargeDesktop = useLargeDesktopView();

    let columnsNumber = 4;

    if (bulkActions.enabled) {
        columnsNumber++;
    }
    if (!shownTransactionId || isLargeDesktop) {
        columnsNumber += 2;
    }

    if (showAccountNames) {
        columnsNumber += 1;
    }
    if (showSplitButton) {
        columnsNumber += 1;
    }

    if (showClasses) {
        columnsNumber += 1;
    }

    const isLoading = transactions == null;
    const isEmpty = transactions?.length === 0;

    const hasAdjacentSplitSibling = useCallback(
        (transaction: TransactionListItemDto, index: number) => {
            if (!transactions) {
                return false;
            }
            return (
                transaction.splitParentId != null &&
                index < transactions.length - 1 &&
                transactions[index + 1].splitParentId ===
                    transaction.splitParentId
            );
        },
        [transactions],
    );

    return (
        <table
            className="table transactions-table position-relative"
            data-testid="transactions-table"
        >
            <thead>
                <tr>
                    {!disableBulkActions && bulkActions.enabled ? (
                        <th>
                            <span hidden={isLoading || isEmpty}>
                                <FormCheckbox
                                    value="page"
                                    isChecked={allTransactionsSelected}
                                    handleChange={() => handleBulkSelect()}
                                    label=""
                                    small
                                />
                            </span>
                        </th>
                    ) : null}
                    {isDesktop && (
                        <th
                            className="transaction-list-item__date"
                            data-testid="transactions-table-date-header"
                        >
                            <SortableHeader
                                asc={TransactionSortValue.DATE_ASC}
                                desc={TransactionSortValue.DATE_DESC}
                                sort={sort}
                                onChange={onSortChange}
                                defaultOrder="desc"
                            >
                                Date
                            </SortableHeader>
                        </th>
                    )}
                    {showSplitButton && (
                        <th className="transaction-list-item__split"></th>
                    )}
                    <th className="transaction-list-item__description">
                        <SortableHeader
                            asc={TransactionSortValue.DESCRIPTION_ASC}
                            desc={TransactionSortValue.DESCRIPTION_DESC}
                            sort={sort}
                            onChange={onSortChange}
                            defaultOrder="asc"
                        >
                            Description
                        </SortableHeader>
                    </th>
                    {(!shownTransactionId || isLargeDesktop) && (
                        <th className="transaction-list-item__category">
                            <SortableHeader
                                asc={TransactionSortValue.CATEGORY_ASC}
                                desc={TransactionSortValue.CATEGORY_DESC}
                                sort={sort}
                                onChange={onSortChange}
                                defaultOrder="asc"
                            >
                                Category
                            </SortableHeader>
                        </th>
                    )}
                    {!shownTransactionId && showClasses && (
                        <th className="transaction-list-item__class">
                            <SortableHeader
                                asc={TransactionSortValue.CLASS_ASC}
                                desc={TransactionSortValue.CLASS_DESC}
                                sort={sort}
                                onChange={onSortChange}
                                defaultOrder="asc"
                            >
                                Class
                            </SortableHeader>
                        </th>
                    )}
                    {showAccountNames && (
                        <th className="d-none d-md-table-cell transaction-list-item__account">
                            <SortableHeader
                                asc={TransactionSortValue.ACCOUNT_ASC}
                                desc={TransactionSortValue.ACCOUNT_DESC}
                                sort={sort}
                                onChange={onSortChange}
                                defaultOrder="asc"
                            >
                                Account
                            </SortableHeader>
                        </th>
                    )}

                    <th
                        className="transaction-list-item__amount"
                        data-testid="transactions-table-amount-header"
                    >
                        <SortableHeader
                            asc={TransactionSortValue.AMOUNT_ASC}
                            desc={TransactionSortValue.AMOUNT_DESC}
                            sort={sort}
                            onChange={onSortChange}
                            defaultOrder="desc"
                        >
                            Amount
                        </SortableHeader>
                    </th>
                    {(!shownTransactionId || isLargeDesktop) && (
                        <th className="transaction-list-item__entity" />
                    )}
                    <th
                        className={classNames("transaction-list-item__action", {
                            "transaction-list-item__action--extended":
                                actionButtonComponent,
                        })}
                    >
                        {actionButtonComponent ? (
                            "Action"
                        ) : (
                            <SortableHeader
                                asc={TransactionSortValue.ACTIONS_ASC}
                                desc={TransactionSortValue.ACTIONS_DESC}
                                sort={sort}
                                onChange={onSortChange}
                                defaultOrder="desc"
                            >
                                Action
                            </SortableHeader>
                        )}
                    </th>
                </tr>
            </thead>
            {isLoading || isEmpty ? (
                <tbody>
                    <tr>
                        <td colSpan={columnsNumber}>
                            {isLoading
                                ? renderLoadingState()
                                : renderEmptyState()}
                        </td>
                    </tr>
                </tbody>
            ) : (
                <>
                    {prependContent}
                    <tbody>
                        {transactions?.map((t, index) => (
                            <TransactionListTableItem
                                transaction={t}
                                key={t.id}
                                onClick={() => onTransactionSelected?.(t)}
                                isActive={t.id === shownTransactionId}
                                isFullView={!shownTransactionId}
                                showAccountName={showAccountNames}
                                disableBulkActions={disableBulkActions}
                                actionButtonComponent={actionButtonComponent}
                                onTransferMatchClick={onTransferMatchClick}
                                onSplitCategoryClick={onSplitCategoryClick}
                                readonly={disableTransactionChange}
                                showClasses={showClasses}
                                hasAdjacentSplitSibling={hasAdjacentSplitSibling(
                                    t,
                                    index,
                                )}
                                showSplitButton={showSplitButton}
                            />
                        ))}
                        {lastRowContent && (
                            <tr>
                                <td colSpan={columnsNumber}>
                                    {lastRowContent}
                                </td>
                            </tr>
                        )}
                    </tbody>
                    {appendContent}
                </>
            )}
            {showFooter && <PaginatedTransactionsTableFooter />}
            {footer}
        </table>
    );
};
