import React from "react";
import classNames from "classnames";
import { AccountCard } from "../general/AccountCard/AccountCard";
import { useFinancialAccountEntitySelection } from "../../hooks/useFinancialAccountEntitySelection";
import { IntegrationAccount } from "../../common/types/integrationAccount";
import { IntegrationConnection } from "../../common/types/integrationConnection";
import { UpdateFinancialAccountEntity } from "./UpdateFinancialAccountEntity";
import { useFinancialAccountsConnectionContext } from "./FinancialAccountsConnectionContext";

export interface FinancialAccountConnectionRowProps {
    account: IntegrationAccount;
    integrationConnection?: IntegrationConnection;
}

export const FinancialAccountConnectionRowV2: React.FC<
    FinancialAccountConnectionRowProps
> = ({ account, integrationConnection }) => {
    const connection = integrationConnection ?? account.integrationConnection!;
    const { showBusinessEntitySelection, nonMockBusinessEntities } =
        useFinancialAccountEntitySelection();
    const entitySelection = showBusinessEntitySelection;
    const { showAccountTypeWarning } = useFinancialAccountsConnectionContext();

    const showWarning =
        showAccountTypeWarning && account.financialAccount.entity === null;

    let content: React.ReactNode;

    if (nonMockBusinessEntities.length === 0) {
        content = <div>No entities to administer</div>;
    } else {
        content = (
            <UpdateFinancialAccountEntity
                account={account.financialAccount}
                isBusiness={true}
                dropdownKey={`accountEntitySelection_${account.id}`}
                selectedEntity={account.financialAccount.entity ?? undefined}
                allowMockEntity
                size="sm"
            />
        );
    }

    return (
        <tr
            className={classNames("accounts-connection-widget__row", {
                "accounts-connection-widget__row--warning": showWarning,
            })}
            key={account.id}
        >
            <td className="accounts-connection-widget__account">
                <AccountCard
                    account={account.financialAccount}
                    connection={connection}
                    showNumber
                />
            </td>
            <td>
                {showWarning && (
                    <span className="mr-3 text-warning text-nowrap">
                        Select Entity
                    </span>
                )}
            </td>

            {entitySelection && (
                <td className="accounts-connection-widget__entity">
                    {content}
                </td>
            )}
        </tr>
    );
};
