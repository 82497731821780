import { currencyFormatter } from "../../../common/helpers/currency";
import { ZoomInIcon } from "../../../icons";
import { ActionActivity } from "../ActionActivity";
import { CampaignActivityProps } from "../ActionActivityItem";

export const PersonalToBusinessActivity: React.FC<CampaignActivityProps> = ({
    animated,
    amountSaved,
}) => (
    <ActionActivity icon={<ZoomInIcon />} animated={animated}>
        Confirmed{" "}
        <span className="action-activity-emphasis">
            {currencyFormatter.format(amountSaved)}
        </span>{" "}
        in savings
    </ActionActivity>
);
