import {
    CategoryBackup,
    Transaction,
    TransactionBase,
    TransactionStatus,
    TransactionType,
} from "../../types/transaction";
import { TaxQuestionKey } from "../../taxSavingRules";

// without any relations loaded
export class TransactionBaseDto {
    id: number;
    amount: number;
    absoluteAmount: number;
    originalAmount: number;
    date: string;
    type: TransactionType;
    status: TransactionStatus;
    bankDescription: string;
    displayDescription: string | null;
    categoryId: string;
    businessCategoryId: string | null;
    personalCategoryId: string | null;
    categoryBackup: CategoryBackup | null;
    memo: string | null;
    amountSaved: number | null;
    potentialAmountSaved: number | null;
    requiredActions: string[];

    taxQuestions: TaxQuestionKey[];
    deductionRate: number;
    requirementsSnoozed: boolean;
    isIgnored: boolean;
    isBusiness: boolean;

    inReview: boolean;

    counterpartyId: string | null;
    splitParentId: number | null;

    static deserialize(dto: TransactionBaseDto): TransactionBase {
        return {
            ...dto,
            date: new Date(dto.date),
        };
    }

    constructor(
        transaction: Omit<
            Transaction,
            | "auditLogs"
            | "financialAccount"
            | "taxQuestionAnswers"
            | "entity"
            | "transactionMatches"
        >,
    ) {
        this.id = transaction.id;
        this.amount = transaction.amount;
        this.absoluteAmount = transaction.absoluteAmount;
        this.originalAmount = transaction.originalAmount;
        this.date = transaction.date.toISOString();
        this.type = transaction.type;
        this.status = transaction.status;
        this.bankDescription = transaction.bankDescription;
        this.displayDescription = transaction.displayDescription;
        this.categoryId = transaction.categoryId;
        this.businessCategoryId = transaction.businessCategoryId;
        this.personalCategoryId = transaction.personalCategoryId;
        this.categoryBackup = transaction.categoryBackup;
        this.memo = transaction.memo;
        this.amountSaved = transaction.amountSaved;
        this.potentialAmountSaved = transaction.potentialAmountSaved;
        this.requiredActions = transaction.requiredActions;
        this.taxQuestions = transaction.taxQuestions;
        this.deductionRate = transaction.deductionRate;
        this.requirementsSnoozed = transaction.requirementsSnoozed;
        this.isIgnored = transaction.isIgnored;
        this.isBusiness = transaction.isBusiness;
        this.inReview = transaction.inReview;
        this.counterpartyId = transaction.counterpartyId;
        this.splitParentId = transaction.splitParentId ?? null;
    }
}
