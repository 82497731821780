import { useCallback } from "react";
import { ActionStatus } from "../../../common/types/actions";
import { useUser } from "../../../hooks/useUser";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { TimerSpinner } from "../../general/TimerSpinner/TimerSpinner";
import { ActionCard } from "../ActionCard";
import styles from "../ActionCard.module.scss";
import { ActionCardCompleted } from "../ActionCardCompleted";
import { ActionCardSection } from "../ActionCardSection";
import { CampaignCardProps } from "../ActionItem";
import { Button } from "../../general/Button/Button";

export const WelcomeVideoCard: React.FC<CampaignCardProps> = ({
    action,
    onConfirm,
    onSkip,
    isLoading,
    ...rest
}) => {
    const { isCompleted } = rest;
    const user = useUser();

    const confirmAction = useCallback(async () => {
        onConfirm?.({
            ...action,
        });
    }, [onConfirm, action]);

    const skipAction = useCallback(() => {
        onSkip?.(action);
    }, [action, onSkip]);

    const firstSection = (inOpened: boolean, closeCard: () => void) =>
        isCompleted ? (
            <ActionCardCompleted header="Reporting unlocked">
                <div className="d-flex gap-2">
                    <Button
                        variant="secondary"
                        size="sm"
                        onClick={(e) => {
                            e.stopPropagation();
                            closeCard();
                        }}
                    >
                        <TimerSpinner />
                        Close
                    </Button>
                </div>
            </ActionCardCompleted>
        ) : (
            <ActionCardSection
                inOpened={inOpened}
                header={`Welcome ${user.firstName}, let's personalize your account`}
                icon={<span className="emoji">🎉</span>}
                isTitle
                ctas={
                    <>
                        {action.status !== ActionStatus.SKIPPED && (
                            <Button
                                size="sm"
                                variant="secondary"
                                onClick={() => skipAction()}
                            >
                                Skip
                            </Button>
                        )}
                        <ButtonWithLoader
                            className={styles.confirmButton}
                            size="sm"
                            loading={!!isLoading}
                            onClick={() => confirmAction()}
                        >
                            Play
                        </ButtonWithLoader>
                    </>
                }
            ></ActionCardSection>
        );

    const otherSections = (inOpened: boolean) => (
        <ActionCardSection
            header="TODO: Add content here"
            inOpened={inOpened}
        ></ActionCardSection>
    );

    return (
        <ActionCard
            {...rest}
            firstSection={firstSection}
            otherSections={otherSections}
        />
    );
};
