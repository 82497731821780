import React, { useRef } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import commonStyles from "../common.module.scss";
import animation from "../../layout/OnboardingLayout.module.scss";
import {
    GoogleLoginHandle,
    LoginWithGoogleButton,
} from "../../../auth/GoogleAuth/LoginWithGoogleButton";
import { HorizontalLine } from "../../../general/HorizontalLine/HorizontalLine";
import { TermsAndPrivacyConsent } from "../../../general/TermsAndPrivacyConsent/TermsAndPrivacyConsent";
import { useKeyboardCommands } from "../../../../hooks/keyboard/useKeyboardCommands";
import { CaptureInteractions } from "../../../general/CaptureInteractions";
import { KeyboardShortcut } from "../../../general/KeyboardShortcut/KeyboardShortcut";
import { RegisterWithEmail } from "./RegisterWithEmail";
import styles from "./Register.module.scss";

interface Props {
    onSubmit(email: string): Promise<void>;
    initialEmail?: string;
}

export const Register: React.FC<Props> = ({ onSubmit, initialEmail }) => {
    const googleRef = useRef<GoogleLoginHandle>();

    useKeyboardCommands({
        commands: [
            {
                key: "g",
                requiresCtrlOrMeta: false,
                callback: () => {
                    googleRef.current?.login();
                },
            },
        ],
    });

    return (
        <div className={styles.contentContainer}>
            <header
                className={classNames(
                    styles.header,
                    styles.section,
                    animation.fadeIn2,
                )}
            >
                <h1 className={commonStyles.title}>
                    The first done for you
                    <br />
                    accounting software
                </h1>
            </header>

            <section className={classNames(styles.section, animation.fadeIn1)}>
                <LoginWithGoogleButton
                    variant="secondary"
                    block
                    size="lg"
                    ref={googleRef as any}
                >
                    Continue with Google
                    <KeyboardShortcut square className="ml-2">
                        G
                    </KeyboardShortcut>{" "}
                </LoginWithGoogleButton>

                <HorizontalLine className={styles.spacer}>or</HorizontalLine>

                <CaptureInteractions className="d-block">
                    <RegisterWithEmail
                        onSubmit={onSubmit}
                        initialEmail={initialEmail}
                    />
                </CaptureInteractions>
            </section>

            <div className={animation.fadeIn1}>
                <TermsAndPrivacyConsent />

                <footer className="mt-5 text-center">
                    Already have an account? <Link to="/">Log in</Link>
                </footer>
            </div>
        </div>
    );
};
