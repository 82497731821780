import React, { useEffect, useRef } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { bem } from "../../../bem";
import { CashFlowReportDto } from "../../../common/dto/reports/cash-flow-tab-report-response.dto";
import { ReportFilters } from "../../../common/types/filters/reports";
import { StickyDataTable } from "../../general/DataTable/StickyDataTable";
import { CashFlowReportTable } from "./CashFlowReportTable";

export interface CashFlowReportTableContainerProps {
    report: CashFlowReportDto;
    filters: ReportFilters;
    withBorder?: boolean;
    boldTotals?: boolean;
}

const block = bem("data-table");

export const CashFlowReportTableContainer: React.FC<
    CashFlowReportTableContainerProps
> = ({ report, filters, withBorder = false, boldTotals = false }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const scrollbarsRef = useRef<Scrollbars>(null);

    useEffect(() => {
        window.setTimeout(() => {
            scrollbarsRef.current?.scrollToRight();
        }, 0);
    }, [report]);

    return (
        <div
            className={block({
                "with-border": withBorder,
                "bold-totals": boldTotals,
                "cash-flow": true,
            })}
            ref={containerRef}
        >
            <StickyDataTable
                containerRef={containerRef}
                ref={scrollbarsRef}
                stickyLeft
                stickyRight={2}
                alwaysHorizontalSticky
            >
                <CashFlowReportTable
                    tableData={report.tableData}
                    filters={filters}
                    isStickingRight={true}
                />
            </StickyDataTable>
        </div>
    );
};
