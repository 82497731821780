import classNames from "classnames";
import { Form, Formik, FormikConfig } from "formik";
import { Link } from "react-router-dom";
import { useEffect, useMemo } from "react";
import {
    StandardModal,
    StandardModalBody,
    StandardModalHeader,
} from "../../../general/Modal/Modal";
import { FormRowRadios } from "../../../forms/FormRowRadios";
import { submitHelper } from "../../../../helpers/form";
import { Class } from "../../../../common/types/class";
import { useClasses } from "../../../../api/class.api";
import { useEntities } from "../../../../hooks/useEntities";
import { Loader } from "../../../general/Loader";
import { ArrowCornerIcon } from "../../../../icons";
import { useTransactionsStatisticsQuery } from "../../../transactions/useTransactionsStatisticsQuery";
import { TransactionClassFilterType } from "../../../../common/helpers/transactions";
import { Button } from "../../../general/Button/Button";

interface Props {
    show: boolean;
    onHide: (closeSettings?: boolean) => void;
    onConfirm: (mergingStrategy: "child" | "parent") => void;
    childClass: Class;
    parentClass: Class;
}

interface FormSchema {
    mergingStrategy: "child" | "parent";
}

export function ClassMergingModal({
    show,
    onHide,
    onConfirm,
    childClass,
    parentClass,
}: Props) {
    const { classesMap } = useClasses();
    const entities = useEntities();

    const filters = useMemo(() => {
        const parentClassChildren =
            classesMap.get(parentClass.id)?.children ?? [];

        return {
            classes: [
                parentClass.id,
                childClass.id,
                ...parentClassChildren.map((c) => c.id),
            ],
            classFilterType: TransactionClassFilterType.ALL_SELECTED,
            entityIds: entities.map((e) => e.id),
        };
    }, [parentClass, childClass, classesMap, entities]);

    const encodedFilters = useMemo(() => {
        const filtersString = JSON.stringify(filters);
        return encodeURIComponent(filtersString);
    }, [filters]);

    const conflictingTransactions = useTransactionsStatisticsQuery({
        filters,
    });

    useEffect(() => {
        if (
            !conflictingTransactions.isLoading &&
            conflictingTransactions.data &&
            conflictingTransactions.data.total === 0
        ) {
            onConfirm("child");
        }
    }, [
        conflictingTransactions.isLoading,
        conflictingTransactions.data,
        onConfirm,
    ]);

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            mergingStrategy: "child",
        },
        onSubmit: submitHelper({
            handler: async ({ mergingStrategy }) => {
                onConfirm(mergingStrategy);
            },
        }),
    };

    if (conflictingTransactions.isLoading) {
        return (
            <StandardModal disableAnimation show={show} onHide={onHide}>
                <Loader />
            </StandardModal>
        );
    }

    return (
        <StandardModal disableAnimation show={show} onHide={onHide}>
            <StandardModalHeader>
                This change might result in a conflict
            </StandardModalHeader>
            <StandardModalBody>
                <p>
                    After moving {childClass.label} into {parentClass.label},
                    some transactions might have 2 classes with the same parent.
                    Choose which class to keep in{" "}
                    <Link
                        to={`/transactions?filters=${encodedFilters}`}
                        target="_blank"
                    >
                        {conflictingTransactions.data?.count} conflicting
                        transactions
                        <ArrowCornerIcon className="ml-1" />
                    </Link>
                </p>

                <Formik {...form}>
                    <Form>
                        <FormRowRadios
                            options={["child", "parent"]}
                            labels={{
                                child: childClass.label,
                                parent: parentClass.label,
                            }}
                            idPrefix="mergingStrategy"
                            fieldName="mergingStrategy"
                        />

                        <footer
                            className={classNames("d-flex", {
                                "justify-content-between": true,
                            })}
                        >
                            <Button
                                variant="secondary"
                                onClick={() => onHide()}
                                className="mr-2"
                                size="lg"
                                data-testid="confirmation-modal-no-button"
                            >
                                Cancel
                            </Button>

                            <Button
                                variant="default"
                                type="submit"
                                size="lg"
                                data-testid="confirmation-modal-yes-button"
                            >
                                Confirm
                            </Button>
                        </footer>
                    </Form>
                </Formik>
            </StandardModalBody>
        </StandardModal>
    );
}
