import React, { useState } from "react";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { ArrowCornerIcon } from "../../../icons";
import { pluralize } from "../../../common/helpers/string";
import { Button } from "../../general/Button/Button";
import {
    StandardModalBody,
    StandardModalHeader,
} from "../../general/Modal/Modal";

interface Props {
    transactionsNumber: number;
    onConfirmReversal: () => Promise<unknown>;
    onSkipReversal: () => Promise<unknown>;
    onCancel: () => void;
    showTransactions: () => void;
}

export const ConfirmRuleReversalModal: React.FC<Props> = ({
    transactionsNumber,
    onConfirmReversal,
    onSkipReversal,
    onCancel,
    showTransactions,
}) => {
    const [removingWithReversal, setRemovingWithReversal] = useState(false);
    const [removingWithoutReversal, setRemovingWithoutReversal] =
        useState(false);

    return (
        <>
            <StandardModalHeader className="p-0" />
            <StandardModalBody>
                <p className="mb-4">
                    <Button
                        variant="tertiary"
                        className="p-0"
                        size="sm"
                        onClick={showTransactions}
                    >
                        {transactionsNumber}
                        {pluralize("transaction", transactionsNumber)}{" "}
                        <ArrowCornerIcon className="ml-1" />
                    </Button>{" "}
                    were changed by this rule. Would you like to revert these
                    changes?
                </p>

                <footer className="d-flex">
                    <Button variant="secondary" onClick={onCancel}>
                        Cancel
                    </Button>
                    <ButtonWithLoader
                        variant="secondary"
                        onClick={onSkipReversal}
                        className="ml-auto mr-2"
                        loading={removingWithoutReversal}
                        setLoading={setRemovingWithoutReversal}
                        disabled={removingWithReversal}
                    >
                        No, just remove rule
                    </ButtonWithLoader>
                    <ButtonWithLoader
                        variant="default"
                        onClick={onConfirmReversal}
                        loading={removingWithReversal}
                        setLoading={setRemovingWithReversal}
                        disabled={removingWithoutReversal}
                    >
                        Yes, revert
                    </ButtonWithLoader>
                </footer>
            </StandardModalBody>
        </>
    );
};
