import React from "react";
import { Counterparty } from "../../common/types/counterparty";
import { TransactionRule } from "../../common/types/transactionRule";
import { RuleCondition } from "./RuleCondition/RuleCondition";
import styles from "./TransactionRuleConditions.module.scss";

interface Props {
    rule: TransactionRule;
    counterparties: Counterparty[];
}

export const TransactionRuleConditions: React.FC<Props> = ({
    rule,
    ...rest
}) => (
    <>
        {rule.conditions.map((condition) => (
            <span key={condition.id} className={styles.condition}>
                <RuleCondition condition={condition} rule={rule} {...rest} />.{" "}
            </span>
        ))}
    </>
);
