import classNames from "classnames";

type ClassValue = string | number | boolean | null | undefined;

type Modifiers = Record<string, ClassValue>;

export function bem(blockName: string): {
    (modifiers: Modifiers): string;
    (elementName: string, modifiers: Modifiers): string;
} {
    return function block(
        elementOrModifiers: string | Modifiers,
        modifiers?: Modifiers,
    ): string {
        if (typeof elementOrModifiers === "string") {
            const elementName = elementOrModifiers;
            return modifiersToClassName(
                `${blockName}__${elementName}`,
                modifiers ?? {},
            );
        }

        return modifiersToClassName(blockName, elementOrModifiers);
    };
}

function modifiersToClassName(className: string, modifiers: Modifiers) {
    return classNames(
        className,
        ...Object.entries(modifiers || {}).map(
            ([modName, modValue]) => modValue && `${className}--${modName}`,
        ),
    );
}
