import React from "react";
import { isObject } from "lodash";
import isNumber from "lodash/isNumber";
import { currencyFormatter } from "../../../common/helpers/currency";
import {
    RuleConditionAmount,
    TransactionRuleCondition,
} from "../../../common/types/transactionRule";

interface Props {
    condition: TransactionRuleCondition;
}

export const RuleAmountCondition: React.FC<Props> = ({ condition }) => {
    if (
        !isObject(condition.value) ||
        (!("min" in condition.value) && !("max" in condition.value))
    ) {
        return null;
    }

    const value = condition.value as RuleConditionAmount;
    const isCredit =
        (isNumber(value.min) && value.min >= 0) ||
        (isNumber(value.max) && value.max > 0);
    const transactionType = isCredit ? "Cash in" : "Cash out";

    if (isNumber(value.min) && isNumber(value.max)) {
        return (
            <DisplayRange
                min={value.min}
                max={value.max}
                isCredit={isCredit}
                transactionType={transactionType}
            />
        );
    }

    if (isNumber(value.min)) {
        return (
            <DisplayMinValue
                value={value.min}
                isCredit={isCredit}
                transactionType={transactionType}
            />
        );
    }

    if (isNumber(value.max)) {
        return (
            <DisplayMaxValue
                value={value.max}
                isCredit={isCredit}
                transactionType={transactionType}
            />
        );
    }

    return null;
};

interface DisplayRangeValueProps {
    min: number;
    max: number;
    isCredit: boolean;
    transactionType: string;
}

const DisplayRange: React.FC<DisplayRangeValueProps> = ({
    min,
    max,
    isCredit,
    transactionType,
}) => {
    const lowerValue = isCredit ? min : max;
    const upperValue = isCredit ? max : min;

    return (
        <>
            {transactionType} between{" "}
            <strong>{currencyFormatter.format(Math.abs(lowerValue))}</strong>{" "}
            and{" "}
            <strong>{currencyFormatter.format(Math.abs(upperValue))}</strong>
        </>
    );
};

interface DisplaySingleValueProps {
    value: number;
    isCredit: boolean;
    transactionType: string;
}

const DisplayMinValue: React.FC<DisplaySingleValueProps> = ({
    value,
    isCredit,
    transactionType,
}) => (
    <>
        {transactionType}
        {value ? (
            <>
                {" "}
                {isCredit ? "above" : "below"}{" "}
                <strong>{currencyFormatter.format(Math.abs(value))}</strong>
            </>
        ) : null}
    </>
);

const DisplayMaxValue: React.FC<DisplaySingleValueProps> = ({
    value,
    isCredit,
    transactionType,
}) => (
    <>
        {transactionType}
        {value ? (
            <>
                {" "}
                {isCredit ? "below" : "above"}{" "}
                <strong>{currencyFormatter.format(Math.abs(value))}</strong>
            </>
        ) : null}
    </>
);
