import { initContract } from "@ts-rest/core";
import { z } from "zod";
import { Category } from "../types/category";

const c = initContract();

export const categoryContract = c.router(
    {
        getCategories: {
            path: "/:workspaceId",
            method: "GET",
            responses: {
                200: c.type<{ categories: Category[] }>(),
            },
        },
        createCategory: {
            path: "/:workspaceId",
            method: "POST",
            body: z.object({
                label: z.string().min(1),
                parentCategoryId: z.string().uuid(),
            }),
            responses: {
                201: c.type<Category>(),
                400: c.type<{ message: string }>(),
            },
        },
        updateCategory: {
            path: "/:workspaceId/:categoryId",
            method: "PATCH",
            body: z.object({
                label: z.string().min(1),
            }),
            responses: {
                201: c.type<Category>(),
                400: c.type<{ message: string }>(),
            },
        },
        deleteCategory: {
            path: "/:workspaceId/:categoryId",
            method: "DELETE",
            responses: {
                200: c.noBody(),
            },
        },
    },
    {
        pathPrefix: "/category",
    },
);
