import React, { useCallback, useState } from "react";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import { useEntity } from "../../../entity/useEntity";
import { Address } from "../../../../common/types/embeddable/address";
import { useEntityAddressMutation } from "../../../../mutations/entity";
import { USAddress } from "../../../../common/types/embeddable/usAddress";
import { Button } from "../../../general/Button/Button";
import { PlainModal, StandardModalHeader } from "../../../general/Modal/Modal";
import { ClientAddress, ClientAddressProps } from "./ClientAddress";

interface Props {
    address?: Address;
}

export const AddressSettings: React.FC<Props> = ({ address }) => {
    const entity = useEntity();
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const mutation = useEntityAddressMutation(entity);

    const handleSubmit: Required<ClientAddressProps>["onSubmit"] = useCallback(
        async (newAddress) => {
            await mutation.mutateAsync(newAddress);
            setShowUpdateModal(false);
        },
        [mutation],
    );

    return (
        <div className="settings__address">
            <ClientAddress
                address={address as USAddress}
                business={!entity.isPersonal}
                readOnly
            />

            <footer className="m-0">
                <Button
                    variant="tertiary"
                    onClick={() => setShowUpdateModal(true)}
                >
                    Change address
                </Button>
            </footer>

            <PlainModal
                show={showUpdateModal}
                onHide={() => setShowUpdateModal(false)}
            >
                <StandardModalHeader>Change address</StandardModalHeader>

                <ClientAddress
                    address={address as USAddress}
                    business={!entity.isPersonal}
                    onSubmit={handleSubmit}
                >
                    <footer className="d-flex justify-content-end gap-2">
                        <Button
                            variant="secondary"
                            type="button"
                            size="lg"
                            onClick={() => setShowUpdateModal(false)}
                        >
                            Cancel
                        </Button>
                        <ButtonWithLoader
                            loading={mutation.isPending}
                            variant="default"
                            type="submit"
                            size="lg"
                        >
                            Save
                        </ButtonWithLoader>
                    </footer>
                </ClientAddress>
            </PlainModal>
        </div>
    );
};
