import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/Overlay";
import { ButtonCore, ButtonProps } from "./ButtonCore";

export interface ExtendedButtonProps extends ButtonProps {
    tooltip?: React.ReactNode;
    tooltipPlacement?: Placement;
}

export const Button = React.forwardRef<HTMLButtonElement, ExtendedButtonProps>(
    ({ children, tooltip, tooltipPlacement, ...props }, ref) => {
        const button = (
            <ButtonCore {...props} ref={ref}>
                {children}
            </ButtonCore>
        );

        if (tooltip) {
            return (
                <OverlayTrigger
                    placement={tooltipPlacement}
                    overlay={<Tooltip id="btn-tooltip">{tooltip}</Tooltip>}
                >
                    {button}
                </OverlayTrigger>
            );
        }

        return button;
    },
);

Button.displayName = "ExtendedButton";
