import React from "react";
import { TaxQuestion } from "../../../../common/taxSavingRules";
import { TaxQuestions } from "../../TaxQuestions/TaxQuestions";
import {
    AnswerHandler,
    TaxAnswers,
    TaxQuestionProps,
} from "../../TaxQuestions/types";

interface Props {
    questions: TaxQuestion[];
    answers: TaxAnswers;
    onAnswer: AnswerHandler;
    renderAnswerComponent: TaxQuestionProps["renderAnswerComponent"];
}

export const TransactionTaxQuestions: React.FC<Props> = ({
    questions,
    answers,
    onAnswer,
    renderAnswerComponent,
}) => {
    if (!questions.length) {
        return null;
    }

    return (
        <TaxQuestions
            questions={questions}
            answers={answers}
            onAnswer={onAnswer}
            renderAnswerComponent={renderAnswerComponent}
        />
    );
};
