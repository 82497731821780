import axios, {
    AxiosError,
    InternalAxiosRequestConfig,
    AxiosResponse,
} from "axios";
import { toast } from "react-toastify";
import { LongRunningTaskStatusDto } from "../common/dto/common/long-running-task-status.dto";
import { RequestWithIdempotencyKeyDto } from "../common/dto/common/request-with-idempotency-key.dto";
import { SERVICE_PREFIX, VERSION_HEADER } from "../common/constants";
import { activeWorkspaceProxy } from "../state/workspaceContext";
import { clearUser } from "../helpers/auth";
import { newVersionAvailableProxy } from "../components/general/NewVersionAvailable.context";
import { clearAccessToken, getAccessToken } from "./auth0";

const client = axios.create({
    baseURL: `/${SERVICE_PREFIX.MAIN_APP}`,
});

client.interceptors.response.use(
    async (response: AxiosResponse<any>) => {
        if (response.data?.longRunningTaskId) {
            return await longRunningTaskHelper(
                response.data.longRunningTaskId,
                response,
            );
        }

        return response;
    },
    (error: AxiosError<any>) => {
        if (
            (!error.response || error.response?.status >= 500) &&
            isInitialRequestWithIdempotencyKey(error.config)
        ) {
            const data: RequestWithIdempotencyKeyDto = JSON.parse(
                error.config?.data,
            );
            data.idempotencyKeyRetry = true;

            return client.request({
                ...error.config,
                data: JSON.stringify(data),
            });
        }

        if (error.response?.status === 401) {
            clearAccessToken();
            clearUser();

            throw error;
        }

        const message = error.response?.data?.message;
        if (message) {
            const joinedMessage = Array.isArray(message)
                ? message.join("\n")
                : message;
            const notificationMessage =
                typeof joinedMessage === "string"
                    ? joinedMessage
                    : "Unknown error";

            toast.error(notificationMessage);
        }

        throw error;
    },
);

let lastSeenVersion: string | null = null;
client.interceptors.response.use(async (response: AxiosResponse<any>) => {
    const backendVersion = response.headers[VERSION_HEADER];

    if (!lastSeenVersion) {
        lastSeenVersion = backendVersion;
    } else if (lastSeenVersion !== backendVersion) {
        newVersionAvailableProxy.notifyNewVersionAvailable();
    }

    return response;
});

client.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    if (config.headers?.authorization) {
        return config;
    }
    try {
        const accessToken = await getAccessToken();

        if (accessToken) {
            config.headers = Object.assign(config.headers ?? {}, {
                authorization: `Bearer ${accessToken}`,
            });
        }

        return config;
    } catch {
        clearAccessToken();
        clearUser();
        activeWorkspaceProxy.changeActiveWorkspace(null);

        throw new axios.Cancel(`Can't get access token`);
    }
});

function isInitialRequestWithIdempotencyKey(
    config: InternalAxiosRequestConfig | undefined,
): boolean {
    if (config) {
        try {
            const data: Partial<RequestWithIdempotencyKeyDto> = JSON.parse(
                config.data,
            );

            return Boolean(data.idempotencyKey && !data.idempotencyKeyRetry);
        } catch {
            return false;
        }
    } else {
        return false;
    }
}

export const backendClient = client;

export function unwrapResponse<T = any>(response: AxiosResponse<T>): T {
    return response?.data;
}

export class LongRunningTaskError extends Error {
    public response: { data: { message: string } };
    constructor(message: string) {
        super(message);
        this.response = { data: { message } };
    }
}

function longRunningTaskHelper(
    id: number,
    response: AxiosResponse<any>,
): Promise<AxiosResponse<any>> {
    return new Promise<AxiosResponse<any>>((resolve, reject) => {
        const interval = setInterval(() => {
            client
                .get<LongRunningTaskStatusDto>(`/long-running-task/${id}`)
                .then(({ data }) => {
                    if (data.isFinished) {
                        if (data.hasError) {
                            reject(new LongRunningTaskError(data.result ?? ""));
                        } else {
                            resolve({ ...response, data: data.result });
                        }

                        clearInterval(interval);
                    }
                })
                .catch((e: Error) => {
                    reject(e);
                    clearInterval(interval);
                });
        }, 5000);
    });
}
