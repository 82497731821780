import React, { useState, useCallback } from "react";
import {
    GridTable,
    GridTableHeader,
} from "../../general/Tables/GridTable/GridTable";
import { SortableTreeList } from "../../general/SortableTreeList/SortableTreeList";
import { useCategoriesTree } from "../../../hooks/useCategoriesTree";
import { CategoryFlattenedItem } from "./utils";
import styles from "./Categories.module.scss";
import { CategoriesHeader } from "./children/CategoriesHeader";
import { CategoryItem } from "./children/CategoryItem";
import { useCustomCategoryFlattenedItems } from "./useCustomCategoryFlattenedItems";

interface Props {
    closeSettingsModal?: (disableAnimation?: boolean) => void;
}

export const Categories: React.FC<Props> = () => {
    const categories = useCategoriesTree();

    const [collapsedCategories, setCollapsedCategories] = useState<Set<string>>(
        new Set(),
    );

    const [search, setSearch] = useState("");

    const flattenedItems = useCustomCategoryFlattenedItems(search);

    const renderItemCallback = useCallback(
        (category: CategoryFlattenedItem) => (
            <CategoryItem
                item={category}
                collapsedCategories={collapsedCategories}
                setCollapsedCategories={setCollapsedCategories}
            />
        ),
        [collapsedCategories, setCollapsedCategories],
    );

    const getItemId = useCallback((c: CategoryFlattenedItem) => c.id, []);

    return (
        <main>
            <CategoriesHeader
                collapsedCategories={collapsedCategories}
                setCollapsedCategories={setCollapsedCategories}
                categories={categories}
                search={search}
                setSearch={setSearch}
            />

            <GridTable className={styles.categoriesTable}>
                <GridTableHeader
                    columnBorders={false}
                    columns={[
                        { key: "name", label: "Name" },
                        { key: "description", label: "Description" },
                        { key: "actions", label: "" },
                    ]}
                    className={styles.headerRow}
                />

                <SortableTreeList
                    items={flattenedItems}
                    getItemId={getItemId as any}
                    listId="categories"
                    renderItem={renderItemCallback as any}
                />
            </GridTable>
        </main>
    );
};
