import React from "react";
import { TransactionDirectionType } from "../../common/categories";
import { TransactionListItemDto } from "../../common/types/transaction";
import { useCategoryLabelGetter } from "../../hooks/useCategoryLabelGetter";
import { CategoryIcon } from "./CategoryIcon";
import "./TransactionCategory.scss";

interface Props {
    transaction: TransactionListItemDto;
}

export const TransactionCategory: React.FC<Props> = ({ transaction }) => {
    const getCategoryLabel = useCategoryLabelGetter();

    const transactionDirection =
        transaction.amount > 0
            ? TransactionDirectionType.incoming
            : TransactionDirectionType.outgoing;

    if (!transaction.category) {
        return null;
    }

    return (
        <span
            className="transaction-category"
            data-testid="transaction-category"
        >
            <CategoryIcon
                categoryId={transaction.categoryId}
                transactionDirection={transactionDirection}
            />
            {getCategoryLabel(transaction.category.id, transactionDirection)}
        </span>
    );
};
