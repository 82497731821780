import { TaxQuestionKey } from "../taxSavingRules";
import { Entity } from "./entity";
import { FinancialAccount } from "./financialAccount";
import { TaxQuestionAnswer } from "./taxQuestionAnswer";
import { AuditLog } from "./auditLog";
import { TransactionMatch } from "./transactionMatch";
import { Counterparty } from "./counterparty";
import { Category } from "./category";

export enum TransactionType {
    EXTERNAL = "external",
    STRIPE = "stripe",
    IMPORTED = "imported",
    RAMP = "ramp",
    MERCURY = "mercury",
    PAYPAL = "paypal",
}

export enum TransactionStatus {
    PENDING = "pending",
    COMPLETED = "completed",
    FAILED = "failed",
}

export enum TransactionClassification {
    BUSINESS = "business",
    PERSONAL = "personal",
}

export enum BasisType {
    CONTRIBUTION = "contribution",
    DISTRIBUTION = "distribution",
}

export enum CategoryBackupKeys {
    USER = "user",
    OPS = "ops",
    CATEGORIZATION_MODEL = "categorizationModel",
    SIMILARITY = "similarity",
    CATEGORIZATION_RULES = "categorizationRules",
}

export type CategoryBackup = Partial<Record<CategoryBackupKeys, string>>;

export interface TransactionBase {
    id: number;
    amount: number;
    absoluteAmount: number;
    originalAmount: number;
    date: Date;
    type: TransactionType;
    status: TransactionStatus;
    bankDescription: string;
    displayDescription: string | null;
    categoryId: string;
    businessCategoryId: string | null;
    personalCategoryId: string | null;
    categoryBackup: CategoryBackup | null;
    counterpartyId: string | null;
    memo: string | null;
    amountSaved: number | null;
    potentialAmountSaved: number | null;
    requiredActions: string[];

    taxQuestions: TaxQuestionKey[];
    deductionRate: number;
    requirementsSnoozed: boolean;
    isIgnored: boolean;
    isBusiness: boolean;

    inReview: boolean;

    pairedTransferId?: number | null;
    splitParentId?: number | null;
    splitDescription?: string | null;

    suggestedCategories?: string[] | null;
}

export interface ClassAssignment {
    classId: string;
    rootClassId: string;
}

export interface Transaction extends TransactionBase {
    financialAccount: FinancialAccount;
    entity: Entity;
    auditLogs?: AuditLog[];
    taxQuestionAnswers: TaxQuestionAnswer[];
    transactionMatches?: TransactionMatch[];
    pairedTransfer?: Transaction | null;
    counterparty?: Counterparty | null;
    splits?: Transaction[] | null;
    classAssignments?: ClassAssignment[];
    category?: Category;
    businessCategory?: Category | null;
}

export type TransactionListItemBaseDto = Pick<
    TransactionBase,
    | "id"
    | "date"
    | "amountSaved"
    | "amount"
    | "status"
    | "splitParentId"
    | "counterpartyId"
    | "categoryId"
    | "isIgnored"
    | "isBusiness"
    | "type"
    | "suggestedCategories"
    | "requiredActions"
    | "requirementsSnoozed"
    | "potentialAmountSaved"
    | "bankDescription"
    | "originalAmount"
    | "pairedTransferId"
    | "inReview"
    | "memo"
    | "displayDescription"
    | "absoluteAmount"
    | "deductionRate"
    | "businessCategoryId"
> & { financialAccountId?: number; requiredActionsCount?: number | null };

export interface TransactionListItemDto extends TransactionListItemBaseDto {
    financialAccount: FinancialAccount;
    entity: Entity;

    category?: Category;
    counterparty?: Counterparty | null;
    classAssignments?: ClassAssignment[];
    transactionMatches?: TransactionMatch[];
    taxQuestionAnswers: TaxQuestionAnswer[];
}

export interface MatchTransferResponse {
    originTransaction: Transaction;
    targetTransaction: Transaction;
}
export interface UnmatchTransferResponse {
    transaction: Transaction;
}
