import React, {
    createContext,
    startTransition,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    NumberParam,
    StringParam,
    UrlUpdateType,
    useQueryParams,
} from "use-query-params";
import { keyBy, omit } from "lodash";
import { useIgnoreLoosingAccessToTransaction } from "../../hooks/useIgnoreLoosingAccessToTransaction";
import { useTypedFlags } from "../../hooks/useTypedFlags";
import { UpdateTransactionDto } from "../../common/dto/transactions/update-transaction.dto";
import { useTransactionsPageSortingCache } from "../../hooks/useTransactionsPageSortingCache";
import { GetTransactionsStatisticsResponseDto } from "../../common/dto/transactions/getTransactions/get-transactions-statistics-response.dto";
import { useTransactionsPageFiltersCache } from "../../hooks/useTransactionsPageFiltersCache";
import { WithRequiredProperties } from "../../common/types/base/generics";
import { TransactionListItemDto } from "../../common/types/transaction";
import { ChildrenProps } from "../../types";
import { useEntities } from "../../hooks/useEntities";
import { noop } from "../../helpers/general";
import { useUpdateTransactionMutation } from "../../mutations/transaction";
import { getTransactionDetails } from "../../lib/transactions";
import { queryClient } from "../../queryClient";
import { useToaster } from "../general/ToastMessages/useToaster";
import { RuleCreationToast } from "../transactionRules/RuleCreationToast/RuleCreationToast";
import { StandardModal } from "../general/Modal/Modal";
import {
    TRANSACTIONS_PAGE_SIZE,
    useTransactionsQuery,
    UseTransactionsQueryParam,
} from "./useTransactionsQuery";
import { LoosingAccessToTransactionModal } from "./LoosingAccessToTransactionModal";
import { TransactionSortValue, UseSort, useSort } from "./useSort";
import {
    DEFAULT_ACTIVITY_FEED_FILTERS,
    TransactionsFilters,
} from "./filters/lib";
import { invalidateTransactionDetailsQueries } from "./TransactionDetails/useTransactionDetailsQuery";

export interface PagedTransactionsTableContextValue {
    currentPage: number;
    totalPages: number;
    transactionCount: number;
    setPage:
        | React.Dispatch<React.SetStateAction<number>>
        | ((
              newValue: number | null | undefined,
              updateType?: UrlUpdateType,
          ) => void);
    handleSortChange: UseSort["handleSortChange"];
    currentSort: UseSort["currentSort"];
    filters: UseTransactionsQueryParam["filters"];
    setCurrentFilters: React.Dispatch<
        React.SetStateAction<TransactionsFilters>
    >;
    transactions?: TransactionListItemDto[];
    statistics?: GetTransactionsStatisticsResponseDto;

    saveTransaction: (
        transaction: TransactionListItemDto,
        payload: UpdateTransactionDto,
    ) => Promise<TransactionListItemDto | undefined>;
    upsertTransaction(transaction: TransactionListItemDto): void;
    updateMany(transactions: TransactionListItemDto[]): void;
    shownTransactionId?: number;
    setShownTransactionId: (t?: number) => void;
}

const defaultValue: PagedTransactionsTableContextValue = {
    currentPage: 0,
    totalPages: 0,
    transactionCount: 0,
    setPage: () => {},
    handleSortChange: () => {},
    currentSort: TransactionSortValue.NONE,
    filters: {
        entityIds: [],
    },
    setCurrentFilters: () => {},

    upsertTransaction: noop,
    updateMany: noop,
    saveTransaction: async (t) => t,
    setShownTransactionId: noop,
};

export const PaginatedTransactionsTableContext =
    createContext<PagedTransactionsTableContextValue>(defaultValue);

export interface PaginatedTransactionsTableContextProviderProps
    extends ChildrenProps {
    parentFilters?: TransactionsFilters;
    page: number;
    setPage:
        | React.Dispatch<React.SetStateAction<number>>
        | ((
              newValue: number | null | undefined,
              updateType?: UrlUpdateType,
          ) => void);
    useCache?: boolean;
}

interface LoosingAccessContext {
    ownerName: string;
    transaction: TransactionListItemDto;
    payload: UpdateTransactionDto;
}

export const PaginatedTransactionsTableContextProvider: React.FC<
    PaginatedTransactionsTableContextProviderProps
> = ({ parentFilters, page, setPage, children, useCache = true }) => {
    const [loosingAccessContext, setLoosingAccessContext] =
        useState<LoosingAccessContext>();
    const { ignoreLoosingAccess } = useIgnoreLoosingAccessToTransaction();
    const [shownTransactionId, setShownTransactionId] = useState<number>();
    const availableEntities = useEntities();
    const availableEntityIds = availableEntities.map((entity) => entity.id);
    const { toast } = useToaster();
    const { suggestedRules: suggestedRulesEnabled } = useTypedFlags();

    const [cachedFilters, setCachedFilters] = useTransactionsPageFiltersCache();
    const [cachedSorting, setCachedSorting] = useTransactionsPageSortingCache();
    const entities = useEntities();
    const [currentFilters, setCurrentFilters] = useState<TransactionsFilters>(
        () => {
            let initialFilters: TransactionsFilters = {
                ...DEFAULT_ACTIVITY_FEED_FILTERS,
            };

            if (parentFilters && Object.keys(parentFilters).length > 0) {
                Object.assign(initialFilters, parentFilters);

                if (parentFilters.entityIds) {
                    initialFilters.entityIds = parentFilters.entityIds.filter(
                        (entityId) =>
                            entities.some(({ id }) => id === entityId),
                    );
                }

                return initialFilters;
            }

            if (useCache && cachedFilters) {
                initialFilters = { ...initialFilters, ...cachedFilters };

                if (cachedFilters.entityIds?.length) {
                    initialFilters.entityIds = cachedFilters.entityIds.filter(
                        (entityId) =>
                            entities.some((entity) => entity.id === entityId),
                    );
                }
            }

            return initialFilters;
        },
    );

    const queryFiltersToPass: WithRequiredProperties<
        TransactionsFilters,
        "entityIds"
    > = useMemo(
        () => ({
            ...currentFilters,
            entityIds:
                currentFilters.entityIds && currentFilters.entityIds.length > 0
                    ? currentFilters.entityIds
                    : entities.map((entity) => entity.id),
        }),
        [currentFilters, entities],
    );

    const { currentSort, sortExpression, handleSortChange } = useSort(
        useCache ? cachedSorting : undefined,
    );

    const handleSortChangeWithPageReset = useCallback(
        (value: TransactionSortValue) => {
            startTransition(() => {
                handleSortChange(value);
                if (useCache) {
                    setCachedSorting(value);
                }
                setPage(1);
            });
        },
        [handleSortChange, useCache, setCachedSorting, setPage],
    );

    const {
        query: { data },
        queryKey,
    } = useTransactionsQuery({
        filters: queryFiltersToPass,
        sort: sortExpression,
        page,
    });

    const updateTransaction = useUpdateTransactionMutation();

    const upsertTransaction = useCallback(
        (transaction: TransactionListItemDto) => {
            queryClient.setQueryData(
                queryKey,
                (prev: { data: TransactionListItemDto[] }) => {
                    if (prev?.data) {
                        return {
                            ...prev,
                            data: prev.data.map((t) =>
                                t.id === transaction.id ? transaction : t,
                            ),
                        };
                    } else {
                        return { data: [transaction], total: 1, pageCount: 1 };
                    }
                },
            );
        },
        [queryKey],
    );

    const hideTransaction = useCallback(
        (transaction: TransactionListItemDto) => {
            queryClient.setQueryData(
                queryKey,
                (prev: { data: TransactionListItemDto[] }) => {
                    if (prev?.data) {
                        return {
                            ...prev,
                            data: prev.data.filter(
                                (t) => t.id !== transaction.id,
                            ),
                        };
                    } else {
                        return { data: [], total: 0, pageCount: 0 };
                    }
                },
            );
        },
        [queryKey],
    );

    const [
        {
            transactionId: shownTransactionIdParam,
            transactionType: shownTransactionTypeParam,
        },
    ] = useQueryParams({
        transactionId: NumberParam,
        transactionType: StringParam,
    });

    useEffect(() => {
        if (shownTransactionIdParam && shownTransactionTypeParam) {
            setTimeout(() => {
                // delay to make sure transaction details are fetched after transaction list
                setShownTransactionId(shownTransactionIdParam);
            }, 500);
        }
    }, [
        shownTransactionIdParam,
        shownTransactionTypeParam,
        setShownTransactionId,
    ]);

    const updateMany = useCallback(
        (transactionsToUpdate: TransactionListItemDto[]) => {
            queryClient.setQueryData(
                queryKey,
                (prev: { data: TransactionListItemDto[] }) => {
                    if (!prev?.data) {
                        return;
                    }

                    const dict = keyBy(transactionsToUpdate, "id");
                    const newArray = prev.data.map((t) =>
                        dict[t.id] ? { ...t, ...dict[t.id] } : t,
                    );

                    return {
                        ...prev,
                        data: newArray,
                    };
                },
            );
        },
        [queryKey],
    );

    const saveTransaction = useCallback(
        async (
            transaction: TransactionListItemDto,
            payload: UpdateTransactionDto,
        ) => {
            try {
                const updateForList = omit(payload, "taxQuestionAnswers");
                if (!payload.entityId && !payload.categoryId) {
                    upsertTransaction({
                        ...transaction,
                        ...updateForList,
                    });
                }

                const response = await updateTransaction.mutateAsync({
                    transaction,
                    update: {
                        loosingAccessConfirmed: ignoreLoosingAccess,
                        ...payload,
                    },
                });

                if ("transaction" in response) {
                    const updated = response.transaction;

                    if (response.ruleCreationContext && suggestedRulesEnabled) {
                        const { categoryId, ...rest } =
                            response.ruleCreationContext;
                        toast(
                            <RuleCreationToast
                                newCategory={categoryId}
                                {...rest}
                            />,
                        );
                    }

                    if (!availableEntityIds.includes(updated.entity.id)) {
                        // user no longer has access to the transaction
                        hideTransaction(updated);
                        setShownTransactionId(undefined);

                        return updated;
                    } else {
                        // refresh transaction if user still has access to it
                        await invalidateTransactionDetailsQueries();

                        const updatedTransaction = await getTransactionDetails(
                            transaction.id,
                        );

                        upsertTransaction(updatedTransaction.transaction);

                        return updatedTransaction.transaction;
                    }
                } else {
                    setLoosingAccessContext({
                        ownerName: response.loosingAccessTo,
                        transaction,
                        payload,
                    });
                }
            } catch (e) {
                upsertTransaction(transaction);
                throw e;
            }
        },
        [
            ignoreLoosingAccess,
            updateTransaction,
            upsertTransaction,
            availableEntityIds,
            hideTransaction,
            suggestedRulesEnabled,
            toast,
        ],
    );

    const onLoosingAccessModalClosed = useCallback(
        (confirmLoosingAccess?: boolean) => {
            if (confirmLoosingAccess && loosingAccessContext) {
                saveTransaction(loosingAccessContext.transaction, {
                    ...loosingAccessContext.payload,
                    loosingAccessConfirmed: true,
                }).catch((e) => {
                    throw e;
                });
            }

            setLoosingAccessContext(undefined);
        },
        [loosingAccessContext, saveTransaction],
    );

    const contextValue = useMemo<PagedTransactionsTableContextValue>(() => {
        const total = data?.total ?? 0;
        const localPageCount = Math.max(
            1,
            Math.ceil(total / TRANSACTIONS_PAGE_SIZE),
        );

        return {
            currentPage: page,
            totalPages: localPageCount,
            transactionCount: total,
            setPage,
            handleSortChange: handleSortChangeWithPageReset,
            currentSort,
            filters: queryFiltersToPass,
            setCurrentFilters,
            transactions: data?.data,
            upsertTransaction,
            saveTransaction,
            updateMany,
            shownTransactionId,
            setShownTransactionId,
        };
    }, [
        currentSort,
        handleSortChangeWithPageReset,
        page,
        queryFiltersToPass,
        setPage,
        data,
        upsertTransaction,
        saveTransaction,
        updateMany,
        shownTransactionId,
        setShownTransactionId,
    ]);

    useEffect(() => {
        if (useCache) {
            setCachedFilters(currentFilters);
        }
    }, [currentFilters, useCache, setCachedFilters]);

    return (
        <>
            <PaginatedTransactionsTableContext.Provider value={contextValue}>
                {children}
            </PaginatedTransactionsTableContext.Provider>
            <StandardModal
                show={!!loosingAccessContext}
                onHide={onLoosingAccessModalClosed}
            >
                {loosingAccessContext && (
                    <LoosingAccessToTransactionModal
                        ownerName={loosingAccessContext.ownerName}
                        close={onLoosingAccessModalClosed}
                    />
                )}
            </StandardModal>
        </>
    );
};
