import classnames from "classnames";
import "./ButtonWithLoader.scss";
import { UseMutationResult } from "@tanstack/react-query";
import { noop } from "../../../helpers/general";
import { SpinnerIcon } from "../../../icons";
import { Button, ExtendedButtonProps } from "../Button/Button";

interface CommonProps extends ExtendedButtonProps {
    className?: string;
}

interface HandlerProps extends CommonProps {
    loading: boolean;
    setLoading?: (isLoading: boolean) => void;
    onClick?: (e?: any) => Promise<unknown> | void;
    mutation?: never;
    mutationData?: never;
}

interface MutationProps<MutationData = void> extends CommonProps {
    loading?: never;
    setLoading?: never;
    onClick?: never;
    mutation: UseMutationResult<unknown, unknown, MutationData>;
    mutationData?: MutationData;
}

export const ButtonWithLoader = <MutationData = void,>({
    loading,
    setLoading = noop,
    onClick,
    children,
    className = "",
    mutation,
    mutationData,
    ...props
}: HandlerProps | MutationProps<MutationData>) => {
    if (!mutation && loading === undefined) {
        throw new Error("Either mutation or loading must be set");
    }

    const finalClassName = classnames(`btn-with-loader ${className}`, {
        "btn-with-loader--loading": loading ?? mutation?.isPending,
    });

    const handleClick = (e: any) => {
        if (onClick) {
            if (loading) {
                return;
            }

            setLoading(true);

            const promise = onClick(e);

            if (promise) {
                promise.finally(() => setLoading(false));
            } else {
                setLoading(false);
            }
        } else if (mutation) {
            if (mutation.isPending) {
                return;
            }

            mutation.mutate(mutationData as any);
        }
    };

    return (
        <Button className={finalClassName} onClick={handleClick} {...props}>
            <span className="btn-with-loader__content">{children}</span>
            <aside className="btn-with-loader__spinner">
                <SpinnerIcon className="icon-color-current" />
            </aside>
        </Button>
    );
};
