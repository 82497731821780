import React from "react";
import { chunk } from "lodash";
import { ContractorPaymentTotals } from "../../../common/types/payroll";
import css from "./PayrollDetails.module.scss";
import { ContractorPaymentDetailsTotalItem } from "./ContractorPaymentDetailsTotalItem";
interface Props {
    totals?: ContractorPaymentTotals | null;
}

const totalsOrder: Array<keyof ContractorPaymentTotals> = [
    "debitAmount",
    "wageAmount",
    "reimbursementAmount",
];
export const ContractorPaymentDetails: React.FC<Props> = ({ totals }) => (
    <div className={css.payrollDetails}>
        <table className="table table-sm table-borderless">
            <tbody>
                {chunk(totalsOrder, 2).map(([itemLeftCol, itemRightCol]) => (
                    <tr key={itemLeftCol + "_" + itemRightCol}>
                        <ContractorPaymentDetailsTotalItem
                            totals={totals}
                            total={itemLeftCol}
                        />
                        <ContractorPaymentDetailsTotalItem
                            totals={totals}
                            total={itemRightCol}
                        />
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);
