import { Entity } from "../../common/types/entity";

export interface CommandsOptions {
    defaultEntity?: Entity;
}

export interface CommandsListProps {
    options?: CommandsOptions;
}

export enum CommandsDirectory {
    HOME = "HOME",
    ACCOUNTS = "ACCOUNTS",
    ONBOARDING = "ONBOARDING",
    WORKSPACES = "WORKSPACES",
}

export const COMMAND_DIRECTORY_LABELS = {
    [CommandsDirectory.HOME]: "Home",
    [CommandsDirectory.ACCOUNTS]: "Add accounts",
    [CommandsDirectory.ONBOARDING]: "Add accounts",
    [CommandsDirectory.WORKSPACES]: "Workspaces",
} as const satisfies Record<CommandsDirectory, string>;
