import isNumber from "lodash/isNumber";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { RuleCreationToastData } from "../../general/ToastMessages/lib/ruleCreation";
import { CategoryIcon } from "../../transactions/CategoryIcon";
import { useRuleCreationMutation } from "../../../mutations/transactionRules";
import { useWorkspaceContext } from "../../../state/workspaceContext";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { useCategoryLabelGetter } from "../../../hooks/useCategoryLabelGetter";
import { TransactionDirectionType } from "../../../common/categories";
import { TransactionRuleCreationContext } from "../TransactionRuleCreation/TransactionRuleCreation.context";
import { pluralize } from "../../../common/helpers/string";
import { CreateTransactionRuleDto } from "../../../common/dto/transactionRule/create-transaction-rule.dto";
import { RuleSource } from "../../../common/types/transactionRule";
import { TransactionRuleUpdateDto } from "../../../common/dto/transactionRule/transaction-rule-update.dto";
import { Button } from "../../general/Button/Button";
import { useCategoriesById } from "../../../hooks/useCategoriesById";
import styles from "./RuleCreationToast.module.scss";

type Props = RuleCreationToastData;

export const RuleCreationToast: React.FC<Props> = ({
    newCategory,
    counterparty,
    conditions,
    transactionsNumber,
    entityId,
}) => {
    const { createRule } = useContext(TransactionRuleCreationContext);
    const labelGetter = useCategoryLabelGetter();
    const categoriesById = useCategoriesById();
    const category = !isNumber(newCategory)
        ? categoriesById[newCategory]
        : undefined;

    const { activeWorkspace } = useWorkspaceContext();
    const [ruleAdded, setRuleAdded] = useState(false);
    const ruleCreation = useRuleCreationMutation(() => setRuleAdded(true));

    const newRuleDefinition = useMemo(
        () =>
            ({
                categoryId: newCategory,
                conditions,
                entityId,
            }) satisfies TransactionRuleUpdateDto,
        [newCategory, conditions, entityId],
    );
    const ruleCreationPayload: CreateTransactionRuleDto | undefined =
        useMemo(() => {
            if (!activeWorkspace) {
                return;
            }

            return {
                workspaceId: activeWorkspace.id,
                applyToExistingTransactions: true,
                rule: newRuleDefinition,
                source: RuleSource.TRANSACTIONS_FEED,
            };
        }, [activeWorkspace, newRuleDefinition]);

    const reviewRule = useCallback(() => {
        createRule({
            definition: {
                ...newRuleDefinition,
                category,
            },
            counterparties: [counterparty],
            source: RuleSource.TRANSACTIONS_FEED,
        });
    }, [newRuleDefinition, counterparty, createRule, category]);

    const categoryIcon = (
        <CategoryIcon
            categoryId={category?.id as string}
            transactionDirection={TransactionDirectionType.mixed}
        />
    );
    const categoryLabel = labelGetter(newCategory);

    return (
        <div className={styles.message}>
            <p>
                Categorize <strong>{counterparty.name}</strong> as{" "}
                <span className={styles.category}>
                    {categoryIcon} <strong>{categoryLabel}</strong>
                </span>{" "}
                going forward
                {transactionsNumber > 0 ? (
                    <>
                        {" "}
                        and apply to{" "}
                        <strong>
                            {transactionsNumber} similar{" "}
                            {pluralize("transaction", transactionsNumber)}
                        </strong>
                    </>
                ) : null}
                ?
            </p>

            <footer className={styles.footer}>
                {ruleAdded ? (
                    "Rule created"
                ) : (
                    <>
                        <Button
                            variant="tertiary"
                            size="sm"
                            onClick={reviewRule}
                            className={styles.review}
                        >
                            Review
                        </Button>
                        <ButtonWithLoader
                            variant="secondary"
                            size="sm"
                            mutation={ruleCreation}
                            mutationData={ruleCreationPayload}
                        >
                            Create rule
                        </ButtonWithLoader>
                    </>
                )}
            </footer>
        </div>
    );
};
