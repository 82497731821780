import { GetSimulationsDashboardResponseDto } from "../common/dto/simulations/get-simulations-dashboard-response.dto";
import { SimulateIncomingSmsDto } from "../common/dto/simulations/simulate-incoming-sms.dto";
import { SimulatePlaidErrorDto } from "../common/dto/simulations/simulate-plaid-error.dto";
import { backendClient, unwrapResponse } from "./backendClient";

export async function getSimulationsDashboard(): Promise<GetSimulationsDashboardResponseDto> {
    return unwrapResponse(await backendClient.get("/simulations/dashboard"));
}

export async function simulateRevokeConnectionPermissions(
    id: number,
): Promise<void> {
    await backendClient.post(`/simulations/revoke-permissions/${id}`);
}

export async function simulatePlaidError(
    data: SimulatePlaidErrorDto,
): Promise<void> {
    await backendClient.post("/simulations/simulate-plaid-error", data);
}

export async function simulateIncomingSms(
    data: SimulateIncomingSmsDto,
): Promise<void> {
    await backendClient.post("/simulations/incoming-sms", data);
}
