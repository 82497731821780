import { InvoiceFilters } from "../../../common/contracts/invoice.contract";
import { InvoiceStatus } from "../../../common/types/invoice";

export type FiltersChangeFn = (payload: Partial<InvoiceFilters>) => void;

export interface FilterProps {
    filters: InvoiceFilters;
    onChange: FiltersChangeFn;
}

export const DEFAULT_FILTERS: InvoiceFilters = {
    statuses: [InvoiceStatus.DRAFT, InvoiceStatus.OPEN, InvoiceStatus.PAID],
};

export enum AggregatedFiltersTabs {
    DATE = "date",
    STATUS = "status",
    DUE = "due",
    AMOUNT = "amount",
    ACCOUNT = "account",
}

export const DEFAULT_TAB = AggregatedFiltersTabs.DATE;
