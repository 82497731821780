import React, { useContext } from "react";
import classNames from "classnames";
import { NUMBER_OF_CELLS_PER_LINE } from "../journalEntryModal/JournalEntryModal";
import styles from "./EditableTable.module.scss";
import { EditableTableContext } from "./EditableTableContext";

const titleClass = "title small " + styles.editableTableTitle;

export const EditableTableHeader = () => {
    const {
        selectedArea,
        setSelectedArea,
        combineWithExistingSelectedArea,
        targetLines,
        headerRef,
    } = useContext(EditableTableContext);

    const setSelectedAreaForColumn = (
        event: React.MouseEvent,
        column: number,
    ) => {
        const newArea = {
            topLeftCellPosition: {
                columnIndex: column,
                rowIndex: 0,
            },
            bottomRightCellPosition: {
                columnIndex: column === 0 ? NUMBER_OF_CELLS_PER_LINE : column,
                rowIndex: targetLines.length - 1,
            },
        };
        if (event.shiftKey) {
            combineWithExistingSelectedArea(
                {
                    columnIndex: column,
                    rowIndex: 0,
                },
                {
                    columnIndex:
                        column === 0 ? NUMBER_OF_CELLS_PER_LINE : column,
                    rowIndex: targetLines.length - 1,
                },
            );
            return;
        }
        setSelectedArea(
            newArea.topLeftCellPosition,
            newArea.bottomRightCellPosition,
        );
    };

    const isFullColumnSelected = (columnIndex: number) => {
        if (!selectedArea) {
            return false;
        }
        return (
            columnIndex >= selectedArea?.topLeftCellPosition.columnIndex &&
            columnIndex <= selectedArea?.bottomRightCellPosition.columnIndex &&
            selectedArea?.topLeftCellPosition.rowIndex === 0 &&
            selectedArea.bottomRightCellPosition.rowIndex ===
                targetLines.length - 1
        );
    };

    return (
        <div ref={headerRef} className={styles.editableTableHeader}>
            <div
                onClick={(e) => setSelectedAreaForColumn(e, 0)}
                className={classNames(
                    styles.editableTableHeaderCell,
                    isFullColumnSelected(0)
                        ? styles.editableTableCellSelected
                        : "",
                )}
            ></div>
            <div
                onClick={(e) => setSelectedAreaForColumn(e, 1)}
                className={classNames(
                    styles.editableTableHeaderCell,
                    isFullColumnSelected(1)
                        ? styles.editableTableCellSelected
                        : "",
                )}
            >
                <p className={titleClass}>Account</p>
            </div>
            <div
                onClick={(e) => setSelectedAreaForColumn(e, 2)}
                className={classNames(
                    styles.editableTableHeaderCell,
                    isFullColumnSelected(2)
                        ? styles.editableTableCellSelected
                        : "",
                )}
            >
                <p className={titleClass}>Debit</p>
            </div>
            <div
                onClick={(e) => setSelectedAreaForColumn(e, 3)}
                className={classNames(
                    styles.editableTableHeaderCell,
                    isFullColumnSelected(3)
                        ? styles.editableTableCellSelected
                        : "",
                )}
            >
                <p className={titleClass}>Credit</p>
            </div>
            <div
                onClick={(e) => setSelectedAreaForColumn(e, 4)}
                className={classNames(
                    styles.editableTableHeaderCell,
                    isFullColumnSelected(4)
                        ? styles.editableTableCellSelected
                        : "",
                )}
            >
                <p className={titleClass}>Description</p>
            </div>
            <div className={styles.editableTableHeaderCell} />
        </div>
    );
};
