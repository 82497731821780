export const VERIFICATION_CODE_LENGTH = 6;
export const SUGGESTED_EMAIL_KEY = "suggestedEmail";
export const DEFAULT_ICON_COLOR = "#D9DDE2";
export const isReducedMotionEnabled =
    (window.matchMedia(`(prefers-reduced-motion: reduce)`) as any) === true ||
    window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

export const HEADER_HEIGHT = 64;

export const BALANCE_SHEET_PREVIEW_URL = "/images/features/balancesheet.png";
export const GENERAL_LEDGER_PREVIEW_URL = "/images/features/generalledger.png";
export const TYPEFORM_OPENING_BALANCES_URL_BASE =
    "https://kickapp.typeform.com/to/wzXotITy";
export const PARTNERSTACK_PARTNERSHIPS_URL_BASE =
    "https://api.partnerstack.com/v1/partnerships/anonymous/kick";
export const TYPEFORM_INVOICES_EARLY_ACCESS_URL =
    "https://kickapp.typeform.com/to/sHXMBQxp";

export const SEARCH_DEBOUNCE_TIME = 500;
export const ACTIVE_USER_ID_KEY = "active-user-id";
