import React, { forwardRef } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import classNames from "classnames";
import "./SelectDropdown.scss";

interface Props {
    className?: string;
    prepend?: React.ReactNode;
    append?: React.ReactNode;
    onScroll?: (e: React.UIEvent) => void;
    children: React.ReactNode;
    dropdownRef?: React.Ref<HTMLDivElement>;
    disableAutoHeight?: boolean;
}

export interface SelectDropdownRef {
    container: HTMLDivElement;
    scrollTop: (top: number) => void;
    getScrollTop: () => number;
    getScrollHeight: () => number;
    getClientHeight: () => number;
}

export const SelectDropdown = forwardRef<SelectDropdownRef | undefined, Props>(
    (
        {
            prepend,
            className,
            children,
            onScroll,
            dropdownRef,
            append,
            disableAutoHeight = false,
        },
        ref,
    ) => (
        <div
            className={classNames("select-dropdown", className)}
            ref={dropdownRef}
        >
            {prepend}
            <Scrollbars
                ref={ref as any} //nosonar
                style={{ width: "100%", height: "100%" }}
                onScroll={onScroll}
                autoHeight={!disableAutoHeight}
                autoHeightMin={48}
                autoHeightMax={260}
            >
                {children}
            </Scrollbars>
            {append}
        </div>
    ),
);
