import classNames from "classnames";
import React from "react";
import { Button } from "../../../../general/Button/Button";
import { noop } from "../../../../../helpers/general";
import { ShareWithUserIcon } from "../../../../../icons";
import { ActionCard, ActionCardProps } from "../../../../actions/ActionCard";
import { ActionCardCompleted } from "../../../../actions/ActionCardCompleted";
import { ActionCardField } from "../../../../actions/ActionCardField";
import { ActionCardSection } from "../../../../actions/ActionCardSection";
import { ActionCloseButton } from "../../../../actions/ActionCloseButton";
import { ActionConfirmButton } from "../../../../actions/ActionConfirmButton";
import { FinancialDocumentExtensionIcon } from "../../../../documents/FinancialDocumentExtensionIcon";
import {
    CustomSelect,
    CustomSelectOption,
} from "../../../../forms/CustomSelect/CustomSelect";
import { ObjectIcon } from "../../../../general/ObjectIcon/ObjectIcon";
import { Tag } from "../../../../general/Tag/Tag";
import { DemoActionProps } from "../lib";
import { useDefaultYear } from "../../../../../hooks/useDefaultYear";
import styles from "./ShareWithAccountantCard.module.scss";

const accountantOptions: Array<CustomSelectOption<string>> = [
    {
        value: "christian-wolff",
        label: (
            <span className="d-flex align-items-center">
                <ObjectIcon
                    size="md"
                    iconUrl="/images/demo/wolff.png"
                    defaultColor="white"
                    className="mr-2"
                />
                Christian Wolff, CPA
            </span>
        ),
    },
];

export const ShareWithAccountantCard: React.FC<DemoActionProps> = ({
    onConfirm,
    onSkip,
    isCompleted,
    ...rest
}) => {
    const year = useDefaultYear();
    const messageText = `Hey Christian, 

Here’s a link to view my reconciled ${year} Profit & Loss and Balance Sheet. 

Kick has categorized all my expenses and ensured everything is accurate and up-to-date.

Best,
Matt
`;
    const firstSection: ActionCardProps["firstSection"] = (
        inOpened,
        closeCard,
    ) => {
        if (isCompleted) {
            return (
                <ActionCardCompleted
                    header={`Emailed your ${year} financials to Christian Wolff`}
                >
                    <ActionCloseButton onClick={closeCard}>
                        Next
                    </ActionCloseButton>
                </ActionCardCompleted>
            );
        }

        return (
            <ActionCardSection
                icon={<ShareWithUserIcon />}
                header={`Your ${year} financials are ready`}
                isTitle
                inOpened={inOpened}
                ctas={
                    <>
                        <Button variant="secondary" onClick={() => onSkip?.()}>
                            Skip
                        </Button>
                        <ActionConfirmButton onClick={() => onConfirm?.()}>
                            Share
                        </ActionConfirmButton>
                    </>
                }
            ></ActionCardSection>
        );
    };

    const otherSections: ActionCardProps["otherSections"] = (inOpened) => (
        <ActionCardSection inOpened={inOpened}>
            <section className={styles.section}>
                <Tag variant="secondary" size="sm" outline>
                    Deadline: March 15th
                </Tag>
            </section>

            <section className={styles.section}>
                <ActionCardField label="Share with your Accountant">
                    <CustomSelect
                        size="xs"
                        onSelected={noop}
                        dropdownKey="accountant"
                        options={accountantOptions}
                        value={accountantOptions[0].value}
                    />
                </ActionCardField>

                <ActionCardField label="Attachment">
                    <FinancialDocumentExtensionIcon
                        filename={`${year} Tax Package.xlsx`}
                        className="mr-2"
                    />
                    {year} Tax Package.xlsx
                </ActionCardField>
            </section>

            <section className={styles.section}>
                <ActionCardField label="Message" className="w-100">
                    <textarea
                        className={classNames(
                            "form-control form-control-sm",
                            styles.message,
                        )}
                    >
                        {messageText}
                    </textarea>
                </ActionCardField>
            </section>
        </ActionCardSection>
    );

    return (
        <ActionCard
            {...rest}
            firstSection={firstSection}
            otherSections={otherSections}
            isCompleted={isCompleted}
        />
    );
};
