import "./SortableTreeList.scss";
import classNames from "classnames";
import { useCallback, useMemo } from "react";
import {
    AbstractSortableList,
    AbstractSortableListProps,
} from "../AbstractSortableList/AbstractSortableList";
import {
    AbstractSortableListItemType,
    AbstractSortableListItemRenderItemProps,
    AbstractSortableListItemState,
} from "../AbstractSortableList/AbstractSortableListItem";

interface Props<T extends AbstractSortableListItemType>
    extends Omit<AbstractSortableListProps<T>, "renderItem"> {
    indentationWidth?: number;
    renderItem: SortableTreeListRenderItemFunctionType<T>;
}

export interface SortableTreeListOptions {
    state: AbstractSortableListItemState;
}

export type SortableTreeListRenderItemFunctionType<
    T extends AbstractSortableListItemType,
> = (item: T, options: SortableTreeListOptions) => React.ReactNode;

export const SortableTreeList = <T extends AbstractSortableListItemType>({
    indentationWidth = 50,
    renderItem,
    renderDragOverlay,
    ...rest
}: Props<T>) => {
    const renderDragOverlayDefault = useCallback(
        (activeItem: T) => (
            <>
                {activeItem
                    ? renderItem(activeItem, {
                          state: {
                              isDragged: false,
                              depth: 0,
                              parentIsDragged: false,
                          },
                      })
                    : null}
            </>
        ),
        [renderItem],
    );

    const renderWrappedItemMemoized = useCallback(
        (item: T, options: AbstractSortableListItemRenderItemProps) => (
            <WrappedItem
                item={item}
                options={options}
                renderItem={renderItem}
                indentationWidth={indentationWidth}
            />
        ),
        [indentationWidth, renderItem],
    );
    return (
        <div className="sortable-tree-list">
            <AbstractSortableList
                {...rest}
                renderItem={renderWrappedItemMemoized}
                renderDragOverlay={
                    renderDragOverlay ?? renderDragOverlayDefault
                }
            />
        </div>
    );
};

export function WrappedItem<T extends AbstractSortableListItemType>({
    item,
    renderItem,
    options: { state, setNodeRef, style },
    indentationWidth,
}: {
    item: T;
    options: AbstractSortableListItemRenderItemProps;
    renderItem: SortableTreeListRenderItemFunctionType<T>;
    indentationWidth: number;
}) {
    const { isDragged, parentIsDragged, depth } = state;

    const memoizedOptions = useMemo(
        () => ({
            state,
        }),
        [state],
    );

    return (
        <div
            className={classNames("sortable-tree-list__item", {
                "sortable-tree-list__item--parent-dragged": parentIsDragged,
                "sortable-tree-list__item--dragged": isDragged,
            })}
            ref={setNodeRef}
            style={style}
        >
            {isDragged && (
                <>
                    <div
                        className="sortable-tree-list__insertion-indicator"
                        style={{
                            left: indentationWidth * depth + 10,
                            width: `calc(100% - ${indentationWidth * depth}px - 10px)`,
                        }}
                    ></div>
                    <div
                        className="sortable-tree-list__insertion-indicator-dot"
                        style={{
                            left: indentationWidth * depth,
                        }}
                    ></div>
                </>
            )}
            {renderItem(item, memoizedOptions)}
        </div>
    );
}
