import { useCallback, useMemo } from "react";

import { JournalEntrySourceType } from "../../../common/types/domains/accounting/journalEntry";
import { AccountTypeWithAccounts } from "../../../hooks/useAccounts";
import { EditableCellSearchOption } from "../editableTable/EditableTableCellSearch";
import {
    JournalEntryLineProxyObject,
    JournalEntryModalFormikConfig,
} from "./JournalEntryModal";
import { calculateDebitAndCreditTotals } from "./JournalEntryModalUtils";

export function useIsManualJournalEntry(values: JournalEntryModalFormikConfig) {
    return useMemo(
        () =>
            values.journalEntryId === undefined ||
            values.journalEntry.source?.type ===
                JournalEntrySourceType.ManualJournalEntry,
        [values.journalEntry.source, values.journalEntryId],
    );
}

export function useAccountsSearchOptions(
    accountTypesWithAccounts: AccountTypeWithAccounts[],
) {
    return useMemo(
        () =>
            accountTypesWithAccounts.reduce((acc, accountType) => {
                const accounts = accountType.accounts;
                const accountsOptions = accounts.map((account) => ({
                    value: account.code,
                    label: `${account.code} - ${account.name}`,
                }));
                acc.push(
                    {
                        label: accountType.type,
                        isGroupLabel: true,
                        value: accountType.type,
                    },
                    ...accountsOptions,
                );
                return acc;
            }, [] as EditableCellSearchOption[]),
        [accountTypesWithAccounts],
    );
}

interface Change<T> {
    field: keyof T;
    value: string | number | null;
}

export interface UpdateAndReturnNewLineParams<T> {
    line: T;
    changes: Array<Change<T>>;
    fillEmptyFieldsAutomatically?: boolean;
}

interface UseUpdateAndReturnNewLineCallbackParams {
    isEditable: boolean;
    accountsSearchOptions: EditableCellSearchOption[];
    memo: string;
    linesRef: React.MutableRefObject<JournalEntryLineProxyObject[]>;
}

interface FillEmptyFieldsParams {
    newLine: JournalEntryLineProxyObject;
    accountsSearchOptions: EditableCellSearchOption[];
    memo: string;
    linesRef: React.MutableRefObject<JournalEntryLineProxyObject[]>;
    field: keyof JournalEntryLineProxyObject;
    value: string | number | null;
}

function fillEmptyFields({
    newLine,
    accountsSearchOptions,
    memo,
    linesRef,
    field,
    value,
}: FillEmptyFieldsParams) {
    if (
        field === "accountCode" &&
        typeof newLine.creditAmount !== "number" &&
        typeof newLine.debitAmount !== "number" &&
        accountsSearchOptions.find(
            (option) => !option.isGroupLabel && option.value === Number(value),
        )
    ) {
        const linesSum = calculateDebitAndCreditTotals(linesRef.current);
        const difference = linesSum.debitAmount - linesSum.creditAmount;
        if (difference > 0) {
            newLine.creditAmount = difference;
        } else if (difference !== 0) {
            newLine.debitAmount = Math.abs(difference);
        }
        const uniqueDescriptionsInOtherLines = new Set(
            linesRef.current
                .map((currentLine) => currentLine.description)
                .filter((description) => Boolean(description)),
        );
        if (uniqueDescriptionsInOtherLines.size === 0) {
            if (memo.length > 0) {
                newLine.description = memo;
            }
        }
        if (uniqueDescriptionsInOtherLines.size === 1) {
            newLine.description =
                uniqueDescriptionsInOtherLines.values().next().value ?? "";
        }
    }
}

export function useUpdateAndReturnNewLineCallback({
    isEditable,
    accountsSearchOptions,
    memo,
    linesRef,
}: UseUpdateAndReturnNewLineCallbackParams) {
    return useCallback(
        ({
            line,
            fillEmptyFieldsAutomatically,
            changes,
        }: UpdateAndReturnNewLineParams<JournalEntryLineProxyObject>) => {
            if (!isEditable) {
                return line;
            }
            const newLine: JournalEntryLineProxyObject = structuredClone(line);

            changes.forEach((change) => {
                (newLine as any)[change.field] = change.value;

                if (change.value !== null) {
                    if (change.field === "debitAmount") {
                        newLine.creditAmount = null;
                    } else if (change.field === "creditAmount") {
                        newLine.debitAmount = null;
                    }
                }
                if (fillEmptyFieldsAutomatically) {
                    fillEmptyFields({
                        newLine,
                        accountsSearchOptions,
                        memo,
                        linesRef,
                        field: change.field,
                        value: change.value,
                    });
                }
            });

            return newLine;
        },
        [isEditable, accountsSearchOptions, memo, linesRef],
    );
}
