import React, { ComponentProps, FC, useMemo } from "react";
import classNames from "classnames";
import { Command } from "../../vendor/cmdk/cmdk";
import { KeyboardShortcut } from "../general/KeyboardShortcut/KeyboardShortcut";
import { EnterIcon } from "../../icons";
import styles from "./Commands.module.scss";
import { Shortcut } from "./Shortcut";

export interface CommandItemProps extends ComponentProps<typeof Command.Item> {
    shortcut?: string[];
}

export const CommandItem: FC<CommandItemProps> = ({
    children,
    className,
    shortcut,
    ...commandItemProps
}) => {
    const keywordsWithShortcuts = useMemo(() => {
        const result = [...(commandItemProps.keywords ?? [])];

        if (shortcut) {
            result.push(...shortcut);
            result.push(`shortcut:${shortcut.join("").toLowerCase()}`);
        }

        return result;
    }, [commandItemProps.keywords, shortcut]);

    return (
        <Command.Item
            className={classNames(styles.commandItem, className)}
            {...commandItemProps}
            keywords={keywordsWithShortcuts}
        >
            {children}

            {shortcut ? <Shortcut keys={shortcut} /> : null}

            <KeyboardShortcut className={styles.confirmShortcut} square>
                <EnterIcon />
            </KeyboardShortcut>
        </Command.Item>
    );
};
