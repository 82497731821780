import { useMemo } from "react";
import { endOfDay, formatISO, startOfYear } from "date-fns";
import { useAccountingTabContext } from "./useAccountingTab";

export const DEFAULT_START_DATE = startOfYear(new Date());
export const DEFAULT_END_DATE = endOfDay(new Date());

export function useProfitLossInitialDates() {
    const accountingCtx = useAccountingTabContext();

    const dateStart = useMemo(
        () =>
            formatISO(accountingCtx?.filters?.startDate ?? DEFAULT_START_DATE, {
                representation: "date",
            }),
        [accountingCtx?.filters?.startDate],
    );
    const dateEnd = useMemo(
        () =>
            formatISO(accountingCtx?.filters?.endDate ?? DEFAULT_END_DATE, {
                representation: "date",
            }),
        [accountingCtx?.filters?.endDate],
    );

    return {
        dateStart,
        dateEnd,
    };
}
