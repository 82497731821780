import classNames from "classnames";
import { useMemo } from "react";
import { GridTableRow } from "../../general/Tables/GridTable/GridTable";
import {
    ExtendedFlattenedItemForAccount,
    ExtendedFlattenedItemForAccountMetaClassification,
} from "./utils";

import styles from "./styles.module.scss";

export function ChartOfAccountsItemClassification({
    item,
    disabledDuringDragging,
}: {
    disabledDuringDragging?: boolean;
    item: ExtendedFlattenedItemForAccount<ExtendedFlattenedItemForAccountMetaClassification>;
}) {
    const cells = useMemo(
        () => [
            {
                key: "name",
                value: item.meta.classification,
            },
            {
                key: "type",
                value: null,
            },
            {
                key: "subtype",
                value: null,
            },
            {
                key: "debit",
                value: null,
            },
            {
                key: "credit",
                value: null,
            },
            {
                key: "balance",
                value: null,
            },
            {
                key: "actions",
                value: null,
            },
        ],
        [item.meta.classification],
    );

    const classNameMemoized = useMemo(
        () =>
            classNames(styles.row, {
                [styles.classificationRow]: true,
                [styles.disabledDuringDragging]: disabledDuringDragging,
            }),
        [disabledDuringDragging],
    );

    return (
        <GridTableRow
            key={item.id}
            cells={cells}
            className={classNameMemoized}
            columnBorders={false}
        />
    );
}
