import { initContract } from "@ts-rest/core";
import { z } from "zod";
import { IntegrationConnectionDto } from "../dto/integrationConnection/integration-connection.dto";

const c = initContract();

export const paypalContract = c.router(
    {
        createConnection: {
            method: "POST",
            path: "/",
            body: z.object({
                workspaceId: z.string().uuid(),
                sharedId: z.string(),
                authCode: z.string(),
                defaultEntityId: z.number().optional(),
            }),
            responses: {
                200: c.type<IntegrationConnectionDto>(),
            },
        },
    },
    {
        pathPrefix: "/paypal",
    },
);
