import React, { useMemo, useState } from "react";
import {
    PLSection,
    ProfitLossReportSummarySections,
    ProfitLossTableReportSection,
} from "../../../common/dto/reports/reports-common.dto";
import { ProfitLossReport } from "../../reports/types";
import { ProfitLossSectionsLabels } from "./constants";
import { ProfitLossTableRow } from "./ProfitLossTableRow";

interface Props {
    report: ProfitLossReport;
    sectionId: ProfitLossTableReportSection;
    sectionData: PLSection;
    columns: string[];
}

function getAccountCodesFromSection(
    sectionData: PLSection,
    report: ProfitLossReport,
): number[] {
    if (sectionData.rows) {
        return (
            (sectionData.rows
                ?.map((row) => row.meta?.code)
                .filter(Boolean) as number[]) || []
        );
    }

    if (sectionData.meta?.summaryOfSections) {
        return sectionData.meta.summaryOfSections.flatMap((sectionId) =>
            getAccountCodesFromSection(report.sections[sectionId], report),
        );
    }

    return [];
}

export const ProfitLossTableSection: React.FC<Props> = ({
    sectionId,
    sectionData,
    columns,
    report,
}) => {
    const [expanded, setExpanded] = useState(true);
    const isExpandable = Boolean(sectionData.rows?.length);
    const isSummary = ProfitLossReportSummarySections.includes(sectionId);
    const accountsInSection = useMemo(
        () => getAccountCodesFromSection(sectionData, report),
        [sectionData, report],
    );

    if (sectionId === ProfitLossTableReportSection.CASH_BALANCE) {
        // only used for the cash flow report
        return null;
    }

    return (
        <>
            <ProfitLossTableRow
                title={ProfitLossSectionsLabels[sectionId]}
                onExpand={() => setExpanded(!expanded)}
                isExpandable={isExpandable}
                isExpanded={expanded}
                columns={columns}
                values={sectionData.data}
                isSummary={isSummary}
                accountCodes={accountsInSection}
            />
            {expanded &&
                sectionData.rows?.map((row) => (
                    <ProfitLossTableRow
                        key={row.category}
                        title={row.category}
                        columns={columns}
                        values={row.data}
                        depth={1}
                        accountCodes={row.meta ? [row.meta.code] : undefined}
                    />
                ))}
            {expanded && isExpandable && (
                <ProfitLossTableRow
                    title={`Total ${ProfitLossSectionsLabels[sectionId]}`}
                    columns={columns}
                    values={sectionData.data}
                    isTotal
                    depth={1}
                    accountCodes={accountsInSection}
                />
            )}
        </>
    );
};
