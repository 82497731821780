import React from "react";
import { Nav, Tab } from "react-bootstrap";
import { NavigationItem } from "../../navigation/NavigationItem";
import {
    CalendarIcon,
    ClassIcon,
    DocumentIcon,
    EditIcon,
    DirectoryIcon,
    FileSheetIcon,
    FinancingIcon,
    FolderIcon,
    LinkIcon,
    AccountsIcon,
    CheckIcon,
    SplitSIcon,
} from "../../../icons";
import { AggregatedDateFilter } from "../../general/AggregatedFilters/DateFilter/AggregatedDateFilter";
import { AggregatedFiltersOverlay } from "../../general/AggregatedFilters/AggregatedFiltersOverlay";
import { GENERAL_COUNTERPARTY_LABEL } from "../../../common/helpers/counterparties";
import { CounterpartyFilter } from "../../general/AggregatedFilters/CounterpartyFilter/CounterpartyFilter";
import { AggregatedAccountsFilter } from "../../general/AggregatedFilters/AccountFilter/AggregatedAccountsFilter";

import { FiltersTypes } from "../../../common/types/filters/general";
import { FinancialDocumentStatusFilter } from "./Tabs/FinancialDocumentStatusFilter/FinancialDocumentStatusFilter";
import { AggregatedClassFilter } from "./Tabs/ClassFilter/AggregatedClassFilter";
import { TransactionAmountFilter } from "./Tabs/AmountFilter/TransactionAmountFilter";
import { TransactionRulesFilter } from "./Tabs/RulesFilter/TransactionRulesFilter";
import { NoteFilter } from "./Tabs/NoteFilter/NoteFilter";
import { ReceiptsFilter } from "./Tabs/ReceiptsFilter/ReceiptsFilter";
import { MatchedFilter } from "./Tabs/MatchedFilter/MatchedFilter";
import { SplitFilter } from "./Tabs/SplitFilter/SplitFilter";
import { AggregatedCategoryFilter } from "./Tabs/CategoryFilter/AggregatedCategoryFilter";
import { FiltersChangeFn, FilterTabs } from "./lib";
import styles from "./FiltersOverlay.module.scss";
import { AccountCodesFilter } from "./Tabs/AccountCodesFilter/AccountCodesFilter";
import { AccountStatusFilter } from "./Tabs/AccountStatusFilter/AccountStatusFilter";

interface Props {
    directionSwitchDisabled?: boolean;
    filters: FiltersTypes;
    onChange: FiltersChangeFn;
    availableFilters: FilterTabs[];
    hardcodedEntityId?: number;
}

const FilterTabIconMapping: Record<FilterTabs, React.ReactNode> = {
    [FilterTabs.CATEGORY]: <FolderIcon />,
    [FilterTabs.DATE]: <CalendarIcon />,
    [FilterTabs.AMOUNT]: <FinancingIcon />,
    [FilterTabs.ACCOUNT]: <AccountsIcon />,
    [FilterTabs.RULES]: <FileSheetIcon />,
    [FilterTabs.CLASS]: <ClassIcon />,
    [FilterTabs.NOTE]: <EditIcon />,
    [FilterTabs.RECEIPTS]: <DocumentIcon />,
    [FilterTabs.MATCHED]: <LinkIcon />,
    [FilterTabs.SPLIT]: <SplitSIcon width={16} height={16} />,
    [FilterTabs.COUNTERPARTY]: <DirectoryIcon />,
    [FilterTabs.DOCUMENT_STATUS]: <CheckIcon />,
    [FilterTabs.ACCOUNT_CODE]: <AccountsIcon />,
    [FilterTabs.ACCOUNT_STATUS]: <AccountsIcon />,
};

const FilterTabLabelMapping: Record<FilterTabs, React.ReactNode> = {
    [FilterTabs.CATEGORY]: "Category",
    [FilterTabs.DATE]: "Date",
    [FilterTabs.AMOUNT]: "Amount",
    [FilterTabs.ACCOUNT]: "Account",
    [FilterTabs.RULES]: "Rules",
    [FilterTabs.CLASS]: "Class",
    [FilterTabs.NOTE]: "Note",
    [FilterTabs.RECEIPTS]: "Receipts",
    [FilterTabs.MATCHED]: "Match",
    [FilterTabs.SPLIT]: "Split",
    [FilterTabs.COUNTERPARTY]: GENERAL_COUNTERPARTY_LABEL,
    [FilterTabs.DOCUMENT_STATUS]: "Status",
    [FilterTabs.ACCOUNT_CODE]: "Account",
    [FilterTabs.ACCOUNT_STATUS]: "Account Status",
};

export const FiltersOverlay: React.FC<Props> = ({
    filters,
    onChange,
    directionSwitchDisabled = false,
    availableFilters,
    hardcodedEntityId,
}) => (
    <AggregatedFiltersOverlay
        filterClassname={styles.filter}
        navigation={
            <>
                {availableFilters.map((filterTab) => (
                    <Nav.Link eventKey={filterTab} key={filterTab}>
                        <NavigationItem
                            icon={FilterTabIconMapping[filterTab]}
                            activeIndicator
                        >
                            {FilterTabLabelMapping[filterTab]}
                        </NavigationItem>
                    </Nav.Link>
                ))}
            </>
        }
        content={
            <>
                <Tab.Pane eventKey={FilterTabs.DATE}>
                    <AggregatedDateFilter
                        filters={filters}
                        onChange={onChange}
                    />
                </Tab.Pane>
                <Tab.Pane eventKey={FilterTabs.CATEGORY}>
                    <AggregatedCategoryFilter
                        filters={filters}
                        onChange={onChange}
                    />
                </Tab.Pane>
                <Tab.Pane
                    className={styles.classTab}
                    eventKey={FilterTabs.CLASS}
                >
                    <AggregatedClassFilter
                        filters={filters}
                        onChange={onChange}
                        showFilterTypeSettings
                    />
                </Tab.Pane>
                <Tab.Pane eventKey={FilterTabs.AMOUNT}>
                    <TransactionAmountFilter
                        filters={filters}
                        onChange={onChange}
                        directionSwitchDisabled={directionSwitchDisabled}
                    />
                </Tab.Pane>
                <Tab.Pane eventKey={FilterTabs.RULES}>
                    <TransactionRulesFilter
                        filters={filters}
                        onChange={onChange}
                    />
                </Tab.Pane>
                <Tab.Pane eventKey={FilterTabs.COUNTERPARTY}>
                    <CounterpartyFilter filters={filters} onChange={onChange} />
                </Tab.Pane>
                <Tab.Pane eventKey={FilterTabs.NOTE}>
                    <NoteFilter filters={filters} onChange={onChange} />
                </Tab.Pane>

                <Tab.Pane eventKey={FilterTabs.RECEIPTS}>
                    <ReceiptsFilter filters={filters} onChange={onChange} />
                </Tab.Pane>

                <Tab.Pane eventKey={FilterTabs.MATCHED}>
                    <MatchedFilter filters={filters} onChange={onChange} />
                </Tab.Pane>

                <Tab.Pane eventKey={FilterTabs.SPLIT}>
                    <SplitFilter filters={filters} onChange={onChange} />
                </Tab.Pane>

                <Tab.Pane eventKey={FilterTabs.ACCOUNT}>
                    <AggregatedAccountsFilter
                        filters={filters}
                        onChange={onChange}
                    />
                </Tab.Pane>

                <Tab.Pane eventKey={FilterTabs.DOCUMENT_STATUS}>
                    <FinancialDocumentStatusFilter
                        filters={filters}
                        onChange={onChange}
                    />
                </Tab.Pane>

                <Tab.Pane
                    eventKey={FilterTabs.ACCOUNT_CODE}
                    className={styles.classTab}
                >
                    <AccountCodesFilter
                        filters={filters}
                        onChange={onChange}
                        hardcodedEntityId={hardcodedEntityId}
                    />
                </Tab.Pane>

                <Tab.Pane eventKey={FilterTabs.ACCOUNT_STATUS}>
                    <AccountStatusFilter
                        filters={filters}
                        onChange={onChange}
                    />
                </Tab.Pane>
            </>
        }
    />
);
