import isNumber from "lodash/isNumber";
import {
    RuleConditionAmount,
    RuleConditionType,
    TransactionRuleCondition,
} from "../../../../common/types/transactionRule";
import {
    AmountConditionType,
    TransactionRuleConditionFormSchema,
    DescriptionConditionType,
    FormRuleConditionType,
    TransactionRuleActionFormSchema,
    FormRuleActionType,
} from "./constants";

export function conditionFormRowToModel(
    condition: TransactionRuleConditionFormSchema,
): Omit<TransactionRuleCondition, "id"> | undefined {
    switch (condition.conditionType) {
        case FormRuleConditionType.CATEGORY:
            return buildCategoryConditionFromFormRow(condition);
        case FormRuleConditionType.SUGGESTED_CATEGORY:
            return buildSuggestedCategoryConditionFromFormRow(condition);
        case FormRuleConditionType.AMOUNT:
            return buildAmountConditionFromFormRow(condition);
        case FormRuleConditionType.BANK_DESCRIPTION:
            return buildDescriptionConditionFromFormRow(condition);
        case FormRuleConditionType.ACCOUNT:
            return buildAccountConditionFromFormRow(condition);
        case FormRuleConditionType.ENTITY:
            return buildEntityConditionFromFormRow(condition);
        case FormRuleConditionType.COUNTERPARTY:
            return buildCounterpartyConditionFromFormRow(condition);
    }
}

function buildCategoryConditionFromFormRow(
    condition: TransactionRuleConditionFormSchema,
): Omit<TransactionRuleCondition, "id"> | undefined {
    if (condition.categoryValue) {
        return {
            conditionType: RuleConditionType.CATEGORY,
            value: condition.categoryValue.id,
        };
    }
}

function buildSuggestedCategoryConditionFromFormRow(
    condition: TransactionRuleConditionFormSchema,
): Omit<TransactionRuleCondition, "id"> | undefined {
    if (condition.categoryValue) {
        return {
            conditionType: RuleConditionType.SUGGESTED_CATEGORY,
            value: condition.categoryValue.id,
        };
    }
}

function buildAmountConditionFromFormRow(
    condition: TransactionRuleConditionFormSchema,
): Omit<TransactionRuleCondition, "id"> {
    const value: RuleConditionAmount = {};

    if (
        condition.amountConditionType === AmountConditionType.BETWEEN &&
        isNumber(condition.amountValueRange?.min) &&
        isNumber(condition.amountValueRange?.max)
    ) {
        value.min = condition.amountIsCredit
            ? condition.amountValueRange.min
            : -condition.amountValueRange.max;
        value.max = condition.amountIsCredit
            ? condition.amountValueRange.max
            : -condition.amountValueRange.min;
    }

    if (
        condition.amountConditionType === AmountConditionType.GREATER_THAN &&
        isNumber(condition.amountSingleValue)
    ) {
        if (condition.amountIsCredit) {
            value.min = condition.amountSingleValue;
        } else {
            value.max = -condition.amountSingleValue;
        }
    }

    if (
        condition.amountConditionType === AmountConditionType.LESS_THAN &&
        isNumber(condition.amountSingleValue)
    ) {
        if (condition.amountIsCredit) {
            value.min = 0;
            value.max = condition.amountSingleValue;
        } else {
            value.min = -condition.amountSingleValue;
            value.max = 0;
        }
    }

    return {
        conditionType: RuleConditionType.AMOUNT,
        value,
    };
}

function buildDescriptionConditionFromFormRow(
    condition: TransactionRuleConditionFormSchema,
): Omit<TransactionRuleCondition, "id"> | undefined {
    if (condition.descriptionValue) {
        return {
            conditionType:
                condition.descriptionConditionType ===
                DescriptionConditionType.IS
                    ? RuleConditionType.DESCRIPTION_IS
                    : RuleConditionType.DESCRIPTION_INCLUDES,
            value: condition.descriptionValue,
        };
    }
}

function buildAccountConditionFromFormRow(
    condition: TransactionRuleConditionFormSchema,
): Omit<TransactionRuleCondition, "id"> | undefined {
    if (condition.accountValue?.length) {
        return {
            conditionType: RuleConditionType.FINANCIAL_ACCOUNTS,
            value: condition.accountValue,
        };
    }
}

function buildEntityConditionFromFormRow(
    condition: TransactionRuleConditionFormSchema,
): Omit<TransactionRuleCondition, "id"> | undefined {
    if (condition.entityValue) {
        return {
            conditionType: RuleConditionType.ENTITY,
            value: condition.entityValue,
        };
    }
}

function buildCounterpartyConditionFromFormRow(
    condition: TransactionRuleConditionFormSchema,
): Omit<TransactionRuleCondition, "id"> | undefined {
    if (condition.counterparty) {
        return {
            conditionType: RuleConditionType.COUNTERPARTY,
            value: condition.counterparty.id,
        };
    }
}

export function formActionsToModel(actions: TransactionRuleActionFormSchema[]) {
    const category = actions.find(
        (a) => a.actionType === FormRuleActionType.CATEGORY,
    )?.categoryValue;
    const entityId = actions.find(
        (a) => a.actionType === FormRuleActionType.ENTITY,
    )?.entityValue;

    if (!category) {
        return { entityId: entityId ?? null };
    }

    return {
        categoryId: category.id,
        entityId: entityId ?? null,
    };
}
