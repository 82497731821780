import React from "react";
import { useField } from "formik";
import { TransactionDirectionType } from "../../../../../common/categories";
import { CategorySelectSimple } from "../../../../transactions/CategorySelect/CategorySelectSimple";
import { TransactionRuleActionFormSchema } from "../constants";

interface Props {
    index: number;
}

export const CategoryActionForm: React.FC<Props> = ({ index }) => {
    const [field, , helpers] = useField<
        TransactionRuleActionFormSchema["categoryValue"]
    >(`actions.${index}.categoryValue`);

    return (
        <CategorySelectSimple
            size="xs"
            onSelected={(value) => helpers.setValue(value)}
            value={field.value}
            dropdownKey={`ruleAction${index}Category`}
            transactionDirection={TransactionDirectionType.mixed}
        />
    );
};
