import React from "react";
import { pluralize } from "../../../../../common/helpers/string";
import { useFinancialAccounts } from "../../../hooks/useFinancialAccounts";
import { BreakableText } from "../../general/BreakableText/BreakableText";

interface Props {
    filters: {
        accountIds?: number[];
    };
}

export const AccountsFilterLabel: React.FC<Props> = ({
    filters: { accountIds },
}) => {
    const accounts = useFinancialAccounts();

    if (accountIds?.length === 1) {
        const selectedAccount = accounts.find(({ id }) => id === accountIds[0]);

        return <BreakableText text={selectedAccount?.name ?? "Account"} />;
    }

    return (
        <>
            {accountIds?.length} {pluralize("Account", accountIds?.length ?? 0)}
        </>
    );
};
