import React, { useState } from "react";
import { object, string } from "yup";
import { Field, Form, Formik, FormikConfig } from "formik";
import classNames from "classnames";
import { submitHelper } from "../../../helpers/form";
import { Entity } from "../../../common/types/entity";
import { FormRow } from "../../forms/FormRow";
import animations from "../layout/OnboardingLayout.module.scss";
import { industryLabels } from "../../entity/constants";
import { Button } from "../../general/Button/Button";
import commonStyles from "./common.module.scss";
import styles from "./WaitlistComment.module.scss";

interface Props {
    entity: Entity;
    onSubmit(comment: string): Promise<void>;
}

interface FormSchema {
    comment: string;
}

export const WaitlistComment: React.FC<Props> = ({ entity, onSubmit }) => {
    const [loading, setLoading] = useState(false);

    let title: React.ReactNode;
    if (!entity.profile?.industry || !entity.profile?.country) {
        return null;
    } else if (entity.profile.country !== "US") {
        title = `Beta program`;
    } else {
        title = `${industryLabels[entity.profile.industry]} beta program`;
    }

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            comment: entity?.profile?.waitlistComment ?? "",
        },
        validationSchema: object().shape({
            comment: string().required("Please provide a comment"),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async (values) => {
                await onSubmit(values.comment);
            },
        }),
    };

    return (
        <div className={classNames(commonStyles.content420)}>
            <header className={classNames(animations.fadeIn2, "text-center")}>
                <h1 className={commonStyles.title}>{title}</h1>

                <p className={commonStyles.subtitle}>
                    What's the number one thing about your business accounting
                    that keeps you up at night?
                </p>
            </header>

            <Formik {...form}>
                {({ isValid }) => (
                    <Form>
                        <FormRow fieldName="comment">
                            <Field
                                name="comment"
                                as="textarea"
                                className={classNames(
                                    "form-control",
                                    styles.textarea,
                                )}
                                placeholder="I don’t have confidence that my books are audit-ready without me having to constantly give feedback and review my bookkeepers work."
                            />
                        </FormRow>

                        <Button
                            type="submit"
                            variant="secondary"
                            block
                            disabled={!isValid}
                        >
                            Submit
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
