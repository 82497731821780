import { CreateManualJournalEntryDto } from "../common/dto/manualJournalEntry/create-manual-journal-entry.dto";
import { GetAccountsResponse } from "../common/types/domains/accounting/accounts";
import {
    GetJournalEntryResponseObject,
    UpsertJournalEntryResponseObject,
    CreateJournalEntryResponseObject,
    GetManualJournalEntriesResponseObject,
    JournalEntry,
} from "../common/types/domains/accounting/journalEntry";
import { GetManualJournalEntriesDto } from "../common/dto/manualJournalEntry/get-manual-journal-entries.dto";
import { EntityScopedRequestDto } from "../common/dto/common/entity-scoped-request.dto";
import { backendClient, unwrapResponse } from "./backendClient";

export async function getAccounts(
    query: EntityScopedRequestDto,
): Promise<GetAccountsResponse> {
    return unwrapResponse(
        await backendClient.get("/accounting/accounts", {
            params: query,
        }),
    );
}

export async function createManualJournalEntry(
    entityId: number,
    data: CreateManualJournalEntryDto,
): Promise<CreateJournalEntryResponseObject> {
    return unwrapResponse(
        await backendClient.post(
            `/accounting/journal-entries/manual/${entityId}`,
            data,
        ),
    );
}

export async function getJournalEntry(
    id: string,
    entityId: number,
): Promise<GetJournalEntryResponseObject> {
    return unwrapResponse(
        await backendClient.get(
            `/accounting/journal-entries/${entityId}/${id}`,
        ),
    );
}

export async function getManualJournalEntries(
    entityId: number,
    params: GetManualJournalEntriesDto,
): Promise<JournalEntry[]> {
    const response = unwrapResponse(
        await backendClient.get(
            `/accounting/journal-entries/manual/${entityId}`,
            { params },
        ),
    ) as GetManualJournalEntriesResponseObject;

    return response.manualJournalEntries.map((je) => ({
        ...je,
        date: new Date(je.date),
    }));
}

export async function upsertJournalEntry(
    entityId: number,
    journalEntryId: string,
    data: CreateManualJournalEntryDto,
): Promise<UpsertJournalEntryResponseObject> {
    return unwrapResponse(
        await backendClient.put(
            `/accounting/journal-entries/manual/${entityId}/${journalEntryId}`,
            data,
        ),
    );
}

export async function deleteJournalEntry(
    entityId: number,
    journalEntryId: string,
): Promise<void> {
    return unwrapResponse(
        await backendClient.delete(
            `/accounting/journal-entries/manual/${entityId}/${journalEntryId}`,
        ),
    );
}
