import React, { FC } from "react";
import { Tab } from "react-bootstrap";
import { PlanManagementProvider } from "../billing/PlanManagement/PlanManagementProvider";
import { useTypedFlags } from "../../hooks/useTypedFlags";
import { useBillingType } from "../../hooks/useBillingType";
import { SubscriptionType } from "../../common/types/billing";
import { useBillingManagement } from "../../hooks/useBillingManagement";
import {
    UserSettingsPages,
    useSettings,
    WorkspaceSettingsPages,
} from "./SettingsProvider";
import { Categories } from "./Categories/Categories";
import { RulesSettings } from "./Rules/RulesSettings";
import { DirectorySettings } from "./Directory/DirectorySettings";
import { ClassesSettings } from "./Classes/ClassesSettings";
import { BillingPage } from "./Billing/BillingPage";
import { PlansPage } from "./Plans/PlansPage";
import { WorkspaceMembers } from "./WorkspaceMembers/WorkspaceMembers";
import { DirectoryContextProvider } from "./Directory/DirectoryContextProvider";

export const WorkspacePanes: FC = () => {
    const {
        directory: canUseDirectory,
        classes: canUseClasses,
        workspaceMemberUi: canUseWorkspaceMembers,
    } = useTypedFlags();
    const { canManageBilling } = useBillingManagement();
    const billingType = useBillingType();
    const showPlans =
        canManageBilling && billingType === SubscriptionType.FLAT_RATE;

    const { close } = useSettings();

    return (
        <>
            {canUseWorkspaceMembers && (
                <Tab.Pane
                    eventKey={WorkspaceSettingsPages.MEMBERS}
                    title="Members"
                >
                    <WorkspaceMembers />
                </Tab.Pane>
            )}

            <Tab.Pane
                eventKey={WorkspaceSettingsPages.CATEGORIES}
                title="Categories"
            >
                <PlanManagementProvider>
                    <Categories />
                </PlanManagementProvider>
            </Tab.Pane>

            <Tab.Pane eventKey={WorkspaceSettingsPages.RULES}>
                <PlanManagementProvider>
                    <RulesSettings />
                </PlanManagementProvider>
            </Tab.Pane>

            {canUseDirectory && (
                <Tab.Pane eventKey={WorkspaceSettingsPages.DIRECTORY}>
                    <PlanManagementProvider>
                        <DirectoryContextProvider>
                            <DirectorySettings />
                        </DirectoryContextProvider>
                    </PlanManagementProvider>
                </Tab.Pane>
            )}

            {canUseClasses && (
                <Tab.Pane eventKey={WorkspaceSettingsPages.CLASSES}>
                    <ClassesSettings closeSettingsModal={close} />
                </Tab.Pane>
            )}

            {canManageBilling && (
                <Tab.Pane eventKey={UserSettingsPages.BILLING} title="Billing">
                    <BillingPage />
                </Tab.Pane>
            )}

            {showPlans && (
                <Tab.Pane eventKey={UserSettingsPages.PLANS} title="Plans">
                    <PlanManagementProvider>
                        <PlansPage />
                    </PlanManagementProvider>
                </Tab.Pane>
            )}
        </>
    );
};
