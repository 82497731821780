import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { getUserProfile, handleLogin } from "../../../lib/auth0";
import { handleGoogleAuth } from "../../../lib/user";
import { PageCoverLoader } from "../../general/PageCoverLoader/PageCoverLoader";
import { getRedirectPath } from "../../../lib/redirectAfterLogin";
import { initApp } from "../../../lib";
import { useToaster } from "../../general/ToastMessages/useToaster";

export const GoogleAuth: React.FC = () => {
    const [inviteCode] = useQueryParam(
        "inviteCode",
        withDefault(StringParam, undefined),
    );
    const navigate = useNavigate();
    const { error } = useToaster();

    useEffect(() => {
        handleLogin()
            .then((token) => getUserProfile(token))
            .then((user) =>
                handleGoogleAuth({
                    auth0UserId: user.sub,
                    name: user.name,
                    avatar: user.picture,
                    email: user.email!,
                    inviteCode,
                }),
            )

            .then(() => initApp())
            .then(() => navigate(getRedirectPath() ?? "/"))
            .catch((e) => {
                error(e.errorDescription ?? e.message ?? "Unknown error");

                navigate("/");
            });
    }, [error, inviteCode, navigate]);

    return <PageCoverLoader />;
};
