import React from "react";
import { useFormikContext } from "formik";
import { Workspace } from "../../common/types/workspace";
import { Entity } from "../../common/types/entity";
import { FormRow } from "../forms/FormRow";
import {
    CustomSelect,
    CustomSelectOption,
} from "../forms/CustomSelect/CustomSelect";
import { UserRole } from "../../common/types/userEntityAssignment";
import { ROLES_LABELS } from "../../common/constants";
import { Logo } from "../general/Logo";
import {
    GridTable,
    GridTableHeader,
    GridTableRow,
} from "../general/Tables/GridTable/GridTable";
import { EntityIcon } from "../entity/EntityIcon";
import { FormCheckbox } from "../forms/FormCheckbox/FormCheckbox";
import { getPossessive } from "../../common/helpers/string";
import styles from "./MemberManagement.module.scss";
import { MemberPermissionsSchema } from "./types";

interface Props {
    entities: Entity[];
    workspace: Workspace;
}

const assignableRoles = [
    UserRole.ADMIN,
    UserRole.MEMBER,
    UserRole.ACCOUNTANT,
] as const;

type AssignableRole = (typeof assignableRoles)[number];

const entityLevelRoleDescription: Record<AssignableRole, string> = {
    [UserRole.MEMBER]:
        "Can view transactions and reports, upload and download documents and add notes.",
    [UserRole.ADMIN]:
        "Can add accounts, manage members, and have full access to transactions and reports.",
    [UserRole.ACCOUNTANT]:
        "Can view transactions and reports, upload and download documents and add notes.",
};

const entityLevelRoleOptions: Array<CustomSelectOption<UserRole>> =
    assignableRoles.map((role) => ({
        value: role,
        label: ROLES_LABELS[role],
        inlineDescription: entityLevelRoleDescription[role],
    }));

const workspaceLevelRoleDescription: Record<AssignableRole, string> = {
    [UserRole.MEMBER]:
        "By picking ”Member”, you can assign custom permissions on entity level below.",
    [UserRole.ADMIN]:
        "By picking ”Admin”, the person will have Admin access across all the entities in the workspace.",
    [UserRole.ACCOUNTANT]:
        "By picking ”Accountant”, you can assign custom permissions on entity level below.",
};

const workspaceLevelRoleOptions: Array<CustomSelectOption<UserRole>> =
    assignableRoles.map((role) => ({
        value: role,
        label: ROLES_LABELS[role],
        inlineDescription: workspaceLevelRoleDescription[role],
    }));

export const ConfigurePermissionsForm: React.FC<Props> = ({
    workspace,
    entities,
}) => {
    const formikContext = useFormikContext<MemberPermissionsSchema>();

    const allIncluded = formikContext.values.entityAssignments.every(
        (assignment) => assignment.included,
    );

    const selectedWorkspaceRole = formikContext.getFieldMeta("workspaceRole")
        .value as AssignableRole;

    const handleWorkspaceRoleChange = (role: AssignableRole) => {
        formikContext.setFieldValue("workspaceRole", role);
        formikContext.setFieldValue(
            "entityAssignments",
            formikContext.values.entityAssignments.map((assignment) => ({
                ...assignment,
                included: role === UserRole.ADMIN || assignment.included,
                role,
            })),
        );
    };

    const toggleSelectAll = () => {
        formikContext.setFieldValue(
            "entityAssignments",
            formikContext.values.entityAssignments.map((assignment) => ({
                ...assignment,
                included: !allIncluded,
            })),
        );
    };

    const entityRolesPreselected = selectedWorkspaceRole === UserRole.ADMIN;

    return (
        <main className={styles.configurePermissionsForm}>
            <section className={styles.section}>
                <h5 className={styles.sectionTitle}>Workspace</h5>

                <div className={styles.singlePermission}>
                    <div>
                        <div className={styles.itemCard}>
                            <span className={styles.logo}>
                                <Logo variant="icon" />
                            </span>
                            {getPossessive(workspace.name)} Workspace
                        </div>
                    </div>
                    <div>
                        <FormRow
                            className={styles.roleSelectGroup}
                            showErrorMessage={false}
                            fieldName="workspaceRole"
                        >
                            {(_helpers, { value }) => (
                                <CustomSelect
                                    className={styles.roleSelect}
                                    value={value}
                                    onSelected={handleWorkspaceRoleChange}
                                    dropdownKey={`workspaceRole`}
                                    options={workspaceLevelRoleOptions}
                                    size="xs"
                                />
                            )}
                        </FormRow>
                    </div>
                    <div className={styles.info}>
                        {
                            workspaceLevelRoleDescription[
                                formikContext.getFieldMeta("workspaceRole")
                                    .value as AssignableRole
                            ]
                        }
                    </div>
                </div>
            </section>

            <section className={styles.section}>
                <h5 className={styles.sectionTitle}>Entities</h5>

                <GridTable className={styles.table}>
                    <GridTableHeader>
                        <div>
                            <FormCheckbox
                                className={styles.checkbox}
                                small
                                value="allIncluded"
                                isChecked={allIncluded}
                                handleChange={toggleSelectAll}
                                disabled={entityRolesPreselected}
                            />
                        </div>
                        <div>Entity</div>
                        <div>Role</div>
                        <div /> {/* info */}
                    </GridTableHeader>

                    {entities.map((entity, index) => (
                        <GridTableRow className={styles.row} key={entity.id}>
                            <div>
                                <FormCheckbox
                                    small
                                    className={styles.checkbox}
                                    value={`entityAssignments.${index}.included`}
                                    isChecked={
                                        formikContext.values.entityAssignments[
                                            index
                                        ].included
                                    }
                                    handleChange={() => {
                                        formikContext.setFieldValue(
                                            `entityAssignments.${index}.included`,
                                            !formikContext.values
                                                .entityAssignments[index]
                                                .included,
                                        );
                                    }}
                                    disabled={entityRolesPreselected}
                                />
                            </div>
                            <div className={styles.itemCard}>
                                <EntityIcon entity={entity} size="xs" />{" "}
                                {entity.name}
                            </div>
                            <div>
                                <FormRow
                                    className={styles.roleSelectGroup}
                                    showErrorMessage={false}
                                    fieldName={`entityAssignments.${index}.role`}
                                >
                                    {({ setValue }, { value }) => (
                                        <CustomSelect
                                            className={styles.roleSelect}
                                            value={value}
                                            onSelected={(val) => setValue(val)}
                                            dropdownKey={`entityAssignments.${index}.role`}
                                            options={entityLevelRoleOptions}
                                            size="xs"
                                            disabled={
                                                entityRolesPreselected ||
                                                !formikContext.values
                                                    .entityAssignments[index]
                                                    .included
                                            }
                                        />
                                    )}
                                </FormRow>
                            </div>
                            <div className={styles.info}>
                                {
                                    entityLevelRoleDescription[
                                        formikContext.getFieldMeta(
                                            `entityAssignments.${index}.role`,
                                        ).value as AssignableRole
                                    ]
                                }
                            </div>
                        </GridTableRow>
                    ))}
                </GridTable>
            </section>
        </main>
    );
};
