import { isArray, isObject, isString } from "lodash";
import isNumber from "lodash/isNumber";
import { Counterparty } from "../../../../common/types/counterparty";
import {
    RuleConditionAmount,
    RuleConditionType,
    TransactionRule,
    TransactionRuleCondition,
} from "../../../../common/types/transactionRule";
import { Category } from "../../../../common/types/category";
import {
    AmountConditionType,
    TransactionRuleConditionFormSchema,
    DescriptionConditionType,
    FormRuleConditionType,
    TransactionRuleActionFormSchema,
    FormRuleActionType,
} from "./constants";

interface ConditionModelToFormRowParams {
    conditionModel: TransactionRuleCondition;
    categoriesById: Record<string, Category>;
    counterparties?: Counterparty[];
}

export function conditionModelToFormRow({
    conditionModel,
    counterparties,
    categoriesById,
}: ConditionModelToFormRowParams):
    | TransactionRuleConditionFormSchema
    | undefined {
    switch (conditionModel.conditionType) {
        case RuleConditionType.CATEGORY:
            return buildCategoryConditionFormRow(
                conditionModel,
                categoriesById,
            );
        case RuleConditionType.SUGGESTED_CATEGORY:
            return buildSuggestedCategoryConditionFormRow(
                conditionModel,
                categoriesById,
            );
        case RuleConditionType.AMOUNT:
            return buildAmountConditionFormRow(conditionModel);
        case RuleConditionType.DESCRIPTION_INCLUDES:
        case RuleConditionType.DESCRIPTION_IS:
            return buildDescriptionConditionFormRow(conditionModel);
        case RuleConditionType.FINANCIAL_ACCOUNTS:
            return buildAccountConditionFormRow(conditionModel);
        case RuleConditionType.ENTITY:
            return buildEntityConditionFormRow(conditionModel);
        case RuleConditionType.COUNTERPARTY:
            return buildCounterpartyConditionFormRow(
                conditionModel,
                counterparties,
            );
    }
}

function buildCategoryConditionFormRow(
    conditionModel: TransactionRuleCondition,
    categoriesById: Record<string, Category>,
) {
    if (
        isString(conditionModel.value) &&
        categoriesById[conditionModel.value]
    ) {
        return {
            ...getDefaultFormRow(),
            id: conditionModel.id,
            conditionType: FormRuleConditionType.CATEGORY,
            categoryValue: categoriesById[conditionModel.value],
        };
    }
}

function buildSuggestedCategoryConditionFormRow(
    conditionModel: TransactionRuleCondition,
    categoriesById: Record<string, Category>,
) {
    if (
        isString(conditionModel.value) &&
        categoriesById[conditionModel.value]
    ) {
        return {
            ...getDefaultFormRow(),
            id: conditionModel.id,
            conditionType: FormRuleConditionType.SUGGESTED_CATEGORY,
            categoryValue: categoriesById[conditionModel.value],
        };
    }
}

function buildAmountConditionFormRow(conditionModel: TransactionRuleCondition) {
    if (isAmountCondition(conditionModel.value)) {
        return {
            ...getDefaultFormRow(),
            id: conditionModel.id,
            conditionType: FormRuleConditionType.AMOUNT,
            amountConditionType: getAmountConditionType(conditionModel.value),
            amountIsCredit: amountConditionIsCredit(conditionModel.value),
            amountSingleValue: getSingleAmountValue(conditionModel.value),
            amountValueRange: getAmountValueRange(conditionModel.value),
        };
    }
}

function buildDescriptionConditionFormRow(
    conditionModel: TransactionRuleCondition,
) {
    return {
        ...getDefaultFormRow(),
        id: conditionModel.id,
        conditionType: FormRuleConditionType.BANK_DESCRIPTION,
        descriptionConditionType:
            conditionModel.conditionType === RuleConditionType.DESCRIPTION_IS
                ? DescriptionConditionType.IS
                : DescriptionConditionType.CONTAINS,
        descriptionValue: isString(conditionModel.value)
            ? [conditionModel.value]
            : (conditionModel.value as string[]),
    };
}

function buildAccountConditionFormRow(
    conditionModel: TransactionRuleCondition,
) {
    let accountValue: number[] = [];
    if (isNumber(conditionModel.value)) {
        accountValue = [conditionModel.value];
    } else if (isArray(conditionModel.value)) {
        accountValue = conditionModel.value as number[];
    }

    return {
        ...getDefaultFormRow(),
        id: conditionModel.id,
        conditionType: FormRuleConditionType.ACCOUNT,
        accountValue,
    };
}

function buildEntityConditionFormRow(conditionModel: TransactionRuleCondition) {
    if (isNumber(conditionModel.value) || isArray(conditionModel.value)) {
        return {
            ...getDefaultFormRow(),
            id: conditionModel.id,
            conditionType: FormRuleConditionType.ENTITY,
            entityValue: isNumber(conditionModel.value)
                ? [conditionModel.value]
                : conditionModel.value.filter(isNumber),
        };
    }
}

function buildCounterpartyConditionFormRow(
    conditionModel: TransactionRuleCondition,
    counterparties?: Counterparty[],
) {
    const counterparty = counterparties?.find(
        (c) => c.id === conditionModel.value,
    );
    if (counterparty) {
        return {
            ...getDefaultFormRow(),
            id: conditionModel.id,
            conditionType: FormRuleConditionType.COUNTERPARTY,
            counterparty,
        };
    }
}

function isAmountCondition(
    value: TransactionRuleCondition["value"],
): value is RuleConditionAmount {
    return isObject(value) && ("min" in value || "max" in value);
}

function amountConditionIsCredit(value: RuleConditionAmount): boolean {
    return (
        (isNumber(value.min) && value.min >= 0) ||
        (isNumber(value.max) && value.max > 0)
    );
}

function getSingleAmountValue(amount: RuleConditionAmount): number | undefined {
    if (isNumber(amount.min) && !isNumber(amount.max)) {
        return Math.abs(amount.min);
    }
    if (isNumber(amount.max) && !isNumber(amount.min)) {
        return Math.abs(amount.max);
    }
}

function getAmountValueRange(
    amount: RuleConditionAmount,
): RuleConditionAmount | undefined {
    if (isNumber(amount.min) && isNumber(amount.max)) {
        if (amount.min >= 0) {
            return {
                min: amount.min,
                max: amount.max,
            };
        } else {
            return {
                min: Math.abs(amount.max),
                max: Math.abs(amount.min),
            };
        }
    }
}

function getAmountConditionType(
    value: RuleConditionAmount,
): AmountConditionType {
    if (isNumber(value.min) && isNumber(value.max)) {
        return AmountConditionType.BETWEEN;
    }
    if (
        (isNumber(value.min) && value.min >= 0) ||
        (isNumber(value.max) && value.max <= 0)
    ) {
        return AmountConditionType.GREATER_THAN;
    }
    return AmountConditionType.LESS_THAN;
}

export function getDefaultFormRow(): Omit<
    TransactionRuleConditionFormSchema,
    "id"
> {
    return {
        amountIsCredit: true,
        amountConditionType: AmountConditionType.GREATER_THAN,
        descriptionConditionType: DescriptionConditionType.CONTAINS,
    };
}

export function modelToFormActions(
    rule: Partial<Pick<TransactionRule, "category" | "entityId">>,
): TransactionRuleActionFormSchema[] {
    const actions: TransactionRuleActionFormSchema[] = [];

    if (rule.category) {
        actions.push({
            actionType: FormRuleActionType.CATEGORY,
            categoryValue: rule.category,
        });
    }

    if (rule.entityId) {
        actions.push({
            actionType: FormRuleActionType.ENTITY,
            entityValue: rule.entityId,
        });
    }

    return actions;
}
