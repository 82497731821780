import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ServerInferRequest } from "@ts-rest/core";
import { getBackendAPIClient } from "../lib/backendAPIClient";
import { paypalContract } from "../common/contracts/paypal.contract";
import { useWorkspaceContext } from "../state/workspaceContext";
import { IntegrationConnection } from "../common/types/integrationConnection";
import { IntegrationConnectionDto } from "../common/dto/integrationConnection/integration-connection.dto";
import { useConnectAccount } from "../components/connectAccount/ConnectAccount.context";
import { addIntegrationConnectionInQueryData } from "../queries/entitiesAccounts";

export const paypalContractClient = getBackendAPIClient(paypalContract);

export function usePayPalConnectionCreationMutation(
    onSuccess?: (connection: IntegrationConnection) => void,
) {
    const { activeWorkspace } = useWorkspaceContext();
    const { updateConnectAccountState } = useConnectAccount();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (
            data: Omit<
                ServerInferRequest<
                    typeof paypalContract.createConnection
                >["body"],
                "workspaceId"
            >,
        ) => {
            if (!activeWorkspace) {
                throw new Error("No active workspace");
            }

            const response = await paypalContractClient.createConnection({
                body: {
                    ...data,
                    workspaceId: activeWorkspace.id,
                },
            });

            return IntegrationConnectionDto.deserialize(response.body);
        },
        onMutate: () => {
            updateConnectAccountState({
                isConnecting: true,
            });
        },
        onSuccess: (connection) => {
            addIntegrationConnectionInQueryData(queryClient, connection);
            const hasAccountsNotReadyForSync =
                connection.integrationAccounts?.some(
                    (a) => !a.financialAccount.entityId,
                );

            if (hasAccountsNotReadyForSync) {
                updateConnectAccountState({
                    isConnecting: false,
                    connectionSaved: true,
                    integrationConnection: connection,
                });
            } else {
                updateConnectAccountState({
                    isConnecting: false,
                });
            }

            onSuccess?.(connection);
        },
    });
}
