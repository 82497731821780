import React from "react";
import { format } from "date-fns";
import classNames from "classnames";
import { flatRatePlanNames } from "../../../../common/flatRateBilling";
import {
    fullDateFormat,
    fullDateFullYearFormat,
} from "../../../../common/helpers/date";
import { useBillingStatus } from "../../../../hooks/useBillingStatus";
import { discountLabel } from "../../../../helpers/billing";
import styles from "./FlatRateBilling.module.scss";

export const CurrentPlanHeader: React.FC = () => {
    const {
        currentPlan,
        periodEnd,
        cancelsAt,
        isTrialing,
        isDowngrading,
        subscriptionDetails,
    } = useBillingStatus();

    const planName = (
        <>
            {flatRatePlanNames[currentPlan]} {isTrialing && "- Free Trial"}
        </>
    );

    let periodEndAction: string;

    if (isTrialing) {
        periodEndAction = "Ends";
    } else if (cancelsAt) {
        periodEndAction = "Ends";
    } else {
        periodEndAction = "Renews";
    }

    let additionalPlanInfo: React.ReactNode;

    if (isDowngrading && periodEnd) {
        additionalPlanInfo = (
            <span className={styles.additionalInfo}>
                (Access until {format(periodEnd, fullDateFormat)})
            </span>
        );
    } else if (isTrialing) {
        additionalPlanInfo = (
            <span className={styles.additionalInfo}>
                {periodEnd && (
                    <>
                        ({periodEndAction} on{" "}
                        {format(cancelsAt ?? periodEnd, fullDateFullYearFormat)}
                        )
                    </>
                )}
            </span>
        );
    }

    return (
        <header className={styles.title} data-testid="current-plan-name">
            <div className={styles.heading}>
                {planName} {additionalPlanInfo}
            </div>
            {subscriptionDetails?.discount && (
                <span className={classNames(styles.additionalInfo)}>
                    {discountLabel(subscriptionDetails.discount)}
                </span>
            )}
        </header>
    );
};
