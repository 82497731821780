import React from "react";
import "./EmptyState.scss";
import { Button, ExtendedButtonProps } from "../../general/Button/Button";

export interface EmptyStateProps {
    header: string;
    body?: React.ReactNode;
    ctaText?: React.ReactNode;
    action?: () => void;
    buttonVariant?: ExtendedButtonProps["variant"];
}

export const EmptyState: React.FC<EmptyStateProps> = ({
    header,
    body,
    ctaText,
    action,
    buttonVariant = "secondary",
}) => (
    <section className="transactions-empty-state">
        <header className="transactions-empty-state__header">{header}</header>
        {body && <p className="transactions-empty-state__body">{body}</p>}
        {ctaText && action ? (
            <footer className="transactions-empty-state__cta">
                <Button variant={buttonVariant} onClick={action}>
                    {ctaText}
                </Button>
            </footer>
        ) : null}
    </section>
);
