import React, { useCallback, useMemo, useState } from "react";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { reorderEntityAccounts } from "../../lib/entity";
import { EntityProvider } from "../entity/EntityProvider";
import { useEntities } from "../../hooks/useEntities";
import { Entity as EntityType } from "../../common/types/entity";
import { Loader } from "../general/Loader";
import { useFinancialAccountsCache } from "../../hooks/useFinancialAccountsCache";
import { FinancialAccount } from "../../common/types/financialAccount";
import { financialAccountsRest } from "../../lib/restClient";
import { useEntitiesContexts } from "../../hooks/useEntitiesContexts";
import {
    entitiesAccountsQueryKey,
    reorderEntityAccountsInQueryData,
    updateFinancialAccountInQueryData,
} from "../../queries/entitiesAccounts";
import { ACCOUNTS_POSSIBLY_PERSONAL } from "../../common/helpers/financialAccount";
import { useCurrentWorkspaceMembers } from "../../api/workspace-member.api";
import { StandardModal } from "../general/Modal/Modal";
import { EntityRow } from "./EntityRow";
import { ConfirmAccountTypeChangeModal } from "./ConfirmAccountTypeChangeModal";
import { AccountsDndContextProvider } from "./AccountsDnd/AccountsDndContextProvider";
import { AccountsHeader } from "./AccountsHeader";
import { AccountTypeChangeForbiddenModal } from "./AccountTypeChangeForbiddenModal";
import { Entity } from "./Entity";
import "./AccountsPage.scss";
import { AccountsChart } from "./AccountsChart/AccountsChart";

interface ConfirmAccountTypeChangeContext {
    newEntity: EntityType;
    account: FinancialAccount;
    order: number[];
    resolver: (response: boolean) => void;
}

interface HandleAccountEntityUpdateParams {
    movedAccount: FinancialAccount;
    entity: EntityType;
    order: number[];
    queryClient: QueryClient;
}

function handleFinancialAccountEntityUpdate({
    movedAccount,
    entity,
    order,
    queryClient,
}: HandleAccountEntityUpdateParams) {
    financialAccountsRest
        .update(movedAccount.id, {
            entityId: entity.id,
            order,
        })
        .finally(() =>
            queryClient.invalidateQueries({
                queryKey: [entitiesAccountsQueryKey],
            }),
        );

    updateFinancialAccountInQueryData(queryClient, {
        ...movedAccount,
        entity,
        entityId: entity.id,
    });
}

export const AccountsPage: React.FC = () => {
    const entitiesContext = useEntitiesContexts();
    const entities = useMemo(
        () =>
            entitiesContext
                ?.filter(
                    // hide "Other entities" entity if it has no accounts
                    (ec) =>
                        !ec.entity.isMockEntity ||
                        ec.financialAccounts.length > 0,
                )
                .map((ec) => ec.entity) ?? [],
        [entitiesContext],
    );
    const entitiesWithBalance = useEntities({
        onlyWithBalance: true,
    });

    const { data: workspaceMembers, isFetched: membersFetched } =
        useCurrentWorkspaceMembers();

    const [, setCachedAccounts] = useFinancialAccountsCache();

    const [confirmTypeChangeContext, setConfirmTypeChangeContext] =
        useState<ConfirmAccountTypeChangeContext>();
    const queryClient = useQueryClient();

    const finalizeEntityChange = useCallback(
        (
            entity: EntityType,
            movedAccount: FinancialAccount,
            order: number[],
        ) => {
            handleFinancialAccountEntityUpdate({
                movedAccount,
                entity,
                order,
                queryClient,
            });

            reorderEntityAccountsInQueryData(queryClient, entity, order);
            setCachedAccounts(undefined); // invalidate accounts cache if moving accounts to different entities
        },
        [queryClient, setCachedAccounts],
    );

    const onConfirmTypeChange = useCallback(
        (confirm?: boolean) => {
            if (!confirmTypeChangeContext) {
                return;
            }

            if (confirm) {
                finalizeEntityChange(
                    confirmTypeChangeContext.newEntity,
                    confirmTypeChangeContext.account,
                    confirmTypeChangeContext.order,
                );
            }

            confirmTypeChangeContext.resolver(confirm ?? false);
            setConfirmTypeChangeContext(undefined);
        },
        [confirmTypeChangeContext, finalizeEntityChange],
    );

    const handleReorder = useCallback(
        async (
            entity: EntityType,
            order: number[],
            movedAccount: FinancialAccount,
        ) => {
            if (movedAccount.entityId === entity.id) {
                reorderEntityAccounts({ order, entityId: entity.id }).catch(
                    () => null,
                );

                reorderEntityAccountsInQueryData(queryClient, entity, order);

                return true;
            }

            if (entity.isPersonal !== movedAccount.entity?.isPersonal) {
                return new Promise<boolean>((resolver) => {
                    setConfirmTypeChangeContext({
                        newEntity: entity,
                        account: movedAccount,
                        order,
                        resolver,
                    });
                });
            } else {
                finalizeEntityChange(entity, movedAccount, order);

                return true;
            }
        },
        [queryClient, finalizeEntityChange],
    );

    if (!membersFetched) {
        return <Loader />;
    }

    return (
        <>
            <AccountsHeader />

            {entitiesWithBalance.length > 1 && (
                <AccountsChart entities={entitiesWithBalance} />
            )}

            <main className="accounts__table">
                <EntityRow
                    className="entity__titles accounts__table__header"
                    entityOrAccount={
                        <span className="label">Entities & accounts</span>
                    }
                    team={<span className="label">Members</span>}
                    lastSync={<span className="label">Last sync</span>}
                    balanceAndActions={<span className="label">Balance</span>}
                />
                <AccountsDndContextProvider onReorder={handleReorder}>
                    {entities.map((entity, idx) => (
                        <EntityProvider entity={entity} key={entity.id}>
                            <Entity
                                last={idx === entities.length - 1}
                                members={workspaceMembers}
                            />
                        </EntityProvider>
                    ))}
                </AccountsDndContextProvider>

                <StandardModal
                    show={!!confirmTypeChangeContext}
                    onHide={onConfirmTypeChange}
                >
                    {confirmTypeChangeContext &&
                        ACCOUNTS_POSSIBLY_PERSONAL[
                            confirmTypeChangeContext.account.sourceType
                        ] && (
                            <ConfirmAccountTypeChangeModal
                                account={confirmTypeChangeContext.account}
                                entity={confirmTypeChangeContext.newEntity}
                                close={onConfirmTypeChange}
                            />
                        )}

                    {confirmTypeChangeContext &&
                        !ACCOUNTS_POSSIBLY_PERSONAL[
                            confirmTypeChangeContext.account.sourceType
                        ] && (
                            <AccountTypeChangeForbiddenModal
                                close={onConfirmTypeChange}
                            />
                        )}
                </StandardModal>
            </main>
        </>
    );
};
