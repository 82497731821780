import { TRANSACTION_UPDATE_SOURCE } from "../constants";
import { User } from "./user";

export enum AuditLogAuthorType {
    USER = "USER", // NOTE: use for updates by users
    OPS = "OPS", // NOTE: use for updates by Kick team
    SYSTEM = "SYSTEM", // NOTE: use for all automatic updates
}

export interface AuditLogAuthorMetadata {
    author: string;
    email?: string;
}

export interface AuditLogSimilarityMetadata {
    description: string;
    date: string;
    amount: number;
}

export interface AuditLogTransferMatchingMetadata {
    description: string;
    date: string;
    amount: number;
}

export interface AuditLogRuleMetadata {
    ruleId: string;
}

export type AuditLogMetadata =
    | AuditLogAuthorMetadata
    | AuditLogSimilarityMetadata
    | AuditLogTransferMatchingMetadata
    | AuditLogRuleMetadata;

export enum AuditLogProperty {
    CATEGORY = "CATEGORY",
    ENTITY = "ENTITY",
    SPLIT = "SPLIT",
}

export interface CategoryAuditLogValue {
    category: string;
    categoryId: string;
}

export interface EntityAuditLogValue {
    entityId: number;
    entityName?: string;
    isBusiness: boolean;
    classificationChanged: boolean;
    category?: string;
}

export interface SplitAuditLogValue {
    splitParentId: number | null;
    originalAmount: number;
}

export type AuditLogValue =
    | CategoryAuditLogValue
    | EntityAuditLogValue
    | SplitAuditLogValue;

export enum AuditLogObjectType {
    TRANSACTION = "TRANSACTION",
}

export interface AuditLog {
    id: number;
    date: Date;
    objectType: AuditLogObjectType | null;
    objectId: string | null;
    property: AuditLogProperty;
    authorType: AuditLogAuthorType;
    updateSource: TRANSACTION_UPDATE_SOURCE;
    metadata: AuditLogMetadata;
    newValue: AuditLogValue;
    user: User | null;
    userId: number | null;
}
