import { useCallback, useState } from "react";

interface UseSubmenuTransitionAnimationProps {
    onAnimationFrame: () => void;
}

export const useSubmenuTransitionAnimation = ({
    onAnimationFrame,
}: UseSubmenuTransitionAnimationProps) => {
    const [isAnimationActive, setIsAnimationActive] = useState(false);

    const animate = useCallback(() => {
        const animationDuration = 550; // ms
        const startTime = performance.now();

        const updateIndicator = (currentTime: number) => {
            const timeElapsed = currentTime - startTime;
            onAnimationFrame();

            if (timeElapsed < animationDuration / 2) {
                setIsAnimationActive(true);
            }

            if (timeElapsed < animationDuration) {
                requestAnimationFrame(updateIndicator);
            } else {
                setIsAnimationActive(false);
            }
        };

        requestAnimationFrame(updateIndicator);
    }, [onAnimationFrame]);

    return {
        isAnimationActive,
        triggerAnimation: animate,
    };
};
