import React from "react";
import classNames from "classnames";
import { ChildrenProps } from "../../../types";

interface Props extends ChildrenProps {
    isTitle?: boolean;
}

export const DataTableHeaderCell: React.FC<Props> = ({ isTitle, children }) => (
    <th
        className={classNames("data-table__header-cell", {
            "data-table__header-cell--title": isTitle,
        })}
    >
        {children}
        <span className="data-table__cover-cell">{children}</span>
    </th>
);
