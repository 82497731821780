import React, { useCallback, useState } from "react";
import { UpdateProfileDto } from "../../../../common/dto/user/update-profile.dto";
import { requestIdentityVerification } from "../../../../lib/authentication";
import {
    CodeVerification,
    CodeVerificationProps,
} from "../../../general/CodeVerification/CodeVerification";
import { useUser } from "../../../../hooks/useUser";
import { StandardModalHeader } from "../../../general/Modal/Modal";
import { EditableProfileFields } from "./types";
import { UpdateEmailForm } from "./UpdateEmailForm";
import { UpdatePhoneForm } from "./UpdatePhoneForm";
import { UpdateProfileFormFooter } from "./UpdateProfileFormFooter";

export interface UpdateProfileFlowProps {
    update: EditableProfileFields;
    onSuccess(payload: UpdateProfileDto): Promise<any>;
    onCancel(): void;
}

const FORM_HEADERS: Record<EditableProfileFields, string> = {
    phoneNumber: "Update Phone Number",
    email: "Update Email",
};

const VERIFICATION_HEADERS: Record<EditableProfileFields, string> = {
    email: "Verify Email",
    phoneNumber: "Verify Phone Number",
};

type UpdateProfilePayload = Pick<UpdateProfileDto, "email" | "phoneNumber">;

export const UpdateProfileFlow: React.FC<UpdateProfileFlowProps> = ({
    update,
    onCancel,
    onSuccess,
}) => {
    const [payload, setPayload] = useState<UpdateProfilePayload>();
    const user = useUser();

    const requestVerification: CodeVerificationProps["request"] =
        useCallback(async () => {
            await requestIdentityVerification({
                phoneOrEmail: payload![update]!,
            });
        }, [payload, update]);

    const handleVerificationSuccess: CodeVerificationProps["onSuccess"] =
        useCallback(
            async (verificationCode) => {
                await onSuccess({
                    [update]: payload![update]!,
                    phoneOrEmail: payload![update]!,
                    verificationCode,
                });
                setPayload(undefined);
            },
            [onSuccess, payload, update],
        );

    const handleSubmit = useCallback((value: UpdateProfilePayload) => {
        setPayload(value);
    }, []);

    const footer = (valid: boolean, loading?: boolean) => (
        <UpdateProfileFormFooter
            valid={valid}
            loading={loading}
            onCancel={onCancel}
        />
    );

    const fieldForm =
        update === "email" ? (
            <UpdateEmailForm email={user.email ?? ""} onSubmit={handleSubmit}>
                {footer}
            </UpdateEmailForm>
        ) : (
            <UpdatePhoneForm
                phoneNumber={user.phoneNumberDisplay ?? ""}
                onSubmit={handleSubmit}
            >
                {footer}
            </UpdatePhoneForm>
        );

    return (
        <>
            <StandardModalHeader>
                <h4 className="m-0">
                    {payload
                        ? VERIFICATION_HEADERS[update]
                        : FORM_HEADERS[update]}
                </h4>
            </StandardModalHeader>

            {payload ? (
                <CodeVerification
                    phoneOrEmail={payload[update] ?? ""}
                    onSuccess={handleVerificationSuccess}
                    request={requestVerification}
                    renderSubmit={footer}
                />
            ) : (
                fieldForm
            )}
        </>
    );
};
