import React from "react";
import { useTypedFlags } from "../../hooks/useTypedFlags";
import { NavigationMenu } from "../navigation/NavigationMenu";
import { NavigationItem } from "../navigation/NavigationItem";
import {
    ActivityIcon,
    CreditCardIcon,
    DirectoryIcon,
    FileSheetIcon,
    MembersIcon,
    PlansIcon,
} from "../../icons";
import { useBillingManagement } from "../../hooks/useBillingManagement";
import { useBillingType } from "../../hooks/useBillingType";
import { SubscriptionType } from "../../common/types/billing";
import { UserSettingsPages, WorkspaceSettingsPages } from "./SettingsProvider";
import { SettingsNavLink } from "./SettingsNavLink";

export const WorkspaceSettingsNavigation: React.FC = () => {
    const {
        directory: canUseDirectory,
        classes: canUseClasses,
        workspaceMemberUi: canUseWorkspaceMembers,
    } = useTypedFlags();
    const { canManageBilling } = useBillingManagement();
    const billingType = useBillingType();
    const showPlans =
        canManageBilling && billingType === SubscriptionType.FLAT_RATE;

    return (
        <>
            <header className="settings__navigation__workspaces-header">
                Workspace
            </header>

            <NavigationMenu className="flex-column">
                {canUseWorkspaceMembers && (
                    <SettingsNavLink eventKey={WorkspaceSettingsPages.MEMBERS}>
                        <NavigationItem icon={<MembersIcon />}>
                            Members
                        </NavigationItem>
                    </SettingsNavLink>
                )}

                <SettingsNavLink eventKey={WorkspaceSettingsPages.CATEGORIES}>
                    <NavigationItem icon={<ActivityIcon />}>
                        Categories
                    </NavigationItem>
                </SettingsNavLink>

                <SettingsNavLink eventKey={WorkspaceSettingsPages.RULES}>
                    <NavigationItem icon={<FileSheetIcon />}>
                        Rules
                    </NavigationItem>
                </SettingsNavLink>

                {canUseDirectory && (
                    <SettingsNavLink
                        eventKey={WorkspaceSettingsPages.DIRECTORY}
                    >
                        <NavigationItem icon={<DirectoryIcon />}>
                            Directory
                        </NavigationItem>
                    </SettingsNavLink>
                )}

                {canUseClasses && (
                    <SettingsNavLink eventKey={WorkspaceSettingsPages.CLASSES}>
                        <NavigationItem icon={<FileSheetIcon />}>
                            Classes
                        </NavigationItem>
                    </SettingsNavLink>
                )}

                {showPlans && (
                    <SettingsNavLink eventKey={UserSettingsPages.PLANS}>
                        <NavigationItem icon={<PlansIcon />}>
                            Plans
                        </NavigationItem>
                    </SettingsNavLink>
                )}

                {canManageBilling && (
                    <SettingsNavLink eventKey={UserSettingsPages.BILLING}>
                        <NavigationItem icon={<CreditCardIcon />}>
                            Billing
                        </NavigationItem>
                    </SettingsNavLink>
                )}
            </NavigationMenu>
        </>
    );
};
