import React, { useMemo } from "react";
import { isString } from "lodash";
import { useCategoryLabelGetter } from "../../../hooks/useCategoryLabelGetter";
import { TransactionRuleCondition } from "../../../common/types/transactionRule";

interface Props {
    condition: TransactionRuleCondition;
}

export const RuleCategoryCondition: React.FC<Props> = ({ condition }) => {
    const categoryLabelGetter = useCategoryLabelGetter();

    const categoryLabel = useMemo(() => {
        if (isString(condition.value)) {
            return categoryLabelGetter(condition.value);
        }
    }, [categoryLabelGetter, condition.value]);

    if (!categoryLabel) {
        return null;
    }

    return (
        <>
            Category is <strong>{categoryLabel}</strong>
        </>
    );
};
