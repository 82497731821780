import React from "react";
import "./TransactionActionsRequiredNumber.scss";

interface Props {
    value: number;
}

export const TransactionActionsRequiredNumber: React.FC<Props> = ({
    value,
}) => (
    <span
        className="transaction-actions-required-number"
        data-testid="transaction-actions-required-number"
    >
        {value}
    </span>
);
