import { Category } from "../../types/category";
import { RuleSource, TransactionRule } from "../../types/transactionRule";
import { EntityDto } from "../entity/entity.dto";
import { TransactionRuleConditionDto } from "./transaction-rule-condition.dto";

export class TransactionRuleDto {
    id: string;
    categoryId: string | null;
    category: Category | null;
    workspaceId: string;
    conditions: TransactionRuleConditionDto[];
    entityId: number | null;
    entity: EntityDto | null;
    source: RuleSource | null;

    constructor(rule: TransactionRule) {
        this.id = rule.id;
        this.categoryId = rule.categoryId;
        this.category = rule.category;
        this.workspaceId = rule.workspaceId;
        this.conditions = rule.conditions.map(
            (condition) => new TransactionRuleConditionDto(condition),
        );
        this.entityId = rule.entityId;
        this.entity = rule.entity ? new EntityDto(rule.entity) : null;
        this.source = rule.source;
    }

    static deserialize(dto: TransactionRuleDto): TransactionRule {
        return {
            ...dto,
            conditions: dto.conditions.map(
                TransactionRuleConditionDto.deserialize,
            ),
            entity: dto.entity ? EntityDto.deserialize(dto.entity) : null,
        };
    }
}
