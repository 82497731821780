import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import classNames from "classnames";
import { TriangleIcon } from "../../../icons";
import { Button } from "../Button/Button";
import styles from "./Pagination.module.scss";

export interface PaginationProps {
    onPageChange(nextPage: number): void;
    currentPage: number;
    pageCount: number;
    className?: string;
    disabled?: boolean;
}

export const Pagination: React.FC<PaginationProps> = ({
    onPageChange,
    currentPage,
    pageCount,
    className,
    disabled,
}) => {
    const [page, setPage] = useState<number | undefined>(currentPage);
    const inputRef = useRef<HTMLInputElement>(null);
    const [width, setWidth] = useState(0);
    const currentPageContainerRef = useRef<HTMLSpanElement>(null);

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
                inputRef.current?.blur();
            }
        },
        [],
    );

    useEffect(() => {
        setPage(currentPage);
    }, [currentPage]);

    useEffect(() => {
        if (currentPageContainerRef.current) {
            let additionalWidth = 0;
            // This is a fix for use cases when a user has 100% or larger zoom
            if (window.devicePixelRatio * 100 >= 200) {
                additionalWidth += 3;
            }
            setWidth(
                currentPageContainerRef.current.offsetWidth + additionalWidth,
            );
        }
    }, [page]);

    const changePage = () => {
        // this happens when a user deletes the value and leaves the input
        // we set the local page to the propagated one
        if (page === undefined) {
            setPage(currentPage);
            return;
        }

        // nothing has changed
        if (page === currentPage) {
            return;
        }

        const safePage = Math.max(Math.min(Number(page), pageCount), 1);
        setPage(safePage);
        onPageChange(safePage);
    };

    const style = useMemo(
        () => ({
            width: `${width}px`,
        }),
        [width],
    );

    return (
        <div className={classNames(styles.pagination, className)}>
            {pageCount > 1 && (
                <>
                    <span className={styles.pager}>
                        <input
                            type="number"
                            placeholder="Enter page"
                            value={Number(page).toFixed(0)}
                            ref={inputRef}
                            onChange={(event) => {
                                if (event.target.value === "") {
                                    setPage(undefined);
                                    return;
                                }
                                setPage(Number(event.target.value));
                            }}
                            onBlur={() => {
                                changePage();
                            }}
                            onKeyDown={onKeyDown}
                            style={style}
                        />
                        <span
                            className={classNames({
                                [styles.invisible]: true,
                            })}
                            ref={currentPageContainerRef}
                        >
                            {page}
                        </span>
                        <span>of {pageCount}</span>
                    </span>

                    <div className={styles.pagerButtonsContainer}>
                        <Button
                            variant="secondary"
                            className={styles.left}
                            icon
                            onClick={() => {
                                onPageChange(currentPage - 1);
                                setPage(currentPage - 1);
                                window.scrollTo({ top: 0, behavior: "smooth" });
                            }}
                            disabled={disabled || currentPage <= 1}
                        >
                            <TriangleIcon />
                        </Button>
                        <Button
                            variant="secondary"
                            icon
                            className={styles.right}
                            onClick={() => {
                                onPageChange(currentPage + 1);
                                setPage(currentPage + 1);
                                window.scrollTo({ top: 0, behavior: "smooth" });
                            }}
                            disabled={disabled || currentPage >= pageCount}
                        >
                            <TriangleIcon />
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};
