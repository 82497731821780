import { DemoFinancialAccount, DemoIntegrationAccount } from "../lib";
import { ConnectionProviderType } from "../../../../../common/types/financialConnection";
import {
    FinancialAccountSourceType,
    FinancialAccountType,
} from "../../../../../common/types/financialAccount";

const chaseIntegrationAccount: DemoIntegrationAccount = {
    connection: {
        bankLogoUrl: "/images/demo/chase.png",
        bankName: "Chase",
        createdAt: new Date(),
        connectionProvider: ConnectionProviderType.PLAID,
    },
};

const americanExpressIntegrationAccount: DemoIntegrationAccount = {
    connection: {
        bankLogoUrl: "/images/demo/american_express.png",
        bankName: "American Express",
        createdAt: new Date(),
        connectionProvider: ConnectionProviderType.PLAID,
    },
};

const stripeIntegrationAccount: DemoIntegrationAccount = {
    connection: {
        createdAt: new Date(),
        connectionProvider: ConnectionProviderType.STRIPE,
    },
};

export const chaseFinancialAccount: DemoFinancialAccount = {
    id: 1,
    accountNumberMask: "1257",
    name: null,
    financialType: FinancialAccountType.CHECKING,
    integrationAccounts: [chaseIntegrationAccount],
    sourceType: FinancialAccountSourceType.PLAID,
};

export const personalChaseFinancialAccount: DemoFinancialAccount = {
    id: 1,
    accountNumberMask: "2490",
    name: null,
    financialType: FinancialAccountType.CHECKING,
    integrationAccounts: [chaseIntegrationAccount],
    sourceType: FinancialAccountSourceType.PLAID,
};

export const stripeFinancialAccount: DemoFinancialAccount = {
    id: 2,
    accountNumberMask: null,
    name: "Stripe",
    financialType: FinancialAccountType.CHECKING,
    integrationAccounts: [stripeIntegrationAccount],
    sourceType: FinancialAccountSourceType.STRIPE,
};

export const americanExpressFinancialAccount: DemoFinancialAccount = {
    id: 3,
    accountNumberMask: "4028",
    name: null,
    financialType: FinancialAccountType.CHECKING,
    integrationAccounts: [americanExpressIntegrationAccount],
    sourceType: FinancialAccountSourceType.PLAID,
};
