import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useFinancialDocumentBulkSelection } from "../FinancialDocumentBulkSelection/useFinancialDocumentBulkSelection";
import { ActionBar } from "../../general/ActionBar/ActionBar";
import actionBarStyles from "../../general/ActionBar/ActionBar.module.scss";
import { ConfirmationModal } from "../../general/ConfirmationModal";
import { useFinancialDocumentBulkActions } from "../FinancialDocumentBulkActions/FinancialDocumentBulkActions";
import { FinancialDocumentBulkActionTypes } from "../../../common/constants";
import { EntitiesDropdown } from "./EntitiesDropdown";

export const FinancialDocumentActionsBar: React.FC = () => {
    const [removeConfirmationDialog, setRemoveConfirmationDialog] =
        useState(false);
    const bulkSelection = useFinancialDocumentBulkSelection();
    const bulkActions = useFinancialDocumentBulkActions();

    const remove = useCallback(async () => {
        await bulkActions.run({
            type: FinancialDocumentBulkActionTypes.REMOVE,
        });

        bulkSelection.clearSelection();

        setRemoveConfirmationDialog(false);
    }, [bulkActions, bulkSelection]);

    return (
        <ActionBar
            selectedCount={bulkSelection.selectedCount}
            totalCount={bulkSelection.totalCount}
            clearSelection={bulkSelection.clearSelection}
            hasSelectedAll={bulkSelection.hasSelectedByCurrentFilters}
            selectAll={bulkSelection.selectAll}
        >
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id="action-bar-remove">
                        Remove all selected documents
                    </Tooltip>
                }
            >
                <button
                    className={classNames(actionBarStyles.button)}
                    disabled={bulkActions.busy}
                    onClick={() => setRemoveConfirmationDialog(true)}
                    data-testid="bulk-remove-button"
                >
                    Remove
                </button>
            </OverlayTrigger>

            <EntitiesDropdown
                disabled={bulkActions.busy}
                onSelected={(entityId) =>
                    bulkActions.run({
                        entityId,
                        type: FinancialDocumentBulkActionTypes.ASSIGN_ENTITY,
                    })
                }
            >
                Entity
            </EntitiesDropdown>

            <ConfirmationModal
                show={removeConfirmationDialog}
                onHide={() => setRemoveConfirmationDialog(false)}
                closeButton
                title="Remove selected documents"
                question={
                    <>
                        You're about to remove {bulkSelection.selectedCount}{" "}
                        documents. This action cannot be undone. Do you want to
                        continue?
                    </>
                }
                onConfirm={remove}
                onReject={() => setRemoveConfirmationDialog(false)}
                yes="Remove documents"
                no="Cancel"
            />
        </ActionBar>
    );
};
