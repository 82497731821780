import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { Pagination } from "../general/Pagination/Pagination";
import { TransactionsActionBar } from "./TransactionsBulkActions/TransactionsActionBar/TransactionsActionBar";
import { TransactionsTotals } from "./TransactionsTotals";
import styles from "./PaginatedTransactionsTableFooter.module.scss";
import { useBulkActions } from "./TransactionsBulkActions/useBulkActions";
import { BulkUpdateStatus } from "./TransactionsBulkActions/lib";
import { PaginatedTransactionsTableContext } from "./PaginatedTransactionsTableContextProvider";
import { useTransactionsStatisticsQuery } from "./useTransactionsStatisticsQuery";

export const PaginatedTransactionsTableFooter: React.FC = () => {
    const {
        setPage,
        currentPage,
        totalPages,
        transactionCount,
        transactions,
        filters,
    } = useContext(PaginatedTransactionsTableContext);

    const { data: statistics } = useTransactionsStatisticsQuery({
        filters,
    });

    const paginationParams = {
        pageCount: totalPages,
        currentPage,
        totalCount: transactionCount,
        onPageChange: setPage,
    };

    const stickyRef = useRef<HTMLTableSectionElement>(null);

    const [isStuckBottom, setIsStuckBottom] = useState(false);

    const bulkActions = useBulkActions();

    const pageChangeWithBulkActionReset = (newPage: number) => {
        if (bulkActions.selectedCount) {
            bulkActions.clearSelection();
        }

        paginationParams.onPageChange(newPage);
    };

    const handleScroll = () => {
        if (stickyRef.current) {
            const rect = stickyRef.current.getBoundingClientRect();
            const isAtBottom = Math.abs(rect.bottom - window.innerHeight) < 1;
            setIsStuckBottom(isAtBottom);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => handleScroll(), [transactionCount]);

    const passingStatistics = useMemo(() => {
        if (bulkActions.selectedCount === 0 || bulkActions.hasSelectedAll) {
            return statistics;
        }

        const selectedStatistics = (transactions || []).reduce(
            (agg, transaction) => {
                if (!bulkActions.isSelected(transaction)) {
                    return agg;
                }

                return {
                    total: agg.total + transaction.amount,
                    cashIn:
                        agg.cashIn +
                        (transaction.amount > 0 ? transaction.amount : 0),
                    cashOut:
                        agg.cashOut +
                        (transaction.amount < 0 ? transaction.amount : 0),
                    averageSum: agg.averageSum + transaction.absoluteAmount,
                };
            },
            {
                total: 0,
                cashIn: 0,
                cashOut: 0,
                averageSum: 0,
            },
        );
        return {
            ...selectedStatistics,
            average: selectedStatistics.averageSum / bulkActions.selectedCount,
            count: bulkActions.selectedCount,
        };
    }, [bulkActions, statistics, transactions]);

    return (
        <>
            {transactions && transactions.length > 0 && (
                <tfoot
                    ref={stickyRef}
                    className={classNames(
                        styles.stickyFooter,
                        isStuckBottom && styles.stuckBottom,
                    )}
                >
                    <tr>
                        <td colSpan={99} className={styles.tableRow}>
                            <div className={styles.tableRowContent}>
                                {passingStatistics && (
                                    <TransactionsTotals
                                        {...passingStatistics}
                                    />
                                )}
                                <TransactionsActionBar />
                                <Pagination
                                    {...paginationParams}
                                    disabled={
                                        bulkActions.updateDetails?.status ===
                                        BulkUpdateStatus.UPDATING
                                    }
                                    onPageChange={pageChangeWithBulkActionReset}
                                />
                            </div>
                        </td>
                    </tr>
                </tfoot>
            )}
        </>
    );
};
