import { UserType } from "../common/types/user";
import { useEntities } from "./useEntities";
import { useUser } from "./useUser";

export function useAccountingState() {
    const user = useUser();
    const entities = useEntities({
        onlyBusiness: true,
    });

    if (user.type !== UserType.REGULAR) {
        return {
            reportsReady: true,
            openingBalancesSubmitted: true,
        };
    }

    const openingBalancesSubmitted = entities.some(
        (entity) => entity.openingBalanceSubmitted,
    );

    const reportsReady = entities.some((entity) => entity.reportsReady);

    return {
        openingBalancesSubmitted,
        reportsReady,
    };
}
