import React from "react";
import { usePlanManagement } from "../PlanManagement/PlanManagementContext";
import { Button, ExtendedButtonProps } from "../../general/Button/Button";

interface Props extends Omit<ExtendedButtonProps, "onClick" | "children"> {
    children: React.ReactNode | ((convert: () => void) => React.ReactNode);
}

export const EndTrialAction: React.FC<Props> = ({
    children,
    ...buttonProps
}) => {
    const { convert } = usePlanManagement();

    if (typeof children === "function") {
        return children(convert);
    } else {
        return (
            <Button {...buttonProps} onClick={convert}>
                {children}
            </Button>
        );
    }
};
