import { FC, ReactNode } from "react";
import { KeyboardShortcut } from "../general/KeyboardShortcut/KeyboardShortcut";
import styles from "./Commands.module.scss";

export interface ShortcutProps {
    keys: ReactNode[];
    hideText?: boolean;
}

export const Shortcut: FC<ShortcutProps> = ({ keys, hideText }) => (
    <span className={styles.shortcut}>
        {keys.map((key, i) => (
            <>
                <KeyboardShortcut key={i} square>
                    {key}
                </KeyboardShortcut>

                {!hideText && i < keys.length - 1 && "then"}
            </>
        ))}
    </span>
);
