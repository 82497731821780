import React, { useEffect, useState } from "react";
import "./styles/main.scss";
import { BrowserRouter, FutureConfig } from "react-router-dom";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { Loader } from "./components/general/Loader";
import { PrivateInterface } from "./components/PrivateInterface";
import { PublicInterface } from "./components/PublicInterface";
import { identifyUser } from "./lib/analytics";
import { User } from "./common/types/user";
import { getEnv } from "./lib/environment";
import { getLDUserKey, getLDWorkspaceKey } from "./common/helpers/featureFlags";
import { useWorkspaceContext } from "./state/workspaceContext";
import { TransactionRuleCreation } from "./components/transactionRules/TransactionRuleCreation/TransactionRuleCreation";
import { useGAClientIdCookie } from "./hooks/useGAClientIdCookie";
import { useExternalRefererCookie } from "./hooks/useExternalRefererCookie";
import { useLandingPageCookie } from "./hooks/useLandingPageCookie";
import { Workspace } from "./common/types/workspace";
import { useUser } from "./hooks/useUser";
import { initApp } from "./lib";
import { ConnectAccountStateProvider } from "./components/connectAccount/ConnectAccount.context";
import { NewVersionAvailableProvider } from "./components/general/NewVersionAvailable.context";
import { getPossessive } from "./common/helpers/string";
import { ToastContainer } from "./lib/toasts";

const getCypressOrRealUser = (user: User, kickEnv: string) => {
    if (Object.hasOwn(window, "Cypress")) {
        return "e2e-test-user";
    }
    return getLDUserKey(kickEnv, user);
};

const getCypressOrRealWorkspace = (workspace: Workspace, kickEnv: string) => {
    if (Object.hasOwn(window, "Cypress")) {
        return "e2e-test-workspace";
    }
    return getLDWorkspaceKey(kickEnv, workspace);
};

// will be easier to update to v7 in the future
const ROUTER_FEATURE_FLAGS: FutureConfig = {
    v7_relativeSplatPath: true,
    v7_startTransition: true,
} as const;

export const App: React.FC = () => {
    useGAClientIdCookie();
    useExternalRefererCookie();
    useLandingPageCookie();

    const user = useUser();
    const [initialized, setInitialized] = useState(false);
    const { activeWorkspaceKey, activeWorkspace } = useWorkspaceContext();

    useEffect(() => {
        if (initialized) {
            return;
        }

        initApp()
            .catch((err) => {
                throw err;
            })
            .finally(() => setInitialized(true));
    }, [initialized]);

    const ldClient = useLDClient();
    const kickEnv = getEnv("KICK_ENV");

    useEffect(() => {
        if (user && activeWorkspace) {
            identifyUser(user);

            ldClient?.identify({
                kind: "multi",
                user: {
                    key: getCypressOrRealUser(user, kickEnv),
                    name: user.name,
                    email: user.email,
                    custom: {
                        createdAt: user.createdAt.toISOString(),
                    },
                },
                workspace: {
                    key: getCypressOrRealWorkspace(activeWorkspace, kickEnv),
                    name: `${getPossessive(user.name)} Workspace`,
                },
            });
        }
    }, [user, ldClient, kickEnv, activeWorkspace]);

    const stateReloadingKey = [activeWorkspaceKey, user?.id].join("-");

    return initialized ? (
        <ConnectAccountStateProvider>
            <NewVersionAvailableProvider>
                <BrowserRouter future={ROUTER_FEATURE_FLAGS}>
                    <QueryParamProvider adapter={ReactRouter6Adapter}>
                        <TransactionRuleCreation>
                            {user ? (
                                <PrivateInterface key={stateReloadingKey} />
                            ) : (
                                <PublicInterface />
                            )}
                            <ToastContainer />
                        </TransactionRuleCreation>
                    </QueryParamProvider>
                </BrowserRouter>
            </NewVersionAvailableProvider>
        </ConnectAccountStateProvider>
    ) : (
        <Loader />
    );
};
