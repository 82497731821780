import { ProfitLossTableReportSection } from "../../../common/dto/reports/reports-common.dto";

export const ProfitLossSectionsOrder = [
    ProfitLossTableReportSection.INCOME,
    ProfitLossTableReportSection.COGS,
    ProfitLossTableReportSection.GROSS_PROFIT,
    ProfitLossTableReportSection.EXPENSES,
    ProfitLossTableReportSection.NET_OPERATING_INCOME,
    ProfitLossTableReportSection.OTHER_INCOME,
    ProfitLossTableReportSection.OTHER_EXPENSES,
    ProfitLossTableReportSection.NET_OTHER_INCOME,
    ProfitLossTableReportSection.NET_INCOME,
] as const;

export const ProfitLossSectionsLabels = {
    [ProfitLossTableReportSection.INCOME]: "Income",
    [ProfitLossTableReportSection.COGS]: "Costs of Good Sold",
    [ProfitLossTableReportSection.EXPENSES]: "Expenses",
    [ProfitLossTableReportSection.GROSS_PROFIT]: "Gross Profit",
    [ProfitLossTableReportSection.NET_OPERATING_INCOME]: "Net Operating Income",
    [ProfitLossTableReportSection.NET_INCOME]: "Net Income",
    [ProfitLossTableReportSection.OTHER_EXPENSES]: "Other Expenses",
    [ProfitLossTableReportSection.OTHER_INCOME]: "Other Income",
    [ProfitLossTableReportSection.NET_OTHER_INCOME]: "Net Other Income",
} as const satisfies Record<
    Exclude<
        ProfitLossTableReportSection,
        ProfitLossTableReportSection.CASH_BALANCE
    >,
    string
>;
