import { initContract } from "@ts-rest/core";
import { z } from "zod";
import { Counterparty } from "../types/counterparty";

const c = initContract();

const optionalFields = {
    email: z.string().optional().nullable(),
    phoneNumber: z.string().optional().nullable(),
    website: z.string().optional().nullable(),
    street: z.string().optional().nullable(),
    street2: z.string().optional().nullable(),
    city: z.string().optional().nullable(),
    postalCode: z.string().optional().nullable(),
    state: z.string().optional().nullable(),
    country: z.string().optional().nullable(),
};
const updatePayload = z.object({
    name: z.string().min(1).optional(),
    ...optionalFields,
});

export interface CounterpartyCreationResponse {
    counterparty: Counterparty;
    revealedOnTransactionsNumber?: number;
}

export const counterpartyContract = c.router(
    {
        createCounterparty: {
            method: "POST",
            path: "",
            body: z.object({
                name: z.string().min(1),
                workspaceId: z.string().uuid(),
                ...optionalFields,
            }),
            responses: {
                200: c.type<CounterpartyCreationResponse>(),
                201: c.type<CounterpartyCreationResponse>(),
                400: c.type<{ message: string }>(),
            },
        },
        updateCounterparty: {
            method: "PATCH",
            path: "/:workspaceId/:counterpartyId",
            body: updatePayload,
            responses: {
                201: c.type<{ counterparty: Counterparty }>(),
                400: c.type<{ message: string }>(),
            },
        },
        deleteCounterparty: {
            method: "DELETE",
            path: "/:workspaceId/:counterpartyId",
            query: z.object({
                force: z.literal("true").optional(),
            }),
            responses: {
                200: c.noBody(),
                409: c.type<{ transactionsFound: true }>(),
            },
        },
        mergeCounterparties: {
            method: "POST",
            path: "/:workspaceId/merge",
            body: z.object({
                counterpartyToMergeId: z.string().uuid(),
                counterpartyToKeepId: z.string().uuid(),
            }),
            responses: {
                200: c.noBody(),
                400: c.type<{ message: string }>(),
            },
        },
        copyGlobalCounterparty: {
            method: "POST",
            path: "/copy-global-to-local",
            body: z.object({
                globalCounterpartyId: z.string().uuid(),
                workspaceId: z.string().uuid(),
                update: updatePayload.optional(),
            }),
            responses: {
                201: c.type<{ localCounterparty: Counterparty }>(),
                400: c.type<{ message: string }>(),
            },
        },
    },
    {
        pathPrefix: "/counterparty",
    },
);
