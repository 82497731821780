import React, { useCallback, useMemo, useState } from "react";
import { z } from "zod";
import { Counterparty } from "../../../common/types/counterparty";
import { CounterpartyDirection } from "../../../common/helpers/counterparties";
import {
    useWorkspaceUserCache,
    WORKSPACE_USER_CACHE_KEYS,
} from "../../../hooks/useWorkspaceUserCache";
import { DirectoryFilters } from "./lib";
import { DirectoryContext } from "./Directory.context";

interface CachedFilters {
    year: number | undefined;
}

interface Props {
    children: React.ReactNode;
}

export const DirectoryContextProvider: React.FC<Props> = ({ children }) => {
    const [addingNew, setAddingNew] = useState(false);
    const [editedItem, setEditedItem] = useState<Counterparty>();
    const [counterpartyDirection, setCounterpartyDirection] =
        useState<CounterpartyDirection>();
    const [cachedFilters, setCachedFilters] =
        useWorkspaceUserCache<CachedFilters>(
            WORKSPACE_USER_CACHE_KEYS.DIRECTORY_FILTERS_CACHE_KEY,
            z.object({
                year: z.number().optional(),
            }),
        );
    const [filters, setFilters] = useState<DirectoryFilters>(() => ({
        year: cachedFilters?.year,
    }));

    const updateFilters = useCallback(
        (newFilters: DirectoryFilters) => {
            setFilters(newFilters);
            setCachedFilters({ year: newFilters.year });
        },
        [setCachedFilters],
    );

    const value = useMemo(
        () => ({
            addingNew,
            setAddingNew,
            filters,
            setFilters: updateFilters,
            editedItem,
            setEditedItem,
            counterpartyDirection,
            setCounterpartyDirection,
        }),
        [addingNew, filters, editedItem, counterpartyDirection, updateFilters],
    );

    return (
        <DirectoryContext.Provider value={value}>
            {children}
        </DirectoryContext.Provider>
    );
};
