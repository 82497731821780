import classNames from "classnames";
import { memo, useMemo, useRef, useState } from "react";
import { ReplaceProperties } from "../../../../common/types/base/generics";
import { Class } from "../../../../common/types/class";
import { GridTableRow } from "../../../general/Tables/GridTable/GridTable";
import { ExtendedFlattenedItem } from "../utils";
import styles from "../ClassesSettings.module.scss";
import { EnterIcon } from "../../../../icons";
import { AddClassRow } from "./AddClassRow";
import { ClassIdCell } from "./ClassIdCell";

import { ClassActions } from "./ClassActions";

export const ClassItem: React.FC<{
    item: ExtendedFlattenedItem;
    collapsedClasses: Set<string>;
    setCollapsedClasses: (value: React.SetStateAction<Set<string>>) => void;
    projectedDepth?: number;
    isDragged?: boolean;
    transactionCount?: number;
}> = memo(
    ({
        item,
        collapsedClasses,
        setCollapsedClasses,
        isDragged,
        projectedDepth,
        transactionCount,
    }) => {
        const [isFocused, setIsFocused] = useState(false);
        const inputRef = useRef<HTMLInputElement>(null);

        const nameCell = useMemo(() => {
            let nameValue;
            if ("addClass" in item.meta) {
                nameValue = (
                    <AddClassRow
                        parentClassId={item.meta.parentClassId}
                        isFocused={isFocused}
                        setIsFocused={setIsFocused}
                        inputRef={inputRef}
                    />
                );
            } else {
                nameValue = (
                    <ClassIdCell
                        isCollapsed={collapsedClasses.has(item.id as string)}
                        item={
                            isDragged
                                ? {
                                      isEmpty: true,
                                      id: String(item.id),
                                      hasParent: item.depth > 0,
                                  }
                                : (item as ReplaceProperties<
                                      ExtendedFlattenedItem,
                                      { meta: { classInstance: Class } }
                                  >)
                        }
                    />
                );
            }
            return nameValue;
        }, [item, isDragged, collapsedClasses, isFocused]);

        const onClickCallback = useMemo(() => {
            let onClick = undefined;
            if (
                "classInstance" in item.meta &&
                !item.meta.classInstance.parentClassId
            ) {
                onClick = () => {
                    setCollapsedClasses((prev) => {
                        const newSet = new Set(prev);
                        if (newSet.has(item.id as string)) {
                            newSet.delete(item.id as string);
                        } else {
                            newSet.add(item.id as string);
                        }
                        return newSet;
                    });
                };
            } else if ("addClass" in item.meta) {
                onClick = () => {
                    inputRef.current?.focus();
                };
            }
            return onClick;
        }, [item, setCollapsedClasses, inputRef]);

        const cells = useMemo(
            () => [
                {
                    key: "name",
                    value: nameCell,
                },
                {
                    key: "transactionCount",
                    value: (
                        <div className={styles.transactionCount}>
                            {transactionCount === 0 ? "-" : transactionCount}
                        </div>
                    ),
                },
                {
                    key: "actions",
                    value:
                        "classInstance" in item.meta ? (
                            <ClassActions
                                classInstance={item.meta.classInstance}
                            />
                        ) : (
                            <AddClassIndicator isFocused={isFocused} />
                        ),
                },
            ],
            [item, isFocused, nameCell, transactionCount],
        );

        return (
            <GridTableRow
                onClick={onClickCallback}
                key={
                    "addClass" in item.meta
                        ? `addClass:${item.meta.parentClassId}`
                        : item.id
                }
                cells={cells}
                className={classNames(styles.row, {
                    [styles.subclassRow]:
                        (!isDragged &&
                            !("addClass" in item.meta) &&
                            item.meta.classInstance.parentClassId) ||
                        (isDragged && projectedDepth && projectedDepth > 0),
                    [styles.addSubclassRow]: "addClass" in item.meta,
                    [styles.bottomBorder]: collapsedClasses.has(
                        item.id as string,
                    ),
                    [styles.isCollapsed]:
                        collapsedClasses.has(item.parentId as string) ||
                        item.meta.isHidden,
                })}
                columnBorders={false}
            />
        );
    },
);

interface AddClassIndicatorProps {
    isFocused: boolean;
}

function AddClassIndicator({ isFocused }: AddClassIndicatorProps) {
    return isFocused ? (
        <div className={styles.addClassIndicator}>
            <EnterIcon />
        </div>
    ) : null;
}
