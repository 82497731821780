import { Transaction } from "../common/types/transaction";
import { TransactionRule } from "../common/types/transactionRule";
import { GetTransactionRulesResponseDto } from "../common/dto/transactionRule/get-transaction-rules-response.dto";
import { CreateTransactionRuleDto } from "../common/dto/transactionRule/create-transaction-rule.dto";
import { TransactionRuleDto } from "../common/dto/transactionRule/transaction-rule.dto";
import { UpdateTransactionRuleDto } from "../common/dto/transactionRule/update-transaction-rule.dto";
import { GetRulesMatchingTransactionsDto } from "../common/dto/transactionRule/get-rules-matching-transactions.dto";
import { GetRuleMatchingTransactionsResponseDto } from "../common/dto/transactionRule/get-rule-matching-transactions-response.dto";
import { backendClient, unwrapResponse } from "./backendClient";

export async function disableCategorizationRule(
    transaction: Transaction,
): Promise<void> {
    await backendClient.post(
        `/legacy-categorization-rules/${transaction.id}/disable`,
    );
}

export async function getRulesForWorkspace(workspaceId: string) {
    const response = unwrapResponse(
        await backendClient.get<GetTransactionRulesResponseDto>(
            `/rules/${workspaceId}`,
        ),
    );

    return GetTransactionRulesResponseDto.deserialize(response);
}

export async function createRule(
    data: CreateTransactionRuleDto,
): Promise<TransactionRule> {
    return TransactionRuleDto.deserialize(
        unwrapResponse(
            await backendClient.post<TransactionRuleDto>(`/rules`, data),
        ),
    );
}

export async function updateRule(
    rule: TransactionRule,
    data: UpdateTransactionRuleDto,
): Promise<TransactionRule> {
    return TransactionRuleDto.deserialize(
        unwrapResponse(
            await backendClient.patch<TransactionRuleDto>(
                `/rules/${rule.workspaceId}/${rule.id}`,
                data,
            ),
        ),
    );
}

export async function getMatchingTransactionsForRule(
    workspaceId: string,
    data: GetRulesMatchingTransactionsDto,
) {
    const dto = unwrapResponse(
        await backendClient.post<GetRuleMatchingTransactionsResponseDto>(
            `/rules/${workspaceId}/get-matching-transactions`,
            data,
        ),
    );

    return GetRuleMatchingTransactionsResponseDto.deserialize(dto);
}
