import React from "react";
import { useCanManageWorkspaceMembers } from "../../../hooks/permissions/useCanManageWorkspaceMembers";
import { useMemberManagement } from "../../memberManagement/useMemberManagement";
import { Button } from "../../general/Button/Button";
import { WorkspaceMemberList } from "./WorkspaceMemberList";

export const WorkspaceMembers: React.FC = () => {
    const { addMember, configurePermissions } = useMemberManagement();
    const canManageWorkspaceMembers = useCanManageWorkspaceMembers();

    return (
        <main>
            <header className="d-flex align-items-start">
                <h3 className="settings__heading">Members</h3>

                {canManageWorkspaceMembers && (
                    <Button
                        variant="secondary"
                        className="ml-auto"
                        onClick={() => addMember()}
                    >
                        Add member
                    </Button>
                )}
            </header>

            <WorkspaceMemberList
                onConfigureMemberPermissions={(member) =>
                    configurePermissions(member)
                }
            />
        </main>
    );
};
