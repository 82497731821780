import React, { useRef } from "react";
import { format } from "date-fns";

import classNames from "classnames";
import "../dates.scss";
import "./DateRangePicker.scss";
import { CheckIcon } from "../../../../icons";
import { DatePickerInputManual } from "../DatePickerInputManual/DatePickerInputManual";
import { FormRowRaw } from "../../FormRowRaw";
import { DateRange, monthlyDateFormat } from "../../../../common/helpers/date";
import { Button } from "../../../general/Button/Button";
import { Separator } from "../../../general/Separator/Separator";
import { useDateRangePicker } from "./useDateRangePicker";
import { DateRangeCalendar } from "./DateRangeCalendar";

interface Props {
    initialValue?: Partial<DateRange>;
    onChange: (
        value: Partial<DateRange>,
        dateSetFrom: DateSetFrom | undefined,
        isAllTime?: boolean,
    ) => void;
    requireSelection?: boolean;
    visiblePresets?: string[];
    monthOnly?: boolean;
}

export type DateSetFrom = "calendar" | "preset" | "input";

export const DateRangePicker: React.FC<Props> = ({
    initialValue = {},
    onChange,
    requireSelection = false,
    visiblePresets,
    monthOnly,
}) => {
    const {
        start,
        setStart,
        end,
        setEnd,
        changeDateRange,
        onActiveStartDateChange,
        selectFromInput,
        presets,
        calendarValue,
        clearValue,
        activeStart,
        selectPreset,
        activePreset,
        maxDate,
    } = useDateRangePicker({
        initialValue,
        onChange,
        requireSelection,
        visiblePresets,
    });

    const rangeContainerRef = useRef<HTMLElement>();
    const checkIcon = <CheckIcon className="icon-color-blue-500" />;

    return (
        <div className="date-range-picker" ref={rangeContainerRef as any}>
            <section className="date-range-picker__presets">
                <span className="label">Period</span>
                {presets.map((preset) => (
                    <>
                        <Button
                            variant="tertiary"
                            key={preset.label}
                            onClick={() => selectPreset(preset)}
                            block
                            className="justify-content-start"
                        >
                            {preset.labelFn
                                ? preset.labelFn(new Date())
                                : preset.label}
                            {activePreset === preset.id && checkIcon}
                        </Button>
                        {preset.followedBySeparator && (
                            <Separator className="my-2 mx-2" />
                        )}
                    </>
                ))}
                {!requireSelection ? (
                    <Button
                        variant="tertiary"
                        onClick={clearValue}
                        className={classNames("justify-content-start", {
                            active: !start && !end,
                        })}
                        block
                    >
                        All time {!start && !end && checkIcon}
                    </Button>
                ) : null}
            </section>
            <section className="date-range-picker__dates">
                <section className="date-range-picker__exact">
                    {monthOnly ? (
                        <>
                            <FormRowRaw
                                value={
                                    start
                                        ? format(start, monthlyDateFormat)
                                        : ""
                                }
                                disabled
                                label="From"
                                size="sm"
                            />
                            <FormRowRaw
                                value={
                                    end ? format(end, monthlyDateFormat) : ""
                                }
                                disabled
                                label="To"
                                size="sm"
                            />
                        </>
                    ) : (
                        <>
                            <DatePickerInputManual
                                value={start}
                                onChange={(value) =>
                                    selectFromInput(value, setStart)
                                }
                                label="From"
                                size="sm"
                            />
                            <DatePickerInputManual
                                value={end}
                                onChange={(value) =>
                                    selectFromInput(value, setEnd, true)
                                }
                                label="To"
                                size="sm"
                            />
                        </>
                    )}
                </section>

                <DateRangeCalendar
                    changeDateRange={changeDateRange}
                    onActiveStartDateChange={onActiveStartDateChange}
                    activeStart={activeStart}
                    calendarValue={calendarValue}
                    maxDate={maxDate}
                />
            </section>
        </div>
    );
};
