import { useMutation } from "@tanstack/react-query";

import { createRampConnection, fixRampConnection } from "../lib/ramp";
import { Entity } from "../common/types/entity";
import { queryClient, rampConnectionsQueryKey } from "../queryClient";
import {
    addFinancialConnectionInQueryData,
    clearConnectionErrorInQueryData,
} from "../queries/entitiesAccounts";
import { RampConnection } from "../common/types/rampConnection";
import { useConnectAccount } from "../components/connectAccount/ConnectAccount.context";
import { FinancialConnection } from "../common/types/financialConnection";
import { useToaster } from "../components/general/ToastMessages/useToaster";

interface RampConnectionCreationPayload {
    code: string;
    defaultEntity?: Entity;
}
export function useRampConnectionCreationMutation(onSuccess?: () => void) {
    const { updateConnectAccountState } = useConnectAccount();
    const { toast } = useToaster();

    return useMutation({
        mutationFn: ({ code, defaultEntity }: RampConnectionCreationPayload) =>
            createRampConnection({ code, defaultEntityId: defaultEntity?.id }),
        onSuccess: (connection) => {
            queryClient.setQueryData(rampConnectionsQueryKey, [
                ...(queryClient.getQueryData<RampConnection[]>(
                    rampConnectionsQueryKey,
                ) ?? []),
                connection,
            ]);
            addFinancialConnectionInQueryData(queryClient, connection);
            if (!connection.accounts[0].financialAccount.entity) {
                updateConnectAccountState({
                    isConnecting: false,
                    connectionSaved: true,
                    connection,
                    foreign: [],
                });
            } else {
                toast(`Ramp account connected`);
            }
            onSuccess?.();
        },
    });
}

interface FixRampConnectionPayload {
    connection: FinancialConnection;
    code: string;
}
export function useFixRampConnectionsMutation(onSuccess?: () => unknown) {
    return useMutation({
        mutationFn: (payload: FixRampConnectionPayload) =>
            fixRampConnection(payload.connection.id, { code: payload.code }),
        onSuccess: (connected) => {
            if (!connected) {
                return;
            }
            clearConnectionErrorInQueryData(queryClient, connected.id);
            onSuccess?.();
            queryClient.setQueryData<FinancialConnection[] | undefined>(
                rampConnectionsQueryKey,
                (connections) =>
                    connections?.map((c) =>
                        c.id === connected.id
                            ? {
                                  ...c,
                                  accounts: connected.accounts,
                                  error: null,
                              }
                            : c,
                    ) ?? [],
            );
        },
    });
}
