import React from "react";
import "./TransactionActionsMessage.scss";
import { ChildrenProps } from "../../../types";

interface Props extends ChildrenProps {
    header: React.ReactNode;
}

export const TransactionActionsMessage: React.FC<Props> = ({
    header,
    children,
}) => (
    <section
        className="transaction-actions-message"
        data-testid="transaction-actions-message"
    >
        <header>{header}</header>
        {children}
    </section>
);
