import React from "react";
import { TreeHelpers, TreeNode } from "../../../general/Tree/Tree.types";
import { FormCheckbox } from "../../../forms/FormCheckbox/FormCheckbox";
import { AccountCard } from "../../../general/AccountCard/AccountCard";
import css from "../FormCheckItem.module.scss";
import { FinancialAccount } from "../../../../common/types/financialAccount";

interface AccountTreeItemProps extends TreeHelpers {
    node: TreeNode<FinancialAccount>;
    toggleSelected: (accountId: number) => void;
    isSelected: (accountId: number) => boolean;
}
export const AccountTreeItem: React.FC<AccountTreeItemProps> = ({
    node,
    toggleSelected,
    isSelected,
}) => (
    <FormCheckbox
        small
        key={node.current.id}
        value={node.current.id}
        isChecked={isSelected(node.current.id)}
        handleChange={() => toggleSelected(node.current.id)}
        className={css.item}
        label={
            <div className={css.itemLabel}>
                <AccountCard
                    account={node.current}
                    iconSize="sm"
                    showType={false}
                    onlyNumber
                    displayNameAsTooltip
                />
            </div>
        }
    />
);
