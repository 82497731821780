import { addMonths } from "date-fns";
import React from "react";
import { Nav, Tab } from "react-bootstrap";
import {
    AccountsIcon,
    CalendarCheckIcon,
    CalendarIcon,
    FileSheetIcon,
    FinancingIcon,
} from "../../../../icons";
import { AggregatedAccountsFilter } from "../../../general/AggregatedFilters/AccountFilter/AggregatedAccountsFilter";
import { AggregatedFiltersOverlay } from "../../../general/AggregatedFilters/AggregatedFiltersOverlay";
import { AggregatedDateFilter } from "../../../general/AggregatedFilters/DateFilter/AggregatedDateFilter";
import { NavigationItem } from "../../../navigation/NavigationItem";
import { AggregatedFiltersTabs, FilterProps } from "../lib";
import { FinancialAccountSourceType } from "../../../../common/types/financialAccount";
import { InvoiceAmountFilter } from "./InvoiceAmountFilter";
import { InvoiceStatusFilter } from "./InvoiceStatusFilter";

export const AggregatedInvoiceFiltersOverlay: React.FC<FilterProps> = ({
    filters,
    onChange,
}) => (
    <AggregatedFiltersOverlay
        navigation={
            <>
                <Nav.Link eventKey={AggregatedFiltersTabs.DATE}>
                    <NavigationItem icon={<CalendarIcon />} activeIndicator>
                        Date
                    </NavigationItem>
                </Nav.Link>

                <Nav.Link eventKey={AggregatedFiltersTabs.DUE}>
                    <NavigationItem icon={<CalendarCheckIcon />}>
                        Due Date
                    </NavigationItem>
                </Nav.Link>

                <Nav.Link eventKey={AggregatedFiltersTabs.STATUS}>
                    <NavigationItem icon={<FileSheetIcon />}>
                        Status
                    </NavigationItem>
                </Nav.Link>

                <Nav.Link eventKey={AggregatedFiltersTabs.ACCOUNT}>
                    <NavigationItem icon={<AccountsIcon />}>
                        Account
                    </NavigationItem>
                </Nav.Link>

                <Nav.Link eventKey={AggregatedFiltersTabs.AMOUNT}>
                    <NavigationItem icon={<FinancingIcon />} activeIndicator>
                        Amount
                    </NavigationItem>
                </Nav.Link>
            </>
        }
        content={
            <>
                <Tab.Pane eventKey={AggregatedFiltersTabs.DATE}>
                    <AggregatedDateFilter
                        filters={filters}
                        onChange={onChange}
                    />
                </Tab.Pane>
                <Tab.Pane eventKey={AggregatedFiltersTabs.DUE}>
                    <AggregatedDateFilter
                        filters={{
                            start: filters.dueStart,
                            end: filters.dueEnd,
                        }}
                        onChange={(val) => {
                            onChange({
                                dueStart: val.start,
                                dueEnd: val.end,
                            });
                        }}
                        maxDate={addMonths(new Date(), 12)}
                    />
                </Tab.Pane>
                <Tab.Pane eventKey={AggregatedFiltersTabs.STATUS}>
                    <InvoiceStatusFilter
                        filters={filters}
                        onChange={onChange}
                    />
                </Tab.Pane>
                <Tab.Pane eventKey={AggregatedFiltersTabs.AMOUNT}>
                    <InvoiceAmountFilter
                        filters={filters}
                        onChange={onChange}
                    />
                </Tab.Pane>
                <Tab.Pane eventKey={AggregatedFiltersTabs.ACCOUNT}>
                    <AggregatedAccountsFilter
                        filters={filters}
                        onChange={onChange}
                        sourceType={FinancialAccountSourceType.STRIPE}
                    />
                </Tab.Pane>
            </>
        }
    />
);
