import React, { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Command } from "../../vendor/cmdk/cmdk";
import { PlusIcon } from "../../icons";
import { useCanManageWorkspaceMembers } from "../../hooks/permissions/useCanManageWorkspaceMembers";
import { useMemberManagement } from "../memberManagement/useMemberManagement";
import { useCanCreateEntities } from "../../hooks/permissions/useCanCreateEntities";
import { useCommands } from "./useCommands";
import { CommandItem } from "./CommandItem";
import { CommandsDirectory } from "./lib";

export const AddCommandsList: FC = () => {
    const navigate = useNavigate();
    const { close: closeCommands, open: changeDirectory } = useCommands();
    const canManageMembers = useCanManageWorkspaceMembers();
    const canCreateEntities = useCanCreateEntities();
    const { addMember } = useMemberManagement();

    const onAddEntity = useCallback(() => {
        navigate("/new-entity");
        closeCommands();
    }, [closeCommands, navigate]);

    const onAddAccount = useCallback(
        () => changeDirectory(CommandsDirectory.ACCOUNTS),
        [changeDirectory],
    );

    const onAddMember = useCallback(() => {
        if (canManageMembers) {
            closeCommands();
            addMember();
        }
    }, [addMember, canManageMembers, closeCommands]);

    return (
        <Command.Group heading="Actions">
            <CommandItem
                onSelect={onAddAccount}
                keywords={["create", "new"]}
                shortcut={["A"]}
            >
                <PlusIcon /> Add account
            </CommandItem>
            {canCreateEntities && (
                <CommandItem
                    onSelect={onAddEntity}
                    keywords={["create", "new"]}
                    shortcut={["E"]}
                >
                    <PlusIcon /> Add entity
                </CommandItem>
            )}
            {canManageMembers && (
                <CommandItem
                    onSelect={onAddMember}
                    keywords={["create", "new", "accountant", "admin"]}
                    shortcut={["M"]}
                >
                    <PlusIcon /> Add member
                </CommandItem>
            )}
        </Command.Group>
    );
};
