import React, { useRef, useState } from "react";
import classNames from "classnames";
import { Form, Formik, FormikConfig } from "formik";
import { object, string } from "yup";
import { FormikProps } from "formik/dist/types";
import { ActionCard, ActionCardProps } from "../ActionCard";
import { ActionStatus } from "../../../common/types/actions";
import sectionStyles from "../ActionCardSection.module.scss";
import { CheckIcon, ShareWithUserIcon } from "../../../icons";
import { ActionCardSection } from "../ActionCardSection";
import { submitHelper } from "../../../helpers/form";
import { CampaignCardProps } from "../ActionItem";
import { FormRow } from "../../forms/FormRow";
import { ActionCardCompleted } from "../ActionCardCompleted";
import { ActionCloseButton } from "../ActionCloseButton";
import { ActionConfirmButton } from "../ActionConfirmButton";
import { useWorkspaceContext } from "../../../state/workspaceContext";
import { UserWorkspaceRole } from "../../../common/userWorkspaceRoles";
import { Button } from "../../general/Button/Button";
import { useMemberManagement } from "../../memberManagement/useMemberManagement";
import styles from "./InviteAccountant.module.scss";

interface FormSchema {
    email: string;
}

export const InviteAccountantCard: React.FC<CampaignCardProps> = ({
    action,
    isLoading,
    isCompleted,
    onConfirm,
    onSkip,
    ...rest
}) => {
    const [loading, setLoading] = useState(false);
    const formRef = useRef<FormikProps<FormSchema>>(null);
    const { activeWorkspace } = useWorkspaceContext();
    const { addMember } = useMemberManagement();

    if (!activeWorkspace) {
        return null;
    }

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            email: "",
        },
        validationSchema: object().shape({
            email: string()
                .required("Provide an email")
                .email()
                .label("E-mail"),
        }),
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async ({ email }) => {
                addMember({
                    email,
                    role: UserWorkspaceRole.ACCOUNTANT,
                    onInvited: () => {
                        onConfirm?.(action);
                    },
                });
            },
        }),
    };

    const firstSection: ActionCardProps["firstSection"] = (
        inOpened,
        closeCard,
    ) => {
        if (isCompleted) {
            return (
                <ActionCardCompleted header="Accountant invited">
                    <ActionCloseButton onClick={closeCard} />
                </ActionCardCompleted>
            );
        }

        return (
            <ActionCardSection
                inOpened={inOpened}
                header="Invite your accountant"
                icon={<ShareWithUserIcon />}
                isTitle
                ctas={
                    <>
                        {action.status !== ActionStatus.SKIPPED && (
                            <Button
                                variant="secondary"
                                onClick={() => onSkip?.(action)}
                            >
                                Skip
                            </Button>
                        )}
                        <ActionConfirmButton
                            loading={!!isLoading || loading}
                            onClick={() => formRef.current?.submitForm()}
                        >
                            Invite
                        </ActionConfirmButton>
                    </>
                }
            />
        );
    };

    const otherSections: ActionCardProps["otherSections"] = (inOpened) => (
        <ActionCardSection
            header="Add your Accountant’s email address"
            inOpened={inOpened}
        >
            <Formik {...form} innerRef={formRef}>
                <Form className={styles.form}>
                    <FormRow
                        fieldName="email"
                        placeholder="Email address"
                        size="xs"
                    />
                </Form>
            </Formik>

            <header
                className={classNames(sectionStyles.header, "mb-3", "mt-4")}
            >
                Inviting your Accountant
            </header>

            <ul className="list-unstyled">
                <li className="mb-2">
                    <CheckIcon className="icon-color-blue-600 icon-size-sm mr-2" />
                    Reduces back-and-forth email
                </li>
                <li className="mb-2">
                    <CheckIcon className="icon-color-blue-600 icon-size-sm mr-2" />
                    Speeds up tax reporting preparation
                </li>
            </ul>
        </ActionCardSection>
    );

    return (
        <ActionCard
            {...rest}
            firstSection={firstSection}
            otherSections={otherSections}
            isCompleted={isCompleted}
        />
    );
};
