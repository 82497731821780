import React, { useContext } from "react";
import { NoMatchingTransactions } from "./NoMatchingTransactions";
import { NoTransactions } from "./NoTransactions";
import { PaginatedTransactionsTableContext } from "./PaginatedTransactionsTableContextProvider";
import "./TransactionsPage.scss";
import { useFiltersActive } from "./useFiltersActive";

export const TransactionsPageEmptyState: React.FC = () => {
    const { filters: currentFilters, setCurrentFilters } = useContext(
        PaginatedTransactionsTableContext,
    );
    const filtersActive = useFiltersActive(currentFilters);

    if (filtersActive) {
        return (
            <NoMatchingTransactions
                onResetFilters={() => setCurrentFilters({})}
            />
        );
    }

    return <NoTransactions />;
};
