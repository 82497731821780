import { useFlags } from "launchdarkly-react-client-sdk";
import { camelCase } from "lodash";
import { useMemo } from "react";
import { FeatureFlags } from "../lib/launchdarkly";

const camelizeKeys = (obj: any): FeatureFlags => {
    const result: FeatureFlags = Object.create(null);

    for (const [key, value] of Object.entries(obj)) {
        result[camelCase(key) as keyof FeatureFlags] = value as boolean;
    }

    return result;
};

export function useTypedFlags() {
    const launchDarklyFlags = useFlags<FeatureFlags>();
    const staticFlags = useMemo(
        () =>
            import.meta.env.VITE_LAUNCHDARKLY_FLAGS
                ? JSON.parse(import.meta.env.VITE_LAUNCHDARKLY_FLAGS)
                : null,
        [],
    );

    const overrideRawValue = localStorage.getItem("overrideFlags") ?? "{}";
    const override = useMemo(
        () => camelizeKeys(JSON.parse(overrideRawValue)),
        [overrideRawValue],
    );

    const transformedStaticFlags = useMemo(
        () => (staticFlags ? camelizeKeys(staticFlags.flagValues) : null),
        [staticFlags],
    );

    return useMemo(
        () => ({
            ...(transformedStaticFlags ?? launchDarklyFlags),
            ...override,
        }),
        [launchDarklyFlags, transformedStaticFlags, override],
    );
}
