import { useMemo } from "react";
import { Dictionary } from "lodash";
import {
    getApplicableQuestions,
    TaxQuestion,
    taxQuestions,
} from "../../../common/taxSavingRules";
import { TransactionsFilters } from "../filters/lib";
import { TaxAnswers } from "../TaxQuestions/types";
import { Category } from "../../../common/types/category";
import { useCategoriesById } from "../../../hooks/useCategoriesById";
import { useDefaultYear } from "../../../hooks/useDefaultYear";
import { SelectedTransaction } from "./transactionsBulkActionsContext";
import { getTaxSavingRuleForSelection } from "./useTaxSavingRulesForBulkActions";

interface GetQuestionsForSelectionOptions {
    selection: Record<number, SelectedTransaction>;
    answers: TaxAnswers;
    categoriesById: Record<string, Category>;
    defaultYear: number;
}

interface GetQuestionsForFiltersParams {
    filters: TransactionsFilters;
    answers: TaxAnswers;
    defaultYear: number;
    categoriesById: Dictionary<Category>;
}

export function useTaxQuestionsForBulkActions(
    selection: Record<number, SelectedTransaction>,
    filters: TransactionsFilters | null,
    answers: TaxAnswers,
): TaxQuestion[] {
    const categoriesById = useCategoriesById();
    const defaultYear = useDefaultYear();

    return useMemo(() => {
        if (filters) {
            return getQuestionsForFilters({
                filters,
                answers,
                defaultYear,
                categoriesById,
            });
        } else {
            return getQuestionsForSelection({
                selection,
                answers,
                categoriesById,
                defaultYear,
            });
        }
    }, [answers, filters, selection, categoriesById, defaultYear]);
}

function getQuestionsForFilters({
    filters,
    answers,
    defaultYear,
    categoriesById,
}: GetQuestionsForFiltersParams) {
    if (filters.category?.length !== 1) {
        return [];
    }

    const selectedCategoryId = filters.category[0];
    const questionsForCategory =
        taxQuestions[defaultYear][categoriesById[selectedCategoryId].taxonomy];

    if (!questionsForCategory) {
        return [];
    }

    return getApplicableQuestions(questionsForCategory, answers);
}

function getQuestionsForSelection({
    selection,
    answers,
    categoriesById,
    defaultYear,
}: GetQuestionsForSelectionOptions) {
    const rules = getTaxSavingRuleForSelection(
        selection,
        categoriesById,
        defaultYear,
    );

    if (!rules?.questions) {
        return [];
    }

    return getApplicableQuestions(rules.questions, answers);
}
