import React from "react";
import { AccountStatus } from "../../../../../common/types/domains/accounting/account";
import { FormCheckbox } from "../../../../forms/FormCheckbox/FormCheckbox";
import { BaseFilterProps } from "../../lib";
import styles from "./AccountStatusFilter.module.scss";

export interface AccountEnabledRadioDef {
    id: string;
    label: React.ReactNode;
    enabledValue?: AccountStatus;
}

const accountEnabledRadios: AccountEnabledRadioDef[] = [
    {
        id: "filter-form-account-status-all",
        label: "All",
        enabledValue: undefined,
    },
    {
        id: "filter-form-account-status-active",
        label: "Active",
        enabledValue: AccountStatus.ENABLED,
    },
    {
        id: "filter-form-account-status-disabled",
        label: "Disabled",
        enabledValue: AccountStatus.DISABLED,
    },
];

export const AccountStatusFilter: React.FC<BaseFilterProps> = ({
    filters,
    onChange,
}) => (
    <div className="transactions-filter-form__amount amount-filter">
        <section className={styles.accountStatusFilter}>
            <header className={styles.accountStatusFilterHeader}>
                Account Status
            </header>

            {accountEnabledRadios.map(({ id, label, enabledValue }) => (
                <FormCheckbox
                    key={id}
                    value={enabledValue ?? ""}
                    type="radio"
                    name="accountEnabled"
                    label={label}
                    isChecked={filters.accountStatus === enabledValue}
                    handleChange={() =>
                        onChange({ accountStatus: enabledValue })
                    }
                    small
                />
            ))}
        </section>
    </div>
);
