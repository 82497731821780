import { DiscountType, InvoiceStatus, SubscriptionStatus } from "../billing";
import { FLAT_RATE_PLANS, FlatRatePlan } from "../flatRateBilling";
import { Address } from "./embeddable/address";

export interface CardDetails {
    brand: string;
    last4: string;
}

export type BillingInterval = "day" | "month" | "week" | "year"; // from stripe, but we only use month and year
export type DiscountDuration = "forever" | "once" | "repeating";

export interface SubscriptionDiscount {
    name: string | null;
    duration: DiscountDuration;
    durationInMonths: number | null;
    code: string | null;
    amountOff: number | null;
    percentOff: number | null;
}

export interface SubscriptionPricingData {
    price: number;
    monthlyPriceCents: number;
    interval: BillingInterval;
    intervalCount: number;
    intervalAmountCents: number;
    discountedIntervalAmountCents: number;
    discountedMonthlyAmountCents: number;
    discount: SubscriptionDiscount | null;
}

export interface SubscriptionDetails extends SubscriptionPricingData {
    type: SubscriptionType;
    status: SubscriptionStatus;
    renewsOn: Date | null;
    plan: FLAT_RATE_PLANS;
    cancelsAt: Date | null;
    cancelOnTrialEnd: boolean;
    periodEndsAt: Date | null; // either subscription is renewed or canceled at this date
    nextPeriodPlan: FLAT_RATE_PLANS; // FREE if cancelled (or not subscribed)
}

export interface BillingStatus {
    totalUsage: number;
    subscriptionDetails: SubscriptionDetails | null;
    trialAvailable: boolean;
    plans: FlatRatePlan[];
}

export interface AppliedDiscount {
    type: DiscountType;
    amount: number;
    value: number;
}

export enum SubscriptionType {
    CUSTOM = "CUSTOM",
    MONTHLY = "MONTHLY",
    FLAT_RATE = "FLAT_RATE",
}

export interface BillingInfo {
    paymentMethod: CardDetails | null;
    name: string | null;
    email: string | null;
    address: Address | null;
}

export interface Invoice {
    status: InvoiceStatus;
    date: Date;
    url: string | null;
    totalInCents: number;
}

export interface BillingHistory {
    invoices: Invoice[];
}
