import React from "react";
import classNames from "classnames";
import { ChildrenProps } from "../../../types";
import styles from "./HorizontalLine.module.scss";

interface HorizontalLineProps extends ChildrenProps {
    className?: string;
}

export const HorizontalLine: React.FC<HorizontalLineProps> = ({
    children,
    className,
}) => <div className={classNames(styles.line, className)}>{children}</div>;
