import React, { forwardRef } from "react";
import classNames from "classnames";

import styles from "./EditableTable.module.scss";

export interface EditableTableCellSelectionState {
    top: boolean;
    bottom: boolean;
    left: boolean;
    right: boolean;
    isInBuffer: boolean;
}

export interface EditableTableCellProps {
    id?: string;
    isFirstRow?: boolean;
    selectionState?: EditableTableCellSelectionState;
    error?: string;
    children?: React.ReactNode;
    className?: string;
    onClick?: () => void;
    draggable?: boolean;
}

const BORDER_STYLE = "1px solid #000";
const BORDER_STYLE_DASHED = "1px dashed var(--Gray-900, #0F1826)";

export function calculateCellBorders(
    selectionState: EditableTableCellSelectionState | undefined,
) {
    const borderStyle = selectionState?.isInBuffer
        ? BORDER_STYLE_DASHED
        : BORDER_STYLE;

    return {
        borderTop: selectionState?.top ? borderStyle : undefined,
        borderBottom: selectionState?.bottom ? borderStyle : undefined,
        borderLeft: selectionState?.left ? borderStyle : undefined,
        borderRight: selectionState?.right ? borderStyle : undefined,
    };
}

export const EditableTableCell = React.memo(
    forwardRef(
        (
            {
                id,
                className,
                isFirstRow,
                selectionState,
                children,
                error,
                onClick,
                draggable,
            }: EditableTableCellProps,
            ref: React.Ref<HTMLDivElement>,
        ) => (
            <div
                data-test={id}
                key={id}
                ref={ref}
                onClick={onClick}
                className={classNames(
                    styles.editableTableCell,
                    isFirstRow && styles.editableTableCellNoTopBorder,
                    selectionState && styles.editableTableCellSelected,
                    error && styles.editableTableCellError,
                    className,
                )}
                style={{
                    cursor: draggable ? "grab" : undefined,
                }}
            >
                {children}
            </div>
        ),
    ),
);
