import React from "react";
import classNames from "classnames";
import animations from "../layout/OnboardingLayout.module.scss";
import { Button } from "../../general/Button/Button";
import commonStyles from "./common.module.scss";

interface Props {
    onBack(): void;
}

export const AccountantWaitlistOutro: React.FC<Props> = () => (
    <div className={classNames(commonStyles.content420)}>
        <header className={classNames(animations.fadeIn2, "text-center")}>
            <h1 className={commonStyles.title}>We got it!</h1>

            <p className={commonStyles.subtitle}>
                We’ll reach out when we’re ready for you. Follow us on X to stay
                in the loop on how we're progressing.
            </p>
        </header>

        <footer className="text-center mb-3">
            <Button
                variant="secondary"
                asChild
                block
                className={animations.fadeIn1}
            >
                <a href="https://x.com/kickfinance" target="_blank">
                    Follow us on X
                </a>
            </Button>
        </footer>
    </div>
);
