import { initContract } from "@ts-rest/core";
import { z } from "zod";
import { FinancialAccountDto } from "../dto/financialAccount/financial-account.dto";

const c = initContract();

export const financialAccountContract = c.router(
    {
        extractMergedAccount: {
            method: "POST",
            path: "/extract-merged-account",
            body: z.object({
                financialAccountId: z.number(),
                entityId: z.number(),
                integrationAccountToExtractId: z.string().uuid(),
            }),
            responses: {
                201: c.type<{
                    extractedFinancialAccount: FinancialAccountDto;
                }>(),
            },
        },
    },
    {
        pathPrefix: "/financial-account",
    },
);
