import classNames from "classnames";
import React, { MouseEventHandler, useCallback } from "react";
import { Button } from "../../general/Button/Button";
import { bem } from "../../../bem";
import {
    CashFlowColumn,
    CashFlowReportRowPath,
    CashFlowTableRow,
} from "../../../common/dto/reports/cash-flow-tab-report-response.dto";
import {
    CASH_FLOW_PERCENT_DIFF_SUFFIX,
    CASH_FLOW_VALUE_DIFF_SUFFIX,
} from "../../../common/dto/reports/reports-common.dto";
import { noop } from "../../../helpers/general";
import { useCashFlowDrillDownContext } from "../CashFlowDrillDown.context";
import "./CashFlowReportCell.scss";
import { CashFlowSectionId } from "../../../common/constants/reports/cashflow.constant";
import { Dollar2Icon, PercentageIcon } from "../../../icons";

interface RenderCellProps {
    column: CashFlowColumn;
    row: CashFlowTableRow;
    isExpanded?: boolean;
    isTitle?: boolean;
    setIsExpanded?: (value: boolean) => void;
    toggleRow?: (id: string) => void;
    rowVisibility?: Record<string, boolean>;
    path: CashFlowReportRowPath[];
    stickyRightOffset?: number;
}

const block = bem("data-table");

const NON_EXPANDABLE_ROWS = [
    CashFlowSectionId.cashInRefunds,
    CashFlowSectionId.cashOutRefunds,
];
export const CashFlowReportCell: React.FC<RenderCellProps> = ({
    row,
    column,
    isTitle = false,
    isExpanded = false,
    setIsExpanded,
    toggleRow = noop,
    rowVisibility = {},
    path,
    stickyRightOffset,
}) => {
    const expandToggle = useCallback<
        MouseEventHandler<HTMLTableCellElement>
    >(() => {
        setIsExpanded?.(!isExpanded);
    }, [isExpanded, setIsExpanded]);

    const rowCanExpand =
        row.children?.length &&
        !NON_EXPANDABLE_ROWS.includes(row.id as CashFlowSectionId);

    const handlePercentClick = useCallback<
        MouseEventHandler<HTMLButtonElement>
    >(
        (event) => {
            event.stopPropagation();
            toggleRow(`${row.id}${CASH_FLOW_PERCENT_DIFF_SUFFIX}`);
        },
        [row.id, toggleRow],
    );

    const handleDiffClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (event) => {
            event.stopPropagation();
            toggleRow(`${row.id}${CASH_FLOW_VALUE_DIFF_SUFFIX}`);
        },
        [row.id, toggleRow],
    );

    const { open: openTransactionsModal } = useCashFlowDrillDownContext();

    if (isTitle) {
        const onCellClick: MouseEventHandler<HTMLTableCellElement> = (
            event,
        ) => {
            if (rowCanExpand) {
                expandToggle(event);
            }
        };

        return (
            <td
                className={classNames(
                    block("cell", {
                        title: true,
                        expandable: rowCanExpand,
                        summary: row.isSummary,
                    }),
                )}
                onClick={onCellClick}
            >
                <div>
                    <span
                        className={classNames({
                            "data-table__cell--expandable": rowCanExpand,
                            "data-table__cell--expanded": isExpanded,
                        })}
                    >
                        {row.data?.[column.id] ?? <>&nbsp;</>}
                    </span>

                    <span className="cash-flow-cell-addon">
                        <Button
                            variant="tertiary"
                            size="sm"
                            icon
                            onClick={handlePercentClick}
                            hidden={
                                !(
                                    `${row.id}${CASH_FLOW_PERCENT_DIFF_SUFFIX}` in
                                    rowVisibility
                                )
                            }
                        >
                            <PercentageIcon />
                        </Button>
                        <Button
                            variant="tertiary"
                            size="sm"
                            icon
                            onClick={handleDiffClick}
                            hidden={
                                !(
                                    `${row.id}${CASH_FLOW_VALUE_DIFF_SUFFIX}` in
                                    rowVisibility
                                )
                            }
                        >
                            <Dollar2Icon />
                        </Button>
                    </span>
                </div>
            </td>
        );
    }

    const onCellClick: MouseEventHandler<HTMLTableCellElement> = () => {
        openTransactionsModal({
            category: row.filters?.category,
            counterpartyId: row.filters?.counterpartyId,
            path,
            columnId: column.id,
        });
    };

    const cellValue = row.data?.[column.id];
    const sentiment = row.options?.[column.id]?.sentiment;

    const hasValue = cellValue !== undefined;

    const isParentRow = path.length === 1;

    const isRowExpandableAndExpanded = rowCanExpand && isExpanded;
    const isRowExpandableAndNotExpanded = rowCanExpand && !isExpanded;

    const isPercentageColumnOfNonExpandedRow =
        column.id === "percent" &&
        rowCanExpand &&
        isParentRow &&
        isRowExpandableAndNotExpanded;

    const shouldShowEmptyValue =
        isPercentageColumnOfNonExpandedRow ||
        !hasValue ||
        isRowExpandableAndExpanded;

    const isComparisonRow = row.isComparison;

    const emptyValue =
        (!rowCanExpand && !hasValue) ||
        isPercentageColumnOfNonExpandedRow ||
        isComparisonRow ? (
            <>-</>
        ) : null;

    const isClickable = column.id !== "percent" && !shouldShowEmptyValue;

    return (
        <td
            className={block("cell", {
                positive: sentiment === "positive",
                negative: isComparisonRow && sentiment !== "positive",
                clickable: isClickable,
                summary: row.isSummary,
            })}
            style={{
                ...(stickyRightOffset && { right: stickyRightOffset }),
                ...(column.id === "percent" && {
                    paddingRight: "0",
                    paddingLeft: "0.75rem",
                }),
            }}
            onClick={isClickable ? onCellClick : undefined}
        >
            {shouldShowEmptyValue ? emptyValue : cellValue}
        </td>
    );
};
