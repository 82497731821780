import {
    RuleConditionType,
    TransactionRuleCondition,
} from "../../types/transactionRule";

export class TransactionRuleConditionDto {
    id: string;
    conditionType: RuleConditionType;
    value: TransactionRuleCondition["value"];

    constructor(condition: TransactionRuleCondition) {
        this.id = condition.id;
        this.conditionType = condition.conditionType;
        this.value = condition.value;
    }

    static deserialize(
        dto: TransactionRuleConditionDto,
    ): TransactionRuleCondition {
        return {
            id: dto.id,
            conditionType: dto.conditionType,
            value: dto.value,
        };
    }
}
