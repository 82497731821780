import { UpdateTransactionDto } from "../dto/transactions/update-transaction.dto";
import { Entity } from "./entity";
import { Transaction } from "./transaction";
import { User } from "./user";

export enum ActionCampaign {
    PERSONAL_TO_BUSINESS = "personal_to_business",
    IRS_REQUIREMENTS = "irs_requirements",

    // Personalize/Onboarding Actions
    WELCOME_VIDEO = "welcome_video",
    CONFIRM_TRANSACTIONS = "confirm_transactions",
    SETUP_RECEIPT_MATCHING = "setup_receipt_matching",
    INVITE_ACCOUNTANT = "invite_accountant",
    BOOK_ONBOARDING_CALL = "book_onboarding_call",
}

export enum FakeActionCampaign {
    CATEGORIZED_TRANSACTIONS = "categorized_transactions",
    MATCHED_RECEIPTS = "matched_receipts",
    CONNECTED_ACCOUNTS = "connected_accounts",
    CREATED_JOURNAL_ENTRIES = "created_journal_entries",
}

export enum ActionCategory {
    PERSONALIZE = "personalize",
    SAVINGS = "savings",
    TAX = "tax",
}

export enum ActionStatus {
    CONFIRMED = "confirmed",
    SKIPPED = "skipped",
    PENDING = "pending",
}

export interface Action<TMeta extends object = object> {
    id: string;
    deduplicationKey: string;
    campaign: ActionCampaign;
    createdAt: Date;
    updatedAt: Date;
    entity: Entity;
    status: ActionStatus;
    transaction?: Transaction;
    meta: TMeta;
}

export interface PersonalToBusinessAction
    extends Action<{ transactionUpdate: UpdateTransactionDto }> {
    campaign: ActionCampaign.PERSONAL_TO_BUSINESS;
}

export interface ActionsSummary {
    total: number;
    pending: number;
    skipped: number;
    categories: Record<
        ActionCategory,
        {
            total: number;
            pending: number;
            skipped: number;
        }
    >;
}

export const DEFAULT_ACTIONS_SUMMARY_STATS = {
    total: 0,
    pending: 0,
    skipped: 0,
};

export const DEFAULT_ACTIONS_SUMMARY: ActionsSummary = {
    ...DEFAULT_ACTIONS_SUMMARY_STATS,
    categories: Object.values(ActionCategory).reduce(
        (acc, category) => {
            acc[category] = DEFAULT_ACTIONS_SUMMARY_STATS;
            return acc;
        },
        {} as Record<ActionCategory, typeof DEFAULT_ACTIONS_SUMMARY_STATS>,
    ),
};

export interface ActionsActivity {
    [period: string]: ActionActivityItem[];
}

export interface ActionActivityItem {
    campaign: ActionCampaign | FakeActionCampaign;
    count: number;
    amountSaved: number;
    description?: string;
    user?: User;
}

export const ACTION_CATEGORY_CAMPAIGN_MAP: Record<
    ActionCategory,
    ActionCampaign[]
> = {
    [ActionCategory.SAVINGS]: [ActionCampaign.PERSONAL_TO_BUSINESS],
    [ActionCategory.TAX]: [ActionCampaign.IRS_REQUIREMENTS],
    [ActionCategory.PERSONALIZE]: [
        ActionCampaign.WELCOME_VIDEO,
        ActionCampaign.CONFIRM_TRANSACTIONS,
        ActionCampaign.SETUP_RECEIPT_MATCHING,
        ActionCampaign.INVITE_ACCOUNTANT,
        ActionCampaign.BOOK_ONBOARDING_CALL,
    ],
};
