import { times } from "lodash";
import {
    Invoice,
    InvoiceSource,
    InvoiceStats,
    InvoiceStatus,
} from "../common/types/invoice";
import { TagVariant } from "../components/general/Tag/Tag";
import { Counterparty } from "../common/types/counterparty";
import { FinancialAccount } from "../common/types/financialAccount";

const STRIPE_INVOICES_BASE_URL = "https://dashboard.stripe.com/invoices";

export const mockInvoiceStats: InvoiceStats = {
    open: {
        count: 10,
        total: 1000,
    },
    overdue: {
        count: 10,
        total: 1000,
    },
    paid: {
        count: 10,
        total: 1000,
    },
};

export function getInvoiceStatusVariant(status: InvoiceStatus): TagVariant {
    switch (status) {
        case InvoiceStatus.DRAFT:
            return "secondary";
        case InvoiceStatus.OPEN:
            return "success";
        case InvoiceStatus.PAID:
            return "primary";
        case InvoiceStatus.VOID:
            return "warning";
        case InvoiceStatus.UNCOLLECTABLE:
            return "danger";
    }
}

export function getStripeAccountIdForInvoice(invoice: Invoice) {
    const account = invoice.financialAccount;
    if (!account) {
        return undefined;
    }
    const integrationAccount = account.integrationAccounts?.[0];
    if (!integrationAccount) {
        return undefined;
    }

    return integrationAccount.sourceAccountId;
}

export function getStripeDashboardUrlForInvoice(invoice: Invoice) {
    const accountId = getStripeAccountIdForInvoice(invoice);
    if (!accountId) {
        return undefined;
    }
    return `${STRIPE_INVOICES_BASE_URL}/${invoice.sourceId}?account=${accountId}`;
}

export function getStripeAccountIdForFinancialAccount(
    account: FinancialAccount,
) {
    const integrationAccount = account.integrationAccounts?.[0];
    if (!integrationAccount) {
        return undefined;
    }

    return integrationAccount.sourceAccountId;
}

export function getStripeDashboardUrlForFinancialAccount(
    account: FinancialAccount,
) {
    const accountId = getStripeAccountIdForFinancialAccount(account);
    if (!accountId) {
        return undefined;
    }
    return `${STRIPE_INVOICES_BASE_URL}/create?account=${accountId}`;
}

export function generateMockInvoices(num: number): Invoice[] {
    const statusValues = Object.values(InvoiceStatus);
    return times(num, () => ({
        id: `inv_${num}`,
        createdAt: new Date(),
        updatedAt: new Date(),
        paidAt: new Date(),
        due: new Date(),
        status: statusValues[Math.floor(Math.random() * statusValues.length)],
        counterparty: {
            name: "John Doe",
            email: "john@doe.com",
        } as Counterparty,
        total: 1000,
        subtotal: 1000,
        tax: 0,
        discount: 0,
        currency: "USD",
        description: `Invoice ${num}`,
        source: InvoiceSource.MANUAL,
        sourceId: `inv_${num}`,
        financialAccountId: 0,
    }));
}
