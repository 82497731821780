import React from "react";
import { Route, Routes } from "react-router-dom";
import { GOOGLE_AUTH_REDIRECT_PATH } from "../common/constants";
import { usePageTracking } from "../hooks/usePageTracking";
import { Login } from "./auth/Login";
import { SimulationsDashboard } from "./simulations/SimulationsDashboard";
import { UnauthenticatedRoute } from "./UnauthenticatedRoute";
import { GoogleAuth } from "./auth/GoogleAuth/GoogleAuth";
import { Onboarding } from "./onboarding/Onboarding";

export const PublicInterface: React.FC = () => {
    usePageTracking();

    return (
        <Routes>
            <Route path="/" element={<Login />} />

            <Route path="/register" element={<Onboarding />}>
                <Route path=":inviteCode" element={<Onboarding />} />
            </Route>

            <Route
                path="/register-invite/:inviteCode"
                element={<Onboarding />}
            />

            <Route path="/simulations" element={<SimulationsDashboard />} />

            <Route path={GOOGLE_AUTH_REDIRECT_PATH} element={<GoogleAuth />} />

            <Route path="*" element={<UnauthenticatedRoute />} />
        </Routes>
    );
};
