import React, { useMemo } from "react";
import {
    BooleanParam,
    JsonParam,
    NumberParam,
    useQueryParam,
    useQueryParams,
    withDefault,
} from "use-query-params";
import { useEntities } from "../../hooks/useEntities";
import { TransactionsFilters } from "./filters/lib";
import { PaginatedTransactionsTableContextProvider } from "./PaginatedTransactionsTableContextProvider";
import { TransactionsPage } from "./TransactionsPage";

export const TransactionsPageContainer: React.FC = () => {
    const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 1), {
        removeDefaultsFromUrl: true,
    });

    const [
        {
            actionsRequired: requiredActionsOnly,
            filters: filtersParam,
            entityId: initialEntityId,
        },
    ] = useQueryParams({
        actionsRequired: withDefault(BooleanParam, undefined),
        filters: JsonParam,
        entityId: NumberParam,
    });

    const entities = useEntities();
    const parentFilters = useMemo<TransactionsFilters>(() => {
        const initialFilters: TransactionsFilters = {};

        if (requiredActionsOnly) {
            initialFilters.requiredAction = requiredActionsOnly;
        }

        if (initialEntityId) {
            const initialEntity = entities.find(
                (entity) => entity.id === initialEntityId,
            );
            if (initialEntity) {
                initialFilters.entityIds = [initialEntity.id];
            }
        }

        if (filtersParam) {
            Object.assign(initialFilters, filtersParam);

            if (filtersParam.start) {
                initialFilters.start = new Date(filtersParam.start);
            }

            if (filtersParam.end) {
                initialFilters.end = new Date(filtersParam.end);
            }
        }

        return initialFilters;
    }, [entities, filtersParam, initialEntityId, requiredActionsOnly]);

    return (
        <PaginatedTransactionsTableContextProvider
            parentFilters={parentFilters}
            page={page}
            setPage={setPage}
        >
            <TransactionsPage />
        </PaginatedTransactionsTableContextProvider>
    );
};
