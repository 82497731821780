import { useMemo } from "react";
import { keyBy } from "lodash";
import { useCategoriesTree } from "./useCategoriesTree";

export function useCategoriesById() {
    const categories = useCategoriesTree();
    return useMemo(
        () =>
            keyBy(
                categories.map((c) => [c, ...(c.subcategories ?? [])]).flat(),
                (c) => c.id,
            ),
        [categories],
    );
}
