import React from "react";
import classNames from "classnames";
import { FormRowRaw } from "../FormRowRaw";
import { BottomArrowIcon } from "../../../icons";
import { InputSize } from "../../../helpers/form";

export interface CustomSelectDefaultTriggerProps {
    value?: React.ReactNode;
    label?: string;
    insideLabel?: boolean;
    size?: InputSize;
    disabled?: boolean;
    className?: string;
}

export const CustomSelectDefaultTrigger: React.FC<
    CustomSelectDefaultTriggerProps
> = ({ value, label, insideLabel, size, disabled, className }) => (
    <div className={classNames("custom-select-default-trigger", className)}>
        <FormRowRaw
            value={value}
            label={label}
            insideLabel={insideLabel}
            disabled={disabled}
            field={
                <div
                    tabIndex={0}
                    data-testid={`${typeof label === "string" ? label.toLowerCase() : ""}`}
                    className={classNames(
                        [
                            "form-control",
                            size ? `form-control-${size}` : undefined,
                        ],
                        {
                            "form-control-disabled": disabled,
                        },
                    )}
                >
                    <span className="custom-select-default-trigger__value">
                        {value}
                    </span>
                </div>
            }
            icon={!disabled && <BottomArrowIcon />}
            iconPosition="right"
        />
    </div>
);
