import React from "react";
import { pluralize } from "../../../../../common/helpers/string";

import { useCategoriesById } from "../../../hooks/useCategoriesById";
import { FiltersTypes } from "../../../common/types/filters/general";

interface CategoryFilterLabelProps {
    filters: Pick<FiltersTypes, "category">;
}

export const CategoryFilterLabel: React.FC<CategoryFilterLabelProps> = ({
    filters: { category },
}) => {
    const categoriesById = useCategoriesById();

    if (category?.length === 1) {
        return <>{categoriesById[category[0]]?.label}</>;
    }

    return (
        <>
            {category?.length} {pluralize("Category", category?.length ?? 0)}
        </>
    );
};
