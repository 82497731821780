import React from "react";
import { IntegrationAccount } from "../../../../common/types/integrationAccount";
import { GridTableRow } from "../../../general/Tables/GridTable/GridTable";
import { FinancialConnectionProviderLabel } from "../../../../common/constants";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import { dateTimeFormatter } from "../../../../common/helpers/date";
import { useExtractMergedAccountMutation } from "../../../../api/financialAccount.api";
import { FinancialAccount } from "../../../../common/types/financialAccount";
import { useToaster } from "../../../general/ToastMessages/useToaster";
import styles from "./MergedAccountsDetails.module.scss";

interface Props {
    integrationAccount: IntegrationAccount;
    financialAccount: FinancialAccount;
    extractingOtherAccount: boolean;
    onExtractStarted: () => void;
    onExtractFinished: () => void;
}

export const MergedAccountDetails: React.FC<Props> = ({
    integrationAccount,
    financialAccount,
    extractingOtherAccount,
    onExtractStarted,
    onExtractFinished,
}) => {
    const connectedAt = integrationAccount.createdAt
        ? ` at ${dateTimeFormatter.format(integrationAccount.createdAt)}`
        : "";
    const connectedThrough = integrationAccount.connection?.connectionProvider
        ? ` through ${FinancialConnectionProviderLabel[integrationAccount.connection?.connectionProvider]}`
        : "";

    const extractMutation = useExtractMergedAccountMutation();
    const { toast } = useToaster();

    const extract = async () => {
        if (!financialAccount.entityId) {
            return;
        }

        onExtractStarted();
        await extractMutation.mutateAsync({
            integrationAccountToExtractId: integrationAccount.id,
            financialAccountId: financialAccount.id,
            entityId: financialAccount.entityId,
        });
        onExtractFinished();

        toast("Account extracted successfully");
    };

    return (
        <GridTableRow className={styles.row}>
            <div>
                Connected{connectedAt}
                {connectedThrough}
            </div>
            <div>
                <ButtonWithLoader
                    loading={extractMutation.isPending}
                    disabled={
                        extractingOtherAccount && !extractMutation.isPending
                    }
                    onClick={extract}
                    variant="tertiary"
                    size="sm"
                >
                    Extract
                </ButtonWithLoader>
            </div>
        </GridTableRow>
    );
};
