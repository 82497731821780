import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CampaignActivityProps } from "../../ActionActivityItem";
import { ActionActivity } from "../../ActionActivity";
import { DocumentIcon } from "../../../../icons";
import { pluralize } from "../../../../common/helpers/string";
import { UserAvatar } from "../../../user/UserAvatar";

export const CreatedJournalEntriesActivity: React.FC<CampaignActivityProps> = ({
    animated,
    count,
    user,
}) => (
    <ActionActivity
        icon={
            user ? (
                <OverlayTrigger
                    overlay={<Tooltip id="make_it_unique">{user.name}</Tooltip>}
                >
                    <div>
                        <UserAvatar user={user} size="sm" square />
                    </div>
                </OverlayTrigger>
            ) : (
                <DocumentIcon />
            )
        }
        animated={animated}
    >
        Created {count} {pluralize("journal entry", count)}
    </ActionActivity>
);
