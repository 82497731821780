import { useMutation, useQuery } from "@tanstack/react-query";
import { ServerInferRequest } from "@ts-rest/core";
import { getBackendAPIClient } from "../lib/backendAPIClient";
import { workspaceMemberContract } from "../common/contracts/workspace-member.contract";
import { useWorkspaceContext } from "../state/workspaceContext";
import { WorkspaceMemberDto } from "../common/dto/workspace/workspace-member.dto";
import { Workspace, WorkspaceMember } from "../common/types/workspace";
import { queryClient } from "../queryClient";
import { WorkspaceInviteDto } from "../common/dto/workspace/workspace-invite.dto";
import { InviteCode } from "../common/types/inviteCode";

export const workspaceMemberContractClient = getBackendAPIClient(
    workspaceMemberContract,
);

export const workspaceMemberQueryKeys = {
    all: () => ["workspace-member"],
    workspace: (workspaceId: Workspace["id"]) => [
        ...workspaceMemberQueryKeys.all(),
        workspaceId,
    ],
    invites: (workspaceId: Workspace["id"]) => [
        ...workspaceMemberQueryKeys.workspace(workspaceId),
        "invites",
    ],
} as const;

export function useCurrentWorkspaceMembers() {
    const { activeWorkspace } = useWorkspaceContext();

    return useQuery({
        queryKey: activeWorkspace
            ? workspaceMemberQueryKeys.workspace(activeWorkspace.id)
            : [],
        queryFn: async () => {
            if (!activeWorkspace?.id) {
                return [];
            }

            const result = await workspaceMemberContractClient.getMembers({
                params: {
                    workspaceId: activeWorkspace.id,
                },
            });

            return result.body.map(WorkspaceMemberDto.deserialize);
        },
        enabled: !!activeWorkspace?.id,
    });
}

export function usePendingWorkspaceInvites() {
    const { activeWorkspace } = useWorkspaceContext();

    return useQuery({
        queryKey: activeWorkspace
            ? workspaceMemberQueryKeys.invites(activeWorkspace.id)
            : [],
        queryFn: async () => {
            if (!activeWorkspace?.id) {
                return [];
            }

            const result = await workspaceMemberContractClient.getInvites({
                params: {
                    workspaceId: activeWorkspace.id,
                },
            });

            return result.body.map(WorkspaceInviteDto.deserialize);
        },
        enabled: !!activeWorkspace?.id,
    });
}

export function useRemoveMemberMutation(
    member: WorkspaceMember,
    workspace: Workspace,
) {
    const { activeWorkspace } = useWorkspaceContext();

    return useMutation({
        mutationFn: async () => {
            if (!activeWorkspace?.id) {
                throw new Error("No active workspace");
            }

            await workspaceMemberContractClient.removeMember({
                params: {
                    workspaceId: workspace.id,
                    memberId: member.user.id.toString(),
                },
            });
        },
    });
}

export function useUpdateWorkspaceAssignmentMutation(
    member: WorkspaceMember,
    workspace: Workspace,
) {
    return useMutation({
        mutationFn: async (
            payload: ServerInferRequest<
                typeof workspaceMemberContract.updateWorkspaceAssignment
            >["body"],
        ) => {
            await workspaceMemberContractClient.updateWorkspaceAssignment({
                params: {
                    workspaceId: workspace.id,
                    memberId: member.user.id.toString(),
                },
                body: payload,
            });
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: workspaceMemberQueryKeys.workspace(workspace.id),
            });
        },
    });
}

export function useInviteMemberMutation(workspace: Workspace) {
    return useMutation({
        mutationFn: async (
            payload: ServerInferRequest<
                typeof workspaceMemberContract.inviteMember
            >["body"],
        ) => {
            await workspaceMemberContractClient.inviteMember({
                params: {
                    workspaceId: workspace.id,
                },
                body: payload,
            });
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: workspaceMemberQueryKeys.invites(workspace.id),
            });
        },
    });
}

export function useResendInviteMutation(workspace: Workspace) {
    return useMutation({
        mutationFn: async (invite: InviteCode) => {
            await workspaceMemberContractClient.resendInvite({
                params: {
                    workspaceId: workspace.id,
                    inviteId: invite.id.toString(),
                },
            });
        },
    });
}

export function useCancelInviteMutation(workspace: Workspace) {
    return useMutation({
        mutationFn: async (invite: InviteCode) => {
            await workspaceMemberContractClient.cancelInvite({
                params: {
                    workspaceId: workspace.id,
                    inviteId: invite.id.toString(),
                },
            });
        },
        onMutate: async (invite) => {
            await queryClient.setQueryData(
                workspaceMemberQueryKeys.invites(workspace.id),
                (data: WorkspaceInviteDto[]) =>
                    data.filter((i) => i.id !== invite.id),
            );
        },
        onSuccess: async (_response) => {
            await queryClient.invalidateQueries({
                queryKey: workspaceMemberQueryKeys.invites(workspace.id),
            });
        },
    });
}
