import React, { useCallback, useMemo } from "react";
import { DateRangeFilter } from "../general/DateRangeFilter/DateRangeFilter";
import { EntitySelect } from "../entity/EntitySelect";
import { DateRange } from "../../common/helpers/date";
import { Button } from "../general/Button/Button";
import { TablePageHeader } from "../pageHeader/TablePageHeader";
import { FilterTabs } from "../pageHeader/filters/lib";
import { AccountingReportFilters } from "./types";
import styles from "./AccountingReportFiltersForm.module.scss";
import { useEntitiesWithAccountingAvailable } from "./useEntitiesWithAccountingAvailable";
import {
    AccountingFiltersSetter,
    useAccountingTabContext,
} from "./useAccountingTab";

interface Props {
    filters: AccountingReportFilters;
    setFilters: AccountingFiltersSetter;
    additionalButtons?: React.ReactNode;
    selectExactDays?: boolean;
    requireDateSelection?: boolean;
    requireReportsReady?: boolean;
    showAccountFilter?: boolean;
    hideCreateJournalEntryButton?: boolean;
    hideDateRangeFilter?: boolean;
    hideClassFilter?: boolean;
    showAccountEnabledFilter?: boolean;
}

export const AccountingReportFiltersForm: React.FC<Props> = ({
    filters,
    setFilters,
    additionalButtons,
    selectExactDays,
    requireDateSelection,
    requireReportsReady,
    showAccountFilter,
    hideCreateJournalEntryButton,
    hideDateRangeFilter = false,
    hideClassFilter = false,
    showAccountEnabledFilter = false,
}) => {
    const { setShowCreateJournalEntryModal } = useAccountingTabContext();

    const availableEntities =
        useEntitiesWithAccountingAvailable(requireReportsReady);
    const setFilterValue = useCallback(
        (key: keyof AccountingReportFilters, value: any) => {
            setFilters((currentFilters) => ({
                ...currentFilters,
                [key]: value,
            }));
        },
        [setFilters],
    );

    const selectRange = useCallback(
        (range?: Partial<DateRange>, isAllTime?: boolean) => {
            setFilters((currentFilters) => ({
                ...currentFilters,
                startDate: range?.start,
                endDate: range?.end,
                isAllTime,
            }));
        },
        [setFilters],
    );

    const dateRange = useMemo(
        () => ({
            start: filters.startDate,
            end: filters.endDate,
        }),
        [filters.startDate, filters.endDate],
    );

    const selectedEntity = useMemo(
        () => availableEntities.find((e) => e.id === filters.entityId),
        [filters.entityId, availableEntities],
    );

    const availableFilters: FilterTabs[] = [];

    if (!hideClassFilter) {
        availableFilters.push(FilterTabs.CLASS);
    }

    if (showAccountFilter) {
        availableFilters.push(FilterTabs.ACCOUNT_CODE);
    }

    if (showAccountEnabledFilter) {
        availableFilters.push(FilterTabs.ACCOUNT_STATUS);
    }

    return (
        <TablePageHeader
            availableFilters={availableFilters}
            prependChildren={
                !hideDateRangeFilter && (
                    <DateRangeFilter
                        onChange={selectRange}
                        monthOnly={!selectExactDays}
                        dateRange={dateRange}
                        requireSelection={requireDateSelection}
                    />
                )
            }
            filters={filters}
            onFiltersChange={(newFilters) => {
                setFilters((currentFilters) => ({
                    ...currentFilters,
                    ...newFilters,
                }));
            }}
            hardcodedEntityId={selectedEntity?.id}
        >
            <EntitySelect
                entities={availableEntities}
                selectedEntity={selectedEntity}
                onChange={(e) => setFilterValue("entityId", e?.id)}
                dropdownKey="accounting-report-entity-select"
                className={styles.entitySelect}
                size="xs"
            />

            {!hideCreateJournalEntryButton && (
                <Button
                    onClick={() => setShowCreateJournalEntryModal(true)}
                    variant="secondary"
                >
                    Create journal entry
                </Button>
            )}
            {additionalButtons}
        </TablePageHeader>
    );
};
