import React, { useCallback, useState } from "react";
import { PlaidAccountsConnectedModal } from "../PlaidAccountsConnectedModal/PlaidAccountsConnectedModal";
import { ConfirmationModal } from "../../general/ConfirmationModal";
import { useUser } from "../../../hooks/useUser";
import { FinancialAccountsConnectedModal } from "../../financialAccount/FinancialAccountsConnectedModal";
import { isPlaidConnection } from "../../../common/helpers/financialConnection";
import { useConnectAccount } from "../../connectAccount/ConnectAccount.context";
import { PlaidConnectionWithAccounts } from "../../../common/types/plaidConnection";
import { FinancialConnectionWithAccounts } from "../../../common/types/financialConnection";
import { FinancialAccountsConnectedModalV2 } from "../../financialAccount/FinancialAccountsConnectedModalV2";
import { NewConnectionLoaderModal } from "./NewConnectionLoaderModal";

export const NewConnectionHandler: React.FC = () => {
    const {
        state: { isConnecting, connection, integrationConnection, ...rest },
    } = useConnectAccount();
    const foreign = "foreign" in rest ? rest.foreign : null;
    const connectionSaved =
        "connectionSaved" in rest ? rest.connectionSaved : true;
    const showMainModal =
        isConnecting || !!connection || !!integrationConnection;
    const [showDismissConfirmation, setShowDismissConfirmation] =
        useState<boolean>(false);

    const user = useUser();
    const { updateConnectAccountState } = useConnectAccount();

    const hideMainModal = useCallback(() => {
        updateConnectAccountState({
            isConnecting: false,
        });
    }, [updateConnectAccountState]);

    const hideConfirmationModal = useCallback(
        () => setShowDismissConfirmation(false),
        [],
    );

    const handleHideAccountsConnected = useCallback(() => {
        const integrationAccounts =
            connection?.accounts ?? integrationConnection?.integrationAccounts;

        const showConfirmation = integrationAccounts?.some(
            (acc) =>
                acc.financialAccount.isBusiness === null ||
                !acc.financialAccount.entityId,
        );

        if (showConfirmation && user.onboardingComplete) {
            setShowDismissConfirmation(true);
        } else {
            hideMainModal();
        }
    }, [
        connection?.accounts,
        integrationConnection?.integrationAccounts,
        hideMainModal,
        user.onboardingComplete,
    ]);

    const handleConfirm = useCallback(() => {
        hideMainModal();
        hideConfirmationModal();
    }, [hideConfirmationModal, hideMainModal]);

    if (!connection && !integrationConnection) {
        return <NewConnectionLoaderModal show={showMainModal} />;
    }

    return (
        <>
            {connection &&
                (isPlaidConnection(connection) ? (
                    <PlaidAccountsConnectedModal
                        show={showMainModal}
                        connection={connection as PlaidConnectionWithAccounts}
                        foreign={foreign ?? []}
                        connectionSaved={connectionSaved}
                        onHide={handleHideAccountsConnected}
                    />
                ) : (
                    <FinancialAccountsConnectedModal
                        show={showMainModal}
                        connection={
                            connection as FinancialConnectionWithAccounts
                        }
                        onHide={handleHideAccountsConnected}
                    />
                ))}
            {integrationConnection && (
                <FinancialAccountsConnectedModalV2
                    show={showMainModal}
                    integrationConnection={integrationConnection}
                    onHide={handleHideAccountsConnected}
                />
            )}

            <ConfirmationModal
                show={showMainModal && showDismissConfirmation}
                onHide={hideConfirmationModal}
                title="Are you sure you want to cancel?"
                question={
                    <>
                        To add your accounts, Kick needs to know whether they
                        are Business or Personal.
                    </>
                }
                onConfirm={handleConfirm}
                onReject={hideConfirmationModal}
                yes="Yes, don't add accounts"
                no="Back"
                yesBtnVariant="secondary"
                noBtnVariant="default"
            />
        </>
    );
};
