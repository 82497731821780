import React from "react";
import {
    FinancialAccount,
    FinancialAccountSourceType,
} from "../../common/types/financialAccount";
import stripeLogo from "../../static/images/icons/stripe.svg";
import gustoLogo from "../../static/images/icons/gusto.svg";
import rampLogo from "../../static/images/icons/ramp.svg";
import mercuryLogo from "../../static/images/icons/mercury.svg";
import paypalLogo from "../../icons/logos/Paypal.svg";
import { BankIcon } from "../../icons";

interface Props {
    account: FinancialAccount;
}
function getIconForAccountType(type: FinancialAccountSourceType) {
    switch (type) {
        case FinancialAccountSourceType.STRIPE:
            return <img src={stripeLogo} alt="Stripe" />;
        case FinancialAccountSourceType.GUSTO:
            return <img src={gustoLogo} alt="Gusto" />;
        case FinancialAccountSourceType.RAMP:
            return <img src={rampLogo} alt="Ramp" />;
        case FinancialAccountSourceType.MERCURY:
            return <img src={mercuryLogo} alt="Mercury" />;
        case FinancialAccountSourceType.PAYPAL:
            return <img src={paypalLogo} alt="PayPal" />;
        default:
            return (
                <BankIcon
                    width={10}
                    height={10}
                    className="icon-color-gray-700"
                />
            );
    }
}

export const FinancialAccountIcon: React.FC<Props> = ({ account }) =>
    getIconForAccountType(account.sourceType);
