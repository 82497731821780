import React from "react";
import classNames from "classnames";
import styles from "./EmptyObjectIcon.module.scss";
import { ObjectIconProps } from "./ObjectIcon";

export type EmptyObjectIconProps = Pick<ObjectIconProps, "size" | "square">;

export const EmptyObjectIcon: React.FC<EmptyObjectIconProps> = ({
    square,
    size,
}) => (
    <div
        className={classNames(
            styles.emptyObjectIcon,
            `object-icon object-icon--${size}`,
            {
                "object-icon--square": square,
            },
        )}
    />
);
