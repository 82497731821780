import { useCallback, useMemo } from "react";
import { BaseFilterProps } from "../../lib";
import { ClassFilterOverlay } from "../../../../Class/ClassFilter/ClassFilterOverlay";
import { TransactionClassFilterType } from "../../../../../common/helpers/transactions";
import styles from "./styles.module.scss";

interface AggregatedClassFilterProps extends BaseFilterProps {
    showFilterTypeSettings?: boolean;
}

export const AggregatedClassFilter: React.FC<AggregatedClassFilterProps> = ({
    filters,
    onChange,
    showFilterTypeSettings = false,
}) => {
    const onChangeCallback = (classes: string[]) => {
        onChange({ ...filters, classes: classes.length ? classes : undefined });
    };

    const onBottomButtonClick = useCallback(() => {
        onChange({
            ...filters,
            classes: undefined,
            classFilterType: TransactionClassFilterType.ANY_SELECTED,
        });
    }, [onChange, filters]);

    const onSettingsChange = useCallback(
        (option: TransactionClassFilterType) => {
            onChange({ ...filters, classFilterType: option });
        },
        [onChange, filters],
    );

    const filterBehaviour = useMemo(
        () => ({
            selectedParentClassIncludesAllChildren: true,
            canSelectOnlyOneClassInTheSameRoot: false,
            bottomButtonIsAlwaysVisible:
                filters.classFilterType !== undefined &&
                filters.classFilterType !==
                    TransactionClassFilterType.ANY_SELECTED,
        }),
        [filters.classFilterType],
    );

    return (
        <div className={styles.container}>
            <ClassFilterOverlay
                selectedClasses={filters.classes ?? []}
                onChange={onChangeCallback}
                behaviour={filterBehaviour}
                containerClassName={styles.filterContainer}
                resetButtonOnClick={onBottomButtonClick}
                classFilterType={
                    filters.classFilterType ??
                    TransactionClassFilterType.ANY_SELECTED
                }
                onSettingsChange={onSettingsChange}
                showFilterTypeSettings={showFilterTypeSettings}
            />
        </div>
    );
};
