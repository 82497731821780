import React, { useContext, useMemo } from "react";
import { Button } from "../../general/Button/Button";
import { DirectoryContext } from "./Directory.context";
import { DirectoryContent } from "./DirectoryContent";

export const DirectorySettings: React.FC = () => {
    const { addingNew, setAddingNew, editedItem } =
        useContext(DirectoryContext);

    const title = useMemo(() => {
        if (addingNew) {
            return "Add new";
        }
        if (editedItem) {
            return "Edit item";
        }
        return "Directory";
    }, [editedItem, addingNew]);

    const formOpened = Boolean(addingNew || editedItem);

    return (
        <>
            <header className="settings__header">
                <h3>{title}</h3>
                {!formOpened && (
                    <Button
                        variant="default"
                        onClick={() => setAddingNew(true)}
                    >
                        Add new
                    </Button>
                )}
            </header>

            <DirectoryContent />
        </>
    );
};
