import React, { FC, useCallback } from "react";
import classNames from "classnames";
import { useWorkspaces } from "../../hooks/useWorkspaces";
import { useWorkspaceContext } from "../../state/workspaceContext";
import { Workspace } from "../../common/types/workspace";
import { EntityIconList } from "../general/EntityIconList/EntityIconList";
import { KickAvatarIcon } from "../../icons";
import { Command } from "../../vendor/cmdk/cmdk";
import styles from "./Commands.module.scss";
import { useCommands } from "./useCommands";
import { CommandItem } from "./CommandItem";

export const WorkspacesCommandsList: FC = () => {
    const { close: closeCommands } = useCommands();
    const userWorkspaces = useWorkspaces({ withEntities: true });
    const { changeActiveWorkspace } = useWorkspaceContext();

    const handleSelected = useCallback(
        (workspace: Workspace) => {
            changeActiveWorkspace(workspace);
            closeCommands();
        },
        [changeActiveWorkspace, closeCommands],
    );

    return (
        <Command.Group heading="Workspaces">
            {userWorkspaces.data?.map((workspace, index) => (
                <CommandItem
                    key={workspace.id}
                    keywords={[
                        "workspace",
                        workspace.name,
                        ...workspace.entities!.map((entity) => entity.name!),
                    ]}
                    onSelect={() => handleSelected(workspace)}
                    shortcut={["O", (index + 1).toString()]}
                >
                    <KickAvatarIcon
                        className={classNames(styles.revert, styles.square)}
                    />
                    Open {workspace.name}{" "}
                    <EntityIconList
                        entities={workspace.entities!}
                        maxEntitiesToDisplay={Number.MAX_VALUE}
                        showLabel={false}
                    />
                </CommandItem>
            ))}
        </Command.Group>
    );
};
