import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isAxiosError } from "axios";
import { finishPasswordlessLogin, handleLogin } from "../../../lib/auth0";
import { initApp } from "../../../lib";
import { CodeVerificationProps } from "../../general/CodeVerification/CodeVerification";
import { PageCoverLoader } from "../../general/PageCoverLoader/PageCoverLoader";
import { getRedirectPath } from "../../../lib/redirectAfterLogin";
import { Auth0Error } from "../../../lib/errors/auth0.error";
import { login } from "../../../api/auth.api";
import { useToaster } from "../../general/ToastMessages/useToaster";
import { LoginFlowStart } from "./LoginFlowStart";
import { LoginFlowCodeAuth } from "./LoginFlowCodeAuth";

export const LoginFlow: React.FC = () => {
    const [finishingLogin, setFinishingLogin] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [providedPhoneOrEmail, setProvidedPhoneOrEmail] = useState<string>();
    const { error } = useToaster();

    const handleTooManyAttemptsError = useCallback(() => {
        error(
            `Too many attempts to login, check your email for instructions or contact support@kick.co`,
        );
    }, [error]);

    const requestCode = useCallback(
        async (phoneNumberOrEmail = providedPhoneOrEmail) => {
            if (phoneNumberOrEmail) {
                try {
                    await login(phoneNumberOrEmail);
                    return true;
                } catch (e) {
                    if (isAxiosError(e) && e.response?.status === 429) {
                        handleTooManyAttemptsError();
                    }

                    return false;
                }
            }
        },
        [handleTooManyAttemptsError, providedPhoneOrEmail],
    );

    const verifyLogin: Required<CodeVerificationProps>["verify"] = useCallback(
        async (verificationCode) => {
            if (providedPhoneOrEmail) {
                try {
                    return await finishPasswordlessLogin(
                        providedPhoneOrEmail,
                        verificationCode,
                    );
                } catch (e) {
                    if (
                        (e as Auth0Error).auth0Error.error ===
                        "too_many_attempts"
                    ) {
                        handleTooManyAttemptsError();
                    } else {
                        throw e;
                    }
                }
            } else {
                return false;
            }
        },
        [handleTooManyAttemptsError, providedPhoneOrEmail],
    );

    useEffect(() => {
        if (location.hash.length > 2) {
            setFinishingLogin(true);
            handleLogin()
                .then(() => initApp())
                .then(() => navigate(getRedirectPath() ?? "/"))
                .catch((e) => {
                    error(e.errorDescription ?? "Unknown error");
                    setFinishingLogin(false);
                    navigate("/");
                });
        }
    }, [location.hash, error, navigate]);

    if (providedPhoneOrEmail) {
        return (
            <LoginFlowCodeAuth
                onBack={() => setProvidedPhoneOrEmail(undefined)}
                providedPhoneOrEmail={providedPhoneOrEmail}
                onRequestCode={requestCode}
                onVerify={verifyLogin}
            />
        );
    } else if (finishingLogin) {
        return <PageCoverLoader>Logging in to Kick…</PageCoverLoader>;
    } else {
        return (
            <LoginFlowStart
                onRequestCode={requestCode}
                onSuccess={(val) => setProvidedPhoneOrEmail(val)}
            />
        );
    }
};
