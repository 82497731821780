import React from "react";
import {
    CategoryAuditLogValue,
    AuditLog,
} from "../../../../common/types/auditLog";
import { useCategoryLabelGetter } from "../../../../hooks/useCategoryLabelGetter";
import { isValidTaxonomy } from "../../../../common/helpers/taxonomy";
import { TRANSACTION_UPDATE_SOURCE } from "../../../../common/constants";
import { useAuditLogAuthorLabel } from "./useAuditLogAuthorLabel";
import { AuditLogSimilarityContent } from "./AuditLogSimilarityContent";
import { AuditLogTransferMatchContent } from "./AuditLogTransferMatchContent";
import { AuditLogRuleContent } from "./AuditLogRuleContent";

interface Props {
    log: AuditLog;
    newValue: CategoryAuditLogValue;
}

export const AuditLogCategoryContent: React.FC<Props> = ({ log, newValue }) => {
    const categoryLabelGetter = useCategoryLabelGetter();
    const categoryLabel = isValidTaxonomy(newValue.category)
        ? categoryLabelGetter(newValue.category)
        : newValue.category;
    const authorLabel = useAuditLogAuthorLabel(log);

    if (log.updateSource === TRANSACTION_UPDATE_SOURCE.SIMILARITY) {
        return (
            <AuditLogSimilarityContent
                log={log}
                categoryLabel={categoryLabel}
            />
        );
    } else if (
        log.updateSource === TRANSACTION_UPDATE_SOURCE.AUTO_TRANSFER_MATCHING
    ) {
        return (
            <AuditLogTransferMatchContent
                log={log}
                categoryLabel={categoryLabel}
            />
        );
    } else if (
        [
            TRANSACTION_UPDATE_SOURCE.CATEGORIZATION_RULE_CREATED,
            TRANSACTION_UPDATE_SOURCE.CATEGORIZATION_RULE_TRIGGERED,
        ].includes(log.updateSource)
    ) {
        return <AuditLogRuleContent categoryLabel={categoryLabel} />;
    } else {
        return (
            <>
                <strong>{authorLabel}</strong> changed the category to{" "}
                <strong>{categoryLabel}</strong>.
            </>
        );
    }
};
