import React, { useMemo, useState, useCallback } from "react";

import { arrayMove } from "@dnd-kit/sortable";
import {
    DndContext,
    DragEndEvent,
    DragMoveEvent,
    DragOverEvent,
    DragStartEvent,
    PointerSensor,
    UniqueIdentifier,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    optimisticallyUpdateClassesOrder,
    useClasses,
    useClassTransactionsCountQuery,
    useRepositionClassMutation,
} from "../../../api/class.api";
import {
    GridTable,
    GridTableFallbacks,
    GridTableHeader,
} from "../../general/Tables/GridTable/GridTable";
import { useWorkspaceContext } from "../../../state/workspaceContext";
import {
    SortableTreeList,
    SortableTreeListOptions,
} from "../../general/SortableTreeList/SortableTreeList";
import {
    buildFlattenedItems,
    ExtendedFlattenedItem,
    getDragDepth,
    getOrderInParent,
    getProjectionForClassesTree,
} from "./utils";
import styles from "./ClassesSettings.module.scss";

import { ClassesSettingsHeader } from "./children/ClassesSettingsHeader";
import { ClassItem } from "./children/ClassItem";
import { DraggedClass } from "./children/DraggedClass";
import { ClassMergingModal } from "./children/ClassMergingModal";

interface Props {
    closeSettingsModal: (disableAnimation?: boolean) => void;
    showHeaderTitle?: boolean;
}

const mergingModalInitialState = {
    show: false,
    id: null,
    parentId: null,
    order: 0,
};

interface OnClassRepositionParams {
    id: string;
    parentClassId: string | null;
    order: number;
    mergingStrategy: "child" | "parent";
}

export const ClassesSettings: React.FC<Props> = ({
    closeSettingsModal,
    showHeaderTitle = true,
}) => {
    const { classes, classesWithChildren, classesMap } = useClasses();
    const classTransactionsCount = useClassTransactionsCountQuery();

    const [collapsedClasses, setCollapsedClasses] = useState<Set<string>>(
        new Set(),
    );
    const [search, setSearch] = useState("");

    const { items: flattenedItems, itemIdToIndexMap } = useMemo(
        () => buildFlattenedItems(classesWithChildren, search),
        [classesWithChildren, search],
    );

    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
    const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
    const [dragDepth, setDragDepth] = useState(0);

    const projected = useMemo(
        () =>
            activeId && overId
                ? getProjectionForClassesTree({
                      items: flattenedItems,
                      itemIdToIndexMap,
                      activeId,
                      overId,
                      dragDepth,
                      indentationWidth: 50,
                      maxPossibleDepth: 1,
                  })
                : null,
        [activeId, overId, dragDepth, itemIdToIndexMap, flattenedItems],
    );

    function handleDragCancel() {
        resetState();
    }

    function resetState() {
        setOverId(null);
        setActiveId(null);
        setDragDepth(0);

        document.body.style.setProperty("cursor", "");
    }

    const repositionClass = useRepositionClassMutation();

    const sensors = useSensors(useSensor(PointerSensor));

    const { activeWorkspace } = useWorkspaceContext();
    const [classMergingModalState, setClassMergingModalState] = useState<{
        show: boolean;
        id: string | null;
        parentId: string | null;
        order: number;
    }>(mergingModalInitialState);

    const onClassReposition = useCallback(
        ({
            id,
            parentClassId,
            order,
            mergingStrategy,
        }: OnClassRepositionParams) => {
            optimisticallyUpdateClassesOrder({
                workspaceId: activeWorkspace!.id,
                id,
                parentClassId,
                order,
            });

            repositionClass.mutateAsync({
                params: { id },
                body: {
                    parentClassId,
                    order,
                    mergingStrategy,
                },
            });
        },
        [activeWorkspace, repositionClass],
    );

    const onMergingModalConfirm = useCallback(
        (mergingStrategy: "child" | "parent") => {
            if (classMergingModalState.id) {
                onClassReposition({
                    id: classMergingModalState.id,
                    parentClassId: classMergingModalState.parentId,
                    order: classMergingModalState.order,
                    mergingStrategy,
                });
            }

            setClassMergingModalState(mergingModalInitialState);
        },
        [classMergingModalState, onClassReposition],
    );

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            resetState();

            const { over, active } = event;

            if (projected && over) {
                const { depth, parentId: targetParentId } = projected;
                const clonedItems: ExtendedFlattenedItem[] = JSON.parse(
                    JSON.stringify(flattenedItems),
                );
                const overIndex = projected.overItemIndex;
                const activeIndex = clonedItems.findIndex(
                    ({ id }) => id === active.id,
                );
                const activeTreeItem = clonedItems[activeIndex];

                const hasNonVirtualChildren = activeTreeItem.children.filter(
                    (c) => !c.isVirtual,
                ).length;

                // NOTE: no change is needed
                if (over.id === active.id) {
                    return;
                }

                // NOTE: prevent impossible state
                if (
                    activeTreeItem.isVirtual ||
                    !("classInstance" in activeTreeItem.meta) ||
                    (hasNonVirtualChildren && targetParentId)
                ) {
                    return;
                }

                clonedItems[activeIndex] = {
                    ...activeTreeItem,
                    depth,
                    parentId: targetParentId,
                };

                const sortedItems = arrayMove(
                    clonedItems,
                    activeIndex,
                    overIndex,
                );

                const shouldBeMergedWithNewParent =
                    targetParentId !== activeTreeItem.parentId &&
                    targetParentId !== null;

                const activeClassHasNoTransactions =
                    classTransactionsCount.data?.[
                        activeTreeItem.meta.classInstance.id
                    ] !== undefined &&
                    classTransactionsCount.data?.[
                        activeTreeItem.meta.classInstance.id
                    ] === 0;

                const targetParentWithChildren = classesWithChildren.find(
                    (c) => c.id === targetParentId,
                );

                const targetParentWithChildrenHasNoTransactions =
                    targetParentWithChildren?.children.every(
                        (c) => classTransactionsCount.data?.[c.id] === 0,
                    );

                const targetParentClassAndItsChildrenHasNoTransactions =
                    targetParentId &&
                    classTransactionsCount.data?.[targetParentId] !==
                        undefined &&
                    classTransactionsCount.data?.[targetParentId] === 0 &&
                    targetParentWithChildrenHasNoTransactions;

                if (
                    !shouldBeMergedWithNewParent ||
                    activeClassHasNoTransactions ||
                    targetParentClassAndItsChildrenHasNoTransactions
                ) {
                    onClassReposition({
                        id: activeTreeItem.meta.classInstance.id,
                        parentClassId: targetParentId as string | null,
                        order: getOrderInParent({
                            overItemIndex: overIndex,
                            items: sortedItems,
                        }),
                        mergingStrategy: "child",
                    });
                } else {
                    setClassMergingModalState({
                        show: true,
                        id: activeTreeItem.meta.classInstance.id,
                        parentId: targetParentId as string | null,
                        order: getOrderInParent({
                            overItemIndex: overIndex,
                            items: sortedItems,
                        }),
                    });
                }
            }
        },
        [
            projected,
            flattenedItems,
            onClassReposition,
            classesWithChildren,
            classTransactionsCount,
        ],
    );

    const handleDragStart = useCallback(
        ({ active: { id: newActiveId } }: DragStartEvent) => {
            setActiveId(newActiveId);
            setOverId(newActiveId);

            document.body.style.setProperty("cursor", "grabbing");
        },
        [setActiveId, setOverId],
    );

    const handleDragMove = useCallback((param: DragMoveEvent) => {
        const { delta } = param;
        setDragDepth(getDragDepth(delta.x, 50));
    }, []);

    const handleDragOver = useCallback(({ over }: DragOverEvent) => {
        setOverId(over?.id ?? null);
    }, []);

    const renderItemCallback = useCallback(
        (c: ExtendedFlattenedItem, options: SortableTreeListOptions) => (
            <ClassItem
                item={c}
                collapsedClasses={collapsedClasses}
                setCollapsedClasses={setCollapsedClasses}
                projectedDepth={projected?.depth}
                isDragged={options.state.isDragged}
                transactionCount={
                    classTransactionsCount.data?.[
                        "classInstance" in c.meta ? c.meta.classInstance.id : ""
                    ]
                }
            />
        ),
        [
            collapsedClasses,
            setCollapsedClasses,
            projected?.depth,
            classTransactionsCount,
        ],
    );

    const childClass = classMergingModalState.id
        ? classesMap.get(classMergingModalState.id) ?? null
        : null;

    const parentClass = classMergingModalState.parentId
        ? classesMap.get(classMergingModalState.parentId) ?? null
        : null;

    const onMergingModalHide = useCallback(
        (closeSettings?: boolean) => {
            if (closeSettings) {
                closeSettingsModal(true);
            } else {
                setClassMergingModalState(mergingModalInitialState);
            }
        },
        [closeSettingsModal],
    );

    const renderDragOverlay = useCallback(
        (c: ExtendedFlattenedItem) => <DraggedClass item={c as any} />,
        [],
    );

    const getItemId = useCallback((c: ExtendedFlattenedItem) => c.id, []);

    return (
        <DndContext
            sensors={sensors}
            onDragOver={handleDragOver}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragMove={handleDragMove}
            onDragCancel={handleDragCancel}
        >
            {childClass && parentClass && (
                <ClassMergingModal
                    show={classMergingModalState.show}
                    onHide={onMergingModalHide}
                    onConfirm={onMergingModalConfirm}
                    childClass={childClass}
                    parentClass={parentClass}
                />
            )}
            <main>
                <ClassesSettingsHeader
                    collapsedClasses={collapsedClasses}
                    setCollapsedClasses={setCollapsedClasses}
                    classes={classes}
                    search={search}
                    setSearch={setSearch}
                    showHeaderTitle={showHeaderTitle}
                />

                <GridTable className={styles.classesTable}>
                    <GridTableHeader
                        columnBorders={false}
                        columns={[
                            { key: "name", label: "Name" },
                            { key: "transactionCount", label: "Transactions" },
                            { key: "actions", label: "" },
                        ]}
                        className={styles.headerRow}
                    />

                    <SortableTreeList
                        items={flattenedItems}
                        getItemId={getItemId as any}
                        listId="classes"
                        activeId={activeId}
                        projected={projected}
                        renderDragOverlay={renderDragOverlay as any}
                        renderItem={renderItemCallback as any}
                    />

                    <GridTableFallbacks
                        header="No classes found for the provided search"
                        dataCount={
                            flattenedItems.filter(
                                ({ meta: { isHidden } }) => !isHidden,
                            ).length
                        }
                    />
                </GridTable>
            </main>
        </DndContext>
    );
};
