import React from "react";
import { User } from "../../../common/types/user";
import { UserAvatar } from "../../user/UserAvatar";
import styles from "./WorkspaceMembers.module.scss";

interface Props {
    user: User;
}

export const MemberCard: React.FC<Props> = ({ user }) => (
    <div className={styles.userCard}>
        <UserAvatar user={user} size="lg" />
        <div>
            <div>{user.name}</div>
            <small className="text-grey">{user.email}</small>
        </div>
    </div>
);
