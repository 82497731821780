import React, { useRef, useState } from "react";
import classNames from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BulkUpdateStatus } from "../lib";
import { NoteDropdown } from "../NoteDropdown/NoteDropdown";
import { TransactionTaxQuestions } from "../ActionBarTaxQuestions/ActionBarTaxQuestions";
import { ActionBarQuestionAnswerPicker } from "../ActionBarTaxQuestions/ActionBarQuestionAnswerPicker";
import { CategorySelectCustom } from "../../CategorySelect/CategorySelectCustom";
import { useBulkActions } from "../useBulkActions";
import actionBarStyles from "../../../general/ActionBar/ActionBar.module.scss";
import { TransactionDirectionType } from "../../../../common/categories";
import { PlanManagementProvider } from "../../../billing/PlanManagement/PlanManagementProvider";
import { ClassFilterOverlay } from "../../../Class/ClassFilter/ClassFilterOverlay";
import {
    PopoverContainer,
    PopoverContainerHandle,
} from "../../../general/PopoverContainer";
import { useClasses } from "../../../../api/class.api";
import { numberFormatter } from "../../../../common/helpers/number";
import { CloseIcon } from "../../../../icons";
import { TransactionEntityPopover } from "../../TransactionEntityPopover";
import { usePersonalCategory } from "../../../../hooks/usePersonalCategory";
import styles from "./TransactionsActionBar.module.scss";
import { TransactionsActionBarCounterparty } from "./TransactionsActionBarCounterparty";

const classFilterBehaviour = {
    selectedParentClassIncludesAllChildren: false,
    canSelectOnlyOneClassInTheSameRoot: true,
    bottomButtonIsAlwaysVisible: true,
};

export const TransactionsActionBar: React.FC = () => {
    const bulkActions = useBulkActions();
    const [selectedClasses, setSelectedClasses] = useState<string[]>([]);
    const personalCategory = usePersonalCategory();

    const { isActivated: areClassesEnabled } = useClasses();

    const classPopoverRef = useRef<PopoverContainerHandle>(null);

    if (!bulkActions) {
        return null;
    }

    const isBusy =
        bulkActions.updateDetails?.status === BulkUpdateStatus.UPDATING;

    const selectedArray = Object.values(bulkActions.selected);
    const firstType = selectedArray[0];
    const firstTransactionDirection = firstType?.transactionDirection;

    const transactionHaveMixedDirections =
        bulkActions.hasSelectedAll ||
        selectedArray.some(
            (value) => value.transactionDirection !== firstTransactionDirection,
        );

    return (
        <section className={classNames(styles.actionBar)}>
            {bulkActions.selectedCount > 0 && (
                <main className={styles.wrapper}>
                    <div className={styles.textContainer}>
                        <span className={styles.selectedItemsText}>
                            {bulkActions.selectedCount} Selected
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="action-bar-category">
                                        Deselect {bulkActions.selectedCount}
                                    </Tooltip>
                                }
                            >
                                <button
                                    className="ml-2"
                                    style={{
                                        padding: 0,
                                        border: 0,
                                        background: "transparent",
                                    }}
                                    onClick={bulkActions.clearSelection}
                                >
                                    <CloseIcon width={12} height={12} />
                                </button>
                            </OverlayTrigger>
                        </span>
                    </div>
                    {!bulkActions.hasSelectedAll ? (
                        <>
                            <span className={styles.separator} />
                            <button
                                type="button"
                                className={styles.button}
                                onClick={bulkActions.selectAll}
                                data-testid="bulk-select-all-button"
                            >
                                Select all{" "}
                                {numberFormatter.format(
                                    bulkActions.totalTransactions,
                                )}
                            </button>
                        </>
                    ) : null}

                    <span className={styles.separator} />

                    <PlanManagementProvider>
                        <CategorySelectCustom
                            onSelected={(category) => {
                                bulkActions.updateSelected({
                                    update: "categoryId",
                                    value: String(category.id),
                                });
                            }}
                            dropdownKey="bulk-update"
                            placement="top-end"
                            transactionDirection={
                                transactionHaveMixedDirections
                                    ? TransactionDirectionType.mixed
                                    : firstTransactionDirection
                            }
                        >
                            {(active) => (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="action-bar-category">
                                            Update category
                                        </Tooltip>
                                    }
                                >
                                    <button
                                        className={classNames(
                                            actionBarStyles.button,
                                            {
                                                active,
                                            },
                                        )}
                                        disabled={isBusy}
                                    >
                                        Category
                                    </button>
                                </OverlayTrigger>
                            )}
                        </CategorySelectCustom>
                        {areClassesEnabled && (
                            <>
                                <span className={styles.divider} />

                                <PopoverContainer
                                    ref={classPopoverRef}
                                    id={`action-bar-class`}
                                    popoverClass="no-padding"
                                    trigger={
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="action-bar-class">
                                                    Update classes
                                                </Tooltip>
                                            }
                                        >
                                            <button
                                                className={classNames(
                                                    actionBarStyles.button,
                                                )}
                                                disabled={isBusy}
                                            >
                                                Class
                                            </button>
                                        </OverlayTrigger>
                                    }
                                    placement="top"
                                    offset={4}
                                >
                                    <ClassFilterOverlay
                                        selectedClasses={selectedClasses}
                                        onChange={setSelectedClasses}
                                        behaviour={classFilterBehaviour}
                                        useAutoHeight
                                        applyButtonOnClick={async () => {
                                            classPopoverRef.current?.close();

                                            await bulkActions.updateSelected({
                                                update: "classes",
                                                value: selectedClasses,
                                            });
                                            setSelectedClasses([]);
                                        }}
                                    />
                                </PopoverContainer>
                            </>
                        )}
                    </PlanManagementProvider>

                    <span className={styles.divider} />
                    <TransactionEntityPopover
                        onChangeToPersonal={() =>
                            bulkActions.updateSelected({
                                update: "categoryId",
                                value: personalCategory.id,
                            })
                        }
                        onChangeToBusiness={(entity) =>
                            bulkActions.updateSelected({
                                update: "entity",
                                value: entity,
                            })
                        }
                    >
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="action-bar-entity">
                                    Update entity
                                </Tooltip>
                            }
                        >
                            <button
                                className={actionBarStyles.button}
                                disabled={isBusy}
                            >
                                Entity
                            </button>
                        </OverlayTrigger>
                    </TransactionEntityPopover>

                    <span className={styles.divider} />
                    <TransactionsActionBarCounterparty isBusy={isBusy} />

                    <span className={styles.divider} />
                    <NoteDropdown
                        dropdownKey="memo"
                        inputPlaceholder="Add note..."
                        placement="top-end"
                        onSubmit={(value) =>
                            bulkActions.updateSelected({
                                update: "memo",
                                value,
                            })
                        }
                    >
                        {(active) => (
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="action-bar-memo">
                                        Add note
                                    </Tooltip>
                                }
                            >
                                <button
                                    className={classNames(
                                        actionBarStyles.button,
                                        {
                                            active,
                                        },
                                    )}
                                    disabled={isBusy}
                                >
                                    Note
                                </button>
                            </OverlayTrigger>
                        )}
                    </NoteDropdown>

                    {bulkActions.taxRule ? (
                        <TransactionTaxQuestions
                            questions={bulkActions.taxQuestions}
                            answers={bulkActions.answers}
                            onAnswer={bulkActions.handleAnswer}
                            renderAnswerComponent={(options) => (
                                <>
                                    <span className={styles.divider} />
                                    <ActionBarQuestionAnswerPicker {...options}>
                                        {(active) => (
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip
                                                        id={`action-bar-${options.question.key}`}
                                                    >
                                                        {options.question.hint}
                                                    </Tooltip>
                                                }
                                            >
                                                <button
                                                    className={classNames(
                                                        actionBarStyles.button,
                                                        {
                                                            active,
                                                        },
                                                    )}
                                                    disabled={
                                                        bulkActions
                                                            .updateDetails
                                                            ?.status ===
                                                        BulkUpdateStatus.UPDATING
                                                    }
                                                >
                                                    {options.question.short}
                                                </button>
                                            </OverlayTrigger>
                                        )}
                                    </ActionBarQuestionAnswerPicker>
                                </>
                            )}
                        />
                    ) : null}
                </main>
            )}
        </section>
    );
};
