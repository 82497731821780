import { useEffect, useRef } from "react";
import { KeyboardCommandCommonOptions, KeyboardCommandRunner } from "./types";
import { isEventFromForm } from "./helpers";

export interface MultikeyKeyboardCommandParams
    extends KeyboardCommandRunner,
        KeyboardCommandCommonOptions {
    keys: string;
}

export interface UseMultikeyKeyboardCommandsProps {
    enabled?: boolean;
    rootEl?: EventTarget;
    command: MultikeyKeyboardCommandParams;
}

export function useMultikeyKeyboardCommand({
    enabled = true,
    rootEl = document,
    command,
}: UseMultikeyKeyboardCommandsProps) {
    const keyHistory = useRef<string>("");
    const {
        keys,
        ignoreForms = true,
        preventDefault = true,
        stopPropagation = true,
        callback,
    } = command;

    useEffect(() => {
        if (!enabled) {
            return;
        }

        function onKeyDown(e: KeyboardEvent) {
            if (e.ctrlKey || e.metaKey || e.shiftKey) {
                return;
            }

            if (ignoreForms && isEventFromForm(e)) {
                return;
            }

            const currentCommand = `${keyHistory.current}${e.key}`;

            if (keys === currentCommand) {
                const callbackResult = callback(e);

                if (preventDefault || callbackResult?.preventDefault) {
                    e.preventDefault();
                }
                if (stopPropagation || callbackResult?.stopPropagation) {
                    e.stopPropagation();
                }

                keyHistory.current = "";
            } else if (keys.startsWith(currentCommand)) {
                keyHistory.current = currentCommand;
            } else {
                keyHistory.current = e.key;
            }
        }

        rootEl.addEventListener("keydown", onKeyDown as EventListener);

        return () => {
            rootEl.removeEventListener("keydown", onKeyDown as EventListener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
