import React, { useCallback } from "react";
import classNames from "classnames";
import {
    FLAT_RATE_PLANS,
    flatRatePlanNames,
} from "../../../common/flatRateBilling";
import { Button } from "../../general/Button/Button";
import { Plans } from "../../billing/Plans/Plans";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import { ThunderboltIcon } from "../../../icons";
import { usePlanManagement } from "../../billing/PlanManagement/PlanManagementContext";
import { BookCallButton } from "../../general/BookCall/BookCallButton";
import { ENTERPRISE_TRIAL_CALL_URL } from "../../../common/billing";
import { startEnterpriseTrial } from "../../../lib/flatRateBilling";
import { useUpdateUserMutation } from "../../../mutations/user";
import { useRecommendedPlan } from "../../../hooks/useRecommendedPlan";
import animations from "../layout/OnboardingLayout.module.scss";
import { Footer } from "../components/Footer";
import commonStyles from "./common.module.scss";

interface Props {
    onContinue(): void;
    back(): void;
}

export const ChoosePlan: React.FC<Props> = ({ onContinue, back }) => {
    const { upgrade } = usePlanManagement();
    const { plans } = useBillingStatus();
    const recommendedPlan = useRecommendedPlan();
    const updateUser = useUpdateUserMutation();

    const handleEnterpriseCallBooked = useCallback(async () => {
        await startEnterpriseTrial();
        await updateUser.mutateAsync({ bookedEnterpriseCall: true });
        onContinue();
    }, [onContinue, updateUser]);

    const renderAction = useCallback(
        (plan: FLAT_RATE_PLANS) => {
            if (plan === FLAT_RATE_PLANS.FREE) {
                return (
                    <Button variant="secondary" size="sm" onClick={onContinue}>
                        Continue on free
                    </Button>
                );
            }

            if (plan === FLAT_RATE_PLANS.ENTERPRISE) {
                return (
                    <BookCallButton
                        variant="action"
                        size="sm"
                        url={ENTERPRISE_TRIAL_CALL_URL}
                        onBooked={handleEnterpriseCallBooked}
                    >
                        <ThunderboltIcon />
                        Request trial
                    </BookCallButton>
                );
            }

            return (
                <Button
                    variant="action"
                    size="sm"
                    onClick={() => upgrade(plan, true)}
                    data-testid={`upgrade-${plan.toLowerCase()}`}
                >
                    <ThunderboltIcon />
                    Start trial
                </Button>
            );
        },
        [handleEnterpriseCallBooked, upgrade, onContinue],
    );

    return (
        <div
            className={classNames(animations.fadeIn1, commonStyles.content1100)}
        >
            <header className={animations.fadeIn2}>
                <h1 className={commonStyles.title}>
                    We recommend the{" "}
                    <em>{flatRatePlanNames[recommendedPlan!]}</em> plan
                </h1>

                <p className={commonStyles.subtitle}>
                    Start a free trial now. If you subscribe this year, you’ll
                    also get last year’s bookkeeping on the house.
                </p>
            </header>

            <Plans
                plans={plans}
                renderAction={renderAction}
                recommended={recommendedPlan}
                stickyHeaders
            />

            <Footer onBack={back} />
        </div>
    );
};
