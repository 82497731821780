import {
    EntityProfileIndustry,
    EntityProfileRevenueBracket,
    EntityProfileType,
} from "../../common/types/entity";

export enum OnboardingFlowType {
    SELF_SERVE = "SELF_SERVE",
    ACCOUNTANT = "ACCOUNTANT",
    BOOKKEEPER = "BOOKKEEPER",
    WAITLIST = "WAITLIST",
}

export type CoverStyle = "full" | "split" | "sidebar" | "none";

export interface StepConfig {
    sidebar?: string;
    cover: CoverStyle;
}

export interface EntityProfileFormSchema {
    name: string;
    type?: EntityProfileType;
    industry?: EntityProfileIndustry;
    industryOther?: string;
    annualRevenue?: EntityProfileRevenueBracket;
    country: string;
}

export interface ProfileFormSchema extends EntityProfileFormSchema {
    firstName: string;
    lastName: string;
    source: string;
}
