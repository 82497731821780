import React, { RefObject, useCallback, useEffect, useRef } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "../../general/Loader";
import "./IntegrationsSettings.scss";
import { financialConnectionsQueryKey } from "../../../queryClient";
import { useSettings } from "../SettingsProvider";
import { AddAccount } from "../../accounts/AddAccount";
import { getUserIntegrations } from "../../../lib/user";
import { FinancialConnectionsSettings } from "./FinancialConnectionsSettings";

interface Props {
    scrollbars: RefObject<Scrollbars>;
}

export const IntegrationsSettings: React.FC<Props> = ({ scrollbars }) => {
    const { path } = useSettings();
    const actionRef = useRef<string | null>(null);

    useEffect(() => {
        const [, action] = path;
        actionRef.current = action;
    }, [path]);

    const integrations = useQuery({
        queryKey: [financialConnectionsQueryKey],
        queryFn: () => getUserIntegrations(),
    });

    const handleFixAction = useCallback(() => {
        const firstBrokenConnection =
            integrations.data?.financialConnections.find(
                (fc) => !!fc.connection.error,
            );

        if (!firstBrokenConnection) {
            return;
        }

        const el = document.querySelector(
            `[data-connection="${firstBrokenConnection.connection.id}"]`,
        );

        if (el && scrollbars.current) {
            const elementRect = el.getBoundingClientRect();
            const scrollbarsRect =
                scrollbars.current.container.getBoundingClientRect();

            scrollbars.current.scrollTop(
                elementRect.top - scrollbarsRect.top - 20,
            );
        }
    }, [integrations.data, scrollbars]);

    useEffect(() => {
        if (integrations.isLoading || !actionRef.current) {
            return;
        }

        if (actionRef.current === "fix") {
            handleFixAction();
        }

        actionRef.current = null;
    }, [integrations.data, handleFixAction, integrations.isLoading]);

    if (!integrations.isSuccess) {
        return <Loader />;
    }

    return (
        <div className="settings__integrations">
            <header className="settings__header">
                <h3>Connected accounts</h3>
                <AddAccount
                    btnText="Connect new account"
                    btnVariant="secondary"
                />
            </header>

            <FinancialConnectionsSettings
                connections={integrations.data.financialConnections}
            />
        </div>
    );
};
