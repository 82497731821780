import React from "react";
import "./NavigationSubsection.scss";
import classNames from "classnames";

interface Props {
    children: React.ReactNode;
    isOpen?: boolean;
}

export const NavigationSubsection: React.FC<Props> = ({ children, isOpen }) => (
    <div
        className={classNames("navigation-subsection-container", {
            "navigation-subsection-container--opened": isOpen,
        })}
    >
        {children}
    </div>
);
