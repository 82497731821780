import { useMemo, useState } from "react";
import { SortOrder } from "../../common/constants";
import { InvoiceSort, InvoiceSortValue } from "../../common/types/invoice";

export const DEFAULT_SORT = InvoiceSortValue.DATE_DESC;

export interface UseSort {
    currentSort: InvoiceSortValue;
    handleSortChange: (value: InvoiceSortValue) => void;
    sortExpression: InvoiceSort | undefined;
}

export const getSortExpression = (sort: InvoiceSortValue) => {
    switch (sort) {
        case InvoiceSortValue.DATE_DESC:
            return {
                field: "date",
                order: SortOrder.DESC,
            };
        case InvoiceSortValue.DATE_ASC:
            return {
                field: "date",
                order: SortOrder.ASC,
            };
        case InvoiceSortValue.DUE_DATE_DESC:
            return {
                field: "dueDate",
                order: SortOrder.DESC,
            };
        case InvoiceSortValue.DUE_DATE_ASC:
            return {
                field: "dueDate",
                order: SortOrder.ASC,
            };
        case InvoiceSortValue.STATUS_ASC:
            return {
                field: "status",
                order: SortOrder.ASC,
            };
        case InvoiceSortValue.STATUS_DESC:
            return {
                field: "status",
                order: SortOrder.DESC,
            };
        case InvoiceSortValue.COUNTERPARTY_ASC:
            return {
                field: "counterparty",
                order: SortOrder.ASC,
            };
        case InvoiceSortValue.COUNTERPARTY_DESC:
            return {
                field: "counterparty",
                order: SortOrder.DESC,
            };
        case InvoiceSortValue.ACCOUNT_DESC:
            return {
                field: "financialAccount",
                order: SortOrder.DESC,
            };
        case InvoiceSortValue.ACCOUNT_ASC:
            return {
                field: "financialAccount",
                order: SortOrder.ASC,
            };
        case InvoiceSortValue.AMOUNT_DESC:
            return {
                field: "amount",
                order: SortOrder.DESC,
            };
        case InvoiceSortValue.AMOUNT_ASC:
            return {
                field: "amount",
                order: SortOrder.ASC,
            };
        case InvoiceSortValue.NONE:
            return undefined;
    }
};

export function useSort(defaultSort: InvoiceSortValue = DEFAULT_SORT): UseSort {
    const [currentSort, setCurrentSort] =
        useState<InvoiceSortValue>(defaultSort);

    const sortExpression: InvoiceSort | undefined = useMemo(
        () => getSortExpression(currentSort),
        [currentSort],
    );

    return {
        currentSort,
        handleSortChange: setCurrentSort,
        sortExpression,
    };
}
