import { keepPreviousData, QueryKey, useQuery } from "@tanstack/react-query";
import { WithRequiredProperties } from "../../common/types/base/generics";
import { GetTransactionsResponseDto } from "../../common/dto/transactions/getTransactions/get-transactions-response.dto";
import { TransactionFiltersDto } from "../../common/dto/transactions/getTransactions/transaction-filters.dto";
import { Entity } from "../../common/types/entity";
import { getTransactions } from "../../lib/transactions";
import { queryClient } from "../../queryClient";
import { Transaction } from "../../common/types/transaction";
import { TransactionsFilters } from "./filters/lib";
import { Sort } from "./useSort";

export const TRANSACTIONS_PAGE_SIZE = 100;
const TRANSACTIONS_QUERY_KEY = "transactions";
const TRANSACTIONS_CACHE_DURATION = 1000 * 30;

export interface UseTransactionsQueryParam {
    filters: WithRequiredProperties<TransactionsFilters, "entityIds">;
    sort?: Sort;
    page: number;
    limit?: number;
}

const loadTransactions = ({
    filters,
    sort,
    page,
    limit,
}: UseTransactionsQueryParam) =>
    filters.entityIds.length > 0
        ? getTransactions({
              page: page - 1,
              limit: limit ?? TRANSACTIONS_PAGE_SIZE,
              filters: convertFiltersToDto(filters),
              sort,
          })
        : { data: [], total: 0, pageCount: 0 };

const queryKey = ({
    filters,
    sort,
    page,
    limit,
}: UseTransactionsQueryParam): QueryKey => [
    TRANSACTIONS_QUERY_KEY,
    filters,
    sort,
    page,
    limit,
];

export function useTransactionsQuery({
    filters,
    sort,
    page,
    limit,
}: UseTransactionsQueryParam) {
    const qk = queryKey({ filters, sort, page, limit });
    return {
        query: useQuery({
            queryKey: qk,
            queryFn: () => loadTransactions({ filters, sort, page, limit }),
            staleTime: TRANSACTIONS_CACHE_DURATION,
            placeholderData: keepPreviousData,
        }),
        queryKey: qk,
    };
}

export const preloadTransactionsQuery = ({
    filters,
    sort,
    page,
    limit,
}: UseTransactionsQueryParam): void => {
    queryClient.prefetchQuery({
        queryKey: queryKey({ filters, sort, page, limit }),
        queryFn: () => loadTransactions({ filters, sort, page, limit }),
        staleTime: TRANSACTIONS_CACHE_DURATION,
    });
};

export function convertFiltersToDto(
    filters: WithRequiredProperties<TransactionsFilters, "entityIds">,
): TransactionFiltersDto;
export function convertFiltersToDto<T extends TransactionsFilters>(
    filters: T,
    entities: Entity[],
): TransactionFiltersDto;
export function convertFiltersToDto<T extends TransactionsFilters>(
    filters: T,
    entities?: Entity[],
): TransactionFiltersDto {
    return {
        ...filters,
        entityIds: filters.entityIds
            ? filters.entityIds
            : entities?.map((entity) => entity.id) ?? [],
        requiredAction: filters.requiredAction || undefined, // nosonar - false is first converted to string 'false', and then backend interprets it as truthy
    };
}

export async function invalidateTransactionsQueries(): Promise<void> {
    return await queryClient.invalidateQueries({
        queryKey: [TRANSACTIONS_QUERY_KEY],
    });
}

export function updateTransactionInTransactionsQuery(
    transactionId: number,
    transactionUpdate: Partial<Transaction>,
) {
    queryClient.setQueriesData({ queryKey: [TRANSACTIONS_QUERY_KEY] }, ((
        oldData: GetTransactionsResponseDto | undefined,
    ) => {
        if (!oldData) {
            return oldData;
        }

        return {
            ...oldData,
            data: oldData.data.map((fetchedTransaction) => {
                const isTargetTransaction =
                    fetchedTransaction.id === transactionId;
                if (isTargetTransaction) {
                    return {
                        ...fetchedTransaction,
                        ...transactionUpdate,
                    };
                }
                return fetchedTransaction;
            }),
        };
    }) as any);
}
