import { Action } from "../../types/actions";
import { ActionDto } from "./action.dto";

export interface GetActionsResponse {
    actions: Action[];
}

export class GetActionsResponseDto {
    actions!: ActionDto[];

    static deserialize(dto: GetActionsResponseDto): GetActionsResponse {
        return {
            actions: dto.actions.map((action) => ActionDto.deserialize(action)),
        };
    }

    constructor(actions: Action[]) {
        this.actions = actions.map((action) => new ActionDto(action));
    }
}
