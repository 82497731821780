import React from "react";

import { useClasses } from "../../../api/class.api";
import { TransactionClassFilterType } from "../../../common/helpers/transactions";
import { pluralize } from "../../../common/helpers/string";
import { FiltersTypes } from "../../../common/types/filters/general";

interface ClassFilterLabelProps {
    filters: Pick<FiltersTypes, "classes" | "classFilterType">;
}

export const ClassFilterLabel: React.FC<ClassFilterLabelProps> = ({
    filters: { classes: classIds, classFilterType },
}) => {
    const { classesMap } = useClasses();

    if (
        classIds?.length === 0 ||
        classFilterType === TransactionClassFilterType.NO_CLASSES
    ) {
        return "No classes";
    }

    if (!classIds) {
        return null;
    }

    if (classIds?.length === 1) {
        return <>{classesMap.get(classIds[0])?.label}</>;
    }

    return (
        <>
            {classIds?.length} {pluralize("Class", classIds?.length)}
        </>
    );
};
