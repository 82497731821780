import { Entity, EntityProfileIndustry } from "../../common/types/entity";
import { OnboardingFlowType } from "./types";

export const WAITLIST_INDUSTRY = [
    EntityProfileIndustry.CONSTRUCTION,
    EntityProfileIndustry.CRYPTO,
    EntityProfileIndustry.MANUFACTURING,
    EntityProfileIndustry.NON_PROFIT,
    EntityProfileIndustry.PHYSICAL_GOODS,
    EntityProfileIndustry.PROPERTY_MANAGEMENT,
    EntityProfileIndustry.REAL_ESTATE_INVESTOR,
    EntityProfileIndustry.RETAIL,
    EntityProfileIndustry.SAAS,
    EntityProfileIndustry.RESTAURANT,
];

export const OLD_WAITLIST = [
    "ecommerce",
    "healthcare",
    "manufacturing",
    "marketplace",
    "real_estate",
    "retail",
    "technology",
    "venture_capital",
    "consumer_goods",
];

export function getOnboardingFlow(entity?: Entity): OnboardingFlowType {
    if (!entity?.profile?.industry || !entity?.profile.type) {
        return OnboardingFlowType.SELF_SERVE;
    }

    if (entity.profile.ignoreWaitlist) {
        return OnboardingFlowType.SELF_SERVE;
    }

    if (
        WAITLIST_INDUSTRY.includes(entity.profile.industry) ||
        OLD_WAITLIST.includes(entity.profile.industry) ||
        entity.profile.country !== "US"
    ) {
        return OnboardingFlowType.WAITLIST;
    }

    if (entity.profile.industry === EntityProfileIndustry.ACCOUNTANT) {
        return OnboardingFlowType.ACCOUNTANT;
    }

    if (entity.profile.industry === EntityProfileIndustry.BOOKKEEPER) {
        return OnboardingFlowType.BOOKKEEPER;
    }

    return OnboardingFlowType.SELF_SERVE;
}
