import React from "react";
import { EagerLoaded } from "../../../../../common/types/base/orm";
import { FinancialDocument } from "../../../../../common/types/financialDocument";
import { formatDate } from "../../../../documents/lib";
import { DocumentName } from "../../../../documents/DocumentName";
import { ZoomInIcon } from "../../../../../icons";
import { useCreateLinkToFinancialDocument } from "../../../../../mutations/transaction";
import { ButtonWithLoader } from "../../../../general/ButtonWithLoader/ButtonWithLoader";
import { Transaction } from "../../../../../common/types/transaction";
import { Button } from "../../../../general/Button/Button";
import styles from "./MatchingView.module.scss";

interface Props {
    financialTransaction: EagerLoaded<Transaction, "transactionMatches">;
    financialDocument: FinancialDocument;
    onSelected(): void;
    onAttached(): void;
}

export const MatchingViewDocumentRow: React.FC<Props> = ({
    financialDocument,
    financialTransaction,
    onSelected,
    onAttached,
}) => {
    const alreadyMatched = financialTransaction.transactionMatches.some(
        (tm) => tm.financialDocument?.id === financialDocument.id,
    );

    const createLink = useCreateLinkToFinancialDocument(
        financialTransaction,
        financialDocument,
        onAttached,
    );

    return (
        <tr>
            <td className={styles.dateValue}>
                {formatDate(financialDocument.createdAt)}
            </td>
            <td>
                <DocumentName fileName={financialDocument.fileName} icon />
            </td>
            <td>
                <span className={styles.actions}>
                    <Button
                        variant="secondary"
                        icon
                        size="sm"
                        onClick={onSelected}
                    >
                        <ZoomInIcon />
                    </Button>

                    <ButtonWithLoader
                        variant="secondary"
                        size="sm"
                        mutation={createLink}
                        disabled={alreadyMatched}
                    >
                        {alreadyMatched ? "Attached" : "Attach"}
                    </ButtonWithLoader>
                </span>
            </td>
        </tr>
    );
};
