import { SubscriptionDiscount } from "./types/billing";

export enum FLAT_RATE_PLANS {
    FREE = "FREE",
    BASIC = "BASIC",
    PLUS = "PLUS",
    ENTERPRISE = "ENTERPRISE",
}

export enum PREMIUM_FEATURES {
    UNLIMITED_ENTITIES = "UNLIMITED_ENTITIES",
    CUSTOM_CATEGORIES = "CUSTOM_CATEGORIES",
    BALANCE_SHEET = "BALANCE_SHEET",
    GENERAL_LEDGER = "GENERAL_LEDGER",
    PAYROLL = "PAYROLL",
    EXPORT_1099_REPORT = "EXPORT_1099_REPORT",
}

export interface FlatRatePlan {
    plan: FLAT_RATE_PLANS;
    features: PREMIUM_FEATURES[];
    billingInterval: number; // in months
    monthlyAmountCents: number;
    intervalAmountCents: number;
    usageLimit: number;
}

export const flatRatePlanNames = {
    [FLAT_RATE_PLANS.BASIC]: "Basic",
    [FLAT_RATE_PLANS.PLUS]: "Plus",
    [FLAT_RATE_PLANS.ENTERPRISE]: "Enterprise",
    [FLAT_RATE_PLANS.FREE]: "Free",
} as const satisfies Record<FLAT_RATE_PLANS, string>;

export interface FlatRatePrice {
    total: number;
    totalBeforeDiscount: number;
    discount: SubscriptionDiscount | null;
    invalidDiscountCode: boolean;
    nextBillingDate: Date;
}

export const TRIAL_LENGTH_DAYS = 14;
export const PAID_PLAN_USAGE_LIMIT = 250_000;
export const FREE_MONTHS_FOR_TRIALING = 3;
export const FREE_MONTHS_FOR_FREEMIUM = 3;
