import { difference, keys } from "lodash";
import { XYSeries } from "@amcharts/amcharts4/.internal/charts/series/XYSeries";
import { ColumnSeries, XYChart } from "@amcharts/amcharts4/charts";
import { format, parse } from "date-fns";
import { Color } from "@amcharts/amcharts4/core";
import { currencyFormatterFinancialStatements } from "../../../common/helpers/currency";
import {
    ProfitLossChartDataSeriesCalculatedIdentifier,
    ProfitLossChartReportSeriesData,
    ProfitLossChartReportSeriesIdentifier,
    ProfitLossTopTransactionsDto,
} from "../../../common/dto/reports/reporting-tab-profit-loss-response.dto";
import { CategoryLabelGetter } from "../../../hooks/useCategoryLabelGetter";
import { styleDefaultChartTooltip } from "../../../lib/charts";
import { ReportFilters } from "../../../common/types/filters/reports";
import {
    getCycleEndForDate,
    getLabelFormatForCycle,
    printLabel,
} from "../../../common/helpers/reports";
import { getProfitLossTabTopTransactions } from "../../../lib/ownerReports";
import { REPORTS_CONTROLLER_DATE_FORMAT } from "../../../common/constants";
import { Category } from "../../../common/types/category";
import { isUUID } from "../../../common/helpers/uuid";

interface ReportTooltipParams {
    chart: XYChart;
    seriesToHookInto: ColumnSeries;
    filters: ReportFilters;
    categoriesById: Record<string, Category>;
}

export interface ReportTooltipSectionsRenderParams {
    item: XYSeries;
    getCategoryLabel: CategoryLabelGetter;
    seriesColorMap: Record<string, string>;
    filters: ReportFilters;
}

const PL_TOOLTIP_WIDTH = 280;

function renderTransactionRow(label: string, value: number, isTotal?: boolean) {
    const mainClass = isTotal
        ? "pl-chart-tooltip__item pl-chart-tooltip__item--total"
        : "pl-chart-tooltip__item";
    return `<div class="${mainClass}">
                    <div class="pl-chart-tooltip__item__label">${label}</div>
                    <div class="pl-chart-tooltip__item__value">${currencyFormatterFinancialStatements.format(
                        value,
                        {
                            addCurrency: true,
                            displaySign: false,
                        },
                    )}</div>
                </div>`;
}

interface RenderTooltipHTMLParams {
    seriesToHookInto: ColumnSeries;
    data: ProfitLossChartReportSeriesData;
    filters: ReportFilters;
    topTransactions?: ProfitLossTopTransactionsDto;
    categoriesById: Record<string, Category>;
}

function renderTooltipHTML({
    seriesToHookInto,
    data,
    filters,
    topTransactions,
    categoriesById,
}: RenderTooltipHTMLParams) {
    const tooltipSections = [];
    const seriesName = isUUID(seriesToHookInto.name)
        ? categoriesById[seriesToHookInto.name].label
        : seriesToHookInto.name;
    tooltipSections.push(
        `<div class="pl-chart-tooltip__header">
                <div class="pl-chart-tooltip__header__bullet" style="--bullet-color: ${
                    (seriesToHookInto.fill as Color).hex
                }"></div>
                <span class="pl-chart-tooltip__header__label">${seriesName}</span>
            </div>`,
    );
    tooltipSections.push(
        `<div class="pl-chart-tooltip__item pl-chart-tooltip__item--date-range">${printLabel(
            data.label,
            filters,
            true,
        )}</div>`,
    );

    let topTransactionsSection = "";
    if (topTransactions && topTransactions.top?.length > 0) {
        for (const transaction of topTransactions.top) {
            topTransactionsSection += renderTransactionRow(
                transaction.description,
                transaction.value,
            );
        }
        if (topTransactions.other !== 0) {
            topTransactionsSection += renderTransactionRow(
                "Other",
                topTransactions.other,
            );
        }
    } else {
        topTransactionsSection = `<div class="pl-chart-tooltip__item">Loading transactions data...</div>`;
    }
    tooltipSections.push(topTransactionsSection);
    const total =
        data[
            seriesToHookInto.dataFields
                .valueY as ProfitLossChartDataSeriesCalculatedIdentifier
        ] ?? 0;
    tooltipSections.push(
        `<div class="pl-chart-tooltip__item pl-chart-tooltip__item--spacer"></div>`,
    );
    tooltipSections.push(renderTransactionRow("Total:", total, true));

    const tooltipHTML = tooltipSections.reduce((acc, html) => acc + html, "");

    return `<div class="pl-chart-tooltip" style="--tooltip-width: ${PL_TOOLTIP_WIDTH}px">
                ${tooltipHTML}
            </div>`;
}

interface GetTopTransactionsDtoParams {
    filters: ReportFilters;
    data: ProfitLossChartReportSeriesData;
    seriesToHookInto: ColumnSeries;
    cacheBuster?: number;
}

function getTopTransactionsDto({
    filters,
    data,
    seriesToHookInto,
    cacheBuster,
}: GetTopTransactionsDtoParams) {
    const start = parse(
        data.label,
        getLabelFormatForCycle(filters.cycle),
        new Date(),
    );

    return {
        startDate: format(start, REPORTS_CONTROLLER_DATE_FORMAT),
        endDate: format(
            getCycleEndForDate(start, filters.cycle),
            REPORTS_CONTROLLER_DATE_FORMAT,
        ),
        entityIds: filters.entityIds,
        categoryIdentifier: seriesToHookInto.dataFields
            .valueY as ProfitLossChartReportSeriesIdentifier,
        itemsShown: difference(keys(data), [
            "label",
        ]) as ProfitLossChartReportSeriesIdentifier[],
        cacheBuster,
    };
}

export function generateSingleCategoryTooltip(
    { chart, seriesToHookInto, filters, categoriesById }: ReportTooltipParams,
    reportCreatedAt?: number,
) {
    seriesToHookInto.columns.template.adapter.add(
        "tooltipHTML",
        function (_, item) {
            const data = item.tooltipDataItem
                .dataContext as ProfitLossChartReportSeriesData;
            getProfitLossTabTopTransactions(
                getTopTransactionsDto({
                    filters,
                    data,
                    seriesToHookInto,
                    cacheBuster: reportCreatedAt,
                }),
            ).then((res) => {
                if (seriesToHookInto.tooltip) {
                    seriesToHookInto.tooltip.html = renderTooltipHTML({
                        seriesToHookInto,
                        data,
                        filters,
                        topTransactions: res,
                        categoriesById,
                    });
                }
            });

            return renderTooltipHTML({
                seriesToHookInto,
                data,
                filters,
                categoriesById,
            });
        },
    );
    seriesToHookInto.columns.template.adapter.add(
        "tooltipX",
        function (tooltipX, target) {
            const barX = target.x as number;
            const barWidth = target.width as number;

            if (barX + barWidth / 2 < chart.pixelWidth / 2) {
                return barWidth + 8 + PL_TOOLTIP_WIDTH;
            } else {
                return -8;
            }
        },
    );

    styleDefaultChartTooltip(seriesToHookInto);

    if (seriesToHookInto.tooltip) {
        seriesToHookInto.tooltip.dy = 0;
        seriesToHookInto.tooltip.label.padding(0, 0, 0, 0);
        seriesToHookInto.tooltip.background.opacity = 0.95;
        seriesToHookInto.tooltip.pointerOrientation = "right";
    }
}
