import React from "react";

interface Props {
    categoryLabel: string;
}

export const AuditLogRuleContent: React.FC<Props> = ({ categoryLabel }) => (
    <>
        Category set to <strong>{categoryLabel}</strong> because transaction was
        matched by rule.
    </>
);
