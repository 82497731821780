import React, { useCallback, useMemo } from "react";
import { PREMIUM_FEATURES } from "../../../common/flatRateBilling";
import { useSettings } from "../../settings/SettingsProvider";
import { useAllowFeature } from "../../../hooks/useAllowFeature";
import { useBillingStatus } from "../../../hooks/useBillingStatus";

interface BlockedFeatureHelpers {
    openPlans: () => void;
    isTrialing: boolean;
}

interface FeatureAccessProps {
    feature: PREMIUM_FEATURES;
    disallowTrials?: boolean;
    renderAllowed: () => React.ReactElement | null;
    renderBlocked: (
        helpers: BlockedFeatureHelpers,
    ) => React.ReactElement | null;
}

export const FeatureAccess: React.FC<FeatureAccessProps> = ({
    feature,
    disallowTrials,
    renderAllowed,
    renderBlocked,
}) => {
    const { isFetched, isTrialing } = useBillingStatus();
    const { open } = useSettings();
    const allowed = useAllowFeature(feature, disallowTrials);

    const openPlans = useCallback(() => {
        open(["plans"]);
    }, [open]);

    const helpers: BlockedFeatureHelpers = useMemo(
        () => ({ openPlans, isTrialing }),
        [openPlans, isTrialing],
    );

    if (!isFetched) {
        return null;
    }

    return allowed ? renderAllowed() : renderBlocked(helpers);
};
