import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import classNames from "classnames";
import styles from "./Button.module.scss";

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    asChild?: boolean;
    variant?: "default" | "action" | "secondary" | "tertiary" | "danger";
    size?: "default" | "sm" | "lg";
    icon?: boolean;
    block?: boolean;
}

const ButtonCore = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            className,
            variant = "default",
            size = "default",
            asChild = false,
            block = false,
            icon,
            ...props
        },
        ref,
    ) => {
        const Comp = asChild ? Slot : "button";

        const buttonClass = classNames(
            styles.kickButton,
            {
                [styles.small]: size === "sm",
                [styles.regular]: size === "default",
                [styles.large]: size === "lg",
            },
            {
                [styles.primary]: variant === "default",
                [styles.secondary]: variant === "secondary",
                [styles.tertiary]: variant === "tertiary",
                [styles.action]: variant === "action",
                [styles.danger]: variant === "danger",
            },
            icon && styles.icon,
            block && styles.block,
            className,
        );

        return <Comp className={buttonClass} ref={ref} {...props} />;
    },
);
ButtonCore.displayName = "ButtonCore";

export { ButtonCore };
