import React, { useCallback } from "react";
import { ONBOARDING_CALL_URL } from "../../../common/billing";
import { ActionStatus } from "../../../common/types/actions";
import { CalendarIcon, CheckIcon } from "../../../icons";
import { BookCallButton } from "../../general/BookCall/BookCallButton";
import { ActionCard } from "../ActionCard";
import styles from "../ActionCard.module.scss";
import { ActionCardCompleted } from "../ActionCardCompleted";
import { ActionCardSection } from "../ActionCardSection";
import { ActionCloseButton } from "../ActionCloseButton";
import { CampaignCardProps } from "../ActionItem";
import { Button } from "../../general/Button/Button";

export const BookOnboardingCallCard: React.FC<CampaignCardProps> = ({
    action,
    onConfirm,
    onSkip,
    ...rest
}) => {
    const { isCompleted } = rest;

    const confirmAction = useCallback(async () => {
        onConfirm?.({
            ...action,
        });
    }, [onConfirm, action]);

    const skipAction = useCallback(() => {
        onSkip?.(action);
    }, [action, onSkip]);

    const firstSection = (inOpened: boolean, closeCard: () => void) =>
        isCompleted ? (
            <ActionCardCompleted header="Kick review call booked">
                <ActionCloseButton onClick={closeCard} />
            </ActionCardCompleted>
        ) : (
            <ActionCardSection
                inOpened={inOpened}
                header="Talk to a Kick expert"
                icon={<CalendarIcon />}
                isTitle
                ctas={
                    <>
                        {action.status !== ActionStatus.SKIPPED && (
                            <Button
                                size="sm"
                                variant="secondary"
                                onClick={skipAction}
                            >
                                Skip
                            </Button>
                        )}
                        <BookCallButton
                            keyboardShortcut="Enter"
                            url={ONBOARDING_CALL_URL}
                            className={styles.confirmButton}
                            size="sm"
                            onBooked={confirmAction}
                        >
                            Book call
                        </BookCallButton>
                    </>
                }
            />
        );

    const otherSections = (inOpened: boolean) => (
        <ActionCardSection
            header={
                <span className="d-flex align-items-center">
                    Schedule a 20-min account review{" "}
                    <img
                        className="ml-2"
                        src="/images/experts.png"
                        alt="Experts"
                    />
                </span>
            }
            inOpened={inOpened}
        >
            <ul className="list-unstyled">
                <li className="mb-2">
                    <CheckIcon className="icon-color-blue-600 icon-size-sm mr-2" />
                    Personalize your account
                </li>
                <li className="mb-2">
                    <CheckIcon className="icon-color-blue-600 icon-size-sm mr-2" />
                    Walk through the platform
                </li>
                <li className="mb-2">
                    <CheckIcon className="icon-color-blue-600 icon-size-sm mr-2" />
                    Discuss accountant support
                </li>
            </ul>
        </ActionCardSection>
    );

    return (
        <ActionCard
            {...rest}
            firstSection={firstSection}
            otherSections={otherSections}
        />
    );
};
