import React from "react";
import { TransactionRule } from "../../common/types/transactionRule";
import { RuleCategoryAction } from "./RuleAction/RuleCategoryAction";
import { RuleEntityAction } from "./RuleAction/RuleEntityAction";

interface Props {
    rule: TransactionRule;
}

export const TransactionRuleActions: React.FC<Props> = ({ rule }) => (
    <>
        <RuleCategoryAction rule={rule} /> <RuleEntityAction rule={rule} />
    </>
);
