import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useActiveNavLink = (parent: HTMLElement | null) => {
    const [activeElement, setActiveElement] =
        useState<HTMLAnchorElement | null>(null);
    const location = useLocation();

    useEffect(() => {
        if (!parent) {
            return;
        }
        const active = parent.querySelector("a.active") as HTMLAnchorElement;
        setActiveElement(active);
    }, [location, parent]);

    return activeElement;
};
