import { ZodObject, ZodRawShape } from "zod";
import { useCallback, useMemo } from "react";
import { useWorkspaceContext } from "../state/workspaceContext";
import { useUser } from "./useUser";

export enum WORKSPACE_USER_CACHE_KEYS {
    LAST_ENTITY_CACHE_KEY = "accounting-page-last-entity",
    LAST_DATE_PERIOD_CACHE_KEY = "accounting-page-last-date-period",
    ACCOUNTS_CACHE_KEY = "activity-page-selected-accounts",
    TRANSACTIONS_PAGE_SORTING_CACHE_KEY = "transactions-page-sorting-cache",
    TRANSACTIONS_PAGE_FILTERS_CACHE_KEY = "transactions-page-filters-cache",
    INVOICES_PAGE_FILTERS_CACHE_KEY = "invoices-page-filters-cache",
    INVOICES_PAGE_SORTING_CACHE_KEY = "invoices-page-sorting-cache",
    CASHFLOW_PAGE_FILTERS_CACHE_KEY = "cashflow-page-filters-cache",
    DIRECTORY_FILTERS_CACHE_KEY = "directory-filters-cache",
}

/**
 * This cache is workspace scoped, and is cleared on every login and logout.
 * WARNING: This hook only reads value from localStorage on mount, it won't refresh for already mounted component.
 * @param key Cache key that will be used to create the final key which contains the workspaceid and the user id as well
 * @param zodSchema If specified, the data returned from the cache is going to be parsed. When parsing fails, undefined is returned
 * @returns
 */
export function useWorkspaceUserCache<T = any>(
    key: WORKSPACE_USER_CACHE_KEYS,
    zodSchema?: ZodObject<ZodRawShape>,
): [T | undefined, (value: T | undefined) => void] {
    const { activeWorkspaceKey } = useWorkspaceContext();
    const user = useUser();
    const finalKey = `${activeWorkspaceKey}/${key}-${user.id}`;

    const value = useMemo<T>(() => {
        const rawValue = localStorage.getItem(finalKey);

        if (!rawValue) {
            return undefined;
        }

        let parsedValue = JSON.parse(rawValue);

        if (zodSchema) {
            if (zodSchema.safeParse(parsedValue).success) {
                parsedValue = zodSchema.parse(parsedValue);
            } else {
                parsedValue = undefined;
            }
        }

        return parsedValue;
    }, [finalKey, zodSchema]);

    const setter = useCallback(
        (v: T | undefined) => {
            if (v === undefined) {
                localStorage.removeItem(finalKey);
            } else {
                localStorage.setItem(finalKey, JSON.stringify(v));
            }
        },
        [finalKey],
    );

    return [value, setter];
}
