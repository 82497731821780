import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useMemo } from "react";
import { useWorkspaceContext } from "../state/workspaceContext";
import { getUserEntities } from "../lib/user";
import { ADMIN_ROLES } from "../common/constants";
import { getEntitiesAccountsQueryKey } from "../queries/entitiesAccounts";
import { getTotalBalance } from "./useFinancialAccounts";

interface Options {
    requireAdminAccess?: boolean;
    onlyBusiness?: boolean;
    onlyPersonal?: boolean;
    onlyWithBalance?: boolean;
    excludeMockEntities?: boolean;
    excludeEmptyMockEntities?: boolean;
    reportsReady?: boolean;
}

type TQueryResult = Awaited<ReturnType<typeof getUserEntities>>;

export function useEntitiesContexts(
    {
        requireAdminAccess,
        onlyBusiness,
        onlyPersonal,
        onlyWithBalance,
        excludeMockEntities,
        excludeEmptyMockEntities = true,
        reportsReady,
    }: Options = {},
    queryOptions?: Omit<UseQueryOptions<TQueryResult>, "queryKey">,
) {
    const { activeWorkspace } = useWorkspaceContext();

    const query = useQuery<TQueryResult>({
        queryKey: getEntitiesAccountsQueryKey(activeWorkspace?.id ?? ""),
        queryFn: async () => {
            if (!activeWorkspace) {
                return undefined;
            }

            return await getUserEntities(activeWorkspace.id);
        },
        ...(queryOptions ?? {}),
        refetchOnMount: false,
        enabled: !!activeWorkspace,
    });

    return useMemo(() => {
        if (!query.data) {
            return undefined;
        }

        let contexts = query.data.entities;

        if (requireAdminAccess) {
            contexts = contexts.filter((e) => ADMIN_ROLES.includes(e.role));
        }
        if (onlyBusiness) {
            contexts = contexts.filter((e) => e.entity.isBusiness);
        }
        if (onlyPersonal) {
            contexts = contexts.filter((e) => e.entity.isPersonal);
        }
        if (excludeMockEntities) {
            contexts = contexts.filter((e) => !e.entity.isMockEntity);
        }
        if (excludeEmptyMockEntities) {
            contexts = contexts.filter(
                (e) => !e.entity.isMockEntity || e.financialAccounts.length > 0,
            );
        }
        if (onlyWithBalance) {
            contexts = contexts.filter(
                (e) => getTotalBalance(e.financialAccounts) > 0,
            );
        }

        if (reportsReady) {
            contexts = contexts.filter((e) => e.entity.reportsReady);
        }

        return contexts;
    }, [
        query.data,
        requireAdminAccess,
        onlyBusiness,
        onlyPersonal,
        excludeMockEntities,
        excludeEmptyMockEntities,
        onlyWithBalance,
        reportsReady,
    ]);
}
