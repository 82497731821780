import React, { useState } from "react";
import { UniqueIdentifier } from "@dnd-kit/core";
import classNames from "classnames";
import { AccountCard } from "../general/AccountCard/AccountCard";
import { FinancialAccountManagementDropdown } from "../financialAccount/FinancialAccountManagementDropdown";
import { FinancialAccount } from "../../common/types/financialAccount";
import { FinancialAccountBalance } from "../financialAccount/FinancialAccountBalance";
import { getLeadingAccountConnection } from "../../common/helpers/financialAccount";
import { EntityAccountDragHandle } from "./EntityAccountDragHandle";
import { EntityRow } from "./EntityRow";
import { EntityAccountLastSync } from "./EntityAccountLastSync";

interface Props {
    account: FinancialAccount;
    sortableId?: UniqueIdentifier;
}

export const EntityAccount: React.FC<Props> = ({ account, sortableId }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const connection = getLeadingAccountConnection(account);

    return (
        <EntityRow
            className={classNames("entity__account", {
                "entity__account--disabled": account.disabled,
                "entity__account--active": dropdownOpen,
            })}
            entityOrAccount={
                <AccountCard
                    account={account}
                    connection={connection ?? undefined}
                    numberInName
                    showType={false}
                />
            }
            lastSync={<EntityAccountLastSync financialAccount={account} />}
            balanceAndActions={
                <div className="d-flex justify-content-between">
                    <div
                        className="entity__account__balance"
                        data-testid="account-balance"
                    >
                        <FinancialAccountBalance account={account} />
                    </div>
                    <div className="entity__account__actions">
                        {sortableId && (
                            <EntityAccountDragHandle sortableId={sortableId} />
                        )}

                        <FinancialAccountManagementDropdown
                            account={account}
                            connection={connection ?? undefined}
                            onToggle={setDropdownOpen}
                        />
                    </div>
                </div>
            }
        />
    );
};
