import React, { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import classNames from "classnames";
import { AccountWithTransactions } from "../../../common/dto/reports/get-account-transactions-report-response.dto";
import { StickyDataTable } from "../../general/DataTable/StickyDataTable";
import { GeneralLedgerTable } from "./GeneralLedgerTable";
import styles from "./GeneralLedgerTableContainer.module.scss";

interface Props {
    data: AccountWithTransactions[];
}

export const GeneralLedgerTableContainer: React.FC<Props> = ({ data }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const scrollbarsRef = useRef<Scrollbars>(null);
    const [amountColOffset, setAmountColOffset] = useState<number>();

    useEffect(() => {
        window.setTimeout(() => {
            // trigger sticky columns
            scrollbarsRef.current?.scrollToRight();
            scrollbarsRef.current?.scrollToLeft();

            const boundingRect = containerRef.current
                ?.querySelector("td:last-child")
                ?.getBoundingClientRect();

            if (boundingRect) {
                setAmountColOffset(boundingRect.width - 1); // decrease by border width
            }
        }, 0);
    }, [data]);

    return (
        <div
            className={classNames("data-table", styles.container)}
            style={
                {
                    "--amount-col-offset": `${amountColOffset}px`,
                } as React.CSSProperties
            }
            ref={containerRef}
        >
            <StickyDataTable
                containerRef={containerRef}
                ref={scrollbarsRef}
                stickyRight
            >
                <GeneralLedgerTable data={data} />
            </StickyDataTable>
        </div>
    );
};
