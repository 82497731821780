import { useMutation } from "@tanstack/react-query";
import {
    FinancialDocument,
    FinancialDocumentBase,
} from "../common/types/financialDocument";
import {
    changeEntityForFinancialDocument,
    createLinkFinancialDocumentToFinancialTransaction,
    detachDocumentFromFinancialTransaction,
    removeFinancialDocument,
} from "../lib/financialDocument";
import { useFinancialDocumentsQuery } from "../components/documents/useFinancialDocumentsQuery";
import { queryClient } from "../queryClient";
import { Entity } from "../common/types/entity";
import {
    financialDocumentQueryKeys,
    financialDocumentsForFinancialAccountKeys,
} from "../components/documents/lib";
import { TransactionMatch } from "../common/types/transactionMatch";
import { invalidateTransactionDetailsQueries } from "../components/transactions/TransactionDetails/useTransactionDetailsQuery";
import { useBillingStatus } from "../hooks/useBillingStatus";

type FetchFinancialQueryResult = ReturnType<
    typeof useFinancialDocumentsQuery
>["data"];

export function updateDocument(updatedDocument: FinancialDocument) {
    queryClient.setQueryData(
        financialDocumentQueryKeys.detail(updatedDocument.id),
        updatedDocument,
    );
    queryClient.setQueriesData<FetchFinancialQueryResult>(
        { queryKey: financialDocumentQueryKeys.lists() },
        (oldData) => {
            if (!oldData) {
                return;
            }

            return {
                ...oldData,
                data: oldData.data.map((document) =>
                    document.id === updatedDocument.id
                        ? updatedDocument
                        : document,
                ),
            };
        },
    );
}

export function useDetachFinancialDocumentMutation(
    financialDocument: FinancialDocument,
    transactionMatch: TransactionMatch,
) {
    const originalMatches = financialDocument.transactionMatches;

    return useMutation({
        mutationFn: async () =>
            detachDocumentFromFinancialTransaction(
                financialDocument.id,
                transactionMatch.id,
            ),
        async onMutate() {
            await queryClient.cancelQueries({
                queryKey: financialDocumentQueryKeys.details(),
            });

            updateDocument({
                ...financialDocument,
                transactionMatches: financialDocument.transactionMatches.filter(
                    (tm) => tm.id !== transactionMatch.id,
                ),
            });

            return financialDocument;
        },
        async onSuccess() {
            await queryClient.invalidateQueries({
                queryKey: financialDocumentQueryKeys.all(),
            });
        },
        onError() {
            if (financialDocument) {
                updateDocument({
                    ...financialDocument,
                    transactionMatches: originalMatches,
                });
            }
        },
    });
}

export function useRemoveFinancialDocumentMutation() {
    const { invalidate: invalidateCreditsUsed } = useBillingStatus();

    return useMutation({
        mutationFn: (financialDocument: FinancialDocumentBase) =>
            removeFinancialDocument(financialDocument.id),
        onMutate(financialDocument) {
            queryClient.setQueriesData<FetchFinancialQueryResult>(
                { queryKey: financialDocumentQueryKeys.lists() },
                (oldData) => {
                    if (!oldData?.data) {
                        return;
                    }

                    return {
                        ...oldData,
                        data: oldData.data.filter(
                            (document) => document.id !== financialDocument.id,
                        ),
                        total: oldData.total - 1,
                    };
                },
            );
        },
        async onSettled() {
            await queryClient.invalidateQueries({
                queryKey: financialDocumentQueryKeys.lists(),
            });

            await invalidateCreditsUsed();
            await invalidateTransactionDetailsQueries();

            await queryClient.invalidateQueries({
                queryKey: financialDocumentsForFinancialAccountKeys.all(),
            });
        },
    });
}

interface ChangeEntityMutationVariables {
    financialDocument: FinancialDocument;
    newEntity: Entity;
}

export function useChangeEntityMutation() {
    return useMutation({
        mutationFn: async ({
            financialDocument,
            newEntity,
        }: ChangeEntityMutationVariables) =>
            changeEntityForFinancialDocument({
                financialDocumentId: financialDocument.id,
                entityId: newEntity.id,
            }),

        async onMutate({ financialDocument, newEntity }) {
            await queryClient.cancelQueries({
                queryKey: financialDocumentQueryKeys.all(),
            });

            const updatedDocument = {
                ...financialDocument,
                entity: newEntity,
            };

            updateDocument(updatedDocument);

            return financialDocument;
        },
        async onSuccess() {
            await queryClient.invalidateQueries({
                queryKey: financialDocumentQueryKeys.all(),
            });
        },
        onError(_, __, financialDocument) {
            if (financialDocument) {
                updateDocument(financialDocument);
            }
        },
    });
}

export function useCreateLinkFinancialDocumentToFinancialTransactionMutation(
    financialDocument: FinancialDocument,
    onLinked?: () => Promise<unknown> | void,
) {
    return useMutation({
        mutationFn: (financialTransactionId: number) =>
            createLinkFinancialDocumentToFinancialTransaction({
                financialDocumentId: financialDocument.id,
                financialTransactionId,
            }),
        async onSuccess() {
            await queryClient.invalidateQueries({
                queryKey: financialDocumentQueryKeys.all(),
            });

            await onLinked?.();
        },
    });
}
