import React, { useCallback, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Counterparty } from "../../common/types/counterparty";
import { CustomSelect } from "../forms/CustomSelect/CustomSelect";
import { useWorkspaceContext } from "../../state/workspaceContext";
import {
    CustomSelectDefaultTrigger,
    CustomSelectDefaultTriggerProps,
} from "../forms/CustomSelect/CustomSelectDefaultTrigger";
import { useDebouncedEffect } from "../../hooks/useDebouncedEffect";
import { SEARCH_DEBOUNCE_TIME } from "../../constants";
import {
    CounterpartiesReturnFormat,
    CounterpartySort,
} from "../../common/constants/counterparty";
import {
    CounterpartyDirection,
    CounterpartyDirectionLabels,
    RegisteredCounterpartyIncludeScope,
} from "../../common/helpers/counterparties";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";
import { PlusIcon } from "../../icons";
import {
    counterpartiesQueryKeys,
    getCounterparties,
    useCreateCounterpartyMutation,
} from "../../api/counterparty.api";
import { CounterpartyCreationResponse } from "../../common/contracts/counterparty.contract";
import { useToaster } from "../general/ToastMessages/useToaster";
import { StandardModal } from "../general/Modal/Modal";
import { CreateCounterpartyModal } from "./CreateCounterpartyModal";
import styles from "./CounterpartySelect.module.scss";
import { CounterpartyVerifiedToast } from "./CounterpartyVerifiedToast/CounterpartyVerifiedToast";

interface Props {
    selectedCounterparty?: Counterparty;
    onChange: (counterparty?: Counterparty) => void;
    dropdownKey: string;
    size?: CustomSelectDefaultTriggerProps["size"];
    className?: string;
    counterpartyDirection?: CounterpartyDirection;
    allowToCreateNew?: boolean;
    children?: React.ReactNode;
    forTransactionEditing?: boolean;
}

export const CounterpartySelect: React.FC<Props> = ({
    selectedCounterparty,
    onChange,
    size,
    className,
    counterpartyDirection,
    allowToCreateNew,
    children,
    forTransactionEditing,
    ...customSelectProps
}) => {
    const { activeWorkspace } = useWorkspaceContext();
    const { toast } = useToaster();
    const [typedSearch, setTypedSearch] = useState<string>();
    const [search, setSearch] = useState<string>();
    const [popoverVisible, setPopoverVisible] = useState(false);
    const directionLabel =
        counterpartyDirection &&
        CounterpartyDirectionLabels[counterpartyDirection];

    useDebouncedEffect(
        () => {
            setSearch(typedSearch);
        },
        [typedSearch],
        SEARCH_DEBOUNCE_TIME,
    );

    const counterpartiesQuery = useQuery({
        queryKey: [
            ...counterpartiesQueryKeys.query(activeWorkspace?.id ?? ""),
            search,
        ],
        queryFn: () => {
            if (!activeWorkspace) {
                return { data: [] };
            }

            return getCounterparties(
                {
                    workspaceId: activeWorkspace.id,
                    search,
                    limit: 50,
                    sort: CounterpartySort.NAME_ASC,
                    includeRegistered: search
                        ? RegisteredCounterpartyIncludeScope.ALL
                        : RegisteredCounterpartyIncludeScope.USED,
                },
                CounterpartiesReturnFormat.JSON,
            );
        },
        enabled: popoverVisible,
    });

    const options = useMemo(
        () =>
            counterpartiesQuery.data?.data.map((c) => ({
                value: c.counterparty.id,
                label: c.counterparty.name,
            })) ?? [],
        [counterpartiesQuery.data],
    );

    const onSelected = useCallback(
        (counterpartyId: string) => {
            const counterparty = counterpartiesQuery.data?.data.find(
                (c) => c.counterparty.id === counterpartyId,
            )?.counterparty;
            if (counterparty) {
                onChange(counterparty);
            }
        },
        [onChange, counterpartiesQuery.data],
    );

    const onShowPopover = useCallback(() => {
        setPopoverVisible(true);
        counterpartiesQuery.refetch();
    }, [counterpartiesQuery]);

    const onHidePopover = useCallback(() => {
        setTypedSearch(undefined);
        setPopoverVisible(false);
    }, []);

    const creationMutation = useCreateCounterpartyMutation();
    const [showCreationModal, setShowCreationModal] = useState(false);

    const onCounterpartyCreated = useCallback(
        (creationResponse: CounterpartyCreationResponse) => {
            onChange(creationResponse.counterparty);

            if (
                creationResponse.revealedOnTransactionsNumber &&
                (forTransactionEditing ||
                    creationResponse.revealedOnTransactionsNumber > 1)
            ) {
                toast(
                    <CounterpartyVerifiedToast
                        counterparty={creationResponse.counterparty}
                        transactionsNumber={
                            creationResponse.revealedOnTransactionsNumber
                        }
                    />,
                    `counterparty-verified-${creationResponse.counterparty.id}}`,
                );
            }
        },
        [toast, forTransactionEditing, onChange],
    );

    const onCreateNew = useCallback(
        (typedName: string) => {
            if (typedName) {
                creationMutation
                    .mutateAsync({ name: typedName })
                    .then((response) => {
                        onCounterpartyCreated(response);
                    });
            } else {
                setShowCreationModal(true);
            }
            setPopoverVisible(false);
        },
        [creationMutation, onCounterpartyCreated],
    );

    const createNewButton = useCallback(
        (typedName: string) => {
            const buttonText = typedName
                ? `Create "${typedName}"`
                : `Create new ${directionLabel?.toLowerCase() ?? ""}`;

            return (
                <ButtonWithLoader
                    variant="tertiary"
                    size="sm"
                    onClick={() => onCreateNew(typedName)}
                    loading={creationMutation.isPending}
                >
                    <PlusIcon className="mr-2" /> {buttonText}
                </ButtonWithLoader>
            );
        },
        [creationMutation, directionLabel, onCreateNew],
    );

    const onCreationModalClosed = useCallback(
        (creationResponse?: CounterpartyCreationResponse) => {
            setShowCreationModal(false);
            if (!creationResponse) {
                return;
            }

            onCounterpartyCreated(creationResponse);
        },
        [onCounterpartyCreated],
    );

    return (
        <>
            <CustomSelect
                onSelected={onSelected}
                options={options}
                loadingOptions={counterpartiesQuery.isFetching}
                onSearch={setTypedSearch}
                searchable
                externalSearch
                value={selectedCounterparty?.id}
                placeholder={`Select ${directionLabel?.toLowerCase() ?? ""}...`}
                onShowPopover={onShowPopover}
                onHidePopover={onHidePopover}
                className={className}
                dropdownClassName={styles.dropdown}
                cta={allowToCreateNew ? createNewButton : undefined}
                showPopover={popoverVisible}
                {...customSelectProps}
            >
                {children ?? (
                    <CustomSelectDefaultTrigger
                        value={
                            selectedCounterparty?.name ??
                            `Select ${directionLabel?.toLowerCase() ?? ""}...`
                        }
                        size={size}
                    />
                )}
            </CustomSelect>
            <StandardModal
                show={showCreationModal}
                onHide={onCreationModalClosed}
            >
                <CreateCounterpartyModal
                    direction={counterpartyDirection}
                    close={onCreationModalClosed}
                    forTransactionEditing={forTransactionEditing}
                />
            </StandardModal>
        </>
    );
};
