import { SubscriptionType } from "../common/types/billing";
import { ADMIN_ROLES } from "../common/constants";
import { useWorkspaceContext } from "../state/workspaceContext";
import { useTypedFlags } from "./useTypedFlags";
import { useBillingManagement } from "./useBillingManagement";
import { useBillingType } from "./useBillingType";
import { useRoles } from "./useRoles";

export function useShowWorkspaceSettings() {
    const { directory: canUseDirectory, classes: canUseClasses } =
        useTypedFlags();
    const { canManageBilling } = useBillingManagement();
    const billingType = useBillingType();
    const showPlans =
        canManageBilling && billingType === SubscriptionType.FLAT_RATE;
    const isAdmin = useRoles(ADMIN_ROLES);
    const { activeWorkspace } = useWorkspaceContext();

    return (
        isAdmin &&
        !!activeWorkspace &&
        [canUseDirectory, canUseClasses, canManageBilling, showPlans].some(
            (flag) => flag,
        )
    );
}
