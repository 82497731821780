import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { EntityIconList } from "../../general/EntityIconList/EntityIconList";
import { GridTableRow } from "../../general/Tables/GridTable/GridTable";
import { useUser } from "../../../hooks/useUser";
import { WORKSPACE_ROLES_LABELS } from "../../../common/constants";
import { useWorkspaceContext } from "../../../state/workspaceContext";
import { ManagementDropdown } from "../../general/ManagementDropdown/ManagementDropdown";
import { WorkspaceMember } from "../../../common/types/workspace";
import { ConfirmationModal } from "../../general/ConfirmationModal";
import { UserWorkspaceRole } from "../../../common/userWorkspaceRoles";
import { useCanManageWorkspaceMembers } from "../../../hooks/permissions/useCanManageWorkspaceMembers";
import { useRemoveMemberMutation } from "../../../api/workspace-member.api";
import { useMemberLastActivity } from "./useMemberLastActivity";
import { MemberCard } from "./MemberCard";
import styles from "./WorkspaceMembers.module.scss";

interface Props {
    member: WorkspaceMember;
    onConfigurePermissions(): void;
}

export const MemberRow: React.FC<Props> = ({
    member,
    onConfigurePermissions,
}) => {
    const { user, role, entityAssignments } = member;

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const lastActivity = useMemberLastActivity(user);
    const canManageWorkspaceMembers = useCanManageWorkspaceMembers();
    const actingUser = useUser();
    const { activeWorkspace } = useWorkspaceContext();
    const removeMember = useRemoveMemberMutation(member, activeWorkspace!);

    const canCurrentUserManageMembers =
        canManageWorkspaceMembers &&
        actingUser.id !== user.id &&
        role !== UserWorkspaceRole.PRIMARY_ADMIN;

    return (
        <>
            <GridTableRow className={styles.row}>
                <div>
                    <MemberCard user={user} />
                </div>
                <div>
                    <span className={styles.list}>
                        <EntityIconList
                            showLabel={false}
                            entities={entityAssignments.map(
                                (assignment) => assignment.entity,
                            )}
                        />
                    </span>
                </div>
                <div>{WORKSPACE_ROLES_LABELS[role]}</div>
                <div>{lastActivity}</div>
                <div>
                    {canCurrentUserManageMembers ? (
                        <ManagementDropdown id={`manage-${user.id}`}>
                            <Dropdown.Item
                                as="button"
                                onClick={onConfigurePermissions}
                            >
                                Configure permissions
                            </Dropdown.Item>

                            {actingUser.id !== user.id && (
                                <Dropdown.Item
                                    as="button"
                                    className="text-danger"
                                    onClick={() =>
                                        setShowDeleteConfirmation(true)
                                    }
                                >
                                    Remove from workspace
                                </Dropdown.Item>
                            )}
                        </ManagementDropdown>
                    ) : null}
                </div>
            </GridTableRow>

            <ConfirmationModal
                show={showDeleteConfirmation}
                title={`Are you sure you want to remove ${user.name || user.email} from the workspace?`}
                question=""
                onConfirm={async () => {
                    await removeMember.mutateAsync();
                    setShowDeleteConfirmation(false);
                }}
                no="Cancel"
                yes="Remove"
                onReject={() => setShowDeleteConfirmation(false)}
            />
        </>
    );
};
