import React, { useEffect, useMemo } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { SortOrder } from "../../../../common/constants";
import { transactionMatchingQueryKeys } from "../../lib";
import { convertFiltersToDto } from "../../../transactions/useTransactionsQuery";
import { getTransactions } from "../../../../lib/transactions";
import { Sort } from "../../../transactions/useSort";
import { TransactionsFilters } from "../../../transactions/filters/lib";
import { NoMatchingTransactions } from "../../../transactions/NoMatchingTransactions";
import { Loader } from "../../../general/Loader";
import { FinancialDocument } from "../../../../common/types/financialDocument";
import { useCreateLinkFinancialDocumentToFinancialTransactionMutation } from "../../../../mutations/financialDocument";
import { useFinancialDocumentDetailsView } from "../useFinancialDocumentDetailsView";
import { useEntities } from "../../../../hooks/useEntities";
import { queryClient } from "../../../../queryClient";
import { TablePageHeader } from "../../../pageHeader/TablePageHeader";
import { FilterTabs } from "../../../pageHeader/filters/lib";
import { EntityFilter } from "../../../general/EntityFilter/EntityFilter";
import { useTypedFlags } from "../../../../hooks/useTypedFlags";
import { useClasses } from "../../../../api/class.api";
import styles from "./MatchingView.module.scss";
import { MatchingViewTransaction } from "./MatchingViewTransaction";

export interface Props {
    financialDocument: FinancialDocument;
    filters: TransactionsFilters;
    onFiltersChange: (filters: TransactionsFilters) => void;
    clearFilters: () => void;
}

const FIXED_SORT: Sort = {
    field: "date",
    order: SortOrder.DESC,
};

export const MatchingView: React.FC<Props> = ({
    financialDocument,
    filters,
    onFiltersChange,
    clearFilters,
}) => {
    const { go: goBackToDefaultView } = useFinancialDocumentDetailsView();
    const match = useCreateLinkFinancialDocumentToFinancialTransactionMutation(
        financialDocument,
        goBackToDefaultView,
    );
    const entities = useEntities();
    const { directory: canUseDirectory } = useTypedFlags();
    const { isActivated: classesEnabled } = useClasses();

    const filtersDto = useMemo(
        () => convertFiltersToDto(filters, entities),
        [filters, entities],
    );

    useEffect(() => {
        queryClient.removeQueries({
            queryKey: transactionMatchingQueryKeys.list(filtersDto),
        });
        // remove previously fetched data when entering matching
    }, [filtersDto]);

    const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
        queryKey: transactionMatchingQueryKeys.list(filtersDto),
        queryFn: ({ pageParam = 0 }) =>
            getTransactions({
                filters: filtersDto,
                sort: FIXED_SORT,
                limit: 20,
                page: pageParam,
            }),
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.pageCount > allPages.length) {
                return allPages.length;
            }
        },
        initialPageParam: 0,
    });

    const { inView, ref } = useInView({
        initialInView: true,
    });

    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, inView]);

    let content;

    if (!data) {
        content = (
            <tr>
                <td colSpan={99}>
                    <Loader />
                </td>
            </tr>
        );
    } else if (data.pages[0].data.length === 0) {
        content = (
            <tr>
                <td colSpan={99}>
                    <NoMatchingTransactions onResetFilters={clearFilters} />
                </td>
            </tr>
        );
    } else {
        content = data.pages
            .flatMap((page) => [...page.data])
            .map((transaction) => (
                <MatchingViewTransaction
                    financialDocument={financialDocument}
                    transaction={transaction}
                    key={transaction.id}
                    onMatch={() => match.mutateAsync(transaction.id)}
                />
            ));
    }

    return (
        <main className={styles.container}>
            <TablePageHeader
                search={filters.search}
                onSearchChange={(value) => {
                    onFiltersChange({ search: value ?? "" });
                }}
                filters={filters}
                onFiltersChange={onFiltersChange}
                availableFilters={[
                    FilterTabs.DATE,
                    FilterTabs.CATEGORY,
                    FilterTabs.ACCOUNT,
                    ...(classesEnabled ? [FilterTabs.CLASS] : []),
                    FilterTabs.AMOUNT,
                    FilterTabs.RULES,
                    ...(canUseDirectory ? [FilterTabs.COUNTERPARTY] : []),
                    FilterTabs.NOTE,
                    FilterTabs.RECEIPTS,
                    FilterTabs.SPLIT,
                    FilterTabs.MATCHED,
                ]}
            >
                <EntityFilter filters={filters} onChange={onFiltersChange} />
            </TablePageHeader>

            <section className={styles.transactions}>
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Description</th>
                            <th className="text-right">Account</th>
                            <th className="text-right">Amount</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>{content}</tbody>

                    <footer
                        className={styles.observerFooter}
                        ref={ref}
                    ></footer>
                </table>
            </section>
        </main>
    );
};
