import { AbstractSortableListItemType } from "../../general/AbstractSortableList/AbstractSortableListItem";
import { checkCategoryLabelConflict } from "../../../helpers/categories";
import { Category as CategoryType } from "../../../common/types/category";
import { Taxonomy } from "../../../common/categories";

export enum CategoryFlattenedItemType {
    Category = "category",
    CustomCategory = "customCategory",
    AddCategory = "addCategory",
}

export type CategoryFlattenedItem = AbstractSortableListItemType & {
    meta: {
        id: string;
        label: string;
        description: string;
        isHidden?: boolean;
        isEditable?: boolean;
        type: CategoryFlattenedItemType;
        category?: CategoryType;
    };
};

export function buildFlattenedItems(
    kickCategories: CategoryType[],
    allowCreateNewCategory: boolean,
    search: string,
): {
    items: CategoryFlattenedItem[];
} {
    const items: CategoryFlattenedItem[] = [];
    for (const kickCategory of kickCategories) {
        const shouldShowParent =
            search.length === 0 ||
            kickCategory.label.toLowerCase().includes(search.toLowerCase()) ||
            (kickCategory.subcategories || []).some(
                (child) =>
                    child.parentCategoryId === kickCategory.id &&
                    child.label.toLowerCase().includes(search.toLowerCase()),
            );

        const children = [
            ...(kickCategory.subcategories ?? []).map((child) => ({
                id: child.id,
                parentId: kickCategory.id,
                depth: 1,
                children: [],
                meta: {
                    id: child.id,
                    label: child.label,
                    description: child.description,
                    isHidden: false,
                    isEditable: Boolean(child.workspaceId),
                    type: child.workspaceId
                        ? CategoryFlattenedItemType.CustomCategory
                        : CategoryFlattenedItemType.Category,
                    category: child,
                },
            })),
        ];

        items.push({
            id: kickCategory.id,
            parentId: kickCategory.parentCategoryId,
            depth: 0,
            children,
            disableAddChildren:
                kickCategory.taxonomy === Taxonomy.uncategorized,
            meta: {
                id: kickCategory.id,
                label: kickCategory.label,
                description: kickCategory.description ?? "",
                type: CategoryFlattenedItemType.Category,
                isHidden: !shouldShowParent,
                isEditable: false,
                category: kickCategory,
            },
        });

        for (const child of children) {
            const shouldShowChildren =
                search.length === 0 ||
                child.meta.label.toLowerCase().includes(search.toLowerCase());

            items.push({
                ...child,
                meta: {
                    ...child.meta,
                    id: child.id,
                    description: child.meta.description ?? "",
                    isHidden: !shouldShowChildren,
                },
            });
        }

        if (
            allowCreateNewCategory &&
            kickCategory.taxonomy !== Taxonomy.uncategorized
        ) {
            items.push({
                id: `addSubCategory:${kickCategory.id}`,
                parentId: kickCategory.id,
                depth: 0,
                children: [],
                meta: {
                    id: `addSubCategory:${kickCategory.id}`,
                    label: "Add Subcategory",
                    description: "",
                    type: CategoryFlattenedItemType.AddCategory,
                    isHidden: !shouldShowParent,
                    isEditable: false,
                },
                isVirtual: true,
            });
        }
    }

    return { items };
}

export function getErrorForAddCategory(
    categories: CategoryType[],
    parentCategoryId: string | null,
    label: string,
) {
    const { conflict, conflictingCategory } = checkCategoryLabelConflict({
        categories,
        parentCategoryId,
        label,
    });

    if (conflict) {
        if (conflictingCategory?.id === parentCategoryId) {
            return "Subcategory can't have the same name as the parent";
        } else if (parentCategoryId !== null) {
            return "Subcategory with this name already exists in the same category";
        } else {
            return `Custom category name ${label} is already taken.`;
        }
    }
    return null;
}
