import React from "react";
import { DocumentThinIcon } from "../../icons";
import { ChildrenProps } from "../../types";
import styles from "./DocumentsEmptyState.module.scss";

interface Props extends ChildrenProps {
    header?: React.ReactNode;
}

export const DocumentsEmptyState: React.FC<Props> = ({ header, children }) => (
    <section
        className={styles.container}
        data-testid="financial-documents-empty-state-section"
    >
        <aside className={styles.icon}>
            <DocumentThinIcon />
        </aside>

        <header>{header}</header>
        <div className="d-flex justify-content-center">{children}</div>
    </section>
);
