import React, { useCallback } from "react";
import { Alert } from "react-bootstrap";
import { ProfitLossReportDto } from "../../common/dto/reports/reporting-tab-profit-loss-response.dto";
import { NonUndefined } from "../../common/helpers/typescript";
import { ReportFilters } from "../../common/types/filters/reports";
import { useIsCategorizationInProgress } from "../../hooks/useIsCategorizationInProgress";
import { EmptyState } from "../general/EmptyState/EmptyState";
import { Loader } from "../general/Loader";
import { useCashFlowContext } from "./cash-flow.context";
import { CashFlowDrillDownContextProvider } from "./CashFlowDrillDownContextProvider";
import { CashFlowDrillDownModal } from "./CashFlowDrillDownModal";
import {
    CashFlowReportChart,
    CashFlowReportChartProps,
} from "./CashFlowReportChart";
import { CashFlowReportTableContainer } from "./CashFlowReportTable/CashFlowReportTableContainer";
import { CategorizationInProgressNotification } from "./CategorizationInProgressNotification";

export interface CashFlowReportProps {
    resetFilters: () => void;
    onChange(filters: Partial<ReportFilters>): void;
}

export const CashFlowReport: React.FC<CashFlowReportProps> = ({
    resetFilters,
    onChange,
}) => {
    const {
        cashFlowReport,
        isCashFlowReportLoading,
        cashFlowFiltersUsed,
        reportCreatedAt,
    } = useCashFlowContext();

    const isCategorizationInProgress = useIsCategorizationInProgress();

    const setCategoryFilter = useCallback<
        NonUndefined<CashFlowReportChartProps["setCategoryFilter"]>
    >(
        (categories) => {
            onChange({ category: categories });
        },
        [onChange],
    );

    if (isCashFlowReportLoading) {
        return <Loader />;
    }

    return cashFlowReport?.chart.series.length &&
        cashFlowFiltersUsed &&
        reportCreatedAt ? (
        <>
            {isCategorizationInProgress && (
                <Alert
                    variant="default"
                    className="reports__categorization-prompt"
                >
                    <CategorizationInProgressNotification />
                </Alert>
            )}
            <CashFlowReportChart
                report={cashFlowReport as unknown as ProfitLossReportDto}
                filters={cashFlowFiltersUsed}
                reportCreatedAt={reportCreatedAt}
                setCategoryFilter={setCategoryFilter}
            />
            <CashFlowDrillDownContextProvider>
                <CashFlowReportTableContainer
                    report={cashFlowReport}
                    filters={cashFlowFiltersUsed}
                    withBorder
                    boldTotals
                />
                <CashFlowDrillDownModal
                    tableData={cashFlowReport.tableData}
                    filters={cashFlowFiltersUsed}
                />
            </CashFlowDrillDownContextProvider>
        </>
    ) : (
        <div className="reporting-tab__empty-state">
            <EmptyState
                header="No matching transactions"
                body="The filters can't reproduce a report"
                ctaText="Clear filters"
                action={resetFilters}
                buttonVariant="secondary"
            />
        </div>
    );
};
