import React, { useCallback, useMemo, useState } from "react";
import { ConfirmPaymentData, StripeError } from "@stripe/stripe-js";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { format } from "date-fns";
import {
    FLAT_RATE_PLANS,
    flatRatePlanNames,
    TRIAL_LENGTH_DAYS,
} from "../../../../common/flatRateBilling";
import { ModalWithPaymentSetupContent } from "../ModalWithPaymentSetupContent";
import { SelectedPaymentMethod } from "../lib";
import { useBillingStatus } from "../../../../hooks/useBillingStatus";
import styles from "../PlanManagement.module.scss";
import { fullDateFormat } from "../../../../common/helpers/date";
import { getPricing } from "../../../../lib/flatRateBilling";
import { CurrentPlanSummary } from "./CurrentPlanSummary";
import { UpgradeModalFooter } from "./UpgradeModalFooter";

interface Props {
    plan: FLAT_RATE_PLANS;
    isTrial: boolean;
    intentId?: string;
}

export const UpgradeSubscriptionModal: React.FC<Props> = ({
    plan,
    intentId,
    isTrial,
}) => {
    const [paymentBusy, setPaymentBusy] = useState(false);
    const [error, setError] = useState<StripeError | null>(null);
    const [appliedDiscountCode, setAppliedDiscountCode] = useState<
        string | null
    >(null);
    const stripe = useStripe();
    const elements = useElements();
    const { plans, subscriptionDetails, isSubscribed, isFetched } =
        useBillingStatus();

    const selectedPlan = plans.find((p) => p.plan === plan)!;

    const isUpgrade = isSubscribed;

    const { data: pricing, isLoading } = useQuery({
        queryKey: ["pricing", plan, appliedDiscountCode],
        queryFn: () =>
            getPricing({
                plan,
                discountCode: appliedDiscountCode ?? undefined,
            }),
        placeholderData: keepPreviousData,
    });

    const handleConfirm = useCallback(
        async (selectedMethod: SelectedPaymentMethod) => {
            if (!stripe || !elements || !intentId) {
                return;
            }

            setPaymentBusy(true);
            setError(null);

            const afterCheckout = `${window.location.pathname}${window.location.search}`;

            const returnUrl = new URL(
                `${window.location.origin}/${isUpgrade ? "upgrade" : "subscribe"}/${plan}`,
            );

            returnUrl.searchParams.set("after", afterCheckout);

            if (
                intentId &&
                (selectedMethod === SelectedPaymentMethod.NEW || !isUpgrade)
            ) {
                returnUrl.searchParams.set("intent", intentId);
            }

            if (appliedDiscountCode) {
                returnUrl.searchParams.set("code", appliedDiscountCode);
            }

            if (selectedMethod === SelectedPaymentMethod.EXISTING) {
                window.location.replace(returnUrl); // if using existing method, just checkout
                return;
            }

            const options: ConfirmPaymentData = {
                return_url: returnUrl.toString(),
            };

            const response = await stripe.confirmSetup({
                elements,
                confirmParams: options,
            });

            if (response.error) {
                setError(response.error);
            }

            setPaymentBusy(false);
        },
        [appliedDiscountCode, elements, intentId, isUpgrade, plan, stripe],
    );

    const submitText = useMemo(() => {
        if (!isTrial) {
            return "Confirm Payment";
        } else if (isUpgrade) {
            return "Upgrade";
        } else {
            return "Start Trial";
        }
    }, [isTrial, isUpgrade]);

    const header = useMemo(() => {
        const planName = (
            <span className={styles.planName}>{flatRatePlanNames[plan]}</span>
        );

        if (isTrial) {
            const trialEndText =
                isUpgrade && subscriptionDetails?.periodEndsAt
                    ? `until your trial ends on ${format(subscriptionDetails.periodEndsAt, fullDateFormat)}`
                    : `Free for ${TRIAL_LENGTH_DAYS} days`;

            return (
                <>
                    <h3>
                        Try {planName} {trialEndText}
                    </h3>
                    <p>
                        Cancel anytime. We'll remind you before the trial ends.
                    </p>
                </>
            );
        } else {
            return (
                <h3>
                    {isUpgrade ? "Upgrade to" : "Subscribe to"} {planName}
                </h3>
            );
        }
    }, [isTrial, isUpgrade, plan, subscriptionDetails?.periodEndsAt]);

    return (
        <ModalWithPaymentSetupContent
            isLoading={isLoading}
            busy={paymentBusy}
            onConfirm={handleConfirm}
            stripeError={error}
            header={header}
            submitText={submitText}
        >
            {pricing && isFetched ? (
                <>
                    <div className="mb-4">
                        <CurrentPlanSummary
                            plan={selectedPlan}
                            isTrial={isTrial}
                            billingDate={pricing.nextBillingDate}
                        />
                    </div>

                    <UpgradeModalFooter
                        billingDate={pricing.nextBillingDate}
                        plan={selectedPlan}
                        pricing={pricing}
                        onDiscountCodeChange={setAppliedDiscountCode}
                        discountCode={appliedDiscountCode}
                        codeInvalid={pricing.invalidDiscountCode}
                        discountFromSubscription={subscriptionDetails?.discount}
                    />
                </>
            ) : null}
        </ModalWithPaymentSetupContent>
    );
};
