import { useMemo } from "react";
import { useSingleBusinessEntity } from "../../../../../hooks/useSingleBusinessEntity";
import { DemoTransaction } from "../lib";
import { Taxonomy } from "../../../../../common/categories";
import { useStandardCategories } from "../../../../../hooks/useStandardCategories";
import {
    americanExpressFinancialAccount,
    chaseFinancialAccount,
    stripeFinancialAccount,
} from "./mockData";

export function useCategorizationSummaryTransactions() {
    const entity = useSingleBusinessEntity();
    const categories = useStandardCategories();

    return useMemo((): DemoTransaction[] => {
        if (!entity) {
            return [];
        }

        return [
            {
                id: 1,
                date: new Date(2024, 8, 19),
                counterparty: {
                    name: "Canva",
                },
                financialAccount: stripeFinancialAccount,
                bankDescription: "Canva",
                displayDescription: "Canva",
                category: categories[Taxonomy.income],
                amount: 3000,
                entity,
            },
            {
                id: 2,
                date: new Date(2024, 8, 17),
                counterparty: {
                    name: "Adobe",
                },
                financialAccount: chaseFinancialAccount,
                bankDescription: "Photoshop subscription",
                displayDescription: "Adobe",
                category: categories[Taxonomy.software_subscriptions],
                amount: -99,
                entity,
            },
            {
                id: 3,
                date: new Date(2024, 8, 15),
                counterparty: {
                    name: "Amazon",
                },
                financialAccount: americanExpressFinancialAccount,
                bankDescription: "Amazon",
                displayDescription: "Amazon",
                category: categories[Taxonomy.supplies_equipment],
                amount: -500,
                entity,
            },
            {
                id: 4,
                date: new Date(2024, 8, 8),
                counterparty: {
                    name: "Gusto",
                },
                financialAccount: chaseFinancialAccount,
                bankDescription: "Gusto",
                displayDescription: "Gusto",
                category: categories[Taxonomy.payroll],
                amount: -1100,
                entity,
            },
            {
                id: 5,
                date: new Date(2024, 8, 3),
                counterparty: {
                    name: "Sam Johnson",
                },
                financialAccount: chaseFinancialAccount,
                bankDescription: "Sam Johnson",
                displayDescription: "Sam Johnson",
                category: categories[Taxonomy.contractors],
                amount: -500,
                entity,
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity]);
}
