import React from "react";
import classNames from "classnames";
import styles from "./Plans.module.scss";

interface Props {
    className?: string;
    open: boolean;
    recommended?: boolean;
    aboveTheFold: React.ReactNode;
    belowTheFold: React.ReactNode;
}

export const PlansColumn: React.FC<Props> = ({
    open,
    aboveTheFold,
    belowTheFold,
    className,
    recommended,
}) => (
    <div
        className={classNames(styles.column, className, {
            [styles.recommended]: recommended,
            [styles.open]: open,
        })}
    >
        <section className={classNames(styles.wrapper, styles.aboveTheFold)}>
            {aboveTheFold}
        </section>
        {open && (
            <section
                className={classNames(styles.wrapper, styles.belowTheFold)}
            >
                {belowTheFold}
            </section>
        )}
    </div>
);
