import React from "react";
import { NavLink } from "react-router-dom";
import { endOfDay, formatISO, startOfYear } from "date-fns";
import classNames from "classnames";
import { NumberParam, useQueryParam } from "use-query-params";
import { BankIcon, GraphIcon } from "../../icons";
import { Entity } from "../../common/types/entity";
import { NavigationItem } from "./NavigationItem";

const DEFAULT_START_DATE = formatISO(startOfYear(new Date()), {
    representation: "date",
});
const DEFAULT_END_DATE = formatISO(endOfDay(new Date()), {
    representation: "date",
});

interface Props {
    entity: Entity;
}

export const EntitySideNavMenu: React.FC<Props> = ({ entity }) => {
    const accountingQueryParams = `?entityId=${entity.id}&startDate=${DEFAULT_START_DATE}&endDate=${DEFAULT_END_DATE}`;

    const [entityId] = useQueryParam("entityId", NumberParam);

    const entityActive = entityId === entity.id;

    return (
        <>
            <NavLink
                to={`/cashflow?entityId=${entity.id}`}
                className={({ isActive }) =>
                    classNames({ active: entityActive && isActive })
                }
                data-testid="entity-side-nav-cash-activity"
            >
                <NavigationItem icon={<GraphIcon />}>Cash Flow</NavigationItem>
            </NavLink>
            <NavLink
                to={`/accounting?entityId=${entity.id}`}
                className={({ isActive }) =>
                    classNames({ active: entityActive && isActive })
                }
                data-testid="entity-side-nav-accounting"
            >
                <NavigationItem icon={<BankIcon />}>Accounting</NavigationItem>
            </NavLink>
            <div className="entities-navigation__content entities-navigation__subcontent">
                <NavLink
                    to={`/accounting/profit-loss${accountingQueryParams}`}
                    className={({ isActive }) =>
                        classNames({ active: entityActive && isActive })
                    }
                >
                    Profit & Loss
                </NavLink>
                <NavLink
                    to={`/accounting/balance-sheet${accountingQueryParams}`}
                    className={({ isActive }) =>
                        classNames({ active: entityActive && isActive })
                    }
                >
                    Balance Sheet
                </NavLink>
            </div>
        </>
    );
};
