import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    BooleanParam,
    DateParam,
    DelimitedArrayParam,
    DelimitedNumericArrayParam,
    NumberParam,
    StringParam,
    useQueryParams,
} from "use-query-params";
import { useWorkspaceContext } from "../../state/workspaceContext";
import {
    useWorkspaceUserCache,
    WORKSPACE_USER_CACHE_KEYS,
} from "../../hooks/useWorkspaceUserCache";
import { Entity } from "../../common/types/entity";
import { useDefaultReportsFilters } from "../reports/useDefaultReportsFilters";
import { AccountStatus } from "../../common/types/domains/accounting/account";
import { TransactionClassFilterType } from "../../common/helpers/transactions";
import { JournalEntryModal } from "./journalEntryModal/JournalEntryModal";
import { AccountingReportFilters } from "./types";
import { useEntitiesWithAccountingAvailable } from "./useEntitiesWithAccountingAvailable";

export type AccountingFiltersSetter = (
    setter: (current: AccountingReportFilters) => AccountingReportFilters,
) => void;

interface DatePeriod {
    startDate: Date | undefined;
    endDate: Date | undefined;
}

function useAccountingTab() {
    const [filtersQuery, setFiltersQuery] = useQueryParams({
        startDate: DateParam,
        endDate: DateParam,
        entityId: NumberParam,
        openJournalEntryModal: BooleanParam,
        openedJournalEntryId: StringParam,
        accountCodes: DelimitedNumericArrayParam,
        accountStatus: StringParam,
        classes: DelimitedArrayParam,
        classFilterType: StringParam,
        isAllTime: BooleanParam,
    });

    const { activeWorkspace } = useWorkspaceContext();
    const [cachedEntityId, setCachedEntityId] = useWorkspaceUserCache<
        number | undefined
    >(WORKSPACE_USER_CACHE_KEYS.LAST_ENTITY_CACHE_KEY);

    const availableEntities = useEntitiesWithAccountingAvailable();
    const defaultReportsFilters = useDefaultReportsFilters();

    const validateEntityId = useCallback(
        (entityId?: number | null) => {
            if (!entityId) {
                return undefined;
            }

            return availableEntities.some(({ id }) => id === entityId)
                ? entityId
                : undefined;
        },
        [availableEntities],
    );

    if (filtersQuery.entityId && !validateEntityId(filtersQuery.entityId)) {
        setFiltersQuery({
            ...filtersQuery,
            entityId:
                validateEntityId(cachedEntityId) ?? availableEntities[0].id,
        });
    }

    const [cachedDatePeriod, setCachedDatePeriod] = useWorkspaceUserCache<
        DatePeriod | undefined
    >(WORKSPACE_USER_CACHE_KEYS.LAST_DATE_PERIOD_CACHE_KEY);

    const parsedCachedDatePeriod = useMemo(() => {
        if (cachedDatePeriod) {
            return {
                startDate: new Date(cachedDatePeriod.startDate as any),
                endDate: new Date(cachedDatePeriod.endDate as any),
            };
        }
        return undefined;
    }, [cachedDatePeriod]);

    // Note: separate date period from last entity as we believe
    // that in future date period will be different
    const [lastDatePeriod, setLastDatePeriod] = useState<
        DatePeriod | undefined
    >(parsedCachedDatePeriod);

    const [lastEntityId, setLastEntityId] = useState<number | undefined>(
        validateEntityId(cachedEntityId),
    );

    const setFilters = useCallback(
        (
            setter: (
                current: AccountingReportFilters,
            ) => AccountingReportFilters,
        ) => {
            setFiltersQuery((current) => {
                const newFilters = setter({
                    startDate: current.startDate ?? undefined,
                    endDate: current.endDate ?? undefined,
                    entityId: current.entityId ?? undefined,
                    openJournalEntryModal:
                        current.openJournalEntryModal ?? undefined,
                    openedJournalEntryId:
                        current.openedJournalEntryId ?? undefined,
                    classes:
                        current.classes?.filter((c) => c !== null) ?? undefined,
                    accountCodes:
                        current.accountCodes?.filter((c) => c !== null) ??
                        undefined,
                    accountStatus:
                        (current.accountStatus as AccountStatus) ?? undefined,
                });

                if (newFilters.startDate && newFilters.endDate) {
                    setCachedDatePeriod({
                        startDate: newFilters.startDate.toISOString(),
                        endDate: newFilters.endDate.toISOString(),
                    } as any);
                    setLastDatePeriod({
                        startDate: newFilters.startDate,
                        endDate: newFilters.endDate,
                    });
                }
                if (newFilters.entityId) {
                    setCachedEntityId(newFilters.entityId);
                    setLastEntityId(newFilters.entityId);
                }
                return newFilters;
            });
        },
        [
            setFiltersQuery,
            setCachedEntityId,
            setLastEntityId,
            setCachedDatePeriod,
            setLastDatePeriod,
        ],
    );

    const cleanFilters = useMemo(
        () => ({
            startDate: !filtersQuery.isAllTime
                ? filtersQuery.startDate ??
                  lastDatePeriod?.startDate ??
                  defaultReportsFilters.start ??
                  undefined
                : undefined,
            endDate: !filtersQuery.isAllTime
                ? filtersQuery.endDate ??
                  lastDatePeriod?.endDate ??
                  defaultReportsFilters.end ??
                  undefined
                : undefined,
            entityId: filtersQuery.entityId ?? lastEntityId ?? undefined,
            openJournalEntryModal:
                filtersQuery.openJournalEntryModal ?? undefined,
            openedJournalEntryId:
                filtersQuery.openedJournalEntryId ?? undefined,
            classes:
                filtersQuery.classes?.filter((c) => c !== null) ?? undefined,
            classFilterType:
                (filtersQuery.classFilterType as TransactionClassFilterType) ??
                undefined,
            accountCodes:
                filtersQuery.accountCodes?.filter((c) => c !== null) ??
                undefined,
            accountStatus:
                (filtersQuery.accountStatus as AccountStatus) ?? undefined,
        }),
        [filtersQuery, lastDatePeriod, lastEntityId, defaultReportsFilters],
    );

    const selectedEntity = useMemo(
        () => availableEntities.find((e) => e.id === cleanFilters.entityId),
        [availableEntities, cleanFilters.entityId],
    );

    useEffect(() => {
        if (
            !selectedEntity &&
            availableEntities.length > 0 &&
            activeWorkspace?.id &&
            availableEntities[0].workspaceId === activeWorkspace?.id
        ) {
            const cachedEntity = availableEntities.find(
                (e) => e.id === cachedEntityId,
            );
            setFiltersQuery({
                entityId: cachedEntity
                    ? cachedEntity.id
                    : availableEntities[0].id,
                startDate:
                    parsedCachedDatePeriod?.startDate ?? filtersQuery.startDate,
                endDate:
                    parsedCachedDatePeriod?.endDate ?? filtersQuery.endDate,
            });
        }
    }, [
        setCachedEntityId,
        filtersQuery.entityId,
        selectedEntity,
        availableEntities,
        setFiltersQuery,
        cachedEntityId,
        activeWorkspace,
        parsedCachedDatePeriod,
        filtersQuery.startDate,
        filtersQuery.endDate,
    ]);

    const setShowCreateJournalEntryModal = useCallback(
        (value: boolean) => {
            setFilters((currentFilters) => ({
                ...currentFilters,
                openJournalEntryModal: value,
                openedJournalEntryId: undefined,
            }));
        },
        [setFilters],
    );

    return {
        filters: cleanFilters,
        setFilters,
        setShowCreateJournalEntryModal,
        selectedEntity,
        openJournalEntryModal: filtersQuery.openJournalEntryModal ?? undefined,
        openedJournalEntryId: filtersQuery.openedJournalEntryId ?? undefined,
    };
}

interface AccountingTabContextType {
    filters: AccountingReportFilters;
    setFilters: AccountingFiltersSetter;
    selectedEntity: Entity | undefined;
    openJournalEntryModal: boolean | undefined;
    openedJournalEntryId: string | undefined;
    setShowCreateJournalEntryModal: (value: boolean) => void;
}

export const AccountingTabContext = createContext<AccountingTabContextType>({
    filters: {},
    setFilters: () => {},
    selectedEntity: undefined,
    openJournalEntryModal: undefined,
    openedJournalEntryId: undefined,
    setShowCreateJournalEntryModal: () => {},
});

export const useAccountingTabContext = () => useContext(AccountingTabContext);

export const AccountingTabProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const accountingTab = useAccountingTab();
    const { filters, setShowCreateJournalEntryModal, selectedEntity } =
        accountingTab;
    return (
        <AccountingTabContext.Provider value={accountingTab}>
            {selectedEntity && (
                <JournalEntryModal
                    journalEntryId={filters.openedJournalEntryId}
                    show={!!filters.openJournalEntryModal}
                    onHide={() => {
                        setShowCreateJournalEntryModal(false);
                    }}
                    entity={selectedEntity}
                />
            )}
            {children}
        </AccountingTabContext.Provider>
    );
};
