import { FC } from "react";
import { Tag, TagProps } from "../general/Tag/Tag";
import styles from "./Commands.module.scss";
import { COMMAND_DIRECTORY_LABELS, CommandsDirectory } from "./lib";

interface Props {
    directories: CommandsDirectory[];
}

const tagProps: TagProps = {
    size: "sm",
    variant: "secondary",
    outline: true,
};

export const CommandsDirectoryBreadcrumbs: FC<Props> = ({ directories }) => (
    <aside className={styles.breadcrumbs}>
        <Tag
            {...tagProps}
            key={directories[directories.length - 1]}
            className={styles.breadcrumb}
        >
            {COMMAND_DIRECTORY_LABELS[directories[directories.length - 1]]}
        </Tag>
    </aside>
);
