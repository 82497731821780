import {
    QueryClient,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { ServerInferRequest } from "@ts-rest/core";
import { getBackendAPIClient } from "../lib/backendAPIClient";
import { categoryContract } from "../common/contracts/category.contract";
import { useWorkspaceContext } from "../state/workspaceContext";
import { Category } from "../common/types/category";

export const categoryClientContract = getBackendAPIClient(categoryContract);

export const categoriesQueryKeys = {
    all: () => ["categories"] as const,
    query: (workspaceId: string) =>
        [...categoriesQueryKeys.all(), workspaceId] as const,
};

export function useWorkspaceCategories() {
    const { activeWorkspaceId } = useWorkspaceContext();

    return useQuery({
        queryKey: categoriesQueryKeys.query(activeWorkspaceId ?? ""),
        queryFn: async () => {
            if (!activeWorkspaceId) {
                return undefined;
            }

            const response = await categoryClientContract.getCategories({
                params: {
                    workspaceId: activeWorkspaceId,
                },
            });

            return response.body;
        },
    });
}

export function useUpdateCustomCategoryMutation(category: Category) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (
            body: ServerInferRequest<
                typeof categoryContract.updateCategory
            >["body"],
        ) => {
            if (!category.workspaceId) {
                throw new Error("Can't update standard category");
            }

            const response = await categoryClientContract.updateCategory({
                params: {
                    workspaceId: category.workspaceId,
                    categoryId: category.id,
                },
                body,
            });

            return response.body;
        },
        onMutate: async (body) => {
            updateCategoryInQueryData(queryClient, { ...category, ...body });

            return category;
        },
        onError: (_, __, originalCategory) => {
            if (originalCategory) {
                updateCategoryInQueryData(queryClient, originalCategory);
            }
        },
    });
}

export function useCreateCustomCategoryMutation() {
    const queryClient = useQueryClient();
    const { activeWorkspace } = useWorkspaceContext();

    return useMutation({
        mutationFn: async (
            body: ServerInferRequest<
                typeof categoryContract.createCategory
            >["body"],
        ) => {
            if (!activeWorkspace) {
                throw new Error("No active workspace");
            }

            const response = await categoryClientContract.createCategory({
                params: {
                    workspaceId: activeWorkspace.id,
                },
                body,
            });

            return response.body;
        },
        onSuccess: (createdCategory) => {
            addCategoryToQueryData(queryClient, createdCategory);
        },
    });
}

export function useDeleteCustomCategoryMutation(category: Category) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async () => {
            if (!category.workspaceId) {
                throw new Error("Can't delete standard category");
            }

            await categoryClientContract.deleteCategory({
                params: {
                    workspaceId: category.workspaceId,
                    categoryId: category.id,
                },
            });
        },
        onMutate: async () => {
            removeCategoryFromQueryData(queryClient, category);
        },
        onError: () => {
            addCategoryToQueryData(queryClient, category);
        },
    });
}

function addCategoryToQueryData(
    queryClient: QueryClient,
    createdCategory: Category,
) {
    queryClient.setQueryData(
        categoriesQueryKeys.query(createdCategory.workspaceId ?? ""),
        (oldData: { categories: Category[] } | undefined) => {
            if (!oldData) {
                return undefined;
            }

            return {
                categories: oldData.categories.map((category) =>
                    category.id === createdCategory.parentCategoryId
                        ? {
                              ...category,
                              subcategories: [
                                  ...(category.subcategories ?? []),
                                  createdCategory,
                              ],
                          }
                        : category,
                ),
            };
        },
    );
}

function removeCategoryFromQueryData(
    queryClient: QueryClient,
    removedCategory: Category,
) {
    queryClient.setQueryData(
        categoriesQueryKeys.query(removedCategory.workspaceId ?? ""),
        (oldData: { categories: Category[] } | undefined) => {
            if (!oldData) {
                return undefined;
            }

            return {
                categories: oldData.categories.map((category) =>
                    category.id === removedCategory.parentCategoryId
                        ? {
                              ...category,
                              subcategories:
                                  category.subcategories?.filter(
                                      (subcategory) =>
                                          subcategory.id !== removedCategory.id,
                                  ) ?? [],
                          }
                        : category,
                ),
            };
        },
    );
}

function updateCategoryInQueryData(
    queryClient: QueryClient,
    updatedCategory: Category,
) {
    queryClient.setQueryData(
        categoriesQueryKeys.query(updatedCategory.workspaceId ?? ""),
        (oldData: { categories: Category[] } | undefined) => {
            if (!oldData) {
                return undefined;
            }

            return {
                categories: oldData.categories.map((c) => ({
                    ...c,
                    subcategories:
                        c.subcategories?.map((subcategory) =>
                            subcategory.id === updatedCategory.id
                                ? updatedCategory
                                : subcategory,
                        ) ?? [],
                })),
            };
        },
    );
}
