import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { EditableTableFooter } from "../editableTable/EditableTableFooter";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { useKeyboardCommands } from "../../../hooks/keyboard/useKeyboardCommands";
import { KeyboardReturnIcon } from "../../../icons";
import { Shortcut } from "../../commands/Shortcut";
import { Button } from "../../general/Button/Button";
import styles from "./JournalEntryModal.module.scss";
import {
    JournalEntryModalErrors,
    JournalEntryModalFormikConfig,
    JournalEntryModalMutableConfig,
} from "./JournalEntryModal";
import { calculateDebitAndCreditTotals } from "./JournalEntryModalUtils";
import { useIsManualJournalEntry } from "./JournalEntryModalHooks";

interface Props {
    loading: boolean;
    onDelete: () => void;
    addLines: () => void;
}

export const JournalEntryModalFooter: React.FC<Props> = ({
    loading,
    onDelete,
    addLines,
}) => {
    const formikContext = useFormikContext<JournalEntryModalFormikConfig>();
    const { values, setFieldValue, handleSubmit } = formikContext;
    const hideAfterSubmit = values.isHideAfterSubmit;
    const errors = formikContext.errors as JournalEntryModalErrors;

    const lines = values.journalEntry.lines;

    const debitAndCreditTotals = useMemo(
        () => calculateDebitAndCreditTotals(lines),
        [lines],
    );

    const isFormNotFilled = useMemo(
        () =>
            debitAndCreditTotals.debitAmount === 0 &&
            debitAndCreditTotals.creditAmount === 0,
        [debitAndCreditTotals],
    );

    const onSubmit = useCallback(
        (closeType: "save" | "save-and-new") => async () => {
            if (closeType === "save") {
                setFieldValue("isHideAfterSubmit", true);
            } else {
                setFieldValue("isHideAfterSubmit", false);
            }
            JournalEntryModalMutableConfig.validate = true;
            await handleSubmit();
        },
        [handleSubmit, setFieldValue],
    );

    useKeyboardCommands({
        commands: [
            {
                key: "Enter",
                requiresCtrlOrMeta: true,
                requiresShift: true,
                callback: () => {
                    onSubmit("save-and-new")();
                },
            },
            {
                key: "Enter",
                requiresCtrlOrMeta: true,
                requiresShift: false,
                callback: () => {
                    onSubmit("save")();
                },
            },
        ],
    });

    const shownError = useMemo(() => {
        const hasErrorsInLines =
            errors.journalEntry?.lines && errors.journalEntry.lines.length > 0;
        const hasErrorsInDebitAndCreditSums = errors.debitAndCreditSums;
        if (hasErrorsInLines && hasErrorsInDebitAndCreditSums) {
            return "Please complete the highlighted fields and make sure the totals match";
        }
        if (hasErrorsInLines) {
            return "Please complete the highlighted fields";
        }
        if (hasErrorsInDebitAndCreditSums) {
            return "Please check that your total debits match total credits";
        }
        return "";
    }, [errors]);

    const isJournalEntryEditable = useIsManualJournalEntry(values);

    return (
        <div className={styles.modalFooter}>
            <EditableTableFooter totals={debitAndCreditTotals} />

            {isJournalEntryEditable && (
                <div className={classNames(styles.footerButtons)}>
                    <div>
                        <Button variant="secondary" onClick={addLines}>
                            Add lines
                        </Button>
                        {values.journalEntry.id && (
                            <ButtonWithLoader
                                loading={loading && hideAfterSubmit}
                                disabled={
                                    isFormNotFilled || shownError.length > 0
                                }
                                variant="secondary"
                                onClick={onDelete}
                                className={styles.deleteButton}
                            >
                                Delete Journal Entry
                            </ButtonWithLoader>
                        )}
                    </div>

                    <div className="d-flex align-items-center gap-buttons">
                        {shownError && (
                            <p className={classNames(styles.footerError)}>
                                {shownError}
                            </p>
                        )}
                        <ButtonWithLoader
                            loading={loading && hideAfterSubmit}
                            disabled={isFormNotFilled || shownError.length > 0}
                            variant="secondary"
                            onClick={onSubmit("save")}
                            tooltip={
                                <Shortcut
                                    keys={["⌘", <KeyboardReturnIcon />]} // nosonar
                                    hideText
                                />
                            }
                        >
                            Save
                        </ButtonWithLoader>
                        <ButtonWithLoader
                            loading={loading && !hideAfterSubmit}
                            disabled={isFormNotFilled || shownError.length > 0}
                            onClick={onSubmit("save-and-new")}
                            variant="default"
                            tooltip={
                                <Shortcut
                                    keys={["⌘", "⇧", <KeyboardReturnIcon />]} // nosonar
                                    hideText
                                />
                            }
                        >
                            Save and new
                        </ButtonWithLoader>
                    </div>
                </div>
            )}
        </div>
    );
};
