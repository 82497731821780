import classNames from "classnames";

import { useMemo } from "react";
import { GridTableRow } from "../../../general/Tables/GridTable/GridTable";
import { ExtendedFlattenedItem } from "../utils";
import { ReplaceProperties } from "../../../../common/types/base/generics";
import { Class } from "../../../../common/types/class";
import styles from "../ClassesSettings.module.scss";
import { ClassIdCell } from "./ClassIdCell";
import { ClassActions } from "./ClassActions";

export function DraggedClass({ item }: { item: ExtendedFlattenedItem }) {
    const cells = useMemo(
        () => [
            {
                key: "name",
                value: (
                    <ClassIdCell
                        item={
                            item as ReplaceProperties<
                                ExtendedFlattenedItem,
                                { meta: { classInstance: Class } }
                            >
                        }
                    />
                ),
            },
            {
                key: "actions",
                value: "classInstance" in item.meta && (
                    <ClassActions
                        numberOfSubclassesInsteadOfDelete={item.children.length}
                        classInstance={item.meta.classInstance}
                    />
                ),
            },
        ],
        [item],
    );

    return (
        <GridTableRow
            className={classNames(styles.row, styles.draggedRow)}
            cells={cells}
        />
    );
}
