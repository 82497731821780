import React, { useCallback } from "react";
import { useWorkspaceContext } from "../../../state/workspaceContext";
import { useRuleCreationMutation } from "../../../mutations/transactionRules";
import { ModalComponentProps } from "../../../helpers/modal";
import { NewTransactionRuleDefinition } from "../../transactionRules/TransactionRuleCreation/TransactionRuleCreation.context";
import { Counterparty } from "../../../common/types/counterparty";
import { RuleSource } from "../../../common/types/transactionRule";
import {
    StandardModalBody,
    StandardModalHeader,
} from "../../general/Modal/Modal";
import styles from "./TransactionRuleFormModal.module.scss";
import {
    RuleForm,
    TransactionRuleSubmitData,
} from "./TransactionRuleForm/RuleForm";

interface Props extends ModalComponentProps {
    newRuleDefinition?: NewTransactionRuleDefinition;
    counterparties?: Counterparty[];
    source: RuleSource;
}

export const AddRuleModal: React.FC<Props> = ({
    close,
    newRuleDefinition,
    counterparties,
    source,
}) => {
    const { activeWorkspace } = useWorkspaceContext();
    const creation = useRuleCreationMutation(close);

    const onCreate = useCallback(
        async (data: TransactionRuleSubmitData) => {
            if (!activeWorkspace) {
                return;
            }

            await creation.mutateAsync({
                workspaceId: activeWorkspace.id,
                source,
                ...data,
            });
        },
        [activeWorkspace, creation, source],
    );

    return (
        <>
            <StandardModalHeader className={styles.header}>
                Add Rule
            </StandardModalHeader>
            <StandardModalBody className={styles.body}>
                <RuleForm
                    onSubmit={onCreate}
                    isSubmitting={creation.isPending}
                    submitText="Create Rule"
                    newRuleDefinition={newRuleDefinition}
                    counterparties={counterparties}
                />
            </StandardModalBody>
        </>
    );
};
