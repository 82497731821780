import {
    ComponentProps,
    FC,
    KeyboardEvent,
    ReactNode,
    useCallback,
    useMemo,
    useRef,
    useState,
} from "react";
import classNames from "classnames";
import { Command, defaultFilter } from "../../vendor/cmdk/cmdk";
import { PlainModal } from "../general/Modal/Modal";
import {
    CommandParams,
    useKeyboardCommands,
} from "../../hooks/keyboard/useKeyboardCommands";
import { commandsContext } from "./commands.context";
import styles from "./Commands.module.scss";
import { AllCommandsList } from "./AllCommandsList";
import { AccountsCommandsList } from "./AccountsCommandsList";
import { CommandsDirectoryBreadcrumbs } from "./CommandsDirectoryBreadcrumbs";
import { OnboardingCommandsList } from "./OnboardingCommandsList";
import { StaticAccountsCommandsList } from "./StaticAccountsCommandsList";
import { WorkspacesCommandsList } from "./WorkspacesCommandsList";
import { CommandsDirectory, CommandsOptions } from "./lib";

export interface CommandsProviderContextProps {
    commands?: CommandParams[];
    children: ReactNode;
}
export const CommandsProvider: FC<CommandsProviderContextProps> = ({
    commands,
    children,
}) => {
    const [directories, setDirectories] = useState<CommandsDirectory[]>([]);
    const [commandOptions, setCommandOptions] = useState<CommandsOptions>({});
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState("");
    const currentDirectory = directories[directories.length - 1];
    const commandRef = useRef(null);

    const open = useCallback(
        (
            directory: CommandsDirectory = CommandsDirectory.HOME,
            options: CommandsOptions = {},
        ) => {
            setCommandOptions(options);
            setIsOpen(true);

            if (isOpen && directory === currentDirectory) {
                return;
            }

            if (isOpen && directory) {
                setDirectories((prev) => [...prev, directory]);
            } else {
                setDirectories(directory ? [directory] : []);
            }
        },
        [isOpen, currentDirectory],
    );

    const filterPrioritisingShortcuts: ComponentProps<
        typeof Command
    >["filter"] = useCallback(
        (value: string, searchValue: string, keywords?: string[]) => {
            if (keywords?.includes(`shortcut:${searchValue.toLowerCase()}`)) {
                return 1; // shortcuts on top
            }

            return defaultFilter?.(value, searchValue, keywords) ?? 0;
        },
        [],
    );

    const close = useCallback(() => {
        setDirectories([]);
        setSearch("");
        setIsOpen(false);
    }, []);

    useKeyboardCommands({
        commands: commands ?? [
            {
                key: "k",
                callback: () => open(),
                requiresCtrlOrMeta: true,
                preventDefault: true,
            },
        ],
    });

    const handleDirectoryBack = useCallback(
        (e: KeyboardEvent) => {
            // if in home directory, just close the modal
            if (directories.length <= 1 && e.key === "Escape") {
                close();
                return;
            }

            // Escape goes to previous page
            // Backspace goes to previous page when search is empty
            if (e.key === "Escape" || (e.key === "Backspace" && !search)) {
                e.preventDefault();
                setDirectories((prev) =>
                    prev.length > 1 ? prev.slice(0, -1) : prev,
                );
            }
        },
        [directories.length, search, close],
    );

    const value = useMemo(
        () => ({ open, close, isOpen }),
        [open, close, isOpen],
    );

    const visibleCommands = useMemo(() => {
        switch (currentDirectory) {
            case CommandsDirectory.ACCOUNTS:
                return <AccountsCommandsList options={commandOptions} />;
            case CommandsDirectory.ONBOARDING:
                return <OnboardingCommandsList />;
            case CommandsDirectory.WORKSPACES:
                return <WorkspacesCommandsList />;
            default:
                return <AllCommandsList />;
        }
    }, [commandOptions, currentDirectory]);

    return (
        <commandsContext.Provider value={value}>
            {children}

            <PlainModal
                show={isOpen}
                onHide={() => setIsOpen(false)}
                className={classNames(styles.commands, {
                    [styles.onboardingView]:
                        currentDirectory === CommandsDirectory.ONBOARDING,
                    [styles.accountsView]:
                        currentDirectory === CommandsDirectory.ACCOUNTS,
                })}
            >
                <Command
                    label="Commands"
                    loop
                    onKeyDown={handleDirectoryBack}
                    filter={filterPrioritisingShortcuts}
                    ref={commandRef}
                >
                    <Command.List>
                        <header
                            className={classNames(
                                styles.header,
                                styles.overlay,
                            )}
                        >
                            <CommandsDirectoryBreadcrumbs
                                directories={directories}
                            />

                            <form>
                                <Command.Input
                                    value={search}
                                    onValueChange={setSearch}
                                    className="form-control"
                                    placeholder="Search or type a command"
                                    autoFocus
                                />
                            </form>
                        </header>

                        <main>
                            <Command.Empty>No results found.</Command.Empty>
                            {visibleCommands}
                        </main>

                        {(currentDirectory === CommandsDirectory.ONBOARDING ||
                            currentDirectory ===
                                CommandsDirectory.ACCOUNTS) && (
                            <footer
                                className={classNames(
                                    styles.staticCommands,
                                    styles.overlay,
                                )}
                            >
                                <StaticAccountsCommandsList
                                    options={commandOptions}
                                />
                            </footer>
                        )}
                    </Command.List>
                </Command>
            </PlainModal>
        </commandsContext.Provider>
    );
};
