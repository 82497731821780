import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { FinancialConnectionWithAccounts } from "../../common/types/financialConnection";
import { useUser } from "../../hooks/useUser";
import { useFinancialAccountEntitySelection } from "../../hooks/useFinancialAccountEntitySelection";
import { Button } from "../general/Button/Button";
import {
    StandardModal,
    StandardModalBody,
    StandardModalHeader,
} from "../general/Modal/Modal";
import { FinancialAccountsConnectionProvider } from "./FinancialAccountsConnectionContext";
import { FinancialAccountsConnectionWidget } from "./FinancialAccountConnectionWidget";
import { isValidConnection } from "./helpers";

export interface FinancialAccountsConnectedModalProps {
    show: boolean;
    connection: FinancialConnectionWithAccounts;
    onHide(): void;
}

export const FinancialAccountsConnectedModal: React.FC<
    FinancialAccountsConnectedModalProps
> = ({ show, connection, onHide }) => {
    const { showBusinessEntitySelection } =
        useFinancialAccountEntitySelection();
    const [submitted, setSubmitted] = useState<boolean>(false);
    const user = useUser();
    const handleSubmit = useCallback(() => {
        if (
            isValidConnection(connection) ||
            submitted ||
            !user.onboardingComplete
        ) {
            setSubmitted(false);
            onHide();
        } else {
            setSubmitted(true);
        }
    }, [connection, onHide, submitted, user.onboardingComplete]);

    return (
        <FinancialAccountsConnectionProvider
            showAccountTypeWarning={submitted && user.onboardingComplete}
        >
            <StandardModal
                show={show}
                onHide={onHide}
                size={connection && showBusinessEntitySelection ? "xl" : "lg"}
                backdrop={connection ? true : "static"}
                keyboard={!!connection}
                className={classNames("accounts-connected-modal", {
                    "accounts-connected-modal--with-entities":
                        showBusinessEntitySelection,
                })}
            >
                <>
                    <StandardModalHeader>Select entity</StandardModalHeader>
                    <StandardModalBody>
                        <p>For which entity are you adding these accounts?</p>

                        <FinancialAccountsConnectionWidget
                            accounts={connection.accounts}
                            connection={connection}
                        />

                        <footer className="d-flex align-items-center justify-content-end">
                            <Button onClick={handleSubmit} size="lg">
                                Continue
                            </Button>
                        </footer>
                    </StandardModalBody>
                </>
            </StandardModal>
        </FinancialAccountsConnectionProvider>
    );
};
