import {
    useWorkspaceUserCache,
    WORKSPACE_USER_CACHE_KEYS,
} from "./useWorkspaceUserCache";

export function useFinancialAccountsCache() {
    return useWorkspaceUserCache<number[]>(
        WORKSPACE_USER_CACHE_KEYS.ACCOUNTS_CACHE_KEY,
    );
}
