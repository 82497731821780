import { TransactionClassFilterType } from "../../../common/helpers/transactions";
import { FiltersTypes } from "../../../common/types/filters/general";

export type FiltersChangeFn = (payload: Partial<FiltersTypes>) => void;

export enum FilterTabs {
    CATEGORY = "category",
    DATE = "date",
    AMOUNT = "amount",
    ACCOUNT = "account",
    RULES = "rules",
    CLASS = "class",
    NOTE = "note",
    RECEIPTS = "receipts",
    MATCHED = "matched",
    SPLIT = "split",
    COUNTERPARTY = "counterparty",
    DOCUMENT_STATUS = "documentStatus",
    ACCOUNT_CODE = "accountCode",
    ACCOUNT_STATUS = "accountStatus",
}

export type ActiveFilter =
    | "date"
    | "category"
    | "classes"
    | "direction"
    | "amount"
    | "rules"
    | "isMatched"
    | "isSplitted"
    | "note"
    | "receipts"
    | "counterparties"
    | "accounts"
    | "documentStatus"
    | "accountCodes"
    | "accountStatus";

export const resetPayload: Record<ActiveFilter, Partial<FiltersTypes>> = {
    category: { category: undefined },
    classes: {
        classes: undefined,
        classFilterType: TransactionClassFilterType.ANY_SELECTED,
    },
    direction: { direction: undefined },
    amount: { minAmount: undefined, maxAmount: undefined },
    date: { start: undefined, end: undefined },
    rules: { transactionRulesIds: undefined },
    isMatched: { isMatched: undefined, isNotMatched: undefined },
    isSplitted: { isSplitted: undefined, isNotSplitted: undefined },
    note: {
        hasNote: undefined,
        noNote: undefined,
        requiresNote: undefined,
    },
    receipts: {
        hasReceipt: undefined,
        noReceipt: undefined,
        requiresReceipt: undefined,
    },
    counterparties: { counterpartiesIds: undefined },
    accounts: { accountIds: undefined },
    documentStatus: { documentStatus: undefined },
    accountCodes: { accountCodes: undefined },
    accountStatus: { accountStatus: undefined },
};

export const DEFAULT_TAB = FilterTabs.DATE;

export const activeFiltersOrder: ActiveFilter[] = [
    "date",
    "amount",
    "accounts",
    "category",
    "classes",
    "counterparties",
    "direction",
    "rules",
    "note",
    "receipts",
    "isMatched",
    "isSplitted",
    "documentStatus",
    "accountCodes",
    "accountStatus",
];

export type PredicateFn = (filters: FiltersTypes) => boolean;

export const isActive: Record<ActiveFilter, PredicateFn> = {
    category: ({ category }) => !!category && category.length > 0,
    classes: ({ classes, classFilterType }) =>
        !!classes || classFilterType === TransactionClassFilterType.NO_CLASSES,
    direction: ({ direction }) => !!direction,
    amount: ({ minAmount, maxAmount }) =>
        minAmount !== undefined || maxAmount !== undefined,
    date: ({ start, end }) => start !== undefined || end !== undefined,
    rules: ({ transactionRulesIds }) =>
        !!transactionRulesIds && transactionRulesIds.length > 0,
    isMatched: ({ isMatched, isNotMatched }) => !isMatched !== !isNotMatched,
    isSplitted: ({ isSplitted, isNotSplitted }) =>
        !isSplitted !== !isNotSplitted,
    note: ({ hasNote, noNote, requiresNote }) =>
        [hasNote, noNote, requiresNote].some((value) => !!value),
    receipts: ({ hasReceipt, noReceipt, requiresReceipt }) =>
        [hasReceipt, noReceipt, requiresReceipt].some((value) => !!value),
    counterparties: ({ counterpartiesIds }) =>
        !!counterpartiesIds && counterpartiesIds.length > 0,
    accounts: ({ accountIds }) => !!accountIds && accountIds.length > 0,
    documentStatus: ({ documentStatus }) => !!documentStatus,
    accountCodes: ({ accountCodes }) =>
        !!accountCodes && accountCodes.length > 0,
    accountStatus: ({ accountStatus }) => !!accountStatus,
} as const;

export interface BaseFilterProps {
    filters: FiltersTypes;
    onChange: FiltersChangeFn;
}

export const ActiveFilterToFilterTabsMap: Record<ActiveFilter, FilterTabs> = {
    category: FilterTabs.CATEGORY,
    date: FilterTabs.DATE,
    amount: FilterTabs.AMOUNT,
    direction: FilterTabs.AMOUNT,
    accounts: FilterTabs.ACCOUNT,
    rules: FilterTabs.RULES,
    classes: FilterTabs.CLASS,
    note: FilterTabs.NOTE,
    receipts: FilterTabs.RECEIPTS,
    isMatched: FilterTabs.MATCHED,
    isSplitted: FilterTabs.SPLIT,
    counterparties: FilterTabs.COUNTERPARTY,
    documentStatus: FilterTabs.DOCUMENT_STATUS,
    accountCodes: FilterTabs.ACCOUNT_CODE,
    accountStatus: FilterTabs.ACCOUNT_STATUS,
};
