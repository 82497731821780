import { z } from "zod";

import {
    MoneyDirection,
    FinancialDocumentStatus,
    ReportingTabCycle,
} from "../../../common/constants";
import {
    TransactionClassification,
    TransactionStatus,
} from "../../../common/types/transaction";
import { TransactionClassFilterType } from "../../../common/helpers/transactions";
import { AccountStatus } from "../../../common/types/domains/accounting/account";

export const filtersSchema = z
    .object({
        search: z.string().optional(),
        start: z
            .string()
            .transform((str) => new Date(str))
            .optional(),
        end: z
            .string()
            .transform((str) => new Date(str))
            .optional(),
        minAmount: z.number().optional(),
        maxAmount: z.number().optional(),
        direction: z.nativeEnum(MoneyDirection).optional(),
        category: z.array(z.string()).optional(),
        requiredAction: z.boolean().optional(),
        classification: z.custom<TransactionClassification>().optional(),
        hasTransferMatch: z.boolean().optional(),
        transactionRulesIds: z.array(z.string().uuid()).optional(),
        statuses: z.array(z.custom<TransactionStatus>()).optional(),
        classes: z.array(z.string().uuid()).optional(),
        processedByML: z.boolean().optional(),
        counterpartiesIds: z.array(z.string().uuid()).optional(),
        isMatched: z.boolean().optional(),
        isNotMatched: z.boolean().optional(),
        isSplitted: z.boolean().optional(),
        isNotSplitted: z.boolean().optional(),
        hasReceipt: z.boolean().optional(),
        noReceipt: z.boolean().optional(),
        requiresReceipt: z.boolean().optional(),
        hasNote: z.boolean().optional(),
        noNote: z.boolean().optional(),
        requiresNote: z.boolean().optional(),
        entityIds: z
            .array(z.number())
            .optional()
            .transform((val) => (val?.length ? val : undefined)),
        accountIds: z.array(z.number()).optional(),
        classFilterType: z.nativeEnum(TransactionClassFilterType).optional(),
        documentStatus: z.nativeEnum(FinancialDocumentStatus).optional(),
        entityId: z.number().optional(),
        accountCodes: z.array(z.number()).optional(),
        accountStatus: z.nativeEnum(AccountStatus).optional(),
        cycle: z.nativeEnum(ReportingTabCycle).optional(),
    })
    .strict();

export type FiltersTypes = z.infer<typeof filtersSchema>;
