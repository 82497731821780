import React, { useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";
import classNames from "classnames";
import { FormRowRaw } from "../../forms/FormRowRaw";
import { useEntity } from "../../entity/useEntity";
import { useEditEntityMutation } from "../../../mutations/entity";
import { EntityProfile } from "../../../common/types/entity";
import { entityTypeLabels } from "../../entity/constants";
import styles from "./EntityGeneralSettings.module.scss";

export const EntityGeneralSettings: React.FC = () => {
    const entity = useEntity();
    const [name, setName] = useState(entity.name);
    const [description, setDescription] = useState(
        entity.profile?.description ?? "",
    );
    const [website, setWebsite] = useState(entity.profile?.website ?? "");
    const editEntity = useEditEntityMutation(entity);

    const editName = useCallback(() => {
        if (name?.trim()) {
            editEntity.mutate({ name });
        } else {
            setName(entity.name);
        }
    }, [name, entity.name, editEntity]);

    const editProfile = useCallback(
        (
            field: keyof EntityProfile,
            value: string,
            setter: (value: any) => void,
        ) => {
            if (value?.trim()) {
                editEntity.mutate({
                    profile: {
                        ...entity.profile,
                        [field]: value,
                    },
                });
            } else {
                setter(entity.profile?.[field] ?? "");
            }
        },
        [entity.profile, editEntity],
    );

    return (
        <section className={classNames("mb-5", styles.container)}>
            <header className="settings__section__header">
                <h4>Business information</h4>
            </header>

            <Row>
                <Col md={6}>
                    <FormRowRaw
                        value={name}
                        label="Entity name"
                        size="sm"
                        onChange={setName}
                        onBlur={editName}
                    />
                </Col>
            </Row>
            {entity.profile?.entityType ? (
                <Row>
                    <Col md={6}>
                        <FormRowRaw
                            value={entityTypeLabels[entity.profile.entityType]}
                            label="Entity type"
                            size="sm"
                            disabled
                        />
                    </Col>
                </Row>
            ) : null}
            <Row>
                <Col md={6}>
                    <FormRowRaw
                        value={description}
                        label="Description"
                        size="sm"
                        field={
                            <textarea
                                className="form-control form-control-sm"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                onBlur={() =>
                                    editProfile(
                                        "description",
                                        description,
                                        setDescription,
                                    )
                                }
                            />
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormRowRaw
                        value={website}
                        label="Website"
                        size="sm"
                        onChange={setWebsite}
                        onBlur={() =>
                            editProfile("website", website, setWebsite)
                        }
                    />
                </Col>
            </Row>
        </section>
    );
};
