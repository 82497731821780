import { useMutation } from "@tanstack/react-query";
import { convert, downgrade, subscribe, upgrade } from "../lib/flatRateBilling";
import { FLAT_RATE_PLANS } from "../common/flatRateBilling";
import { queryClient } from "../queryClient";
import { billingQueryKeys } from "../hooks/useBillingStatus";
import { invalidateTransactionsQueries } from "../components/transactions/useTransactionsQuery";
import { SubscribeDto } from "../common/dto/billing/flatRate/subscribe.dto";
import { UpgradeDto } from "../common/dto/billing/flatRate/upgrade.dto";
import { ConvertDto } from "../common/dto/billing/flatRate/convert.dto";

export function useChangePlanMutation(
    newPlan: FLAT_RATE_PLANS,
    onSuccess?: () => void,
) {
    return useMutation({
        mutationFn: () => downgrade({ plan: newPlan }),
        async onSuccess() {
            await queryClient.invalidateQueries({
                queryKey: billingQueryKeys.all(),
            });
            onSuccess?.();
        },
    });
}

export function useConvertTrialMutation(
    params: ConvertDto,
    onSuccess?: () => void,
) {
    return useMutation({
        mutationFn: () => convert(params),
        async onSuccess() {
            await queryClient.invalidateQueries({
                queryKey: billingQueryKeys.all(),
            });
            await invalidateTransactionsQueries();
            onSuccess?.();
        },
    });
}

export function useSubscribeMutation(
    params: SubscribeDto,
    onSuccess?: () => void,
) {
    return useMutation({ mutationFn: () => subscribe(params), onSuccess });
}

export function useUpgradeMutation(params: UpgradeDto, onSuccess?: () => void) {
    return useMutation({ mutationFn: () => upgrade(params), onSuccess });
}
