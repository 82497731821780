import React from "react";
import { animated } from "@react-spring/web";
import classNames from "classnames";
import { ChildrenProps } from "../../types";
import styles from "./ActionCardSection.module.scss";

interface Props extends ChildrenProps {
    header?: React.ReactNode;
    icon?: React.ReactNode;
    inOpened?: boolean;
    isCompleted?: boolean;
    isSkipped?: boolean;
    isTitle?: boolean;
    style?: React.CSSProperties;
    ctas?: React.ReactNode;
}

export const ActionCardSection: React.FC<Props> = ({
    header,
    icon,
    style,
    inOpened,
    isCompleted,
    isSkipped,
    isTitle,
    children,
    ctas,
}) => (
    <animated.section
        className={classNames(styles.section, {
            [styles.inOpened]: inOpened,
            [styles.isCompleted]: isCompleted,
            [styles.isSkipped]: isSkipped,
            [styles.isTitle]: isTitle,
        })}
        style={style}
    >
        {icon && <div className={styles.icon}>{icon}</div>}
        {header && (
            <header className={styles.header}>
                {header}
                {ctas && inOpened && <div className={styles.ctas}>{ctas}</div>}
            </header>
        )}
        {children && <main>{children}</main>}
    </animated.section>
);
