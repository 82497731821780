import classNames from "classnames";
import React, { useCallback } from "react";
import { ActiveFilters } from "../../../general/ActiveFilters/ActiveFilters";
import { AggregatedFilters } from "../../../general/AggregatedFilters/AggregatedFilters";
import css from "../../../general/AggregatedFilters/AggregatedFilters.module.scss";
import { AggregatedFiltersProvider } from "../../../general/AggregatedFilters/AggregatedFiltersProvider";
import { Card } from "../../../general/Card/Card";
import { EntityFilter } from "../../../general/EntityFilter/EntityFilter";
import { SearchFilter } from "../../../general/SearchFilter/SearchFilter";
import { useFiltersActive } from "../../useFiltersActive";
import { DEFAULT_TAB, FilterProps } from "../lib";
import { Button } from "../../../general/Button/Button";
import {
    useActiveFilters,
    useResetFilter,
} from "./ActiveFilters/ActivityFilter.hooks";
import { AggregatedInvoiceFiltersOverlay } from "./AggregatedInvoiceFiltersOverlay";
import { InvoicesActiveFilterButton } from "./ActiveFilters/InvoiceActiveFilterButton";

interface Props extends FilterProps {
    onCreateInvoice: () => void;
}

export const AggregatedInvoiceFilters: React.FC<Props> = ({
    filters,
    onChange,
    onCreateInvoice,
}) => {
    const filtersActive = useFiltersActive(filters);
    const activeFilters = useActiveFilters(filters);
    const resetActiveFilters = useResetFilter(onChange, activeFilters);
    const updateSearchFilter = useCallback(
        (search?: string) => {
            onChange({
                search,
            });
        },
        [onChange],
    );

    return (
        <AggregatedFiltersProvider defaultTab={DEFAULT_TAB}>
            <Card className={css.filters}>
                <section className={css.filters}>
                    <SearchFilter
                        filter={filters.search}
                        onChange={updateSearchFilter}
                    />

                    <AggregatedFilters>
                        <AggregatedInvoiceFiltersOverlay
                            filters={filters}
                            onChange={onChange}
                        />
                    </AggregatedFilters>
                </section>

                {filtersActive && (
                    <ActiveFilters
                        activeFilters={activeFilters}
                        renderVisibleFilters={(visibleFilters) =>
                            visibleFilters.map((filterId) => (
                                <InvoicesActiveFilterButton
                                    key={filterId}
                                    filterId={filterId}
                                    filters={filters}
                                    onChange={onChange}
                                />
                            ))
                        }
                        clearFilters={resetActiveFilters}
                    />
                )}

                <section className={classNames(css.filters, "ml-auto")}>
                    <EntityFilter filters={filters} onChange={onChange} />
                    <Button variant="default" onClick={onCreateInvoice}>
                        Create Invoice
                    </Button>
                </section>
            </Card>
        </AggregatedFiltersProvider>
    );
};
