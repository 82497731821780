import { FC } from "react";
import { Command } from "../../vendor/cmdk/cmdk";
import americanExpressLogo from "../../static/images/logos/americanexpress.svg";
import bankOfAmericaLogo from "../../static/images/logos/bankofamerica.svg";
import capitalOneLogo from "../../static/images/logos/capitalone.png";
import chaseLogo from "../../static/images/logos/chase.png";
import citibankLogo from "../../static/images/logos/citibank.png";
import mercuryLogo from "../../static/images/icons/mercury.svg";
import rampLogo from "../../static/images/icons/ramp.svg";
import wellsFargoLogo from "../../static/images/logos/wellsfargo.png";
import { ConnectPlaid } from "../plaid/ConnectPlaid/ConnectPlaid";
import { useTypedFlags } from "../../hooks/useTypedFlags";
import { CreateMercuryConnection } from "../mercury/CreateMercuryConnection";
import { CreateRampConnection } from "../ramp/CreateRampConnection";
import { PLAID_INSTITUTIONS_NAMES } from "../../common/constants";
import { useAccountsConnectionContext } from "../financialAccount/AccountConnectionWidget/AccountsConnectionContext";
import { useCommands } from "./useCommands";
import styles from "./Commands.module.scss";
import { CommandItem } from "./CommandItem";

export const OnboardingCommandsList: FC = () => {
    const { rampIntegration, mercuryIntegration } = useTypedFlags();
    const { defaultEntity } = useAccountsConnectionContext();

    const { close: closeCommands } = useCommands();

    return (
        <Command.Group heading="Popular">
            {mercuryIntegration && (
                <CreateMercuryConnection
                    onCreated={closeCommands}
                    defaultEntity={defaultEntity}
                >
                    {({ connecting, initializeConnection }) => (
                        <CommandItem
                            onSelect={initializeConnection}
                            disabled={connecting}
                            keywords={["mercury"]}
                        >
                            <img
                                className={styles.icon}
                                src={mercuryLogo}
                                alt="Mercury"
                            />{" "}
                            Mercury
                        </CommandItem>
                    )}
                </CreateMercuryConnection>
            )}

            {rampIntegration && (
                <CreateRampConnection
                    onCreated={closeCommands}
                    defaultEntity={defaultEntity}
                >
                    {({ connecting, initializeConnection }) => (
                        <CommandItem
                            onSelect={initializeConnection}
                            disabled={connecting}
                        >
                            <img
                                className={styles.icon}
                                src={rampLogo}
                                alt="Ramp"
                            />{" "}
                            Ramp
                        </CommandItem>
                    )}
                </CreateRampConnection>
            )}

            <ConnectPlaid
                onConnected={closeCommands}
                institutionName={PLAID_INSTITUTIONS_NAMES.CHASE}
                defaultEntity={defaultEntity}
            >
                {({ connecting, initializeConnection }) => (
                    <CommandItem
                        onSelect={initializeConnection}
                        disabled={connecting}
                        keywords={["chase"]}
                    >
                        <img
                            className={styles.icon}
                            src={chaseLogo}
                            alt="Chase"
                        />{" "}
                        Chase
                    </CommandItem>
                )}
            </ConnectPlaid>

            <ConnectPlaid
                onConnected={closeCommands}
                institutionName={PLAID_INSTITUTIONS_NAMES.AMERICAN_EXPRESS}
                defaultEntity={defaultEntity}
            >
                {({ connecting, initializeConnection }) => (
                    <CommandItem
                        onSelect={initializeConnection}
                        disabled={connecting}
                        keywords={["american express", "amex"]}
                    >
                        <img
                            className={styles.icon}
                            src={americanExpressLogo}
                            alt="American Express"
                        />{" "}
                        American Express{" "}
                    </CommandItem>
                )}
            </ConnectPlaid>

            <ConnectPlaid
                onConnected={closeCommands}
                institutionName={PLAID_INSTITUTIONS_NAMES.CAPITAL_ONE}
                defaultEntity={defaultEntity}
            >
                {({ connecting, initializeConnection }) => (
                    <CommandItem
                        onSelect={initializeConnection}
                        disabled={connecting}
                        keywords={["capital one"]}
                    >
                        <img
                            className={styles.icon}
                            src={capitalOneLogo}
                            alt="Capital One"
                        />{" "}
                        Capital One{" "}
                    </CommandItem>
                )}
            </ConnectPlaid>

            <ConnectPlaid
                onConnected={closeCommands}
                institutionName={PLAID_INSTITUTIONS_NAMES.BANK_OF_AMERICA}
                defaultEntity={defaultEntity}
            >
                {({ connecting, initializeConnection }) => (
                    <CommandItem
                        onSelect={initializeConnection}
                        disabled={connecting}
                        keywords={["bank of america", "boa"]}
                    >
                        <img
                            className={styles.icon}
                            src={bankOfAmericaLogo}
                            alt="Bank of America"
                        />{" "}
                        Bank of America{" "}
                    </CommandItem>
                )}
            </ConnectPlaid>

            <ConnectPlaid
                onConnected={closeCommands}
                institutionName={PLAID_INSTITUTIONS_NAMES.WELLS_FARGO}
                defaultEntity={defaultEntity}
            >
                {({ connecting, initializeConnection }) => (
                    <CommandItem
                        onSelect={initializeConnection}
                        disabled={connecting}
                        keywords={[
                            "wellsfargo",
                            "wells fargo",
                            "wells",
                            "fargo",
                        ]}
                    >
                        <img
                            className={styles.icon}
                            src={wellsFargoLogo}
                            alt="Wells Fargo"
                        />{" "}
                        Wells Fargo
                    </CommandItem>
                )}
            </ConnectPlaid>

            <ConnectPlaid
                onConnected={closeCommands}
                institutionName={PLAID_INSTITUTIONS_NAMES.CITIBANK}
                defaultEntity={defaultEntity}
            >
                {({ connecting, initializeConnection }) => (
                    <CommandItem
                        onSelect={initializeConnection}
                        disabled={connecting}
                        keywords={["citibank", "citi bank", "citi"]}
                    >
                        <img
                            className={styles.icon}
                            src={citibankLogo}
                            alt="Citibank"
                        />{" "}
                        Citibank
                    </CommandItem>
                )}
            </ConnectPlaid>
        </Command.Group>
    );
};
