import classNames from "classnames";
import { PopoverContainer } from "../../general/PopoverContainer";
import { MultiselectProps } from "./Multiselect";
import styles from "./Multiselect.module.scss";

interface MultiSelectContainerProps extends MultiselectProps {
    children: React.ReactNode;
    inDropdown?: boolean;
    isActive: boolean;
    searchRef: React.MutableRefObject<HTMLInputElement | undefined>;
    setSearch: (search: string) => void;
}

export const MultiSelectContainer = ({
    children,
    inDropdown,
    buttonText,
    buttonClass,
    name,
    value,
    options,
    isActive,
    searchRef,
    setSearch,
    containerClassname,
    ...popoverButtonProps
}: MultiSelectContainerProps) =>
    inDropdown ? (
        <PopoverContainer
            buttonText={
                typeof buttonText === "function"
                    ? buttonText(value, options)
                    : buttonText
            }
            buttonClass={classNames("popover-activator", buttonClass, {
                "popover-activator--selected": !!isActive,
            })}
            id={name}
            onShow={() => searchRef.current?.focus()}
            onHide={() => setSearch("")}
            offset={4}
            {...popoverButtonProps}
        >
            {children}
        </PopoverContainer>
    ) : (
        <div
            className={classNames(
                styles.multiselectContainer,
                containerClassname,
            )}
        >
            {children}
        </div>
    );
