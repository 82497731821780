import React, { useMemo } from "react";
import { Switch } from "../Switch/Switch";
import { AccountCard } from "../AccountCard/AccountCard";
import { Entity } from "../../../common/types/entity";
import { FinancialAccount } from "../../../common/types/financialAccount";
import { AccountFilterSharedProps } from "./lib";

interface Props extends AccountFilterSharedProps {
    account: FinancialAccount;
    entity: Entity;
}

export const AccountsFilterAccount: React.FC<Props> = ({
    account,
    isAccountSelected,
    toggleAccount,
    entity,
    disabled,
}) => {
    const selected = useMemo(
        () => isAccountSelected(account.id, entity.id),
        [isAccountSelected, account.id, entity.id],
    );

    return (
        <div className="accounts-filter__account">
            <Switch
                disabled={disabled}
                active={selected}
                onChanged={() => toggleAccount(account.id, entity.id)}
                size="sm"
                direction="rtl"
                label={
                    <AccountCard
                        account={account}
                        onlyNumber={true}
                        showType={false}
                        iconSize="sm"
                    />
                }
            />
        </div>
    );
};
