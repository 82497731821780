import { useEffect } from "react";
import { useQueryParams, StringParam, withDefault } from "use-query-params";
import {
    PARTNER_KEY_COOKIE,
    XID_COOKIE,
} from "../../common/registrationCookies";
import { setCookie } from "../../helpers/cookie";

export function usePartnerStackCookies() {
    const [
        { ps_xid: partnerStackXid, ps_partner_key: partnerStackPartnerKey },
    ] = useQueryParams({
        ps_xid: withDefault(StringParam, ""),
        ps_partner_key: withDefault(StringParam, ""),
    });

    useEffect(() => {
        if (partnerStackXid) {
            setCookie(XID_COOKIE, partnerStackXid);
            setCookie(PARTNER_KEY_COOKIE, partnerStackPartnerKey);
        }
    }, [partnerStackPartnerKey, partnerStackXid]);
}
