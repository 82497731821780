import React from "react";
import { Col, Row } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { getSimulationsDashboard } from "../../lib/simulations";
import { Loader } from "../general/Loader";
import { RecentMessages } from "./RecentMessages";
import { SimulatePlaidAccountError } from "./SimlulatePlaidAccountError";
import { SimulateIncomingSms } from "./SimulateIncomingSms";
import { ExternalConnections } from "./ExternalConnections";

export const SimulationsDashboard: React.FC = () => {
    const { data: dashboard } = useQuery({
        queryKey: ["simulationsDashboard"],
        queryFn: getSimulationsDashboard,
    });

    return dashboard ? (
        <main className="pt-5 pl-5 pr-5 pb-5">
            <Row className="mb-4">
                <Col>
                    <RecentMessages messages={dashboard.recentMessages} />
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <ExternalConnections
                        connections={dashboard.plaidConnections}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <SimulatePlaidAccountError />
                </Col>
                <Col md={3}>
                    <SimulateIncomingSms />
                </Col>
            </Row>
        </main>
    ) : (
        <Loader />
    );
};
