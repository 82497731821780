import { useCallback, useState, useMemo } from "react";
import { Tooltip } from "react-bootstrap";
import { UpdateTransactionDto } from "../../../common/dto/transactions/update-transaction.dto";
import { ActionStatus } from "../../../common/types/actions";
import { ReceiptIcon } from "../../../icons";
import { ActionCard } from "../ActionCard";
import { ActionCardCompleted } from "../ActionCardCompleted";
import { ActionCardSection } from "../ActionCardSection";
import { ActionCardTransaction } from "../ActionCardTransaction";
import { CampaignCardProps } from "../ActionItem";
import { TransactionDetails } from "../transactionDetails/TransactionDetails";
import { ActionConfirmButton } from "../ActionConfirmButton";
import { ActionCloseButton } from "../ActionCloseButton";
import { UPLOAD_RECEIPT_ACTION } from "../../../common/requiredActions";
import { TaxQuestionKey } from "../../../common/taxSavingRules";
import { Button } from "../../general/Button/Button";
import { TransactionUpdateMetaDto } from "../../../common/dto/actions/transaction-update-meta.dto";

export const IRSRequirementsCard: React.FC<
    CampaignCardProps<TransactionUpdateMetaDto>
> = ({ action: originalAction, onConfirm, onSkip, isLoading, ...rest }) => {
    const [action, setAction] = useState(originalAction);
    const { isCompleted } = rest;
    const [receiptUploaded, setReceiptUploaded] = useState(false);

    const travelDestinationRequired = useMemo(() => {
        const answers = action.meta.transactionUpdate?.taxQuestionAnswers || [];

        return answers.some(
            (q) =>
                q.key === TaxQuestionKey.TRAVEL_DESTINATION && q.answer === "",
        );
    }, [action.meta.transactionUpdate?.taxQuestionAnswers]);

    const receiptRequired = action.transaction?.requiredActions.includes(
        UPLOAD_RECEIPT_ACTION,
    );

    const requiresAction =
        (receiptRequired && !receiptUploaded) || travelDestinationRequired;

    const updateTransaction = useCallback(
        (update: Partial<UpdateTransactionDto>) => {
            setAction((prev) => ({
                ...prev,
                meta: {
                    ...prev.meta,
                    transactionUpdate: {
                        ...prev.meta.transactionUpdate,
                        ...update,
                    },
                },
            }));
        },
        [],
    );

    const confirmAction = useCallback(() => {
        onConfirm?.(action);
    }, [action, onConfirm]);

    const skipAction = useCallback(() => {
        onSkip?.(action);
    }, [action, onSkip]);

    const getRequirementsTooltip = useMemo(() => {
        const requirements: string[] = [];

        if (receiptRequired && !receiptUploaded) {
            requirements.push("Receipt is required");
        }

        if (travelDestinationRequired) {
            requirements.push("Travel destination is required");
        }

        return requirements.length ? (
            <Tooltip id={`action-${action.id}-requirements`}>
                {requirements.join(" and ")} to confirm this action.
            </Tooltip>
        ) : undefined;
    }, [
        action.id,
        receiptRequired,
        receiptUploaded,
        travelDestinationRequired,
    ]);

    const firstSection = (inOpened: boolean, closeCard: () => void) =>
        isCompleted ? (
            <ActionCardCompleted header={`Requirements confirmed`}>
                <ActionCloseButton onClick={closeCard} />
            </ActionCardCompleted>
        ) : (
            <ActionCardSection
                inOpened={inOpened}
                header="Handle IRS requirements for this transaction"
                icon={<ReceiptIcon />}
                ctas={
                    <>
                        {action.status !== ActionStatus.SKIPPED && (
                            <Button
                                variant="secondary"
                                onClick={() => skipAction()}
                            >
                                Skip
                            </Button>
                        )}
                        <ActionConfirmButton
                            disabled={requiresAction}
                            loading={isLoading}
                            onClick={confirmAction}
                            tooltip={getRequirementsTooltip}
                        />
                    </>
                }
                isTitle
            >
                {action.transaction && (
                    <ActionCardTransaction
                        transaction={action.transaction}
                        showEntity
                        showCategory
                    />
                )}
            </ActionCardSection>
        );

    const otherSections = (inOpened: boolean) => (
        <ActionCardSection
            header="Confirm these transaction details"
            inOpened={inOpened}
        >
            <TransactionDetails
                action={action}
                showMemo
                showReceipt
                mustAddReceiptIfRequired
                onReceiptAdded={() => setReceiptUploaded(true)}
                onUpdate={updateTransaction}
            />
        </ActionCardSection>
    );

    return (
        <ActionCard
            {...rest}
            firstSection={firstSection}
            otherSections={otherSections}
        />
    );
};
