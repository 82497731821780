import React from "react";
import {
    AuditLog,
    AuditLogSimilarityMetadata,
} from "../../../../common/types/auditLog";
import { currencyFormatter } from "../../../../common/helpers/currency";

interface Props {
    log: AuditLog;
    categoryLabel: string;
}

export const AuditLogSimilarityContent: React.FC<Props> = ({
    log,
    categoryLabel,
}) => {
    const metadata = log.metadata as AuditLogSimilarityMetadata;
    const similarity =
        metadata.amount && metadata.description ? (
            <>
                {metadata.amount < 0 ? "purchase at" : "credit from"}{" "}
                <strong>{metadata.description}</strong> for{" "}
                <strong>
                    {currencyFormatter.format(Math.abs(metadata.amount))}
                </strong>
            </>
        ) : (
            "other transaction"
        );

    return (
        <>
            Category set to <strong>{categoryLabel}</strong> because transaction
            is similar to {similarity}.
        </>
    );
};
