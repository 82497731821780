import React, { useContext } from "react";
import { AccountingReportContext } from "../AccountingReport.context";
import { accountingNumberFormatter } from "../../../common/helpers/number";
import { DataTableCell } from "../../general/DataTable/DataTableCell";
import { ProfitLossDrillDown } from "./ProfitLossDrillDown";

interface Props {
    value: number;
    label?: string;
    accountCodes?: number[];
}

export const ProfitLossValueCell: React.FC<Props> = ({
    value,
    accountCodes,
    label,
}) => {
    const { filters } = useContext(AccountingReportContext);
    const { startDate, endDate, entityId, classes, classFilterType } = filters;
    const displayValue = accountingNumberFormatter(value);

    return (
        <DataTableCell>
            {value ? (
                <ProfitLossDrillDown
                    accountCodes={accountCodes}
                    label={label}
                    startDate={startDate}
                    endDate={endDate}
                    entityId={entityId}
                    classes={classes}
                    classFilterType={classFilterType}
                >
                    {displayValue}
                </ProfitLossDrillDown>
            ) : (
                displayValue
            )}
        </DataTableCell>
    );
};
