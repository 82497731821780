import { UserWorkspaceRole } from "../userWorkspaceRoles";
import { Entity } from "./entity";
import { UserRole } from "./userEntityAssignment";

export enum InvitedUserRole {
    ADMIN = "admin",
    MEMBER = "member",
    ACCOUNTANT = "accountant",
}

export interface InviteEntityAssignment {
    entity: Entity;
    role: UserRole;
}

export interface InviteCode {
    id: number;
    email: string | null;
    used: boolean;
    entityAssignments: InviteEntityAssignment[];
    role: UserWorkspaceRole | null;
    iconColor: string;
}
