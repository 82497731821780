import { KeyboardEvent, useMemo } from "react";

const INTERACTION_KEYS = ["enter", "space", " "];

type Interactions = "onClick" | "onMouseLeave" | "onMouseEnter";

interface Focusable {
    tabIndex: 0;
}

export interface ClickWithKeyboardEventInteraction<
    T extends (...args: any) => any,
> extends Focusable {
    onClick: T;
    onKeyPress: (e: KeyboardEvent) => ReturnType<T>;
}

export interface LeaveWithBlurInteraction<T extends (...args: any) => any>
    extends Focusable {
    onMouseLeave: T;
    onBlur: () => ReturnType<T>;
}

export interface EnterWithFocusInteraction<T extends (...args: any) => any>
    extends Focusable {
    onMouseEnter: T;
    onFocus: () => ReturnType<T>;
}

type UseInteraction<T extends (...args: any) => any> =
    | ClickWithKeyboardEventInteraction<T>
    | LeaveWithBlurInteraction<T>
    | EnterWithFocusInteraction<T>;

export function useInteraction<T extends (...args: any) => any>(
    cb?: T,
    interaction: Interactions = "onClick",
): UseInteraction<T> | undefined {
    return useMemo(() => {
        if (!cb) {
            return;
        }

        if (interaction === "onClick") {
            return {
                onClick: cb,
                onKeyPress: (e: KeyboardEvent) => {
                    if (INTERACTION_KEYS.includes(e.key.toLowerCase())) {
                        return cb();
                    }
                },
                tabIndex: 0,
            };
        } else if (interaction === "onMouseLeave") {
            return {
                onMouseLeave: cb,
                onBlur: cb,
                tabIndex: 0,
            };
        } else if (interaction === "onMouseEnter") {
            return {
                onMouseEnter: cb,
                onFocus: cb,
                tabIndex: 0,
            };
        }
    }, [cb, interaction]);
}
