import React from "react";
import { Dropdown, DropdownProps } from "react-bootstrap";
import classNames from "classnames";
import { DotsHorizontalIcon } from "../../../icons";
import { CustomButtonToggle } from "../CustomToggle/CustomToggle";
import styles from "./ManagementDropdown.module.scss";
export interface ManagementDropdownProps extends DropdownProps {
    id: string;
    className?: string;
    toggleClassName?: string;
    toggleDisabled?: boolean;
    trigger?: React.ReactNode;
    popperConfigStrategy?: "fixed" | "absolute";
}

export const ManagementDropdown: React.FC<ManagementDropdownProps> = ({
    id,
    className,
    toggleClassName,
    toggleDisabled,
    trigger = <DotsHorizontalIcon />,
    children,
    popperConfigStrategy,
    ...rest
}) => (
    <Dropdown
        {...rest}
        className={classNames(
            "management-dropdown",
            styles.dropdown,
            className,
        )}
        data-testid="management-dropdown"
    >
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <Dropdown.Toggle
                id={id}
                as={CustomButtonToggle}
                disabled={toggleDisabled}
                size="sm"
            >
                {trigger}
            </Dropdown.Toggle>
        </div>

        <Dropdown.Menu
            popperConfig={{ strategy: popperConfigStrategy }}
            className={styles.menu}
            data-testid="dropdown-menu"
            rootCloseEvent="mousedown"
        >
            {children}
        </Dropdown.Menu>
    </Dropdown>
);
