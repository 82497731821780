import { startOfMonth, subMonths } from "date-fns";
import { DEFAULT_NUMBER_OF_MONTHS_AVAILABLE } from "../common/constants";
import { useUser } from "./useUser";

export function useDefaultBookkeepingStartDate() {
    const user = useUser();

    return subMonths(
        startOfMonth(user.createdAt),
        DEFAULT_NUMBER_OF_MONTHS_AVAILABLE,
    );
}
