import { subMinutes } from "date-fns";
import { FinancialAccount } from "../common/types/financialAccount";
import { useIsDemoUser } from "./useIsDemoUser";

export function useFinancialAccountSyncedAt(account: FinancialAccount) {
    const isDemoUser = useIsDemoUser();
    if (isDemoUser) {
        return subMinutes(new Date(), 4);
    }

    return account.balanceSyncedAt ?? account.transactionsSyncedAt;
}
