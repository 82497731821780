export enum AccountType {
    cash = "Cash",
    accountsReceivable = "Accounts Receivable",
    prepaidExpenses = "Prepaid Expenses",
    inventory = "Inventory",
    otherCurrentAssets = "Other Current Assets",
    fixedAssets = "Fixed Assets",
    intangibleAssets = "Intangible Assets",
    otherAssets = "Other Assets",
    accountsPayable = "Accounts Payable",
    creditCards = "Credit Cards",
    payrollLiabilities = "Payroll Liabilities",
    otherCurrentLiabilities = "Other Current Liabilities",
    notesPayable = "Notes Payable",
    longTermLiabilities = "Long-Term Liabilities",
    otherLiabilities = "Other Liabilities",
    equity = "Equity",
    income = "Income",
    cogs = "COGS",
    payrollExpenses = "Payroll Expenses",
    payrollTaxes = "Payroll Taxes",
    benefits = "Benefits",
    operatingExpenses = "Operating Expenses",
    otherIncome = "Other Income",
    otherExpenses = "Other Expenses",
    taxExpenses = "Tax Expenses",
}

export enum AccountSubtype {
    cashOnHand = "Cash on Hand",
    bankAccount = "Bank Account",
    paymentProcessor = "Payment Processor",
    bankTransferClearing = "Bank Transfer Clearing",
    paymentTransferClearing = "Payment Transfer Clearing",
    accountsReceivable = "Accounts Receivable",
    prepaidExpenses = "Prepaid Expenses",
    inventory = "Inventory",
    otherCurrentAssets = "Other Current Assets",
    land = "Land",
    buildings = "Buildings",
    leaseholds = "Leaseholds",
    vehicles = "Vehicles",
    equipment = "Equipment",
    furnitureAndFixtures = "Furniture And Fixtures",
    otherFixedAssets = "Other Fixed Assets",
    accumulatedDepreciation = "Accumulated Depreciation",
    startupCosts = "Startup Costs",
    trademarksAndPatents = "Trademarks and Patents",
    goodwill = "Goodwill",
    otherIntangibleAssets = "Other Intangible Assets",
    accumulatedAmortization = "Accumulated Amortization",
    intercompanyReceivables = "Intercompany Receivables",
    employeeLoans = "Employee Loans",
    securityDeposits = "Security deposits",
    otherAssets = "Other Assets",
    accountsPayable = "Accounts Payable",
    creditCard = "Credit Card",
    creditCardClearing = "Credit Card Clearing",
    payrollPayable = "Payroll Payable",
    salesTaxPayable = "Sales Tax Payable",
    deferredRevenue = "Deferred Revenue",
    accruedExpenses = "Accrued Expenses",
    lineOfCredit = "Line of Credit",
    shortTermLoans = "Short-term Loans",
    currentMaturitiesOfLongTermDebt = "Current Maturities of Long-Term Debt",
    longTermLoans = "Long-Term Loans",
    lessCurrentMaturitiesOfLongTermDebt = "Less Current Maturities of Long-Term Debt",
    intercompanyPayables = "Intercompany Payables",
    otherLiabilities = "Other Liabilities",
    contributions = "Contributions",
    distributions = "Distributions",
    capital = "Capital",
    commonStock = "Common Stock",
    additionalPaidInCapital = "Additional Paid-In Capital",
    dividends = "Dividends",
    retainedEarnings = "Retained Earnings",
    revenue = "Revenue",
    refundsAndReturns = "Refunds and Returns",
    cogsGoodsAndMaterials = "COGS - Goods and Materials",
    cogsLabor = "COGS - Labor",
    cogsOther = "COGS - Other",
    salariesAndWages = "Salaries and Wages",
    guaranteedPaymentsToPartners = "Guaranteed payments to partners",
    officersCompensation = "Officers compensation",
    contractLabor = "Contract Labor",
    salariesAndWagesClearing = "Salaries and Wages - Clearing",
    commisions = "Commisions",
    payrollTaxes = "Payroll Taxes",
    employeeBenefits = "Employee Benefits",
    advertisingAndMarketing = "Advertising and Marketing",
    computersAndElectronics = "Computers and Electronics",
    learningAndDevelopment = "Learning and Development",
    licensesAndPermits = "Licenses and Permits",
    propertyTaxes = "Property Taxes",
    entertainment = "Entertainment",
    insurance = "Insurance",
    internetAndPhone = "Internet and Phone",
    facilities = "Facilities",
    meals = "Meals",
    merchantAndBankFees = "Merchant and Bank Fees",
    suppliesAndEquipment = "Supplies and Equipment",
    softwareAndSubscriptions = "Software and Subscriptions",
    officeExpenses = "Office Expenses",
    officeUtilities = "Office Utilities",
    legalAndProfessionalFees = "Legal and Professional Fees",
    rentAndLease = "Rent and Lease",
    travel = "Travel",
    vehicle = "Vehicle",
    otherOperatingExpenses = "Other Operating Expenses",
    interestIncome = "Interest Income",
    gainLossAssetDisposals = "Gain/Loss Asset Disposals",
    rebateIncome = "Rebate Income",
    otherIncome = "Other Income",
    interestExpense = "Interest Expense",
    depreciation = "Depreciation",
    amortization = "Amortization",
    finesAndPenalties = "Fines and Penalties",
    badDebts = "Bad debts",
    donations = "Donations",
    otherExpense = "Other Expense",
    federalTaxes = "Federal Taxes",
    stateAndLocalTaxes = "State and Local Taxes",
    uncategorizedIncome = "Uncategorized Income",
    uncategorizedExpenses = "Uncategorized Expenses",
}

export enum AccountClass {
    assets = "Assets",
    liabilities = "Liabilities",
    equity = "Equity",
    income = "Income",
    expenses = "Expenses",
}

export enum BalanceSheetDerivativeLines {
    netIncome = "Net Income",
}

export enum DefaultCashBasisAccountCodes {
    cashOnHands = 10000,
    bankTransferClearing = 10900,
    paymentTransferClearing = 10901,
    accountsReceivable = 11000,
    inventory = 13000,
    otherCurrentAssets = 14000,
    land = 15000,
    buildings = 15100,
    leaseholdImprovements = 15200,
    vehicles = 15300,
    equipment = 15400,
    furnitureAndFixturesAsset = 15500,
    otherFixedAssets = 15800,
    accumulatedDepreciation = 15900,
    startupCosts = 16000,
    trademarksAndPatents = 16100,
    goodwill = 16200,
    otherIntangibleAssets = 16800,
    accumulatedAmortization = 16900,
    intercompanyReceivables = 19000,
    employeeLoans = 19100,
    securityDeposits = 19200,
    otherLongTermAssets = 19900,
    accountsPayable = 20000,
    creditCardClearing = 21900,
    payrollPayable = 22000,
    salesTaxPayable = 23000,
    lineOfCredit = 24000,
    shortTermLoans = 24100,
    currentMaturitiesOfLongTermDebt = 24900,
    longTermLoans = 25000,
    lessCurrentMaturitiesOfLongTermDebt = 25900,
    intercompanyPayables = 29000,
    otherLiabilities = 29900,
    revenue = 40000,
    refundsAndReturns = 40100,
    uncategorizedIncome = 49999,
    cogsGoodsAndMaterials = 50000,
    cogsLabor = 50100,
    cogsOther = 50200,
    salariesAndWages = 60000,
    contractLabor = 60100,
    salariesAndWagesClearing = 60900,
    payrollTaxes = 61000,
    employeeBenefits = 62000,
    advertisingAndMarketing = 70000,
    learningAndDevelopment = 70100,
    licensesAndPermits = 70200,
    propertyTaxes = 70300,
    entertainment = 70400,
    insuranceBusiness = 70500,
    insuranceOwnersPartnersOfficers = 70501,
    internetAndPhone = 70600,
    facilitiesRepairsAndMaintenance = 70700,
    facilitiesCleaningFees = 70701,
    facilitiesManagementFees = 70702,
    furnitureAndFixturesExpense = 70800,
    meals = 70900,
    mealsEmployeeCatering = 70901,
    merchantAndBankFees = 71000,
    suppliesAndEquipment = 71100,
    softwareAndSubscriptions = 71200,
    officeExpenses = 71300,
    officeUtilities = 71400,
    legalAndProfessionalFees = 71500,
    rentAndLease = 71600,
    travel = 71700,
    vehicle = 71800,
    vehicleLeases = 71801,
    vehicleParkingAndTolls = 71802,
    otherOperatingExpenses = 79900,
    uncategorizedExpenses = 79999,
    interestIncome = 80000,
    gainLossAssetDisposals = 80100,
    rebateIncome = 80200,
    otherIncome = 84900,
    interestExpense = 85000,
    depreciation = 85100,
    amortization = 85200,
    finesAndPenalties = 85300,
    badDebts = 85400,
    donations = 85500,
    otherExpense = 89900,
    federalTaxes = 90000,
    stateAndLocalTaxes = 90100,
}
