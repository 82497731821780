import { useQuery } from "@tanstack/react-query";
import { FinancialDocument } from "../../../common/types/financialDocument";
import { getFinancialDocumentFile } from "../../../lib/financialDocument";
import { financialDocumentFileQueryKeys } from "../lib";

export function useFinancialDocumentFileQuery(
    financialDocument: FinancialDocument,
) {
    return useQuery({
        queryKey: financialDocumentFileQueryKeys.file(financialDocument.id),
        queryFn: () => getFinancialDocumentFile(financialDocument.id),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
}
