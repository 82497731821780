import React, { forwardRef } from "react";
import { useInteraction } from "../../../hooks/useInteraction";
import { Button, ExtendedButtonProps } from "../Button/Button";
import styles from "./CustomToggle.module.scss";

interface CustomToggleProps extends React.HTMLAttributes<HTMLDivElement> {
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const CustomToggle: React.FC<CustomToggleProps> = forwardRef(
    ({ children, onClick }, ref) => {
        const toggleClick = useInteraction(onClick);

        return (
            <div {...toggleClick} className={styles.toggle}>
                {children}
                <div ref={ref as any} />
            </div>
        );
    },
);

export const CustomButtonToggle: React.FC<ExtendedButtonProps> = forwardRef(
    ({ children, onClick, size, variant }, ref) => {
        const toggleClick = useInteraction(onClick);

        return (
            <Button
                icon
                {...toggleClick}
                className={styles.toggle}
                ref={ref as any}
                size={size}
                variant={variant || "tertiary"}
                type="button"
            >
                {children}
            </Button>
        );
    },
);
