import React, { useCallback, useMemo, useState } from "react";
import { Entity } from "../../../../common/types/entity";
import { FinancialAccount } from "../../../../common/types/financialAccount";
import { useFinancialAccounts } from "../../../../hooks/useFinancialAccounts";
import { FormRowRaw } from "../../../forms/FormRowRaw";
import { CustomSelect } from "../../../forms/CustomSelect/CustomSelect";
import { AccountCard } from "../../../general/AccountCard/AccountCard";
import { getAccountNameWithLast4 } from "../../../../common/helpers/financialAccount";
import { useAccountMergingMutation } from "../../../../mutations/financialAccount";
import { ButtonWithLoader } from "../../../general/ButtonWithLoader/ButtonWithLoader";
import { ModalComponentProps } from "../../../../helpers/modal";
import { MergeFinancialAccountsResponse } from "../../../../common/dto/financialAccount/mergeFinancialAccounts/merge-financial-accounts-response.dto";
import { useToaster } from "../../../general/ToastMessages/useToaster";
import { Button } from "../../../general/Button/Button";
import {
    StandardModalBody,
    StandardModalHeader,
} from "../../../general/Modal/Modal";
import styles from "./MergeAccountsModal.module.scss";
import { ExecuteMergeOptions, MergePreview } from "./MergePreview";
interface Props extends ModalComponentProps {
    financialAccount: FinancialAccount;
    entity: Entity;
}

export const MergeAccountsModal: React.FC<Props> = ({
    financialAccount,
    entity,
    close,
}) => {
    const otherAccounts = useFinancialAccounts({ entityId: entity.id });
    const [accountToMerge, setAccountToMerge] = useState(financialAccount);
    const [destinationAccount, setDestinationAccount] =
        useState<FinancialAccount>();
    const selectOptions = useMemo(
        () =>
            otherAccounts
                .filter((account) => account.id !== accountToMerge.id)
                .map((account) => ({
                    value: account.id,
                    label: (
                        <AccountCard
                            account={account}
                            showType={false}
                            numberInName={true}
                        />
                    ),
                })),
        [otherAccounts, accountToMerge],
    );
    const onSelectAccount = useCallback(
        (accountId: number) => {
            setDestinationAccount(
                otherAccounts.find((account) => account.id === accountId),
            );
        },
        [otherAccounts],
    );
    const [mergePreview, setMergePreview] =
        useState<MergeFinancialAccountsResponse>();

    const mergedAccountName = getAccountNameWithLast4(accountToMerge, 2);
    const destinationAccountName =
        destinationAccount && getAccountNameWithLast4(destinationAccount, 2);

    const flipAccounts = useCallback(() => {
        if (destinationAccount) {
            setAccountToMerge(destinationAccount);
            setDestinationAccount(accountToMerge);
        }
    }, [accountToMerge, destinationAccount]);

    const mergeMutation = useAccountMergingMutation((response) => {
        setMergePreview(response);
    });
    const { toast } = useToaster();

    const previewMerge = useCallback(() => {
        if (!destinationAccount) {
            return;
        }

        mergeMutation.mutate({
            accountToMergeId: accountToMerge.id,
            destinationAccountId: destinationAccount.id,
            entityId: entity.id,
            dryRun: true,
        });
    }, [destinationAccount, mergeMutation, accountToMerge.id, entity.id]);

    const executeMerge = useCallback(
        async (options: ExecuteMergeOptions) => {
            if (!destinationAccount) {
                return;
            }

            await mergeMutation.mutateAsync({
                accountToMergeId: accountToMerge.id,
                destinationAccountId: destinationAccount.id,
                entityId: entity.id,
                dryRun: false,
                ...options,
            });

            toast("Accounts merged");
            close();
        },
        [
            destinationAccount,
            mergeMutation,
            accountToMerge.id,
            entity.id,
            close,
            toast,
        ],
    );

    return (
        <div className={styles.mergeAccountsModal}>
            <StandardModalHeader>Merge accounts</StandardModalHeader>
            <StandardModalBody>
                {mergePreview && destinationAccount ? (
                    <MergePreview
                        destinationAccount={destinationAccount}
                        accountToMerge={accountToMerge}
                        mergePreview={mergePreview}
                        executeMerge={executeMerge}
                        onReset={() => setMergePreview(undefined)}
                    />
                ) : (
                    <>
                        <FormRowRaw
                            label="Merge"
                            value={accountToMerge}
                            field={
                                <div className="form-control">
                                    <AccountCard
                                        account={accountToMerge}
                                        showType={false}
                                        numberInName={true}
                                    />
                                </div>
                            }
                        />
                        <FormRowRaw
                            label="into"
                            value={destinationAccount}
                            field={
                                <CustomSelect
                                    options={selectOptions}
                                    dropdownKey="destination-account"
                                    onSelected={onSelectAccount}
                                    value={destinationAccount?.id}
                                />
                            }
                        />

                        {destinationAccount && (
                            <>
                                <div className="d-flex justify-content-center mb-4">
                                    <Button
                                        variant="secondary"
                                        onClick={flipAccounts}
                                    >
                                        Flip accounts
                                    </Button>
                                </div>

                                <p>
                                    This will move all transactions from{" "}
                                    <strong>{mergedAccountName}</strong> into{" "}
                                    <strong>{destinationAccountName}</strong>{" "}
                                    and remove{" "}
                                    <strong>{mergedAccountName}</strong>
                                </p>

                                <footer className="text-right">
                                    <ButtonWithLoader
                                        variant="default"
                                        loading={mergeMutation.isPending}
                                        onClick={previewMerge}
                                    >
                                        Preview merge
                                    </ButtonWithLoader>
                                    <p className="small mb-0 mt-2">
                                        This will not modify any data yet
                                    </p>
                                </footer>
                            </>
                        )}
                    </>
                )}
            </StandardModalBody>
        </div>
    );
};
