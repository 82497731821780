import React from "react";
import { pluralize } from "../../../../../common/helpers/string";

interface Props {
    filters: {
        counterpartiesIds?: string[];
    };
}

export const CounterpartiesFilterLabel: React.FC<Props> = ({
    filters: { counterpartiesIds },
}) => (
    <>
        {counterpartiesIds?.length}{" "}
        {pluralize("Vendor", counterpartiesIds?.length ?? 0)}/
        {pluralize("Customer", counterpartiesIds?.length ?? 0)}
    </>
);
