import { useContext } from "react";
import { TransactionRuleCreationContext } from "../../transactionRules/TransactionRuleCreation/TransactionRuleCreation.context";
import { Button } from "../../general/Button/Button";
import { RuleSource } from "../../../common/types/transactionRule";
import { TablePageHeader } from "../../pageHeader/TablePageHeader";

export const RulesPageHeader: React.FC<{
    setSearch: (search: string) => void;
    search: string;
}> = ({ setSearch, search }) => {
    const { createRule } = useContext(TransactionRuleCreationContext);
    return (
        <TablePageHeader
            search={search}
            onSearchChange={(value) => setSearch(value ?? "")}
        >
            <Button
                variant="secondary"
                onClick={() =>
                    createRule({
                        source: RuleSource.SETTINGS,
                    })
                }
            >
                Add rule
            </Button>
        </TablePageHeader>
    );
};
