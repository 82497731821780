import React from "react";
import {
    FLAT_RATE_PLANS,
    PREMIUM_FEATURES,
} from "../../common/flatRateBilling";
import { useAllowFeature } from "../../hooks/useAllowFeature";
import { useBillingStatus } from "../../hooks/useBillingStatus";
import { useAccountingState } from "../../hooks/useAccountingState";
import { AccessBlockedPage } from "../billing/AccessBlockedPage/AccessBlockedPage";
import { NotSubscribedUpgradeActions } from "../billing/AccessBlockedPage/NotSubscribedUpgradeActions";
import { EndTrialAction } from "../billing/AccessBlockedPage/EndTrialAction";
import { SubmitOpeningBalancesAction } from "../billing/AccessBlockedPage/SubmitOpeningBalancesAction";
import { PlanManagementProvider } from "../billing/PlanManagement/PlanManagementProvider";
import { Loader } from "../general/Loader";
import { useUser } from "../../hooks/useUser";
import { UserType } from "../../common/types/user";
import { ReportAccess } from "./ReportAccess";

interface ReportSubscribedAccessProps {
    feature: PREMIUM_FEATURES.BALANCE_SHEET | PREMIUM_FEATURES.GENERAL_LEDGER;
    previewUrl: string;
    report: string;
    renderReportPage: () => React.ReactElement;
}

export const ReportSubscribedAccess: React.FC<ReportSubscribedAccessProps> = ({
    feature,
    report,
    previewUrl,
    renderReportPage,
}) => {
    const { isSubscribed, isTrialing, isFetched } = useBillingStatus();
    const allowFeature = useAllowFeature(feature);
    const { openingBalancesSubmitted } = useAccountingState();
    const user = useUser();

    if (!isFetched) {
        return <Loader />;
    }

    if (!isSubscribed || !allowFeature) {
        return (
            <PlanManagementProvider>
                <AccessBlockedPage
                    headline={
                        <>
                            Upgrade to <em>Plus</em> or <em>Enterprise</em> to
                            access your {report}
                        </>
                    }
                    subheading={<>Your {report} will be available soon!</>}
                    actions={
                        <NotSubscribedUpgradeActions
                            targetPlan={FLAT_RATE_PLANS.PLUS}
                        />
                    }
                    imageUrl={previewUrl}
                />
            </PlanManagementProvider>
        );
    } else if (user.type === UserType.REGULAR && isTrialing) {
        return (
            <AccessBlockedPage
                headline={
                    <>Your {report} will be ready after your trial ends</>
                }
                subheading={
                    <>
                        Upgrade and submit opening balances to get access within
                        48 hours.
                    </>
                }
                imageUrl={previewUrl}
                actions={
                    <>
                        <PlanManagementProvider>
                            <EndTrialAction variant="action">
                                Upgrade now
                            </EndTrialAction>
                        </PlanManagementProvider>

                        {!openingBalancesSubmitted && (
                            <SubmitOpeningBalancesAction />
                        )}
                    </>
                }
            />
        );
    } else {
        return (
            <ReportAccess
                report={report}
                previewUrl={previewUrl}
                renderReportPage={renderReportPage}
            />
        );
    }
};
