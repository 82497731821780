import classNames from "classnames";

import { memo, useMemo } from "react";
import { GridTableRow } from "../../general/Tables/GridTable/GridTable";
import { accountingNumberFormatter } from "../../../common/helpers/number";
import {
    AccountSubtype,
    AccountType,
} from "../../../common/constants/domains/accounting/accountsv2";
import { Tag } from "../../general/Tag/Tag";
import styles from "./styles.module.scss";
import {
    ExtendedFlattenedItemForAccount,
    ExtendedFlattenedItemForAccountMetaAccount,
} from "./utils";
import { ChartOfAccountsItemActions } from "./ChartOfAccountsItemActions";

interface Props {
    item: ExtendedFlattenedItemForAccount<ExtendedFlattenedItemForAccountMetaAccount>;
    isDragged?: boolean;
    entityId: number;
    isDisabledDuringDragging?: boolean;
    canReorder?: boolean;
    openAddAccountModal: ({
        accountType,
        subType,
        newAccountIdToEdit,
    }: {
        accountType: AccountType;
        subType: AccountSubtype;
        newAccountIdToEdit?: string;
    }) => void;
    onDisableAccount: (accountId: string) => void;
    newAccountCodeOnDrag?: number;
}

export const ChartOfAccountsItem: React.FC<Props> = memo(
    ({
        item,
        entityId,
        openAddAccountModal,
        isDisabledDuringDragging,
        onDisableAccount,
        isDragged,
        newAccountCodeOnDrag,
        canReorder,
    }) => {
        const { account, lastItemInSubtype } = item.meta;

        const cells = useMemo(
            () => [
                {
                    key: "name",
                    value: (
                        <div
                            className={classNames(
                                styles.columnContent,
                                styles.nameColumn,
                            )}
                        >
                            <span
                                className={classNames(
                                    isDragged && styles.newAccountCodeSpan,
                                )}
                            >
                                {newAccountCodeOnDrag ?? account.code}
                            </span>{" "}
                            - {account.name}{" "}
                            {account.isDisabled && (
                                <Tag
                                    className={styles.tagDisabled}
                                    variant="light"
                                >
                                    Disabled
                                </Tag>
                            )}
                        </div>
                    ),
                },
                {
                    key: "type",
                    value: (
                        <div
                            className={classNames(
                                styles.columnContent,
                                styles.typeColumn,
                            )}
                        >
                            {account.type}
                        </div>
                    ),
                },
                {
                    key: "subtype",
                    value: (
                        <div
                            className={classNames(
                                styles.columnContent,
                                styles.subtypeColumn,
                            )}
                        >
                            {account.subtype}
                        </div>
                    ),
                },
                {
                    key: "debit",
                    value: (
                        <div
                            className={classNames(
                                styles.columnContent,
                                styles.debitColumn,
                            )}
                        >
                            {accountingNumberFormatter(account.debit)}
                        </div>
                    ),
                },
                {
                    key: "credit",
                    value: (
                        <div
                            className={classNames(
                                styles.columnContent,
                                styles.creditColumn,
                            )}
                        >
                            {accountingNumberFormatter(account.credit)}
                        </div>
                    ),
                },
                {
                    key: "balance",
                    value: (
                        <div
                            className={classNames(
                                styles.columnContent,
                                styles.balanceColumn,
                            )}
                        >
                            {accountingNumberFormatter(account.balance)}
                        </div>
                    ),
                },
                {
                    key: "actions",
                    className: styles.actionsColumnContainer,
                    value: (
                        <ChartOfAccountsItemActions
                            account={account}
                            isDragged={isDragged ?? false}
                            entityId={entityId}
                            itemId={item.id as string}
                            openAddAccountModal={openAddAccountModal}
                            onDisableAccount={onDisableAccount}
                            canReorder={canReorder ?? false}
                        />
                    ),
                },
            ],
            [
                isDragged,
                newAccountCodeOnDrag,
                account,
                item.id,
                openAddAccountModal,
                onDisableAccount,
                entityId,
                canReorder,
            ],
        );

        const classNameMemoized = useMemo(
            () =>
                classNames(styles.row, {
                    [styles.lastItemInSubtype]: lastItemInSubtype,
                    [styles.disabledDuringDragging]:
                        account.isDisabled || isDisabledDuringDragging,
                    [styles.isDragged]: isDragged,
                }),
            [
                lastItemInSubtype,
                account.isDisabled,
                isDisabledDuringDragging,
                isDragged,
            ],
        );

        return (
            <GridTableRow
                key={item.id}
                cells={cells}
                className={classNameMemoized}
                columnBorders={false}
            />
        );
    },
);
