import { WorkspaceMember } from "../../types/workspace";
import { UserDto } from "../user/user.dto";
import { UserWorkspaceRole } from "../../userWorkspaceRoles";
import { WorkspaceMemberEntityAssignmentDto } from "./workspace-member-entity-assignment.dto";

export class WorkspaceMemberDto {
    static deserialize(dto: WorkspaceMemberDto): WorkspaceMember {
        return {
            user: UserDto.deserialize(dto.user),
            role: dto.role,
            entityAssignments: dto.entityAssignments.map(
                WorkspaceMemberEntityAssignmentDto.deserialize,
            ),
        };
    }

    user: UserDto;
    role: UserWorkspaceRole;
    entityAssignments: WorkspaceMemberEntityAssignmentDto[];

    constructor(member: WorkspaceMember) {
        this.user = new UserDto(member.user);
        this.role = member.role;
        this.entityAssignments = member.entityAssignments.map(
            (ea) => new WorkspaceMemberEntityAssignmentDto(ea),
        );
    }
}
