import { CashFlowReportDto } from "../common/dto/reports/cash-flow-tab-report-response.dto";
import { GetReportingTabReportDto } from "../common/dto/reports/get-reporting-tab-report.dto";
import { ProfitLossTopTransactionsDto } from "../common/dto/reports/reporting-tab-profit-loss-response.dto";
import { GetProfitLossTopTransactionsDto } from "../common/dto/reports/get-profit-loss-top-transactions.dto";
import { backendClient, unwrapResponse } from "./backendClient";

export async function getCashFlowTabReport(
    requestDto: GetReportingTabReportDto,
): Promise<CashFlowReportDto> {
    return unwrapResponse(
        await backendClient.get("/owner-reports/cash-flow", {
            params: requestDto,
            paramsSerializer: { indexes: true },
        }),
    );
}

export async function getProfitLossTabTopTransactions(
    requestDto: GetProfitLossTopTransactionsDto,
): Promise<ProfitLossTopTransactionsDto> {
    return unwrapResponse(
        await backendClient.get("/owner-reports/cash-flow/top-transactions", {
            params: requestDto,
            paramsSerializer: { indexes: true },
        }),
    );
}
