import React from "react";
import { pluralize } from "../../../../../common/helpers/string";
import { useAccounts } from "../../../hooks/useAccounts";
import { FiltersTypes } from "../../../common/types/filters/general";
import styles from "./AccountingPageFilters.module.scss";

type AccountingReportFilters = Pick<FiltersTypes, "accountCodes" | "entityId">;

export const AccountCodeFilterLabel: React.FC<AccountingReportFilters> = ({
    accountCodes,
    entityId,
}) => {
    const { accountsByCode } = useAccounts({ entityId });

    if (accountCodes?.length === 1) {
        return (
            <span className={styles.accountCodeFilterLabel}>
                {accountsByCode[accountCodes[0]]?.code} -{" "}
                {accountsByCode[accountCodes[0]]?.name}
            </span>
        );
    }

    return (
        <>
            {accountCodes?.length}{" "}
            {pluralize("Account", accountCodes?.length ?? 0)}
        </>
    );
};
