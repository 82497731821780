import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import classNames from "classnames";
import { bem } from "../../../bem";
import {
    CashFlowColumn,
    CashFlowReportRowPath,
    CashFlowTableData,
    CashFlowTableRow,
} from "../../../common/dto/reports/cash-flow-tab-report-response.dto";
import {
    CASH_FLOW_PERCENT_DIFF_SUFFIX,
    CASH_FLOW_VALUE_DIFF_SUFFIX,
} from "../../../common/dto/reports/reports-common.dto";
import { NonUndefined } from "../../../common/helpers/typescript";
import { ReportFilters } from "../../../common/types/filters/reports";
import { CashFlowReportCell } from "./CashFlowReportCell";
import { CashFlowReportHeaderCell } from "./CashFlowReportHeaderCell";
import styles from "./CashFlowTable.module.scss";
import { GetStickyRightOffsetFunctionType } from "./CashFlowReport.types";
export interface CashFlowReportTableProps {
    tableData: CashFlowTableData;
    filters: ReportFilters;
    tableProps?: React.HTMLAttributes<HTMLTableElement>;
    isStickingRight: boolean;
}

const block = bem("data-table");

export const CashFlowReportTable: React.FC<CashFlowReportTableProps> = ({
    tableData,
    filters,
    tableProps = {},
    isStickingRight,
}) => {
    const { rows, columns } = tableData;
    const defaultRowVisibility = useMemo<Record<string, boolean>>(
        () =>
            Object.fromEntries(
                rows.flatMap(function fn({
                    id,
                    children,
                }): Array<[string, boolean]> {
                    return [
                        [id, !hasSuffix(id)],
                        ...(children?.flatMap(fn) ?? []),
                    ];
                }),
            ),
        [rows],
    );
    const [rowVisibility, setRowVisibility] = useState(defaultRowVisibility);
    useEffect(
        () => setRowVisibility(defaultRowVisibility),
        [defaultRowVisibility],
    );
    const toggleRow = useCallback<
        NonUndefined<CashFlowReportRowProps["toggleRow"]>
    >(
        (id) =>
            setRowVisibility((map) => ({
                ...map,
                [id]: !map[id],
            })),
        [],
    );

    const [totalColumnWidth, setTotalColumnWidth] = useState(0);

    const totalHeaderCellRef = useRef<HTMLTableCellElement | null>(null);

    useEffect(() => {
        if (!totalHeaderCellRef.current) {
            return;
        }
        const resizeObserver = new ResizeObserver(() => {
            if (totalHeaderCellRef.current) {
                setTotalColumnWidth(totalHeaderCellRef.current.offsetWidth);
            }
        });
        resizeObserver.observe(totalHeaderCellRef.current);
        return () => resizeObserver.disconnect(); // clean up
    }, [totalHeaderCellRef]);

    const getStickyRightOffset = useCallback<GetStickyRightOffsetFunctionType>(
        (col) => (col.id === "percent" ? totalColumnWidth : undefined),
        [totalColumnWidth],
    );

    return (
        <table
            className={classNames(block("table", {}), {
                [styles.cashFlowTableStickingRight]: isStickingRight,
            })}
            {...tableProps}
        >
            <thead>
                <tr>
                    {columns.map((column) => (
                        <CashFlowReportHeaderCell
                            key={column.id}
                            label={
                                {
                                    title: "",
                                    percent: "%",
                                    total: "Total",
                                }[column.id] ?? column.id
                            }
                            isTitle={column.isTitle}
                            filters={filters}
                            stickyRightOffset={getStickyRightOffset(column)}
                            columnId={column.id}
                            headerCellRef={
                                column.id === "total"
                                    ? totalHeaderCellRef
                                    : undefined
                            }
                        />
                    ))}
                </tr>
            </thead>

            <tbody>
                {rows.map(
                    (row) =>
                        rowVisibility[row.id] && (
                            <CashFlowReportRow
                                key={row.id}
                                columns={tableData.columns}
                                row={row}
                                depth={0}
                                toggleRow={toggleRow}
                                rowVisibility={rowVisibility}
                                getStickyRightOffset={getStickyRightOffset}
                            />
                        ),
                )}
            </tbody>
        </table>
    );
};

interface CashFlowReportRowProps {
    columns: CashFlowColumn[];
    row: CashFlowTableRow;
    depth: number;
    path?: CashFlowReportRowPath[];
    toggleRow?: (id: string) => void;
    rowVisibility?: Record<string, boolean>;
    getStickyRightOffset: GetStickyRightOffsetFunctionType;
}
export const CashFlowReportRow: React.FC<CashFlowReportRowProps> = ({
    columns,
    row,
    depth,
    toggleRow,
    rowVisibility,
    getStickyRightOffset,
    path: parentPath = [],
}) => {
    const [isExpanded, setIsExpanded] = useState(row.isExpanded ?? false);
    const titleColId = columns.find((col) => col.isTitle)?.id;

    const title = (titleColId && row.data?.[titleColId]) ?? "";
    const path = [...parentPath, { id: row.id, label: title }];

    return (
        <>
            <tr
                key={row.id}
                className={block("row", {
                    [`depth-${depth}`]: true,
                    total: row.isTotal,
                    underlined:
                        row.isTotal || (depth === 0 && !hasSuffix(row.id)),
                    highlighted: row.isHighlighted,
                })}
            >
                {columns.map((col) => (
                    <CashFlowReportCell
                        key={col.id}
                        column={col}
                        row={row}
                        isTitle={col.id === "title"}
                        stickyRightOffset={getStickyRightOffset(col)}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        toggleRow={toggleRow}
                        rowVisibility={rowVisibility}
                        path={path}
                    />
                ))}
            </tr>
            {isExpanded &&
                row.children?.map(
                    (childRow) =>
                        rowVisibility?.[childRow.id] !== false && (
                            <CashFlowReportRow
                                key={childRow.id}
                                columns={columns}
                                row={childRow}
                                depth={depth + 1}
                                toggleRow={toggleRow}
                                rowVisibility={rowVisibility}
                                getStickyRightOffset={getStickyRightOffset}
                                path={path}
                            />
                        ),
                )}
        </>
    );
};

function hasSuffix(id: string) {
    return (
        id.endsWith(CASH_FLOW_PERCENT_DIFF_SUFFIX) ||
        id.endsWith(CASH_FLOW_VALUE_DIFF_SUFFIX)
    );
}
