import { QueryKey, useQuery } from "@tanstack/react-query";
import { WithRequiredProperties } from "../../common/types/base/generics";
import { TransactionFiltersDto } from "../../common/dto/transactions/getTransactions/transaction-filters.dto";
import { Entity } from "../../common/types/entity";
import { getTransactionsStatistics } from "../../lib/transactions";
import { TransactionsFilters } from "./filters/lib";

const TRANSACTIONS_STATISTICS_QUERY_KEY = "transactions-stats";
const TRANSACTIONS_CACHE_DURATION = 1000 * 30;

export interface UseTransactionsQueryParam {
    filters: WithRequiredProperties<TransactionsFilters, "entityIds">;
}

const queryKey = ({ filters }: UseTransactionsQueryParam): QueryKey => [
    TRANSACTIONS_STATISTICS_QUERY_KEY,
    filters,
];

export function useTransactionsStatisticsQuery({
    filters,
}: UseTransactionsQueryParam) {
    return useQuery({
        queryKey: queryKey({ filters }),
        queryFn: () =>
            getTransactionsStatistics({
                filters: convertFiltersToDto(filters),
            }),
        staleTime: TRANSACTIONS_CACHE_DURATION,
    });
}

export function convertFiltersToDto(
    filters: WithRequiredProperties<TransactionsFilters, "entityIds">,
): TransactionFiltersDto;
export function convertFiltersToDto<T extends TransactionsFilters>(
    filters: T,
    entities: Entity[],
): TransactionFiltersDto;
export function convertFiltersToDto<T extends TransactionsFilters>(
    filters: T,
    entities?: Entity[],
): TransactionFiltersDto {
    return {
        ...filters,
        entityIds: filters.entityIds
            ? filters.entityIds
            : entities?.map((entity) => entity.id) ?? [],
        requiredAction: filters.requiredAction || undefined, // nosonar - false is first converted to string 'false', and then backend interprets it as truthy
    };
}
