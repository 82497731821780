import React, { useCallback } from "react";
// eslint-disable-next-line no-restricted-imports
import { Modal as BootstrapModal } from "react-bootstrap";
import classNames from "classnames";
import { isReducedMotionEnabled } from "../../../constants";
import { Button } from "../Button/Button";
import { CloseIcon } from "../../../icons";
import { KeyboardShortcut } from "../KeyboardShortcut/KeyboardShortcut";
import styles from "./Modal.module.scss";

interface StandardModalProps
    extends React.ComponentProps<typeof BootstrapModal> {
    disableAnimation?: boolean;
    afterHideCallback?: () => void;
    hideCloseButton?: boolean;
}

interface PlainModalProps extends React.ComponentProps<typeof BootstrapModal> {
    hideCloseButton?: boolean;
}

const AFTER_HIDE_CALLBACK_DELAY = 10;

export const StandardModalHeader: React.FC<
    React.ComponentProps<typeof BootstrapModal.Header>
> = ({ closeButton, className, ...props }) => (
    <BootstrapModal.Header
        className={classNames(styles.modalHeader, className)}
        {...props}
        closeButton={false}
    >
        {props.children}
    </BootstrapModal.Header>
);

export const StandardModalBody: React.FC<
    React.ComponentProps<typeof BootstrapModal.Body>
> = ({ ...props }) => (
    <BootstrapModal.Body {...props}>{props.children}</BootstrapModal.Body>
);

export const StandardModalFooter: React.FC<
    React.ComponentProps<typeof BootstrapModal.Footer>
> = ({ ...props }) => (
    <BootstrapModal.Footer {...props}>{props.children}</BootstrapModal.Footer>
);

export const StandardModal: React.FC<StandardModalProps> = (props) => {
    const { afterHideCallback, onHide, hideCloseButton, className } = props;
    const onExited = useCallback(() => {
        if (afterHideCallback) {
            // NOTE: adding delay to better handle unfocusing events
            setTimeout(() => {
                afterHideCallback();
            }, AFTER_HIDE_CALLBACK_DELAY);
        }
    }, [afterHideCallback]);

    return (
        <BootstrapModal
            {...props}
            animation={!props.disableAnimation && !isReducedMotionEnabled}
            enforceFocus={false}
            data-testid="modal"
            onExited={onExited}
            className={classNames(styles.standard, className)}
        >
            {onHide && !hideCloseButton && (
                <Button
                    className={styles.closeButton}
                    icon
                    variant="tertiary"
                    tooltip={<KeyboardShortcut>Esc</KeyboardShortcut>}
                    tooltipPlacement="bottom"
                    onClick={onHide}
                >
                    <CloseIcon />
                </Button>
            )}

            {props.children}
        </BootstrapModal>
    );
};

export const PlainModal: React.FC<PlainModalProps> = ({
    onClose,
    closeButton,
    hideCloseButton,
    className,
    ...props
}) => (
    <BootstrapModal
        {...props}
        animation={!props.disableAnimation && !isReducedMotionEnabled}
        enforceFocus={false}
        data-testid="modal"
        className={classNames(styles.plain, className)}
    >
        {props.onHide && !hideCloseButton && (
            <Button
                className={styles.closeButtonPlain}
                icon
                size="sm"
                variant="tertiary"
                tooltip={<KeyboardShortcut>Esc</KeyboardShortcut>}
                tooltipPlacement="bottom"
                onClick={props.onHide}
            >
                <CloseIcon />
            </Button>
        )}
        <StandardModalBody>{props.children}</StandardModalBody>
    </BootstrapModal>
);
