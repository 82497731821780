import React from "react";
import { addMonths, format } from "date-fns";
import {
    FlatRatePlan,
    flatRatePlanNames,
} from "../../../../common/flatRateBilling";
import { Card } from "../../../general/Card/Card";
import { currencyFormatterNoFractions } from "../../../../common/helpers/currency";
import { fromIntToMonetary } from "../../../../common/helpers/monetary";
import { fullDateFullYearFormat } from "../../../../common/helpers/date";
import styles from "./UpgradeSubscriptionModal.module.scss";

interface Props {
    billingDate: Date;
    plan: FlatRatePlan;
    isTrial: boolean;
}

export const CurrentPlanSummary: React.FC<Props> = ({
    billingDate,
    plan,
    isTrial,
}) => {
    const { billingInterval, intervalAmountCents } = plan;

    const realSubscriptionEnd = addMonths(billingDate, billingInterval);

    let trialSection: React.ReactNode;

    if (isTrial) {
        trialSection = (
            <section className={styles.section}>
                <main>
                    <header className={styles.header}>
                        {flatRatePlanNames[plan.plan]} Trial
                    </header>
                    <small className={styles.info}>
                        {format(new Date(), fullDateFullYearFormat)} -{" "}
                        {format(billingDate, fullDateFullYearFormat)}
                    </small>
                </main>

                <aside className={styles.pricing}>
                    <span className={styles.value}>
                        {currencyFormatterNoFractions.format(0)}
                    </span>
                </aside>
            </section>
        );
    }

    return (
        <Card className="p-0">
            {trialSection}

            <section className={styles.section}>
                <main>
                    <header className={styles.header}>Subscription</header>
                    <small className={styles.info}>
                        {format(billingDate, fullDateFullYearFormat)} -{" "}
                        {format(realSubscriptionEnd, fullDateFullYearFormat)}
                    </small>
                </main>

                <aside className={styles.pricing}>
                    <span className={styles.value}>
                        {currencyFormatterNoFractions.format(
                            fromIntToMonetary(intervalAmountCents),
                        )}
                    </span>
                    <small className={styles.info}>
                        per {billingInterval === 3 ? "quarter" : "year"}
                    </small>
                </aside>
            </section>
        </Card>
    );
};
