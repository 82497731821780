import { useMemo, useState } from "react";
import { SortOrder } from "../../common/constants";

export enum TransactionSortValue {
    DATE_ASC = "DATE_ASC",
    DATE_DESC = "DATE_DESC",
    DESCRIPTION_ASC = "DESCRIPTION_ASC",
    DESCRIPTION_DESC = "DESCRIPTION_DESC",
    CATEGORY_ASC = "CATEGORY_ASC",
    CATEGORY_DESC = "CATEGORY_DESC",
    ACCOUNT_ASC = "ACCOUNT_ASC",
    ACCOUNT_DESC = "ACCOUNT_DESC",
    AMOUNT_ASC = "AMOUNT_ASC",
    AMOUNT_DESC = "AMOUNT_DESC",
    ACTIONS_ASC = "ACTIONS_ASC",
    ACTIONS_DESC = "ACTIONS_DESC",
    CLASS_ASC = "CLASS_ASC",
    CLASS_DESC = "CLASS_DESC",
    NONE = "NONE",
}

export const DEFAULT_SORT = TransactionSortValue.DATE_DESC;

export interface Sort {
    field: string;
    order: SortOrder;
}

export interface UseSort {
    currentSort: TransactionSortValue;
    handleSortChange: (value: TransactionSortValue) => void;
    sortExpression: Sort | undefined;
}

export const getSortExpression = (sort: TransactionSortValue) => {
    switch (sort) {
        case TransactionSortValue.DATE_DESC:
            return {
                field: "date",
                order: SortOrder.DESC,
            };
        case TransactionSortValue.DATE_ASC:
            return {
                field: "date",
                order: SortOrder.ASC,
            };
        case TransactionSortValue.DESCRIPTION_DESC:
            return {
                field: "displayDescription",
                order: SortOrder.DESC,
            };
        case TransactionSortValue.DESCRIPTION_ASC:
            return {
                field: "displayDescription",
                order: SortOrder.ASC,
            };
        case TransactionSortValue.CATEGORY_DESC:
            return {
                field: "category",
                order: SortOrder.DESC,
            };
        case TransactionSortValue.CATEGORY_ASC:
            return {
                field: "category",
                order: SortOrder.ASC,
            };
        case TransactionSortValue.ACCOUNT_DESC:
            return {
                field: "financialAccount",
                order: SortOrder.DESC,
            };
        case TransactionSortValue.ACCOUNT_ASC:
            return {
                field: "financialAccount",
                order: SortOrder.ASC,
            };
        case TransactionSortValue.AMOUNT_DESC:
            return {
                field: "amount",
                order: SortOrder.DESC,
            };
        case TransactionSortValue.AMOUNT_ASC:
            return {
                field: "amount",
                order: SortOrder.ASC,
            };
        case TransactionSortValue.ACTIONS_DESC:
            return {
                field: "requiredActionsCount",
                order: SortOrder.DESC,
            };
        case TransactionSortValue.ACTIONS_ASC:
            return {
                field: "requiredActionsCount",
                order: SortOrder.ASC,
            };
        case TransactionSortValue.CLASS_ASC:
            return {
                field: "class",
                order: SortOrder.ASC,
            };
        case TransactionSortValue.CLASS_DESC:
            return {
                field: "class",
                order: SortOrder.DESC,
            };
        case TransactionSortValue.NONE:
            return undefined;
    }
};

export function useSort(
    defaultSort: TransactionSortValue = DEFAULT_SORT,
): UseSort {
    const [currentSort, setCurrentSort] =
        useState<TransactionSortValue>(defaultSort);

    const sortExpression: Sort | undefined = useMemo(
        () => getSortExpression(currentSort),
        [currentSort],
    );

    return {
        currentSort,
        handleSortChange: setCurrentSort,
        sortExpression,
    };
}
