import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { FinancialAccount } from "../../../../common/types/financialAccount";
import { useProcessorConnectionStatus } from "../../useProcessorConnectionStatus";
import {
    financialConnectionsQueryKey,
    queryClient,
} from "../../../../queryClient";
import { CreateStripeConnection } from "../../../stripe/CreateStripeConnection";
import { ConnectPlaid } from "../../../plaid/ConnectPlaid/ConnectPlaid";
import { OptionGroupItem } from "../../../general/OptionGroup/OptionGroupItem";
import StripeLogo from "../../../../icons/logos/Stripe.svg?react";
import PaypalLogo from "../../../../icons/logos/Paypal.svg?react";
import VenmoLogo from "../../../../icons/logos/Venmo.svg?react";
import CashAppLogo from "../../../../icons/logos/CashApp.svg?react";
import { Footer } from "../../components/Footer";
import { Button } from "../../../general/Button/Button";
import { OptionGroupCustomItem } from "../../../general/OptionGroup/OptionGroupCustomItem";
import commonStyles from "../common.module.scss";
import animations from "../../layout/OnboardingLayout.module.scss";
import { PersonalDataAlert } from "../../components/PersonalDataAlert";
import { useKeyboardCommands } from "../../../../hooks/keyboard/useKeyboardCommands";
import { KeyboardReturnIcon } from "../../../../icons";
import { KeyboardShortcut } from "../../../general/KeyboardShortcut/KeyboardShortcut";

interface Props {
    initialValue: string;
    financialAccounts: FinancialAccount[];
    onSubmit(requested: string): void;
    back(): void;
}

export const PaymentProcessor: React.FC<Props> = ({
    onSubmit,
    back,
    financialAccounts,
    initialValue,
}) => {
    const [requested, setRequested] = useState<string>(initialValue);

    const {
        venmoConnected,
        paypalConnected,
        stripeConnected,
        cashAppConnected,
        hasAnyProcessorConnected,
    } = useProcessorConnectionStatus(financialAccounts);

    const handleConnected = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [financialConnectionsQueryKey],
        });
    }, []);

    const isPristine = !hasAnyProcessorConnected && !requested;
    const handleNext = useCallback(
        () => onSubmit(requested),
        [onSubmit, requested],
    );

    useKeyboardCommands({
        commands: [
            {
                key: "Enter",
                requiresCtrlOrMeta: false,
                callback: () => {
                    if (isPristine) {
                        return;
                    }

                    handleNext();
                },
            },
        ],
    });

    return (
        <div
            className={classNames(animations.fadeIn1, commonStyles.content780)}
        >
            <h1 className={classNames(commonStyles.title, animations.fadeIn2)}>
                Select payment processor
            </h1>

            <PersonalDataAlert />

            <CreateStripeConnection onCreated={handleConnected}>
                {({ initializeConnection }) => (
                    <OptionGroupItem
                        icon={<StripeLogo />}
                        title="Stripe"
                        selected={stripeConnected}
                        onToggle={initializeConnection}
                    />
                )}
            </CreateStripeConnection>

            <ConnectPlaid onConnected={handleConnected}>
                {({ initializeConnection }) => (
                    <>
                        <OptionGroupItem
                            icon={<PaypalLogo />}
                            title="PayPal"
                            selected={paypalConnected}
                            onToggle={initializeConnection}
                        />
                        <OptionGroupItem
                            icon={<VenmoLogo />}
                            title="Venmo"
                            selected={venmoConnected}
                            onToggle={initializeConnection}
                        />
                        <OptionGroupItem
                            icon={<CashAppLogo />}
                            title="Cash App"
                            selected={cashAppConnected}
                            onToggle={initializeConnection}
                        />
                    </>
                )}
            </ConnectPlaid>

            <OptionGroupCustomItem
                value={requested}
                empty="Other"
                onChange={setRequested}
            />

            <Footer onBack={back}>
                <Button variant="secondary" onClick={handleNext}>
                    {isPristine ? (
                        "No payment processor"
                    ) : (
                        <>
                            Next
                            <KeyboardShortcut square className="ml-2">
                                <KeyboardReturnIcon />
                            </KeyboardShortcut>
                        </>
                    )}
                </Button>
            </Footer>
        </div>
    );
};
