import React from "react";

import { Button } from "../../Button/Button";
import { Separator } from "../../Separator/Separator";
import styles from "./FilterActionsFooter.module.scss";

interface FilterActionsFooterProps {
    selectedCount: number;
    deselectFunction: () => void;
    reverseFunction?: () => void;
    applyFunction?: () => void;
}

export const FilterActionsFooter: React.FC<FilterActionsFooterProps> = ({
    selectedCount,
    deselectFunction,
    reverseFunction,
    applyFunction,
}) => {
    const buttonsToDisplay: React.ReactNode[] = [];

    if (applyFunction) {
        buttonsToDisplay.push(
            <Button onClick={applyFunction} variant="tertiary" size="sm">
                Apply
            </Button>,
        );
    }

    if (reverseFunction) {
        buttonsToDisplay.push(
            <Button onClick={reverseFunction} variant="tertiary" size="sm">
                Reverse
            </Button>,
        );
    }

    if (selectedCount > 0) {
        buttonsToDisplay.push(
            <Button onClick={deselectFunction} variant="tertiary" size="sm">
                Deselect ({selectedCount})
            </Button>,
        );
    }

    if (buttonsToDisplay.length === 0) {
        return null;
    }

    return (
        <div className={styles.filterFooter}>
            {buttonsToDisplay.map((button, index) => (
                <>
                    {button}
                    {index < buttonsToDisplay.length - 1 && (
                        <Separator orientation="vertical" />
                    )}
                </>
            ))}
        </div>
    );
};
