import { useCallback } from "react";
import { User } from "../common/types/user";
import { queryClient } from "../queryClient";
import { entitiesAccountsQueryKey } from "../queries/entitiesAccounts";

import { ACTIVE_USER_ID_KEY } from "../constants";
import { userStatusQueryKey, useUserStatus } from "./useUserStatus";

export function useUpdateUser() {
    const { data: status } = useUserStatus();

    return useCallback(
        (updatedUser: User) => {
            if (!status) {
                return;
            }

            queryClient.setQueryData([userStatusQueryKey], {
                ...status,
                user: updatedUser,
            });

            // copied over from redux
            localStorage.setItem(ACTIVE_USER_ID_KEY, updatedUser.id.toString());

            if (status.user.isSubscribed !== updatedUser.isSubscribed) {
                queryClient.invalidateQueries({
                    queryKey: [entitiesAccountsQueryKey],
                });
            }
        },
        [status],
    );
}
