import classNames from "classnames";
import { isEqual, omitBy } from "lodash";
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useQueryParams } from "use-query-params";
import { NonUndefined } from "../../common/helpers/typescript";
import { TransactionListItemDto } from "../../common/types/transaction";
import { useBillingStatus } from "../../hooks/useBillingStatus";
import { useKeyboardCommands } from "../../hooks/keyboard/useKeyboardCommands";
import { StandardModal } from "../general/Modal/Modal";
import { useSettings } from "../settings/SettingsProvider";
import { useClasses } from "../../api/class.api";
import { useLargeDesktopView } from "../../hooks/useMobileView";
import { TablePageHeader } from "../pageHeader/TablePageHeader";
import { FilterTabs } from "../pageHeader/filters/lib";
import { useTypedFlags } from "../../hooks/useTypedFlags";
import { EntityFilter } from "../general/EntityFilter/EntityFilter";
import {
    DEFAULT_ACTIVITY_FEED_FILTERS,
    FilterProps,
    FiltersChangeFn,
} from "./filters/lib";
import {
    PaginatedTransactionsTable,
    PaginatedTransactionsTableProps,
} from "./PaginatedTransactionsTable";
import { PaginatedTransactionsTableContext } from "./PaginatedTransactionsTableContextProvider";
import { TransactionDetailsContainer } from "./TransactionDetails/TransactionDetailsContainer";
import { invalidateTransactionDetailsQueries } from "./TransactionDetails/useTransactionDetailsQuery";
import { BulkUpdateMode } from "./TransactionsBulkActions/lib";
import {
    TransactionsBulkActionsContextProvider,
    TransactionsBulkActionsContextProviderProps,
} from "./TransactionsBulkActions/TransactionsBulkActionsContextProvider";
import { useBulkActions } from "./TransactionsBulkActions/useBulkActions";
import "./TransactionsPage.scss";
import { TransactionsPageEmptyState } from "./TransactionsPageEmptyState";
import { TransactionsTableProps } from "./TransactionsTable";
import { TransferMatchingContainer } from "./TransferMatching/TransferMatchingContainer";
import { useActivityFeedCta } from "./useActivityFeedCta";
import { invalidateTransactionsQueries } from "./useTransactionsQuery";
import { TransactionSplitModal } from "./TransactionSplitV2/TransactionSplitModal";
import { TransactionsExport } from "./TransactionsExport";
import { TransactionActionsToggleFilter } from "./filters/TransactionActionsToggleFilter";

export const TransactionsPage: React.FC = (props) => {
    const { invalidate: invalidateCreditsUsed } = useBillingStatus();
    const { updateMany } = useContext(PaginatedTransactionsTableContext);
    const {
        setPage,
        transactionCount,
        filters: queryFiltersToPass,
    } = useContext(PaginatedTransactionsTableContext);

    const onBulkUpdated: TransactionsBulkActionsContextProviderProps["onUpdated"] =
        useCallback(
            (updatedTransactions, { mode }) => {
                if (mode === BulkUpdateMode.SELECTED) {
                    updateMany(updatedTransactions);
                } else {
                    void invalidateTransactionsQueries();
                    setPage(1);
                }

                void invalidateCreditsUsed();
                void invalidateTransactionDetailsQueries();
            },
            [invalidateCreditsUsed, setPage, updateMany],
        );

    return (
        <TransactionsBulkActionsContextProvider
            currentFilters={queryFiltersToPass}
            onUpdated={onBulkUpdated}
            totalTransactions={transactionCount}
        >
            <TransactionsPageContent {...props} />
        </TransactionsBulkActionsContextProvider>
    );
};

export const TransactionsPageContent: React.FC = () => {
    const {
        shownTransactionId,
        setShownTransactionId,
        setPage,
        currentPage,
        totalPages,
        currentSort,
        filters: currentFilters,
        setCurrentFilters,
        transactions,
    } = useContext(PaginatedTransactionsTableContext);
    const [, setSearchParams] = useQueryParams();

    // When sorting or page changes, set shown transaction to undefined
    // The case of filters changes is handled in handleFiltersChange
    useEffect(
        () => setShownTransactionId(undefined),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentPage, currentSort],
    );

    const bulkActions = useBulkActions();

    const { directory: canUseDirectory } = useTypedFlags();
    const { isActivated: classesEnabled } = useClasses();

    const handleTransactionSelected = useCallback<
        NonUndefined<PaginatedTransactionsTableProps["onTransactionSelected"]>
    >(
        (transaction) => setShownTransactionId(transaction.id),
        [setShownTransactionId],
    );

    const handleFiltersChange = useCallback<FiltersChangeFn>(
        (value) => {
            const newFilters = omitBy(
                { ...currentFilters, ...value },
                (v) => v === null || v === undefined,
            );

            if (isEqual(newFilters, currentFilters)) {
                return;
            }

            bulkActions.clearSelection();
            setPage(1);
            setShownTransactionId(undefined);
            setCurrentFilters(newFilters);
            setSearchParams({
                filters: JSON.stringify(newFilters),
            });
        },
        [
            currentFilters,
            bulkActions,
            setPage,
            setShownTransactionId,
            setCurrentFilters,
            setSearchParams,
        ],
    );

    const renderEmptyState = useCallback<
        NonUndefined<TransactionsTableProps["renderEmptyState"]>
    >(() => <TransactionsPageEmptyState />, []);

    const [prependContent, appendContent] = useActivityFeedCta({
        filters: currentFilters,
        currentSort,
        currentPage,
        totalPages,
        transactionSelected: !!shownTransactionId,
        showSplitButton: true,
    });

    const { isOpened: settingsOpened } = useSettings();

    const selectAll = useCallback(
        (e: KeyboardEvent) => {
            if (
                e.target instanceof HTMLInputElement ||
                e.target instanceof HTMLTextAreaElement
            ) {
                return;
            }

            if (transactions) {
                bulkActions.select(transactions);
            }

            return {
                preventDefault: true,
                stopPropagation: true,
            };
        },
        [bulkActions, transactions],
    );
    const commands = useMemo(
        () => ({
            commands: settingsOpened
                ? []
                : [
                      {
                          key: "a",
                          callback: selectAll,
                          requiresCtrlOrMeta: true,
                          preventDefault: false,
                          stopPropagation: false,
                      },
                  ],
        }),
        [selectAll, settingsOpened],
    );
    useKeyboardCommands(commands);
    const [
        openedTransactionForTransferMatching,
        setOpenedTransactionForTransferMatching,
    ] = useState<TransactionListItemDto | null>(null);
    const onTransferMatchClick = useCallback(
        (transaction: TransactionListItemDto) => {
            setOpenedTransactionForTransferMatching(transaction);
        },
        [],
    );

    const filterProps: FilterProps = {
        filters: currentFilters,
        onChange: handleFiltersChange,
    };
    const [openedTransactionForSplit, setOpenedTransactionForSplit] =
        useState<TransactionListItemDto>();

    const { isActivated } = useClasses();

    const isLargeDesktop = useLargeDesktopView();

    let footerWidth = 9;
    if (shownTransactionId && !isLargeDesktop) {
        footerWidth -= 1;
    } else if (isActivated) {
        footerWidth += 1;
    }

    return (
        <section className="transactions">
            <TablePageHeader
                search={filterProps.filters.search}
                onSearchChange={(value) => {
                    filterProps.onChange({ search: value ?? "" });
                }}
                filters={filterProps.filters}
                onFiltersChange={filterProps.onChange}
                defaultFilters={DEFAULT_ACTIVITY_FEED_FILTERS}
                availableFilters={[
                    FilterTabs.DATE,
                    FilterTabs.CATEGORY,
                    FilterTabs.ACCOUNT,
                    ...(classesEnabled ? [FilterTabs.CLASS] : []),
                    FilterTabs.AMOUNT,
                    FilterTabs.RULES,
                    ...(canUseDirectory ? [FilterTabs.COUNTERPARTY] : []),
                    FilterTabs.NOTE,
                    FilterTabs.RECEIPTS,
                    FilterTabs.SPLIT,
                    FilterTabs.MATCHED,
                ]}
            >
                <EntityFilter
                    filters={filterProps.filters}
                    onChange={filterProps.onChange}
                />

                <TransactionActionsToggleFilter
                    filters={filterProps.filters}
                    onChange={filterProps.onChange}
                />

                <TransactionsExport currentFilters={filterProps.filters} />
            </TablePageHeader>

            <section className="transactions__body">
                <main>
                    <PaginatedTransactionsTable
                        onTransactionSelected={handleTransactionSelected}
                        prependContent={prependContent}
                        appendContent={appendContent}
                        onSplitCategoryClick={setOpenedTransactionForSplit}
                        showClasses={isActivated}
                        showSplitButton
                        footerWidth={footerWidth}
                        renderEmptyState={renderEmptyState}
                        onTransferMatchClick={onTransferMatchClick}
                    />
                </main>

                {shownTransactionId && (
                    <TransactionDetailsContainer
                        id={shownTransactionId}
                        onSplit={setOpenedTransactionForSplit}
                        onClose={() => setShownTransactionId()}
                        onMatch={setOpenedTransactionForTransferMatching}
                    />
                )}
            </section>
            <StandardModal
                size="xl"
                className={classNames(
                    "fullscreen-modal",
                    "fullscreen-modal--expanded",
                )}
                show={Boolean(openedTransactionForTransferMatching)}
                onHide={() => {
                    setOpenedTransactionForTransferMatching(null);
                }}
            >
                {openedTransactionForTransferMatching && (
                    <TransferMatchingContainer
                        key={openedTransactionForTransferMatching.id}
                        transactionId={openedTransactionForTransferMatching.id}
                        transaction={openedTransactionForTransferMatching}
                    />
                )}
            </StandardModal>

            <TransactionSplitModal
                transaction={openedTransactionForSplit}
                setTransaction={setOpenedTransactionForSplit}
            />
        </section>
    );
};
