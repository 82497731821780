import { Counterparty } from "../../types/counterparty";

export class CounterpartyDto {
    static deserialize(dto: CounterpartyDto): Counterparty {
        return dto;
    }

    id: string;
    name: string;
    workspaceId: string | null;
    updatedByUserId: number | null;
    email: string | null;
    phoneNumber: string | null;
    website: string | null;
    street: string | null;
    street2: string | null;
    city: string | null;
    postalCode: string | null;
    state: string | null;
    country: string | null;

    constructor(counterparty: Counterparty) {
        this.id = counterparty.id;
        this.name = counterparty.name;
        this.workspaceId = counterparty.workspaceId;
        this.updatedByUserId = counterparty.updatedByUserId;
        this.email = counterparty.email;
        this.phoneNumber = counterparty.phoneNumber;
        this.website = counterparty.website;
        this.street = counterparty.street;
        this.street2 = counterparty.street2;
        this.city = counterparty.city;
        this.postalCode = counterparty.postalCode;
        this.state = counterparty.state;
        this.country = counterparty.country;
    }
}
