import React from "react";
import classNames from "classnames";
import gridTableStyles from "../../../../styles/grid-table.module.scss";
import { MergeFinancialAccountsResponse } from "../../../../common/dto/financialAccount/mergeFinancialAccounts/merge-financial-accounts-response.dto";
import { FormCheckbox } from "../../../forms/FormCheckbox/FormCheckbox";
import { Transaction } from "../../../../common/types/transaction";
import { BothModifiedConflict } from "../../../../common/dto/financialAccount/mergeFinancialAccounts/both-modified-conflict.dto";
import { Collapsible } from "../../../general/Collapsible/Collapsible";
import styles from "./BothModifiedConflicts.module.scss";
import { TransactionToMerge } from "./TransactionToMerge";

interface Props {
    conflicts: MergeFinancialAccountsResponse["bothModifiedConflicts"];
    resolutions: number[];
    onResolve: (
        conflict: BothModifiedConflict,
        transaction: Transaction,
    ) => void;
}

export const BothModifiedConflicts: React.FC<Props> = ({
    conflicts,
    resolutions,
    onResolve,
}) => {
    const header = (
        <div className={styles.header}>
            <main>
                <p>
                    Following transactions were modified in both accounts.
                    Please select which version to keep in every case.
                </p>
            </main>
        </div>
    );
    return (
        <Collapsible header={header}>
            <div className={styles.bothModifiedConflicts}>
                {conflicts.map((conflict) => (
                    <div
                        className={classNames(
                            gridTableStyles.gridTable,
                            styles.table,
                        )}
                        key={conflict.transactionFromDestination.id}
                    >
                        <div className={gridTableStyles.headerRow}>
                            <div>Select</div>
                            <div className={styles.headerCell}>
                                In destination
                            </div>
                        </div>
                        <TransactionToMerge
                            transaction={conflict.transactionFromDestination}
                            prepend={
                                <div>
                                    <FormCheckbox
                                        type="radio"
                                        isChecked={resolutions.includes(
                                            conflict.transactionFromDestination
                                                .id,
                                        )}
                                        value={
                                            conflict.transactionFromDestination
                                                .id
                                        }
                                        handleChange={() =>
                                            onResolve(
                                                conflict,
                                                conflict.transactionFromDestination,
                                            )
                                        }
                                    />
                                </div>
                            }
                            onClick={() =>
                                onResolve(
                                    conflict,
                                    conflict.transactionFromDestination,
                                )
                            }
                            className={styles.conflictRow}
                        />
                        <div className={gridTableStyles.headerRow}>
                            <div className={styles.headerCell}>To merge</div>
                        </div>
                        <TransactionToMerge
                            transaction={conflict.transactionFromMerged}
                            prepend={
                                <div>
                                    <FormCheckbox
                                        type="radio"
                                        isChecked={resolutions.includes(
                                            conflict.transactionFromMerged.id,
                                        )}
                                        value={
                                            conflict.transactionFromMerged.id
                                        }
                                        handleChange={() =>
                                            onResolve(
                                                conflict,
                                                conflict.transactionFromMerged,
                                            )
                                        }
                                    />
                                </div>
                            }
                            onClick={() =>
                                onResolve(
                                    conflict,
                                    conflict.transactionFromMerged,
                                )
                            }
                            className={styles.conflictRow}
                        />
                    </div>
                ))}
            </div>
        </Collapsible>
    );
};
