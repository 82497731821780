import { useMemo } from "react";
import { Dictionary } from "lodash";
import { TAX_SAVING_RULES, TaxRule } from "../../../common/taxSavingRules";
import { Category } from "../../../common/types/category";
import { TransactionsFilters } from "../filters/lib";
import { useCategoriesById } from "../../../hooks/useCategoriesById";
import { useDefaultYear } from "../../../hooks/useDefaultYear";
import { SelectedTransaction } from "./transactionsBulkActionsContext";

export function useTaxSavingRulesForBulkActions(
    selection: Record<number, SelectedTransaction>,
    filters: TransactionsFilters | null,
): TaxRule | null {
    const categoriesById = useCategoriesById();
    const defaultYear = useDefaultYear();

    return useMemo(() => {
        if (filters) {
            return getTaxSavingRuleForFilters(
                filters,
                defaultYear,
                categoriesById,
            );
        } else {
            return getTaxSavingRuleForSelection(
                selection,
                categoriesById,
                defaultYear,
            );
        }
    }, [filters, selection, categoriesById, defaultYear]);
}

export function getTaxSavingRuleForSelection(
    selection: Record<number, SelectedTransaction>,
    categoriesById: Record<string, Category>,
    defaultYear: number,
): TaxRule | null {
    const selected = Object.values(selection);

    if (selected.length === 0) {
        return null;
    }

    if (selected.some((t) => t.isIgnored)) {
        return null;
    }

    const allBusiness = selected.every((t) => t.isBusiness);
    const sameCategory = selected.every(
        (t) => t.categoryId === selected[0].categoryId,
    );

    if (allBusiness && sameCategory) {
        const taxonomy = categoriesById[selected[0].categoryId].taxonomy;

        return taxonomy ? TAX_SAVING_RULES[defaultYear][taxonomy] : null;
    } else {
        return null;
    }
}

export function getTaxSavingRuleForFilters(
    filters: TransactionsFilters,
    defaultYear: number,
    categoriesById: Dictionary<Category>,
): TaxRule | null {
    if (filters.category?.length !== 1) {
        return null;
    }

    const taxonomy = categoriesById[filters.category[0]]?.taxonomy;
    if (!taxonomy) {
        return null;
    }

    return TAX_SAVING_RULES[defaultYear][taxonomy];
}
