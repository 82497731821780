import { useEffect } from "react";
import { useUser } from "../../hooks/useUser";
import { trackEvent } from "../../lib/analytics";
import { useUpdateUserMutation } from "../../mutations/user";

export function useEmailVerifiedTracking() {
    const updateUserMutation = useUpdateUserMutation();
    const { email, avatar, eventsTriggered } = useUser();

    useEffect(() => {
        if (!email) {
            return;
        }

        if (eventsTriggered?.emailVerified) {
            return;
        }

        updateUserMutation.mutate({
            eventsTriggered: {
                ...eventsTriggered,
                emailVerified: true,
            },
        });

        void trackEvent("signup_email_verified", {
            method: avatar ? "google" : "app", // users who singed up with google will have an avatar
            email,
        });
    }, [avatar, email, eventsTriggered, updateUserMutation]);
}
