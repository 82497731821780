import React from "react";
import { ShareWithUserIcon } from "../../../icons";
import { ActionActivity } from "../ActionActivity";
import { CampaignActivityProps } from "../ActionActivityItem";

export const InviteAccountantActivity: React.FC<CampaignActivityProps> = ({
    animated,
    description,
}) => (
    <ActionActivity icon={<ShareWithUserIcon />} animated={animated}>
        Invited {description ?? "accountant"}
    </ActionActivity>
);
