import React from "react";
import { format } from "date-fns";
import { TransactionAmount } from "../../TransactionAmount";
import { Transaction } from "../../../../common/types/transaction";
import { EntityIcon } from "../../../entity/EntityIcon";
import { TransactionInstitution } from "../TransactionInstitution";
import { useBreakableText } from "../../../../hooks/useBreakableText";
import { getTransactionAccountNameWithLast4 } from "../../../../common/helpers/transactions";
import { getLeadingAccountConnection } from "../../../../common/helpers/financialAccount";
import styles from "./SimplifedTransactionDetails.module.scss";

interface Props {
    transaction: Transaction;
}

export const SimplifiedTransactionDetails: React.FC<Props> = ({
    transaction,
}) => {
    const breakableDescription = useBreakableText(
        transaction.displayDescription ?? transaction.bankDescription,
    );
    const connection = getLeadingAccountConnection(
        transaction.financialAccount,
    );

    return (
        <article className={styles.details}>
            <header className={styles.header}>
                <div className={styles.description}>{breakableDescription}</div>

                <TransactionAmount transaction={transaction} color addSign />
            </header>

            <main className={styles.info}>
                <section>
                    <header className="label">Date</header>
                    <div>{format(transaction.date, "MMMM d, ''yy")}</div>
                </section>

                <section>
                    <header className="label">Entity</header>
                    <div className={styles.valueWithIcon}>
                        <EntityIcon entity={transaction.entity} size="xs" />

                        {transaction.entity.name}
                    </div>
                </section>

                {connection ? (
                    <section>
                        <header className="label">Institution</header>
                        <div className={styles.valueWithIcon}>
                            <TransactionInstitution transaction={transaction} />
                        </div>
                    </section>
                ) : null}

                <section>
                    <header className="label">Bank description</header>
                    <div>{transaction.bankDescription}</div>
                </section>

                <section>
                    <header className="label">Account</header>
                    <div>
                        {getTransactionAccountNameWithLast4(transaction, 2)}
                    </div>
                </section>
            </main>
        </article>
    );
};
