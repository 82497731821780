import React, { useMemo } from "react";
import { FinancialDocumentUploadDropzone } from "../documents/FinancialDocumentUpload/FinancialDocumentUploadDropzone";
import { DocumentThinIcon } from "../../icons";
import { useFinancialDocumentUpload } from "../documents/FinancialDocumentUpload/useFinancialDocumentUpload";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";
import { FinancialDocumentsUpload } from "../documents/FinancialDocumentUpload/FinancialDocumentsUpload";
import { ChildrenProps } from "../../types";
import styles from "./ManualImportDropzone.module.scss";

export const UniversalImportDropzone: React.FC<ChildrenProps> = ({
    children,
}) => {
    const { uploadingDocuments } = useFinancialDocumentUpload();

    const isUploading = useMemo(
        () => uploadingDocuments.some((fd) => fd.isUploading),
        [uploadingDocuments],
    );

    return (
        <FinancialDocumentUploadDropzone>
            {uploadingDocuments.length ? (
                children
            ) : (
                <div className={styles.dropzone}>
                    <span className={styles.icon}>
                        <DocumentThinIcon />
                    </span>

                    <p className={styles.cta}>
                        Drop in CSVs, PDFs, Excel, or Images
                    </p>

                    <FinancialDocumentsUpload
                        render={(open) => (
                            <ButtonWithLoader
                                loading={isUploading}
                                variant="default"
                                onClick={open}
                                size="sm"
                            >
                                Upload
                            </ButtonWithLoader>
                        )}
                    />
                </div>
            )}
        </FinancialDocumentUploadDropzone>
    );
};
