import React from "react";
import classNames from "classnames";
import { ChildrenProps } from "../../../types";
import styles from "./CollapsibleInfoBanner.module.scss";

interface Props extends ChildrenProps {
    className?: string;
}

export const CollapsibleInfoBanner: React.FC<Props> = ({
    className,
    children,
}) => (
    <section className={classNames(styles.banner, className)}>
        {children}
    </section>
);
