import React, { useMemo } from "react";
import { CustomSelect } from "../../forms/CustomSelect/CustomSelect";
import { getAnswerText, OTHER_ANSWER } from "../../../common/taxSavingRules";
import { TaxQuestionAnswerComponentProps } from "./types";

export const TaxQuestionAnswerSelect: React.FC<
    TaxQuestionAnswerComponentProps
> = ({ question, answer, onAnswer, size, labelStyle = "inside" }) => {
    const options = useMemo(
        () => [
            ...question.options.map((option) => ({
                value: getAnswerText(option),
            })),
            { value: OTHER_ANSWER },
        ],
        [question.options],
    );

    return (
        <CustomSelect
            onSelected={(newAnswer) => onAnswer(question.key, newAnswer)}
            dropdownKey={`question_${question.key}`}
            options={options}
            label={labelStyle !== "placeholder" ? question.question : undefined}
            placeholder={
                labelStyle === "placeholder" ? question.question : undefined
            }
            size={size}
            value={answer}
            insideLabel={labelStyle === "inside"}
            searchable={options.length > 10}
        />
    );
};
