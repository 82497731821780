import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    getAnswerText,
    getApplicableQuestions,
    getRulesForDate,
    TaxQuestion,
    TaxQuestionKey,
} from "../../../common/taxSavingRules";
import { TaxQuestionAnswerSelect } from "../../transactions/TaxQuestions/TaxQuestionAnswerSelect";
import { ActionCardDetail } from "../ActionCardDetail";
import { TaxQuestionAnswerDto } from "../../../common/dto/transactions/tax-question-answer.dto";
import { useCategoriesById } from "../../../hooks/useCategoriesById";
import { TransactionDetailProps } from "./TransactionDetails";

const convertAnswers = (
    answers: TaxQuestionAnswerDto[],
): Record<string, string> =>
    answers.reduce(
        (acc, answer) => {
            acc[answer.key] = answer.answer;
            return acc;
        },
        {} as Record<string, string>,
    );

const getDefaultOption = (question: TaxQuestion) => {
    if (question.key === TaxQuestionKey.TRAVEL_DESTINATION) {
        return "";
    }

    const defaultOption = question.options.find((option) => {
        if (typeof option !== "string") {
            return option.default;
        }
        return false;
    });

    return defaultOption ?? question.options[0];
};

export const TaxQuestionDetails: React.FC<TransactionDetailProps> = ({
    action,
    onUpdate,
}) => {
    const transaction = action.transaction!;
    const transactionUpdate = action.meta.transactionUpdate;
    const categoriesById = useCategoriesById();
    const category =
        (transactionUpdate?.categoryId
            ? categoriesById[transactionUpdate?.categoryId]
            : undefined) ?? transaction.category;

    const [answers, setAnswers] = useState<Record<string, string>>(
        convertAnswers(transaction.taxQuestionAnswers ?? []),
    );

    const rules = useMemo(
        () => getRulesForDate(transaction.date),
        [transaction],
    );

    const taxQuestions = useMemo(
        () => (category && rules?.[category.taxonomy]?.questions) ?? [],
        [rules, category],
    );

    const questions = useMemo(() => {
        const defaults = taxQuestions
            .map((question) => ({
                [question.key]: getAnswerText(getDefaultOption(question)),
            }))
            .reduce((acc, answer) => ({ ...acc, ...answer }), {});
        return getApplicableQuestions(taxQuestions, {
            ...defaults,
            ...answers,
        });
    }, [taxQuestions, answers]);

    const updateAnswers = useCallback((key: TaxQuestionKey, answer: string) => {
        setAnswers((prev) => ({
            ...prev,
            [key]: answer,
        }));
    }, []);

    const getAnswer = useCallback(
        (question: TaxQuestion) =>
            answers[question.key] ?? getAnswerText(getDefaultOption(question)),
        [answers],
    );

    const finalAnswers = useMemo(
        () =>
            questions
                .map((question) => ({
                    key: question.key,
                    answer: getAnswer(question),
                }))
                .filter(
                    (answer) =>
                        answer.key === TaxQuestionKey.TRAVEL_DESTINATION ||
                        answer.answer !== "",
                ),
        [questions, getAnswer],
    );

    useEffect(() => {
        onUpdate({
            taxQuestionAnswers: finalAnswers.length ? finalAnswers : undefined,
        });
    }, [finalAnswers, onUpdate]);

    return (
        <>
            {questions.map((question) => (
                <ActionCardDetail
                    key={`${action.id}-${question.key}`}
                    label={question.short}
                    required={
                        question.key === TaxQuestionKey.TRAVEL_DESTINATION &&
                        !answers[question.key]
                    }
                >
                    <TaxQuestionAnswerSelect
                        question={question}
                        answer={getAnswer(question)}
                        onAnswer={updateAnswers}
                        labelStyle="placeholder"
                        size="xs"
                    />
                </ActionCardDetail>
            ))}
        </>
    );
};
