import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import classNames from "classnames";

import { TriangleIcon } from "../../../../icons";
import { CategoryIcon } from "../../../transactions/CategoryIcon";
import { useCategoriesTree } from "../../../../hooks/useCategoriesTree";
import { CategoryFlattenedItem, getErrorForAddCategory } from "../utils";
import styles from "../Categories.module.scss";
import { useUpdateCustomCategoryMutation } from "../../../../api/category.api";

export const CategoryIdCell: React.FC<{
    isCollapsed?: boolean;
    item: CategoryFlattenedItem;
}> = ({ item, isCollapsed }) => {
    const kickCategories = useCategoriesTree();

    const [inputValue, setInputValue] = useState(item.meta.label);
    const updateCategory = useUpdateCustomCategoryMutation(item.meta.category!);

    const inputRef = useRef<HTMLInputElement>(null);
    const spanRef = useRef<HTMLSpanElement>(null);
    const [error, setError] = useState<string | null>(null);
    const [isFocused, setIsFocused] = useState(false);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setInputValue(e.target.value);

        const categoryError = getErrorForAddCategory(
            kickCategories,
            item.parentId as string | null,
            e.target.value,
        );
        setError(categoryError);
    };

    const [width, setWidth] = useState(0);

    useEffect(() => {
        if (spanRef.current) {
            setWidth(spanRef.current.offsetWidth);
        }
    }, [inputValue]);

    const onBlur = useCallback(async () => {
        setIsFocused(false);
        if (!error) {
            await updateCategory.mutateAsync({ label: inputValue });
        } else {
            setInputValue(item.meta.label);
        }
    }, [error, inputValue, item, updateCategory]);

    const onKeyDown = useCallback(
        async (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (error) {
                return;
            }
            if (e.key === "Enter") {
                await updateCategory.mutateAsync({ label: inputValue });
                inputRef.current?.blur();
            }
        },
        [error, inputValue, updateCategory],
    );

    const onClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    }, []);

    const onPointerDown = useCallback(
        (e: React.PointerEvent<HTMLDivElement>) => {
            e.stopPropagation();
        },
        [],
    );

    const onFocus = useCallback((e: React.FocusEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setIsFocused(true);
    }, []);

    const style = useMemo(
        () => ({
            width: `${width + 6}px`,
        }),
        [width],
    );

    const isEditable = item.meta.isEditable;

    return (
        <div
            className={classNames("d-flex align-items-center", {
                [styles.categoryIdCell]: true,
                [styles.categoryIdCellSubCategory]: true,
            })}
        >
            <div>
                {item.parentId ? (
                    <div
                        className={classNames(styles.rowIcon, styles.childIcon)}
                    />
                ) : (
                    <CategoryIcon categoryId={item.id as string} />
                )}
            </div>

            <OverlayTrigger
                show={isFocused && !!error}
                placement="top"
                overlay={<Tooltip id="add-category-error">{error}</Tooltip>}
            >
                <div>
                    {isEditable && (
                        <input
                            className={styles.addCategoryInput}
                            type="text"
                            placeholder="Enter category name..."
                            value={inputValue}
                            ref={inputRef}
                            onChange={onChange}
                            onClick={onClick}
                            onPointerDown={onPointerDown}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            style={style}
                        />
                    )}
                    <span
                        className={classNames(styles.addCategoryInput, {
                            [styles.invisible]: isEditable,
                        })}
                        id="hide"
                        ref={spanRef}
                    >
                        {inputValue}
                    </span>

                    {item.parentId === null && !item.disableAddChildren && (
                        <span
                            className={classNames(
                                styles.triangleIcon,
                                isCollapsed && styles.triangleIconCollapsed,
                            )}
                        >
                            <TriangleIcon />
                        </span>
                    )}
                </div>
            </OverlayTrigger>
        </div>
    );
};
