import React from "react";
import { getReportDateRangeLabel } from "../../common/helpers/reports";
import styles from "./AccountingReportHeader.module.scss";

export interface AccountingReportHeaderProps {
    entityName: string;
    reportType: string;
    dateLabel?: string;
    startDate?: Date;
    endDate?: Date;
}

export const AccountingReportHeader: React.FC<AccountingReportHeaderProps> = ({
    entityName,
    reportType,
    dateLabel,
    startDate,
    endDate,
}) => {
    const dateRangeLabel =
        startDate && endDate
            ? getReportDateRangeLabel(startDate, endDate)
            : null;

    return (
        <header className={styles.header}>
            <h3 className="font-weight-medium mb-4">{entityName}</h3>
            <h5 className="font-weight-medium mb-1">{reportType}</h5>
            <p className="text-grey">{dateLabel ?? dateRangeLabel}</p>
        </header>
    );
};
