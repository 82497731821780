import React from "react";
import "./AccountConnectionWidget/AccountsConnectionWidget.scss";
import classNames from "classnames";
import { useFinancialAccountEntitySelection } from "../../hooks/useFinancialAccountEntitySelection";
import { FinancialConnection } from "../../common/types/financialConnection";
import { IntegrationAccount } from "../../common/types/integrationAccount";
import { useFinancialAccountsConnectionContext } from "./FinancialAccountsConnectionContext";
import { FinancialAccountConnectionRow } from "./FinancialAccountConnectionRow";
import { isValidAccount } from "./helpers";

export interface AccountConnectionWidgetProps {
    accounts: IntegrationAccount[];
    connection?: FinancialConnection;
}

export const FinancialAccountsConnectionWidget: React.FC<
    AccountConnectionWidgetProps
> = ({ accounts, connection }) => {
    const { showBusinessEntitySelection } =
        useFinancialAccountEntitySelection();
    const entitySelection = showBusinessEntitySelection;
    const { showAccountTypeWarning } = useFinancialAccountsConnectionContext();
    const hasError =
        showAccountTypeWarning &&
        accounts.some((a) => !isValidAccount(a.financialAccount));

    return (
        <table
            className={classNames("table accounts-connection-widget", {
                "accounts-connection-widget--error": hasError,
            })}
        >
            <thead>
                {entitySelection && accounts.length > 0 && (
                    <tr className="small text-grey accounts-connection-widget__header-labels">
                        <th>Account</th>
                        <th></th>
                        <th>Entity</th>
                    </tr>
                )}
            </thead>

            <tbody>
                {accounts.length > 0 ? (
                    <>
                        {accounts.map((account) => (
                            <FinancialAccountConnectionRow
                                key={account.id}
                                account={account}
                                connection={connection}
                            />
                        ))}
                    </>
                ) : null}
            </tbody>
        </table>
    );
};
