import classNames from "classnames";
import { useState } from "react";
import { CloseIcon } from "../../../../icons";
import styles from "../Categories.module.scss";
import { ConfirmationModal } from "../../../general/ConfirmationModal";
import { CategoryFlattenedItem } from "../utils";
import { useDeleteCustomCategoryMutation } from "../../../../api/category.api";
import { Button } from "../../../general/Button/Button";

export const CategoryActions: React.FC<{
    category: CategoryFlattenedItem;
    numberOfSubcategoriesInsteadOfDelete?: number;
    onDelete?: () => void;
}> = ({ category, numberOfSubcategoriesInsteadOfDelete }) => {
    const deleteCategory = useDeleteCustomCategoryMutation(
        category.meta.category!,
    );
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    return (
        <div
            className={styles.categoryRowActionsContainer}
            onPointerDown={(e) => e.stopPropagation()}
        >
            {numberOfSubcategoriesInsteadOfDelete === undefined && (
                <Button
                    onClick={() => setShowDeleteModal(true)}
                    className={styles.closeButton}
                    icon
                    variant="tertiary"
                    size="sm"
                >
                    <CloseIcon />
                </Button>
            )}
            {numberOfSubcategoriesInsteadOfDelete !== undefined && (
                <button
                    className={classNames(
                        styles.closeIconContainer,
                        styles.numberOfSubcategories,
                        numberOfSubcategoriesInsteadOfDelete === 0 &&
                            styles.invisible,
                    )}
                >
                    <span>{numberOfSubcategoriesInsteadOfDelete}</span>
                </button>
            )}

            <ConfirmationModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                title={`Are you sure that you want to delete subcategory: ${category.meta.label}?`}
                question=""
                no="Cancel"
                onReject={() => setShowDeleteModal(false)}
                onConfirm={() => {
                    deleteCategory.mutate();
                    setShowDeleteModal(false);
                }}
                yes="Delete"
            />
        </div>
    );
};
