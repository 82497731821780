import { FC, useCallback, useMemo, useState } from "react";
import { AddRuleModal } from "../../settings/Rules/AddRuleModal";
import { TransactionRuleFormModal } from "../../settings/Rules/TransactionRuleFormModal";
import { Counterparty } from "../../../common/types/counterparty";
import { ChildrenProps } from "../../../types";
import { RuleSource } from "../../../common/types/transactionRule";
import {
    CreateRuleOptions,
    NewTransactionRuleDefinition,
    TransactionRuleCreationContext,
} from "./TransactionRuleCreation.context";

export const TransactionRuleCreation: FC<ChildrenProps> = ({ children }) => {
    const [newRuleDefinition, setNewRuleDefinition] =
        useState<NewTransactionRuleDefinition>();
    const [counterparties, setCounterparties] = useState<Counterparty[]>();
    const [showModal, setShowModal] = useState(false);
    const [source, setSource] = useState<RuleSource>();

    const createRule = useCallback(
        ({
            definition,
            counterparties: newRuleCounterparties,
            source: creationSource,
        }: CreateRuleOptions) => {
            setNewRuleDefinition(definition);
            setCounterparties(newRuleCounterparties);
            setSource(creationSource);
            setShowModal(true);
        },
        [],
    );

    const closeModal = useCallback(() => {
        setNewRuleDefinition(undefined);
        setCounterparties(undefined);
        setShowModal(false);
    }, []);

    const contextValue = useMemo(() => ({ createRule }), [createRule]);

    return (
        <>
            <TransactionRuleCreationContext.Provider value={contextValue}>
                {children}
            </TransactionRuleCreationContext.Provider>

            <TransactionRuleFormModal onHide={closeModal} show={showModal}>
                {showModal && source && (
                    <AddRuleModal
                        close={closeModal}
                        newRuleDefinition={newRuleDefinition}
                        counterparties={counterparties}
                        source={source}
                    />
                )}
            </TransactionRuleFormModal>
        </>
    );
};
