import React, { useContext, useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import classNames from "classnames";
import { topStartPopperConfig } from "../../../../helpers/overlays";
import { useMobileView } from "../../../../hooks/useMobileView";
import { useReceiptUpload } from "../../ReceiptUpload/useReceiptUpload";
import { DocumentIcon } from "../../../../icons";
import { RECEIPT_UPLOAD_HIGHLIGHTED_ELEMENT } from "../../ReceiptUpload/lib";
import { Loader } from "../../../general/Loader";
import { CustomToggle } from "../../../general/CustomToggle/CustomToggle";
import { StandardModal } from "../../../general/Modal/Modal";
import { TransactionMatchingContainer } from "../TransactionMatching/TransactionMatchingContainer";
import { TransactionDetailsContext } from "../transactionDetails.context";
import styles from "./TransactionMatches.module.scss";

export const TransactionMatchesAddReceipt: React.FC = () => {
    const mobile = useMobileView();
    const { transaction } = useContext(TransactionDetailsContext);
    const { inputProps, isDragActive, isUploading, open } = useReceiptUpload();
    const [showMatchingModal, setShowMatchingModal] = useState(false);

    let label: React.ReactNode;
    if (mobile) {
        label = <>Tap to add receipt</>;
    } else if (isDragActive) {
        label = <>Drop your file to upload</>;
    } else {
        label = <>Upload receipt</>;
    }

    return (
        <>
            <OverlayTrigger
                overlay={
                    <Tooltip id="transaction-receipt">
                        Drop any files here or click to upload
                    </Tooltip>
                }
                placement="top-start"
                popperConfig={topStartPopperConfig}
            >
                <div
                    className={classNames(
                        "form-group form-group--with-label form-group--inside-label form-group--icon form-group--icon--right mb-0",
                        RECEIPT_UPLOAD_HIGHLIGHTED_ELEMENT,
                        styles.addReceipt,
                    )}
                    data-testid="transaction-matches-add-receipt"
                >
                    <input
                        data-testid="transaction-matches-add-receipt-input d-none"
                        {...inputProps}
                    />

                    {isUploading ? (
                        <div className="d-flex justify-content-center align-items-center p-0 form-control mb-0">
                            <Loader inline />
                        </div>
                    ) : (
                        <Dropdown drop="down">
                            <Dropdown.Toggle as={CustomToggle}>
                                <div className="d-flex justify-content-between align-items-center form-control mb-0">
                                    <label className="form-group__label">
                                        {label}
                                    </label>

                                    <span className="form-group__icon">
                                        <DocumentIcon />
                                    </span>
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={open}>
                                    Upload Receipt
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => setShowMatchingModal(true)}
                                >
                                    Browse from documents
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </div>
            </OverlayTrigger>

            <StandardModal
                className="fullscreen-modal"
                show={showMatchingModal}
                onHide={() => setShowMatchingModal(false)}
            >
                {transaction && showMatchingModal && (
                    <TransactionMatchingContainer transaction={transaction} />
                )}
            </StandardModal>
        </>
    );
};
