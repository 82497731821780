import React, { useMemo, useState } from "react";
import classNames from "classnames";
import commonStyles from "../common.module.scss";
import animations from "../../layout/OnboardingLayout.module.scss";
import { ActionKey } from "./lib";
import { HowItWorksAction } from "./HowItWorksAction";
import styles from "./HowItWorks.module.scss";
import { HowItWorksFooter } from "./HowItWorksFooter";

interface Props {
    onBack: () => void;
    onFinish: () => void;
}

const actionsOrder: ActionKey[] = [
    "startPreview",
    "categorizationSummary",
    "personalToBusiness",
    "shareWithAccountant",
    "interlude",
];

export const HowItWorks: React.FC<Props> = ({ onFinish, onBack }) => {
    const [closed, setClosed] = useState<ActionKey[]>([]);
    const [closing, setClosing] = useState(false);
    const [waiting, setWaiting] = useState(false);

    const [activeCard, ...otherCards] = useMemo(
        () => actionsOrder.filter((key) => !closed.includes(key)),
        [closed],
    );

    const handleClosed = () => {
        const closedCards = [...closed, activeCard];

        setClosed(closedCards);
        setClosing(false);

        if (closedCards.length === actionsOrder.length) {
            onFinish();
        }
    };

    const handleBack = () => {
        if (closed.length === 0) {
            return onBack();
        }

        setClosed(closed.slice(0, -1));
    };

    return (
        <>
            <main
                className={classNames(
                    animations.fadeIn1,
                    commonStyles.content780,
                    styles.main,
                )}
            >
                {activeCard && (
                    <div className={styles.activeAction}>
                        <HowItWorksAction
                            key={`active-${activeCard}`}
                            actionKey={activeCard}
                            onClosed={handleClosed}
                            onClosing={() => {
                                setClosing(true);
                                setWaiting(false);
                            }}
                            onAction={() => setWaiting(true)}
                            open
                        />
                    </div>
                )}

                <section className={styles.otherActions}>
                    {otherCards.map((key) => (
                        <HowItWorksAction
                            key={key}
                            actionKey={key}
                            open={false}
                        />
                    ))}
                </section>
            </main>

            <HowItWorksFooter
                onBack={handleBack}
                waiting={waiting}
                closing={closing}
                actionKey={activeCard}
            />
        </>
    );
};
