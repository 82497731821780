import React from "react";
import { NavLink } from "react-router-dom";
import { endOfMonth, parse, startOfMonth } from "date-fns";
import { useDrillDownUrl } from "../useDrillDownUrl";
import { ChildrenProps } from "../../../types";
import { TransactionClassFilterType } from "../../../common/helpers/transactions";

interface Props extends ChildrenProps {
    accountCodes?: number[];
    startDate?: Date;
    endDate?: Date;
    entityId?: number;
    label?: string;
    classes?: string[];
    classFilterType?: TransactionClassFilterType;
}

export const ProfitLossDrillDown: React.FC<Props> = ({
    accountCodes,
    startDate,
    endDate,
    label,
    entityId,
    children,
    classes,
    classFilterType,
}) => {
    if (label) {
        const date = parse(label, "yyyy-MM", new Date());

        startDate = startOfMonth(date);
        endDate = endOfMonth(date);
    }

    if (!startDate || !endDate) {
        throw new Error("startDate and endDate or label are required");
    }

    const path = useDrillDownUrl({
        startDate,
        endDate,
        entityId,
        accountCodes,
        classes,
        classFilterType,
    });

    return (
        <NavLink to={path} className="data-table__drill-down">
            {children}
        </NavLink>
    );
};
