import { useMemo } from "react";
import { useWorkspaceCategories } from "../api/category.api";
import { EagerLoaded } from "../common/types/base/orm";
import { Category } from "../common/types/category";
import { useAppConfig } from "./useAppConfig";

export enum CategoryStructure {
    FLAT = "flat",
    TREE = "tree",
}

interface UseCategoriesOptions<T extends CategoryStructure> {
    structure: T;
    onlyStandardCategories?: boolean;
}

export function useCategories(
    options: UseCategoriesOptions<CategoryStructure.TREE>,
): Array<EagerLoaded<Category, "subcategories">>;
export function useCategories(
    options: UseCategoriesOptions<CategoryStructure.FLAT>,
): Category[];
export function useCategories<T extends CategoryStructure = CategoryStructure>({
    onlyStandardCategories = false,
    structure,
}: UseCategoriesOptions<T>):
    | Array<EagerLoaded<Category, "subcategories">>
    | Category[] {
    const categoriesToShow = useCategoriesToShow(onlyStandardCategories);

    const flat = useMemo(
        () =>
            categoriesToShow
                .map((category) => [
                    category,
                    ...(category.subcategories ?? []),
                ])
                .flat(),
        [categoriesToShow],
    );

    if (structure === CategoryStructure.TREE) {
        return categoriesToShow;
    }

    return flat;
}

function useCategoriesToShow(onlyStandardCategories: boolean) {
    const { data: appConfigData } = useAppConfig();
    const workspaceCategories = useWorkspaceCategories();

    if (onlyStandardCategories || workspaceCategories.isLoading) {
        return (appConfigData?.categories ?? []) as Array<
            EagerLoaded<Category, "subcategories">
        >;
    }

    return (workspaceCategories.data?.categories ??
        appConfigData?.categories ??
        []) as Array<EagerLoaded<Category, "subcategories">>;
}
