import { useCallback } from "react";

export function useResetFilters<
    TFilters extends object,
    TActiveFilterId extends string,
>(
    resetPayload: Record<TActiveFilterId, Partial<TFilters>>,
    onChange: (
        filters: Partial<TFilters>,
    ) => void | ((currentFilters: TFilters) => TFilters),
    filterIds: TActiveFilterId | TActiveFilterId[],
) {
    return useCallback(() => {
        const filters = Array.isArray(filterIds) ? filterIds : [filterIds];
        onChange({
            ...filters.reduce(
                (acc, filterId) => ({
                    ...acc,
                    ...resetPayload[filterId],
                }),
                {},
            ),
        });
    }, [filterIds, onChange, resetPayload]);
}
