import React, {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useState,
} from "react";
import { isFunction } from "lodash";
import type { Plaid } from "plaid-link";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import {
    connectWithPlaid,
    getPlaidConnections,
} from "../../../lib/plaidConnection";
import { CreateBankAccountConnectionDto } from "../../../common/dto/onboarding/create-bank-account-connection.dto";
import { PlaidConnection } from "../../../common/types/plaidConnection";
import { CreatePlaidConnectionResponse } from "../../../common/dto/plaid/create-plaid-connection-response.dto";
import { FullAccountNumber } from "../../../common/dto/financialAccount/get-account-numbers-response.dto";
import {
    useAddPlaidAccountsMutation,
    useBankConnectionCreationMutation,
} from "../../../mutations/plaidConnection";
import { Entity } from "../../../common/types/entity";
import { CreateAccountHandle } from "../../accounts/AddAccounts/lib";
import { useConnectAccount } from "../../connectAccount/ConnectAccount.context";
import { PLAID_INSTITUTIONS_NAMES } from "../../../common/constants";
import { ExtendedButtonProps } from "../../general/Button/Button";
import { PossibleConnectionDuplicatesModal } from "./PossibleConnectionDuplicatesModal";

export interface ConnectPlaidAccountProps {
    onSavingConnection?: (saving: boolean) => void;
    btnVariant?: ExtendedButtonProps["variant"];
    btnText?: React.ReactNode;
    btnSize?: ExtendedButtonProps["size"];
    btnClass?: string;
    institutionName?: PLAID_INSTITUTIONS_NAMES;
    onConnected?: (
        connection: PlaidConnection,
        numbers: FullAccountNumber[],
    ) => void;
    disabled?: boolean;
    defaultEntity?: Entity;
    beforeConnect?: () => Promise<void>;
    children?: (props: {
        connecting: boolean;
        initializeConnection: () => void;
    }) => React.ReactNode;
}

interface SavedLinkResponse {
    publicToken: string;
    metadata: Plaid.OnSuccessMetaData;
}

export const ConnectPlaid = forwardRef<
    CreateAccountHandle,
    ConnectPlaidAccountProps
>(
    (
        {
            btnVariant = "default",
            btnText = "Connect Now",
            btnSize,
            btnClass,
            institutionName,
            onSavingConnection,
            onConnected,
            disabled,
            defaultEntity,
            beforeConnect,
            children,
        },
        ref,
    ) => {
        const { updateConnectAccountState } = useConnectAccount();
        const [connecting, setConnecting] = useState(false);
        const [savedLinkResponse, setSavedLinkResponse] =
            useState<SavedLinkResponse>();
        const [possibleDuplicates, setPossibleDuplicates] =
            useState<PlaidConnection[]>();

        const createConnection = useBankConnectionCreationMutation();
        const addAccounts = useAddPlaidAccountsMutation();

        const handleSuccessfulConnection = useCallback(
            async (publicToken: string, metadata: Plaid.OnSuccessMetaData) => {
                if (!metadata.institution) {
                    return publicToken;
                }

                const similarConnections = await getPlaidConnections(
                    metadata.institution.institution_id,
                );

                if (similarConnections.length > 0) {
                    setPossibleDuplicates(similarConnections);
                    setSavedLinkResponse({ publicToken, metadata });
                } else {
                    return publicToken;
                }
            },
            [],
        );

        const authorize = useCallback(async () => {
            setConnecting(true);

            const connectResult = await connectWithPlaid({
                institutionName,
            });

            if (connectResult.connected) {
                return await handleSuccessfulConnection(
                    connectResult.publicToken,
                    connectResult.metadata,
                );
            } else {
                setConnecting(false);
            }
        }, [handleSuccessfulConnection, institutionName]);

        const connect = useCallback(
            async (data: CreateBankAccountConnectionDto) => {
                onSavingConnection?.(true);
                updateConnectAccountState({
                    isConnecting: true,
                });
                const response = await createConnection.mutateAsync(data);
                onSavingConnection?.(false);

                return response;
            },
            [onSavingConnection, updateConnectAccountState, createConnection],
        );

        const onBankConnected = useCallback(
            ({ connection, numbers }: CreatePlaidConnectionResponse) => {
                setConnecting(false);
                onConnected?.(connection, numbers);
            },
            [onConnected],
        );

        const finalizeConnection = useCallback(
            async (publicToken: string) => {
                const connectedResult = await connect({
                    publicToken,
                    defaultEntityId: defaultEntity?.id,
                });
                onBankConnected(connectedResult);
            },
            [onBankConnected, connect, defaultEntity],
        );

        const initializeConnection = useCallback(async () => {
            if (connecting) {
                return;
            }

            try {
                if (beforeConnect) {
                    await beforeConnect();
                }

                const publicToken = await authorize();

                if (publicToken) {
                    await finalizeConnection(publicToken);
                }
            } catch {
                setConnecting(false);
            }
        }, [authorize, beforeConnect, connecting, finalizeConnection]);

        const connectNewDespiteDuplicates = useCallback(() => {
            setPossibleDuplicates(undefined);
            if (savedLinkResponse) {
                finalizeConnection(savedLinkResponse.publicToken).catch(() =>
                    setConnecting(false),
                );
                setSavedLinkResponse(undefined);
            }
        }, [finalizeConnection, savedLinkResponse]);

        const useExistingConnection = useCallback(
            (connection?: PlaidConnection) => {
                setPossibleDuplicates(undefined);
                setSavedLinkResponse(undefined);

                if (connection) {
                    addAccounts
                        .mutateAsync(connection)
                        .finally(() => setConnecting(false));
                } else {
                    setConnecting(false);
                }
            },
            [addAccounts],
        );

        useImperativeHandle(
            ref,
            () => ({
                initializeConnection: () => {
                    if (possibleDuplicates || connecting) {
                        return;
                    }

                    return initializeConnection();
                },
            }),
            [connecting, initializeConnection, possibleDuplicates],
        );

        return (
            <>
                {isFunction(children) ? (
                    children({ connecting, initializeConnection })
                ) : (
                    <ButtonWithLoader
                        loading={connecting}
                        onClick={initializeConnection}
                        variant={btnVariant}
                        size={btnSize}
                        className={btnClass}
                        disabled={disabled}
                        data-testid="connect-plaid-btn"
                    >
                        {btnText}
                    </ButtonWithLoader>
                )}

                {possibleDuplicates && (
                    <PossibleConnectionDuplicatesModal
                        show={!!possibleDuplicates}
                        connections={possibleDuplicates}
                        onConnectNew={connectNewDespiteDuplicates}
                        onUseExisting={useExistingConnection}
                    />
                )}
            </>
        );
    },
);
