import React from "react";
import classNames from "classnames";
import { Loader } from "../../general/Loader";
import {
    StandardModal,
    StandardModalBody,
    StandardModalHeader,
} from "../../general/Modal/Modal";
interface Props {
    show: boolean;
}

export const NewConnectionLoaderModal: React.FC<Props> = ({ show }) => (
    <StandardModal
        show={show}
        size="lg"
        backdrop="static"
        keyboard={false}
        className={classNames("accounts-connected-modal")}
    >
        <>
            <StandardModalHeader>Loading accounts</StandardModalHeader>
            <StandardModalBody>
                <p>This might take a few seconds...</p>

                <div className="py-5">
                    <Loader />
                </div>
            </StandardModalBody>
        </>
    </StandardModal>
);
