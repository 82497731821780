import { chunk, range } from "lodash";
import { Taxonomy } from "../../../common/categories";
import { BulkUpdatePayload } from "../../../common/dto/transactions/bulk/bulk-update-payload";
import { BulkUpdateTransactionsDto } from "../../../common/dto/transactions/bulk/bulk-update-transactions.dto";
import { TaxQuestionKey } from "../../../common/taxSavingRules";
import { Category } from "../../../common/types/category";
import { Entity } from "../../../common/types/entity";
import { TransactionsFilters } from "../filters/lib";
import { WithRequiredProperties } from "../../../common/types/base/generics";
import { SelectedTransaction } from "./transactionsBulkActionsContext";

export enum BulkUpdateStatus {
    UPDATING = "UPDATING",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
}

export enum BulkUpdateMode {
    SELECTED = "SELECTED",
    FILTERS = "FILTERS",
}

export type BulkUpdateParams =
    | {
          update: "categoryId";
          value: string;
      }
    | {
          update: "memo";
          value: string;
      }
    | {
          update: "taxQuestionAnswers";
          value: Array<{
              key: TaxQuestionKey;
              answer: string;
          }>;
      }
    | {
          update: "entity";
          value: Entity;
      }
    | {
          update: "classes";
          value: string[];
      }
    | {
          update: "counterpartyId";
          value: string | null;
      };

export type BulkUpdateDetails = {
    status: BulkUpdateStatus;
    transactionsCount: number;
    isUndo?: boolean;
    mode: BulkUpdateMode;
} & BulkUpdateParams;

export const BULK_ACTIONS_CHUNK_SIZE = 20;

function getPayloadForBulkUpdate(
    updateParams: BulkUpdateParams,
    businessEntity?: Entity,
): BulkUpdatePayload {
    if (updateParams.update === "categoryId") {
        const { value: categoryId } = updateParams;

        return {
            categoryId,
            entityId: businessEntity?.id,
        };
    }

    if (updateParams.update === "entity") {
        return {
            entityId: updateParams.value.id,
        };
    }

    return {
        [updateParams.update]: updateParams.value,
    };
}

interface PreparePayloadsForSelectedTransactionsParams {
    updateParams: BulkUpdateParams;
    selected: Record<number, SelectedTransaction>;
    businessEntity?: Entity;
}

export function preparePayloadsForSelectedTransactions({
    updateParams,
    selected,
    businessEntity,
}: PreparePayloadsForSelectedTransactionsParams): BulkUpdateTransactionsDto[] {
    const payload = getPayloadForBulkUpdate(updateParams, businessEntity);
    const selectedTransactionsIds = Object.values(selected).map((t) => t.id);

    return chunk(selectedTransactionsIds, BULK_ACTIONS_CHUNK_SIZE).map(
        (transactionIds) => ({
            transactions: transactionIds.map((transactionId) => ({
                transactionId,
                ...payload,
            })),
        }),
    );
}

interface PreparePayloadsForUndoParams {
    lastUpdateParams: BulkUpdateParams;
    previousTransactions: Record<number, SelectedTransaction>;
    standardCategories: Record<Taxonomy, Category>;
}

export function preparePayloadsForUndo({
    lastUpdateParams,
    previousTransactions,
    standardCategories,
}: PreparePayloadsForUndoParams): BulkUpdateTransactionsDto[] {
    return chunk(
        Object.values(previousTransactions),
        BULK_ACTIONS_CHUNK_SIZE,
    ).map((transactions) => ({
        transactions: transactions.map((transaction) => ({
            transactionId: transaction.id,
            ...getPayloadForBulkUpdate(
                getBulkUpdateParamsForUndo(
                    lastUpdateParams,
                    transaction,
                    standardCategories,
                ),
            ),
        })),
    }));
}

function getBulkUpdateParamsForUndo(
    lastUpdateParams: BulkUpdateParams,
    previousTransaction: SelectedTransaction,
    standardCategories: Record<Taxonomy, Category>,
): BulkUpdateParams {
    if (
        lastUpdateParams.update === "categoryId" &&
        lastUpdateParams.value === standardCategories[Taxonomy.personal].id
    ) {
        return {
            update: "entity",
            value: previousTransaction.entity,
        };
    }

    if (
        lastUpdateParams.update === "entity" &&
        previousTransaction.entity.isPersonal
    ) {
        return {
            update: "categoryId",
            value: standardCategories[Taxonomy.personal].id,
        };
    }

    if (lastUpdateParams.update === "classes") {
        return {
            update: "classes",
            value:
                previousTransaction.classAssignments?.map(
                    ({ classId }) => classId,
                ) ?? [],
        };
    }

    if (lastUpdateParams.update === "counterpartyId") {
        return {
            update: "counterpartyId",
            value: previousTransaction.counterpartyId,
        };
    }

    return {
        update: lastUpdateParams.update,
        value: previousTransaction[lastUpdateParams.update],
    } as BulkUpdateParams;
}

interface PreparePayloadsForFiltersParams {
    updateParams: BulkUpdateParams;
    filters: WithRequiredProperties<TransactionsFilters, "entityIds">;
    transactionCount: number;
    businessEntity?: Entity;
}

export function preparePayloadsForFilters({
    updateParams,
    filters,
    transactionCount,
    businessEntity,
}: PreparePayloadsForFiltersParams): BulkUpdateTransactionsDto[] {
    const payload = getPayloadForBulkUpdate(updateParams, businessEntity);
    const pages = Math.ceil(transactionCount / BULK_ACTIONS_CHUNK_SIZE);
    /*
     * For situations when transactions after the update no longer match selected filters,
     * (we are updating a property we also filter on), we should skip paging:
     * each batch fetches transactions from the DB, so the next batch will always be page 0.
     *
     * For updates unrelated to current filters, keep paging normally
     */
    const updateMatchesFilters =
        !!filters[updateParams.update as keyof TransactionsFilters];

    return range(pages).map((page) => ({
        payload,
        options: {
            filters: {
                ...filters,
                start: filters.start ? filters.start.toISOString() : undefined,
                end: filters.end ? filters.end.toISOString() : undefined,
            },
            page: updateMatchesFilters ? 0 : page,
            limit: BULK_ACTIONS_CHUNK_SIZE,
        },
    }));
}
