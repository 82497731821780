import { useMemo } from "react";
import { subMinutes } from "date-fns";
import { FinancialAccount } from "../common/types/financialAccount";
import { IntegrationAccount } from "../common/types/integrationAccount";
import { useIsDemoUser } from "./useIsDemoUser";

function useLatestSyncTime(
    accounts: Array<FinancialAccount | IntegrationAccount>,
    key: "balanceSyncedAt" | "transactionsSyncedAt",
) {
    const isDemoUser = useIsDemoUser();
    return useMemo(() => {
        if (isDemoUser) {
            return subMinutes(new Date(), 4);
        }
        const sorted = [...accounts].sort((a, b) => {
            const aValue = a[key];
            const bValue = b[key];

            return (
                (bValue ? new Date(bValue).getTime() : 0) -
                (aValue ? new Date(aValue).getTime() : 0)
            );
        });

        return sorted[0]?.[key];
    }, [accounts, isDemoUser, key]);
}

export function useLatestBalanceSyncTime(
    accounts: Array<FinancialAccount | IntegrationAccount>,
) {
    return useLatestSyncTime(accounts, "balanceSyncedAt");
}

export function useLatestTransactionsSyncTime(
    accounts: Array<FinancialAccount | IntegrationAccount>,
) {
    return useLatestSyncTime(accounts, "transactionsSyncedAt");
}
