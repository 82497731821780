import { GA_CLIENT_ID } from "../common/registrationCookies";
import { getCookie } from "../helpers/cookie";

export function useGAClientIdCookie() {
    const ga = getCookie("_ga");

    if (ga) {
        const clientId = ga.substring(6);
        document.cookie = `${GA_CLIENT_ID}=${clientId}; samesite=strict; path=/; `;
    }
}
