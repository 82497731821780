import { initContract } from "@ts-rest/core";
import { z } from "zod";
import { WorkspaceMemberDto } from "../dto/workspace/workspace-member.dto";
import { UserWorkspaceRole } from "../userWorkspaceRoles";
import { UserRole } from "../types/userEntityAssignment";
import { WorkspaceInviteDto } from "../dto/workspace/workspace-invite.dto";

const c = initContract();

export const workspaceMemberContract = c.router(
    {
        getMembers: {
            method: "GET",
            path: "/",
            responses: {
                200: c.type<WorkspaceMemberDto[]>(),
            },
        },
        removeMember: {
            method: "DELETE",
            path: "/:memberId",
            responses: {
                200: c.noBody(),
            },
        },
        updateWorkspaceAssignment: {
            method: "PATCH",
            path: "/:memberId",
            body: z.object({
                role: z.nativeEnum(UserWorkspaceRole),
                entityAssignments: z.array(
                    z.object({
                        entityId: z.number(),
                        role: z.nativeEnum(UserRole),
                    }),
                ),
            }),
            responses: {
                200: c.noBody(),
            },
        },
        inviteMember: {
            method: "POST",
            path: "/invite",
            body: z.object({
                email: z.string(),
                role: z.nativeEnum(UserWorkspaceRole),
                entityAssignments: z.array(
                    z.object({
                        entityId: z.number(),
                        role: z.nativeEnum(UserRole),
                    }),
                ),
            }),
            responses: {
                201: c.noBody(),
            },
        },
        getInvites: {
            method: "GET",
            path: "/invite",
            responses: {
                200: c.type<WorkspaceInviteDto[]>(),
            },
        },
        resendInvite: {
            method: "POST",
            path: "/invite/:inviteId/resend",
            body: c.noBody(),
            responses: {
                200: c.noBody(),
            },
        },
        cancelInvite: {
            method: "DELETE",
            path: "/invite/:inviteId",
            responses: {
                200: c.noBody(),
            },
        },
    },
    {
        pathPrefix: "/workspace-member/:workspaceId",
    },
);
