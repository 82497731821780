import { InvoiceSortValue } from "../common/types/invoice";
import {
    useWorkspaceUserCache,
    WORKSPACE_USER_CACHE_KEYS,
} from "./useWorkspaceUserCache";

export function useInvoicesPageSortingCache(): [
    InvoiceSortValue | undefined,
    (value: InvoiceSortValue | undefined) => void,
] {
    const [value, setter] = useWorkspaceUserCache<InvoiceSortValue>(
        WORKSPACE_USER_CACHE_KEYS.INVOICES_PAGE_SORTING_CACHE_KEY,
    );

    return [
        value,
        (sorting: InvoiceSortValue | undefined) => {
            setter(sorting);
        },
    ];
}
