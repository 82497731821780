import { initContract } from "@ts-rest/core";
import { z } from "zod";

const c = initContract();

export const authContract = c.router(
    {
        login: {
            path: "/login",
            method: "POST",
            body: z.object({
                emailOrPhoneNumber: z.union([
                    z.string().email(),
                    z.string().regex(/^\d{10}$/),
                ]),
            }),
            responses: {
                204: c.noBody(),
                429: c.type<{ tooManyLoginAttempts: true }>(),
            },
        },
    },
    {
        pathPrefix: "/auth",
    },
);
