import classNames from "classnames";
import { Col, Row } from "react-bootstrap";
import { pluralizeWithCount } from "../../common/helpers/string";
import { InvoiceStats as InvoiceStatsType } from "../../common/types/invoice";
import { Amount } from "../general/Amount/Amount";
import { Card } from "../general/Card/Card";
import styles from "./InvoiceStats.module.scss";

interface Props {
    invoiceStats?: InvoiceStatsType;
    showConnectOverlay?: boolean;
}

export const InvoiceStats: React.FC<Props> = ({ invoiceStats }) => (
    <Row>
        <Col>
            <Card className={styles.card}>
                <span className={styles.title}>Open invoices</span>
                <Amount
                    className={styles.amount}
                    amount={invoiceStats?.open?.total ?? 0}
                    separateCurrencySign
                    showNegative={false}
                />
                <span className={styles.count}>
                    {pluralizeWithCount(
                        "invoice",
                        invoiceStats?.open.count ?? 0,
                    )}
                </span>
            </Card>
        </Col>
        <Col>
            <Card className={styles.card}>
                <span className={styles.title}>Overdue invoices</span>
                <Amount
                    className={classNames(styles.amount, styles.amountOverdue)}
                    amount={invoiceStats?.overdue.total ?? 0}
                    separateCurrencySign
                    showNegative={false}
                />
                <span className={styles.count}>
                    {pluralizeWithCount(
                        "invoice",
                        invoiceStats?.overdue.count ?? 0,
                    )}
                </span>
            </Card>
        </Col>
        <Col>
            <Card className={styles.card}>
                <span className={styles.title}>Paid invoices</span>
                <Amount
                    className={styles.amount}
                    amount={invoiceStats?.paid?.total ?? 0}
                    separateCurrencySign
                    showNegative={false}
                />
                <span className={styles.count}>
                    {pluralizeWithCount(
                        "invoice",
                        invoiceStats?.paid.count ?? 0,
                    )}
                </span>
            </Card>
        </Col>
    </Row>
);
