import {
    ConnectionProvider,
    IntegrationConnection,
} from "../../types/integrationConnection";
import { IntegrationAccountDto } from "../financialAccount/integration-account.dto";

export class IntegrationConnectionDto {
    id: string;
    provider: ConnectionProvider;
    providerId: string | null;
    label: string | null;
    workspaceId: string;
    createdByUserId: number | null;
    createdAt: string;
    institutionLogoUrl: string | null;
    institutionName: string | null;
    metadata: any;
    error: string | null;
    integrationAccounts?: IntegrationAccountDto[];

    constructor(connection: IntegrationConnection) {
        this.id = connection.id;
        this.provider = connection.provider;
        this.providerId = connection.providerId;
        this.label = connection.label;
        this.workspaceId = connection.workspaceId;
        this.createdByUserId = connection.createdByUserId;
        this.createdAt = connection.createdAt.toISOString();
        this.institutionLogoUrl = connection.institutionLogoUrl;
        this.institutionName = connection.institutionName;
        this.metadata = connection.metadata;
        this.error = connection.error;
        this.integrationAccounts = connection.integrationAccounts?.map(
            (account) => new IntegrationAccountDto(account),
        );
    }

    static deserialize(dto: IntegrationConnectionDto): IntegrationConnection {
        return {
            ...dto,
            createdAt: new Date(dto.createdAt),
            integrationAccounts: dto.integrationAccounts?.map((account) =>
                IntegrationAccountDto.deserialize(account),
            ),
        };
    }
}
