import { useMemo } from "react";
import {
    PLCell,
    ProfitLossTableReportSection,
    ProfitLossTableReportSectionsDto,
} from "../../../common/dto/reports/reports-common.dto";
import { ProfitLossReport } from "../types";
import { PLDataRow } from "./types";

export function useTableData(report: ProfitLossReport) {
    return useMemo(() => getFlatTableData(report.sections), [report.sections]);
}

function cellData(cells: PLCell[]): Record<string, number> {
    return cells.reduce(
        (acc, { label, value }) => {
            acc[label] = value;
            return acc;
        },
        {} as Record<string, number>,
    );
}

// this is temporary, until we finalize the report DTO response
function getFlatTableData(
    sections: ProfitLossTableReportSectionsDto,
): PLDataRow[] {
    const flatRows: PLDataRow[] = [];
    const nextId = () => flatRows.length;

    for (const key of Object.values(
        ProfitLossTableReportSection,
    ) as ProfitLossTableReportSection[]) {
        const { rows, data } = sections[key];

        if (rows?.length) {
            for (const { category, data: cells } of rows) {
                flatRows.push({
                    section: key,
                    category,
                    isTotal: false,
                    data: cellData(cells),
                    id: nextId(),
                });
            }
        } else {
            // push an empty row for sections with no rows
            flatRows.push({
                section: key,
                category: "",
                isTotal: false,
                data: cellData(data),
                id: nextId(),
            });
        }
    }

    return flatRows;
}
