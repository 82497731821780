import React from "react";
import classNames from "classnames";
import styles from "./TermsAndPrivacyConsent.module.scss";

interface Props {
    action?: string;
    className?: string;
}

export const TermsAndPrivacyConsent: React.FC<Props> = ({
    action = "continuing",
    className,
}) => (
    <footer className={classNames(styles.consent, className)}>
        By {action} you agree to the{" "}
        <a
            href="https://kick.co/legal#terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
        >
            Terms
        </a>{" "}
        &amp;{" "}
        <a
            href="https://kick.co/legal#privacy"
            target="_blank"
            rel="noopener noreferrer"
        >
            Privacy Policy
        </a>
        .
    </footer>
);
