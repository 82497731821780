import { useField } from "formik";
import { useMemo } from "react";
import { FormRuleConditionType, TransactionRuleFormSchema } from "../constants";

export function useTaxonomyFromRuleConditionsForm() {
    const [conditionsField] =
        useField<TransactionRuleFormSchema["conditions"]>(`conditions`);

    return useMemo(() => {
        const categoryCondition = conditionsField.value.find(
            (c) =>
                c.conditionType === FormRuleConditionType.CATEGORY ||
                c.conditionType === FormRuleConditionType.SUGGESTED_CATEGORY,
        );

        return categoryCondition?.categoryValue?.taxonomy;
    }, [conditionsField.value]);
}
