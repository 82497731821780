import { FinancialDocumentBulkActionTypes } from "../constants";
import { Entity } from "./entity";
import { TransactionMatch } from "./transactionMatch";
import { EagerLoaded } from "./base/orm";

export interface FinancialDocumentBase {
    id: number;
    source: string;
    createdAt: Date;
    path: string;
    fileName: string;
    date: Date | null;
    amount: number | null;
    hasAttemptedMatching: boolean;

    isSpreadsheet: boolean | null; // Replace with file type: receipt, spreadsheet, etc.
    numTransactions: number | null;

    // Put in CSV metadata JSON
    spreadsheetImporterStatus: SpreadsheetImporterStatus; // csvImporterStatus
    csvFormatStr: string | null;
    spreadsheetParsingRuleId: number | null;
}

export interface FinancialDocument extends FinancialDocumentBase {
    entity: Entity | null; // directly link to entity
    transactionMatches: Array<
        EagerLoaded<TransactionMatch, "financialTransaction">
    >;
}

export interface RemoveAction {
    type: FinancialDocumentBulkActionTypes.REMOVE;
}

export interface AssignToEntityAction {
    type: FinancialDocumentBulkActionTypes.ASSIGN_ENTITY;
    entityId: number;
}

export type FinancialDocumentBulkAction = RemoveAction | AssignToEntityAction;

export enum SpreadsheetImporterStatus {
    NOT_IMPORTED = "not_imported",
    NEEDS_VERIFICATION = "needs_verification",
    IMPORT_RUNNING = "currently_importing",
    IMPORT_FAILED = "import_failed",
    IMPORT_SUCCESS = "import_success",
}
