import classNames from "classnames";
import React from "react";
import { ButtonGroup } from "react-bootstrap";
import { ReportingTabCycle } from "../../../common/constants";
import { Entity } from "../../../common/types/entity";
import { ReportFilters } from "../../../common/types/filters/reports";
import { ArrowDownIcon, ArrowUpIcon } from "../../../icons";
import { EntityIcon } from "../../entity/EntityIcon";
import { CustomSelect } from "../../forms/CustomSelect/CustomSelect";
import { DateRangeFilter } from "../../general/DateRangeFilter/DateRangeFilter";
import { EntityFilter } from "../../general/EntityFilter/EntityFilter";
import { Button } from "../../general/Button/Button";

const cycleLabels: Record<ReportingTabCycle, string> = {
    [ReportingTabCycle.WEEK]: "By week",
    [ReportingTabCycle.MONTH]: "By month",
};
const cycleOptions = [
    {
        value: ReportingTabCycle.WEEK,
        label: cycleLabels[ReportingTabCycle.WEEK],
    },
    {
        value: ReportingTabCycle.MONTH,
        label: cycleLabels[ReportingTabCycle.MONTH],
    },
];

export interface ReportsPageFiltersProps {
    filters: ReportFilters;
    onChange(filters: Partial<ReportFilters>): void;
    hardcodedEntity?: Entity;
}

export const ReportsPageFilters: React.FC<ReportsPageFiltersProps> = ({
    filters,
    onChange,
    hardcodedEntity,
}) => (
    <section className="reports-filter-form">
        <div className="reports-filter-form__nav"></div>
        <div className="reports-filter-form__filters">
            <ButtonGroup className="gap-buttons">
                <DateRangeFilter
                    dateRange={{
                        start: filters.start,
                        end: filters.end,
                    }}
                    onChange={onChange}
                    monthOnly
                />
                <CustomSelect
                    onSelected={(cycle) => onChange({ cycle })}
                    value={filters.cycle}
                    dropdownKey="reports-tab-cycle-select"
                    options={cycleOptions}
                    className="reports-filter-form__cycle-select"
                    popoverClassName="reports-filter-form__cycle-select-popover"
                >
                    {(opened) => (
                        <Button
                            variant="secondary"
                            className={classNames({
                                active: opened,
                            })}
                        >
                            {cycleLabels[filters.cycle]}
                            {opened ? <ArrowUpIcon /> : <ArrowDownIcon />}
                        </Button>
                    )}
                </CustomSelect>
            </ButtonGroup>
            {hardcodedEntity ? (
                <Button variant="secondary" size="sm">
                    <EntityIcon entity={hardcodedEntity} size="xs" />
                    <span className="reports-filter-form__hardcoded-entity__name">
                        {hardcodedEntity.name}
                    </span>
                </Button>
            ) : (
                <EntityFilter
                    filters={filters}
                    onlyBusiness
                    onChange={onChange}
                />
            )}
        </div>
    </section>
);
