import { useContext } from "react";
import { CategoryStructure, useCategories } from "../../../hooks/useCategories";
import { Category } from "../../../common/types/category";
import { COUNTERPARTY_TAXONOMY_BY_DIRECTION } from "../../../common/helpers/counterparties";
import { DirectoryContext } from "./Directory.context";

export function useCategoriesForDirectoryDrilldown(): undefined | Category[] {
    const workspaceCategories = useCategories({
        structure: CategoryStructure.FLAT,
    });
    const { counterpartyDirection } = useContext(DirectoryContext);

    if (!counterpartyDirection) {
        return;
    }

    const taxonomies =
        COUNTERPARTY_TAXONOMY_BY_DIRECTION[counterpartyDirection];

    return workspaceCategories.filter((category) =>
        taxonomies.includes(category.taxonomy),
    );
}
