import React from "react";
import { EntityIcon } from "../../entity/EntityIcon";
import { FormCheckbox } from "../../forms/FormCheckbox/FormCheckbox";
import { Entity } from "../../../common/types/entity";
import { BreakableText } from "../BreakableText/BreakableText";
import styles from "./EntityFilter.module.scss";

export interface EntityFilterItemProps {
    entity: Entity;
    isSelected: boolean;
    onToggle(entityId: number): void;
}

export const EntityFilterItem: React.FC<EntityFilterItemProps> = ({
    isSelected,
    entity,
    onToggle,
}) => (
    <FormCheckbox
        small
        value={entity.id}
        isChecked={isSelected}
        handleChange={() => onToggle(entity.id)}
        className={styles.item}
        label={
            <div className={styles.itemLabel}>
                <EntityIcon entity={entity} size="sm" />
                <BreakableText className="ml-2" text={entity.name} />
            </div>
        }
    />
);
