import { useMemo } from "react";
import {
    EntityProfileRevenueBracket,
    EntityProfileType,
    EntityType,
} from "../common/types/entity";
import { FLAT_RATE_PLANS } from "../common/flatRateBilling";
import { useBillingStatus } from "./useBillingStatus";
import { useEntities } from "./useEntities";

export const ENTERPRISE_REVENUE_BRACKETS = [
    EntityProfileRevenueBracket.MORE_THAN_1M,
];

export function useRecommendedPlan() {
    const entities = useEntities();

    const { currentPlan, plans, isFetched } = useBillingStatus();

    const recommendedPlan = useMemo(() => {
        if (!isFetched || !entities.length) {
            return null;
        }

        if (
            entities.some(
                (entity) =>
                    entity.profile?.annualRevenue &&
                    ENTERPRISE_REVENUE_BRACKETS.includes(
                        entity.profile?.annualRevenue as any,
                    ),
            )
        ) {
            return FLAT_RATE_PLANS.ENTERPRISE;
        }

        const hasCCorpEntity = entities.some(
            (entity) =>
                (entity.profile?.entityType &&
                    [EntityType.C_CORP, EntityType.C_CORP_WITH_S_CORP].includes(
                        entity.profile?.entityType,
                    )) ||
                entity.profile?.type === EntityProfileType.C_CORP,
        );

        if (hasCCorpEntity) {
            return FLAT_RATE_PLANS.ENTERPRISE;
        }

        const hasSCorpOrPartnershipEntity = entities.some(
            (entity) =>
                entity.profile?.entityType === EntityType.LLC_WITH_S_CORP ||
                entity.profile?.type === EntityProfileType.S_CORP ||
                entity.profile?.type === EntityProfileType.PARTNERSHIP,
        );

        if (hasSCorpOrPartnershipEntity) {
            return FLAT_RATE_PLANS.PLUS;
        }

        return FLAT_RATE_PLANS.BASIC;
    }, [isFetched, entities]);

    const isUpgrade =
        plans.findIndex(({ plan }) => plan === recommendedPlan) >
        plans.findIndex(({ plan }) => plan === currentPlan);

    return isUpgrade ? recommendedPlan : null;
}
