import React, { forwardRef, useCallback, useImperativeHandle } from "react";
import { useParams } from "react-router-dom";
import { getWebAuth } from "../../../lib/auth0";
import { getEnv } from "../../../lib/environment";
import { GoogleIcon } from "../../../icons";
import { GOOGLE_AUTH_REDIRECT_PATH } from "../../../common/constants";
import { Button, ExtendedButtonProps } from "../../general/Button/Button";

type LoginWithGoogleButtonProps = Omit<ExtendedButtonProps, "onClick">;

export interface GoogleLoginHandle {
    login(): void;
}

export interface RouteParams {
    inviteCode?: string;
}

export const LoginWithGoogleButton = forwardRef<
    GoogleLoginHandle,
    LoginWithGoogleButtonProps
>(({ children, ...props }, ref) => {
    const { inviteCode } = useParams() as Readonly<RouteParams>;

    const handleGoogleLogin = useCallback(() => {
        const redirect = new URL(window.location.origin);
        redirect.pathname = GOOGLE_AUTH_REDIRECT_PATH;

        if (inviteCode) {
            redirect.searchParams.set("inviteCode", inviteCode);
        }

        getWebAuth().authorize({
            connection: "google-oauth2",
            responseType: "token",
            redirectUri: redirect.toString(),
            audience: getEnv("VITE_AUTH0_AUDIENCE"),
        });
    }, [inviteCode]);

    useImperativeHandle(
        ref,
        () => ({
            login: handleGoogleLogin,
        }),
        [handleGoogleLogin],
    );

    return (
        <Button {...props} onClick={handleGoogleLogin}>
            <GoogleIcon className="icon-color-revert" /> {children}
        </Button>
    );
});
