import React, {
    forwardRef,
    PropsWithChildren,
    useCallback,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import classNames from "classnames";
import { Placement } from "react-bootstrap/Overlay";
import "./PopoverContainer.scss";
import { ArrowDownIcon, ArrowUpIcon } from "../../icons";
import { Button, ExtendedButtonProps } from "../general/Button/Button";

export interface PopoverContainerHandle {
    close(): void;
    open(): void;
}

export interface PopoverButtonProps {
    buttonVariant?: ExtendedButtonProps["variant"];
    buttonText?: React.ReactNode;
    buttonClass?: string;
    buttonActiveClass?: string;
    buttonSize?: "sm" | "lg";
    renderButton?: (isShown: boolean) => React.ReactNode;
}

export interface PopoverContainerProps extends PopoverButtonProps {
    trigger?: React.ReactNode;
    container?: HTMLElement;
    id: string;
    placement?: Placement;
    offset?: number;
    onHide?: () => void;
    onShow?: () => void;
    disabled?: boolean;
    popoverClass?: string;
    maxDropdownWidth?: number;
    showArrow?: boolean;
}

export const PopoverContainer = forwardRef<
    PopoverContainerHandle,
    PropsWithChildren<PopoverContainerProps>
>(
    (
        {
            id,
            buttonText,
            buttonVariant = "secondary",
            buttonClass,
            buttonActiveClass,
            buttonSize,
            children,
            placement = "bottom-start",
            offset,
            onHide,
            onShow,
            trigger,
            container,
            disabled,
            popoverClass,
            maxDropdownWidth,
            showArrow = true,
            renderButton,
        },
        ref,
    ) => {
        const popperConfig = useMemo(
            () => ({
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, offset ?? 0],
                        },
                    },
                ],
            }),
            [offset],
        );

        const [isShown, setIsShown] = useState(false);
        const triggerRef = useRef<HTMLButtonElement>(null);

        useImperativeHandle<any, PopoverContainerHandle>(ref, () => ({
            close: () => {
                if (isShown) {
                    setTimeout(() => triggerRef.current?.click(), 0);
                }
            },
            open: () => {
                if (!isShown) {
                    setTimeout(() => triggerRef.current?.click(), 0);
                }
            },
        }));

        const handleEnter = useCallback(() => {
            onShow?.();
            setIsShown(true);
        }, [onShow]);

        const handleExit = useCallback(() => {
            onHide?.();
            setIsShown(false);
        }, [onHide]);

        const popoverStyle = useMemo(
            () =>
                maxDropdownWidth ? { maxWidth: maxDropdownWidth } : undefined,
            [maxDropdownWidth],
        );

        return (
            <OverlayTrigger
                trigger={disabled ? [] : "click"}
                container={container}
                placement={placement}
                rootClose={true}
                onEnter={handleEnter}
                onExit={handleExit}
                popperConfig={popperConfig}
                flip={true}
                overlay={
                    <Popover
                        data-testid="popover-container-accounts-filter"
                        id={id}
                        className={classNames(
                            "popover-container",
                            popoverClass,
                        )}
                        style={popoverStyle}
                    >
                        <Popover.Content>{children}</Popover.Content>
                    </Popover>
                }
            >
                {trigger ? (
                    <span className="popover-trigger" ref={triggerRef}>
                        {trigger}
                    </span>
                ) : (
                    <Button
                        data-testid={`popover-button-${id}`}
                        variant={buttonVariant}
                        size={buttonSize}
                        className={classNames(
                            buttonClass,
                            isShown ? ["active", buttonActiveClass] : [],
                            { "no-pointer-events": disabled },
                        )}
                        ref={triggerRef}
                    >
                        {renderButton ? renderButton(isShown) : buttonText}
                        {!disabled &&
                            showArrow &&
                            (isShown ? <ArrowUpIcon /> : <ArrowDownIcon />)}
                    </Button>
                )}
            </OverlayTrigger>
        );
    },
);
