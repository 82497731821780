import { FinancialAccount } from "../../common/types/financialAccount";
import { FinancialAccountIcon } from "../financialAccount/FinancialAccountIcon";
import { Amount } from "../general/Amount/Amount";
import {
    StandardModal,
    StandardModalBody,
    StandardModalHeader,
} from "../general/Modal/Modal";
import { SelectableItem } from "../general/SelectableItem/SelectableItem";

interface Props {
    invoicingAccounts?: FinancialAccount[];
    show: boolean;
    onHide: () => void;
    onSelect: (account: FinancialAccount) => void;
}

export const AccountSelectModal: React.FC<Props> = ({
    show,
    onHide,
    invoicingAccounts,
    onSelect,
}) => (
    <StandardModal show={show} onHide={onHide}>
        <StandardModalHeader>Select account</StandardModalHeader>

        <StandardModalBody>
            {invoicingAccounts?.map((account) => (
                <SelectableItem
                    key={account.id}
                    title={account.name}
                    onSelect={() => onSelect(account)}
                    icon={<FinancialAccountIcon account={account} />}
                    description={
                        account.balance && (
                            <Amount
                                amount={account.balance}
                                separateCurrencySign
                            />
                        )
                    }
                >
                    <span className="fas fa-chevron-right" />
                </SelectableItem>
            ))}
        </StandardModalBody>
    </StandardModal>
);
