import React from "react";
import { FinancialConnection } from "../../common/types/financialConnection";
import { isPlaidConnection } from "../../common/helpers/financialConnection";
import { BrokenFinancialConnectionLink } from "../financialAccount/BrokenFinancialConnectionLink";
import { BrokenPlaidConnectionLink } from "../plaid/BrokenPlaidConnectionLink";
import { PlaidAccountMetadata } from "../../common/helpers/plaid";
import { IntegrationAccount } from "../../common/types/integrationAccount";

interface Props {
    connection: FinancialConnection;
    integrationAccount: IntegrationAccount;
    balanceSyncedAt: Date | null;
}

export const BrokenConnectionLink: React.FC<Props> = ({
    connection,
    integrationAccount,
    balanceSyncedAt,
}) =>
    isPlaidConnection(connection) ? (
        <BrokenPlaidConnectionLink
            connection={connection}
            churnedAccount={
                integrationAccount.metadata?.[
                    PlaidAccountMetadata.ACCOUNT_CHURN
                ]
                    ? integrationAccount
                    : undefined
            }
        />
    ) : (
        <BrokenFinancialConnectionLink
            connection={connection}
            balanceSyncedAt={balanceSyncedAt}
        />
    );
