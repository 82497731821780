import React from "react";
import { chunk } from "lodash";
import { PayrollTotals } from "../../../common/types/payroll";
import css from "./PayrollDetails.module.scss";
import { PayrollDetailsTotalItem } from "./PayrollDetailsTotalItem";
interface Props {
    totals?: PayrollTotals | null;
}

const totalsOrder: Array<keyof PayrollTotals> = [
    "companyDebit",
    "netPayDebit",
    "taxDebit",
    "reimbursementDebit",
    "childSupportDebit",
    "reimbursements",
    "grossPay",
    "netPay",
    "employeeBonuses",
    "employeeCommissions",
    "employeeCashTips",
    "employeePaycheckTips",
    "additionalEarnings",
    "ownersDraw",
    "employerTaxes",
    "benefits",
    "employeeTaxes",
    "employeeBenefitsDeductions",
    "checkAmount",
    "deferredPayrollTaxes",
];
export const PayrollDetails: React.FC<Props> = ({ totals }) => (
    <div className={css.payrollDetails}>
        <table className="table table-sm table-borderless">
            <tbody>
                {chunk(totalsOrder, 2).map(([itemLeftCol, itemRightCol]) => (
                    <tr key={itemLeftCol + "_" + itemRightCol}>
                        <PayrollDetailsTotalItem
                            totals={totals}
                            total={itemLeftCol}
                        />
                        <PayrollDetailsTotalItem
                            totals={totals}
                            total={itemRightCol}
                        />
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);
