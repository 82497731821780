import { createContext, useContext, useMemo } from "react";
import {
    GridTable,
    GridTableHeader,
    GridTableRow,
} from "../GridTable/GridTable";

interface SimpleTableProps {
    className?: string;
    columns: Array<{
        key: string;
        label: string;
    }>;
    rows: Array<{
        id: string;
        cells: Array<{
            value: string | React.ReactNode;
            onClick?: () => void;
        }>;
        onClick?: () => void;
    }>;
    columnBorders?: boolean;
}

export const SimpleTable = ({
    className,
    columns,
    rows,
    columnBorders,
}: SimpleTableProps) => {
    const context = useMemo(
        () => ({ columns, columnBorders }),
        [columns, columnBorders],
    );

    return (
        <SimpleTableContext.Provider value={context}>
            <GridTable className={className}>
                <SimpleTableHeader />
                {rows.map((row) => (
                    <SimpleTableRow
                        onClick={row.onClick}
                        key={row.id}
                        cells={row.cells}
                    />
                ))}
            </GridTable>
        </SimpleTableContext.Provider>
    );
};

interface SimpleTableContextType {
    columns: Array<{
        key: string;
        label: string;
    }>;
    columnBorders?: boolean;
}

const SimpleTableContext = createContext<SimpleTableContextType>({
    columns: [],
});

const SimpleTableHeader = () => {
    const { columns, columnBorders } = useContext(SimpleTableContext);
    return <GridTableHeader columns={columns} columnBorders={columnBorders} />;
};

interface SimpleTableRowProps {
    cells: Array<{
        value: string | React.ReactNode;
    }>;
    onClick?: () => void;
}

const SimpleTableRow = ({ cells, onClick }: SimpleTableRowProps) => {
    const { columns, columnBorders } = useContext(SimpleTableContext);
    return (
        <GridTableRow
            cells={cells.map((cell, index) => ({
                key: columns[index].key,
                ...cell,
            }))}
            onClick={onClick}
            columnBorders={columnBorders}
        />
    );
};
