export const RECEIPT_TYPES = [
    "image/png",
    "image/jpeg",
    "application/pdf",
] as const;
export const SPREADSHEET_TYPES = [
    "text/csv",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
    "application/vnd.ms-excel.sheet.macroEnabled.12", // xlsm
] as const;
export const WORD_TYPES = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
] as const;

export const ZIP_ARCHIVE_TYPE = "application/zip";

export const ARCHIVE_TYPES = [ZIP_ARCHIVE_TYPE] as const;

export const FINANCIAL_DOCUMENT_TYPES = [
    ...RECEIPT_TYPES,
    ...SPREADSHEET_TYPES,
    ...WORD_TYPES,
];

export type FinancialDocumentType = (typeof FINANCIAL_DOCUMENT_TYPES)[number];

export const MAX_FINANCIAL_DOCUMENT_FILE_SIZE_MB = 20;
export const MAX_FINANCIAL_DOCUMENT_FILE_SIZE_BYTES =
    MAX_FINANCIAL_DOCUMENT_FILE_SIZE_MB * 1024 * 1024;
export const MAX_ARCHIVE_FILE_SIZE_MB = 75;
export const MAX_ARCHIVE_FILE_SIZE_BYTES =
    MAX_ARCHIVE_FILE_SIZE_MB * 1024 * 1024;

export const MAX_ARCHIVE_FILE_S3_SIZE_MB = 2048;
export const MAX_ARCHIVE_FILE_S3_SIZE_BYTES =
    MAX_ARCHIVE_FILE_S3_SIZE_MB * 1024 * 1024;
