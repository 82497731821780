import React from "react";
import classNames from "classnames";
import {
    GridTable,
    GridTableFallbacks,
    GridTableHeader,
} from "../../general/Tables/GridTable/GridTable";
import { GetCounterpartiesResponseRowDto } from "../../../common/dto/counterparty/get-counterparties-response.dto";
import { CounterpartyDirection } from "../../../common/helpers/counterparties";
import { CounterpartySort } from "../../../common/constants/counterparty";
import { SortableHeader } from "../../general/SortableHeader/SortableHeader";
import styles from "./CounterpartiesList.module.scss";
import { CounterpartiesListItem } from "./CounterpartiesListItem";
import { useCategoriesForDirectoryDrilldown } from "./useCategoriesForDirectoryDrilldown";

interface Props {
    data?: GetCounterpartiesResponseRowDto[];
    direction?: CounterpartyDirection;
    sort: CounterpartySort;
    setSort: (sort: CounterpartySort) => void;
    isLoading?: boolean;
}

export const CounterpartiesList: React.FC<Props> = ({
    data,
    direction,
    sort,
    setSort,
    isLoading,
}) => {
    const showRevenue =
        !direction || direction === CounterpartyDirection.CUSTOMER;
    const showSpend = !direction || direction === CounterpartyDirection.VENDOR;
    const categoriesForDrilldown = useCategoriesForDirectoryDrilldown();

    return (
        <GridTable
            className={classNames({
                [styles.allTable]: !direction,
                [styles.customersTable]:
                    direction === CounterpartyDirection.CUSTOMER,
                [styles.vendorsTable]:
                    direction === CounterpartyDirection.VENDOR,
            })}
        >
            <GridTableHeader>
                <div>
                    <SortableHeader
                        sort={sort}
                        asc={CounterpartySort.NAME_ASC}
                        desc={CounterpartySort.NAME_DESC}
                        onChange={setSort}
                        defaultOrder="asc"
                    >
                        Name
                    </SortableHeader>
                </div>
                <div>Category</div>
                <div>
                    <SortableHeader
                        sort={sort}
                        asc={CounterpartySort.NUMBER_OF_TRANSACTIONS_ASC}
                        desc={CounterpartySort.NUMBER_OF_TRANSACTIONS_DESC}
                        onChange={setSort}
                        defaultOrder="desc"
                    >
                        #
                    </SortableHeader>
                </div>
                {showRevenue && (
                    <div>
                        <SortableHeader
                            sort={sort}
                            asc={CounterpartySort.REVENUE_ASC}
                            desc={CounterpartySort.REVENUE_DESC}
                            onChange={setSort}
                            defaultOrder="desc"
                        >
                            Revenue
                        </SortableHeader>
                    </div>
                )}
                {showSpend && (
                    <div>
                        <SortableHeader
                            sort={sort}
                            asc={CounterpartySort.SPEND_ASC}
                            desc={CounterpartySort.SPEND_DESC}
                            onChange={setSort}
                            defaultOrder="desc"
                        >
                            Spend
                        </SortableHeader>
                    </div>
                )}
            </GridTableHeader>
            {data?.map((row) => (
                <CounterpartiesListItem
                    key={row.counterparty.id}
                    row={row}
                    showRevenue={showRevenue}
                    showSpend={showSpend}
                    categoriesForDrilldown={categoriesForDrilldown}
                />
            ))}
            <GridTableFallbacks
                dataCount={data?.length ?? 0}
                loading={isLoading}
                header="No entries found in Directory"
            />
        </GridTable>
    );
};
