import React from "react";
import { AuditLog } from "../../../../common/types/auditLog";
import { timeAgo } from "../../../../helpers/date";
import { TransactionDetailsHistoryLogContent } from "./TransactionDetailsHistoryLogContent";

interface Props {
    log: AuditLog;
}

export const TransactionDetailsHistoryLog: React.FC<Props> = ({ log }) => (
    <div
        className="transaction-details-history__log"
        data-testid="transaction-details-history-log"
    >
        <main>
            <TransactionDetailsHistoryLogContent log={log} />
        </main>{" "}
        <aside>{timeAgo(log.date)}</aside>
    </div>
);
