import {
    AuditLog,
    AuditLogAuthorMetadata,
    AuditLogAuthorType,
} from "../../../../common/types/auditLog";
import { useUser } from "../../../../hooks/useUser";

export function useAuditLogAuthorLabel(log: AuditLog): string {
    const user = useUser();

    switch (log.authorType) {
        case AuditLogAuthorType.USER:
            return log.userId === user.id
                ? "You"
                : (log.metadata as AuditLogAuthorMetadata).author;
        default:
            return "Kick";
    }
}
