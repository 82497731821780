import React from "react";
import { Dropdown } from "react-bootstrap";
import { PlaidConnectionWithAccounts } from "../../../../common/types/plaidConnection";
import { getEntityPaneKey } from "../../../settings/lib";
import { useSettings } from "../../../settings/SettingsProvider";
import { ManagementDropdown } from "../../../general/ManagementDropdown/ManagementDropdown";

export interface PlaidConnectionSettingsDropdownProps {
    busy?: boolean;
    connection: PlaidConnectionWithAccounts;
    onRemove(): void;
}

export const ExternalConnectionSettingsDropdown: React.FC<
    PlaidConnectionSettingsDropdownProps
> = ({ busy, connection, onRemove }) => {
    const { open } = useSettings();
    const firstAccount = connection.accounts[0];
    const firstAccountEntity = firstAccount?.financialAccount.entity;

    return (
        <ManagementDropdown
            id={`connection-dropdown__${connection.id}`}
            toggleDisabled={busy}
        >
            {firstAccount && firstAccountEntity && (
                <Dropdown.Item
                    onClick={() =>
                        open([
                            getEntityPaneKey("accounts", firstAccountEntity),
                            firstAccount.id.toString(),
                        ])
                    }
                >
                    Settings
                </Dropdown.Item>
            )}
            <Dropdown.Item onClick={onRemove} className="text-danger">
                Remove integration
            </Dropdown.Item>
        </ManagementDropdown>
    );
};
