import {
    Dropdown,
    DropdownProps,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import React from "react";
import { timeAgo } from "../../helpers/date";
import { AccountTypeChange } from "../settings/Accounts/AccountDetails/AccountTypeChange";
import { CaptureClicks } from "../general/CaptureClicks";
import { getEntityPaneKey } from "../settings/lib";
import { useSettings } from "../settings/SettingsProvider";
import { ManagementDropdown } from "../general/ManagementDropdown/ManagementDropdown";
import { FinancialAccount } from "../../common/types/financialAccount";
import { useFinancialAccountRemovalMutation } from "../../mutations/financialAccount";
import { FinancialConnection } from "../../common/types/financialConnection";
import { FinancialConnectionProviderLabel } from "../../common/constants";
import { ACCOUNTS_POSSIBLY_PERSONAL } from "../../common/helpers/financialAccount";
import { FinancialAccountStateManager } from "./FinancialAccountStateManager";

interface Props {
    connection?: FinancialConnection;
    account: FinancialAccount;
    onToggle?: DropdownProps["onToggle"];
    allowRemove?: boolean;
}

export const FinancialAccountManagementDropdown: React.FC<Props> = ({
    account,
    connection,
    allowRemove,
    onToggle,
}) => {
    const { open } = useSettings();

    const removeAccount = useFinancialAccountRemovalMutation(account);
    const entity = account.entity;
    const canBePersonal = ACCOUNTS_POSSIBLY_PERSONAL[account.sourceType];

    return (
        <ManagementDropdown
            id={`account-dropdown__${account.id}`}
            onToggle={onToggle}
            className="external-bank-account-management-dropdown"
        >
            {entity && (
                <>
                    <Dropdown.Item
                        as="button"
                        onClick={() =>
                            open([
                                getEntityPaneKey("accounts", entity),
                                account.id.toString(),
                            ])
                        }
                    >
                        Settings
                    </Dropdown.Item>
                    <Dropdown.Divider />
                </>
            )}
            {canBePersonal ? (
                <Dropdown.Item as="div">
                    Account type
                    <CaptureClicks className="ml-auto">
                        <AccountTypeChange
                            account={account}
                            connection={connection}
                            showLabel={false}
                        />
                    </CaptureClicks>
                </Dropdown.Item>
            ) : null}

            {connection && (
                <Dropdown.Item as="div">
                    <CaptureClicks className="w-100">
                        <FinancialAccountStateManager
                            account={account}
                            connection={connection}
                            tooltip={
                                <Tooltip id={`state-manager-${account.id}`}>
                                    {connection.error ? (
                                        <>
                                            <p className="mb-2">
                                                This account has been
                                                disconnected. <br />
                                                {!!account.balanceSyncedAt && (
                                                    <>
                                                        Last sync{" "}
                                                        {timeAgo(
                                                            account.balanceSyncedAt,
                                                        )}
                                                        .
                                                    </>
                                                )}
                                            </p>
                                            Click to fix it.
                                        </>
                                    ) : (
                                        <>
                                            Disconnecting{" "}
                                            {
                                                FinancialConnectionProviderLabel[
                                                    connection
                                                        .connectionProvider
                                                ]
                                            }{" "}
                                            won't bring your new transactions
                                            into the platform. You can choose if
                                            you want to keep older transactions
                                            or delete them.
                                        </>
                                    )}
                                </Tooltip>
                            }
                        />
                    </CaptureClicks>
                </Dropdown.Item>
            )}
            {allowRemove && (
                <>
                    <Dropdown.Divider />
                    <OverlayTrigger
                        placement="left"
                        overlay={
                            <Tooltip id={`state-manager-${account.id}`}>
                                If you remove this account you will lose all
                                previous transactions.
                            </Tooltip>
                        }
                    >
                        <Dropdown.Item
                            as="button"
                            className="text-danger"
                            onClick={() => removeAccount.mutate()}
                        >
                            Remove account
                        </Dropdown.Item>
                    </OverlayTrigger>
                </>
            )}
        </ManagementDropdown>
    );
};
