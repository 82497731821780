import { keyBy } from "lodash";
import { useMemo } from "react";
import { Taxonomy } from "../common/categories";
import { Category } from "../common/types/category";
import { CategoryStructure, useCategories } from "./useCategories";

export function useStandardCategories(): Record<Taxonomy, Category> {
    const categories = useCategories({
        onlyStandardCategories: true,
        structure: CategoryStructure.FLAT,
    });

    return useMemo(
        () =>
            keyBy(categories, (c) => c.taxonomy) as Record<Taxonomy, Category>,
        [categories],
    );
}
