import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { PlaidConnectionWithAccounts } from "../../../common/types/plaidConnection";
import { useFinancialAccountEntitySelection } from "../../../hooks/useFinancialAccountEntitySelection";
import { AccountsConnectionProvider } from "../../financialAccount/AccountConnectionWidget/AccountsConnectionContext";
import { isValidConnection } from "../../financialAccount/AccountConnectionWidget/helpers";
import { useUser } from "../../../hooks/useUser";
import "./PlaidAccountsConnectedModal.scss";
import { InvalidPlaidAccount } from "../../../common/dto/plaid/plaid-common";
import { pluralize } from "../../../common/helpers/string";
import { KeyboardReturnIcon } from "../../../icons";
import { useKeyboardCommands } from "../../../hooks/keyboard/useKeyboardCommands";
import { Button } from "../../general/Button/Button";
import { KeyboardShortcut } from "../../general/KeyboardShortcut/KeyboardShortcut";
import {
    StandardModal,
    StandardModalBody,
    StandardModalHeader,
} from "../../general/Modal/Modal";
import { PlaidAccountsConnectedModalBody } from "./PlaidAccountsConnectedModalBody";

export interface PlaidAccountsConnectedModalProps {
    show: boolean;
    connection: PlaidConnectionWithAccounts;
    foreign: InvalidPlaidAccount[] | null;
    connectionSaved: boolean;
    onHide(): void;
}

export const PlaidAccountsConnectedModal: React.FC<
    PlaidAccountsConnectedModalProps
> = ({ show, connection, foreign, connectionSaved, onHide }) => {
    const { showPersonalEntitySelection, showBusinessEntitySelection } =
        useFinancialAccountEntitySelection();
    const showEntitySelection =
        showPersonalEntitySelection || showBusinessEntitySelection;
    const [submitted, setSubmitted] = useState<boolean>(false);
    const user = useUser();

    const handleSubmit = useCallback(() => {
        if (
            (connection && isValidConnection(connection)) ||
            submitted ||
            !user.onboardingComplete
        ) {
            setSubmitted(false);
            onHide();
        } else {
            setSubmitted(true);
        }
    }, [connection, onHide, submitted, user.onboardingComplete]);

    const title = connectionSaved ? (
        <>
            Confirm whether your{" "}
            {pluralize("account", connection.accounts.length)}{" "}
            {connection.accounts.length > 1 ? "are" : "is"} Personal or Business
        </>
    ) : (
        <>Not able to connect selected accounts</>
    );

    useKeyboardCommands({
        commands: [
            {
                key: "Enter",
                requiresCtrlOrMeta: false,
                callback: () => {
                    handleSubmit();
                },
            },
        ],
    });

    return (
        <AccountsConnectionProvider
            showAccountTypeWarning={submitted && user.onboardingComplete}
        >
            <StandardModal
                show={show}
                onHide={onHide}
                size="lg"
                backdrop={connection ? true : "static"}
                keyboard={!!connection}
                className={classNames("accounts-connected-modal", {
                    "accounts-connected-modal--with-entities":
                        connection && showEntitySelection && connectionSaved,
                })}
            >
                <>
                    <StandardModalHeader>{title}</StandardModalHeader>
                    <StandardModalBody>
                        <PlaidAccountsConnectedModalBody
                            connection={connection}
                            connectionSaved={connectionSaved}
                            foreign={foreign}
                        />

                        <footer className="d-flex align-items-center justify-content-end">
                            <Button onClick={handleSubmit} size="lg">
                                Continue
                                <KeyboardShortcut square>
                                    <KeyboardReturnIcon />
                                </KeyboardShortcut>
                            </Button>
                        </footer>
                    </StandardModalBody>
                </>
            </StandardModal>
        </AccountsConnectionProvider>
    );
};
