import React from "react";
import { Button } from "../../general/Button/Button";
import { pluralize } from "../../../common/helpers/string";
import { CounterpartyVerifiedToastData } from "../../general/ToastMessages/lib/counterpartyVerified";
import { useCounterpartyTransactionsOpener } from "../../../hooks/useCounterpartyTransactionsOpener";
import styles from "./CounterpartyVerifiedToast.module.scss";

type Props = CounterpartyVerifiedToastData;

export const CounterpartyVerifiedToast: React.FC<Props> = ({
    counterparty,
    transactionsNumber,
}) => {
    const transactionsOpener = useCounterpartyTransactionsOpener(counterparty, {
        includeDateFilter: false,
    });

    return (
        <div className={styles.message}>
            <p>
                <strong>{counterparty.name}</strong> was assigned to{" "}
                {transactionsNumber}{" "}
                {pluralize("transaction", transactionsNumber)}
            </p>

            <footer className={styles.footer}>
                <Button
                    variant="tertiary"
                    size="sm"
                    className={styles.review}
                    onClick={transactionsOpener}
                >
                    Review
                </Button>
            </footer>
        </div>
    );
};
