import React, { useCallback, useEffect, useState } from "react";
import { NumberParam, useQueryParam } from "use-query-params";
import { isFunction } from "lodash";
import { useCashFlowPageFiltersCache } from "../../hooks/useCashFlowPageFiltersCache";
import { useEntities } from "../../hooks/useEntities";
import { TablePageHeader } from "../pageHeader/TablePageHeader";
import { FilterTabs } from "../pageHeader/filters/lib";
import { CashFlowContextProvider } from "./CashFlowContextProvider";
import "./CashFlowPage.scss";
import { CashFlowPageFiltersProps } from "./CashFlowPageFilters/CashFlowPageFilters";
import { CashFlowReport } from "./CashFlowReport";
import { useDefaultReportsFilters } from "./useDefaultReportsFilters";
import { ReportBillingCta } from "./ReportBillingCta/ReportBillingCta";
import { ReportsPageFilters } from "./filters/ReportsPageFilters";

export const CashFlowPage: React.FC = () => {
    const defaultFilters = useDefaultReportsFilters();
    const [cachedFilters, setCachedFilters] = useCashFlowPageFiltersCache();
    const [entityIdParam] = useQueryParam("entityId", NumberParam);
    const entities = useEntities();
    const [filters, setFilters] = useState({
        ...defaultFilters,
        ...cachedFilters,
    });

    const onFiltersChange = useCallback<CashFlowPageFiltersProps["onChange"]>(
        (newFilters) => {
            setFilters((oldFilters) => {
                const f = isFunction(newFilters)
                    ? newFilters(oldFilters)
                    : { ...oldFilters, ...newFilters };
                setCachedFilters(f);
                return f;
            });
        },
        [setCachedFilters],
    );

    useEffect(() => {
        const entity =
            entityIdParam && entities.find((e) => e.id === entityIdParam);

        if (!entity) {
            return;
        }

        onFiltersChange((oldFilters) => {
            if (
                oldFilters.entityIds.length !== 1 ||
                oldFilters.entityIds[0] !== entity.id
            ) {
                return {
                    ...oldFilters,
                    entityIds: [entity.id],
                };
            }

            return oldFilters;
        });
    }, [entityIdParam, onFiltersChange, entities]);

    return (
        <main className="reports">
            <CashFlowContextProvider filters={filters}>
                <TablePageHeader
                    filters={filters}
                    onFiltersChange={onFiltersChange}
                    availableFilters={[
                        FilterTabs.CATEGORY,
                        FilterTabs.ACCOUNT,
                        FilterTabs.CLASS,
                    ]}
                >
                    <ReportsPageFilters
                        filters={filters}
                        onChange={onFiltersChange}
                    />
                </TablePageHeader>

                <ReportBillingCta />

                <CashFlowReport
                    resetFilters={() => setFilters(defaultFilters)}
                    onChange={onFiltersChange}
                />
            </CashFlowContextProvider>
        </main>
    );
};
