import { FinancialDocumentDto } from "../common/dto/financialDocument/financial-document.dto";
import { FetchFinancialDocumentsResponseDto } from "../common/dto/financialDocument/fetch-financial-documents-response.dto";
import { FetchFinancialDocumentsDto } from "../common/dto/financialDocument/fetch-financial-documents.dto";
import { UploadFinancialDocumentDto } from "../common/dto/financialDocument/upload-financial-document.dto";
import { ChangeEntityForFinancialDocumentDto } from "../common/dto/financialDocument/change-entity-for-financial-document.dto";
import { FinancialDocumentsBulkActionDto } from "../common/dto/financialDocument/bulk/financial-documents-bulk-action.dto";
import { CreateLinkFinancialDocumentAndTransactionDto } from "../common/dto/financialDocument/create-link-financial-document-and-transaction.dto";
import { FinancialDocument } from "../common/types/financialDocument";
import { AccountInfoExtractionInputDto } from "../common/dto/financialDocument/account-info-extraction-input.dto";
import { AccountInfoExtractionResponseDto } from "../common/dto/financialDocument/account-info-extraction-response.dto";
import { backendClient, unwrapResponse } from "./backendClient";

export async function queryFinancialDocuments(
    params: FetchFinancialDocumentsDto,
) {
    const { data, ...pagedResponse } = unwrapResponse(
        await backendClient.get<FetchFinancialDocumentsResponseDto>(
            "/financial-document",
            {
                params,
                paramsSerializer: { indexes: true },
            },
        ),
    );

    return {
        data: data.map(FinancialDocumentDto.deserialize),
        ...pagedResponse,
    };
}

interface GetFinancialDocumentFileResponse {
    data: ArrayBuffer;
    contentType: string;
}

export async function fetchFinancialDocument(id: number) {
    const response = unwrapResponse(
        await backendClient.get(`/financial-document/${id}`),
    );

    return FinancialDocumentDto.deserialize(response);
}

export async function getFinancialDocumentFile(
    id: number,
): Promise<GetFinancialDocumentFileResponse> {
    const response = await backendClient.get(`/financial-document/${id}/file`, {
        responseType: "arraybuffer",
    });

    return {
        data: unwrapResponse(response),
        contentType: response.headers["content-type"],
    };
}

export async function downloadDocuments(workspaceId: string) {
    await backendClient.get(`/financial-document/download/${workspaceId}`);
}

export async function uploadDocument(data: UploadFinancialDocumentDto) {
    const response: FinancialDocumentDto[] = unwrapResponse(
        await backendClient.post("/financial-document", data),
    );

    return response.map((fd) => FinancialDocumentDto.deserialize(fd));
}

export async function detachDocumentFromFinancialTransaction(
    financialDocumentId: number,
    matchId: number,
) {
    const response = unwrapResponse(
        await backendClient.post(
            `/financial-document/${financialDocumentId}/detach/${matchId}`,
        ),
    );

    return FinancialDocumentDto.deserialize(response);
}

export async function changeEntityForFinancialDocument(
    data: ChangeEntityForFinancialDocumentDto,
) {
    const response = unwrapResponse(
        await backendClient.post(`/financial-document/change-entity`, data),
    );

    return FinancialDocumentDto.deserialize(response);
}

export async function removeFinancialDocument(id: number) {
    await backendClient.delete(`/financial-document/${id}`);
}

export async function bulkAction(
    payload: FinancialDocumentsBulkActionDto,
): Promise<void> {
    await backendClient.post(`/financial-document/bulk-action`, payload);
}

export async function createLinkFinancialDocumentToFinancialTransaction(
    payload: CreateLinkFinancialDocumentAndTransactionDto,
): Promise<FinancialDocument> {
    const response = unwrapResponse(
        await backendClient.post(`/financial-document/link`, payload),
    );

    return FinancialDocumentDto.deserialize(response);
}

export async function extractAccountInfo(
    payload: AccountInfoExtractionInputDto,
) {
    const response = unwrapResponse(
        await backendClient.post(
            `/financial-document/extract-account-info`,
            payload,
        ),
    );

    return response as AccountInfoExtractionResponseDto;
}
