import React, { useMemo } from "react";
import {
    CheckboxButtonGroup,
    CheckboxButtonGroupProps,
} from "../general/RadioButtonGroup/CheckboxButtonGroup";
import { BusinessIcon, HomeIcon } from "../../icons";

interface Props {
    showIcon?: boolean;
    showLabel?: boolean;
    value: boolean | null;
    loading?: boolean;
    disabled?: boolean;
    onChange?: (value: boolean) => void;
    size?: CheckboxButtonGroupProps["size"];
}

export const FinancialAccountTypeSelect: React.FC<Props> = ({
    showIcon = false,
    showLabel = true,
    value,
    ...rest
}) => {
    const options = useMemo(
        () => [
            {
                value: true,
                label: (
                    <>
                        {showIcon ? <BusinessIcon /> : null}
                        {showLabel ? "Business" : null}
                    </>
                ),
            },
            {
                value: false,
                label: (
                    <>
                        {showIcon ? <HomeIcon /> : null}
                        {showLabel ? "Personal" : null}
                    </>
                ),
            },
        ],
        [showIcon, showLabel],
    );

    return (
        <CheckboxButtonGroup
            options={options}
            value={value ?? undefined}
            {...rest}
        />
    );
};
