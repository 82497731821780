import React, { useMemo } from "react";
import "./ObjectIcon.scss";
import classNames from "classnames";
import { ChildrenProps } from "../../../types";

export type IconSize = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
export interface ObjectIconProps extends ChildrenProps {
    name?: string;
    defaultColor: string;
    iconUrl?: string;
    size: IconSize;
    square?: boolean;
    rhombus?: boolean;
    dynamic?: boolean;
    active?: boolean;
    border?: string;
    className?: string;
}

export const ObjectIcon: React.FC<ObjectIconProps> = ({
    name,
    defaultColor,
    iconUrl,
    size,
    square,
    rhombus,
    dynamic,
    active,
    children,
    border,
    className,
}) => {
    const style = useMemo(
        () => ({
            backgroundColor: defaultColor,
            backgroundImage: iconUrl ? `url(${iconUrl})` : undefined,
            outline: rhombus ? undefined : border,
        }),
        [defaultColor, iconUrl, border, rhombus],
    );

    return (
        <div
            className={classNames(
                `object-icon object-icon--${size}`,
                {
                    "object-icon--square": square,
                    "object-icon--rhombus": rhombus,
                    "object-icon--dynamic": dynamic,
                    "object-icon--active": active,
                },
                className,
            )}
            style={rhombus ? undefined : style}
        >
            {rhombus && (
                <div
                    className="object-icon__rhombus"
                    style={{
                        ...style,
                        border: rhombus ? `1px solid ${border}` : undefined,
                    }}
                />
            )}
            <span className="object-icon__content">
                {children || (!iconUrl && name?.charAt(0).toUpperCase())}
            </span>
        </div>
    );
};
