import React, { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";
import { FINANCIAL_DOCUMENTS_PAGE_SIZE } from "../../../../documents/useFinancialDocumentsQuery";
import { useFinancialDocumentFilters } from "../../../../documents/useFinancialDocumentFilters";
import { Transaction } from "../../../../../common/types/transaction";
import { EagerLoaded } from "../../../../../common/types/base/orm";
import { financialDocumentQueryKeys } from "../../../../documents/lib";
import { queryFinancialDocuments } from "../../../../../lib/financialDocument";
import documentStyles from "../../../../documents/DocumentsPage.module.scss";
import { Loader } from "../../../../general/Loader";
import { DocumentsEmptyState } from "../../../../documents/DocumentsEmptyState";
import { FinancialDocument } from "../../../../../common/types/financialDocument";
import { useWorkspaceContext } from "../../../../../state/workspaceContext";
import { Button } from "../../../../general/Button/Button";
import { TablePageHeader } from "../../../../pageHeader/TablePageHeader";
import { FilterTabs } from "../../../../pageHeader/filters/lib";
import styles from "./MatchingView.module.scss";
import { MatchingViewDocumentRow } from "./MatchingViewDocumentRow";

interface Props {
    transaction: EagerLoaded<Transaction, "transactionMatches">;
    onFinancialDocumentSelected(
        financialDocument: FinancialDocument | null,
    ): void;
}

export const MatchingView: React.FC<Props> = ({
    transaction,
    onFinancialDocumentSelected,
}) => {
    const [page, setPage] = useState<number>(1);
    const { activeWorkspaceKey } = useWorkspaceContext();
    const onFiltersChange = useCallback(() => setPage(1), []);
    const { filters, resetFilters, handleFiltersChange } =
        useFinancialDocumentFilters(onFiltersChange);

    const currentQueryParams = {
        page: page - 1,
        limit: FINANCIAL_DOCUMENTS_PAGE_SIZE,
        filters,
        workspace: activeWorkspaceKey,
    };

    const {
        data: financialDocumentsResponse,
        isLoading,
        hasNextPage,
        fetchNextPage,
    } = useInfiniteQuery({
        queryKey: financialDocumentQueryKeys.list(currentQueryParams),
        queryFn: ({ pageParam = 0 }) =>
            queryFinancialDocuments({
                ...currentQueryParams,
                page: pageParam,
            }),
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.pageCount > allPages.length) {
                return allPages.length;
            }
        },
        initialPageParam: 0,
    });

    let emptyState = null;

    if (isLoading || !financialDocumentsResponse?.pages?.length) {
        emptyState = (
            <tbody>
                <tr>
                    <td colSpan={99} className={documentStyles.empty}>
                        <Loader />
                    </td>
                </tr>
            </tbody>
        );
    } else if (!financialDocumentsResponse.pages[0].total) {
        emptyState = (
            <tbody>
                <tr>
                    <td colSpan={99} className={documentStyles.empty}>
                        <DocumentsEmptyState header="The filters don’t match any of the documents.">
                            <Button
                                size="sm"
                                variant="tertiary"
                                onClick={resetFilters}
                            >
                                Clear filters
                            </Button>
                        </DocumentsEmptyState>
                    </td>
                </tr>
            </tbody>
        );
    }

    const { inView, ref } = useInView({
        initialInView: true,
    });

    useEffect(() => {
        if (inView && hasNextPage) {
            void fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, inView]);

    return (
        <main className={styles.container}>
            <TablePageHeader
                search={filters.search}
                onSearchChange={(value) => {
                    handleFiltersChange({ search: value ?? "" });
                }}
                filters={filters}
                onFiltersChange={handleFiltersChange}
                availableFilters={[FilterTabs.DATE, FilterTabs.DOCUMENT_STATUS]}
            />

            <section className={styles.documents}>
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            <th className={styles.dateColumn}>Upload date</th>
                            <th className={styles.wide}>Name</th>
                            <th className={styles.actionsColumn}></th>
                        </tr>
                    </thead>

                    {emptyState ?? (
                        <tbody>
                            {financialDocumentsResponse!.pages
                                .flatMap((p) => [...p.data])
                                .map((doc) => (
                                    <MatchingViewDocumentRow
                                        key={doc.id}
                                        financialTransaction={transaction}
                                        financialDocument={doc}
                                        onSelected={() =>
                                            onFinancialDocumentSelected(doc)
                                        }
                                        onAttached={() =>
                                            onFinancialDocumentSelected(null)
                                        }
                                    />
                                ))}
                        </tbody>
                    )}
                </table>
                <footer className={styles.observerFooter} ref={ref}></footer>
            </section>
        </main>
    );
};
