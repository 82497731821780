import React from "react";
import { clamp } from "lodash";
import classNames from "classnames";
import { SettingsSection } from "../../SettingsSection";
import { Card } from "../../../general/Card/Card";
import { useBillingStatus } from "../../../../hooks/useBillingStatus";
import { FLAT_RATE_PLANS } from "../../../../common/flatRateBilling";
import {
    currencyFormatterFractionsIfNeeded,
    currencyFormatterNoFractions,
    currencyFormatterShort,
} from "../../../../common/helpers/currency";
import { fromIntToMonetary } from "../../../../common/helpers/monetary";
import { useSettings } from "../../SettingsProvider";
import { Button } from "../../../general/Button/Button";
import { ThunderboltIcon } from "../../../../icons";
import { CustomProgressBar } from "../../../general/CustomProgressBar/CustomProgressBar";
import { EndTrialAction } from "../../../billing/AccessBlockedPage/EndTrialAction";
import { getBillingIntervalAdjective } from "../../../../common/billing";
import { PlanManagementProvider } from "../../../billing/PlanManagement/PlanManagementProvider";
import { CurrentPlanHeader } from "./CurrentPlanHeader";
import { CurrentPlanFooter } from "./CurrentPlanFooter";
import styles from "./FlatRateBilling.module.scss";

export const CurrentPlan: React.FC = () => {
    const { open } = useSettings();
    const {
        currentPlan,
        plans,
        trialAvailable,
        totalUsage,
        isFetched,
        isTrialing,
        subscriptionDetails,
    } = useBillingStatus();

    if (!isFetched) {
        return null;
    }

    const currentPlanData = plans.find((plan) => plan.plan === currentPlan)!;
    const maxUsage = currentPlanData.usageLimit;

    const isAtOrOverLimit = totalUsage >= maxUsage;
    const isOnFreePlan = currentPlan === FLAT_RATE_PLANS.FREE;
    const clampedUsage = clamp(totalUsage, 0, maxUsage);

    const monthlyAmount =
        subscriptionDetails?.monthlyPriceCents ??
        currentPlanData.monthlyAmountCents;

    const discountedMonthlyAmount =
        subscriptionDetails?.discountedMonthlyAmountCents ?? monthlyAmount;

    const intervalAmount =
        subscriptionDetails?.intervalAmountCents ??
        currentPlanData.intervalAmountCents;

    const discountedIntervalAmount =
        subscriptionDetails?.discountedIntervalAmountCents ?? monthlyAmount;

    const isEnterpriseEligible = ![
        FLAT_RATE_PLANS.ENTERPRISE,
        FLAT_RATE_PLANS.FREE,
    ].includes(currentPlan);

    return (
        <SettingsSection heading="Current Plan">
            <Card className={styles.card}>
                <section className={classNames(styles.section)}>
                    <main>
                        <div>
                            <CurrentPlanHeader />

                            <div>
                                <header className="mb-2">
                                    {discountedMonthlyAmount !==
                                        monthlyAmount && (
                                        <s className="mr-2">
                                            {currencyFormatterFractionsIfNeeded.format(
                                                fromIntToMonetary(
                                                    monthlyAmount,
                                                ),
                                            )}
                                        </s>
                                    )}
                                    {currencyFormatterFractionsIfNeeded.format(
                                        fromIntToMonetary(
                                            discountedMonthlyAmount,
                                        ),
                                    )}
                                    /m
                                </header>

                                {subscriptionDetails && (
                                    <div className={styles.additionalInfo}>
                                        {discountedIntervalAmount !==
                                            intervalAmount && (
                                            <s className="mr-2">
                                                {currencyFormatterFractionsIfNeeded.format(
                                                    fromIntToMonetary(
                                                        intervalAmount,
                                                    ),
                                                )}
                                            </s>
                                        )}
                                        {currencyFormatterFractionsIfNeeded.format(
                                            fromIntToMonetary(
                                                discountedIntervalAmount,
                                            ),
                                        )}{" "}
                                        billed{" "}
                                        {getBillingIntervalAdjective(
                                            subscriptionDetails?.interval ??
                                                "month",
                                            subscriptionDetails?.intervalCount ??
                                                1,
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>

                        <aside className={styles.actions}>
                            {isOnFreePlan ? (
                                <Button
                                    variant="action"
                                    onClick={() => open(["plans"])}
                                    data-testid={`${trialAvailable ? "start-trial-button" : "upgrade-button"}`}
                                >
                                    <ThunderboltIcon />
                                    {trialAvailable ? "Start trial" : "Upgrade"}
                                </Button>
                            ) : (
                                <Button
                                    variant="secondary"
                                    onClick={() => open(["plans"])}
                                    data-testid="update-plan-button"
                                >
                                    Update plan
                                </Button>
                            )}

                            {isTrialing && (
                                <PlanManagementProvider>
                                    <EndTrialAction variant="secondary">
                                        Start subscription
                                    </EndTrialAction>
                                </PlanManagementProvider>
                            )}
                        </aside>
                    </main>
                </section>

                {isEnterpriseEligible && (
                    <section
                        className={classNames(
                            styles.section,
                            styles.progressBarSection,
                        )}
                    >
                        <header className={styles.title}>
                            Enterprise eligibility
                        </header>

                        <p className={styles.additionalInfo}>
                            You are{" "}
                            {currencyFormatterNoFractions.format(
                                maxUsage - clampedUsage,
                            )}{" "}
                            away from being eligible for Kick Enterprise.
                        </p>

                        <main>
                            <span className="text-left">
                                {currencyFormatterNoFractions.format(0)}
                            </span>
                            <span className="text-right">
                                {currencyFormatterNoFractions.format(maxUsage)}
                            </span>
                            <CustomProgressBar
                                className={styles.progressBar}
                                value={clampedUsage}
                                max={maxUsage}
                                renderValue={(value) => (
                                    <>
                                        {currencyFormatterShort
                                            .format(value)
                                            .toLowerCase()}{" "}
                                        business expenses
                                    </>
                                )}
                            />
                        </main>
                    </section>
                )}

                {currentPlan === FLAT_RATE_PLANS.FREE && (
                    <section
                        className={classNames(
                            styles.section,
                            styles.progressBarSection,
                        )}
                    >
                        <main>
                            <span className="text-left">
                                {currencyFormatterNoFractions.format(0)}
                            </span>
                            <span className="text-right">
                                {currencyFormatterNoFractions.format(maxUsage)}
                            </span>
                            <CustomProgressBar
                                className={styles.progressBar}
                                value={clampedUsage}
                                max={maxUsage}
                                renderValue={(value) => (
                                    <>
                                        {currencyFormatterShort
                                            .format(value)
                                            .toLowerCase()}{" "}
                                        business expenses
                                    </>
                                )}
                            />
                        </main>
                    </section>
                )}

                <CurrentPlanFooter
                    isOnFreePlan={isOnFreePlan}
                    isAtOrOverLimit={isAtOrOverLimit}
                    maxUsage={maxUsage}
                />
            </Card>
        </SettingsSection>
    );
};
