import React, { useMemo } from "react";
import { uniqBy } from "lodash";
import { useFinancialAccounts } from "../../../hooks/useFinancialAccounts";
import { getLeadingAccountConnection } from "../../../common/helpers/financialAccount";
import { FinancialConnectionIcon } from "../../financialConnection/FinancialConnectionIcon";
import { getConnectionLabel } from "../../../common/helpers/financialConnection";
import { ObjectIcon } from "../ObjectIcon/ObjectIcon";
import { DotsHorizontalIcon } from "../../../icons";
import { BreakableText } from "../BreakableText/BreakableText";
import { getAnonymousAccountNumber } from "../../../common/helpers/bankAccount";
import { AccountFilterSharedProps } from "./lib";

interface Props {
    isAccountSelected: AccountFilterSharedProps["isAccountSelected"];
    showSelectedIcons: boolean;
}

const ICONS_LIMIT = 4;

export const AccountsFilterAccountValueDisplay: React.FC<Props> = ({
    isAccountSelected,
    showSelectedIcons,
}) => {
    const accounts = useFinancialAccounts();
    const selectedAccounts = useMemo(
        () =>
            accounts.filter(
                (account) =>
                    account.entityId &&
                    isAccountSelected(account.id, account.entityId),
            ),
        [accounts, isAccountSelected],
    );

    if (selectedAccounts.length === 0) {
        return <>Select accounts</>;
    }

    if (selectedAccounts.length === 1) {
        const account = selectedAccounts[0];
        const connection = getLeadingAccountConnection(account);
        return (
            <>
                {showSelectedIcons && (
                    <span className="accounts-filter__label__icon">
                        <FinancialConnectionIcon
                            account={account}
                            connection={connection ?? undefined}
                        />
                    </span>
                )}
                <span className="accounts-filter__label__value-name">
                    {account.accountNumberMask && (
                        <span>
                            {getAnonymousAccountNumber(
                                account.accountNumberMask,
                                4,
                            )}
                        </span>
                    )}
                    {!account.accountNumberMask && (
                        <BreakableText text={account.name} />
                    )}
                </span>
            </>
        );
    }

    const accountsUniqueByConnection = uniqBy(selectedAccounts, (account) => {
        const connection = getLeadingAccountConnection(account);

        return connection && getConnectionLabel(connection);
    });

    return (
        <>
            {showSelectedIcons && (
                <>
                    {accountsUniqueByConnection
                        .slice(0, ICONS_LIMIT)
                        .map((account) => (
                            <span
                                className="accounts-filter__label__icon"
                                key={account.id}
                            >
                                <FinancialConnectionIcon
                                    account={account}
                                    connection={
                                        getLeadingAccountConnection(account) ??
                                        undefined
                                    }
                                />
                            </span>
                        ))}
                    {accountsUniqueByConnection.length > ICONS_LIMIT && (
                        <ObjectIcon name="..." defaultColor="#ffffff" size="xs">
                            <DotsHorizontalIcon className="accounts-filter__more-icon" />
                        </ObjectIcon>
                    )}
                </>
            )}
            <span className="accounts-filter__label__values-num">
                {selectedAccounts.length} accounts
            </span>
        </>
    );
};
