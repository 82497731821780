import { animated, useSpring } from "@react-spring/web";
import { ChildrenProps } from "../../types";
import styles from "./ActionActivity.module.scss";

interface Props extends ChildrenProps {
    icon: React.ReactNode;
    animated: boolean;
}

export const ActionActivity: React.FC<Props> = ({
    icon,
    animated: shouldAnimate,
    children,
}) => {
    const [animateHeight] = useSpring(() => ({
        from: { height: 0, marginTop: -20 },
        to: { height: 20, marginTop: 0 },
        immediate: !shouldAnimate,
    }));

    return (
        <animated.div style={animateHeight} className={styles.actionActivity}>
            <div className={styles.actionActivityIcon}>{icon}</div>
            <div className={styles.actionActivityDescription}>{children}</div>
        </animated.div>
    );
};
