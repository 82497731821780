export const campaignTrackingCookies = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
] as const;

export type CampaignTrackingCookie = (typeof campaignTrackingCookies)[number];

export function getCampaign(cookies: Record<CampaignTrackingCookie, string>) {
    return {
        source: cookies.utm_source,
        medium: cookies.utm_medium,
        campaign: cookies.utm_campaign,
        term: cookies.utm_term,
        content: cookies.utm_content,
    };
}
