import React from "react";
import { useCanManageBilling } from "../../../hooks/useCanManageBilling";
import styles from "./AccessBlocked.module.scss";

export interface UpgradePageProps {
    headline: React.ReactElement;
    subheading?: React.ReactElement;
    actions?: React.ReactNode;
    imageUrl: string;
}

export const AccessBlockedPage: React.FC<UpgradePageProps> = ({
    headline,
    subheading,
    imageUrl,
    actions,
}) => {
    const canManageBilling = useCanManageBilling();
    const showActions = Boolean(canManageBilling && actions);

    return (
        <main className={styles.page}>
            <header className={styles.header}>
                <h3 className={styles.headline}>{headline}</h3>
                {subheading && (
                    <p className={styles.subheading}>{subheading}</p>
                )}

                {showActions && (
                    <aside className={styles.actions}>{actions}</aside>
                )}
            </header>

            <article className={styles.imageContainer}>
                <img
                    className={styles.image}
                    src={imageUrl}
                    alt="Premium feature preview"
                />
            </article>
        </main>
    );
};
