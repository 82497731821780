import React from "react";
import { Button } from "../../../general/Button/Button";
import { LeftArrowIcon } from "../../../../icons";
import styles from "./MatchingViewHeader.module.scss";

interface Props {
    onBack(): void;
}

export const MatchingViewHeader: React.FC<Props> = ({ onBack }) => (
    <div className={styles.header}>
        <Button
            size="sm"
            variant="secondary"
            icon
            onClick={onBack}
            data-testid="financial-document-matching-view-back-button"
        >
            <LeftArrowIcon />
        </Button>
        Find transaction
    </div>
);
