import { ActionCampaign, FakeActionCampaign } from "../../common/types/actions";
import { User } from "../../common/types/user";
import { BookOnboardingCallActivity } from "./bookOnboardingCall/BookOnboardingCallActivity";
import { ConfirmTransactionsActivity } from "./confirmTransactions/ConfirmTransactionsActivity";
import { InviteAccountantActivity } from "./inviteAccountant/InviteAccountantActivity";
import { IRSRequirementsActivity } from "./irsRequirements/IRSRequirementsActivity";
import { PersonalToBusinessActivity } from "./personalToBusiness/PersonalToBusinessActivity";
import { SetupReceiptMatchingActivity } from "./setupReceiptMatching/SetupReceiptMatchingActivity";
import { WelcomeVideoActivity } from "./welcomeVideo/WelcomeVideoActivity";
import { CategorizedTransactionsActivity } from "./fake/categorizedTransactions/CategorizedTransactionsActivity";
import { MatchedReceiptsActivity } from "./fake/matchedReceipts/MatchedReceiptsActivity";
import { ConnectedAccountsActivity } from "./fake/connectedAccounts/ConnectedAccountsActivity";
import { CreatedJournalEntriesActivity } from "./fake/createdJournalEntries/CreatedJournalEntriesActivity";

export interface CampaignActivityProps {
    campaign: ActionCampaign | FakeActionCampaign;
    count: number;
    amountSaved: number;
    animated: boolean;
    description?: string;
    user?: User;
}

const CAMPAIGN_ACIVITIES: Record<
    ActionCampaign | FakeActionCampaign,
    React.ComponentType<CampaignActivityProps>
> = {
    book_onboarding_call: BookOnboardingCallActivity,
    confirm_transactions: ConfirmTransactionsActivity,
    invite_accountant: InviteAccountantActivity,
    irs_requirements: IRSRequirementsActivity,
    personal_to_business: PersonalToBusinessActivity,
    setup_receipt_matching: SetupReceiptMatchingActivity,
    welcome_video: WelcomeVideoActivity,
    categorized_transactions: CategorizedTransactionsActivity,
    matched_receipts: MatchedReceiptsActivity,
    connected_accounts: ConnectedAccountsActivity,
    created_journal_entries: CreatedJournalEntriesActivity,
};

export const ActionActivityItem: React.FC<CampaignActivityProps> = (props) => {
    const Activity = CAMPAIGN_ACIVITIES[props.campaign];
    return <Activity {...props} />;
};
