import React, { FC, useCallback } from "react";
import { Command } from "../../vendor/cmdk/cmdk";
import {
    ActivityIcon,
    Card3Icon,
    ClassIcon,
    DirectoryIcon,
    FileSheetIcon,
    MembersIcon,
    PlansIcon,
} from "../../icons";
import {
    EntitySettingsPages,
    UserSettingsPages,
    useSettings,
    WorkspaceSettingsPages,
} from "../settings/SettingsProvider";
import { useTypedFlags } from "../../hooks/useTypedFlags";
import { useBillingManagement } from "../../hooks/useBillingManagement";
import { useBillingType } from "../../hooks/useBillingType";
import { SubscriptionType } from "../../common/types/billing";
import { useEntities } from "../../hooks/useEntities";
import { EntityPaneKey, getEntityPaneKey } from "../settings/lib";
import { EntitySettingsCommandItem } from "./EntitySettingsCommandItem";
import { useCommands } from "./useCommands";
import { CommandItem } from "./CommandItem";

export const WorkspaceSettingsCommandsList: FC = () => {
    const { open: openSettings } = useSettings();
    const { close: closeCommands } = useCommands();

    const handleSelected = useCallback(
        (
            path: Array<
                WorkspaceSettingsPages | UserSettingsPages | EntityPaneKey
            >,
        ) => {
            openSettings(path);
            closeCommands();
        },
        [openSettings, closeCommands],
    );

    const { directory: canUseDirectory, classes: canUseClasses } =
        useTypedFlags();

    const { canManageBilling } = useBillingManagement();
    const billingType = useBillingType();
    const showPlans =
        canManageBilling && billingType === SubscriptionType.FLAT_RATE;

    const entities = useEntities({
        requireAdminAccess: true,
    });

    return (
        <Command.Group heading="Workspace settings">
            <CommandItem
                keywords={["custom"]}
                onSelect={() =>
                    handleSelected([WorkspaceSettingsPages.MEMBERS])
                }
            >
                <MembersIcon /> Go to Members
            </CommandItem>

            <CommandItem
                keywords={["custom"]}
                onSelect={() =>
                    handleSelected([WorkspaceSettingsPages.CATEGORIES])
                }
            >
                <ActivityIcon /> Go to Categories
            </CommandItem>

            <CommandItem
                keywords={["categorization", "category"]}
                onSelect={() => handleSelected([WorkspaceSettingsPages.RULES])}
            >
                <FileSheetIcon /> Go to Rules
            </CommandItem>
            {canUseClasses && (
                <CommandItem
                    onSelect={() =>
                        handleSelected([WorkspaceSettingsPages.CLASSES])
                    }
                >
                    <ClassIcon /> Go to Classes
                </CommandItem>
            )}
            {canUseDirectory && (
                <CommandItem
                    onSelect={() =>
                        handleSelected([WorkspaceSettingsPages.DIRECTORY])
                    }
                >
                    <DirectoryIcon /> Go to Directory
                </CommandItem>
            )}
            {showPlans && (
                <CommandItem
                    onSelect={() => handleSelected([UserSettingsPages.PLANS])}
                >
                    <PlansIcon /> Go to Plans
                </CommandItem>
            )}
            {canManageBilling && (
                <CommandItem
                    onSelect={() => handleSelected([UserSettingsPages.BILLING])}
                >
                    <Card3Icon /> Go to Billing
                </CommandItem>
            )}

            {entities.map((entity, index) => (
                <EntitySettingsCommandItem
                    key={entity.id}
                    entity={entity}
                    index={index + 1}
                    onSelected={() =>
                        handleSelected([
                            getEntityPaneKey(
                                EntitySettingsPages.SETTINGS,
                                entity,
                            ),
                        ])
                    }
                />
            ))}
        </Command.Group>
    );
};
