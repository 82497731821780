export const XID_COOKIE = "ps_xid";
export const PARTNER_KEY_COOKIE = "ps_partner_key";
export const FIRST_TOUCH = "kick_ft";
export const LAST_TOUCH = "kick_lt";
export const EXTERNAL_REFERER = "kick_external_referer";
export const LANDING_PAGE = "kick_landing_page";
export const GA_CLIENT_ID = "ga_client_id";

export type RegistrationCookies =
    | typeof XID_COOKIE
    | typeof PARTNER_KEY_COOKIE
    | typeof FIRST_TOUCH
    | typeof LAST_TOUCH
    | typeof EXTERNAL_REFERER
    | typeof LANDING_PAGE
    | typeof GA_CLIENT_ID;
