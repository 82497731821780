import { Class } from "../../common/types/class";
import { ObjectIcon } from "../general/ObjectIcon/ObjectIcon";

interface ClassObjectIconProps {
    class: Class;
    className?: string;
}
export function ClassObjectIcon({
    class: { label, iconColor },
    className,
}: ClassObjectIconProps) {
    return (
        <ObjectIcon
            name={label}
            defaultColor={iconColor}
            size="xs"
            rhombus
            className={className}
        />
    );
}
