import classNames from "classnames";
import { isEqual, omitBy } from "lodash";
import { useCallback, useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { EagerLoaded } from "../../common/types/base/orm";
import { FinancialAccount } from "../../common/types/financialAccount";
import { Invoice } from "../../common/types/invoice";
import { getStripeDashboardUrlForFinancialAccount } from "../../helpers/invoice";
import { Pagination } from "../general/Pagination/Pagination";
import { AccountSelectModal } from "./AccountSelectModal";
import { AggregatedInvoiceFilters } from "./filters/AggregatedFilters/AggregatedInvoiceFilters";
import { FiltersChangeFn } from "./filters/lib";
import { InvoiceDetails } from "./InvoiceDetails";
import { InvoicesTable } from "./InvoicesTable";
import { InvoiceStats } from "./InvoiceStats";
import { PaginatedInvoicesTableContext } from "./PaginatedInvoicesTableContextProvider";

interface Props {
    invoicingAccounts: FinancialAccount[];
}
export const InvoicePageContent: React.FC<Props> = ({ invoicingAccounts }) => {
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice>();
    const [showAccountSelectModal, setShowAccountSelectModal] = useState(false);

    const {
        filters,
        currentSort,
        invoices,
        handleSortChange,
        setFilters,
        setPage,
        currentPage,
        totalPages,
        invoiceCount,
        invoiceStats,
    } = useContext(PaginatedInvoicesTableContext);

    const onCreateInvoice = useCallback(() => {
        if (invoicingAccounts.length === 1) {
            window.open(
                getStripeDashboardUrlForFinancialAccount(invoicingAccounts[0]),
                "_blank",
            );
            return;
        }

        setShowAccountSelectModal(true);
    }, [invoicingAccounts]);

    const onAccountSelect = useCallback(
        (account: FinancialAccount) => {
            setShowAccountSelectModal(false);
            window.open(
                getStripeDashboardUrlForFinancialAccount(account),
                "_blank",
            );
        },
        [setShowAccountSelectModal],
    );

    const onInvoiceClick = useCallback(
        (invoice: Invoice) => {
            setSelectedInvoice(invoice);
        },
        [setSelectedInvoice],
    );

    const handleFiltersChange = useCallback<FiltersChangeFn>(
        (value) => {
            const newFilters = omitBy(
                { ...filters, ...value },
                (v) => v === null || v === undefined,
            );

            if (isEqual(newFilters, filters)) {
                return;
            }

            setFilters(newFilters);
            setSelectedInvoice(undefined);
        },
        [filters, setFilters],
    );

    return (
        <>
            <Col className={classNames("d-flex flex-column gap-4 p-0")}>
                <AggregatedInvoiceFilters
                    filters={filters ?? {}}
                    onChange={handleFiltersChange}
                    onCreateInvoice={onCreateInvoice}
                />
                <InvoiceStats invoiceStats={invoiceStats} />
                <Row>
                    <Col sm={12} md={selectedInvoice ? 8 : 12}>
                        <InvoicesTable
                            invoices={invoices ?? []}
                            invoiceCount={invoiceCount}
                            onInvoiceClick={onInvoiceClick}
                            sort={currentSort}
                            onSortChange={handleSortChange}
                            selectedInvoiceId={selectedInvoice?.id}
                            pagination={
                                <Pagination
                                    pageCount={totalPages}
                                    currentPage={currentPage}
                                    onPageChange={setPage}
                                />
                            }
                        />
                    </Col>
                    {selectedInvoice && (
                        <Col md={4} className="d-none d-md-block">
                            <InvoiceDetails
                                invoice={
                                    selectedInvoice as EagerLoaded<
                                        Invoice,
                                        "lines"
                                    >
                                }
                                setInvoice={setSelectedInvoice}
                            />
                        </Col>
                    )}
                </Row>
            </Col>
            <AccountSelectModal
                onHide={() => setShowAccountSelectModal(false)}
                show={showAccountSelectModal}
                invoicingAccounts={invoicingAccounts}
                onSelect={onAccountSelect}
            />
        </>
    );
};
