import { Category } from "../common/types/category";

interface CheckCategoryLabelConflictParams {
    categories: Category[];
    parentCategoryId: string | null;
    label: string;
    targetCategoryId?: string;
}

export function checkCategoryLabelConflict({
    categories,
    parentCategoryId,
    label,
    targetCategoryId,
}: CheckCategoryLabelConflictParams) {
    const parent = categories.find((c) => c.id === parentCategoryId);
    const conflictWithParent =
        parent && parent.label.toLowerCase() === label.toLowerCase();
    if (conflictWithParent) {
        return {
            conflict: true,
            conflictingCategory: parent,
        };
    }

    const conflictWithDefaultCategory = categories.find(
        (c) => c.label.toLowerCase() === label.toLowerCase(),
    );
    if (conflictWithDefaultCategory) {
        return {
            conflict: true,
            conflictingCategory: conflictWithDefaultCategory,
        };
    }

    const conflictWithDefaultSubcategory = categories.reduce<Category | null>(
        (acc, c) => {
            const subCategory = (c.subcategories ?? []).find(
                (sc) => sc.label.toLowerCase() === label.toLowerCase(),
            );
            if (subCategory) {
                return subCategory;
            }
            return acc;
        },
        null,
    );
    if (conflictWithDefaultSubcategory) {
        return {
            conflict: true,
            conflictingCategory: conflictWithDefaultSubcategory,
        };
    }

    const categoriesToCheckAgainst = categories.filter(
        (c) => c.parentCategoryId === parentCategoryId,
    );

    if (categoriesToCheckAgainst.length === 0) {
        return {
            conflict: false,
            conflictingCategory: null,
        };
    }

    const conflictingCategory = categoriesToCheckAgainst.find(
        (c) =>
            c.label.toLowerCase() === label.toLowerCase() &&
            c.id !== targetCategoryId,
    );
    return {
        conflict: !!conflictingCategory,
        conflictingCategory,
    };
}
