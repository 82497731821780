import { useCallback } from "react";
import { useWorkspaceContext } from "../state/workspaceContext";
import { useUser } from "./useUser";
import { WORKSPACE_USER_CACHE_KEYS } from "./useWorkspaceUserCache";

/**
 * This call clears all cache that is workspace scoped.
 */
export function useClearWorkspaceUserCache(): () => void {
    const { activeWorkspaceKey } = useWorkspaceContext();
    const user = useUser();

    return useCallback(() => {
        if (user) {
            Object.values(WORKSPACE_USER_CACHE_KEYS).forEach((cacheKey) => {
                const key = `${activeWorkspaceKey}/${cacheKey}-${user.id}`;

                localStorage.removeItem(key);
            });
        }
    }, [activeWorkspaceKey, user]);
}
