import React, { useEffect } from "react";
import { Tooltip } from "react-bootstrap";
import { TooltipProps } from "react-bootstrap/Tooltip";

export const DynamicTooltip = React.forwardRef<any, TooltipProps>(
    ({ popper, children, ...props }, ref) => {
        useEffect(() => {
            popper.scheduleUpdate();
        }, [children, popper]);

        return (
            <Tooltip ref={ref} {...props}>
                {children}
            </Tooltip>
        );
    },
);
