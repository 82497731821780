import { useQuery } from "@tanstack/react-query";
import {
    accountingReportsContract,
    ExtendedAccount,
} from "../common/contracts/accounting-reports";
import { getBackendAPIClient } from "../lib/backendAPIClient";

export const accountingReportsContractClient = getBackendAPIClient(
    accountingReportsContract,
);

export function getChartOfAccountsQueryKey(entityId: number | null) {
    return ["chart-of-accounts", entityId];
}

export type ChartOfAccountsResponse =
    | {
          accounts: ExtendedAccount[];
      }
    | undefined;

export function useChartOfAccountsQuery(entityId: number | null) {
    return useQuery<ChartOfAccountsResponse>({
        queryKey: getChartOfAccountsQueryKey(entityId),
        queryFn: async () => {
            if (!entityId) {
                return undefined;
            }

            const response =
                await accountingReportsContractClient.getChartOfAccounts({
                    params: {
                        entityId: entityId.toString(),
                    },
                });
            return {
                accounts: response.body.accounts.sort(
                    (a, b) => a.code - b.code,
                ),
            };
        },
    });
}
