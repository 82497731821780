import { TransactionListItemDto } from "../common/types/transaction";

export function getAmountSaved(
    transaction: TransactionListItemDto,
): number | null {
    return (
        transaction.amountSaved ??
        ((transaction.requirementsSnoozed &&
            transaction.potentialAmountSaved) ||
            null)
    );
}
