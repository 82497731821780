import React, { useMemo } from "react";
import { format, parse } from "date-fns";
import { DataTableHeaderCell } from "../../general/DataTable/DataTableHeaderCell";

interface Props {
    label: string;
}

export const ProfitLossTableHeaderCell: React.FC<Props> = ({ label }) => {
    const formattedLabel = useMemo(
        () => format(parse(label, "yyyy-MM", new Date()), "MMM yyyy"),
        [label],
    );

    return <DataTableHeaderCell>{formattedLabel}</DataTableHeaderCell>;
};
