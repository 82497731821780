import React from "react";
import { User } from "../../common/types/user";
import { ObjectIcon, ObjectIconProps } from "../general/ObjectIcon/ObjectIcon";

interface Props
    extends Pick<
        ObjectIconProps,
        "size" | "square" | "border" | "active" | "className"
    > {
    user: User;
}

export const UserAvatar: React.FC<Props> = ({ user, size = "lg", ...rest }) => (
    <ObjectIcon
        name={user.name ?? user.email}
        iconUrl={user.avatar ?? undefined}
        defaultColor={user.iconColor}
        size={size}
        {...rest}
    />
);
