import React, { useContext, useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import classNames from "classnames";
import { size, uniq } from "lodash";
import actionBarStyles from "../../../general/ActionBar/ActionBar.module.scss";
import { CounterpartySelect } from "../../../counterparty/CounterpartySelect";
import {
    CounterpartyDirection,
    CounterpartyDirectionLabels,
    GENERAL_COUNTERPARTY_LABEL,
    getCounterpartyDirectionForTaxonomy,
} from "../../../../common/helpers/counterparties";
import { useTypedFlags } from "../../../../hooks/useTypedFlags";
import { PaginatedTransactionsTableContext } from "../../PaginatedTransactionsTableContextProvider";
import { useBulkActions } from "../useBulkActions";
import { useCategoriesById } from "../../../../hooks/useCategoriesById";

interface Props {
    isBusy: boolean;
}

export const TransactionsActionBarCounterparty: React.FC<Props> = ({
    isBusy,
}) => {
    const bulkActions = useBulkActions();
    const { directory: isDirectoryEnabled } = useTypedFlags();
    const { filters } = useContext(PaginatedTransactionsTableContext);
    const categoriesById = useCategoriesById();
    const selectedTaxonomies = useMemo(() => {
        if (size(bulkActions.selected)) {
            return uniq(
                Object.values(bulkActions.selected).map(
                    (transaction) =>
                        categoriesById[transaction.categoryId]?.taxonomy,
                ),
            );
        }

        if (bulkActions.hasSelectedAll && filters.category) {
            return uniq(
                filters.category.map(
                    (categoryId) => categoriesById[categoryId]?.taxonomy,
                ),
            );
        }

        return [];
    }, [
        bulkActions.selected,
        bulkActions.hasSelectedAll,
        filters.category,
        categoriesById,
    ]);
    const directions = useMemo(() => {
        if (selectedTaxonomies.length) {
            return uniq(
                selectedTaxonomies.map((taxonomy) =>
                    getCounterpartyDirectionForTaxonomy(taxonomy),
                ),
            );
        } else {
            return Object.values(CounterpartyDirection);
        }
    }, [selectedTaxonomies]);

    const directionsLabels = directions.map(
        (direction) => CounterpartyDirectionLabels[direction],
    );
    const counterpartiesLabel =
        directionsLabels.length === 1
            ? directionsLabels[0]
            : GENERAL_COUNTERPARTY_LABEL;

    if (!isDirectoryEnabled) {
        return null;
    }

    return (
        <CounterpartySelect
            onChange={(counterparty) =>
                counterparty &&
                bulkActions.updateSelected({
                    update: "counterpartyId",
                    value: counterparty.id,
                })
            }
            dropdownKey="counterpartyBulkEdit"
            counterpartyDirection={
                directions.length === 1 ? directions[0] : undefined
            }
            allowToCreateNew
            forTransactionEditing
        >
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id="action-bar-counterparty">
                        Update {counterpartiesLabel.toLowerCase()}
                    </Tooltip>
                }
            >
                <button
                    className={classNames(actionBarStyles.button)}
                    disabled={isBusy}
                >
                    {counterpartiesLabel}
                </button>
            </OverlayTrigger>
        </CounterpartySelect>
    );
};
