import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSettings } from "./SettingsProvider";

export const SettingsRoute: React.FC = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { open } = useSettings();

    useEffect(() => {
        const settingsPath = pathname
            .toLowerCase()
            .replace("/settings", "")
            .split("/")
            .filter(Boolean);

        if (settingsPath.length) {
            open(settingsPath);
        } else {
            open();
        }

        navigate("/");
    }, [navigate, open, pathname]);

    return null;
};
