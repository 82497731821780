import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ChildrenProps } from "../../../types";
import styles from "./EntityIconList.module.scss";

interface Props extends ChildrenProps {
    tooltipId: string;
    tooltip: React.ReactNode;
}
export const IconWithTooltip: React.FC<Props> = ({
    tooltipId,
    tooltip,
    children,
}) => (
    <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={tooltipId}>{tooltip}</Tooltip>}
    >
        <span className={styles.icon}>{children}</span>
    </OverlayTrigger>
);
