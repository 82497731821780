import { useContext } from "react";
import { commandsContext } from "./commands.context";

export function useCommands() {
    const context = useContext(commandsContext);

    if (!context) {
        throw new Error("useCommands must be used within a CommandsProvider");
    }

    return context;
}
