import React, { useMemo } from "react";
import { useField } from "formik";
import { Dropdown } from "react-bootstrap";
import styles from "../RuleForm.module.scss";
import { CustomSelect } from "../../../../forms/CustomSelect/CustomSelect";
import {
    FormRuleActionType,
    TransactionRuleActionFormSchema,
    TransactionRuleFormSchema,
} from "../constants";
import { ManagementDropdown } from "../../../../general/ManagementDropdown/ManagementDropdown";
import { CategoryActionForm } from "./CategoryActionForm";
import { EntityActionForm } from "./EntityActionForm";

interface Props {
    index: number;
    onRemove: (index: number) => void;
}

export const RuleActionForm: React.FC<Props> = ({ index, onRemove }) => {
    const [{ value: allActions }] =
        useField<TransactionRuleFormSchema["actions"]>("actions");
    const [actionTypeField, , actionTypeHelpers] = useField<
        TransactionRuleActionFormSchema["actionType"]
    >(`actions.${index}.actionType`);
    const canRemove = allActions.length > 1;
    const actionType = actionTypeField.value;

    const selectedTypes = useMemo(
        () =>
            allActions.map((a) => a.actionType).filter((v) => v !== undefined),
        [allActions],
    );
    const actionTypesOptions = useMemo(
        () =>
            Object.values(FormRuleActionType)
                .filter((v) => !selectedTypes.includes(v) || v === actionType)
                .map((value) => ({ value })),
        [selectedTypes, actionType],
    );

    return (
        <>
            <div className={styles.sectionRowContent}>
                <CustomSelect
                    value={actionType}
                    onSelected={(v) => actionTypeHelpers.setValue(v)}
                    dropdownKey={`actionType${index}`}
                    placeholder="Select"
                    size="xs"
                    empty="No available actions"
                    options={actionTypesOptions}
                />

                {actionType === FormRuleActionType.CATEGORY && (
                    <CategoryActionForm index={index} />
                )}

                {actionType === FormRuleActionType.ENTITY && (
                    <EntityActionForm index={index} />
                )}
            </div>
            {canRemove && (
                <ManagementDropdown
                    id={`actionManagement${index}`}
                    className={styles.conditionManagementDropdown}
                >
                    <Dropdown.Item
                        onClick={() => onRemove(index)}
                        className="text-danger"
                    >
                        Remove
                    </Dropdown.Item>
                </ManagementDropdown>
            )}
        </>
    );
};
