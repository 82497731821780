import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";

import { noop } from "../../helpers/general";
import { useEntities } from "../../hooks/useEntities";
import { PlainModal } from "../general/Modal/Modal";
import { ChildrenProps } from "../../types";
import { getEntityPaneKey } from "./lib";
import { Settings } from "./Settings";

interface SettingsContextProps {
    path: string[];
    isOpened: boolean;
    entityId?: number;
    open(path?: string[]): void;
    close(): void;
}

export const SettingsContext = createContext<SettingsContextProps>({
    path: [],
    isOpened: false,
    open: noop,
    close: noop,
});

export function useSettings() {
    const ctx = useContext(SettingsContext);
    if (!ctx) {
        throw new Error("Can only be used inside a SettingsProvider");
    }

    return ctx;
}

export const DEFAULT_PATH = ["general"];
export enum UserSettingsPages {
    GENERAL = "general",
    RECEIPTS = "receipts",
    INTEGRATIONS = "integrations",
    SECURITY = "security",
    BILLING = "billing",
    PLANS = "plans",
}

export enum WorkspaceSettingsPages {
    CATEGORIES = "categories",
    RULES = "rules",
    CLASSES = "classes",
    DIRECTORY = "directory",
    MEMBERS = "members",
}

export enum EntitySettingsPages {
    ACCOUNTS = "accounts",
    ALLOCATION = "allocation",
    SETTINGS = "settings",
    PAYROLL = "payroll",
    CATEGORIES = "categories",
}

export const SettingsProvider: React.FC<ChildrenProps> = ({ children }) => {
    const [path, setPath] = useState<string[]>([]);
    const [entityId, setEntityId] = useState<number>();
    const entities = useEntities();

    const validPages = useMemo(
        () => [
            ...Object.values(UserSettingsPages),
            ...Object.values(WorkspaceSettingsPages),
            ...entities
                .map((e) =>
                    Object.values(EntitySettingsPages).map((p) =>
                        getEntityPaneKey(p, e),
                    ),
                )
                .flat(),
        ],
        [entities],
    );

    const open = useCallback(
        (newPath: string[] = DEFAULT_PATH) => {
            if ((validPages as string[]).includes(newPath[0])) {
                setPath(newPath);

                const entityIdMatch = /(\d+)$/.exec(newPath[0]);

                if (entityIdMatch) {
                    setEntityId(parseInt(entityIdMatch[0], 10));
                } else {
                    setEntityId(undefined);
                }
            } else {
                setPath(DEFAULT_PATH);
                setEntityId(undefined);
            }
        },
        [validPages],
    );

    const [disableAnimationState, setDisableAnimationState] = useState(false);

    const close = useCallback((disableAnimation?: boolean) => {
        if (disableAnimation) {
            // NOTE: in case of stacked modals,
            // to prevent page unresponsiveness cause of missing animation events
            // we need to disable animation
            setDisableAnimationState(true);
            setTimeout(() => {
                setPath([]);
                setEntityId(undefined);
            }, 5);
            setTimeout(() => {
                setDisableAnimationState(false);
            }, 500);
        } else {
            setPath([]);
            setEntityId(undefined);
        }
    }, []);

    const settingsContextValue = useMemo(
        () => ({
            path,
            isOpened: path.length > 0,
            open,
            entityId,
            close,
        }),
        [entityId, open, path, close],
    );

    return (
        <SettingsContext.Provider value={settingsContextValue}>
            {children}

            <PlainModal
                className="settings-widget__modal"
                show={path.length > 0}
                onHide={close}
                disableAnimation={disableAnimationState}
            >
                <Settings />
            </PlainModal>
        </SettingsContext.Provider>
    );
};
