import React, { useCallback, useMemo } from "react";
import { useEntities } from "../../hooks/useEntities";
import { Entity } from "../../common/types/entity";
import { EntitySelect } from "../entity/EntitySelect";
import { CustomSelectProps } from "../forms/CustomSelect/CustomSelect";
import { FinancialAccount } from "../../common/types/financialAccount";
import { useChangeFinancialAccountEntityMutation } from "../../mutations/financialAccount";

interface Props {
    account: FinancialAccount;
    selectedEntity?: Entity;
    dropdownKey: string;
    size?: CustomSelectProps["size"];
    disabled?: boolean;
    onUpdated?: () => void;
    allowMockEntity?: boolean;
    isBusiness?: boolean;
}

export const UpdateFinancialAccountEntity: React.FC<Props> = ({
    account,
    selectedEntity,
    disabled,
    onUpdated,
    allowMockEntity = false,
    isBusiness,
    ...rest
}) => {
    const businessEntities = useEntities({
        onlyBusiness: isBusiness === true ? true : undefined,
        onlyPersonal: isBusiness === false ? true : undefined,
        requireAdminAccess: true,
        excludeEmptyMockEntities: !allowMockEntity,
    });
    const displayedEntities = useMemo(() => {
        const selectedBusinessEntity = businessEntities.some(
            (e) => e.id === selectedEntity?.id,
        );

        if (selectedBusinessEntity) {
            return businessEntities;
        } else {
            return businessEntities.concat(
                selectedEntity ? [selectedEntity] : [],
            );
        }
    }, [selectedEntity, businessEntities]);

    const changeFinancialAccountEntity =
        useChangeFinancialAccountEntityMutation(account);

    const selectEntity = useCallback(
        (entity?: Entity) => {
            if (entity && entity.id !== selectedEntity?.id) {
                changeFinancialAccountEntity
                    .mutateAsync(entity)
                    .then(() => onUpdated?.());
            }
        },
        [changeFinancialAccountEntity, selectedEntity?.id, onUpdated],
    );
    const isPersonal = selectedEntity?.isPersonal;

    return (
        <EntitySelect
            entities={displayedEntities}
            onChange={selectEntity}
            selectedEntity={selectedEntity}
            disabled={disabled ?? isPersonal}
            {...rest}
        />
    );
};
