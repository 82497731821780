import { CreateOauthConnectionDto } from "../common/dto/oauth/create-oauth-connection.dto";
import { FinancialConnectionDto } from "../common/dto/financialAccount/financial-connection.dto";
import { FinancialConnectionWithAccounts } from "../common/types/financialConnection";
import { CreateMercuryOauthConnectionDto } from "../common/dto/mercury/create-mercury-oauth-connection.dto";
import { backendClient, unwrapResponse } from "./backendClient";

export async function createMercuryConnection(
    data: CreateMercuryOauthConnectionDto,
): Promise<FinancialConnectionWithAccounts> {
    return FinancialConnectionDto.deserialize(
        unwrapResponse(await backendClient.post("/mercury-connection", data)),
    );
}

export async function fixMercuryConnection(
    id: number,
    data: CreateOauthConnectionDto,
): Promise<FinancialConnectionWithAccounts> {
    return FinancialConnectionDto.deserialize(
        unwrapResponse(
            await backendClient.post(`/mercury-connection/fix/${id}`, data),
        ),
    );
}
