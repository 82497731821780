import React, { useState } from "react";
import { Form, Formik, FormikConfig } from "formik";
import { omit } from "lodash";
import classNames from "classnames";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";
import { submitHelper } from "../../helpers/form";
import { UserRole } from "../../common/types/userEntityAssignment";
import { Entity } from "../../common/types/entity";
import { Workspace, WorkspaceMember } from "../../common/types/workspace";
import { useUpdateWorkspaceAssignmentMutation } from "../../api/workspace-member.api";
import { useToaster } from "../general/ToastMessages/useToaster";
import { Button } from "../general/Button/Button";
import { StandardModalBody, StandardModalHeader } from "../general/Modal/Modal";
import { getPossessive } from "../../common/helpers/string";
import styles from "./MemberManagement.module.scss";
import { ConfigurePermissionsForm } from "./ConfigurePermissionsForm";
import { MemberPermissionsSchema } from "./types";

interface Props {
    member: WorkspaceMember;
    workspace: Workspace;
    entities: Entity[];
    closeModal: () => void;
}

export const ConfigurePermissionsModal: React.FC<Props> = ({
    member,
    workspace,
    entities,
    closeModal,
}) => {
    const { toast } = useToaster();
    const [loading, setLoading] = useState(false);

    const updateWorkspaceAssignment = useUpdateWorkspaceAssignmentMutation(
        member,
        workspace,
    );

    const form: FormikConfig<MemberPermissionsSchema> = {
        initialValues: {
            workspaceRole: member.role,
            entityAssignments: entities.map((entity) => {
                const assignment = member.entityAssignments.find(
                    (ea) => ea.entity.id === entity.id,
                );

                return {
                    included: !!assignment,
                    entityId: entity.id,
                    role: assignment?.role ?? UserRole.MEMBER,
                };
            }),
        },
        validate({ entityAssignments }) {
            if (!entityAssignments.some((ea) => ea.included)) {
                return {
                    entityAssignments: "At least one entity must be selected",
                };
            }
        },
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async ({ workspaceRole, entityAssignments }) => {
                await updateWorkspaceAssignment.mutateAsync({
                    role: workspaceRole,
                    entityAssignments: entityAssignments
                        .filter((ea) => ea.included)
                        .map((ea) => omit(ea, "included")),
                });

                closeModal();
                toast(`User permissions updated`);
            },
        }),
    };

    return (
        <>
            <StandardModalHeader>
                Configure{" "}
                {member.user.preferredName
                    ? getPossessive(member.user.preferredName)
                    : null}{" "}
                permissions
            </StandardModalHeader>

            <StandardModalBody>
                <section className={styles.layout}>
                    <aside>
                        <ul
                            className={classNames(
                                "list-unstyled",
                                styles.steps,
                            )}
                        >
                            <li
                                className={classNames(
                                    styles.step,
                                    styles.activeStep,
                                )}
                            >
                                Configure permissions
                            </li>
                        </ul>
                    </aside>
                    <section>
                        <Formik {...form}>
                            {({ isValid }) => (
                                <Form>
                                    <ConfigurePermissionsForm
                                        workspace={workspace}
                                        entities={entities}
                                    />

                                    <footer className={styles.footer}>
                                        <Button
                                            variant="secondary"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </Button>
                                        <ButtonWithLoader
                                            loading={loading}
                                            variant="default"
                                            type="submit"
                                            disabled={!isValid}
                                        >
                                            Update permissions
                                        </ButtonWithLoader>
                                    </footer>
                                </Form>
                            )}
                        </Formik>
                    </section>
                </section>
            </StandardModalBody>
        </>
    );
};
