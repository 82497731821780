import React from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { Loader } from "./Loader";

interface Props {
    queryResult: UseQueryResult;
    children: React.ReactNode;
}

export const LoaderOnInitialLoading: React.FC<Props> = ({
    queryResult,
    children,
}) => (
    <>
        {queryResult.isLoading && <Loader />}
        {queryResult.isSuccess && queryResult.data && children}
    </>
);
