import { useContext, useMemo } from "react";
import { UserRole } from "../common/types/userEntityAssignment";
import { Entity } from "../common/types/entity";
import { ADMIN_ROLES } from "../common/constants";
import { EntityContext } from "../components/entity/entity.context";
import { useEntitiesContexts } from "./useEntitiesContexts";
import { useActiveWorkspaceRole } from "./permissions/useActiveWorkspaceRole";

export function useRoles(roles: UserRole[], entity?: Entity) {
    const entities = useEntitiesContexts();

    return useMemo(() => {
        if (entity) {
            return (
                entities?.some(
                    (e) => e.entity.id === entity.id && roles.includes(e.role),
                ) ?? false
            );
        } else {
            return entities?.some((ctx) => roles.includes(ctx.role)) ?? false;
        }
    }, [entities, entity, roles]);
}

export function useIsPrimaryAdminInAnyEntity() {
    return useActiveWorkspaceRole();
}

export function useIsAdmin(entity?: Entity) {
    const entityContext = useContext(EntityContext);
    const entityToCheck = entity ?? entityContext.entity;

    if (!entityToCheck) {
        throw new Error("Entity context is not set or entity is not provided");
    }

    return useRoles(ADMIN_ROLES, entityToCheck);
}
