import React, {
    RefObject,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import isNumber from "lodash/isNumber";
import Scrollbars from "react-custom-scrollbars-2";
import { keyBy } from "lodash";
import { Loader } from "../../general/Loader";
import "./AccountsSettings.scss";
import { useFinancialAccounts } from "../../../hooks/useFinancialAccounts";
import { FullAccountNumber } from "../../../common/dto/financialAccount/get-account-numbers-response.dto";
import { EntitySettingsPages, useSettings } from "../SettingsProvider";
import { useEntity } from "../../entity/useEntity";
import { getEntityPaneKey } from "../lib";
import { EntityNoAccounts } from "../../accounts/EntityNoAccounts";
import { Collapsible } from "../../general/Collapsible/Collapsible";
import { AccountCard } from "../../general/AccountCard/AccountCard";
import { currencyFormatter } from "../../../common/helpers/currency";
import { AddAccount } from "../../accounts/AddAccount";
import { FinancialAccount } from "../../../common/types/financialAccount";
import {
    ExtendedFinancialAccount,
    getFinancialAccountNumbers,
} from "../../../lib/financialAccount";
import { AccountDetails } from "./AccountDetails/AccountDetails";

type AccountNumbers = Record<number, FullAccountNumber>;

async function getAccountNumbers(): Promise<AccountNumbers> {
    const numbers = await getFinancialAccountNumbers();

    return keyBy(numbers.numbers, "id");
}

interface Props {
    scrollbars: RefObject<Scrollbars>;
}

export const AccountsSettings: React.FC<Props> = ({ scrollbars }) => {
    const entity = useEntity();
    const baseAccounts = useFinancialAccounts({
        entityId: entity.id,
    });
    const {
        path: [accountsPage, activeAccount],
    } = useSettings();
    const activeAccountId = activeAccount
        ? parseInt(activeAccount, 10)
        : undefined;

    const [accountNumbers, setAccountNumbers] = useState<AccountNumbers>();
    const [openedAccounts, setOpenedAccounts] = useState<number[]>([]);

    useEffect(() => {
        getAccountNumbers().then((numbers) => {
            setAccountNumbers(numbers);
        });
    }, []);

    const scrollToAccount = useCallback(
        (accountId: number) => {
            setTimeout(() => {
                const el = document.querySelector<HTMLElement>(
                    `[data-account-id="${accountId}"]`,
                );
                if (el && scrollbars.current) {
                    const elementRect = el.getBoundingClientRect();
                    const scrollbarsRect =
                        scrollbars.current.container.getBoundingClientRect();
                    scrollbars.current.scrollTop(
                        elementRect.top - scrollbarsRect.top - 20,
                    );
                }
            }, 100);
        },
        [scrollbars],
    );

    useEffect(() => {
        if (
            accountsPage ===
                getEntityPaneKey(EntitySettingsPages.ACCOUNTS, entity) &&
            activeAccountId &&
            openedAccounts.length === 0
        ) {
            const selectedAccount = baseAccounts.find(
                (a) => a.id === activeAccountId,
            );

            if (selectedAccount) {
                setOpenedAccounts([selectedAccount.id]);
                scrollToAccount(activeAccountId);
            }
        }
    }, [
        accountsPage,
        activeAccountId,
        baseAccounts,
        entity,
        scrollToAccount,
        openedAccounts,
    ]);

    const onOpen = useCallback((account: FinancialAccount) => {
        setOpenedAccounts((accounts) => [...accounts, account.id]);
    }, []);

    const onClose = useCallback((account: FinancialAccount) => {
        setOpenedAccounts((accounts) =>
            accounts.filter((a) => a !== account.id),
        );
    }, []);

    const accounts: ExtendedFinancialAccount[] = useMemo(
        () =>
            baseAccounts.map((account) => ({
                ...account,
                accountNumber:
                    accountNumbers?.[account.id]?.accountNumber ?? undefined,
            })),
        [accountNumbers, baseAccounts],
    );

    if (!accounts) {
        return <Loader />;
    }

    if (!accounts.length) {
        return <EntityNoAccounts />;
    }

    return (
        <section className="accounts-settings">
            <header className="settings__header">
                <div>
                    <small className="mb-3 d-block">{entity.name}</small>
                    <h3>Accounts</h3>
                </div>
                <AddAccount
                    btnText="Add account"
                    btnVariant="secondary"
                    btnClassName="accounts-settings__add-account"
                    defaultEntity={entity}
                />
            </header>

            <main>
                {accounts.map((account) => (
                    <Collapsible
                        header={
                            <div
                                className="d-flex justify-content-between align-items-center"
                                data-account-id={account.id}
                            >
                                <AccountCard account={account} numberInName />
                                {isNumber(account.balance) ? (
                                    <span className="font-weight-medium">
                                        {currencyFormatter.format(
                                            account.balance,
                                        )}
                                    </span>
                                ) : null}
                            </div>
                        }
                        key={account.id}
                        open={openedAccounts.includes(account.id)}
                        onToggle={(opened) =>
                            opened ? onOpen(account) : onClose(account)
                        }
                        stacked
                    >
                        <AccountDetails account={account} />
                    </Collapsible>
                ))}
            </main>
        </section>
    );
};
