import classNames from "classnames";
import { memo, useMemo, useRef, useState } from "react";
import { GridTableRow } from "../../../general/Tables/GridTable/GridTable";
import { CategoryFlattenedItem, CategoryFlattenedItemType } from "../utils";
import styles from "../Categories.module.scss";
import { EnterIcon } from "../../../../icons";
import { AddCategoryRow } from "./AddCategoryRow";
import { CategoryIdCell } from "./CategoryIdCell";

import { CategoryActions } from "./CategoryActions";

export const CategoryItem: React.FC<{
    item: CategoryFlattenedItem;
    collapsedCategories: Set<string>;
    setCollapsedCategories: (value: React.SetStateAction<Set<string>>) => void;
    projectedDepth?: number;
}> = memo(
    ({ item, collapsedCategories, setCollapsedCategories, projectedDepth }) => {
        const [isFocused, setIsFocused] = useState(false);
        const inputRef = useRef<HTMLInputElement>(null);

        const nameCell = useMemo(() => {
            let nameValue;
            if (item.meta.type === CategoryFlattenedItemType.AddCategory) {
                nameValue = (
                    <AddCategoryRow
                        parentCategoryId={item.parentId as string}
                        isFocused={isFocused}
                        setIsFocused={setIsFocused}
                        inputRef={inputRef}
                    />
                );
            } else {
                nameValue = (
                    <CategoryIdCell
                        isCollapsed={collapsedCategories.has(item.id as string)}
                        item={item}
                    />
                );
            }
            return nameValue;
        }, [item, collapsedCategories, isFocused]);

        const onClickCallback = useMemo(() => {
            let onClick = undefined;
            if (
                item.meta.type === CategoryFlattenedItemType.Category &&
                !item.parentId
            ) {
                onClick = () => {
                    setCollapsedCategories((prev) => {
                        const newSet = new Set(prev);
                        if (newSet.has(item.id as string)) {
                            newSet.delete(item.id as string);
                        } else {
                            newSet.add(item.id as string);
                        }
                        return newSet;
                    });
                };
            } else if (
                item.meta.type === CategoryFlattenedItemType.AddCategory
            ) {
                onClick = () => {
                    inputRef.current?.focus();
                };
            }
            return onClick;
        }, [item, setCollapsedCategories, inputRef]);

        const description = useMemo(() => {
            if (!item.meta.description) {
                return "";
            }
            const trimmedDescription = item.meta.description.trim();
            const hasCommonExamples =
                trimmedDescription.includes("Common examples:");

            if (!hasCommonExamples) {
                return item.meta.description;
            }
            const indexOfCommonExamples =
                trimmedDescription.indexOf("Common examples:");
            return trimmedDescription.slice(0, indexOfCommonExamples);
        }, [item.meta.description]);

        const cells = useMemo(
            () => [
                {
                    key: "name",
                    value: nameCell,
                },
                {
                    key: "description",
                    value: (
                        <div className={styles.description}>{description}</div>
                    ),
                },
                {
                    key: "actions",
                    value:
                        item.meta.type ===
                        CategoryFlattenedItemType.CustomCategory ? (
                            <CategoryActions category={item} />
                        ) : (
                            <AddCategoryIndicator isFocused={isFocused} />
                        ),
                },
            ],
            [item, isFocused, nameCell, description],
        );

        return (
            <GridTableRow
                onClick={onClickCallback}
                key={
                    item.meta.type === CategoryFlattenedItemType.AddCategory
                        ? `addCategory:${item.parentId}`
                        : item.id
                }
                cells={cells}
                className={classNames(styles.row, {
                    [styles.subcategoryRow]:
                        (item.meta.type !==
                            CategoryFlattenedItemType.AddCategory &&
                            item.parentId) ||
                        (projectedDepth && projectedDepth > 0),
                    [styles.addSubcategoryRow]:
                        item.meta.type ===
                        CategoryFlattenedItemType.AddCategory,
                    [styles.bottomBorder]: collapsedCategories.has(
                        item.id as string,
                    ),
                    [styles.isCollapsed]:
                        collapsedCategories.has(item.parentId as string) ||
                        item.meta.isHidden,
                })}
                columnBorders={false}
            />
        );
    },
);

interface AddCategoryIndicatorProps {
    isFocused: boolean;
}

function AddCategoryIndicator({ isFocused }: AddCategoryIndicatorProps) {
    return isFocused ? (
        <div className={styles.addCategoryIndicator}>
            <EnterIcon />
        </div>
    ) : null;
}
