import React, { useCallback, useContext, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { CounterpartyDirection } from "../../../common/helpers/counterparties";
import {
    CounterpartiesReturnFormat,
    CounterpartySort,
} from "../../../common/constants/counterparty";
import { useWorkspaceContext } from "../../../state/workspaceContext";
import {
    counterpartiesQueryKeys,
    getCounterparties,
} from "../../../api/counterparty.api";
import styles from "./DirectoryView.module.scss";
import { DirectoryFiltersForm } from "./DirectoryFiltersForm";
import { DirectoryDownload } from "./DirectoryDownload";
import { CounterpartiesList } from "./CounterpartiesList";
import { useAvailableYears } from "./useAvailableYears";
import { DirectoryContext } from "./Directory.context";

export const DirectoryView: React.FC = () => {
    const availableYears = useAvailableYears();
    const [sort, setSort] = useState<CounterpartySort>(
        CounterpartySort.NUMBER_OF_TRANSACTIONS_DESC,
    );
    const {
        filters,
        setFilters,
        counterpartyDirection,
        setCounterpartyDirection,
    } = useContext(DirectoryContext);

    const changeDirection = useCallback(
        (newDirection?: CounterpartyDirection) => {
            if (
                newDirection === CounterpartyDirection.VENDOR &&
                [
                    CounterpartySort.REVENUE_DESC,
                    CounterpartySort.REVENUE_ASC,
                ].includes(sort)
            ) {
                setSort(CounterpartySort.SPEND_DESC);
            }
            if (
                newDirection === CounterpartyDirection.CUSTOMER &&
                [
                    CounterpartySort.SPEND_DESC,
                    CounterpartySort.SPEND_ASC,
                ].includes(sort)
            ) {
                setSort(CounterpartySort.REVENUE_DESC);
            }

            setCounterpartyDirection(newDirection);
        },
        [sort, setCounterpartyDirection],
    );

    const { activeWorkspace } = useWorkspaceContext();
    const counterparties = useQuery({
        queryKey: [
            ...counterpartiesQueryKeys.query(activeWorkspace?.id ?? ""),
            counterpartyDirection,
            filters,
            sort,
        ],
        queryFn: () => {
            if (activeWorkspace) {
                return getCounterparties(
                    {
                        workspaceId: activeWorkspace.id,
                        includeUsageStats: true,
                        direction: counterpartyDirection,
                        search: filters.search,
                        startDate: filters.year
                            ? `${filters.year}-01-01`
                            : undefined,
                        endDate: filters.year
                            ? `${filters.year}-12-31`
                            : undefined,
                        sort,
                    },
                    CounterpartiesReturnFormat.JSON,
                );
            }
        },
    });

    return (
        <Tab.Container activeKey="all">
            <header className={styles.header}>
                <section className={styles.filters}>
                    <DirectoryFiltersForm
                        filters={filters}
                        setFilters={setFilters}
                        availableYears={availableYears}
                    />
                    <DirectoryDownload />
                </section>
                <Nav variant="tabs" className={styles.tabs}>
                    <Nav.Item>
                        <Nav.Link
                            active={!counterpartyDirection}
                            onClick={() => changeDirection()}
                        >
                            All
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                            active={
                                counterpartyDirection ===
                                CounterpartyDirection.CUSTOMER
                            }
                            onClick={() =>
                                changeDirection(CounterpartyDirection.CUSTOMER)
                            }
                        >
                            Customers
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                            active={
                                counterpartyDirection ===
                                CounterpartyDirection.VENDOR
                            }
                            onClick={() =>
                                changeDirection(CounterpartyDirection.VENDOR)
                            }
                        >
                            Vendors
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </header>

            <CounterpartiesList
                data={counterparties.data?.data}
                direction={counterpartyDirection}
                sort={sort}
                setSort={setSort}
                isLoading={counterparties.isLoading}
            />
        </Tab.Container>
    );
};
