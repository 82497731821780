import React from "react";
import { PREMIUM_FEATURES } from "../../../common/flatRateBilling";
import { BALANCE_SHEET_PREVIEW_URL } from "../../../constants";
import { ReportSubscribedAccess } from "../ReportSubscribedAccess";
import { BalanceSheetPreviewPage } from "./BalanceSheetPreviewPage";

export const BalanceSheetAccessPage: React.FC = () => (
    <ReportSubscribedAccess
        feature={PREMIUM_FEATURES.BALANCE_SHEET}
        report="Balance Sheet"
        previewUrl={BALANCE_SHEET_PREVIEW_URL}
        renderReportPage={() => <BalanceSheetPreviewPage />}
    />
);
