import React from "react";
import classNames from "classnames";
import { Tag } from "../../../general/Tag/Tag";
import { Button } from "../../../general/Button/Button";
import { KeyboardReturnIcon, LeftArrowIcon } from "../../../../icons";
import { KeyboardShortcut } from "../../../general/KeyboardShortcut/KeyboardShortcut";
import { actionConfigs, ActionKey } from "./lib";
import styles from "./HowItWorks.module.scss";

interface Props {
    onBack?: () => void;
    waiting?: boolean;
    closing?: boolean;
    actionKey: ActionKey;
}

export const HowItWorksFooter: React.FC<Props> = ({
    onBack,
    waiting,
    closing,
    actionKey,
}) => {
    const { heading, hint, description } = actionConfigs[actionKey] ?? {};

    return (
        <footer
            className={classNames(styles.footer, {
                [styles.waiting]: waiting,
                [styles.closing]: closing,
            })}
        >
            <main className={styles.currentAction}>
                <header>
                    <h2 className={styles.heading}>{heading}</h2>
                    {description && (
                        <p className={styles.description}>{description}</p>
                    )}
                </header>

                <p className={styles.hint}>{hint}</p>
            </main>

            <aside className={styles.waitAction}>
                <p className={styles.hint}>
                    Hit{" "}
                    <Tag variant="secondary" outline>
                        Next
                    </Tag>{" "}
                    or{" "}
                    <KeyboardShortcut square className="ml-2">
                        <KeyboardReturnIcon />
                    </KeyboardShortcut>{" "}
                    to continue
                </p>
            </aside>

            <aside className={styles.back}>
                <Button variant="secondary" icon onClick={onBack}>
                    <LeftArrowIcon />
                </Button>
            </aside>
        </footer>
    );
};
