import React, { useCallback } from "react";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { PlusIcon, SplitIcon } from "../../../../../icons";
import styles from "../RuleForm.module.scss";
import {
    TransactionRuleConditionFormSchema,
    TransactionRuleFormSchema,
} from "../constants";
import { Button } from "../../../../general/Button/Button";
import { getDefaultFormRow } from "../modelToForm.helpers";
import { TransactionRule } from "../../../../../common/types/transactionRule";
import { RuleConditionForm } from "./RuleConditionForm";

interface Props {
    rule?: TransactionRule;
}

export const RuleConditionsForm: React.FC<Props> = () => {
    const { values, setFieldValue } =
        useFormikContext<TransactionRuleFormSchema>();

    const addCondition = useCallback(() => {
        setFieldValue("conditions", [
            ...values.conditions,
            {
                id: Date.now().toString(),
                ...getDefaultFormRow(),
            },
        ]);
    }, [values.conditions, setFieldValue]);

    const duplicateCondition = useCallback(
        (condition: TransactionRuleConditionFormSchema) => {
            setFieldValue("conditions", [
                ...values.conditions,
                {
                    ...condition,
                    id: Date.now().toString(),
                },
            ]);
        },
        [values.conditions, setFieldValue],
    );

    const removeCondition = useCallback(
        (id: string) => {
            setFieldValue(
                "conditions",
                values.conditions.filter((condition) => condition.id !== id),
            );
        },
        [values.conditions, setFieldValue],
    );

    return (
        <section className={styles.sectionConditions}>
            <header className={styles.header}>
                <aside
                    className={classNames(
                        styles.sectionIcon,
                        styles.sectionIconConditions,
                    )}
                >
                    <SplitIcon className="icon-color-gray-700" />
                </aside>
                <h5>Conditions</h5>
            </header>

            <main className={styles.content}>
                <div className={styles.sectionRows}>
                    {values.conditions.map((condition, index) => (
                        <div className={styles.sectionRow} key={condition.id}>
                            <RuleConditionForm
                                condition={condition}
                                index={index}
                                onRemove={removeCondition}
                                onDuplicate={duplicateCondition}
                            />
                        </div>
                    ))}
                    <div className={styles.sectionRow}>
                        <Button
                            variant="secondary"
                            onClick={addCondition}
                            type="button"
                        >
                            <PlusIcon />
                            Add
                        </Button>
                    </div>
                </div>
            </main>
        </section>
    );
};
