import React from "react";
import { subDays } from "date-fns";
import classNames from "classnames";
import { noop } from "lodash";
import { GridTableRow } from "../../../../general/Tables/GridTable/GridTable";
import {
    Transaction,
    TransactionStatus,
} from "../../../../../common/types/transaction";
import { SplitIcon } from "../../../../../icons";
import { MatchingTransaction } from "./MatchingTransaction";
import styles from "./RuleTransactionsPreview.module.scss";

const today = new Date();
const entity = {
    name: "Internet Money",
    iconColor: "#dddddd",
};

const fakeTransactions = [
    {
        id: 1,
        displayDescription: "Starbucks",
        amount: -48.85,
        date: subDays(today, 1),
        status: TransactionStatus.COMPLETED,
        entity,
    },
    {
        id: 2,
        displayDescription: "Burger King",
        amount: -32.45,
        date: subDays(today, 4),
        status: TransactionStatus.COMPLETED,
        entity,
    },
    {
        id: 3,
        displayDescription: "Burger King",
        amount: -80,
        date: subDays(today, 4),
        status: TransactionStatus.COMPLETED,
        entity,
    },
    {
        id: 4,
        displayDescription: "Burger King",
        amount: -32.45,
        date: subDays(today, 10),
        status: TransactionStatus.COMPLETED,
        entity,
    },
] as any as Transaction[];

interface Props {
    mode: "no_conditions" | "no_transactions_found";
}

export const NoConditionsPlaceholder: React.FC<Props> = ({ mode }) => {
    const noConditions = mode === "no_conditions";

    return (
        <>
            {noConditions
                ? fakeTransactions.map((transaction) => (
                      <GridTableRow
                          className={classNames(
                              styles.row,
                              styles.bodyRow,
                              styles.placeholderRow,
                          )}
                          key={transaction.id}
                      >
                          <MatchingTransaction
                              transaction={transaction}
                              isChecked={false}
                              isDisabled={false}
                              toggle={noop}
                          />
                      </GridTableRow>
                  ))
                : null}
            <div className={styles.noConditionsOverlay}>
                <div className={styles.noConditionsIcon}>
                    <SplitIcon
                        className="icon-color-gray-700"
                        width={12}
                        height={12}
                    />
                </div>
                {noConditions ? (
                    <p>Add conditions to view impacted transactions</p>
                ) : (
                    <p>
                        No transactions match selected conditions.
                        <br />
                        You can still create this rule and apply it going
                        forward.
                    </p>
                )}
            </div>
        </>
    );
};
