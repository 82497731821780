import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useToaster } from "../ToastMessages/useToaster";

export interface UseResend {
    resendBlocked: boolean;
    handleResend: () => Promise<void>;
}

const RESEND_DELAY = 60_000;

export function useResend(request: () => Promise<void>): UseResend {
    const [resendBlocked, setResendBlocked] = useState<boolean>(false);
    const { error: errorToast } = useToaster();

    useEffect(() => {
        let timer: number;
        if (resendBlocked) {
            timer = window.setTimeout(() => {
                setResendBlocked(false);
            }, RESEND_DELAY);
        }

        return () => {
            window.clearTimeout(timer);
        };
    }, [resendBlocked]);

    const handleResend = useCallback(async () => {
        try {
            setResendBlocked(true);
            await request();
        } catch (error) {
            if (
                axios.isAxiosError(error) &&
                (error.response?.data as any)?.message
            ) {
                errorToast((error.response?.data as any)?.message);
            } else {
                errorToast("There was a problem sending the code");
            }

            setResendBlocked(false);
        }
    }, [errorToast, request]);

    return {
        handleResend,
        resendBlocked,
    };
}
