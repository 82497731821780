import React, { MouseEvent, useCallback } from "react";
import { ButtonGroup } from "react-bootstrap";
import classNames from "classnames";
import "./CheckboxButtonGroup.scss";
import { without } from "lodash";
import { Button } from "../../general/Button/Button";

export interface CheckboxButtonOption<TValue> {
    value: TValue;
    label: React.ReactNode;
    disabled?: boolean;
    readonly?: boolean;
}

export interface CheckboxButtonGroupProps<
    TValue = any,
    TOptionValue = TValue extends any[] ? TValue[number] : TValue,
> {
    className?: string;
    options: Array<CheckboxButtonOption<TOptionValue>>;
    size?: "sm" | "lg";
    showControls?: boolean;
    disabled?: boolean;
    loading?: boolean;
    value: TValue;
    onChange?(value: TValue, e: MouseEvent): void;
}
export const CheckboxButtonGroup = /* nosonar */ <
    T extends string | number | boolean | object | null | undefined,
>({
    className,
    options,
    value,
    onChange,
    disabled,
    loading,
    showControls,
    size = "lg",
}: CheckboxButtonGroupProps<T>) => {
    const isMultipleChoice = Array.isArray(value);
    const isEmpty = !isMultipleChoice && value === undefined;

    const handleClick = useCallback(
        (option: CheckboxButtonOption<T>, e: MouseEvent) => {
            if (option.disabled || option.readonly) {
                return;
            }

            if (isMultipleChoice) {
                const newValue = value.includes(option.value)
                    ? without(value, option.value)
                    : [...value, option.value];

                onChange?.(newValue as T, e);
            } else {
                onChange?.(option.value, e);
            }
        },
        [isMultipleChoice, onChange, value],
    );

    const isChecked = useCallback(
        (option: CheckboxButtonOption<T>): boolean =>
            isMultipleChoice
                ? value.includes(option.value)
                : value === option.value,
        [isMultipleChoice, value],
    );

    return (
        <ButtonGroup
            className={classNames(
                "checkbox-button-group",
                `checkbox-button-group--${size}`,
                {
                    "checkbox-button-group--loading": loading,
                    "checkbox-button-group--with-controls": showControls,
                    "checkbox-button-group--multiple": isMultipleChoice,
                    "checkbox-button-group--empty": isEmpty,
                },
                className,
            )}
        >
            {options.map((option) => (
                <Button
                    key={String(option.value)}
                    disabled={disabled ?? option.disabled}
                    onClick={(e) => handleClick(option, e)}
                    variant={isMultipleChoice ? "secondary" : "tertiary"}
                    className={classNames(
                        "checkbox-button-group__option",
                        "w-50",
                        {
                            active: isChecked(option),
                            "checkbox-button-group__option--disabled":
                                option.disabled,
                            "checkbox-button-group__option--readonly":
                                option.readonly,
                        },
                    )}
                >
                    <span className="checkbox-button-group__option__label">
                        {option.label}
                    </span>
                </Button>
            ))}
        </ButtonGroup>
    );
};
