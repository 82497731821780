import React, { useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getAdditionalOAuthAuthorizationResponseParams } from "../../../lib/oauth";
import { OAuthServiceType } from "../../../common/constants";
import { sendWindowMessage } from "../../../hooks/useWindowMessage";

interface RouteParams {
    service: string;
}

export const OAuthCallback: React.FC = () => {
    const { service } = useParams() as Readonly<RouteParams>;
    const location = useLocation();
    const params = useMemo(
        () => new URLSearchParams(location.search),
        [location.search],
    );
    const code = params.get("code") ?? params.get("authorization_code");

    useEffect(() => {
        if (code) {
            sendWindowMessage(service, {
                code,
                additionalParams: getAdditionalOAuthAuthorizationResponseParams(
                    service as OAuthServiceType,
                    params,
                ),
            });
            window.close();
        } else {
            const timeout = setTimeout(() => window.close(), 5000);

            return () => clearTimeout(timeout);
        }
    }, [code, params, service]);

    return !code ? <p>Couldn't authorize access</p> : null;
};
