import { useCallback } from "react";
import { ReportFilters } from "../common/types/filters/reports";
import { filtersSchema } from "../common/types/filters/general";
import {
    useWorkspaceUserCache,
    WORKSPACE_USER_CACHE_KEYS,
} from "./useWorkspaceUserCache";

export function useCashFlowPageFiltersCache(): [
    ReportFilters | undefined,
    (value: ReportFilters | undefined) => void,
] {
    const [value, setter] = useWorkspaceUserCache<ReportFilters>(
        WORKSPACE_USER_CACHE_KEYS.CASHFLOW_PAGE_FILTERS_CACHE_KEY,
        filtersSchema,
    );

    const valueSetter = useCallback(
        (sorting: ReportFilters | undefined) => {
            setter(sorting);
        },
        [setter],
    );

    return [value, valueSetter];
}
