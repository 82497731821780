import React, { useCallback, useEffect, useRef, useState } from "react";
import Scrollbars, { positionValues } from "react-custom-scrollbars-2";
import { useTransactionDetailsQuery } from "../TransactionDetails/useTransactionDetailsQuery";
import { Loader } from "../../general/Loader";
import { TransactionListItemDto } from "../../../common/types/transaction";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import { MatchingView } from "./MatchingView/MatchingView";
import styles from "./TransferMatching.module.scss";
import { TransferMatchingTransactionsHeader } from "./TransferMatchingTransactionsHeader/TransferMatchingTransactionsHeader";

interface Props {
    // NOTE: pass transaction to optimize first meaningful paint before full data is loaded
    transaction?: TransactionListItemDto;
    transactionId: number;
}

export const TransferMatchingContainer: React.FC<Props> = ({
    transactionId,
    transaction: passedTransaction,
}) => {
    const { transaction } = useTransactionDetailsQuery(transactionId);
    const shownPrefetchedTransaction = transaction ?? passedTransaction;
    // NOTE: for reactivity (to not wait for refetching of transaction details query)
    const [pairedTransaction, setPairedTransaction] = useState<
        TransactionListItemDto | undefined
    >(transaction?.pairedTransfer ?? undefined);

    const { transaction: pairedTransactionData } = useTransactionDetailsQuery(
        shownPrefetchedTransaction?.pairedTransferId ?? null,
    );

    useEffect(() => {
        if (pairedTransactionData) {
            setPairedTransaction(pairedTransactionData);
        }
    }, [setPairedTransaction, pairedTransactionData]);

    const [showStickyHeader, setShowStickyHeader] = useState(false);

    const scrollbarsRef = useRef<Scrollbars>(null);
    const transactionsHeaderRef = useRef<HTMLDivElement>(null);

    const onScroll = useCallback(
        (values: positionValues) => {
            if (scrollbarsRef.current && transactionsHeaderRef.current) {
                const tableHeight = transactionsHeaderRef.current.offsetTop;
                setShowStickyHeader(values.scrollTop - tableHeight > 0);
            }
        },
        [scrollbarsRef],
    );

    const onScrollOrWindowChange = useCallback(() => {
        if (scrollbarsRef.current) {
            onScroll(scrollbarsRef.current.getValues());
        }
    }, [onScroll, scrollbarsRef]);

    useWindowDimensions({
        onChange: onScrollOrWindowChange,
    });

    useEffect(() => {
        onScrollOrWindowChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollbarsRef.current, onScrollOrWindowChange]);

    return (
        <div className={styles.layout}>
            <Scrollbars onScrollFrame={onScroll} ref={scrollbarsRef}>
                <header className={styles.headerArea}>
                    <div className={styles.header}>Match your transaction</div>
                    <TransferMatchingTransactionsHeader
                        transactionsHeaderRef={transactionsHeaderRef}
                        originTransaction={shownPrefetchedTransaction}
                        pairedTransaction={pairedTransaction}
                        setPairedTransaction={setPairedTransaction}
                        visible
                    />
                </header>
                <main className={styles.contentArea}>
                    {transaction && (
                        <MatchingView
                            transaction={transaction}
                            pairedTransaction={pairedTransaction ?? null}
                            setPairedTransaction={setPairedTransaction}
                        />
                    )}
                    {!transaction && <Loader />}
                </main>
            </Scrollbars>
            <TransferMatchingTransactionsHeader
                isSticky
                originTransaction={shownPrefetchedTransaction}
                pairedTransaction={pairedTransaction}
                setPairedTransaction={setPairedTransaction}
                visible={showStickyHeader}
            />
        </div>
    );
};
