import { ReactNode } from "react";
import classNames from "classnames";
import { AlertIcon } from "../../../icons";
import styles from "./ErrorToast.module.scss";

export const ErrorToast = ({ children }: { children: ReactNode }) => (
    <div className={classNames("d-flex", styles.errorToast)}>
        <AlertIcon className="mr-2 mt-1" />
        {children}
    </div>
);
