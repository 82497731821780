import { Counterparty } from "./counterparty";
import { FinancialAccount } from "./financialAccount";

export enum InvoiceStatus {
    DRAFT = "draft",
    OPEN = "open",
    PAID = "paid",
    VOID = "void",
    UNCOLLECTABLE = "uncollectable",
}

export enum InvoiceSource {
    MANUAL = "manual",
    STRIPE = "stripe",
}

export interface Invoice {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    due: Date | null;
    paidAt: Date | null;
    total: number;
    subtotal: number;
    tax: number;
    discount: number;
    currency: string;
    counterparty?: Counterparty;
    description: string | null;
    status: InvoiceStatus;
    source: InvoiceSource;
    sourceId: string | null;
    lines?: InvoiceLineItem[];
    financialAccountId: number;
    financialAccount?: FinancialAccount;
}

export interface InvoiceLineItem {
    id: string;
    amount: number;
    unitAmount?: number | null;
    currency: string;
    description: string | null;
    quantity: number | null;
    source: InvoiceSource;
    sourceId: string;
}

export interface InvoiceSort {
    field: string;
    order: "ASC" | "DESC";
}

export enum InvoiceSortValue {
    DATE_ASC = "DATE_ASC",
    DATE_DESC = "DATE_DESC",
    DUE_DATE_ASC = "DUE_DATE_ASC",
    DUE_DATE_DESC = "DUE_DATE_DESC",
    STATUS_ASC = "STATUS_ASC",
    STATUS_DESC = "STATUS_DESC",
    COUNTERPARTY_ASC = "COUNTERPARTY_ASC",
    COUNTERPARTY_DESC = "COUNTERPARTY_DESC",
    ACCOUNT_ASC = "ACCOUNT_ASC",
    ACCOUNT_DESC = "ACCOUNT_DESC",
    AMOUNT_ASC = "AMOUNT_ASC",
    AMOUNT_DESC = "AMOUNT_DESC",
    NONE = "NONE",
}

export interface InvoiceStat {
    count: number;
    total: number;
}

export interface InvoiceStats {
    open: InvoiceStat;
    overdue: InvoiceStat;
    paid: InvoiceStat;
}
