import React from "react";
import { InputSize } from "../../../helpers/form";
import { CategoryIcon } from "../CategoryIcon";
import { CustomSelectDefaultTrigger } from "../../forms/CustomSelect/CustomSelectDefaultTrigger";
import { Category } from "../../../common/types/category";
import styles from "./CategorySelectSimple.module.scss";
import {
    CategorySelectCustom,
    CategorySelectCustomProps,
} from "./CategorySelectCustom";

export interface CategorySelectSimpleProps
    extends Omit<CategorySelectCustomProps, "children"> {
    value?: Category;
    size?: InputSize;
}

export const CategorySelectSimple: React.FC<CategorySelectSimpleProps> = ({
    value,
    placement = "bottom-start",
    size,
    ...props
}) => (
    <CategorySelectCustom placement={placement} {...props}>
        {() => (
            <CustomSelectDefaultTrigger
                size={size}
                value={
                    value ? (
                        <div className={styles.control}>
                            <CategoryIcon categoryId={value.id} />
                            {value.label}
                        </div>
                    ) : (
                        "Select category"
                    )
                }
            />
        )}
    </CategorySelectCustom>
);
