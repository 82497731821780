import { sortBy } from "lodash";
import { FINANCIAL_ACCOUNT_TYPE_LABEL } from "../constants";
import {
    FinancialAccount,
    FinancialAccountSourceType,
    FinancialAccountType,
} from "../types/financialAccount";
import { FinancialConnection } from "../types/financialConnection";
import { getAnonymousAccountNumber } from "./bankAccount";

export const ACCOUNTS_POSSIBLY_PERSONAL: Record<
    FinancialAccountSourceType,
    boolean
> = {
    [FinancialAccountSourceType.FILE_IMPORT]: true,
    [FinancialAccountSourceType.PLAID]: true,
    [FinancialAccountSourceType.STRIPE]: false,
    [FinancialAccountSourceType.GUSTO]: false,
    [FinancialAccountSourceType.MERCURY]: false,
    [FinancialAccountSourceType.RAMP]: false,
    [FinancialAccountSourceType.PAYPAL]: true,
} as const;

export function getAccountNameWithLast4(
    account: FinancialAccount,
    accountNumberMaskLength = 4,
) {
    const name = account.name ?? "";

    if (/\d{4}$/.test(name) || !account.accountNumberMask) {
        return name;
    } else {
        return `${name} ${getAnonymousAccountNumber(
            account.accountNumberMask,
            accountNumberMaskLength,
        )}`;
    }
}

export function getAccountNameForConfirmation(account: FinancialAccount) {
    const name = account.name ?? "";

    if (/\d{4}$/.test(name) || !account.accountNumberMask) {
        return name;
    } else {
        const last4Digits = account.accountNumberMask.substring(
            account.accountNumberMask.length - 4,
        );

        return `${name} ${last4Digits}`;
    }
}

export function checkIfEnteredAccountNameIsValidForConfirmation(
    accountName: string,
    account: FinancialAccount,
) {
    const replacedPart = /\s/g;
    return (
        accountName.replace(replacedPart, "").toLowerCase() ===
        getAccountNameForConfirmation(account)
            .replace(replacedPart, "")
            .toLowerCase()
    );
}

export function getAccountShortName(account: FinancialAccount) {
    switch (account.sourceType) {
        case FinancialAccountSourceType.PLAID:
            return FINANCIAL_ACCOUNT_TYPE_LABEL[account.financialType];
        case FinancialAccountSourceType.STRIPE:
            return "Stripe";
        case FinancialAccountSourceType.GUSTO:
            return "Gusto";
        default:
            return account.name;
    }
}

export function isCardAccount(account: FinancialAccount) {
    return account.financialType === FinancialAccountType.CREDIT_CARD;
}

export function getLeadingAccountConnection(
    account: FinancialAccount,
): FinancialConnection | null {
    if (!account.integrationAccounts) {
        return null;
    }

    const connections = account.integrationAccounts
        .map((ia) => ia.connection)
        .filter((c) => c !== null && c !== undefined);

    if (!connections.length) {
        return null;
    }

    const healthyConnections = connections.filter((c) => !c.error);

    if (healthyConnections.length) {
        return sortBy(healthyConnections, (c) =>
            new Date(c.createdAt).getTime(),
        )[0];
    }

    return sortBy(connections, (c) => new Date(c.createdAt).getTime())[0];
}
