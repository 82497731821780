import React, { FC, useCallback, useMemo, useState } from "react";
import { ChildrenProps } from "../../types";
import { WorkspaceMember } from "../../common/types/workspace";
import { UserWorkspaceRole } from "../../common/userWorkspaceRoles";
import { StandardModal } from "../general/Modal/Modal";
import { useWorkspaceContext } from "../../state/workspaceContext";
import { useEntities } from "../../hooks/useEntities";
import { ConfigurePermissionsModal } from "./ConfigurePermissionsModal";
import { AddMemberModal } from "./AddMemberModal";
import {
    AddMemberOptions,
    memberManagementContext,
} from "./memberManagement.context";

const DEFAULT_ADD_MEMBER_OPTIONS: AddMemberOptions = {
    email: "",
    role: UserWorkspaceRole.MEMBER,
};

export const MemberManagementProvider: FC<ChildrenProps> = ({ children }) => {
    const { activeWorkspace } = useWorkspaceContext();
    const entitiesUserCanAdminister = useEntities({
        requireAdminAccess: true,
        excludeAllMockEntities: true,
    });

    const [configurePermissions, setConfigurePermissions] =
        useState<WorkspaceMember | null>(null);
    const [addMember, setAddMember] = useState<AddMemberOptions | null>(null);

    const handleAddMember = useCallback(
        (options: AddMemberOptions = DEFAULT_ADD_MEMBER_OPTIONS) => {
            setAddMember(options);
        },
        [],
    );

    const handleConfigurePermissions = useCallback(
        (member: WorkspaceMember) => {
            setConfigurePermissions(member);
        },
        [],
    );

    const value = useMemo(
        () => ({
            addMember: handleAddMember,
            configurePermissions: handleConfigurePermissions,
        }),
        [handleAddMember, handleConfigurePermissions],
    );

    return (
        <>
            <memberManagementContext.Provider value={value}>
                {children}
            </memberManagementContext.Provider>

            <StandardModal
                show={!!configurePermissions}
                onHide={() => setConfigurePermissions(null)}
                size="lg"
                className="overlay-modal"
            >
                {configurePermissions && (
                    <ConfigurePermissionsModal
                        member={configurePermissions}
                        workspace={activeWorkspace!}
                        entities={entitiesUserCanAdminister}
                        closeModal={() => setConfigurePermissions(null)}
                    />
                )}
            </StandardModal>

            <StandardModal
                show={!!addMember}
                onHide={() => setAddMember(null)}
                size="lg"
                className="overlay-modal"
            >
                {addMember ? (
                    <AddMemberModal
                        workspace={activeWorkspace!}
                        entities={entitiesUserCanAdminister}
                        closeModal={() => setAddMember(null)}
                        providedEmail={addMember.email}
                        providedRole={addMember.role}
                        onInvited={addMember.onInvited}
                    />
                ) : null}
            </StandardModal>
        </>
    );
};
