import React from "react";
import { pluralize } from "../../../../../common/helpers/string";
import { FiltersTypes } from "../../../common/types/filters/general";

interface RulesFilterLabelProps {
    filters: Pick<FiltersTypes, "transactionRulesIds">;
}

export const RulesFilterLabel: React.FC<RulesFilterLabelProps> = ({
    filters: { transactionRulesIds },
}) => (
    <>
        {transactionRulesIds?.length}{" "}
        {pluralize("Rule", transactionRulesIds?.length ?? 0)}
    </>
);
