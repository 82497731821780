import { EntityDto } from "../entity/entity.dto";
import { UserRole } from "../../types/userEntityAssignment";
import { WorkspaceMemberEntityAssignment } from "../../types/workspace";

export class WorkspaceMemberEntityAssignmentDto {
    static deserialize(
        dto: WorkspaceMemberEntityAssignmentDto,
    ): WorkspaceMemberEntityAssignment {
        return {
            entity: EntityDto.deserialize(dto.entity),
            role: dto.role,
        };
    }

    entity: EntityDto;
    role: UserRole;

    constructor(workspaceEntityAssignment: WorkspaceMemberEntityAssignment) {
        this.entity = new EntityDto(workspaceEntityAssignment.entity);
        this.role = workspaceEntityAssignment.role;
    }
}
