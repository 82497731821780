export enum CounterpartiesReturnFormat {
    JSON = "json",
    CSV = "csv",
}

export enum CounterpartySort {
    NAME_ASC = "name_asc",
    NAME_DESC = "name_desc",
    // CATEGORY_ASC = "category_asc",
    // CATEGORY_DESC = "category_desc",
    NUMBER_OF_TRANSACTIONS_ASC = "number_of_transactions_asc",
    NUMBER_OF_TRANSACTIONS_DESC = "number_of_transactions_desc",
    REVENUE_ASC = "revenue_asc",
    REVENUE_DESC = "revenue_desc",
    SPEND_ASC = "spend_asc",
    SPEND_DESC = "spend_desc",
}

export enum CounterpartyVerifiedScope {
    LOCAL = "local",
    GLOBAL = "global",
}
