import React, { useCallback, useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import {
    addAdditionalEmail,
    getAdditionalEmailsForUser,
    removeAdditionalEmail,
} from "../../../lib/user";
import { AdditionalEmail } from "../../../common/types/additionalEmail";
import { Loader } from "../../general/Loader";
import { PlainModal, StandardModalHeader } from "../../general/Modal/Modal";
import { Button } from "../../general/Button/Button";
import { AddReceiptsEmail } from "./AddReceiptsEmail";
import "./ReceiptEmails.scss";
import { ReceiptsEmailsRow } from "./ReceiptsEmailsRow";

export const ReceiptEmails: React.FC = () => {
    const [emails, setEmails] = useState<AdditionalEmail[]>();
    const [addingReceiptsEmail, setAddingReceiptsEmail] = useState(false);

    const closeReceiptsEmailModal = useCallback(
        () => setAddingReceiptsEmail(false),
        [],
    );

    const handleAddReceiptsEmail = useCallback(
        async (email: string) => {
            const createdEmail = await addAdditionalEmail({ email });
            setEmails([...(emails ?? []), createdEmail]);
            closeReceiptsEmailModal();
        },
        [closeReceiptsEmailModal, emails],
    );

    const handleRemoveReceiptsEmail = useCallback(
        async (email: string) => {
            setEmails((emails ?? []).filter((e) => e.email !== email));
            await removeAdditionalEmail(email);
        },
        [emails],
    );

    useEffect(() => {
        getAdditionalEmailsForUser().then((response) =>
            setEmails(response.emails),
        );
    }, []);

    if (!emails) {
        return <Loader />;
    }

    return (
        <div className="receipt-emails">
            {emails.length > 0 ? (
                <ListGroup>
                    <ListGroup.Item>
                        <small>Email</small>
                    </ListGroup.Item>

                    {emails.map((row) => (
                        <ReceiptsEmailsRow
                            key={row.email}
                            value={row}
                            onRemove={handleRemoveReceiptsEmail}
                        />
                    ))}

                    <ListGroup.Item className="py-4">
                        <Button
                            variant="tertiary"
                            size="sm"
                            onClick={() => setAddingReceiptsEmail(true)}
                        >
                            Add email address
                        </Button>
                    </ListGroup.Item>
                </ListGroup>
            ) : (
                <Button
                    variant="default"
                    size="lg"
                    onClick={() => setAddingReceiptsEmail(true)}
                >
                    Add receipts email
                </Button>
            )}

            <PlainModal
                show={addingReceiptsEmail}
                onHide={closeReceiptsEmailModal}
            >
                <StandardModalHeader>Add Receipts Email</StandardModalHeader>

                <AddReceiptsEmail
                    onAddEmail={handleAddReceiptsEmail}
                    onCancel={closeReceiptsEmailModal}
                />
            </PlainModal>
        </div>
    );
};
