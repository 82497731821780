import React, { MouseEvent } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import classNames from "classnames";
import { TransactionListItemDto } from "../../../common/types/transaction";
import styles from "./TransactionSplitColumnContent.module.scss";

interface Props {
    transaction: TransactionListItemDto;
    onClick?: (event: MouseEvent) => void;
    hasAdjacentSplitSibling?: boolean;
}

export const TransactionSplitColumnContent: React.FC<Props> = ({
    transaction,
    onClick,
    hasAdjacentSplitSibling,
}) => {
    const isSplit = transaction.splitParentId !== null;

    if (!isSplit) {
        return null;
    }

    return (
        <div className={styles.content}>
            <div
                className={classNames(styles.splitButtonContainer, {
                    [styles.splitButtonContainerAdjacentSibling]:
                        hasAdjacentSplitSibling,
                })}
            >
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`view-split:${transaction.id}`}>
                            View split
                        </Tooltip>
                    }
                >
                    <button className={styles.splitButton} onClick={onClick}>
                        S
                    </button>
                </OverlayTrigger>
            </div>
        </div>
    );
};
