import { Entity } from "./entity";
import { User } from "./user";

export enum UserRole {
    ADMIN = "admin",
    MEMBER = "member",
    ACCOUNTANT = "accountant",
}

export interface UserEntityAssignment {
    user: User;
    entity: Entity;
    role: UserRole;
}
