import React, { useCallback, useState } from "react";
import { TransactionRule } from "../../../common/types/transactionRule";
import { StandardModal } from "../../general/Modal/Modal";
import { ConfirmationModal } from "../../general/ConfirmationModal";
import {
    RuleRemovalError,
    useRuleRemovalMutation,
} from "../../../api/transactionRule.api";
import { ArrowCornerIcon } from "../../../icons";
import { Button } from "../../general/Button/Button";
import { pluralize } from "../../../common/helpers/string";
import { ConfirmRuleReversalModal } from "./ConfirmRuleReversalModal";

interface ChildrenProps {
    onRemove: () => void;
    isRemoving: boolean;
}

interface Props {
    rule: TransactionRule;
    showTransactions: () => void;
    transactionsCount: number;
    children: (props: ChildrenProps) => React.ReactNode;
}

export const RuleRemoval: React.FC<Props> = ({
    rule,
    transactionsCount,
    showTransactions,
    children,
}) => {
    const [
        confirmTransactionsForReversalNumber,
        setConfirmTransactionsForReversalNumber,
    ] = useState<number>();
    const [reversalErrorsNumber, setReversalErrorsNumber] = useState<number>();
    const [processedTransactionsNumber, setProcessedTransactionsNumber] =
        useState<number>();
    const handleRemovalError = useCallback((error: RuleRemovalError) => {
        if (error.reversalErrorsNumber) {
            setConfirmTransactionsForReversalNumber(undefined);
            setReversalErrorsNumber(error.reversalErrorsNumber);
            setProcessedTransactionsNumber(error.processedTransactionsNumber);
        }
    }, []);

    const removal = useRuleRemovalMutation(rule, {
        onError: handleRemovalError,
    });

    const onRemove = useCallback(() => {
        if (transactionsCount) {
            setConfirmTransactionsForReversalNumber(transactionsCount);
        } else {
            removal.mutate(undefined);
        }
    }, [removal, transactionsCount]);

    return (
        <>
            {children({ onRemove, isRemoving: removal.isPending })}
            <StandardModal
                show={!!confirmTransactionsForReversalNumber}
                onHide={() =>
                    setConfirmTransactionsForReversalNumber(undefined)
                }
            >
                <ConfirmRuleReversalModal
                    transactionsNumber={
                        confirmTransactionsForReversalNumber ?? 0
                    }
                    showTransactions={showTransactions}
                    onConfirmReversal={() =>
                        removal.mutateAsync({
                            revertTransactionChanges: "true",
                        })
                    }
                    onSkipReversal={() =>
                        removal.mutateAsync({
                            revertTransactionChanges: "false",
                        })
                    }
                    onCancel={() =>
                        setConfirmTransactionsForReversalNumber(undefined)
                    }
                />
            </StandardModal>
            <ConfirmationModal
                show={!!reversalErrorsNumber}
                onHide={() => setReversalErrorsNumber(undefined)}
                question={
                    <>
                        {processedTransactionsNumber ? (
                            <>
                                Changes in {processedTransactionsNumber}{" "}
                                {pluralize(
                                    "transaction",
                                    processedTransactionsNumber,
                                )}{" "}
                                were reversed.{" "}
                            </>
                        ) : null}
                        Changes in{" "}
                        <Button
                            variant="tertiary"
                            className="p-0"
                            size="sm"
                            onClick={showTransactions}
                        >
                            {reversalErrorsNumber}{" "}
                            {pluralize(
                                "transaction",
                                reversalErrorsNumber ?? 0,
                            )}
                            <ArrowCornerIcon className="ml-1" />
                        </Button>{" "}
                        could not be reversed. Do you want to proceed with
                        removal of the rule?
                    </>
                }
                yes="Yes, remove rule"
                onConfirm={() =>
                    removal.mutateAsync({
                        revertTransactionChanges: "false",
                    })
                }
                no="No, keep rule"
                onReject={() => setReversalErrorsNumber(undefined)}
            />
        </>
    );
};
