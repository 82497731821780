import React from "react";
import { EntityIcon } from "../../entity/EntityIcon";
import { Entity } from "../../../common/types/entity";
import { EntityIconList } from "../EntityIconList/EntityIconList";
import { BreakableText } from "../BreakableText/BreakableText";

interface Props {
    everythingSelected: boolean;
    selectedEntities: Entity[];
    singleEntityName?: boolean;
    showSelectedIcons: boolean;
}

const ICONS_LIMIT = 4;

export const AccountsFilterEntityValueDisplay: React.FC<Props> = ({
    everythingSelected,
    selectedEntities,
    singleEntityName,
    showSelectedIcons,
}) => {
    if (everythingSelected) {
        return <>All accounts</>;
    }

    if (selectedEntities.length === 1 && singleEntityName) {
        return (
            <>
                {showSelectedIcons && (
                    <EntityIcon entity={selectedEntities[0]} size="xs" />
                )}
                <span className="accounts-filter__label__value-name">
                    <BreakableText text={selectedEntities[0].name} />
                </span>
            </>
        );
    }

    return (
        <>
            {showSelectedIcons && (
                <EntityIconList
                    entities={selectedEntities}
                    maxEntitiesToDisplay={ICONS_LIMIT}
                />
            )}
        </>
    );
};
