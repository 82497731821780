import React from "react";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import classNames from "classnames";
import { useEntity } from "../../entity/useEntity";
import { getEntityPayrolls } from "../../../lib/payroll";
import { Payroll as PayrollType } from "../../../common/types/payroll";
import { currencyFormatter } from "../../../common/helpers/currency";
import { Collapsible } from "../../general/Collapsible/Collapsible";
import { fullDateFormat } from "../../../common/helpers/date";
import { getPayrollQueryKey } from "../../../queryClient";
import css from "./Payroll.module.scss";
import { PayrollDetails } from "./PayrollDetails";
import { SyncGustoConnection } from "./SyncGustoConnection";
import { ContractorPaymentDetails } from "./ContractPaymentDetails";

function getTotalPayroll(payroll: PayrollType) {
    const { totals, contractorPaymentTotals } = payroll;

    const contractorPaymentTotal = contractorPaymentTotals
        ? contractorPaymentTotals.debitAmount
        : null;
    if (contractorPaymentTotal !== null) {
        return currencyFormatter.format(contractorPaymentTotal);
    }

    const payrollTotal = totals
        ? totals.companyDebit +
          totals.benefits +
          totals.employeeBenefitsDeductions
        : null;

    if (payrollTotal !== null) {
        return currencyFormatter.format(payrollTotal);
    }

    return null;
}

function getPayrollTitle(payroll: PayrollType) {
    const { processedDate, offCycle, contractorPaymentTotals } = payroll;
    const suffix = processedDate
        ? ` for ${format(processedDate, fullDateFormat)}`
        : "";

    if (offCycle) {
        return `Off-cycle Payroll${suffix}`;
    }

    if (contractorPaymentTotals) {
        return `Contract Payment${suffix}`;
    }

    return `Payroll for ${getPayrollPeriodString(payroll)}`;
}

function getPayrollPeriodString(payroll: PayrollType) {
    const { payPeriodStartDate, payPeriodEndDate } = payroll;

    if (!payPeriodStartDate || !payPeriodEndDate) {
        return "";
    }

    return (
        format(payPeriodStartDate, fullDateFormat) +
        " - " +
        format(payPeriodEndDate, fullDateFormat)
    );
}

export const Payroll: React.FC = () => {
    const entity = useEntity();
    const { data: payrolls } = useQuery({
        queryKey: getPayrollQueryKey(entity.id),
        queryFn: () => getEntityPayrolls(entity),
    });

    return (
        <section className="entity-payrolls">
            <header className="d-flex justify-content-between">
                <div>
                    <small className="mb-3 d-block">{entity.name}</small>
                    <h3 className="settings__heading">Payroll</h3>
                </div>
                <SyncGustoConnection
                    entity={entity}
                    className={classNames(
                        "btn btn-secondary btn-sm",
                        css.syncButton,
                    )}
                >
                    Sync Payroll
                </SyncGustoConnection>
            </header>

            <main className={css.payrollContainer}>
                {payrolls
                    ? payrolls.map((payroll) => (
                          <Collapsible
                              key={payroll.id}
                              className={css.payrollItem}
                              header={
                                  <div className="d-flex justify-content-between align-items-center">
                                      <span className="period">
                                          {getPayrollTitle(payroll)}
                                      </span>
                                      <span className={css.payrollTotal}>
                                          {getTotalPayroll(payroll)}
                                      </span>
                                  </div>
                              }
                              stacked
                          >
                              {payroll.contractorPaymentTotals ? (
                                  <ContractorPaymentDetails
                                      totals={payroll.contractorPaymentTotals}
                                  />
                              ) : (
                                  <PayrollDetails totals={payroll.totals} />
                              )}
                          </Collapsible>
                      ))
                    : null}
            </main>
        </section>
    );
};
