import React, { useState } from "react";
import classNames from "classnames";
import { FormRowRaw, FormRowRawProps } from "../../forms/FormRowRaw";
import { useClickOutside } from "../../../hooks/useClickOutside";
import { CloseIcon } from "../../../icons";
import { Button } from "../../general/Button/Button";
import css from "./ResizableTextInput.module.scss";

interface ResizableTextInputProps extends FormRowRawProps {
    disableResize?: boolean;
    containerClassName?: string;
}
export const ResizableTextInput: React.FC<ResizableTextInputProps> = ({
    onFocus,
    disableResize,
    containerClassName,
    ...props
}) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const container = useClickOutside(() => setIsFocused(false));

    return (
        <div
            ref={container as any}
            className={classNames(containerClassName, {
                [css.resizable]: !disableResize,
                [css.focused]: isFocused,
            })}
        >
            <FormRowRaw
                {...props}
                onFocus={() => {
                    setIsFocused(true);
                    onFocus?.();
                }}
            />

            {props.value && (
                <Button
                    variant="tertiary"
                    size="sm"
                    icon
                    onClick={() => {
                        props.onChange?.("");
                        setIsFocused(false);
                    }}
                >
                    <CloseIcon />
                </Button>
            )}
        </div>
    );
};
