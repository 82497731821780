import { Transaction } from "../../types/transaction";
import { TransactionDto } from "../transactions/transaction.dto";

export interface GetRuleMatchingTransactionsResponse {
    transactions: Transaction[];
    total: number;
}

export class GetRuleMatchingTransactionsResponseDto {
    transactions: TransactionDto[];
    total: number;

    constructor(response: GetRuleMatchingTransactionsResponse) {
        this.transactions = response.transactions.map(
            (transaction) => new TransactionDto(transaction),
        );
        this.total = response.total;
    }

    static deserialize(
        dto: GetRuleMatchingTransactionsResponseDto,
    ): GetRuleMatchingTransactionsResponse {
        return {
            transactions: dto.transactions.map((transaction) =>
                TransactionDto.deserialize(transaction),
            ),
            total: dto.total,
        };
    }
}
