import React from "react";
import classNames from "classnames";
import gridTableStyles from "../../../../styles/grid-table.module.scss";
import { formatDateForTransaction } from "../../../../helpers/date";
import { TransactionAmount } from "../../../transactions/TransactionAmount";
import { TransactionCategory } from "../../../transactions/TransactionCategory";
import { Transaction } from "../../../../common/types/transaction";
import { useInteraction } from "../../../../hooks/useInteraction";
import styles from "./TransacitonToMerge.module.scss";

interface Props {
    transaction: Transaction;
    prepend?: React.ReactNode;
    onClick?: () => void;
    className?: string;
}

export const TransactionToMerge: React.FC<Props> = ({
    transaction,
    prepend,
    onClick,
    className,
}) => {
    const handleClick = useInteraction(onClick);

    return (
        <div
            className={classNames(gridTableStyles.row, className)}
            {...handleClick}
        >
            {prepend}
            <div className={styles.dateColumn}>
                {formatDateForTransaction(transaction.date)}
            </div>
            <div>
                {transaction.displayDescription ?? transaction.bankDescription}
            </div>
            <div>
                <TransactionCategory transaction={transaction} />
            </div>
            <div className={styles.amountColumn}>
                <TransactionAmount
                    transaction={transaction}
                    addSign={true}
                    color={true}
                />
            </div>
        </div>
    );
};
