import React from "react";
import { useEntities } from "../../../hooks/useEntities";
import { TransactionRule } from "../../../common/types/transactionRule";
import { EntityIcon } from "../../entity/EntityIcon";
import styles from "./RuleEntityAction.module.scss";

interface Props {
    rule: TransactionRule;
}

export const RuleEntityAction: React.FC<Props> = ({ rule }) => {
    const entities = useEntities();
    const entity = rule.entityId
        ? entities.find((e) => e.id === rule.entityId)
        : null;
    const entityNameFirstWord = entity?.name?.split(" ")[0];
    const entityNameRest = entityNameFirstWord
        ? entity.name?.substring(entityNameFirstWord.length)
        : null;

    if (!entity) {
        return null;
    }

    return (
        <>
            Move to{" "}
            <div className={styles.entityLead}>
                <EntityIcon entity={entity} size="xs" />
                <strong>{entityNameFirstWord}</strong>
            </div>
            {entityNameRest && (
                <>
                    {" "}
                    <strong className={styles.entityRest}>
                        {entityNameRest}
                    </strong>
                </>
            )}
            .
        </>
    );
};
