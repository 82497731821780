import { QueryClient } from "@tanstack/react-query";
import { FinancialAccount } from "../common/types/financialAccount";

export const unassignedAccountsQueryKey = "unassignedAccounts";

export function addUnassignedAccountInQueryData(
    queryClient: QueryClient,
    account: FinancialAccount,
) {
    queryClient.setQueryData<FinancialAccount[] | undefined>(
        [unassignedAccountsQueryKey],
        (data: FinancialAccount[] | undefined) => {
            if (!data) {
                return data;
            }

            if (data.some((a) => a.id === account.id)) {
                return data;
            }

            return [...data, account];
        },
    );
}

export function removeUnassignedAccountFromQueryData(
    queryClient: QueryClient,
    account: FinancialAccount,
) {
    queryClient.setQueryData<FinancialAccount[] | undefined>(
        [unassignedAccountsQueryKey],
        (data: FinancialAccount[] | undefined) => {
            if (!data) {
                return data;
            }

            return data.filter((a) => a.id !== account.id);
        },
    );
}
