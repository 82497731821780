import fileDownload from "js-file-download";
import { extension as getExtensionFromMime } from "mime-types";
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { toBase64 } from "../../../helpers/file";
import { useMobileView } from "../../../hooks/useMobileView";
import {
    detachFinancialTransactionMatch,
    uploadTransactionReceipt,
} from "../../../lib/transactions";
import { ReceiptUploadDropzone } from "../ReceiptUpload/ReceiptUploadDropzone";
import { UpdateTransactionDto } from "../../../common/dto/transactions/update-transaction.dto";
import { Card } from "../../general/Card/Card";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import { getFinancialDocumentFile } from "../../../lib/financialDocument";
import { StandardModal } from "../../general/Modal/Modal";
import { Transaction } from "../../../common/types/transaction";
import { EagerLoaded } from "../../../common/types/base/orm";
import { PaginatedTransactionsTableContext } from "../PaginatedTransactionsTableContextProvider";
import { useKeyboardCommands } from "../../../hooks/keyboard/useKeyboardCommands";
import {
    invalidateTransactionDetailsQueries,
    useTransactionDetailsQuery,
} from "./useTransactionDetailsQuery";
import { TransactionDetailsContext } from "./transactionDetails.context";
import { TransactionDetailsProps } from "./types";
import { TransactionDetails } from "./TransactionDetails";

export interface TransactionDetailsContainerProps {
    id: number;
    onSplit: (transaction: Transaction) => void;
    onMatch: (transaction: Transaction) => void;
    onClose(): void;
}

export const TransactionDetailsContainer: React.FC<
    TransactionDetailsContainerProps
> = ({ id, onClose, onSplit, onMatch }) => {
    const ref = useRef<HTMLDivElement>();
    const [downloading, setDownloading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const { saveTransaction, upsertTransaction } = useContext(
        PaginatedTransactionsTableContext,
    );
    const [prevTransaction, setPrevTransaction] = useState<
        | EagerLoaded<
              Transaction,
              "transactionMatches" | "pairedTransfer" | "splits"
          >
        | undefined
    >(undefined);

    const { invalidate: invalidateSavings } = useBillingStatus();

    const { transaction, memoRequired, receiptRequired } =
        useTransactionDetailsQuery(id);

    useEffect(() => {
        if (!transaction) {
            return;
        }
        setPrevTransaction(transaction);
    }, [transaction, prevTransaction]);

    const downloadReceipt = useCallback(async () => {
        const financialDocument =
            transaction?.transactionMatches[0]?.financialDocument;

        if (!financialDocument) {
            return;
        }

        setDownloading(true);

        try {
            const { data, contentType } = await getFinancialDocumentFile(
                financialDocument.id,
            );
            const ext = getExtensionFromMime(contentType);
            const filenameHasExtension = Boolean(
                ext && financialDocument.fileName.endsWith(ext),
            );

            const filenameForDownload = filenameHasExtension
                ? financialDocument.fileName
                : `${financialDocument.fileName}.${ext}`;

            fileDownload(data, filenameForDownload, contentType);
        } finally {
            setDownloading(false);
        }
    }, [transaction]);

    const updateTransaction: TransactionDetailsProps["onUpdate"] = useCallback(
        async (payload: UpdateTransactionDto) => {
            if (transaction) {
                await saveTransaction(transaction, payload);
            }
        },
        [transaction, saveTransaction],
    );

    const addReceipt: TransactionDetailsProps["onAddReceipt"] = useCallback(
        async (file) => {
            setUploading(true);

            try {
                const receipt = await toBase64(file);

                await uploadTransactionReceipt(id, {
                    receipt,
                    contentType: file.type,
                    name: file.name,
                });

                await invalidateTransactionDetailsQueries();
                await invalidateSavings();
            } finally {
                setUploading(false);
            }
        },
        [id, invalidateSavings],
    );

    const removeReceipt: TransactionDetailsProps["onRemoveReceipt"] =
        useCallback(async () => {
            if (!transaction) {
                return;
            }

            const link = transaction?.transactionMatches[0];

            if (!link) {
                return;
            }

            await detachFinancialTransactionMatch(transaction.id, link.id);
            await invalidateTransactionDetailsQueries();
            await invalidateSavings();
        }, [transaction, invalidateSavings]);

    const isMobile = useMobileView();

    const contextValue = useMemo(
        () => ({
            transaction,
            downloadingReceipt: downloading,
            onDownloadReceipt: downloadReceipt,
            onRemoveReceipt: removeReceipt,
            uploadingReceipt: uploading,
            onAddReceipt: addReceipt,
            onUpdate: updateTransaction,
            onSplit,
            onMatch,
            setTransaction: upsertTransaction,
            memoRequired,
            receiptRequired,
        }),
        [
            transaction,
            downloading,
            downloadReceipt,
            removeReceipt,
            uploading,
            addReceipt,
            updateTransaction,
            upsertTransaction,
            memoRequired,
            receiptRequired,
            onSplit,
            onMatch,
        ],
    );

    useKeyboardCommands({
        commands: [
            {
                key: "Escape",
                callback: onClose,
                requiresCtrlOrMeta: false,
                repeat: false,
            },
        ],
        rootEl: ref.current,
    });

    const transactionDetailsBody = (
        <TransactionDetailsContext.Provider value={contextValue}>
            <ReceiptUploadDropzone onAddReceipt={addReceipt}>
                <TransactionDetails onClose={onClose} />
            </ReceiptUploadDropzone>
        </TransactionDetailsContext.Provider>
    );

    return (
        <>
            {isMobile ? (
                <StandardModal
                    show
                    onEscapeKeyDown={() => {
                        onClose();
                    }}
                >
                    {transactionDetailsBody}
                </StandardModal>
            ) : (
                <Card className="transactions__details-aside">
                    {transactionDetailsBody}
                </Card>
            )}
        </>
    );
};
