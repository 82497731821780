import React from "react";
import classNames from "classnames";
import { noop } from "../../../helpers/general";
import { ResendCode } from "../../general/CodeVerification/ResendCode";
import { ButtonWithLoader } from "../../general/ButtonWithLoader/ButtonWithLoader";
import { CodeVerification } from "../../general/CodeVerification/CodeVerification";
import animation from "../layout/OnboardingLayout.module.scss";
import styles from "./RegisterCodeVerification.module.scss";

interface Props {
    email: string;
    onSuccess?(): Promise<void>;
    requestVerification(): Promise<void>;
    verifyCode(code: string): Promise<unknown>;
}

export const RegisterCodeVerification: React.FC<Props> = ({
    email,
    verifyCode,
    requestVerification,
    onSuccess,
}) => (
    <div className={classNames(styles.contentContainer, animation.fadeIn)}>
        <header className={styles.header}>
            <h1 className={styles.title}>Check your email</h1>

            <p>
                We just sent a 6-digit code to{" "}
                <strong className="font-weight-medium">{email}</strong>
                <br />
                Proceed by typing your code below.
            </p>
        </header>

        <section className={styles.code}>
            <CodeVerification
                phoneOrEmail={email}
                request={requestVerification}
                verify={verifyCode}
                onSuccess={onSuccess ?? noop}
                lead={null}
                resend={null}
                requestOnMount={false}
                renderSubmit={(valid, loading) => (
                    <>
                        <ButtonWithLoader
                            type="submit"
                            variant="default"
                            loading={loading}
                            disabled={!valid}
                            className="mb-4"
                            block
                        >
                            Verify
                        </ButtonWithLoader>

                        <ResendCode request={requestVerification} />
                    </>
                )}
                small
            />
        </section>
    </div>
);
