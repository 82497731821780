import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { User } from "../../../common/types/user";
import { UserRole } from "../../../common/types/userEntityAssignment";
import { UserAvatar } from "../../user/UserAvatar";
import { ROLES_LABELS } from "../../../common/constants";

interface Props {
    user: User;
    role: UserRole;
}

export const EntityTeamMember: React.FC<Props> = ({ user, role }) => (
    <div className="entity-team__member">
        <OverlayTrigger
            overlay={
                <Tooltip id={`team-member-${user.id}`}>
                    <div className="font-weight-medium mb-1">
                        {user.name}
                        <br />
                        {user.email}
                    </div>
                    {ROLES_LABELS[role]}
                </Tooltip>
            }
        >
            <div>
                <UserAvatar user={user} size="sm" />
            </div>
        </OverlayTrigger>
    </div>
);
