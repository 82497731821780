import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import classNames from "classnames";
import { differenceInDays } from "date-fns";
import { ArrowCornerWhiteIcon, ArrowDownIcon, ChatIcon } from "../../../icons";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import { Tag } from "../../general/Tag/Tag";
import { useSettings } from "../../settings/SettingsProvider";
import { currencyFormatterNoFractions } from "../../../common/helpers/currency";
import { pluralize } from "../../../common/helpers/string";
import {
    FLAT_RATE_PLANS,
    flatRatePlanNames,
    PAID_PLAN_USAGE_LIMIT,
} from "../../../common/flatRateBilling";
import { intercom } from "../../../lib/intercom";
import { Button } from "../../general/Button/Button";
import styles from "./BillingState.module.scss";

export const BillingState: React.FC = () => {
    const {
        isTrialing,
        isSubscribed,
        totalUsage,
        plans,
        periodEnd,
        currentPlan,
        isFetched,
    } = useBillingStatus();
    const { open } = useSettings();
    const intercomCallbacksRegistered = useRef(false);
    const [intercomShown, setIntercomShown] = useState(false);

    useEffect(() => {
        if (intercomCallbacksRegistered.current) {
            return;
        }

        intercom("onShow", () => setIntercomShown(true));
        intercom("onHide", () => setIntercomShown(false));

        intercomCallbacksRegistered.current = true;
    }, []);

    if (!isFetched) {
        return null;
    }

    const freePlan = plans[0];

    let state: React.ReactNode;

    if (!isSubscribed) {
        const usedPercentage = Math.min(
            Math.ceil((totalUsage / freePlan.usageLimit!) * 100),
            100,
        );

        const isOver = totalUsage >= freePlan.usageLimit!;

        const tooltip = isOver ? (
            <>
                You've hit the{" "}
                {currencyFormatterNoFractions.format(freePlan.usageLimit!)} free
                business expense limit. <br />
                Upgrade now to continue using Kick.
            </>
        ) : (
            <>
                You used {usedPercentage}% of your business expenses.
                <br />
                Upgrade and put the books on autopilot with less risk and more
                savings.
            </>
        );

        state = (
            <OverlayTrigger
                overlay={
                    <Tooltip id="free-billing-state-tootlip">{tooltip}</Tooltip>
                }
            >
                <button
                    className={classNames(styles.billingState, {
                        [styles.warning]: isOver,
                    })}
                    onClick={() => open(["plans"])}
                >
                    {usedPercentage}% used
                    <Tag variant="cta" size="sm">
                        Upgrade
                    </Tag>
                </button>
            </OverlayTrigger>
        );
    } else if (isTrialing) {
        const daysLeft = differenceInDays(periodEnd!, new Date());

        state = (
            <OverlayTrigger
                overlay={
                    <Tooltip id="trial-billing-state-tootlip">
                        <strong className={styles.tooltipHighlight}>
                            Your {flatRatePlanNames[currentPlan]} plan will
                            start in {daysLeft} {pluralize("day", daysLeft)}
                        </strong>
                        We'll automatically bill your credit card.
                        <br />
                        Just relax and kick back.
                    </Tooltip>
                }
            >
                <button
                    className={classNames(styles.billingState)}
                    onClick={() => open(["billing"])}
                >
                    {daysLeft} {pluralize("day", daysLeft)} left
                    <Tag variant="primary" outline size="sm">
                        {flatRatePlanNames[currentPlan]} Trial
                    </Tag>
                </button>
            </OverlayTrigger>
        );
    } else if (
        currentPlan !== FLAT_RATE_PLANS.ENTERPRISE &&
        totalUsage >= PAID_PLAN_USAGE_LIMIT
    ) {
        state = (
            <Button
                variant="action"
                onClick={() => open(["plans"])}
                className={styles.enterpriseButton}
                tooltip={
                    <>
                        <strong className={styles.tooltipHighlight}>
                            You’re eligible for Kick Enterprise
                        </strong>{" "}
                        Click to learn more.
                    </>
                }
            >
                Enterprise eligible
                <ArrowCornerWhiteIcon />
            </Button>
        );
    }

    return (
        <section className={styles.billingStateContainer}>
            <OverlayTrigger
                show={intercomShown ? false : undefined}
                overlay={
                    <Tooltip id="support-tootlip">
                        Support{" "}
                        <span className={styles.supportHighlight}>?</span>
                    </Tooltip>
                }
            >
                <Button id="intercom-launcher" variant="secondary" icon>
                    {intercomShown ? <ArrowDownIcon /> : <ChatIcon />}
                </Button>
            </OverlayTrigger>

            {state}
        </section>
    );
};
