import { arrayMove } from "@dnd-kit/sortable";
import { UniqueIdentifier } from "@dnd-kit/core";
import { FlattenedItem } from "../../general/AbstractSortableList/types";
import { AbstractSortableListItemType } from "../../general/AbstractSortableList/AbstractSortableListItem";

import { ExtendedAccount } from "../../../common/contracts/accounting-reports";
import { AccountingReportFilters } from "../types";
import {
    AccountClass,
    AccountType,
} from "../../../common/constants/domains/accounting/accountsv2";

export function getDragDepth(offset: number, indentationWidth: number) {
    return Math.round(offset / indentationWidth);
}

interface ProjectionForChartOfAccountsList {
    items: ExtendedFlattenedItemForAccount[];
    itemIdToIndexMap: Map<UniqueIdentifier, number>;
    activeId: UniqueIdentifier;
    overId: UniqueIdentifier;
    indentationWidth: number;
    maxPossibleDepth: number;
}

export interface ExtendedFlattenedItemForAccountMetaAccount {
    account: ExtendedAccount;
    lastItemInSubtype: boolean;
}

export interface ExtendedFlattenedItemForAccountMetaClassification {
    // Account type is used for other income/expense accounts
    classification: AccountClass | AccountType;
}

export type ExtendedFlattenedItemForAccountMeta =
    | ExtendedFlattenedItemForAccountMetaAccount
    | ExtendedFlattenedItemForAccountMetaClassification;

export type ExtendedFlattenedItemForAccount<
    T extends
        ExtendedFlattenedItemForAccountMeta = ExtendedFlattenedItemForAccountMeta,
> = AbstractSortableListItemType & {
    meta: T extends ExtendedFlattenedItemForAccountMetaClassification
        ? ExtendedFlattenedItemForAccountMetaClassification
        : ExtendedFlattenedItemForAccountMetaAccount;
};

// NOTE: We need to write custom projection logic because
// every sorting list has a lot of too-specific edge cases.
// Based on this experience, it's better to duplicate this code
// and modify it instead of trying to make it more generic.
export function getProjectionForChartOfAccountsList({
    items,
    itemIdToIndexMap,
    activeId,
    overId,
}: ProjectionForChartOfAccountsList) {
    let overItemIndex = itemIdToIndexMap.get(overId) ?? 0;

    const activeItemIndex = itemIdToIndexMap.get(activeId) ?? 0;
    const activeItem = items[activeItemIndex];

    if (!("account" in activeItem.meta)) {
        throw new Error("Active item has no account and can't have subtype");
    }

    const { start: startOfSubtypeIndex, end: endOfSubtypeIndex } =
        findSubtypeBoundsBasedOnItemIndex(items, activeItemIndex);

    overItemIndex = fixOverItemIndexIfNeeded({
        startOfSubtypeIndex,
        endOfSubtypeIndex,
        overItemIndex,
        items,
        activeItemIndex,
    });

    const newItems = arrayMove(items, activeItemIndex, overItemIndex);
    const projectedDepth = activeItem.depth;

    const maxDepth = 0;
    const minDepth = 0;
    let depth = projectedDepth;

    if (projectedDepth > maxDepth) {
        depth = maxDepth;
    }

    if (projectedDepth < minDepth) {
        depth = minDepth;
    }

    const parentId = null;
    const currentOrderInSubtype = activeItemIndex - startOfSubtypeIndex;
    const targetOrderInSubtype = overItemIndex - startOfSubtypeIndex;

    const targetType = activeItem.meta.account.type;
    const targetSubtype = activeItem.meta.account.subtype;

    return {
        depth,
        maxDepth,
        minDepth,
        parentId,
        overItemIndex,
        activeItemIndex,
        newItems,
        currentOrderInSubtype,
        targetOrderInSubtype,
        targetType,
        targetSubtype,
    };
}

interface FixOverItemIndexIfNeededProps {
    startOfSubtypeIndex: number;
    endOfSubtypeIndex: number;
    overItemIndex: number;
    items: ExtendedFlattenedItemForAccount[];
    activeItemIndex: number;
}

function fixOverItemIndexIfNeeded({
    startOfSubtypeIndex,
    endOfSubtypeIndex,
    overItemIndex,
    items,
    activeItemIndex,
}: FixOverItemIndexIfNeededProps) {
    const activeItem = items[activeItemIndex];
    const overItem = items[overItemIndex];

    if (!("account" in activeItem.meta)) {
        throw new Error("Active item has no account and shouldn't be active");
    }

    const isOverItemAfterActiveItem = overItemIndex > activeItemIndex;

    if (
        !("account" in overItem.meta) ||
        overItem.meta.account.subtype !== activeItem.meta.account.subtype
    ) {
        return isOverItemAfterActiveItem
            ? endOfSubtypeIndex
            : startOfSubtypeIndex;
    }

    return overItemIndex;
}

interface GetOrderInParentProps {
    overItemIndex: number;
    items: FlattenedItem[];
}

export function getOrderInParent({
    overItemIndex,
    items,
}: GetOrderInParentProps) {
    const item = items[overItemIndex];
    const itemsBefore = items
        .slice(0, overItemIndex)
        .filter((i) => i.parentId === item.parentId);

    return itemsBefore.length;
}

export function findSubtypeBoundsBasedOnItemIndex(
    array: ExtendedFlattenedItemForAccount[],
    index: number,
) {
    const item = array[index];
    if (!("account" in item.meta)) {
        throw new Error("Item has no account and can't have subtype");
    }

    const subsetValue = item.meta.account.subtype;

    // Move left until we find a different value or hit the start
    let start = index;
    while (
        start > 0 &&
        "account" in array[start - 1].meta &&
        (array[start - 1].meta as ExtendedFlattenedItemForAccountMetaAccount)
            .account.subtype === subsetValue
    ) {
        start--;
    }

    // Move right until we find a different value or hit the end
    let end = index;
    while (
        end < array.length - 1 &&
        "account" in array[end + 1].meta &&
        (array[end + 1].meta as ExtendedFlattenedItemForAccountMetaAccount)
            .account.subtype === subsetValue
    ) {
        end++;
    }

    return { start, end };
}

export function isAccountWillBeDeletedOnDisabling(account?: ExtendedAccount) {
    if (!account) {
        return true;
    }

    return (
        account.isManuallyCreated && account.debit === 0 && account.credit === 0
    );
}

export function buildFlattenedItemsForChartOfAccounts({
    accounts,
    filters,
}: {
    accounts?: ExtendedAccount[];
    filters: AccountingReportFilters;
}): {
    items: ExtendedFlattenedItemForAccount[];
    itemIdToIndexMap: Map<UniqueIdentifier, number>;
} {
    if (!accounts) {
        return {
            items: [],
            itemIdToIndexMap: new Map(),
        };
    }
    let filteredAccounts = accounts;
    if (filters.accountStatus) {
        const isDisabled = filters.accountStatus === "disabled";
        filteredAccounts = filteredAccounts.filter(
            (a) => a.isDisabled === isDisabled,
        );
    }

    const items: ExtendedFlattenedItemForAccount[] = [];

    for (const [index, account] of filteredAccounts.entries()) {
        if (
            index === 0 ||
            filteredAccounts[index - 1].class !== account.class
        ) {
            const groupKey = [
                AccountType.otherIncome,
                AccountType.otherExpenses,
            ].includes(account.type as AccountType)
                ? (account.type as AccountType)
                : account.class;
            items.push({
                id: groupKey,
                parentId: null,
                depth: 0,
                isVirtual: true,
                children: [],
                meta: {
                    classification: groupKey,
                },
            });
        }
        items.push({
            id: account.id,
            parentId: null,
            depth: 0,
            children: [],
            meta: {
                account,
                lastItemInSubtype:
                    filteredAccounts[index + 1] &&
                    filteredAccounts[index + 1].subtype !== account.subtype,
            },
        });
    }

    return {
        items,
        itemIdToIndexMap: new Map(items.map((item, index) => [item.id, index])),
    };
}
