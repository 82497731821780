import React from "react";
import classNames from "classnames";
import { ChildrenProps } from "../../../types";
import styles from "./SidebarInfo.module.scss";

interface Props extends ChildrenProps {
    className?: string;
}

export const SidebarInfo: React.FC<Props> = ({ className, children }) => (
    <section className={classNames(styles.info, className)}>{children}</section>
);
