import React from "react";
import { Alert } from "react-bootstrap";
import { useBillingManagement } from "../../../hooks/useBillingManagement";
import { currencyFormatterShort } from "../../../common/helpers/currency";
import { PlanManagementProvider } from "../../billing/PlanManagement/PlanManagementProvider";
import { EndTrialAction } from "../../billing/AccessBlockedPage/EndTrialAction";
import { ThunderboltIcon } from "../../../icons";
import { useSettings } from "../../settings/SettingsProvider";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import { flatRatePlanNames } from "../../../common/flatRateBilling";
import { Button } from "../../general/Button/Button";
import styles from "./ReportBillingCta.module.scss";

export const ReportBillingCta: React.FC = () => {
    const { currentUsageLimit, canManageBilling, showUsageLimitReachedCta } =
        useBillingManagement();
    const { isTrialing, currentPlan } = useBillingStatus();
    const { open } = useSettings();

    if (!canManageBilling || (!isTrialing && !showUsageLimitReachedCta)) {
        return null;
    }

    if (showUsageLimitReachedCta) {
        return (
            <Alert variant="default" className={styles.alert}>
                You’ve reached the{" "}
                {currencyFormatterShort.format(currentUsageLimit)} expense
                limit. Upgrade now for full access to historical data.
                <aside>
                    <Button
                        onClick={() => open(["plans"])}
                        variant="action"
                        data-testid="cta-cover-button"
                        size="sm"
                    >
                        <ThunderboltIcon />
                        Upgrade
                    </Button>
                </aside>
            </Alert>
        );
    }

    return (
        <Alert variant="default" className={styles.alert}>
            Your {flatRatePlanNames[currentPlan]} Trial includes up to 4 months
            of data. Start your subscription now to get full access to your
            historicals
            <aside>
                <PlanManagementProvider>
                    <EndTrialAction variant="action" size="sm">
                        Start subscription
                    </EndTrialAction>
                </PlanManagementProvider>
            </aside>
        </Alert>
    );
};
