import React, { useCallback, useMemo } from "react";
import { Entity } from "../../common/types/entity";
import {
    CustomSelect,
    CustomSelectProps,
} from "../forms/CustomSelect/CustomSelect";
import { EntityDisplay } from "./EntityDisplay";

interface Props {
    entities: Entity[];
    selectedEntity?: Entity;
    onChange: (entity: Entity | undefined) => void;
    dropdownKey: string;
    size?: CustomSelectProps["size"];
    disabled?: boolean;
    placeholder?: string;
    className?: string;
    label?: string;
}

export const EntitySelect: React.FC<Props> = ({
    entities,
    selectedEntity,
    onChange,
    placeholder = "Select entity",
    ...selectOptions
}) => {
    const entitiesOptions = useMemo(
        () =>
            entities.map((entity) => ({
                value: entity.id.toString(),
                label: <EntityDisplay entity={entity} />,
            })),
        [entities],
    );

    const onEntityChanged = useCallback(
        (entityId: string) => {
            onChange(entities.find((e) => e.id.toString() === entityId));
        },
        [entities, onChange],
    );

    return (
        <CustomSelect
            value={selectedEntity?.id.toString()}
            onSelected={onEntityChanged}
            options={entitiesOptions}
            placeholder={placeholder}
            {...selectOptions}
        />
    );
};
