import React, { useState } from "react";
import { actionConfigs, ActionKey } from "./lib";
import styles from "./HowItWorks.module.scss";

interface Props {
    actionKey: ActionKey;
    onClosed?: () => void;
    onClosing?: () => void;
    onAction?: () => void;
    open: boolean;
}

export const HowItWorksAction: React.FC<Props> = ({
    actionKey,
    open,
    onClosed,
    onAction,
    onClosing,
}) => {
    const [completed, setCompleted] = useState<boolean>(false);
    const [skipped, setSkipped] = useState<boolean>(false);
    const Action = actionConfigs[actionKey].component;

    return (
        <div className={styles.action}>
            <Action
                key={actionKey}
                onConfirm={() => {
                    setCompleted(true);
                    onAction?.();
                }}
                isCompleted={completed}
                onSkip={() => {
                    setSkipped(true);
                    onAction?.();
                }}
                isSkipped={skipped}
                opened={open}
                onCardClosed={onClosed}
                onCardClosing={onClosing}
            />
        </div>
    );
};
