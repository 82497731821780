import React, { useMemo } from "react";
import { UserRole } from "../../../common/types/userEntityAssignment";
import { Entity } from "../../../common/types/entity";
import { WorkspaceMember } from "../../../common/types/workspace";
import { EntityTeamMember } from "./EntityTeamMember";
import "./EntityTeam.scss";

interface Props {
    entity: Entity;
    members: WorkspaceMember[];
}

const order = [UserRole.ADMIN, UserRole.ACCOUNTANT, UserRole.MEMBER];
const MAX_ASSIGNMENTS = 7;

function getMemberRoleInEntity(entity: Entity, member: WorkspaceMember) {
    return member.entityAssignments.find((ea) => ea.entity.id === entity.id)
        ?.role;
}

export const EntityTeam: React.FC<Props> = ({ entity, members }) => {
    const memberRoles = useMemo(
        () =>
            members.reduce(
                (acc, member) => {
                    const role = getMemberRoleInEntity(entity, member);

                    if (role) {
                        acc[member.user.id] = role;
                    }

                    return acc;
                },
                {} as Record<number, UserRole>,
            ),
        [entity, members],
    );

    const sortedMembers = useMemo(
        () =>
            order.flatMap((role) =>
                (members ?? []).filter((m) => memberRoles[m.user.id] === role),
            ),
        [memberRoles, members],
    );

    const displayedAssignments = sortedMembers.slice(0, MAX_ASSIGNMENTS);

    return (
        <section className="entity-team" data-testid="entity-team">
            {displayedAssignments.map((assignment) => (
                <EntityTeamMember
                    user={assignment.user}
                    role={memberRoles[assignment.user.id]}
                    key={assignment.user.id}
                />
            ))}
            {sortedMembers.length > MAX_ASSIGNMENTS && (
                <div className="entity-team__overflow">
                    +{sortedMembers.length - MAX_ASSIGNMENTS}
                </div>
            )}
        </section>
    );
};
