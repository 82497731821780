import { useNavigate } from "react-router-dom";
import { useDocumentParams } from "./useDocumentParams";

export function useDocumentDetails() {
    const navigate = useNavigate();
    const { documentId } = useDocumentParams();
    return {
        shownDocumentId: documentId ? Number(documentId) : null,
        showDocumentDetails: (financialDocumentId: number) => {
            navigate(`/documents/${financialDocumentId}`);
        },
        closeDetails: () => {
            navigate("/documents");
        },
    };
}
