import React, { useContext } from "react";
import { IgnoreRequirementsIcon } from "../../../icons";
import { Button } from "../../general/Button/Button";
import { usePersonalCategory } from "../../../hooks/usePersonalCategory";
import { TransactionDetailsContext } from "./transactionDetails.context";
import { TransactionActionsMessage } from "./TransactionActionsMessage";

export const TransactionRequirementsIgnoredMessage: React.FC = () => {
    const { onUpdate } = useContext(TransactionDetailsContext);
    const personalCategory = usePersonalCategory();

    return (
        <TransactionActionsMessage
            header={
                <>
                    <IgnoreRequirementsIcon className="icon-color-current" />{" "}
                    Requirements ignored
                </>
            }
        >
            <p>
                This means you may not have the substantiation required by the
                IRS during an audit.
            </p>
            <p>
                <Button
                    variant="tertiary"
                    size="sm"
                    onClick={() =>
                        onUpdate({ categoryId: personalCategory.id })
                    }
                >
                    Mark as personal
                </Button>
                or
                <Button
                    variant="tertiary"
                    size="sm"
                    onClick={() => onUpdate({ requirementsSnoozed: false })}
                >
                    Enable requirements
                </Button>
                to reduce your risk.
            </p>
        </TransactionActionsMessage>
    );
};
