import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
    ActionsIcon,
    ActivityIcon,
    BillingIcon,
    DocumentIcon,
    GraphIcon,
    GrowthIcon,
    LedgerIcon,
    SheetIcon,
} from "../../icons";
import { Command } from "../../vendor/cmdk/cmdk";
import { useProfitLossInitialDates } from "../accounting/useProfitLossInitialDates";
import { CommandItem } from "./CommandItem";
import { useCommands } from "./useCommands";

export const NavigationCommands: FC = () => {
    const navigate = useNavigate();
    const { close: closeCommands } = useCommands();
    const { dateStart, dateEnd } = useProfitLossInitialDates();

    const handleSelected = useCallback(
        (path: string) => {
            navigate(path);
            closeCommands();
        },
        [closeCommands, navigate],
    );

    return (
        <Command.Group heading="Navigation">
            <CommandItem
                shortcut={["G", "A"]}
                onSelect={() => handleSelected("/")}
            >
                <ActionsIcon /> Go to Actions tab
            </CommandItem>

            <CommandItem
                shortcut={["G", "C"]}
                onSelect={() => handleSelected("/cashflow")}
            >
                <GraphIcon /> Go to Cash Flow tab
            </CommandItem>

            <CommandItem
                shortcut={["G", "T"]}
                onSelect={() => handleSelected("/transactions")}
            >
                <ActivityIcon /> Go to Transactions tab
            </CommandItem>

            <CommandItem
                shortcut={["G", "D"]}
                onSelect={() => handleSelected("/documents")}
            >
                <DocumentIcon /> Go to Documents tab
            </CommandItem>

            <CommandItem
                shortcut={["G", "O"]}
                onSelect={() => handleSelected("/accounts")}
            >
                <DocumentIcon /> Go to Accounts tab
            </CommandItem>

            <CommandItem
                shortcut={["G", "I"]}
                onSelect={() => handleSelected("/invoices")}
            >
                <BillingIcon /> Go to Invoicing tab
            </CommandItem>

            <CommandItem
                shortcut={["G", "P"]}
                onSelect={() =>
                    handleSelected(
                        `/accounting/profit-loss?startDate=${dateStart}&endDate=${dateEnd}`,
                    )
                }
            >
                <GrowthIcon /> Go to Profit & Loss
            </CommandItem>

            <CommandItem
                shortcut={["G", "B"]}
                onSelect={() => handleSelected("/accounting/balance-sheet")}
            >
                <SheetIcon /> Go to Balance Sheet
            </CommandItem>

            <CommandItem
                shortcut={["G", "G"]}
                onSelect={() => handleSelected("/accounting/general-ledger")}
            >
                <LedgerIcon /> Go to General Ledger
            </CommandItem>
        </Command.Group>
    );
};
