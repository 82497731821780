import React, { Children, useState } from "react";
import classNames from "classnames";
import "./SelectableItem.scss";
import { Overlay, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/Overlay";
import { useClickOutside } from "../../../hooks/useClickOutside";
import { defaultPopperConfig } from "../../../helpers/overlays";
import { useInteraction } from "../../../hooks/useInteraction";
import { ChildrenProps } from "../../../types";
import { SelectableItemOption } from "./SelectableItemOption";

export interface SelectableItemOptionType<T> {
    key: string | number;
    item: T;
    icon: React.ReactNode;
    title: React.ReactNode;
    description: React.ReactNode;
}

export interface SelectableItemProps<T = any> extends ChildrenProps {
    selected?: boolean;
    disabled?: boolean;
    onSelect?: (selection?: T) => void;
    icon?: React.ReactNode;
    iconSize?: 50 | 60 | 75 | 100;
    title: React.ReactNode;
    description?: React.ReactNode;
    className?: string;
    multiple?: boolean;
    instantSelect?: boolean;
    footer?: React.ReactNode;
    options?: Array<SelectableItemOptionType<T>>;
    optionsId?: string;
    tooltip?: string;
    tooltipId?: string;
    tooltipPlacement?: Placement;
    badge?: React.ReactNode;
}

export const SelectableItem: React.FC<SelectableItemProps> = ({
    selected,
    disabled = false,
    onSelect,
    icon,
    iconSize = 50,
    title,
    description,
    multiple,
    className,
    instantSelect,
    footer,
    children,
    options,
    optionsId,
    tooltip,
    tooltipId,
    tooltipPlacement = "right",
    badge,
}) => {
    const [showOptions, setShowOptions] = useState(false);
    const popoverContainer = useClickOutside<HTMLDivElement>(() =>
        setShowOptions(false),
    );

    if (options && !optionsId) {
        throw new Error(
            "SelectableItem: optionsId param required if options are provided",
        );
    }

    if (tooltip && !tooltipId) {
        throw new Error(
            "SelectableItem: tooltipId param required if tooltip is provided",
        );
    }

    const selectOption = (option: SelectableItemOptionType<any>) => {
        onSelect?.(option.item);
        setShowOptions(false);
    };

    const hasCustomAction = Boolean(Children.count(children));
    const handleActivated = useInteraction(() => {
        if (disabled) {
            return;
        }

        if (options) {
            setShowOptions(!showOptions);
        } else {
            onSelect?.();
        }
    });

    return (
        <>
            <div
                className={classNames("selectable-item", className, {
                    "selectable-item--selected": selected && !instantSelect,
                    "selectable-item--with-options": Boolean(options),
                    "selectable-item--with-options--opened": showOptions,
                    "selectable-item--disabled": disabled,
                    "selectable-item--multiple": multiple,
                    "selectable-item--instant-select": instantSelect,
                    "selectable-item--custom-action": hasCustomAction,
                    "selectable-item--has-footer": footer,
                })}
                {...handleActivated}
                ref={popoverContainer}
            >
                {icon && (
                    <div
                        className={`selectable-item__icon selectable-item__icon--${iconSize}`}
                    >
                        {icon}
                    </div>
                )}

                <div className="selectable-item__content">
                    <div className="selectable-item__text">
                        {badge}
                        <div className="selectable-item__title">{title}</div>
                        {description ? (
                            <div className="selectable-item__description">
                                {description}
                            </div>
                        ) : null}
                    </div>
                </div>

                {hasCustomAction && (
                    <div className="selectable-item__custom-action">
                        {children}
                    </div>
                )}

                {footer && (
                    <div className="selectable-item__footer">{footer}</div>
                )}

                {tooltip && tooltipId && (
                    <OverlayTrigger
                        overlay={<Tooltip id={tooltipId}>{tooltip}</Tooltip>}
                        placement={tooltipPlacement}
                    >
                        <span className="fas fa-question-circle selectable-item__tooltip" />
                    </OverlayTrigger>
                )}
            </div>
            {options && optionsId && (
                <Overlay
                    target={popoverContainer.current}
                    container={popoverContainer.current}
                    show={showOptions}
                    popperConfig={defaultPopperConfig}
                    placement="bottom-start"
                >
                    <Popover
                        id={optionsId}
                        className="selectable-item__options"
                    >
                        {options.map((option) => (
                            <SelectableItemOption
                                icon={option.icon}
                                title={option.title}
                                description={option.description}
                                key={option.key}
                                onClick={() => selectOption(option)}
                            />
                        ))}
                    </Popover>
                </Overlay>
            )}
        </>
    );
};
