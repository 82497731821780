import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Transaction } from "../../../common/types/transaction";
import { EntityIcon } from "../../entity/EntityIcon";

interface Props {
    transaction: Transaction;
}

export const TransactionEntityContent: React.FC<Props> = ({ transaction }) => (
    <div>
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip
                    id={`entity:${transaction.id}`}
                    className="transaction-list-item__description__tooltip"
                >
                    {transaction.entity && (
                        <label>{transaction.entity.name}</label>
                    )}
                </Tooltip>
            }
        >
            <div>
                <EntityIcon entity={transaction.entity} size="sm" />
            </div>
        </OverlayTrigger>
    </div>
);
