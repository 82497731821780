import React from "react";
import classNames from "classnames";

interface Props {
    label: string;
    value: React.ReactNode;
    className?: string;
}

export const TransactionDetailsField: React.FC<Props> = ({
    label,
    value,
    className,
}) => (
    <div
        className={classNames("transaction-details__field", className)}
        data-testid="transaction-details-field"
    >
        <label>{label}</label>
        <span>{value}</span>
    </div>
);
