import React from "react";
import { DemoActionProps } from "../lib";
import { PersonalToBusinessCard } from "../../../../actions/personalToBusiness/PersonalToBusinessCard";
import { ActionCloseButton } from "../../../../actions/ActionCloseButton";
import { usePersonalToBusinessAction } from "./usePersonalToBusinessAction";

export const DemoPersonalToBusiness: React.FC<DemoActionProps> = ({
    ...cardProps
}) => {
    const action = usePersonalToBusinessAction();

    return (
        <PersonalToBusinessCard
            {...cardProps}
            action={action}
            renderCompletedCtas={(closeCard) => (
                <ActionCloseButton onClick={closeCard}>Next</ActionCloseButton>
            )}
        />
    );
};
