import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { Category } from "../../../../../common/types/category";
import { useCategoriesTree } from "../../../../../hooks/useCategoriesTree";
import { FilterSearch } from "../../../../general/FilterSearch/FilterSearch";
import { Tree } from "../../../../general/Tree/Tree";
import { TreeNode } from "../../../../general/Tree/Tree.types";
import { TreeProvider } from "../../../../general/Tree/TreeProvider";
import { FilterActionsFooter } from "../../../../general/Filter/FilterActionsFooter/FilterActionsFooter";
import { BaseFilterProps } from "../../lib";
import css from "./AggregatedCategoryFilter.module.scss";
import { CategoryTreeItem } from "./CategoryTreeItem";
import { useExpandParentCategory } from "./useExpandParentCategory";

export const AggregatedCategoryFilter: React.FC<BaseFilterProps> = ({
    filters,
    onChange,
}) => {
    const [search, setSearch] = useState("");
    const searchRef = useRef<HTMLInputElement>();
    const categories = useCategoriesTree();

    const forceExpanded: string[] = useExpandParentCategory(
        categories,
        filters.category,
    );

    const isChecked = useCallback(
        (category: Category): boolean =>
            filters.category?.find(
                (categoryId) => categoryId === category.id,
            ) != null,
        [filters.category],
    );

    const reverseSelection = () => {
        const newCategories: Category[] = [];

        for (const mainCategory of categoriesToDisplay) {
            if (!filters.category?.some((id) => mainCategory.id === id)) {
                newCategories.push(mainCategory);
            }

            for (const subCategory of mainCategory.children) {
                if (filters.category?.some((id) => subCategory.id === id)) {
                    continue;
                }

                newCategories.push(subCategory);
            }
        }

        onChange({ category: newCategories.map(({ id }) => id) });
    };

    const handleChange = useCallback(
        (category: Category): void => {
            const categoryWithSubcategories: Category[] = [
                category,
                ...(category.subcategories ?? []),
            ];

            let categoryToSet: string[] | undefined = undefined;
            if (isChecked(category)) {
                const newValue =
                    filters.category?.filter(
                        (categoryFilter) =>
                            !categoryWithSubcategories.some(
                                (c) => c.id === categoryFilter,
                            ),
                    ) ?? [];

                categoryToSet = newValue.length > 0 ? newValue : undefined;
            } else {
                categoryToSet = [
                    ...(filters.category ?? []),
                    ...categoryWithSubcategories.map(({ id }) => id),
                ];
            }

            onChange({ category: categoryToSet });

            searchRef.current?.focus();
        },
        [isChecked, filters.category, onChange],
    );

    const categoryMatchesSearch = useCallback(
        (category: Category) =>
            category.label.toLowerCase().includes(search.toLowerCase()),
        [search],
    );

    const categoriesToDisplay = useMemo(() => {
        return categories
            .filter((category) =>
                [category, ...getSubcategories(category)].some((cat) =>
                    categoryMatchesSearch(cat),
                ),
            )
            .map((category) => ({
                ...category,
                children: getSubcategories(category).filter(
                    (subcategory) =>
                        categoryMatchesSearch(subcategory) ||
                        categoryMatchesSearch(category),
                ),
            }));

        function getSubcategories(category: Category): Category[] {
            return category.subcategories ?? [];
        }
    }, [categories, categoryMatchesSearch]);

    useEffect(() => {
        searchRef.current?.focus();

        return () => {
            setSearch("");
        };
    }, []);

    return (
        <div className={css.filter}>
            <FilterSearch
                value={search}
                onChange={setSearch}
                inputRef={searchRef}
            />
            <div className={css.categories}>
                <Scrollbars style={{ width: "100%", height: "100%" }}>
                    {categoriesToDisplay.length > 0 ? (
                        <TreeProvider
                            items={categoriesToDisplay}
                            expandAll={!!search}
                            forceExpanded={forceExpanded}
                        >
                            <Tree
                                renderNode={(node: TreeNode<Category>) => (
                                    <CategoryTreeItem
                                        node={node}
                                        handleChange={handleChange}
                                        isChecked={isChecked}
                                    />
                                )}
                            />
                        </TreeProvider>
                    ) : (
                        <p className={css.empty}>No matching categories</p>
                    )}
                </Scrollbars>
            </div>

            {!!filters.category?.length && (
                <FilterActionsFooter
                    reverseFunction={reverseSelection}
                    deselectFunction={() => onChange({ category: undefined })}
                    selectedCount={filters.category.length}
                />
            )}
        </div>
    );
};
