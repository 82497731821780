/**
 * Receives UUID v7 and returns 8-character string that should be unique across different
 * UUIDs generated around the same time.
 */
export function getShortUuid(uuid: string): string {
    return uuid.slice(-8);
}

export function getShortUuidWithPrefix(uuid: string, prefix: string): string {
    return `${prefix}-${getShortUuid(uuid)}`.toUpperCase();
}

const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export function isUUID(uuid: string): boolean {
    return uuidRegex.test(uuid);
}
