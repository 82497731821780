import React from "react";
import { NavLink } from "react-router-dom";
import { FeatureAccess } from "../general/FeatureAccess/FeatureAccess";
import { PREMIUM_FEATURES } from "../../common/flatRateBilling";
import { useTotalBalance } from "../../hooks/useFinancialAccounts";
import { Amount } from "../general/Amount/Amount";
import { ThunderboltIcon } from "../../icons";
import { useCanCreateEntities } from "../../hooks/permissions/useCanCreateEntities";
import { useCanAddAccounts } from "../../hooks/permissions/useCanAddAccounts";
import { Button } from "../general/Button/Button";
import { AddAccount } from "./AddAccount";

export const AccountsHeader: React.FC = () => {
    const totalBalance = useTotalBalance();
    const canCreateEntities = useCanCreateEntities();
    const canAddAccounts = useCanAddAccounts();

    return (
        <header className="accounts__header">
            <div className="accounts__header__total-balance">
                <span className="label">Cash balance</span>
                <Amount
                    amount={totalBalance}
                    separateCurrencySign
                    showNegative
                />
            </div>
            <div className="d-flex gap-buttons">
                {canCreateEntities && (
                    <FeatureAccess
                        feature={PREMIUM_FEATURES.UNLIMITED_ENTITIES}
                        renderAllowed={() => (
                            <NavLink
                                to="/new-entity"
                                className="btn btn-secondary btn-sm"
                                data-testid="add-entity-btn"
                            >
                                Add entity
                            </NavLink>
                        )}
                        renderBlocked={({ openPlans }) => (
                            <Button
                                variant="secondary"
                                onClick={openPlans}
                                data-testid="add-entity-btn-upgrade"
                                tooltip="Upgrade to add multiple entities to Kick"
                            >
                                <ThunderboltIcon className="icon-color-blue-600" />{" "}
                                Add entity
                            </Button>
                        )}
                    />
                )}

                {canAddAccounts && (
                    <AddAccount btnText="Add account" btnVariant="default" />
                )}
            </div>
        </header>
    );
};
