import { ServerInferRequest } from "@ts-rest/core";
import { useMutation } from "@tanstack/react-query";
import { getBackendAPIClient } from "../lib/backendAPIClient";
import { financialDocumentContract } from "../common/contracts/financial-document.contract";
import { FinancialDocumentDto } from "../common/dto/financialDocument/financial-document.dto";

export const financialDocumentContractClient = getBackendAPIClient(
    financialDocumentContract,
);

export function useCreateUploadUrlMutation() {
    return useMutation({
        mutationFn: async (
            body: ServerInferRequest<
                typeof financialDocumentContract.createUploadUrl
            >["body"],
        ) => {
            const result =
                await financialDocumentContractClient.createUploadUrl({ body });

            return result.body.url;
        },
    });
}

export function useProcessS3Upload(workspaceId: string) {
    return useMutation({
        mutationFn: async (
            body: ServerInferRequest<
                typeof financialDocumentContract.uploadDocumentS3
            >["body"],
        ) => {
            const result =
                await financialDocumentContractClient.uploadDocumentS3({
                    body: {
                        ...body,
                        workspaceId,
                    },
                });

            return result.body.map(FinancialDocumentDto.deserialize);
        },
    });
}
