import { arrayMove } from "@dnd-kit/sortable";
import { UniqueIdentifier } from "@dnd-kit/core";
import { FlattenedItem } from "../../general/AbstractSortableList/types";
import { Class } from "../../../common/types/class";
import { AbstractSortableListItemType } from "../../general/AbstractSortableList/AbstractSortableListItem";
import {
    checkClassLabelConflict,
    ClassWithChildren,
} from "../../../helpers/class";

export function getDragDepth(offset: number, indentationWidth: number) {
    return Math.round(offset / indentationWidth);
}

interface ProjectionForClassesTree {
    items: FlattenedItem[];
    itemIdToIndexMap: Map<UniqueIdentifier, number>;
    activeId: UniqueIdentifier;
    overId: UniqueIdentifier;
    dragDepth: number;
    indentationWidth: number;
    maxPossibleDepth: number;
}

// NOTE: We need to write custom projection logic because
// Classes list has a lot of too-specific edge cases.
// Based on this experience, it's better to duplicate this code
// and modify it instead of trying to make it more generic.
export function getProjectionForClassesTree({
    items,
    itemIdToIndexMap,
    activeId,
    overId,
    dragDepth,
    maxPossibleDepth,
}: ProjectionForClassesTree) {
    let overItemIndex = itemIdToIndexMap.get(overId) ?? 0;

    const activeItemIndex = itemIdToIndexMap.get(activeId) ?? 0;
    const activeItem = items[activeItemIndex];

    overItemIndex = fixOverItemIndexIfNeeded({
        overItemIndex,
        items,
        activeItemIndex,
        itemIdToIndexMap,
    });

    const newItems = arrayMove(items, activeItemIndex, overItemIndex);
    const previousItem = newItems[overItemIndex - 1];
    const nextItem = newItems[overItemIndex + 1];
    const projectedDepth = activeItem.depth + dragDepth;

    const maxDepth = getMaxDepth({
        previousItem,
        maxPossibleDepth,
        activeItem,
    });
    const minDepth = getMinDepth({
        nextItem,
        overItem: items[overItemIndex],
        activeItem,
    });
    let depth = projectedDepth;

    if (projectedDepth > maxDepth) {
        depth = maxDepth;
    }

    if (projectedDepth < minDepth) {
        depth = minDepth;
    }

    const parentId = getParentId({
        depth,
        previousItem,
        newItems,
        overItemIndex,
    });

    return {
        depth,
        maxDepth,
        minDepth,
        parentId,
        overItemIndex,
        newItems,
    };
}

interface GetParentIdProps {
    depth: number;
    previousItem: FlattenedItem;
    newItems: FlattenedItem[];
    overItemIndex: number;
}

function getParentId({
    depth,
    previousItem,
    newItems,
    overItemIndex,
}: GetParentIdProps) {
    if (depth === 0 || !previousItem) {
        return null;
    }

    if (depth === previousItem.depth) {
        return previousItem.parentId;
    }

    if (depth > previousItem.depth) {
        return previousItem.id;
    }

    const newParent = newItems
        .slice(0, overItemIndex)
        .reverse()
        .find((item) => item.depth === depth)?.parentId;

    return newParent ?? null;
}

interface FixOverItemIndexIfNeededProps {
    overItemIndex: number;
    items: FlattenedItem[];
    activeItemIndex: number;
    itemIdToIndexMap: Map<UniqueIdentifier, number>;
}

function fixOverItemIndexIfNeeded({
    overItemIndex,
    items,
    activeItemIndex,
    itemIdToIndexMap,
}: FixOverItemIndexIfNeededProps) {
    const activeItem = items[activeItemIndex];
    const isOverItemAfterActiveItem = overItemIndex > activeItemIndex;

    // NOTE: prevent impossible action with cycled parenting
    if (items[overItemIndex].parentId === activeItem.id) {
        const firstRootElement = items.findIndex(
            ({ parentId, id }) =>
                parentId === activeItem.id || id === activeItem.id,
        );

        overItemIndex = Math.max(firstRootElement, 0);
    }

    // NOTE: prevent impossible action with nesting more than 1 level
    // support only 2-level nesting
    if (
        activeItem.children.some((c) => !c.isVirtual) &&
        overItemIndex !== activeItemIndex
    ) {
        if (items[overItemIndex].parentId) {
            const parentIndex =
                itemIdToIndexMap.get(items[overItemIndex].parentId as string) ??
                0;
            const nextParent = items
                .slice(overItemIndex)
                .find(
                    ({ parentId }) =>
                        parentId !== items[overItemIndex].parentId,
                );
            const nextParentIndex = nextParent
                ? itemIdToIndexMap.get(nextParent.id) ?? -1
                : -1;
            if (
                Math.abs(nextParentIndex - overItemIndex) -
                    (isOverItemAfterActiveItem ? 1 : 0) <
                Math.abs(parentIndex - overItemIndex)
            ) {
                overItemIndex = nextParentIndex;
            } else {
                overItemIndex = parentIndex;
            }
        }
        // NOTE: hack to deal with unexpected/tricky DnD behavior
        overItemIndex = overItemIndex + (isOverItemAfterActiveItem ? -1 : 0);
    }

    return overItemIndex;
}

interface GetOrderInParentProps {
    overItemIndex: number;
    items: FlattenedItem[];
}

export function getOrderInParent({
    overItemIndex,
    items,
}: GetOrderInParentProps) {
    const item = items[overItemIndex];
    const itemsBefore = items
        .slice(0, overItemIndex)
        .filter((i) => i.parentId === item.parentId);

    return itemsBefore.length;
}

interface GetMaxDepthProps {
    previousItem: FlattenedItem;
    maxPossibleDepth: number;
    activeItem: FlattenedItem;
}

function getMaxDepth({
    previousItem,
    maxPossibleDepth,
    activeItem,
}: GetMaxDepthProps) {
    if (previousItem) {
        const depthOfParent =
            previousItem.depth - (previousItem.parentId ? 1 : 0);
        return Math.min(
            // NOTE: this might be too-specific for Classes case as virtual items
            // are used only for "add new item" placeholder
            previousItem.isVirtual ? depthOfParent : previousItem.depth + 1,
            maxPossibleDepth,
            activeItem.children.some((c) => !c.isVirtual) ? 0 : Infinity,
        );
    }

    return 0;
}

interface GetMinDepthProps {
    nextItem: FlattenedItem;
    overItem: FlattenedItem;
    activeItem: FlattenedItem;
}

function getMinDepth({ nextItem, overItem, activeItem }: GetMinDepthProps) {
    if (overItem === activeItem) {
        return activeItem.depth;
    }
    if (nextItem && !activeItem.children.some((c) => !c.isVirtual)) {
        return nextItem.depth;
    }

    return 0;
}

export type ExtendedFlattenedItem = AbstractSortableListItemType & {
    meta:
        | { classInstance: Class; isHidden?: boolean }
        | { addClass: true; parentClassId: string | null; isHidden?: boolean };
};

export function buildFlattenedItems(
    classesWithChildren: ClassWithChildren[],
    search: string,
): {
    items: ExtendedFlattenedItem[];
    itemIdToIndexMap: Map<UniqueIdentifier, number>;
} {
    const items: ExtendedFlattenedItem[] = [];

    for (const c of classesWithChildren) {
        if (c.parentClassId) {
            continue;
        }
        const shouldShowParent =
            search.length === 0 ||
            c.label.toLowerCase().includes(search.toLowerCase()) ||
            c.children.some((child) =>
                child.label.toLowerCase().includes(search.toLowerCase()),
            );

        items.push({
            id: c.id,
            parentId: c.parentClassId,
            depth: c.parentClassId ? 1 : 0,
            children: c.children.map((child) => ({
                id: child.id,
                children: [],
            })),
            meta: {
                classInstance: c,
                isHidden: !shouldShowParent,
            },
        });
        c.children.forEach((child) => {
            const shouldShowChild =
                search.length === 0 ||
                child.label.toLowerCase().includes(search.toLowerCase());

            items.push({
                id: child.id,
                parentId: c.id,
                depth: 1,
                children: [],
                meta: {
                    classInstance: child,
                    isHidden: !shouldShowChild,
                },
            });
        });
        items.push({
            id: `addSubClass:${c.rootClassId}`,
            parentId: c.id,
            depth: 1,
            children: [],
            meta: {
                parentClassId: c.id,
                addClass: true,
                isHidden: !shouldShowParent,
            },
            isVirtual: true,
        });
    }
    if (search.length === 0) {
        items.push({
            id: `addClass`,
            parentId: null,
            depth: 0,
            children: [],
            meta: {
                parentClassId: null,
                addClass: true,
            },
            isVirtual: true,
        });
    }
    const itemIdToIndexMap = new Map(
        items.map((item, index) => [item.id, index]),
    );
    return { items, itemIdToIndexMap };
}

export function getErrorForAddClass(
    classes: Class[],
    parentClassId: string | null,
    label: string,
) {
    const { conflict, conflictingClass } = checkClassLabelConflict({
        classes,
        parentClassId,
        label,
    });
    if (conflict) {
        if (conflictingClass?.id === parentClassId) {
            return "Subclass can't have the same name as the parent";
        } else if (parentClassId !== null) {
            return "Subclass with this name already exists in the same class";
        } else {
            return "Class with this name already exists";
        }
    }
    return null;
}
