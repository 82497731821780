import { FieldArray, Form, useFormikContext } from "formik";
import { useEffect } from "react";
import classNames from "classnames";
import { Transaction } from "../../../common/types/transaction";
import { Entity } from "../../../common/types/entity";
import { UpdateTransactionSplitV2Dto } from "../../../common/dto/transactions/update-transaction-split-v2.dto";
import gridTable from "../../../styles/grid-table.module.scss";
import { Taxonomy } from "../../../common/categories";
import {
    fromIntToMonetary,
    fromMonetaryToInt,
} from "../../../common/helpers/monetary";
import { useStandardCategories } from "../../../hooks/useStandardCategories";
import { Button } from "../../general/Button/Button";
import { PlusIcon } from "../../../icons";
import styles from "./TransactionSplitModal.module.scss";
import { TransactionSplitRow } from "./TransactionSplitRow";

interface Props {
    transaction: Transaction;
    entities: Entity[];
}

const areAmountsSymmetric = (
    splits: UpdateTransactionSplitV2Dto[],
): boolean => {
    const firstAmount = splits[0].amount;
    return splits.every((split) => split.amount === firstAmount);
};

export const TransactionSplitForm: React.FC<Props> = ({
    transaction,
    entities,
}) => {
    const { values, setFieldValue } = useFormikContext<{
        splits: UpdateTransactionSplitV2Dto[];
    }>();

    const categories = useStandardCategories();

    const parentSplit = values.splits.find(
        (split) => split.id === transaction.id,
    );

    const handleEntityChange = (
        index: number,
        entityId: number | undefined,
    ) => {
        setFieldValue(`splits.${index}.entityId`, entityId);

        const entity = entities.find((e) => e.id === entityId);
        if (!entity || entity.isPersonal) {
            setFieldValue(
                `splits.${index}.categoryId`,
                categories[Taxonomy.personal].id,
            );
        }
    };

    const handleAddSplit = () => {
        const currentSplits = values.splits;
        if (areAmountsSymmetric(currentSplits)) {
            const newAmountInCents = Math.floor(
                fromMonetaryToInt(transaction.originalAmount) /
                    (currentSplits.length + 1),
            );
            const newAmount = fromIntToMonetary(newAmountInCents);

            setFieldValue("splits", [
                ...currentSplits.map((split) => ({
                    ...split,
                    amount: newAmount,
                })),
                {
                    categoryId: parentSplit?.categoryId,
                    entityId: parentSplit?.entityId,
                    amount: newAmount,
                },
            ]);
        } else {
            setFieldValue("splits", [
                ...currentSplits,
                {
                    categoryId: parentSplit?.categoryId,
                    entityId: parentSplit?.entityId,
                    amount: fromIntToMonetary(
                        Math.floor(
                            fromMonetaryToInt(parentSplit?.amount ?? 0) / 2,
                        ),
                    ),
                },
            ]);
        }
    };

    const handleRemoveSplit = (
        index: number,
        remove: (index: number) => void,
    ) => {
        const currentSplits = values.splits;
        if (areAmountsSymmetric(currentSplits)) {
            remove(index);

            const newAmountInCents = Math.floor(
                fromMonetaryToInt(transaction.originalAmount) /
                    (currentSplits.length - 1),
            );
            const newAmount = fromIntToMonetary(newAmountInCents);

            currentSplits.forEach((_, i) => {
                if (i !== index) {
                    // Skip the removed index
                    setFieldValue(
                        `splits.${i < index ? i : i - 1}.amount`,
                        newAmount,
                    );
                }
            });
        } else {
            remove(index);
        }
    };

    useEffect(() => {
        const parentIndex = values.splits.findIndex(
            (split) => split.id === transaction.id,
        );

        if (values.splits.some((s) => isNaN(fromMonetaryToInt(s.amount)))) {
            return;
        }

        if (parentIndex !== -1) {
            const nonParentTotal = values.splits
                .filter((split) => split.id !== transaction.id)
                .reduce((sum, split) => {
                    const amountInCents = fromMonetaryToInt(split.amount);
                    return sum + amountInCents;
                }, 0);

            const originalAmountInCents = fromMonetaryToInt(
                transaction.originalAmount,
            );

            const parentAmountInCents = originalAmountInCents - nonParentTotal;

            const parentAmount = fromIntToMonetary(parentAmountInCents);

            if (values.splits[parentIndex].amount !== parentAmount) {
                setFieldValue(`splits.${parentIndex}.amount`, parentAmount);
            }
        }
    }, [
        values.splits,
        transaction.originalAmount,
        transaction.id,
        setFieldValue,
    ]);

    return (
        <Form>
            <section className={styles.container}>
                <div
                    className={classNames(
                        styles.gridTable,
                        gridTable.gridTable,
                    )}
                >
                    <div className={classNames(gridTable.headerRow)}>
                        <div></div>
                        <div>Description</div>
                        <div className={styles.amountColumnHeader}>Amount</div>
                        <div>Category</div>
                        <div>Entity</div>
                        <div></div>
                    </div>
                    <FieldArray name="splits">
                        {({ remove }) => (
                            <>
                                {values.splits.map((split, index) => (
                                    <TransactionSplitRow
                                        key={`${split.id}-${index}`}
                                        split={split}
                                        index={index}
                                        isParent={split.id === transaction.id}
                                        entities={entities}
                                        transaction={transaction}
                                        onRemove={() =>
                                            handleRemoveSplit(index, remove)
                                        }
                                        setFieldValue={setFieldValue}
                                        onEntityChange={(entityId) =>
                                            handleEntityChange(index, entityId)
                                        }
                                    />
                                ))}
                            </>
                        )}
                    </FieldArray>
                </div>
                <div className="mt-3 ml-5 mb-3">
                    <Button
                        type="button"
                        variant="secondary"
                        onClick={handleAddSplit}
                    >
                        <PlusIcon />
                        Add split
                    </Button>
                </div>
            </section>
        </Form>
    );
};
