import { useEffect } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { SimpleTable } from "../../../general/Tables/SimpleTable/SimpleTable";
import { JournalEntry } from "../../../../common/types/domains/accounting/journalEntry";
import { formatDate } from "../../../../helpers/date";
import { getShortJournalEntryReferenceNumber } from "../../../../common/helpers/accounting";
import { accountingNumberFormatter } from "../../../../common/helpers/number";
import { sumMonetaryValues } from "../../../../common/helpers/monetary";
import { Button } from "../../../general/Button/Button";
import { Loader } from "../../../general/Loader";
import {
    StandardModalBody,
    StandardModalHeader,
} from "../../../general/Modal/Modal";
import styles from "./RemoveFinancialAccount.module.scss";

interface RemoveAccountManualJournalsReviewContentProps {
    journalEntries: UseQueryResult<JournalEntry[], unknown>;
    back: () => void;
    forward: () => void;
    entityId: number;
}

const columns = [
    { key: "date", label: "Date" },
    { key: "reference", label: "Reference" },
    { key: "debitCredit", label: "Debit/Credit" },
];

export function RemoveAccountManualJournalsReviewContent({
    journalEntries,
    back,
    forward,
    entityId,
}: RemoveAccountManualJournalsReviewContentProps) {
    const onJournalEntryClick = (journalEntry: JournalEntry) => {
        window.open(
            `/accounting/general-ledger?entityId=${entityId}&openedJournalEntryId=${journalEntry.id}&openJournalEntryModal=1`,
            "_blank",
            "noopener,noreferrer",
        );
    };

    useEffect(() => {
        if (journalEntries.isFetched && journalEntries.data?.length === 0) {
            forward();
        }
    }, [journalEntries, forward]);

    if (journalEntries.isLoading) {
        return <Loader />;
    }

    return (
        <>
            <StandardModalHeader>
                This account has manual journals
            </StandardModalHeader>
            <StandardModalBody>
                <p className={styles.modalText}>
                    To remove this account, adjust the below journal entries
                    first.
                </p>
                <SimpleTable
                    className={styles.manualJournalsTable}
                    columns={columns}
                    rows={
                        journalEntries.data?.map((journalEntry) => ({
                            id: journalEntry.id,
                            cells: [
                                { value: formatDate(journalEntry.date) },
                                {
                                    value: getShortJournalEntryReferenceNumber(
                                        journalEntry.id,
                                    ),
                                },
                                {
                                    value: accountingNumberFormatter(
                                        journalEntry.lines.reduce(
                                            (sum, line) =>
                                                sumMonetaryValues([
                                                    sum,
                                                    line.debitAmount ?? 0,
                                                ]),
                                            0,
                                        ),
                                    ),
                                },
                            ],
                            onClick: () => onJournalEntryClick(journalEntry),
                        })) ?? []
                    }
                    columnBorders
                />
                <footer className="d-flex justify-content-between">
                    <Button
                        variant="secondary"
                        className="mr-2"
                        size="lg"
                        onClick={back}
                    >
                        Back
                    </Button>
                </footer>
            </StandardModalBody>
        </>
    );
}
