import { useCallback, useEffect, useState } from "react";
import { KeyboardReturnIcon } from "../../icons";
import { TimerSpinner } from "../general/TimerSpinner/TimerSpinner";
import { useKeyboardCommands } from "../../hooks/keyboard/useKeyboardCommands";
import { useTimer } from "../../hooks/useTimer";
import { Button, ExtendedButtonProps } from "../general/Button/Button";
import { KeyboardShortcut } from "../general/KeyboardShortcut/KeyboardShortcut";

interface Props extends ExtendedButtonProps {
    onClick: () => void;
    loading?: boolean;
    showKeyboardShortcut?: boolean;
    duration?: number;
}

export const ActionCloseButton: React.FC<Props> = ({
    onClick,
    showKeyboardShortcut,
    children = "Close",
    duration = 2000,
    ...rest
}) => {
    const [timerStarted, setTimerStarted] = useState(false);
    const { start, clear } = useTimer(onClick, duration);

    useKeyboardCommands({
        commands: [
            {
                key: "Enter",
                callback: onClick,
                requiresCtrlOrMeta: false,
                repeat: false,
            },
        ],
    });

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            clear();
            onClick();
        },
        [clear, onClick],
    );

    useEffect(() => {
        if (!timerStarted) {
            setTimerStarted(true);
            start();
        }
    }, [timerStarted, start]);

    return (
        <Button variant="secondary" onClick={handleClick} {...rest}>
            <TimerSpinner duration={duration} className="mr-2" /> {children}
            {showKeyboardShortcut && (
                <KeyboardShortcut square>
                    <KeyboardReturnIcon />
                </KeyboardShortcut>
            )}
        </Button>
    );
};
