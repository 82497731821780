import {
    ProfitLossReportMetaDto,
    ProfitLossTableReportSectionsDto,
} from "./reports-common.dto";

export interface ProfitLossReportDto {
    columnLabels: string[];
    sections: ProfitLossTableReportSectionsDto;
    chart: ProfitLossChartReportDto;
    meta: ProfitLossReportMetaDto;
}

export interface TransactionItemEntry {
    description: string;
    value: number;
    id: string;
}

export interface ProfitLossTopTransactionsDto {
    top: TransactionItemEntry[];
    other: number;
}

export enum ProfitLossChartDataSeriesCalculatedIdentifier {
    OTHER = "other",
    INCOME_REST = "income_rest",
    DUMMY = "dummy",
}

export interface ProfitLossChartDataSeriesItem {
    label: ProfitLossChartReportSeriesIdentifier;
    parentCategory: string | null;
}

export type ProfitLossChartReportSeriesIdentifier =
    | ProfitLossChartDataSeriesCalculatedIdentifier
    | string;

export interface ProfitLossChartReportDto {
    data: ProfitLossChartReportSeriesData[];
    series: ProfitLossChartDataSeriesItem[];
}

export type ProfitLossChartReportSeriesData = {
    label: string;
} & Record<ProfitLossChartReportSeriesIdentifier, number>;
