import { OverlayTriggerType } from "react-bootstrap/esm/OverlayTrigger";
import { useSortable } from "@dnd-kit/sortable";
import { useState } from "react";
import classNames from "classnames";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ExtendedAccount } from "../../../common/contracts/accounting-reports";
import {
    AccountType,
    AccountSubtype,
} from "../../../common/constants/domains/accounting/accountsv2";
import { useEnableAccountMutation } from "../../../api/account.api";
import { AddIcon, DragHandle6DotIcon } from "../../../icons";
import { ManagementDropdown } from "../../general/ManagementDropdown/ManagementDropdown";

import { Button } from "../../general/Button/Button";
import styles from "./styles.module.scss";
import { isAccountWillBeDeletedOnDisabling } from "./utils";

const tooltipTriggers: OverlayTriggerType[] = ["hover", "focus"];

interface Props {
    itemId: string;
    account: ExtendedAccount;
    isDragged: boolean;
    entityId: number;
    openAddAccountModal: ({
        accountType,
        subType,
        newAccountIdToEdit,
    }: {
        accountType: AccountType;
        subType: AccountSubtype;
        newAccountIdToEdit?: string;
    }) => void;
    onDisableAccount: (accountId: string) => void;
    canReorder: boolean;
}

export function ChartOfAccountsItemActions({
    account,
    entityId,
    openAddAccountModal,
    onDisableAccount,
    canReorder,
    itemId,
}: Readonly<Props>) {
    const { mutate: enableAccount } = useEnableAccountMutation();

    const [dropdownOpened, setDropdownOpened] = useState(false);

    return (
        <div
            className={classNames(
                styles.columnContent,
                styles.actionsColumn,
                dropdownOpened && styles.actionsColumnAlwaysVisible,
            )}
        >
            {canReorder && <ActionDragListeners itemId={itemId} />}

            <ManagementDropdown
                id={`chart-of-accounts-item-dropdown__${itemId}`}
                className={classNames(
                    styles.actionIconContainer,
                    styles.dropdownButton,
                )}
                popperConfigStrategy="fixed"
                onToggle={(isOpen) => {
                    setDropdownOpened(isOpen);
                }}
            >
                <Dropdown.Item
                    onClick={() => {
                        openAddAccountModal({
                            accountType: account.type as AccountType,
                            subType: account.subtype as AccountSubtype,
                            newAccountIdToEdit: account.id,
                        });
                    }}
                >
                    Rename
                </Dropdown.Item>
                {!account.financialAccountId && !account.isDisabled && (
                    <Dropdown.Item
                        className={styles.deactivateButton}
                        onClick={() => {
                            onDisableAccount(account.id);
                        }}
                    >
                        {isAccountWillBeDeletedOnDisabling(account)
                            ? "Delete"
                            : "Deactivate"}
                    </Dropdown.Item>
                )}
                {account.isDisabled && (
                    <Dropdown.Item
                        onClick={() => {
                            enableAccount({
                                params: {
                                    entityId: String(entityId),
                                    id: account.id,
                                },
                                body: {},
                            });
                        }}
                    >
                        Enable
                    </Dropdown.Item>
                )}
            </ManagementDropdown>

            <OverlayTrigger
                trigger={tooltipTriggers}
                overlay={
                    <Tooltip id={`add-account-to-subtype`}>
                        Add account to subtype {account.subtype}
                    </Tooltip>
                }
            >
                <Button
                    onClick={() => {
                        openAddAccountModal({
                            accountType: account.type as AccountType,
                            subType: account.subtype as AccountSubtype,
                        });
                    }}
                    variant="tertiary"
                    size="sm"
                    icon
                >
                    <AddIcon
                        className={classNames(
                            styles.actionIcon,
                            "icon-color-gray-900",
                        )}
                    />
                </Button>
            </OverlayTrigger>
        </div>
    );
}

function ActionDragListeners({ itemId }: { itemId: string }) {
    const { attributes, listeners } = useSortable({ id: itemId });

    return (
        <Button
            {...attributes}
            {...listeners}
            icon
            variant="tertiary"
            size="sm"
        >
            <DragHandle6DotIcon
                className={classNames(styles.actionIcon, "icon-color-gray-900")}
            />
        </Button>
    );
}
