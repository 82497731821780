import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ActionCategory } from "../../common/types/actions";
import { ActionsActivityList } from "./ActionsActivityList";
import { ActionsList } from "./ActionsList";

interface Params {
    category: ActionCategory;
}

export const ActionsPage: React.FC = () => {
    const { category = ActionCategory.PERSONALIZE } =
        useParams() as Readonly<Params>;
    return (
        <Col className="d-flex flex-column gap-4 p-0">
            <Row>
                <Col sm={12} md={8}>
                    <ActionsList category={category} />
                </Col>
                <Col md={4} className="d-none d-md-block">
                    <ActionsActivityList />
                </Col>
            </Row>
        </Col>
    );
};
