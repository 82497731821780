import { useEffect } from "react";
import {
    ColumnDef,
    createRow,
    getCoreRowModel,
    getExpandedRowModel,
    getGroupedRowModel,
    Row,
    useReactTable,
} from "@tanstack/react-table";
import { useStandardCategories } from "../../../hooks/useStandardCategories";
import { ProfitLossTableReportSection } from "../../../common/dto/reports/reports-common.dto";
import { PLDataRow } from "./types";
import { rowExpansionHelper } from "./rowExpansionHelper";

export const sectionLabels: Record<ProfitLossTableReportSection, string> = {
    [ProfitLossTableReportSection.INCOME]: "Income",
    [ProfitLossTableReportSection.COGS]: "Costs of Good Sold",
    [ProfitLossTableReportSection.EXPENSES]: "Expenses",
    [ProfitLossTableReportSection.GROSS_PROFIT]: "Gross Profit",
    [ProfitLossTableReportSection.NET_OPERATING_INCOME]: "Net Operating Income",
    [ProfitLossTableReportSection.NET_INCOME]: "Net Income",
    [ProfitLossTableReportSection.OTHER_EXPENSES]: "Other Expenses",
    [ProfitLossTableReportSection.OTHER_INCOME]: "Other Income",
    [ProfitLossTableReportSection.NET_OTHER_INCOME]: "Net Other Income",
    [ProfitLossTableReportSection.CASH_BALANCE]: "", // dummy, not displayed
};

export enum PLColumns {
    TITLE = "title",
    SECTION = "section",
    PARENT_CATEGORY = "parentCategory",
    TOTAL = "total",
}

export enum PLColumnLabels {
    TOTAL = "Total",
}

export function isCustomPLColumnLabel(label: string): label is PLColumnLabels {
    return label in PLColumnLabels;
}

export function useProfitLossReportTable(
    data: PLDataRow[],
    columns: Array<ColumnDef<PLDataRow>>,
    grouping: PLColumns[],
) {
    const categoryMap = useStandardCategories();

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        enableExpanding: true,
        state: {
            grouping,
        },
        groupedColumnMode: "remove",
    });

    useEffect(() => {
        const addTotalsRow = (row: Row<PLDataRow>) => {
            row.subRows = row.subRows.filter((r) => !r.original.isTotal);

            const { canExpand } = rowExpansionHelper(row, categoryMap);
            if (canExpand) {
                const totalsRow = createRow<PLDataRow>(
                    table as any,
                    `${row.id}>total`,
                    {
                        ...row.original,
                        isTotal: true,
                    },
                    row.index + 1,
                    row.depth + 1,
                    [],
                    row.id,
                );

                row.subRows.forEach(addTotalsRow);
                row.subRows.push(totalsRow as any);
            }
        };

        table.getRowModel().rows.forEach(addTotalsRow);
    }, [categoryMap, table, data]);

    return table;
}
