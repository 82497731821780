import classNames from "classnames";
import { ChildrenProps } from "../../types";
import styles from "./ActionCardDetail.module.scss";

interface Props extends ChildrenProps {
    label: string;
    required?: boolean;
}

export const ActionCardDetail: React.FC<Props> = ({
    label,
    children,
    required,
}) => (
    <div
        className={classNames(styles.detailItem, {
            [styles.required]: required,
        })}
    >
        <div className={styles.detailLabel}>{label}</div>
        <div className={styles.detailValue}>{children}</div>
    </div>
);
