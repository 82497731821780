import { FlatRatePrice } from "../../../flatRateBilling";
import { SubscriptionDiscount } from "../../../types/billing";

export class CalculatePriceResponseDto {
    static deserialize(dto: CalculatePriceResponseDto): FlatRatePrice {
        return {
            ...dto,
            nextBillingDate: new Date(dto.nextBillingDate),
        };
    }

    nextBillingDate: string;

    constructor(
        public total: number,
        public totalBeforeDiscount: number,
        public discount: SubscriptionDiscount | null,
        public invalidDiscountCode: boolean,
        nextBillingDate: Date,
    ) {
        this.nextBillingDate = nextBillingDate.toISOString();
    }
}
