import React, { useState } from "react";
import { Form, Formik, FormikConfig } from "formik";
import { object, string } from "yup";
import classNames from "classnames";
import { Counterparty } from "../../common/types/counterparty";
import { FormRow } from "../forms/FormRow";
import { Button } from "../general/Button/Button";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";
import { submitHelper } from "../../helpers/form";
import { AddressForm } from "../forms/AddressForm";
import { Card } from "../general/Card/Card";
import { PhoneNumberInput } from "../phoneNumber/PhoneNumberInput";
import styles from "./CounterpartyForm.module.scss";

interface Props {
    counterparty?: Counterparty;
    fullWidth?: boolean;
    onSubmit: (data: FormSchema) => Promise<void>;
    submitText: string;
    onCancelled: () => void;
}

export interface FormSchema {
    name: string;
    email: string | null;
    phoneNumber: string | null;
    website: string | null;
    street: string | null;
    street2: string | null;
    city: string | null;
    postalCode: string | null;
    state: string | null;
    country: string | null;
}

export const CounterpartyForm: React.FC<Props> = ({
    counterparty,
    fullWidth,
    onSubmit,
    onCancelled,
    submitText,
}) => {
    const [loading, setLoading] = useState(false);

    const form: FormikConfig<FormSchema> = {
        initialValues: {
            name: counterparty?.name ?? "",
            email: counterparty?.email ?? "",
            phoneNumber: counterparty?.phoneNumber ?? "",
            website: counterparty?.website ?? "",
            street: counterparty?.street ?? "",
            street2: counterparty?.street2 ?? "",
            city: counterparty?.city ?? "",
            postalCode: counterparty?.postalCode ?? "",
            state: counterparty?.state ?? "",
            country: counterparty?.country ?? "US",
        },
        validationSchema: object({
            name: string().required("Name is required"),
        }),
        onSubmit: submitHelper({
            handler: ({
                name,
                city,
                email,
                phoneNumber,
                website,
                postalCode,
                street,
                street2,
                country,
                state,
            }) => {
                if (country !== "US") {
                    state = null;
                }

                return onSubmit({
                    name,
                    city: city || null,
                    email: email || null,
                    phoneNumber: phoneNumber || null,
                    website: website || null,
                    postalCode: postalCode || null,
                    street: street || null,
                    street2: street2 || null,
                    country: country || null,
                    state: state || null,
                });
            },
            loading,
            setLoading,
        }),
    };

    return (
        <Formik {...form}>
            <Form>
                <section className={styles.section}>
                    <h4>Basic info</h4>
                    <Card>
                        <div
                            className={classNames({
                                [styles.narrowContainer]: !fullWidth,
                            })}
                        >
                            <FormRow fieldName="name" label="Name" size="xs" />
                            <FormRow
                                fieldName="email"
                                label="Email"
                                size="xs"
                            />
                            <PhoneNumberInput size="xs" />
                            <FormRow
                                fieldName="website"
                                label="Website"
                                size="xs"
                            />
                        </div>
                    </Card>
                </section>

                <section className={styles.section}>
                    <h4>Address</h4>
                    <Card>
                        <div
                            className={classNames({
                                [styles.narrowContainer]: !fullWidth,
                            })}
                        >
                            <AddressForm
                                includeCountry
                                includeStreet2
                                prefix=""
                                fieldSize="xs"
                            />
                        </div>
                    </Card>
                </section>

                <footer
                    className={classNames(styles.footer, {
                        [styles.narrowFooter]: !fullWidth,
                    })}
                >
                    <Button
                        type="button"
                        variant="secondary"
                        onClick={onCancelled}
                    >
                        Cancel
                    </Button>
                    <ButtonWithLoader
                        type="submit"
                        variant="default"
                        loading={loading}
                    >
                        {submitText}
                    </ButtonWithLoader>
                </footer>
            </Form>
        </Formik>
    );
};
