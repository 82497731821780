import React from "react";
import classNames from "classnames";
import gridTable from "../../../styles/grid-table.module.scss";
import { Counterparty } from "../../../common/types/counterparty";
import { TransactionRule } from "../../../common/types/transactionRule";
import styles from "./TransactionRules.module.scss";
import { RuleSettings } from "./RuleSettings";

interface Props {
    rules: TransactionRule[];
    counterparties: Counterparty[];
    transactionCounts: Record<string, number>;
}

export const TransactionRules: React.FC<Props> = ({
    rules,
    transactionCounts,
    ...rest
}) => (
    <section className={classNames(styles.container, gridTable.gridTable)}>
        <div
            className={classNames(
                styles.row,
                gridTable.headerRow,
                gridTable.columnBorders,
            )}
        >
            <div>Condition</div>
            <div>Action</div>
            <div>Transactions</div>
        </div>
        {rules.map((rule) => (
            <div
                className={classNames(
                    styles.row,
                    gridTable.row,
                    gridTable.columnBorders,
                )}
                key={rule.id}
            >
                <RuleSettings
                    rule={rule}
                    transactionsCount={transactionCounts[rule.id] ?? 0}
                    {...rest}
                />
            </div>
        ))}
    </section>
);
