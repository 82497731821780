import { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
    useAccountDisablingMutation,
    useFinancialAccountRemovalMutation,
} from "../../../../mutations/financialAccount";

import { StandardModal } from "../../../general/Modal/Modal";
import {
    FinancialAccount,
    FinancialAccountSourceType,
} from "../../../../common/types/financialAccount";
import { getManualJournalEntries } from "../../../../lib/accounting";
import { useToaster } from "../../../general/ToastMessages/useToaster";
import { RemoveAccountAttentionContent } from "./RemoveAccountAttentionContent";
import { RemoveAccountManualJournalsReviewContent } from "./RemoveAccountManualJournalsReviewContent";
import { RemoveAccountConfirmationContent } from "./RemoveAccountConfirmationContent";

interface Props {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    account: FinancialAccount;
    closeModal: () => void;
}

enum RemoveAccountConfirmationStep {
    Attention,
    ManualJournalsReview,
    Confirmation,
}

export function RemoveFinancialAccountModal({
    showModal,
    setShowModal,
    account,
    closeModal,
}: Props) {
    const { toast } = useToaster();

    const removeAccount = useFinancialAccountRemovalMutation(account, () => {
        toast("Account successfully removed");
        closeModal();
    });
    const disableAccount = useAccountDisablingMutation(account, () => {
        toast("Account disabled. We won't bring new data for this account.");
        closeModal();
    });
    const [selectedOption, setSelectedOption] = useState(
        "keep-historical-data",
    );

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.id);
    };

    const manualJournalEntries = useQuery({
        queryKey: ["manualJournalEntries", account.entityId, account.id],
        queryFn: () =>
            getManualJournalEntries(account.entityId!, {
                financialAccountId: account.id,
            }),
        enabled: !!account.isBusiness,
    });

    const [confirmationStep, setConfirmationStep] = useState(
        RemoveAccountConfirmationStep.Attention,
    );

    const hasManualJournals =
        manualJournalEntries.data && manualJournalEntries.data.length > 0;

    const onRemoveAccount = useCallback(() => {
        if (manualJournalEntries.isLoading) {
            return;
        }
        if (hasManualJournals) {
            setConfirmationStep(
                RemoveAccountConfirmationStep.ManualJournalsReview,
            );
            return;
        }
        setConfirmationStep(RemoveAccountConfirmationStep.Confirmation);
    }, [manualJournalEntries.isLoading, hasManualJournals]);

    const removeAccountLoadingState = hasManualJournals
        ? false
        : removeAccount.isPending;

    const accountDoesntHaveConnection =
        account.sourceType === FinancialAccountSourceType.FILE_IMPORT;

    useEffect(() => {
        // NOTE: The difference with file imported accounts is that for them we don't need a first step
        // and automatically transition them to the next one in the state machine (MJs review)
        // At the same time, MJs review state will be automatically transitioned to the confirmation step
        // if there are no conflicting manual journals.
        if (showModal && accountDoesntHaveConnection) {
            setConfirmationStep(
                RemoveAccountConfirmationStep.ManualJournalsReview,
            );
        }
    }, [accountDoesntHaveConnection, showModal]);

    return (
        <StandardModal show={showModal} onHide={() => setShowModal(false)}>
            {showModal && (
                <>
                    {confirmationStep ===
                        RemoveAccountConfirmationStep.Attention && (
                        <RemoveAccountAttentionContent
                            account={account}
                            closeModal={() => setShowModal(false)}
                            selectedOption={selectedOption}
                            handleOptionChange={handleOptionChange}
                            disableAccount={disableAccount}
                            onRemoveAccount={onRemoveAccount}
                            removeAccountLoadingState={
                                removeAccountLoadingState
                            }
                        />
                    )}
                    {confirmationStep ===
                        RemoveAccountConfirmationStep.ManualJournalsReview && (
                        <RemoveAccountManualJournalsReviewContent
                            entityId={account.entityId!}
                            journalEntries={manualJournalEntries}
                            forward={() =>
                                setConfirmationStep(
                                    RemoveAccountConfirmationStep.Confirmation,
                                )
                            }
                            back={() =>
                                setConfirmationStep(
                                    RemoveAccountConfirmationStep.Attention,
                                )
                            }
                        />
                    )}

                    {confirmationStep ===
                        RemoveAccountConfirmationStep.Confirmation && (
                        <RemoveAccountConfirmationContent
                            account={account}
                            back={
                                accountDoesntHaveConnection
                                    ? undefined
                                    : () =>
                                          setConfirmationStep(
                                              RemoveAccountConfirmationStep.Attention,
                                          )
                            }
                            removeAccountMutation={removeAccount}
                        />
                    )}
                </>
            )}
        </StandardModal>
    );
}
