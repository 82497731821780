import React from "react";
import { FinancialDocumentStatus } from "../../../common/constants";
import { FinancialDocumentFilters } from "../../documents/FinancialDocumentsPageFilters/lib";

interface StatusFilterLabelProps {
    filters: Pick<FinancialDocumentFilters, "documentStatus">;
}

export const StatusFilterLabel: React.FC<StatusFilterLabelProps> = ({
    filters,
}) => (
    <>
        {filters.documentStatus === FinancialDocumentStatus.MATCHED
            ? "Matched"
            : "Not Matched"}
    </>
);
