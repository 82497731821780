import React from "react";
import { FormCheckbox } from "../../../../forms/FormCheckbox/FormCheckbox";
import { BaseFilterProps } from "../../lib";
import styles from "./MatchedFilter.module.scss";

export const MatchedFilter: React.FC<BaseFilterProps> = ({
    filters,
    onChange,
}) => (
    <section className={styles.direction}>
        <header className={styles.header}>Matched</header>

        <FormCheckbox
            value="all"
            label="All"
            isChecked={[filters.isMatched, filters.isNotMatched].every(
                (value) => !value,
            )}
            handleChange={() =>
                onChange({
                    isMatched: undefined,
                    isNotMatched: undefined,
                })
            }
            small
        />
        <FormCheckbox
            value="matched"
            label="Is matched"
            isChecked={!!filters.isMatched}
            handleChange={() => onChange({ isMatched: !filters.isMatched })}
            small
        />
        <FormCheckbox
            value="not-matched"
            label="Is not matched"
            isChecked={!!filters.isNotMatched}
            handleChange={() =>
                onChange({ isNotMatched: !filters.isNotMatched })
            }
            small
        />
    </section>
);
