import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ServerInferRequest } from "@ts-rest/core";
import { getBackendAPIClient } from "../lib/backendAPIClient";
import { financialAccountContract } from "../common/contracts/financial-account.contract";
import { FinancialAccountDto } from "../common/dto/financialAccount/financial-account.dto";
import { entitiesAccountsQueryKey } from "../queries/entitiesAccounts";

export const financialAccountContractClient = getBackendAPIClient(
    financialAccountContract,
);

export function useExtractMergedAccountMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (
            body: ServerInferRequest<
                typeof financialAccountContract.extractMergedAccount
            >["body"],
        ) => {
            const response =
                await financialAccountContractClient.extractMergedAccount({
                    body,
                });

            return {
                extractedFinancialAccount: FinancialAccountDto.deserialize(
                    response.body.extractedFinancialAccount,
                ),
            };
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [entitiesAccountsQueryKey],
            });
        },
    });
}
