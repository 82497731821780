import { TransactionDirectionType } from "../../../common/categories";
import { CategorySelectSimple } from "../../transactions/CategorySelect/CategorySelectSimple";
import { ActionCardDetail } from "../ActionCardDetail";
import { useCategoriesById } from "../../../hooks/useCategoriesById";
import { TransactionDetailProps } from "./TransactionDetails";

export const CategoryDetail: React.FC<TransactionDetailProps> = ({
    action,
    onUpdate,
}) => {
    const transaction = action.transaction!;
    const transactionUpdate = action.meta.transactionUpdate;
    const categoriesById = useCategoriesById();
    const categoryFromUpdate = transactionUpdate?.categoryId
        ? categoriesById[transactionUpdate.categoryId]
        : undefined;

    return (
        <ActionCardDetail label="Category">
            <CategorySelectSimple
                suggestedCategories={transaction.suggestedCategories ?? []}
                showPersonal={false}
                hideCustomCategories
                size="xs"
                onSelected={(category) =>
                    onUpdate({
                        categoryId: category.id as string,
                    })
                }
                value={categoryFromUpdate ?? transaction.category}
                transactionDirection={TransactionDirectionType.mixed}
                dropdownKey={`action-detail-category-${action.id}`}
            />
        </ActionCardDetail>
    );
};
