import React, { useState } from "react";
import { ActionCardTransactionsTable } from "../../../../actions/ActionCardTransactionsTable";
import { Category } from "../../../../../common/types/category";
import { TransactionListItemDto } from "../../../../../common/types/transaction";
import { useCategorizationSummaryTransactions } from "./useCategorizationSummaryTransactions";

export const ActionTransactionsTable: React.FC = () => {
    const originalTransactions = useCategorizationSummaryTransactions();
    const [transactions, setTransactions] = useState(
        () => originalTransactions,
    );

    const updateTransaction = (
        transaction: TransactionListItemDto,
        category: Category,
    ) => {
        setTransactions((prev) =>
            prev.map((t) =>
                t.id === transaction.id
                    ? { ...t, category, categoryId: category.id }
                    : t,
            ),
        );
    };

    return (
        <ActionCardTransactionsTable
            transactions={transactions as TransactionListItemDto[]}
            onUpdateCategory={updateTransaction}
            footer="View all (24)"
        />
    );
};
