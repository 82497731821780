import {
    createContext,
    PropsWithChildren,
    startTransition,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { UrlUpdateType } from "use-query-params";
import { useInvoicesWithStats } from "../../api/invoice.api";
import { InvoiceQueryParams } from "../../common/contracts/invoice.contract";
import {
    Invoice,
    InvoiceSortValue,
    InvoiceStats,
} from "../../common/types/invoice";
import { useInvoicesPageFiltersCache } from "../../hooks/useInvoicesPageFiltersCache";
import { useInvoicesPageSortingCache } from "../../hooks/useInvoicesPageSortingCache";
import { useSort, UseSort } from "./useSort";

const PER_PAGE = 50;

export interface PaginatedInvoicesTableContextValue {
    currentPage: number;
    totalPages: number;
    invoiceCount: number;
    setPage:
        | React.Dispatch<React.SetStateAction<number>>
        | ((
              newValue: number | null | undefined,
              updateType?: UrlUpdateType,
          ) => void);
    handleSortChange: UseSort["handleSortChange"];
    currentSort: UseSort["currentSort"];
    filters: InvoiceQueryParams["filters"];
    setFilters: React.Dispatch<
        React.SetStateAction<InvoiceQueryParams["filters"]>
    >;
    invoices?: Invoice[];
    invoiceStats?: InvoiceStats;
}

const defaultValue: PaginatedInvoicesTableContextValue = {
    currentPage: 0,
    totalPages: 0,
    invoiceCount: 0,
    setPage: () => {},
    handleSortChange: () => {},
    currentSort: InvoiceSortValue.NONE,
    filters: {},
    setFilters: () => {},
};

export const PaginatedInvoicesTableContext =
    createContext<PaginatedInvoicesTableContextValue>(defaultValue);

export function PaginatedInvoicesTableContextProvider({
    children,
}: PropsWithChildren) {
    const [cachedFilters, setCachedFilters] = useInvoicesPageFiltersCache();
    const [cachedSorting, setCachedSorting] = useInvoicesPageSortingCache();
    const [filters, setFilters] = useState<InvoiceQueryParams["filters"]>(
        () => {
            let initialFilters: InvoiceQueryParams["filters"] = {};

            if (cachedFilters) {
                initialFilters = { ...initialFilters, ...cachedFilters };
            }

            return initialFilters;
        },
    );

    useEffect(() => setCachedFilters(filters), [filters, setCachedFilters]);

    const [page, setPage] = useState(1);

    const { sortExpression, currentSort, handleSortChange } =
        useSort(cachedSorting);

    const [invoicesResult, invoicesWithStatsResult] = useInvoicesWithStats({
        filters,
        sort: sortExpression,
        page,
        limit: PER_PAGE,
    });

    const { data: invoices } = invoicesResult;
    const { data: invoiceStats } = invoicesWithStatsResult;

    const handleSortChangeWithPageReset = useCallback(
        (value: InvoiceSortValue) => {
            startTransition(() => {
                handleSortChange(value);
                setCachedSorting(value);
                setPage(1);
            });
        },
        [handleSortChange, setCachedSorting, setPage],
    );

    const contextValue = useMemo<PaginatedInvoicesTableContextValue>(
        () => ({
            currentPage: page,
            totalPages: invoices?.pageCount ?? 1,
            invoiceCount: invoices?.total ?? 0,
            invoices: invoices?.data,
            setPage,
            handleSortChange: handleSortChangeWithPageReset,
            currentSort,
            filters,
            setFilters,
            invoiceStats: invoiceStats?.data,
        }),
        [
            page,
            invoices,
            handleSortChangeWithPageReset,
            currentSort,
            filters,
            invoiceStats?.data,
        ],
    );

    return (
        <PaginatedInvoicesTableContext.Provider value={contextValue}>
            {children}
        </PaginatedInvoicesTableContext.Provider>
    );
}
