import { useMutation } from "@tanstack/react-query";
import { UpdateTransactionSplitsV2Dto } from "../common/dto/transactions/update-transaction-splits-v2.dto";
import { Transaction } from "../common/types/transaction";
import { updateTransactionSplitsV2 } from "../lib/transactions";

interface UseUpdateTransactionSplitsV2Options {
    onSuccess?: (data: Transaction) => void;
}

export const useUpdateTransactionSplitsV2 = (
    options?: UseUpdateTransactionSplitsV2Options,
) =>
    useMutation<
        Transaction,
        Error,
        { transactionId: number; dto: UpdateTransactionSplitsV2Dto }
    >({
        mutationFn: ({ transactionId, dto }) =>
            updateTransactionSplitsV2(transactionId, dto),
        onSuccess: (data) => {
            options?.onSuccess?.(data);
        },
    });
