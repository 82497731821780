import React, { forwardRef, PropsWithChildren } from "react";
import classNames from "classnames";
import { animated } from "@react-spring/web";
import css from "./Card.module.scss";

interface Props {
    className?: string;
    onClick?: () => void;
    style?: any;
}
export const AnimatedCard = forwardRef<
    HTMLDivElement,
    PropsWithChildren<Props>
>(({ children, className, style, ...otherProps }, ref) => (
    <animated.div
        ref={ref}
        className={classNames(css.card, className)}
        style={style}
        {...otherProps}
    >
        {children}
    </animated.div>
));
