import {
    EntityProfileIndustry,
    EntityProfileRevenueBracket,
    EntityProfileType,
} from "../../common/types/entity";

export const EXIT_ANIMATION_DURATION = 500;
export const PRODUCT_PREVIEW_URL = "/images/preview.png";
export const TESTIMONIAL_AVATAR_URL = "/images/testimonial-avatar.png";

export const industries = Object.values(EntityProfileIndustry);
export const entityTypes = Object.values(EntityProfileType).filter(
    // NOTE: delete after migration
    (type) =>
        type !== EntityProfileType.LLC &&
        type !== EntityProfileType.NO_LEGAL_ENTITY,
);

export const annualRevenueBrackets = Object.values(EntityProfileRevenueBracket);
