import { AxiosError } from "axios";
import {
    UserStatusResponse,
    UserStatusResponseDto,
} from "../common/dto/onboarding/user-status-response.dto";
import { AddAdditionalEmailDto } from "../common/dto/user/add-additional-email.dto";
import { AdditionalEmail } from "../common/types/additionalEmail";
import { User } from "../common/types/user";
import { GetAdditionalEmailsResponseDto } from "../common/dto/user/get-additional-emails-response.dto";
import { UserDto } from "../common/dto/user/user.dto";
import {
    UserWorkspacesResponse,
    UserWorkspacesResponseDto,
} from "../common/dto/onboarding/user-workspaces-response.dto";
import {
    UserEntitiesResponse,
    UserEntitiesResponseDto,
} from "../common/dto/onboarding/user-entities-response.dto";
import { UpdateProfileDto } from "../common/dto/user/update-profile.dto";
import { UserIntegrationsResponseDto } from "../common/dto/user/user-integrations-response.dto";
import { HandleGoogleAuthDto } from "../common/dto/user/handle-google-auth.dto";
import { AddPhoneNumberDto } from "../common/dto/user/add-phone-number.dto";
import { backendClient, unwrapResponse } from "./backendClient";

export async function getUserStatus(): Promise<UserStatusResponse | void> {
    try {
        const response: UserStatusResponseDto = unwrapResponse(
            await backendClient.get(`/user/status`),
        );

        return UserStatusResponseDto.deserialize(response);
    } catch {
        // ignore error
    }
}

export class TeamNotFoundError extends Error {
    constructor() {
        super("Team not found");
    }
}

export async function getUserEntities(
    activeWorkspaceId: string,
): Promise<UserEntitiesResponse | void> {
    try {
        const response: UserEntitiesResponseDto = unwrapResponse(
            await backendClient.get(
                `/user/workspaces/${activeWorkspaceId}/entities`,
            ),
        );

        return UserEntitiesResponseDto.deserialize(response);
    } catch (error) {
        if ((error as AxiosError).response?.status === 404) {
            throw new TeamNotFoundError();
        }
        // ignore other errors
    }
}

export async function getUserWorkspaces(
    withEntities: boolean,
): Promise<UserWorkspacesResponse | void> {
    try {
        const response: UserWorkspacesResponseDto = unwrapResponse(
            await backendClient.get(`/user/workspaces/`, {
                params: { withEntities },
            }),
        );

        return UserWorkspacesResponseDto.deserialize(response);
    } catch {
        // ignore error
    }
}

export async function updateProfile(data: UpdateProfileDto): Promise<User> {
    return UserDto.deserialize(
        unwrapResponse(await backendClient.patch("/user/profile", data)),
    );
}

export async function getAdditionalEmailsForUser(): Promise<GetAdditionalEmailsResponseDto> {
    return unwrapResponse(
        await backendClient.get("/user/profile/additional-email"),
    );
}

export async function addAdditionalEmail(
    data: AddAdditionalEmailDto,
): Promise<AdditionalEmail> {
    return unwrapResponse(
        await backendClient.post("/user/profile/additional-email", data),
    );
}

export async function removeAdditionalEmail(email: string): Promise<void> {
    return await backendClient.delete(
        `/user/profile/additional-email/${email}`,
    );
}

export async function getUserIntegrations() {
    const dto: UserIntegrationsResponseDto = unwrapResponse(
        await backendClient.get("/user/integrations"),
    );

    return UserIntegrationsResponseDto.deserialize(dto);
}

export async function handleGoogleAuth(data: HandleGoogleAuthDto) {
    return unwrapResponse(await backendClient.post("/google-auth", data));
}

export async function addPhoneNumber(data: AddPhoneNumberDto): Promise<User> {
    return UserDto.deserialize(
        unwrapResponse(
            await backendClient.post("/user/profile/phone-number", data),
        ),
    );
}
