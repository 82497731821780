import classNames from "classnames";
import React, {
    MouseEvent,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { UpdateTransactionDto } from "../../../common/dto/transactions/update-transaction.dto";
import { TransactionListItemDto } from "../../../common/types/transaction";
import {
    useDesktopView,
    useLargeDesktopView,
} from "../../../hooks/useMobileView";
import { EntityIcon } from "../../entity/EntityIcon";
import { FormCheckbox } from "../../forms/FormCheckbox/FormCheckbox";
import { CategorySelectPrompt } from "../CategorySelect/CategorySelectPrompt";
import { TransactionAction } from "../TransactionAction";
import { TransactionAmount } from "../TransactionAmount";
import { TransactionCategoryChange } from "../TransactionCategoryChange";
import { TransactionEntityCompact } from "../TransactionEntityCompact";
import { useBulkActions } from "../TransactionsBulkActions/useBulkActions";
import { TransferMatchLink } from "../TransferMatchLink/TransferMatchLink";
import { useChangeTransactionEntity } from "../useChangeTransactionEntity";
import { useChangeTransactionToPersonal } from "../useChangeTransactionToPersonal";
import { PaginatedTransactionsTableContext } from "../PaginatedTransactionsTableContextProvider";
import { ClassFilter } from "../../Class/ClassFilter/ClassFilter";
import { Tag } from "../../general/Tag/Tag";
import { TransactionAccountColumnContent } from "./TransactionAccountColumnContent";
import { TransactionDateColumnContent } from "./TransactionDateColumnContent";
import { TransactionDescriptionColumnContent } from "./TransactionDescriptionColumnContent";
import "./TransactionListItem.scss";
import { TransactionSplitColumnContent } from "./TransactionSplitColumnContent";

interface Props {
    transaction: TransactionListItemDto;
    onClick: () => void;
    isActive: boolean;
    isFullView: boolean;
    showAccountName: boolean;
    disableBulkActions?: boolean;
    actionButtonComponent?: (
        transaction: TransactionListItemDto,
    ) => React.ReactNode;
    onTransferMatchClick?: (transaction: TransactionListItemDto) => void;
    onSplitCategoryClick?: (transaction: TransactionListItemDto) => void;
    hideTransferMatchLink?: boolean;
    readonly?: boolean;
    tag?: string;
    showClasses?: boolean;
    hasAdjacentSplitSibling?: boolean;
    showSplitButton?: boolean;
}

const BLOCK_EVENT_KEY = "blockCategorySelectClick";

export const TransactionListTableItem: React.FC<Props> = ({
    transaction,
    onClick,
    isActive,
    isFullView,
    showAccountName,
    disableBulkActions,
    actionButtonComponent,
    onTransferMatchClick,
    hideTransferMatchLink = false,
    readonly = false,
    onSplitCategoryClick,
    tag,
    showClasses,
    hasAdjacentSplitSibling,
    showSplitButton,
}) => {
    const { saveTransaction } = useContext(PaginatedTransactionsTableContext);
    const bulkActions = useBulkActions();
    const changeToBusiness = useChangeTransactionEntity(transaction);
    const changeToPersonal = useChangeTransactionToPersonal(transaction);
    const waitingForUpdate = useRef({
        counter: 0,
        isWaiting: false,
    });
    const previousTransactionClasses = useRef(transaction.classAssignments);

    const handleTransactionUpdate = useCallback(
        async (payload: UpdateTransactionDto) => {
            await saveTransaction(transaction, payload);
        },
        [saveTransaction, transaction],
    );

    const handleClick = useCallback(
        (event: MouseEvent) => {
            // NOTE: we don't want to register click on the row if user clicked on category select,
            // click on category select should be propagated up however, to close other category selects
            if ((event as any)[BLOCK_EVENT_KEY]) {
                return;
            }

            if (bulkActions.enabled && (event.metaKey || event.ctrlKey)) {
                if (bulkActions.isSelected(transaction)) {
                    bulkActions.deselect(transaction);
                } else {
                    bulkActions.select(transaction);
                }

                return;
            }

            onClick();
        },
        [onClick, bulkActions, transaction],
    );

    const handleSplitCategoryClick = useCallback(
        (event?: MouseEvent) => {
            if (event) {
                (event as any)[BLOCK_EVENT_KEY] = true;
            }
            if (onSplitCategoryClick) {
                onSplitCategoryClick(transaction);
            }
        },
        [transaction, onSplitCategoryClick],
    );

    const isDesktop = useDesktopView();
    const isLargeDesktop = useLargeDesktopView();

    // NOTE: for optimistic update
    const [localClasses, setLocalClasses] = useState(
        transaction.classAssignments?.map((c) => c.classId) ?? [],
    );

    const handleClassesChange = useCallback(
        async (classIds: string[]) => {
            const counter = ++waitingForUpdate.current.counter;
            waitingForUpdate.current.isWaiting = true;
            setLocalClasses(classIds);
            await handleTransactionUpdate({ classIds });
            if (counter !== waitingForUpdate.current.counter) {
                return;
            }
            waitingForUpdate.current.isWaiting = false;
        },
        [handleTransactionUpdate],
    );

    useEffect(() => {
        const newArray =
            transaction.classAssignments?.map((c) => c.classId) ?? [];

        const localClassesSet = new Set(localClasses);

        if (
            previousTransactionClasses.current ===
                transaction.classAssignments ||
            waitingForUpdate.current.isWaiting
        ) {
            return;
        }

        const isEqual =
            newArray.length === localClasses.length &&
            newArray.every((id) => localClassesSet.has(id));

        if (isEqual) {
            return;
        }

        setLocalClasses(newArray);
        previousTransactionClasses.current = transaction.classAssignments;
    }, [transaction.classAssignments, localClasses]);

    const [hasOpenedClassFilter, setHasOpenedClassFilter] = useState(false);

    const onClassFilterEnter = useCallback(() => {
        setHasOpenedClassFilter(true);
    }, []);

    const onClassFilterExit = useCallback(() => {
        setHasOpenedClassFilter(false);
    }, []);

    return (
        <tr
            className={classNames(
                "transaction-list-item",
                `transaction-list-item--${transaction.status}`,
                `transaction-list-item--${transaction.type}`,
                {
                    "transaction-list-item--credit": transaction.amount > 0,
                    "transaction-list-item--active": isActive,
                    "transaction-list-item--ignored": transaction.isIgnored,
                },
                {
                    "transaction-list-item--has-opened-class-filter":
                        hasOpenedClassFilter,
                },
            )}
            data-testid="transaction-list-item"
            onClick={handleClick}
        >
            {!disableBulkActions && bulkActions.enabled ? (
                <td className="transaction-list-item__select">
                    <FormCheckbox
                        value="page"
                        isChecked={
                            bulkActions.hasSelectedAll ||
                            bulkActions.isSelected(transaction)
                        }
                        disabled={bulkActions.hasSelectedAll}
                        handleChange={() =>
                            bulkActions.isSelected(transaction)
                                ? bulkActions.deselect(transaction)
                                : bulkActions.select(transaction)
                        }
                        preventClickPropagation
                        small
                    />
                </td>
            ) : null}
            {isDesktop && (
                <td className="transaction-list-item__date">
                    <TransactionDateColumnContent transaction={transaction} />
                </td>
            )}

            {showSplitButton && (
                <td className="transaction-list-item__split">
                    <TransactionSplitColumnContent
                        transaction={transaction}
                        hasAdjacentSplitSibling={hasAdjacentSplitSibling}
                        onClick={handleSplitCategoryClick}
                    />
                </td>
            )}

            <td className="transaction-list-item__description">
                <div className="transaction-list-item__description-container">
                    <div className="d-flex flex-column">
                        <TransactionDescriptionColumnContent
                            transaction={transaction}
                        />
                        <span className="d-block d-md-none transaction-list-item__date transaction-list-item__date--inline">
                            <TransactionDateColumnContent
                                transaction={transaction}
                            />
                        </span>
                    </div>
                    {isDesktop && tag && (
                        <Tag
                            className="transaction-list-item__tag"
                            outline
                            variant="primary"
                        >
                            {tag}
                        </Tag>
                    )}
                </div>
            </td>

            {(isFullView || isLargeDesktop) && (
                <td
                    className="transaction-list-item__category"
                    data-testid="transaction-list-item-category"
                >
                    <div className="transaction-list-item__category-container">
                        <span onClick={(e: any) => (e[BLOCK_EVENT_KEY] = true)}>
                            {readonly ? (
                                <CategorySelectPrompt
                                    transaction={transaction}
                                    readonly
                                />
                            ) : (
                                <TransactionCategoryChange
                                    transaction={transaction}
                                    onUpdate={handleTransactionUpdate}
                                />
                            )}
                        </span>
                        {!hideTransferMatchLink &&
                            transaction.pairedTransferId && (
                                <span
                                    onClick={(e: any) =>
                                        (e[BLOCK_EVENT_KEY] = true)
                                    }
                                >
                                    <TransferMatchLink
                                        onClick={onTransferMatchClick}
                                        transaction={transaction}
                                    />
                                </span>
                            )}
                    </div>
                </td>
            )}

            {isFullView && (
                <>
                    {showClasses && (
                        <td className="transaction-list-item__class">
                            <span
                                onClick={(e: any) =>
                                    (e[BLOCK_EVENT_KEY] = true)
                                }
                                className={classNames(
                                    "transaction-list-item__class__content",
                                    {
                                        "transaction-list-item__class__content--always-visible":
                                            hasOpenedClassFilter ||
                                            localClasses.length > 0,
                                    },
                                )}
                            >
                                <ClassFilter
                                    onShow={onClassFilterEnter}
                                    onHide={onClassFilterExit}
                                    selectedClasses={localClasses}
                                    onChange={(classIds) => {
                                        handleClassesChange(classIds);
                                    }}
                                    behaviour={{
                                        selectedParentClassIncludesAllChildren:
                                            false,
                                        canSelectOnlyOneClassInTheSameRoot:
                                            true,
                                    }}
                                />
                            </span>
                        </td>
                    )}
                </>
            )}
            {showAccountName && (
                <td
                    className="transaction-list-item__account d-none d-md-table-cell"
                    data-testid="transaction-list-item-account"
                >
                    {transaction.financialAccount && (
                        <TransactionAccountColumnContent
                            transaction={transaction}
                        />
                    )}
                </td>
            )}

            <td className="transaction-list-item__amount">
                <TransactionAmount
                    transaction={transaction}
                    addSign={true}
                    color={true}
                />
            </td>
            {(isFullView || isLargeDesktop) && (
                <td className="transaction-list-item__entity">
                    {readonly ? (
                        <EntityIcon entity={transaction.entity} size="xs" />
                    ) : (
                        <TransactionEntityCompact
                            transaction={transaction}
                            onChangeToPersonal={() => changeToPersonal.mutate()}
                            onChangeToBusiness={(entity) =>
                                changeToBusiness.mutate(entity)
                            }
                            loading={
                                changeToPersonal.isPending ||
                                changeToBusiness.isPending
                            }
                        >
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id={`entity-popover-tooltip`}>
                                        {transaction.entity.name}
                                    </Tooltip>
                                }
                            >
                                <div className="inner-button">
                                    <div>
                                        <EntityIcon
                                            entity={transaction.entity}
                                            size="xs"
                                        />
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </TransactionEntityCompact>
                    )}
                </td>
            )}
            <td
                className={classNames("transaction-list-item__action", {
                    "transaction-list-item__action--extended":
                        actionButtonComponent,
                })}
            >
                {actionButtonComponent ? (
                    actionButtonComponent(transaction)
                ) : (
                    <TransactionAction transaction={transaction} />
                )}
            </td>
        </tr>
    );
};
