import React, { HTMLAttributes } from "react";
import {
    ClickWithKeyboardEventInteraction,
    useInteraction,
} from "../../hooks/useInteraction";

interface ClickableContainerProps extends HTMLAttributes<HTMLDivElement> {
    onClick: () => void;
}

export const ClickableContainer: React.FC<ClickableContainerProps> = ({
    children,
    onClick,
    ...rest
}) => {
    const clickInteraction = useInteraction(
        onClick,
    ) as ClickWithKeyboardEventInteraction<() => void>;

    return (
        <div {...clickInteraction} {...rest}>
            {children}
        </div>
    );
};
