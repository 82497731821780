import { useCallback } from "react";
import { Action, ActionCampaign } from "../../common/types/actions";
import { BookOnboardingCallCard } from "./bookOnboardingCall/BookOnboardingCallCard";
import { ConfirmTransactionsCard } from "./confirmTransactions/ConfirmTransactionsCard";
import { InviteAccountantCard } from "./inviteAccountant/InviteAccountantCard";
import { IRSRequirementsCard } from "./irsRequirements/IRSRequirementsCard";
import { PersonalToBusinessCard } from "./personalToBusiness/PersonalToBusinessCard";
import { SetupReceiptMatchingCard } from "./setupReceiptMatching/SetupReceiptMatchingCard";
import { WelcomeVideoCard } from "./welcomeVideo/WelcomeVideoCard";

const CAMPAIGN_CARDS: Record<
    ActionCampaign,
    React.ComponentType<CampaignCardProps>
> = {
    book_onboarding_call: BookOnboardingCallCard,
    confirm_transactions: ConfirmTransactionsCard,
    invite_accountant: InviteAccountantCard,
    irs_requirements: IRSRequirementsCard,
    personal_to_business: PersonalToBusinessCard,
    setup_receipt_matching: SetupReceiptMatchingCard,
    welcome_video: WelcomeVideoCard,
};

export interface CampaignCardProps<TActionMeta extends object = object> {
    onClick?: () => void;
    isCompleted?: boolean;
    isSkipped?: boolean;
    isLoading?: boolean;
    opened: boolean;
    onCardClosing?: () => void;
    onCardClosed?: () => void;

    action: Action<TActionMeta>;
    onConfirm?: (action: Action, data?: object) => void;
    onSkip?: (action: Action, data?: object) => void;
}

export type ActionItemProps = Omit<
    CampaignCardProps,
    "onClick" | "onCardClosing" | "onCardClosed"
> & {
    onClick: (action: Action) => void;
    onCardClosing: (action: Action) => void;
    onCardClosed: (action: Action) => void;
};

export const ActionItem: React.FC<ActionItemProps> = ({
    onClick,
    onCardClosing,
    onCardClosed,
    ...props
}) => {
    const Card = CAMPAIGN_CARDS[props.action.campaign];

    const handleClick = useCallback(() => {
        onClick(props.action);
    }, [onClick, props.action]);

    const handleCardClosing = useCallback(() => {
        onCardClosing(props.action);
    }, [onCardClosing, props.action]);

    const handleCardClosed = useCallback(() => {
        onCardClosed(props.action);
    }, [onCardClosed, props.action]);

    return (
        <Card
            onClick={handleClick}
            onCardClosing={handleCardClosing}
            onCardClosed={handleCardClosed}
            {...props}
        />
    );
};
