import React, { useLayoutEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
    fullText: string;
    tooltipId: string;
    children: (ref: React.RefObject<HTMLElement>) => React.ReactElement;
}

export const EllipsisWithTooltip: React.FC<Props> = ({
    fullText,
    tooltipId,
    children,
}) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const ref = useRef<HTMLElement>(null);

    useLayoutEffect(() => {
        if (ref.current) {
            setShowTooltip(ref.current.scrollHeight > ref.current.clientHeight);
        }
    }, [fullText]);

    return (
        <OverlayTrigger
            show={showTooltip ? undefined : false}
            overlay={<Tooltip id={tooltipId}>{fullText}</Tooltip>}
        >
            {children(ref)}
        </OverlayTrigger>
    );
};
