import React, { useMemo } from "react";
import { Card } from "../general/Card/Card";
import { PREMIUM_FEATURES } from "../../common/flatRateBilling";
import { ThunderboltIcon } from "../../icons";
import { useTypedFlags } from "../../hooks/useTypedFlags";
import { useAllowFeature } from "../../hooks/useAllowFeature";
import { AccountingReportFiltersForm } from "./AccountingReportFiltersForm";
import {
    DEFAULT_END_DATE,
    DEFAULT_START_DATE,
    useProfitLossInitialDates,
} from "./useProfitLossInitialDates";
import { useAccountingTabContext } from "./useAccountingTab";
import { useEntitiesWithAccountingAvailable } from "./useEntitiesWithAccountingAvailable";
import { AccountingReportLink } from "./AccountingReportLink";

export const AccountingPage: React.FC = () => {
    const { filters, setFilters } = useAccountingTabContext();
    const availableEntities = useEntitiesWithAccountingAvailable();
    const entityId = filters.entityId ?? availableEntities[0]?.id;

    const { dateStart, dateEnd } = useProfitLossInitialDates();
    const filtersWithDefaultDatesAsFallback = useMemo(
        () => ({
            ...filters,
            startDate: filters.startDate ?? DEFAULT_START_DATE,
            endDate: filters.endDate ?? DEFAULT_END_DATE,
        }),
        [filters],
    );

    const { accountingReportsComingSoon, chartOfAccountsEnabled } =
        useTypedFlags();

    const balanceSheetAllowed = useAllowFeature(PREMIUM_FEATURES.BALANCE_SHEET);

    return (
        <>
            <AccountingReportFiltersForm
                filters={filtersWithDefaultDatesAsFallback}
                setFilters={setFilters}
                requireDateSelection
                selectExactDays
                hideClassFilter
            />
            <Card label="Accountant Reports">
                <AccountingReportLink
                    title={
                        <>
                            Profit & Loss <br />
                            <small>(Modified Cash Basis)</small>
                        </>
                    }
                    description="Reports your income and expenses over a specified period of time"
                    link={`/accounting/profit-loss?startDate=${dateStart}&endDate=${dateEnd}&entityId=${entityId}`}
                    active
                />
                <AccountingReportLink
                    title={
                        <>
                            Balance Sheet
                            {!balanceSheetAllowed && (
                                <ThunderboltIcon className="icon-color-blue-600 icon-size-text" />
                            )}
                        </>
                    }
                    description="Snapshot of your financial position at a given point of time, broken down into assets, liabilities, and equity"
                    link={`/accounting/balance-sheet?startDate=${dateStart}&endDate=${dateEnd}&entityId=${entityId}`}
                    active
                />
                <AccountingReportLink
                    title={<>General Ledger</>}
                    description="Record of all transactions in your business that helps in preparing financial statements"
                    link={`/accounting/general-ledger?startDate=${dateStart}&endDate=${dateEnd}&entityId=${entityId}`}
                    active
                />
                {chartOfAccountsEnabled && (
                    <AccountingReportLink
                        title={<>Chart of Accounts</>}
                        description="Categorized list of all your business’s accounts, used to organize transactions and track financial activity"
                        active
                        link={`/accounting/chart-of-accounts?startDate=${dateStart}&endDate=${dateEnd}&entityId=${entityId}`}
                    />
                )}
                {accountingReportsComingSoon && (
                    <>
                        <AccountingReportLink
                            title="Trial Balance"
                            description="Report that checks the accuracy of your books by listing all account balances, ensuring total debits match total credits"
                            active={false}
                        />
                        <AccountingReportLink
                            title="Accounts Receivable Aging"
                            description="Breakdown of outstanding invoices by due date, helping you track unpaid balances and manage collections"
                            active={false}
                        />
                        <AccountingReportLink
                            title="2024 Tax Package"
                            description="Compiled set of reports and documents to streamline tax filing and maximize deductions"
                            active={false}
                        />
                    </>
                )}
            </Card>
        </>
    );
};
