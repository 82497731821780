import { useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { TransactionListItemDto } from "../../common/types/transaction";
import { Entity } from "../../common/types/entity";
import { PaginatedTransactionsTableContext } from "./PaginatedTransactionsTableContextProvider";

export function useChangeTransactionEntity(
    transaction: TransactionListItemDto,
) {
    const { saveTransaction } = useContext(PaginatedTransactionsTableContext);

    return useMutation({
        mutationFn: async (entity: Entity) => {
            const previousEntity = transaction.entity;

            if (previousEntity !== entity) {
                return await saveTransaction(transaction, {
                    entityId: entity.id,
                });
            }
        },
    });
}
