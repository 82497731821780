interface ClassLike {
    id: string;
}
export interface ReorderClassesInParentParams<T extends ClassLike> {
    classes: T[];
    orderChange:
        | {
              typeOfChange: "removed";
              to?: never;
              targetId?: never;
          }
        | {
              to: number;
              typeOfChange: "inserted";
              targetId: string;
          }
        | {
              to: number;
              typeOfChange: "moved";
              targetId: string;
          };
}

export const reorderClassesInParent = <T extends ClassLike>({
    classes,
    orderChange: { typeOfChange, targetId, to },
}: ReorderClassesInParentParams<T>) => {
    const newArray = [...classes];
    if (typeOfChange === "removed") {
        return newArray;
    }
    const index = newArray.findIndex((c) => c?.id === targetId);
    const extractedItem = newArray.splice(index, 1)[0];
    newArray.splice(to, 0, extractedItem);
    return newArray;
};
