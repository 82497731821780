import { useMutation } from "@tanstack/react-query";
import { ServerInferRequest } from "@ts-rest/core";
import { accountContract } from "../common/contracts/account.contract";
import { getBackendAPIClient } from "../lib/backendAPIClient";
import { getAccountsQueryKey } from "../hooks/useAccounts";
import { queryClient } from "../queryClient";

import {
    AccountType,
    AccountSubtype,
    GetAccountsResponse,
} from "../common/types/domains/accounting/accounts";
import { getSubtypeBordersInCoA } from "../common/constants/domains/accounting/chartOfAccountsV2";
import { MergedEntityType } from "../common/types/entity";
import { ExtendedAccount } from "../common/contracts/accounting-reports";
import { Account } from "../common/types/domains/accounting/account";
import {
    ChartOfAccountsResponse,
    getChartOfAccountsQueryKey,
} from "./accountingReports.api";

export const accountContractClient = getBackendAPIClient(accountContract);

export function useCreateAccountMutation() {
    return useMutation({
        mutationFn: async ({
            params,
            body,
        }: ServerInferRequest<typeof accountContract.createAccount>) => {
            const response = await accountContractClient.createAccount({
                params: { entityId: params.entityId },
                body,
            });
            return response.body.account;
        },
        onSuccess: async (account, { params }) => {
            optimisticallyAddAccountInRelatedQueries(
                account.id,
                params.entityId,
                account,
            );
            invalidateAccountRelatedQueries(params.entityId);
        },
    });
}

export function useDeactivateAccountMutation() {
    return useMutation({
        mutationFn: async ({
            params,
        }: ServerInferRequest<typeof accountContract.disableAccount>) => {
            const response = await accountContractClient.disableAccount({
                params,
            });
            return response.body;
        },
        onSuccess: async (response, { params }) => {
            if (response.deleted) {
                optimisticallyDeleteAccountInRelatedQueries(
                    params.id,
                    params.entityId,
                );
            } else {
                optimisticallyUpdateAccountInRelatedQueries(
                    params.id,
                    params.entityId,
                    {
                        isDisabled: true,
                    },
                );
            }
            invalidateAccountRelatedQueries(params.entityId);
        },
    });
}

export function useEnableAccountMutation() {
    return useMutation({
        mutationFn: async ({
            params,
        }: ServerInferRequest<typeof accountContract.enableAccount>) => {
            const response = await accountContractClient.enableAccount({
                params,
            });
            return response.body.account;
        },
        onMutate: async ({ params }) => {
            optimisticallyUpdateAccountInRelatedQueries(
                params.id,
                params.entityId,
                {
                    isDisabled: false,
                },
            );
        },
        onSuccess: async (account, { params }) => {
            optimisticallyUpdateAccountInRelatedQueries(
                params.id,
                params.entityId,
                {
                    isDisabled: account?.isDisabled,
                },
            );
            invalidateAccountRelatedQueries(params.entityId);
        },
        onError: (error, { params }) => {
            invalidateAccountRelatedQueries(params.entityId);
        },
    });
}

export function useUpdateAccountMutation() {
    return useMutation({
        mutationFn: async ({
            params,
            body,
        }: ServerInferRequest<typeof accountContract.updateAccount>) => {
            const response = await accountContractClient.updateAccount({
                params,
                body,
            });
            return response.body.account;
        },
        onSuccess: async (account, { params }) => {
            optimisticallyUpdateAccountInRelatedQueries(
                params.id,
                params.entityId,
                {
                    name: account.name,
                },
            );
            invalidateAccountRelatedQueries(params.entityId);
        },
    });
}

export function useChangeAccountPositionMutation() {
    return useMutation({
        mutationFn: async ({
            params,
            body,
        }: ServerInferRequest<
            typeof accountContract.changeAccountPosition
        >) => {
            const response = await accountContractClient.changeAccountPosition({
                params,
                body,
            });
            return response.body.account;
        },
        onSuccess: async (_, { params }) => {
            invalidateAccountRelatedQueries(params.entityId);
        },
    });
}

interface OptimisticChangeAccountPositionParams {
    entityType: MergedEntityType;
    entityId: string;
    accountId: string;
    targetOrder: number;
    targetType: AccountType;
    targetSubtype: AccountSubtype;
}

// NOTE:
// needs to be called outside (=before) the mutation promise,
// otherwise new order will be applied after animation of Sortable list items
// returning to their initial positions which looks unsexy from UX perspective
export function optimisticallyChangeAccountPosition({
    entityId,
    accountId,
    targetOrder,
    targetType,
    targetSubtype,
    entityType,
}: OptimisticChangeAccountPositionParams) {
    const data = queryClient.getQueryData<ChartOfAccountsResponse>(
        getChartOfAccountsQueryKey(Number(entityId)),
    );

    if (!data) {
        return;
    }

    const { accounts } = data;

    const accountsMap = new Map([...(accounts ?? [])].map((a) => [a.id, a]));

    const account = accountsMap.get(accountId);

    if (!account) {
        return;
    }

    const sortedAccountsInTheCurrentSubtype = accounts
        .filter(
            (ac) =>
                account.type === ac.type &&
                account.subtype === ac.subtype &&
                ac.id !== accountId,
        )
        .sort((a, b) => a.code - b.code);

    const sortedAccountsInTheTargetSubtype = accounts
        .filter(
            (ac) =>
                ac.type === targetType &&
                ac.subtype === targetSubtype &&
                ac.id !== accountId,
        )
        .sort((a, b) => a.code - b.code);

    sortedAccountsInTheTargetSubtype.splice(targetOrder, 0, account);

    const hasChangedSubtype = account.subtype !== targetSubtype;

    if (hasChangedSubtype) {
        updateAccountCodesInSubtype({
            entityType,
            sortedAccounts: sortedAccountsInTheCurrentSubtype,
            type: account.type,
            subtype: account.subtype,
            MapWithUpdatedAccounts: accountsMap,
        });
        updateAccountCodesInSubtype({
            entityType,
            sortedAccounts: sortedAccountsInTheTargetSubtype,
            type: targetType,
            subtype: targetSubtype,
            MapWithUpdatedAccounts: accountsMap,
        });
    } else {
        updateAccountCodesInSubtype({
            entityType,
            sortedAccounts: sortedAccountsInTheTargetSubtype,
            type: targetType,
            subtype: targetSubtype,
            MapWithUpdatedAccounts: accountsMap,
        });
    }

    queryClient.setQueryData(getChartOfAccountsQueryKey(Number(entityId)), {
        accounts: Array.from(accountsMap.values()).sort(
            (a, b) => a.code - b.code,
        ),
    });
}

function updateAccountCodesInSubtype({
    entityType,
    sortedAccounts,
    type,
    subtype,
    MapWithUpdatedAccounts,
}: {
    entityType: MergedEntityType;
    sortedAccounts: ExtendedAccount[];
    type: AccountType;
    subtype: AccountSubtype;
    MapWithUpdatedAccounts: Map<string, ExtendedAccount>;
}) {
    const subtypeBorders = getSubtypeBordersInCoA(entityType, type, subtype);

    sortedAccounts.forEach((account, i) => {
        MapWithUpdatedAccounts.set(account.id, {
            ...account,
            code: subtypeBorders.startFrom + i,
        });
    });
}

function invalidateAccountRelatedQueries(entityId: string) {
    queryClient.invalidateQueries({
        queryKey: getChartOfAccountsQueryKey(Number(entityId)),
    });
    queryClient.invalidateQueries({
        queryKey: getAccountsQueryKey(Number(entityId)),
    });
}

function optimisticallyDeleteAccountInRelatedQueries(
    accountId: string,
    entityId: string,
) {
    const CoAData = queryClient.getQueryData<ChartOfAccountsResponse>(
        getChartOfAccountsQueryKey(Number(entityId)),
    );

    if (CoAData) {
        const { accounts } = CoAData;
        const filteredAccounts = accounts?.filter((a) => a.id !== accountId);
        queryClient.setQueryData(getChartOfAccountsQueryKey(Number(entityId)), {
            accounts: filteredAccounts,
        });
    }

    const accountsData = queryClient.getQueryData<GetAccountsResponse>(
        getAccountsQueryKey(Number(entityId)),
    );
    if (accountsData) {
        const { accounts } = accountsData;
        const filteredAccounts = accounts?.filter((a) => a.id !== accountId);
        queryClient.setQueryData(getAccountsQueryKey(Number(entityId)), {
            accounts: filteredAccounts,
        });
    }
}

function optimisticallyUpdateAccountInRelatedQueries(
    accountId: string,
    entityId: string,
    update: Partial<Account>,
) {
    const CoAData = queryClient.getQueryData<ChartOfAccountsResponse>(
        getChartOfAccountsQueryKey(Number(entityId)),
    );

    if (CoAData) {
        const { accounts } = CoAData;
        const newAccounts = accounts?.map((a) =>
            a.id === accountId ? { ...a, ...update } : a,
        );
        queryClient.setQueryData(getChartOfAccountsQueryKey(Number(entityId)), {
            accounts: newAccounts,
        });
    }

    const accountsData = queryClient.getQueryData<GetAccountsResponse>(
        getAccountsQueryKey(Number(entityId)),
    );
    if (accountsData) {
        const { accounts } = accountsData;
        const newAccounts = accounts?.map((a) =>
            a.id === accountId ? { ...a, ...update } : a,
        );
        queryClient.setQueryData(getAccountsQueryKey(Number(entityId)), {
            accounts: newAccounts,
        });
    }
}

function optimisticallyAddAccountInRelatedQueries(
    accountId: string,
    entityId: string,
    account: Account,
) {
    const CoAData = queryClient.getQueryData<ChartOfAccountsResponse>(
        getChartOfAccountsQueryKey(Number(entityId)),
    );

    if (CoAData) {
        const { accounts } = CoAData;

        const extendedAccount: ExtendedAccount = {
            ...account,
            debit: 0,
            credit: 0,
            balance: 0,
        };
        const newAccounts = [...accounts, extendedAccount].sort(
            (a, b) => a.code - b.code,
        );
        queryClient.setQueryData(getChartOfAccountsQueryKey(Number(entityId)), {
            accounts: newAccounts,
        });
    }

    const accountsData = queryClient.getQueryData<GetAccountsResponse>(
        getAccountsQueryKey(Number(entityId)),
    );
    if (accountsData) {
        const { accounts } = accountsData;
        const newAccounts = [...accounts, account].sort(
            (a, b) => a.code - b.code,
        );
        queryClient.setQueryData(getAccountsQueryKey(Number(entityId)), {
            accounts: newAccounts,
        });
    }
}
