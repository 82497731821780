import React from "react";
import { SidebarInfo } from "../../components/SidebarInfo";
import animation from "../../layout/OnboardingLayout.module.scss";
import { Testimonial } from "../../components/Testimonial/Testimonial";

export const ConnectAccountsSidebar: React.FC = () => (
    <SidebarInfo>
        <header className={animation.fadeIn1}>
            Why should I connect my personal accounts and credit cards?
        </header>

        <p className={animation.fadeIn2}>
            Connecting your personal accounts helps Kick maintain accurate
            financials by categorizing transfers.
        </p>

        <p className={animation.fadeIn3}>
            Personal accounts also help Kick identify tax savings through
            business deductions which are easy to miss like Meals, Travel and
            Home Office.
        </p>

        <aside className={`mt-5 ${animation.fadeIn4}`}>
            <Testimonial />
        </aside>
    </SidebarInfo>
);
