import { useCallback, useEffect, useState } from "react";
import {
    Action,
    ActionCategory,
    ActionStatus,
} from "../../common/types/actions";
import { useCompleteAction } from "../../mutations/actions";
import { Loader } from "../general/Loader";
import { useActions } from "../../hooks/useActions";
import {
    invalidateSkippedActions,
    useSkippedActions,
} from "../../hooks/useSkippedActions";
import { useWorkspaceContext } from "../../state/workspaceContext";
import { invalidateActionsSummary } from "../../hooks/useActionsSummary";
import { invalidateActionsActivity } from "../../hooks/useActionsActivity";
import { trackEvent } from "../../lib/analytics";
import { TransactionUpdateMetaDto } from "../../common/dto/actions/transaction-update-meta.dto";
import { SkippedActions } from "./SkippedActions";
import { ActionsEmptyState } from "./ActionsEmptyState";
import { ActionItem, ActionItemProps } from "./ActionItem";

interface Props {
    category: ActionCategory;
}

interface ActionEventData {
    action: Action;
    actions: Action[];
    skippedActions: Action[];
    additionalData?: object;
}

function getActionEventData({
    action,
    actions,
    skippedActions,
    additionalData,
}: ActionEventData) {
    return {
        action_id: action.id,
        action_campaign: action.campaign,
        action_created_at: action.createdAt,
        entity_id: action.entity.id,
        workspace_id: action.entity.workspaceId,
        meta: {
            transaction_update: (action.meta as TransactionUpdateMetaDto)
                ?.transactionUpdate,
            transaction_id: action.transaction?.id,
            ...(additionalData ?? {}),
            actions: actions.length,
            skipped_actions: skippedActions.length,
        },
    };
}

export const ActionsList: React.FC<Props> = ({ category }) => {
    const [actions, setActions] = useState<Action[]>([]);
    const [skippedActions, setSkippedActions] = useState<Action[]>([]);
    const { activeWorkspace } = useWorkspaceContext();
    const [openedActionId, setOpenedActionId] = useState<string | undefined>();
    const [isCategoryRefetching, setIsCategoryRefetching] = useState(false);

    const actionsResponse = useActions(category);

    const openAction = useCallback(
        (action: Action) => {
            void trackEvent(
                "action_viewed",
                getActionEventData({ action, actions, skippedActions }),
            );

            setOpenedActionId(action.id);
        },
        [actions, skippedActions],
    );

    useEffect(() => {
        if (actionsResponse.data) {
            setActions(actionsResponse.data.actions);
            setIsCategoryRefetching(false);
            setOpenedActionId(undefined);
        }
    }, [actionsResponse.data]);

    const openNextAction = useCallback(() => {
        if (!actions) {
            return;
        }

        const currentActionIndex = actions.findIndex(
            (a) => a.id === openedActionId,
        );
        const nextAction = actions[currentActionIndex + 1];

        if (nextAction) {
            openAction(nextAction);
            return;
        }

        const firstPendingAction = actions.find(
            (a) => a.status === ActionStatus.PENDING,
        );

        if (firstPendingAction) {
            openAction(firstPendingAction);
        } else {
            setOpenedActionId(undefined);
        }
    }, [actions, openAction, openedActionId]);

    useEffect(() => {
        setIsCategoryRefetching(true);
    }, [category]);

    useEffect(() => {
        if (actions && !openedActionId) {
            openNextAction();
        }
    }, [actions, openNextAction, openedActionId]);

    const skippedActionsData = useSkippedActions(category);

    useEffect(() => {
        if (skippedActionsData.data) {
            setSkippedActions(skippedActionsData.data.actions);
        }
    }, [skippedActionsData.data, setSkippedActions]);

    const completeAction = useCompleteAction();

    const updateAction = useCallback((oldAction: Action, newAction: Action) => {
        if (oldAction.status === ActionStatus.SKIPPED) {
            setSkippedActions((prev) =>
                prev?.map((a) => (a.id === newAction.id ? newAction : a)),
            );
        } else {
            setActions((prev) =>
                prev?.map((a) => (a.id === newAction.id ? newAction : a)),
            );
        }
    }, []);

    const confirmAction = useCallback(
        (action: Action, data?: object) => {
            const newAction = {
                ...action,
                status: ActionStatus.CONFIRMED,
            };

            completeAction.mutate(newAction, {
                onSuccess: () => {
                    void trackEvent(
                        "action_completed",
                        getActionEventData({
                            action,
                            actions,
                            skippedActions,
                            additionalData: data,
                        }),
                    );

                    updateAction(action, newAction);
                },
            });
        },
        [actions, completeAction, skippedActions, updateAction],
    );

    const skipAction = useCallback(
        (action: Action, data?: object) => {
            const newAction = {
                ...action,
                status: ActionStatus.SKIPPED,
            };

            completeAction.mutate(newAction, {
                onSuccess: () => {
                    void trackEvent(
                        "action_skipped",
                        getActionEventData({
                            action,
                            actions,
                            skippedActions,
                            additionalData: data,
                        }),
                    );
                    updateAction(action, newAction);
                },
            });
        },
        [actions, completeAction, skippedActions, updateAction],
    );

    const onActionClick = useCallback(
        (action: Action) => {
            openAction(action);
        },
        [openAction],
    );

    const removeAction = useCallback(
        (action: Action) => {
            setActions((prev) => prev?.filter((a) => a.id !== action.id));
            if (activeWorkspace) {
                invalidateActionsSummary(activeWorkspace.id);
            }
        },
        [activeWorkspace],
    );

    const removeSkippedAction = useCallback(
        (action: Action) => {
            setSkippedActions((prev) =>
                prev?.filter((a) => a.id !== action.id),
            );
        },
        [setSkippedActions],
    );

    const onCardClosing: ActionItemProps["onCardClosing"] = useCallback(
        (action) => {
            if (!activeWorkspace) {
                return;
            }

            switch (action.status) {
                case ActionStatus.CONFIRMED:
                    invalidateActionsActivity(activeWorkspace.id);
                    break;
                case ActionStatus.SKIPPED:
                    invalidateSkippedActions(activeWorkspace.id, category);
                    break;
            }

            if (action.id === openedActionId) {
                openNextAction();
            }
        },
        [activeWorkspace, openedActionId, category, openNextAction],
    );

    if (
        actionsResponse.isLoading ||
        (actionsResponse.isRefetching && isCategoryRefetching)
    ) {
        return <Loader />;
    }

    return (
        <>
            {actions && actions.length > 0 ? (
                actions.map((action) => (
                    <ActionItem
                        key={action.id}
                        action={action}
                        isLoading={completeAction.isPending}
                        isCompleted={action.status === ActionStatus.CONFIRMED}
                        isSkipped={action.status === ActionStatus.SKIPPED}
                        onCardClosing={onCardClosing}
                        onCardClosed={removeAction}
                        opened={openedActionId === action.id}
                        onClick={onActionClick}
                        onSkip={skipAction}
                        onConfirm={confirmAction}
                    />
                ))
            ) : (
                <ActionsEmptyState category={category} />
            )}

            {skippedActions && skippedActions?.length > 0 && (
                <SkippedActions
                    actions={skippedActions}
                    renderAction={(action) => (
                        <ActionItem
                            key={action.id}
                            action={action}
                            isLoading={completeAction.isPending}
                            isCompleted={
                                action.status === ActionStatus.CONFIRMED
                            }
                            onCardClosing={onCardClosing}
                            onCardClosed={removeSkippedAction}
                            opened={openedActionId === action.id}
                            onClick={onActionClick}
                            onConfirm={confirmAction}
                        />
                    )}
                />
            )}
        </>
    );
};
