import { getCookie, setCookie } from "../helpers/cookie";
import { EXTERNAL_REFERER } from "../common/registrationCookies";

function getExternalReferrer() {
    try {
        if (!document.referrer) {
            return "direct";
        }

        const referrerHostname = new URL(document.referrer).hostname;

        if (!referrerHostname.includes("kick.co")) {
            return document.referrer;
        }

        const lastTouch = getCookie("kick_lt");

        if (lastTouch) {
            const parsed = JSON.parse(lastTouch);
            return parsed.referrer; // Keep the original external referrer
        }

        return "direct";
    } catch {
        return "direct";
    }
}

export function useExternalRefererCookie() {
    const externalReferrer = getExternalReferrer();
    setCookie(EXTERNAL_REFERER, externalReferrer);
}
