import React, { useCallback, useMemo, useRef, useState } from "react";
import { groupBy, without } from "lodash";
import { FieldInputProps } from "formik/dist/types";
import classNames from "classnames";
import { PopoverButtonProps } from "../../general/PopoverContainer";
import { FilterSearch } from "../../general/FilterSearch/FilterSearch";
import { SelectDropdown } from "../../general/SelectDropdown/SelectDropdown";
import { Button } from "../../general/Button/Button";
import styles from "./Multiselect.module.scss";
import {
    MultiselectOption,
    MultiselectOptionsSection,
    ValueType,
} from "./types";
import { MultiselectSection } from "./MultiselectSection";
import { MultiSelectContainer } from "./MultiselectContainer";

export interface MultiselectProps
    extends Pick<FieldInputProps<ValueType[]>, "name" | "value" | "onBlur">,
        Omit<PopoverButtonProps, "buttonText"> {
    options: MultiselectOption[];
    buttonText?:
        | React.ReactNode
        | ((
              value: ValueType[],
              allOptions: MultiselectOption[],
          ) => React.ReactNode);
    onChange(value: ValueType[]): void;
    filterFn?(search: string, option: MultiselectOption): boolean;
    searchable?: boolean;
    inDropdown?: boolean;
    resetButtonClassname?: string;
    containerClassname?: string;
    onReset?(): void;
    disableAutoHeight?: boolean;
}

export const Multiselect = ({
    options,
    value,
    onChange,
    buttonText = (v, allOptions) => {
        if (!v.length) {
            return "Select";
        }

        if (v.length === allOptions.length) {
            return "All";
        }

        if (v.length < 3) {
            return value.join(", ");
        }

        return `${v.slice(0, 2).join(", ")}, and ${v.length - 2} more`;
    },
    buttonClass,
    disableAutoHeight,
    name,
    searchable = true,
    filterFn = (search: string, option: MultiselectOption) => {
        const query = search.toLowerCase();

        if (!option.label) {
            return false;
        }

        if (typeof option.label === "string") {
            return option.label.toLowerCase().includes(query);
        }

        if (typeof option.label === "number") {
            return String(option.label).includes(query);
        }

        if (!option.searchableLabel) {
            return false;
        }

        return option.searchableLabel.toLowerCase().includes(query);
    },
    inDropdown = true,
    resetButtonClassname,
    onReset,
    ...popoverButtonProps
}: MultiselectProps) => {
    const isActive = Boolean(value.length && value.length !== options.length);
    const [search, setSearch] = useState("");
    const searchRef = useRef<HTMLInputElement>();

    const filteredOptions = useMemo(
        () => options.filter((option) => filterFn(search, option)),
        [filterFn, options, search],
    );

    const filteredOptionsBySection: MultiselectOptionsSection[] =
        useMemo(() => {
            const grouped = groupBy(
                filteredOptions,
                (option) => option.section ?? "",
            );

            return Object.entries(grouped).map(([section, sectionOptions]) => ({
                name: section,
                options: sectionOptions,
            }));
        }, [filteredOptions]);

    const isChecked = useCallback(
        (key: ValueType) => Boolean(value?.includes(key)),
        [value],
    );

    const handleChange = useCallback(
        (key: ValueType) => {
            if (isChecked(key)) {
                const newValue = without(value, key);

                onChange(newValue);
            } else {
                onChange([...(value ?? []), key]);
            }

            searchRef.current?.focus();
        },
        [value, isChecked, onChange],
    );

    return (
        <MultiSelectContainer
            options={options}
            value={value}
            onChange={onChange}
            buttonText={buttonText}
            buttonClass={buttonClass}
            name={name}
            searchable={searchable}
            inDropdown={inDropdown}
            searchRef={searchRef}
            setSearch={setSearch}
            isActive={isActive}
            {...popoverButtonProps}
        >
            <SelectDropdown
                className={styles.multiselect}
                disableAutoHeight={disableAutoHeight}
                prepend={
                    searchable && (
                        <FilterSearch
                            value={search}
                            onChange={setSearch}
                            inputRef={searchRef}
                        />
                    )
                }
                append={
                    value.length && onReset ? (
                        <Button
                            onClick={onReset}
                            className={classNames(
                                styles.resetButton,
                                resetButtonClassname,
                            )}
                            variant="tertiary"
                        >
                            Reset
                        </Button>
                    ) : null
                }
            >
                {filteredOptions.length > 0 ? (
                    filteredOptionsBySection.map((section) => (
                        <MultiselectSection
                            section={section}
                            key={section.name}
                            isChecked={isChecked}
                            handleChange={handleChange}
                        />
                    ))
                ) : (
                    <p className={styles.empty}>No matching results</p>
                )}
            </SelectDropdown>
        </MultiSelectContainer>
    );
};
