import React from "react";
import classNames from "classnames";

import "./Tag.scss";
import { ChildrenProps } from "../../../types";

export type TagVariant =
    | "primary"
    | "danger"
    | "warning"
    | "success"
    | "secondary"
    | "cta"
    | "light";
export interface TagProps extends ChildrenProps {
    variant?: TagVariant;
    disabled?: boolean;
    outline?: boolean;
    round?: boolean;
    size?: "xs" | "sm" | "lg";
    icon?: boolean;
    className?: string;
}

export const Tag: React.FC<TagProps> = ({
    children,
    outline,
    disabled,
    round,
    icon,
    variant = "primary",
    className,
    size,
}) => (
    <span
        className={classNames(
            "tag",
            {
                [`tag--${variant}`]: variant,
                [`tag--${size}`]: size,
                "tag--outline": outline,
                "tag--disabled": disabled,
                "tag--round": round,
                "tag--icon": icon,
            },
            className,
        )}
    >
        {children}
    </span>
);
