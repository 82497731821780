import { Address } from "./embeddable/address";
import { TaxLocation } from "./taxLocation";
import { User } from "./user";

export enum EntityIndustry {
    CREATOR = "CREATOR",
    REAL_ESTATE = "REAL_ESTATE",
    OTHER = "OTHER",
}

export interface Entity {
    id: number;
    name: string | null;
    address: Address | null;
    bookkeepingYears: number[];
    bookkeepingStart: Date;
    taxLocations: TaxLocation[];
    onboardingComplete: boolean;
    industry: EntityIndustry;
    iconColor: string;
    isBusiness: boolean;
    isPersonal: boolean;
    user: User | null;
    userId: number | null;
    profile: EntityProfile | null;
    isMockEntity: boolean;
    workspaceId: string | null;
    openingBalanceSubmitted: boolean;
    reportsReady: boolean;
    federalTaxRate: number;
}

export enum EntityType {
    LLC_WITH_S_CORP = "llc_s_corp",
    C_CORP_WITH_S_CORP = "c_corp_with_s_corp",
    NOT_REGISTERED = "not_registered",
    LLC = "llc",
    C_CORP = "c_corp",
}

// will be renamed to EntityType after old onboarding is removed
export enum EntityProfileType {
    SOLE_PROPRIETORSHIP = "sole_proprietorship",
    // NOTE: "llc" is outdated, delete after migraiton
    LLC = "llc",
    SMLLC = "smllc",
    MMLLC = "mmllc",
    S_CORP = "s_corp",
    PARTNERSHIP = "partnership",
    C_CORP = "c_corp",
    NO_LEGAL_ENTITY = "no_legal_entity",
}

export enum EntityProfileIndustry {
    ACCOUNTANT = "accountant",
    AGENCY = "agency",
    BOOKKEEPER = "bookkeeper",
    CONSTRUCTION = "construction",
    CONSULTING = "consulting",
    CONTENT = "content",
    CRYPTO = "crypto",
    TRUCKING = "trucking",
    FINANCIAL_ADVISOR = "financial_advisor",
    INVESTOR = "investor",
    LEGAL = "legal",
    MANUFACTURING = "manufacturing",
    MEDICAL = "medical",
    NON_PROFIT = "non_profit",
    PHYSICAL_GOODS = "physical_goods",
    PROPERTY_MANAGEMENT = "property_management",
    PSYCHOLOGIST = "psychologist",
    REAL_ESTATE_AGENT = "real_estate_agent",
    REAL_ESTATE_INVESTOR = "real_estate_investor",
    RESTAURANT = "restaurant",
    RETAIL = "retail",
    SAAS = "saas",
    TRADEPERSON = "tradeperson",
    ENTERTAINMENT = "entertainment",
    OTHER = "other",
}

export enum ProfessionalType {
    CORPORATE_ACCOUNTANT = "corporate_accountant",
    FIRM_ACCOUNTANT = "firm_accountant",
    BOOKKEEPER = "bookkeeper",
    TAX_ADVISOR = "tax_advisor",
}

export enum FirmSize {
    "0_5" = "0_5",
    "6_9" = "6_9",
    "10_20" = "10_20",
    "21_49" = "21_49",
    "50_PLUS" = "50_PLUS",
}

export enum EntityProfileRevenueBracket {
    LESS_THAN_50K = "<$50k",
    BETWEEN_50K_AND_200K = "$50k - $200k",
    BETWEEN_200K_AND_1M = "$200k - $1M",
    MORE_THAN_1M = "$1M+",
}

export type MergedEntityType = EntityType | EntityProfileType;

export interface EntityProfile {
    description?: string;
    isPartnership?: boolean;
    partnershipPercentage?: number;
    website?: string;
    annualExpenses?: number;
    entityType?: EntityType;

    // Onboarding V2 profile
    annualRevenue?: EntityProfileRevenueBracket;
    type?: EntityProfileType;
    industry?: EntityProfileIndustry;

    // Onboarding V2 firm info
    professionalType?: ProfessionalType;
    firmSize?: FirmSize;
    howDidYouLearn?: string;

    // Onboarding V2 waitlist
    waitlistComment?: string;

    payrollProviders?: string[];
    paymentProcessorRequest?: string;

    // Onboarding V3
    country?: string;
    source?: string;
    ignoreWaitlist?: boolean;
    industryOther?: string;
}
