import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCommands } from "../../commands/useCommands";
import { CommandsDirectory } from "../../commands/lib";

export const AddAccountsRoute: React.FC = () => {
    const navigate = useNavigate();
    const { open } = useCommands();

    useEffect(() => {
        open(CommandsDirectory.ACCOUNTS);
        navigate("/");
    }, [navigate, open]);

    return null;
};
