import { UserRole } from "./types/userEntityAssignment";

export enum UserWorkspaceRole {
    PRIMARY_ADMIN = "primary_admin",
    ADMIN = "admin",
    ACCOUNTANT = "accountant",
    MEMBER = "member",
}

export const userWorkspaceRolesInOrder = [
    UserWorkspaceRole.PRIMARY_ADMIN,
    UserWorkspaceRole.ADMIN,
    UserWorkspaceRole.ACCOUNTANT,
    UserWorkspaceRole.MEMBER,
] as const;

export const entityRoleToWorkspaceRole = {
    [UserRole.ACCOUNTANT]: UserWorkspaceRole.ACCOUNTANT,
    [UserRole.ADMIN]: UserWorkspaceRole.ADMIN,
    [UserRole.MEMBER]: UserWorkspaceRole.MEMBER,
} as const satisfies Record<UserRole, UserWorkspaceRole>;
