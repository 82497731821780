import React, {
    RefObject,
    useCallback,
    useMemo,
    useRef,
    useState,
} from "react";
import { Formik, FormikConfig } from "formik";
import { number, object, string } from "yup";
import { FormikProps } from "formik/dist/types";
import { without } from "lodash";
import { FinancialDocumentUploadProvider } from "../documents/FinancialDocumentUpload/FinancialDocumentUploadProvider";
import { FinancialDocumentUploadContextValue } from "../documents/FinancialDocumentUpload/financialDocumentUploadContext";
import { useRemoveFinancialDocumentMutation } from "../../mutations/financialDocument";
import { FinancialAccountType } from "../../common/types/financialAccount";
import { submitHelper } from "../../helpers/form";
import { UploadingDocumentPlaceholder } from "../documents/lib";
import {
    StandardModal,
    StandardModalBody,
    StandardModalHeader,
} from "../general/Modal/Modal";
import { useEntities } from "../../hooks/useEntities";
import { useWorkspaceContext } from "../../state/workspaceContext";
import { FinancialDocument } from "../../common/types/financialDocument";
import { createFinancialAccountFromDocuments } from "../../lib/financialAccount";
import { queryClient } from "../../queryClient";
import { getEntitiesAccountsQueryKey } from "../../queries/entitiesAccounts";
import { extractAccountInfo } from "../../lib/financialDocument";
import { AccountInfoExtractionResponseDto } from "../../common/dto/financialDocument/account-info-extraction-response.dto";
import {
    ALLOWED_FILE_TYPES_FOR_UNIVERSAL_IMPORT,
    ALLOWED_FINANCIAL_ACCOUNT_TYPES_FOR_FILE_IMPORT,
} from "../../common/constants";
import { AddAccountProps } from "../accounts/AddAccounts/lib";
import { useToaster } from "../general/ToastMessages/useToaster";
import { UniversalImport } from "./UniversalImport";
import { isBankWithoutAccountNumber } from "./lib";

interface ManualImportAccountFormSchema {
    name: string;
    bankName: string;
    accountNumber: string;
    accountType: FinancialAccountType;
    entityId: number;
}

type ManualImportAccountContainerProps = React.ComponentProps<
    typeof StandardModal
> &
    AddAccountProps;

export const UniversalImportAccountContainer: React.FC<
    ManualImportAccountContainerProps
> = ({ defaultEntity, onCreated, ...modalProps }) => {
    const { activeWorkspaceKey } = useWorkspaceContext();
    const uploaderRef = useRef<FinancialDocumentUploadContextValue>();
    const formikRef = useRef<FormikProps<ManualImportAccountFormSchema>>(null);

    const [showAccountDetails, setShowAccountDetails] = useState(false);
    const [creatingAccount, setCreatingAccount] = useState(false);
    const removeFinancialDocumentMutation =
        useRemoveFinancialDocumentMutation();
    const allowedEntities = useEntities({ excludeAllMockEntities: true });
    const { toast } = useToaster();

    const validationSchema = useMemo(
        () =>
            object().shape({
                name: string().optional(),
                bankName: string().required("Provide bank account"),
                accountNumber: string().when(
                    "bankName",
                    ([bankName], schema) =>
                        bankName && isBankWithoutAccountNumber(bankName)
                            ? schema.notRequired()
                            : schema
                                  .required("Provide account number")
                                  .matches(/^\d+$/, "Only numbers allowed"),
                ),
                accountType: string()
                    .required("Select account type")
                    .oneOf(
                        ALLOWED_FINANCIAL_ACCOUNT_TYPES_FOR_FILE_IMPORT,
                        "Select account type",
                    ),
                entityId: number()
                    .required("Select entity")
                    .oneOf(
                        allowedEntities.map((e) => e.id),
                        "Invalid entity",
                    ),
            }),
        [allowedEntities],
    );

    const cleanupAndHide = useCallback(() => {
        if (!uploaderRef.current) {
            return;
        }

        const { uploadingDocuments, clearUploads } = uploaderRef.current;

        uploadingDocuments.forEach((item) => {
            if (item.financialDocument) {
                removeFinancialDocumentMutation.mutate(item.financialDocument);
            }
        });

        clearUploads();

        modalProps.onHide?.();
    }, [modalProps, removeFinancialDocumentMutation]);

    const handleUploadCompleted = useCallback(
        async (uploadedDocuments: FinancialDocument[]) => {
            setShowAccountDetails(true);

            if (!formikRef.current) {
                return;
            }

            const accountInfo: AccountInfoExtractionResponseDto =
                await extractAccountInfo({
                    workspaceId: activeWorkspaceKey,
                    documentIds: uploadedDocuments.map((d) => d.id),
                });
            const { values, setValues } = formikRef.current;

            const newValues: ManualImportAccountFormSchema = { ...values };
            const fieldsToValidate = Object.keys(accountInfo) as Array<
                keyof AccountInfoExtractionResponseDto
            >;

            const nonPopulatedFields = fieldsToValidate.filter(
                (field) => !values[field],
            );

            for (const field of nonPopulatedFields) {
                try {
                    await validationSchema.validateAt(field, accountInfo);
                } catch {
                    continue;
                }

                newValues[field] = accountInfo[field] as any;
            }

            setValues(newValues);
        },
        [activeWorkspaceKey, validationSchema],
    );

    const handleRemoveItem = useCallback(
        (item: UploadingDocumentPlaceholder) => {
            if (!item.financialDocument) {
                return;
            }

            removeFinancialDocumentMutation.mutate(item.financialDocument);
            uploaderRef.current?.updateUploads((prev) => without(prev, item));
        },
        [removeFinancialDocumentMutation],
    );

    const form: FormikConfig<ManualImportAccountFormSchema> = {
        initialValues: {
            name: "",
            bankName: "",
            accountNumber: "",
            accountType: null as any,
            entityId: defaultEntity?.id ?? (null as any),
        },
        validateOnMount: true,
        validationSchema,
        onSubmit: submitHelper({
            loading: creatingAccount,
            setLoading: setCreatingAccount,
            async handler({
                bankName,
                accountNumber,
                accountType,
                name,
                entityId,
            }: ManualImportAccountFormSchema): Promise<void> {
                const financialDocumentIds = uploaderRef
                    .current!.uploadingDocuments.map(
                        (fd) => fd.financialDocument,
                    )
                    .filter((fd) => !!fd)
                    .map((fd) => fd.id);

                if (!financialDocumentIds.length) {
                    return;
                }

                const { needsVerification } =
                    await createFinancialAccountFromDocuments({
                        type: accountType,
                        bankName,
                        accountNumber,
                        name,
                        entityId,
                        documentIds: financialDocumentIds,
                        workspaceId: activeWorkspaceKey,
                    });

                toast(
                    needsVerification
                        ? "Account added. Your transactions are being imported. Check back in 24h."
                        : "Account added. You can upload additional files anytime in the Settings tab.",
                );

                await queryClient.invalidateQueries({
                    queryKey: getEntitiesAccountsQueryKey(activeWorkspaceKey),
                });

                modalProps.onHide?.();
                onCreated();
            },
        }),
    };

    return (
        <FinancialDocumentUploadProvider
            accept={ALLOWED_FILE_TYPES_FOR_UNIVERSAL_IMPORT}
            onCompleted={handleUploadCompleted}
            ref={uploaderRef as RefObject<FinancialDocumentUploadContextValue>}
        >
            <StandardModal size="lg" {...modalProps} onHide={cleanupAndHide}>
                <StandardModalHeader>
                    Import Bank Statements
                </StandardModalHeader>
                <StandardModalBody>
                    <Formik {...form} innerRef={formikRef}>
                        <UniversalImport
                            creatingAccount={creatingAccount}
                            showDetails={showAccountDetails}
                            onRemoveItem={handleRemoveItem}
                        />
                    </Formik>
                </StandardModalBody>
            </StandardModal>
        </FinancialDocumentUploadProvider>
    );
};
