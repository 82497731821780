import React, { useCallback, useMemo } from "react";
import { Collapsible } from "../general/Collapsible/Collapsible";
import mercuryLogo from "../../static/images/icons/mercury.svg";
import { ClockIcon } from "../../icons";
import { timeAgo } from "../../helpers/date";
import { DisplayIntegrationAccount } from "../integrationAccount/DisplayIntegrationAccount";
import { FinancialConnectionWithAccounts } from "../../common/types/financialConnection";
import { useToaster } from "../general/ToastMessages/useToaster";
import css from "./MercuryConnectionStatus.module.scss";
import { CreateMercuryConnection } from "./CreateMercuryConnection";

interface Props {
    connection: FinancialConnectionWithAccounts;
}

export const MercuryConnectionStatus: React.FC<Props> = ({ connection }) => {
    const { toast } = useToaster();

    const handleConnected = useCallback(() => {
        toast(`Integration successfully connected`);
    }, [toast]);

    const mostRecentTransactionsSyncedAt = useMemo(() => {
        const { accounts } = connection;
        const dates = accounts
            .map(({ transactionsSyncedAt }) => transactionsSyncedAt)
            .filter(Boolean) as Date[];

        return new Date(Math.max(...dates.map((date) => date.getTime())));
    }, [connection]);

    const header = (
        <div className={css.header}>
            <img src={mercuryLogo} alt="Mercury" />
            <main>
                <h5>Mercury</h5>
                {mostRecentTransactionsSyncedAt && (
                    <small>
                        <ClockIcon
                            height={12}
                            width={12}
                            className="icon-color-current"
                        />
                        {timeAgo(mostRecentTransactionsSyncedAt)}
                    </small>
                )}
            </main>
        </div>
    );

    return (
        <Collapsible
            className={css.mercuryConnection}
            header={header}
            footer={
                <div className={css.footerSection}>
                    <CreateMercuryConnection
                        btnVariant="secondary"
                        onCreated={handleConnected}
                    >
                        Connect another account
                    </CreateMercuryConnection>
                </div>
            }
        >
            <>
                {connection.accounts.map((account) => (
                    <DisplayIntegrationAccount
                        key={account.id}
                        className={css.displayAccount}
                        connection={connection}
                        integrationAccount={account}
                    />
                ))}
            </>
        </Collapsible>
    );
};
