import React, { useState } from "react";
import { UseMutationResult } from "@tanstack/react-query";
import { Button, ExtendedButtonProps } from "../general/Button/Button";
import { ButtonWithLoader } from "./ButtonWithLoader/ButtonWithLoader";
import { PlainModal, StandardModalHeader } from "./Modal/Modal";

interface CommonProps {
    title?: React.ReactNode;
    question?: React.ReactNode;
    yes?: string;
    yesBtnVariant?: ExtendedButtonProps["variant"];
    no?: string;
    noBtnVariant?: ExtendedButtonProps["variant"];
    onReject?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    closeButton?: boolean;

    onHide?: () => void;
    show?: boolean;
    disableAnimation?: boolean;
    className?: string;
}

interface PromiseProps extends CommonProps {
    onConfirm: (e: any) => Promise<unknown> | void;
    onConfirmMutation?: never;
}

interface MutationProps extends CommonProps {
    onConfirm?: never;
    onConfirmMutation: UseMutationResult<unknown, unknown, void>;
}

export const ConfirmationModal: React.FC<PromiseProps | MutationProps> = ({
    title,
    question = "Are you sure?",
    yes = "Yes",
    no = "No",
    yesBtnVariant = "default",
    noBtnVariant = "secondary",
    onConfirm,
    onConfirmMutation,
    onReject,
    show,
    onHide,
    disableAnimation,
    className,
}) => {
    const [loading, setLoading] = useState(false);

    return (
        <PlainModal
            onHide={onHide}
            disableAnimation={disableAnimation}
            show={show}
            className={className}
        >
            <>
                {title && <StandardModalHeader>{title}</StandardModalHeader>}

                {question && (
                    <p
                        className="mb-4"
                        data-testid="confirmation-modal-question"
                    >
                        {question}
                    </p>
                )}
                <footer className="d-flex justify-content-end gap-2">
                    {onReject && (
                        <Button
                            variant={noBtnVariant}
                            onClick={onReject}
                            size="lg"
                            data-testid="confirmation-modal-no-button"
                        >
                            {no}
                        </Button>
                    )}
                    {onConfirmMutation ? (
                        <ButtonWithLoader
                            variant={yesBtnVariant}
                            mutation={onConfirmMutation}
                            size="lg"
                            data-testid="confirmation-modal-yes-button"
                        >
                            {yes}
                        </ButtonWithLoader>
                    ) : (
                        <ButtonWithLoader
                            variant={yesBtnVariant}
                            loading={loading}
                            setLoading={setLoading}
                            onClick={onConfirm}
                            size="lg"
                            data-testid="confirmation-modal-yes-button"
                        >
                            {yes}
                        </ButtonWithLoader>
                    )}
                </footer>
            </>
        </PlainModal>
    );
};
