import React from "react";
import { Field, Form, Formik, FormikConfig } from "formik";
import classNames from "classnames";
import { format, isSameDay } from "date-fns";
import { FormRowRaw } from "../../../forms/FormRowRaw";
import {
    currencyFormatterFractionsIfNeeded,
    currencyFormatterNoFractions,
} from "../../../../common/helpers/currency";
import { Card } from "../../../general/Card/Card";
import {
    FlatRatePlan,
    FlatRatePrice,
} from "../../../../common/flatRateBilling";
import { fromIntToMonetary } from "../../../../common/helpers/monetary";
import { shortDateWithOrdinalsFormat } from "../../../../common/helpers/date";
import { SubscriptionDiscount } from "../../../../common/types/billing";
import { discountLabel } from "../../../../helpers/billing";
import { Button } from "../../../general/Button/Button";
import styles from "./UpgradeSubscriptionModal.module.scss";

interface Props {
    plan: FlatRatePlan;
    pricing: FlatRatePrice;
    discountCode: string | null;
    onDiscountCodeChange: (code: string | null) => void;
    codeInvalid?: boolean;
    billingDate: Date;
    discountFromSubscription?: SubscriptionDiscount | null;
}

export const UpgradeModalFooter: React.FC<Props> = ({
    discountCode,
    onDiscountCodeChange,
    codeInvalid,
    pricing,
    billingDate,
    discountFromSubscription,
}) => {
    const { total, totalBeforeDiscount } = pricing;

    const form: FormikConfig<{ code: string }> = {
        initialValues: { code: discountCode ?? "" },
        onSubmit: ({ code }) => {
            if (code) {
                onDiscountCodeChange(code);
            }
        },
        onReset() {
            onDiscountCodeChange(null);
        },
    };

    const futureBilling = !isSameDay(billingDate, new Date());
    const activeDiscount = pricing.discount ?? discountFromSubscription;

    return (
        <Card className={classNames(styles.footer, "p-0")}>
            <section className={styles.section}>
                <Formik {...form}>
                    {({ values, resetForm }) => (
                        <Form className={styles.form}>
                            <FormRowRaw
                                value={values.code}
                                field={
                                    <Field
                                        name="code"
                                        className="form-control form-control-xs"
                                        readOnly={!!discountCode}
                                        data-testid="coupon-input"
                                    />
                                }
                                error={
                                    codeInvalid
                                        ? "Invalid or expired code"
                                        : undefined
                                }
                                showErrorMessage={codeInvalid}
                            />

                            {discountCode ? (
                                <Button
                                    onClick={() => resetForm()}
                                    variant="secondary"
                                    data-testid="remove-coupon-button"
                                >
                                    Remove
                                </Button>
                            ) : (
                                <Button
                                    variant="secondary"
                                    type="submit"
                                    data-testid="submit-coupon-button"
                                >
                                    Add
                                </Button>
                            )}
                        </Form>
                    )}
                </Formik>
            </section>

            {activeDiscount ? (
                <section className={styles.section}>
                    <main className={styles.info}>Applied discount</main>

                    <aside className={styles.pricing}>
                        {discountLabel(activeDiscount)}
                    </aside>
                </section>
            ) : null}

            {futureBilling ? (
                <section className={styles.section}>
                    <main className={styles.info}>Total for today</main>

                    <aside className={classNames(styles.pricing, styles.value)}>
                        {currencyFormatterNoFractions.format(0)}
                    </aside>
                </section>
            ) : null}

            <section className={styles.section}>
                <main className={styles.info}>
                    Total{" "}
                    {futureBilling
                        ? `on ${format(
                              pricing.nextBillingDate,
                              shortDateWithOrdinalsFormat,
                          )}`
                        : "for today"}
                </main>

                <aside className={classNames(styles.pricing, styles.value)}>
                    <div className={styles.totals}>
                        {totalBeforeDiscount !== total ? (
                            <span className={styles.discountedTotal}>
                                {currencyFormatterFractionsIfNeeded.format(
                                    fromIntToMonetary(totalBeforeDiscount),
                                )}
                            </span>
                        ) : null}

                        <span className={styles.total}>
                            {total < 0 ? (
                                <>
                                    {currencyFormatterFractionsIfNeeded.format(
                                        fromIntToMonetary(0),
                                    )}

                                    <div className={styles.info}>
                                        Credit:{" "}
                                        {currencyFormatterFractionsIfNeeded.format(
                                            fromIntToMonetary(Math.abs(total)),
                                        )}{" "}
                                        applied to your next payment
                                    </div>
                                </>
                            ) : (
                                <>
                                    {currencyFormatterFractionsIfNeeded.format(
                                        fromIntToMonetary(total),
                                    )}
                                </>
                            )}
                        </span>
                    </div>
                </aside>
            </section>
        </Card>
    );
};
