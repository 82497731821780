import { useState } from "react";
import classNames from "classnames";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { PlusIcon } from "../../../../icons";
import styles from "../Categories.module.scss";
import { getErrorForAddCategory } from "../utils";
import { useCategoriesTree } from "../../../../hooks/useCategoriesTree";
import { useCreateCustomCategoryMutation } from "../../../../api/category.api";

export const AddCategoryRow: React.FC<{
    parentCategoryId: string;
    isFocused: boolean;
    setIsFocused: (value: boolean) => void;
    inputRef: React.RefObject<HTMLInputElement>;
}> = ({ parentCategoryId, isFocused, setIsFocused, inputRef }) => {
    const kickCategories = useCategoriesTree();
    const createCustomCategoryMutation = useCreateCustomCategoryMutation();

    const [inputValue, setInputValue] = useState("");
    const [error, setError] = useState<string | null>(null);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setInputValue(e.target.value);
        setError(
            getErrorForAddCategory(
                kickCategories,
                parentCategoryId,
                e.target.value,
            ),
        );
    };

    return (
        <div className="d-flex align-items-center">
            <PlusIcon
                className={classNames(
                    styles.addCategoryIcon,
                    "icon-color-gray-500",
                )}
            />
            <OverlayTrigger
                show={isFocused && !!error}
                placement="top"
                overlay={<Tooltip id="add-category-error">{error}</Tooltip>}
            >
                <input
                    className={classNames(styles.addCategoryInput, {
                        [styles.addCategoryInputError]: error,
                    })}
                    type="text"
                    placeholder={`Add subcategory...`}
                    onBlur={() => {
                        setIsFocused(false);
                    }}
                    onFocus={() => {
                        setIsFocused(true);
                    }}
                    value={inputValue}
                    onChange={onChange}
                    onKeyDown={async (e) => {
                        if (error) {
                            return;
                        }
                        if (e.key === "Enter") {
                            await createCustomCategoryMutation.mutateAsync({
                                label: inputValue,
                                parentCategoryId,
                            });
                            setInputValue("");
                            setTimeout(() => {
                                inputRef.current?.scrollIntoView({
                                    behavior: "instant",
                                    block: "end",
                                });
                            }, 5);
                        }
                    }}
                    ref={inputRef}
                />
            </OverlayTrigger>
        </div>
    );
};
