import React from "react";
import isNumber from "lodash/isNumber";
import NumberFormat from "react-number-format";
import { FilterProps } from "../lib";
import { FormRowRaw } from "../../../forms/FormRowRaw";
import styles from "./InvoiceAmountFilter.module.scss";

export const InvoiceAmountFilter: React.FC<FilterProps> = ({
    filters,
    onChange,
}) => {
    const minAmountValue = isNumber(filters.minAmount) ? filters.minAmount : "";
    const maxAmountValue = isNumber(filters.maxAmount) ? filters.maxAmount : "";

    return (
        <div className="invoice-filter-form__amount amount-filter">
            <section className={styles.values}>
                <FormRowRaw
                    value={minAmountValue}
                    label="From"
                    size="sm"
                    field={
                        <NumberFormat
                            value={minAmountValue}
                            onValueChange={(v) =>
                                onChange({
                                    minAmount: v.floatValue ?? undefined,
                                })
                            }
                            prefix="$ "
                            allowEmptyFormatting
                            className="form-control form-control-sm"
                        />
                    }
                />

                <FormRowRaw
                    value={maxAmountValue}
                    label="To"
                    size="sm"
                    field={
                        <NumberFormat
                            value={maxAmountValue}
                            onValueChange={(v) =>
                                onChange({
                                    maxAmount: v.floatValue ?? undefined,
                                })
                            }
                            prefix="$ "
                            allowEmptyFormatting
                            className="form-control form-control-sm"
                        />
                    }
                />
            </section>
        </div>
    );
};
