import React from "react";
import classNames from "classnames";
import { Button } from "../../general/Button/Button";
import { ChildrenProps } from "../../../types";
import { LeftArrowIcon } from "../../../icons";
import styles from "./Footer.module.scss";

interface Props extends ChildrenProps {
    className?: string;
    onBack?(): void;
    onBackDisabled?: boolean;
}

export const Footer: React.FC<Props> = ({
    onBack,
    onBackDisabled,
    className,
    children,
}) => (
    <footer className={classNames(styles.footer, className)}>
        {onBack && (
            <Button
                type="button"
                variant="secondary"
                icon
                onClick={onBack}
                disabled={onBackDisabled}
            >
                <LeftArrowIcon />
            </Button>
        )}

        <div>{children}</div>
    </footer>
);
