import { useCallback, useMemo, useState } from "react";
import { UpdateTransactionDto } from "../../../common/dto/transactions/update-transaction.dto";
import { currencyFormatter } from "../../../common/helpers/currency";
import {
    calculatePotentialSavings,
    isReceiptRequired,
} from "../../../common/taxSavingRules";
import { Action, ActionStatus } from "../../../common/types/actions";
import { ZoomInIcon } from "../../../icons";
import { useRuleCreationMutation } from "../../../mutations/transactionRules";
import { ActionCard } from "../ActionCard";
import { ActionCardCompleted } from "../ActionCardCompleted";
import { ActionCardSection } from "../ActionCardSection";
import { ActionCardTransaction } from "../ActionCardTransaction";
import { ActionTransactionRule } from "../ActionTransactionRule";
import { ActionCloseButton } from "../ActionCloseButton";
import { ActionConfirmButton } from "../ActionConfirmButton";
import { CampaignCardProps } from "../ActionItem";
import { TransactionDetails } from "../transactionDetails/TransactionDetails";
import { CreateTransactionRuleDto } from "../../../common/dto/transactionRule/create-transaction-rule.dto";
import { isVerifiedCounterparty } from "../../../common/helpers/counterparties";
import { Button } from "../../general/Button/Button";
import { useCategoriesById } from "../../../hooks/useCategoriesById";
import { TransactionUpdateMetaDto } from "../../../common/dto/actions/transaction-update-meta.dto";

export interface PersonalToBusinessCardProps
    extends CampaignCardProps<TransactionUpdateMetaDto> {
    renderCompletedCtas?: (closeCard: () => void) => React.ReactNode;
}

const canCreateRule = (action: Action<TransactionUpdateMetaDto>) =>
    action.transaction?.counterparty &&
    isVerifiedCounterparty(action.transaction.counterparty) &&
    action.meta.transactionUpdate;

export const PersonalToBusinessCard: React.FC<PersonalToBusinessCardProps> = ({
    action: originalAction,
    onConfirm,
    onSkip,
    isLoading,
    renderCompletedCtas,
    ...rest
}) => {
    const [action, setAction] = useState(originalAction);
    const [rule, setRule] = useState<CreateTransactionRuleDto>();
    const { isCompleted } = rest;
    const ruleCreation = useRuleCreationMutation();
    const categoriesById = useCategoriesById();
    const categoryFromUpdate = action.meta.transactionUpdate?.categoryId
        ? categoriesById[action.meta.transactionUpdate.categoryId]
        : undefined;

    const receiptNeeded = useMemo(
        () =>
            action.transaction &&
            isReceiptRequired({
                ...action.transaction,
                category: categoryFromUpdate ?? action.transaction.category,
            }),
        [action, categoryFromUpdate],
    );

    const potentialSavings = useMemo(() => {
        if (!action.transaction || !categoryFromUpdate) {
            return 0;
        }
        return calculatePotentialSavings({
            ...action.transaction,
            category: categoryFromUpdate,
        });
    }, [action, categoryFromUpdate]);

    const updateTransaction = useCallback(
        (update: Partial<UpdateTransactionDto>) => {
            setAction((prev) => ({
                ...prev,
                meta: {
                    ...prev.meta,
                    transactionUpdate: {
                        ...prev.meta.transactionUpdate,
                        ...update,
                    },
                },
            }));
        },
        [],
    );

    const confirmAction = useCallback(async () => {
        if (rule) {
            await ruleCreation.mutateAsync(rule);
        }

        onConfirm?.(action, {
            rule,
        });
    }, [action, onConfirm, rule, ruleCreation]);

    const skipAction = useCallback(() => {
        onSkip?.(action);
    }, [action, onSkip]);

    const confirmedMessage =
        potentialSavings === 0
            ? "Transaction moved to business entity"
            : `${currencyFormatter.format(potentialSavings)} write-off confirmed`;

    const firstSection = (inOpened: boolean, closeCard: () => void) =>
        isCompleted ? (
            <ActionCardCompleted header={confirmedMessage}>
                {renderCompletedCtas ? (
                    renderCompletedCtas(closeCard)
                ) : (
                    <ActionCloseButton onClick={closeCard} />
                )}
            </ActionCardCompleted>
        ) : (
            <ActionCardSection
                inOpened={inOpened}
                header="Move this personal transaction to business?"
                icon={<ZoomInIcon />}
                isTitle
                ctas={
                    <>
                        {action.status !== ActionStatus.SKIPPED && (
                            <Button
                                variant="secondary"
                                onClick={() => skipAction()}
                            >
                                Skip
                            </Button>
                        )}
                        <ActionConfirmButton
                            loading={!!isLoading || ruleCreation.isPending}
                            onClick={() => confirmAction()}
                            savings={potentialSavings}
                        />
                    </>
                }
            >
                {action.transaction && (
                    <ActionCardTransaction transaction={action.transaction} />
                )}
            </ActionCardSection>
        );

    const otherSections = (inOpened: boolean) => (
        <>
            <ActionCardSection
                header="If so, confirm these transaction details"
                inOpened={inOpened}
            >
                <TransactionDetails
                    action={action}
                    onUpdate={updateTransaction}
                    showReceipt={receiptNeeded}
                />
            </ActionCardSection>
            {canCreateRule(action) && (
                <ActionCardSection
                    header="Create a rule for next time?"
                    inOpened={inOpened}
                >
                    <ActionTransactionRule
                        transaction={action.transaction!}
                        update={action.meta.transactionUpdate!}
                        onUpdate={setRule}
                    />
                </ActionCardSection>
            )}
        </>
    );

    return (
        <ActionCard
            {...rest}
            firstSection={firstSection}
            otherSections={otherSections}
        />
    );
};
