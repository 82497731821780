const vowels = ["a", "e", "i", "o", "u"];
const esEndings = ["s", "x", "z", "ch", "sh", "o"];
const vesEndings = ["f", "fe"];

export function pluralize(word: string, count: number): string {
    const plural = count !== 1;

    if (!plural) {
        return word;
    }

    if (word.endsWith("y")) {
        if (vowels.includes(word[word.length - 2])) {
            return word + "s";
        } else {
            return word.substring(0, word.length - 1) + "ies";
        }
    }

    if (esEndings.some((ending) => word.endsWith(ending))) {
        return word + "es";
    }

    if (vesEndings.some((ending) => word.endsWith(ending))) {
        return word.substring(0, word.length - 1) + "ves";
    }

    return word + "s";
}

export function pluralizeWithCount(word: string, count: number): string {
    return `${count} ${pluralize(word, count)}`;
}

export function getPossessive(word: string): string {
    if (word.endsWith("'") || word.endsWith("'s")) {
        return word;
    }

    return word + (word.endsWith("s") ? "'" : "'s");
}
