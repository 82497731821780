import { useCallback } from "react";
import {
    TransactionDirectionType,
    mapCategoryName,
} from "../common/categories";
import { isValidTaxonomy } from "../common/helpers/taxonomy";
import { useCategoriesById } from "./useCategoriesById";
import { useStandardCategories } from "./useStandardCategories";

export type CategoryLabelGetter = (
    id: string,
    transactionDirection?: TransactionDirectionType,
) => string;

export function useCategoryLabelGetter(): CategoryLabelGetter {
    const categories = useCategoriesById();
    const standardCategories = useStandardCategories();

    return useCallback(
        (id: string, transactionDirection = TransactionDirectionType.mixed) => {
            // supporting taxonomy passed as an id is kept for backwards compatibility but should be removed
            const category = isValidTaxonomy(id)
                ? standardCategories[id]
                : categories[id];

            if (!category) {
                return "";
            }

            return mapCategoryName({
                category,
                transactionDirection,
            });
        },
        [categories, standardCategories],
    );
}
