import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
} from "react";
import { isFunction, isString } from "lodash";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";
import {
    AddAccountProps,
    CreateAccountHandle,
} from "../accounts/AddAccounts/lib";
import { ExtendedButtonProps } from "../general/Button/Button";
import { useInteraction } from "../../hooks/useInteraction";
import { usePayPalConnectionCreationMutation } from "../../api/paypal.api";

interface Props extends AddAccountProps {
    children?:
        | React.ReactNode
        | ((props: {
              connecting: boolean;
              initializeConnection: () => void;
          }) => React.ReactNode);
    btnVariant?: ExtendedButtonProps["variant"];
}
export const CreatePayPalConnection = forwardRef<CreateAccountHandle, Props>(
    (
        {
            children = <>Connect</>,
            onCreated,
            defaultEntity,
            btnVariant = "tertiary",
        },
        ref,
    ) => {
        const paypalWindowRef = useRef<Window | null>();
        const connectionCreationMutation =
            usePayPalConnectionCreationMutation(onCreated);
        const initializeConnection = useCallback(() => {
            paypalWindowRef.current = window.open("/api/paypal-auth");
        }, []);

        useEffect(() => {
            const handler = (event: MessageEvent) => {
                if (
                    !isString(event.data) ||
                    !event.data.startsWith("{") ||
                    !event.data.endsWith("}")
                ) {
                    return;
                }
                const data = JSON.parse(event.data);

                if (!data.onboardedCompleteToken || !data.sharedId) {
                    return;
                }

                paypalWindowRef.current?.close();

                connectionCreationMutation.mutate({
                    authCode: data.onboardedCompleteToken,
                    sharedId: data.sharedId,
                    defaultEntityId: defaultEntity?.id,
                });
            };

            window.addEventListener("message", handler);

            return () => {
                window.removeEventListener("message", handler);
            };
        }, [connectionCreationMutation, defaultEntity]);

        useImperativeHandle(
            ref,
            () => ({
                initializeConnection,
            }),
            [initializeConnection],
        );

        const authorize = useInteraction(initializeConnection);

        if (isFunction(children)) {
            return children({
                connecting: connectionCreationMutation.isPending,
                initializeConnection,
            });
        }

        return (
            <span {...authorize}>
                <ButtonWithLoader
                    loading={connectionCreationMutation.isPending}
                    variant={btnVariant}
                    size="sm"
                >
                    {children}
                </ButtonWithLoader>
            </span>
        );
    },
);
