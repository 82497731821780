import { isObject, isString } from "lodash";
import { Counterparty } from "../../../../../common/types/counterparty";
import { CategoryLabelGetter } from "../../../../../hooks/useCategoryLabelGetter";
import {
    RuleConditionType,
    TransactionRule,
    TransactionRuleCondition,
} from "../../../../../common/types/transactionRule";
import { FinancialAccount } from "../../../../../common/types/financialAccount";
import { Entity } from "../../../../../common/types/entity";
import { getAccountNameWithLast4 } from "../../../../../common/helpers/financialAccount";

interface GetSearchableLabelOptions {
    labelGetter: CategoryLabelGetter;
    counterparties: Counterparty[];
    financialAccounts: FinancialAccount[];
    entities: Entity[];
}

export function getSearchableLabel(
    rule: TransactionRule,
    { labelGetter, ...rest }: GetSearchableLabelOptions,
): string {
    const categoryLabel = rule.categoryId ? labelGetter(rule.categoryId) : "";

    return rule.conditions
        .map((condition) =>
            getConditionSearchableLabel(condition, { labelGetter, ...rest }),
        )
        .concat(categoryLabel)
        .join(" ");
}

function getConditionSearchableLabel(
    condition: TransactionRuleCondition,
    {
        counterparties,
        financialAccounts,
        entities,
        labelGetter,
    }: GetSearchableLabelOptions,
): string {
    switch (condition.conditionType) {
        case RuleConditionType.COUNTERPARTY:
            return getCounterpartyLabel(condition, counterparties);
        case RuleConditionType.FINANCIAL_ACCOUNTS:
            return getFinancialAccountsLabel(condition, financialAccounts);
        case RuleConditionType.ENTITY:
            return getEntitiesLabel(condition, entities);
        case RuleConditionType.DESCRIPTION_INCLUDES:
        case RuleConditionType.DESCRIPTION_IS:
            return getDescriptionLabel(condition);
        case RuleConditionType.AMOUNT:
            return getAmountLabel(condition);
        case RuleConditionType.CATEGORY:
        case RuleConditionType.SUGGESTED_CATEGORY:
            return getCategoryLabel(condition, labelGetter);
    }
}

function getCounterpartyLabel(
    condition: TransactionRuleCondition,
    counterparties: Counterparty[],
): string {
    return (
        counterparties.find(
            (counterparty) => counterparty.id === condition.value,
        )?.name ?? ""
    );
}

function getFinancialAccountsLabel(
    condition: TransactionRuleCondition,
    financialAccounts: FinancialAccount[],
): string {
    if (!Array.isArray(condition.value)) {
        return "";
    }
    const selectedAccounts = condition.value
        .map((accountId) =>
            financialAccounts.find((account) => account.id === accountId),
        )
        .filter((a) => a !== undefined);

    return selectedAccounts
        .map((account) => getAccountNameWithLast4(account))
        .join(" ");
}

function getEntitiesLabel(
    condition: TransactionRuleCondition,
    entities: Entity[],
): string {
    if (!Array.isArray(condition.value)) {
        return "";
    }
    const selectedEntities = condition.value
        .map((entityId) => entities.find((entity) => entity.id === entityId))
        .filter((e) => e !== undefined);

    return selectedEntities.map((entity) => entity.name).join(" ");
}

function getDescriptionLabel(condition: TransactionRuleCondition): string {
    if (isString(condition.value)) {
        return condition.value;
    }
    if (Array.isArray(condition.value)) {
        return condition.value.join(" ");
    }

    return "";
}

function getAmountLabel(condition: TransactionRuleCondition): string {
    if (!isObject(condition.value) || Array.isArray(condition.value)) {
        return "";
    }

    const amounts: number[] = [];
    if ("min" in condition.value && condition.value.min) {
        amounts.push(condition.value.min);
    }
    if ("max" in condition.value && condition.value.max) {
        amounts.push(condition.value.max);
    }

    return amounts.join(" ");
}

function getCategoryLabel(
    condition: TransactionRuleCondition,
    labelGetter: CategoryLabelGetter,
): string {
    if (!isString(condition.value)) {
        return "";
    }
    return labelGetter(condition.value);
}
