import React from "react";
import { useField } from "formik";
import { TransactionRuleActionFormSchema } from "../constants";
import { EntitySelect } from "../../../../entity/EntitySelect";
import { useEntities } from "../../../../../hooks/useEntities";

interface Props {
    index: number;
}

export const EntityActionForm: React.FC<Props> = ({ index }) => {
    const [field, , helpers] = useField<
        TransactionRuleActionFormSchema["entityValue"]
    >(`actions.${index}.entityValue`);
    const entities = useEntities();
    const selectedEntity = entities.find((e) => e.id === field.value);

    return (
        <EntitySelect
            selectedEntity={selectedEntity}
            entities={entities}
            onChange={(e) => helpers.setValue(e?.id)}
            dropdownKey={`ruleEntityAction${index}`}
            size="xs"
        />
    );
};
