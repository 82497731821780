import { SubscriptionStatus } from "../common/billing";
import { SubscriptionType } from "../common/types/billing";
import { useBillingStatus } from "./useBillingStatus";
import { useUser } from "./useUser";

export function useBillingType() {
    const { stripeSubscriptionStatus } = useUser();
    const { subscriptionDetails } = useBillingStatus();

    if (
        !subscriptionDetails ||
        stripeSubscriptionStatus !== SubscriptionStatus.ACTIVE
    ) {
        return SubscriptionType.FLAT_RATE;
    }

    return subscriptionDetails.type;
}
