import React, { MouseEventHandler, useCallback } from "react";
import classNames from "classnames";
import { useInteraction } from "../../../hooks/useInteraction";
import { ChildrenProps } from "../../../types";

interface Props extends ChildrenProps {
    className?: string;
    onClick?: MouseEventHandler;
    disabled?: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

export const SelectDropdownOption: React.FC<Props> = ({
    className,
    onClick,
    disabled,
    children,
    onMouseEnter,
    onMouseLeave,
}) => {
    const handleClick = useCallback(
        (e: React.MouseEvent) => {
            if (!disabled && onClick) {
                onClick(e);
            }
        },
        [onClick, disabled],
    );

    const clickIfNotDisabled = useInteraction(handleClick);
    const mouseEnter = useInteraction(onMouseEnter, "onMouseEnter");
    const mouseLeave = useInteraction(onMouseLeave, "onMouseLeave");

    return (
        <div
            className={classNames("select-dropdown__option", className, {
                "select-dropdown__option--disabled": disabled,
            })}
            data-testid="select-dropdown-option"
            {...clickIfNotDisabled}
            {...mouseEnter}
            {...mouseLeave}
        >
            {children}
        </div>
    );
};
