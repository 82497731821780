import { InviteCode } from "../../types/inviteCode";
import { UserWorkspaceRole } from "../../userWorkspaceRoles";
import { WorkspaceMemberEntityAssignmentDto } from "./workspace-member-entity-assignment.dto";

export class WorkspaceInviteDto {
    static deserialize(dto: WorkspaceInviteDto): InviteCode {
        return {
            id: dto.id,
            email: dto.email,
            used: dto.used,
            entityAssignments: dto.entityAssignments.map(
                WorkspaceMemberEntityAssignmentDto.deserialize,
            ),
            role: dto.role,
            iconColor: dto.iconColor,
        };
    }

    id: number;
    email: string | null;
    used: boolean;
    entityAssignments: WorkspaceMemberEntityAssignmentDto[];
    role: UserWorkspaceRole | null;
    iconColor: string;

    constructor(invite: InviteCode) {
        this.id = invite.id;
        this.email = invite.email;
        this.used = invite.used;
        this.entityAssignments = invite.entityAssignments.map(
            (ea) => new WorkspaceMemberEntityAssignmentDto(ea),
        );
        this.role = invite.role;
        this.iconColor = invite.iconColor;
    }
}
