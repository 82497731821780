import classNames from "classnames";

import gridTableStyles from "@styles/grid-table.module.scss";
import { TransactionDirectionType } from "../../common/categories";
import { TransactionListItemDto } from "../../common/types/transaction";
import { formatDateForTransaction } from "../../helpers/date";
import { CategorySelectSimple } from "../transactions/CategorySelect/CategorySelectSimple";
import { TransactionAccountColumnContent } from "../transactions/ListItem/TransactionAccountColumnContent";
import { TransactionAmount } from "../transactions/TransactionAmount";
import { Category } from "../../common/types/category";
import styles from "./ActionCardTransactionsTable.module.scss";
import { TransactionDescriptionContent } from "./transactionContent/TransactionDescriptionContent";

interface Props {
    transactions: TransactionListItemDto[];
    onUpdateCategory: (
        transaction: TransactionListItemDto,
        category: Category,
    ) => void;
    footer?: React.ReactNode;
}

export const ActionCardTransactionsTable: React.FC<Props> = ({
    transactions,
    onUpdateCategory,
    footer,
}) => {
    const getTransactionDirection = (transaction: TransactionListItemDto) =>
        transaction.amount > 0
            ? TransactionDirectionType.incoming
            : TransactionDirectionType.outgoing;

    return (
        <div className={classNames(gridTableStyles.gridTable, styles.table)}>
            {transactions.map((transaction) => (
                <div
                    className={classNames(gridTableStyles.row, styles.row)}
                    key={transaction.id}
                >
                    <div>{formatDateForTransaction(transaction.date)}</div>
                    <TransactionAccountColumnContent
                        transaction={transaction}
                        size="xs"
                    />
                    <TransactionDescriptionContent transaction={transaction} />

                    <TransactionAmount
                        className={styles.amount}
                        transaction={transaction}
                        color
                    />
                    <CategorySelectSimple
                        showPersonal={false}
                        size="xs"
                        onSelected={(category) =>
                            onUpdateCategory(transaction, category)
                        }
                        value={transaction.category}
                        transactionDirection={getTransactionDirection(
                            transaction,
                        )}
                        dropdownKey={`action-detail-category-${transaction.id}`}
                    />
                </div>
            ))}

            {footer && <footer className={styles.footer}>{footer}</footer>}
        </div>
    );
};
