import { ClassesSettings } from "./ClassesSettings";

export const ClassesPage: React.FC = () => (
    <div>
        <ClassesSettings
            closeSettingsModal={() => {}}
            showHeaderTitle={false}
        />
    </div>
);
