import React from "react";
import classNames from "classnames";
import styles from "./DiscountCode.module.scss";

interface Props {
    name: string;
    value: number;
    className?: string;
}

export const BetaDiscountCode: React.FC<Props> = ({
    name,
    value,
    className,
}) => {
    const normalizedName = name.replace(/\W/g, "").toLowerCase();

    return (
        <div className={classNames(styles.code, className)}>
            {value}
            {normalizedName}
        </div>
    );
};
