import React, { useCallback } from "react";
import classNames from "classnames";
import { useField } from "formik";
import styles from "../RuleForm.module.scss";
import { PlayIcon, PlusIcon } from "../../../../../icons";
import { TransactionRuleActionFormSchema } from "../constants";
import { Button } from "../../../../general/Button/Button";
import { RuleActionForm } from "./RuleActionForm";

export const RuleActionsForm: React.FC = () => {
    const [{ value: actions }, , { setValue: setActions }] =
        useField<TransactionRuleActionFormSchema[]>("actions");

    const addAction = useCallback(() => {
        setActions([...actions, {}]);
    }, [actions, setActions]);

    const removeAction = useCallback(
        (index: number) => {
            setActions(actions.filter((_, i) => i !== index));
        },
        [actions, setActions],
    );

    return (
        <section className={styles.actions}>
            <header className={styles.header}>
                <aside
                    className={classNames(
                        styles.sectionIcon,
                        styles.sectionIconActions,
                    )}
                >
                    <PlayIcon className="icon-color-blue-600" />
                </aside>
                <h5>Action</h5>
            </header>

            <main className={styles.content}>
                <div className={styles.sectionRows}>
                    {actions.map((action, index) => (
                        <div
                            className={styles.sectionRow}
                            key={`${action.actionType}_${action.entityValue}_${action.categoryValue}`}
                        >
                            <RuleActionForm
                                index={index}
                                onRemove={removeAction}
                            />
                        </div>
                    ))}
                    <div className={styles.sectionRow}>
                        <Button
                            variant="secondary"
                            onClick={addAction}
                            type="button"
                        >
                            <PlusIcon />
                            Add
                        </Button>
                    </div>
                </div>
            </main>
        </section>
    );
};
