import React, { useMemo } from "react";
import "./AccountCard.scss";
import classNames from "classnames";
import { isBoolean } from "lodash";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getAnonymousAccountNumber } from "../../../common/helpers/bankAccount";
import { EMPTY_STATE } from "../../../common/constants";
import {
    getAccountNameForConfirmation,
    getAccountNameWithLast4,
    getLeadingAccountConnection,
} from "../../../common/helpers/financialAccount";
import { FinancialAccount } from "../../../common/types/financialAccount";
import { FinancialConnection } from "../../../common/types/financialConnection";
import { FinancialConnectionIcon } from "../../financialConnection/FinancialConnectionIcon";
import { BreakableText } from "../BreakableText/BreakableText";
import { IntegrationConnection } from "../../../common/types/integrationConnection";

export interface AccountCardProps {
    account: FinancialAccount;
    connection?: FinancialConnection | IntegrationConnection;
    showNumber?: boolean;
    onlyNumber?: boolean;
    numberInName?: boolean;
    showType?: boolean;
    className?: string;
    iconSize?: "xs" | "sm" | "md";
    maxNameLength?: number;
    accountNameConfirmationMode?: boolean;
    displayNameAsTooltip?: boolean;
}

function getAccountClassificationLabel(
    account: FinancialAccount,
): "Business" | "Personal" | typeof EMPTY_STATE {
    if (!isBoolean(account.isBusiness)) {
        return EMPTY_STATE;
    }

    return account.isBusiness ? "Business" : "Personal";
}

export const AccountCard: React.FC<AccountCardProps> = ({
    account,
    connection,
    showNumber,
    numberInName,
    className,
    showType = true,
    onlyNumber = false,
    iconSize = "md",
    maxNameLength,
    accountNameConfirmationMode,
    displayNameAsTooltip,
}) => {
    connection =
        connection ?? getLeadingAccountConnection(account) ?? undefined;

    let label: string | null = null;

    if (showNumber && account.accountNumberMask) {
        label = getAnonymousAccountNumber(account.accountNumberMask);
    } else if (showType) {
        label = getAccountClassificationLabel(account);
    }

    let displayedName: string = useMemo(() => {
        if (accountNameConfirmationMode) {
            return getAccountNameForConfirmation(account);
        }
        if (!account.accountNumberMask) {
            return account.name ?? "";
        }
        if (onlyNumber) {
            return getAnonymousAccountNumber(account.accountNumberMask, 0);
        }
        if (numberInName) {
            return getAccountNameWithLast4(account, 2);
        }

        return account.name ?? "";
    }, [account, onlyNumber, numberInName, accountNameConfirmationMode]);

    if (maxNameLength && displayedName.length > maxNameLength) {
        displayedName = displayedName.slice(0, maxNameLength) + "...";
    }

    return (
        <div
            className={classNames("account-card", className, {
                "account-card--only-number":
                    onlyNumber && account.accountNumberMask,
            })}
        >
            <div
                className={`account-card__icon account-card__icon--${iconSize}`}
            >
                <FinancialConnectionIcon
                    connection={connection}
                    account={account}
                />
            </div>
            <div className="account-card__name-type">
                <OverlayTrigger
                    show={
                        displayNameAsTooltip && account.name ? undefined : false
                    }
                    overlay={
                        <Tooltip id={`account-number-${account.id}`}>
                            {account.name}
                        </Tooltip>
                    }
                >
                    <div
                        className="account-card__name"
                        data-testid="account-name"
                    >
                        <BreakableText text={displayedName} />
                    </div>
                </OverlayTrigger>
                {!onlyNumber && !accountNameConfirmationMode && (
                    <div className="account-card__type">{label}</div>
                )}
            </div>
        </div>
    );
};
