import React, { useMemo } from "react";
import { Collapsible } from "../general/Collapsible/Collapsible";
import rampLogo from "../../static/images/icons/ramp.svg";
import { ClockIcon } from "../../icons";
import { timeAgo } from "../../helpers/date";
import { DisplayIntegrationAccount } from "../integrationAccount/DisplayIntegrationAccount";
import { FinancialConnectionWithAccounts } from "../../common/types/financialConnection";
import css from "./RampConnectionStatus.module.scss";

interface Props {
    connection: FinancialConnectionWithAccounts;
}

export const RampConnectionStatus: React.FC<Props> = ({ connection }) => {
    const mostRecentTransactionsSyncedAt = useMemo(() => {
        const { accounts } = connection;
        const dates = accounts
            .map(({ transactionsSyncedAt }) => transactionsSyncedAt)
            .filter(Boolean) as Date[];

        return new Date(Math.max(...dates.map((date) => date.getTime())));
    }, [connection]);

    const header = (
        <div className={css.header}>
            <img src={rampLogo} alt="Ramp" />
            <main>
                <h5>Ramp</h5>
                {mostRecentTransactionsSyncedAt && (
                    <small>
                        <ClockIcon
                            height={12}
                            width={12}
                            className="icon-color-current"
                        />
                        {timeAgo(mostRecentTransactionsSyncedAt)}
                    </small>
                )}
            </main>
        </div>
    );

    if (connection.accounts.length === 0) {
        return null;
    }

    return (
        <Collapsible className={css.rampConnection} header={header}>
            <>
                {connection.accounts.map((account) => (
                    <DisplayIntegrationAccount
                        key={account.id}
                        className={css.displayAccount}
                        connection={connection}
                        integrationAccount={account}
                    />
                ))}
            </>
        </Collapsible>
    );
};
