import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CategoryIcon } from "../../transactions/CategoryIcon";
import { useCategoryLabelGetter } from "../../../hooks/useCategoryLabelGetter";
import { Tag } from "../../general/Tag/Tag";
import { Taxonomy } from "../../../common/categories";
import { EllipsisWithTooltip } from "../../general/EllipsisWithTooltip/EllipsisWithTooltip";
import styles from "./CounterpartyCategories.module.scss";

interface Props {
    categories: string[];
    tooltipPrefix: string;
}

export const CounterpartyCategories: React.FC<Props> = ({
    categories,
    tooltipPrefix,
}) => {
    const [first, ...other] = categories;
    const labelGetter = useCategoryLabelGetter();

    if (!first) {
        return null;
    }

    const label = labelGetter(first as Taxonomy);

    return (
        <div className={styles.container}>
            <CategoryIcon categoryId={first} />
            <EllipsisWithTooltip
                fullText={label}
                tooltipId={`${tooltipPrefix}first`}
            >
                {(ref) => (
                    <span className={styles.label} ref={ref}>
                        {label}
                    </span>
                )}
            </EllipsisWithTooltip>

            {other.length > 0 && (
                <OverlayTrigger
                    overlay={
                        <Tooltip id={`${tooltipPrefix}other`}>
                            {other
                                .map((item) => labelGetter(item as Taxonomy))
                                .join(", ")}
                        </Tooltip>
                    }
                >
                    <span>
                        <Tag size="xs" variant="secondary" outline>
                            +{other.length}
                        </Tag>
                    </span>
                </OverlayTrigger>
            )}
        </div>
    );
};
