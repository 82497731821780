import { useMemo } from "react";
import { useEntitiesContexts } from "./useEntitiesContexts";

interface Options {
    requireAdminAccess?: boolean;
    onlyBusiness?: boolean;
    onlyPersonal?: boolean;
    onlyWithBalance?: boolean;
    excludeAllMockEntities?: boolean;
    excludeEmptyMockEntities?: boolean;
    reportsReady?: boolean;
}

export function useEntities(options: Options = {}) {
    const ec = useEntitiesContexts(options);
    return useMemo(() => ec?.map((e) => e.entity) ?? [], [ec]);
}
