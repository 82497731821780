import { useSpring, animated } from "@react-spring/web";
import { Link } from "react-router-dom";
import { ActionCategory } from "../../common/types/actions";
import { Button } from "../general/Button/Button";
import styles from "./ActionsEmptyState.module.scss";

interface Props {
    category: ActionCategory;
}

export const ActionsEmptyState: React.FC<Props> = ({ category }) => {
    const animation = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
    });

    return (
        <animated.div style={animation} className={styles.emptyState}>
            <h4>All set 😎</h4>
            <p>You're done with your {category} actions</p>
            {category === ActionCategory.PERSONALIZE ? (
                <Button asChild variant="secondary">
                    <Link to="/actions/savings">View Savings actions</Link>
                </Button>
            ) : (
                <Button asChild variant="secondary">
                    <Link to="/cashflow">Open Cash Flow</Link>
                </Button>
            )}
        </animated.div>
    );
};
