import { useFormikContext } from "formik";
import React from "react";

import { ConfirmationModal } from "../../general/ConfirmationModal";
import { useDeleteJournalEntry } from "../../../mutations/accounting";
import { JournalEntryModalFormikConfig } from "./JournalEntryModal";

interface Props {
    onBack: () => void;
    onDeleteSuccess: () => void;
    onHide?: () => void;
    show?: boolean;
    disableAnimation?: boolean;
}

export const JournalEntryModalDelete: React.FC<Props> = ({
    onBack,
    onDeleteSuccess,
    onHide,
    show,
    disableAnimation,
}) => {
    const formikContext = useFormikContext<JournalEntryModalFormikConfig>();
    const { values, resetForm } = formikContext;

    const onDelete = useDeleteJournalEntry({
        journalEntryId: values.journalEntryId,
        entityId: values.entityId,
        onSuccess: () => {
            onDeleteSuccess();
            resetForm();
        },
    });

    return (
        <ConfirmationModal
            title="Are you sure that you want to delete this journal entry?"
            question=""
            no="Back"
            onReject={onBack}
            onConfirm={onDelete.mutateAsync}
            onHide={onHide}
            show={show}
            disableAnimation={disableAnimation}
        />
    );
};
