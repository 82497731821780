import { isEqual } from "lodash";
import { useMemo } from "react";
import { InvoiceFilters } from "../../common/contracts/invoice.contract";
import { DEFAULT_FILTERS } from "./filters/lib";

export function useFiltersActive(currentFilters: InvoiceFilters): boolean {
    return useMemo(
        () => !isEqual(currentFilters, DEFAULT_FILTERS),
        [currentFilters],
    );
}
