import classNames from "classnames";

import { ReplaceProperties } from "../../../../common/types/base/generics";
import { Class } from "../../../../common/types/class";
import { ExtendedFlattenedItem } from "../utils";

import styles from "../ClassesSettings.module.scss";
import { ClassObjectIcon } from "../../../Class/ClassObjectIcon";
import { TriangleIcon } from "../../../../icons";

export const ClassIdCell: React.FC<{
    isCollapsed?: boolean;
    item:
        | ReplaceProperties<
              ExtendedFlattenedItem,
              { meta: { classInstance: Class } }
          >
        | {
              isEmpty: true;
              id: string;
              hasParent: boolean;
          };
}> = ({ item, isCollapsed }) => (
    <div
        className={classNames("d-flex align-items-center", {
            [styles.classIdCell]: true,
            [styles.classIdCellSubClass]: true,
        })}
    >
        {"meta" in item && (
            <>
                {item.parentId && (
                    <div
                        className={classNames(styles.rowIcon, styles.childIcon)}
                    />
                )}
                {!item.parentId && (
                    <ClassObjectIcon class={item.meta.classInstance} />
                )}
            </>
        )}

        <div>
            <p className={styles.classItemLabel}>
                {"meta" in item ? item.meta.classInstance.label : ""}
            </p>
            {"meta" in item &&
                item.meta.classInstance.parentClassId === null && (
                    <span
                        className={classNames(
                            styles.triangleIcon,
                            isCollapsed && styles.triangleIconCollapsed,
                        )}
                    >
                        <TriangleIcon />
                    </span>
                )}
        </div>
    </div>
);
