import React, { useCallback, useMemo, useRef, useState } from "react";
import { useField } from "formik";
import { useInfiniteQuery } from "@tanstack/react-query";
import isNumber from "lodash/isNumber";
import Scrollbars from "react-custom-scrollbars-2";
import classNames from "classnames";
import {
    TransactionRuleConditionFormSchema,
    TransactionRuleFormSchema,
} from "../constants";
import { useWorkspaceContext } from "../../../../../state/workspaceContext";
import { getMatchingTransactionsForRule } from "../../../../../lib/transactionRules";
import {
    GridTable,
    GridTableHeader,
    GridTableRow,
} from "../../../../general/Tables/GridTable/GridTable";
import { FormCheckbox } from "../../../../forms/FormCheckbox/FormCheckbox";
import { Transaction } from "../../../../../common/types/transaction";
import { Loader } from "../../../../general/Loader";
import { conditionFormRowToModel } from "../formToModel.helpers";
import { TransactionRule } from "../../../../../common/types/transactionRule";
import { NoConditionsPlaceholder } from "./NoConditionsPlaceholder";
import { MatchingTransaction } from "./MatchingTransaction";
import styles from "./RuleTransactionsPreview.module.scss";

const PAGE_SIZE = 50;
const NEXT_PAGE_TRIGGERING_OFFSET = 100;

interface Props {
    rule?: TransactionRule;
}

export const RuleTransactionsPreview: React.FC<Props> = ({ rule }) => {
    const [conditionsField] =
        useField<TransactionRuleConditionFormSchema[]>("conditions");
    const [
        { value: excludedTransactionsIds },
        ,
        { setValue: setExcludedTransactions },
    ] = useField<TransactionRuleFormSchema["excludedTransactionsIds"]>(
        "excludedTransactionsIds",
    );
    const [
        { value: applyToExistingTransactions },
        ,
        { setValue: setApplyToExistingTransactions },
    ] = useField<TransactionRuleFormSchema["applyToExistingTransactions"]>(
        "applyToExistingTransactions",
    );

    const conditions = useMemo(
        () =>
            conditionsField.value
                .map(conditionFormRowToModel)
                .filter((v) => v !== undefined),
        [conditionsField.value],
    );
    const { activeWorkspace } = useWorkspaceContext();
    const [total, setTotal] = useState<number>();

    const noConditions = conditions.length < 2;
    const matchingTransactions = useInfiniteQuery({
        queryKey: [
            "rulesMatchingTransactions",
            activeWorkspace?.id,
            conditions,
            rule?.id,
        ],
        queryFn: async ({ pageParam = 0 }) => {
            if (!activeWorkspace || noConditions) {
                setTotal(undefined);
                return;
            }

            const result = await getMatchingTransactionsForRule(
                activeWorkspace.id,
                {
                    conditions,
                    page: pageParam,
                    limit: PAGE_SIZE,
                    excludeAppliedRuleId: rule?.id,
                },
            );

            setTotal(result.total);
            return result.transactions;
        },
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages) =>
            lastPage && lastPage.length === PAGE_SIZE
                ? pages.length
                : undefined,
    });
    const noMatchingTransactions = total === 0;
    const showingTransactions = isNumber(total) && total > 0;

    const toggleTransaction = useCallback(
        (transaction: Transaction) => {
            if (excludedTransactionsIds.includes(transaction.id)) {
                setExcludedTransactions(
                    excludedTransactionsIds.filter(
                        (id) => id !== transaction.id,
                    ),
                );
            } else {
                setExcludedTransactions([
                    ...excludedTransactionsIds,
                    transaction.id,
                ]);
            }
        },
        [excludedTransactionsIds, setExcludedTransactions],
    );

    const toggleAll = useCallback(() => {
        if (!applyToExistingTransactions) {
            setApplyToExistingTransactions(true);
        } else if (!excludedTransactionsIds.length) {
            setApplyToExistingTransactions(false);
        }

        setExcludedTransactions([]);
    }, [
        excludedTransactionsIds,
        applyToExistingTransactions,
        setExcludedTransactions,
        setApplyToExistingTransactions,
    ]);

    const scrollbars = useRef<Scrollbars>(null);
    const onScroll = useCallback(() => {
        if (scrollbars.current) {
            const scrollTop = scrollbars.current.getScrollTop();
            const scrollHeight = scrollbars.current.getScrollHeight();
            const clientHeight = scrollbars.current.getClientHeight();

            if (
                scrollTop + clientHeight >=
                    scrollHeight - NEXT_PAGE_TRIGGERING_OFFSET &&
                !matchingTransactions.isFetchingNextPage
            ) {
                matchingTransactions.fetchNextPage();
            }
        }
    }, [matchingTransactions]);

    return (
        <>
            <h5 className={styles.header}>
                Transactions
                {isNumber(total) && total > 0 && ` (${total})`}
            </h5>
            <Scrollbars
                ref={scrollbars}
                onScroll={onScroll}
                style={{ height: "calc(100% - 70px)" }}
            >
                <GridTable className={styles.table}>
                    <GridTableHeader
                        className={classNames(styles.row, styles.headerRow)}
                    >
                        <div>
                            {showingTransactions && (
                                <FormCheckbox
                                    value="allSelected"
                                    isChecked={
                                        !excludedTransactionsIds.length &&
                                        applyToExistingTransactions
                                    }
                                    handleChange={toggleAll}
                                    small
                                />
                            )}
                        </div>
                        <div>Date</div>
                        <div>Description</div>
                        <div className={styles.amountColumn}>Amount</div>
                        <div />
                    </GridTableHeader>
                    {matchingTransactions.data?.pages.map((page) =>
                        page?.map((transaction) => (
                            <GridTableRow
                                className={classNames(
                                    styles.row,
                                    styles.bodyRow,
                                    {
                                        [styles.deselectedRow]:
                                            excludedTransactionsIds.includes(
                                                transaction.id,
                                            ) || !applyToExistingTransactions,
                                    },
                                )}
                                key={transaction.id}
                            >
                                <MatchingTransaction
                                    transaction={transaction}
                                    isChecked={
                                        !excludedTransactionsIds.includes(
                                            transaction.id,
                                        ) && applyToExistingTransactions
                                    }
                                    isDisabled={!applyToExistingTransactions}
                                    toggle={toggleTransaction}
                                />
                            </GridTableRow>
                        )),
                    )}
                    {noConditions && (
                        <NoConditionsPlaceholder mode="no_conditions" />
                    )}
                    {noMatchingTransactions && (
                        <NoConditionsPlaceholder mode="no_transactions_found" />
                    )}
                </GridTable>
                {(matchingTransactions.hasNextPage ||
                    matchingTransactions.isLoading) && <Loader />}
            </Scrollbars>
        </>
    );
};
