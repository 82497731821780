import React from "react";
import { ChildrenProps } from "../../types";
import styles from "./ActionCardField.module.scss";

interface Props extends ChildrenProps {
    label: string;
    className?: string;
}

export const ActionCardField: React.FC<Props> = ({
    className,
    label,
    children,
}) => (
    <div className={className}>
        <label className={styles.label}>{label}</label>
        <span className={styles.content}>{children}</span>
    </div>
);
