import {
    EntityProfileType,
    EntityType,
    MergedEntityType,
} from "../../../types/entity";
import { AccountClass, AccountType } from "./accountsv2";

export const accountTypeToAccountClass: Record<AccountType, AccountClass> = {
    [AccountType.cash]: AccountClass.assets,
    [AccountType.accountsReceivable]: AccountClass.assets,
    [AccountType.prepaidExpenses]: AccountClass.assets,
    [AccountType.inventory]: AccountClass.assets,
    [AccountType.otherCurrentAssets]: AccountClass.assets,
    [AccountType.investments]: AccountClass.assets,
    [AccountType.fixedAssets]: AccountClass.assets,
    [AccountType.intangibleAssets]: AccountClass.assets,
    [AccountType.otherAssets]: AccountClass.assets,
    [AccountType.accountsPayable]: AccountClass.liabilities,
    [AccountType.creditCards]: AccountClass.liabilities,
    [AccountType.payrollLiabilities]: AccountClass.liabilities,
    [AccountType.otherCurrentLiabilities]: AccountClass.liabilities,
    [AccountType.shortTermLoans]: AccountClass.liabilities,
    [AccountType.longTermLoans]: AccountClass.liabilities,
    [AccountType.otherLiabilities]: AccountClass.liabilities,
    [AccountType.equity]: AccountClass.equity,
    [AccountType.income]: AccountClass.income,
    [AccountType.cogs]: AccountClass.expenses,
    [AccountType.payrollExpenses]: AccountClass.expenses,
    [AccountType.payrollTaxes]: AccountClass.expenses,
    [AccountType.employeeBenefits]: AccountClass.expenses,
    [AccountType.operatingExpenses]: AccountClass.expenses,
    [AccountType.otherIncome]: AccountClass.income,
    [AccountType.otherExpenses]: AccountClass.expenses,
    [AccountType.taxExpenses]: AccountClass.expenses,
};

export const accountClassesIncreasingByDebit = [
    AccountClass.assets,
    AccountClass.expenses,
];

export const accountClassesWithTypes = Object.keys(
    accountTypeToAccountClass,
).reduce(
    (acc, accountType) => {
        const accountClass =
            accountTypeToAccountClass[accountType as AccountType];
        if (!acc[accountClass]) {
            acc[accountClass] = [];
        }
        acc[accountClass].push(accountType as AccountType);
        return acc;
    },
    {} as Record<AccountClass, AccountType[]>,
);

export const isCorporationLedger = (entityType: MergedEntityType) =>
    [
        EntityType.C_CORP,
        EntityType.C_CORP_WITH_S_CORP,
        EntityType.LLC_WITH_S_CORP,
        EntityProfileType.C_CORP,
        EntityProfileType.S_CORP,
    ].includes(entityType);

export const isPartnershipLedger = (entityType: MergedEntityType) =>
    [EntityProfileType.PARTNERSHIP, EntityProfileType.MMLLC].includes(
        entityType as any,
    );

export const isSolePropLedger = (entityType: MergedEntityType) =>
    [
        EntityType.LLC,
        EntityType.NOT_REGISTERED,
        EntityProfileType.LLC,
        EntityProfileType.SOLE_PROPRIETORSHIP,
        EntityProfileType.NO_LEGAL_ENTITY,
        EntityProfileType.SMLLC,
    ].includes(entityType);
