import { createContext, useContext } from "react";
import { FLAT_RATE_PLANS } from "../../../common/flatRateBilling";
import { noop } from "../../../helpers/general";

export interface PlanManagementContextValue {
    upgrade(plan: FLAT_RATE_PLANS, isTrial: boolean): void;
    downgrade(plan: FLAT_RATE_PLANS): void;
    convert(): void;
}

export const planManagementContext = createContext<PlanManagementContextValue>({
    downgrade: noop,
    upgrade: noop,
    convert: noop,
});

export function usePlanManagement() {
    const ctx = useContext(planManagementContext);

    if (!ctx) {
        throw new Error(
            "usePlanManagement must be used within a PlanManagementProvider",
        );
    }

    return ctx;
}
