import React, {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useState,
} from "react";
import { isFunction } from "lodash";
import { useWindowMessage } from "../../hooks/useWindowMessage";
import {
    OAuthAuthorizationResponse,
    triggerAuthorization,
} from "../../lib/oauth";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";
import { OAuthServiceType } from "../../common/constants";
import { useInteraction } from "../../hooks/useInteraction";
import {
    AddAccountProps,
    CreateAccountHandle,
} from "../accounts/AddAccounts/lib";
import { useRampConnectionCreationMutation } from "../../mutations/rampConnection";
import { useEntities } from "../../hooks/useEntities";
import { ExtendedButtonProps } from "../general/Button/Button";

interface Props extends AddAccountProps {
    children?:
        | React.ReactNode
        | ((props: {
              connecting: boolean;
              initializeConnection: () => void;
          }) => React.ReactNode);
    btnVariant?: ExtendedButtonProps["variant"];
}
export const CreateRampConnection = forwardRef<CreateAccountHandle, Props>(
    (
        {
            children = <>Connect</>,
            onCreated,
            defaultEntity,
            btnVariant = "tertiary",
        },
        ref,
    ) => {
        const mutation = useRampConnectionCreationMutation(onCreated);
        const [creating, setCreating] = useState(false);

        const businessEntities = useEntities({
            onlyBusiness: true,
            excludeAllMockEntities: true,
        });
        if (!defaultEntity && businessEntities.length === 1) {
            defaultEntity = businessEntities[0];
        }

        const handler = ({ code }: OAuthAuthorizationResponse) => {
            if (creating) {
                mutation.mutate({ code, defaultEntity });
            }
            setCreating(false);
        };

        useWindowMessage<OAuthAuthorizationResponse>({
            scope: OAuthServiceType.RAMP,
            handler,
        });

        const initializeConnection = useCallback(() => {
            if (creating) {
                return;
            }

            triggerAuthorization(OAuthServiceType.RAMP);
            setCreating(true);
        }, [creating]);

        const authorize = useInteraction(initializeConnection);

        useImperativeHandle(
            ref,
            () => ({
                initializeConnection,
            }),
            [initializeConnection],
        );

        if (isFunction(children)) {
            return children({ connecting: creating, initializeConnection });
        }

        return (
            <span {...authorize}>
                <ButtonWithLoader
                    loading={mutation.isPending}
                    variant={btnVariant}
                    size="sm"
                >
                    {children}
                </ButtonWithLoader>
            </span>
        );
    },
);
