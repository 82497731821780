import React from "react";
import { useField } from "formik";
import { TransactionRuleConditionFormSchema } from "../constants";
import { EntityFilter } from "../../../../general/EntityFilter/EntityFilter";

interface Props {
    index: number;
}

export const EntityConditionForm: React.FC<Props> = ({ index }) => {
    const [field, , helpers] = useField<
        TransactionRuleConditionFormSchema["entityValue"]
    >(`conditions.${index}.entityValue`);

    return (
        <EntityFilter
            filters={{ entityIds: field.value ?? [] }}
            onChange={(filters) => {
                helpers.setValue(filters.entityIds);
            }}
        />
    );
};
