import { useWorkspaceContext } from "../../state/workspaceContext";
import { useUser } from "../useUser";

export function useActiveWorkspaceRole() {
    const { activeWorkspace } = useWorkspaceContext();
    const user = useUser();
    return (
        activeWorkspace?.userAssignments.find(
            (assignment) => assignment.userId === user.id,
        )?.role ?? null
    );
}
