import React from "react";
import { format } from "date-fns";
import { fullDateFullYearFormat } from "../../../../common/helpers/date";
import { AccountingReportFilters } from "../../types";
import { AccountingReportFooter } from "../../AccountingReportFooter";
import { BalanceSheetReport } from "../types";
import { BalanceSheetTableSection } from "../BalanceSheetTableSection";
import { Entity } from "../../../../common/types/entity";
import { BalanceSheetDownloadTableRow } from "./BalanceSheetDownloadTableRow";

interface Props {
    report: BalanceSheetReport;
    filters: AccountingReportFilters;
    generationDate: Date;
    entity: Entity;
}

export const BALANCE_SHEET_DOWNLOAD_TABLE_ID = "balance-sheet-download-table";

const TABLE_PROPS: React.HTMLAttributes<HTMLTableElement> = {
    id: BALANCE_SHEET_DOWNLOAD_TABLE_ID,
};

export const BalanceSheetDownloadTable: React.FC<Props> = ({
    report,
    filters,
    generationDate,
    entity,
}) => {
    const entityName = entity?.name;
    const columnsNumber = 4;

    const emptyRow = (
        <tr>
            <td colSpan={columnsNumber}>&nbsp;</td>
        </tr>
    );

    return (
        <table className="download-table" {...TABLE_PROPS}>
            <tbody>
                {emptyRow}
                <tr>
                    <td
                        colSpan={columnsNumber}
                        className={
                            "download-table__cell--bold download-table__cell--center"
                        }
                        style={{ fontSize: "22px" }}
                    >
                        {entityName}
                    </td>
                </tr>
                <tr>
                    <td
                        colSpan={columnsNumber}
                        className={
                            "download-table__cell--bold download-table__cell--center"
                        }
                        style={{ fontSize: "22px" }}
                    >
                        Balance Sheet
                    </td>
                </tr>
                <tr>
                    <td
                        colSpan={columnsNumber}
                        className="download-table__cell--center"
                    >
                        As of{" "}
                        {filters.endDate &&
                            format(filters.endDate, fullDateFullYearFormat)}
                    </td>
                </tr>
                {emptyRow}
                {report.rows.map((row) => (
                    <BalanceSheetTableSection
                        key={row.label}
                        section={row}
                        depth={0}
                        renderRow={(props) => (
                            <BalanceSheetDownloadTableRow
                                emptyRow={emptyRow}
                                {...props}
                            />
                        )}
                    />
                ))}
                {emptyRow}
                <tr>
                    <td
                        colSpan={columnsNumber}
                        className={
                            "download-table__cell--center download-table__font-9"
                        }
                    >
                        <AccountingReportFooter
                            generationDate={generationDate}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
