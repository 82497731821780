import {
    AuditLog,
    SplitAuditLogValue,
} from "../../../../common/types/auditLog";
import { useAuditLogAuthorLabel } from "./useAuditLogAuthorLabel";

interface Props {
    log: AuditLog;
    newValue: SplitAuditLogValue;
}

export const AuditLogSplitContent: React.FC<Props> = ({ log, newValue }) => {
    const authorLabel = useAuditLogAuthorLabel(log);
    return (
        <>
            <strong>{authorLabel}</strong>{" "}
            {newValue.splitParentId ? "split" : "unsplit"} this transaction.
        </>
    );
};
