import React, { useState } from "react";
import { Form, Formik, FormikConfig } from "formik";
import { object, string } from "yup";
import { omit } from "lodash";
import { Entity } from "../../common/types/entity";
import { UserRole } from "../../common/types/userEntityAssignment";
import { submitHelper } from "../../helpers/form";
import { Workspace } from "../../common/types/workspace";
import { UserWorkspaceRole } from "../../common/userWorkspaceRoles";
import { useInviteMemberMutation } from "../../api/workspace-member.api";
import { useToaster } from "../general/ToastMessages/useToaster";
import { StandardModalBody, StandardModalHeader } from "../general/Modal/Modal";
import { AddMemberDetailsStep } from "./AddMemberDetailsStep";
import { AddMemberFormSchema, AddMemberStep } from "./types";
import { AddMemberSteps } from "./AddMemberSteps";
import styles from "./MemberManagement.module.scss";
import { ConfigurePermissionsStep } from "./ConfigurePermissionsStep";

interface Props {
    workspace: Workspace;
    entities: Entity[];
    providedEmail?: string;
    providedRole?: UserWorkspaceRole;
    closeModal: () => void;
    onInvited?: () => void;
}

const defaultEntityRoleByWorkspaceRole = {
    [UserWorkspaceRole.PRIMARY_ADMIN]: UserRole.ADMIN,
    [UserWorkspaceRole.ADMIN]: UserRole.ADMIN,
    [UserWorkspaceRole.MEMBER]: UserRole.MEMBER,
    [UserWorkspaceRole.ACCOUNTANT]: UserRole.ACCOUNTANT,
} as const satisfies Record<UserWorkspaceRole, UserRole>;

export const AddMemberModal: React.FC<Props> = ({
    workspace,
    entities,
    providedEmail,
    providedRole,
    onInvited,
    closeModal,
}) => {
    const { toast } = useToaster();
    const [currentStep, setCurrentStep] = useState<AddMemberStep>(
        providedEmail ? AddMemberStep.PERMISSIONS : AddMemberStep.DETAILS,
    );

    const [loading, setLoading] = useState(false);
    const inviteMemberMutation = useInviteMemberMutation(workspace);

    const form: FormikConfig<AddMemberFormSchema> = {
        initialValues: {
            email: providedEmail ?? "",
            workspaceRole: providedRole ?? UserWorkspaceRole.MEMBER,
            entityAssignments: entities.map((entity) => ({
                included: true,
                entityId: entity.id,
                role: defaultEntityRoleByWorkspaceRole[
                    providedRole ?? UserWorkspaceRole.MEMBER
                ],
            })),
        },
        validationSchema: object().shape({
            email: string()
                .required("Email is required")
                .email("Provide a valid email"),
        }),
        validate({ entityAssignments }) {
            if (!entityAssignments.some((ea) => ea.included)) {
                return {
                    entityAssignments: "At least one entity must be selected",
                };
            }
        },
        onSubmit: submitHelper({
            loading,
            setLoading,
            handler: async ({ email, workspaceRole, entityAssignments }) => {
                if (currentStep !== AddMemberStep.PERMISSIONS) {
                    return;
                }

                await inviteMemberMutation.mutateAsync({
                    email,
                    role: workspaceRole,
                    entityAssignments: entityAssignments
                        .filter((ea) => ea.included)
                        .map((ea) => omit(ea, "included")),
                });

                onInvited?.();
                closeModal();
                toast(`Invite sent to ${email}`);
            },
        }),
    };

    return (
        <>
            <StandardModalHeader>Add member</StandardModalHeader>

            <StandardModalBody>
                <section className={styles.layout}>
                    <aside>
                        <AddMemberSteps currentStep={currentStep} />
                    </aside>
                    <Formik {...form}>
                        {({ submitForm }) => {
                            switch (currentStep) {
                                case AddMemberStep.DETAILS:
                                    return (
                                        <Form>
                                            <AddMemberDetailsStep
                                                onBack={closeModal}
                                                onNext={() =>
                                                    setCurrentStep(
                                                        AddMemberStep.PERMISSIONS,
                                                    )
                                                }
                                            />
                                        </Form>
                                    );

                                case AddMemberStep.PERMISSIONS:
                                    return (
                                        <Form>
                                            <ConfigurePermissionsStep
                                                busy={loading}
                                                workspace={workspace}
                                                entities={entities}
                                                onBack={() =>
                                                    setCurrentStep(
                                                        AddMemberStep.DETAILS,
                                                    )
                                                }
                                                onNext={() => submitForm()}
                                            />
                                        </Form>
                                    );
                            }
                        }}
                    </Formik>
                </section>
            </StandardModalBody>
        </>
    );
};
