import { FC } from "react";
import { Command } from "../../vendor/cmdk/cmdk";
import { ConnectPlaid } from "../plaid/ConnectPlaid/ConnectPlaid";
import plaidLogo from "../../static/images/icons/plaid.svg";
import americanExpressLogo from "../../static/images/logos/americanexpress.svg";
import bankOfAmericaLogo from "../../static/images/logos/bankofamerica.svg";
import capitalOneLogo from "../../static/images/logos/capitalone.png";
import chaseLogo from "../../static/images/logos/chase.png";
import citibankLogo from "../../static/images/logos/citibank.png";
import wellsFargoLogo from "../../static/images/logos/wellsfargo.png";
import { CreateStripeConnection } from "../stripe/CreateStripeConnection";
import stripeLogo from "../../static/images/icons/stripe.svg";
import { useTypedFlags } from "../../hooks/useTypedFlags";
import { CreateRampConnection } from "../ramp/CreateRampConnection";
import rampLogo from "../../static/images/icons/ramp.svg";
import paypalLogo from "../../static/images/icons/paypal.svg";
import { CreateMercuryConnection } from "../mercury/CreateMercuryConnection";
import mercuryLogo from "../../static/images/icons/mercury.svg";
import { CreateGustoConnection } from "../gusto/CreateGustoConnection";
import gustoLogo from "../../static/images/icons/gusto.svg";
import { PLAID_INSTITUTIONS_NAMES } from "../../common/constants";
import { UniversalImportTrigger } from "../manualImport/UniversalImportTrigger";
import { UploadIcon } from "../../icons";
import { CreatePayPalConnection } from "../paypal/CreatePayPalConnection";
import { CommandItem } from "./CommandItem";
import { useCommands } from "./useCommands";
import styles from "./Commands.module.scss";
import { CommandsListProps } from "./lib";

const PLAID_SUPPORTED_ACCOUNTS = [
    "us bank",
    "pnc",
    "usaa",
    "td",
    "regions",
    "navy federal credit union",
    "charles schwab",
    "citizens",
    "huntigton",
    "betterment",
    "merill lynch",
    "vanguard",
];

export interface AccountsCommandsListProps extends CommandsListProps {
    includeStaticAccounts?: boolean;
}

export const AccountsCommandsList: FC<AccountsCommandsListProps> = ({
    includeStaticAccounts = false,
    options = {},
}) => {
    const {
        rampIntegration,
        mercuryIntegration,
        universalImporterUi,
        gustoIntegration,
        payPalIntegration,
    } = useTypedFlags();

    const { close: closeCommands } = useCommands();

    return (
        <Command.Group heading="Accounts">
            <CreateStripeConnection
                onCreated={closeCommands}
                defaultEntity={options.defaultEntity}
            >
                {({ connecting, initializeConnection }) => (
                    <CommandItem
                        onSelect={initializeConnection}
                        disabled={connecting}
                    >
                        <img
                            className={styles.icon}
                            src={stripeLogo}
                            alt="Stripe"
                        />{" "}
                        Stripe
                    </CommandItem>
                )}
            </CreateStripeConnection>

            {mercuryIntegration && (
                <CreateMercuryConnection
                    onCreated={closeCommands}
                    defaultEntity={options.defaultEntity}
                >
                    {({ connecting, initializeConnection }) => (
                        <CommandItem
                            onSelect={initializeConnection}
                            disabled={connecting}
                            keywords={["mercury"]}
                        >
                            <img
                                className={styles.icon}
                                src={mercuryLogo}
                                alt="Mercury"
                            />{" "}
                            Mercury
                        </CommandItem>
                    )}
                </CreateMercuryConnection>
            )}

            {rampIntegration && (
                <CreateRampConnection
                    onCreated={closeCommands}
                    defaultEntity={options.defaultEntity}
                >
                    {({ connecting, initializeConnection }) => (
                        <CommandItem
                            onSelect={initializeConnection}
                            disabled={connecting}
                        >
                            <img
                                className={styles.icon}
                                src={rampLogo}
                                alt="Ramp"
                            />{" "}
                            Ramp
                        </CommandItem>
                    )}
                </CreateRampConnection>
            )}

            {payPalIntegration && (
                <CreatePayPalConnection onCreated={closeCommands}>
                    {({ connecting, initializeConnection }) => (
                        <CommandItem
                            onSelect={initializeConnection}
                            disabled={connecting}
                        >
                            <img
                                className={styles.icon}
                                src={paypalLogo}
                                alt="PayPal"
                            />{" "}
                            PayPal
                        </CommandItem>
                    )}
                </CreatePayPalConnection>
            )}

            <ConnectPlaid
                onConnected={closeCommands}
                institutionName={PLAID_INSTITUTIONS_NAMES.CHASE}
                defaultEntity={options.defaultEntity}
            >
                {({ connecting, initializeConnection }) => (
                    <CommandItem
                        onSelect={initializeConnection}
                        disabled={connecting}
                        keywords={["chase"]}
                    >
                        <img
                            className={styles.icon}
                            src={chaseLogo}
                            alt="Chase"
                        />{" "}
                        Chase
                    </CommandItem>
                )}
            </ConnectPlaid>

            <ConnectPlaid
                onConnected={closeCommands}
                institutionName={PLAID_INSTITUTIONS_NAMES.AMERICAN_EXPRESS}
                defaultEntity={options.defaultEntity}
            >
                {({ connecting, initializeConnection }) => (
                    <CommandItem
                        onSelect={initializeConnection}
                        disabled={connecting}
                        keywords={["american express", "amex"]}
                    >
                        <img
                            className={styles.icon}
                            src={americanExpressLogo}
                            alt="American Express"
                        />{" "}
                        American Express{" "}
                    </CommandItem>
                )}
            </ConnectPlaid>

            <ConnectPlaid
                onConnected={closeCommands}
                institutionName={PLAID_INSTITUTIONS_NAMES.CAPITAL_ONE}
                defaultEntity={options.defaultEntity}
            >
                {({ connecting, initializeConnection }) => (
                    <CommandItem
                        onSelect={initializeConnection}
                        disabled={connecting}
                        keywords={["capital one"]}
                    >
                        <img
                            className={styles.icon}
                            src={capitalOneLogo}
                            alt="Capital One"
                        />{" "}
                        Capital One{" "}
                    </CommandItem>
                )}
            </ConnectPlaid>

            <ConnectPlaid
                onConnected={closeCommands}
                institutionName={PLAID_INSTITUTIONS_NAMES.BANK_OF_AMERICA}
                defaultEntity={options.defaultEntity}
            >
                {({ connecting, initializeConnection }) => (
                    <CommandItem
                        onSelect={initializeConnection}
                        disabled={connecting}
                        keywords={["bank of america", "boa"]}
                    >
                        <img
                            className={styles.icon}
                            src={bankOfAmericaLogo}
                            alt="Bank of America"
                        />{" "}
                        Bank of America{" "}
                    </CommandItem>
                )}
            </ConnectPlaid>

            <ConnectPlaid
                onConnected={closeCommands}
                institutionName={PLAID_INSTITUTIONS_NAMES.WELLS_FARGO}
                defaultEntity={options.defaultEntity}
            >
                {({ connecting, initializeConnection }) => (
                    <CommandItem
                        onSelect={initializeConnection}
                        disabled={connecting}
                        keywords={[
                            "wellsfargo",
                            "wells fargo",
                            "wells",
                            "fargo",
                        ]}
                    >
                        <img
                            className={styles.icon}
                            src={wellsFargoLogo}
                            alt="Wells Fargo"
                        />{" "}
                        Wells Fargo
                    </CommandItem>
                )}
            </ConnectPlaid>

            <ConnectPlaid
                onConnected={closeCommands}
                institutionName={PLAID_INSTITUTIONS_NAMES.CITIBANK}
                defaultEntity={options.defaultEntity}
            >
                {({ connecting, initializeConnection }) => (
                    <CommandItem
                        onSelect={initializeConnection}
                        disabled={connecting}
                        keywords={["citibank", "citi bank", "citi"]}
                    >
                        <img
                            className={styles.icon}
                            src={citibankLogo}
                            alt="Citibank"
                        />{" "}
                        Citibank
                    </CommandItem>
                )}
            </ConnectPlaid>

            {gustoIntegration && (
                <CreateGustoConnection
                    onCreated={closeCommands}
                    defaultEntity={options.defaultEntity}
                >
                    {({ connecting, initializeConnection }) => (
                        <CommandItem
                            onSelect={initializeConnection}
                            disabled={connecting}
                        >
                            <img
                                className={styles.icon}
                                src={gustoLogo}
                                alt="Gusto"
                            />{" "}
                            Gusto
                        </CommandItem>
                    )}
                </CreateGustoConnection>
            )}

            {includeStaticAccounts && (
                <>
                    <ConnectPlaid
                        onConnected={closeCommands}
                        defaultEntity={options.defaultEntity}
                    >
                        {({ connecting, initializeConnection }) => (
                            <CommandItem
                                onSelect={initializeConnection}
                                disabled={connecting}
                                keywords={PLAID_SUPPORTED_ACCOUNTS}
                            >
                                <img
                                    className={styles.icon}
                                    src={plaidLogo}
                                    alt="Plaid"
                                />{" "}
                                Add other bank institutions with Plaid
                            </CommandItem>
                        )}
                    </ConnectPlaid>

                    {universalImporterUi && (
                        <UniversalImportTrigger
                            onCreated={closeCommands}
                            defaultEntity={options.defaultEntity}
                        >
                            {({ connecting, initializeConnection }) => (
                                <CommandItem
                                    onSelect={initializeConnection}
                                    disabled={connecting}
                                    keywords={["pdf", "excel", "images"]}
                                >
                                    <span className={styles.customIcon}>
                                        <UploadIcon />
                                    </span>
                                    <span>
                                        Import Transactions{" "}
                                        <span className={styles.info}>
                                            (.csv, .pdf, .xlsx)
                                        </span>
                                    </span>
                                </CommandItem>
                            )}
                        </UniversalImportTrigger>
                    )}
                </>
            )}
        </Command.Group>
    );
};
