import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { TransactionListItemDto } from "../../../common/types/transaction";
import styles from "../ActionCardTransaction.module.scss";
import {
    getCounterpartyDirectionLabelForTransaction,
    isVerifiedCounterparty,
} from "../../../common/helpers/counterparties";

interface Props {
    transaction: TransactionListItemDto;
}

export const TransactionDescriptionContent: React.FC<Props> = ({
    transaction,
}) => {
    const counterpartyLabel =
        getCounterpartyDirectionLabelForTransaction(transaction);
    const counterpartyToShow =
        transaction.counterparty &&
        isVerifiedCounterparty(transaction.counterparty)
            ? transaction.counterparty
            : null;

    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip
                    id={`bankDescription:${transaction.id}`}
                    className="transaction-list-item__description__tooltip"
                >
                    {counterpartyToShow && (
                        <>
                            <span className="label">{counterpartyLabel}</span>
                            <p>{counterpartyToShow.name}</p>
                        </>
                    )}
                    <span className="label">Bank description</span>
                    <p>{transaction.bankDescription}</p>
                </Tooltip>
            }
        >
            <div className={styles.transactionDescription}>
                {transaction.displayDescription ?? transaction.bankDescription}
            </div>
        </OverlayTrigger>
    );
};
