import { GetOauthAuthorizationUrlResponseDto } from "../common/dto/oauth/get-oauth-authorization-url-response.dto";
import { OAuthServiceType } from "../common/constants";
import { backendClient, unwrapResponse } from "./backendClient";

export interface OAuthAuthorizationResponse {
    code: string;
    additionalParams: any;
}

export function triggerAuthorization(service: string) {
    window.open(`/oauth/authorize/${service}`, "OAuth Authorization");
}

export async function getOAuthAuthorizationUrl(
    service: string,
    options?: any,
): Promise<GetOauthAuthorizationUrlResponseDto> {
    return unwrapResponse(
        await backendClient.get(`/oauth/authorization-url/${service}`, {
            params: options,
        }),
    );
}

export function getAdditionalOAuthAuthorizationResponseParams(
    serviceType: OAuthServiceType,
    params: URLSearchParams,
): any {
    if (serviceType === OAuthServiceType.QUICKBOOKS) {
        return { realmId: params.get("realmId") };
    } else {
        return null;
    }
}
