import React from "react";
import classNames from "classnames";
import { numberFormatter } from "../../../common/helpers/number";
import { ChildrenProps } from "../../../types";
import { Separator } from "../Separator/Separator";
import styles from "./ActionBar.module.scss";

export interface ActionBarProps extends ChildrenProps {
    className?: string;
    selectedCount: number;
    totalCount: number;
    hasSelectedAll: boolean;
    clearSelection(): void;
    selectAll(): void;
}

export const ActionBar: React.FC<ActionBarProps> = ({
    children,
    selectedCount,
    hasSelectedAll,
    totalCount,
    selectAll,
    clearSelection,
    className,
}) => (
    <section className={classNames(styles.actionBar, className)}>
        {selectedCount > 0 && (
            <main className={styles.wrapper}>
                <section
                    className={classNames(
                        styles.section,
                        styles.selectionSection,
                    )}
                >
                    <div className={styles.textContainer}>
                        <span className={styles.selectedItemsText}>
                            {selectedCount} Selected
                        </span>
                    </div>
                    <button
                        type="button"
                        className={styles.button}
                        onClick={clearSelection}
                        data-testid="bulk-deselect-button"
                    >
                        Deselect
                    </button>
                    {!hasSelectedAll ? (
                        <>
                            <button
                                type="button"
                                className={styles.button}
                                onClick={selectAll}
                                data-testid="bulk-select-all-button"
                            >
                                Select all {numberFormatter.format(totalCount)}
                            </button>
                        </>
                    ) : null}
                </section>

                <Separator orientation="vertical" />
                <section
                    className={classNames(
                        styles.section,
                        styles.updatesSection,
                    )}
                >
                    {children}
                </section>
            </main>
        )}
    </section>
);
