import { SubscriptionDiscount } from "../common/types/billing";
import { currencyFormatterFractionsIfNeeded } from "../common/helpers/currency";
import { fromIntToMonetary } from "../common/helpers/monetary";

export function discountValueLabel(discount: SubscriptionDiscount): string {
    if (discount.amountOff) {
        return `${currencyFormatterFractionsIfNeeded.format(fromIntToMonetary(discount.amountOff))} off`;
    }

    return `${discount.percentOff}% off`;
}

export function discountDurationLabel(discount: SubscriptionDiscount): string {
    if (discount.duration === "repeating") {
        return `for ${discount.durationInMonths} months`;
    }

    return discount.duration;
}

export function discountLabel(discount: SubscriptionDiscount): string {
    return `${discountValueLabel(discount)} ${discountDurationLabel(discount)}`;
}
