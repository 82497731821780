import React, { useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { Field, Form, Formik, FormikConfig } from "formik";
import { object, string } from "yup";
import { CalendarIcon, EditIcon } from "../../../icons";
import "./UserInfo.scss";
import { dateFormatter } from "../../../common/helpers/date";
import { submitHelper } from "../../../helpers/form";
import { FormRow } from "../../forms/FormRow";
import { useUser } from "../../../hooks/useUser";
import { userRest } from "../../../lib/restClient";
import { FormRowRaw } from "../../forms/FormRowRaw";
import { useUpdateUser } from "../../../hooks/useUpdateUser";
import { useToaster } from "../../general/ToastMessages/useToaster";
import { Button } from "../../general/Button/Button";
import { UpdateProfileModal } from "./UpdateProfile/UpdateProfileModal";
import { EditableProfileFields } from "./UpdateProfile/types";

export const UserInfo: React.FC = () => {
    const [update, setUpdate] = useState<EditableProfileFields | null>(null);
    const user = useUser();
    const updateUser = useUpdateUser();
    const { error } = useToaster();

    const handleCancelUpdate = useCallback(() => {
        setUpdate(null);
    }, []);

    const form: FormikConfig<{ preferredName: string }> = {
        initialValues: {
            preferredName: user.preferredName ?? "",
        },
        validationSchema: object().shape({
            preferredName: string().required("Preferred name is required"),
        }),
        onSubmit: submitHelper({
            handler: async ({ preferredName }) => {
                const previousName = user.preferredName ?? "";

                try {
                    updateUser({
                        ...user,
                        preferredName,
                        name: preferredName,
                    });

                    await userRest.update(user.id, { preferredName });
                } catch {
                    error("Failed to update profile");

                    updateUser({
                        ...user,
                        preferredName: previousName,
                        name: previousName,
                    });
                }
            },
        }),
    };

    return (
        <div className="settings__user-info">
            <Row>
                <Col sm={12}>
                    <Formik {...form}>
                        {({ submitForm }) => (
                            <Form>
                                <FormRow
                                    showErrorMessage={false}
                                    fieldName="preferredName"
                                    label="Preferred Name"
                                >
                                    <Field
                                        name="preferredName"
                                        className="form-control"
                                        onBlur={() => {
                                            submitForm();
                                        }}
                                    />
                                </FormRow>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormRowRaw
                        value={user.email ?? ""}
                        label="Email address"
                        icon={
                            <Button
                                variant="tertiary"
                                size="sm"
                                icon
                                className="btn"
                                onClick={() => setUpdate("email")}
                            >
                                <EditIcon />
                            </Button>
                        }
                        iconPosition="right"
                        readOnly
                    />
                </Col>
                <Col>
                    <FormRowRaw
                        label="Phone number"
                        value={user.phoneNumber}
                        field={
                            <NumberFormat
                                className="form-control"
                                value={user.phoneNumberDisplay ?? ""}
                                format="(###) ###-####"
                                displayType="text"
                                readOnly
                            />
                        }
                        icon={
                            <Button
                                variant="tertiary"
                                size="sm"
                                icon
                                className="btn"
                                onClick={() => setUpdate("phoneNumber")}
                            >
                                <EditIcon />
                            </Button>
                        }
                        iconPosition="right"
                        readOnly
                    />
                </Col>
            </Row>
            {user.dateOfBirth && (
                <Row>
                    <Col sm={6}>
                        <FormRowRaw
                            value={dateFormatter.format(
                                new Date(user.dateOfBirth),
                            )}
                            label="Date of birth"
                            readOnly
                            icon={<CalendarIcon />}
                        />
                    </Col>
                </Row>
            )}

            <UpdateProfileModal update={update} onClose={handleCancelUpdate} />
        </div>
    );
};
