import { ActionActivity } from "../ActionActivity";
import { CampaignActivityProps } from "../ActionActivityItem";

export const WelcomeVideoActivity: React.FC<CampaignActivityProps> = ({
    animated,
}) => (
    <ActionActivity
        icon={<span className="emoji">🎉</span>}
        animated={animated}
    >
        Started personalization
    </ActionActivity>
);
