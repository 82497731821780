import React, { ReactNode } from "react";
import { Collapsible } from "../Collapsible/Collapsible";
import styles from "./FAQ.module.scss";

export interface Question {
    question: string;
    answer: ReactNode;
}

interface Props {
    questions: Question[];
}

export const Faq: React.FC<Props> = ({ questions }) => (
    <section>
        {questions.map(({ question, answer }) => (
            <Collapsible
                key={question}
                header={question}
                className={styles.question}
            >
                <div className="mb-3">{answer}</div>
            </Collapsible>
        ))}
    </section>
);
