import React, { useMemo } from "react";
import { format } from "date-fns";
import { AccountingReportFilters } from "../types";
import { AccountingReportCompact } from "../AccountingReportCompact";
import { ExcelTableWrapper } from "../../excelExport/ExcelTableWrapper";
import { Entity } from "../../../common/types/entity";
import { AccountingReportContext } from "../AccountingReport.context";
import { BalanceSheetTable } from "./BalanceSheetTable";
import { BalanceSheetDownloadTable } from "./balanceSheetDownload/BalanceSheetDownloadTable";
import { BalanceSheetReport } from "./types";

interface Props {
    report: BalanceSheetReport;
    filters: AccountingReportFilters;
    generationDate: Date;
    entity: Entity;
}

export const BalanceSheetContent: React.FC<Props> = ({
    report,
    filters,
    generationDate,
    entity,
}) => {
    const { endDate } = filters;
    const dateLabel = endDate && "As of " + format(endDate, "MMMM d, yyyy");

    const accountingReportContext = useMemo(() => ({ filters }), [filters]);

    if (!entity) {
        return null;
    }

    return (
        <>
            <AccountingReportCompact
                entityName={entity.name ?? ""}
                reportType="Balance Sheet"
                dateLabel={dateLabel}
                generationDate={generationDate}
            >
                <AccountingReportContext.Provider
                    value={accountingReportContext}
                >
                    <BalanceSheetTable report={report} />
                </AccountingReportContext.Provider>
            </AccountingReportCompact>
            <ExcelTableWrapper>
                <BalanceSheetDownloadTable
                    report={report}
                    filters={filters}
                    generationDate={generationDate}
                    entity={entity}
                />
            </ExcelTableWrapper>
        </>
    );
};
