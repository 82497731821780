import React, {
    forwardRef,
    PropsWithChildren,
    useCallback,
    useImperativeHandle,
    useMemo,
    useState,
} from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { without } from "lodash";
import axios from "axios";
import { v7 as uuid } from "uuid";
import { UploadingDocumentPlaceholder } from "../lib";
import { uploadDocument } from "../../../lib/financialDocument";
import { toBase64 } from "../../../helpers/file";
import { useWorkspaceContext } from "../../../state/workspaceContext";
import { FinancialDocument } from "../../../common/types/financialDocument";
import {
    ARCHIVE_TYPES,
    FINANCIAL_DOCUMENT_TYPES,
    MAX_ARCHIVE_FILE_S3_SIZE_BYTES,
    MAX_ARCHIVE_FILE_S3_SIZE_MB,
    MAX_ARCHIVE_FILE_SIZE_BYTES,
    MAX_ARCHIVE_FILE_SIZE_MB,
    MAX_FINANCIAL_DOCUMENT_FILE_SIZE_BYTES,
    MAX_FINANCIAL_DOCUMENT_FILE_SIZE_MB,
} from "../../../common/constants/financial-documents";
import { useTypedFlags } from "../../../hooks/useTypedFlags";
import {
    useCreateUploadUrlMutation,
    useProcessS3Upload,
} from "../../../api/financialDocument.api";
import { useToaster } from "../../general/ToastMessages/useToaster";
import {
    financialDocumentUploadContext,
    FinancialDocumentUploadContextValue,
} from "./financialDocumentUploadContext";

const ERROR_CODES: Record<string, string> = {
    "file-too-large": `Max allowed size is ${MAX_FINANCIAL_DOCUMENT_FILE_SIZE_MB}MB`,
    "file-invalid-type":
        "Only PDF, PNG, JPEG, spreadsheet (CSV, XLS, XLSX, XLSM), Word (DOC, DOCX), and ZIP files are allowed",
};

function isArchiveType(type: string) {
    return ARCHIVE_TYPES.includes(type as any);
}

export interface FinancialDocumentUploadProviderProps {
    accept?: string[];
    onCompleted?(uploadedDocuments: FinancialDocument[]): Promise<void>;
}

export const FinancialDocumentUploadProvider = forwardRef<
    FinancialDocumentUploadContextValue,
    PropsWithChildren<FinancialDocumentUploadProviderProps>
>(({ children, accept, onCompleted }, ref) => {
    const { activeWorkspaceKey } = useWorkspaceContext();
    const { directS3DocumentUpload } = useTypedFlags();
    const createUploadUrlMutation = useCreateUploadUrlMutation();
    const processS3Upload = useProcessS3Upload(activeWorkspaceKey);

    const [uploadingDocuments, setUploadingDocuments] = useState<
        UploadingDocumentPlaceholder[]
    >([]);

    const maxArchiveFileSize = directS3DocumentUpload
        ? MAX_ARCHIVE_FILE_S3_SIZE_MB
        : MAX_ARCHIVE_FILE_SIZE_MB;

    const maxArchiveFileSizeBytes = directS3DocumentUpload
        ? MAX_ARCHIVE_FILE_S3_SIZE_BYTES
        : MAX_ARCHIVE_FILE_SIZE_BYTES;

    const { error } = useToaster();
    const handleFilesRejected = useCallback(
        (rejections: FileRejection[]) => {
            rejections.forEach((rejection) => {
                error(
                    ERROR_CODES[rejection.errors[0].code]
                        ? `${rejection.file.name} - ${
                              ERROR_CODES[rejection.errors[0].code]
                          }`
                        : "Unknown error when uploading file",
                );
            });
        },
        [error],
    );

    const updateUpload = useCallback(
        (
            placeholder: UploadingDocumentPlaceholder,
            payload: Partial<UploadingDocumentPlaceholder>,
        ) => {
            setUploadingDocuments((prev) =>
                prev.map((el) =>
                    el.fileName === placeholder.fileName
                        ? {
                              ...el,
                              ...payload,
                          }
                        : el,
                ),
            );
        },
        [],
    );

    const regularUpload = useCallback(
        async (file: File) => {
            const placeholder: UploadingDocumentPlaceholder = {
                fileName: file.name,
                isUploading: true,
            };

            const contentToUpload = await toBase64(file);

            try {
                setUploadingDocuments((prev) => [...prev, placeholder]);
                const uploadResult = await uploadDocument({
                    workspaceId: activeWorkspaceKey,
                    file: contentToUpload,
                    filename: file.name,
                    contentType: file.type,
                });

                updateUpload(placeholder, {
                    isUploading: false,
                    financialDocument:
                        uploadResult.length === 1 ? uploadResult[0] : undefined,
                });

                return uploadResult;
            } catch {
                setUploadingDocuments((prev) => without(prev, placeholder));
            }
        },
        [activeWorkspaceKey, updateUpload],
    );

    const directS3Upload = useCallback(
        async (file: File) => {
            const placeholder: UploadingDocumentPlaceholder = {
                fileName: file.name,
                isUploading: true,
            };

            try {
                setUploadingDocuments((prev) => [...prev, placeholder]);
                const uploadPath = `${uuid()}/${file.name}`;

                const uploadUrl = await createUploadUrlMutation.mutateAsync({
                    path: uploadPath,
                });

                await axios.put(uploadUrl, file, {
                    headers: {
                        "Content-Type": file.type,
                    },
                    onUploadProgress: ({ total, loaded }) => {
                        if (total && loaded) {
                            updateUpload(placeholder, {
                                progress: Math.round((loaded / total) * 100),
                            });
                        }
                    },
                });

                const result = await processS3Upload.mutateAsync({
                    workspaceId: activeWorkspaceKey,
                    path: uploadPath,
                });

                updateUpload(placeholder, {
                    isUploading: false,
                });

                return result;
            } catch {
                setUploadingDocuments((prev) => without(prev, placeholder));
            }
        },
        [
            activeWorkspaceKey,
            createUploadUrlMutation,
            processS3Upload,
            updateUpload,
        ],
    );

    const processFile = useCallback(
        async (file: File) => {
            if (directS3DocumentUpload && isArchiveType(file.type)) {
                return directS3Upload(file);
            } else {
                return regularUpload(file);
            }
        },
        [directS3DocumentUpload, directS3Upload, regularUpload],
    );

    const handleFilesAccepted = useCallback(
        async (files: File[]) => {
            if (
                files.some(
                    (file) =>
                        file.size >
                        (isArchiveType(file.type)
                            ? maxArchiveFileSizeBytes
                            : MAX_FINANCIAL_DOCUMENT_FILE_SIZE_BYTES),
                )
            ) {
                error(
                    `Max allowed size is ${MAX_FINANCIAL_DOCUMENT_FILE_SIZE_MB}MB or ${maxArchiveFileSize}MB for archives`,
                );
                return;
            }

            const results = await Promise.allSettled(files.map(processFile));
            const successful = results
                .filter((result) => result.status === "fulfilled")
                .map((result) => result.value as FinancialDocument[])
                .flat();

            await onCompleted?.(successful);
        },
        [
            error,
            maxArchiveFileSize,
            maxArchiveFileSizeBytes,
            onCompleted,
            processFile,
        ],
    );

    const { getRootProps, getInputProps, isDragAccept, open } = useDropzone({
        onDropRejected: handleFilesRejected,
        onDropAccepted: handleFilesAccepted,
        accept: accept ?? [...FINANCIAL_DOCUMENT_TYPES, ...ARCHIVE_TYPES],
        multiple: true,
        noClick: true,
    });

    const clearUploads = useCallback(() => {
        setUploadingDocuments([]);
    }, []);

    const value = useMemo(
        () => ({
            isDragActive: isDragAccept,
            inputProps: getInputProps(),
            rootProps: getRootProps(),
            open,
            uploadingDocuments,
            updateUploads: setUploadingDocuments,
            clearUploads,
        }),
        [
            clearUploads,
            getInputProps,
            getRootProps,
            isDragAccept,
            open,
            uploadingDocuments,
        ],
    );

    useImperativeHandle(ref, () => value, [value]);

    return (
        <financialDocumentUploadContext.Provider value={value}>
            {children}
        </financialDocumentUploadContext.Provider>
    );
});
