import React, { useMemo } from "react";
import { range } from "lodash";
import classNames from "classnames";
import { SkeletonCell } from "../../general/Skeleton/SkeletonCell";
import "./TransactionsTableCta.scss";
import { currencyFormatterShort } from "../../../common/helpers/currency";
import { ThunderboltIcon } from "../../../icons";
import { useBillingManagement } from "../../../hooks/useBillingManagement";
import { useBillingStatus } from "../../../hooks/useBillingStatus";
import { useSettings } from "../../settings/SettingsProvider";
import { EndTrialAction } from "../../billing/AccessBlockedPage/EndTrialAction";
import { PlanManagementProvider } from "../../billing/PlanManagement/PlanManagementProvider";
import { Button } from "../../general/Button/Button";

export interface TransactionsTableCtaProps {
    rows: number;
    showAccountNames?: boolean;
    bulkActionsEnabled?: boolean;
    transactionSelected?: boolean;
    header?: React.ReactNode;
    description?: React.ReactNode;
    reversed?: boolean;
    showSplitButton?: boolean;
}
export const TransactionsTableCta: React.FC<TransactionsTableCtaProps> = ({
    rows: rowsCount,
    header,
    description,
    showAccountNames,
    bulkActionsEnabled,
    transactionSelected,
    reversed,
    showSplitButton,
}) => {
    const { isTrialing } = useBillingStatus();
    const { currentUsageLimit } = useBillingManagement();
    const { open } = useSettings();

    const rows = useMemo(() => range(rowsCount), [rowsCount]);

    let action: React.ReactNode;

    if (isTrialing) {
        action = (
            <PlanManagementProvider>
                <EndTrialAction variant="action" size="sm">
                    Start subscription
                </EndTrialAction>
            </PlanManagementProvider>
        );
    } else {
        action = (
            <Button
                onClick={() => open(["plans"])}
                variant="action"
                data-testid="cta-cover-button"
                size="sm"
            >
                <ThunderboltIcon />
                Upgrade
            </Button>
        );
    }

    return (
        <tbody
            className={classNames(
                "transactions-table-cta",
                `transactions-table-cta--rows-${rowsCount}`,
                {
                    "transactions-table-cta--reversed": reversed,
                },
            )}
        >
            {rows.map((row) => (
                <tr key={row} className="transaction-list-item">
                    {bulkActionsEnabled ? (
                        <SkeletonCell
                            skeleton={20}
                            className="transaction-list-item__select"
                            style={
                                {
                                    "--background": "transparent",
                                    "--border-color":
                                        "rgba(83, 100, 126, 0.18)", // misc-alpha-300
                                } as React.CSSProperties
                            }
                        />
                    ) : null}

                    <SkeletonCell
                        skeleton={40}
                        className="transaction-list-item__date d-none d-md-table-cell"
                    />
                    {showSplitButton && (
                        <SkeletonCell
                            skeleton={40}
                            className="transaction-list-item__split"
                        />
                    )}

                    <SkeletonCell
                        skeleton={row % 2 ? 120 : 160}
                        className="transaction-list-item__description"
                    />

                    {!transactionSelected && (
                        <SkeletonCell
                            skeleton={row % 2 ? 100 : 140}
                            className="transaction-list-item__category"
                        />
                    )}

                    {showAccountNames && (
                        <SkeletonCell
                            skeleton={88}
                            className="d-none d-md-table-cell transaction-list-item__account"
                        />
                    )}

                    <SkeletonCell
                        skeleton={row % 2 ? 60 : 68}
                        className="transaction-list-item__amount"
                    />

                    {!transactionSelected && (
                        <SkeletonCell
                            skeleton={20}
                            className="transaction-list-item__entity"
                        />
                    )}

                    <SkeletonCell
                        skeleton={20}
                        className="transaction-list-item__action"
                    />
                </tr>
            ))}

            <aside
                className="transactions-table-cta__cover"
                data-testid="transactions-cta-cover"
            >
                <header className="m-3 transactions-table-cta__header">
                    {header ?? (
                        <>
                            You’ve reached the{" "}
                            {currencyFormatterShort.format(currentUsageLimit)}{" "}
                            expense limit
                        </>
                    )}
                </header>

                {description && <p className="m-0">{description}</p>}

                {action}
            </aside>
        </tbody>
    );
};
