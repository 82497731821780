import { useMutation, useQuery } from "@tanstack/react-query";
import { ServerInferRequest } from "@ts-rest/core";
import { useMemo } from "react";
import { getBackendAPIClient } from "../lib/backendAPIClient";
import { useWorkspaceContext } from "../state/workspaceContext";
import { queryClient } from "../queryClient";
import { DeepOmit } from "../common/types/base/generics";
import { Class } from "../common/types/class";
import { classContract } from "../common/contracts/class.contract";
import {
    ReorderClassesInParentParams,
    reorderClassesInParent,
} from "../common/helpers/class";
import { useTypedFlags } from "../hooks/useTypedFlags";
import { ClassWithChildren, getNormalizedClasses } from "../helpers/class";
import { invalidateTransactionsQueries } from "../components/transactions/useTransactionsQuery";

export const classContractClient = getBackendAPIClient(classContract);

export const getClassesQueryKey = (workspaceId: string) => [
    "classes",
    workspaceId,
];

export const getClassTransactionsCountQueryKey = (workspaceId: string) => [
    "classTransactionsCount",
    workspaceId,
];

export function useClasses(): {
    classes: Class[];
    classesWithChildren: ClassWithChildren[];
    classesMap: Map<string, ClassWithChildren>;
    sortedClassesIndexMap: Map<string, number>;
    isActivated: boolean;
} {
    const { classes } = useTypedFlags();
    const { activeWorkspace } = useWorkspaceContext();
    const classesQuery = useQuery({
        queryKey: activeWorkspace?.id
            ? getClassesQueryKey(activeWorkspace.id)
            : [],
        queryFn: async () => {
            if (!activeWorkspace?.id) {
                return;
            }
            const result = await classContractClient.getClasses({
                params: { workspaceId: activeWorkspace.id },
            });
            return result.body.classes;
        },
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        refetchOnMount: true,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });

    const normalizedClasses = useMemo(() => {
        if (!classesQuery.data) {
            return {
                sortedClasses: [],
                classesWithChildren: [],
                classesMap: new Map(),
                sortedClassesIndexMap: new Map(),
            };
        }

        return getNormalizedClasses(classesQuery.data);
    }, [classesQuery.data]);

    return {
        classes: normalizedClasses.sortedClasses,
        classesWithChildren: normalizedClasses.classesWithChildren,
        classesMap: normalizedClasses.classesMap,
        sortedClassesIndexMap: normalizedClasses.sortedClassesIndexMap,
        isActivated: classes && normalizedClasses.sortedClasses.length > 0,
    };
}

export function useClassTransactionsCountQuery() {
    const { activeWorkspace } = useWorkspaceContext();
    return useQuery({
        queryKey: activeWorkspace?.id
            ? getClassTransactionsCountQueryKey(activeWorkspace.id)
            : [],
        queryFn: async () => {
            if (!activeWorkspace?.id) {
                return;
            }
            const result = await classContractClient.getClassTransactionsCount({
                params: { workspaceId: activeWorkspace.id },
            });
            return result.body;
        },
    });
}

export function useCreateClassMutation() {
    const { activeWorkspace } = useWorkspaceContext();
    if (!activeWorkspace) {
        throw new Error("Expecting active workspace");
    }
    return useMutation({
        mutationFn: async (
            schema: ServerInferRequest<
                typeof classContract.createClass
            >["body"],
        ) => {
            const result = await classContractClient.createClass({
                params: { workspaceId: activeWorkspace.id },
                body: schema,
            });
            return result.body.class;
        },
        onSuccess: async (createdClass) => {
            queryClient.setQueryData(getClassesQueryKey(activeWorkspace.id), [
                ...(queryClient.getQueryData<Class[]>(
                    getClassesQueryKey(activeWorkspace.id),
                ) ?? []),
                createdClass,
            ]);
            invalidateClassesQueries(activeWorkspace.id);
        },
    });
}

export function useUpdateClassMutation() {
    const { activeWorkspace } = useWorkspaceContext();
    if (!activeWorkspace) {
        throw new Error("Expecting active workspace");
    }
    return useMutation({
        mutationFn: async (
            schema: DeepOmit<
                ServerInferRequest<typeof classContract.updateClass>,
                "params",
                "workspaceId"
            >,
        ) => {
            const result = await classContractClient.updateClass({
                params: {
                    workspaceId: activeWorkspace.id,
                    id: schema.params.id,
                },
                body: schema.body,
            });
            return result.body.class;
        },
        onSuccess: async (updatedClass) => {
            if (!updatedClass) {
                return;
            }
            const previousData =
                queryClient.getQueryData<Class[]>(
                    getClassesQueryKey(activeWorkspace.id),
                ) ?? [];

            queryClient.setQueryData(
                getClassesQueryKey(activeWorkspace.id),
                previousData.map((c) =>
                    c.id === updatedClass.id ? updatedClass : c,
                ),
            );
            invalidateClassesQueries(activeWorkspace.id);
        },
    });
}

export function useRepositionClassMutation() {
    const { activeWorkspace } = useWorkspaceContext();
    if (!activeWorkspace) {
        throw new Error("Expecting active workspace");
    }
    return useMutation({
        mutationFn: async (
            schema: DeepOmit<
                ServerInferRequest<typeof classContract.changeClassPosition>,
                "params",
                "workspaceId"
            >,
        ) => {
            const result = await classContractClient.changeClassPosition({
                params: {
                    workspaceId: activeWorkspace.id,
                    id: schema.params.id,
                },
                body: schema.body,
            });
            return result.body.class;
        },
        onSuccess: async (updatedClass) => {
            if (!updatedClass) {
                return;
            }
            const previousData =
                queryClient.getQueryData<Class[]>(
                    getClassesQueryKey(activeWorkspace.id),
                ) ?? [];

            queryClient.setQueryData(
                getClassesQueryKey(activeWorkspace.id),
                previousData.map((c) =>
                    c.id === updatedClass.id ? updatedClass : c,
                ),
            );
            invalidateClassesQueries(activeWorkspace.id);
            // NOTE: after classes reposition, we need to reset local classes in transactions
            invalidateTransactionsQueries();
        },
        async onError() {
            invalidateClassesQueries(activeWorkspace.id);
        },
    });
}

export function useDeleteClassMutation() {
    const { activeWorkspace } = useWorkspaceContext();
    if (!activeWorkspace) {
        throw new Error("Expecting active workspace");
    }
    return useMutation({
        mutationFn: async (id: string) => {
            await classContractClient.deleteClass({
                params: { workspaceId: activeWorkspace.id, id },
            });
        },
        onSuccess: async (_, id) => {
            const previousData =
                queryClient.getQueryData<Class[]>(
                    getClassesQueryKey(activeWorkspace.id),
                ) ?? [];

            queryClient.setQueryData(
                getClassesQueryKey(activeWorkspace.id),
                previousData.filter((c) => c.id !== id),
            );

            invalidateClassesQueries(activeWorkspace.id);
        },
    });
}

export interface OptimisticUpdateClassesParams {
    workspaceId: string;
    id: string;
    parentClassId: string | null;
    order: number;
}

// NOTE:
// needs to be called outside (=before) the mutation promise,
// otherwise new order will be applied after animation of Sortable list items
// returning to their initial positions which looks unsexy from UX perspective
export async function optimisticallyUpdateClassesOrder({
    workspaceId,
    id,
    parentClassId,
    order,
}: OptimisticUpdateClassesParams) {
    const classes = queryClient.getQueryData<Class[]>(
        getClassesQueryKey(workspaceId),
    );
    const newClasses = new Map([...(classes ?? [])].map((c) => [c.id, c]));

    let targetClass: Class | undefined;
    let targetParentClass: Class | undefined;
    for (const c of newClasses.values()) {
        if (c.id === id) {
            targetClass = c;
            newClasses.set(c.id, {
                ...c,
                parentClassId,
            });
        }
        if (c.id === parentClassId) {
            targetParentClass = c;
        }
    }

    if (!targetClass || (parentClassId !== null && !targetParentClass)) {
        return;
    }
    newClasses.set(targetClass.id, {
        ...newClasses.get(targetClass.id)!,
        rootClassId: targetParentClass
            ? targetParentClass.rootClassId
            : targetClass.id,
    });

    if (targetClass.parentClassId !== parentClassId) {
        const reorderedClassesInPreviousParent = reorderClassesInTargetParent({
            classes: Array.from(newClasses.values()),
            orderChange: {
                typeOfChange: "removed",
            },
            targetParentClassId: targetClass.parentClassId,
        });
        const reorderedClassesInNewParent = reorderClassesInTargetParent({
            classes: Array.from(newClasses.values()),
            orderChange: {
                typeOfChange: "inserted",
                to: order,
                targetId: id,
            },
            targetParentClassId: parentClassId,
        });

        [
            ...reorderedClassesInNewParent,
            ...reorderedClassesInPreviousParent,
        ].forEach((c) => newClasses.set(c.id, c));
    } else {
        const reorderedClasses = reorderClassesInTargetParent({
            classes: Array.from(newClasses.values()),
            orderChange: {
                typeOfChange: "moved",
                to: order,
                targetId: id,
            },
            targetParentClassId: parentClassId,
        });
        reorderedClasses.forEach((c) => newClasses.set(c.id, c));
    }

    queryClient.setQueryData(
        getClassesQueryKey(workspaceId),
        Array.from(newClasses.values()),
    );
}

function invalidateClassesQueries(workspaceId: string) {
    queryClient.invalidateQueries({
        queryKey: getClassesQueryKey(workspaceId),
    });
}

interface ReorderClassesInTargetParentParams
    extends ReorderClassesInParentParams<Class> {
    targetParentClassId: string | null;
}

export function reorderClassesInTargetParent({
    classes,
    orderChange,
    targetParentClassId,
}: ReorderClassesInTargetParentParams) {
    const classesInParent = classes
        .filter((c) => c.parentClassId === targetParentClassId)
        .sort((a, b) => a.order - b.order)
        .map((c) => ({
            ...c,
        }));
    const reorderedClasses = reorderClassesInParent({
        classes: classesInParent,
        orderChange,
    });
    for (const [index, c] of reorderedClasses.entries()) {
        c.order = index;
    }
    return reorderedClasses;
}
