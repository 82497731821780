import React, { useMemo } from "react";
import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FlattenedItem } from "./types";

export type AbstractSortableListItemType = FlattenedItem;

export interface AbstractSortableListItemRenderItemProps {
    state: AbstractSortableListItemState;
    setNodeRef: (node: HTMLElement | null) => void;
    style: React.CSSProperties;
}

export type RenderItemFunctionType<T extends AbstractSortableListItemType> = (
    item: T,
    options: AbstractSortableListItemRenderItemProps,
) => React.ReactNode;

export interface AbstractSortableListItemState {
    isDragged: boolean;
    depth: number;
    parentIsDragged: boolean;
}

export interface AbstractSortableListItemProps<
    T extends AbstractSortableListItemType,
> {
    id: UniqueIdentifier;
    state: AbstractSortableListItemState;
    renderItem: RenderItemFunctionType<T>;
    item: T;
}

export const AbstractSortableListItem = <
    T extends AbstractSortableListItemType,
>({
    id,
    state,
    renderItem,
    item,
}: AbstractSortableListItemProps<T>) => {
    const { setNodeRef, transform, transition } = useSortable({
        id,
    });

    const isActive = state.isDragged;

    const style = useMemo(
        () => ({
            transform: CSS.Transform.toString(transform),
            transition: isActive ? transition : "all 200ms",
        }),
        [transform, transition, isActive],
    );

    return (
        <>
            {renderItem(item, {
                state,
                setNodeRef,
                style,
            })}
        </>
    );
};
