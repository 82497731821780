import { useEffect } from "react";
import { useEntities } from "../../hooks/useEntities";
import { useTransactionsPageFiltersCache } from "../../hooks/useTransactionsPageFiltersCache";
import { useTransactionsPageSortingCache } from "../../hooks/useTransactionsPageSortingCache";
import { preloadTransactionsQuery } from "./useTransactionsQuery";
import { DEFAULT_SORT, getSortExpression } from "./useSort";

export function usePrefetchTransactions() {
    const [cachedFilters] = useTransactionsPageFiltersCache();
    const [cachedSorting] = useTransactionsPageSortingCache();
    const entities = useEntities();

    useEffect(() => {
        preloadTransactionsQuery({
            filters: {
                requiredAction: false,
                ...(cachedFilters ?? {}),
                entityIds:
                    cachedFilters?.entityIds ?? entities.map(({ id }) => id),
            },
            page: 1,
            sort: getSortExpression(cachedSorting ?? DEFAULT_SORT),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
