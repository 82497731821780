import React, { useMemo } from "react";
import { useEntities } from "../../../hooks/useEntities";
import { EntityIconList } from "../EntityIconList/EntityIconList";
import { PopoverContainer } from "../PopoverContainer";
import { Entity } from "../../../common/types/entity";
import styles from "./EntityFilter.module.scss";
import { EntityFilterOverlay } from "./EntityFilterOverlay";
import { EntityFilterProps } from "./types";

export const EntityFilter: React.FC<EntityFilterProps> = ({
    filters,
    onChange,
    onlyBusiness,
}) => {
    const entities = useEntities();
    const selectedEntities = useMemo(() => {
        let entitiesToReturn: Entity[] = [];

        const entityIds = filters.entityIds;

        if (!entityIds || entityIds.length === 0) {
            entitiesToReturn = entities;
        } else {
            entitiesToReturn = entities.filter((entity) =>
                entityIds.includes(entity.id),
            );
        }

        if (onlyBusiness) {
            return entitiesToReturn.filter(({ isBusiness }) => isBusiness);
        }

        return entitiesToReturn;
    }, [entities, filters.entityIds, onlyBusiness]);

    return (
        <PopoverContainer
            id="entities-filter"
            popoverClass={styles.popover}
            buttonActiveClass=""
            buttonText={
                <EntityIconList
                    entities={selectedEntities}
                    maxEntitiesToDisplay={4}
                />
            }
            offset={4}
        >
            <EntityFilterOverlay
                filters={filters}
                onlyBusiness={onlyBusiness}
                onChange={onChange}
            />
        </PopoverContainer>
    );
};
