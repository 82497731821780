import { useQuery } from "@tanstack/react-query";
import { subDays } from "date-fns";
import {
    ActionActivityItem,
    ActionCampaign,
    ActionsActivity,
    FakeActionCampaign,
} from "../common/types/actions";
import { getActionsActivity } from "../lib/actions";
import { queryClient } from "../queryClient";
import { useWorkspaceContext } from "../state/workspaceContext";
import { User } from "../common/types/user";
import { useIsDemoUser } from "./useIsDemoUser";

export const ACTIONS_ACTIVITY_QUERY_KEY = "actionsActivity";

export function useActionsActivity() {
    const { activeWorkspace } = useWorkspaceContext();
    const isDemoAccount = useIsDemoUser();

    return useQuery({
        queryKey: [ACTIONS_ACTIVITY_QUERY_KEY, activeWorkspace?.id],
        queryFn: async () => {
            const activity = activeWorkspace
                ? await getActionsActivity(activeWorkspace.id)
                : ({} as ActionsActivity);

            if (isDemoAccount) {
                addDemoActivity(activity);
            }

            return activity;
        },
    });
}

export function invalidateActionsActivity(workspaceId: string) {
    queryClient.invalidateQueries({
        queryKey: [ACTIONS_ACTIVITY_QUERY_KEY, workspaceId],
    });
}

function addDemoActivity(activity: ActionsActivity) {
    if (!activity[new Date().toDateString()]) {
        activity[new Date().toDateString()] = [];
    }
    const todayActions = activity[new Date().toDateString()];

    const todaySavings = todayActions.find(
        (item) => item.campaign === ActionCampaign.PERSONAL_TO_BUSINESS,
    );

    if (todaySavings) {
        todaySavings.amountSaved += 50;
        todaySavings.count += 1;
    } else {
        todayActions.push({
            campaign: ActionCampaign.PERSONAL_TO_BUSINESS,
            count: 1,
            amountSaved: 50,
        });
    }

    const yesterdayActions: ActionActivityItem[] = [];
    activity[subDays(new Date(), 1).toDateString()] = yesterdayActions;

    yesterdayActions.push({
        campaign: FakeActionCampaign.CATEGORIZED_TRANSACTIONS,
        count: 4,
        amountSaved: 0,
    });
    yesterdayActions.push({
        campaign: FakeActionCampaign.MATCHED_RECEIPTS,
        count: 3,
        amountSaved: 0,
    });
    yesterdayActions.push({
        campaign: FakeActionCampaign.CREATED_JOURNAL_ENTRIES,
        count: 2,
        amountSaved: 0,
        user: {
            name: "Christian Wolff",
            avatar: "https://kick-production-public.s3.us-west-2.amazonaws.com/demoAccount/christian_wolff.png",
        } as User,
    });

    const lastWeekActions: ActionActivityItem[] = [];
    activity[subDays(new Date(), 7).toDateString()] = lastWeekActions;

    lastWeekActions.push({
        campaign: ActionCampaign.INVITE_ACCOUNTANT,
        count: 0,
        amountSaved: 0,
        description: "Christian Wolff",
    });
    lastWeekActions.push({
        campaign: ActionCampaign.PERSONAL_TO_BUSINESS,
        count: 3,
        amountSaved: 50.4,
    });
    lastWeekActions.push({
        campaign: FakeActionCampaign.CATEGORIZED_TRANSACTIONS,
        count: 71,
        amountSaved: 0,
    });
    lastWeekActions.push({
        campaign: FakeActionCampaign.CONNECTED_ACCOUNTS,
        count: 2,
        amountSaved: 0,
    });
}
