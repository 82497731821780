import React from "react";
import { TransactionListItemDto } from "../../common/types/transaction";
import { useEntities } from "../../hooks/useEntities";
import { Entity } from "../../common/types/entity";
import { PopoverContainer } from "../general/PopoverContainer";
import { TransactionEntityMenuItem } from "./TransactionEntityMenuItem";

interface Props {
    transaction?: TransactionListItemDto;
    onChangeToPersonal(): void;
    onChangeToBusiness(entity: Entity): void;

    children: React.ReactNode;
}

export const TransactionEntityPopover: React.FC<Props> = ({
    transaction,
    onChangeToPersonal,
    onChangeToBusiness,
    children,
}) => {
    const entities = useEntities();

    return (
        <PopoverContainer
            id="entity-popover-container"
            trigger={children}
            offset={4}
            popoverClass="no-padding"
        >
            <div style={{ padding: 4 }}>
                {entities.map((entity) => (
                    <TransactionEntityMenuItem
                        key={entity.id}
                        transaction={transaction}
                        entity={entity}
                        onSelect={() =>
                            entity.isBusiness
                                ? onChangeToBusiness(entity)
                                : onChangeToPersonal()
                        }
                    />
                ))}
            </div>
        </PopoverContainer>
    );
};
