import React from "react";
import { isArray, isString } from "lodash";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Tag } from "../../general/Tag/Tag";
import {
    RuleConditionType,
    TransactionRuleCondition,
} from "../../../common/types/transactionRule";

interface Props {
    condition: TransactionRuleCondition;
}

export const RuleDescriptionCondition: React.FC<Props> = ({ condition }) => {
    const operator =
        condition.conditionType === RuleConditionType.DESCRIPTION_INCLUDES
            ? "contains"
            : "is";

    if (
        isString(condition.value) ||
        (isArray(condition.value) && condition.value.length === 1)
    ) {
        const value = isString(condition.value)
            ? condition.value
            : condition.value[0];

        return (
            <>
                Bank description {operator} <strong>{value}</strong>
            </>
        );
    }

    if (isArray(condition.value)) {
        const [firstValue, ...otherValues] = condition.value;

        return (
            <>
                Bank description {operator} <strong>{firstValue},</strong>{" "}
                <OverlayTrigger
                    overlay={
                        <Tooltip id={`other-values-${condition.id}`}>
                            {otherValues.join(", ")}
                        </Tooltip>
                    }
                >
                    <span>
                        <Tag variant="secondary" outline>
                            + {otherValues.length}
                        </Tag>
                    </span>
                </OverlayTrigger>
            </>
        );
    }

    return null;
};
