import React, { useEffect } from "react";
import { PageCoverLoader } from "../../../general/PageCoverLoader/PageCoverLoader";
import { useTimer } from "../../../../hooks/useTimer";
import { useBillingStatus } from "../../../../hooks/useBillingStatus";
import {
    FLAT_RATE_PLANS,
    flatRatePlanNames,
} from "../../../../common/flatRateBilling";
import { SubscriptionType } from "../../../../common/types/billing";
import { useCanManageBilling } from "../../../../hooks/useCanManageBilling";
import styles from "./Outro.module.scss";

interface Props {
    onFinished(): void;
}
export const Outro: React.FC<Props> = ({ onFinished }) => {
    const { start } = useTimer(onFinished, 3_000);
    const { isFetched, currentPlan, subscriptionType, bookedEnterpriseCall } =
        useBillingStatus();
    const canManageBilling = useCanManageBilling();

    useEffect(() => {
        if (isFetched) {
            start();
        }
    }, [isFetched, start]);

    const currentPlanName = flatRatePlanNames[currentPlan];
    let headline: React.ReactNode;

    if (
        subscriptionType !== SubscriptionType.FLAT_RATE ||
        currentPlan === FLAT_RATE_PLANS.FREE ||
        !canManageBilling
    ) {
        headline = "Get ready to kick back..";
    } else if (bookedEnterpriseCall) {
        headline = (
            <>
                Try <em>{currentPlanName}</em> Plan free for 30 days while we
                finish setting up your account
            </>
        );
    } else {
        headline = (
            <>
                Your {currentPlanName} plan trial is about to start, get ready
                to kick back...
            </>
        );
    }

    return (
        <PageCoverLoader backdrop className={styles.cover} logo={false}>
            <div className={styles.outro}>
                <h1 className="onboarding__title">Processing your data</h1>
                <p className="onboarding__headline">{headline}</p>
            </div>
        </PageCoverLoader>
    );
};
