import classNames from "classnames";
import { capitalize } from "lodash";
import { pluralize } from "../../common/helpers/string";
import { Invoice, InvoiceSortValue } from "../../common/types/invoice";
import { formatDateForTransaction } from "../../helpers/date";
import {
    getInvoiceStatusVariant,
    getStripeDashboardUrlForInvoice,
} from "../../helpers/invoice";
import { ArrowCornerIcon } from "../../icons";
import { EntityIcon } from "../entity/EntityIcon";
import { Amount } from "../general/Amount/Amount";
import { SortableHeader } from "../general/SortableHeader/SortableHeader";
import { Tag } from "../general/Tag/Tag";
import { Button } from "../general/Button/Button";
import styles from "./InvoicesTable.module.scss";

interface InvoicesTableProps {
    invoices: Invoice[];
    onInvoiceClick: (invoice: Invoice) => void;
    onSortChange?: (sort: InvoiceSortValue) => void;
    sort: InvoiceSortValue;
    pagination?: React.ReactNode;
    invoiceCount: number;
    selectedInvoiceId?: string;
}

export const InvoicesTable: React.FC<InvoicesTableProps> = ({
    invoices,
    onInvoiceClick,
    sort,
    onSortChange,
    pagination,
    invoiceCount,
    selectedInvoiceId,
}) => (
    <table className={classNames("table", styles.table)}>
        <thead>
            <tr>
                <th>
                    <SortableHeader
                        asc={InvoiceSortValue.DATE_ASC}
                        desc={InvoiceSortValue.DATE_DESC}
                        sort={sort}
                        onChange={onSortChange}
                        defaultOrder="desc"
                    >
                        Date
                    </SortableHeader>
                </th>
                <th>
                    <SortableHeader
                        asc={InvoiceSortValue.STATUS_ASC}
                        desc={InvoiceSortValue.STATUS_DESC}
                        sort={sort}
                        onChange={onSortChange}
                        defaultOrder="desc"
                    >
                        Status
                    </SortableHeader>
                </th>
                <th>
                    <SortableHeader
                        asc={InvoiceSortValue.COUNTERPARTY_ASC}
                        desc={InvoiceSortValue.COUNTERPARTY_DESC}
                        sort={sort}
                        onChange={onSortChange}
                        defaultOrder="desc"
                    >
                        Customer
                    </SortableHeader>
                </th>
                <th>
                    <SortableHeader
                        asc={InvoiceSortValue.DUE_DATE_ASC}
                        desc={InvoiceSortValue.DUE_DATE_DESC}
                        sort={sort}
                        onChange={onSortChange}
                        defaultOrder="desc"
                    >
                        Due Date
                    </SortableHeader>
                </th>
                <th className={styles.amount}>
                    <SortableHeader
                        asc={InvoiceSortValue.AMOUNT_ASC}
                        desc={InvoiceSortValue.AMOUNT_DESC}
                        sort={sort}
                        onChange={onSortChange}
                        defaultOrder="desc"
                    >
                        Amount
                    </SortableHeader>
                </th>
                <th className={styles.entity}></th>
                {!selectedInvoiceId && <th></th>}
            </tr>
        </thead>
        <tbody>
            {invoices.map((invoice) => (
                <tr
                    key={invoice.id}
                    className={classNames(styles.invoiceTableItem, {
                        [styles.invoiceTableItemActive]:
                            invoice.id === selectedInvoiceId,
                    })}
                    onClick={() => onInvoiceClick(invoice)}
                >
                    <td className={styles.created}>
                        {formatDateForTransaction(new Date(invoice.createdAt))}
                    </td>
                    <td className={styles.status}>
                        <Tag
                            size="sm"
                            outline
                            variant={getInvoiceStatusVariant(invoice.status)}
                        >
                            {capitalize(invoice.status ?? "")}
                        </Tag>
                    </td>
                    <td className={styles.customer}>
                        <div className={styles.customerContent}>
                            {invoice.counterparty ? (
                                <>
                                    <span className={styles.customerName}>
                                        {invoice.counterparty?.name}
                                    </span>
                                    <span className={styles.customerEmail}>
                                        {invoice.counterparty?.email}
                                    </span>
                                </>
                            ) : (
                                "-"
                            )}
                        </div>
                    </td>
                    <td className={styles.dueDate}>
                        {invoice.due !== null
                            ? formatDateForTransaction(new Date(invoice.due))
                            : "-"}
                    </td>
                    <td className={styles.amount}>
                        <Amount amount={invoice.total} />
                    </td>
                    <td className={styles.entity}>
                        {invoice.financialAccount?.entity && (
                            <EntityIcon
                                size="xs"
                                entity={invoice.financialAccount?.entity}
                            />
                        )}
                    </td>
                    {!selectedInvoiceId && (
                        <td>
                            <Button
                                size="sm"
                                icon
                                variant="tertiary"
                                tooltip="View in Stripe"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    window.open(
                                        getStripeDashboardUrlForInvoice(
                                            invoice,
                                        ),
                                        "_blank",
                                    );
                                }}
                            >
                                <ArrowCornerIcon className={styles.viewIcon} />
                            </Button>
                        </td>
                    )}
                </tr>
            ))}
        </tbody>
        <tfoot className={styles.tableFooter}>
            <tr className={styles.tableRow}>
                <td colSpan={99}>
                    <div className={styles.tableRowContent}>
                        <div>
                            {invoiceCount} {pluralize("invoice", invoiceCount)}
                        </div>
                        {pagination}
                    </div>
                </td>
            </tr>
        </tfoot>
    </table>
);
