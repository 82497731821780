import { RegisterDto } from "../common/dto/user/onboarding/register.dto";
import { InviteCodeValidResponseDto } from "../common/dto/user/invite-code-valid-response.dto";
import { backendClient, unwrapResponse } from "./backendClient";

export async function register(
    email: string,
    inviteCode?: string,
): Promise<void> {
    await backendClient.post("/user/onboarding/register", {
        email,
        inviteCode,
    } satisfies RegisterDto);
}

export async function trackSignupComplete(): Promise<void> {
    await backendClient.post("/user/onboarding/signup-complete");
}

export async function completeOnboarding(): Promise<void> {
    await backendClient.post("/user/onboarding/complete");
}

export async function isInviteCodeValid(
    code: string,
): Promise<InviteCodeValidResponseDto> {
    return unwrapResponse(
        await backendClient.get(`/user/onboarding/invite-code-valid/${code}`),
    );
}
