import React from "react";
import classNames from "classnames";
import { UploadingDocumentPlaceholder } from "../documents/lib";
import { UploadIcon } from "../../icons";
import { Button } from "../general/Button/Button";
import { FinancialDocumentsUpload } from "../documents/FinancialDocumentUpload/FinancialDocumentsUpload";
import styles from "./ImportedDocuments.module.scss";
import { ImportedDocumentsRow } from "./ImportedDocumentsRow";

export interface Props {
    items: UploadingDocumentPlaceholder[];
    onRemoveItem: (item: UploadingDocumentPlaceholder) => void;
    showTransactionsCount?: boolean;
    className?: string;
}

export const ImportedDocuments: React.FC<Props> = ({
    items,
    onRemoveItem,
    showTransactionsCount = true,
    className,
}) => (
    <section className={classNames(styles.documentsTable, className)}>
        <header className={classNames(styles.header, styles.row)}>
            <span>Name</span>
            {showTransactionsCount ? (
                <span className="text-right">Transactions</span>
            ) : (
                <span />
            )}
        </header>

        {items.map((item) => (
            <ImportedDocumentsRow
                item={item}
                onRemove={() => onRemoveItem(item)}
                key={item.financialDocument?.id ?? item.fileName}
                showTransactionsCount={showTransactionsCount}
            />
        ))}

        <footer className={classNames(styles.row, styles.footer)}>
            <FinancialDocumentsUpload
                render={(open) => (
                    <Button variant="tertiary" onClick={open} size="sm">
                        <UploadIcon />
                        Upload another file
                    </Button>
                )}
            />
        </footer>
    </section>
);
