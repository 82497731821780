import { useState } from "react";
import classNames from "classnames";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { PlusIcon } from "../../../../icons";
import { useClasses, useCreateClassMutation } from "../../../../api/class.api";
import styles from "../ClassesSettings.module.scss";
import { getErrorForAddClass } from "../utils";

export const AddClassRow: React.FC<{
    parentClassId: string | null;
    isFocused: boolean;
    setIsFocused: (value: boolean) => void;
    inputRef: React.RefObject<HTMLInputElement>;
}> = ({ parentClassId, isFocused, setIsFocused, inputRef }) => {
    const { classes } = useClasses();
    const addClass = useCreateClassMutation();
    const [inputValue, setInputValue] = useState("");
    const [error, setError] = useState<string | null>(null);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setInputValue(e.target.value);
        setError(getErrorForAddClass(classes, parentClassId, e.target.value));
    };

    return (
        <div className="d-flex align-items-center">
            <PlusIcon
                className={classNames(
                    styles.addClassIcon,
                    "icon-color-gray-500",
                )}
            />
            <OverlayTrigger
                show={isFocused && !!error}
                placement="top"
                overlay={<Tooltip id="add-class-error">{error}</Tooltip>}
            >
                <input
                    className={classNames(styles.addClassInput, {
                        [styles.addClassInputError]: error,
                    })}
                    type="text"
                    placeholder={
                        parentClassId ? `Add subclass...` : "New class..."
                    }
                    onBlur={() => {
                        setIsFocused(false);
                    }}
                    onFocus={() => {
                        setIsFocused(true);
                    }}
                    value={inputValue}
                    onChange={onChange}
                    onKeyDown={async (e) => {
                        if (error) {
                            return;
                        }
                        if (e.key === "Enter") {
                            await addClass.mutateAsync({
                                label: inputValue,
                                parentClassId,
                            });
                            setInputValue("");
                            setTimeout(() => {
                                inputRef.current?.scrollIntoView({
                                    behavior: "instant",
                                    block: "end",
                                });
                            }, 5);
                        }
                    }}
                    ref={inputRef}
                />
            </OverlayTrigger>
        </div>
    );
};
