import { useQuery } from "@tanstack/react-query";
import { getUserWorkspaces } from "../lib/user";

interface UseWorkspacesProps {
    withEntities?: boolean;
}

export const useWorkspaces = ({
    withEntities = false,
}: UseWorkspacesProps = {}) => {
    const userWorkspaces = useQuery({
        queryKey: ["workspaces", withEntities],
        queryFn: async () => {
            const response = await getUserWorkspaces(withEntities);
            return response?.workspaces ?? [];
        },
    });

    return userWorkspaces;
};
