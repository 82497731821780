import { GraphIcon } from "../../../icons";
import { ActionActivity } from "../ActionActivity";
import { CampaignActivityProps } from "../ActionActivityItem";

export const ConfirmTransactionsActivity: React.FC<CampaignActivityProps> = ({
    animated,
}) => (
    <ActionActivity icon={<GraphIcon />} animated={animated}>
        Confirmed recent transactions
    </ActionActivity>
);
