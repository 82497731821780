import React, { useCallback } from "react";
import {
    StandardModal,
    StandardModalBody,
    StandardModalHeader,
} from "../general/Modal/Modal";
import { Button } from "../general/Button/Button";

export interface NewVersionModalProps {
    show: boolean;
}
export const NewVersionModal: React.FC<NewVersionModalProps> = ({ show }) => {
    const refresh = useCallback(() => {
        window.location.reload();
    }, []);

    return (
        <StandardModal show={show}>
            <StandardModalHeader>New version available</StandardModalHeader>
            <StandardModalBody>
                <p>
                    A new version of the application is available. Please
                    refresh your browser window to continue using Kick.
                </p>

                <footer className="d-flex align-items-center justify-content-center">
                    <Button size="lg" onClick={refresh}>
                        Refresh
                    </Button>
                </footer>
            </StandardModalBody>
        </StandardModal>
    );
};
