import React from "react";
import colors from "@styles/colors.module.scss";
import { Entity } from "../../common/types/entity";
import { UserAvatar } from "../user/UserAvatar";
import { IconSize, ObjectIcon } from "../general/ObjectIcon/ObjectIcon";

interface Props {
    size?: IconSize;
    entity: Entity;
    withBorders?: boolean;
    className?: string;
}

export const EntityIcon: React.FC<Props> = ({
    size = "md",
    entity,
    withBorders,
    className,
}) => {
    const borderColor =
        entity.iconColor === colors.white ? colors.miscAlpha100 : colors.white;
    const border = withBorders ? `1px inset ${borderColor}` : undefined;
    return entity.user ? (
        <UserAvatar
            user={entity.user}
            size={size}
            square
            border={border}
            className={className}
        />
    ) : (
        <ObjectIcon
            name={entity.name ?? ""}
            defaultColor={entity.iconColor}
            size={size}
            square
            border={border}
            className={className}
        />
    );
};
