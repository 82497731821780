import React, { useCallback } from "react";
import classNames from "classnames";
import { useEntities } from "../../hooks/useEntities";
import { EntityIcon } from "../entity/EntityIcon";
import { Entity } from "../../common/types/entity";
import "./EntitiesNavigation.scss";
import { TriangleIcon } from "../../icons";
import { Button } from "../general/Button/Button";

interface Props {
    isEntityActive: (entity: Entity) => boolean;
    isEntityDisabled?: (entity: Entity) => boolean;
    onSelectEntity?: (entity: Entity) => void;
    renderNavigation: (entity: Entity) => React.ReactNode;
    renderAddon?: (entity: Entity) => React.ReactNode;
    openedEntity?: number;
    setOpenedEntity: (entityId?: number) => void;
    label?: string;
}

export const EntitiesNavigation: React.FC<Props> = ({
    isEntityActive,
    onSelectEntity,
    renderNavigation,
    renderAddon,
    openedEntity,
    setOpenedEntity,
    isEntityDisabled,
    label = "Entities",
}) => {
    const entities = useEntities();

    const toggleEntityMenu = useCallback(
        (entity: Entity) => {
            if (openedEntity !== entity.id) {
                setOpenedEntity(entity.id);
                onSelectEntity?.(entity);
            } else {
                setOpenedEntity(undefined);
            }
        },
        [onSelectEntity, openedEntity, setOpenedEntity],
    );

    return (
        <section
            className="entities-navigation"
            data-testid="entities-navigation"
        >
            <span className="label entities-navigation__header">{label}</span>
            {entities.map((entity) => (
                <div
                    data-testid="entities-navigation-entity"
                    key={entity.id}
                    className={classNames("entities-navigation__entity-menu", {
                        "entities-navigation__entity-menu--opened":
                            openedEntity === entity.id,
                    })}
                >
                    {!isEntityDisabled?.(entity) && (
                        <Button
                            data-testid="entities-navigation-entity-button"
                            variant="tertiary"
                            onClick={() => toggleEntityMenu(entity)}
                            className={classNames(
                                "entities-navigation__name",
                                "nav-link",
                                {
                                    active: isEntityActive(entity),
                                },
                            )}
                        >
                            <EntityIcon entity={entity} size="xs" />
                            <span>
                                {entity.isPersonal ? "Personal" : entity.name}
                            </span>
                            <TriangleIcon className="entities-navigation__toggle" />
                        </Button>
                    )}
                    {isEntityDisabled?.(entity) && (
                        <div
                            className={classNames(
                                "entities-navigation__name",
                                "nav-link",
                                {
                                    active: isEntityActive(entity),
                                },
                                "gap-2",
                            )}
                        >
                            <EntityIcon entity={entity} size="xs" />
                            <span>
                                {entity.isPersonal ? "Personal" : entity.name}
                            </span>
                        </div>
                    )}
                    {renderAddon?.(entity)}
                    <div
                        className="entities-navigation__content"
                        data-testid="entities-navigation-entity-submenu"
                    >
                        {renderNavigation(entity)}
                    </div>
                </div>
            ))}
        </section>
    );
};
