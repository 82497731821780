import React, { CSSProperties, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Entity } from "../../../common/types/entity";
import {
    useFinancialAccounts,
    useTotalBalance,
} from "../../../hooks/useFinancialAccounts";
import { currencyFormatter } from "../../../common/helpers/currency";
import { AccountChartTooltip } from "./AccountChartTooltip";
import { AccountsChartEntityAccounts } from "./AccountsChartEntityAccounts";
import { AccountsChartEntityContextProvider } from "./AccountsChartEntityContextProvider";

interface Props {
    entity: Entity;
    globalTotalBalance: number;
}

const DISPLAY_THRESHOLD = 0.5;
const ANIMATION_LENGTH = 200; //ms

export const AccountsChartEntity: React.FC<Props> = ({
    entity,
    globalTotalBalance,
}) => {
    const entityTotalBalance = useTotalBalance(entity);
    const percentage = (entityTotalBalance / globalTotalBalance) * 100;

    const cssVariables = {
        "--entity-width": percentage + "%",
        "--entity-color": entity.user?.iconColor ?? entity.iconColor,
        "--entity-animation-length": ANIMATION_LENGTH + "ms",
    } as CSSProperties;
    const accounts = useFinancialAccounts({
        entityId: entity.id,
    });

    const containerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const [contentHidden, setContentHidden] = useState(true);

    const initialRender = useRef(true);
    useEffect(() => {
        const timeout = setTimeout(
            () => {
                if (containerRef.current && contentRef.current) {
                    setContentHidden(
                        contentRef.current.offsetWidth >
                            containerRef.current.offsetWidth,
                    );
                }
                // * 2 because we want to wait for the animation to finish
            },
            initialRender.current ? 100 : ANIMATION_LENGTH * 2,
        );

        initialRender.current = false;

        return () => clearTimeout(timeout);
    }, [percentage, entity.name]);

    const content = (
        <div
            className={classNames("accounts-chart__entity", {
                "accounts-chart__entity--content-hidden": contentHidden,
            })}
            style={cssVariables}
            ref={containerRef}
        >
            <AccountsChartEntityContextProvider>
                <AccountsChartEntityAccounts
                    entity={entity}
                    totalBalance={entityTotalBalance}
                    accounts={accounts}
                />
                <AccountsChartEntityAccounts
                    entity={entity}
                    totalBalance={entityTotalBalance}
                    accounts={accounts}
                    proxy
                />
                <div
                    className="accounts-chart__entity__description"
                    ref={contentRef}
                >
                    <header>{entity.name}</header>
                    {currencyFormatter.format(entityTotalBalance)}
                </div>
            </AccountsChartEntityContextProvider>
        </div>
    );

    if (percentage < DISPLAY_THRESHOLD) {
        return null;
    }

    return contentHidden ? (
        <OverlayTrigger
            overlay={
                <Tooltip
                    id={`entity-balance-${entity.id}`}
                    className="accounts-chart__tooltip"
                >
                    <AccountChartTooltip entity={entity} />
                </Tooltip>
            }
            trigger={accounts.length > 1 ? [] : ["hover"]}
        >
            {content}
        </OverlayTrigger>
    ) : (
        content
    );
};
