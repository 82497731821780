import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { queryClient } from "../../../queryClient";
import { getTransactionDetails } from "../../../lib/transactions";
import { TransactionDetailsResponseDto } from "../../../common/dto/transactions/transaction-details-response.dto";
import { Transaction } from "../../../common/types/transaction";

const TRANSACTION_DETAILS_QUERY_KEY = "transactionDetails";

export function updateTransactionsDetailsQueryData(
    transactionId: number,
    transactionUpdate: Partial<Transaction>,
) {
    queryClient.setQueryData([TRANSACTION_DETAILS_QUERY_KEY, transactionId], ((
        oldData?: TransactionDetailsResponseDto,
    ) => ({
        ...oldData,
        transaction: {
            ...oldData?.transaction,
            ...transactionUpdate,
        },
    })) as any);
}

export function useTransactionDetailsQuery(id: number | null) {
    const { data } = useQuery({
        queryKey: [TRANSACTION_DETAILS_QUERY_KEY, id],
        queryFn: () => (id ? getTransactionDetails(id) : null),
    });

    return useMemo(
        () =>
            data ?? {
                transaction: undefined,
                receiptRequired: false,
                memoRequired: false,
            },
        [data],
    );
}

export function invalidateTransactionDetailsQueries(
    transactionId?: number,
): Promise<void> {
    return queryClient.invalidateQueries({
        queryKey: transactionId
            ? [TRANSACTION_DETAILS_QUERY_KEY, transactionId]
            : [TRANSACTION_DETAILS_QUERY_KEY],
    });
}

export function removeTransactionDetailsQueries(transactionId?: number) {
    queryClient.removeQueries({
        queryKey: transactionId
            ? [TRANSACTION_DETAILS_QUERY_KEY, transactionId]
            : [TRANSACTION_DETAILS_QUERY_KEY],
    });
}
