import { FC, ReactNode } from "react";
import classNames from "classnames";
import styles from "./KeyboardShortcut.module.scss";

export interface KeyboardShortcutProps {
    children: ReactNode;
    square?: boolean;
    className?: string;
}

export const KeyboardShortcut: FC<KeyboardShortcutProps> = ({
    children,
    square,
    className,
}) => (
    <span
        className={classNames(
            styles.keyboardShortcut,
            {
                [styles.square]: square,
            },
            className,
        )}
    >
        {children}
    </span>
);
