import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import {
    CampaignTrackingCookie,
    campaignTrackingCookies,
    getCampaign,
} from "../common/campaignCookies";
import { getAnalytics } from "../lib/analytics";

export function usePageTracking() {
    const location = useLocation();
    useEffect(() => {
        const campaignCookies = document.cookie.split("; ").reduce(
            (acc, cookie) => {
                const [name, value] = cookie.split("=");
                if (
                    campaignTrackingCookies.includes(
                        name as CampaignTrackingCookie,
                    )
                ) {
                    acc[name as CampaignTrackingCookie] = value;
                }
                return acc;
            },
            {} as Record<CampaignTrackingCookie, string>,
        );

        getAnalytics()?.page(
            {},
            {
                context: getCampaign(campaignCookies),
            },
        );
    }, [location]);
}
