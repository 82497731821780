import {
    NumberParam,
    DateTimeParam,
    withDefault,
    createEnumParam,
    useQueryParams,
} from "use-query-params";
import { ReportingTabCycle } from "../../common/constants";

export interface ReportParams {
    entityId?: number;
    cycle?: ReportingTabCycle;
    start?: Date;
    end?: Date;
}

export function useReportParams(): ReportParams {
    const [params] = useQueryParams({
        entityId: withDefault(NumberParam, undefined),
        cycle: withDefault(
            createEnumParam(Object.values(ReportingTabCycle)),
            undefined,
        ),
        start: withDefault(DateTimeParam, undefined),
        end: withDefault(DateTimeParam, undefined),
    });

    return params;
}
