import React from "react";
import { ReceiptEmails } from "./ReceiptEmails";
import "./ReceiptsSettings.scss";

export const ReceiptsSettings: React.FC = () => (
    <div className="settings__receipts">
        <header className="settings__header">
            <h3>Receipts</h3>
        </header>

        <section>
            <header className="settings__receipts__address">
                <small>Receipt forwarding address</small>
                <span className="settings__receipts__receipts-email">
                    receipts@kick.co
                </span>
            </header>

            <ReceiptEmails />
        </section>
    </div>
);
