import React from "react";
import classNames from "classnames";
import commonStyles from "../../steps/common.module.scss";
import {
    GrowthIcon,
    KeyboardReturnIcon,
    SavingsIcon,
    SignIcon,
} from "../../../../icons";
import { useKeyboardCommands } from "../../../../hooks/keyboard/useKeyboardCommands";
import { Button } from "../../../general/Button/Button";
import { KeyboardShortcut } from "../../../general/KeyboardShortcut/KeyboardShortcut";
import {
    StandardModalBody,
    StandardModalHeader,
} from "../../../general/Modal/Modal";
import styles from "./ConnectPrivateAccounts.module.scss";

interface ConnectPrivateAccountsProps {
    onContinue(): void;
}

export const ConnectPrivateAccounts: React.FC<ConnectPrivateAccountsProps> = ({
    onContinue,
}) => {
    useKeyboardCommands({
        commands: [
            {
                key: "Enter",
                requiresCtrlOrMeta: false,
                callback: onContinue,
            },
        ],
    });

    return (
        <>
            <StandardModalHeader>
                Don't forget to add your{" "}
                <em className={commonStyles.highlight}>personal accounts</em>
            </StandardModalHeader>

            <StandardModalBody>
                <p className="mb-4">Here's why this step is important:</p>
                <ul className={classNames("list-unstyled", styles.list)}>
                    <li className={styles.item}>
                        <span className={styles.icon}>
                            <GrowthIcon />
                        </span>
                        Ensure accurate financials by auto matching Transfers.
                    </li>

                    <li className={styles.item}>
                        <span className={styles.icon}>
                            <SavingsIcon />
                        </span>
                        <span>
                            Don’t miss out on tax savings–users save{" "}
                            <em className={commonStyles.highlight}>$4k</em> on
                            average.
                        </span>
                    </li>

                    <li className={styles.item}>
                        <span className={styles.icon}>
                            <SignIcon />
                        </span>
                        Handle Meals, Travel and Home Office tax deductions.
                    </li>
                </ul>

                <footer className={styles.footer}>
                    <Button variant="secondary" onClick={onContinue}>
                        Continue to Accounts
                        <KeyboardShortcut square className="ml-2">
                            <KeyboardReturnIcon />
                        </KeyboardShortcut>
                    </Button>
                </footer>
            </StandardModalBody>
        </>
    );
};
