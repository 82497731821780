import { useQuery } from "@tanstack/react-query";
import { getAppConfig } from "../lib/config";

export const appConfigQueryKeys = {
    all: () => ["appConfig"] as const,
} as const;

export function useAppConfig() {
    return useQuery({
        queryKey: appConfigQueryKeys.all(),
        queryFn: getAppConfig,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        staleTime: Infinity,
    });
}
