import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import classNames from "classnames";
import { useQueryParam } from "use-query-params";
import fileDownload from "js-file-download";
import { Loader } from "../general/Loader";
import "./TransactionsExport.scss";
import { DownloadIcon, ThunderboltIcon } from "../../icons";
import { get1099Report } from "../../api/ownerReports.api";
import { useEntities } from "../../hooks/useEntities";
import {
    getTransactionsCsv,
    getTransactionsXlsx,
} from "../../lib/transactions";
import { useWorkspaceContext } from "../../state/workspaceContext";
import { useTypedFlags } from "../../hooks/useTypedFlags";
import { FeatureAccess } from "../general/FeatureAccess/FeatureAccess";
import { PREMIUM_FEATURES } from "../../common/flatRateBilling";
import { PlanManagementProvider } from "../billing/PlanManagement/PlanManagementProvider";
import { EndTrialAction } from "../billing/AccessBlockedPage/EndTrialAction";
import { CustomButtonToggle } from "../general/CustomToggle/CustomToggle";
import { convertFiltersToDto } from "./useTransactionsQuery";
import { useFiltersActive } from "./useFiltersActive";
import { TransactionsFilters } from "./filters/lib";

export enum TransactionsExportFormat {
    CSV = "csv",
    XLSX = "xlsx",
}

interface Props {
    currentFilters: TransactionsFilters;
}

interface ExportFunctionParams {
    withFilters: boolean;
    format: TransactionsExportFormat;
    isLoading: boolean;
    loadingSetter: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExportDropDownItem: React.FC<{
    exportFn: (data: ExportFunctionParams) => Promise<void>;
    withFilters?: boolean;
    format: TransactionsExportFormat;
    children: React.ReactNode;
}> = ({ exportFn, withFilters, format, children }) => {
    const [isLoading, setIsLoading] = useState(false);
    return (
        <Dropdown.Item
            onClick={() =>
                exportFn({
                    withFilters: !!withFilters,
                    format,
                    isLoading,
                    loadingSetter: setIsLoading,
                })
            }
        >
            {isLoading ? (
                <span className="transactions-export__icon">
                    <Loader inline={true} />
                </span>
            ) : null}
            {children}
        </Dropdown.Item>
    );
};

export const TransactionsExport: React.FC<Props> = ({ currentFilters }) => {
    const [opened, setOpened] = useState(false);
    const [exporting1099, setExporting1099] = useState(false);
    const filtersActive = useFiltersActive(currentFilters);
    const { download1099Report } = useTypedFlags();
    const [dropdownOpened] = useQueryParam<boolean>("exportTransactions");

    const entities = useEntities();
    const { activeWorkspace } = useWorkspaceContext();

    useEffect(() => {
        if (dropdownOpened) {
            setOpened(true);
        }
    }, [dropdownOpened]);

    const exportTransactions = useCallback(
        async ({
            withFilters,
            format,
            isLoading,
            loadingSetter,
        }: ExportFunctionParams) => {
            if (isLoading) {
                return;
            }

            loadingSetter(true);

            try {
                if (format === TransactionsExportFormat.CSV) {
                    const response = await getTransactionsCsv(
                        convertFiltersToDto(
                            withFilters ? currentFilters : {},
                            entities,
                        ),
                    );

                    fileDownload(response, `transactions.csv`, "text/csv");
                } else {
                    const response = await getTransactionsXlsx(
                        convertFiltersToDto(
                            withFilters ? currentFilters : {},
                            entities,
                        ),
                    );

                    fileDownload(response, `transactions.xlsx`);
                }
            } finally {
                loadingSetter(false);
            }
        },
        [currentFilters, entities],
    );

    const download1099 = useCallback(async () => {
        if (exporting1099 || !activeWorkspace) {
            return;
        }

        setExporting1099(true);

        try {
            const data = await get1099Report(activeWorkspace.id);
            fileDownload(data, "2024 1099 Transactions.xlsx");
            setExporting1099(false);
        } catch {
            setExporting1099(false);
        }
    }, [exporting1099, activeWorkspace]);

    const onDropdownToggle = useCallback(
        (isOpen: boolean, event: SyntheticEvent<Dropdown>, metadata: any) => {
            if (metadata.source === "select") {
                setOpened(true);
            } else {
                setOpened(isOpen);
            }
        },
        [],
    );

    return (
        <Dropdown show={opened} onToggle={onDropdownToggle}>
            <Dropdown.Toggle
                id="transactions-export"
                as={CustomButtonToggle}
                variant="secondary"
                className={classNames({
                    active: opened,
                })}
            >
                <DownloadIcon />
            </Dropdown.Toggle>

            <Dropdown.Menu align="right">
                {filtersActive ? (
                    <>
                        <ExportDropDownItem
                            exportFn={exportTransactions}
                            withFilters
                            format={TransactionsExportFormat.CSV}
                        >
                            Filtered transactions (.csv)
                        </ExportDropDownItem>

                        <ExportDropDownItem
                            exportFn={exportTransactions}
                            withFilters
                            format={TransactionsExportFormat.XLSX}
                        >
                            Filtered transactions (.xlsx)
                        </ExportDropDownItem>
                    </>
                ) : null}
                <ExportDropDownItem
                    exportFn={exportTransactions}
                    format={TransactionsExportFormat.CSV}
                >
                    All transactions (.csv)
                </ExportDropDownItem>
                <ExportDropDownItem
                    exportFn={exportTransactions}
                    format={TransactionsExportFormat.XLSX}
                >
                    All transactions (.xlsx)
                </ExportDropDownItem>
                {download1099Report && (
                    <FeatureAccess
                        feature={PREMIUM_FEATURES.EXPORT_1099_REPORT}
                        disallowTrials
                        renderAllowed={() => (
                            <Dropdown.Item onClick={() => download1099()}>
                                {exporting1099 ? (
                                    <span className="transactions-export__icon">
                                        <Loader inline={true} />
                                    </span>
                                ) : null}
                                2024 1099 transactions (.xlsx)
                            </Dropdown.Item>
                        )}
                        renderBlocked={({ openPlans, isTrialing }) =>
                            isTrialing ? (
                                <PlanManagementProvider>
                                    <EndTrialAction>
                                        {(convert) => (
                                            <OverlayTrigger
                                                overlay={
                                                    <Tooltip
                                                        id={`blocked-1099`}
                                                    >
                                                        Start subscription to
                                                        download 1099
                                                        transactions for 2024
                                                    </Tooltip>
                                                }
                                            >
                                                <Dropdown.Item
                                                    onClick={convert}
                                                >
                                                    <ThunderboltIcon className="icon-color-blue-600 mr-2" />
                                                    2024 1099 transactions
                                                    (.xlsx)
                                                </Dropdown.Item>
                                            </OverlayTrigger>
                                        )}
                                    </EndTrialAction>
                                </PlanManagementProvider>
                            ) : (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip id={`blocked-1099`}>
                                            Upgrade to download 1099
                                            transactions for 2024
                                        </Tooltip>
                                    }
                                >
                                    <Dropdown.Item onClick={() => openPlans()}>
                                        <ThunderboltIcon className="icon-color-blue-600 mr-2" />
                                        2024 1099 transactions (.xlsx)
                                    </Dropdown.Item>
                                </OverlayTrigger>
                            )
                        }
                    />
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};
