import { useMemo } from "react";
import { TYPEFORM_INVOICES_EARLY_ACCESS_URL } from "../../constants";
import { useUser } from "../../hooks/useUser";
import { Button } from "../general/Button/Button";
import styles from "./InvoicesTeaser.module.scss";

export const InvoicesTeaser: React.FC = () => {
    const user = useUser();

    const typeformUrl = useMemo(
        () =>
            `${TYPEFORM_INVOICES_EARLY_ACCESS_URL}#email=${encodeURIComponent(user.email ?? "")}&name=${encodeURIComponent(user.name ?? "")}&user_id=${user.id}`,
        [user],
    );

    return (
        <div className={styles.teaser}>
            <h4>Coming soon! 🚀</h4>
            <p>Join the waitlist to get early access to Kick Invoicing</p>
            <Button asChild size="sm" variant="secondary">
                <a href={typeformUrl}>Request early access</a>
            </Button>
        </div>
    );
};
