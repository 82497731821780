import classNames from "classnames";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useActionsSummary } from "../../hooks/useActionsSummary";
import { useActiveNavLink } from "../../hooks/useActiveNavLink";
import { useEntities } from "../../hooks/useEntities";
import {
    AccountsIcon,
    ActionsIcon,
    ActivityIcon,
    BankIcon,
    BillingIcon,
    DocumentIcon,
    GraphIcon,
    RemoveIcon,
} from "../../icons";
import { useTypedFlags } from "../../hooks/useTypedFlags";
import { BillingState } from "../billing/BillingState/BillingState";
import { EntityManagementDropdown } from "../entity/EntityManagementDropdown";
import { Button } from "../general/Button/Button";
import { useSubmenuTransitionAnimation } from "../../hooks/useSubmenuTransitionAnimation";
import { EntitiesNavigation } from "./EntitiesNavigation";
import { EntitySideNavMenu } from "./EntitySideNavMenu";
import "./Navigation.scss";
import { useNavigationContext } from "./NavigationContext";
import { NavigationDropdown } from "./NavigationDropdown";
import { NavigationItem } from "./NavigationItem";
import { NavigationMenu } from "./NavigationMenu";
import { NavigationSubsection } from "./NavigationSubsection";
import { NavigationSubsectionItem } from "./NavigationSubsectionItem";
import { NavigationSubsectionController } from "./NavigationSubsectionController";

export const Navigation: React.FC = () => {
    const location = useLocation();
    const { close } = useNavigationContext();
    const navigationRef = useRef<HTMLDivElement>(null);
    const indicatorRef = useRef<HTMLDivElement>(null);
    const [isReady, setIsReady] = useState(false);
    const [transactionSubsectionOpened, setTransactionSubsectionOpened] =
        useState(false);
    const { classes: canUseClasses } = useTypedFlags();

    const actionsSummary = useActionsSummary();

    const activeNavLink = useActiveNavLink(navigationRef.current);

    const calculateActiveLinkIndicatorPosition = useCallback(() => {
        if (!activeNavLink || !indicatorRef.current) {
            return;
        }

        const { offsetTop } = activeNavLink;
        indicatorRef.current.style.transform = `translateY(${offsetTop}px)`;

        setIsReady(true);
    }, [activeNavLink, indicatorRef]);

    const { isAnimationActive, triggerAnimation } =
        useSubmenuTransitionAnimation({
            onAnimationFrame: calculateActiveLinkIndicatorPosition,
        });

    useEffect(() => {
        triggerAnimation();
    }, [triggerAnimation, transactionSubsectionOpened]);

    useEffect(() => {
        calculateActiveLinkIndicatorPosition();
    }, [activeNavLink, calculateActiveLinkIndicatorPosition]);

    const entities = useEntities();

    const [openedEntity, setOpenedEntity] = useState<number | undefined>(
        entities[0]?.isBusiness ? entities[0]?.id : undefined,
    );

    const businessEntities = useEntities({
        onlyBusiness: true,
        excludeAllMockEntities: true,
    });
    const hasBusinessEntities = businessEntities.length > 0;

    const showActionIndicator = !!actionsSummary.data?.pending;

    const isSubsection = ["/rules", "/categories", "/classes"].includes(
        location.pathname,
    );

    const shouldOpenTransactionSubsection = useMemo(
        () => isSubsection || activeNavLink?.pathname === "/transactions",
        [isSubsection, activeNavLink],
    );

    useEffect(() => {
        if (shouldOpenTransactionSubsection) {
            setTransactionSubsectionOpened(true);
        } else if (!isSubsection) {
            setTransactionSubsectionOpened(false);
        }
    }, [shouldOpenTransactionSubsection, isSubsection]);

    return (
        <nav className="navigation">
            <header className="navigation__header">
                <NavigationDropdown />

                <aside className="ml-auto">
                    <Button
                        size="sm"
                        variant="tertiary"
                        icon
                        onClick={close}
                        className="navigation__header__close"
                    >
                        <RemoveIcon />
                    </Button>
                </aside>
            </header>
            <div className="navigation__content" ref={navigationRef}>
                <NavigationMenu>
                    <div
                        ref={indicatorRef}
                        className={classNames("nav-indicator", {
                            "nav-indicator--ready": isReady,
                            "nav-indicator--subsection": isSubsection,
                            "nav-indicator--submenu-animation-active":
                                isAnimationActive,
                        })}
                    />
                    <NavLink
                        to="/actions"
                        className={({ isActive }) =>
                            classNames({
                                active: isActive || location.pathname === "/",
                            })
                        }
                    >
                        <NavigationItem icon={<ActionsIcon />}>
                            Actions
                            {showActionIndicator && (
                                <div className="badge__container">
                                    <div className="badge__indicator" />
                                </div>
                            )}
                        </NavigationItem>
                    </NavLink>
                    <NavLink
                        to="/invoices"
                        className={({ isActive }) =>
                            classNames({ active: isActive })
                        }
                        end
                    >
                        <NavigationItem icon={<BillingIcon />}>
                            Invoicing
                        </NavigationItem>
                    </NavLink>
                    <NavLink
                        to="/cashflow"
                        className={({ isActive }) =>
                            classNames({ active: isActive })
                        }
                        end
                    >
                        <NavigationItem icon={<GraphIcon />}>
                            Cash Flow
                        </NavigationItem>
                    </NavLink>

                    <NavLink
                        to="/transactions"
                        className={({ isActive }) =>
                            classNames({ active: isActive })
                        }
                        end
                    >
                        <NavigationItem icon={<ActivityIcon />}>
                            Transactions
                            <NavigationSubsectionController
                                open={transactionSubsectionOpened}
                                disabled={shouldOpenTransactionSubsection}
                                isSubsection={isSubsection}
                                onToggle={() =>
                                    setTransactionSubsectionOpened(
                                        (prev: boolean) => !prev,
                                    )
                                }
                            />
                        </NavigationItem>
                    </NavLink>

                    <NavigationSubsection isOpen={transactionSubsectionOpened}>
                        <NavLink
                            to={`/rules`}
                            className={({ isActive }) =>
                                classNames({ active: isActive })
                            }
                            end
                        >
                            <NavigationSubsectionItem>
                                Rules
                            </NavigationSubsectionItem>
                        </NavLink>
                        <NavLink
                            to={`/categories`}
                            className={({ isActive }) =>
                                classNames({ active: isActive })
                            }
                            end
                        >
                            <NavigationSubsectionItem>
                                Categories
                            </NavigationSubsectionItem>
                        </NavLink>
                        {canUseClasses && (
                            <NavLink
                                to={`/classes`}
                                className={({ isActive }) =>
                                    classNames({ active: isActive })
                                }
                                end
                            >
                                <NavigationSubsectionItem>
                                    Classes
                                </NavigationSubsectionItem>
                            </NavLink>
                        )}
                    </NavigationSubsection>
                    <NavLink
                        to="/accounts"
                        className={({ isActive }) =>
                            classNames({ active: isActive })
                        }
                    >
                        <NavigationItem icon={<AccountsIcon />}>
                            Accounts
                        </NavigationItem>
                    </NavLink>

                    <NavLink
                        to="/documents"
                        className={({ isActive }) =>
                            classNames({ active: isActive })
                        }
                    >
                        <NavigationItem icon={<DocumentIcon />}>
                            Documents
                        </NavigationItem>
                    </NavLink>

                    {hasBusinessEntities && (
                        <NavLink
                            to="/accounting"
                            className={({ isActive }) =>
                                classNames({ active: isActive })
                            }
                        >
                            <NavigationItem icon={<BankIcon />}>
                                Accounting
                            </NavigationItem>
                        </NavLink>
                    )}
                </NavigationMenu>

                <EntitiesNavigation
                    label="Views"
                    isEntityActive={() => false}
                    isEntityDisabled={(e) => e.isPersonal}
                    renderNavigation={(e) => <EntitySideNavMenu entity={e} />}
                    renderAddon={(e) => (
                        <EntityManagementDropdown entity={e} showAccounts />
                    )}
                    openedEntity={openedEntity}
                    setOpenedEntity={setOpenedEntity}
                />
            </div>

            <footer className="navigation__footer">
                <BillingState />
            </footer>
        </nav>
    );
};
