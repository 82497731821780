import React, {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useState,
} from "react";
import { isFunction } from "lodash";
import { useWindowMessage } from "../../hooks/useWindowMessage";
import {
    OAuthAuthorizationResponse,
    triggerAuthorization,
} from "../../lib/oauth";
import { ButtonWithLoader } from "../general/ButtonWithLoader/ButtonWithLoader";
import { OAuthServiceType } from "../../common/constants";
import { useInteraction } from "../../hooks/useInteraction";
import { CreateAccountHandle } from "../accounts/AddAccounts/lib";
import { useMercuryConnectionCreationMutation } from "../../mutations/mercuryConnection";
import { FinancialConnectionWithAccounts } from "../../common/types/financialConnection";
import { Entity } from "../../common/types/entity";
import { ExtendedButtonProps } from "../general/Button/Button";

interface Props {
    children?:
        | React.ReactNode
        | ((props: {
              connecting: boolean;
              initializeConnection: () => void;
          }) => React.ReactNode);
    btnVariant?: ExtendedButtonProps["variant"];
    onCreated: (connection: FinancialConnectionWithAccounts) => void;
    defaultEntity?: Entity;
}
export const CreateMercuryConnection = forwardRef<CreateAccountHandle, Props>(
    (
        {
            children = <>Connect</>,
            onCreated,
            btnVariant = "tertiary",
            defaultEntity,
        },
        ref,
    ) => {
        const mutation = useMercuryConnectionCreationMutation(onCreated);
        const [creating, setCreating] = useState(false);
        const handler = ({ code }: OAuthAuthorizationResponse) => {
            if (creating) {
                mutation.mutate({ code, defaultEntity });
            }
            setCreating(false);
        };

        useWindowMessage<OAuthAuthorizationResponse>({
            scope: OAuthServiceType.MERCURY,
            handler,
        });

        const initializeConnection = useCallback(() => {
            if (creating) {
                return;
            }

            triggerAuthorization(OAuthServiceType.MERCURY);
            setCreating(true);
        }, [creating]);

        const authorize = useInteraction(initializeConnection);

        useImperativeHandle(
            ref,
            () => ({
                initializeConnection,
            }),
            [initializeConnection],
        );

        if (isFunction(children)) {
            return children({ connecting: creating, initializeConnection });
        }

        return (
            <span {...authorize}>
                <ButtonWithLoader
                    loading={mutation.isPending}
                    variant={btnVariant}
                    size="sm"
                >
                    {children}
                </ButtonWithLoader>
            </span>
        );
    },
);
