import React from "react";
import classNames from "classnames";
import { Card } from "../general/Card/Card";
import { SearchFilter } from "../general/SearchFilter/SearchFilter";
import { FiltersTypes } from "../../common/types/filters/general";
import styles from "./TablePageHeader.module.scss";
import { FiltersChangeFn, FilterTabs } from "./filters/lib";
import { Filters, FiltersProps } from "./filters/Filters";

interface Props
    extends Omit<FiltersProps, "filters" | "onChange" | "availableFilters"> {
    children?: React.ReactNode;
    className?: string;

    prependChildren?: React.ReactNode;

    search?: string;
    onSearchChange?: (val: string) => void;

    filters?: FiltersTypes;
    onFiltersChange?: FiltersChangeFn;
    availableFilters?: FilterTabs[];
    hardcodedEntityId?: number;
}

export const TablePageHeader: React.FC<Props> = ({
    children,
    prependChildren,
    search,
    onSearchChange,
    className,
    filters,
    onFiltersChange,

    availableFilters,
    defaultFilters,
    defaultTab = availableFilters?.[0],
    directionSwitchDisabled,
    hardcodedEntityId,
}) => (
    <Card className={classNames(styles.container, "mb-4", className)}>
        <div className="d-flex gap-buttons">
            {prependChildren}
            {onSearchChange && (
                <SearchFilter
                    placeholder="Search rules"
                    onChange={(value) => onSearchChange(value ?? "")}
                    filter={search}
                    minLength={2}
                />
            )}

            {onFiltersChange && !!availableFilters?.length && (
                <Filters
                    onChange={onFiltersChange}
                    filters={filters || {}}
                    availableFilters={availableFilters}
                    defaultFilters={defaultFilters}
                    defaultTab={defaultTab}
                    directionSwitchDisabled={directionSwitchDisabled}
                    hardcodedEntityId={hardcodedEntityId}
                />
            )}
        </div>

        <div className="d-flex gap-buttons">{children}</div>
    </Card>
);
