import React from "react";
import { useQuery } from "@tanstack/react-query";
import { AddressSettings } from "../General/Address/AddressSettings";
import { Loader } from "../../general/Loader";
import { useEntity } from "../../entity/useEntity";
import { getAddressQueryKey } from "../../../queryClient";
import { getAddress } from "../../../lib/entity";

export const EntityAddressSettings: React.FC = () => {
    const entity = useEntity();
    const address = useQuery({
        queryKey: getAddressQueryKey(entity.id),
        queryFn: () => getAddress(entity),
    });

    return (
        <section>
            <header className="settings__section__header">
                <h4>{entity.isBusiness ? "Business" : "Home"} address</h4>
            </header>
            {address.isSuccess ? (
                <AddressSettings address={address.data ?? undefined} />
            ) : (
                <Loader />
            )}
        </section>
    );
};
