import { useQuery } from "@tanstack/react-query";
import { queryFinancialDocuments } from "../../lib/financialDocument";
import { FetchFinancialDocumentsDto } from "../../common/dto/financialDocument/fetch-financial-documents.dto";
import { financialDocumentQueryKeys } from "./lib";

export const FINANCIAL_DOCUMENTS_PAGE_SIZE = 20;
export const REFETCH_FOR_MATCHING_RESULTS_INTERVAL_MS = 10_000;
export function useFinancialDocumentsQuery(
    queryParams: FetchFinancialDocumentsDto,
    shouldRefetch?: () => boolean,
) {
    return useQuery({
        queryKey: financialDocumentQueryKeys.list(queryParams),
        queryFn: () => queryFinancialDocuments(queryParams),
        refetchInterval(query) {
            const hasDocumentsWithoutMatchAttempt =
                query.state.data?.data?.some(
                    (financialDocument) =>
                        !financialDocument.transactionMatches.length,
                );

            return hasDocumentsWithoutMatchAttempt || shouldRefetch?.()
                ? REFETCH_FOR_MATCHING_RESULTS_INTERVAL_MS
                : false;
        },
    });
}
