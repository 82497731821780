import React, { useCallback, useEffect, useMemo, useState } from "react";
import { keyBy, mapValues, omit, omitBy } from "lodash";
import { TransactionListItemDto } from "../../../common/types/transaction";
import { bulkUpdateTransactions } from "../../../lib/transactions";
import { useObserver } from "../../../hooks/useObserver";
import { SelectedAnswer, TaxAnswers } from "../TaxQuestions/types";
import { TransactionsFilters } from "../filters/lib";
import { useSequentialRequest } from "../../../hooks/useSequentialRequests";
import { BulkUpdateTransactionsDto } from "../../../common/dto/transactions/bulk/bulk-update-transactions.dto";
import { TransactionDirectionType } from "../../../common/categories";
import { useWorkspaceContext } from "../../../state/workspaceContext";
import { ChildrenProps } from "../../../types";
import { useEntities } from "../../../hooks/useEntities";
import { useToaster } from "../../general/ToastMessages/useToaster";
import { useStandardCategories } from "../../../hooks/useStandardCategories";
import { useTaxQuestionsForBulkActions } from "./useTaxQuestionsForBulkActions";
import { useTaxSavingRulesForBulkActions } from "./useTaxSavingRulesForBulkActions";
import {
    BULK_ACTIONS_CHUNK_SIZE,
    BulkUpdateDetails,
    BulkUpdateMode,
    BulkUpdateParams,
    BulkUpdateStatus,
    preparePayloadsForFilters,
    preparePayloadsForSelectedTransactions,
    preparePayloadsForUndo,
} from "./lib";
import {
    SelectedTransaction,
    transactionsBulkActionsContext,
    TransactionsBulkActionsContextValue,
} from "./transactionsBulkActionsContext";
import { TransactionsUpdateToast } from "./TransactionsUpdateStatus/TransactionsUpdateToast";

function mapTransactionToSelected(
    transaction: TransactionListItemDto,
): SelectedTransaction {
    return {
        id: transaction.id,
        type: transaction.type,
        isIgnored: transaction.isIgnored,
        categoryId: transaction.categoryId,
        isBusiness: transaction.isBusiness,
        memo: transaction.memo,
        taxQuestionAnswers: transaction.taxQuestionAnswers,
        entity: transaction.entity,
        transactionDirection:
            transaction.amount > 0
                ? TransactionDirectionType.incoming
                : TransactionDirectionType.outgoing,
        counterpartyId: transaction.counterpartyId,
    };
}

const TOAST_MESSAGE_KEY = "transactions-bulk-actions";

export interface TransactionsBulkActionsContextProviderProps
    extends ChildrenProps {
    onUpdated(
        updatedTransactions: TransactionListItemDto[],
        details: BulkUpdateDetails,
    ): void;
    totalTransactions: number;
    currentFilters: TransactionsFilters;
}

export const TransactionsBulkActionsContextProvider: React.FC<
    TransactionsBulkActionsContextProviderProps
> = ({ onUpdated, children, currentFilters, totalTransactions }) => {
    const [updateDetails, setUpdateDetails] =
        useState<BulkUpdateDetails | null>(null);
    const [answers, setAnswers] = useState<TaxAnswers>({} as TaxAnswers);
    const standardCategories = useStandardCategories();

    const [selected, setSelected] = useState<
        Record<number, SelectedTransaction>
    >({});

    const [filtersSelection, setFiltersSelection] =
        useState<TransactionsFilters | null>(null);

    const {
        notify: notifySelectionChanged,
        subscribe: subscribeToSelectionChanges,
    } = useObserver<void>();
    const { toast } = useToaster();

    const { activeWorkspaceId } = useWorkspaceContext();

    const updateSequence = useSequentialRequest(
        bulkUpdateTransactions.bind(null, activeWorkspaceId ?? ""),
    );

    const entities = useEntities();

    useEffect(() => {
        if (
            updateSequence.progress > 0 &&
            updateSequence.progress < updateSequence.total &&
            updateDetails
        ) {
            toast(
                <TransactionsUpdateToast
                    updateDetails={updateDetails}
                    updateProgress={
                        updateSequence.progress * BULK_ACTIONS_CHUNK_SIZE
                    }
                />,
                TOAST_MESSAGE_KEY,
                { preventAutoClose: true },
            );
        }
    }, [updateSequence.progress, updateSequence.total, updateDetails, toast]);

    const selectedCount = useMemo(
        () =>
            filtersSelection ? totalTransactions : Object.keys(selected).length,
        [filtersSelection, selected, totalTransactions],
    );

    useEffect(() => {
        setAnswers({} as TaxAnswers);
        notifySelectionChanged();
    }, [selectedCount, notifySelectionChanged]);

    useEffect(() => {
        setFiltersSelection(null);
    }, [currentFilters]);

    const taxRule = useTaxSavingRulesForBulkActions(selected, filtersSelection);
    const taxQuestions = useTaxQuestionsForBulkActions(
        selected,
        filtersSelection,
        answers,
    );

    const isSelected: TransactionsBulkActionsContextValue["isSelected"] =
        useCallback((transaction) => !!selected[transaction.id], [selected]);

    const select: TransactionsBulkActionsContextValue["select"] = useCallback(
        (transactionOrTransactions) => {
            const selectionUpdate: Record<number, SelectedTransaction> =
                Array.isArray(transactionOrTransactions)
                    ? keyBy(
                          transactionOrTransactions.map(
                              mapTransactionToSelected,
                          ),
                          "id",
                      )
                    : {
                          [transactionOrTransactions.id]:
                              mapTransactionToSelected(
                                  transactionOrTransactions,
                              ),
                      };

            setSelected((prev) => ({ ...prev, ...selectionUpdate }));
        },
        [],
    );
    const deselect: TransactionsBulkActionsContextValue["deselect"] =
        useCallback((transactionOrTransactions) => {
            if (Array.isArray(transactionOrTransactions)) {
                const dict = keyBy(transactionOrTransactions, "id");
                setSelected((prev) =>
                    omitBy(prev, (value) => value.id in dict),
                );
            } else {
                setSelected((prev) => omit(prev, transactionOrTransactions.id));
            }
        }, []);

    const doUpdate = useCallback(
        async (
            details: BulkUpdateDetails,
            payloads: BulkUpdateTransactionsDto[],
        ) => {
            setUpdateDetails(details);

            const result = (await updateSequence.start(payloads)).flat();

            if (details.mode === BulkUpdateMode.SELECTED) {
                setSelected(keyBy(result.map(mapTransactionToSelected), "id"));
            }

            onUpdated(result, details);

            setUpdateDetails((prev) => ({
                ...prev!,
                status: BulkUpdateStatus.SUCCESS,
            }));
        },
        [onUpdated, updateSequence],
    );

    const undo = useCallback(
        async (
            previousTransactions: Record<number, SelectedTransaction>,
            updateDetailsForUndo: BulkUpdateDetails,
        ) => {
            const payloads = preparePayloadsForUndo({
                lastUpdateParams: updateDetailsForUndo,
                previousTransactions,
                standardCategories,
            });

            try {
                const newDetails = {
                    ...updateDetailsForUndo,
                    status: BulkUpdateStatus.UPDATING,
                    transactionsCount: Object.keys(previousTransactions).length,
                    isUndo: true,
                };
                await doUpdate(newDetails, payloads);

                toast(
                    <TransactionsUpdateToast
                        updateDetails={{
                            ...newDetails,
                            status: BulkUpdateStatus.SUCCESS,
                        }}
                    />,
                    TOAST_MESSAGE_KEY,
                );
            } catch {
                setUpdateDetails((prev) => ({
                    ...prev!,
                    status: BulkUpdateStatus.ERROR,
                }));
            }
        },
        [doUpdate, toast, standardCategories],
    );

    const updateSelected = useCallback(
        async (params: BulkUpdateParams) => {
            const payloads = filtersSelection
                ? preparePayloadsForFilters({
                      updateParams: params,
                      filters: {
                          ...filtersSelection,
                          entityIds:
                              filtersSelection.entityIds &&
                              filtersSelection.entityIds.length > 0
                                  ? filtersSelection.entityIds
                                  : entities.map((entity) => entity.id),
                      },
                      transactionCount: selectedCount,
                  })
                : preparePayloadsForSelectedTransactions({
                      updateParams: params,
                      selected,
                  });

            try {
                const newDetails = {
                    status: BulkUpdateStatus.UPDATING,
                    transactionsCount: selectedCount,
                    mode: filtersSelection
                        ? BulkUpdateMode.FILTERS
                        : BulkUpdateMode.SELECTED,
                    ...params,
                };

                await doUpdate(newDetails, payloads);

                toast(
                    <TransactionsUpdateToast
                        updateDetails={{
                            ...newDetails,
                            status: BulkUpdateStatus.SUCCESS,
                        }}
                        previousTransactions={
                            filtersSelection ? undefined : { ...selected }
                        }
                        undo={undo}
                    />,
                    TOAST_MESSAGE_KEY,
                );
            } catch {
                setUpdateDetails((prev) => ({
                    ...prev!,
                    status: BulkUpdateStatus.ERROR,
                }));
            }
        },
        [
            doUpdate,
            filtersSelection,
            selected,
            selectedCount,
            toast,
            undo,
            entities,
        ],
    );

    const handleAnswer = useCallback(
        (selectedAnswers: SelectedAnswer[]) => {
            setAnswers((prev) => ({
                ...prev,
                ...mapValues(keyBy(selectedAnswers, "key"), "answer"),
            }));

            return updateSelected({
                update: "taxQuestionAnswers",
                value: selectedAnswers,
            });
        },
        [updateSelected],
    );

    const clearSelection: TransactionsBulkActionsContextValue["clearSelection"] =
        useCallback(() => {
            setSelected({});
            setFiltersSelection(null);
        }, []);

    const selectByCurrentFilters = useCallback(() => {
        clearSelection();
        setFiltersSelection(currentFilters);
    }, [clearSelection, currentFilters]);

    const bulkActionsContextValue = useMemo(
        () => ({
            enabled: true,
            select,
            selected,
            deselect,
            clearSelection,
            isSelected,
            selectedCount,
            updateDetails,
            updateSelected,
            subscribeToSelectionChanges,
            taxRule,
            answers,
            taxQuestions,
            handleAnswer,
            selectAll: selectByCurrentFilters,
            hasSelectedAll: !!filtersSelection,
            totalTransactions,
        }),
        [
            answers,
            clearSelection,
            selected,
            deselect,
            filtersSelection,
            handleAnswer,
            isSelected,
            select,
            selectByCurrentFilters,
            selectedCount,
            subscribeToSelectionChanges,
            taxQuestions,
            taxRule,
            totalTransactions,
            updateDetails,
            updateSelected,
        ],
    );

    return (
        <transactionsBulkActionsContext.Provider
            value={bulkActionsContextValue}
        >
            {children}
        </transactionsBulkActionsContext.Provider>
    );
};
