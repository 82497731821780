import React, { useContext } from "react";
import { Transaction } from "../../../common/types/transaction";
import { TransactionCategoryChange } from "../TransactionCategoryChange";
import { CATEGORIZE_ACTION } from "../../../common/requiredActions";
import { useMobileView } from "../../../hooks/useMobileView";
import { TransactionDetailsSection } from "./TransactionDetailsSection";
import { TransactionDetailsContext } from "./transactionDetails.context";

interface TransactionDetailsCategoryProps {
    transaction: Transaction;
}

export const TransactionDetailsCategory: React.FC<
    TransactionDetailsCategoryProps
> = ({ transaction }) => {
    const { onUpdate } = useContext(TransactionDetailsContext);
    const enableWarning =
        transaction.requiredActions.includes(CATEGORIZE_ACTION);

    const isMobile = useMobileView();

    return (
        <TransactionDetailsSection
            className="transaction-details__section--category"
            addon={
                <TransactionCategoryChange
                    transaction={transaction}
                    onUpdate={onUpdate}
                    enableWarning={enableWarning}
                    placement={isMobile ? "bottom" : "left-start"}
                />
            }
        />
    );
};
