import { useEffect, useState } from "react";
import {
    Transaction,
    TransactionListItemDto,
} from "../../../common/types/transaction";
import { useEntities } from "../../../hooks/useEntities";
import { Loader } from "../../general/Loader";
import { TransactionSortValue, useSort } from "../../transactions/useSort";
import { useTransactionsQuery } from "../../transactions/useTransactionsQuery";
import { ActionCardTransactionsTable } from "../ActionCardTransactionsTable";
import { UpdateTransactionDto } from "../../../common/dto/transactions/update-transaction.dto";
import { useUpdateTransactionMutation } from "../../../mutations/transaction";
import { queryClient } from "../../../queryClient";
import { Category } from "../../../common/types/category";
import styles from "./RecentTransactions.module.scss";

interface Props {
    onLoadingComplete?: (count: number) => void;
}

const REFRESH_INTERVAL = 5000;
const MAX_TRANSACTIONS = 5;

export const RecentTransactions: React.FC<Props> = ({ onLoadingComplete }) => {
    const entities = useEntities();
    const { sortExpression } = useSort(TransactionSortValue.DATE_DESC);
    const [loading, setLoading] = useState(false);

    const {
        query: { data, isLoading, refetch },
        queryKey,
    } = useTransactionsQuery({
        sort: sortExpression,
        filters: {
            entityIds: entities
                .filter((entity) => entity.isBusiness)
                .map((entity) => entity.id),
            processedByML: true,
        },
        page: 1,
        limit: MAX_TRANSACTIONS,
    });

    useEffect(() => {
        if (!data?.data) {
            return;
        }

        const hasEnoughTransactions =
            data.data.length > 0 &&
            (data.data.length >= MAX_TRANSACTIONS ||
                data.total <= MAX_TRANSACTIONS);

        if (!hasEnoughTransactions) {
            setLoading(true);
            const timer = setTimeout(() => {
                refetch();
            }, REFRESH_INTERVAL);

            return () => {
                clearTimeout(timer);
            };
        }

        setLoading(false);
    }, [data, refetch]);

    useEffect(() => {
        if (!loading && onLoadingComplete) {
            onLoadingComplete(data?.data.length ?? 0);
        }
    }, [data?.data.length, loading, onLoadingComplete]);

    const updateTransaction = useUpdateTransactionMutation();

    const updateCategory = async (
        transaction: TransactionListItemDto,
        category: Category,
    ) => {
        const payload: UpdateTransactionDto = {};
        const payloadForOptimisticUpdate: Partial<Transaction> = {};

        payload.categoryId = category.id;
        payloadForOptimisticUpdate.category = category;

        queryClient.setQueryData(
            queryKey,
            (prev: { data: TransactionListItemDto[] }) => {
                if (prev?.data) {
                    return {
                        ...prev,
                        data: prev.data.map((t) =>
                            t.id === transaction.id
                                ? {
                                      ...t,
                                      ...payload,
                                      ...payloadForOptimisticUpdate,
                                  }
                                : t,
                        ),
                    };
                } else {
                    return {
                        data: [
                            {
                                ...transaction,
                                ...payload,
                                ...payloadForOptimisticUpdate,
                            },
                        ],
                        total: 1,
                        pageCount: 1,
                    };
                }
            },
        );
        await updateTransaction.mutateAsync({ transaction, update: payload });
    };

    if (isLoading || loading) {
        return (
            <div className={styles.loadingMessage}>
                <Loader inline /> We're processing your transactions.
            </div>
        );
    }

    return (
        <ActionCardTransactionsTable
            transactions={data?.data ?? []}
            onUpdateCategory={updateCategory}
        />
    );
};
